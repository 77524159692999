import React, { FunctionComponent } from 'react';
import Card from '@Components/Card/Card';
import { ModalWrapper } from './PickupBooking.styled';

const ModalPickupBooking: FunctionComponent<types.cards.IOutletPickupCardProps['params']> = ({
	selectedOutletId,
	disabledDates,
	setAlertVisibility,
	bookingPickupAppointment,
	cancelCurrentAppointment,
	bookNewAppointment,
}) => {
	return (
		<ModalWrapper>
			<Card<types.cards.IOutletPickupCardProps>
				card="pickupBooking"
				withoutContainerPadding
				params={{
					selectedOutletId,
					disabledDates,
					setAlertVisibility,
					bookingPickupAppointment,
					cancelCurrentAppointment,
					bookNewAppointment,
				}}
			/>
		</ModalWrapper>
	);
};

export default ModalPickupBooking;
