import ConditionalRender from '@Components/ConditionalRender/ConditionalRender';
import {
	PrimaryMediumButtonFixed,
	PrimaryMediumButtonFlex,
	SecondaryMediumButtonFixed,
	SecondaryMediumButtonFlex,
} from '@Components/controls/Button/Button';
import Divider from '@Components/Divider/Divider';
import Icon from '@Components/Icon/Icon';
import { Caption, HeadlineBold, ComparisonCurrency, TitleBold, SmallCurrency } from '@Components/Typography/Typography';
import useScreen from '@Utils/hooks/useScreen';
import useTranslate from '@Utils/hooks/useTranslate';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { IPlanComparison } from '../PlanComparison';
import {
	ButtonsFixed,
	ButtonsSubtitle,
	ButtonWrapper,
	ButtonWrapperXS,
	CaptionWrapper,
	Empty,
	EmptyLine,
	LocalAndGcc,
	PlanButton,
	PlanDataItem,
	PlanDataItemHBB,
	PlanHeader,
	PlanHeaderCommitment,
	PlanHeaderTitle,
	PlanHeaderType,
	PlanItem,
	PlanItems,
	PlanItemValue,
	PlanWrapper,
	Progress,
	ProgressEmpty,
	ProgressFill,
	RowTitle,
	RowWrapper,
	StoreCredit,
	StoreCreditItem,
	SubtitleWrapper,
	DurationWrapper,
	IconsWrapper,
	Unlimited,
	PlansWrapper,
	HBBCaptionWrapper,
} from './Plan.styled';
import { matchScreen } from '@Utils/layout/layoutSupport';

interface IPlanProps {
	plan: IPlanComparison;
	max: number;
	index: number;
	isProductCardRoute?: boolean;
	onPlanSelection?: (planIndex: number) => void;
	planDetailsMode: boolean;
	currentPlanIsHBB: boolean;
	currentPlanIsPrepaid: boolean;
	hbbWfbbProcess?: boolean;
}
const Plan: FunctionComponent<IPlanProps> = ({
	plan,
	max,
	index,
	onPlanSelection,
	planDetailsMode,
	isProductCardRoute,
	currentPlanIsHBB,
	currentPlanIsPrepaid,
	hbbWfbbProcess,
}) => {
	const [dataFill, setDataFill] = useState<number>(0);
	const [dataEmpty, setDataEmpty] = useState<number>(100);
	const [priceWithVat, setPriceWithVat] = useState<number>(0);
	const { translate } = useTranslate();
	const { screenClass } = useScreen();

	useEffect(() => {
		if (hbbWfbbProcess) {
			setPriceWithVat(Number(plan?.offerPrice) || 0);
		} else {
			setPriceWithVat(
				plan.planVatValue && plan.monthlyPayment ? Number(plan.planVatValue) + Number(plan.monthlyPayment) : 0
			);
		}
	}, [plan]);

	useEffect(() => {
		if (typeof plan.localAndGccData === 'number') {
			if (plan.localAndGccData <= 0) {
				setDataFill(0);
				setDataEmpty(100);
			} else if (plan.localAndGccData > 0 && plan.localAndGccData < 100) {
				const percentage = Math.round((plan.localAndGccData / 100) * 100);
				setDataFill(percentage);
				setDataEmpty(100 - percentage);
			} else {
				setDataFill(100);
				setDataEmpty(0);
			}
		}
	}, [plan.localAndGccData]);

	const RowTitleXS = ({ title }: { title: string }) => {
		return (
			<ConditionalRender
				show={index === 0 && screenClass === 'xs'}
				onTrue={() => (
					<RowWrapper>
						<RowTitle>{translate(title)}</RowTitle>
					</RowWrapper>
				)}
			/>
		);
	};

	const buttons = () => {
		return (
			<ConditionalRender
				show={screenClass === 'xs' && !hbbWfbbProcess}
				onTrue={() => (
					<ConditionalRender
						show={index === 0}
						onTrue={() => (
							<ButtonsFixed>
								<SubtitleWrapper>
									<ButtonsSubtitle>{translate('plan.button.subtitle.select.plan')}</ButtonsSubtitle>
								</SubtitleWrapper>
								<ButtonWrapperXS>
									<SecondaryMediumButtonFlex
										uniqueId="plan-comparison.keep.my.plan"
										onClick={() => {
											onPlanSelection && onPlanSelection(0);
										}}
									>
										{translate('plan-comparison.keep.my.plan')}
									</SecondaryMediumButtonFlex>
								</ButtonWrapperXS>

								<ButtonWrapperXS>
									<PrimaryMediumButtonFlex
										uniqueId="plan-comparison.select.new.plan"
										onClick={() => {
											onPlanSelection && onPlanSelection(1);
										}}
									>
										{translate('plan-comparison.select.new.plan')}
									</PrimaryMediumButtonFlex>
								</ButtonWrapperXS>
							</ButtonsFixed>
						)}
					/>
				)}
				onFalse={() => (
					<ConditionalRender
						show={!!(max > 1 || (max === 1 && hbbWfbbProcess))}
						onTrue={() => (
							<PlanButton>
								<ConditionalRender
									show={!!plan.keepThisPlan}
									onTrue={() => (
										<ButtonWrapper>
											<SecondaryMediumButtonFixed
												uniqueId="plan-comparison.keep.this.plan"
												onClick={() => {
													onPlanSelection && onPlanSelection(index);
												}}
											>
												{translate(
													isProductCardRoute
														? 'plan-comparison.keep.current.plan'
														: 'plan-comparison.keep.this.plan'
												)}
											</SecondaryMediumButtonFixed>
										</ButtonWrapper>
									)}
								/>
								<ConditionalRender
									show={!!plan.selectPlan}
									onTrue={() => (
										<ButtonWrapper>
											<PrimaryMediumButtonFixed
												uniqueId="plan-comparison.select.plan"
												onClick={() => {
													onPlanSelection && onPlanSelection(index);
												}}
											>
												{translate(
													hbbWfbbProcess
														? 'plan-comparison.apply.plan'
														: isProductCardRoute
														? 'plan-comparison.select.upgrade.plan'
														: 'plan-comparison.select.plan'
												)}
											</PrimaryMediumButtonFixed>
										</ButtonWrapper>
									)}
								/>
							</PlanButton>
						)}
					/>
				)}
			/>
		);
	};

	const PlanTableRows = () => {
		return (
			<>
				<ConditionalRender
					show={!!hbbWfbbProcess}
					onTrue={() => (
						<PlansWrapper>
							<RowTitleXS title="plan-comparison.monthly.payment" />
							<PlanItem>
								<PlanDataItemHBB height={matchScreen('xs') ? 'unset' : '80px'}>
									<ConditionalRender
										show={!!plan.monthlyPayment}
										onTrue={() => (
											<>
												<PlanItemValue>
													<SmallCurrency>{translate('omr')}</SmallCurrency>
													<HeadlineBold>{plan.monthlyPayment}</HeadlineBold>
												</PlanItemValue>
												<HBBCaptionWrapper>
													<Caption color="black54">
														{translate(
															'plan-comparison.price.with.vat',
															plan.planVatPercent || 0,
															priceWithVat
														)}
													</Caption>
												</HBBCaptionWrapper>
											</>
										)}
										onFalse={() => (
											<Empty>
												<EmptyLine></EmptyLine>
											</Empty>
										)}
									/>
								</PlanDataItemHBB>
							</PlanItem>
							<RowTitleXS title="plan-comparison.data.included" />
							<PlanItem>
								<PlanDataItemHBB
									height={matchScreen('xs') ? (!!plan.dataIncluded ? 'unset' : '62px') : '44px'}
									className="PlanDataItemHBB"
								>
									<ConditionalRender
										show={!!plan.dataIncluded}
										onTrue={() =>
											plan?.dataIncluded?.toString().toLowerCase() !== 'unlimited' ? (
												<>
													<PlanItemValue>
														<HeadlineBold>{plan.dataIncluded}</HeadlineBold>
														<SmallCurrency>{translate('gb.simple')}</SmallCurrency>
													</PlanItemValue>
													<Divider withoutLine marginTop={8} />
													<Progress>
														<ProgressFill dataFill={100}></ProgressFill>
														<ProgressEmpty dataFill={0}></ProgressEmpty>
													</Progress>
												</>
											) : (
												<Unlimited>
													<Icon name="unlimited" fill="black54" height={32} width={32} />
												</Unlimited>
											)
										}
										onFalse={() => (
											<PlanDataItemHBB>
												<Divider withoutLine marginTop={8} />
												<Empty>
													<EmptyLine></EmptyLine>
												</Empty>
												<Divider withoutLine marginTop={4} />
											</PlanDataItemHBB>
										)}
									/>
								</PlanDataItemHBB>
							</PlanItem>

							<RowTitleXS title="plan-comparison.download.speed" />
							<PlanItem>
								<PlanDataItemHBB
									height={matchScreen('xs') ? (!!plan.downloadSpeed ? 'unset' : '62px') : '44px'}
								>
									<ConditionalRender
										show={!!plan.downloadSpeed}
										onTrue={() =>
											plan.downloadSpeed !== 'unlimited' ? (
												<>
													<PlanItemValue>
														<HeadlineBold>{plan.downloadSpeed}</HeadlineBold>
														<SmallCurrency>
															{translate(
																plan.downloadSpeedUnit === 'GB'
																	? 'gb.simple'
																	: 'mb.simple'
															)}
														</SmallCurrency>
													</PlanItemValue>
													<Divider withoutLine marginTop={8} />
													<Progress>
														<ProgressFill dataFill={100}></ProgressFill>
														<ProgressEmpty dataFill={0}></ProgressEmpty>
													</Progress>
												</>
											) : (
												<Unlimited>
													<Icon name="unlimited" fill="black54" height={32} width={32} />
												</Unlimited>
											)
										}
										onFalse={() => (
											<>
												<Divider withoutLine marginTop={8} />
												<Empty>
													<EmptyLine></EmptyLine>
												</Empty>
												<Divider withoutLine marginTop={4} />
											</>
										)}
									/>
								</PlanDataItemHBB>
							</PlanItem>
							<RowTitleXS title="plan-comparison.upload.speed" />
							<PlanItem>
								<PlanDataItemHBB
									height={matchScreen('xs') ? (!!plan.uploadSpeed ? 'unset' : '62px') : '44px'}
								>
									<ConditionalRender
										show={!!plan.uploadSpeed}
										onTrue={() =>
											plan.uploadSpeed !== 'unlimited' ? (
												<>
													<PlanItemValue>
														<HeadlineBold>{plan.uploadSpeed}</HeadlineBold>
														<SmallCurrency>{translate('mb.simple')}</SmallCurrency>
													</PlanItemValue>
													<Divider withoutLine marginTop={8} />
													<Progress>
														<ProgressFill dataFill={100}></ProgressFill>
														<ProgressEmpty dataFill={0}></ProgressEmpty>
													</Progress>
												</>
											) : (
												<Unlimited>
													<Icon name="unlimited" fill="black54" height={32} width={32} />
												</Unlimited>
											)
										}
										onFalse={() => (
											<>
												<Divider withoutLine marginTop={8} />
												<Empty>
													<EmptyLine></EmptyLine>
												</Empty>
												<Divider withoutLine marginTop={4} />
											</>
										)}
									/>
								</PlanDataItemHBB>
							</PlanItem>
							<RowTitleXS title="plan-comparison.free.minutes.to.fixed" />
							<PlanItem>
								<PlanDataItemHBB
									height={
										matchScreen('xs') ? (!!plan.freeMinutesToOmantel ? 'unset' : '62px') : 'unset'
									}
								>
									<ConditionalRender
										show={!!plan.freeMinutesToOmantel}
										onTrue={() =>
											plan.freeMinutesToOmantel !== 'unlimited' ? (
												<PlanDataItemHBB>
													<PlanItemValue>
														<HeadlineBold>{plan.freeMinutesToOmantel}</HeadlineBold>
														<SmallCurrency>{translate('min')}</SmallCurrency>
													</PlanItemValue>
												</PlanDataItemHBB>
											) : (
												<Unlimited>
													<Icon name="unlimited" fill="black54" height={32} width={32} />
												</Unlimited>
											)
										}
										onFalse={() => (
											<Empty>
												<EmptyLine></EmptyLine>
											</Empty>
										)}
									/>
								</PlanDataItemHBB>
							</PlanItem>

							<RowTitleXS title="plan-comparison.intl.calls.discount" />
							<PlanItem>
								<PlanDataItemHBB
									height={matchScreen('xs') ? (!!plan.intlCallsDiscount ? '76px' : '62px') : '44px'}
								>
									<ConditionalRender
										show={!!plan.intlCallsDiscount}
										onTrue={() => (
											<StoreCredit>
												<StoreCreditItem>
													<HeadlineBold color="primary">
														{plan.intlCallsDiscount}
													</HeadlineBold>
													<SmallCurrency color="primary">%</SmallCurrency>
												</StoreCreditItem>
											</StoreCredit>
										)}
										onFalse={() => (
											<Empty>
												<EmptyLine></EmptyLine>
											</Empty>
										)}
									/>
								</PlanDataItemHBB>
							</PlanItem>
						</PlansWrapper>
					)}
					onFalse={() => (
						<>
							<ConditionalRender
								show={!currentPlanIsHBB && !currentPlanIsPrepaid}
								onTrue={() => (
									<>
										<RowTitleXS title="plan-comparison.monthly.payment" />
										<PlanItem>
											<ConditionalRender
												show={!!plan.monthlyPayment}
												onTrue={() => (
													<PlanItemValue>
														<SmallCurrency>{translate('omr')}</SmallCurrency>
														<HeadlineBold>{plan.monthlyPayment}</HeadlineBold>
													</PlanItemValue>
												)}
												onFalse={() => (
													<Empty>
														<EmptyLine></EmptyLine>
													</Empty>
												)}
											/>
										</PlanItem>
										<RowTitleXS title="plan-comparison.local.gcc.data" />
										<ConditionalRender
											show={!!plan.localAndGccData}
											onTrue={() => (
												<PlanDataItem padding={22}>
													<PlanItemValue>
														<HeadlineBold>{plan.localAndGccData}</HeadlineBold>
														<SmallCurrency>{translate('gb.simple')}</SmallCurrency>
													</PlanItemValue>
													<LocalAndGcc>
														<Caption color="black54">
															{translate('plan-comparison.local.gcc')}
														</Caption>
													</LocalAndGcc>
													<Progress>
														<ProgressFill dataFill={dataFill}></ProgressFill>
														<ProgressEmpty dataFill={dataEmpty}></ProgressEmpty>
													</Progress>
												</PlanDataItem>
											)}
											onFalse={() => (
												<PlanItem padding={40}>
													<Empty>
														<EmptyLine></EmptyLine>
													</Empty>
												</PlanItem>
											)}
										/>
										<RowTitleXS title="plan-comparison.local.minutes" />
										<PlanItem>
											<ConditionalRender
												show={!!plan.localMinutes}
												onTrue={() =>
													plan.localMinutes !== 'unlimited' ? (
														<PlanItemValue>
															<HeadlineBold>{plan.localMinutes}</HeadlineBold>
															<SmallCurrency>{translate('min')}</SmallCurrency>
														</PlanItemValue>
													) : (
														<Icon name="unlimited" fill="black54" height={32} width={32} />
													)
												}
												onFalse={() => (
													<Empty>
														<EmptyLine></EmptyLine>
													</Empty>
												)}
											/>
										</PlanItem>
										<RowTitleXS title="plan-comparison.international.minutes" />
										<PlanItem>
											<ConditionalRender
												show={!!plan.internationalMinutes}
												onTrue={() =>
													plan.internationalMinutes !== 'unlimited' ? (
														<PlanItemValue>
															<HeadlineBold>{plan.internationalMinutes}</HeadlineBold>
															<SmallCurrency>{translate('min')}</SmallCurrency>
														</PlanItemValue>
													) : (
														<Icon name="unlimited" fill="black54" height={32} width={32} />
													)
												}
												onFalse={() => (
													<Empty>
														<EmptyLine></EmptyLine>
													</Empty>
												)}
											/>
										</PlanItem>
										<RowTitleXS title="plan-comparison.roaming.recieving.minutes" />
										<PlanItem>
											<ConditionalRender
												show={!!plan.roamingRecievingMinutes}
												onTrue={() =>
													plan.roamingRecievingMinutes !== 'unlimited' ? (
														<PlanItemValue>
															<HeadlineBold>{plan.roamingRecievingMinutes}</HeadlineBold>
															<SmallCurrency>{translate('min')}</SmallCurrency>
														</PlanItemValue>
													) : (
														<Icon name="unlimited" fill="black54" height={32} width={32} />
													)
												}
												onFalse={() => (
													<Empty>
														<EmptyLine></EmptyLine>
													</Empty>
												)}
											/>
										</PlanItem>
										<RowTitleXS title="plan-comparison.world.roaming.data" />
										<PlanItem>
											<ConditionalRender
												show={!!plan.worldRoamingData}
												onTrue={() =>
													plan.worldRoamingData !== 'unlimited' ? (
														<PlanItemValue>
															<HeadlineBold>{plan.worldRoamingData}</HeadlineBold>
															<SmallCurrency>{translate('gb.simple')}</SmallCurrency>
														</PlanItemValue>
													) : (
														<Icon name="unlimited" fill="black54" height={32} width={32} />
													)
												}
												onFalse={() => (
													<Empty>
														<EmptyLine></EmptyLine>
													</Empty>
												)}
											/>
										</PlanItem>
										<RowTitleXS title="plan-comparison.data.sharing" />
										<PlanItem>
											<ConditionalRender
												show={!!plan.dataSharing}
												onTrue={() =>
													plan.dataSharing !== 'unlimited' ? (
														<PlanItemValue>
															<HeadlineBold>{plan.dataSharing}</HeadlineBold>
															<SmallCurrency>{translate('gb.simple')}</SmallCurrency>
														</PlanItemValue>
													) : (
														<Icon name="unlimited" fill="black54" height={32} width={32} />
													)
												}
												onFalse={() => (
													<Empty>
														<EmptyLine></EmptyLine>
													</Empty>
												)}
											/>
										</PlanItem>
										<RowTitleXS title="plan-comparison.store.credit" />
										<ConditionalRender
											show={!!plan.storeCredit}
											onTrue={() => (
												<PlanItem padding={32}>
													<StoreCredit>
														<StoreCreditItem>
															<SmallCurrency color="primary">
																{translate('omr')}
															</SmallCurrency>
															<HeadlineBold color="primary">
																{plan.storeCredit}
															</HeadlineBold>
														</StoreCreditItem>
													</StoreCredit>
												</PlanItem>
											)}
											onFalse={() => (
												<PlanItem padding={36}>
													<Empty>
														<EmptyLine></EmptyLine>
													</Empty>
												</PlanItem>
											)}
										/>
									</>
								)}
							/>
							<ConditionalRender
								show={currentPlanIsHBB}
								onTrue={() => (
									<>
										<RowTitleXS title="plan-comparison.monthly.payment" />
										<PlanItem padding={20}>
											<ConditionalRender
												show={!!plan.monthlyPayment}
												onTrue={() => (
													<PlanDataItemHBB>
														<PlanItemValue>
															<SmallCurrency>{translate('omr')}</SmallCurrency>
															<HeadlineBold>{plan.monthlyPayment}</HeadlineBold>
														</PlanItemValue>
														<CaptionWrapper>
															<Caption color="black54">
																{translate(
																	'plan-comparison.price.with.vat',
																	plan.planVatPercent || 0,
																	priceWithVat
																)}
															</Caption>
														</CaptionWrapper>
													</PlanDataItemHBB>
												)}
												onFalse={() => (
													<>
														<Divider withoutLine marginTop={10} />
														<Empty>
															<EmptyLine></EmptyLine>
														</Empty>
														<Divider withoutLine marginTop={8} />
													</>
												)}
											/>
										</PlanItem>
										<RowTitleXS title="plan-comparison.data.included" />
										<PlanItem>
											<ConditionalRender
												show={!!plan.dataIncluded}
												onTrue={() => (
													<PlanDataItemHBB withCaption>
														<PlanItemValue>
															<HeadlineBold>{plan.dataIncluded}</HeadlineBold>
															<SmallCurrency>{translate('gb.simple')}</SmallCurrency>
														</PlanItemValue>
														<LocalAndGcc>
															<Caption color="black54">
																{translate('plan-comparison.data.included')}
															</Caption>
														</LocalAndGcc>
														<Progress>
															<ProgressFill dataFill={100}></ProgressFill>
															<ProgressEmpty dataFill={0}></ProgressEmpty>
														</Progress>
													</PlanDataItemHBB>
												)}
												onFalse={() => (
													<>
														<Divider withoutLine marginTop={30} />
														<Empty>
															<EmptyLine></EmptyLine>
														</Empty>
														<Divider withoutLine marginTop={20} />
													</>
												)}
											/>
										</PlanItem>

										<RowTitleXS title="plan-comparison.download.speed" />
										<PlanItem>
											<ConditionalRender
												show={!!plan.downloadSpeed}
												onTrue={() =>
													plan.downloadSpeed !== 'unlimited' ? (
														<PlanDataItemHBB>
															<PlanItemValue>
																<HeadlineBold>{plan.downloadSpeed}</HeadlineBold>
																<SmallCurrency>
																	{translate(
																		plan.downloadSpeedUnit === 'GB'
																			? 'gb.simple'
																			: 'mb.simple'
																	)}
																</SmallCurrency>
															</PlanItemValue>
															<Divider withoutLine marginTop={8} />
															<Progress>
																<ProgressFill dataFill={100}></ProgressFill>
																<ProgressEmpty dataFill={0}></ProgressEmpty>
															</Progress>
														</PlanDataItemHBB>
													) : (
														<Icon name="unlimited" fill="black54" height={32} width={32} />
													)
												}
												onFalse={() => (
													<>
														<Divider withoutLine marginTop={8} />
														<Empty>
															<EmptyLine></EmptyLine>
														</Empty>
														<Divider withoutLine marginTop={4} />
													</>
												)}
											/>
										</PlanItem>
										<RowTitleXS title="plan-comparison.upload.speed" />
										<PlanItem>
											<ConditionalRender
												show={!!plan.uploadSpeed}
												onTrue={() =>
													plan.uploadSpeed !== 'unlimited' ? (
														<PlanDataItemHBB>
															<PlanItemValue>
																<HeadlineBold>{plan.uploadSpeed}</HeadlineBold>
																<SmallCurrency>{translate('mb.simple')}</SmallCurrency>
															</PlanItemValue>
															<Divider withoutLine marginTop={8} />
															<Progress>
																<ProgressFill dataFill={100}></ProgressFill>
																<ProgressEmpty dataFill={0}></ProgressEmpty>
															</Progress>
														</PlanDataItemHBB>
													) : (
														<Icon name="unlimited" fill="black54" height={32} width={32} />
													)
												}
												onFalse={() => (
													<>
														<Divider withoutLine marginTop={8} />
														<Empty>
															<EmptyLine></EmptyLine>
														</Empty>
														<Divider withoutLine marginTop={4} />
													</>
												)}
											/>
										</PlanItem>
										<RowTitleXS title="plan-comparison.free.minutes.to.fixed" />
										<PlanItem>
											<ConditionalRender
												show={!!plan.freeMinutesToOmantel}
												onTrue={() =>
													plan.freeMinutesToOmantel !== 'unlimited' ? (
														<PlanDataItem>
															<PlanItemValue>
																<HeadlineBold>{plan.freeMinutesToOmantel}</HeadlineBold>
															</PlanItemValue>
														</PlanDataItem>
													) : (
														<Icon name="unlimited" fill="black54" height={32} width={32} />
													)
												}
												onFalse={() => (
													<Empty>
														<EmptyLine></EmptyLine>
													</Empty>
												)}
											/>
										</PlanItem>

										<RowTitleXS title="plan-comparison.intl.calls.discount" />
										<PlanItem padding={48}>
											<ConditionalRender
												show={!!plan.intlCallsDiscount}
												onTrue={() => (
													<StoreCredit>
														<StoreCreditItem>
															<HeadlineBold color="primary">
																{plan.intlCallsDiscount}
															</HeadlineBold>
															<SmallCurrency color="primary">%</SmallCurrency>
														</StoreCreditItem>
													</StoreCredit>
												)}
												onFalse={() => (
													<Empty>
														<EmptyLine></EmptyLine>
													</Empty>
												)}
											/>
										</PlanItem>
									</>
								)}
							/>
							<ConditionalRender
								show={currentPlanIsPrepaid}
								onTrue={() => (
									<>
										<RowTitleXS title="plan-comparison.data.allowance" />
										<ConditionalRender
											show={!!plan.localAndGccData}
											onTrue={() => (
												<PlanDataItem padding={9}>
													<PlanItemValue
														padding={10}
														currentPlanIsPrepaid={currentPlanIsPrepaid}
													>
														<TitleBold>{plan.localAndGccData}</TitleBold>
														<ComparisonCurrency>
															{' '}
															{translate('gb.simple')}
														</ComparisonCurrency>
													</PlanItemValue>
													<Progress>
														<ProgressFill dataFill={dataFill}></ProgressFill>
														<ProgressEmpty dataFill={dataEmpty}></ProgressEmpty>
													</Progress>
												</PlanDataItem>
											)}
											onFalse={() => (
												<PlanItem padding={40}>
													<Empty>
														<EmptyLine></EmptyLine>
													</Empty>
												</PlanItem>
											)}
										/>
										<RowTitleXS title="plan-comparison.social.media.data" />
										<ConditionalRender
											show={!!plan.socialMediaDataValue}
											onTrue={() => (
												<PlanDataItem padding={10}>
													<PlanItemValue
														padding={10}
														currentPlanIsPrepaid={currentPlanIsPrepaid}
													>
														<TitleBold>{plan.socialMediaDataValue}</TitleBold>
														<ComparisonCurrency>
															{translate('gb.simple')}
														</ComparisonCurrency>
													</PlanItemValue>
													<Progress>
														<ProgressFill dataFill={dataFill}></ProgressFill>
														<ProgressEmpty dataFill={dataEmpty}></ProgressEmpty>
													</Progress>
													<IconsWrapper>
														<Icon name="instagramSquare" height={24} width={24} />
														<Icon name="whatsappSquare" height={24} width={24} />
														<Icon name="snapchatSquare" height={24} width={24} />
														<Icon name="twitterSquare" height={24} width={24} />
														<Icon name="facebookSquare" height={24} width={24} />
													</IconsWrapper>
												</PlanDataItem>
											)}
											onFalse={() => (
												<PlanItem padding={40}>
													<Empty>
														<EmptyLine></EmptyLine>
													</Empty>
												</PlanItem>
											)}
										/>
										<RowTitleXS title="plan-comparison.flexi.minutes" />
										<PlanItem>
											<ConditionalRender
												show={!!plan.localMinutes}
												onTrue={() =>
													plan.localMinutes !== 'unlimited' ? (
														<PlanItemValue currentPlanIsPrepaid={currentPlanIsPrepaid}>
															<TitleBold>{plan.localMinutes}</TitleBold>
															<ComparisonCurrency>{translate('min')}</ComparisonCurrency>
														</PlanItemValue>
													) : (
														<Icon name="unlimited" fill="black54" height={32} width={32} />
													)
												}
												onFalse={() => (
													<PlanItem padding={40}>
														<Empty>
															<EmptyLine></EmptyLine>
														</Empty>
													</PlanItem>
												)}
											/>
										</PlanItem>
										<RowTitleXS title="plan-comparison.cashback.promotion" />
										<PlanItem padding={24}>
											<CaptionWrapper>
												<Caption color="black87">
													{translate('plan-comparison.cashback.description')}
												</Caption>
											</CaptionWrapper>
										</PlanItem>
										<RowTitleXS title="plan-comparison.plan.duration.time" />
										<PlanItem>
											<ConditionalRender
												show={currentPlanIsPrepaid && !!plan.durationLabel}
												onTrue={() => (
													<PlanItemValue currentPlanIsPrepaid={currentPlanIsPrepaid}>
														<TitleBold>{plan.durationLabel?.split(' ')[0]}</TitleBold>
														<DurationWrapper>
															<ComparisonCurrency>
																{plan.durationLabel?.split(' ')[1]}
															</ComparisonCurrency>
														</DurationWrapper>
													</PlanItemValue>
												)}
											/>
											<ConditionalRender
												show={!!plan.planContractDuration}
												onTrue={() => (
													<PlanItemValue>
														<HeadlineBold>{plan.planContractDuration}</HeadlineBold>
													</PlanItemValue>
												)}
											/>
											<ConditionalRender
												show={!currentPlanIsPrepaid && !plan.planContractDuration}
												onTrue={() => (
													<PlanItem padding={40}>
														<Empty>
															<EmptyLine></EmptyLine>
														</Empty>
													</PlanItem>
												)}
											/>
										</PlanItem>
									</>
								)}
							/>
						</>
					)}
				/>
			</>
		);
	};

	return (
		<PlanWrapper hbbWfbbProcess={hbbWfbbProcess} index={index} max={max} className="PlanWrapper">
			<PlanHeader
				planType={plan.type}
				currentPlanIsPrepaid={currentPlanIsPrepaid}
				hbbWfbbProcess={hbbWfbbProcess}
			>
				<PlanHeaderTitle hbbWfbbProcess={hbbWfbbProcess}>
					{currentPlanIsPrepaid && !hbbWfbbProcess && `${translate('omr')} `} {plan.name}
				</PlanHeaderTitle>
				<ConditionalRender
					show={!currentPlanIsPrepaid}
					onTrue={() => <PlanHeaderCommitment>{plan.contractCommitment}</PlanHeaderCommitment>}
				/>
				<ConditionalRender
					show={!!plan.isCurrentPlan || !!plan.isRecommendedPlan}
					onTrue={() => (
						<PlanHeaderType>
							{!!plan.isCurrentPlan
								? translate('plan-comparison.your.current.plan')
								: translate('plan-comparison.recommended.plan')}
						</PlanHeaderType>
					)}
				/>
			</PlanHeader>
			<PlanItems>
				<PlanTableRows />
				<ConditionalRender
					show={screenClass === 'xs'}
					onTrue={() => <Divider withoutLine marginBottom={154} />}
				/>
			</PlanItems>
			{!planDetailsMode && buttons()}
		</PlanWrapper>
	);
};

export default Plan;
