import { ButtonM, DisplayMBold } from '@Components/Typography/Typography';
import styled from 'styled-components';
import { isRTL, matchScreen } from '@Utils/layout/layoutSupport';
import { Col } from 'react-grid-system';

export const Wrapper = styled.div`
	${() => ({
		padding: matchScreen('xs') ? 0 : '8px 16px',
	})}
`;
export const ChevronWrapper = styled(Col)`
	padding-top: 8px;
`;
export const Title = styled(DisplayMBold)`
	${({ theme }) => ({ color: theme.colors.black87 })}
	margin-bottom: 16px;
	margin-top: 8px;
`;
export const HeaderWrapper = styled.div`
	display: flex;
`;
export const SubHeaderWrapper = styled.div`
	display: flex;
	justify-content: flex-start;
	align-items: center;
`;
export const CardWrapper = styled.div`
	padding-top 24px;
`;

export const TextButton = styled(ButtonM)`
	display: inline-block;
	cursor: pointer;
	text-decoration: underline;
	${() => isRTL() && 'padding-left: 8px'};
	${({ theme }) => ({
		textDecorationColor: theme.colors.primary,
		color: theme.colors.primary,
	})}
`;

export const BGContainer = styled.div`
	overflow: hidden;
	transform: translateX(100px);
	position: absolute;
	top: 100px;
	right: 60px;
	opacity: 0.3;
`;
