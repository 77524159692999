import PriceTag from '@Components/PriceTag/PriceTag';
import { IProduct } from '@Views/Comparison/Comparison';
import React, { FunctionComponent } from 'react';
import {
	ProductContainer,
	BrandWrapper,
	TitleWrapper,
	ProductNameWrapper,
	PriceWrapper,
	InfoContainer,
	TextWrapper,
} from './Product.styled';

interface IProductProps {
	product: IProduct;
}

const Product: FunctionComponent<IProductProps> = ({
	product: {
		pricesWithVat: { listPrice },
		product,
	},
}) => {
	return (
		<ProductContainer>
			<img src={product.thumbnail} height={64} />
			<InfoContainer>
				<TitleWrapper>
					<BrandWrapper color="black87">{product.manufacturer ?? ''}</BrandWrapper>
					<ProductNameWrapper color="black87">{product.name}</ProductNameWrapper>
				</TitleWrapper>
				<PriceWrapper>
					<TextWrapper>From</TextWrapper>
					<PriceTag price={listPrice} size="mini" duration="+VAT" />
				</PriceWrapper>
			</InfoContainer>
		</ProductContainer>
	);
};

export default Product;
