import immerHandleActions from '@Redux/utils/immerHandleActions';
import {
	clearPersistentCheckoutDetails,
	clearTelesalesInformation,
	IPersistentCheckoutData,
	setPersistentCheckoutDetails,
} from './actions';

const init = {
	hbbWfbbProcessCheckout: undefined,
	prepaidProcessCheckout: undefined,
	telesalesInformation: undefined,
};

type draft = IPersistentCheckoutData['payload'];

const reducers = {
	[setPersistentCheckoutDetails.toString()](draft: draft, action: IPersistentCheckoutData) {
		const payload = {
			hbbWfbbProcessCheckout: { ...draft.hbbWfbbProcessCheckout, ...action.payload.hbbWfbbProcessCheckout },
			prepaidProcessCheckout: { ...draft.prepaidProcessCheckout, ...action.payload.prepaidProcessCheckout },
			telesalesInformation: { ...draft.telesalesInformation, ...action.payload.telesalesInformation },
		};
		return payload;
	},
	[clearPersistentCheckoutDetails.toString()](draft: draft) {
		return {
			hbbWfbbProcessCheckout: undefined,
			prepaidProcessCheckout: undefined,
			telesalesInformation: { ...draft.telesalesInformation },
		};
	},
	[clearTelesalesInformation.toString()](draft: draft) {
		return {
			hbbWfbbProcessCheckout: { ...draft.hbbWfbbProcessCheckout },
			prepaidProcessCheckout: { ...draft.prepaidProcessCheckout },
			telesalesInformation: undefined,
		};
	},
};

export default immerHandleActions(reducers, init);
