import Icon from '@Components/Icon/Icon';
import useTranslate from '@Utils/hooks/useTranslate';
import React, { FunctionComponent } from 'react';
import { Col, Hidden, Row, Visible } from 'react-grid-system';
import {
	EmailContainer,
	EmailValue,
	DescriptionWrapper,
	ShippingMethodCardContainer,
	TitleWrapper,
} from './DigitalProductShippingMethod.styled';
import { HeaderWrapper, IconWrapper } from '@Components/Card/cards/TermsAndConditions/TermsAndConditions.styled';
import useCommerceProfile from '@Utils/hooks/useCommerceProfile';

const ICON_SIZE = 40;
const ADDRESS_ICON_SIZE = 32;

const DigitalProductShippingMethod: FunctionComponent<types.cards.IDigitalProductShippingMethodCardProps> = () => {
	const { translate } = useTranslate();
	const { contactDetails } = useCommerceProfile();

	return (
		<ShippingMethodCardContainer>
			<Row>
				<Hidden xs>
					<Col lg={2} md={2} sm={2}>
						<Row align="center" justify="center">
							<Icon name="messages" fill="black38" width={ICON_SIZE} height={ICON_SIZE} />
						</Row>
					</Col>
				</Hidden>
				<Col lg={10} md={10} sm={10}>
					<Row>
						<HeaderWrapper>
							<Visible xs>
								<IconWrapper>
									<Icon name="messages" fill="black38" width={ICON_SIZE} height={ICON_SIZE} />
								</IconWrapper>
							</Visible>
							<div>
								<TitleWrapper>{translate('digital-product-shipping-method.title')}</TitleWrapper>
								<DescriptionWrapper>
									{translate('digital-product-shipping-method.description')}
								</DescriptionWrapper>
							</div>
						</HeaderWrapper>
					</Row>
					<Row>
						<EmailContainer>
							<Icon name="messages" width={ADDRESS_ICON_SIZE} height={ADDRESS_ICON_SIZE} fill="black38" />
							<EmailValue>{contactDetails?.email}</EmailValue>
						</EmailContainer>
					</Row>
				</Col>
			</Row>
		</ShippingMethodCardContainer>
	);
};

export default DigitalProductShippingMethod;
