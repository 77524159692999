import React, { FunctionComponent, useEffect, useState } from 'react';
import { setModal } from '@Redux/modules/modal/actions';
import { useDispatch } from 'react-redux';
import { Row, Col } from 'react-grid-system';
import {
	ActionWrapper,
	CardContainer,
	Header,
	LogoWrapper,
	ColorPickerWrapper,
	CarouselWrapper,
	BigBackgroundTitle,
	Background,
	ContentWrapper,
} from './ImagePreview.styled';
import Icon from '@Components/Icon/Icon';
import Carousel from '@Components/Carousel/Carousel';
import ColorPicker from '@Components/controls/ColorPicker/ColorPicker';
import { ColorPickerPosition } from '@Components/controls/ColorPicker/ColorPicker.styles';
import ConditionalRender from '@Components/ConditionalRender/ConditionalRender';

const enableBackgroundTypo = false;

const ImagePreviewCard: FunctionComponent<types.cards.IImagePreviewCardProps> = ({
	params: { images, selectedImageIndex, availableColors, pickedColor, backgroundTitle = '' },
}) => {
	const [selectedColor, setSelectedColor] = useState<string | null>(pickedColor ? pickedColor : null);
	const [selectedImage, setSelectedImage] = useState<string | null>('');
	const backgroundContent = `${backgroundTitle} ${backgroundTitle} ${backgroundTitle}`;
	const dispatch = useDispatch();

	useEffect(() => {
		if (availableColors.length) {
			const selectedColorProperty = availableColors.find((color) => color.color === selectedColor);
			setSelectedImage(selectedColorProperty?.fullImage ?? '');
		} else if (images.length) {
			setSelectedImage(images[0]);
		}
	}, [selectedColor, availableColors, pickedColor]);

	const handleClose = () => {
		dispatch(
			setModal({
				type: null,
				data: null,
			})
		);
	};

	return (
		<CardContainer>
			<Header>
				<Row justify="between">
					<Col>
						<LogoWrapper>
							<Icon name="omantelLogo" width={96} height={48} />
						</LogoWrapper>
					</Col>
					{availableColors && (
						<Col>
							<ColorPickerWrapper>
								<ColorPicker
									colors={availableColors}
									setSelectedColor={setSelectedColor}
									selectedColor={selectedColor || pickedColor}
									position={ColorPickerPosition.HORIZONTAL}
								/>
							</ColorPickerWrapper>
						</Col>
					)}

					<Col>
						<Row justify="end">
							<ActionWrapper onClick={handleClose}>
								<Icon width={40} height={40} name="close" fill="black" />
							</ActionWrapper>
						</Row>
					</Col>
				</Row>
			</Header>
			<ConditionalRender
				show={enableBackgroundTypo}
				onTrue={() => (
					<ContentWrapper>
						<BigBackgroundTitle color="black2">{backgroundContent}</BigBackgroundTitle>
						<BigBackgroundTitle padding="0 0 0 4vw" color="black6">
							{backgroundContent}
						</BigBackgroundTitle>
						<BigBackgroundTitle color="black2">{backgroundContent}</BigBackgroundTitle>
					</ContentWrapper>
				)}
			/>
			<CarouselWrapper marginTop={enableBackgroundTypo ? '-420px' : '0'}>
				{selectedImage && (
					<Carousel
						selectedPhotoIndex={selectedImageIndex}
						enableModalPreview={false}
						// images={images} // TODO: after images will be configured propery
						images={[selectedImage]}
						previewMode={true}
					/>
				)}
			</CarouselWrapper>
			<Background />
		</CardContainer>
	);
};
export default ImagePreviewCard;
