import * as Yup from 'yup';
import useTranslate from '@Utils/hooks/useTranslate';
import moment from 'moment';
import compareDates from '@Utils/date/compareDates';
const nameRegex = /^^[A-Za-zżźćńółęąśŻŹĆĄŚĘŁÓŃء-ي\-\s]+$/;

export default (isWfbbProcess = false): Yup.AnyObjectSchema => {
	const { translate } = useTranslate();
	let isHbbWfbbProcessShape = {};

	const contactDataShape = {
		firstName: Yup.string()
			.matches(nameRegex, translate('validate.personal.data.first.name.must.be.string'))
			.required(translate('validate.personal.data.first.name.required')),
		middleName: Yup.string().matches(nameRegex, translate('validate.personal.data.middle.name.must.be.string')),
		lastName: Yup.string()
			.matches(nameRegex, translate('validate.personal.data.last.name.must.be.string'))
			.required(translate('validate.personal.data.last.name.required')),
		email: Yup.string()
			.email(translate('validate.personal.data.email.must.be.email'))
			.required(translate('validate.personal.data.email.required')),
		phoneNumber: Yup.string()
			.required(translate('validate.personal.data.phone.number.required'))
			.length(8, translate('validate.personal.data.oman.format.phone.number'))
			.matches(
				/(90[1-9][0-9]{5})|((9[1-9]|(7[1289]))[0-9]{6})$|(2)[2-6][0-9]{6}$|77[0-9]{6}$/,
				translate('validate.personal.data.oman.format.phone.number')
			),
	};

	if (isWfbbProcess) {
		isHbbWfbbProcessShape = {
			birthDate: Yup.string()
				.required(translate('validate.personal.data.birth-date.required'))
				.max(10, translate('document-details.fill-date-properly'))
				.min(10, translate('document-details.fill-date-properly'))
				.matches(/[0-9]/, translate('document-details.name.only.number'))
				.test('invalid-birth-date', translate('document-details.invalid-date'), (birthDate) => {
					const dateFormat = new RegExp('[0-9]{2}/[0-9]{2}/[0-9]{4}');
					if (!dateFormat.test(birthDate || '')) return false;
					if (!moment(birthDate, 'DD/MM/YYYY').isValid()) return false;

					const first = moment(birthDate, 'DD/MM/YYYY').format('YYYY-MM-DD');
					const second = moment(moment().format('DD/MM/YYYY'), 'DD/MM/YYYY').format('YYYY-MM-DD');

					return !compareDates(first, second).first.isGreater;
				}),
			fatherName: Yup.string()
				.matches(nameRegex, translate('validate.personal.data.father-name.must.be.string'))
				.required(translate('validate.personal.data.father-name.required')),
			grandfatherName: Yup.string()
				.matches(nameRegex, translate('validate.personal.data.grandfather-name.must.be.string'))
				.required(translate('validate.personal.data.grandfather-name.required')),
		};
	}

	return Yup.object().shape({ ...contactDataShape, ...isHbbWfbbProcessShape });
};
