import styled from 'styled-components';
import { ParagraphMBold, ParagraphS, BodyM } from '@Components/Typography/Typography';
import { marginRight } from '@Utils/layout/layoutSupport';

export const IconWrapper = styled.div`
	${() => marginRight('16px')}
`;

export const InfoWrapper = styled.div`
	display: flex;
	padding: 8px 16px;
	border-radius: 4px;
	align-items: center;
	${({ theme }) => {
		return {
			backgroundColor: theme.colors.black6,
		};
	}}
`;

export const ParagraphMBoldWrapper = styled(ParagraphMBold)`
	${({ theme }) => {
		return {
			color: theme.colors.black87,
		};
	}}
	padding-bottom: 8px;
`;

export const ParagraphSWrapper = styled(ParagraphS)`
	${({ theme }) => {
		return {
			color: theme.colors.black54,
		};
	}}
	padding-bottom: 8px;
`;

export const BodyMWrapper = styled(BodyM)`
	${({ theme }) => {
		return {
			color: theme.colors.black54,
		};
	}}
`;

export const VehicleDetailsWrapper = styled.div`
	display: flex;
	flex-direction: column;
`;

export const VehicleDetailsCardWrapper = styled.div`
	display: flex;
`;
