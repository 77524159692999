import { ActionFunction1, createAction } from 'redux-actions';

export interface ISetToastAction {
	payload: {
		active: boolean;
		error: boolean;
		label: string;
		toastAction: {
			label: string;
			onPress: () => void;
		};
	};
}

export const setToast: ActionFunction1<any, any> = createAction('SET_TOAST', (action: ISetToastAction) => action);
