import React, { FunctionComponent, useEffect, useState } from 'react';
import {
	LeftContainer,
	Wrapper,
	TopSectionWrapper,
	UserNameLabelWrapper,
	UserIconWrapper,
} from '@Components/MegaMenu/subcomponents/MyOmantel/MegaMenuMyOmantel.styled';
import useTranslate from '@Utils/hooks/useTranslate';
import ConditionalRender from '@Components/ConditionalRender/ConditionalRender';
import { useSelector } from 'react-redux';
import NonLoggedContent from './NonLoggedContent/NonLoggedContent';
import LoggedContent from './LoggedContent/LoggedContent';
import useScreen from '@Utils/hooks/useScreen';
import Icon from '@Components/Icon/Icon';
import { BodySHigher, BodySBoldHigher, ParagraphMBold } from '@Components/Typography/Typography.styled';
import { ProfileService } from '@Services/profile/profileService';
import { Hidden } from 'react-grid-system';

interface IMegaMenuMyOmantelProps {
	forceClose: () => void;
	mobileMenu: boolean;
	isMd: boolean;
}

const MegaMenuMyOmantel: FunctionComponent<IMegaMenuMyOmantelProps> = ({ mobileMenu, isMd, forceClose }) => {
	const { translate } = useTranslate();
	const isLogged = !!useSelector((state: types.redux.IState) => state.api.authentication.signedIn);
	const { screen } = useScreen();
	const isXsSm = screen(['xs', 'sm']);
	const isXs = screen(['xs']);
	const [userName, setUserName] = useState<string>('');

	useEffect(() => {
		if (isLogged) {
			ProfileService.getUserProfile().subscribe(
				(response) => {
					setUserName(response.firstName);
				},
				() => {
					setUserName('');
				}
			);
		}
	}, [isLogged]);

	return (
		<Wrapper mobileMenu={mobileMenu} isMd={isMd} isXs={isXsSm} isSm={screen(['sm'])}>
			<LeftContainer mobileMenu={mobileMenu} isMd={isMd}>
				<TopSectionWrapper isXS={screen(['xs'])}>
					<Hidden xs={isXs && isLogged}>
						<ParagraphMBold>{translate('megamenu.my-omantel.title')}</ParagraphMBold>
					</Hidden>
					<ConditionalRender
						show={isLogged}
						onTrue={() => (
							<UserNameLabelWrapper>
								<BodySHigher>
									{translate('megamenu.my-omantel.good-morning')}
									{'\xa0'}
								</BodySHigher>
								<BodySBoldHigher>{userName}</BodySBoldHigher>
								<UserIconWrapper>
									<Icon name="phantomUser" width={36} height={36} />
								</UserIconWrapper>
							</UserNameLabelWrapper>
						)}
					/>
				</TopSectionWrapper>
				<ConditionalRender
					show={isLogged}
					onTrue={() => <LoggedContent forceClose={forceClose} />}
					onFalse={() => <NonLoggedContent />}
				/>
			</LeftContainer>
		</Wrapper>
	);
};

export default MegaMenuMyOmantel;
