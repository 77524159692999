import { updateCart, IUpdateCartAction } from './actions';
import immerHandleActions from '@Redux/utils/immerHandleActions';

const init = {
	cartUpdated: false,
};

type draft = typeof init;

export interface ICartDraft extends draft {}

const reducers = {
	[updateCart.toString()](draft: draft, action: IUpdateCartAction) {
		return { ...draft, cartUpdated: action.payload };
	},
};

export default immerHandleActions(reducers, init);
