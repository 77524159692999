export interface IValidateOffer {
	valid: boolean;
}
interface IValidateOfferEntry extends IValidateOffer {
	_entry: string;
}

const model: types.models.IInputModel = {
	_entry: 'response.response.validate',
	valid: (model: IValidateOfferEntry) => model._entry === 'true',
};

export default model;
