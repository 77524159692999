import styled from 'styled-components';
import { Col } from 'react-grid-system';

export const AddCardFormWrapper = styled(Col)`
	margin-top: 16px;
	margin-bottom: 16px;
`;

export const ImageWrapper = styled.div`
	margin: 0 24px;
	border-radius: 8px;
	box-shadow: 0 7px 8px 0 rgba(0, 0, 0, 0.05), 0 5px 22px 4px rgba(0, 0, 0, 0.04), 0 12px 17px 2px rgba(0, 0, 0, 0.04);
`;

export const SmallScreenImageWrapper = styled.div`
	margin: 0;
	border-radius: 8px;
	box-shadow: 0 7px 8px 0 rgba(0, 0, 0, 0.05), 0 5px 22px 4px rgba(0, 0, 0, 0.04), 0 12px 17px 2px rgba(0, 0, 0, 0.04);
`;

export const CheckboxWrapper = styled.div`
	display: flex;
	flex: 1;
	width: 100%;
	padding: 24px 0 0;
	align-items: center;
`;

export const LogoWrapper = styled.div`
	padding-top: 16px;
	display: flex;
	justify-content: center;
	align-items: center;
`;
