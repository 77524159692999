import store from '@Redux/store';

export function getLanguage(): types.redux.settings.language {
	return store.getState().settings.language;
}

export function getLang(): string {
	return store.getState().settings.language.toLowerCase() ?? 'en';
}

export function getTypographyLang(): string {
	return location.pathname.toLowerCase().startsWith('/ar') ? 'ar' : 'en';
}

export function getBrowserLanguage(): types.redux.settings.language {
	const FALLBACK_LANGUAGE = 'EN';

	let browserLanguage = navigator.language ?? FALLBACK_LANGUAGE;

	if (browserLanguage.includes('ar')) {
		browserLanguage = 'AR';
	} else if (browserLanguage.includes('en')) {
		browserLanguage = 'EN';
	} else {
		browserLanguage = FALLBACK_LANGUAGE;
	}

	return browserLanguage as types.redux.settings.language;
}

export const getLanguageCode = (): string => {
	return getLanguage() === 'EN' ? '-1' : '-23';
};
