import hasObjectPath from '@Utils/objectsValidation/hasObjectPath';
interface IInputObj {
	[key: string]: any;
}

export default function (obj: IInputObj, path: string, defaultValue?: unknown): any {
	const paths = path.split('.');
	let lastVal = defaultValue;
	for (const path of paths) {
		if (hasObjectPath(obj, path)) {
			lastVal = obj[path];
			obj = obj[path];
		} else {
			return defaultValue;
		}
	}

	return lastVal;
}
