import { IWhatsInTheBoxItem } from '@ApiModels/productById';
import { BodyMBold } from '@Components/Typography/Typography';
import React, { FunctionComponent } from 'react';
import { BoxContentContainer, IconWrapper } from './BoxContent.styled';

const BoxContent: FunctionComponent<IWhatsInTheBoxItem> = ({ name, img }) => {
	return (
		<BoxContentContainer>
			<IconWrapper>
				<img src={img} />
			</IconWrapper>
			<BodyMBold>{name}</BodyMBold>
		</BoxContentContainer>
	);
};

export default BoxContent;
