import styled from 'styled-components';
import { ValueBold, ParagraphM, BodyS } from '@Components/Typography/Typography.styled';
import { matchScreen, paddingLeft, paddingRight, marginLeft } from '@Utils/layout/layoutSupport';

export const CardContainer = styled.div`
	display: flex;
	flex-direction: column;
`;
export const HeaderWrapper = styled.div`
	display: flex;
	align-items: center;
	padding: 12px 16px;
`;

export const HeaderDescriptionWrapper = styled.div`
	flex: 1;
	padding: 0 32px;
`;

export const HeaderTitle = styled(ValueBold)`
	${({ theme }) => ({
		color: theme.colors.black87,
	})}
`;

export const ContentWrapper = styled.div`
	flex: 1;
	padding-top: 16px;
	padding-bottom: 8px;
	${() => paddingLeft(matchScreen('xs') ? 16 : 72)}
	${() => paddingRight(16)}
`;

export const PaymentInfoWrapper = styled.div`
	${({ theme }) => ({
		backgroundColor: theme.colors.grey50,
	})}
	display: flex;
	flex-direction: row;
	${() => paddingLeft(16)}
	padding-top: 16px;
`;

export const PaymentLabelColumn = styled.div`
	padding-top: 4px;
	display: flex;
	flex-direction: column;
	${() => marginLeft(-16)}
	gap: 8px;
	${() => paddingRight(16)}
`;

export const MakasibInfoWrapper = styled.div`
	${({ theme }) => ({
		backgroundColor: theme.colors.grey50,
	})}
	display: flex;
	flex-direction: row;
	align-items: center;
	${() => paddingLeft(16)}
	padding-top: 16px;
	position: relative;
`;

export const DividerWrapper = styled.div<{ isSmall?: boolean }>`
	${() => marginLeft(-16)}
	${({ isSmall }) => ({
		width: isSmall ? '90%' : '95%',
	})}
position: absolute;
	bottom: 0;
`;

export const TitleLabelWrapper = styled(ParagraphM)<{ isCardPayment?: boolean }>`
	${() => marginLeft(32)}
	z-index: 999;
	${({ isCardPayment }) => ({
		marginBottom: isCardPayment ? '-16px' : '0',
	})}
`;

export const PaymentTokenWrapper = styled.div`
	width: 78%;
`;

export const PaymentIconWrapper = styled.div<{ isMakasib?: boolean }>`
	z-index: 999;
	${({ isMakasib }) => ({
		marginTop: isMakasib ? '-24px' : '0',
	})}
`;

export const LabelWrapper = styled(BodyS)<{ isCardPayment?: boolean }>`
	${() => marginLeft(32)}
	z-index: 999;
	margin-bottom: 16px;
	${({ isCardPayment, theme }) => ({
		color: theme.colors.black54,
		marginTop: isCardPayment ? '-16px' : '0',
	})}
`;
