import { IEbuUserProfile } from '@Utils/api/models/ebuUserProfile';
import api from '@Utils/api';
import { Observable } from 'rxjs';
import { IUserProfile } from '@ApiModels/userProfile';
import { dispatch } from '@Redux/store';
import { signIn } from '@Redux/modules/api/authentication/actions';
import { map, tap } from 'rxjs/operators';
import { IGuestLogIn } from '@ApiModels/guestLogIn';
import { IUserGiftCards, IUserGiftCardsByOrderId } from '@ApiModels/userGiftCards';
import { ISimDetails } from '@ApiModels/simDetails';
import { getLoginType } from '@Redux/modules/api/authentication/selectors';
import { IDetail } from '@ApiModels/contractDetails';
import { IGenerateOtp } from '@ApiModels/generateOtp';
import { IGenerateOtpPayload } from '@Utils/api/queries/omantelApi';
import { IWindow } from '../../App';
import { setB2bUser } from '@Redux/modules/api/authentication/actions';
import { getB2bUser } from '@Utils/ebu/b2b2UserCheckings';
import { DISABLE_EBU_SINGLE_PURCHASE, DISABLE_EBU_SINGLE_PURCHASE_B2B_BAQATI } from '@Config/features.config';
import { setSelectedItems } from '@Redux/modules/selectedItems/actions';
import { ICorpAccounts } from '@ApiModels/corpAccounts';
export class ProfileService {
	public static getUserProfile(force?: boolean, telesales?: boolean): Observable<IUserProfile> {
		const updateCleverTap = (userId: string) => {
			if (((window as unknown) as IWindow).clevertap !== undefined) {
				if (!!userId) {
					((window as unknown) as IWindow).clevertap.onUserLogin.push({
						Site: {
							Identity: userId,
						},
					});
				}
			}
		};
		if (force) {
			return api.omantelApi
				.getFullUserProfileForCurrentUser()
				.forceCall()
				.pipe(
					tap({
						next: (r) => {
							updateCleverTap(r.userId);
							dispatch(signIn({ signedIn: true, loginType: 'user', telesales }));
							const isB2bUser = getB2bUser(r.documentType);
							dispatch(
								setB2bUser(
									isB2bUser && !DISABLE_EBU_SINGLE_PURCHASE && !DISABLE_EBU_SINGLE_PURCHASE_B2B_BAQATI
								)
							);
							dispatch(
								setSelectedItems({
									isEbuDocumentType: isB2bUser,
									isMasterUser: r?.isMaster,
								})
							);
						},
						error: () => {
							updateCleverTap('');
							dispatch(signIn({ signedIn: false, loginType: getLoginType(), telesales }));
							dispatch(setB2bUser(false));
							dispatch(
								setSelectedItems({
									isEbuDocumentType: false,
									isMasterUser: false,
								})
							);
						},
					})
				);
		}

		return api.omantelApi
			.getFullUserProfileForCurrentUser()
			.call()
			.pipe(
				tap({
					next: (r) => {
						updateCleverTap(r.userId);
						dispatch(signIn({ signedIn: true, loginType: 'user', telesales }));
						const isB2bUser = getB2bUser(r.documentType);
						dispatch(
							setB2bUser(
								isB2bUser && !DISABLE_EBU_SINGLE_PURCHASE && !DISABLE_EBU_SINGLE_PURCHASE_B2B_BAQATI
							)
						);
						dispatch(
							setSelectedItems({
								isEbuDocumentType: isB2bUser,
								isMasterUser: r?.isMaster,
							})
						);
					},
					error: () => {
						updateCleverTap('');
						dispatch(signIn({ signedIn: false, loginType: getLoginType(), telesales }));
						dispatch(setB2bUser(false));
						dispatch(
							setSelectedItems({
								isEbuDocumentType: false,
								isMasterUser: false,
							})
						);
					},
				})
			);
	}

	public static getOmantelIdentity(): Observable<IGuestLogIn> {
		return api.omantelShop.getOmantelIdentity().call();
	}
	public static getUserGiftCards(): Observable<IUserGiftCards> {
		return api.omantelApi.getUserGiftCards().disableCache().call();
	}
	public static getEbuUserProfile(): Observable<IEbuUserProfile> {
		return api.omantelApi.getEbuUserProfileForCurrentUser().disableCache().call();
	}
	public static getUserGiftCardsByOrderId(orderId: string): Observable<IUserGiftCardsByOrderId> {
		return api.omantelApi.getUserGiftCardsByOrderId(orderId).call();
	}
	public static getSimDetails(phoneNumber: string): Observable<ISimDetails> {
		return api.omantelApi
			.getSimDetails(phoneNumber)
			.call()
			.pipe(map(({ accounts }) => accounts[0]));
	}
	public static getContractDetails(subscriberNo: string): Observable<IDetail> {
		return api.omantelApi.getContractDetails(subscriberNo).call();
	}
	public static generateOTP(payload: IGenerateOtpPayload): Observable<IGenerateOtp> {
		return api.omantelApi.generateOTP(payload).call();
	}
	public static getCorpAccountsForCurrentUser(): Observable<ICorpAccounts> {
		return api.omantelApi.getCorpAccountsForCurrentUser().disableCache().call();
	}
}
