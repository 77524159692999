import React, { FunctionComponent, useRef } from 'react';
import { Col, Row } from 'react-grid-system';
import { ActionWrapper, CardWrapper } from './MapCard.styled';
import { GoogleMap } from 'react-google-maps';
import Map from '@Components/Map/Map';
import { MapContainerElement, MapElement } from '../DeliveryAddress/DeliveryAddress.styled';
import Icon from '@Components/Icon/Icon';
import { setModal } from '@Redux/modules/modal/actions';
import { dispatch } from '@Redux/store';
import { BodyMBold } from '@Components/Typography/Typography.styled';
import useTranslate from '@Utils/hooks/useTranslate';
import Divider from '@Components/Divider/Divider';

const MapCard: FunctionComponent<types.cards.IMapCardProps> = ({ params: { coordinates } }): JSX.Element => {
	const mapsRef = useRef<GoogleMap>();
	const { translate } = useTranslate();

	const handleClose = () => {
		dispatch(
			setModal({
				type: null,
				data: null,
			})
		);
	};

	return (
		<CardWrapper>
			<Row>
				<Col xs={11}>
					<BodyMBold color="black87">{translate('account.management.delivery.address')}</BodyMBold>
				</Col>
				<Col>
					<ActionWrapper onClick={handleClose}>
						<Icon name="close" fill="black" />
					</ActionWrapper>
				</Col>
			</Row>
			<Divider color="transparent" marginTop={8} marginBottom={0} />
			<Row>
				<Col>
					<Map
						mapElement={<MapElement fullMap={true} />}
						zoom={16}
						position={coordinates}
						mapsRef={mapsRef}
						markerPosition={coordinates}
						onClick={() => {}}
						containerElement={<MapContainerElement height={488} />}
						locked
					/>
				</Col>
			</Row>
		</CardWrapper>
	);
};

export default MapCard;
