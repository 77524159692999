import { IGiftCardByOrderId } from '@ApiModels/userGiftCards';
import { PrimaryCircularLoader } from '@Components/CircularLoader/CircularLoader';
import ConditionalRender from '@Components/ConditionalRender/ConditionalRender';
import Divider from '@Components/Divider/Divider';
import GiftCardCodeCopyToClipboard from '@Components/GiftCardCode/GiftCardCode';
import Icon from '@Components/Icon/Icon';
import { BodyM, HeadlineBold } from '@Components/Typography/Typography.styled';
import { setModal } from '@Redux/modules/modal/actions';
import { dispatch } from '@Redux/store';
import { ProfileService } from '@Services/profile/profileService';
import useTranslate from '@Utils/hooks/useTranslate';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { Col, Row } from 'react-grid-system';
import {
	ActionWrapper,
	CardContainer,
	CartTitle,
	ContentWrapper,
	EmailCodes,
	PriceCountry,
} from './GiftCardCodes.styled';

const GiftCardCodesCard: FunctionComponent<types.cards.IGiftCardCodesCardProps> = ({
	params: { name, price, country, orderId, partNumber },
}) => {
	const { translate } = useTranslate();
	const [giftCardCodes, setGiftCardCodes] = useState<IGiftCardByOrderId[]>([]);
	const [loading, setLoading] = useState<boolean>(false);

	const handleClose = () => {
		dispatch(
			setModal({
				type: null,
				data: null,
			})
		);
	};

	useEffect(() => {
		setLoading(true);
		ProfileService.getUserGiftCardsByOrderId(orderId).subscribe(
			(gcards) => {
				setGiftCardCodes(gcards?.userGiftCardsByOrderId?.filter((it) => it.productId === partNumber));
			},
			() => {
				setGiftCardCodes([]);
			},
			() => {
				setLoading(false);
			}
		);
	}, []);

	return (
		<CardContainer>
			<CartTitle>
				<Row>
					<Col xs={11}>{translate('gift.card.modal.details')}</Col>
					<Col>
						<ActionWrapper onClick={handleClose}>
							<Icon name="close" fill="black" />
						</ActionWrapper>
					</Col>
				</Row>
			</CartTitle>
			<Divider color="black12" />
			<ContentWrapper>
				<HeadlineBold color="black87">{name}</HeadlineBold>
				<PriceCountry>
					<BodyM color="black54">{translate('giftcard-price', price)}</BodyM>
					<BodyM color="black54">|</BodyM>
					<BodyM color="black54">{translate('giftcard-country', country)}</BodyM>
				</PriceCountry>
				<Divider marginBottom={24} withoutLine />
				<ConditionalRender
					show={loading}
					onTrue={() => (
						<>
							<Divider marginTop={40} withoutLine />
							<PrimaryCircularLoader />
						</>
					)}
					onFalse={() => (
						<>
							{giftCardCodes?.map((giftCard, idx) => (
								<>
									<GiftCardCodeCopyToClipboard giftCard={giftCard} key={idx} />
									<Divider marginBottom={24} withoutLine />
								</>
							))}
							<EmailCodes>
								<BodyM color="black87">{translate('gift.card.code.mail')}</BodyM>
							</EmailCodes>
						</>
					)}
				/>
			</ContentWrapper>
		</CardContainer>
	);
};
export default GiftCardCodesCard;
