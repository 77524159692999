import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { modalTypes, setModal } from '@Redux/modules/modal/actions';
import useTranslate from '@Utils/hooks/useTranslate';
import { ICartItem, IInsuranceCartItem } from '@ApiModels/viewCart';
import { IProductById, IProductByIdItem } from '@ApiModels/productById';
import { useCart } from '@Utils/context/CartContext';
import {
	ADD_INSURANCE_BI,
	EXCLUDE_CART_ITEMS,
	GET_PRICE_BY_CAT_ENTRY_INSURANCE_BI,
	INSURANCE_PROMOTION_CART_ITEMS,
	REMOVE_INSURANCE_BI,
} from '@Components/GetInsurance/GetInsurance';
import { dispatch } from '@Redux/store';
import { addBusyIndicator, removeBusyIndicator } from '@Redux/modules/busyIndicator/actions';
import { OrderFlowService } from '@Services/orderFlow/orderFlow';
import { ProductsService } from '@Services/productsService/productsService';
import { errors } from '@Utils/api/errors/errors';
import { InventoryService } from '@Services/inventory/inventoryService';
import { IPriceByCatEntry } from '@ApiModels/priceByCatEntry';
import { isUserSignedIn } from '@Redux/modules/api/authentication/selectors';
import TagManager from 'react-gtm-module';
import { useSelector } from 'react-redux';
import { getUserId } from '@Redux/modules/api/authentication/selectors';
import { PaymentMethod } from '@Components/Modal/modals/AdditionalProduct/AdditionalProduct';

export type DeviceInsuranceDuration = '12' | '24';

interface IDeviceInsurance {
	addInsurance: (newInsuranceDuration: '12' | '24') => Promise<void>;
	activeInsuranceInCart: ICartItem | IInsuranceCartItem | undefined;
	activeInsuranceDetails: IProductByIdItem | undefined;
	handleChangePeriod: (newInsuranceDuration: DeviceInsuranceDuration) => Promise<void>;
	onRemove: () => Promise<void>;
	openModal: () => void;
	validateDeviceInsurance: (additionalService: string, insurancePromotion: boolean) => boolean;
	getInsurance: (partNumber: string, insurancePromotion: boolean | undefined) => Promise<IProductById>;
	additionalProduct: IProductById | undefined;
	priceFromCatEntry: IPriceByCatEntry['price'] | undefined;
	phoneNumber: string | undefined;
	planId: string | undefined;
	paymentMethod: PaymentMethod;
	setPaymentMethod: (method: PaymentMethod) => void;
}

export const DeviceInsuranceContext = createContext<IDeviceInsurance>({
	addInsurance: (newInsuranceDuration: DeviceInsuranceDuration) => {
		return new Promise<void>((resolve) => resolve());
	},
	handleChangePeriod: (newInsuranceDuration: DeviceInsuranceDuration) => {
		return new Promise<void>((resolve) => resolve());
	},
	onRemove: () => new Promise<void>((resolve) => {}),
	getInsurance: (partNumber: string, insurancePromotion: boolean | undefined) => {
		return new Promise<IProductById>((resolve) => resolve({} as IProductById));
	},
	openModal: () => {},
	validateDeviceInsurance: () => false,
	activeInsuranceInCart: {} as ICartItem | IInsuranceCartItem | undefined,
	activeInsuranceDetails: {} as IProductByIdItem | undefined,
	additionalProduct: {} as IProductById | undefined,
	priceFromCatEntry: {} as IPriceByCatEntry['price'] | undefined,
	phoneNumber: '',
	planId: '',
	setPaymentMethod: () => {},
	paymentMethod: PaymentMethod.UPFRONT_PAYMENT,
});

export const useDeviceInsurance = (): IDeviceInsurance => useContext(DeviceInsuranceContext);

const DeviceInsuranceContextProvider = ({ children }: { children: any }): JSX.Element => {
	const { cart, cartItemsLength } = useCart();
	const [paymentMethod, setPaymentMethod] = useState<PaymentMethod>(PaymentMethod.UPFRONT_PAYMENT);
	const [additionalProduct, setAdditionalProduct] = useState<IProductById>();
	const [priceFromCatEntry, setPriceFromCatEntry] = useState<IPriceByCatEntry['price']>();
	const { translate } = useTranslate();
	const isSignedIn = useSelector((state: types.redux.IState) => state.api.authentication.signedIn);

	const activeInsuranceInCart = useMemo(() => {
		return cart.items.find((item) => EXCLUDE_CART_ITEMS.includes(item.partNumber));
	}, [cart.items, additionalProduct]);

	const activeInsuranceDetails = useMemo(() => {
		return additionalProduct?.items?.find((item) => activeInsuranceInCart?.partNumber === item.partNumber);
	}, [activeInsuranceInCart, additionalProduct]);

	const phoneNumber = useMemo(() => {
		return cart.items.find((item) => !!item.plan?.mobilePhone)?.plan?.mobilePhone;
	}, [cart.items]);

	const planId = useMemo(() => {
		return cart.items.find((item) => !!item.plan?.planDetails?.planId)?.plan?.planDetails?.planId;
	}, [cart.items]);

	useEffect(() => {
		if (activeInsuranceDetails?.id) {
			dispatch(addBusyIndicator(GET_PRICE_BY_CAT_ENTRY_INSURANCE_BI));
			InventoryService.getCatEntry({ catEntryId: activeInsuranceDetails.id }).subscribe(
				(response) => {
					setPriceFromCatEntry(response.price);
					dispatch(removeBusyIndicator(GET_PRICE_BY_CAT_ENTRY_INSURANCE_BI));
				},
				() => {
					dispatch(removeBusyIndicator(GET_PRICE_BY_CAT_ENTRY_INSURANCE_BI));
				}
			);
		}
	}, [activeInsuranceDetails]);

	const removeProtection = ({
		updateCart,
		newInsuranceDuration,
	}: {
		updateCart: boolean;
		newInsuranceDuration?: '12' | '24';
	}): Promise<boolean> => {
		dispatch(addBusyIndicator(REMOVE_INSURANCE_BI));
		return new Promise((resolve) => {
			OrderFlowService.removeSingleCartItem(activeInsuranceInCart?.orderItemId || '', updateCart).subscribe(
				() => {
					TagManager.dataLayer({
						dataLayer: {
							Product_Name: activeInsuranceInCart?.name,
							event: 'Remove item from cart',
							Logged_in: isSignedIn ? 'Yes' : 'No',
							userId: getUserId(),
						},
					});
					dispatch(removeBusyIndicator(REMOVE_INSURANCE_BI));
					if (newInsuranceDuration) addInsurance(newInsuranceDuration).catch(() => {});
					resolve(true);
				},
				() => {
					dispatch(removeBusyIndicator(REMOVE_INSURANCE_BI));
					resolve(false);
				}
			);
		});
	};

	const getInsurance = async (partNumber: string, insurancePromotion: boolean | undefined): Promise<IProductById> => {
		return new Promise((resolve) => {
			ProductsService.getProductById({ productId: partNumber }).subscribe(
				(product) => {
					if (insurancePromotion) {
						product.items = product?.items?.filter((it) => {
							return INSURANCE_PROMOTION_CART_ITEMS.includes(it?.partNumber);
						});
					} else {
						product.items = product?.items?.filter((it) => {
							return !INSURANCE_PROMOTION_CART_ITEMS.includes(it?.partNumber);
						});
					}
					setAdditionalProduct(product);
					resolve(product);
				},
				() => {
					resolve({} as IProductById);
				}
			);
		});
	};

	const addInsurance = async (newInsuranceDuration: '12' | '24'): Promise<void> => {
		const insuranceId = additionalProduct?.items?.find((item) => item.duration === newInsuranceDuration)?.id;
		const payload = {
			quantity: '1',
			productId: insuranceId || '',
		};
		dispatch(addBusyIndicator(ADD_INSURANCE_BI));
		return new Promise((resolve) => {
			resolve();
			OrderFlowService.addItemsToCart([payload]).subscribe(
				() => {
					dispatch(removeBusyIndicator(ADD_INSURANCE_BI));
				},
				(e) => {
					if (e.response?.errors?.length > 0) {
						if (e.response?.errors[0]?.errorKey === errors.ITEM_OUT_OF_STOCK) {
							dispatch(
								setModal({
									type: modalTypes.GENERIC_ERROR,
									data: {
										icon: 'warning',
										iconFill: 'primary',
										title: translate('product.cart-card.modal.out-of-stock.title'),
										description: translate('product.cart-card.modal.out-of-stock.description'),
									},
								})
							);
						} else if (e.response?.errors[0]?.errorKey === errors.SESSION_GENERIC_USER) {
							dispatch(
								setModal({
									type: modalTypes.LOGIN_SESSION_EXPIRED,
									data: {},
								})
							);
						}
					}
					dispatch(removeBusyIndicator(ADD_INSURANCE_BI));
				},
				() => {
					resolve();
				}
			);
		});
	};

	const openModal = () => {
		if (additionalProduct?.items && additionalProduct.items.length > 1) {
			dispatch(
				setModal({
					withoutContainer: true,
					type: modalTypes.ADDITIONAL_PRODUCT,
					data: { additionalProduct, phoneNumber, planId, paymentMethod },
				})
			);
		}
	};

	const handleChangePeriod = async (newInsuranceDuration: '12' | '24') => {
		await removeProtection({ updateCart: false, newInsuranceDuration });
	};

	const onRemove = async () => {
		await removeProtection({ updateCart: true });
	};

	const validateDeviceInsurance = (additionalService: string, insurancePromotion: boolean) => {
		return (
			isUserSignedIn() &&
			cartItemsLength === 0 &&
			!activeInsuranceInCart &&
			!!additionalService &&
			!!insurancePromotion
		);
	};

	return (
		<DeviceInsuranceContext.Provider
			value={{
				addInsurance,
				activeInsuranceInCart,
				activeInsuranceDetails,
				handleChangePeriod,
				onRemove,
				openModal,
				getInsurance,
				additionalProduct,
				priceFromCatEntry,
				validateDeviceInsurance,
				phoneNumber,
				planId,
				paymentMethod,
				setPaymentMethod,
			}}
		>
			{children}
		</DeviceInsuranceContext.Provider>
	);
};

export default DeviceInsuranceContextProvider;
