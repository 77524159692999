import { BodyMBold } from '@Components/Typography/Typography';
import { marginLeft } from '@Utils/layout/layoutSupport';
import styled from 'styled-components';

export const MessageBarContainer = styled.div<{ backgroundColor: types.theme.themeColors; width: string }>`
	display: flex;
	padding: 8px 16px;
	border-radius: 4px;
	${({ theme, backgroundColor, width = 'unset' }) => ({ backgroundColor: theme.colors[backgroundColor], width })};
	flex-direction: row;
	align-items: center;
`;

export const MessageWrapper = styled.p<{ color: types.theme.themeColors; font: keyof types.ITypography }>`
	${({ theme, color }) => ({ color: theme.colors[color] })}
	${({ theme, font }) => ({ ...theme.typography[font] })}
	margin:4px;
	${() => marginLeft(16)}
`;

export const MessageWrapperBold = styled(BodyMBold)<{
	color: types.theme.themeColors;
}>`
	${({ theme, color }) => ({ color: theme.colors[color] })}
	${() => marginLeft(16)}
`;

export const DoubleText = styled.div<{ textAlign: 'left' | 'center' | 'right' }>`
	width: 100%;
	display: flex;
	${({ textAlign }) => ({ alignItems: textAlign })}
	justiy-content: center;
	flex-direction: column;
`;
