import React, { FunctionComponent, useEffect } from 'react';
import { ContentWrapper, MenuBackground, MobileMenuHeader, HeaderContent, ChildrenWrapper } from './SidePanel.styled';
import useScreen from '@Utils/hooks/useScreen';
import ConditionalRender from '@Components/ConditionalRender/ConditionalRender';
import Icon from '@Components/Icon/Icon';

interface IMobileMenu {
	open: boolean;
	setPanelExpanded: (expanded: boolean) => void;
	children: React.ReactNode;
}

const SidePanel: FunctionComponent<IMobileMenu> = ({ open, setPanelExpanded, children }) => {
	const { screen, screenClass } = useScreen();

	useEffect(() => {
		if (open) {
			document.body.style.overflow = 'hidden';
		} else {
			document.body.style.overflow = 'unset';
		}
	}, [open]);

	const handleCloseMobileMenu = () => {
		setPanelExpanded(!open);
	};

	return (
		<aside>
			<ConditionalRender
				show={screen(['xs', 'sm', 'md'])}
				onTrue={() => (
					<>
						<ContentWrapper open={open}>
							<MobileMenuHeader>
								<HeaderContent>
									<Icon
										name="close"
										width={24}
										height={24}
										fill="black87"
										onClick={handleCloseMobileMenu}
									/>
								</HeaderContent>
							</MobileMenuHeader>
							<ChildrenWrapper>{children}</ChildrenWrapper>
						</ContentWrapper>
						<MenuBackground open={open} />
					</>
				)}
			/>
		</aside>
	);
};

export default SidePanel;
