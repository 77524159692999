import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { ParagraphS } from '@Components/Typography/Typography.styled';
import { marginLeft, marginRight } from '@Utils/layout/layoutSupport';

export const Item = styled(Link)`
	display: inline-block;
	text-decoration: none;
	transition: all 0.1s ease-in-out;
	${({ theme }) => ({ color: theme.colors.dark57, ...theme.typography.paragraphS })}
	&:hover {
		color: ${({ theme }) => theme.colors.black87};
		opacity: 1;
	}
`;

export const FirstItem = styled(ParagraphS)`
	display: inline-block;
	text-decoration: none;
	transition: all 0.1s ease-in-out;
	cursor: pointer;
	${({ theme }) => ({ color: theme.colors.dark57 })}
	&:hover {
		color: ${({ theme }) => theme.colors.black87};
		opacity: 1;
	}
`;

export const FinalItem = styled(ParagraphS)`
	display: inline-block;
	text-decoration: none;
	transition: all 0.1s ease-in-out;
	${({ theme }) => ({ color: theme.colors.dark })}
	&:hover {
		color: ${({ theme }) => theme.colors.black87};
		opacity: 1;
	}
`;

export const FirstWrapper = styled.div`
	${() => marginRight(12)}
`;

export const IconWrapper = styled.div`
	${() => marginLeft(9)}
	${() => marginRight(6)}
`;

export const Container = styled.div`
	display: flex;
	margin: 24px 0;
	justify-content: flex-start;
	align-items: center;
	flex-wrap: wrap;
	& > span {
		display: flex;
		&:first-of-type a {
			${({ theme }) => ({ color: theme.colors.primary })}
			&:hover {
				color: ${({ theme }) => {
					return theme.colors.primary;
				}};
				opacity: 0.75;
			}
		}
	}
`;
