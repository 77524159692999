import React, { Fragment, FunctionComponent, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { RedirectItem } from '@Components/NavigationBar/NavigationBar.styled';
import {
	ActiveItemTextWrapper,
	ItemTextWrapper,
	HeaderTextWrapper,
	ItemWrapper,
	Label,
	LabelWrapper,
	LinkItemTextLabelWrapper,
	SubItemTextWrapper,
	SearchHeaderTextWrapper,
	ActiveItemSearchWrapper,
	SubItemTextSearchWrapper,
} from '@Components/MegaMenuMultilevelItem/MegaMenuMultilevelItem.styled';
import ConditionalRender from '@Components/ConditionalRender/ConditionalRender';
import useTranslate from '@Utils/hooks/useTranslate';
import { AdditionalTitleContainer, AdditionalTitleTextWrapper } from '@Components/MegaMenu/MegaMenu.styled';
import { IconWrapper } from '@Components/MegaMenuItem/MegaMenuItem.styled';
import Icon from '@Components/Icon/Icon';
import { useSelector } from 'react-redux';
import { MenusToRemoveForCBU } from '@Config/app.config';

export enum MegaMenuMultilevelItemType {
	HEADER = 'header',
	LINK = 'link',
	ITEM = 'item',
	SUB_ITEM = 'subItem',
}

interface IMegaMenuItem {
	name: string;
	onClick?: (e?: React.MouseEvent<HTMLAnchorElement>) => void;
	active?: boolean;
	iconLeft?: types.iconNames;
	iconRight?: types.iconNames;
	type: MegaMenuMultilevelItemType;
	paddingTop?: number;
	paddingLeft?: number;
	to?: string;
	subCategory?: boolean;
	additionalLabel?: string;
	isSearch?: boolean;
	id?: string;
}

const ICON_SIZE = 16;

const MegaMenuMultilevelItem: FunctionComponent<IMegaMenuItem> = ({
	name,
	onClick,
	iconLeft,
	iconRight,
	active,
	isSearch,
	type,
	to = '',
	subCategory,
	additionalLabel,
	id = '',
}) => {
	const isSignedIn = useSelector((state: types.redux.IState) => state.api.authentication.signedIn);
	const isB2b = useSelector((state: types.redux.IState) => state.api.authentication.isB2b);
	const isB2b2c = useSelector((state: types.redux.IState) => state.api.authentication.isB2b2c);
	const isCBU = !isB2b && !isB2b2c;

	const { translate } = useTranslate();
	const TextWrapper = useMemo(() => {
		if (type === MegaMenuMultilevelItemType.HEADER && !!isSearch) {
			return SearchHeaderTextWrapper;
		} else if (type === MegaMenuMultilevelItemType.ITEM && !!isSearch) {
			return ActiveItemSearchWrapper;
		} else if (type === MegaMenuMultilevelItemType.HEADER) {
			return HeaderTextWrapper;
		} else if (type === MegaMenuMultilevelItemType.ITEM && active) {
			return ActiveItemTextWrapper;
		} else if (type === MegaMenuMultilevelItemType.SUB_ITEM && !!additionalLabel) {
			return LinkItemTextLabelWrapper;
		} else if (type === MegaMenuMultilevelItemType.SUB_ITEM && !!isSearch) {
			return SubItemTextSearchWrapper;
		} else if (type === MegaMenuMultilevelItemType.SUB_ITEM) {
			return SubItemTextWrapper;
		} else {
			return ItemTextWrapper;
		}
	}, [active, type]);

	const className = useMemo(() => {
		if (type === MegaMenuMultilevelItemType.HEADER) {
			return 'menuCategory';
		} else if (subCategory) {
			return 'menuSubCategory';
		} else if (type === MegaMenuMultilevelItemType.SUB_ITEM) {
			return 'menuSubCategory';
		} else if (type === MegaMenuMultilevelItemType.ITEM) {
			return 'menuCategory';
		} else if (!subCategory) {
			return 'menuCategory';
		} else {
			return 'menuSubCategory';
		}
	}, [active, type]);

	const LinkComponent = ({ children }: { children: JSX.Element }) => {
		if (type !== MegaMenuMultilevelItemType.HEADER) {
			return to ? (
				<RedirectItem
					href={to}
					onClick={(e) => {
						if (type === MegaMenuMultilevelItemType.ITEM) e.preventDefault();
					}}
				>
					{children}
				</RedirectItem>
			) : (
				<Link
					to={to}
					replace={to.includes('http')}
					onClick={(e: React.MouseEvent<HTMLAnchorElement>) => {
						if (type === MegaMenuMultilevelItemType.ITEM) e.preventDefault();
					}}
				>
					{children}
				</Link>
			);
		}
		return <Fragment>{children}</Fragment>;
	};

	if (isCBU && isSignedIn && MenusToRemoveForCBU?.includes(id)) {
		return <></>;
	}

	return (
		<LinkComponent>
			<ItemWrapper
				onClick={() => {
					onClick?.();
				}}
				active={active}
				isHeader={type === MegaMenuMultilevelItemType.HEADER}
				isItem={type === MegaMenuMultilevelItemType.ITEM}
				isSubItem={type === MegaMenuMultilevelItemType.SUB_ITEM}
				additionalLabel={additionalLabel}
			>
				<ConditionalRender
					show={type === MegaMenuMultilevelItemType.HEADER}
					onTrue={() => (
						<>
							<IconWrapper
								rotate={
									iconLeft === 'chevronRight' ||
									iconLeft === 'chevronLeft' ||
									iconLeft === 'arrowRightBox'
								}
							>
								<Icon name={iconLeft} fill="primary" width={ICON_SIZE} height={ICON_SIZE} />
							</IconWrapper>
							<TextWrapper className={className}>{name}</TextWrapper>
							<ConditionalRender
								show={!!to}
								onTrue={() => (
									<Link to={to} replace={to.includes('http')}>
										<AdditionalTitleContainer>
											<AdditionalTitleTextWrapper>
												{translate('menu.item.header.see-all')}
											</AdditionalTitleTextWrapper>
											<IconWrapper
												rotate={
													iconRight === 'chevronRight' ||
													iconRight === 'chevronLeft' ||
													iconRight === 'arrowRightBox'
												}
											>
												<Icon
													name={iconRight}
													fill="primary"
													width={ICON_SIZE}
													height={ICON_SIZE}
												/>
											</IconWrapper>
										</AdditionalTitleContainer>
									</Link>
								)}
							/>
						</>
					)}
					onFalse={() => (
						<>
							<IconWrapper
								rotate={
									iconLeft === 'chevronRight' ||
									iconLeft === 'chevronLeft' ||
									iconLeft === 'arrowRightBox'
								}
							>
								<Icon name={iconLeft} fill="primary" width={ICON_SIZE} height={ICON_SIZE} />
							</IconWrapper>
							<TextWrapper className={className}>{name}</TextWrapper>
							<ConditionalRender
								show={!!additionalLabel && type === MegaMenuMultilevelItemType.SUB_ITEM}
								onTrue={() => (
									<LabelWrapper>
										<Label>{additionalLabel}</Label>
									</LabelWrapper>
								)}
							/>
							<IconWrapper
								rotate={
									iconRight === 'chevronRight' ||
									iconRight === 'chevronLeft' ||
									iconRight === 'arrowRightBox'
								}
							>
								<Icon name={iconRight} fill="primary" width={ICON_SIZE} height={ICON_SIZE} />
							</IconWrapper>
						</>
					)}
				/>
			</ItemWrapper>
		</LinkComponent>
	);
};

export default MegaMenuMultilevelItem;
