import { IAddress } from '@ApiModels/commerceProfile';
import ConditionalRender from '@Components/ConditionalRender/ConditionalRender';
import { SecondaryMediumButtonFixed, TertiaryMediumButtonAuto } from '@Components/controls/Button/Button';
import TextArea from '@Components/controls/TextArea/TextArea';
import Divider from '@Components/Divider/Divider';
import Map from '@Components/Map/Map';
import { CaptionBold } from '@Components/Typography/Typography.styled';
import useScreen from '@Utils/hooks/useScreen';
import useTranslate from '@Utils/hooks/useTranslate';
import React, { FunctionComponent } from 'react';
import { Col, Row } from 'react-grid-system';
import { AddressValue, MapElement, MapWrapper } from './AddressBook.styled';

export interface IAddressBookDetails {
	address: IAddress;
	deleteBtn?: boolean;
	editBtn?: boolean;
	editLandmark?: (value: string) => void;
	removeAddress?: (value: string) => void;
	editAddress?: (address: IAddress) => void;
	onEditButtonClick?: () => void;
	isHbbWfbbProcess?: boolean;
	divider?: number;
}

const AddressBookDetails: FunctionComponent<IAddressBookDetails> = ({
	address,
	deleteBtn = false,
	editBtn = false,
	removeAddress,
	editAddress,
	isHbbWfbbProcess,
	editLandmark,
	onEditButtonClick,
	divider = 40,
}): JSX.Element => {
	const { translate } = useTranslate();
	const { screen } = useScreen();
	const isXs = screen('xs');

	const addressSimpleElement = (title: string, value: string) => {
		if (!value || value?.length === 0) {
			value = '-';
		}
		return (
			<>
				<CaptionBold color="black54">{translate(`deliver-address.text-field.${title}`)}</CaptionBold>
				<AddressValue color="black87">{value}</AddressValue>
				<Divider withoutLine marginTop={8} />
			</>
		);
	};

	const getHouse = () => {
		const house = [];
		house.push(address.house);
		house.push(address?.apartment ? '/' + address.apartment : '');
		return house.join('');
	};

	return (
		<>
			<Row>
				<Col xs={12} sm={12} md={6} lg={6}>
					{addressSimpleElement('governorate', address.governorate)}
					{addressSimpleElement('wilayat', address.wilayat)}
					{address.area
						? addressSimpleElement('city/area', `${address.city} / ${address.area}`)
						: addressSimpleElement('city', address.city)}
					{!!address.way && address.way !== 'null' && addressSimpleElement('way', address.way)}
					{!!address.house && address.house !== 'null' && addressSimpleElement('house-info', getHouse())}
				</Col>
				<Col>
					<MapWrapper>
						<Map
							mapElement={<MapElement />}
							zoom={17}
							position={address?.markerPosition}
							markerPosition={address?.markerPosition}
							containerElement={<MapElement />}
							locked
						/>
					</MapWrapper>
				</Col>
			</Row>
			<Divider withoutLine marginTop={20} />

			{onEditButtonClick && (
				<TertiaryMediumButtonAuto
					uniqueId="edit.internet.installation.address"
					onClick={() => {
						onEditButtonClick?.();
					}}
				>
					{translate('shipping-method.edit-address')}
				</TertiaryMediumButtonAuto>
			)}
			<Divider withoutLine marginTop={isXs ? 60 : 20} />

			<ConditionalRender
				show={!!isHbbWfbbProcess}
				onTrue={() => (
					<TextArea
						onChange={(value) => editLandmark?.(String(value))}
						label={translate('shipping.method.additional.landmark')}
						id="additionalLandmark"
						value={address.landmark || ''}
						marginBottom={10}
						maxLength={64}
						width="100%"
					/>
				)}
				onFalse={() => (
					<>
						<CaptionBold color="black54">
							{translate('shipping-details.billing.contact-data.data.landmark')}
						</CaptionBold>
						<AddressValue>{address.landmark ?? '-'}</AddressValue>
					</>
				)}
			/>

			<Divider withoutLine marginTop={divider} />
			<ConditionalRender
				show={!!editBtn}
				onTrue={() => (
					<SecondaryMediumButtonFixed
						uniqueId="edit.shipping.address"
						onClick={() => {
							onEditButtonClick?.();
							!onEditButtonClick && editAddress?.(address);
						}}
					>
						{translate('shipping-method.edit-address')}
					</SecondaryMediumButtonFixed>
				)}
			/>
			<ConditionalRender
				show={!!deleteBtn && !!removeAddress}
				onTrue={() => (
					<TertiaryMediumButtonAuto
						uniqueId="payment-tokens.delete"
						onClick={() => removeAddress?.(address.nickName ?? '')}
					>
						{translate('secure-checkout.payment-tokens.delete')}
					</TertiaryMediumButtonAuto>
				)}
			/>
		</>
	);
};

export default AddressBookDetails;
