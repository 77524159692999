import { createAction } from 'redux-actions';

export interface IVoucherApplied {
	amountOMR: number;
	voucherCode: string;
}

export interface IMakasibPoints {
	isTouched?: boolean;
	vouchers?: IVoucherApplied[];
	makasibDiscount?: number;
}

export const setMakasibPoints = createAction('SET_MAKASIB_POINTS', (action: IMakasibPoints) => action);
