import { BodyS } from '@Components/Typography/Typography.styled';
import styled from 'styled-components';

interface IDragAndDropZone {
	mouseIn: boolean;
}

export const DragAndDropZone = styled.div<IDragAndDropZone>`
	height: 100%;
	min-height: 300px;
	width: 100%;
	border-radius: 4px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	${({ theme }) => ({ border: `2px dashed ${theme.colors.black54}`, backgroundColor: theme.colors.background })};
`;

export const SubTitleButton = styled(BodyS)`
	${({ theme }) => ({ color: theme.colors.black54 })};
	width: 120px;
	text-align: center;
`;

export const ErrorMessage = styled(BodyS)`
	${({ theme }) => ({ color: theme.colors.warning })};
	text-align: center;
	width: 80%;
`;

export const Loader = styled.div`
	${({ theme }) => ({ backgroundColor: theme.colors.primary })};
	width: 170px;
	height: 4px;
	border-radius: 2px;
	margin-top: 6px;
	margin-bottom: 16px;
`;
