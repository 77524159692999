import styled from 'styled-components';
import { BodyS, ParagraphM, TitleBold, Title } from '@Components/Typography/Typography.styled';
import { paddingLeft } from '@Utils/layout/layoutSupport';

export const FORM_WIDTH = 552;

export const TopBar = styled.div`
	width: 100%;
	padding: 16px 0px;
	${({ theme }) => ({
		backgroundColor: theme.colors.background,
	})}
`;

export const BarContent = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-start;
	flex-wrap: no-wrap;
	gap: 8px;
`;

export const Change = styled(BodyS)`
	display: inline;
	cursor: pointer;
	text-decoration: underline;
	${() => paddingLeft(8)}
	${({ theme }) => ({
		color: theme.colors.primary,
	})}
`;

export const BarTitle = styled(Title)`
	${({ theme }) => ({
		color: theme.colors.black87,
	})}
`;

export const TitleContent = styled(TitleBold)`
	${({ theme }) => ({
		color: theme.colors.black87,
	})}
`;

export const SubTitle = styled(ParagraphM)`
	${({ theme }) => ({
		color: theme.colors.black87,
	})}
`;

export const ContentWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	border-radius: 8px;
	margin: 40px 0px;
	padding: 16px 0px 116px;
	${({ theme }) => ({
		backgroundColor: theme.colors.white,
		border: `1px solid ${theme.colors.primary}`,
	})}
`;

export const FormContent = styled.div`
	width: 100%;
	max-width: ${FORM_WIDTH}px;
`;

export const Button = styled.div`
	width: 100%;
	> div {
		width: 100%;
	}
`;
