import ConditionalRender from '@Components/ConditionalRender/ConditionalRender';
import React, { FunctionComponent, useRef, useState } from 'react';
import {
	Label,
	TextAreaContainer,
	TextAreaContent,
	TextAreaCounter,
	TextAreaInput,
	TextAreaMessage,
	TextAreaWrapper,
} from './TextArea.styled';

export interface ITextAreaProps {
	label: string;
	placeholder?: string;
	id: string | number;
	disabled?: boolean;
	error?: boolean;
	onChange: (value: string | number) => void;
	value: string | number;
	typography?: keyof types.ITypography;
	marginBottom?: number;
	message?: string;
	rows?: number;
	maxLength?: number;
	width?: string;
}

const TextArea: FunctionComponent<ITextAreaProps> = ({
	disabled,
	label,
	onChange,
	typography = 'valueBold',
	value,
	placeholder,
	error,
	marginBottom,
	message,
	rows,
	maxLength,
	width,
}: ITextAreaProps) => {
	const [active, setActive] = useState(false);
	const textAreaRef = useRef<HTMLTextAreaElement>(null);

	const handleOnBlur = () => {
		if (!disabled) {
			setActive(false);
		}
	};

	const handleOnFocus = () => {
		if (!disabled) {
			setActive(true);
			if (textAreaRef.current) {
				textAreaRef.current.focus();
			}
		}
	};

	const handleOnChange = (e: { target: { value: React.ReactText } }) => {
		onChange(e.target.value);
	};

	return (
		<TextAreaContainer width={width}>
			<TextAreaWrapper
				width={width}
				active={active}
				disabled={!!disabled}
				onClick={handleOnFocus}
				error={!!error}
				marginBottom={marginBottom}
				message={!!message}
			>
				<TextAreaContent>
					<Label value={value} active={active} error={!!error} disabled={!!disabled}>
						{label}
					</Label>
					<TextAreaInput
						value={value}
						ref={textAreaRef}
						onFocus={handleOnFocus}
						onBlur={handleOnBlur}
						onChange={handleOnChange}
						typography={typography}
						placeholder={placeholder}
						active={active}
						disabled={disabled}
						rows={rows}
						maxLength={maxLength}
					/>
				</TextAreaContent>
			</TextAreaWrapper>
			<ConditionalRender
				show={!!message && !maxLength}
				onTrue={() => (
					<TextAreaMessage error={!!error} marginBottom={marginBottom}>
						{message}
					</TextAreaMessage>
				)}
			/>
			<ConditionalRender
				show={!!maxLength}
				onTrue={() => (
					<TextAreaCounter error={!!error} marginBottom={marginBottom}>
						{`${value?.toString().length} / ${maxLength}`}
					</TextAreaCounter>
				)}
			/>
		</TextAreaContainer>
	);
};

export default TextArea;
