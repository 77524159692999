import React, { ButtonHTMLAttributes, FunctionComponent, ReactElement, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { ButtonContainer, ButtonSize, ButtonVariant, ButtonWidth, ButtonWrapper } from './Button.styled';
import { ButtonL, ButtonM } from '@Components/Typography/Typography.styled';
import ConditionalRender from '@Components/ConditionalRender/ConditionalRender';
import {
	PrimaryCircularLoader,
	OrangeCircularLoader,
	CarInsuranceCircularLoader,
} from '@Components/CircularLoader/CircularLoader';
import { CSSProperties } from 'styled-components';

export interface IButtonBaseProps {
	children?: string | ReactElement;
	disabled?: boolean;
	listener?: string | string[] | boolean;
	underlayColor?: types.theme.themeColors;
	underlayLoadingColor?: types.theme.themeColors;
	sideMargin?: number;
	onClick: (e?: any) => void;
	type?: ButtonHTMLAttributes<HTMLButtonElement>['type'];
	uniqueId: string;
	textAlign?: CSSProperties['textAlign'];
	rightPadding?: number;
	leftPadding?: number;
}

export interface IButtonStyleProps {
	variant: ButtonVariant;
	width: ButtonWidth;
	size: ButtonSize;
}

export interface IButtonProps extends IButtonBaseProps, IButtonStyleProps {}

const Button: FunctionComponent<IButtonProps> = ({
	variant,
	width,
	size,
	disabled,
	listener,
	children,
	onClick,
	underlayColor,
	underlayLoadingColor,
	sideMargin,
	type,
	uniqueId,
	textAlign,
	leftPadding,
	rightPadding,
}) => {
	const busyIndicatorsList = useSelector((state: types.redux.IState) => state.busyIndicator.list);
	let loading: boolean = false;
	if (Array.isArray(listener)) {
		loading = listener.some((v) => busyIndicatorsList.includes(v));
	} else if (typeof listener === 'boolean') {
		loading = listener;
	} else if (listener) {
		loading = busyIndicatorsList.includes(listener);
	}

	const fontColor: types.theme.themeColors = useMemo(() => {
		if (disabled) {
			return 'black38';
		}
		if (variant === ButtonVariant.WARNING || variant === ButtonVariant.QUATERNARY) {
			return 'white';
		}
		if (variant === ButtonVariant.QUATERNARY_NEGATIVE) {
			return 'green';
		}
		return variant === ButtonVariant.DEFAULT ||
			variant === ButtonVariant.PRIMARY ||
			variant === ButtonVariant.PRIMARY_ROUNDED
			? 'white'
			: 'primary';
	}, [disabled, variant]);

	const label: ReactElement = useMemo(() => {
		const ButtonType = size === ButtonSize.LARGE ? ButtonL : ButtonM;

		return (
			<ButtonType id={`${uniqueId}.button`} color={fontColor}>
				{children}
			</ButtonType>
		);
	}, [fontColor, size, children]);

	const loader: ReactElement =
		variant === ButtonVariant.DEFAULT || variant === ButtonVariant.PRIMARY || variant === ButtonVariant.WARNING ? (
			<OrangeCircularLoader />
		) : variant === ButtonVariant.QUATERNARY_DISABLED ? (
			<CarInsuranceCircularLoader />
		) : (
			<PrimaryCircularLoader />
		);

	return (
		<ButtonWrapper
			variant={variant}
			width={width}
			size={size}
			disabled={disabled || loading}
			underlayColor={underlayColor}
			textAlign={textAlign}
			underlayLoadingColor={underlayLoadingColor}
		>
			<ButtonContainer
				variant={variant}
				width={width}
				size={size}
				disabled={disabled || loading}
				isLoading={loading}
				onClick={onClick}
				sideMargin={sideMargin}
				type={type}
				id={`${uniqueId}.btn`}
				rightPadding={rightPadding}
				leftPadding={leftPadding}
			>
				<ConditionalRender show={!!loading} onTrue={() => loader} onFalse={() => label} />
			</ButtonContainer>
		</ButtonWrapper>
	);
};

export const DefaultLargeButtonFixed: FunctionComponent<IButtonBaseProps> = (props) => (
	<Button {...props} variant={ButtonVariant.DEFAULT} size={ButtonSize.LARGE} width={ButtonWidth.FIXED} />
);

export const DefaultMediumButtonFixed: FunctionComponent<IButtonBaseProps> = (props) => (
	<Button {...props} variant={ButtonVariant.DEFAULT} size={ButtonSize.MEDIUM} width={ButtonWidth.FIXED} />
);

export const DefaultLargeButtonAuto: FunctionComponent<IButtonBaseProps> = (props) => (
	<Button {...props} variant={ButtonVariant.DEFAULT} size={ButtonSize.LARGE} width={ButtonWidth.AUTO} />
);

export const DefaultMediumButtonAuto: FunctionComponent<IButtonBaseProps> = (props) => (
	<Button {...props} variant={ButtonVariant.DEFAULT} size={ButtonSize.MEDIUM} width={ButtonWidth.AUTO} />
);

export const PrimaryLargeButtonFixed: FunctionComponent<IButtonBaseProps> = (props) => (
	<Button {...props} variant={ButtonVariant.PRIMARY} size={ButtonSize.LARGE} width={ButtonWidth.FIXED} />
);

export const PrimaryMediumButtonFixed: FunctionComponent<IButtonBaseProps> = (props) => (
	<Button {...props} variant={ButtonVariant.PRIMARY} size={ButtonSize.MEDIUM} width={ButtonWidth.FIXED} />
);

export const PrimaryMediumButtonFlex: FunctionComponent<IButtonBaseProps> = (props) => (
	<Button {...props} variant={ButtonVariant.PRIMARY} size={ButtonSize.MEDIUM} width={ButtonWidth.FLEX} />
);

export const PrimaryLargeButtonAuto: FunctionComponent<IButtonBaseProps> = (props) => (
	<Button {...props} variant={ButtonVariant.PRIMARY} size={ButtonSize.LARGE} width={ButtonWidth.AUTO} />
);

export const PrimaryLargeButtonFlex: FunctionComponent<IButtonBaseProps> = (props) => (
	<Button {...props} variant={ButtonVariant.PRIMARY} size={ButtonSize.LARGE} width={ButtonWidth.FLEX} />
);

export const PrimaryMediumButtonAuto: FunctionComponent<IButtonBaseProps> = (props) => (
	<Button {...props} variant={ButtonVariant.PRIMARY} size={ButtonSize.MEDIUM} width={ButtonWidth.AUTO} />
);

export const SecondaryLargeButtonFixed: FunctionComponent<IButtonBaseProps> = (props) => (
	<Button {...props} variant={ButtonVariant.SECONDARY} size={ButtonSize.LARGE} width={ButtonWidth.FIXED} />
);

export const SecondaryLargeButtonFlex: FunctionComponent<IButtonBaseProps> = (props) => (
	<Button {...props} variant={ButtonVariant.SECONDARY} size={ButtonSize.LARGE} width={ButtonWidth.FLEX} />
);

export const SecondaryMediumButtonFixed: FunctionComponent<IButtonBaseProps> = (props) => (
	<Button {...props} variant={ButtonVariant.SECONDARY} size={ButtonSize.MEDIUM} width={ButtonWidth.FIXED} />
);

export const SecondaryMediumButtonFixedNoBorder: FunctionComponent<IButtonBaseProps> = (props) => (
	<Button {...props} variant={ButtonVariant.SECONDARY_NO_BORDER} size={ButtonSize.MEDIUM} width={ButtonWidth.FIXED} />
);

export const SecondaryMediumButtonFlexNoBorder: FunctionComponent<IButtonBaseProps> = (props) => (
	<Button {...props} variant={ButtonVariant.SECONDARY_NO_BORDER} size={ButtonSize.MEDIUM} width={ButtonWidth.FLEX} />
);

export const SecondaryLargeButtonFlexNoBorder: FunctionComponent<IButtonBaseProps> = (props) => (
	<Button {...props} variant={ButtonVariant.SECONDARY_NO_BORDER} size={ButtonSize.LARGE} width={ButtonWidth.FLEX} />
);

export const SecondaryMediumButtonFlex: FunctionComponent<IButtonBaseProps> = (props) => (
	<Button {...props} variant={ButtonVariant.SECONDARY} size={ButtonSize.MEDIUM} width={ButtonWidth.FLEX} />
);

export const SecondaryLargeButtonAuto: FunctionComponent<IButtonBaseProps> = (props) => (
	<Button {...props} variant={ButtonVariant.SECONDARY} size={ButtonSize.LARGE} width={ButtonWidth.AUTO} />
);

export const SecondaryMediumButtonAuto: FunctionComponent<IButtonBaseProps> = (props) => (
	<Button {...props} variant={ButtonVariant.SECONDARY} size={ButtonSize.MEDIUM} width={ButtonWidth.AUTO} />
);

export const TertiaryLargeButtonFixed: FunctionComponent<IButtonBaseProps> = (props) => (
	<Button {...props} variant={ButtonVariant.TERTIARY} size={ButtonSize.LARGE} width={ButtonWidth.FIXED} />
);

export const TertiaryMediumButtonFixed: FunctionComponent<IButtonBaseProps> = (props) => (
	<Button {...props} variant={ButtonVariant.TERTIARY} size={ButtonSize.MEDIUM} width={ButtonWidth.FIXED} />
);

export const TertiaryMediumButtonFlex: FunctionComponent<IButtonBaseProps> = (props) => (
	<Button {...props} variant={ButtonVariant.TERTIARY} size={ButtonSize.MEDIUM} width={ButtonWidth.FLEX} />
);

export const TertiaryLargeButtonAuto: FunctionComponent<IButtonBaseProps> = (props) => (
	<Button {...props} variant={ButtonVariant.TERTIARY} size={ButtonSize.LARGE} width={ButtonWidth.AUTO} />
);

export const TertiaryLargeButtonFlex: FunctionComponent<IButtonBaseProps> = (props) => (
	<Button {...props} variant={ButtonVariant.TERTIARY} size={ButtonSize.LARGE} width={ButtonWidth.FLEX} />
);

export const TertiaryMediumButtonAuto: FunctionComponent<IButtonBaseProps> = (props) => (
	<Button {...props} variant={ButtonVariant.TERTIARY} size={ButtonSize.MEDIUM} width={ButtonWidth.AUTO} />
);

export const QuaternaryMediumButtonFixed: FunctionComponent<IButtonBaseProps> = (props) => (
	<Button
		{...props}
		variant={ButtonVariant.QUATERNARY_NO_BACKGROUND}
		size={ButtonSize.MEDIUM}
		width={ButtonWidth.FIXED}
	/>
);

export const QuaternaryMediumButtonFlex: FunctionComponent<IButtonBaseProps> = (props) => (
	<Button
		{...props}
		variant={ButtonVariant.QUATERNARY_NO_BACKGROUND}
		size={ButtonSize.MEDIUM}
		width={ButtonWidth.FLEX}
	/>
);

export const WarningMediumButtonFixed: FunctionComponent<IButtonBaseProps> = (props) => (
	<Button {...props} variant={ButtonVariant.WARNING} size={ButtonSize.MEDIUM} width={ButtonWidth.FIXED} />
);

export const WarningLargeButtonFixed: FunctionComponent<IButtonBaseProps> = (props) => (
	<Button {...props} variant={ButtonVariant.WARNING} size={ButtonSize.LARGE} width={ButtonWidth.FIXED} />
);

export const QuaternaryNegativeMediumButtonFlex: FunctionComponent<IButtonBaseProps> = (props) => (
	<Button {...props} variant={ButtonVariant.QUATERNARY_NEGATIVE} size={ButtonSize.MEDIUM} width={ButtonWidth.FLEX} />
);

export default Button;
