import React, { FunctionComponent } from 'react';
import { CheckoutWrapper, CheckoutStepperWrapper } from './Checkout.styled';
import CheckoutBar from '@Components/CheckoutBar/CheckoutBar';
import Checkout from '@Views/Checkout/Checkout';
import { Col, Container, Row, Visible } from 'react-grid-system';
import CheckoutStepper from '@Components/CheckoutStepper/CheckoutStepper';
import { useSelector } from 'react-redux';
import { useCart } from '@Utils/context/CartContext';
import ConditionalRender from '@Components/ConditionalRender/ConditionalRender';
import Card from '@Components/Card/Card';
import BusyIndicator from '@Components/BusyIndicator/BusyIndicator';
import { GET_TELESALES_CART_BI } from '../../App';
import { SectionName } from '@Views/Checkout/Checkout.styled';
import useTranslate from '@Utils/hooks/useTranslate';
import useTelesales from '@Utils/hooks/useTeleasales';
import OutletContextProvider from '@Utils/context/OutletContext';

const CheckoutLayout: FunctionComponent = () => {
	const isTelesalesFromRedux = !!useSelector((state: types.redux.IState) => state.api.authentication.telesales);
	const { cartItemsLength } = useCart();
	const { isTelesales } = useTelesales();
	const { translate } = useTranslate();

	return (
		<OutletContextProvider>
			<CheckoutWrapper>
				<CheckoutBar />
				<Visible xs sm>
					<Container>
						<Row>
							<Col xs={12}>
								<CheckoutStepperWrapper>
									<CheckoutStepper />
								</CheckoutStepperWrapper>
							</Col>
						</Row>
					</Container>
				</Visible>
				<ConditionalRender
					show={!isTelesalesFromRedux && !isTelesales}
					onTrue={() => <Checkout />}
					onFalse={() => (
						<ConditionalRender
							show={cartItemsLength > 0 && isTelesalesFromRedux}
							onTrue={() => <Checkout />}
							onFalse={() => (
								<Container>
									<BusyIndicator listener={GET_TELESALES_CART_BI} skeleton="telesalesCart">
										<>
											<Row>
												<Col>
													<SectionName>
														{translate('telesales-checkout.view.empty.cart')}
													</SectionName>
												</Col>
											</Row>
											<Card<types.cards.ITelesalesDescriptionProps>
												card="telesalesDescription"
												params={{
													description1: translate('telesales.expired'),
												}}
												marginBottom={16}
											/>
										</>
									</BusyIndicator>
								</Container>
							)}
						/>
					)}
				/>
			</CheckoutWrapper>
		</OutletContextProvider>
	);
};

export default CheckoutLayout;
