import React, { FunctionComponent } from 'react';
import { SingleProductWrapper, TileSize } from '@Components/Card/cards/ProductCard/ProductCard.styled';
import { ScreenClass } from 'react-grid-system';
import Card from '@Components/Card/Card';
import { IItem } from '@ApiModels/productsByCategoryId';
import { matchScreen } from '@Utils/layout/layoutSupport';
import { AppleRelatedProductsWrapper } from '@Components/AppleRelatedProducts/AppleRelatedProducts.styled';

interface IAppleRelatedProducts {
	products?: IItem[];
	screenClass: ScreenClass;
	showInstalment?: boolean;
	clearProductPage?: () => void;
}

const AppleRelatedProducts: FunctionComponent<IAppleRelatedProducts> = ({
	products,
	screenClass,
	showInstalment,
	clearProductPage,
}) => {
	return (
		<AppleRelatedProductsWrapper screenClass={screenClass}>
			{products
				?.filter((item) => !!item)
				.map((product, index) =>
					index < (matchScreen(['xxl', 'xl']) ? 4 : 3) ? (
						<SingleProductWrapper
							className={`single-product-${screenClass}`}
							screenClass={screenClass}
							key={index}
							onClick={clearProductPage}
						>
							<Card<types.cards.IProductCardProps>
								card="product"
								params={{
									product,
									tileSize: TileSize.BIG,
									iconsHidden: true,
									layoutChange: false,
									isRelatedProduct: true,
									inventory: product.inventory,
									showInstalment,
								}}
								marginTop={0}
								marginBottom={10}
							/>
						</SingleProductWrapper>
					) : (
						''
					)
				)}
		</AppleRelatedProductsWrapper>
	);
};

export default AppleRelatedProducts;
