import { PlanBarType } from '@Components/PlanBar/PlanBarType';
import { ISinglePackage } from '@ApiModels/singlePackage';

export const getBarTypeByPlan = (plan: ISinglePackage, inactive?: boolean): PlanBarType => {
	if (plan.primaryOffer || plan.isHBB) {
		return inactive ? PlanBarType.UNLIMITED_INACTIVE : PlanBarType.UNLIMITED;
	}
	if (plan.alUfuq) {
		return inactive ? PlanBarType.BAQATI_AL_UFUQ_INACTIVE : PlanBarType.BAQATI_AL_UFUQ;
	}
	return inactive ? PlanBarType.BAQATI_INACTIVE : PlanBarType.BAQATI;
};

export const getBarType = (plan: ISinglePackage, planIndex: number, selectedPlanIndex?: number): PlanBarType => {
	if (plan.primaryOffer) {
		return planIndex === selectedPlanIndex ? PlanBarType.UNLIMITED : PlanBarType.UNLIMITED_INACTIVE;
	}
	if (plan.alUfuq) {
		return planIndex === selectedPlanIndex ? PlanBarType.BAQATI_AL_UFUQ : PlanBarType.BAQATI_AL_UFUQ_INACTIVE;
	}
	return planIndex === selectedPlanIndex ? PlanBarType.BAQATI : PlanBarType.BAQATI_INACTIVE;
};
