import styled from 'styled-components';
import { Subheading } from '@Components/Typography/Typography';
import { Container, Row } from 'react-grid-system';

export const ContentWrapper = styled.div<{ paddingLeft: number; paddingRight: number }>`
	${({ paddingLeft, paddingRight }) => ({
		paddingLeft,
		paddingRight,
	})}
`;

export const SectionName = styled(Subheading)`
	text-transform: uppercase;
	padding: 8px;
	${({ theme }) => ({
		color: theme.colors.black54,
	})}
`;

export const SortingMethodsRow = styled(Row)<types.IStyledProps>`
	padding: 0 16px;
`;

export const WhiteSpaceWrapper = styled.div<{ isSMMD?: boolean; isXS?: boolean }>`
	position: relative;
	background-color: white;
	padding-bottom: 50px;
	${({ isSMMD, isXS }) => ({
		margin: isXS ? '0 -10px -64px' : isSMMD ? '0 -32px -64px' : '0 -200% -64px',
	})}
`;

export const ProductBoxWrapper = styled.div<{ marginRight?: number }>`
	${({ theme, marginRight }) => ({
		backgroundColor: theme.colors.background,
		marginRight: marginRight ?? 0,
	})}
	width: 100%;
`;

export const ProductAdditionalInfoPanelWrapper = styled.div<{ customMargin?: string }>`
	background-color: white;
	position: relative;
	padding-top: 1px;
	${({ customMargin }) => ({
		margin: customMargin ? customMargin : '56px 0px 64px',
	})}
`;

export const ProductAdditionalInfoPanelContainer = styled(Container)`
	max-width: 100%;
`;

export const StickyWrapper = styled.div`
	position: sticky;
	top: 154px;
	padding-bottom: 16px;
`;

export const AdditionalInfoWrapper = styled.div<{ paddingLeft: number; paddingRight: number }>`
	${({ paddingLeft, paddingRight }) => ({
		paddingLeft,
		paddingRight,
	})}
`;

export const TopContentWrapper = styled.div<{ marginLeft: number; marginRight: number }>`
	${({ marginLeft, marginRight }) => ({
		marginLeft,
		marginRight,
	})}
`;
