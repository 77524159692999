import styled from 'styled-components';
import { BodyM, BodyMBold, TitleBold } from '@Components/Typography/Typography';
import { matchScreen } from '@Utils/layout/layoutSupport';

export const CardContainer = styled.div`
	padding: 24px;
	display: flex;
	flex-direction: column;
	text-align: center;
	max-width: 100%;
	align-items: center;
	width: ${() => (matchScreen('xs') ? 'auto' : '600px')};
`;

export const ImageWrapper = styled.div`
	display: flex;
	align-items: flex-start;
	padding-bottom: 24px;
`;

export const CartTitle = styled(TitleBold)`
	padding-bottom: 8px;
	${({ theme }) => ({
		color: theme.colors.black87,
	})}
`;
export const CartDescription = styled(BodyM)`
	display: inline-block;
	${({ theme }) => ({
		color: theme.colors.black87,
	})};
	padding-bottom: 24px;
	text-align: center;
`;
export const Highlighted = styled(BodyMBold)`
	display: inline-block;
	${({ theme }) => ({
		color: theme.colors.black87,
	})}
`;

export const ActionWrapper = styled.div`
	display: flex;
	justify-content: flex-end;
`;

export const CartButtonWrapper = styled.div`
	display: flex;
	justify-content: flex-end;
	align-self: flex-end;
	justify-self: flex-end;
	margin-top: 32px;
`;

export const HeaderContainer = styled.div<{ isXS: boolean }>`
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	align-self: flex-end;
`;

export const IconContainer = styled.div`
	display: flex;
	align-items: center;
`;

export const IconButton = styled.div`
	cursor: pointer;
	padding: 8px;
`;
