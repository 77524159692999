import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import useTranslate from '@Utils/hooks/useTranslate';
import { Col, Hidden, Row } from 'react-grid-system';
import Icon from '@Components/Icon/Icon';
import {
	Horizontal,
	IcoWrapper,
	Link,
	OrderItemWrapper,
	PaymentAndDeliveryContainer,
	PaymentDetails,
	PaymentDetailsDesc,
	PaymentDetailsTitle,
	Status,
	StatusWrapper,
	Underline,
} from './GuestOrderItem.styled';
import { BodyS, Caption, Value, ValueBold } from '@Components/Typography/Typography.styled';
import ConditionalRender from '@Components/ConditionalRender/ConditionalRender';
import { useLocation } from 'react-router-dom';
import Divider from '@Components/Divider/Divider';
import { IOrderDetails, IOrderDetailsItem } from '@ApiModels/orderDetails';
import CartPaymentSummary from '../../CartPaymentSummary/CartPaymentSummary';
import { dispatch } from '@Redux/store';
import BusyIndicator from '@Components/BusyIndicator/BusyIndicator';
import { modalTypes, setModal } from '@Redux/modules/modal/actions';
import TitleWithLine from '@Components/TitleWithLine/TitleWithLine';
import OrderTracking from '@Components/OrderTracking/OrderTracking';
import { OrderHistoryType } from '@Components/Card/cards/OrderHistory/OrderHistory';
import { BUY_HBB, BUY_WFBB } from '@Views/Map/Map';

export interface IGuestOrderSingleItem {
	orderId?: string;
	details: IOrderDetails | undefined;
	detailsItem: IOrderDetailsItem;
	item?: string;
	type?: OrderHistoryType;
	status?: string;
	isOrderHistoryView?: boolean;
}

export const GET_ORDER_HISTORY_DETAILS_BI = 'GET_ORDER_HISTORY_DETAILS_BI';

const GuestOrderItem: FunctionComponent<IGuestOrderSingleItem> = ({
	details,
	detailsItem,
	type,
	status,
	isOrderHistoryView = false,
}): JSX.Element => {
	const { translate } = useTranslate();
	const ICON_SIZE = 40;
	const STATUS_ICON_SIZE = 21;
	const { search } = useLocation();
	const query = new URLSearchParams(search);
	const [isDigital, setIsDigital] = useState<boolean>(false);
	const [isAddressEmpty, setIsAddressEmpty] = useState<boolean>(false);

	useEffect(() => {
		detailsItem?.shipmentNumber === '-' ? setIsDigital(true) : '';
		if (details && details.orderItem) {
			const _item = details.orderItem[0];
			setIsAddressEmpty(!_item.addressLine?.filter((it) => !!it).length);
		} else {
			setIsAddressEmpty(true);
		}
	}, [detailsItem]);

	const getAddress = () => {
		if (details && details.orderItem) {
			const _item = details.orderItem[0];
			return `${_item.addressLine?.filter((it) => !!it)}, ${_item.city} ${_item.zipCode}, ${_item.country}`;
		}
		return '';
	};

	const getOrderId = () => {
		return query.get('orderId') || '';
	};

	const hbbWfbbOrderDetails = useMemo(() => {
		return details?.orderItem?.find((item) => [BUY_HBB, BUY_WFBB].includes(item.partNumber || ''));
	}, [details]);

	return (
		<OrderItemWrapper>
			<Row>
				<Hidden xs sm md>
					<Col sm={1}>
						<IcoWrapper>
							<Icon name="order" width={ICON_SIZE} height={ICON_SIZE} />
						</IcoWrapper>
					</Col>
				</Hidden>
				<Col>
					<Row>
						<Col>
							<Divider marginBottom={8} withoutLine />
							<Horizontal>
								<ValueBold color="black87">
									{translate('account.management.order.id', details?.orderId || '')}
								</ValueBold>
								<Value color="black87">{detailsItem?.data}</Value>
							</Horizontal>
							<Divider marginBottom={8} withoutLine />
						</Col>
						<Col md={4}>
							<StatusWrapper>
								<Horizontal>
									<ConditionalRender
										show={type === OrderHistoryType.ORDERS_IN_PROGRESS}
										onTrue={() => (
											<>
												<Status>
													<Caption color="support3">
														{translate('account.management.order.in.progress')}
													</Caption>
												</Status>
												<Icon
													name="waiting"
													width={STATUS_ICON_SIZE}
													height={STATUS_ICON_SIZE}
													fill="black38"
												/>
											</>
										)}
									/>
									<ConditionalRender
										show={type === OrderHistoryType.ORDER_HISTORY}
										onTrue={() => (
											<>
												<ConditionalRender
													show={status === 'S'}
													onTrue={() => (
														<>
															<Status>
																<Caption color="positive">
																	{translate('account.management.order.completed')}
																</Caption>
															</Status>
															<Icon
																name="success"
																width={STATUS_ICON_SIZE}
																height={STATUS_ICON_SIZE}
															/>
														</>
													)}
													onFalse={() => (
														<>
															<Status>
																<Caption color="warning">
																	{translate('account.management.order.canceled')}
																</Caption>
															</Status>
															<Icon
																name="error"
																fill="warning"
																width={STATUS_ICON_SIZE}
																height={STATUS_ICON_SIZE}
															/>
														</>
													)}
												/>
											</>
										)}
									/>
								</Horizontal>
							</StatusWrapper>
						</Col>
					</Row>
				</Col>
			</Row>
			<Row>
				<Hidden xs sm md>
					<Col sm={1}></Col>
				</Hidden>
				<Col>
					<BusyIndicator listener={GET_ORDER_HISTORY_DETAILS_BI} skeleton="orderTrackingDetails">
						<>
							<TitleWithLine
								title={translate('account.management.payment.shipment.status')}
								marginTop={36}
								marginBottom={20}
							/>
							<PaymentAndDeliveryContainer>
								<ConditionalRender
									show={
										!isDigital &&
										!detailsItem.isPickup &&
										!isAddressEmpty &&
										!detailsItem.isOutletPickup
									}
									onTrue={() => (
										<PaymentDetails>
											<Icon name="locationPin" width={ICON_SIZE} height={ICON_SIZE} />
											<PaymentDetailsDesc>
												<PaymentDetailsTitle>
													{translate('account.management.delivery.address')}
												</PaymentDetailsTitle>
												<BodyS color="black54">{getAddress()}</BodyS>
												<Divider color="transparent" marginBottom={16} />
												<Link
													onClick={() => {
														dispatch(
															setModal({
																type: modalTypes.SHOW_ON_MAP,
																data: {
																	coordinates: detailsItem.coordinates,
																},
															})
														);
													}}
												>
													<Underline color="primary">
														{translate('account.management.show.on.map')}
													</Underline>
												</Link>
											</PaymentDetailsDesc>
										</PaymentDetails>
									)}
								/>
								<ConditionalRender
									show={!!detailsItem?.paymentDate}
									onTrue={() => (
										<PaymentDetails>
											<Icon name="card" width={ICON_SIZE} height={ICON_SIZE} />
											<PaymentDetailsDesc justifyContent="flex-start">
												<PaymentDetailsTitle>
													{translate('account.management.online.payment')}
												</PaymentDetailsTitle>
												<BodyS color="black54">
													{translate(
														'account.management.payment.recieved',
														detailsItem?.paymentDate ?? ''
													)}
												</BodyS>
											</PaymentDetailsDesc>
										</PaymentDetails>
									)}
								/>
							</PaymentAndDeliveryContainer>
							<OrderTracking
								orderId={getOrderId()}
								detailsItem={detailsItem}
								type={OrderHistoryType.ORDERS_IN_PROGRESS}
								isOrderHistoryView={isOrderHistoryView}
								status={details?.orderStatus || ''}
								hbbWfbbOrderDetails={hbbWfbbOrderDetails}
							/>
							<CartPaymentSummary
								params={{
									upfront: Number(detailsItem.upfrontPaymentSum),
									period: detailsItem.period,
									periodPrice: detailsItem.periodPaymentSum,
									items: detailsItem.items,
								}}
								card="cartPaymentSummary"
							/>
						</>
					</BusyIndicator>
				</Col>
			</Row>
		</OrderItemWrapper>
	);
};

export default GuestOrderItem;
