import styled from 'styled-components';
import { matchScreen, marginLeft, marginRight } from '@Utils/layout/layoutSupport';
import theme from '@Utils/theme/theme';

export const OutletPickupWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	${() => ({
		margin: matchScreen('xs') ? '0 -64px -8px -20px' : '0px -40px -8px 12px',
	})}
	${() => ({
		...marginLeft(matchScreen('sm') ? '0px' : matchScreen('xs') ? '0px' : '12px'),
		...marginRight(matchScreen('xs') ? '-64px' : '-40px'),
	})}
`;

export const MapWrapper = styled.div`
	${() => ({
		height: matchScreen('xs') ? 350 : 300,
		width: matchScreen('xs') || matchScreen('sm') ? '100%' : 300,
	})}
	${() => ({
		...marginLeft(matchScreen('sm') ? '8px' : '0px'),
		...marginRight(matchScreen('sm') ? '24px' : '0px'),
	})}
`;

export const MapElement = styled.div`
	width: 100%;
	height: 100%;
	${() => ({
		paddingTop: matchScreen('xs') ? 16 : 0,
	})}
`;

export const OutletWrapper = styled.div<{ selected?: boolean }>`
	display: flex;
	flex-direction: column;
	border-radius: 8px;
	&:hover {
		${({ theme }) => ({
			backgroundColor: theme.colors.primary6,
		})};
	}
	margin-bottom: 16px;
	width: 100%;
	${() => ({
		...marginLeft(matchScreen('xl') || matchScreen('lg') ? '-28px' : '-10px'),
		...marginRight('32px'),
	})}
	${({}) => ({
		backgroundColor: theme.colors.grey50,
	})};
	${({ selected, theme }) =>
		selected &&
		`
border: 2px solid ${theme.colors.primary}

`};
`;

export const RadioButtonWrapper = styled.div`
	margin: 22px 18px;
`;

export const OutletSmallContainerWrapper = styled.div`
	margin-left: 18px;
	${() => marginLeft('18px')}
`;
export const OutletLabelsWrapper = styled.div`
	display: flex;
	flex-direction: column;
	margin-top: 16px;
`;

export const OutletSmallWrapper = styled.div`
	margin-bottom: 4px;
`;

export const LongContentLabelWrapper = styled.div`
	margin-bottom: 4px;
	margin-top: 16px;
`;

export const LongContentHourWrapper = styled.div`
	margin-bottom: 8px;
	margin-top: 16px;
`;

export const HourLabelWrapper = styled.div`
	margin-bottom: 8px;
	display: flex;
`;

export const OutletLongContainerWrapper = styled.div`
	display: flex;
	flex-direction: column;
	margin-bottom: 16px;
	${() => marginLeft('80px')}
`;
