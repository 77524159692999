import { ICartItem } from '@ApiModels/viewCart';
import ConditionalRender from '@Components/ConditionalRender/ConditionalRender';
import Icon from '@Components/Icon/Icon';
import PriceTag from '@Components/PriceTag/PriceTag';
import { useCart } from '@Utils/context/CartContext';
import useTranslate from '@Utils/hooks/useTranslate';
import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { Col, Hidden, Row, useScreenClass, Visible } from 'react-grid-system';
import {
	CartPaymentSummaryContainer,
	CartPaymentSummaryTitle,
	CustomLine,
	EmptyLine,
	EmptyLineContainer,
	HeaderRow,
	HeaderText,
	ItemRow,
	SmallElementContainer,
	ItemsWrapper,
	ItemText,
	SmallPaymentSummaryWrapper,
	MediumPaymentSummaryTitle,
	SmallTextColumn,
	SmallVatWrapper,
	PriceContainer,
	SmallCartPaymentRow,
	PriceRow,
	SmallCartPaymentSummaryTitle,
	SmallItemText,
	VatWrapper,
	VatAmountContainer,
	ItemsColumn,
	SmallSingleItemRow,
} from './CartPaymentSummary.styled';
import CartPaymentSummaryItem from './subcomponents/CartPaymentSummaryItem';
import { ItemType } from '@Services/productsService/productsService';
import { BUY_HBB, BUY_WFBB } from '@Views/Map/Map';
import useScreen from '@Utils/hooks/useScreen';
import { MAKASIB_POINTS_PRODUCT_ID, MAKASIB_VOUCHER_PRODUCT_ID, POSTPAID_PLAN } from '@Config/app.config';
import { roundMoney } from '@Utils/converters/roundMoney';
import { BodyMBold, CaptionBigger, CaptionBiggerBold, Info, InfoBold } from '@Components/Typography/Typography.styled';
import { isVoucherPlanAction } from '@Utils/plans/planAction';
import Divider from '@Components/Divider/Divider';
import { useBuyNowPayLater } from '@Utils/context/BuyNowPayLaterContext';
import { getLang } from '@Utils/language/language';

const GIFTCARD = 'GIFTCARD';
const BNPL_PLAN_ACTIONS = ['BNPL_BAQATI', 'BNPL_HBB', 'BNPL_WFBB'];

const CartPaymentSummary: FunctionComponent<types.cards.ICartPaymentSummaryCardProps> = ({
	params: { upfront, periodPrice, items },
}) => {
	const { translate } = useTranslate();
	const screenClass = useScreenClass();
	const [upfrontPaymentOpen, setUpfrontPaymentOpen] = useState<boolean>(false);
	const [paymentMonthlyOpen, setPaymentMonthlyOpen] = useState<boolean>(false);
	const [longPaymentMonthlyOpen, setLongPaymentMonthlyOpen] = useState<boolean>(false);
	const [ebuItem, setEbuItem] = useState<ICartItem>();
	const [valueOfDiscount, setValueOfDiscount] = useState<number>(0);
	const CHEVRON_ICON_SIZE = 24;
	const {
		cart: { totalProductPrice },
		cartHbbWfbbWithoutOtherItem,
		cartItemsVatValue,
		cartItemsDevicePlanVatSum,
		twoYearInstallmentValue,
		isEbuCart,
	} = useCart();
	const { screen } = useScreen();
	const isXS = screen('xs');
	let isBNPLOrderHistory = false;
	if (items[0]?.plan?.PA !== undefined && items[0]?.plan?.PA !== null) {
		isBNPLOrderHistory = BNPL_PLAN_ACTIONS.includes(items[0]?.plan?.PA);
	} else {
		isBNPLOrderHistory = false;
	}

	const isMakasibInItems = useMemo(() => {
		return items.some(
			(item) => item.productId === MAKASIB_POINTS_PRODUCT_ID || item.productId === MAKASIB_VOUCHER_PRODUCT_ID
		);
	}, [items]);

	const { isBuyNowPayLaterInCart } = useBuyNowPayLater();

	const isMakasibItem = (item: ICartItem) => {
		return item.productId === MAKASIB_POINTS_PRODUCT_ID || item.productId === MAKASIB_VOUCHER_PRODUCT_ID;
	};
	const [totalWithVat, setTotalWithVat] = useState(0);
	const [totalWithoutVat, setTotalWithoutVat] = useState(0);

	const isMakasibVoucher = useMemo(() => {
		return items.some((item) => item.productId === MAKASIB_VOUCHER_PRODUCT_ID) ?? false;
	}, [items]);

	const vat = (item: ICartItem): number => {
		return Number(item?.plan?.vatValue ?? item?.vat ?? 0);
	};

	const vatPercent = (item: ICartItem): number => {
		if (item?.plan?.PA === BUY_HBB) {
			return Number(item?.plan?.planVatPercent || 0);
		}
		return Number(item?.plan?.vatPercent ?? item?.vatPercent ?? item.plan?.planVatPercent) ?? 0;
	};

	const vouchersValue = useMemo(() => {
		return items
			.filter((item) => item.productId === MAKASIB_VOUCHER_PRODUCT_ID)
			.reduce((vouchersValue, item) => vouchersValue + Number(item.price ?? 0), 0);
	}, [items]);

	const makasibPointsValue = useMemo(() => {
		const makasibPointsItem = items.find((item) => {
			return item.productId === MAKASIB_POINTS_PRODUCT_ID;
		});
		return Number(
			makasibPointsItem?.unitPrice
				? makasibPointsItem.unitPrice
				: makasibPointsItem?.price
				? makasibPointsItem.price
				: 0
		);
	}, [items]);

	// There is possibility that some product will have other vatPercent so display highest in summary
	const vatPercentHighest = items.reduce((accumulator, item) => {
		if (item?.plan?.PA === BUY_HBB) {
			if (accumulator < Number(item?.plan?.planVatPercent)) {
				accumulator = Number(item?.plan?.planVatPercent);
			}
			return accumulator;
		} else if (item?.plan?.vatPercent) {
			if (accumulator < Number(item?.plan?.vatPercent ?? item?.vatPercent)) {
				accumulator = Number(item?.plan?.vatPercent ?? item?.vatPercent);
			}
			return accumulator;
		} else {
			return accumulator;
		}
	}, 0);

	const cartItemsPlanPriceSum = items.reduce((accumulator, item) => {
		if (item?.plan?.priceMonthly) {
			return item?.plan?.PA !== ItemType.ITEM_ACCESSORY_INSTALMENTS &&
				item?.plan?.PA !== ItemType.TABLETS_LAPTOPS_INSTALMENTS &&
				item?.plan?.PA !== ItemType.B2B2C_ITEM_ACCESSORY_BAQATI &&
				item?.plan?.PA !== ItemType.B2B2C_TABLETS_LAPTOPS_BAQATI &&
				item?.plan?.PA !== ItemType.B2B_ITEM_ACCESSORY_BAQATI &&
				item?.plan?.PA !== ItemType.B2B_TABLETS_LAPTOPS_BAQATI &&
				!item?.plan?.PA?.includes(POSTPAID_PLAN)
				? accumulator + Number(item?.plan?.priceMonthly)
				: accumulator;
		} else {
			return accumulator;
		}
	}, 0);

	const cartItemsDeviceInstallmentSum = items?.reduce(
		(accumulator, item) => (item?.plan?.IV ? accumulator + Number(item?.plan?.IV) : accumulator),
		0
	);

	const _periodPrice = periodPrice ?? cartItemsPlanPriceSum + cartItemsDeviceInstallmentSum;

	const showHbbWfbbInfo = (item: ICartItem) => {
		return ![BUY_HBB, BUY_WFBB].includes(item?.PA || '') && ![BUY_HBB, BUY_WFBB].includes(item.plan?.PA || '');
	};

	const isDigitalProductInCart = useMemo(() => {
		return items.some((item) => item.isDigital || item.defType === 'GIFTCARD');
	}, [items]);

	const totalVatFromItems = useMemo(() => {
		return items.reduce((totalVatFromItems, item) => {
			const itemVat =
				!!item?.vat || !!item?.vatValue
					? totalVatFromItems + Number(item?.vat || item?.vatValue)
					: totalVatFromItems;
			if (isDigitalProductInCart && (item.isDigital || item.defType === 'GIFTCARD' || isMakasibItem(item))) {
				return totalVatFromItems;
			} else {
				return itemVat;
			}
		}, 0);
	}, [items]);

	const totalAmountWithoutVat = useMemo(() => {
		const totalAmountWithoutVat = items.reduce((totalAmountWithoutVat, item) => {
			if (isMakasibItem(item)) {
				return totalAmountWithoutVat;
			} else {
				return totalAmountWithoutVat + Number(item.price || '0') - Number(item?.vat || item?.vatValue || '0');
			}
		}, 0);
		const discountableItemsAmount = items.reduce((discountableAmount, item) => {
			if (isMakasibItem(item) || item.isDigital || item.defType === 'GIFTCARD') {
				return discountableAmount;
			} else {
				return discountableAmount + Number(item.price || '0');
			}
		}, 0);
		const vatNotPaidFromVoucher =
			discountableItemsAmount + vouchersValue > totalVatFromItems
				? 0
				: totalVatFromItems - discountableItemsAmount - vouchersValue;
		return totalAmountWithoutVat + vouchersValue + vatNotPaidFromVoucher + makasibPointsValue;
	}, [items]);

	const undiscountableCartValue = useMemo(() => {
		const undiscoundableItems = items.filter((item) => item.defType === GIFTCARD);
		if (undiscoundableItems.length > 0) {
			let value = 0;
			undiscoundableItems.forEach((item) => (value = +Number(item.taxablePrice ?? 0)));
			return value;
		} else return 0;
	}, [items]);

	const undiscountableTotalCartValue: number = useMemo(() => {
		const undiscoundableItems = items.filter((item) => item.defType === GIFTCARD);
		if (undiscoundableItems.length > 0) {
			let value = 0;
			undiscoundableItems.forEach((item) => (value = +Number(item.unitPrice ?? 0)));
			return value;
		} else return 0;
	}, [items]);

	useEffect(() => {
		setEbuItem(items?.[0]);
	}, [items]);

	useEffect(() => {
		let totalWithoutVat = 0;
		let totalWithVat = 0;
		items.map((item) => {
			if (item?.plan?.PA?.includes('BNPL')) {
				totalWithoutVat = totalWithoutVat + Number(item?.plan?.BNPL_UPFRONT_MINUS_VAT || 0);
				totalWithVat = totalWithVat + Number(item?.plan?.BNPL_UPFRONT_AMOUNT);
			} else {
				totalWithoutVat = totalWithoutVat + Number(item?.plan?.taxablePrice || 0);
				totalWithVat = totalWithVat + Number(item?.plan?.price);
			}
		});
		setTotalWithVat(totalWithVat);
		setTotalWithoutVat(totalWithoutVat);
	}, [items]);

	const price = () => {
		if (isEbuCart) {
			return ebuItem?.taxablePrice || 0;
		} else if (isBuyNowPayLaterInCart || isBNPLOrderHistory) {
			return totalWithoutVat;
		} else {
			const price = upfront
				? totalAmountWithoutVat
				: isDigitalProductInCart
				? totalAmountWithoutVat
				: Number(totalProductPrice || '0') - cartItemsVatValue;
			return price < 0
				? undiscountableCartValue
				: price < undiscountableCartValue
				? undiscountableCartValue
				: price;
		}
	};

	const totalPrice = () => {
		if (upfront) {
			return upfront - vouchersValue - makasibPointsValue;
		} else if (isBuyNowPayLaterInCart) {
			return totalWithVat;
		} else {
			const price = Number(totalProductPrice || '0') - vouchersValue - makasibPointsValue;
			return price < 0 ? 0 : price;
		}
	};

	return (
		<CartPaymentSummaryContainer>
			<ConditionalRender
				show={isXS}
				onTrue={() => (
					<SmallPaymentSummaryWrapper>
						<ConditionalRender
							show={isBuyNowPayLaterInCart || isBNPLOrderHistory}
							onTrue={() => <Divider withoutLine marginTop={8} />}
						/>
						<Row>
							<SmallCartPaymentSummaryTitle>
								{translate('payment.summary.title')}
							</SmallCartPaymentSummaryTitle>
						</Row>
						<ConditionalRender
							show={isBuyNowPayLaterInCart || isBNPLOrderHistory}
							onTrue={() => (
								<>
									<SmallCartPaymentRow>
										<SmallTextColumn>
											<BodyMBold>{translate('payment.summary.upfront')}</BodyMBold>
											<CaptionBigger color="black54">
												{translate('payment.summary.one.time.payment')}
											</CaptionBigger>
										</SmallTextColumn>
										<PriceContainer>
											<VatWrapper>
												<PriceTag price={price()} />
												<SmallVatWrapper isUpfront={true}>
													<Info color="black54">
														{translate(
															'product.cart-card.with-vat',
															vatPercentHighest ?? 0
														)}
													</Info>
													<VatAmountContainer color="black54" isMakasib={isMakasibInItems}>
														{`OMR ${roundMoney(totalPrice())}`}
													</VatAmountContainer>
												</SmallVatWrapper>
											</VatWrapper>
										</PriceContainer>
									</SmallCartPaymentRow>
								</>
							)}
						/>
						<ConditionalRender
							show={isBuyNowPayLaterInCart || isBNPLOrderHistory}
							onTrue={() => (
								<>
									<SmallCartPaymentRow>
										<SmallTextColumn>
											<BodyMBold>
												{twoYearInstallmentValue > 0
													? translate('product.cart-card.23months')
													: translate('product.cart-card.11months')}
											</BodyMBold>
										</SmallTextColumn>
										<PriceContainer>
											<VatWrapper>
												<PriceTag price={_periodPrice ?? 0} />
											</VatWrapper>
										</PriceContainer>
									</SmallCartPaymentRow>
									<SmallCartPaymentRow>
										<SmallTextColumn>
											<BodyMBold>{translate('product.cart-card.last')}</BodyMBold>
										</SmallTextColumn>
										<PriceContainer>
											<VatWrapper>
												<PriceTag price={items[0].BNPLFEP ?? 0} />
											</VatWrapper>
										</PriceContainer>
									</SmallCartPaymentRow>
									<Divider withoutLine marginBottom={40} />
								</>
							)}
						/>
						<ConditionalRender
							show={
								!!Number(upfront ?? totalProductPrice) && !isBuyNowPayLaterInCart && !isBNPLOrderHistory
							}
							onTrue={() => (
								<>
									<SmallCartPaymentRow>
										<SmallTextColumn>
											<BodyMBold>
												{translate(
													!!_periodPrice ? 'payment.summary.upfront' : 'product.card.price'
												)}
											</BodyMBold>
											<CaptionBigger color="black54">
												{translate('payment.summary.one.time.payment')}
											</CaptionBigger>
										</SmallTextColumn>
										<PriceContainer>
											<VatWrapper>
												<PriceTag price={price()} />
												<SmallVatWrapper isUpfront={true}>
													<Info color="black54">
														{translate(
															'product.cart-card.with-vat',
															vatPercentHighest ?? 0
														)}
													</Info>
													<VatAmountContainer color="black54" isMakasib={isMakasibInItems}>
														{`OMR ${roundMoney(totalPrice())}`}
													</VatAmountContainer>
												</SmallVatWrapper>
												<ConditionalRender
													show={isMakasibInItems}
													onTrue={() => (
														<SmallVatWrapper>
															<Info color="primary">
																{translate('product.cart-card.after-discount')}
															</Info>
															<VatAmountContainer color="primary">
																{`OMR ${roundMoney(
																	isEbuCart
																		? ebuItem?.totalAmount
																		: (upfront ?? Number(totalProductPrice)) < 0
																		? undiscountableTotalCartValue
																		: (upfront ?? Number(totalProductPrice)) <
																		  undiscountableTotalCartValue
																		? undiscountableTotalCartValue
																		: upfront ?? Number(totalProductPrice)
																)}`}
															</VatAmountContainer>
														</SmallVatWrapper>
													)}
												/>
											</VatWrapper>
										</PriceContainer>
									</SmallCartPaymentRow>
									<SmallCartPaymentRow>
										<ItemsColumn>
											{items
												?.map((item) => {
													if (
														item.productId !== MAKASIB_POINTS_PRODUCT_ID &&
														item.productId !== MAKASIB_VOUCHER_PRODUCT_ID
													)
														return item;
												})
												.map(
													(item) =>
														item && (
															<>
																<Divider withoutLine marginTop={8} />
																<SmallSingleItemRow>
																	<CaptionBiggerBold>{item?.name}</CaptionBiggerBold>
																	<PriceTag
																		price={
																			isVoucherPlanAction(item)
																				? Number(item?.price)
																				: Number(item?.price) /
																						(item?.quantity ?? 0) -
																						vat(item) || 0
																		}
																		color="black87"
																		size="miniregularbold"
																	/>
																</SmallSingleItemRow>
																<SmallElementContainer>
																	<SmallSingleItemRow>
																		<CaptionBigger>
																			{translate(
																				'payment.summary.upfront.vat',
																				String(item?.vatPercent)
																			)}
																		</CaptionBigger>
																		<PriceTag
																			price={vat(item)}
																			color="black87"
																			size="miniregular"
																		/>
																	</SmallSingleItemRow>
																</SmallElementContainer>
																<Divider marginTop={12} marginBottom={12} />
															</>
														)
												)}

											<ConditionalRender
												show={items.some(
													(item) => item.productId === MAKASIB_VOUCHER_PRODUCT_ID
												)}
												onTrue={() =>
													items
														.filter((item) => item.productId === MAKASIB_VOUCHER_PRODUCT_ID)
														.map((item, index) => (
															<>
																<Divider withoutLine marginTop={index > 0 ? 16 : 8} />
																<SmallSingleItemRow>
																	<CaptionBiggerBold color="primary">
																		{translate(
																			'cart.e-voucher.summary.title',
																			item?.voucherId
																				? item?.voucherId
																				: item?.plan?.voucherId
																				? item?.plan?.voucherId
																				: ''
																		)}
																	</CaptionBiggerBold>
																	<PriceTag
																		price={item.unitPrice}
																		color="primary"
																		size="miniregularbold"
																	/>
																</SmallSingleItemRow>
																<Divider marginTop={12} />
															</>
														))
												}
											/>
											<ConditionalRender
												show={items.some(
													(item) => item.productId === MAKASIB_POINTS_PRODUCT_ID
												)}
												onTrue={() => (
													<>
														<Divider
															withoutLine
															marginTop={
																items.some(
																	(item) =>
																		item.productId === MAKASIB_VOUCHER_PRODUCT_ID
																)
																	? 16
																	: 8
															}
														/>
														<SmallSingleItemRow>
															<CaptionBiggerBold color="primary">
																{translate('cart.makasib.summary.title')}
															</CaptionBiggerBold>
															<PriceTag
																price={
																	items.find(
																		(item) =>
																			item.productId === MAKASIB_POINTS_PRODUCT_ID
																	)?.unitPrice ?? 0
																}
																color="primary"
																size="miniregularbold"
															/>
														</SmallSingleItemRow>
														<Divider marginTop={12} />
													</>
												)}
											/>
										</ItemsColumn>
									</SmallCartPaymentRow>
								</>
							)}
						/>

						<ConditionalRender
							show={!!_periodPrice && !isBuyNowPayLaterInCart && !isBNPLOrderHistory}
							onTrue={() => (
								<>
									<SmallCartPaymentRow>
										<SmallTextColumn>
											<BodyMBold>
												{isEbuCart
													? translate('payment.summary.description.installments')
													: twoYearInstallmentValue > 0
													? translate('product.cart-card.for24months')
													: translate('product.cart-card.for12months')}
											</BodyMBold>
											<CaptionBigger color="black54">
												{translate('plan-comparison.monthly.payment')}
											</CaptionBigger>
										</SmallTextColumn>
										<PriceContainer>
											<VatWrapper>
												<PriceTag price={(_periodPrice ?? 0) - cartItemsDevicePlanVatSum} />
												<ConditionalRender
													show={!isEbuCart}
													onTrue={() => (
														<SmallVatWrapper isUpfront={true}>
															<Info color="black54">
																{translate(
																	'product.cart-card.with-vat',
																	vatPercentHighest ?? 0
																)}
															</Info>
															<VatAmountContainer color="black54">
																{`OMR ${roundMoney(_periodPrice)}`}
															</VatAmountContainer>
														</SmallVatWrapper>
													)}
												/>
											</VatWrapper>
										</PriceContainer>
									</SmallCartPaymentRow>
									<ConditionalRender
										show={!isEbuCart}
										onTrue={() => (
											<SmallCartPaymentRow>
												<ItemsColumn>
													{items
														?.map((item) => {
															if (
																item.productId !== MAKASIB_POINTS_PRODUCT_ID &&
																item.productId !== MAKASIB_VOUCHER_PRODUCT_ID
															)
																return item;
														})
														.map(
															(item) =>
																item &&
																Number(item?.plan?.IV ?? 0) > 0 && (
																	<>
																		<Divider withoutLine marginTop={8} />
																		<SmallSingleItemRow>
																			<CaptionBiggerBold>
																				{item?.name}
																			</CaptionBiggerBold>
																			<PriceTag
																				price={item?.plan?.IV ?? 0}
																				color="black87"
																				size="miniregularbold"
																			/>
																		</SmallSingleItemRow>
																		<SmallElementContainer></SmallElementContainer>
																		<Divider marginTop={12} marginBottom={12} />
																	</>
																)
														)}
													{items
														?.map((item) => {
															if (
																item.productId !== MAKASIB_POINTS_PRODUCT_ID &&
																item.productId !== MAKASIB_VOUCHER_PRODUCT_ID
															)
																return item;
														})
														.map(
															(item) =>
																item && (
																	<ConditionalRender
																		show={!!item.plan?.planMonthy}
																		onTrue={() => (
																			<>
																				<Divider withoutLine marginTop={8} />
																				<SmallSingleItemRow>
																					<CaptionBiggerBold>
																						{item.plan?.planName}
																					</CaptionBiggerBold>
																					<PriceTag
																						price={
																							item.plan
																								?.planTaxablePrice ?? 0
																						}
																						color="black87"
																						size="miniregularbold"
																					/>
																				</SmallSingleItemRow>
																				<SmallElementContainer>
																					<SmallSingleItemRow>
																						<CaptionBigger>
																							{translate(
																								'payment.summary.bill.vat',
																								String(
																									item.plan
																										?.planVatPercent ??
																										0
																								)
																							)}
																						</CaptionBigger>
																						<PriceTag
																							price={
																								item.plan
																									?.planVatValue ?? 0
																							}
																							color="black87"
																							size="miniregular"
																						/>
																					</SmallSingleItemRow>
																				</SmallElementContainer>
																				<Divider marginTop={12} />
																			</>
																		)}
																	/>
																)
														)}
												</ItemsColumn>
											</SmallCartPaymentRow>
										)}
									/>
								</>
							)}
						/>
					</SmallPaymentSummaryWrapper>
				)}
				onFalse={() => (
					<>
						<Row>
							<Hidden xs>
								<ConditionalRender
									show={screen('sm')}
									onTrue={() => (
										<MediumPaymentSummaryTitle>
											{translate('payment.summary.title')}
										</MediumPaymentSummaryTitle>
									)}
									onFalse={() => (
										<CartPaymentSummaryTitle>
											{translate('payment.summary.title')}
										</CartPaymentSummaryTitle>
									)}
								/>
							</Hidden>
							<Visible xs>
								<SmallCartPaymentSummaryTitle>
									{translate('payment.summary.title')}
								</SmallCartPaymentSummaryTitle>
							</Visible>
						</Row>
						<Hidden xs>
							<HeaderRow>
								{getLang() === 'ar' ? (
									<Col
										lg={8}
										md={3}
										sm={4}
										offset={{ lg: 1, md: 6, sm: 5 }}
										style={{ textAlign: 'left' }}
									>
										<HeaderText>{translate('cart.items.one-time')}</HeaderText>
									</Col>
								) : (
									<Col lg={3} md={3} sm={4} offset={{ lg: 6, md: 6, sm: 5 }}>
										<HeaderText>{translate('cart.items.one-time')}</HeaderText>
									</Col>
								)}

								<Col lg={3} md={3} sm={3}>
									<HeaderText alignRight>{translate('cart.items.monthly')}</HeaderText>
								</Col>
							</HeaderRow>
						</Hidden>

						<ItemsWrapper>
							<ConditionalRender
								show={isBuyNowPayLaterInCart || isBNPLOrderHistory}
								onTrue={() => (
									<ConditionalRender
										show={!cartHbbWfbbWithoutOtherItem}
										onTrue={() => (
											<ItemRow divider={true} paddingTop="20" paddingBottom="12">
												<Col lg={6} md={6} sm={6} xs={12}>
													<Row align="center" style={{ height: '100%' }}>
														<Visible xs>
															<SmallItemText>
																{translate('payment.summary.upfront')}
															</SmallItemText>
														</Visible>
														<Hidden xs>
															<ItemText>{translate('payment.summary.upfront')}</ItemText>
														</Hidden>
													</Row>
												</Col>
												<Col lg={3} md={3} sm={3} xs={9}>
													<PriceRow>
														<HeaderText>
															{screenClass.includes('xs')
																? translate('cart.items.one-time')
																: ''}
														</HeaderText>
														<PriceContainer>
															<VatWrapper>
																<PriceTag
																	price={price()}
																	size={screen('sm') ? 'miniregularbold' : 'small'}
																/>
																<SmallVatWrapper
																	isUpfront={true}
																	isSM={screen('sm')}
																	isLG={screen('lg')}
																>
																	<Info color="black54">
																		{translate(
																			'product.cart-card.with-vat',
																			isEbuCart
																				? ebuItem?.vatPercent || 0
																				: vatPercentHighest ?? 0
																		)}
																	</Info>
																	<VatAmountContainer color="black54">
																		{`OMR ${roundMoney(totalPrice())}`}
																	</VatAmountContainer>
																</SmallVatWrapper>
															</VatWrapper>
														</PriceContainer>
													</PriceRow>
												</Col>
												<Hidden xs>
													<Col sm={3} md={3}>
														<PriceRow>
															<HeaderText>
																{screenClass.includes('xs')
																	? translate('cart.items.monthly')
																	: ''}
															</HeaderText>
															<EmptyLine />
														</PriceRow>
													</Col>
												</Hidden>
											</ItemRow>
										)}
									/>
								)}
								onFalse={() => (
									<>
										<ConditionalRender
											show={!cartHbbWfbbWithoutOtherItem}
											onTrue={() => (
												<ItemRow
													divider={!upfrontPaymentOpen && !!_periodPrice}
													paddingTop="20"
													paddingBottom="12"
												>
													<Col lg={6} md={6} sm={6} xs={12}>
														<Row align="center" style={{ height: '100%' }}>
															{upfrontPaymentOpen ? (
																<Icon
																	name="chevronUp"
																	fill="black87"
																	width={CHEVRON_ICON_SIZE}
																	height={CHEVRON_ICON_SIZE}
																	onClick={() => {
																		setUpfrontPaymentOpen(false);
																	}}
																/>
															) : (
																<Icon
																	name="chevronDown"
																	fill="black87"
																	width={CHEVRON_ICON_SIZE}
																	height={CHEVRON_ICON_SIZE}
																	onClick={() => setUpfrontPaymentOpen(true)}
																/>
															)}
															<Visible xs>
																<SmallItemText>
																	{translate(
																		!!_periodPrice
																			? 'payment.summary.upfront'
																			: 'product.card.price'
																	)}
																</SmallItemText>
															</Visible>
															<Hidden xs>
																<ItemText>
																	{translate(
																		!!_periodPrice
																			? 'payment.summary.upfront'
																			: 'product.card.price'
																	)}
																</ItemText>
															</Hidden>
														</Row>
													</Col>
													<Col lg={3} md={3} sm={3} xs={9}>
														<PriceRow>
															<HeaderText>
																{screenClass.includes('xs')
																	? translate('cart.items.one-time')
																	: ''}
															</HeaderText>
															<PriceContainer>
																<VatWrapper>
																	<PriceTag
																		price={price()}
																		size={
																			screen('sm') ? 'miniregularbold' : 'small'
																		}
																	/>
																	<SmallVatWrapper
																		isUpfront={true}
																		isSM={screen('sm')}
																		isLG={screen('lg')}
																	>
																		<Info color="black54">
																			{translate(
																				'product.cart-card.with-vat',
																				isEbuCart
																					? ebuItem?.vatPercent || 0
																					: vatPercentHighest ?? 0
																			)}
																		</Info>
																		<VatAmountContainer
																			color="black54"
																			isMakasib={isMakasibInItems}
																		>
																			{`OMR ${roundMoney(totalPrice())}`}
																		</VatAmountContainer>
																	</SmallVatWrapper>
																	<ConditionalRender
																		show={isMakasibInItems}
																		onTrue={() => (
																			<SmallVatWrapper
																				isSM={screen('sm')}
																				isLG={screen('lg')}
																			>
																				<Info color="primary">
																					{translate(
																						'product.cart-card.after-discount'
																					)}
																				</Info>
																				<VatAmountContainer color="primary">
																					{`OMR ${roundMoney(
																						isEbuCart
																							? ebuItem?.totalAmount
																							: (upfront ??
																									Number(
																										totalProductPrice
																									)) < 0
																							? undiscountableTotalCartValue
																							: (upfront ??
																									Number(
																										totalProductPrice
																									)) <
																							  undiscountableTotalCartValue
																							? undiscountableTotalCartValue
																							: upfront ??
																							  Number(totalProductPrice)
																					)}`}
																				</VatAmountContainer>
																			</SmallVatWrapper>
																		)}
																	/>
																</VatWrapper>
															</PriceContainer>
														</PriceRow>
													</Col>
													<Hidden xs>
														<Col sm={3} md={3}>
															<PriceRow>
																<HeaderText>
																	{screenClass.includes('xs')
																		? translate('cart.items.monthly')
																		: ''}
																</HeaderText>
																<EmptyLine />
															</PriceRow>
														</Col>
													</Hidden>
												</ItemRow>
											)}
										/>
										<ConditionalRender
											show={upfrontPaymentOpen && !cartHbbWfbbWithoutOtherItem}
											onTrue={() => (
												<>
													{items
														?.map((item) => {
															if (
																item.productId !== MAKASIB_POINTS_PRODUCT_ID &&
																item.productId !== MAKASIB_VOUCHER_PRODUCT_ID &&
																item.plan?.PA !== ItemType.DEVICE_INSURANCE_INSTALMENT
															)
																return item;
														})
														.map((item, index) =>
															!!item ? (
																[...Array(item.quantity).keys()].map((_, itemIndex) => (
																	<>
																		<ConditionalRender
																			show={showHbbWfbbInfo(item)}
																			onTrue={() => (
																				<>
																					<CartPaymentSummaryItem
																						isEbuCart={isEbuCart}
																						key={index}
																						item={item}
																						vat={vat(item)}
																						vatPercent={vatPercent(item)}
																					/>
																					<Divider
																						withoutLine
																						marginBottom={-4}
																					/>
																					<Row>
																						<CustomLine />
																					</Row>
																				</>
																			)}
																		/>
																	</>
																))
															) : (
																<></>
															)
														)}

													<ConditionalRender
														show={items?.some(
															(item) => item.productId === MAKASIB_VOUCHER_PRODUCT_ID
														)}
														onTrue={() =>
															items
																.filter(
																	(item) =>
																		item.productId === MAKASIB_VOUCHER_PRODUCT_ID
																)
																.map((item, index) => (
																	<>
																		<CartPaymentSummaryItem
																			isEbuCart={isEbuCart}
																			key={items?.length + 1}
																			item={item}
																			vat={0}
																			single
																			vatPercent={0}
																			isVoucher={true}
																		/>
																		<ConditionalRender
																			show={
																				index + 1 !==
																					items.filter(
																						(item) =>
																							item.productId ===
																							MAKASIB_VOUCHER_PRODUCT_ID
																					).length ||
																				(index + 1 ===
																					items.filter(
																						(item) =>
																							item.productId ===
																							MAKASIB_VOUCHER_PRODUCT_ID
																					).length &&
																					items?.some(
																						(item) =>
																							item.productId ===
																							MAKASIB_POINTS_PRODUCT_ID
																					))
																			}
																			onTrue={() => (
																				<Row>
																					<CustomLine />
																				</Row>
																			)}
																		/>
																	</>
																))
														}
													/>
													<ConditionalRender
														show={items?.some(
															(item) => item.productId === MAKASIB_POINTS_PRODUCT_ID
														)}
														onTrue={() => (
															<>
																<CartPaymentSummaryItem
																	isEbuCart={isEbuCart}
																	key={items?.length + 1}
																	item={
																		items?.find(
																			(item) =>
																				item.productId ===
																				MAKASIB_POINTS_PRODUCT_ID
																		) ?? items[0]
																	}
																	vat={0}
																	single
																	vatPercent={0}
																	isMakasib={true}
																/>
															</>
														)}
													/>
												</>
											)}
										/>
									</>
								)}
							/>
							<ConditionalRender
								show={isBuyNowPayLaterInCart || isBNPLOrderHistory}
								onTrue={() => (
									<>
										<ItemRow paddingTop="24" paddingBottom="8" divider={true}>
											<Col lg={6} md={6} sm={6} xs={12}>
												<Row>
													<Visible xs>
														<SmallItemText>
															{items[0]?.plan?.installmentDuration === '24'
																? translate('product.cart-card.23months')
																: translate('product.cart-card.11months')}
														</SmallItemText>
													</Visible>
													<Hidden xs>
														<ItemText>
															{items[0]?.plan?.installmentDuration === '24'
																? translate('product.cart-card.23months')
																: translate('product.cart-card.11months')}
														</ItemText>
													</Hidden>
												</Row>
											</Col>
											<Hidden xs>
												<Col sm={3} md={3} lg={3}>
													<EmptyLineContainer>
														<EmptyLine />
													</EmptyLineContainer>
												</Col>
											</Hidden>
											<Col sm={3} md={3} lg={3}>
												<PriceRow>
													<HeaderText>
														{screenClass.includes('xs')
															? translate('cart.items.monthly')
															: ''}
													</HeaderText>
													<PriceContainer>
														<VatWrapper>
															<PriceTag
																currencySideLeft={true}
																price={items[0]?.plan?.IV}
																discountColor="black54"
																duration={translate(
																	'select-protection-modal.payment.mo'
																)}
																size={
																	isEbuCart
																		? 'medium'
																		: screen('sm')
																		? 'miniregularbold'
																		: 'small'
																}
															/>
														</VatWrapper>
													</PriceContainer>
												</PriceRow>
											</Col>
										</ItemRow>
										<ItemRow divider={true} paddingTop="24" paddingBottom="8">
											<Col lg={6} md={6} sm={6} xs={12}>
												<Row>
													<Visible xs>
														<SmallItemText>
															{translate('product.cart-card.last')}
														</SmallItemText>
													</Visible>
													<Hidden xs>
														<ItemText>{translate('product.cart-card.last')}</ItemText>
													</Hidden>
												</Row>
											</Col>
											<Hidden xs>
												<Col sm={3} md={3} lg={3}>
													<EmptyLineContainer>
														<EmptyLine />
													</EmptyLineContainer>
												</Col>
											</Hidden>
											<Col sm={3} md={3} lg={3}>
												<PriceRow>
													<HeaderText>
														{screenClass.includes('xs')
															? translate('cart.items.monthly')
															: ''}
													</HeaderText>
													<PriceContainer>
														<VatWrapper>
															<PriceTag
																currencySideLeft={true}
																price={items[0]?.plan?.BNPLFEP ?? 0}
																discountColor="black54"
																duration={translate(
																	'select-protection-modal.payment.mo'
																)}
																size={
																	isEbuCart
																		? 'medium'
																		: screen('sm')
																		? 'miniregularbold'
																		: 'small'
																}
															/>
														</VatWrapper>
													</PriceContainer>
												</PriceRow>
											</Col>
										</ItemRow>
									</>
								)}
							/>

							<ConditionalRender
								show={!!_periodPrice && !isBuyNowPayLaterInCart && !isBNPLOrderHistory}
								onTrue={() => (
									<>
										<ItemRow
											isEbu={isEbuCart}
											paddingTop="24"
											paddingBottom={paymentMonthlyOpen ? '20' : '16'}
										>
											<Col lg={6} md={6} sm={6} xs={12}>
												<Row>
													<ConditionalRender
														show={!isEbuCart}
														onTrue={() => (
															<>
																{paymentMonthlyOpen ? (
																	<Icon
																		name="chevronUp"
																		fill="black87"
																		width={CHEVRON_ICON_SIZE}
																		height={CHEVRON_ICON_SIZE}
																		onClick={() => {
																			setPaymentMonthlyOpen(false);
																		}}
																	/>
																) : (
																	<Icon
																		name="chevronDown"
																		fill="black87"
																		width={CHEVRON_ICON_SIZE}
																		height={CHEVRON_ICON_SIZE}
																		onClick={() => setPaymentMonthlyOpen(true)}
																	/>
																)}
															</>
														)}
													/>
													<Visible xs>
														<SmallItemText>
															{isEbuCart
																? translate('payment.summary.description.installments')
																: twoYearInstallmentValue > 0
																? translate('product.cart-card.for24months')
																: translate('product.cart-card.for12months')}
														</SmallItemText>
													</Visible>
													<Hidden xs>
														<ItemText>
															{isEbuCart
																? translate('payment.summary.description.installments')
																: twoYearInstallmentValue > 0
																? translate('product.cart-card.for24months')
																: translate('product.cart-card.for12months')}
														</ItemText>
													</Hidden>
												</Row>
											</Col>
											<Hidden xs>
												<Col sm={3} md={3} lg={3}>
													<EmptyLineContainer>
														<EmptyLine />
													</EmptyLineContainer>
												</Col>
											</Hidden>
											<Col sm={3} md={3} lg={3}>
												<PriceRow>
													<HeaderText>
														{screenClass.includes('xs')
															? translate('cart.items.monthly')
															: ''}
													</HeaderText>
													<PriceContainer>
														<VatWrapper>
															<PriceTag
																currencySideLeft={true}
																price={
																	isEbuCart
																		? (Number(ebuItem?.standardPrice) || 0) /
																		  (Number(ebuItem?.installmentDuration) || 0)
																		: (_periodPrice ?? 0) -
																		  cartItemsDevicePlanVatSum
																}
																discount={
																	isEbuCart
																		? (Number(ebuItem?.standardPrice) || 0) /
																		  (Number(ebuItem?.installmentDuration) || 0)
																		: 0
																}
																discountColor="black54"
																duration={translate(
																	'select-protection-modal.payment.mo'
																)}
																size={
																	isEbuCart
																		? 'medium'
																		: screen('sm')
																		? 'miniregularbold'
																		: 'small'
																}
															/>
															<ConditionalRender
																show={isEbuCart}
																onTrue={() => (
																	<SmallVatWrapper>
																		<Info color="primary">
																			{translate(
																				'product.cart-card.after-discount'
																			)}
																		</Info>
																		<VatAmountContainer color="primary">
																			{`OMR ${roundMoney(ebuItem?.IV || 0)}`}
																		</VatAmountContainer>
																	</SmallVatWrapper>
																)}
															/>
														</VatWrapper>
													</PriceContainer>
												</PriceRow>

												<ConditionalRender
													show={!isEbuCart}
													onTrue={() => (
														<SmallVatWrapper
															isMonthly={true}
															isMD={screen('md')}
															isSM={screen('sm')}
															isLG={screen('lg')}
														>
															<Info color="black54">
																{translate(
																	'product.cart-card.with-vat',
																	vatPercentHighest ?? 0
																)}
															</Info>
															<InfoBold color="black54">
																{`OMR ${roundMoney(_periodPrice)}`}
															</InfoBold>
														</SmallVatWrapper>
													)}
												/>
											</Col>
										</ItemRow>
										<ConditionalRender
											show={paymentMonthlyOpen}
											onTrue={() => (
												<>
													{items?.map((item, index) => (
														<>
															<ConditionalRender
																show={
																	!!item.plan?.planMonthy ||
																	item.plan?.PA ===
																		ItemType.DEVICE_INSURANCE_INSTALMENT
																}
																onTrue={() => (
																	<>
																		<ConditionalRender
																			show={
																				Number(
																					item.plan?.planTaxablePrice ?? 0
																				) > 0 && showHbbWfbbInfo(item)
																			}
																			onTrue={() => (
																				<CartPaymentSummaryItem
																					isEbuCart={isEbuCart}
																					key={index}
																					single
																					monthly
																					item={item}
																					vat={vat(item)}
																					vatPercent={vatPercent(item)}
																				/>
																			)}
																		/>
																		<ConditionalRender
																			show={
																				item?.plan?.PA !==
																					ItemType.ITEM_ACCESSORY_INSTALMENTS &&
																				item?.plan?.PA !==
																					ItemType.TABLETS_LAPTOPS_INSTALMENTS &&
																				item?.plan?.PA !==
																					ItemType.B2B2C_ITEM_ACCESSORY_BAQATI &&
																				item?.plan?.PA !==
																					ItemType.B2B2C_TABLETS_LAPTOPS_BAQATI &&
																				item?.plan?.PA !==
																					ItemType.B2B_ITEM_ACCESSORY_BAQATI &&
																				item?.plan?.PA !==
																					ItemType.B2B_TABLETS_LAPTOPS_BAQATI
																			}
																			onTrue={() => (
																				<CartPaymentSummaryItem
																					isEbuCart={isEbuCart}
																					key={index}
																					monthly
																					planName={item.plan?.planName}
																					planPrices={{
																						planTaxablePrice:
																							item.plan?.planTaxablePrice,
																						planVatValue:
																							item.plan?.planVatValue,
																						planvatPercent:
																							item.plan?.planVatPercent,
																					}}
																					item={item}
																					vat={vat(item)}
																					vatPercent={vatPercent(item)}
																				/>
																			)}
																		/>
																	</>
																)}
															/>
														</>
													))}
												</>
											)}
										/>
									</>
								)}
							/>
						</ItemsWrapper>
					</>
				)}
			/>
		</CartPaymentSummaryContainer>
	);
};

export default CartPaymentSummary;
