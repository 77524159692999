import styled from 'styled-components';

interface ICardContainerProps {
	elevated?: boolean;
	bordered?: boolean;
	marginBottom?: number;
	marginTop?: number;
	fullHeight: boolean;
	showShadowOnHover?: boolean;
	isXs?: boolean;
}

interface ICardContentProps {
	fullHeight: boolean;
	withoutContainerPadding: boolean;
	overflow?: string;
}

export const CardContainer = styled.div<types.IStyledProps & ICardContainerProps>`
	transition: all 0.05s ease-out;
	&:hover {
		${({ theme, showShadowOnHover }) =>
			showShadowOnHover
				? `transition: all 0.1s ease-in; box-shadow: 0 7px 8px 0 rgba(0, 0, 0, 0.05), 0 5px 22px 4px ${theme.colors.black4}, 0 12px 17px 2px ${theme.colors.black4};`
				: undefined}
	}
	${({ theme, bordered, marginBottom = 0, marginTop = 0, fullHeight, isXs }) => {
		const styles = {
			background: theme.colors.white,
			borderRadius: isXs ? '0px' : '8px',
			flex: fullHeight ? 1 : undefined,
			left: 0,
			marginBottom: marginBottom || 0,
			marginTop: marginTop || 0,
			padding: 0,
			width: '100%',
			zIndex: 5,
		};
		!!bordered &&
			Object.assign(styles, {
				borderWidth: '1px',
				borderColor: theme.colors.black12,
			});
		return styles;
	}}
`;

export const CardContent = styled.div<types.IStyledProps & ICardContentProps>`
	${({ withoutContainerPadding, fullHeight, overflow }) => {
		return {
			borderRadius: '8px',
			flex: fullHeight ? 1 : undefined,
			padding: withoutContainerPadding ? 0 : `16px`,
			overflow: overflow ? overflow : 'hidden',
			display: fullHeight ? 'flex' : undefined,
		};
	}}
`;
