import React, { FunctionComponent, useMemo } from 'react';
import Card from '@Components/Card/Card';
import useScreen from '@Utils/hooks/useScreen';
import { getResponsiveModalStyles } from '@Utils/layout/layoutSupport';
import { ModalContainer } from '@Components/Modal/subComponents/ModalContainer/ModalContiner.styled';

const OTP: FunctionComponent<types.cards.IOTPCardProps['params']> = (params) => {
	const { screenClass } = useScreen();
	const styles = useMemo(() => getResponsiveModalStyles(screenClass), [screenClass]);
	return (
		<ModalContainer styles={styles}>
			<Card<types.cards.IOTPCardProps> card="OTP" params={params} withoutContainerPadding />
		</ModalContainer>
	);
};

export default OTP;
