import Icon from '@Components/Icon/Icon';
import React, { FunctionComponent } from 'react';
import { Line, TitleSeparatorContainer, TitleWrapper } from './TitleSeparator.styled';

interface ITitleSeparatorProps {
	title: string;
	typography?: keyof types.ITypography;
	onClick?: () => void;
	expandAvailable?: boolean;
	expanded?: boolean;
	withLine?: boolean;
	iconExpand?: types.iconNames;
	iconHide?: types.iconNames;
}

const TitleSeparator: FunctionComponent<ITitleSeparatorProps> = ({
	title,
	typography = 'displayM',
	onClick,
	expandAvailable,
	expanded,
	withLine = true,
	iconExpand = 'chevronDown',
	iconHide = 'chevronUp',
}: ITitleSeparatorProps) => {
	return (
		<TitleSeparatorContainer>
			<TitleWrapper typography={typography}>{title}</TitleWrapper>
			{withLine && <Line />}
			{expandAvailable && (
				<Icon name={expanded ? iconHide : iconExpand} width={24} height={24} onClick={onClick} />
			)}
		</TitleSeparatorContainer>
	);
};

export default TitleSeparator;
