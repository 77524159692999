import React, { FunctionComponent } from 'react';
import { SkeletonRow, Rectangular } from '@Components/Skeleton/Skeleton.styled';

const CartItem: FunctionComponent = () => {
	return (
		<SkeletonRow marginTop={24} marginBottom={24}>
			<Rectangular width="100%" height={80} />
		</SkeletonRow>
	);
};

export default CartItem;
