import { AccountType, IAccount } from '@ApiModels/accounts';
import ConditionalRender from '@Components/ConditionalRender/ConditionalRender';
import { SecondaryMediumButtonAuto } from '@Components/controls/Button/Button';
import Icon from '@Components/Icon/Icon';
import { modalTypes, setModal } from '@Redux/modules/modal/actions';
import { AccountsService } from '@Services/accounts/accountsService';
import { OrderFlowService } from '@Services/orderFlow/orderFlow';
import { useCart } from '@Utils/context/CartContext';
import useTranslate from '@Utils/hooks/useTranslate';
import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
	ButtonsContainer,
	ButtonUnderline,
	GetESIMContainer,
	SubtitleWrapper,
	TitleColumn,
	TitleContainer,
	TitleWrapper,
} from './GetESIM.styled';
import { ICartItem } from '@ApiModels/viewCart';

interface IGetESIM {
	orderItemId: string;
	isOrderHistoryView?: boolean;
	summaryView?: boolean;
	cartItem?: ICartItem;
}

const ICON_SIZE = 48;

const GetESIM: FunctionComponent<IGetESIM> = ({ orderItemId, isOrderHistoryView, summaryView, cartItem }) => {
	const { translate } = useTranslate();
	const dispatch = useDispatch();
	const [accounts, setAccounts] = useState<IAccount[]>([]);
	const {
		cart: { items },
	} = useCart();

	const { selectedNumber, transfer } = useMemo(() => {
		if (cartItem?.plan?.esimNo) {
			return { selectedNumber: cartItem?.plan?.esimNo, transfer: cartItem?.plan?.esimAction === 'TRANSFER' };
		}
		const item = items.find(({ orderItemId: orderId }) => orderItemId === orderId);
		return { selectedNumber: item?.esimNo, transfer: item?.esimAction === 'TRANSFER' };
	}, [items, cartItem]);

	useEffect(() => {
		AccountsService.getAccountsForLoggedUser().subscribe(
			({ accounts }) => {
				setAccounts(accounts.filter((acc) => acc.accountType === AccountType.MOBILE));
			},
			() => {}
		);
	}, []);

	const onRemove = () => {
		if (selectedNumber) {
			OrderFlowService.manageOrderItemESim({
				orderItemId,
				esimAction: 'DELETE',
				esimNo: selectedNumber,
			}).subscribe(
				() => {},
				() => {}
			);
		}
	};

	const openModal = () => {
		dispatch(setModal({ type: modalTypes.SELECT_NUMBER, data: { orderItemId, accounts } }));
	};

	return (
		<ConditionalRender
			show={!!cartItem?.plan?.esimNo || (!isOrderHistoryView && !summaryView)}
			onTrue={() => (
				<GetESIMContainer>
					<TitleContainer>
						<Icon
							name={isOrderHistoryView ? 'eSim' : 'transferSim'}
							width={ICON_SIZE}
							height={ICON_SIZE}
							fill="black38"
						/>
						<TitleColumn>
							<TitleWrapper>
								{!selectedNumber ? translate('get-esim.title') : `+968 ${selectedNumber}`}
							</TitleWrapper>
							<ConditionalRender
								show={!!selectedNumber}
								onTrue={() => (
									<>
										<SubtitleWrapper>
											{isOrderHistoryView
												? translate(
														transfer
															? 'get-esim.subtitle.transfer.order.history'
															: 'get-esim.subtitle.order.history'
												  )
												: translate(
														transfer ? 'get-esim.subtitle.transfer' : 'get-esim.subtitle'
												  )}
										</SubtitleWrapper>
										<ConditionalRender
											show={!isOrderHistoryView && !summaryView}
											onTrue={() => (
												<ButtonsContainer>
													<ConditionalRender
														show={!!accounts.length}
														onTrue={() => (
															<ButtonUnderline onClick={openModal}>
																{translate('get-esim.button.change.number')}
															</ButtonUnderline>
														)}
													/>
													<ButtonUnderline onClick={onRemove}>
														{translate('get-esim.button.remove.number')}
													</ButtonUnderline>
												</ButtonsContainer>
											)}
										/>
									</>
								)}
							/>
						</TitleColumn>
					</TitleContainer>
					<ConditionalRender
						show={!selectedNumber}
						onTrue={() => (
							<SecondaryMediumButtonAuto
								onClick={openModal}
								uniqueId="esim.select-number"
								underlayColor="grey100"
							>
								{translate('get-esim.button.select')}
							</SecondaryMediumButtonAuto>
						)}
					/>
				</GetESIMContainer>
			)}
		/>
	);
};

export default GetESIM;
