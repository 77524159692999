import { DocumentState } from '@Components/Card/cards/DocumentDetails/DocumentDetails';
import { IFileParams } from '@Components/DragAndDrop/DragAndDrop';
import { WfbbTypes } from '@Utils/converters/customerDetailsFromGIS';
import { Action, ActionFunction1, createAction } from 'redux-actions';

export interface IHbbWfbbProcessCheckout {
	plansSelectedStr?: string;
	requestChannel?: string;
	staffRemarks?: string;
	operationContext?: string;
	firstName?: string;
	lastName?: string;
	title?: string;
	networkType?: string;
	region?: string;
	landmark?: string;
	bandwidth?: string;
	cabinet?: string;
	contactNumber1?: string;
	contactNumber2?: string;
	dp?: string;
	emailId?: string;
	exe?: string;
	area?: string;
	swatSOSStatus?: string;
	gisDetails?: string;
	latitude?: string;
	longitude?: string;
	plansSelected?: string;
	willyat?: string;
	wayNumber?: string;
	buildingNumber?: string;
	customerName?: string;
	customerId?: string;
	customerIdType?: DocumentState;
	expiryDate?: string;
	orderId?: string;
	customerType?: string;
	documentScan?: { base64frontSide: IFileParams | null; base64backSide: IFileParams | null };
	requestType?: string;
	partNumber?: string;
	// additional wfbb properties below
	wfbbType?: WfbbTypes;
	middleName?: string;
	gender?: string;
	language?: string;
	birthDate?: string;
	fatherName?: string;
	grandfatherName?: string;
}
export interface ITelesalesInformation {
	phoneNumber?: string;
	contactNumber?: string;
	email?: string;
	orderId?: string;
	saveInProgress?: boolean;
}

export interface IPrepaidProcessCheckout {
	customerId?: string;
	customerIdType?: DocumentState;
	customerType?: string;
	orderItemId?: string;
}

export interface IPersistentCheckoutData {
	payload: {
		hbbWfbbProcessCheckout?: IHbbWfbbProcessCheckout;
		telesalesInformation?: ITelesalesInformation;
		prepaidProcessCheckout?: IPrepaidProcessCheckout;
	};
}

export const setPersistentCheckoutDetails: ActionFunction1<
	IPersistentCheckoutData['payload'],
	Action<IPersistentCheckoutData['payload']>
> = createAction('SET_PERSISTEN_CHECKOUT_DETAILS', (action: IPersistentCheckoutData['payload']) => action);
export const clearPersistentCheckoutDetails = createAction('CLEAR_PERSISTENT_CHECKOUT_DETAILS');
export const clearTelesalesInformation = createAction('CLEAR_TELESALES_INFORMATION');
