import ConditionalRender from '@Components/ConditionalRender/ConditionalRender';
import {
	PrimaryLargeButtonFlex,
	TertiaryLargeButtonFlex,
	TertiaryMediumButtonAuto,
} from '@Components/controls/Button/Button';
import Checkbox from '@Components/controls/Checkbox/Checkbox';
import { CheckboxShape } from '@Components/controls/Checkbox/Checkbox.styled';
import Divider from '@Components/Divider/Divider';
import Icon from '@Components/Icon/Icon';
import OtpInputs from '@Components/OtpInputs/OtpInputs';
import { ParagraphS } from '@Components/Typography/Typography.styled';
import { addBusyIndicator, removeBusyIndicator } from '@Redux/modules/busyIndicator/actions';
import { setModal } from '@Redux/modules/modal/actions';
import { OrderFlowService } from '@Services/orderFlow/orderFlow';
import { ProductsService } from '@Services/productsService/productsService';
import { getTimerFormat } from '@Utils/date/moment';
import externalLinks from '@Utils/externalLinks/externalLinks';
import useOtpTimer from '@Utils/hooks/useOtpTimer';
import useScreen from '@Utils/hooks/useScreen';
import useTranslate from '@Utils/hooks/useTranslate';
import { Formik, FormikProps } from 'formik';
import React, { FunctionComponent, RefObject, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { AjaxError } from 'rxjs/ajax';
import {
	BackgroundContainer,
	ButtonsContainer,
	HeaderContainer,
	Horizontal,
	IconButton,
	OTPCardContainer,
	OTPInputsContainer,
	ResendButtonContainer,
	SubheaderBoldWrapper,
	SubheaderContainer,
	SubheaderWrapper,
	TermsButton,
	TermsContainer,
	TermsText,
	TitleWrapper,
} from './OTP.styled';

export interface IOtpFormikFields {
	otp: string[];
}

const DEFAULT_TIME = 90000;
const inputs: RefObject<HTMLInputElement>[] = [];

const CONFIRM_OTP_BI = 'confirmOTP';

const OTP: FunctionComponent<types.cards.IOTPCardProps> = ({
	params: {
		msisdn,
		onClickResend,
		onConfirm,
		operationId,
		isMakasib,
		orderId,
		onConfirmError,
		onConfirmSuccess,
		handleSetOrder,
		setOrderInProgress,
	},
}) => {
	const { screen } = useScreen();
	const isXS = screen('xs');
	const dispatch = useDispatch();
	const [otpArrayCode] = useState<string[]>([]);
	const otpRef = useRef<FormikProps<IOtpFormikFields>>(null);
	const [disabled, setDisabled] = useState(false);
	const [termsAccepted, setTermsAccepted] = useState(false);
	const { translate } = useTranslate();

	const createRefs = (numberOfInputs: number) => {
		for (let index = 0; index < numberOfInputs; index++) {
			inputs[index] = React.createRef();
		}
		return inputs;
	};

	const {
		timer: { time, activated },
		setTimer,
	} = useOtpTimer();

	useEffect(() => {
		setTimer({ activated: true, time: DEFAULT_TIME });
		return () => {
			setTimer({ time: 0, activated: false });
		};
	}, []);

	const confirm = () => {
		const token = String(otpRef.current?.values.otp ?? '').replace(/,/g, '');
		if (token.length === 5) {
			if (!!isMakasib) {
				dispatch(addBusyIndicator(CONFIRM_OTP_BI));
				setDisabled(true);
				OrderFlowService.validateMakasibOtp(orderId ?? '', {
					msisdn,
					token: String(otpRef.current?.values.otp ?? '').replace(/,/g, ''),
				}).subscribe(
					(r) => {
						if (r.validate && !!handleSetOrder && !!setOrderInProgress) {
							dispatch(setModal({ closeAllModals: true, type: null }));
							setOrderInProgress(true);
							handleSetOrder();
						} else {
							otpRef.current?.setFieldError('otp', translate('otp-card.error'));
						}
						setDisabled(false);
						dispatch(removeBusyIndicator(CONFIRM_OTP_BI));
					},
					() => {
						setDisabled(false);
						dispatch(removeBusyIndicator(CONFIRM_OTP_BI));
					}
				);
			} else {
				dispatch(addBusyIndicator(CONFIRM_OTP_BI));
				setDisabled(true);
				ProductsService.validateOTP({
					token: String(otpRef.current?.values.otp ?? '').replace(/,/g, ''),
					operationId,
				}).subscribe(
					async ({ isOK }) => {
						if (onConfirm && isOK) {
							try {
								await onConfirm.toPromise();
								onConfirmSuccess?.();
							} catch (e) {
								if (e instanceof AjaxError) {
									onConfirmError?.(e);
								}
							}
						} else if (!isOK) {
							otpRef.current?.setFieldError('otp', translate('otp-card.error'));
						}
						setDisabled(false);
						dispatch(removeBusyIndicator(CONFIRM_OTP_BI));
					},
					() => {
						setDisabled(false);
						dispatch(removeBusyIndicator(CONFIRM_OTP_BI));
					}
				);
			}
		} else {
			otpRef.current?.setFieldError('otp', translate('otp-card.error'));
		}
	};

	const closeIconSize = isXS ? 24 : 40;

	return (
		<OTPCardContainer isXS={isXS}>
			<BackgroundContainer>
				<Icon name="background" width="100%" height={400} />
			</BackgroundContainer>
			<HeaderContainer>
				<Icon name="omantelLogo" width={80} height={40} />
				<IconButton
					onClick={() => {
						dispatch(setModal({ closeAllModals: true, type: null }));
					}}
				>
					<Icon name="close" width={closeIconSize} height={closeIconSize} fill="black87" />
				</IconButton>
			</HeaderContainer>
			<TitleWrapper>{translate('otp-card.title')}</TitleWrapper>
			<SubheaderContainer>
				<SubheaderWrapper>
					{translate(!!isMakasib ? 'makasib-otp-card.subheader-1' : 'otp-card.subheader-1')}
				</SubheaderWrapper>
				<SubheaderBoldWrapper>
					{translate(!!isMakasib ? 'makasib-otp-card.subheader-2' : 'otp-card.subheader-2')}
				</SubheaderBoldWrapper>
				<SubheaderWrapper>
					{translate(!!isMakasib ? 'makasib-otp-card.subheader-3' : 'otp-card.subheader-3')}
				</SubheaderWrapper>
				<SubheaderBoldWrapper>{`***${msisdn.slice(5)}`}.</SubheaderBoldWrapper>
			</SubheaderContainer>
			<SubheaderContainer>
				<SubheaderWrapper>{translate('otp-card.otp-title')}</SubheaderWrapper>
			</SubheaderContainer>
			<OTPInputsContainer>
				<Formik<IOtpFormikFields>
					initialValues={{ otp: otpArrayCode }}
					onSubmit={() => {
						confirm();
					}}
					innerRef={otpRef}
				>
					{({ setFieldValue, values, errors }) => (
						<>
							<OtpInputs
								otpArrayCode={values.otp}
								inputRefs={createRefs(5)}
								onChange={(value) => {
									setFieldValue('otp', value.split(','));
								}}
								error={!!errors.otp}
								disabled={disabled}
							/>
							<ConditionalRender
								show={!!errors.otp}
								onTrue={() => (
									<>
										<Horizontal>
											<>
												<Icon name="warning" fill="warning" width={24} height={24} />
												<ParagraphS color="warning">{errors.otp}</ParagraphS>
											</>
										</Horizontal>
									</>
								)}
								onFalse={() => <Divider marginBottom={54} withoutLine />}
							/>
						</>
					)}
				</Formik>
			</OTPInputsContainer>
			<SubheaderContainer>
				<SubheaderWrapper>{translate('otp-card.valid')}</SubheaderWrapper>
				<SubheaderBoldWrapper>{getTimerFormat(time)}.</SubheaderBoldWrapper>
			</SubheaderContainer>
			<ResendButtonContainer>
				<TertiaryMediumButtonAuto
					uniqueId="otp-card.resend"
					onClick={async () => {
						if (onClickResend) {
							try {
								await onClickResend.toPromise();
								setTimer({ time: DEFAULT_TIME, activated: true });
							} catch {}
						}
					}}
				>
					{translate('otp-card.resend')}
				</TertiaryMediumButtonAuto>
			</ResendButtonContainer>
			<TermsContainer>
				<Checkbox
					shape={CheckboxShape.SQUARE}
					size={22}
					active={termsAccepted}
					onChange={() => {
						setTermsAccepted(!termsAccepted);
					}}
				/>
				<TermsText>
					{translate('otp-card.terms-1')}
					<TermsButton
						onClick={() => {
							window.open(externalLinks('PLAN_TERMS'));
						}}
					>
						{translate('otp-card.terms-2')}
					</TermsButton>
				</TermsText>
			</TermsContainer>
			<ButtonsContainer>
				<PrimaryLargeButtonFlex
					uniqueId="otp-card.confirm"
					onClick={() => {
						confirm();
					}}
					listener={CONFIRM_OTP_BI}
					disabled={!termsAccepted || time === 0}
				>
					{translate('otp-card.confirm')}
				</PrimaryLargeButtonFlex>
				<TertiaryLargeButtonFlex
					uniqueId="otp-card.cancel"
					onClick={() => {
						dispatch(setModal({ closeAllModals: true, type: null }));
					}}
					listener={CONFIRM_OTP_BI}
				>
					{translate('otp-card.cancel')}
				</TertiaryLargeButtonFlex>
			</ButtonsContainer>
		</OTPCardContainer>
	);
};

export default OTP;
