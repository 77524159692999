import React, { FunctionComponent, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	ButtonWrapper,
	ContentContainer,
	RadioButtonsWrapper,
	Title,
	ButtonTextWrapper,
} from '@Components/NotifyMe/NotifyMe.styled';
import Divider from '@Components/Divider/Divider';
import useTranslate from '@Utils/hooks/useTranslate';
import TextField from '@Components/controls/TextField/TextField';
import RadioButton from '@Components/controls/RadioButton/RadioButton';
import { PrimaryLargeButtonFixed } from '@Components/controls/Button/Button';
import { addBusyIndicator, removeBusyIndicator } from '@Redux/modules/busyIndicator/actions';
import { modalTypes, setModal } from '@Redux/modules/modal/actions';
import { OrderFlowService } from '@Services/orderFlow/orderFlow';
import { Formik, FormikProps } from 'formik';
import notifyMeValidator from '@Utils/validators/notifyMeValidator';
import { CUSTOMER_CARE_PHONE, CUSTOMER_B2B2C_CARE_PHONE } from '@Config/app.config';

interface IFormikFields {
	fullName: string;
	contact: string;
}

enum NotifyType {
	EMAIL = 'EMAIL',
	SMS = 'SMS',
}

const NOTIFY_ME_BI = 'NOTIFY_ME_BI';

const NotifyMe: FunctionComponent = () => {
	const [notifyType, setNotifyType] = useState<NotifyType>(NotifyType.EMAIL);
	const dispatch = useDispatch();
	const selectedProduct = useSelector((state: types.redux.IState) => state.selectedItems?.details);
	const formikRef = useRef<FormikProps<IFormikFields>>(null);
	const isB2b = useSelector((state: types.redux.IState) => state.api.authentication.isB2b);
	const isB2b2c = useSelector((state: types.redux.IState) => state.api.authentication.isB2b2c);

	const { translate } = useTranslate();

	const handleNotifyService = ({ contact, fullName }: { contact: string; fullName: string }) => {
		dispatch(addBusyIndicator(NOTIFY_ME_BI));
		const data = notifyType === NotifyType.EMAIL ? { email: contact } : { phoneNumber: contact };
		OrderFlowService.createNotificationRequest({
			fullName,
			partNumber: selectedProduct?.partNumber ?? '',
			...data,
		}).subscribe(
			({ isSuccess }) => {
				if (isSuccess) {
					dispatch(setModal({ type: modalTypes.NOTIFY_AVAILABILITY, data }));
					formikRef.current?.resetForm();
				}
				dispatch(removeBusyIndicator(NOTIFY_ME_BI));
			},
			({ response }) => {
				const requestExists = response?.errors?.[0]?.errorParameters?.[0] === 'request exists';
				dispatch(
					setModal({
						type: modalTypes.GENERIC_ERROR,
						data: {
							icon: 'warning',
							iconFill: 'primary',
							title: translate(
								requestExists ? 'notify-me.error-subscribed.title' : 'notify-me.error.title'
							),
							description: translate(
								requestExists ? 'notify-me.error-subscribed.subtitle' : 'notify-me.error.subtitle',
								isB2b2c || isB2b ? CUSTOMER_B2B2C_CARE_PHONE : CUSTOMER_CARE_PHONE
							),
						},
					})
				);
				dispatch(removeBusyIndicator(NOTIFY_ME_BI));
			}
		);
	};

	if (!selectedProduct) {
		return null;
	}

	return (
		<ContentContainer>
			<Formik<IFormikFields>
				validationSchema={() => notifyMeValidator(notifyType === NotifyType.EMAIL)}
				initialValues={{ contact: '', fullName: '' }}
				onSubmit={(values) => {
					handleNotifyService(values);
				}}
				innerRef={formikRef}
			>
				{({ values: { contact, fullName }, setFieldValue, handleSubmit, errors, touched }) => (
					<>
						<Divider marginBottom={10} withoutLine />
						<Title>{translate('notify.me.when.available')}</Title>
						<TextField
							id="1"
							label={translate('notify.me.label.full.name')}
							value={fullName}
							onChange={(value) => {
								setFieldValue('fullName', value);
							}}
							width="100%"
							message={!!errors.fullName && touched.fullName ? errors.fullName : ''}
							error={!!errors.fullName && touched.fullName}
						/>
						<RadioButtonsWrapper>
							<RadioButton
								label={translate('notify.me.radio.button.email')}
								selected={notifyType}
								onClick={() => {
									setNotifyType(NotifyType.EMAIL);
									setFieldValue('contact', '');
								}}
								name="radio"
								value={NotifyType.EMAIL}
								marginRight={16}
							/>
							<RadioButton
								label={translate('notify.me.radio.button.sms')}
								selected={notifyType}
								onClick={() => {
									setNotifyType(NotifyType.SMS);
									setFieldValue('contact', '');
								}}
								name="radio"
								value={NotifyType.SMS}
								marginRight={16}
							/>
						</RadioButtonsWrapper>
						<TextField
							id="2"
							label={translate(`notify.me.label.${notifyType}`)}
							value={contact}
							onChange={(value) => {
								setFieldValue('contact', value);
							}}
							width="100%"
							message={!!errors.contact && touched.contact ? errors.contact : ''}
							error={!!errors.contact && touched.contact}
						/>
						<Divider marginBottom={24} marginTop={24} />

						<ButtonWrapper>
							<PrimaryLargeButtonFixed
								uniqueId="notify.me.button"
								listener={NOTIFY_ME_BI}
								onClick={handleSubmit}
							>
								<ButtonTextWrapper>{translate('notify.me.button')}</ButtonTextWrapper>
							</PrimaryLargeButtonFixed>
						</ButtonWrapper>
					</>
				)}
			</Formik>
		</ContentContainer>
	);
};

export default NotifyMe;
