import { BodyS, ParagraphS, TitleBold } from '@Components/Typography/Typography.styled';
import styled from 'styled-components';
import { matchScreen, paddingRight } from '@Utils/layout/layoutSupport';
import { Container } from 'react-grid-system';

export const DeliveryAddressCardContainer = styled.div`
	background: white;
	width: 100%;
	${() =>
		matchScreen('xs') && {
			height: '100%',
		}}
`;

export const DeliveryAddressCardTitle = styled(TitleBold)`
	${({ theme }) => ({ color: theme.colors.black87 })}
`;

export const DeliveryAddressHeader = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	margin-top: 16px;
`;

export const MissingAreaReminderWrapper = styled(BodyS)`
	max-width: 80%;
	margin-bottom: 16px;
`;

export const AreaInformationColumn = styled.div`
	margin-top: 16px;
	display: flex;
	flex-direction: column;
	margin-bottom: -6px;
`;

export const SingleInformationWrapper = styled.div`
	margin-top: 6px;
`;

export const DeliveryAddressBottom = styled.div<{ isIncorrectArea: boolean }>`
	display: flex;
	flex-direction: row;
	margin-top: 16px;
	${({ isIncorrectArea }) => ({
		justifyContent: isIncorrectArea ? 'flex-end' : 'space-between',
	})};
	${() =>
		matchScreen('xs') && {
			position: 'fixed',
			bottom: '0',
			width: 'calc(100% - 32px)',
			paddingBottom: '16px',
		}}
`;

export const MapElement = styled.div<{ fullMap: boolean; isIncorrectArea?: boolean }>`
	height: 100%;
	transition: all 0.3s ease-in-out;
	${({ fullMap, isIncorrectArea }) => {
		const isSm = matchScreen('sm');
		const isXs = matchScreen('xs');
		const isMd = matchScreen('md');

		if (fullMap) {
			return {
				width: '100%',
			};
		} else if (isXs && !fullMap && !isIncorrectArea) {
			return {
				width: 0,
			};
		} else if (isSm && !fullMap) {
			return {
				width: 'calc(100% - 348px)',
			};
		} else if (isXs && !!isIncorrectArea) {
			return {
				width: '100%',
				marginTop: '16px',
			};
		} else if (isMd) {
			return {
				width: '46%',
			};
		} else {
			return {
				width: '50%',
			};
		}
	}}
`;

export const MapDescription = styled(ParagraphS)`
	${({ theme }) => ({ color: theme.colors.black })}
	height: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
`;

export const ButtonWrapper = styled.div`
	margin: 0 -15px;
`;

export const TextFieldsContainer = styled.div<{ fullMap: boolean; isIncorrectArea?: boolean }>`
	position: absolute;
	transition: all 0.1s ease-in-out;
	height: calc(100% - 170px);
	overflow-y: scroll;
	${({ fullMap }) => ({
		opacity: fullMap ? 0 : 1,
		transitionDelay: fullMap ? '0' : '0.2s',
		zIndex: fullMap ? -1 : 11,
	})};
	${(isIncorrectArea) =>
		!!isIncorrectArea && {
			left: '54%',
		}}
	${(isIncorrectArea) =>
		!isIncorrectArea && {
			right: '16px',
		}}
	${(isIncorrectArea) =>
		!!isIncorrectArea &&
		matchScreen('md') && {
			left: '50%',
		}}
	${(isIncorrectArea) =>
		matchScreen('xs') &&
		!isIncorrectArea && {
			right: '50%',
			transform: 'translateX(50%)',
			paddingLeft: '20px',
		}}
		${(isIncorrectArea) =>
		matchScreen('xs') &&
		!!isIncorrectArea && {
			left: '6px',
		}}
			${() =>
		matchScreen('sm') && {
			left: '32%',
		}}
	max-width: 100%;
	${() => paddingRight(10)}
	// Firefox
	${({ theme }) => ({
		scrollbarWidth: 'thin',
		scrollbarColor: `${theme.colors.grey300} transparent`,
	})}

	// Chrome, Opera, Etc.
	&::-webkit-scrollbar-thumb {
		border-radius: 12px;
		transition: all 0.2s ease-in-out;
		opacity: 1;
		box-shadow: inset 0 0 10px 10px rgb(0 0 0 / 12%);
		border: solid 4px transparent;
	}
`;

export const ContentContainer = styled.div`
	display: flex;
	flex-direction: row;
	${() =>
		!matchScreen('xs') && {
			height: 'calc(100vh - 170px)',
			maxHeight: '50vh',
		}};
	${() =>
		matchScreen('xs') && {
			height: 'calc( 100vh - 310px )',
		}};
`;

export const MapButton = styled.div`
	width: 48px;
	height: 48px;
	border-radius: 4px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	box-shadow: 0 7px 8px 0 rgba(0, 0, 0, 0.05), 0 5px 22px 4px rgba(0, 0, 0, 0.04), 0 12px 17px 2px rgba(0, 0, 0, 0.04);
	cursor: pointer;
	${({ theme }) => ({ backgroundColor: theme.colors.white })}
	margin-left: 8px;
`;

export const ZoomContainer = styled.div<{ fullMap: boolean }>`
	position: absolute;
	bottom: 88px;
	right: 32px;
	z-index: 100;
	transition: all 0.2s ease-in-out;
	${({ fullMap }) => ({
		opacity: fullMap ? 1 : 0,
		transitionDelay: !fullMap ? '0s' : '0.2s',
		transitionDuration: !fullMap ? '0s' : '0.3s',
	})}
`;

export const CurrentPositionButtonContainer = styled.div<{ fullMap: boolean }>`
	position: absolute;
	top: 112px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	right: 32px;
	left: 32px;
	z-index: 10;
	transition: all 0.2s ease-in-out;
	${({ fullMap }) => ({
		opacity: fullMap ? 1 : 0,
		transitionDelay: !fullMap ? '0s' : '0.2s',
		transitionDuration: !fullMap ? '0s' : '0.3s',
	})}
`;

export const MapContainerElement = styled.div<{ height?: number }>`
	${({ height }) => ({
		height: matchScreen('xs') ? '60vh' : height || '100%',
		maxHeight: matchScreen('xs') ? 'unset' : '80vh',
	})}
	width: 100%;
	z-index: 2;
	direction: ltr;
`;

export const FullMapContainer = styled(Container)`
	margin: 0;
	width: 100%;
`;
