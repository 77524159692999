export default function (inputString: string): number {
	inputString = JSON.stringify(inputString) || '';
	let hash = 0;
	let chr;

	if (!inputString || !inputString.length) {
		return hash;
	}

	for (let i = 0; i < inputString.length; i++) {
		chr = inputString.charCodeAt(i);
		hash = (hash << 5) - hash + chr;
		hash |= 0; // Convert to 32bit integer
	}
	return hash;
}
