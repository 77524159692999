import { PlanBarType } from '@Components/PlanBar/PlanBarType';
import { BodySBold, Caption, Label, TitleBold } from '@Components/Typography/Typography.styled';
import { isRTL, matchScreen } from '@Utils/layout/layoutSupport';
import styled from 'styled-components';

interface IPlanComparisonProps {
	padding?: number;
	planType?: PlanBarType;
	menuMargin?: number;
	dataFill?: number;
	currentPlanIsPrepaid?: boolean;
	hbbWfbbProcess?: boolean;
	height?: number;
}

interface IPlanIndexProps {
	index: number;
	max: number;
	hbbWfbbProcess?: boolean;
}
export const Unlimited = styled.div``;

export const PlansWrapper = styled.div``;

export const PlanWrapper = styled.div<IPlanIndexProps>`
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	${({ hbbWfbbProcess }) => ({ minWidth: hbbWfbbProcess ? (matchScreen('xs') ? 260 : 170) : 'unset' })}
	${({ index, max }) =>
		isRTL()
			? {
					marginRight: index > 0 ? '-1px' : 'none',
					borderBottomRightRadius: index === 0 ? '8px' : 'none',
					borderBottomLeftRadius: index === max - 1 ? '8px' : 'none',
			  }
			: {
					marginLeft: index > 0 ? '-1px' : 'none',
					borderBottomLeftRadius: index === 0 ? '8px' : 'none',
					borderBottomRightRadius: index === max - 1 ? '8px' : 'none',
			  }}
	overflow: hidden;
`;

export const PlanHeader = styled.div<IPlanComparisonProps>`
	${({ currentPlanIsPrepaid, hbbWfbbProcess }) => ({
		paddingTop: hbbWfbbProcess ? 12 : currentPlanIsPrepaid ? 36 : 16,
		display: hbbWfbbProcess ? 'flex' : 'unset',
		justifyContent: hbbWfbbProcess ? 'center' : 'unset',
		flexDirection: hbbWfbbProcess ? 'column' : 'unset',
		alignItems: hbbWfbbProcess ? 'center' : 'unset',
	})}
	padding-bottom: 12px;
	text-align: center;
	height: 96px;
	box-sizing: border-box;
	${({ planType, currentPlanIsPrepaid, theme }) => {
		switch (planType) {
			case PlanBarType.BAQATI:
				return currentPlanIsPrepaid
					? {
							backgroundImage: `linear-gradient(to top, ${theme.colors.warning}, ${theme.colors.warningLight})`,
							color: theme.colors.white,
					  }
					: {
							backgroundImage: `linear-gradient(to top, ${theme.colors.support2}, ${theme.colors.support4})`,
							color: theme.colors.white,
					  };
			case PlanBarType.BAQATI_AL_UFUQ:
				return {
					backgroundImage: `linear-gradient(to top, ${theme.colors.black87}, ${theme.colors.support3})`,
					color: theme.colors.white,
				};
			case PlanBarType.UNLIMITED:
				return {
					backgroundImage: `linear-gradient(to top, ${theme.colors.support1}, ${theme.colors.support5})`,
					color: theme.colors.white,
				};
			default:
				return {};
		}
	}}
`;

export const PlanHeaderTitle = styled(TitleBold)<{ hbbWfbbProcess?: boolean }>`
	color: inherit;
	text-overflow: ellipsis;
	overflow: hidden;
	padding-left: 16px;
	padding-right: 16px;
	${({ hbbWfbbProcess }) => ({ whiteSpace: hbbWfbbProcess ? 'break-spaces' : 'nowrap' })}
`;

export const PlanHeaderCommitment = styled(Caption)`
	${({ theme }) => ({
		color: theme.colors.white70,
	})}
`;

export const PlanHeaderType = styled(Label)`
	display: inline-block;
	padding: 2px 4px;
	align-items: center;
	border-radius: 4px;
	margin-top: 8px;
	${({ theme }) => ({
		color: theme.colors.support3,
		backgroundColor: theme.colors.white,
	})}
`;

export const PlanItems = styled.div<IPlanComparisonProps>`
	padding: 0 16px;
	background-color: white;
	${({ theme }) => ({
		border: matchScreen('xs') ? 'none' : `1px solid ${theme.colors.black12}`,
	})}
`;

export const PlanItem = styled.div<IPlanComparisonProps>`
	:not(:last-child) {
		${({ theme }) => ({
			borderBottom: matchScreen('xs') ? 'none' : `1px solid ${theme.colors.black12}`,
		})}
	}
	${({ padding }) => ({
		padding: `${padding ?? '16'}px 0`,
	})}
	${({ padding }) => ({
		paddingTop: matchScreen('xs') ? 44 : `${padding ?? '12'}px 0`,
	})}
`;

export const PlanItemValue = styled.div<IPlanComparisonProps>`
	height: 32px;
	display: flex;
	${({ currentPlanIsPrepaid, padding }) => ({
		alignItems: currentPlanIsPrepaid ? 'flex-end' : 'flex-start',
		gap: currentPlanIsPrepaid ? 5 : 0,
		paddingTop: matchScreen('xs') ? 0 : padding ?? 0,
		paddingBottom: matchScreen('xs') ? 16 : padding ?? 0,
	})}
	justify-content: center;
`;

export const PlanDataItem = styled.div<{ padding?: number }>`
	${({ theme }) => ({
		borderBottom: matchScreen('xs') ? 'none' : `0px solid ${theme.colors.black12}`,
	})}
	${({ padding }) => ({
		paddingTop: matchScreen('xs') ? 0 : padding ?? 0,
		paddingBottom: matchScreen('xs') ? 16 : padding ?? 0,
	})}
	${({ theme }) => ({
		borderBottom: matchScreen('xs') ? 'none' : `1px solid ${theme.colors.black12}`,
	})}
`;

export const PlanDataItemHBB = styled.div<{ withCaption?: boolean; margin?: string; height?: string }>`
	${({ theme, margin, height }) => ({
		margin,
		height,
		borderBottom: matchScreen('xs') ? 'none' : `0px solid ${theme.colors.black12}`,
	})}
	${({ withCaption }) => ({
		paddingTop: matchScreen('xs') ? 0 : withCaption ? 5 : 0,
		paddingBottom: matchScreen('xs') ? 0 : withCaption ? 6 : 0,
	})}
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
`;

export const LocalAndGcc = styled.div`
	height: 30px;
	display: flex;
	align-items: center;
	justify-content: center;
`;

export const Progress = styled.div`
	margin-top: 2px;
	width: 100%;
	border-radius: 4px;
	height: 4px;
	display: flex;
	overflow: hidden;
`;

export const ProgressFill = styled.div<IPlanComparisonProps>`
	${({ theme, dataFill }) => ({
		backgroundColor: theme.colors.primary,
		width: `${dataFill}%`,
	})}
`;

export const ProgressEmpty = styled.div<IPlanComparisonProps>`
	${({ theme, dataFill }) => ({
		backgroundColor: theme.colors.black6,
		width: `${dataFill}%`,
	})}
`;

export const DurationWrapper = styled.div`
	& > * {
		text-transform: uppercase;
	}
`;

export const Empty = styled.div`
	height: 32px;
	display: flex;
	align-items: center;
	justify-content: center;
`;

export const EmptyLine = styled.div`
	width: 16px;
	height: 2px;
	border-radius: 2px;
	display: inline-block;
	${({ theme }) => ({
		backgroundColor: theme.colors.black38,
	})}
`;

export const StoreCredit = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
`;

export const StoreCreditItem = styled.div`
	border-radius: 8px;
	${({ theme }) => ({
		border: `1px solid ${theme.colors.primary}`,
	})}
	display: flex;
	align-items: flex-start;
	justify-content: center;
	padding: 6px 8px 0 8px;
`;

export const PlanButton = styled.div``;

export const ButtonWrapper = styled.div`
	${({ theme }) => ({
		border: `1px solid ${theme.colors.black12}`,
	})}
	border-top: none;
	display: flex;
	align-items: flex-start;
	justify-content: center;
	${({ theme }) => ({
		backgroundColor: theme.colors.white,
		padding: matchScreen('xs') ? '16px 0' : '16px 16px',
	})}
`;

export const RowTitle = styled(BodySBold)`
	${({ theme }) => ({
		color: theme.colors.black54,
	})}
`;

export const ButtonsSubtitle = styled(Caption)`
	${({ theme }) => ({
		color: theme.colors.black87,
	})}
`;

export const SubtitleWrapper = styled.div`
	${({ theme }) => ({
		color: theme.colors.black87,
		width: '100%',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		paddingBottom: 16,
	})}
`;
export const ButtonsFixed = styled.div`
	${() => ({
		backgroundColor: 'white',
		height: 154,
		width: '100%',
		position: 'fixed',
		bottom: 0,
		display: 'flex',
		alignItems: 'flex-end',
		flexDirection: 'column',
		justifyContent: 'flex-end',
		boxShadow:
			'0 7px 8px 0 rgba(0, 0, 0, 0.05), 0 0 22px 4px rgba(0, 0, 0, 0.04), 0 12px 17px 2px rgba(0, 0, 0, 0.04)',
	})}
`;

export const ButtonWrapperXS = styled.div`
	${() => ({
		width: '100%',
		paddingLeft: 16,
		paddingRight: 16,
		boxSizing: 'border-box',
		marginBottom: 16,
	})}
`;

export const RowWrapper = styled.div`
	${({ theme }) => ({
		backgroundColor: theme.colors.background,
	})}
	position: absolute;
	width: 100%;
	margin-left: -16px;
	height: 32px;
	display: flex;
	align-items: center;
	justify-content: center;
`;

export const CaptionWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
`;

export const HBBCaptionWrapper = styled.div`
	min-height: 32px;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
`;

export const IconsWrapper = styled.div`
	display: flex;
	gap: 3px;
	margin-top: 10px;
	justify-content: center;
`;

export const ImageWrapper = styled.div`
	margin: 14px 0;
	width: 100%;
	overflow: hidden;
	display: flex;
	justify-content: center;
`;
