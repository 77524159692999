import React, { FunctionComponent, useEffect, useState } from 'react';
import useTranslate from '@Utils/hooks/useTranslate';
import { Col, Hidden, Row } from 'react-grid-system';
import Icon from '@Components/Icon/Icon';
import BusyIndicator from '@Components/BusyIndicator/BusyIndicator';
import { useDispatch, useSelector } from 'react-redux';
import { addBusyIndicator, removeBusyIndicator } from '@Redux/modules/busyIndicator/actions';
import {
	CardWrapper,
	DeleteToken,
	Horizontal,
	PaymentMethodEmpty,
	PaymentMethods,
	PaymentToken,
	TextAlign,
	ToggleText,
	ToggleWrapper,
} from './PaymentMethod.styled';
import Divider from '@Components/Divider/Divider';
import { BodyS, BodySBold, Caption, ValueBold } from '@Components/Typography/Typography.styled';
import { PaymentService } from '@Services/payment/payment';
import { IPaymentToken } from '@ApiModels/paymentTokens';
import ConditionalRender from '@Components/ConditionalRender/ConditionalRender';
import Toggle from '@Components/controls/Toggle/Toggle';
import { setActivePaymentToken } from '@Redux/modules/paymentTokens/actions';

const GET_PAYMENT_TOKENS_BI = 'GET_PAYMENT_TOKENS_BI';
const ICON_SIZE = 40;
const TOKEN_ICON_SIZE = 48;

const PaymentMethod: FunctionComponent<types.cards.IPaymentMethodCardProps> = ({}): JSX.Element => {
	const { translate } = useTranslate();
	const dispatch = useDispatch();
	const [paymentTokens, setPaymentTokens] = useState<IPaymentToken[]>([]);
	const defaultPaymentToken = useSelector((state: types.redux.IState) => state.paymentTokens.activePaymentToken);

	useEffect(() => {
		dispatch(addBusyIndicator(GET_PAYMENT_TOKENS_BI));
		PaymentService.getPaymentTokens().subscribe(
			(r) => {
				setPaymentTokens(r.tokens);
				dispatch(removeBusyIndicator(GET_PAYMENT_TOKENS_BI));
			},
			() => {
				dispatch(removeBusyIndicator(GET_PAYMENT_TOKENS_BI));
			}
		);
	}, []);

	const removePaymentToken = (tokenId: string) => {
		dispatch(addBusyIndicator(GET_PAYMENT_TOKENS_BI));
		PaymentService.removePaymentToken(tokenId).subscribe(
			(r) => {
				setPaymentTokens(paymentTokens.filter((token) => token.id !== tokenId));
				dispatch(removeBusyIndicator(GET_PAYMENT_TOKENS_BI));
			},
			() => {
				dispatch(removeBusyIndicator(GET_PAYMENT_TOKENS_BI));
			}
		);
	};

	const toogleActive = (tokenId: string): boolean => {
		if (defaultPaymentToken === tokenId) {
			return true;
		}
		return false;
	};

	const setToggleState = (tokenId: string, state: boolean) => {
		dispatch(
			setActivePaymentToken({
				activePaymentToken: state === true ? tokenId : '',
			})
		);
		return state;
	};

	return (
		<CardWrapper>
			<Row>
				<Hidden xs sm md>
					<Col xs={1}>
						<Icon name="paymentMethod" width={ICON_SIZE} height={ICON_SIZE} />
					</Col>
				</Hidden>
				<Col>
					<Divider color="transparent" marginBottom={8} />
					<ValueBold color="black87">{translate('account.management.settings.payment.method')}</ValueBold>
					<Divider color="transparent" marginBottom={44} />
					<BusyIndicator listener={GET_PAYMENT_TOKENS_BI} skeleton="orderTracking">
						<>
							<ConditionalRender
								show={paymentTokens.length === 0}
								onTrue={() => (
									<PaymentMethodEmpty>
										<BodySBold color="black38">
											{translate('account.management.settings.payment.method.empty')}
										</BodySBold>
									</PaymentMethodEmpty>
								)}
							/>
							<ConditionalRender
								show={paymentTokens.length > 0}
								onTrue={() => (
									<PaymentMethods>
										{paymentTokens.map((token, index) => (
											<PaymentToken key={index}>
												<Horizontal>
													{token.cardType === 'VISA' ? (
														<Icon
															name="visa"
															width={TOKEN_ICON_SIZE}
															height={TOKEN_ICON_SIZE}
														/>
													) : token.cardType === 'AMERICAN_EXPRESS' ? (
														<Icon
															name="american_express"
															width={TOKEN_ICON_SIZE}
															height={TOKEN_ICON_SIZE}
														/>
													) : (
														<Icon
															name="mastercard"
															width={TOKEN_ICON_SIZE}
															height={TOKEN_ICON_SIZE}
														/>
													)}
													<TextAlign>
														<BodyS color="black54">{token.displayNumber}</BodyS>
													</TextAlign>
													<ToggleWrapper>
														<ToggleText>
															<Caption color="black54">
																{toogleActive(token.id)
																	? translate(
																			'account.management.settings.payment.method.default'
																	  )
																	: translate(
																			'account.management.settings.payment.method.set.as.default'
																	  )}
															</Caption>
														</ToggleText>
														<Toggle
															active={toogleActive(token.id)}
															onChange={(event) =>
																setToggleState(
																	token.id,
																	(event.target as HTMLInputElement).checked
																)
															}
														/>
													</ToggleWrapper>
												</Horizontal>
												<DeleteToken onClick={() => removePaymentToken(token.id)}>
													{translate('account.management.settings.payment.method.delete')}
												</DeleteToken>
											</PaymentToken>
										))}
									</PaymentMethods>
								)}
							/>
						</>
					</BusyIndicator>
				</Col>
			</Row>
		</CardWrapper>
	);
};

export default PaymentMethod;
