import styled from 'styled-components';
import { DisplayMBold, HeadlineBold, BodyS, Header, BigCurrency } from '@Components/Typography/Typography';
import { marginRight, paddingRight, paddingLeft, borderRadius } from '@Utils/layout/layoutSupport';

export const IconWrapper = styled.div`
	padding: 4px 4px;
	${({ theme }) => {
		return {
			backgroundColor: theme.colors.green,
			...marginRight('16px'),
			...paddingRight('4px'),
			...paddingLeft('4px'),
			...borderRadius('0px', '8px', '8px', '0px'),
		};
	}}
`;

export const ValueWrapper = styled.div`
	display: flex;
	align-items: center;
`;

export const DisplayMBoldWrapper = styled(DisplayMBold)`
	${({ theme }) => {
		return {
			color: theme.colors.black87,
		};
	}}
`;

export const HeadlineBoldWrapper = styled(HeadlineBold)`
	${({ theme }) => {
		return {
			color: theme.colors.black87,
		};
	}}
	margin-right: 8px;
`;

export const BodySWrapper = styled(BodyS)`
	${({ theme }) => {
		return {
			color: theme.colors.black38,
		};
	}}
`;

export const ValueRangeWrapper = styled.div`
	display: flex;
	align-items: center;
	margin-top: 15px;
	margin-bottom: 5px;
`;

export const HeaderWrapper = styled(Header)`
	${({ theme }) => {
		return {
			color: theme.colors.black54,
		};
	}}
`;

export const BigCurrencyWrapper = styled(BigCurrency)`
	${({ theme }) => {
		return {
			color: theme.colors.black54,
		};
	}}
	margin-right: 5px;
`;

export const VehicleValueWrapper = styled.div`
	display: flex;
	flex-direction: column;
`;

export const VehicleValueCardWrapper = styled.div`
	display: flex;
	align-items: flex-start;
	padding: 16px 16px 16px 16px;
	${() => paddingLeft('0px')}
`;
