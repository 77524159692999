import { BodyM, ButtonM } from '@Components/Typography/Typography';
import styled from 'styled-components';
import { isRTL, matchScreen } from '@Utils/layout/layoutSupport';

export const CardContainer = styled.div`
	width: ${() => (matchScreen(['xs', 'sm']) ? 'auto' : '800px')};
`;

export const BGContainer = styled.div`
	overflow: hidden;
	transform: translateX(100px);
	position: absolute;
	bottom: 0;
	right: 0;
	opacity: 0.2;
`;

export const CardWrapper = styled.div<{ isXS?: boolean }>`
	${({ isXS }) => (isXS ? 'padding: 24px 16px;' : 'padding: 24px 40px;')}
`;

export const LogoWrapper = styled.div`
	padding: 0px 15px;
`;

export const CloseIconWrapper = styled.div`
	padding: 0px 15px;
	cursor: pointer;
`;

export const Button = styled.div<{ isXS?: boolean }>`
	${({ isXS }) => ({
		width: isXS ? '100%' : '320px',
	})}
	> div {
		width: 100%;
	}
`;

export const TextButton = styled(ButtonM)`
	display: inline-block;
	cursor: pointer;
	text-decoration: underline;
	${() => isRTL() && 'padding-left: 8px'};
	${({ theme }) => ({
		textDecorationColor: theme.colors.primary,
		color: theme.colors.primary,
	})}
`;

export const TextBodyM = styled(BodyM)`
	display: inline;
	${({ theme }) => ({
		color: theme.colors.black54,
	})}
`;

export const CreateAccount = styled.div`
	display: inline-block;
`;

export const Footer = styled.div`
	${({ theme }) => ({ backgroundColor: theme.colors.grey100 })}
	padding: 24px 40px;
`;

export const Header = styled.div`
	display: flex;
	justify-content: space-between;
	width: 100%;
	z-index: 2;
`;
