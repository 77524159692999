import React, { FunctionComponent, useEffect, useState } from 'react';
import {
	BackButtonWrapper,
	BackIconWrapper,
	BackTitleWrapper,
	ListElement,
	MenuBackground,
	MenuContentWrapper,
	MenuSubContent,
} from './MobileMenu.styled';
import useTranslate from '@Utils/hooks/useTranslate';
import useScreen from '@Utils/hooks/useScreen';
import Icon from '@Components/Icon/Icon';
import ConditionalRender from '@Components/ConditionalRender/ConditionalRender';
import MegaMenu, { MegaMenuContext } from '@Components/MegaMenu/MegaMenu';
import { IExpandableItem, ILinkItem } from '@Components/NavigationBar/NavigationBar';
import MegaMenuMultilevelItem, {
	MegaMenuMultilevelItemType,
} from '@Components/MegaMenuMultilevelItem/MegaMenuMultilevelItem';
import { isRTL } from '@Utils/layout/layoutSupport';
import { getLanguage } from '@Utils/language/language';
import { useHistory, useLocation } from 'react-router-dom';
import { dispatch } from '@Redux/store';
import { setLanguage } from '@Redux/modules/settings/language/actions';
import { BodySHigher } from '@Components/Typography/Typography.styled';
import externalLinks from '@Utils/externalLinks/externalLinks';
import OptionsBar from '@Components/OptionsBar/OptionsBar';
import {
	LanguageButton,
	LanguageButtonWrapper,
	MobileMenuButtonsWrapper,
	PaymentIconWrapper,
} from '@Components/MegaMenu/MegaMenu.styled';
import { useSelector } from 'react-redux';

const ARROW_ICON_SIZE = 16;
const ICONS_SIZE = 24;
interface IMobileMenu {
	open: boolean;
	setMenuExpanded: (expanded: boolean) => void;
	setMegaMenuOpen: (megaMenuOpen: boolean) => void;
	activeMenuIndex: number;
	setActiveMenuIndex: (activeMenuIndex: number) => void;
	items: (IExpandableItem | ILinkItem)[];
}

const MobileMenu: FunctionComponent<IMobileMenu> = ({
	open,
	setMenuExpanded,
	items,
	setMegaMenuOpen,
	activeMenuIndex,
	setActiveMenuIndex,
}) => {
	const [megaMenuContext, setMegaMenuContext] = useState('');
	const { translate } = useTranslate();
	const { screen } = useScreen();
	const { search } = useLocation();
	const history = useHistory();
	const telemarketer = useSelector((state: types.redux.IState) => state.api.authentication.telemarketer);

	useEffect(() => {
		if (!open) {
			setActiveMenuIndex(-1);
			setMenuExpanded(false);
			setMegaMenuContext('');
		}
	}, [open]);

	const handleCloseMobileMenu = () => {
		setMenuExpanded(!open);
	};

	const handleChangeLanguage = () => {
		const query = new URLSearchParams(search);
		if (getLanguage() === 'EN') {
			history.push(`/ar/${location.pathname.substring(4, location.pathname.length)}${query ? `?${query}` : ''}`);
			dispatch(setLanguage('AR'));
		} else {
			dispatch(setLanguage('EN'));
			history.push(`/en/${location.pathname.substring(4, location.pathname.length)}${query ? `?${query}` : ''}`);
		}
	};

	const handleSubMenuBack = () => {
		if (!!megaMenuContext) {
			if (activeMenuIndex >= 0) {
				setActiveMenuIndex(-1);
			} else {
				setMegaMenuContext('');
			}
		} else {
			setMenuExpanded(!open);
		}
	};

	const handleMenuItemClick = (label: string) => {
		setMegaMenuContext(label.toLowerCase());
	};

	return (
		<ConditionalRender
			show={screen(['xs', 'sm'])}
			onTrue={() => (
				<>
					<MenuContentWrapper telemarketer={telemarketer} open={open}>
						<OptionsBar />
						<ConditionalRender
							show={activeMenuIndex < 0 && megaMenuContext === ''}
							onTrue={() => (
								<>
									{items.map((item, index) => {
										return (
											<ListElement key={index}>
												<MegaMenuMultilevelItem
													key={index}
													type={item.itemType}
													onClick={() => {
														item.itemType !== MegaMenuMultilevelItemType.LINK &&
															handleMenuItemClick(item.label);
													}}
													to={item.to}
													name={translate(`menu.item.${item.label}`)}
													paddingLeft={16}
													iconRight={
														item.itemType === MegaMenuMultilevelItemType.ITEM &&
														item.iconRight
															? item.iconRight
															: undefined
													}
												/>
											</ListElement>
										);
									})}
								</>
							)}
							onFalse={() => (
								<BackButtonWrapper onClick={handleSubMenuBack}>
									<BackIconWrapper>
										<Icon
											name={isRTL() ? 'arrowRightBox' : 'arrowLeftBox'}
											width={16}
											height={16}
											fill="primary"
										/>
									</BackIconWrapper>
									<BackTitleWrapper>{translate('mobile-menu.step.back')}</BackTitleWrapper>
								</BackButtonWrapper>
							)}
						/>
						<MenuSubContent open={!!megaMenuContext}>
							<MegaMenu
								handleCloseMobileMenu={handleCloseMobileMenu}
								open
								context={megaMenuContext as MegaMenuContext}
								mobileMenu={true}
								setMegaMenuOpen={setMegaMenuOpen}
								setMegaMenuContext={setMegaMenuContext}
								activeMenuIndex={activeMenuIndex}
								setActiveMenuIndex={setActiveMenuIndex}
								setMenuExpanded={setMenuExpanded}
							/>
						</MenuSubContent>
						<ConditionalRender
							show={!megaMenuContext}
							onTrue={() => (
								<MobileMenuButtonsWrapper>
									<LanguageButtonWrapper onClick={handleChangeLanguage}>
										<LanguageButton>{translate('navbar.language')}</LanguageButton>
										<Icon
											name={isRTL() ? 'arrowLeftBox' : 'arrowRightBox'}
											fill="primary"
											width={ARROW_ICON_SIZE}
											height={ARROW_ICON_SIZE}
										/>
									</LanguageButtonWrapper>
									<PaymentIconWrapper href={externalLinks('PERSONAL_BILL_PAYMENT')}>
										<BodySHigher color="black87">
											{translate('megamenu.search.pay-bill')}
										</BodySHigher>
										<Icon name="payBill" width={ICONS_SIZE} height={ICONS_SIZE} fill="primary" />
									</PaymentIconWrapper>
									<PaymentIconWrapper href={externalLinks('TOP_UP')}>
										<BodySHigher color="black87">{translate('megamenu.search.top-up')}</BodySHigher>
										<Icon name="topUp" width={ICONS_SIZE} height={ICONS_SIZE} fill="primary" />
									</PaymentIconWrapper>
								</MobileMenuButtonsWrapper>
							)}
						/>
					</MenuContentWrapper>
					<MenuBackground open={open} telemarketer={telemarketer} />
				</>
			)}
		/>
	);
};

export default MobileMenu;
