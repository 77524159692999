import React, { FunctionComponent, RefObject, useEffect, useState } from 'react';
import { Container, Row } from 'react-grid-system';
import { CurrentLine, TabItem, TabsContainer, TabSelectorContainer, TabText } from './TabSelector.styled';

interface ITabsSelector {
	tabs: { tabName: string; disabled?: boolean }[];
	onSelectTabIndex: (index: number) => void;
	currentTab: number;
}

const inputs: RefObject<HTMLDivElement>[] = [];

for (let index = 0; index < 5; index++) {
	inputs[index] = React.createRef();
}

const TabSelector: FunctionComponent<ITabsSelector> = ({ tabs, onSelectTabIndex, currentTab }) => {
	const [leftPosition, setLeftPosition] = useState(0);
	const [width, setWidth] = useState(0);

	const setCurrentPosition = () => {
		if (inputs[currentTab]?.current?.getBoundingClientRect()) {
			const { x, width } = inputs[currentTab]?.current?.getBoundingClientRect() ?? {};
			setLeftPosition(x ?? 0);
			setWidth(width ?? 0);
		}
	};

	useEffect(() => {
		let resizeId: NodeJS.Timeout;
		setTimeout(setCurrentPosition, 10);
		const handleResize = () => {
			clearTimeout(resizeId);
			resizeId = setTimeout(setCurrentPosition, 500);
		};
		window.addEventListener('resize', handleResize);
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, [currentTab]);

	return (
		<TabSelectorContainer>
			<Container>
				<Row>
					<TabsContainer>
						{tabs.map((tab, index) => (
							<TabItem
								disabled={!!tab.disabled}
								key={index}
								ref={inputs[index]}
								onClick={(e) => {
									if (!tab.disabled) {
										onSelectTabIndex(index);
									}
								}}
							>
								<TabText disabled={!!tab.disabled} active={currentTab === index}>
									{tab.tabName}
								</TabText>
							</TabItem>
						))}
					</TabsContainer>
				</Row>
			</Container>
			<CurrentLine left={leftPosition} width={width} />
		</TabSelectorContainer>
	);
};

export default TabSelector;
