import React, { FunctionComponent } from 'react';
import { ContentWrapper } from './MultiSegmentPicker.styled';
import Segment, { ISegmentSize } from '@Components/controls/Segment/Segment';
import { IPricesWithVat } from '@Components/Card/cards/ProductCard/subcomponents/PriceSection/PriceSection';

export interface IAvailableValueItem {
	id: string;
	label: string;
	icon?: string;
	available?: boolean;
	price?: IPricesWithVat;
}

interface IMultiSegmentPicker {
	setSelectedValue: (selectedValue: string) => void;
	selectedValue: string | null;
	availableValues: IAvailableValueItem[];
	isAppleWatch: boolean;
}

const SegmentSelector: FunctionComponent<IMultiSegmentPicker> = ({
	setSelectedValue,
	selectedValue,
	availableValues,
	isAppleWatch,
}) => {
	return (
		<ContentWrapper>
			{availableValues.map((item) => {
				return (
					<Segment
						key={item.id}
						id={item.id}
						icon={item.available ? undefined : 'outOfStock'}
						size={isAppleWatch ? ISegmentSize.SMALL_WIDE : ISegmentSize.SMALL}
						label={item.label}
						selected={item.id === selectedValue}
						onClick={() => setSelectedValue(item.id)}
						margin={8}
					/>
				);
			})}
		</ContentWrapper>
	);
};

export default SegmentSelector;
