import React, { FunctionComponent } from 'react';
import Card from '@Components/Card/Card';

const ModalPlanSelectorCard: FunctionComponent<types.cards.IPlanSelectorCardProps['params']> = ({
	plansToCompare,
	otherPlans,
	recommendedPlan,
	productAllSKU,
	selectedFilters,
	setSelectedPlan,
	defaultSelectedPlanIndex,
	currentPlanIsHBB,
	currentPlanIsPrepaid,
	pricesWithVat,
	setSelectedPlanInstallment,
	selectedProductName,
	emmitEvent,
}) => {
	return (
		<Card<types.cards.IPlanSelectorCardProps>
			card="planSelector"
			params={{
				plansToCompare,
				otherPlans,
				recommendedPlan,
				setSelectedPlan,
				defaultSelectedPlanIndex,
				productAllSKU,
				selectedFilters,
				currentPlanIsHBB,
				currentPlanIsPrepaid,
				pricesWithVat,
				setSelectedPlanInstallment,
				selectedProductName,
				emmitEvent,
			}}
			withoutContainerPadding
		/>
	);
};

export default ModalPlanSelectorCard;
