import { STORE_ID } from '@Config/app.config';
import { getLanguageCode } from '@Utils/language/language';

export const getInventoryBySKUId = (SKUId: string): types.endpoints.IEndpointConfig => {
	return {
		url: `/wcs/resources/store/${STORE_ID}/inventoryavailability/${SKUId}`,
	};
};

export const getInventoryByPartNumber = (partNumber: string): types.endpoints.IEndpointConfig => {
	return {
		url: `/wcs/resources/store/${STORE_ID}/inventoryavailability/byPartNumber/${partNumber}`,
	};
};

export const getInventoryByProductId = (productId: string): types.endpoints.IEndpointConfig => {
	return {
		url: `/wcs/resources/store/${STORE_ID}/inventory-by-product?productId=${productId}&langId=${getLanguageCode()}`,
	};
};

export const getCatEntry = ({ catEntryId }: { catEntryId: string }): types.endpoints.IEndpointConfig => {
	return {
		method: 'GET',
		url: `/wcs/resources/store/${STORE_ID}/price-by-catentry?catEntryId=${catEntryId}&langId=${getLanguageCode()}`,
	};
};
