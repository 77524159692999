import { Value, ValueBold } from '@Components/Typography/Typography.styled';
import styled from 'styled-components';
import { matchScreen } from '@Utils/layout/layoutSupport';

export const AutoCompleteListContainer = styled.div`
	${({ theme }) => ({ backgroundColor: theme.colors.white })}
	position: absolute;
	top: 54px;
	padding: 16px;
	width: 588px;
	max-width: 100%;
	border-radius: 10px;
	${() => (matchScreen('xs') ? { left: -16 } : {})}
`;

export const PredictionItemContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	cursor: pointer;
	&:hover {
		${({ theme }) => ({ backgroundColor: theme.colors.primary6 })}
	}
`;

export const ValueBoldWrapper = styled(ValueBold)`
	${({ theme }) => ({ color: theme.colors.black87 })}
`;

export const ValueWrapper = styled(Value)`
	${({ theme }) => ({ color: theme.colors.black54 })}
`;

export const ValuesContainer = styled.div`
	padding: 8px;
`;
