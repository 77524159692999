import { useSelector } from 'react-redux';
import { getTranslation } from '@Redux/modules/settings/language/selectors';

type ContentType = (string | number)[];

interface IUseTranslate {
	translate: (key: string, ...content: ContentType) => string;
}

const useTranslate = (): IUseTranslate => {
	const language = useSelector((state: types.redux.IState) => state.settings.language);

	const translate = (key: string, ...content: ContentType) => {
		const translatedText = getTranslation(key, language);
		const contentLength = content.length;
		let output;
		if (contentLength > 0) {
			let lastChangedIndex = 0;
			let indexOf = translatedText.indexOf('%%', lastChangedIndex);
			output = content
				.map((val: string | number, index: number) => {
					if (indexOf >= 0) {
						indexOf = translatedText.indexOf('%%', lastChangedIndex);
						const content =
							indexOf >= 0
								? `${translatedText.slice(lastChangedIndex, indexOf)}${val}${
										(contentLength === index + 1 && translatedText.slice(indexOf + 2)) || ''
								  }`
								: `${translatedText.slice(lastChangedIndex)}`;
						lastChangedIndex = translatedText.indexOf('%%', lastChangedIndex) + 2;
						return content;
					}
					return;
				})
				?.join('');
		} else {
			output = translatedText;
		}
		return output;
	};

	return { translate };
};

export default useTranslate;
