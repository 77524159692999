import { BodySBold, Counter } from '@Components/Typography/Typography.styled';
import { left } from '@Utils/layout/layoutSupport';
import styled from 'styled-components';

export const Container = styled.div`
	display: flex;
	gap: 16px;
	align-items: center;
	position: relative;
	padding-bottom: 8px;
`;

export const PreviewButton = styled(BodySBold)`
	width: 100%;
	cursor: pointer;
	text-decoration: underline;
	${({ theme }) => ({ color: theme.colors.primary })}
`;

export const FormatName = styled(Counter)<{ iconSize: number }>`
	position: absolute;
	${({ theme }) => ({ color: theme.colors.white })}
	${() => left(16)}
`;
