import { updateBreadcrumb, IUpdateBreadcrumbsAction } from './actions';
import immerHandleActions from '@Redux/utils/immerHandleActions';

const init = {
	items: [] as types.redux.breadcrumbs.IBreadcrumb[],
};

type draft = typeof init;

export interface IBreadcrumbsDraft extends draft {}

const reducers = {
	[updateBreadcrumb.toString()](draft: draft, action: IUpdateBreadcrumbsAction) {
		draft.items = action.payload;
		return draft;
	},
};

export default immerHandleActions(reducers, init);
