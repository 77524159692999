export interface IBnplSession {
	session: {
		BNPLSI: string;
		BNPIURL: string;
	};
}

const model: types.models.IInputModel = {
	session: 'response.response',
};

export default model;
