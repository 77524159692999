import { string, object, AnyObjectSchema } from 'yup';
export default (withValue: boolean, maxValue: string): AnyObjectSchema => {
	const shape = {
		licenseNumber: string()
			.required('required')
			.test(
				'len',
				'insurance.insurer.license.validation.error',
				(val) => !!val && val.length > 3 && val.length < 13
			),
		contactNumber: string()
			.required('required')
			.matches(
				/(^90[1-9][0-9]{5})$|(^(9[1-9]|^(7[1289]))[0-9]{6}$)|^(2)[2-6][0-9]{6}$/,
				'insurance.insurer.contact.hint'
			),
		vehicleNumber: string()
			.required('required')
			.test(
				'len',
				'insurance.vehicle.value.validation.error',
				(val) => !!val && val.length > 1 && val.length < 7
			),
		chars: string().required('required'),
		vehicleValue: string(),
	};
	if (withValue) {
		shape.vehicleValue = string()
			.required('required')
			.test('max', 'insurance.vehicle.value.validation.error', (val) => Number(val) <= Number(maxValue))
			.test('numbers', 'insurance.vehicle.value.validation.error', (val) => Number(val) > 0)
			.test('numbers', 'insurance.vehicle.value.validation.error', (val) =>
				!val ? false : new RegExp(/^\d+$/).test(val)
			);
	}

	return object().shape(shape);
};
