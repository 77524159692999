import { ISinglePackage } from '@ApiModels/singlePackage';
import ConditionalRender from '@Components/ConditionalRender/ConditionalRender';
import { PrimaryLargeButtonFlex } from '@Components/controls/Button/Button';
import TextField from '@Components/controls/TextField/TextField';
import Divider from '@Components/Divider/Divider';
import Icon from '@Components/Icon/Icon';
import { TitleBold } from '@Components/Typography/Typography.styled';
import { addBusyIndicator, removeBusyIndicator } from '@Redux/modules/busyIndicator/actions';
import { dispatch } from '@Redux/store';
import { OrderFlowService } from '@Services/orderFlow/orderFlow';
import useTranslate from '@Utils/hooks/useTranslate';
import { getLang } from '@Utils/language/language';
import contactFormValidator from '@Utils/validators/contactFormValidator';
import { Formik } from 'formik';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { Container } from 'react-grid-system';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import {
	BarContent,
	BarTitle,
	Button,
	Change,
	ContentWrapper,
	FormContent,
	FORM_WIDTH,
	SubTitle,
	TitleContent,
	TopBar,
} from './Contact.styled';
import { CUSTOMER_CARE_PHONE, CUSTOMER_B2B2C_CARE_PHONE } from '@Config/app.config';
export interface IContactFormikFields {
	name: string;
	phoneNumber: string;
}
const SEND_CONTACT_DETAILS_BI = 'SEND_CONTACT_DETAILS_BI';

const Contact: FunctionComponent = () => {
	const [dataSend, setDataSend] = useState<boolean>(false);
	const [valuesInput, setInputValues] = useState<IContactFormikFields>();
	const hbbWfbbProcessCheckout = useSelector(
		(state: types.redux.IState) => state.persistentCheckout.hbbWfbbProcessCheckout
	);
	const { translate } = useTranslate();
	const history = useHistory();
	const location = useLocation<{
		selectedWfbbPlan?: ISinglePackage;
	}>();
	const selectedWfbbPlan = location.state?.selectedWfbbPlan;
	const wfbbAvailable = !!selectedWfbbPlan;
	const isB2b = useSelector((state: types.redux.IState) => state.api.authentication.isB2b);
	const isB2b2c = useSelector((state: types.redux.IState) => state.api.authentication.isB2b2c);

	useEffect(() => {
		if (!hbbWfbbProcessCheckout?.region) {
			history.push(`${getLang()}/store`);
		}
	}, [hbbWfbbProcessCheckout]);

	const onSubmitDetails = (values: IContactFormikFields) => {
		setInputValues(values);
		dispatch(addBusyIndicator(SEND_CONTACT_DETAILS_BI));
		const payload = {
			offer: 'eshophbb',
			phone: values.phoneNumber,
			language: getLang(),
			name: values.name,
			message: `{"productId":"${selectedWfbbPlan?.partNumber || ''}","productName":"${
				selectedWfbbPlan?.name || ''
			}"}`,
		};
		OrderFlowService.directContact(payload).subscribe(
			() => {
				setDataSend(true);
				dispatch(removeBusyIndicator(SEND_CONTACT_DETAILS_BI));
			},
			() => {
				dispatch(removeBusyIndicator(SEND_CONTACT_DETAILS_BI));
			}
		);
	};

	const handleChangeAddress = () => {
		history.goBack();
	};

	const handleGoToHomepage = () => {
		history.push(`${getLang()}/store`);
	};

	function getTitle() {
		let addressArr = [];
		addressArr =
			hbbWfbbProcessCheckout?.region && hbbWfbbProcessCheckout?.region !== 'null'
				? [hbbWfbbProcessCheckout?.region]
				: [];
		addressArr =
			hbbWfbbProcessCheckout?.wayNumber && hbbWfbbProcessCheckout?.wayNumber !== 'null'
				? [...addressArr, ' ' + hbbWfbbProcessCheckout?.wayNumber]
				: [...addressArr];
		addressArr =
			hbbWfbbProcessCheckout?.buildingNumber && hbbWfbbProcessCheckout?.buildingNumber !== 'null'
				? [...addressArr, ' ' + hbbWfbbProcessCheckout?.buildingNumber]
				: [...addressArr];
		return addressArr.join();
	}

	return (
		<>
			<TopBar>
				<Container>
					<BarContent>
						{hbbWfbbProcessCheckout?.region && (
							<>
								<BarTitle>{translate('contact.bar.title')}</BarTitle>
								<TitleBold>{getTitle()}</TitleBold>
							</>
						)}
						<Change onClick={handleChangeAddress}>{translate('contact.bar.title.change')}</Change>
					</BarContent>
				</Container>
			</TopBar>
			<Container>
				<ContentWrapper>
					<ConditionalRender
						show={dataSend}
						onTrue={() => (
							<>
								<Icon name="success" height={96} width={96} />
								<Divider withoutLine marginBottom={24} />
								<TitleContent>
									{translate('contact.title.success', valuesInput?.name || '')}
								</TitleContent>
								<Divider withoutLine marginBottom={8} />
								<SubTitle>{translate('contact.request.sent.subtitle')}</SubTitle>
								<Divider withoutLine marginBottom={8} />
								<SubTitle>
									{translate('contact.request.contact.you.subtitle', valuesInput?.phoneNumber || '')}
								</SubTitle>
								<Divider withoutLine marginBottom={32} />
								<PrimaryLargeButtonFlex uniqueId="go.back.button" onClick={handleGoToHomepage}>
									{translate('go.back.button.primary')}
								</PrimaryLargeButtonFlex>
							</>
						)}
						onFalse={() => (
							<>
								<Icon
									name={wfbbAvailable ? 'msgInfo' : 'warning'}
									fill="primary"
									height={96}
									width={96}
								/>
								<Divider withoutLine marginBottom={24} />
								<TitleContent>
									{translate(wfbbAvailable ? 'contact.title.wfbb' : 'contact.title')}
								</TitleContent>
								<Divider withoutLine marginBottom={8} />
								<ConditionalRender
									show={!wfbbAvailable}
									onTrue={() => (
										<>
											<SubTitle>
												{translate(
													'contact.subtitle',
													isB2b2c || isB2b ? CUSTOMER_B2B2C_CARE_PHONE : CUSTOMER_CARE_PHONE
												)}
											</SubTitle>
										</>
									)}
								/>
								<Divider withoutLine marginBottom={32} />
								<Formik<IContactFormikFields>
									validationSchema={contactFormValidator()}
									initialValues={{
										name: '',
										phoneNumber: '',
									}}
									onSubmit={(values) => {
										onSubmitDetails(values);
									}}
								>
									{({ setFieldValue, values, errors, handleSubmit, isSubmitting }) => {
										return (
											<FormContent>
												<TextField
													width={FORM_WIDTH}
													id="contactName"
													label={translate('contact.name.label')}
													placeholder={translate('contact.name.placeholder')}
													value={values.name}
													onChange={(value) => {
														setFieldValue('name', value.toString().replace(/[0-9]/, ''));
													}}
													message={!!errors.name ? errors.name : ''}
													error={!!errors.name}
													marginBottom={16}
												/>
												<TextField
													width={FORM_WIDTH}
													id="contactPhoneNumber"
													label={translate('contact.phone.number.label')}
													placeholder={translate('contact.phone.number.placeholder')}
													value={values.phoneNumber}
													onChange={(value) => {
														setFieldValue(
															'phoneNumber',
															value.toString().replace(/[a-zA-Z]/, '')
														);
													}}
													message={!!errors.phoneNumber ? errors.phoneNumber : ''}
													error={!!errors.phoneNumber}
													marginBottom={16}
												/>

												<Button>
													<PrimaryLargeButtonFlex
														uniqueId="contact.button"
														onClick={handleSubmit}
														disabled={isSubmitting}
														listener={SEND_CONTACT_DETAILS_BI}
													>
														{translate('contact.button.primary')}
													</PrimaryLargeButtonFlex>
												</Button>
											</FormContent>
										);
									}}
								</Formik>
							</>
						)}
					/>
				</ContentWrapper>
			</Container>
		</>
	);
};

export default Contact;
