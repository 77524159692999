import * as omantelShop from './queries/omantelShop';
import * as omantelApi from './queries/omantelApi';
import * as wcm from './queries/wcm';
import * as eCommerce from './queries/eCommerce';
import * as omantelPortal from './queries/omantelPortal';
import * as microApi from './queries/microApi';

export type ApiQuery =
	| keyof typeof omantelShop
	| keyof typeof microApi
	| keyof typeof omantelApi
	| keyof typeof wcm
	| keyof typeof eCommerce
	| keyof typeof omantelPortal
	| string;

export default {
	omantelShop,
	microApi,
	omantelApi,
	wcm,
	eCommerce,
	omantelPortal,
};
