import styled from 'styled-components';

interface IWhatsInTheBoxContainer {
	marginTop: number;
	marginBottom: number;
}

export const WhatsInTheBoxContainer = styled.div<IWhatsInTheBoxContainer>`
	${({ marginTop, marginBottom }) => ({
		marginTop,
		marginBottom,
	})}
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;
`;
