import React, { FunctionComponent, useEffect } from 'react';
import RadioButton from '@Components/controls/RadioButton/RadioButton';
import Divider from '@Components/Divider/Divider';
import Icon from '@Components/Icon/Icon';
import { BodySBold, MiniDescription, ValueBold } from '@Components/Typography/Typography.styled';
import { setPersistentCheckoutDetails } from '@Redux/modules/persistentCheckoutData/actions';
import { dispatch } from '@Redux/store';
import useTranslate from '@Utils/hooks/useTranslate';
import simDocumentDetailsValidator from '@Utils/validators/simDocumentDetailsValidator';
import { Formik } from 'formik';
import { Col, Hidden, Row, Visible } from 'react-grid-system';
import { useSelector } from 'react-redux';
import {
	ContactDetailsWrapper,
	FormWrapper,
	HeaderWrapper,
	IconContainer,
	IconWrapper,
	ResidentIconWrapper,
	Subtitle,
} from './SimDocumentDetails.styled';
import TextField from '@Components/controls/TextField/TextField';
import { useCart } from '@Utils/context/CartContext';
import { POSTPAID_PLAN } from '@Config/app.config';

export enum DocumentState {
	NATIONAL = '101',
	RESIDENCE = '104',
}
export interface IDocumentDetailsFormikFields {
	customerIdType: DocumentState;
	customerId: string;
}

export const UPDATE_PROFILE = 'updateContactDetails';

const SimDocumentDetails: FunctionComponent<types.cards.IDocumentDetailsProps> = ({
	params: { documentDetailsRef },
}) => {
	const { translate } = useTranslate();
	const persistentCheckout = useSelector((state: types.redux.IState) => state.persistentCheckout);
	const { cart } = useCart();

	useEffect(() => {
		if (documentDetailsRef?.current && persistentCheckout.prepaidProcessCheckout) {
			const { setFieldValue } = documentDetailsRef.current;
			const { customerIdType, customerId } = persistentCheckout.prepaidProcessCheckout;
			customerIdType && setFieldValue('customerIdType', customerIdType);
			customerId && setFieldValue('customerId', customerId);
		}
	}, [persistentCheckout]);

	const onSaveDetails = (values: IDocumentDetailsFormikFields) => {
		const prepaidItem = cart.items.find((cartItem) => [POSTPAID_PLAN].includes(cartItem.plan?.PA || ''));
		if (!!prepaidItem)
			dispatch(
				setPersistentCheckoutDetails({
					prepaidProcessCheckout: {
						customerId: values.customerId,
						customerIdType: values.customerIdType,
						customerType: 'CONSUMER',
						orderItemId: prepaidItem.orderItemId,
					},
				})
			);
	};

	return (
		<ContactDetailsWrapper>
			<Row>
				<Hidden xs sm>
					<IconWrapper>
						<Icon name="personalInfo" width={40} height={40} />
					</IconWrapper>
				</Hidden>
				<Col>
					<HeaderWrapper>
						<Visible xs sm>
							<IconWrapper>
								<Icon name="personalInfo" width={40} height={40} />
							</IconWrapper>
						</Visible>
					</HeaderWrapper>
					<div>
						<ValueBold>{translate('sim-document-details.title')}</ValueBold>
						<Divider marginBottom={8} withoutLine />
						<Subtitle>{translate('sim-document-details.provide.details')}</Subtitle>
						<Divider marginBottom={32} withoutLine />
						<BodySBold color="black87">{translate('sim-document-details.1.point')}</BodySBold>
					</div>
					<Formik<IDocumentDetailsFormikFields>
						validationSchema={simDocumentDetailsValidator()}
						validateOnBlur={true}
						initialValues={{
							customerIdType: DocumentState.NATIONAL,
							customerId: '',
						}}
						innerRef={documentDetailsRef}
						onSubmit={(values) => {
							onSaveDetails(values);
						}}
					>
						{({ setFieldValue, values, errors, touched, setFieldTouched, handleChange }) => {
							return (
								<FormWrapper>
									<Divider marginBottom={16} withoutLine />
									<RadioButton
										fontColor="black87"
										onClick={(value) => {
											setFieldTouched('customerIdType', true);
											setFieldValue('customerIdType', value);
										}}
										value={DocumentState.NATIONAL}
										selected={values.customerIdType}
										label={translate(`sim-document.details.radio.button.${DocumentState.NATIONAL}`)}
									/>

									<Divider marginBottom={16} withoutLine />
									<RadioButton
										fontColor="black87"
										onClick={(value) => {
											setFieldTouched('customerIdType', true);
											setFieldValue('customerIdType', value);
										}}
										value={DocumentState.RESIDENCE}
										selected={values.customerIdType}
										label={translate(
											`sim-document.details.radio.button.${DocumentState.RESIDENCE}`
										)}
									/>

									<Divider marginBottom={16} withoutLine />
									<MiniDescription color="black54">
										{translate('sim-document-details.description.1.point')}
									</MiniDescription>

									<Divider marginBottom={32} withoutLine />
									<BodySBold color="black87">
										{translate('sim-document-details.provide-civil-number')}
									</BodySBold>

									<Divider marginBottom={16} withoutLine />
									<TextField
										width={440}
										id="customerId"
										label={translate('sim-document.data.form.civil-number')}
										placeholder={translate('sim-document.data.form.civil-number')}
										value={values.customerId}
										onChange={(value) => {
											setFieldTouched('customerId', true);
											handleChange('customerId');
											setFieldValue('customerId', value.toString());
										}}
										message={!!errors.customerId && touched.customerId ? errors.customerId : ''}
										error={!!errors.customerId && touched.customerId}
										marginBottom={8}
									/>

									<MiniDescription color="black54">
										{translate('sim-document-details.description.2.point')}
									</MiniDescription>
									<ResidentIconWrapper>
										<IconContainer>
											<Icon name="resident" width={265} height="auto" />
										</IconContainer>
									</ResidentIconWrapper>
								</FormWrapper>
							);
						}}
					</Formik>
				</Col>
			</Row>
		</ContactDetailsWrapper>
	);
};

export default SimDocumentDetails;
