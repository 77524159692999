export interface IUserProfile {
	_birthday: string;
	_isMaster: string;
	alternateEmail1: string;
	alternateEmail2: string;
	apartment: string;
	birthday: boolean;
	dateOfBirth: string;
	city: string;
	companyName: string;
	createdAt: Date;
	documentId: string;
	documentType: string;
	email: string;
	firstName: string;
	house: string;
	isEmailActivated: boolean;
	isMaster: boolean;
	isOtpProfile: boolean;
	landmark: string;
	lastName: string;
	preferredLanguage: string;
	region: string;
	uid: string;
	userId: string;
	way: string;
	area: string;
}

const model = {
	_birthday: 'response.GetFullUserProfileForCurrentUserResponse.birthday',
	_isMaster: 'response.GetFullUserProfileForCurrentUserResponse.isMaster',
	alternateEmail1: 'response.GetFullUserProfileForCurrentUserResponse.alternateEmail1',
	alternateEmail2: 'response.GetFullUserProfileForCurrentUserResponse.alternateEmail2',
	apartment: 'response.GetFullUserProfileForCurrentUserResponse.apartment',
	birthday: (model: IUserProfile): boolean => model._birthday === 'Y',
	dateOfBirth: 'response.GetFullUserProfileForCurrentUserResponse.dateOfBirth',
	city: 'response.GetFullUserProfileForCurrentUserResponse.city',
	companyName: 'response.GetFullUserProfileForCurrentUserResponse.companyName',
	createdAt: 'response.GetFullUserProfileForCurrentUserResponse.createdAt',
	documentId: 'response.GetFullUserProfileForCurrentUserResponse.documentId',
	documentType: 'response.GetFullUserProfileForCurrentUserResponse.documentType',
	email: 'response.GetFullUserProfileForCurrentUserResponse.email',
	firstName: 'response.GetFullUserProfileForCurrentUserResponse.firstName',
	house: 'response.GetFullUserProfileForCurrentUserResponse.house',
	isEmailActivated: (model: IUserProfile): boolean => !!model.email,
	isMaster: (model: IUserProfile): boolean => model._isMaster === 'Y',
	landmark: 'response.GetFullUserProfileForCurrentUserResponse.landmark',
	lastName: 'response.GetFullUserProfileForCurrentUserResponse.lastName',
	preferredLanguage: 'response.GetFullUserProfileForCurrentUserResponse.preferredLanguage',
	region: 'response.GetFullUserProfileForCurrentUserResponse.region',
	uid: 'response.GetFullUserProfileForCurrentUserResponse.uid',
	userId: 'response.GetFullUserProfileForCurrentUserResponse.userId',
	way: 'response.GetFullUserProfileForCurrentUserResponse.way',
	area: 'response.GetFullUserProfileForCurrentUserResponse.area',
	isOtpProfile: (model: IUserProfile): boolean => !!(model.uid && model.uid.startsWith('OTP')),
};

export default model;
