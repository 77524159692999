import * as Yup from 'yup';

const nameRegex = /^^[A-Za-zżźćńółęąśŻŹĆĄŚĘŁÓŃء-ي\-\s]+$/;

export default (): Yup.AnyObjectSchema => {
	const shape = {
		firstName: Yup.string()
			.matches(nameRegex, 'validate.personal.data.first.name.required')
			.required('validate.personal.data.first.name.required'),
		lastName: Yup.string()
			.matches(nameRegex, 'validate.personal.data.last.name.required')
			.required('validate.personal.data.last.name.required'),
		email: Yup.string()
			.email('insurance.owner.data.incorrect.email')
			.required('insurance.owner.data.incorrect.email'),
	};

	return Yup.object().shape(shape);
};
