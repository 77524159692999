import { BodyM, DisplayMBold } from '@Components/Typography/Typography.styled';
import styled from 'styled-components';

export const GeneralErrorContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	padding: 16px;
`;

export const MainTextWrapper = styled(DisplayMBold)`
	${({ theme }) => ({
		color: theme.colors.black87,
	})};
	margin-top: 32px;
	text-align: center;
`;

export const TextWrapper = styled(BodyM)`
	${({ theme }) => ({
		color: theme.colors.black,
	})};
	margin-top: 8px;
	width: 75%;
	text-align: center;
`;
