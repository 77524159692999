import React, { FunctionComponent, useEffect, useRef } from 'react';
import {
	FooterWrapper,
	SectionTitle,
	SocialMediaIconWrapper,
	SocialMediaWrapper,
	BottomSectionItem,
	MainItemsWrapper,
	SocialMediaContainer,
	ContactItemsWrapper,
} from './Footer.styled';
import { Container, Row, Col } from 'react-grid-system';
import Icon from '@Components/Icon/Icon';
import FooterItem from '@Components/Footer/subcomponents/FooterItem/FooterItem';
import useTranslate from '@Utils/hooks/useTranslate';
import useScreen from '@Utils/hooks/useScreen';
import externalLinks from '@Utils/externalLinks/externalLinks';
import { BottomSectionFirstWrapper, BottomSectionSecondWrapper } from '@Components/Footer/Footer.styled';
import { matchScreen } from '@Utils/layout/layoutSupport';
import { hasCookie, NO_HEADER_FOOTER_COOKIE } from '@Utils/cookie/cookie';
import ConditionalRender from '@Components/ConditionalRender/ConditionalRender';

interface ISectionItem {
	label: string;
	to?: string;
	number?: string;
}

interface ISection {
	title: string;
	items: ISectionItem[];
}

const quickContact: ISection[] = [
	{
		title: 'footer.title.quick-contact',
		items: [
			{
				label: 'footer.call.centre-number',
				number: '1234',
			},
			{
				label: 'footer.call.international-operator',
				number: '1305',
			},
			{
				label: 'footer.call.business-centre',
				number: '1235',
			},
		],
	},
];

const sections: ISection[] = [
	{
		title: 'footer.company',
		items: [
			{
				label: 'footer.company.about-us',
				to: externalLinks('ABOUT_US'),
			},
			{
				label: 'footer.company.csr',
				to: externalLinks('CSR'),
			},
			{
				label: 'footer.company.career',
				to: externalLinks('CARER'),
			},
			{
				label: 'footer.company.media-center',
				to: externalLinks('MEDIA_CENTRE'),
			},
			{
				label: 'footer.company.innovation-labs',
				to: externalLinks('INNOVATION_LABS'),
			},
		],
	},
	{
		title: 'footer.company.customer-care',
		items: [
			{
				label: 'footer.customer-care.support',
				to: externalLinks('PERSONAL'),
			},
			{
				label: 'footer.customer-care.payment-channels',
				to: externalLinks('PAYMENT_CHANNELS'),
			},
			{
				label: 'footer.customer-care.find-outlet',
				to: externalLinks('PERSONAL'),
			},
			{
				label: 'footer.customer-care.coverage',
				to: externalLinks('COVERAGE'),
			},
		],
	},
	{
		title: 'footer.business',
		items: [
			{
				label: 'footer.business.investors',
				to: externalLinks('INVESTORS'),
			},
			{
				label: 'footer.business.isupplier',
				to: externalLinks('I_SUPPlIER'),
			},
			{
				label: 'footer.business.wholesale',
				to: externalLinks('WHOLESALE'),
			},
			{
				label: 'footer.business.e-tendering',
				to: externalLinks('PERSONAL'),
			},
			{
				label: 'footer.business.excavation',
				to: externalLinks('PERSONAL'),
			},
		],
	},
	{
		title: 'footer.policies',
		items: [
			{
				label: 'footer.policies.privacy-policy',
				to: externalLinks('PRIVACY_POLICY'),
			},
			{
				label: 'footer.policies.whistleblowing-policy',
				to: externalLinks('WHISTLE_BLOWING_POLICY'),
			},
		],
	},
];

interface ISocialMediaItem {
	iconName: types.iconNames;
	href: string;
}

const socialMedia: ISocialMediaItem[] = [
	{
		iconName: 'facebook',
		href: 'https://www.facebook.com/omantel.om',
	},
	{
		iconName: 'twitter',
		href: 'https://twitter.com/omantel',
	},
	{
		iconName: 'instagram',
		href: 'https://instagram.com/',
	},
	{
		iconName: 'youtube',
		href: 'https://www.youtube.com/user/omantelom',
	},
	{
		iconName: 'whatsApp',
		href: 'https://api.whatsapp.com/send?phone=96872700700',
	},
];

const bottomSectionItems: ISectionItem[] = [
	{
		label: 'footer.copyrights',
		to: externalLinks('COPYRIGHTS'),
	},
	{
		label: 'footer.standard-customer-agreement',
		to: externalLinks('REGULATORY'),
	},
	{
		label: 'footer.site-map',
		to: externalLinks('SITE_MAP'),
	},
];

interface IFooter {
	setFooterHeight: (height: number) => void;
	cartHeight?: number;
}

const Footer: FunctionComponent<IFooter> = ({ setFooterHeight, cartHeight = 0 }) => {
	const footerRef = useRef<HTMLDivElement>(null);
	const { translate } = useTranslate();
	const { screen } = useScreen();
	const isXSmall = screen('xs');
	const isXsSmMd = matchScreen(['xs', 'sm', 'md']);

	useEffect(() => {
		setTimeout(() => {
			setFooterHeight(Number(footerRef.current?.getBoundingClientRect().height) + 30 ?? 0);
		}, 200);

		const handleResize = () => {
			setFooterHeight(Number(footerRef.current?.getBoundingClientRect().height) + 30 ?? 0);
		};

		window.addEventListener('resize', handleResize);
	}, []);

	return (
		<ConditionalRender
			show={!hasCookie(NO_HEADER_FOOTER_COOKIE)}
			onTrue={() => (
				<FooterWrapper ref={footerRef} marginBottom={cartHeight}>
					<Container fluid={isXSmall}>
						<Row>
							<Col xs={12} lg={4}>
								<ContactItemsWrapper>
									<SectionTitle justifyContent={isXSmall ? 'center' : 'flex-start'} color="white70">
										{translate(quickContact[0].title)}
									</SectionTitle>
									{quickContact[0].items.map((item, index) => (
										<FooterItem
											key={index}
											to={item.to}
											number={item.number}
											isExternal={item?.to?.indexOf('://') !== -1}
											label={translate(item.label)}
											marginBottom={26}
											justifyContent={isXSmall ? 'center' : 'flex-start'}
											themeColor="white70"
											leftIcon="phone"
										/>
									))}
								</ContactItemsWrapper>
							</Col>
							{sections.map((section, index) => (
								<Col xs={isXSmall ? 12 : 3} lg={2} key={index}>
									<MainItemsWrapper>
										<SectionTitle
											justifyContent={isXSmall ? 'center' : 'flex-start'}
											color="white70"
										>
											{translate(section.title)}
										</SectionTitle>
										{section.items.map((item, index) => (
											<FooterItem
												key={index}
												to={item.to}
												isExternal={item?.to?.indexOf('://') !== -1}
												label={translate(item.label)}
												marginBottom={24}
												justifyContent={isXSmall ? 'center' : 'flex-start'}
												themeColor="white"
											/>
										))}
									</MainItemsWrapper>
								</Col>
							))}
						</Row>
						<Row align={isXsSmMd ? 'normal' : 'center'}>
							<Col xs={isXsSmMd ? 12 : 4}>
								<Row nogutter justify={isXsSmMd ? 'center' : 'start'}>
									<SectionTitle justifyContent={isXSmall ? 'center' : 'flex-start'} color="white70">
										{translate('footer.follow-us')}
									</SectionTitle>
								</Row>
							</Col>
							<Col xs={isXsSmMd ? 12 : 4}>
								<SocialMediaWrapper smallView={isXsSmMd}>
									{socialMedia.map((socialMediaItem, index) => (
										<SocialMediaIconWrapper href={socialMediaItem.href} key={index}>
											<Icon name={socialMediaItem.iconName} height={40} width={40} />
										</SocialMediaIconWrapper>
									))}
								</SocialMediaWrapper>
							</Col>
						</Row>
						<SocialMediaContainer>
							<Col xs={isXsSmMd ? 12 : 6}>
								<BottomSectionFirstWrapper smallView={isXSmall}>
									<SocialMediaIconWrapper smallView={isXSmall}>
										<Icon name="omantelLogoTransparent" height={16} width={32} />
									</SocialMediaIconWrapper>
									<SectionTitle justifyContent={isXSmall ? 'center' : 'flex-start'} color="white70">
										{translate('footer.oman.telecommunications')}
									</SectionTitle>
								</BottomSectionFirstWrapper>
							</Col>
							<Col xs={isXsSmMd ? 12 : 6}>
								<BottomSectionSecondWrapper smallView={isXSmall}>
									{bottomSectionItems.map((item, index) => (
										<BottomSectionItem key={index}>
											<FooterItem
												marginBottom={24}
												label={translate(item.label)}
												to={item.to}
												isExternal={true}
												typography="captionXs"
												themeColor="white70"
											/>
										</BottomSectionItem>
									))}
								</BottomSectionSecondWrapper>
							</Col>
						</SocialMediaContainer>
					</Container>
				</FooterWrapper>
			)}
			onFalse={() => <></>}
		/>
	);
};

export default Footer;
