import { SecondaryMediumButtonFixed } from '@Components/controls/Button/Button';
import styled from 'styled-components';
import { textRight } from '@Utils/layout/layoutSupport';
import { Header, BodyS, BodySBold, SmallCurrencyVat } from '@Components/Typography/Typography.styled';
export const Container = styled.div<{ isXs?: boolean }>`
	${({ theme }) => ({
		backgroundColor: theme.colors.grey100,
	})};
	border-radius: 8px;
	padding: 16px;
	display: flex;
	align-items: center;
	${({ isXs }) => ({
		flexDirection: isXs ? 'column' : 'row',
		justifyContent: isXs ? 'center' : 'space-between',
	})};
`;

export const TitleContainer = styled.div<{ isXs?: boolean }>`
	display: flex;
	gap: 16px;
	${({ isXs }) => ({
		flexDirection: isXs ? 'column' : 'row',
	})};
`;

export const TitleColumn = styled.div<{ isXs?: boolean }>`
	display: flex;
	flex-direction: column;
	gap: 4px;
	margin-right: 5px;
	justify-content: center;
	${({ isXs }) => ({
		alignItems: isXs ? 'center' : 'flex-start',
	})}
`;

export const TitleWrapper = styled(Header)<{ isXs?: boolean }>`
	${({ theme, isXs }) => ({
		paddingTop: 8,
		color: theme.colors.black87,
		textAlign: isXs ? 'center' : 'unset',
	})}
`;

export const SubtitleWrapper = styled(BodyS)`
	${({ theme }) => ({
		color: theme.colors.black87,
	})}
`;

export const ButtonsContainer = styled.div<{ isXs?: boolean }>`
	margin-top: 20px;
	display: flex;
	${({ isXs }) => ({
		flexDirection: isXs ? 'column' : 'row',
		justifyContent: isXs ? 'center' : 'unset',
		alignItems: isXs ? 'center' : 'unset',
	})};
	gap: 24px;
`;

export const ButtonUnderline = styled(BodySBold)`
	${({ theme }) => ({
		color: theme.colors.primary,
	})}
	text-decoration: underline;
	cursor: pointer;
`;

export const Button = styled(SecondaryMediumButtonFixed)`
	${({ theme }) => ({
		color: theme.colors.primary,
		width: 200,
	})}
	cursor: pointer;
`;

export const VatWrapper = styled.div<{ isXs?: boolean }>`
	${({ isXs }) => ({
		marginTop: isXs ? '16px' : '0px',
	})}
`;

export const Vat = styled(SmallCurrencyVat)`
	${() => textRight()}
	${({ theme }) => ({
		color: theme.colors.black54,
	})}
`;

export const PriceTitle = styled(Header)`
	${({ theme }) => ({
		color: theme.colors.black87,
	})}
`;
