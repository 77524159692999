import api from '@Utils/api';
import { forkJoin, Observable, of } from 'rxjs';
import { IPopularProducts } from '@ApiModels/popularProducts';
import { IMarketingBanners } from '@ApiModels/marketingBanners';
import { concatMap } from 'rxjs/operators';
import { IItem } from '@ApiModels/productsByCategoryId';
import { ItemType, ProductsService } from '@Services/productsService/productsService';
import { InventoryService } from '@Services/inventory/inventoryService';
import { ISearchMenuList } from '@ApiModels/searchMenuList';
import { ENABLE_ESHOP_LITE } from '@Config/features.config';
import { restBannersResponseMock, tradeInBannersResponseMock } from '@Utils/mocks/index';
export class EspotService {
	static getSearchMenuList(categoryName: string): Observable<ISearchMenuList> {
		return api.omantelShop.getSearchMenuList(categoryName).call();
	}
	static getPopularProducts(): Observable<IPopularProducts> {
		return api.omantelShop.getPopularProducts().call();
	}
	static getMarketingBanners(categoryName: string, onlyOneCategory?: boolean): Observable<IMarketingBanners> {
		if (ENABLE_ESHOP_LITE) {
			return categoryName === 'TradeInBanners' ? of(tradeInBannersResponseMock) : of(restBannersResponseMock);
		} else if (!!onlyOneCategory) {
			return api.omantelShop.getMarketingBanners(categoryName).call();
		} else {
			return api.omantelShop
				.getMarketingBanners(categoryName)
				.call()
				.pipe(
					concatMap(async ({ banners }: IMarketingBanners) => {
						const productBanner = await api.omantelShop
							.getMarketingBanners('ProductsViewBanner')
							.call()
							.toPromise();
						if (productBanner?.banners?.length) {
							banners.unshift(...productBanner.banners);
						}
						return { banners };
					})
				);
		}
	}

	static getSimilarItems(category: string): Observable<IItem[]> {
		return api.omantelShop
			.getSimilarItems(category)
			.call()
			.pipe(
				concatMap((products: IPopularProducts) => {
					const promisesArray = this.getProducts(products);
					return promisesArray.length ? forkJoin(promisesArray) : of([]);
				})
			);
	}
	static getRecommendedForYou(): Observable<IItem[]> {
		return api.omantelShop
			.getRecommendedForYou()
			.call()
			.pipe(
				concatMap((products: IPopularProducts) => {
					const promisesArray = this.getProducts(products);
					return promisesArray.length ? forkJoin(promisesArray) : of([]);
				})
			);
	}
	static getProducts(products: IPopularProducts): Promise<IItem>[] {
		let promisesArray: Promise<IItem>[] = [];
		products.products.map((product) => {
			promisesArray = [
				...promisesArray,
				new Promise((resolve) => {
					ProductsService.getProductById({
						productId: product.uniqueID,
						itemType: ItemType.UNKNOWN,
					}).subscribe(
						(r) => {
							if (r?.item?.uniqueID) {
								InventoryService.getAvailableProductComponents({ product: r.item }).subscribe(
									(response) => {
										resolve(response.product);
									},
									() => {
										resolve(([] as unknown) as IItem);
									}
								);
							} else {
								resolve(([] as unknown) as IItem);
							}
						},
						() => {
							resolve(([] as unknown) as IItem);
						}
					);
				}),
			];
		});
		return promisesArray;
	}
}
