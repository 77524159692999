import React, { FunctionComponent } from 'react';
import Card from '@Components/Card/Card';
import {
	RelatedProductsWrapper,
	SingleProductWrapper,
	TileSize,
} from '@Components/Card/cards/ProductCard/ProductCard.styled';
import { ScreenClass } from 'react-grid-system';
import { IItem } from '@ApiModels/productsByCategoryId';

interface IRelatedProducts {
	products?: IItem[];
	screenClass: ScreenClass;
}

const RelatedProducts: FunctionComponent<IRelatedProducts> = ({ products, screenClass }) => {
	return (
		<RelatedProductsWrapper screenClass={screenClass}>
			{products
				?.filter((item, index, sortedProducts) => {
					const iphone14ProFirstOccurrenceIndex = sortedProducts.findIndex(
						(product) => product.name === 'iPhone 14 Pro'
					);
					if (index === iphone14ProFirstOccurrenceIndex) return true;
					else
						return (
							item.name !== 'iPhone 14 Pro' && item.inventory && Object.keys(item.inventory).length !== 0
						);
				})
				.map((product, index) =>
					index < 4 ? (
						<SingleProductWrapper
							className={`single-product-${screenClass}`}
							screenClass={screenClass}
							key={index}
						>
							<Card<types.cards.IProductCardProps>
								card="product"
								params={{
									product,
									tileSize: TileSize.BIG,
									iconsHidden: true,
									layoutChange: false,
									isRelatedProduct: true,
								}}
								marginTop={0}
								marginBottom={10}
							/>
						</SingleProductWrapper>
					) : (
						''
					)
				)}
		</RelatedProductsWrapper>
	);
};

export default RelatedProducts;
