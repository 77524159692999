import styled from 'styled-components';
import { matchScreen } from '@Utils/layout/layoutSupport';

export const CardWrapper = styled.div`
	display: flex;
	padding: 24px;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: ${() => (matchScreen(['xs']) ? 'auto' : '552px')};
`;

export const TitleWrapper = styled.div<{ marginLeft: number }>`
	text-align: center;
	flex-grow: 1;
	${({ marginLeft }) => ({ marginLeft })}
`;

export const ActionWrapper = styled.div`
	display: flex;
	justify-content: flex-end;
	cursor: pointer;
`;

export const CardHeader = styled.div`
	width: 100%;
	display: flex;
	padding-bottom: 16px;
	align-items: flex-end;
`;
