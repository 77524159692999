import React, { FunctionComponent } from 'react';
import {
	CardContainer,
	ImageWrapper,
	CartTitle,
	CartDescription,
	ActionWrapper,
	HeaderContainer,
	IconButton,
	CartButtonWrapper,
} from '@Components/Card/cards/GenericError/GenericError.styled';
import useTranslate from '@Utils/hooks/useTranslate';
import { PrimaryMediumButtonFixed } from '@Components/controls/Button/Button';
import Icon from '@Components/Icon/Icon';
import { setModal } from '@Redux/modules/modal/actions';
import { useDispatch } from 'react-redux';
import ConditionalRender from '@Components/ConditionalRender/ConditionalRender';
import useScreen from '@Utils/hooks/useScreen';
import Divider from '@Components/Divider/Divider';
import { getLang } from '@Utils/language/language';
import { Link } from 'react-router-dom';

const ICON_SIZE = 96;

const GenericError: FunctionComponent<types.cards.IGenericErrorCardProps> = ({
	params: { icon, iconFill, title, description, withButton, goToCartButton },
}) => {
	const { translate } = useTranslate();
	const dispatch = useDispatch();
	const { screen } = useScreen();
	const isXS = screen(['xs']);

	const handleClose = () => {
		dispatch(
			setModal({
				type: null,
				data: null,
			})
		);
	};

	return (
		<CardContainer>
			<HeaderContainer isXS={isXS}>
				<IconButton
					onClick={() => {
						dispatch(setModal({ closeAllModals: true, type: null }));
					}}
				>
					<Icon name="close" width={24} height={24} fill="black54" />
				</IconButton>
			</HeaderContainer>
			<Divider marginBottom={32} />
			<ImageWrapper>
				<Icon name={icon} width={ICON_SIZE} height={ICON_SIZE} fill={iconFill} />
			</ImageWrapper>
			<CartTitle>{title}</CartTitle>
			<CartDescription>{description}</CartDescription>
			<ConditionalRender
				show={withButton}
				onTrue={() => (
					<ActionWrapper>
						<PrimaryMediumButtonFixed uniqueId="generic.error.handle.close" onClick={handleClose}>
							{translate('close')}
						</PrimaryMediumButtonFixed>
					</ActionWrapper>
				)}
			/>
			<ConditionalRender
				show={goToCartButton}
				onTrue={() => (
					<CartButtonWrapper>
						<Link to={`/${getLang()}/cart`}>
							<PrimaryMediumButtonFixed uniqueId="generic.error.handle.close" onClick={handleClose}>
								{translate('buy-now-pay-later.modal.go-to-the-cart')}
							</PrimaryMediumButtonFixed>
						</Link>
					</CartButtonWrapper>
				)}
			/>
		</CardContainer>
	);
};
export default GenericError;
