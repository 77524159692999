import {
	BodyS,
	BodySBold,
	Caption,
	DisplayMBold,
	InfoBold,
	SmallCurrencyVat,
	TitleBold,
	HeadlineBold,
	ParagraphMBold,
} from '@Components/Typography/Typography';
import {
	flexDirection,
	justifyContent,
	marginLeft,
	marginRight,
	matchScreen,
	paddingLeft,
	right,
	textRight,
} from '@Utils/layout/layoutSupport';
import { Col, Row } from 'react-grid-system';
import styled from 'styled-components';

export const CartPaymentSummaryContainer = styled(Col)``;

export const CartPaymentSummaryTitle = styled(DisplayMBold)`
	${({ theme }) => ({ color: theme.colors.black87 })}
	margin-bottom: 16px;
	margin-top: 8px;
`;

export const MediumPaymentSummaryTitle = styled(HeadlineBold)`
	${({ theme }) => ({ color: theme.colors.black87 })}
	margin-bottom: 24px;
	margin-top: 8px;
`;

export const SmallCartPaymentSummaryTitle = styled(TitleBold)`
	${({ theme }) => ({ color: theme.colors.black87 })}
	margin-bottom: 32px;
	margin-top: 8px;
`;

export const SmallElementContainer = styled.div`
	margin-top: 8px;
`;

export const SmallTextColumn = styled.div`
	display: flex;
	flex-direction: column;
	gap: 4px;
`;

export const SmallSingleItemRow = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	width: 100%;
`;

export const ItemsColumn = styled.div`
	display: flex;
	width: 100%;
	flex-direction: column;
	margin-bottom: 24px;
`;

export const SmallPaymentSummaryWrapper = styled.div`
	margin-bottom: -40px;
`;

export const SmallCartPaymentRow = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	margin: 0px -15px 16px;
`;

export const HeaderRow = styled(Row)`
	${({ theme }) => ({
		borderBottom: '1px solid',
		borderColor: theme.colors.black12,
	})}
`;

export const SmallVatWrapper = styled.div<{
	isMonthly?: boolean;
	isMD?: boolean;
	isUpfront?: boolean;
	isSM?: boolean;
	isLG?: boolean;
}>`
	align-self: flex-end;
	justify-self: flex-end;
	align-items: flex-end;
	justify-content: flex-end;
	align-content: flex-end;
	display: flex;
	flex-direction: row;
	gap: 2px;
	${({ isMonthly, isUpfront, isSM, isLG, isMD }) => ({
		marginTop: isSM ? '0px' : isUpfront ? '-8px' : '0',
		width: isSM ? '160%' : isLG ? '110%' : isMonthly ? '120%' : '100%',
		...marginLeft(isSM ? -50 : isLG && isMonthly ? -8 : isLG ? -12 : isMonthly && isMD ? -24 : isMonthly ? -30 : 0),
	})}
`;

export const HeaderText = styled(Caption)<{ alignRight?: boolean }>`
	${({ theme, alignRight }) => ({
		color: theme.colors.black54,
		marginBottom: '7px',
	})}
	${() => marginRight('-2px')}
	${({ alignRight }) => alignRight && `text-align: right`}
`;

export const ItemText = styled(ParagraphMBold)`
	${({ theme }) => ({ color: theme.colors.black87 })}
	${() => marginLeft('8px')}
`;

export const SmallItemText = styled(BodySBold)`
	${({ theme }) => ({ color: theme.colors.black87 })}
	${() => marginLeft('8px')}
`;

export const ItemsWrapper = styled.div``;

export const ItemRow = styled(Row)<{ divider?: boolean; paddingTop?: string; paddingBottom?: string; isEbu?: boolean }>`
	${({ paddingTop }) => (paddingTop ? { paddingTop: `${paddingTop}px;` } : null)}
	${({ paddingBottom }) => (paddingBottom ? { paddingBottom: `${paddingBottom}px;` } : null)}
	${({ theme, divider }) => (divider ? { borderBottom: '1px solid', borderColor: theme.colors.black12 } : null)};
	${({ isEbu }) => ({ ...paddingLeft(isEbu ? '24px' : '0') })}
	${() => marginLeft('32px')}
`;

export const EmptyLine = styled.div<{ isMakasib?: boolean }>`
	width: 16px;
	height: 2px;
	border-radius: 2px;
	margin-top: 10px;
	${({ theme, isMakasib }) => ({ backgroundColor: isMakasib ? theme.colors.primary : theme.colors.black38 })}
`;

export const VatAmountContainer = styled(InfoBold)<{ isMakasib?: boolean }>`
	${({ theme, isMakasib }) => ({
		textDecoration: isMakasib ? 'line-through' : 'none',
	})};
`;

export const DiscountPriceContainer = styled(BodyS)`
	position: absolute;
	top: 4px;
	text-decoration-line: line-through;
	${() => right('126px')}
	${({ theme }) => ({
		color: theme.colors.grey,
	})};
`;

export const SmallDiscountPriceContainer = styled(BodyS)`
	position: absolute;
	top: -2px;
	text-decoration-line: line-through;
	${() => right('90px')}
	${({ theme }) => ({
		color: theme.colors.grey,
	})};
`;

export const PriceContainer = styled.div`
	${() => ({
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'end',
		position: 'relative',
	})}
`;

export const PriceRow = styled.div`
	height: 80%;
	${() => ({
		display: 'flex',
		alignItems: matchScreen('xs') ? 'flex-start' : 'center',
		justifyContent: 'space-between',
	})}
	${() => flexDirection('row')}
	${() => marginRight(-4)}
`;

export const VatWrapper = styled.div`
	${() => textRight()}
`;

export const Vat = styled(SmallCurrencyVat)`
	${() => textRight()}
	${({ theme }) => ({
		color: theme.colors.black54,
	})}
`;

export const DividerContainer = styled.div`
	margin-right: -16px;
	margin-left: -16px;
`;

export const CustomLine = styled.div`
	${({ theme }) => ({
		borderBottom: `1px solid ${theme.colors.grey300}`,
		bacgroundColor: 'red',
	})}
	margin-left: 32px;
	width: calc(100% + 40px);
`;

export const EmptyLineContainer = styled.div`
	display: flex;
	justify-content: flex-end;
	flex-direction: row;
`;

export const PriceWrapper = styled.div`
	display: flex;
	${() => justifyContent('flex-end')};
	flex-direction: row;
	padding: 0;
`;
