import Divider from '@Components/Divider/Divider';
import useTranslate from '@Utils/hooks/useTranslate';
import React, { FunctionComponent, useEffect, useCallback } from 'react';
import {
	OutletAvailableWrapper,
	TitleWrapper,
	ContentWrapper,
	DeviceInfoWrapper,
	OutletsWrapper,
	ImageWrapper,
	Image,
	SingleInfoWrapper,
	AttributeWrapper,
	ColorDotWrapper,
	CloseIconWrapper,
	SmallResolutionLabelwrapper,
	SmallResolutionInfoWrapper,
} from './OutletAvailable.styled';
import {
	TitleBold,
	SubheadingH2,
	BodyM,
	BodyMBold,
	BodyS,
	BodySBold,
	ParagraphSBold,
} from '@Components/Typography/Typography.styled';
import { ColorDot } from '@Components/controls/ColorPicker/subcomponents/Slider.styled';
import { Col, Row, useScreenClass, Hidden, Visible } from 'react-grid-system';
import OutletInformation from './subComponents/OutletInformation';
import { setModal } from '@Redux/modules/modal/actions';
import { useDispatch } from 'react-redux';
import Icon from '@Components/Icon/Icon';
import { IOutletsModalObjects, setOpenOutletModal } from '@Redux/modules/configurator/actions';
import { useSelector } from 'react-redux';
import ConditionalRender from '@Components/ConditionalRender/ConditionalRender';

const lightColors = ['#faf6f2'];
const APPLE_WATCH = 'APPLE_WATCH';
const DEVICE_LABEL_TYPE = 'DEVICE_LABEL_TYPE';

const OutletAvailable: FunctionComponent<types.cards.IOutletAvailableCardProps> = ({
	params: { productDetails, chosenColor, chosenCapacity, outletsInformation },
}) => {
	const { translate } = useTranslate();

	const deviceColor = productDetails?.availableColors?.find((item) => item.value === chosenColor);
	const deviceCapacity = productDetails?.availableCapacity?.find((item) => item.id === chosenCapacity);
	const dispatch = useDispatch();
	const screenClass = useScreenClass();
	const appleWatchLabelType = productDetails?.attributes?.find((attr) => attr.name === DEVICE_LABEL_TYPE);
	const isAppleWatch =
		appleWatchLabelType?.values.find((val) => val.value === 'APPLE_WATCH')?.value === 'APPLE_WATCH';
	const handleClose = () => {
		dispatch(
			setModal({
				type: null,
				data: null,
			})
		);
	};

	const modalTitle = () => {
		if (screenClass.includes('xs') || screenClass.includes('sm') || screenClass.includes('md')) {
			return translate('outlet-available.modal.title-small');
		} else {
			return translate('outlet-available.modal.title');
		}
	};

	useEffect(() => {
		dispatch(
			setOpenOutletModal({
				outlets: outletsInformation?.outlets.map((item) => {
					return ({ id: item.branchId, isOpen: false } as unknown) as IOutletsModalObjects;
				}),
			})
		);
	}, []);

	const outletsFocusOpen = useSelector((state: types.redux.IState) => state.configurator.outlets);

	const isFocusOnOpenModal = useCallback(() => {
		if (outletsFocusOpen?.find((item) => item.isOpen === true)) {
			return true;
		} else {
			return false;
		}
	}, [outletsFocusOpen]);

	const fixedHeight = () => {
		if (screenClass.includes('xs')) {
			return isFocusOnOpenModal() ? '513px' : '389px';
		} else if (screenClass.includes('sm')) {
			return isFocusOnOpenModal() ? '600px' : '417px';
		} else if (screenClass.includes('md')) {
			return isFocusOnOpenModal() ? '600px' : '461px;';
		} else if (screenClass.includes('lg')) {
			return '560px';
		} else {
			return '560px';
		}
	};

	const outletsWrapperHeight = () => {
		if (screenClass.includes('xs')) {
			return isFocusOnOpenModal() ? '344px' : '220px';
		} else if (screenClass.includes('sm')) {
			return isFocusOnOpenModal() ? '400px' : '220px';
		} else if (screenClass.includes('md')) {
			return isFocusOnOpenModal() ? '500px' : '360px';
		} else if (screenClass.includes('lg')) {
			return '480px';
		} else {
			return '480px';
		}
	};

	return (
		<OutletAvailableWrapper heightFixed={fixedHeight()}>
			<TitleWrapper>
				<Hidden xs sm>
					<TitleBold>{modalTitle()}</TitleBold>
				</Hidden>
				<Visible xs sm>
					<ParagraphSBold>{modalTitle()}</ParagraphSBold>
				</Visible>
				<CloseIconWrapper onClick={handleClose}>
					<Icon name="close" fill="black" width={24} height={24} />
				</CloseIconWrapper>
			</TitleWrapper>
			<Divider />
			<ContentWrapper>
				<Col sm={12} md={4} lg={4}>
					<DeviceInfoWrapper
						isSmallScreen={screenClass.includes('xs') || screenClass.includes('sm')}
						isSM={screenClass.includes('sm')}
					>
						<Hidden xs>
							<ImageWrapper isSmallScreen={screenClass.includes('sm')}>
								<Image src={deviceColor?.fullImage ?? productDetails?.fullImage ?? ''} />
							</ImageWrapper>
						</Hidden>
						<Hidden xs sm>
							<SingleInfoWrapper>
								<SubheadingH2 textTransform="uppercase" color="black54">
									{translate('outlet-available.modal.device-label')}
								</SubheadingH2>
								<AttributeWrapper>
									<ConditionalRender
										show={!!productDetails?.manufacturer}
										onTrue={() => (
											<>
												<BodyMBold>
													{productDetails?.manufacturer}
													{'\xa0'}
												</BodyMBold>
												<BodyM>{productDetails?.name}</BodyM>
											</>
										)}
									/>
								</AttributeWrapper>
							</SingleInfoWrapper>
							<SingleInfoWrapper>
								<SubheadingH2 textTransform="uppercase" color="black54">
									{translate(
										isAppleWatch
											? 'product.configuration.caseColor'
											: 'outlet-available.modal.storage-label'
									)}
								</SubheadingH2>
								<AttributeWrapper>
									<BodyMBold>{deviceCapacity?.value}</BodyMBold>
								</AttributeWrapper>
							</SingleInfoWrapper>
							<SingleInfoWrapper>
								<SubheadingH2 textTransform="uppercase" color="black54">
									{translate(
										isAppleWatch
											? 'product.configuration.bandColor'
											: 'outlet-available.modal.color-label'
									)}
									{'\xa0'}
									{chosenColor}
								</SubheadingH2>
								<AttributeWrapper>
									<ColorDotWrapper>
										{deviceColor ? (
											<ColorDot
												isLightColor={
													lightColors ? lightColors.includes(deviceColor?.color) : false
												}
												color={deviceColor?.color}
												unavailable={!deviceColor?.available}
											/>
										) : null}
									</ColorDotWrapper>
								</AttributeWrapper>
							</SingleInfoWrapper>
						</Hidden>
						<Visible xs sm>
							<SmallResolutionInfoWrapper isSM={screenClass.includes('sm')}>
								<SmallResolutionLabelwrapper>
									<BodySBold>
										{productDetails?.manufacturer}
										{'\xa0'}
										{productDetails?.name}
									</BodySBold>
								</SmallResolutionLabelwrapper>
								<SmallResolutionLabelwrapper>
									<BodyS color="black54">
										{translate('outlet-available.modal.color-label')}
										{'\xa0'}
										{chosenColor}
										{'\xa0\xa0|\xa0\xa0'}
										{translate('outlet-available.modal.storage-label')}
										{'\xa0'}
										{deviceCapacity?.value}
									</BodyS>
								</SmallResolutionLabelwrapper>
							</SmallResolutionInfoWrapper>
						</Visible>
					</DeviceInfoWrapper>
					<Visible xs sm>
						<OutletsWrapper height={outletsWrapperHeight()}>
							{outletsInformation?.outlets.map((item) => (
								<OutletInformation
									key={item.branchId}
									id={item.branchId}
									isProductAvailable={item.available}
									outletName={item.branchName ? item.branchName : ''}
									openHours={item.openHours}
									latitude={item.latitude}
									longitude={item.longitude}
									wilayat={item.wilayat}
									city={item.city}
									address={item.address}
								/>
							))}
						</OutletsWrapper>
					</Visible>
				</Col>
				<Hidden xs sm>
					<Col sm={12} md={8} lg={8}>
						<Row>
							<OutletsWrapper height={outletsWrapperHeight()}>
								{outletsInformation?.outlets.map((item) => (
									<OutletInformation
										key={item.branchId}
										id={item.branchId}
										isProductAvailable={item.available}
										outletName={item.branchName ? item.branchName : ''}
										openHours={item.openHours}
										latitude={item.latitude}
										longitude={item.longitude}
										wilayat={item.wilayat}
										city={item.city}
										address={item.address}
									/>
								))}
							</OutletsWrapper>
						</Row>
					</Col>
				</Hidden>
			</ContentWrapper>
		</OutletAvailableWrapper>
	);
};

export default OutletAvailable;
