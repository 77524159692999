import styled from 'styled-components';

interface IIconsWrapperProps {
	marginRight?: number;
}

export const IconsWrapper = styled.div<IIconsWrapperProps>`
	display: flex;
	justify-content: flex-start;
	align-items: center;
	${({ marginRight }) => marginRight && { marginRight }}
`;

export const SingleIconWrapper = styled.div`
	width: 40px;
	height: 40px;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
`;
