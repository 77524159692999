import { Subheading } from '@Components/Typography/Typography.styled';
import styled from 'styled-components';
import { marginRight } from '@Utils/layout/layoutSupport';

export const InsuranceOfferCardWraper = styled.div`
	padding: 0 16px 0 16px;
`;

export const ImageWrapper = styled.div`
	width: 48px;
	height: 48px;
	${() => marginRight('8px')}
	padding: 6px 0 5.4px;
	border-radius: 4px;
	box-shadow: 0 7px 8px 0 rgba(0, 0, 0, 0.05), 0 5px 22px 4px rgba(0, 0, 0, 0.04), 0 12px 17px 2px rgba(0, 0, 0, 0.04);
`;

export const Image = styled.img`
	height: 48px;
	width: 48px;
`;

export const InsuranceTitleWrapper = styled.div`
	display: flex;
	align-items: center;
`;

export const ChipsWrapper = styled.div`
	display: flex;
	flex-wrap: wrap;
`;

export const SectionTitle = styled(Subheading)`
	padding: 16px 0;
	text-transform: uppercase;
	${({ theme }) => {
		return {
			color: theme.colors.black54,
		};
	}}
`;
