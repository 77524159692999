import styled from 'styled-components';
import { BodySBold, DisplayMBold, HeadlineBold, Subheading } from '@Components/Typography/Typography';

export const InsuranceOfferCardWraper = styled.div`
	padding: 16px;
	${({ theme }) => {
		return {
			backgroundColor: theme.colors.green,
		};
	}}
`;

export const ImageWrapper = styled.div`
	width: 42px;
	height: 42px;
	padding: 4px;
	border-radius: 4px;
	${({ theme }) => {
		return {
			backgroundColor: theme.colors.white,
		};
	}}
	box-shadow: 0 7px 8px 0 rgba(0, 0, 0, 0.05), 0 5px 22px 4px rgba(0, 0, 0, 0.04), 0 12px 17px 2px rgba(0, 0, 0, 0.04);
`;

export const Image = styled.img`
	height: 42px;
	width: 42px;
`;

export const TitleWrapper = styled(BodySBold)`
	${({ theme }) => {
		return {
			color: theme.colors.white,
		};
	}}
`;

export const ValueWrapper = styled(DisplayMBold)`
	margin-left: 6px;
	${({ theme }) => {
		return {
			color: theme.colors.white,
		};
	}}
`;

export const CurrencyWrapper = styled(HeadlineBold)`
	${({ theme }) => {
		return {
			color: theme.colors.white,
		};
	}}
`;

export const SubheadingWrapper = styled(Subheading)`
	${({ theme }) => {
		return {
			color: theme.colors.white70,
		};
	}}
`;

export const PriceWrapper = styled.div`
	margin: 24px 0 12px 0;
	display: flex;
	align-items: baseline;
`;
