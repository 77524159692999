import { IPrice } from '@ApiModels/productsByPartNumber';

export interface ITypeaheadProductsBySearchTerm {
	catalogEntryTypeCode: string;
	hasSingleSKU: boolean;
	name: string;
	partNumber: string;
	price: IPrice[];
	resourceId: string;
	storeID: string;
	thumbnail: string;
	uniqueID: string;
}

interface ISuggestionView {
	identifier: string;
	entry: ITypeaheadProductsBySearchTerm[];
}

export interface ITypeaheadSuggestions {
	suggestionView: ISuggestionView[];
	items: ITypeaheadProductsBySearchTerm[];
}

interface IModel extends ITypeaheadSuggestions {
	_suggestionView: ISuggestionView[];
}

const model: types.models.IInputModel = {
	_suggestionView: ['response.suggestionView', []],
	suggestionView: (model: IModel) => model._suggestionView,
	items: (model: IModel) =>
		model.suggestionView.find((suggestion) => suggestion.identifier === 'Product')?.entry ?? [],
};

export default model;
