import React, { Fragment, FunctionComponent, useEffect, useState } from 'react';
import { TrackingKeys } from '@ApiModels/tracking';
import Accordion from '@Components/Accordion/Accordion';
import ConditionalRender from '@Components/ConditionalRender/ConditionalRender';
import Icon from '@Components/Icon/Icon';
import { IGetOrderTrackingResponse } from '@Services/orderFlow/orderFlow';
import useTranslate from '@Utils/hooks/useTranslate';
import { useSelector } from 'react-redux';
import {
	StatusContainer,
	TrackingStepperContainer,
	IconContainer,
	StatusWrapper,
	DescriptionWrapper,
	Line,
	LineContainer,
	DescriptionContainer,
	LineStatus,
} from './TrackingStepper.styled';
import Divider from '@Components/Divider/Divider';

const STEPS_MAP = new Map<
	TrackingKeys,
	{ title: string; doneTitle?: string; description: string; doneDescription?: string }
>([
	[
		'ORDER_RECEIVED',
		{
			title: 'tracking.title.ORDER_RECEIVED',
			description: 'tracking.description.ORDER_RECEIVED',
		},
	],
	[
		'REQUEST_FROM_SUPPLIER',
		{
			title: 'tracking.title.REQUEST_FROM_SUPPLIER',
			description: 'tracking.description.REQUEST_FROM_SUPPLIER',
		},
	],
	[
		'PREPARED',
		{
			title: 'tracking.title.PREPARED',
			doneTitle: 'tracking.doneTitle.PREPARED',
			description: 'tracking.description.PREPARED',
			doneDescription: 'tracking.doneDescription.PREPARED',
		},
	],
	[
		'SHIPPED',
		{
			title: 'tracking.title.SHIPPED',
			doneTitle: 'tracking.doneTitle.SHIPPED',
			description: 'tracking.description.SHIPPED',
		},
	],
	[
		'DELIVERED',
		{
			title: 'tracking.title.DELIVERED',
			doneTitle: 'tracking.doneTitle.DELIVERED',
			description: '',
			doneDescription: 'tracking.doneDescription.DELIVERED',
		},
	],
	[
		'REQUEST_RECEIVED',
		{
			title: 'tracking.title.REQUEST_RECEIVED',
			doneTitle: 'tracking.doneTitle.REQUEST_RECEIVED',
			description: '',
			doneDescription: 'tracking.doneDescription.REQUEST_RECEIVED',
		},
	],
	[
		'REQUEST_REJECTED',
		{
			title: 'tracking.title.REQUEST_REJECTED',
			doneTitle: 'tracking.doneTitle.REQUEST_REJECTED',
			description: '',
			doneDescription: 'tracking.doneDescription.REQUEST_REJECTED',
		},
	],
	[
		'PROCCESSING',
		{
			title: 'tracking.title.PROCCESSING',
			doneTitle: 'tracking.doneTitle.PROCCESSING',
			description: 'tracking.description.PROCCESSING',
			doneDescription: 'tracking.doneDescription.PROCCESSING',
		},
	],
	[
		'FULFILLMENT_IN_PROGRESS',
		{
			title: 'tracking.title.FULFILLMENT_IN_PROGRESS',
			doneTitle: 'tracking.doneTitle.FULFILLMENT_IN_PROGRESS',
			description: 'tracking.description.FULFILLMENT_IN_PROGRESS',
			doneDescription: 'tracking.doneDescription.FULFILLMENT_IN_PROGRESS',
		},
	],
	[
		'INSTALLATION',
		{
			title: 'tracking.title.INSTALLATION',
			doneTitle: 'tracking.doneTitle.INSTALLATION',
			description: '',
			doneDescription: 'tracking.doneDescription.INSTALLATION',
		},
	],
	[
		'INSTALLATION_COMPLETED',
		{
			title: 'tracking.title.INSTALLATION_COMPLETED',
			doneTitle: 'tracking.doneTitle.INSTALLATION_COMPLETED',
			description: '',
			doneDescription: 'tracking.doneDescription.INSTALLATION_COMPLETED',
		},
	],
	[
		'REQUEST_COMPLETED',
		{
			title: 'tracking.doneTitle.REQUEST_COMPLETED',
			description: '',
			doneDescription: 'tracking.doneDescription.REQUEST_COMPLETED',
		},
	],
	[
		'REQUEST_ACCEPTED',
		{
			title: 'tracking.doneTitle.REQUEST_ACCEPTED',
			doneTitle: 'tracking.doneTitle.REQUEST_ACCEPTED',
			description: '',
			doneDescription: 'tracking.doneDescription.REQUEST_ACCEPTED',
		},
	],
	[
		'READY_TO_PICKUP',
		{
			title: 'tracking.title.READY_TO_PICKUP',
			description: 'tracking.description.READY_TO_PICKUP',
		},
	],
	[
		'COLLECTED',
		{
			title: 'tracking.title.COLLECTED',
			description: 'tracking.description.COLLECTED',
		},
	],
]);

interface ITrackingStepper {
	orderTracking: IGetOrderTrackingResponse;
	isWFBB?: boolean;
}

const TrackingStepper: FunctionComponent<ITrackingStepper> = ({ orderTracking, isWFBB }) => {
	const [accordionTitle, setAccordionTitle] = useState('');
	const [accordionDescription, setAccordionDescription] = useState('');
	const { translate } = useTranslate();
	const language = useSelector((state: types.redux.IState) => state.settings.language);

	useEffect(() => {
		const { tracking } = orderTracking ?? { tracking: [] };
		const activeIndex = tracking.findIndex(({ statusTime }) => !statusTime) ?? -1;
		const { title, description } = STEPS_MAP.get(tracking[activeIndex]?.status) ?? {
			title: 'tracking.doneTitle.DELIVERED',
			description: 'tracking.doneDescription.DELIVERED',
		};
		setAccordionTitle(translate(title));
		setAccordionDescription(translate(description));
	}, [orderTracking, language]);

	const getStatus = (status1: string, status2: string): LineStatus => {
		if (status1 && status2) {
			return 'done';
		}
		if (status1 && !status2) {
			return 'inProgress';
		}
		return 'waiting';
	};

	return (
		<Accordion
			title={accordionTitle}
			icon="package"
			iconColor="black38"
			subtitleColor="black54"
			iconSize={42}
			marginLeft={52}
			withoutContainerPadding
			subtitle={accordionDescription}
			constantMargin
			enableSubtitleOnExpand
			titleBold={true}
		>
			<TrackingStepperContainer>
				{orderTracking.tracking.map(({ status, statusTime }, index) => {
					const { title, doneTitle, description, doneDescription } = STEPS_MAP.get(status) ?? {};
					const nextStatusTime = orderTracking.tracking[index + 1]?.statusTime;
					const isLast = orderTracking.tracking.length === index + 1;
					return (
						<Fragment key={status}>
							<ConditionalRender
								show={index === 0}
								onTrue={() => (
									<>
										<Divider marginBottom={32} withoutLine />
										<DescriptionWrapper withMargin>{statusTime}</DescriptionWrapper>
									</>
								)}
							/>
							<StatusContainer>
								<ConditionalRender
									show={!!statusTime}
									onTrue={() => (
										<IconContainer backgroundColor="positive">
											<Icon name="check" fill="white" width={16} height={16} />
										</IconContainer>
									)}
									onFalse={() => (
										<ConditionalRender
											show={!statusTime && !!orderTracking.tracking[index - 1]?.statusTime}
											onTrue={() => <IconContainer backgroundColor="primary" />}
											onFalse={() => <IconContainer inactive />}
										/>
									)}
								/>
								<StatusWrapper>
									{statusTime && doneTitle ? translate(doneTitle) : title && translate(title)}
								</StatusWrapper>
							</StatusContainer>
							<StatusContainer>
								<ConditionalRender
									show={!isLast}
									onTrue={() => (
										<LineContainer>
											<Line status={getStatus(statusTime, nextStatusTime)} />
										</LineContainer>
									)}
								/>
								<DescriptionContainer isLast={isLast}>
									<DescriptionWrapper withMargin={!!(isLast && statusTime)}>
										{statusTime && doneDescription
											? translate(doneDescription)
											: isWFBB
											? ''
											: description && translate(description)}
									</DescriptionWrapper>
									<ConditionalRender
										show={!isLast}
										onTrue={() => (
											<DescriptionWrapper>
												{!!nextStatusTime
													? isWFBB
														? ''
														: nextStatusTime
													: getStatus(statusTime, nextStatusTime) === 'inProgress' &&
													  !(status === 'DELIVERED')
													? isWFBB
														? !statusTime && !!orderTracking.tracking[index - 1]?.statusTime
															? ''
															: translate('tracking.inProgress')
														: translate('tracking.inProgress')
													: ''}
											</DescriptionWrapper>
										)}
									/>
								</DescriptionContainer>
							</StatusContainer>
						</Fragment>
					);
				})}
			</TrackingStepperContainer>
		</Accordion>
	);
};

export default TrackingStepper;
