import ConditionalRender from '@Components/ConditionalRender/ConditionalRender';
import { roundMoney } from '@Utils/converters/roundMoney';
import useTranslate from '@Utils/hooks/useTranslate';
import React, { FunctionComponent } from 'react';
import {
	SmallPrice,
	PriceTagWrapper,
	MainWrapper,
	DiscountPriceWrapper,
	BigPrice,
	BigCurrencyWrapper,
	SmallCurrencyWrapper,
	DiscountWrapper,
	DiscountStrikethroughWrapper,
	AdditionalInfoWrapper,
	Multiplier,
	AdditionalCost,
	DurationWrapper,
	MiniPrice,
	MiniCurrencyWrapper,
	MiniCurrencyBoldWrapperRegular,
	SmallDurationWrapper,
	MiniDurationWrapper,
	MiniPriceRegular,
	MiniCurrencyWrapperRegular,
	MiniDiscountPrice,
	MiniPriceRegularBold,
} from './PriceTag.styled';

interface IPriceTagProps {
	price: number | string | undefined;
	discount?: number;
	size?: 'small' | 'big' | 'mini' | 'miniregular' | 'miniregularbold' | 'medium';
	color?: types.theme.themeColors;
	discountColor?: types.theme.themeColors;
	durationColor?: types.theme.themeColors;
	currency?: string;
	multiplier?: string;
	additionalCost?: string;
	duration?: string;
	isPrepaid?: boolean;
	flexDirection?: 'row' | 'column';
	currencySideLeft?: boolean;
}

const PriceTag: FunctionComponent<IPriceTagProps> = ({
	price,
	discount,
	size = 'small',
	color = 'black87',
	discountColor = 'primary',
	currency = 'omr',
	multiplier,
	durationColor,
	additionalCost,
	duration,
	isPrepaid = false,
	flexDirection = 'row',
	currencySideLeft = false,
}: IPriceTagProps) => {
	const { translate } = useTranslate();
	switch (size) {
		case 'mini': {
			return (
				<PriceTagWrapper isPrepaid={isPrepaid}>
					<AdditionalInfoWrapper>
						<MiniCurrencyWrapper color={color}>{translate(currency)}</MiniCurrencyWrapper>
						<ConditionalRender
							show={!!duration}
							onTrue={() => (
								<MiniDurationWrapper
									className="duration"
									color={!!durationColor ? durationColor : color}
								>
									{duration}
								</MiniDurationWrapper>
							)}
						/>
					</AdditionalInfoWrapper>
					<MiniPrice color={color}>{roundMoney(price)}</MiniPrice>
				</PriceTagWrapper>
			);
		}
		case 'miniregular': {
			return (
				<PriceTagWrapper>
					<AdditionalInfoWrapper>
						<MiniCurrencyWrapperRegular color={color}>{translate(currency)}</MiniCurrencyWrapperRegular>
						<ConditionalRender
							show={!!duration}
							onTrue={() => (
								<MiniDurationWrapper color={!!durationColor ? durationColor : color}>
									{duration}
								</MiniDurationWrapper>
							)}
						/>
					</AdditionalInfoWrapper>
					<MiniPriceRegular color={color}>{roundMoney(price)}</MiniPriceRegular>
				</PriceTagWrapper>
			);
		}
		case 'miniregularbold': {
			return (
				<PriceTagWrapper>
					<AdditionalInfoWrapper>
						<MiniCurrencyBoldWrapperRegular color={color}>
							{translate(currency)}
						</MiniCurrencyBoldWrapperRegular>
						<ConditionalRender
							show={!!duration}
							onTrue={() => (
								<MiniDurationWrapper color={!!durationColor ? durationColor : color}>
									{duration}
								</MiniDurationWrapper>
							)}
						/>
					</AdditionalInfoWrapper>
					<MiniPriceRegularBold color={color}>{roundMoney(price)}</MiniPriceRegularBold>
				</PriceTagWrapper>
			);
		}
		case 'small': {
			return (
				<MainWrapper flexDirection={flexDirection}>
					<DiscountPriceWrapper>
						<ConditionalRender
							show={!!discount}
							onTrue={() => (
								<DiscountStrikethroughWrapper color={discountColor}>
									<MiniDiscountPrice color={discountColor}>{roundMoney(discount)}</MiniDiscountPrice>
								</DiscountStrikethroughWrapper>
							)}
						/>
					</DiscountPriceWrapper>
					<PriceTagWrapper>
						<AdditionalInfoWrapper>
							<SmallCurrencyWrapper color={color}>{translate(currency)}</SmallCurrencyWrapper>
							<ConditionalRender
								show={!!duration}
								onTrue={() => (
									<SmallDurationWrapper color={!!durationColor ? durationColor : color}>
										{duration}
									</SmallDurationWrapper>
								)}
							/>
						</AdditionalInfoWrapper>
						<SmallPrice color={color}>{roundMoney(price)}</SmallPrice>
					</PriceTagWrapper>
				</MainWrapper>
			);
		}
		case 'big': {
			return (
				<PriceTagWrapper>
					<ConditionalRender
						show={!!discount}
						onTrue={() => (
							<DiscountWrapper>
								<BigPrice color="grey300">{roundMoney(discount ?? 0)}</BigPrice>
								<BigCurrencyWrapper color="grey300">{translate(currency)}</BigCurrencyWrapper>
							</DiscountWrapper>
						)}
					/>
					<AdditionalInfoWrapper>
						<BigCurrencyWrapper color={color}>{translate(currency)}</BigCurrencyWrapper>
						<ConditionalRender
							show={!!duration}
							onTrue={() => (
								<DurationWrapper color={!!durationColor ? durationColor : color}>
									{duration}
								</DurationWrapper>
							)}
						/>
					</AdditionalInfoWrapper>
					<BigPrice color={color}>{roundMoney(price)}</BigPrice>

					<AdditionalInfoWrapper>
						<ConditionalRender show={!!multiplier} onTrue={() => <Multiplier>{multiplier}</Multiplier>} />
						<ConditionalRender
							show={!!additionalCost}
							onTrue={() => <AdditionalCost>{additionalCost}</AdditionalCost>}
						/>
					</AdditionalInfoWrapper>
				</PriceTagWrapper>
			);
		}
		case 'medium': {
			return (
				<MainWrapper flexDirection={flexDirection}>
					<PriceTagWrapper>
						<ConditionalRender
							show={currencySideLeft}
							onTrue={() => (
								<AdditionalInfoWrapper>
									<SmallCurrencyWrapper color={color}>{translate(currency)}</SmallCurrencyWrapper>
									<ConditionalRender
										show={!!duration}
										onTrue={() => (
											<SmallDurationWrapper color={!!durationColor ? durationColor : color}>
												{duration}
											</SmallDurationWrapper>
										)}
									/>
								</AdditionalInfoWrapper>
							)}
						/>
						<ConditionalRender
							show={!!discount}
							onTrue={() => (
								<DiscountStrikethroughWrapper color={discountColor}>
									<SmallPrice color={color}>{roundMoney(price)}</SmallPrice>
								</DiscountStrikethroughWrapper>
							)}
							onFalse={() => <SmallPrice color={color}>{roundMoney(price)}</SmallPrice>}
						/>
						<ConditionalRender
							show={!currencySideLeft}
							onTrue={() => (
								<AdditionalInfoWrapper>
									<SmallCurrencyWrapper color={color}>{translate(currency)}</SmallCurrencyWrapper>
									<ConditionalRender
										show={!!duration}
										onTrue={() => (
											<SmallDurationWrapper color={!!durationColor ? durationColor : color}>
												{duration}
											</SmallDurationWrapper>
										)}
									/>
								</AdditionalInfoWrapper>
							)}
						/>
					</PriceTagWrapper>
				</MainWrapper>
			);
		}
	}
};

export default PriceTag;
