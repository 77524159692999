const config: types.apiConfig.IConfigType = {
	defaultCacheTime: 1800000, // ms (30 min)
	minDelayBetweenSameRequests: 30000, // ms (30 sec)
	minDelayBetweenSameRequestsWhenError: 30000, // ms (30 sec)
	timeout: 120000, // ms (2 min)
	inventoryCacheTime: 1800000, // ms (30 min)
	marketingBannersCacheTime: 3600000, // ms (60 min)
	productByIdCacheTime: 3600000, // ms (60 min)
};

export default config;
