import { BodyS } from '@Components/Typography/Typography.styled';
import { matchScreen } from '@Utils/layout/layoutSupport';
import styled from 'styled-components';

export const CopyToClipboard = styled.div`
	align-self: stretch;
	align-items: center;
	display: flex;
	justify-content: space-between;
	padding: 14px 16px 8px 16px;
	border-radius: 4px;
	${({ theme }) => ({
		border: `solid 1px ${theme.colors.primary}`,
		backgroundColor: theme.colors.primary6,
	})}
	${() => {
		return { flexDirection: matchScreen('xs') ? 'column' : 'row' };
	}}
`;

export const CopyToClipboardClick = styled.div`
	cursor: pointer;
`;

export const SerialNumAndExpiryDate = styled.div`
	display: flex;
	justify-content: center;
`;

export const SerialNum = styled(BodyS)`
	${({ theme }) => ({
		color: theme.colors.black54,
	})}
	margin-left: 8px;
	margin-right: 8px;
`;
