import { useState } from 'react';
import { AccountsService } from '@Services/accounts/accountsService';

interface IUseContactPhoneNumber {
	contactPhoneNumber?: string;
	getContactPhoneNumber?: (telesalesPhoneNumber: string) => void;
}

const OMANTEL_ACCOUNT_GROUP_CAT_5 = '5';
const OMANTEL_ACCOUNT_GROUP_CAT_9 = '9';

const useContactPhoneNumber = (): IUseContactPhoneNumber => {
	const [contactPhoneNumber, setContactPhoneNumber] = useState<string>();

	const getContactPhoneNumber = (telesalesPhoneNumber: string) => {
		AccountsService.getCustomerAccountOperation({
			doNotGetPinPukDetails: 'true',
			forceNumber: telesalesPhoneNumber,
		}).subscribe(
			(res) => {
				const contactData = res?.customerAccount?.customer?.relatedParty?.individual?.contactMedium;
				const customerAccountData = res?.customerAccount?.customer?.customerAccount;
				const productSpecification = res?.customerAccount?.product?.productSpecification;
				const isConsumer = Array.isArray(customerAccountData)
					? !!customerAccountData[0]?.customer?.characteristic?.find((item) => item.value === 'CONSUMER')
					: !!customerAccountData?.customer.characteristic?.find((item) => item.value === 'CONSUMER');
				const isEmployee = Array.isArray(customerAccountData)
					? !!customerAccountData[0]?.customer?.characteristic?.find(
							(item) =>
								item.name === 'ACCOUNT_CATEGORY' &&
								(item.value === OMANTEL_ACCOUNT_GROUP_CAT_5 ||
									item.value === OMANTEL_ACCOUNT_GROUP_CAT_9)
					  )
					: !!customerAccountData?.customer.characteristic?.find(
							(item) =>
								item.name === 'ACCOUNT_CATEGORY' &&
								(item.value === OMANTEL_ACCOUNT_GROUP_CAT_5 ||
									item.value === OMANTEL_ACCOUNT_GROUP_CAT_9)
					  );
				const isBaqatiNumber =
					productSpecification?.productCategory?.type === 'MOBILE' &&
					productSpecification?.productSpecCharacteristic.productSpecCharacteristicValue?.value ===
						'POSTPAID' &&
					(isConsumer || isEmployee);
				if (isBaqatiNumber) {
					setContactPhoneNumber(telesalesPhoneNumber);
				} else if (!!contactData) {
					const findedPhoneNumber = contactData?.find((item) => item.type === 'PHONE_NUMBER');
					setContactPhoneNumber(findedPhoneNumber?.medium?.number ?? telesalesPhoneNumber);
				} else {
					setContactPhoneNumber(telesalesPhoneNumber);
				}
			},
			() => {
				setContactPhoneNumber(telesalesPhoneNumber);
			}
		);
	};

	return { contactPhoneNumber, getContactPhoneNumber };
};

export default useContactPhoneNumber;
