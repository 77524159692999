export interface IChooseShipment {
	orderItem: IOrderItem[];
	orderId: string;
	resourceName: string;
}

interface IOrderItem {
	orderItemId: string;
}

const model: types.models.IInputModel = {
	orderItem: ['response.orderItem', []],
	orderId: 'response.orderId',
	resourceName: 'response.resourceName',
};

export default model;
