import React, { FunctionComponent } from 'react';
import {
	CardContainer,
	ImageWrapper,
	CartTitle,
	CartDescription,
	Highlighted,
	ActionWrapper,
} from '@Components/Card/cards/NotifyAvailability/NotifyAvailability.styled';
import useTranslate from '@Utils/hooks/useTranslate';
import { PrimaryMediumButtonFixed } from '@Components/controls/Button/Button';
import Icon from '@Components/Icon/Icon';
import { setModal } from '@Redux/modules/modal/actions';
import { useDispatch } from 'react-redux';

const ICON_SIZE = 96;

const NotifyAvailabilityCard: FunctionComponent<types.cards.INotifyAvailabilityCardProps> = ({
	params: { email, phoneNumber },
}) => {
	const { translate } = useTranslate();
	const dispatch = useDispatch();

	const handleClose = () => {
		dispatch(
			setModal({
				type: null,
				data: null,
			})
		);
	};
	const getDescription = () => {
		if (!!email) {
			return (
				<>
					{translate('notify-card.description.email.part-1')} <Highlighted>{`(${email})`}</Highlighted>
					{translate('notify-card.description.email.part-2')}
				</>
			);
		} else if (!!phoneNumber) {
			return (
				<>
					{translate('notify-card.description.number.part-1')} <Highlighted>{`(${phoneNumber})`}</Highlighted>
					{translate('notify-card.description.number.part-2')}
				</>
			);
		}
		return;
	};

	return (
		<CardContainer>
			<ImageWrapper>
				<Icon name="notifyBell" width={ICON_SIZE} height={ICON_SIZE} fill="black38" />
			</ImageWrapper>
			<CartTitle>{translate('notify-card.title')}</CartTitle>
			<CartDescription>{getDescription()}</CartDescription>
			<ActionWrapper>
				<PrimaryMediumButtonFixed uniqueId="modal.close.notify.card" onClick={handleClose}>
					{translate('close')}
				</PrimaryMediumButtonFixed>
			</ActionWrapper>
		</CardContainer>
	);
};
export default NotifyAvailabilityCard;
