import React from 'react';
import ConditionalRender from '@Components/ConditionalRender/ConditionalRender';
import { useInsurance } from '@Utils/context/InsuranceContext';
import {
	Divider,
	BackButtonWrapper,
	ButtonWrapper,
	InsuranceStepperContainer,
	Button,
} from './InsuranceStepper.styled';
import Step from './Step/Step';
import useTranslate from '@Utils/hooks/useTranslate';
import { Col, Row, Visible } from 'react-grid-system';
import { NUMBER_OF_STEPS } from '@Config/app.config';
import { useHistory } from 'react-router-dom';

export const INSURANCE_STEPPER_BUTTON_BI = 'insuranceStepperButton';

const InsuranceStepperCard = (): JSX.Element => {
	const { activeIndex, steps, mainColor, prevStep } = useInsurance();
	const { translate } = useTranslate();
	const history = useHistory();

	return (
		<InsuranceStepperContainer>
			<ConditionalRender
				show={activeIndex < NUMBER_OF_STEPS}
				onTrue={() => (
					<Row align="center" justify="center" nogutter>
						{steps.map(({ iconName, description }, index) => (
							<Col key={`col-${index}`} xs={activeIndex === index ? 4 : 2}>
								<Step
									iconName={iconName}
									active={activeIndex === index}
									description={translate(description)}
									checked={activeIndex > index}
									mainColor={mainColor}
								/>
							</Col>
						))}
						<Visible md lg xl>
							<Col sm={2} key="col-back-button">
								<BackButtonWrapper>
									<Divider />
									<ButtonWrapper>
										<Button
											onClick={() => {
												prevStep();
												history.goBack();
											}}
											activeIndex={activeIndex}
										>
											{translate('insurance.step.back')}
										</Button>
									</ButtonWrapper>
								</BackButtonWrapper>
							</Col>
						</Visible>
					</Row>
				)}
			/>
		</InsuranceStepperContainer>
	);
};

export default InsuranceStepperCard;
