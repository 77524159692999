import RxQuery from '@Utils/api/rxQuery/rxQuery';
import endpoints from '@Utils/api/endpoints';
import { ICalculateInstallemntRequest } from '@ApiModels/calculateInstallment';

const microApi = () => new RxQuery('microAPI');

export const getSmartVoucher = (cr: string, phone: string): RxQuery => {
	return microApi()
		.setEndpoint(endpoints.microApi.getSmartVoucher({ cr, phone }))
		.disableCache()
		.setType('getSmartVoucher');
};

export const calculateInstallment = (request: ICalculateInstallemntRequest): RxQuery => {
	return microApi()
		.setEndpoint(endpoints.microApi.calculateInstallment())
		.setType('calculateInstallment')
		.disableCache()
		.setPayload((request as unknown) as any);
};
