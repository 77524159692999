import React, { FunctionComponent } from 'react';
import {
	ActionWrapper,
	DeleteAction,
	DeleteActionWrapper,
	PaymentInformation,
	PaymentTokenCardNumber,
	PaymentTokenContainer,
	PaymentTokenItemWrapper,
	SelectedText,
} from './PaymentTokenItem.styled';
import Icon from '@Components/Icon/Icon';
import ConditionalRender from '@Components/ConditionalRender/ConditionalRender';
import Toggle from '@Components/controls/Toggle/Toggle';
import useTranslate from '@Utils/hooks/useTranslate';
import { IPaymentToken } from '@ApiModels/paymentTokens';
import { useDispatch } from 'react-redux';
import { modalTypes, setModal } from '@Redux/modules/modal/actions';

interface IPaymentTokenItem {
	token: IPaymentToken;
	active?: boolean;
	showDelete?: boolean;
	showSelect?: boolean;
	handleOnChange?: (id: string) => void;
	prepaidProcessCheckout?: boolean;
}

const PaymentTokenItem: FunctionComponent<IPaymentTokenItem> = ({
	token,
	active = false,
	showDelete = false,
	showSelect = false,
	handleOnChange = (_: string) => {},
	prepaidProcessCheckout,
}) => {
	const { translate } = useTranslate();
	const dispatch = useDispatch();

	const handleOnDelete = () => {
		dispatch(setModal({ type: modalTypes.DELETE_PAYMENT_TOKEN, data: { token } }));
	};

	return (
		<PaymentTokenItemWrapper>
			<PaymentTokenContainer>
				<PaymentInformation>
					<Icon width={48} height={48} name={token.cardType.toLocaleLowerCase() as types.iconNames} />
					<PaymentTokenCardNumber>{token.displayNumber}</PaymentTokenCardNumber>
				</PaymentInformation>
				<ConditionalRender
					show={showSelect}
					onTrue={() => (
						<ActionWrapper>
							<ConditionalRender
								show={!prepaidProcessCheckout}
								onTrue={() => (
									<SelectedText>{translate('secure-checkout.payment-tokens.selected')}</SelectedText>
								)}
							/>
							<Toggle active={active} onChange={() => handleOnChange(token.id)} />
						</ActionWrapper>
					)}
				/>
			</PaymentTokenContainer>

			<ConditionalRender
				show={showDelete}
				onTrue={() => (
					<DeleteActionWrapper>
						<DeleteAction onClick={handleOnDelete}>
							{translate('secure-checkout.payment-tokens.delete')}
						</DeleteAction>
					</DeleteActionWrapper>
				)}
			/>
		</PaymentTokenItemWrapper>
	);
};

export default PaymentTokenItem;
