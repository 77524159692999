import { createGlobalStyle } from 'styled-components';
import theme from '@Utils/theme/theme';

export default createGlobalStyle`    
    @font-face {
        font-family: 'IBMPlexSans-Bold';
        src: url('./fonts/IBMPlexSans-Bold.ttf') format('truetype');
        font-weight: normal;
        font-style: normal;
    }
    @font-face {
        font-family: 'IBMPlexSans-ExtraLight';
        src: url('./fonts/IBMPlexSans-ExtraLight.ttf') format('truetype');
        font-weight: normal;
        font-style: normal;
    }
    @font-face {
        font-family: 'IBMPlexSans-Light';
        src: url('./fonts/IBMPlexSans-Light.ttf') format('truetype');
        font-weight: normal;
        font-style: normal;
    }
    @font-face {
        font-family: 'IBMPlexSans-Medium';
        src: url('./fonts/IBMPlexSans-Medium.ttf') format('truetype');
        font-weight: normal;
        font-style: normal;
    }
    @font-face {
        font-family: 'IBMPlexSans-Regular';
        src: url('./fonts/IBMPlexSans-Regular.ttf') format('truetype');
        font-weight: normal;
        font-style: normal;
    }
    @font-face {
        font-family: 'IBMPlexSans-SemiBold';
        src: url('./fonts/IBMPlexSans-SemiBold.ttf') format('truetype');
        font-weight: normal;
        font-style: normal;
    }
    @font-face {
        font-family: 'IBMPlexSansArabic-Thin';
        src: url('./fonts/IBMPlexSansArabic-Thin.ttf') format('truetype');
        font-weight: normal;
        font-style: normal;
    }
    @font-face {
        font-family: 'IBMPlexSansArabic-Bold';
        src: url('./fonts/IBMPlexSansArabic-Bold.ttf') format('truetype');
        font-weight: normal;
        font-style: normal;
    }
    @font-face {
        font-family: 'IBMPlexSansArabic-ExtraLight';
        src: url('./fonts/IBMPlexSansArabic-ExtraLight.ttf') format('truetype');
        font-weight: normal;
        font-style: normal;
    }
    @font-face {
        font-family: 'IBMPlexSansArabic-Light';
        src: url('./fonts/IBMPlexSansArabic-Light.ttf') format('truetype');
        font-weight: normal;
        font-style: normal;
    }
    @font-face {
        font-family: 'IBMPlexSansArabic-Medium';
        src: url('./fonts/IBMPlexSansArabic-Medium.ttf') format('truetype');
        font-weight: normal;
        font-style: normal;
    }
    @font-face {
        font-family: 'IBMPlexSansArabic-Regular';
        src: url('./fonts/IBMPlexSansArabic-Regular.ttf') format('truetype');
        font-weight: normal;
        font-style: normal;
    }
    @font-face {
        font-family: 'IBMPlexSansArabic-SemiBold';
        src: url('./fonts/IBMPlexSansArabic-SemiBold.ttf') format('truetype');
        font-weight: normal;
        font-style: normal;
    }
    @font-face {
        font-family: 'IBMPlexSans-Thin';
        src: url('./fonts/IBMPlexSans-Thin.ttf') format('truetype');
        font-weight: normal;
        font-style: normal;
    }
    @font-face {
        font-family: 'FSAlbertArabic-ExtraBold';
        src: url('./fonts/FSAlbertArabic-ExtraBold.eot?') format('eot'), url('./fonts/FSAlbertArabic-ExtraBold.ttf') format('truetype');
        font-weight: normal;
        font-style: normal;
    }
    @font-face {
        font-family: 'FSAlbertArabic-Bold';
        src: url('./fonts/FSAlbertArabic-Bold.eot?') format('eot'), url('./fonts/FSAlbertArabic-Bold.ttf') format('truetype');
        font-weight: normal;
        font-style: normal;
    }
    @font-face {
        font-family: 'FSAlbertArabic-Regular';
        src: url('./fonts/FSAlbertArabic-Regular.eot?') format('eot'), url('./fonts/FSAlbertArabic-Regular.ttf') format('truetype');
        font-weight: normal;
        font-style: normal;
    }
    @font-face {
        font-family: 'FSAlbertArabic-Light';
        src: url('./fonts/FSAlbertArabic-Light.eot?') format('eot'), url('./fonts/FSAlbertArabic-Light.ttf') format('truetype');
        font-weight: normal;
        font-style: normal;
    }
    @font-face {
        font-family: 'FSAlbert-ExtraBold';
        src: url('./fonts/FSAlbert-ExtraBold.eot?') format('eot'), url('./fonts/FSAlbert-ExtraBold.ttf') format('truetype');
        font-weight: normal;
        font-style: normal;
    }
    @font-face {
        font-family: 'FSAlbert-Bold';
        src: url('./fonts/FSAlbert-Bold.eot?') format('eot'), url('./fonts/FSAlbert-Bold.ttf') format('truetype');
        font-weight: normal;
        font-style: normal;
    }
    @font-face {
        font-family: 'FSAlbert-Regular';
        src: url('./fonts/FSAlbert-Regular.eot?') format('eot'), url('./fonts/FSAlbert-Regular.ttf') format('truetype');
        font-weight: normal;
        font-style: normal;
    }
    @font-face {
        font-family: 'FSAlbert-Light';
        src: url('./fonts/FSAlbert-Light.eot?') format('eot'), url('./fonts/FSAlbert-Light.ttf') format('truetype');
        font-weight: normal;
        font-style: normal;
    }
    @font-face {
        font-family: 'FSAlvar-Bold';
        src: url('./fonts/FSAlvar-Bold.eot?') format('eot'), url('./fonts/FSAlvar-Bold.ttf') format('truetype');
        font-weight: normal;
        font-style: normal;
    }
    @font-face {
        font-family: 'FSAlvar-Regular';
        src: url('./fonts/FSAlvar-Regular.eot?') format('eot'), url('./fonts/FSAlvar-Regular.ttf') format('truetype');
        font-weight: normal;
        font-style: normal;
    }
    @font-face {
        font-family: 'FSAlvar-Light';
        src: url('./fonts/FSAlvar-Light.eot?') format('eot'), url('./fonts/FSAlvar-Light.ttf') format('truetype');
        font-weight: normal;
        font-style: normal;
    }

    ::-webkit-scrollbar {
		width: 12px;
		height: 12px;
	}

	::-webkit-scrollbar-thumb {
		border-radius: 12px;
		transition: all .2s ease-in-out;
		box-shadow: inset 0 0 10px 10px ${theme.colors.black12};
		border: solid 4px transparent;
	}
	::-webkit-scrollbar-thumb:hover {
	  	box-shadow: inset 0 0 10px 10px ${theme.colors.black24};
	}
	::-webkit-scrollbar-thumb:active {
	 	 box-shadow: inset 0 0 10px 10px ${theme.colors.black38};
	}
	::-webkit-scrollbar-track {
	  	box-shadow: inset 0 0 10px 10px transparent;
	  	border: solid 4px transparent;
	}
    ::-ms-reveal {
      display: none;
    }

	body {
		margin: 0;
        background-color: ${theme.colors.background};
        a {
            text-decoration: none;
        }
	}

    html, body {
        max-width: 100%;
        scrollbar-width: thin;
        overflow-x: hidden;
    }
`;
