import React, { FunctionComponent } from 'react';
import { LabelWidth } from '@Components/controls/Tabs/Tabs.styled';
import { Label, Container, LabelWrapper } from './SecondaryLabel.styled';

interface ISecondaryLabelProps {
	label: string;
	labelWidth: LabelWidth;
	active: boolean;
	setActiveItem: () => void;
	disabled?: boolean;
}

const SecondaryLabel: FunctionComponent<ISecondaryLabelProps> = ({
	label,
	labelWidth,
	active,
	setActiveItem,
	disabled,
}) => {
	return (
		<Container labelWidth={labelWidth} active={active} disabled={!!disabled} onClick={setActiveItem}>
			<LabelWrapper labelWidth={labelWidth} active={active} disabled={!!disabled}>
				<Label active={active} disabled={!!disabled}>
					{label}
				</Label>
			</LabelWrapper>
		</Container>
	);
};

export default SecondaryLabel;
