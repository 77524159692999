import styled from 'styled-components';
import { DisplayMBold, Header, BodySHigher, CaptionBigger, ParagraphS } from '@Components/Typography/Typography.styled';
import { marginLeft, marginRight, matchScreen, paddingLeft } from '@Utils/layout/layoutSupport';
import * as cssTypes from 'csstype';
import { Row } from 'react-grid-system';

export const LeftContainer = styled.div<{ mobileMenu: boolean; isMd: boolean }>`
	padding-top: 32px;
	display: flex;
	flex-direction: column;
	${({ mobileMenu }) => ({
		width: '100%',
		flex: mobileMenu ? 'unset' : 3,
	})}
	${({ mobileMenu }) => paddingLeft(mobileMenu ? 16 : 0)}
	${({ isMd }) =>
		isMd
			? {
					height: 'calc(100% - 68px - 32px)',
			  }
			: {}}
`;

export const TitleWrapper = styled.div`
	display: flex;
	flex-direction: row;
	max-width: calc(100% - 32px);
	margin-bottom: 32px;
	justify-content: space-between;
	${({ theme }) => ({
		borderBottom: '1px solid',
		borderColor: theme.colors.grey300,
	})};
`;

export const Wrapper = styled.div<{ mobileMenu: boolean; isMd: boolean; isXs: boolean; isSm: boolean }>`
	${({ mobileMenu, isMd }) => {
		const isXsSM = matchScreen(['xs', 'sm']);
		const properStyles = isMd
			? { height: '100%', width: '100%' }
			: mobileMenu
			? {
					overflowY: !isXsSM ? ('scroll' as cssTypes.Property.OverflowY) : 'unset',
					overflowX: !isXsSM ? ('hidden' as cssTypes.Property.OverflowY) : 'unset',
					width: '100%',
					height: !isXsSM ? 'calc(100vh - 152px)' : 'unset',
			  }
			: { display: 'flex', height: '100%', width: '100%' };
		return properStyles;
	}}
	margin-bottom: 16px;
	::-webkit-scrollbar {
		display: none;
	}
	${({ isXs, isSm }) =>
		isXs && !isSm
			? {
					flexDirection: 'column',
					height: '100%',
					width: '100%',
					minHeight: '600px;',
			  }
			: {
					flexDirection: 'row',
					height: '100%',
					width: '100%',
			  }}
`;

export const LeftSectionWrapper = styled(Row)`
	margin-right: 16px;
	margin-top: 24px;
	width: 100%;
`;

export const HeaderWrapper = styled(Header)`
	${({ theme }) => ({
		color: theme.colors.black87,
		marginBottom: '16px',
	})}
`;

export const ButtonsContainer = styled.div<{ isLG?: boolean; isLGMD?: boolean }>`
	display: flex;
	${({}) => {
		const isXl = matchScreen(['xl']);
		return {
			gap: isXl ? '8px' : '0',
		};
	}}
	${({ isLG, isLGMD }) => ({
		width: isLG ? '376px' : isLGMD ? '330px' : undefined,
		flexDirection: isLGMD ? 'row' : 'column',
	})}
`;

export const ValueWrapper = styled(DisplayMBold)<{ icon?: boolean }>`
	${({ theme }) => ({ color: theme.colors.black87 })}
	${({ icon }) => icon && { margin: `0 8px`, marginTop: 4 }}
`;

export const UnlimitedContainer = styled.div`
	display: flex;
	flex-direction: row;
`;

export const UserNameLabelWrapper = styled.div`
	display: flex;
	fled-direction: row;
	align-items: center;
`;

export const UserIconWrapper = styled.div`
	${() => marginLeft('8px')};
`;

export const TopSectionWrapper = styled.div<{ isXS?: boolean }>`
	display: flex;
	flex-direction: row;
	${({ isXS }) => (isXS ? 'justify-content:center;' : 'justify-content:space-between;')}
	${() => marginRight(24)}
	align-items: center;
`;

export const RocketWrapper = styled.div`
	position: absolute;
	width: 94.52px;
	height: 199.74px;
	left: calc(50% - 94.52px / 2 + 0.08px);
	top: calc(50% - 199.74px / 2 - 2.77px);
`;

export const RocketShadowWrapper = styled.div`
	position: absolute;
	width: 291.44px;
	height: 100.06px;
	left: calc(50% - 291.44px / 2 + 95.72px);
	top: calc(50% - 100.06px / 2 + 56.6px);
	z-index: 1;
`;

export const RightContentWrapper = styled.div<{ isxl?: boolean; isMd?: boolean; isSm?: boolean; isXs?: boolean }>`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	${({ isxl, isXs, isMd, isSm }) => ({
		position: isxl ? 'relative' : 'static',
		marginTop: isXs ? '24px' : isSm ? '-30px' : '-4px',
		height: isMd || isSm ? '100%' : 'unset',
	})}
	${({ isxl, isXs, isMd, isSm }) => marginLeft(isxl ? '42px' : isMd ? '0' : isSm ? '0' : isXs ? '0' : '-0')}
	z-index: 100;
`;

export const LabelWrapper = styled.div`
	margin-bottom: 8px;
`;

export const CreateAccountLabelWrapper = styled.div<{ isXS?: boolean }>`
	margin-bottom: 16px;
	text-align: center;
	${({ isXS }) => ({
		marginTop: isXS ? '24px' : '0px',
	})}
`;

export const IconWrapper = styled.div`
	margin-top: 2px;
	margin-left: 4px;
`;

export const ForgotButtonContainer = styled.div`
	display: flex;
	justify-content: center;
	flex-direction: row;
	white-space: nowrap;
`;

export const MegaMenuCustomButton = styled.div<{
	customWidth?: string;
	secondary?: boolean;
	primaryBackground?: boolean;
	backgroundColor?: types.theme.themeColors;
	padding?: string;
	height?: string;
	justifyContent?: string;
}>`
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 14px 16px;
	${({ customWidth, primaryBackground, theme, backgroundColor, padding, height, justifyContent }) => ({
		justifyContent,
		height,
		padding,
		width: customWidth ? customWidth : undefined,
		backgroundColor: backgroundColor
			? theme.colors[backgroundColor]
			: primaryBackground
			? theme.colors.primary
			: '#FCF3E6',
	})}
	border-radius: 4px;
	&:hover {
		${({ theme, primaryBackground }) => {
			return {
				backgroundColor: primaryBackground ? theme.colors.primaryHover : theme.colors.primary6,
				cursor: 'pointer',
			};
		}}}
	}
	&:active {
		${({ theme, primaryBackground }) => {
			return {
				backgroundColor: primaryBackground ? theme.colors.primaryPressed : theme.colors.primary15,
			};
		}}
		}}
	}
	${({ theme, secondary }) => {
		if (secondary) {
			return {
				border: `solid 2px ${theme.colors.primary}`,
			};
		}
	}}
`;

export const LoggedInfoBoxWrapper = styled.div<{ isXS?: boolean }>`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 16px;
	gap: 8px;
	border-radius: 8px;
	height 72px;
	text-align: center;
	${({ theme, isXS }) => ({ backgroundColor: theme.colors.white, margin: isXS ? '0px -7.5px 16px' : '0px -7.5px' })}
`;

export const SmallTitleWrapper = styled(BodySHigher)`
	margin-bottom: 8px;
`;

export const LoggedButtonsWrapper = styled.div<{ isXS?: boolean }>`
height 72px;
margin: 0px -7.5px;
gap: 8px;
display: flex;
justify-content: center;
align-items: center;
padding:16px;
${({ isXS }) => ({
	flexDirection: isXS ? 'row' : 'column',
})}
`;

export const CheckyourEmailWrapper = styled.div<{ margin?: string }>`
	display: flex;
	flex-direction: row:
	justify-content: center;
	align-items: center;
	cursor: pointer;
	${({ margin }) => ({
		margin: margin ? margin : '16px 0 0 0',
	})}
`;

export const StoreOrdersWrapper = styled.div`
cursor: pointer;
display: flex;
flex-direction: row:
justify-content: center;
align-items: center;

`;

export const ButtonIconWrapper = styled.div`
	${() => marginRight(4)}
	margin-top: 2px;
	display: flex;
	justify-content: center;
	align-items: center;
`;

export const MakasibLabelWrapper = styled.div<{ isXS?: boolean; isSM?: boolean }>`
	margin: 13px -7.5px 0px;
	display: flex;
	${({ isXS, isSM }) => ({
		justifyContent: isXS ? 'center' : 'flex-start',
		flexDirection: isSM ? 'column' : 'row',
		alignItems: isSM ? 'center' : 'unset',
	})}
`;

export const TierLabelWrapper = styled(CaptionBigger)<{ makasibColor: string; makasibBorder: string }>`
	margin-top: 3px;
	${({ makasibColor, makasibBorder }) => ({
		border: `1px solid`,
		background: makasibColor,
		borderColor: makasibBorder,
	})}
	border-radius: 4px;
	padding: 0px 8px 0px 8px;
	height: 16px;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	background-clip: text;
	text-fill-color: transparent;
`;

export const EmailOrderWrapper = styled.div<{ isXs?: boolean }>`
	display: flex;
	flex-direction: row;
	margin-top: 34px;
	margin-bottom: 24px;
	${({ isXs }) => ({ marginLeft: isXs ? '24px' : '0' })}
`;

export const PointsWrapper = styled(ParagraphS)`
	margin-top: 0px;
	margin-left: 8px;
	${({ theme }) => ({ color: theme.colors.black54 })}
	height: 24px;
	margin-bottom: 16px;
`;

export const ButtonWrapper = styled.div`
	width: 100%;
	margin-bottom: 16px;
`;

export const ButtonLabelWrapper = styled.div`
	white-space: nowrap;
`;
