import immerHandleActions from '@Redux/utils/immerHandleActions';
import { pushRequestInProgress, pullRequestInProgress } from './actions';

interface IRequestInProgress extends types.redux.api.requestInProgress.IRequestInProgress {}
interface IRequestInProgressAction extends types.redux.api.requestInProgress.IRequestInProgressAction {}

const reducers = {
	[pushRequestInProgress.toString()](draft: IRequestInProgress[], action: IRequestInProgressAction) {
		const requestInProgress = action.payload;
		if (
			!draft.find((b) => {
				return b.url === requestInProgress.url && b.payloadHash === requestInProgress.payloadHash;
			})
		) {
			draft.push(requestInProgress);
		}
		return draft;
	},
	[pullRequestInProgress.toString()](draft: IRequestInProgress[], action: IRequestInProgressAction) {
		const requestInProgress = action.payload;
		draft = draft.filter((b) => b.url !== requestInProgress.url && b.payloadHash !== requestInProgress.payloadHash);
		return draft;
	},
};

const init: IRequestInProgress[] = [];

export default immerHandleActions(reducers, init);
