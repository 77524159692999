import React, { FunctionComponent } from 'react';
import { SkeletonRow, Rectangular } from '@Components/Skeleton/Skeleton.styled';
import { matchScreen } from '@Utils/layout/layoutSupport';

const DisplayMBoldMyOmantelSkeleton: FunctionComponent = () => {
	const isXs = matchScreen('xs');
	const isSm = matchScreen('sm');
	const isMd = matchScreen('md');
	const width = isXs ? 200 : isSm ? 80 : isMd ? 100 : 160;

	return (
		<SkeletonRow marginBottom={16}>
			<Rectangular width={width} height={40} color="grey300" />
		</SkeletonRow>
	);
};

export default DisplayMBoldMyOmantelSkeleton;
