import { ParagraphS, ParagraphSBold, TitleBold } from '@Components/Typography/Typography';
import { marginRight } from '@Utils/layout/layoutSupport';
import { Row } from 'react-grid-system';
import styled from 'styled-components';

export const CheckoutInfoCardContainer = styled(Row)`
	padding: 8px 16px;
`;

export const TitleWrapper = styled(TitleBold)`
	${({ theme }) => ({ color: theme.colors.black87 })}
	margin-bottom: 16px;
`;

export const DescriptionWrapper = styled(ParagraphS)`
	${({ theme }) => ({ color: theme.colors.black87 })}
	margin-bottom: 4px;
`;

export const DescriptionContainer = styled(Row)``;

export const ListItem = styled.li`
	margin: 0;
	padding: 0;
`;

export const List = styled.ul`
	margin: 0;
	padding: 0 0 0 15px;
`;

export const BoldDescriptionWrapper = styled(ParagraphSBold)`
	${({ theme }) => ({ color: theme.colors.black87 })}
	margin-bottom: 4px;
`;

export const VerticalDivider = styled.div<{ wihoutMargin?: boolean }>`
	display: inline-block;
	width: 1px;
	height: 100%;
	margin: 0;
	border-radius: 2px;
	background-color: ${({ theme }) => theme.colors.black12}};
	margin-left: ${({ wihoutMargin }) => (wihoutMargin ? 0 : 24)}px;
	`;

export const CardIconsContainer = styled(Row)`
	margin-top: 12px;
`;

export const IconWrapper = styled.div`
	overflow: hidden;
	display: flex;
	align-items: center;
	width: 56px;
	height: 32px;
	border: solid 1px;
	${({ theme }) => ({
		borderColor: theme.colors.grey300,
	})}
	${() => marginRight(8)}
`;

export const InfoWrapper = styled.div<{ additional?: boolean }>`
	display: flex;
	height: 100%;
	${({ additional }) => ({
		justifyContent: additional ? 'start' : 'space-between',
	})};
`;

export const ColContent = styled.div`
	display: flex;
`;
