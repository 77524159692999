import * as Yup from 'yup';
import useTranslate from '@Utils/hooks/useTranslate';

export default (): Yup.AnyObjectSchema => {
	const { translate } = useTranslate();

	const shape = {
		phoneNumber: Yup.string()
			.required(translate('validate.personal.data.phone.number.required'))
			.length(8, translate('validate.personal.data.oman.format.phone.number')),
	};

	return Yup.object().shape(shape);
};
