import Icon from '@Components/Icon/Icon';
import React, { FunctionComponent } from 'react';
import {
	AutoCompleteListContainer,
	PredictionItemContainer,
	ValueBoldWrapper,
	ValuesContainer,
	ValueWrapper,
} from './AutoCompleteList.styled';

interface IAutoCompleteListProps {
	predictions: google.maps.places.AutocompletePrediction[];
	onSelect: (id: google.maps.places.AutocompletePrediction) => void;
}

interface IPredictionItemProps {
	prediction: google.maps.places.AutocompletePrediction;
}

const ICON_SIZE = 24;

const AutoCompleteList: FunctionComponent<IAutoCompleteListProps> = ({ predictions, onSelect }) => {
	const PredictionItem: FunctionComponent<IPredictionItemProps> = ({ prediction }) => {
		return (
			<PredictionItemContainer
				onClick={() => {
					onSelect(prediction);
				}}
			>
				<Icon name="locationPin" width={ICON_SIZE} height={ICON_SIZE} fill="black38" />
				<ValuesContainer>
					<ValueBoldWrapper>{prediction.structured_formatting.main_text}</ValueBoldWrapper>
					<ValueWrapper>{prediction.structured_formatting.secondary_text}</ValueWrapper>
				</ValuesContainer>
			</PredictionItemContainer>
		);
	};
	return (
		<AutoCompleteListContainer>
			{predictions.map((prediction, index) => (
				<PredictionItem key={index} prediction={prediction} />
			))}
		</AutoCompleteListContainer>
	);
};

export default AutoCompleteList;
