import { IPriceByCatEntry } from '@ApiModels/priceByCatEntry';

export interface IBuyNowPayLaterInstallmentResponse extends IPriceByCatEntry {
	resourceName: string;
}

const model: types.models.IInputModel = {
	price: ['response.response', []],
};

export default model;
