import immerHandleActions from '@Redux/utils/immerHandleActions';
import { setViewType, ISetViewType } from './actions';

const reducers = {
	[setViewType.toString()](_state: types.redux.settings.viewType, action: ISetViewType) {
		return action.payload;
	},
};

export default immerHandleActions<types.redux.settings.viewType>(reducers, 'GRID');
