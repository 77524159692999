import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { matchScreen, left, right, paddingRight, paddingLeft } from '@Utils/layout/layoutSupport';
import { CaptionBiggerBold } from '@Components/Typography/Typography.styled';

export enum ItemType {
	LINK = 'LINK',
}

interface IHeader {
	menuExpanded: boolean;
	hideShadow?: boolean;
	isAppleMenuVisible?: boolean;
}

export const NavigationBarWrapper = styled.header<IHeader>`
	background-color: ${({ theme }) => theme.colors.white};
	${({ menuExpanded, hideShadow, isAppleMenuVisible }) => {
		return {
			boxShadow:
				menuExpanded || hideShadow || isAppleMenuVisible
					? 'unset'
					: '0 7px 8px 0 rgba(0, 0, 0, 0.05), 0 5px 22px 4px rgba(0, 0, 0, 0.04), 0 12px 17px 2px rgba(0, 0, 0, 0.04)',
		};
	}}
	width: 100%;
	position: fixed;
	transition: all 0.2s ease-in-out;
	z-index: 50;
	${() => left(0)};
`;

export const NavigationBarContent = styled.div`
	display: flex;
	${() => {
		return {
			height: matchScreen(['xxl', 'xl']) ? '68px' : '56px',
		};
	}}
	position: relative;
	align-items: center;
`;

export const ItemsBar = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;
`;

export const LeftSideItems = styled.div`
	& > a {
		${() => {
			const isLarge = matchScreen(['xxl', 'xl']);
			return { margin: isLarge ? '0 16px' : '0 8px' };
		}}
	}
`;

export const RightSideItems = styled.div`
	display: flex;
	justify-content: flex-end;
	align-items: center;
`;

export const RedirectItem = styled.a`
	transition: all 0.1s ease-in-out;
	text-decoration: none;
	cursor: pointer;
	color: ${({ theme }) => theme.colors.black87};
	${({ theme }) => ({ ...theme.typography.title })}
`;

export const MenuItemLink = styled.p<{ active?: boolean }>`
	box-sizing: border-box;
	cursor: pointer;
`;

const ExpandableItem = styled.a<{ megaMenuOpen: boolean; active?: boolean }>`
	display: inline-flex;
	transition: all 0.1s ease-in-out;
	position: relative;
	align-items: center;
`;

export const ExpandableTitleItem = styled(ExpandableItem)`
	color: ${({ theme, active }) => (active ? theme.colors.primary : theme.colors.black87)};
	transition: color .3s;
	cursor: pointer;
	${({ theme }) =>
		matchScreen(['xxl', 'xl']) ? { ...theme.typography.bodySHigher } : { ...theme.typography.captionBiggerBold }}
	&:hover > p::before {
		background-color: ${({ theme }) => theme.colors.primary};
		border-radius: 2px;
		${({}) => (matchScreen(['xxl', 'xl']) ? { bottom: '-29px' } : { bottom: '-25px' })}
		content: "";
		height: 2px;
		position: absolute;
		right: 50%;
		transform: translateX(50%);
		width: ${({ active }) => (active ? '100%' : '8px')};
	}
	&:hover > p {
		position: relative;
		top -4px;
		color: ${({ theme }) => theme.colors.primary};
	}
	& > p::before {
		background-color: ${({ theme }) => theme.colors.primary};
		border-radius: 2px;
		${({}) => (matchScreen(['xxl', 'xl']) ? { bottom: '-10px' } : { bottom: '-8px' })}
		content: "";
		height: 2px;
		position: absolute;
		right: 50%;
		transform: translateX(50%);
		width: ${({ active }) => (active ? '100%' : '0px')};
	}
`;

export const LogoLinkWrapper = styled.div`
	cursor: pointer;
	${() => {
		const isLarge = matchScreen(['xxl', 'xl']);
		const isSmallDevice = matchScreen(['sm', 'xs']);
		return paddingRight(isLarge ? 16 : isSmallDevice ? 0 : 8);
	}}
	${() => paddingLeft(0)}
`;

export const IconLinkItem = styled(Link)``;

export const IconItem = styled.div<{ customBackground?: types.theme.themeColors }>`
	position: relative;
	${({ theme, customBackground }) => ({
		backgroundColor: customBackground ? theme.colors[customBackground] : 'unset',
	})}
	border-radius: 4px;
	width: 36px;
	height: 36px;
	box-sizing: border-box;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	&:hover {
		background-color: #faeede;
	}
`;

export const PaymentIconWrapper = styled.a`
	display: flex;
	gap: 8px;
	align-items: center;
	justify-content: center;
	padding: 8px;
	border-radius: 4px;
	background: ${({ theme }) => theme.colors.primary};
	& > p {
		color: #ffffff;
	}
	&:hover {
		background: #f59616;
	}
`;

export const IconsWrapper = styled.div`	
	display: flex;
	gap: 8px;
	align-items: center;
    justify-content: center;
}
`;

export const CartItemsCounterWrapper = styled.div`
	position: absolute;
	top: -10px;
	${({ theme }) => ({
		backgroundColor: '#0D75DB',
		border: `1px solid ${theme.colors.white}`,
	})}
	${() => right(-6)}
	border-radius: 48px;
	width: 20px;
	height: 20px;
	display: flex;
	align-items: center;
	justify-content: center;
`;
export const CartItemsCounter = styled(CaptionBiggerBold)`
	margin-top: 2px;
	${({ theme }) => ({
		color: theme.colors.white,
	})}
`;

export const NavigationBarContainer = styled.div`
	position: relative;
`;
