import * as Yup from 'yup';
import useTranslate from '@Utils/hooks/useTranslate';
const tenantRegex = /^^[0-9a-zA-Z-]+$/;

export default (): Yup.AnyObjectSchema => {
	const { translate } = useTranslate();

	const tenantDataShape = {
		tenantName: Yup.string()
			.matches(tenantRegex, translate('microsoft-automation-proccess.validate.tenant-name-match'))
			.required(translate('microsoft-automation-proccess.validate.tenant-name-required')),
		tenantId: Yup.string()
			.matches(tenantRegex, translate('microsoft-automation-proccess.validate.tenant-id-match'))
			.required(translate('microsoft-automation-proccess.validate.tenant-id-required')),
	};

	return Yup.object().shape({ ...tenantDataShape });
};
