import styled from 'styled-components';
import { matchScreen } from '@Utils/layout/layoutSupport';

interface IDetailsWrapperProps {
	isPrepaid?: boolean;
}

export const CardWrapper = styled.div`
	max-width: 1110px;
	margin: auto;
	padding: 0px 16px;
`;

export const BusyIndicatorWrapper = styled.div`
	text-align: center;
`;

export const TitleBoldWrapper = styled.div<IDetailsWrapperProps>`
	${(isPrepaid) => {
		return {
			textAlign: isPrepaid ? 'center' : 'left',
		};
	}}
`;

export const Header = styled.div`
	padding-top: 24px;
	border-bottom: 1px solid ${({ theme }) => theme.colors.black12};
`;

export const ActionWrapper = styled.div`
	display: flex;
	justify-content: flex-end;
	padding-bottom: 24px;
	cursor: pointer;
`;

export const PlanComparisonWrapper = styled.div`
	padding: 24px 0;
	${() => {
		return {
			marginLeft: matchScreen(['xs']) ? -16 : 0,
			marginRight: matchScreen(['xs']) ? -16 : 0,
		};
	}}
`;

export const DetailsWrapper = styled.div<IDetailsWrapperProps>`
	${(isPrepaid) => {
		return {
			height: isPrepaid ? 'none' : 'calc(100vh - 56px)',
			overflowy: isPrepaid ? 'none' : 'scroll',
		};
	}}
`;
