export interface IInstallment {
	productOfferId?: number;
	duration: number;
}

export interface ISmartVoucher {
	commitmentDuration: number;
	deviceDiscount: number;
	upFrontPayment: number;
	installments: IInstallment[];
}

export interface ISmartVouchersResponse {
	vouchers: ISmartVoucher[];
}

const model: types.models.IInputModel = {
	_data: 'response',
	vouchers: (model: any) => {
		return model._data;
	},
};

export default model;
