import React, { FunctionComponent } from 'react';
import Card from '@Components/Card/Card';

const DeletePaymentToken: FunctionComponent<types.cards.IDeletePaymentTokenCardProps['params']> = ({ token }) => {
	return (
		<Card<types.cards.IDeletePaymentTokenCardProps>
			card="deletePaymentToken"
			params={{ token }}
			withoutContainerPadding
		/>
	);
};

export default DeletePaymentToken;
