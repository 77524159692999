import { PlanBarType } from '@Components/PlanBar/PlanBarType';
import { isRTL } from '@Utils/layout/layoutSupport';
import styled from 'styled-components';

interface IPlanComparisonProps {
	padding?: number;
	planType?: PlanBarType;
	menuMargin?: number;
	dataFill?: number;
}

export const PlanComparisonContainer = styled.div`
	display: flex;
	margin-bottom: 32px;
	overflow-x: scroll;
`;

export const MenuPanel = styled.div<IPlanComparisonProps>`
	height: fit-content;
	${({ menuMargin }) => ({
		marginTop: menuMargin,
	})}
	${() =>
		isRTL()
			? {
					borderTopRightRadius: '8px',
					borderBottomRightRadius: '8px',
			  }
			: {
					borderTopLeftRadius: '8px',
					borderBottomLeftRadius: '8px',
			  }}
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: stretch;
	gap: 0;
	${({ theme }) => ({
		padding: '0 8px',
		backgroundColor: theme.colors.grey100,
	})}
`;

export const Plans = styled.div`
	border-top-left-radius: 8px;
	border-top-right-radius: 8px;
	overflow: hidden;
	display: flex;
	min-width: 700px;
	width: 100%;
`;
