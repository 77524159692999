export interface IDoCheckout {
	orderId: string;
	resourceName: string;
	success: boolean;
}

const model: types.models.IInputModel = {
	orderId: 'response.orderId',
	resourceName: 'response.resourceName',
	success: (model: IDoCheckout) => {
		return !!model.orderId && !!model.resourceName;
	},
};

export default model;
