import { ICheckout } from '@Redux/modules/checkout/actions';

interface IAttribute {
	value: string;
	key: string;
}

export type AddressType = 'ShippingAndBilling' | 'Shipping';

export interface IAddress {
	organization?: string;
	governorate: string;
	wilayat: string;
	city: string;
	way: string;
	house?: string;
	apartment?: string;
	landmark?: string;
	markerPosition?: { lat: number; lng: number };
	addressId?: string;
	nickName?: string;
	addressType?: string;
	zipCode?: string;
	area: string;
}

interface IContact {
	lastName: string;
	personTitle: string;
	middleName: string;
	zipCode: string;
	country: string;
	city: string;
	nickName: string;
	addressType: AddressType;
	addressLine: string[];
	geographicalShippingCode: string;
	addressId: string;
	phone1: string;
	firstName: string;
	email1: string;
	attributes: IAttribute[];
	state: string;
	primary: string;
	organizationName: string;
	area: string;
}

interface ICommerceProfileReponse {
	lastName: string;
	country: string;
	zipCode: string;
	registrationStatus: string;
	preferredLanguage: string;
	resourceId: string;
	city: string;
	distinguishedName: string;
	orgizationId: string;
	addressId: string;
	accountStatus: string;
	email1: string;
	ext_version: string;
	profileType: string;
	contact: IContact[];
	state: string;
	nickName: string;
	resourceName: string;
	addressLine: string[];
	checkoutProfileUrl: string;
	userId: string;
	organizationDistinguishedName: string;
	logonId: string;
	registrationApprovalStatus: string;
	contactUrl: string;
	passwordExpired: string;
	primary: string;
}

const getLatLng = (geographicalShippingCode: string): { lat: number; lng: number } | undefined => {
	const latLng = geographicalShippingCode?.split('"');

	if (!Number.isNaN(Number(latLng?.[7])) && !Number.isNaN(Number(latLng?.[3]))) {
		return {
			lat: Number(latLng[7]),
			lng: Number(latLng[3]),
		};
	}
	return undefined;
};

const getArea = (attributes: IAttribute[]): string => {
	return attributes?.find((attr) => attr.key === 'addressField1')?.value ?? '';
};

const getLandmark = (attributes: IAttribute[]): string => {
	return attributes?.find((attr) => attr.key === 'addressField2')?.value ?? '';
};

const getWilayat = (attributes: IAttribute[]): string => {
	return attributes?.find((attr) => attr.key === 'addressField3')?.value ?? '';
};

const getAddress = (addresses: IContact[], addresType: AddressType) => {
	const address = addresses?.find((address) => address.addressType === addresType);
	if (address) {
		const { geographicalShippingCode, addressLine, city, attributes, state, organizationName, addressId } = address;
		return {
			organization: organizationName,
			governorate: state,
			wilayat: getWilayat(attributes),
			city,
			area: getArea(attributes),
			way: addressLine?.[0] ?? '',
			house: addressLine?.[1] ?? '',
			apartment: addressLine?.[2] ?? '',
			landmark: getLandmark(attributes),
			markerPosition: getLatLng(geographicalShippingCode),
			addressId,
		};
	}
};

const getAllAddress = (addresses: IContact[]) => {
	const all: IAddress[] = [];
	addresses?.forEach((address) => {
		if (address) {
			const {
				geographicalShippingCode,
				addressLine,
				city,
				attributes,
				state,
				organizationName,
				addressId,
				nickName,
				addressType,
				zipCode,
			} = address;
			all.push({
				organization: organizationName,
				governorate: state,
				wilayat: getWilayat(attributes),
				area: getArea(attributes),
				city,
				way: addressLine?.[0] ?? '',
				house: addressLine?.[1] ?? '',
				apartment: addressLine?.[2] ?? '',
				landmark: getLandmark(attributes),
				markerPosition: getLatLng(geographicalShippingCode),
				addressId,
				nickName,
				addressType,
				zipCode,
			});
		}
	});
	return all;
};

interface ICommerceProfileModel extends ICommerceProfile {
	_response: ICommerceProfileReponse;
}

export interface ICommerceProfile {
	billingAddress: ICheckout['payload']['billingAddress'];
	shipmentAddress: ICheckout['payload']['shipmentAddress'];
	contactDetails: ICheckout['payload']['contactDetails'];
	addresses: IAddress[];
}

export const model: types.models.IInputModel = {
	_response: 'response',
	billingAddress: (model: ICommerceProfileModel) => getAddress(model._response.contact, 'ShippingAndBilling'),
	shipmentAddress: (model: ICommerceProfileModel) => getAddress(model._response.contact, 'Shipping'),
	addresses: (model: ICommerceProfileModel) => getAllAddress(model._response.contact),
	contactDetails: (model: ICommerceProfileModel) => {
		const address =
			model._response?.contact?.find((address) => address.addressType === 'ShippingAndBilling') ??
			model._response?.contact?.find((address) => address.nickName === 'ShippingAndBilling');
		if (address) {
			const { firstName, phone1, email1, lastName, middleName, personTitle } = address;
			return {
				title: personTitle,
				firstName,
				middleName,
				lastName,
				email: email1,
				phoneNumber: phone1,
			};
		}
	},
};

export default model;
