import styled from 'styled-components';
import { matchScreen } from '@Utils/layout/layoutSupport';

export const CheckoutWrapper = styled.div`
	position: relative;
	${({ theme }) => ({
		backgroundColor: theme.colors.background,
		paddingBottom: matchScreen(['xs', 'sm', 'md']) ? 40 : 0,
	})}
`;

export const CheckoutStepperWrapper = styled.div`
	display: flex;
	align-items: flex-start;
	justify-content: center;
`;
