import styled from 'styled-components';
import { BodyS, BodySBold, Caption } from '@Components/Typography/Typography.styled';
import { marginLeft, marginRight, paddingLeft } from '@Utils/layout/layoutSupport';

export const PaymentTokenItemWrapper = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1;
	width: 100%;
	justify-content: center;
	align-items: flex-start;
	padding: 16px;
	border-radius: 8px;
	${({ theme }) => ({
		backgroundColor: theme.colors.grey50,
	})}
`;

export const PaymentTokenContainer = styled.div`
	display: flex;
	flex-direction: row;
	flex: 1;
	width: 100%;
	justify-content: flex-start;
	align-items: center;
`;

export const PaymentInformation = styled.div`
	padding: 0 8px;
	display: flex;
	flex: 1;
	justify-content: flex-start;
	align-items: center;
`;

export const PaymentTokenCardNumber = styled(BodyS)`
	${() => marginLeft(8)}
	${({ theme }) => ({
		color: theme.colors.black54,
	})}
`;

export const SelectedText = styled(Caption)`
	${() => marginRight(16)}
	${({ theme }) => ({
		color: theme.colors.black54,
	})}
`;

export const ActionWrapper = styled.div``;

export const DeleteActionWrapper = styled.div`
	${() => paddingLeft(64)}
`;

export const DeleteAction = styled(BodySBold)`
	cursor: pointer;
	text-decoration: underline;
	${() => marginLeft(8)}
	${({ theme }) => ({
		color: theme.colors.primary,
	})}
`;
