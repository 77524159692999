import { convertToArray } from '@Utils/converters/convertToArray';
import { displayCardNumber } from '@Utils/converters/payment';

export interface IPaymentToken {
	cardType: string;
	paymentGateway: string;
	lastName: string;
	firstName: string;
	cardNumber: string;
	id: string;
	secondaryText: string;
	text: string;
	displayNumber: string;
	data: {
		cardType: string;
		paymentGateway: string;
		lastName: string;
		firstName: string;
		cardNumber: string;
		id: string;
		displayNumber: string;
	};
}

export interface IPaymentTokens {
	_tokens: any;
	tokens: IPaymentToken[] | [];
}

const model: types.models.IInputModel = {
	_tokens: 'response.getPaymentTokensForCurrentUserResponse.paymentToken',
	tokens(model: IPaymentTokens) {
		const paymentTokens = model._tokens && Object.keys(model._tokens).length ? convertToArray(model._tokens) : [];
		return paymentTokens.map((token: IPaymentToken['data']) => ({
			...token,
			displayNumber: displayCardNumber(token.cardNumber),
		}));
	},
};

export default model;
