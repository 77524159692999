import React, { VoidFunctionComponent } from 'react';
import GridProductSkeleton from '../Product/subcomponents/GridProductSkeleton';

const UpsellProductSkeleton: VoidFunctionComponent = () => {
	return (
		<>
			<GridProductSkeleton />
			<GridProductSkeleton />
			<GridProductSkeleton />
		</>
	);
};
export default UpsellProductSkeleton;
