import { Action, ActionFunction1, createAction } from 'redux-actions';

export interface IUpdateTelesalesAction {
	payload: boolean;
}

export const telesalesSaveInProgress: ActionFunction1<
	IUpdateTelesalesAction['payload'],
	Action<IUpdateTelesalesAction['payload']>
> = createAction('TELESALES_SAVE_IN_PROGRESS', (action: IUpdateTelesalesAction['payload']) => action);
