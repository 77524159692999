import { Caption, ParagraphM, ParagraphMBold } from '@Components/Typography/Typography';
import { marginLeft, matchScreen, paddingLeft } from '@Utils/layout/layoutSupport';
import { Row } from 'react-grid-system';
import styled from 'styled-components';

interface IPlanProps {
	isPrepaidProcess: boolean;
}

export const CheckoutNextStepCardContainer = styled(Row)``;

export const SecureTransactionWrapper = styled.div`
	display: flex;
	align-items: center;
	${() => ({
		marginTop: matchScreen(['xl', 'lg']) ? 16 : matchScreen(['xs', 'sm']) ? 0 : 8,
		justifyContent: matchScreen(['xl', 'lg']) ? 'center' : 'flex-start',
	})}
`;

export const SecureTransactionText = styled(Caption)`
	${() => marginLeft('8px')}
`;

export const HighlightedText = styled.span`
	${({ theme }) => ({
		color: theme.colors.black54,
	})}
`;
export const NextStepWrapper = styled.div`
	display: flex;
	align-items: flex-start;
	${({}) => ({
		flexDirection: matchScreen(['xl', 'lg']) ? 'column' : 'row',
		marginTop: matchScreen(['xs']) ? -8 : 0,
	})}
`;

export const NextStepTitle = styled(ParagraphM)<IPlanProps>`
	display: inline;
	${({ theme, isPrepaidProcess }) => ({
		color: isPrepaidProcess ? theme.colors.black87 : theme.colors.black54,
		marginBottom: matchScreen(['xl', 'lg']) ? 0 : matchScreen('md') ? 0 : 8,
	})}
`;

export const NextStep = styled(ParagraphMBold)<IPlanProps>`
	display: inline;
	${({ theme, isPrepaidProcess }) => ({
		color: isPrepaidProcess ? theme.colors.black87 : theme.colors.black54,
		marginBottom: matchScreen(['xl', 'lg']) ? 16 : matchScreen('md') ? 0 : 8,
	})};
	${() => (matchScreen(['xl', 'lg']) ? paddingLeft(0) : paddingLeft(4))}
`;

export const SetOrderDescription = styled(Caption)`
	${({ theme }) => ({
		color: theme.colors.black87,
	})}
	text-align: center;
	margin-bottom: 16px;
`;
