import {
	TitleBold,
	ParagraphMBold,
	ParagraphS,
	MiniDescription,
	SmallCurrencyVat,
	Header,
} from '@Components/Typography/Typography.styled';
import { marginRight, right, isRTL, textRight, matchScreen } from '@Utils/layout/layoutSupport';
import styled from 'styled-components';

export const Container = styled.div`
	${({ theme }) => ({
		backgroundColor: theme.colors.white,
		width: matchScreen(['xs', 'sm', 'md']) ? '100%' : 'unset',
		height: matchScreen(['xs', 'sm', 'md']) ? '100%' : 'unset',
		overflowY: !matchScreen(['xs', 'sm', 'md']) ? 'scroll' : 'unset',
		padding: matchScreen('xs') ? '0px' : '0px 0px 8px 8px',
	})};
	border-radius: 8px;
	max-width: 640px;
	max-height: 100vh;
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	z-index: 5;
`;
export const TitleWrapper = styled(TitleBold)`
	${({ theme }) => ({
		color: theme.colors.black87,
	})};
	width: 60%;
	text-align: center;
`;

export const CloseButtonContainer = styled.div`
	position: absolute;
	top: 36px;
	${() => right(36)}
`;

export const SubtitleContainer = styled.div`
	display: flex;
	justify-content: space-between;
	margin-bottom: 8px;
`;

export const SubtitleWrapper = styled(ParagraphMBold)`
	${({ theme }) => ({
		color: theme.colors.black87,
	})};
	${() => marginRight(16)}
`;

export const ButtonContainer = styled.div`
	${() => ({
		marginTop: matchScreen(['xs', 'sm', 'md']) ? 'unset' : '32px',
		justifyContent: matchScreen(['xs', 'sm']) ? 'center' : 'flex-end',
		display: matchScreen(['xs', 'sm']) ? 'unset' : 'flex',
	})};
	padding-top: 8px;
`;

export const InsuranceDescriptionContainer = styled.div`
	${({ theme }) => ({
		backgroundColor: theme.colors.grey100,
		padding: 16,
	})};
`;

export const ContainerInstruction = styled.div`
	${({ theme }) => ({
		color: theme.colors.black87,
		flexDirection: 'row',
		alignItems: 'flex-start',
		display: 'flex',
	})};
`;

export const PaymentDetailsContainer = styled.div`
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	display: flex;
	padding: 24px 0;
`;

export const Instruction = styled(ParagraphS)<{ padding?: boolean }>`
	${({ theme, padding }) => ({
		color: theme.colors.black87,
		flexDirection: isRTL() ? 'row' : 'row-reverse',
		paddingLeft: padding ? (isRTL() ? 0 : 8) : 0,
		paddingRight: padding ? (isRTL() ? 8 : 0) : 0,
	})};
`;

export const InstructionSubtitle = styled(MiniDescription)`
	${({ theme }) => ({
		color: theme.colors.black87,
		paddingTop: 10,
	})};
`;

export const PaymentSubtitle = styled(MiniDescription)`
	${({ theme }) => ({
		color: theme.colors.black87,
	})};
`;

export const VatWrapper = styled.div`
	max-height: 44px;
`;

export const Vat = styled(SmallCurrencyVat)`
	${() => textRight()}
	${({ theme }) => ({
		color: theme.colors.black54,
	})}
`;

export const PriceTitle = styled(Header)`
	${({ theme }) => ({
		color: theme.colors.black87,
	})}
`;

export const TopContainer = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	height: 60px;
`;

export const ContentWrapper = styled.div`
	padding: 16px;
`;

export const ScrollContent = styled.div`
	${() => ({
		height: matchScreen(['xs', 'sm', 'md']) ? 'calc(100dvh - 148px)' : 'unset',
		marginBottom: matchScreen(['xs', 'sm', 'md']) ? '16px' : '0px',
	})};
	overflow-y: scroll;
	::-webkit-scrollbar {
		display: none;
	}
`;
