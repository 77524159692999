import styled from 'styled-components';
import { CaptionBold, Caption } from '@Components/Typography/Typography';

interface IAdditionalInfo {
	paddingTop?: number;
}

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	padding-top: 8px;
`;

export const CaptionWrapper = styled(CaptionBold)<IAdditionalInfo>`
	text-align: center;
	${({ theme, paddingTop = 16 }) => ({
		color: theme.colors.black87,
		paddingTop,
	})}
`;

export const DeliveryInfo = styled(Caption)`
	padding-top: 12px;
	text-align: center;
`;

export const DeliveryDate = styled(CaptionBold)`
	text-align: center;
`;

export const ItemTitle = styled(Caption)`
	padding-top: 24px;
	text-align: center;
	${({ theme }) => ({
		color: theme.colors.black54,
	})}
`;
