import React, { FunctionComponent } from 'react';
import { CircularLoaderWrapper, Circle, Bar } from './CircularLoader.styled';

export interface ICircularLoaderBaseProps {
	size: number;
}

export interface ICircularLoaderProps extends ICircularLoaderBaseProps {
	backgroundColor: types.theme.themeColors;
	barColor: types.theme.themeColors;
}

const CircularLoader: FunctionComponent<ICircularLoaderProps> = ({ size, backgroundColor, barColor }) => {
	return (
		<CircularLoaderWrapper size={size}>
			<Circle size={size} backgroundColor={backgroundColor} barColor={barColor} />
			<Bar size={size} backgroundColor={backgroundColor} barColor={barColor} />
		</CircularLoaderWrapper>
	);
};

export const PrimaryCircularLoader: FunctionComponent = () => (
	<CircularLoader backgroundColor="black12" barColor="primary" size={24} />
);

export const WhiteCircularLoader: FunctionComponent = () => (
	<CircularLoader backgroundColor="white12" barColor="white" size={24} />
);

export const OrangeCircularLoader: FunctionComponent = () => (
	<CircularLoader backgroundColor="white12" barColor="orange" size={24} />
);
export const CarInsuranceCircularLoader: FunctionComponent = () => (
	<CircularLoader backgroundColor="transparentGreen" barColor="green" size={24} />
);

export default CircularLoader;
