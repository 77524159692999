import { store } from '@Redux/store';
import { translations } from '@Utils/translations/translations';

export const NO_TRANSLATION = 'No translation';

export const selectedLanguage: types.redux.settings.language = store?.getState()?.settings?.language;

export const getTranslation = (key: string, language?: types.redux.settings.language): string => {
	try {
		return translations[key][(language ?? store?.getState()?.settings?.language).toLowerCase()];
	} catch {
		return NO_TRANSLATION;
	}
};
