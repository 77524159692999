import React, { FunctionComponent } from 'react';
import Card from '@Components/Card/Card';

const ModalGiftCardCodes: FunctionComponent<types.cards.IGiftCardCodesCardProps['params']> = ({
	name,
	price,
	country,
	orderId,
	partNumber,
}) => {
	return (
		<Card<types.cards.IGiftCardCodesCardProps>
			card="giftCardCodes"
			params={{ name, price, country, orderId, partNumber }}
			withoutContainerPadding
		/>
	);
};

export default ModalGiftCardCodes;
