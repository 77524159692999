import useTranslate from '@Utils/hooks/useTranslate';
import React, { FunctionComponent } from 'react';
import {
	CombarisonOverflowBarContainer,
	SubheaderWrapper,
	SwitchContainer,
	TitleWrapper,
	HighlightText,
	ContentContainer,
	TitleContainer,
	ProductsContainer,
} from './ComparisonOverflowBar.styled';
import PrimaryToggle from '@Components/controls/Toggle/Toggle';
import { IProduct } from '@Views/Comparison/Comparison';
import Product from './subcomponents/Product';
import ConditionalRender from '@Components/ConditionalRender/ConditionalRender';
import useScreen from '@Utils/hooks/useScreen';

interface IComparisonOverflowBarProps {
	setHighlightDifferences: (val: boolean) => void;
	highlightDifferences: boolean;
	products: IProduct[];
	top: number;
	overflowEnabled?: boolean;
	leftPosition: number;
	paddingLeft: number;
}

const ComparisonOverflowBar: FunctionComponent<IComparisonOverflowBarProps> = ({
	setHighlightDifferences,
	highlightDifferences,
	products,
	top,
	overflowEnabled,
	leftPosition,
	paddingLeft,
}) => {
	const { translate } = useTranslate();
	const { screen } = useScreen();
	const isXS = screen('xs');

	return (
		<CombarisonOverflowBarContainer top={top} overflowEnabled={overflowEnabled} leftPosition={leftPosition}>
			<ContentContainer paddingLeft={paddingLeft}>
				<ConditionalRender
					show={!isXS}
					onTrue={() => (
						<TitleContainer
							overflowEnabled={overflowEnabled}
							shadowWidth={overflowEnabled ? (leftPosition / 2 > 4 ? 4 : leftPosition / 2) : 0}
						>
							<SubheaderWrapper>{translate('comparison.compare')}</SubheaderWrapper>
							<TitleWrapper>{translate('comparison.title')}</TitleWrapper>
							<SwitchContainer>
								<PrimaryToggle
									active={highlightDifferences}
									onChange={() => {
										setHighlightDifferences(!highlightDifferences);
									}}
								/>
								<HighlightText>{translate('comparison.differences')}</HighlightText>
							</SwitchContainer>
						</TitleContainer>
					)}
				/>
				<ProductsContainer paddingLeft={leftPosition} overflowEnabled={overflowEnabled}>
					{products.map((product) => (
						<Product key={product.product.uniqueID} product={product} />
					))}
				</ProductsContainer>
			</ContentContainer>
		</CombarisonOverflowBarContainer>
	);
};

export default ComparisonOverflowBar;
