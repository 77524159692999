import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { ISetTimerAction, setTimer } from '@Redux/modules/timer/actions';
import { dispatch } from '@Redux/store';

interface IUseOtpTimer {
	timer: {
		time: number;
		activated: boolean;
	};
	setTimer: typeof setTimer;
}
const SECOND = 1000;

const useOtpTimer = (): IUseOtpTimer => {
	const [delay, setDelay] = useState<number | null>(1000);
	const { time, activated } = useSelector((state: types.redux.IState) => state.timer);

	_useInterval(() => {
		dispatch(
			setTimer({
				time: time - SECOND,
				activated: true,
			})
		);
	}, delay);

	useEffect(() => {
		if (time <= 0) {
			dispatch(
				setTimer({
					time: 0,
					activated: false,
				})
			);
			setDelay(null);
		}
	}, [time]);

	useEffect(() => {
		if (activated) {
			setDelay(SECOND);
		} else {
			setDelay(null);
		}
	}, [activated]);

	const _setTimer = ({ time, activated }: ISetTimerAction['payload']) => dispatch(setTimer({ time, activated }));

	return { timer: { time, activated }, setTimer: _setTimer };
};

// https://overreacted.io/making-setinterval-declarative-with-react-hooks/
function _useInterval(callback: () => void, delay: number | null) {
	const savedCallback = useRef<() => void>();

	useEffect(() => {
		savedCallback.current = callback;
	});

	useEffect(() => {
		const tick = () => {
			if (savedCallback.current) savedCallback.current();
		};
		if (delay !== null) {
			const id = setInterval(tick, delay);
			return () => clearInterval(id);
		}
		return () => {};
	}, [delay]);
}

export default useOtpTimer;
