import immerHandleActions from '@Redux/utils/immerHandleActions';
import { setLanguage } from '@Redux/modules/settings/language/actions';
import { checkLayoutDirection } from '@Utils/layout/layoutSupport';
import { getBrowserLanguage } from '@Utils/language/language';

interface ISetLanguageAction extends types.redux.settings.ISetLanguageAction {}

const reducers = {
	[setLanguage.toString()](_state: types.redux.settings.layout, action: ISetLanguageAction) {
		return checkLayoutDirection(action.payload);
	},
};

const init = getBrowserLanguage() === 'AR' ? 'rtl' : 'ltr';

export default immerHandleActions(reducers, init);
