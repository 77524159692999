import { BillingType } from '@ApiModels/accounts';
import { IUsage } from '@ApiModels/usage';
import { AccountsService } from '@Services/accounts/accountsService';
import api from '@Utils/api';
import { Observable, of } from 'rxjs';
import { concatMap } from 'rxjs/operators';

export class UsageService {
	public static getUsage(): Observable<IUsage> {
		return AccountsService.getMainAccount().pipe(
			concatMap((currentAccount) => {
				if (currentAccount) {
					return api.omantelApi
						.getUsage({
							accountType: currentAccount.accountType,
							billingType: currentAccount.billingType,
							getProducts: currentAccount.billingType === BillingType.POSTPAID,
							language: 'EN',
							phoneNumber: currentAccount.accountNum,
							serializeNulls: true,
						})
						.call();
				} else {
					return of({
						balance: 0,
						bonusBalance: 0,
						packages: [],
						totals: [],
					});
				}
			})
		);
	}
}
