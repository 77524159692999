export interface ILoyaltyBalance {
	amount: string;
	unit: string;
}

export interface ITier {
	name: string;
	pointToUpgrade: string;
	id: string;
}

export interface ILoyaltyAccount {
	loyaltyBalance: ILoyaltyBalance[];
	tier: ITier;
	status: string;
	id: string;
	rewardAmount: string;
	statusAmount: string;
	pointsToUpgrade: string;
	pointsToExpire: string;
}

const model = {
	id: 'response.getLoyaltyUserProfileResponse.userProfile.loyaltyId',
	rewardAmount: 'response.getLoyaltyUserProfileResponse.userProfile.rewardPoints',
	pointsToUpgrade: 'response.getLoyaltyUserProfileResponse.userProfile.pointsToNextTier',
	status: 'response.getLoyaltyUserProfileResponse.userProfile.status',
	tier: 'response.getLoyaltyUserProfileResponse.userProfile.tierName',
	pointsToExpire: 'response.getLoyaltyUserProfileResponse.userProfile.expiryRewardPoints',

	statusAmount(model: ILoyaltyAccount): string {
		if (model.loyaltyBalance) {
			return model.loyaltyBalance.filter((element) => element.unit === 'STATUS_POINTS')[0].amount;
		}
		return '0';
	},
};

export default model;
