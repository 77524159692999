import RxQuery from '@Utils/api/rxQuery/rxQuery';
import endpoints from '@Utils/api/endpoints';
import {
	IContactPayload,
	ICreateNotificationRequest,
	IHbbOrderPayload,
	IMoveOrderPayload,
	IValidatePlan,
	IValidateEbuOffer,
	IValidateTelesalesDocumentIdPayload,
	IValidateTelesalesSmsPasswordPayload,
	IWfbbOrderPayload,
	IPrepaidOrderPayload,
	IPickupOrderPayload,
	IMakePaymentRefundPayload,
	IRefundPaymentLogPayload,
} from '@Services/orderFlow/orderFlow';
import * as payloads from '@Utils/api/payloads/omantelShop';
import { IUpdateProfilePayloadInput } from '@Services/profile/commerceProfileService';
import { IAddItemsToCartPayload } from '@Services/orderFlow/orderFlow';
import config from '../config';
import { IGetProductsByParentCategory } from '../endpointsGenerators/products/productsEndpointsGenerators';
import {
	IProductByIdSpecificSkusPayload,
	IRelatedProductsByIdPayload,
} from '@Services/productsService/productsService';
import { IManageOrderItemESim } from '@Utils/api/payloads/omantelShop';
import { IMakasibOtpPayload } from '@ApiModels/validateMakasibOtp';
import { IInstallmentAmountPayload, IBuyNowPayLaterInstallmentPayload } from '@Services/inventory/inventoryService';
import { IBuNowPayLeterSessionPayload } from '@Services/authentication/authenticationService';

const omantelShop = () => new RxQuery('omantelShop');

// Products
export const getProductsByCategoryId = (categoryId: string): RxQuery => {
	return omantelShop()
		.setEndpoint(endpoints.omantelShop.getProductsByCategoryId(categoryId))
		.setType('productsByCategoryId');
};

export const getProductsByPartNumber = (partNumber: string): RxQuery => {
	return omantelShop()
		.setEndpoint(endpoints.omantelShop.getProductsByPartNumber(partNumber))
		.setType('productsByPartNumber');
};

export const getProductsByParentCategory = (config: IGetProductsByParentCategory): RxQuery => {
	return omantelShop()
		.setEndpoint(endpoints.omantelShop.getProductsByParentCategory(config))
		.setType('productsByCategoryId');
};

export const getByProductId = ({
	productId,
	itemType,
	cacheOff,
	customCatalog,
}: IProductByIdSpecificSkusPayload): RxQuery => {
	return omantelShop()
		.setEndpoint(endpoints.omantelShop.getProductById({ productId, itemType, customCatalog }))
		.setType('productById')
		.setCacheTime(cacheOff ? 0 : config.productByIdCacheTime)
		.setTimeout(120000);
};

export const getRelatedProductsById = ({ productId, itemType }: IRelatedProductsByIdPayload): RxQuery => {
	return omantelShop()
		.setEndpoint(endpoints.omantelShop.getRelatedProductsById({ productId, itemType }))
		.setType('relatedProductsById')
		.setTimeout(120000);
};

export const getProductIdBySeo = (seo: string): RxQuery => {
	return omantelShop()
		.setEndpoint(endpoints.omantelShop.getProductIdBySeo(seo))
		.setType('productIdBySeo')
		.setTimeout(120000);
};

export const getProductInstallment = (productId: string): RxQuery => {
	return omantelShop()
		.setEndpoint(endpoints.omantelShop.getProductInstallment(productId))
		.setType('productInstallment');
};

// Categories
export const getNestedCategories = (): RxQuery => {
	return omantelShop()
		.setEndpoint(endpoints.omantelShop.getNestedCategories())
		.setType('nestedCategories')
		.disabledContentTypeHeader();
};

export const getFlatCategories = (): RxQuery => {
	return omantelShop()
		.setEndpoint(endpoints.omantelShop.getFlatCategories())
		.setType('flatCategories')
		.disabledContentTypeHeader();
};

// Inventory
export const getInventoryBySKUId = (SKUId: string): RxQuery => {
	return omantelShop()
		.setEndpoint(endpoints.omantelShop.getInventoryBySKUId(SKUId))
		.setType('inventoryBySKUId')
		.withoutTokens()
		.setCacheTime(config.inventoryCacheTime);
};

export const getInventoryByPartNumber = (partNumber: string): RxQuery => {
	return omantelShop()
		.setEndpoint(endpoints.omantelShop.getInventoryByPartNumber(partNumber))
		.setType('inventoryByPartNumber')
		.withoutTokens()
		.setCacheTime(config.inventoryCacheTime);
};

export const getInventoryByProductId = (productId: string): RxQuery => {
	return omantelShop()
		.setEndpoint(endpoints.omantelShop.getInventoryByProductId(productId))
		.setType('inventoryByProductId')
		.withoutTokens()
		.setCacheTime(config.inventoryCacheTime);
};

export const getProductSuggestionsBySearchTerm = (searchTerm: string): RxQuery => {
	return omantelShop()
		.setEndpoint(endpoints.omantelShop.getProductSuggestionsBySearchTerm(searchTerm))
		.setType('typeaheadProductsBySearchTerm');
};

export const getProductOutletAvailability = (partNumber: string): RxQuery => {
	return omantelShop()
		.setEndpoint(endpoints.omantelShop.getProductOutletAvailability(partNumber))
		.setType('getProductOutletAvailability');
};

export const getProductOutletsPickup = (partNumber: string): RxQuery => {
	return omantelShop()
		.setEndpoint(endpoints.omantelShop.getProductOutletsPickup(partNumber))
		.setType('getProductOutletsPickup');
};

export const getOutletPickup = (branchId: string): RxQuery => {
	return omantelShop()
		.setEndpoint(endpoints.omantelShop.getOutletPickup(branchId))
		.setType('getProductOutletsPickup');
};

// Espot
export const getPopularProducts = (): RxQuery => {
	return omantelShop().setEndpoint(endpoints.omantelShop.getPopularProducts()).setType('popularProducts');
};
export const getSearchMenuList = (categoryName: string): RxQuery => {
	return omantelShop()
		.setEndpoint(endpoints.omantelShop.getSearchMenuList(categoryName))
		.setType('searchMenuList')
		.disableCache();
};
export const getSimilarItems = (category: string): RxQuery => {
	return omantelShop().setEndpoint(endpoints.omantelShop.getSimilarItems(category)).setType('popularProducts');
};
export const getRecommendedForYou = (): RxQuery => {
	return omantelShop().setEndpoint(endpoints.omantelShop.getRecommendedForYou()).setType('popularProducts');
};
export const getMarketingBanners = (categoryName: string): RxQuery => {
	return omantelShop()
		.setEndpoint(endpoints.omantelShop.getMarketingBanners(categoryName))
		.setCacheTime(config.marketingBannersCacheTime)
		.setType('marketingBanners');
};

// Order Flow
export const guestLogIn = (): RxQuery => {
	return omantelShop().setEndpoint(endpoints.omantelShop.guestLogIn()).setType('guestLogIn');
};

export const guestLogOut = (): RxQuery => {
	return omantelShop().disableCache().setEndpoint(endpoints.omantelShop.guestLogOut());
};

export const viewCart = (): RxQuery => {
	return omantelShop()
		.setEndpoint(endpoints.omantelShop.viewCart())
		.setType('viewCart')
		.setCacheTime(500)
		.disabledContentTypeHeader();
};

export const addItemsToCart = (items: IAddItemsToCartPayload[]): RxQuery => {
	return omantelShop()
		.setPayload(payloads.getProducts(items))
		.disableCache()
		.setEndpoint(endpoints.omantelShop.addItemsToCart());
};

export const clearAllCartItems = (): RxQuery => {
	return omantelShop().disableCache().setEndpoint(endpoints.omantelShop.clearAllCartItems());
};

export const removeSingleCartItem = (orderItemId: string): RxQuery => {
	return omantelShop()
		.setPayload(payloads.removeSingleCartItem(orderItemId))
		.disableCache()
		.setEndpoint(endpoints.omantelShop.removeSingleCartItem());
};

export const changeCartItemQuantity = (orderItemId: string, quantity: number): RxQuery => {
	return omantelShop()
		.setPayload(payloads.changeCartItemQuantity(orderItemId, quantity))
		.disableCache()
		.setEndpoint(endpoints.omantelShop.updateSingleCartItem());
};

export const changeCartItemData = (data: IPrepaidOrderPayload | IPickupOrderPayload): RxQuery => {
	return omantelShop()
		.setPayload(payloads.changeCartItemData(data))
		.disableCache()
		.setEndpoint(endpoints.omantelShop.updateSingleCartItem());
};

export const getUserProfile = (): RxQuery => {
	return omantelShop()
		.setEndpoint(endpoints.omantelShop.getProfile())
		.setType('commerceProfile')
		.setInvalidateKey('getCommerceProfile')
		.disableCache();
};

export const deleteUserAddress = (nickName: string): RxQuery => {
	return omantelShop()
		.setEndpoint(endpoints.omantelShop.deleteUserAddress(nickName))
		.setInvalidateKey('getCommerceProfile')
		.disableCache();
};

export const updateProfile = (nickName: string, profile: IUpdateProfilePayloadInput): RxQuery => {
	return omantelShop()
		.setEndpoint(endpoints.omantelShop.updateProfile(nickName))
		.setPayload<string | string[] | undefined>(payloads.updateUserProfile(profile))
		.disableCache();
};

export const addContactToProfile = (profile: IUpdateProfilePayloadInput): RxQuery => {
	return omantelShop()
		.setEndpoint(endpoints.omantelShop.addContactToProfile())
		.setPayload<string | string[] | undefined>(payloads.updateUserProfile(profile))
		.disableCache();
};

export const userLogIn = (logonId: string, logonPassword: string): RxQuery => {
	return omantelShop()
		.setPayload(payloads.userLogIn(logonId, logonPassword))
		.setEndpoint(endpoints.omantelShop.userLogIn())
		.disableCache()
		.setType('guestLogIn');
};

export const getOmantelIdentity = (): RxQuery => {
	return omantelShop()
		.setEndpoint(endpoints.omantelShop.getOmantelIdentity())
		.setPayload(payloads.getOmantelIdentity())
		.disableCache()
		.setType('guestLogIn')
		.withoutTokens();
};

export const userLogOut = (): RxQuery => {
	return omantelShop().setEndpoint(endpoints.omantelShop.userLogOut()).disableCache();
};

export const getOrderHistory = (pageNumber: number, pageSize: number): RxQuery => {
	return omantelShop()
		.setEndpoint(endpoints.omantelShop.getOrderHistory({ pageNumber, pageSize }))
		.setType('orderHistory')
		.setTimeout(120000);
};

export const getOrderDetails = (orderId: string): RxQuery => {
	return omantelShop().setEndpoint(endpoints.omantelShop.getOrderDetails({ orderId })).setType('orderDetails');
};

export const getOrderMSKeys = (orderId: string): RxQuery => {
	return omantelShop().setEndpoint(endpoints.omantelShop.getOrderMSKeys({ orderId })).setType('msKeysList');
};

export const getItemMSKey = (itemId: string): RxQuery => {
	return omantelShop().setEndpoint(endpoints.omantelShop.getItemMSKey({ itemId })).setType('msKeysList');
};

export const validateMakasibOtp = (orderId: string, payload: IMakasibOtpPayload): RxQuery => {
	return omantelShop()
		.setEndpoint(endpoints.omantelShop.validateMakasibOtp({ orderId }))
		.setPayload(payloads.validateMakasibOtp(payload))
		.setType('validateMakasibOtp')
		.disableCache();
};

export const validateOffer = (payload: IValidatePlan): RxQuery => {
	return omantelShop()
		.setEndpoint(endpoints.omantelShop.validateOffer())
		.setPayload(payloads.validateOffer(payload))
		.setType('validateOffer')
		.disableCache();
};

export const validateEbuOffer = (payload: IValidateEbuOffer): RxQuery => {
	return omantelShop()
		.setEndpoint(endpoints.omantelShop.validateEbuOffer())
		.setPayload(payloads.validateEbuOffer(payload))
		.setType('validateOffer')
		.disableCache();
};

export const chooseARAMEXShipment = ({
	addressId,
	pickupDelivery,
}: {
	addressId?: string;
	pickupDelivery?: boolean;
}): RxQuery => {
	return omantelShop()
		.setEndpoint(endpoints.omantelShop.chooseARAMEXShipment())
		.setPayload(payloads.chooseShipment({ addressId, pickupDelivery }))
		.disableCache()
		.setType('chooseShipment');
};

export const chooseCybersourcePayment = (piAmount: string): RxQuery => {
	return omantelShop()
		.setEndpoint(endpoints.omantelShop.chooseCybersourcePayment())
		.setPayload(payloads.chooseCybersourcePayment(piAmount))
		.disableCache()
		.setType('choosePaymentMethod');
};

export const deleteAllPaymentInstructions = (): RxQuery => {
	return omantelShop().setEndpoint(endpoints.omantelShop.deleteAllPaymentInstructions()).disableCache();
};

export const createNotificationRequest = (payload: ICreateNotificationRequest): RxQuery => {
	return omantelShop()
		.setEndpoint(endpoints.omantelShop.createNotificationRequest())
		.setPayload(payloads.createNotificationRequest(payload))
		.setType('createNotificationRequest')
		.disableCache();
};

export const manageOrderItemESim = (payload: Required<IManageOrderItemESim>): RxQuery => {
	return omantelShop()
		.setPayload(payloads.manageOrderItemESim(payload))
		.disableCache()
		.setEndpoint(endpoints.omantelShop.updateSingleCartItem());
};

export const getCatEntry = ({ catEntryId }: { catEntryId: string }): RxQuery => {
	return omantelShop()
		.setEndpoint(endpoints.omantelShop.getCatEntry({ catEntryId }))
		.setType('priceByCatEntry')
		.disableCache();
};

export const getInstallmentAmount = ({
	mobilePhone,
	creditRating,
	accountNo,
	emfConfigId,
	productId,
	serviceCode,
	voucherAmount,
	installmentDuration,
	isDisabledBnplCr7985,
}: IInstallmentAmountPayload): RxQuery => {
	const payload: any = {
		mobilePhone,
		accountNo,
		emfConfigId,
		productId,
		serviceCode,
		voucherAmount,
		installmentDuration,
	};

	if (!isDisabledBnplCr7985) {
		payload.creditRating = creditRating;
	}

	return omantelShop()
		.setPayload(payloads.getInstallmentAmount(payload))
		.setEndpoint(endpoints.omantelShop.getInstallmentAmount(payload))
		.setType('installmentAmount')
		.disableCache();
};

export const getBuyNowPayLaterInstallmentAmount = ({
	productId,
	installmentDuration,
	bnplProductPrice,
	mobilePhone,
	creditRating,
	isDisabledBnplCr7985,
	accountNo,
	emfConfigId,
	serviceCode,
}: IBuyNowPayLaterInstallmentPayload): RxQuery => {
	const payload: any = {
		productId,
		installmentDuration,
	};

	if (!isDisabledBnplCr7985) {
		payload.bnplProductPrice = bnplProductPrice;
		payload.mobilePhone = mobilePhone;
		payload.creditRating = creditRating;
		payload.accountNo = accountNo;
		payload.emfConfigId = emfConfigId;
		payload.serviceCode = serviceCode;
	}

	return omantelShop()
		.setPayload(payloads.getBuyNowPayLaterInstallmentAmount(payload))
		.setEndpoint(endpoints.omantelShop.getBuyNowPayLaterInstallmentAmount(payload))
		.setType('buyNowPayLaterInstallment')
		.disableCache();
};

export const getOrderTracking = ({ orderId }: types.payloads.IGetOrderTracking): RxQuery => {
	return omantelShop()
		.setEndpoint(endpoints.omantelShop.getOrderTracking({ orderId }))
		.setCacheTime(5000)
		.setType('tracking');
};

export const setHbbWfbbOrder = (params: IHbbOrderPayload | IWfbbOrderPayload, isWfbb: boolean): RxQuery => {
	return omantelShop()
		.setPayload(payloads.setHbbWfbbOrder(params))
		.setEndpoint(isWfbb ? endpoints.omantelShop.setWfbbOrder() : endpoints.omantelShop.setHbbOrder())
		.setTimeout(120000)
		.setType('setHbbWfbbOrder')
		.disableCache();
};

export const directContact = (params: IContactPayload): RxQuery => {
	return omantelShop()
		.setPayload(payloads.directContact(params))
		.setEndpoint(endpoints.omantelApi.directContact())
		.disableCache();
};

export const makePaymentRefund = (params: IMakePaymentRefundPayload): RxQuery => {
	return omantelShop()
		.setPayload(payloads.makePaymentRefund(params))
		.setEndpoint(endpoints.omantelApi.makePaymentRefund())
		.disableCache();
};

export const cancelOrder = (params: IMakePaymentRefundPayload): RxQuery => {
	return omantelShop().setEndpoint(endpoints.omantelShop.cancelOrder(params)).disableCache();
};

export const refundPaymentLog = (
	params: IRefundPaymentLogPayload,
	orderId: types.payloads.IRefundPaymentLog
): RxQuery => {
	return omantelShop()
		.setEndpoint(endpoints.omantelShop.refundPaymentLog(orderId))
		.setPayload(payloads.refundPaymentLog(params))
		.disableCache();
};

export const otpValidateDocumentId = (params: IValidateTelesalesDocumentIdPayload): RxQuery => {
	return omantelShop()
		.setPayload(payloads.otpValidateDocumentId(params))
		.disableCache()
		.setEndpoint(endpoints.omantelShop.otpValidateDocumentId(params.orderId))
		.setType('validateOtpDocumentId');
};

export const otpValidateSmsPassword = (params: IValidateTelesalesSmsPasswordPayload): RxQuery => {
	return omantelShop()
		.setPayload(payloads.otpValidateSmsPassword(params))
		.disableCache()
		.setEndpoint(endpoints.omantelShop.otpValidateSmsPassword(params.orderId))
		.setType('validateOtpSmsPassword');
};

export const moveOrder = (params: IMoveOrderPayload): RxQuery => {
	return omantelShop()
		.setPayload(payloads.moveOrder(params))
		.setEndpoint(endpoints.omantelShop.moveOrder({ orderId: params.orderId }))
		.disableCache();
};

export const getMakasibPointsCalculation = (): RxQuery => {
	return omantelShop()
		.disableCache()
		.setEndpoint(endpoints.omantelShop.getMakasibPointsCalculation())
		.setType('getMakasibPointsCalculation');
};

export const getRemoteConfig = (remoteConfigId: string): RxQuery => {
	return omantelShop()
		.setEndpoint(endpoints.omantelShop.getRemoteConfig(remoteConfigId))
		.setType('productsByCategoryId');
};

export const createBuyNowPayLaterSession = (params: IBuNowPayLeterSessionPayload): RxQuery => {
	return omantelShop()
		.setPayload(payloads.createBuyNowPayLaterSession(params))
		.setEndpoint(endpoints.omantelShop.createBuyNowPayLaterSession())
		.setType('createBuyNowPayLaterSession')
		.disableCache();
};

export const retriveBuyNowPayLaterSession = (orderId: string): RxQuery => {
	return omantelShop()
		.setPayload(payloads.retriveBuyNowPayLaterSession(orderId))
		.setEndpoint(endpoints.omantelShop.retriveBuyNowPayLaterSession())
		.setType('retriveBuyNowPayLaterSession')
		.disableCache();
};
