import styled from 'styled-components';
import { marginLeft as layoutMarginLeft } from '@Utils/layout/layoutSupport';

export const ProductBoxWrapper = styled.div<{ marginRight?: number }>`
	${({ theme, marginRight }) => ({
		backgroundColor: theme.colors.background,
		marginRight: marginRight ?? 0,
	})}
	width: 100%;
`;

export const ChildernCollapseWrapper = styled.div<{ expanded: boolean; minHeight: number; marginLeft?: number }>`
	${({ expanded, minHeight = 0 }) => {
		return {
			maxHeight: expanded ? 2000 : minHeight ?? 0,
		};
	}}
	${({ marginLeft = 0 }) => layoutMarginLeft(marginLeft)}

	overflow: hidden;
	transition: all 0.3s ease-in-out;
`;

export const IconWrapper = styled.div`
	position: absolute;
	top: -11px;
	z-index: 10;
	left: calc(50% - 12px);
	background-color: white;
	border-radius: 16px;
`;
