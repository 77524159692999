import { Action, ActionFunction1, createAction } from 'redux-actions';

export interface IUpdateCartAction {
	payload: boolean;
}

export const updateCart: ActionFunction1<
	IUpdateCartAction['payload'],
	Action<IUpdateCartAction['payload']>
> = createAction('UPDATE_CART', (action: IUpdateCartAction['payload']) => action);
