/**
 * Formats duration as m:ss
 *
 * @param duration in milliseconds
 * @returns formated duration
 */
export function getTimerFormat(duration: number): string {
	const seconds = duration / 1000;
	const min = Math.floor(seconds / 60);
	const sec = Math.floor(seconds % 60);
	return `${min}:${sec < 10 ? '0' + sec.toString() : sec}`;
}
