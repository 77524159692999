import useTranslate from '@Utils/hooks/useTranslate';
import * as Yup from 'yup';

export default (): Yup.AnyObjectSchema => {
	const { translate } = useTranslate();
	return Yup.object().shape({
		name: Yup.string()
			.min(2)
			.matches(/[a-zA-Z]+/g, translate('contact.error.name.only.letters'))
			.required(translate('contact.name.error.required')),
		phoneNumber: Yup.string()
			.matches(/[0-9]+/g, translate('contact.error.name.only.numbers'))
			.required(translate('contact.error.phone.required')),
	});
};
