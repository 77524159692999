import styled from 'styled-components';
import { CaptionBigger } from '@Components/Typography/Typography';
import { isRTL, marginRight, matchScreen } from '@Utils/layout/layoutSupport';

interface ISocialMediaWrapper {
	smallView: boolean;
}
interface IBottomSectionWrapper {
	smallView?: boolean;
}
interface ISectionTitle {
	justifyContent: types.justifyContent;
}

export const FooterWrapper = styled.div<{ marginBottom: number }>`
	background-color: #000B25;
	padding: 49px 0 26px;
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	${({ marginBottom }) => ({ marginBottom })}
	 &:before {    
		${() =>
			isRTL()
				? {
						left: '0',
						transform: 'scaleX(-100%)',
				  }
				: {
						right: '0',
				  }}
		${({}) => {
			const isXlLg = matchScreen(['xl', 'lg']);
			if (isXlLg) {
				return {
					backgroundImage: 'url(footerPattern.png)',
				};
			} else return { backgroundImage: '' };
		}}
		background-repeat: no-repeat;
		background-size: cover;
		bottom: 80px;
		height: 333px;
		position: absolute;
		width: 635px;
		content: "";
	 }
}
`;

export const SectionTitle = styled(CaptionBigger)<ISectionTitle>`
	margin-bottom: 24px;
	${({ justifyContent }) => ({ justifyContent })}
	display: flex;
`;

export const SocialMediaWrapper = styled.div<ISocialMediaWrapper>`
	${({ smallView }) => {
		const isMdSmXs = matchScreen(['md', 'sm', 'xs']);
		return {
			paddingBottom: smallView ? 56 : isMdSmXs ? 26 : 8,
			justifyContent: smallView ? 'center' : 'flex-start',
		};
	}}
	margin: 0 -8px;
	display: flex;
	min-height: 24px;
`;

export const SocialMediaContainer = styled.div`
	display: flex;
	align-items: normal;
	justify-content: flex-start;
	align-items: center;
	${({}) => {
		const isMdSmXs = matchScreen(['md', 'sm', 'xs']);
		const isXs = matchScreen(['xs']);
		if (isMdSmXs) {
			return {
				flexDirection: isXs ? 'column-reverse' : 'column',
			};
		}
	}}
`;

export const SocialMediaIconWrapper = styled.a<{ smallView?: boolean }>`
	text-decoration: none;
	margin-bottom: 16px;
	${({}) => {
		const isXs = matchScreen(['xs']);
		return marginRight(isXs ? 0 : 16);
	}}
`;

export const BottomSectionItem = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
`;

export const ContactItemsWrapper = styled.div`
	padding-bottom: 8px;
	${({}) => {
		const isMdSmXs = matchScreen(['md', 'sm', 'xs']);
		if (isMdSmXs) {
			return {
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				paddingBottom: '46px',
			};
		}
	}}
	width: 100%;
`;
export const BottomSectionFirstWrapper = styled.div<IBottomSectionWrapper>`
	display: flex;
	flex-wrap: wrap;
	width: 100%;
	${({}) => {
		const isMdSmXs = matchScreen(['md', 'sm', 'xs']);
		const isXs = matchScreen(['xs']);
		return {
			justifyContent: isMdSmXs ? 'center' : 'flex-start',
			flexDirection: isXs ? 'column' : 'row',
			alignItems: isXs ? 'center' : isMdSmXs ? 'flex-start' : 'normal',
		};
	}}
`;

export const BottomSectionSecondWrapper = styled.div<IBottomSectionWrapper>`
	display: flex;
	flex-wrap: wrap;
	width: 100%;
	${({}) => {
		const isMdSmXs = matchScreen(['md', 'sm', 'xs']);
		const isXs = matchScreen(['xs']);
		return {
			justifyContent: isMdSmXs ? 'center' : 'flex-end',
			gap: isXs ? '0' : '48px',
			flexDirection: isXs ? 'column' : 'row',
			marginBottom: isXs ? '26px' : '0',
		};
	}}
`;

export const MainItemsWrapper = styled.div`
	width: 100%;
	padding-bottom: 8px;
	${({}) => {
		const isMdSmXs = matchScreen(['md', 'sm', 'xs']);
		if (isMdSmXs) {
			return {
				paddingBottom: '46px',
			};
		}
	}}
`;
