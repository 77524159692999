import { CaptionBigger, SmallCurrencyVat, BodyS, MiniDurationBold, InfoBold } from '@Components/Typography/Typography';
import { marginLeft, textRight, right, marginRight } from '@Utils/layout/layoutSupport';
import { Row } from 'react-grid-system';
import styled from 'styled-components';

export const ContinueToCheckoutCardContainer = styled(Row)<{ isSM?: boolean; isMd?: boolean }>`
	${({ isSM, isMd }) => `padding: ${isSM || isMd ? 16 : 0}px 16px ${!isSM && !isMd ? 16 : 8}px`}
`;

export const SecureTransactionWrapper = styled.div<{ isSM?: boolean; isSMMD?: boolean }>`
	display: flex;
	align-items: center;
	justify-content: center;
	${({ isSM, isSMMD }) => `margin-top: ${isSM ? 8 : 16}px;
	margin-bottom: ${isSMMD ? 8 : 0}px;
	`}
`;

export const SecureTransactionText = styled(CaptionBigger)`
	${() => marginLeft('8px')}
`;

export const HighlightedText = styled.span`
	${({ theme }) => ({
		color: theme.colors.black54,
	})}
`;

export const PricesContainer = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

export const PriceBlockContainer = styled.div<{ isBottomMode?: boolean }>`
	${({ isBottomMode }) => ({
		marginTop: isBottomMode ? '8px' : '24px',
	})};
	display: flex;
	flex-direction: column;
`;

export const SmallOMRWrapper = styled(MiniDurationBold)`
	${() => marginRight(2)}
`;

export const StrikethroughWrapper = styled.div`
	font-weight: bold;
	text-decoration: line-through;
	display: inline;
`;

export const PriceHeaderWrapper = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
`;

export const PriceAccordion = styled.div`
	display: flex;
	flex-direction: column;
`;

export const VatAmountContainer = styled(InfoBold)<{ isMakasib?: boolean }>`
	${({ theme, isMakasib }) => ({
		textDecoration: isMakasib ? 'line-through' : 'none',
	})};
`;

export const VatWrapper = styled.div`
	align-self: flex-end;
	justify-self: flex-end;
	display: flex;
	flex-direction: row;
	gap: 2px;
`;

export const DiscountPriceContainer = styled(BodyS)<{ isSmallMd?: boolean }>`
	position: absolute;
	margin-bottom: 0px;
	text-decoration-line: line-through;
	${({ theme, isSmallMd }) => ({
		color: theme.colors.grey,
		top: isSmallMd ? '20px' : '4px',
		...right(isSmallMd ? '142px' : '84px'),
	})};
`;

export const PriceContainer = styled.div``;

export const Vat = styled(SmallCurrencyVat)`
	${() => textRight()}
	${({ theme }) => ({
		color: theme.colors.black54,
	})};
`;
