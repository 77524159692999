import styled from 'styled-components';
import { DisplayMBold, Subheading } from '@Components/Typography/Typography';

export const AppleBrandStoreProductSection = styled.div<{ separator?: boolean }>`
	display: flex;
	align-items: center;
	justify-content: space-between;
	${({ separator }) => {
		return {
			margin: separator ? '-56px 0 28px 0' : '68px 0 28px 0',
		};
	}}
`;

export const Container = styled.div`
	width: 100%;
`;

export const AppleBrandStoreHeroBanner = styled.div<{ isSmall?: boolean; isXS?: boolean }>`
	display: flex;
	flex-direction: column;
	justify-items: center;
	align-items: center;
	border-radius: 8px;
	${({ theme, isSmall, isXS }) => {
		return {
			backgroundColor: theme.colors.white,
			padding: isSmall ? '32px' : isXS ? '24px 16px' : '48px 48px 24px',
		};
	}}
`;

export const AppleBrandStoreProductSubSection = styled.div`
	margin: 32px 0;
`;

export const DescriptionWrapper = styled.div<{ isSmall?: boolean }>`
	margin: 16px 96px 0px;
	text-align: center;
`;

export const SmallDescriptionWrapper = styled.div<{ isSmall?: boolean }>`
	margin: 16px 32px 0px;
	text-align: center;
`;

export const XSDescriptionWrapper = styled.div<{ isSmall?: boolean }>`
	text-align: center;
	margin-top: 16px;
`;

export const IconTextWrapper = styled.div`
	margin-top: 8px;
`;

export const ImageWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-items: center;
	align-items: center;
	margin: 24px;
`;

export const XSImageWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-items: center;
	align-items: center;
	margin: 16px;
	width: 117px;
	height: 72px;
`;

export const ImagesWrapper = styled.div`
	display: flex;
	flex-direction: row;
`;

export const XSImagesWrapper = styled.div`
	display: flex;
	flex-direction: column;
	margin-bottom: 16px;
`;

export const AppleBrandStoreProductSubSectionTitle = styled(Subheading)`
	${({ theme }) => {
		return {
			color: theme.colors.black54,
		};
	}}
`;

export const AppleBrandStoreProductSectionTitle = styled(DisplayMBold)`
	${({ theme }) => {
		return {
			color: theme.colors.black87,
		};
	}}
`;

export const ExtraSupportWrapper = styled.div<{ isSmXs?: boolean }>`
	display: flex;
	${({ isSmXs }) => {
		return {
			flexDirection: isSmXs ? 'column' : 'row',
		};
	}}
	gap: 16px;
`;
