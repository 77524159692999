import React, { FunctionComponent, RefObject, useEffect, useMemo, useRef, useState } from 'react';
import {
	Content,
	MegaMenuContainer,
	ContentContainer,
	MobileMenuButtonsWrapper,
	LanguageButtonWrapper,
	LanguageButton,
	PaymentIconWrapper,
} from '@Components/MegaMenu/MegaMenu.styled';
import MegaMenuSearch from '@Components/MegaMenu/subcomponents/Search/MegaMenuSearch';
import Icon from '@Components/Icon/Icon';
import ConditionalRender from '@Components/ConditionalRender/ConditionalRender';
import MegaMenuMyOmantel from './subcomponents/MyOmantel/MegaMenuMyOmantel';
import { useSelector } from 'react-redux';
import { usePrepaidMigration } from '@Utils/context/PrepaidMigrationContext';
import MegaMenuPostpaid from '@Components/MegaMenu/subcomponents/Postpaid/MegaMenuPostpaid';
import MegaMenuHomeServices from '@Components/MegaMenu/subcomponents/HomeServices/MegaMenuHomeServices';
import MegaMenuStore from '@Components/MegaMenu/subcomponents/Store/MegaMenuStore';
import MegaMenuServices from '@Components/MegaMenu/subcomponents/Services/MegaMenuServices';
import MegaMenuPrepaid from '@Components/MegaMenu/subcomponents/Prepaid/MegaMenuPrepaid';
import { isRTL, matchScreen } from '@Utils/layout/layoutSupport';
import { useHistory, useLocation } from 'react-router-dom';
import { getLanguage } from '@Utils/language/language';
import { dispatch } from '@Redux/store';
import { setLanguage } from '@Redux/modules/settings/language/actions';
import { BodySHigher } from '@Components/Typography/Typography.styled';
import useTranslate from '@Utils/hooks/useTranslate';
import externalLinks from '@Utils/externalLinks/externalLinks';

const ARROW_ICON_SIZE = 16;
const ICONS_SIZE = 24;

export type MegaMenuContext =
	| 'store'
	| 'postpaid'
	| 'prepaid'
	| 'homeservices'
	| 'services'
	| 'search'
	| 'myomantel'
	| '';

interface IMegaMenu {
	handleCloseMobileMenu?: () => void;
	open: boolean;
	mobileMenu?: boolean;
	isMd?: boolean;
	context: MegaMenuContext;
	itemsBarRef?: RefObject<HTMLDivElement>;
	setMegaMenuOpen: (megaMenuOpen: boolean) => void;
	setMegaMenuContext: (megaMenuOpen: string) => void;
	activeMenuIndex: number;
	setActiveMenuIndex: (activeMenuIndex: number) => void;
	setMenuExpanded: (index: boolean) => void;
}

export const CLOSE_BUTTON_SIZE = 24;
export const RIGHT_DIVIDER_MARGIN_BOTTOM = 56;

const MegaMenu: FunctionComponent<IMegaMenu> = ({
	handleCloseMobileMenu,
	open,
	context,
	mobileMenu,
	isMd,
	setMegaMenuOpen,
	setMegaMenuContext,
	itemsBarRef,
	activeMenuIndex,
	setActiveMenuIndex,
	setMenuExpanded,
}) => {
	const [forceClose, setForceClose] = useState<boolean>(false);
	const [hasFocusedElement, setHasFocusedElement] = useState<boolean>(false);
	const isLogged = !!useSelector((state: types.redux.IState) => state.api.authentication.signedIn);
	const language = useSelector((state: types.redux.IState) => state.settings.language);
	const telemarketer = useSelector((state: types.redux.IState) => state.api.authentication.telemarketer);
	const wrapperRef = useRef<HTMLDivElement>(null);
	const { choosenNumber } = usePrepaidMigration();
	const isSmallDevice = matchScreen(['xs', 'sm']);
	const locationHook = useLocation();
	const { search } = useLocation();
	const history = useHistory();
	const { translate } = useTranslate();

	const isOpen = useMemo(() => {
		return (open || hasFocusedElement) && !forceClose;
	}, [open, hasFocusedElement, forceClose]);

	const minHeight = useMemo(() => {
		switch (context) {
			case 'store':
				return 240;
			case 'postpaid':
				return 305;
			case 'prepaid':
				return 305;
			case 'homeservices':
				return 340;
			case 'services':
				return 268;
			case 'search':
				return 430;
			case 'myomantel':
				return isLogged ? 244 : 352;
			default:
				return 400;
		}
	}, [context, mobileMenu]);

	const handleForceClose = () => {
		setForceClose(true);
		setTimeout(() => {
			setMegaMenuOpen(false);
			if (handleCloseMobileMenu) {
				handleCloseMobileMenu();
			}
			setForceClose(false);
		}, 400);
	};

	useEffect(() => {
		document.addEventListener('mousedown', useOutsideAlerter);
	}, []);

	const useOutsideAlerter = (event: any) => {
		if (wrapperRef.current && !wrapperRef?.current?.contains(event.target)) {
			if (itemsBarRef && itemsBarRef.current && !itemsBarRef?.current?.contains(event.target)) {
				setMegaMenuOpen(false);
			}
		}
	};

	const handleChangeLanguage = () => {
		const query = new URLSearchParams(search);
		if (getLanguage() === 'EN') {
			history.push(
				`/ar/${location.pathname.substring(4, locationHook.pathname.length)}${query ? `?${query}` : ''}`
			);
			dispatch(setLanguage('AR'));
		} else {
			dispatch(setLanguage('EN'));
			history.push(
				`/en/${location.pathname.substring(4, locationHook.pathname.length)}${query ? `?${query}` : ''}`
			);
		}
	};

	const underMenuButtons = () => {
		return (
			<ConditionalRender
				show={!!mobileMenu}
				onTrue={() => (
					<MobileMenuButtonsWrapper>
						<LanguageButtonWrapper onClick={handleChangeLanguage}>
							<LanguageButton>{translate('navbar.language')}</LanguageButton>
							<Icon
								name={isRTL() ? 'arrowLeftBox' : 'arrowRightBox'}
								fill="primary"
								width={ARROW_ICON_SIZE}
								height={ARROW_ICON_SIZE}
							/>
						</LanguageButtonWrapper>
						<PaymentIconWrapper href={externalLinks('PERSONAL_BILL_PAYMENT')}>
							<BodySHigher color="black87">{translate('megamenu.search.pay-bill')}</BodySHigher>
							<Icon name="payBill" width={ICONS_SIZE} height={ICONS_SIZE} fill="primary" />
						</PaymentIconWrapper>
						<PaymentIconWrapper href={externalLinks('TOP_UP')}>
							<BodySHigher color="black87">{translate('megamenu.search.top-up')}</BodySHigher>
							<Icon name="topUp" width={ICONS_SIZE} height={ICONS_SIZE} fill="primary" />
						</PaymentIconWrapper>
					</MobileMenuButtonsWrapper>
				)}
			/>
		);
	};

	const content = useMemo(() => {
		switch (context) {
			case 'store':
				return (
					<Content open={isOpen} activeMenuIndex={activeMenuIndex} context={context}>
						<ContentContainer
							mobileMenu={!!mobileMenu}
							withAccent={!mobileMenu}
							isMd={isMd}
							style={isSmallDevice ? { maxWidth: 'unset' } : {}}
						>
							<MegaMenuStore
								setMenuExpanded={setMenuExpanded}
								setMegaMenuOpen={setMegaMenuOpen}
								setMegaMenuContext={setMegaMenuContext}
								activeMenuIndex={activeMenuIndex}
								setActiveMenuIndex={setActiveMenuIndex}
								mobileMenu={!!mobileMenu}
								isMd={!!isMd}
							/>
						</ContentContainer>
						{underMenuButtons()}
					</Content>
				);
			case 'postpaid':
				return (
					<Content
						extendMore={true}
						open={isOpen}
						height="86%"
						beforeHeight="43px"
						withAccent={!mobileMenu}
						isMd={isMd}
						activeMenuIndex={activeMenuIndex}
						context={context}
					>
						<ContentContainer
							mobileMenu={!!mobileMenu}
							withAccent={!mobileMenu}
							isMd={isMd}
							style={isSmallDevice ? { maxWidth: 'unset' } : {}}
						>
							<MegaMenuPostpaid
								activeMenuIndex={activeMenuIndex}
								setActiveMenuIndex={setActiveMenuIndex}
								mobileMenu={!!mobileMenu}
								isMd={!!isMd}
							/>
						</ContentContainer>
						{underMenuButtons()}
					</Content>
				);
			case 'prepaid':
				return (
					<Content
						extendMore={true}
						open={isOpen}
						height="86%"
						beforeHeight="43px"
						withAccent={!mobileMenu}
						isMd={isMd}
						activeMenuIndex={activeMenuIndex}
						context={context}
					>
						<ContentContainer
							mobileMenu={!!mobileMenu}
							withAccent={!mobileMenu}
							isMd={isMd}
							style={isSmallDevice ? { maxWidth: 'unset' } : {}}
						>
							<MegaMenuPrepaid
								activeMenuIndex={activeMenuIndex}
								setActiveMenuIndex={setActiveMenuIndex}
								mobileMenu={!!mobileMenu}
								isMd={!!isMd}
							/>
						</ContentContainer>
						{underMenuButtons()}
					</Content>
				);
			case 'homeservices':
				return (
					<Content
						extendMore={true}
						open={isOpen}
						height="86%"
						beforeHeight="143px"
						withAccent={!mobileMenu}
						isMd={isMd}
						activeMenuIndex={activeMenuIndex}
						style={isSmallDevice ? { maxWidth: 'unset' } : {}}
						context={context}
					>
						<ContentContainer
							mobileMenu={!!mobileMenu}
							withAccent={!mobileMenu}
							isMd={isMd}
							style={isSmallDevice ? { maxWidth: 'unset' } : {}}
						>
							<MegaMenuHomeServices
								activeMenuIndex={activeMenuIndex}
								setActiveMenuIndex={setActiveMenuIndex}
								mobileMenu={!!mobileMenu}
								isMd={!!isMd}
							/>
						</ContentContainer>
						{underMenuButtons()}
					</Content>
				);
			case 'services':
				return (
					<Content
						extendMore={true}
						open={isOpen}
						height="86%"
						beforeHeight="143px"
						withAccent={!mobileMenu}
						isMd={isMd}
						activeMenuIndex={activeMenuIndex}
						style={isSmallDevice ? { maxWidth: 'unset' } : {}}
						context={context}
					>
						<ContentContainer
							mobileMenu={!!mobileMenu}
							withAccent={!mobileMenu}
							isMd={isMd}
							style={isSmallDevice ? { maxWidth: 'unset' } : {}}
						>
							<MegaMenuServices
								activeMenuIndex={activeMenuIndex}
								setActiveMenuIndex={setActiveMenuIndex}
								mobileMenu={!!mobileMenu}
								isMd={!!isMd}
							/>
						</ContentContainer>
						{underMenuButtons()}
					</Content>
				);
			case 'search':
				return (
					<Content open={isOpen} activeMenuIndex={activeMenuIndex} context={context}>
						<ContentContainer mobileMenu={!!mobileMenu} withAccent={false} isMd={isMd}>
							<MegaMenuSearch
								setFocus={setHasFocusedElement}
								forceClose={handleForceClose}
								mobileMenu={!!mobileMenu}
							/>
						</ContentContainer>
					</Content>
				);
			case 'myomantel':
				return (
					<Content open={isOpen} activeMenuIndex={activeMenuIndex} context={context}>
						<ContentContainer mobileMenu={!!mobileMenu} withAccent={!mobileMenu} isMd={isMd}>
							<MegaMenuMyOmantel forceClose={handleForceClose} mobileMenu={!!mobileMenu} isMd={!!isMd} />
						</ContentContainer>
					</Content>
				);
		}
	}, [context, location.pathname, language, open, mobileMenu, isOpen, activeMenuIndex]);

	return (
		<MegaMenuContainer
			open={isOpen}
			minHeight={minHeight}
			mobileMenu={!!mobileMenu}
			isLogged={isLogged}
			ref={wrapperRef}
			telemarketer={!!telemarketer}
			prepaidChosenNumber={!!choosenNumber}
			activeMenuIndex={activeMenuIndex}
			context={context}
		>
			{content}
		</MegaMenuContainer>
	);
};

export default MegaMenu;
