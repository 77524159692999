import styled from 'styled-components';

interface ICAccountWrapperProps {
	footerHeight?: number;
	minheight: number;
}

export const AccountWrapper = styled.div<ICAccountWrapperProps>`
	position: relative;
	${({ footerHeight, theme }) => ({
		backgroundColor: theme.colors.background,
		paddingBottom: footerHeight,
	})}
	min-height: ${({ minheight }) => minheight}px;
`;

export const MenuWrapper = styled.div`
	padding: 16px;
	border-radius: 8px;
	${({ theme }) => ({
		backgroundColor: theme.colors.white,
	})}
`;
