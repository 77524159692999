import React, { FunctionComponent } from 'react';
import Card from '@Components/Card/Card';

const ModalNotifyAvailability: FunctionComponent<types.cards.IGenericErrorCardProps['params']> = ({
	icon,
	iconFill,
	title,
	description,
	withButton,
	goToCartButton,
}) => {
	return (
		<Card<types.cards.IGenericErrorCardProps>
			card="genericError"
			params={{ icon, iconFill, title, description, withButton, goToCartButton }}
			withoutContainerPadding
		/>
	);
};

export default ModalNotifyAvailability;
