import styled from 'styled-components';
import { textLeft } from '@Utils/layout/layoutSupport';
import { ParagraphM } from '@Components/Typography/Typography.styled';

export const Container = styled.div`
	${({ theme }) => ({
		backgroundColor: theme.colors.grey100,
	})};
	border-radius: 8px;
	padding: 16px;
	margin-bottom: 16px;
`;

export const Title = styled(ParagraphM)`
	${({ theme }) => ({
		paddingTop: 16,
		color: theme.colors.black87,
	})}
	${() => textLeft()}
`;

export const ButtonContainer = styled.div`
	width: 100%;
`;

export const TopContainer = styled.div`
	display: flex;
`;

export const IconContainer = styled.div`
	min-width: 152px;
	position: relative;
	display: flex;
	justify-content: flex-start;
`;
