import Card from '@Components/Card/Card';
import Divider from '@Components/Divider/Divider';
import Icon from '@Components/Icon/Icon';
import { setModal } from '@Redux/modules/modal/actions';
import useTranslate from '@Utils/hooks/useTranslate';
import React, { FunctionComponent, useState } from 'react';
import { Col, Row, Visible } from 'react-grid-system';
import { useDispatch } from 'react-redux';
import { AddedToCartCardContainer, TitleContainer, TitleWrapper } from './Upsell.styled';
import { TileSize } from '@Components/Card/cards/ProductCard/ProductCard.styled';
import { IRelatedProductByIdItem } from '@ApiModels/relatedProductsById';

const CLOSE_ICON_SIZE = 24;

const AddedToCart: FunctionComponent<types.cards.IUpsellCardProps> = ({ params: { products, upsellProducts } }) => {
	const [filteredProducts, setFilteredProducts] = useState<IRelatedProductByIdItem[]>([]);
	const dispatch = useDispatch();
	const { translate } = useTranslate();

	const closeModal = () => {
		dispatch(
			setModal({
				type: null,
				data: null,
			})
		);
	};

	const getItemWidth = (productsNumber: number) => {
		if ([1, 2].includes(productsNumber)) {
			return 6;
		} else {
			return 4;
		}
	};

	return (
		<AddedToCartCardContainer>
			<div>
				<TitleContainer>
					<TitleWrapper>{translate('upsell.title')}</TitleWrapper>
					<Icon
						name="close"
						fill="black87"
						width={CLOSE_ICON_SIZE}
						height={CLOSE_ICON_SIZE}
						onClick={closeModal}
					/>
				</TitleContainer>
				<Visible xs>
					<Divider />
				</Visible>
			</div>
			<Row>
				<>
					{upsellProducts
						?.slice(0, 3)
						.filter((item, index, sortedProducts) => {
							const iphone14ProFirstOccurrenceIndex = sortedProducts.findIndex(
								(product) => product.name === 'iPhone 14 Pro'
							);
							if (index === iphone14ProFirstOccurrenceIndex) return true;
							else
								return (
									item.name !== 'iPhone 14 Pro' &&
									item.inventory &&
									Object.keys(item.inventory).length !== 0
								);
						})
						.map((product, index) => (
							<Col key={index} sm={getItemWidth(products.length)}>
								<Card<types.cards.IProductCardProps>
									card="product"
									params={{
										product,
										tileSize: TileSize.BIG,
										iconsHidden: true,
										layoutChange: false,
										buttonAction: () => closeModal(),
										isRelatedProduct: true,
									}}
									marginTop={0}
									marginBottom={10}
								/>
							</Col>
						))}
				</>
			</Row>
		</AddedToCartCardContainer>
	);
};

export default AddedToCart;
