import { ApiQuery } from '@Utils/api';
type InvalidateArray = ApiQuery[];
import { dispatch, store } from '@Redux/store';
import { invalidateCache } from '@Redux/modules/api/cache/actions';

export default function (invalidateArray: InvalidateArray, forAllUsers = false): void {
	const hashMap = store.getState().api.cache.hashMap;
	if (Array.isArray(invalidateArray)) {
		invalidateArray.forEach((invalidateKey) => {
			const currentInvalidateInfo = hashMap[invalidateKey];
			if (currentInvalidateInfo) {
				dispatch(
					invalidateCache({
						endpointUrl: currentInvalidateInfo.endpointUrl,
						payloadHash: forAllUsers ? currentInvalidateInfo.currentHash : undefined,
						source: currentInvalidateInfo.source,
					})
				);
			}
		});
	}
}
