import { matchScreen, marginRight, marginLeft, isRTL } from '@Utils/layout/layoutSupport';
import styled from 'styled-components';

export const InformationWrapper = styled.div`
	display: flex;
	flex-direction: column;
`;

export const SmallLabelWrapper = styled.div`
	margin-top: 4px;
`;

export const WilayatLabelWrapper = styled.div`
	${() => ({
		marginTop: matchScreen('xs') ? '24px' : '0px',
	})}
`;

export const ShortContentWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	align-content: center;
	${() => marginRight(matchScreen('xs') ? '0px' : '16px')}
`;

export const LongContentWrapper = styled.div`
	display: flex;
	flex-directon: column;
	${() => ({
		marginTop: matchScreen('xs') ? '8px' : '0px',
	})}
	${() => marginLeft(matchScreen('xs') || matchScreen('sm') ? '16px' : '0px')}
`;

export const LongContentLabelWrapper = styled.div`
	margin-bottom: 8px;
	margin-top: 24px;
`;

export const ProductAvailableWrapper = styled.div`
	display: flex;
`;

export const DividerWrapper = styled.div`
	${() => ({
		margin: matchScreen('xs') ? '20px 0px' : '16px 16px 16px 0px',
	})}
`;

export const IconWrapper = styled.div<{ positive: boolean }>`
	${({ positive }) => ({
		margin: positive ? '0px 8px 0px 8px' : isRTL() ? '0px 8px 0px 32px' : '0px 32px 0px 8px',
	})}
`;

export const LabelWrapper = styled.div`
	margin-top: 4px;
`;

export const HourLabelWrapper = styled.div`
	margin-bottom: 8px;
	display: flex;
`;

export const MapWrapper = styled.div`
	${() => ({
		height: matchScreen('xs') || matchScreen('sm') ? 220 : 334,
		width: matchScreen('xs') || matchScreen('sm') ? 468 : 400,
	})}
	${() => marginRight(matchScreen('xs') || matchScreen('sm') ? '0' : '40px')}
`;

export const MapElement = styled.div`
	width: 100%;
	height: 100%;
	${() => ({
		paddingTop: matchScreen('xs') ? 16 : 0,
	})}
`;
