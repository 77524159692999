import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import ConditionalRender from '@Components/ConditionalRender/ConditionalRender';
import Divider from '@Components/Divider/Divider';
import useTranslate from '@Utils/hooks/useTranslate';
import {
	OrderCardContainer,
	OrderCardTitle,
	PriceAccordion,
	PriceBlockContainer,
	PriceHeaderWrapper,
	SmallOMRWrapper,
	VatWrapper,
	VatAmountContainer,
	StrikethroughWrapper,
} from './Order.styled';
import OrderItem from './subcomponents/OrderItem';
import CartAdditionalInfo from '@Components/CartAdditionalInfo/CartAdditionalInfo';
import { BUY_HBB, BUY_WFBB } from '@Views/Map/Map';
import { ItemType } from '@Services/productsService/productsService';
import { MAKASIB_POINTS_PRODUCT_ID, MAKASIB_VOUCHER_PRODUCT_ID, POSTPAID_PLAN } from '@Config/app.config';
import { BodyMBold, Info, InfoBold, CaptionBiggerBold, HeadlineBold } from '@Components/Typography/Typography.styled';
import { roundMoney } from '@Utils/converters/roundMoney';
import { PriceTagWrapper } from '@Components/PriceTag/PriceTag.styled';
import { useCart } from '@Utils/context/CartContext';
import useScreen from '@Utils/hooks/useScreen';
import { ICartItem } from '@ApiModels/viewCart';
import { useBuyNowPayLater } from '@Utils/context/BuyNowPayLaterContext';

const GIFTCARD = 'GIFTCARD';

const OrderCard: FunctionComponent<types.cards.IOrderCardProps> = ({ params: { withPlan } }) => {
	const { translate } = useTranslate();
	const { isBuyNowPayLaterInCart } = useBuyNowPayLater();
	const {
		cart,
		vatPercentHighest,
		twoYearInstallmentValue,
		cartItemsVatValue,
		cartItemsDevicePlanVatSum,
		cartItemsPlanPriceSum,
		cartItemsDeviceInstallmentSum,
		isEbuCart,
	} = useCart();

	const periodPrice = cartItemsPlanPriceSum + cartItemsDeviceInstallmentSum;

	const preorderDate = cart?.items[0]?.plan?.preorderDate;
	const isHbbWfbbItem = cart?.items.find((item) => [BUY_HBB, BUY_WFBB].includes(item.plan?.PA || ''));
	const [ebuItem, setEbuItem] = useState<ICartItem>();
	const hasMoreItemsInCart = cart?.items.length > 1;
	const [valueOfDiscount, setValueOfDiscount] = useState<number>(0);
	const [totalWithVat, setTotalWithVat] = useState(0);
	const [totalWithoutVat, setTotalWithoutVat] = useState(0);

	const { screen } = useScreen();

	const vatPercent = cart.items.reduce((accumulator, currentValue) => {
		if (currentValue?.plan?.vatPercent) {
			if (accumulator < Number(currentValue?.plan?.vatPercent)) {
				accumulator = Number(currentValue?.plan?.vatPercent);
			}
			return accumulator;
		} else {
			return accumulator;
		}
	}, 0);

	const vouchersValue = useMemo(() => {
		let counter = 0;
		cart.items
			.filter((item) => item.productId === MAKASIB_VOUCHER_PRODUCT_ID)
			.forEach((item) => (counter = counter + Number(item.usedVoucherValueInOMR ?? 0)));
		return counter;
	}, [cart.items]);

	const monthlyVat = cart.items.reduce((accumulator, currentValue) => {
		if (
			currentValue?.plan?.planMonthy &&
			currentValue?.plan?.PA !== ItemType.ITEM_ACCESSORY_INSTALMENTS &&
			currentValue?.plan?.PA !== ItemType.ITEM_ACCESSORY_INSTALMENTS_HBB &&
			currentValue?.plan?.PA !== ItemType.ITEM_ACCESSORY_INSTALMENTS_WFBB &&
			currentValue?.plan?.PA !== ItemType.TABLETS_LAPTOPS_INSTALMENTS &&
			currentValue?.plan?.PA !== ItemType.TABLETS_LAPTOPS_INSTALMENTS_HBB &&
			currentValue?.plan?.PA !== ItemType.TABLETS_LAPTOPS_INSTALMENTS_WFBB &&
			currentValue?.plan?.PA !== ItemType.B2B2C_ITEM_ACCESSORY_BAQATI &&
			currentValue?.plan?.PA !== ItemType.B2B2C_TABLETS_LAPTOPS_BAQATI &&
			currentValue?.plan?.PA !== ItemType.B2B_ITEM_ACCESSORY_BAQATI &&
			currentValue?.plan?.PA !== ItemType.B2B_TABLETS_LAPTOPS_BAQATI &&
			!currentValue?.plan?.PA?.includes(POSTPAID_PLAN)
		) {
			const itemQuantity = Number(currentValue?.quantity) || 0;
			const installmentValue = Number(currentValue?.plan?.IV) || 0;
			const planMonthy = Number(currentValue?.plan?.planMonthy) || 0;
			return accumulator + installmentValue * itemQuantity + planMonthy * itemQuantity;
		} else if (
			(currentValue?.plan?.planMonthy &&
				(currentValue?.plan?.PA === ItemType.ITEM_ACCESSORY_INSTALMENTS ||
					currentValue?.plan?.PA === ItemType.ITEM_ACCESSORY_INSTALMENTS_HBB ||
					currentValue?.plan?.PA === ItemType.ITEM_ACCESSORY_INSTALMENTS_WFBB ||
					currentValue?.plan?.PA === ItemType.TABLETS_LAPTOPS_INSTALMENTS ||
					currentValue?.plan?.PA === ItemType.TABLETS_LAPTOPS_INSTALMENTS_WFBB ||
					currentValue?.plan?.PA === ItemType.TABLETS_LAPTOPS_INSTALMENTS_HBB)) ||
			currentValue?.plan?.PA === ItemType.B2B2C_ITEM_ACCESSORY_BAQATI ||
			currentValue?.plan?.PA === ItemType.B2B2C_TABLETS_LAPTOPS_BAQATI ||
			currentValue?.plan?.PA === ItemType.B2B_ITEM_ACCESSORY_BAQATI ||
			currentValue?.plan?.PA === ItemType.B2B_TABLETS_LAPTOPS_BAQATI
		) {
			const installmentValue = Number(currentValue?.plan?.IV) || 0;
			return accumulator + installmentValue;
		} else {
			return accumulator;
		}
	}, 0);

	const undiscountableCartValue = useMemo(() => {
		const undiscoundableItems = cart.items.filter((item) => item.defType === GIFTCARD);
		if (undiscoundableItems.length > 0) {
			let value = 0;
			undiscoundableItems.forEach((item) => (value = +Number(item.taxablePrice ?? 0)));
			return value;
		} else return 0;
	}, [cart]);

	const undiscountableTotalCartValue: number = useMemo(() => {
		const undiscoundableItems = cart.items.filter((item) => item.defType === GIFTCARD);
		if (undiscoundableItems.length > 0) {
			let value = 0;
			undiscoundableItems.forEach((item) => (value = +Number(item.unitPrice ?? 0)));
			return value;
		} else return 0;
	}, [cart]);

	useEffect(() => {
		setValueOfDiscount(
			Number(cart.totalProductPrice) -
				(!!cart.items.find((item) => item.productId === MAKASIB_POINTS_PRODUCT_ID)?.price
					? Number(cart.items.find((item) => item.productId === MAKASIB_POINTS_PRODUCT_ID)?.price)
					: 0) +
				vouchersValue
		);
		setEbuItem(cart?.items?.[0]);
	}, [cart]);

	useEffect(() => {
		let totalWithoutVat = 0;
		let totalWithVat = 0;
		cart.items.map((item) => {
			if (item.PA?.includes('BNPL')) {
				totalWithoutVat = totalWithoutVat + Number(item.BNPL_UPFRONT_MINUS_VAT || 0);
				totalWithVat = totalWithVat + Number(item.BNPL_UPFRONT_AMOUNT);
			} else {
				totalWithoutVat = totalWithoutVat + Number(item?.taxablePrice || 0);
				totalWithVat = totalWithVat + Number(item.price);
			}
		});
		setTotalWithVat(totalWithVat);
		setTotalWithoutVat(totalWithoutVat);
	}, [cart.items]);

	return (
		<OrderCardContainer>
			<OrderCardTitle>{translate('order-card.title')}</OrderCardTitle>
			{cart.items
				.filter((item) => item.productId !== MAKASIB_POINTS_PRODUCT_ID)
				.filter((item) => item.productId !== MAKASIB_VOUCHER_PRODUCT_ID)
				.map((cartItem) => (
					<OrderItem key={cartItem.orderItemId} item={cartItem} />
				))}
			<ConditionalRender
				show={!!preorderDate}
				onTrue={() => <CartAdditionalInfo preorder={{ preorder: !!preorderDate, preorderDate }} />}
			/>
			<ConditionalRender
				show={isBuyNowPayLaterInCart && !isEbuCart}
				onTrue={() => (
					<>
						<ConditionalRender
							show={!isHbbWfbbItem || hasMoreItemsInCart}
							onTrue={() => (
								<>
									<Divider marginTop={16} marginBottom={24} />
									<PriceBlockContainer>
										<PriceAccordion>
											<PriceHeaderWrapper>
												<ConditionalRender
													show={screen(['xl', 'lg'])}
													onTrue={() => (
														<CaptionBiggerBold>
															{translate('product.cart-card.upfront')}
														</CaptionBiggerBold>
													)}
													onFalse={() => (
														<BodyMBold>{translate('product.cart-card.upfront')}</BodyMBold>
													)}
												/>
												<PriceTagWrapper>
													<SmallOMRWrapper>{translate('omr')}</SmallOMRWrapper>
													<ConditionalRender
														show={screen(['xl', 'lg'])}
														onTrue={() => (
															<BodyMBold>
																{isBuyNowPayLaterInCart
																	? roundMoney(totalWithoutVat)
																	: roundMoney(
																			Number(cart.totalProductPrice) -
																				cartItemsVatValue ?? 0
																	  )}
															</BodyMBold>
														)}
														onFalse={() => (
															<HeadlineBold>
																{isBuyNowPayLaterInCart
																	? roundMoney(totalWithoutVat)
																	: roundMoney(
																			Number(cart.totalProductPrice) -
																				cartItemsVatValue ?? 0
																	  )}
															</HeadlineBold>
														)}
													/>
												</PriceTagWrapper>
											</PriceHeaderWrapper>
											<VatWrapper>
												<Info color="black54">
													{translate('product.cart-card.with-vat', vatPercentHighest ?? 0)}
												</Info>
												<VatAmountContainer color="black54">
													{isBuyNowPayLaterInCart
														? `${translate('omr')} ${roundMoney(totalWithVat)}`
														: `${translate('omr')} ${roundMoney(cart.totalProductPrice)}`}
												</VatAmountContainer>
											</VatWrapper>
										</PriceAccordion>
									</PriceBlockContainer>
								</>
							)}
						/>

						<PriceBlockContainer>
							<PriceAccordion>
								<PriceHeaderWrapper>
									<ConditionalRender
										show={screen(['xl', 'lg'])}
										onTrue={() => (
											<CaptionBiggerBold>
												{twoYearInstallmentValue > 0
													? translate('product.cart-card.23months')
													: translate('product.cart-card.11months')}
											</CaptionBiggerBold>
										)}
										onFalse={() => (
											<BodyMBold>
												{twoYearInstallmentValue > 0
													? translate('product.cart-card.23months')
													: translate('product.cart-card.11months')}
											</BodyMBold>
										)}
									/>
									<PriceTagWrapper>
										<SmallOMRWrapper>{translate('omr')}</SmallOMRWrapper>
										<ConditionalRender
											show={screen(['xl', 'lg'])}
											onTrue={() => (
												<BodyMBold>
													{roundMoney(periodPrice - cartItemsDevicePlanVatSum)}
												</BodyMBold>
											)}
											onFalse={() => (
												<HeadlineBold>
													{roundMoney(periodPrice - cartItemsDevicePlanVatSum)}
												</HeadlineBold>
											)}
										/>
									</PriceTagWrapper>
								</PriceHeaderWrapper>
							</PriceAccordion>
						</PriceBlockContainer>
						<PriceBlockContainer>
							<PriceAccordion>
								<PriceHeaderWrapper>
									<ConditionalRender
										show={screen(['xl', 'lg'])}
										onTrue={() => (
											<CaptionBiggerBold>{translate('product.cart-card.last')}</CaptionBiggerBold>
										)}
										onFalse={() => <BodyMBold>{translate('product.cart-card.last')}</BodyMBold>}
									/>
									<PriceTagWrapper>
										<SmallOMRWrapper>{translate('omr')}</SmallOMRWrapper>
										<ConditionalRender
											show={screen(['xl', 'lg'])}
											onTrue={() => <BodyMBold>{roundMoney(cart.items[0].BNPLFEP)}</BodyMBold>}
											onFalse={() => (
												<HeadlineBold>{roundMoney(cart.items[0].BNPLFEP)}</HeadlineBold>
											)}
										/>
									</PriceTagWrapper>
								</PriceHeaderWrapper>
							</PriceAccordion>
						</PriceBlockContainer>
					</>
				)}
				onFalse={() => (
					<>
						<ConditionalRender
							show={!isHbbWfbbItem || hasMoreItemsInCart}
							onTrue={() => (
								<>
									<Divider marginTop={16} marginBottom={24} />
									<PriceBlockContainer>
										<PriceAccordion>
											<PriceHeaderWrapper>
												<ConditionalRender
													show={screen(['xl', 'lg'])}
													onTrue={() => (
														<CaptionBiggerBold>
															{translate(
																!monthlyVat
																	? 'payment.summary.price'
																	: 'product.cart-card.upfront'
															)}
														</CaptionBiggerBold>
													)}
													onFalse={() => (
														<BodyMBold>
															{translate(
																!monthlyVat
																	? 'payment.summary.price'
																	: 'product.cart-card.upfront'
															)}
														</BodyMBold>
													)}
												/>
												<PriceTagWrapper>
													<SmallOMRWrapper>{translate('omr')}</SmallOMRWrapper>
													<ConditionalRender
														show={screen(['xl', 'lg'])}
														onTrue={() => (
															<BodyMBold>
																{roundMoney(
																	isEbuCart
																		? ebuItem?.taxablePrice
																		: Number(cart.totalProductPrice) -
																				cartItemsVatValue <
																		  0
																		? undiscountableCartValue
																		: (Number(cart.totalProductPrice) -
																				cartItemsVatValue ?? 0) <
																		  undiscountableCartValue
																		? undiscountableCartValue
																		: Number(cart.totalProductPrice) -
																				cartItemsVatValue ?? 0
																)}
															</BodyMBold>
														)}
														onFalse={() => (
															<HeadlineBold>
																{roundMoney(
																	isEbuCart
																		? ebuItem?.taxablePrice
																		: Number(cart.totalProductPrice) -
																				cartItemsVatValue <
																		  0
																		? undiscountableCartValue
																		: (Number(cart.totalProductPrice) -
																				cartItemsVatValue ?? 0) <
																		  undiscountableCartValue
																		? undiscountableCartValue
																		: Number(cart.totalProductPrice) -
																				cartItemsVatValue ?? 0
																)}
															</HeadlineBold>
														)}
													/>
												</PriceTagWrapper>
											</PriceHeaderWrapper>
											<VatWrapper>
												<Info color="black54">
													{translate(
														'product.cart-card.with-vat',
														isEbuCart ? ebuItem?.vatPercent || 0 : vatPercentHighest ?? 0
													)}
												</Info>
												<VatAmountContainer
													color="black54"
													isMakasib={cart.items.some(
														(item) =>
															item.productId === MAKASIB_POINTS_PRODUCT_ID ||
															item.productId === MAKASIB_VOUCHER_PRODUCT_ID
													)}
												>
													{`${translate('omr')} ${roundMoney(
														isEbuCart
															? ebuItem?.vatValue
															: cart.items.some(
																	(item) =>
																		item.productId === MAKASIB_POINTS_PRODUCT_ID ||
																		item.productId === MAKASIB_VOUCHER_PRODUCT_ID
															  )
															? valueOfDiscount ?? 0
															: cart.totalProductPrice
													)}`}
												</VatAmountContainer>
											</VatWrapper>
											<ConditionalRender
												show={cart.items.some(
													(item) =>
														item.productId === MAKASIB_POINTS_PRODUCT_ID ||
														item.productId === MAKASIB_VOUCHER_PRODUCT_ID
												)}
												onTrue={() => (
													<VatWrapper>
														<Info color="primary">
															{translate('product.cart-card.after-discount')}
														</Info>
														<VatAmountContainer color="primary">
															{`OMR ${roundMoney(
																Number(cart.totalProductPrice) < 0
																	? 0
																	: Number(cart.totalProductPrice) ?? 0
															)}`}
														</VatAmountContainer>
													</VatWrapper>
												)}
											/>
										</PriceAccordion>
									</PriceBlockContainer>
								</>
							)}
						/>
						<ConditionalRender
							show={!!periodPrice}
							onTrue={() => (
								<>
									<PriceBlockContainer>
										<PriceAccordion>
											<PriceHeaderWrapper>
												<ConditionalRender
													show={screen(['xl', 'lg'])}
													onTrue={() => (
														<CaptionBiggerBold>
															{isEbuCart
																? translate('payment.summary.description.installments')
																: twoYearInstallmentValue > 0
																? screen('lg')
																	? translate('product.cart-card.24mo')
																	: translate('product.cart-card.for24months')
																: screen('lg')
																? translate('product.cart-card.for12mo')
																: translate('product.cart-card.for12months')}
														</CaptionBiggerBold>
													)}
													onFalse={() => (
														<BodyMBold>
															{isEbuCart
																? translate('payment.summary.description.installments')
																: twoYearInstallmentValue > 0
																? screen('lg')
																	? translate('product.cart-card.24mo')
																	: translate('product.cart-card.for24months')
																: screen('lg')
																? translate('product.cart-card.for12mo')
																: translate('product.cart-card.for12months')}
														</BodyMBold>
													)}
												/>
												<PriceTagWrapper>
													<SmallOMRWrapper>{translate('omr')}</SmallOMRWrapper>
													<ConditionalRender
														show={screen(['xl', 'lg'])}
														onTrue={() => (
															<BodyMBold>
																<ConditionalRender
																	show={isEbuCart}
																	onTrue={() => (
																		<>
																			<StrikethroughWrapper>
																				{roundMoney(
																					(Number(ebuItem?.standardPrice) ||
																						0) /
																						(Number(
																							ebuItem?.installmentDuration
																						) || 0)
																				)}
																			</StrikethroughWrapper>
																		</>
																	)}
																	onFalse={() => (
																		<>
																			{roundMoney(
																				periodPrice - cartItemsDevicePlanVatSum
																			)}
																		</>
																	)}
																/>
															</BodyMBold>
														)}
														onFalse={() => (
															<HeadlineBold>
																<ConditionalRender
																	show={isEbuCart}
																	onTrue={() => (
																		<>
																			<StrikethroughWrapper>
																				{roundMoney(
																					(Number(ebuItem?.standardPrice) ||
																						0) /
																						(Number(
																							ebuItem?.installmentDuration
																						) || 0)
																				)}
																			</StrikethroughWrapper>
																		</>
																	)}
																	onFalse={() => (
																		<>
																			{roundMoney(
																				periodPrice - cartItemsDevicePlanVatSum
																			)}
																		</>
																	)}
																/>
															</HeadlineBold>
														)}
													/>
												</PriceTagWrapper>
											</PriceHeaderWrapper>
											<ConditionalRender
												show={!isEbuCart}
												onTrue={() => (
													<VatWrapper>
														<Info color="black54">
															{translate(
																'product.cart-card.with-vat',
																vatPercentHighest ?? 0
															)}
														</Info>
														<InfoBold color="black54">{`${translate('omr')} ${roundMoney(
															periodPrice
														)}`}</InfoBold>
													</VatWrapper>
												)}
												onFalse={() => (
													<VatWrapper>
														<Info color="primary">
															{translate('product.cart-card.after-discount')}
														</Info>
														<InfoBold color="primary">{`${translate('omr')} ${roundMoney(
															ebuItem?.IV
														)}`}</InfoBold>
													</VatWrapper>
												)}
											/>
										</PriceAccordion>
									</PriceBlockContainer>
								</>
							)}
						/>
					</>
				)}
			/>
		</OrderCardContainer>
	);
};

export default OrderCard;
