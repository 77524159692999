import { Subheading } from '@Components/Typography/Typography';
import styled from 'styled-components';

export const CategoryCardContainer = styled.div`
	padding: 12px 8px;
`;

export const CategoryTextWrapper = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	margin: 0px 8px;
`;

export const FilterSubheadingWrapper = styled(Subheading)`
	margin: 8px 0px;
	${({ theme }) => ({
		color: theme.colors.black54,
	})}
	text-transform: uppercase;
	user-select: none;
`;

export const IconWrapper = styled.div<types.IStyledProps & { focused: boolean }>`
	transition: all 0.1s ease-in-out;
	${({ focused }) => (focused ? `transform: rotate(180deg);` : null)}
	cursor: pointer;
`;

export const CategoryWrapper = styled.button`
	display: flex;
	align-items: center;
	padding: 8px;
	border: none;
	background-color: transparent;
	cursor: pointer;
`;

export const ShowMoreButtonRow = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	align-items: center;
`;

export const RangeRow = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	padding: 16px 8px;
`;

export const RangeSeparator = styled.div`
	width: 16px;
	height: 1.5px;
	${({ theme }) => ({
		background: theme.colors.black87,
	})}
`;

export const RangeInput = styled.div`
	width: 96px !important;
`;

export const RangeButtonsRow = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	margin-right: 8px;
	margin-left: -8px;
`;
