export interface IHbbWfbbOrderResponse {
	requestId: string;
	internalRequestId: string;
}

const model: types.models.IInputModel = {
	requestId: 'response.response.requestId',
	internalRequestId: 'response.response.internalRequestId',
};

export default model;
