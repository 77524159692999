export interface IValidateResourcePayload {
	customerCode?: string | null;
	customerType?: string | null;
	requestType: string;
	serviceOrderType: string;
}

export interface IResourceDetails {
	response: string;
}

export interface IValidateResource {
	_resultEntry: IResourceDetails;
	response: IResourceDetails;
}

const model: types.models.IInputModel = {
	response: 'response',
};

export default model;
