import { Observable } from 'rxjs';
import api from '@Utils/api';
import { ILoyaltyAccount } from '@ApiModels/makasibLoyaltyAccount';

interface IResponse {
	bonusAmount: number;
	reservationId: string;
	voucherExpirationDate: string;
}
export interface IVoucherReservation {
	voucherReservationResponse: IResponse;
}

export class MakasibService {
	public static getLoyaltyAccountOperation(phoneNumber: string): Observable<ILoyaltyAccount> {
		return api.omantelApi.getMakasibLoyaltyAccountOperation(phoneNumber).call();
	}
	public static manageMakasibVoucherReservation(
		voucherCode: string,
		action: 'GW' | 'U' | 'G'
	): Observable<IVoucherReservation> {
		return api.omantelApi.manageMakasibVoucherReservation(voucherCode, action).call();
	}
}
