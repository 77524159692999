import { ActionFunction0, ActionFunction1, createAction } from 'redux-actions';

interface ICacheApiRequestAction extends types.redux.api.cache.ICacheRequestAction {}
interface IInvalidateCacheAction {
	source: string;
	url: string;
	payloadHash?: number;
}

export const cacheApiRequest: ActionFunction1<any, any> = createAction(
	'API_CACHE_REQUEST',
	(action: ICacheApiRequestAction) => action
);
export const invalidateCache: ActionFunction1<any, any> = createAction(
	'API_INVALIDATE_CACHE',
	(action: IInvalidateCacheAction) => action
);
export const invalidateAllCache: ActionFunction0<any> = createAction('API_INVALIDATE_ALL_CACHE');
