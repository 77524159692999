import React, { FunctionComponent, useState } from 'react';
import useTranslate from '@Utils/hooks/useTranslate';
import { Col, Hidden, Row } from 'react-grid-system';
import Icon from '@Components/Icon/Icon';
import BusyIndicator from '@Components/BusyIndicator/BusyIndicator';
import { useDispatch, useSelector } from 'react-redux';
import {
	AddNewAddressButtonWrapper,
	AddressBookElement,
	AddressBookEmpty,
	CardWrapper,
	ChevronWrapper,
	Horizontal,
	IconWrapper,
	TextAlign,
	ToggleText,
	ToggleWrapper,
} from './AddressBook.styled';
import Divider from '@Components/Divider/Divider';
import { BodySBold, Caption, ValueBold } from '@Components/Typography/Typography.styled';
import ConditionalRender from '@Components/ConditionalRender/ConditionalRender';
import Toggle from '@Components/controls/Toggle/Toggle';
import { setActiveAddressBook } from '@Redux/modules/addressBook/actions';
import { PrimaryLargeButtonFlex, TertiaryMediumButtonAuto } from '@Components/controls/Button/Button';
import AddressBookDetails from './AddressBookDetails';
import useAddressBook, { GET_ADDRESS_BOOK_BI } from '@Utils/hooks/useAddressBook';

const ICON_SIZE = 40;

const AddressBook: FunctionComponent<types.cards.IAddressBookProps> = ({}): JSX.Element => {
	const { translate } = useTranslate();
	const dispatch = useDispatch();
	const { addressBook, addNewAddress, getTitle, removeAddress, editAddress } = useAddressBook();
	const [openedAddressBook, setOpenedAddressBook] = useState<string>('');
	const activeAddressBook = useSelector((state: types.redux.IState) => state.addressBook.activeAddressBook);

	const toogleActive = (tokenId: string): boolean => {
		if (activeAddressBook === tokenId) {
			return true;
		}
		return false;
	};

	const setToggleState = (addressId: string, state: boolean) => {
		dispatch(
			setActiveAddressBook({
				activeAddressBook: state === true ? addressId : '',
			})
		);
		return state;
	};

	const openAddressBook = (id: string) => {
		if (openedAddressBook === id) {
			setOpenedAddressBook('');
		} else {
			setOpenedAddressBook(id);
		}
	};

	return (
		<CardWrapper>
			<Row>
				<Hidden xs sm md>
					<Col xs={1}>
						<Icon name="addressBook" width={ICON_SIZE} height={ICON_SIZE} />
					</Col>
				</Hidden>
				<Col>
					<Divider color="transparent" marginBottom={8} />
					<ValueBold color="black87">{translate('account.management.settings.address.book')}</ValueBold>
					<Divider color="transparent" marginBottom={44} />
					<BusyIndicator listener={GET_ADDRESS_BOOK_BI} skeleton="orderTracking">
						<>
							<ConditionalRender
								show={addressBook.length === 0}
								onTrue={() => (
									<AddressBookEmpty>
										<BodySBold color="black38">
											{translate('account.management.settings.address.book.empty')}
										</BodySBold>
										<Divider withoutLine marginBottom={32} />
										<Icon name="address_book_empty_state" width={305} height={305} />
										<Divider withoutLine marginBottom={32} />
										<AddNewAddressButtonWrapper>
											<PrimaryLargeButtonFlex
												uniqueId="add.new.address"
												onClick={() => addNewAddress()}
											>
												{translate('account.management.settings.address.book.add')}
											</PrimaryLargeButtonFlex>
										</AddNewAddressButtonWrapper>
									</AddressBookEmpty>
								)}
							/>
							<ConditionalRender
								show={addressBook.length > 0}
								onTrue={() => (
									<>
										{addressBook
											.filter((a) => a.nickName !== 'ShippingAndBilling')
											.map((address, index) => (
												<AddressBookElement key={index}>
													<Horizontal>
														<IconWrapper width={32}>
															<Icon
																name="location"
																fill="black54"
																width={32}
																height={32}
															/>
														</IconWrapper>
														<TextAlign>
															<ValueBold color="black87">{getTitle(address)}</ValueBold>
														</TextAlign>
														{addressBook.length > 1 && (
															<ToggleWrapper>
																<ToggleText>
																	<Caption color="black54">
																		{toogleActive(address.addressId ?? '')
																			? translate(
																					'account.management.settings.payment.method.default'
																			  )
																			: translate(
																					'account.management.settings.payment.method.set.as.default'
																			  )}
																	</Caption>
																</ToggleText>
																<Toggle
																	active={toogleActive(address.addressId ?? '')}
																	onChange={(event) =>
																		setToggleState(
																			address.addressId ?? '',
																			(event.target as HTMLInputElement).checked
																		)
																	}
																/>
															</ToggleWrapper>
														)}
														<ChevronWrapper>
															<Icon
																onClick={() => openAddressBook(address.addressId ?? '')}
																name={
																	openedAddressBook === address.addressId
																		? 'chevronUp'
																		: 'chevronDown'
																}
																fill="black87"
																width={24}
																height={24}
															/>
														</ChevronWrapper>
													</Horizontal>
													<ConditionalRender
														show={openedAddressBook === address.addressId}
														onTrue={() => (
															<>
																<Divider withoutLine marginTop={24} />
																<AddressBookDetails
																	address={address}
																	deleteBtn={true}
																	editBtn={true}
																	removeAddress={(nickName) => {
																		removeAddress(nickName ?? '');
																	}}
																	editAddress={() => {
																		editAddress(address);
																	}}
																/>
															</>
														)}
													/>
												</AddressBookElement>
											))}
										<Divider color="black12" marginTop={24} marginBottom={7} />
										<TertiaryMediumButtonAuto
											uniqueId="add.new.address.book"
											onClick={addNewAddress}
										>
											{translate('account.management.settings.address.book.add')}
										</TertiaryMediumButtonAuto>
									</>
								)}
							/>
						</>
					</BusyIndicator>
				</Col>
			</Row>
		</CardWrapper>
	);
};

export default AddressBook;
