import React, { FunctionComponent } from 'react';
import {
	TenatDataWrapper,
	SubTitle,
	TenatVerificationSuccessWrapper,
	ButtonWrapper,
	TenatData,
	Label,
	MainTitle,
} from './TenatVerificationSuccess.styled';
import { PrimaryLargeButtonFlex } from '@Components/controls/Button/Button';
import useTranslate from '@Utils/hooks/useTranslate';
import { useHistory } from 'react-router-dom';
import { getLang } from '@Utils/language/language';
import Icon from '@Components/Icon/Icon';
import useScreen from '@Utils/hooks/useScreen';

interface ITenatVerificationSuccessProps {
	params: {
		tenantName: string;
		tenantId: string;
	};
}

const SUCCESS_ICON_SIZE = 48;

const TenatVerificationSuccess: FunctionComponent<ITenatVerificationSuccessProps> = ({
	params: { tenantName, tenantId },
}) => {
	const { translate } = useTranslate();
	const history = useHistory();

	const { screen } = useScreen();

	return (
		<TenatVerificationSuccessWrapper
			sizes={{ isL: screen('lg'), isMd: screen('md'), isSm: screen('sm'), isXs: screen('xs') }}
		>
			<Icon height={SUCCESS_ICON_SIZE} width={SUCCESS_ICON_SIZE} name="success" />
			<MainTitle color="positive">
				{translate('microsoft-automation-proccess.tenant.verification.success.info')}
			</MainTitle>
			<TenatDataWrapper>
				<div>
					<Label>{translate('microsoft-automation-proccess.tenant.verification.success.tenant-name')}</Label>
					<Label>{translate('microsoft-automation-proccess.tenant.verification.success.tenant-id')}</Label>
					<Label>{translate('microsoft-automation-proccess.tenant.verification.success.provider')}</Label>
				</div>
				<div>
					<TenatData>{tenantName}</TenatData>
					<TenatData>{tenantId}</TenatData>
					<TenatData>{translate('omantel')}</TenatData>
				</div>
			</TenatDataWrapper>
			<SubTitle>{translate('microsoft-automation-proccess.tenant.verification.success.go-to-checkout')}</SubTitle>
			<ButtonWrapper>
				<PrimaryLargeButtonFlex
					uniqueId="tenant-verification-success.go-to-checkout"
					onClick={() => {
						history.push(`/${getLang()}/checkout/1`);
					}}
					textAlign="center"
				>
					{translate('microsoft-automation-proccess.account-creation-process.success.go-to-checkout')}
				</PrimaryLargeButtonFlex>
			</ButtonWrapper>
		</TenatVerificationSuccessWrapper>
	);
};

export default TenatVerificationSuccess;
