import styled from 'styled-components';

interface IAppWrapper {
	direction: types.redux.settings.layout;
	marginTop: number;
}

export const AppWrapper = styled.div<IAppWrapper>`
	${({ direction, marginTop }) => ({
		direction,
		marginTop,
	})}
`;
