import styled from 'styled-components';
import { CSSObject } from 'styled-components';
import { matchScreen } from '@Utils/layout/layoutSupport';

interface ISliderProps {
	width: number;
	center: boolean;
	previewMode: boolean;
}
interface IImagePreview {
	selectable: boolean;
	previewMode: boolean;
}
interface IWithPreview {
	previewMode: boolean;
	smallImage?: boolean;
}
interface IIconWrapper {
	paddingRight?: number;
}
interface IPlaceholder {
	size: number;
}
interface IContentContainer {
	previewMode: boolean;
}

const MAX_IMAGE_HEIGHT = 450;

export const SliderWrapper = styled.div`
	display: inline-flex;
	align-items: center;
	justify-content: center;
	flex-direction: row;
	width: 100%;
`;

export const IconWrapper = styled.div<IIconWrapper>`
	${({ paddingRight }) => {
		return {
			paddingRight: paddingRight ?? 0,
		};
	}}
	display: inline-block
	transition: all 0.1s ease-in-out;
	cursor: pointer;
`;

export const PreviewImageWrapper = styled.div<IWithPreview>`
	${({ previewMode, smallImage }) => {
		const inPreviewMode = previewMode
			? {
					display: 'flex',
					alignItems: 'flex-end',
					justifyContent: 'center',
					height: '450px',
					marginBottom: 32,
			  }
			: { marginBottom: 16, height: smallImage ? '265px' : '320px' };
		return {
			...inPreviewMode,
		};
	}}
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
`;

export const Slider = styled.div<ISliderProps>`
	${({ width, center, previewMode }) => {
		const shadowContainer = previewMode
			? {
					height: '118%',
					paddingLeft: 16,
					paddingBottom: 20,
			  }
			: {};
		const centerItem = center
			? {
					display: 'flex',
					justifyContent: 'center',
			  }
			: {};
		return { width, ...centerItem, ...shadowContainer };
	}}

	overflow-x: scroll;
	::-webkit-scrollbar {
		display: none;
	}
	-ms-overflow-style: none;
	scrollbar-width: none;
`;

export const SliderItemsWrapper = styled.div`
	display: flex;
	float: left;
`;

export const ImagePreview = styled.img<IImagePreview>`
	${({ selectable, previewMode }) => {
		const inPreviewMode = previewMode
			? ({ maxHeight: MAX_IMAGE_HEIGHT, maxWidth: '100%', objectFit: 'contain' } as CSSObject)
			: {};
		return { cursor: selectable ? 'pointer' : 'default', maxWidth: '100%', ...inPreviewMode };
	}}
`;

export const BigImageWrapper = styled.div<IWithPreview>`
	display: flex;
	width: 100%;
	${({ previewMode }) => {
		return {
			justifyContent: previewMode ? 'space-between' : 'center',
			alignItems: previewMode ? 'center' : 'normal',
			paddingLeft: 0,
		};
	}}
`;

export const Placeholder = styled.div<IPlaceholder>`
	${({ size }) => {
		return {
			paddingRight: size,
		};
	}}
`;

export const SliderContainer = styled.div`
	${() => {
		return {
			marginLeft: matchScreen('sm') ? -30 : 0,
			marginRight: matchScreen('sm') ? -30 : 0,
		};
	}}
`;

export const ContentContainer = styled.div<IContentContainer>`
	${({ previewMode }) => {
		return {
			backgroundColor: !previewMode && matchScreen(['xxl', 'xl', 'lg', 'md']) ? 'white' : 'inherit',
		};
	}};
	padding-top: 24px;
	padding-bottom: 16px;
	border-radius: 8px;
	width: 100%;
	max-width: 1100px;
	direction: ltr;
`;

export const LoaderContainer = styled.div`
	position: absolute;
	left: 50%;
	top: 50%;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
`;
