import Icon from '@Components/Icon/Icon';
import useTranslate from '@Utils/hooks/useTranslate';
import React, { FunctionComponent } from 'react';
import { GeneralErrorContainer, MainTextWrapper, TextWrapper } from './GeneralError.styled';

const GeneralError: FunctionComponent = () => {
	const { translate } = useTranslate();
	return (
		<GeneralErrorContainer>
			<Icon name="emptyState" width={311} height={305} />
			<MainTextWrapper>{translate('general-error.title')}</MainTextWrapper>
			<TextWrapper>{translate('general-error.subtitle')}</TextWrapper>
		</GeneralErrorContainer>
	);
};

export default GeneralError;
