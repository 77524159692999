import ConditionalRender from '@Components/ConditionalRender/ConditionalRender';
import { PlanBarType } from '@Components/PlanBar/PlanBarType';
import useScreen from '@Utils/hooks/useScreen';
import React, { FunctionComponent } from 'react';
import Plan from './Plan/Plan';
import { MenuPanel, PlanComparisonContainer, Plans } from './PlanComparison.styled';
import PlanMenuItem from './PlanMenuItem/PlanMenuItem';

export interface IMigrationPlanComparison {
	id: string;
	type: PlanBarType;
	name: string;
	contractCommitment: string;
	isCurrentPlan?: boolean;
	isRecommendedPlan?: boolean;
	monthlyPayment?: number | string;
	localAndGccData?: number | string;
	localMinutes?: number | string;
	internationalMinutes?: number | string;
	roamingRecievingMinutes?: number | string;
	worldRoamingData?: number | string;
	dataSharing?: number | string;
	storeCredit?: number | string;
	keepThisPlan?: boolean;
	selectPlan?: boolean;
	dataIncluded?: number | string;
	downloadSpeed?: number | string;
	downloadSpeedUnit?: string;
	uploadSpeed?: number | string;
	freeMinutesToOmantel?: number | string;
	intlCallsDiscount?: number | string;
	planVatPercent?: number | string;
	planVatValue?: number | string;
	partNumber: string;
	duration: number;
}

export interface IPlanComparisonProps {
	plans: IMigrationPlanComparison[];
}

const PlanComparison: FunctionComponent<IPlanComparisonProps> = ({ plans }) => {
	const { screen } = useScreen();
	const isMDLower = screen(['xs', 'sm', 'md']);
	const filteredPlans = plans.filter((plan) => plan.type !== PlanBarType.BAQATI_AL_UFUQ);

	return (
		<PlanComparisonContainer>
			<ConditionalRender
				show={isMDLower}
				onTrue={() => (
					<Plans>
						{filteredPlans.map((plan, index) => (
							<Plan key={index} plan={plan} max={filteredPlans.length} index={index} />
						))}
					</Plans>
				)}
				onFalse={() => (
					<>
						<MenuPanel menuMargin={96}>
							<ConditionalRender
								show={!isMDLower}
								onTrue={() => (
									<>
										<PlanMenuItem title="plan-comparison.monthly.payment" />
										<PlanMenuItem title="plan-comparison.local.gcc.data" />
										<PlanMenuItem title="plan-comparison.local.minutes" />
										<PlanMenuItem title="plan-comparison.international.minutes" />
										<PlanMenuItem title="plan-comparison.roaming.recieving.minutes" />
										<PlanMenuItem title="plan-comparison.world.roaming.data" />
										<PlanMenuItem title="plan-comparison.data.sharing" isLast />
									</>
								)}
							/>
						</MenuPanel>
						<Plans>
							{filteredPlans.map((plan, index) => (
								<Plan key={index} plan={plan} max={filteredPlans.length} index={index} />
							))}
						</Plans>
					</>
				)}
			/>
		</PlanComparisonContainer>
	);
};

export default PlanComparison;
