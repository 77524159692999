import styled from 'styled-components';
import {
	BodyMBold,
	Caption,
	CaptionBold,
	Subheading,
	Info,
	CaptionHigher,
	MiniDurationBold,
	CaptionBigger,
} from '@Components/Typography/Typography';
import { isRTL, matchScreen, marginLeft, marginRight } from '@Utils/layout/layoutSupport';

interface IOutOfStockDescription {
	paddingTop?: number;
}
interface ICostWrapper {
	fullWidth?: boolean;
	withoutPadding?: boolean;
}

interface ISummary {
	isCorporate?: boolean;
	isPreorder?: boolean;
}

export const SelectedItemsWrapper = styled.div``;

export const HeaderWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	padding-bottom: 8px;
`;

export const HeaderText = styled(Subheading)`
	${({ theme }) => ({
		color: theme.colors.black87,
	})}
	text-transform: uppercase;
	margin-bottom: 16px;
`;

export const ItemWrapper = styled.div`
	display: flex;
	padding: 8px 0;
`;

export const ItemImage = styled.div<{ src: string }>`
	width: 37.5px;
	height: 37.5px;
	${({ src }) =>
		src && {
			background: `url(${src})`,
			backgroundSize: 'contain',
			backgroundRepeat: 'no-repeat',
			backgroundPosition: 'center center',
		}}
`;

export const ItemDescriptionWrapper = styled.div`
	padding: 0 8px;
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	width: calc(100% - 46px);
`;

export const DeviceDescriptionWrapper = styled.div`
	padding: 0 8px;
	flex: 1;
`;

export const ItemTitle = styled(CaptionBold)``;

export const ItemDescription = styled(Caption)`
	padding-top: 4px;
	${({ theme }) => ({
		color: theme.colors.black54,
	})}
`;

export const SummaryWrapper = styled.div<ISummary>`
	padding-top: ${({ isCorporate = false, isPreorder }) => (isCorporate ? 10 : isPreorder ? 8 : 0)}px;
`;

export const CostWrapper = styled.div<ICostWrapper>`
	${({ fullWidth, withoutPadding }) => ({
		width: fullWidth ? '100%' : 'auto',
		paddingBottom: withoutPadding ? 0 : '12px',
	})}
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

export const CostTitle = styled(BodyMBold)`
	${({ theme }) => ({
		color: theme.colors.black87,
	})}
`;

export const SmallOMRWrapper = styled(MiniDurationBold)`
	${() => marginRight(2)}
`;

export const ButtonWrapper = styled.div`
	margin-top: 20px;
	padding-top: 4px;
	padding-bottom: 16px;
`;

export const SecureTransactionWrapper = styled.div<{ isBottom?: boolean }>`
	display: flex;
	align-items: center;
	justify-content: center;
	${({ isBottom }) => ({
		marginTop: isBottom ? '16px' : '0px',
	})}
`;

export const SecureTransactionText = styled(CaptionBigger)`
	${() =>
		isRTL()
			? {
					marginRight: '8px',
			  }
			: {
					marginLeft: '8px',
			  }}
`;

export const HighlightedText = styled.span`
	${({ theme }) => ({
		color: theme.colors.black54,
	})}
`;

export const OutOfStockWrapper = styled.div`
	display: flex;
	flex-direction: column;
	padding-top: 8px;
`;

export const PriceAccordion = styled.div<{ isBnpl?: boolean }>`
	display: flex;
	flex-direction: column;
	${(isBnpl) => ({
		cursor: isBnpl ? 'unset' : 'pointer',
	})}
`;

export const BottomColumn = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	${() => ({
		marginLeft: matchScreen('xs') ? 0 : '23px',
	})}
`;

export const PriceBlockContainer = styled.div<{ isBottom?: boolean; isFirst?: boolean; salesLead?: boolean }>`
	display: flex;
	flex-direction: column;
	${({ isBottom, isFirst, salesLead }) => ({
		marginTop: isFirst ? '16px' : isBottom ? '8px' : salesLead ? '0px' : '24px',
	})}
`;

export const PriceIconWrapper = styled.div`
	display: flex;
	flex-direction: row;
`;

export const VatWrapper = styled.div`
	align-self: flex-end;
	justify-self: flex-end;
	display: flex;
	flex-direction: row;
	gap: 2px;
`;

export const PriceTagWrapper = styled.div`
	display: flex;
	flex-direction: row;
`;

export const ArrowIconContainer = styled.div<{ isShowFull: boolean }>`
	${({ isShowFull }) => ({
		transform: isRTL() ? (isShowFull ? 'rotate(0)' : 'rotate(90deg)') : isShowFull ? 'rotate(0)' : 'rotate(-90deg)',
	})}
`;

export const PriceHeaderWrapper = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
`;

export const PriceContentWrapper = styled.div<{ isShown?: boolean }>`
	display: flex;
	flex-direction: column;
	${() => marginLeft(20)}
	${({ isShown }) => ({
		marginBottom: isShown ? '12px' : '0',
	})}
`;

export const VerticalDivider = styled.div`
${() => marginRight(8)}
	width: 1px;
	border-radius: 35px;
	background-color: ${({ theme }) => theme.colors.black87}};
	`;

export const PriceContentRowWrapper = styled.div<{ isBottomMode?: boolean }>`
	display: flex;
	flex-direction: row;
	${({ isBottomMode }) => ({
		marginTop: isBottomMode ? '8px' : '16px',
	})}
`;

export const TooltipIconWrapper = styled.div`
	margin-top: -2px;
`;

export const VatRow = styled.div`
	display: flex;
	flex-direction: row;
	gap: 4px;
`;

export const PriceDetailsWrapper = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	justify-conent: center;
	gap: 4px;
`;

export const DeviceWithPlanWrapper = styled(CaptionHigher)`
text-align: center;
display: flex;
flex-direction: column;
align-items: center;
	p {
		display: inline;
		margin 0 2px;
	}
`;

export const OutOfStockDescription = styled(CaptionBold)<IOutOfStockDescription>`
	text-align: center;
	${({ theme, paddingTop = 16 }) => ({
		color: theme.colors.black87,
		paddingTop,
	})}
`;

export const OutOfStockItemTitle = styled(Caption)`
	padding-top: 4px;
	text-align: center;
	${({ theme }) => ({
		color: theme.colors.black54,
	})}
`;

export const BottomSectionCartWrapper = styled(Caption)`
	${() => ({
		display: 'flex',
		justifyContent: 'center',
		paddingBottom: matchScreen('xs') ? 100 : 64,
		marginLeft: matchScreen('xs') ? -16 : 0,
		marginRight: matchScreen('xs') ? -16 : 0,
	})}
`;

export const BottomSectionPricingWithButton = styled.div`
	${() => ({
		width: 'calc(100% - 32px)',
		flexDirection: matchScreen('xs') ? 'column' : 'row',
		paddingLeft: 16,
		paddingRight: 16,
	})}
	background-color: white;
	position: absolute;
	bottom: -1px;
	display: flex;
	justify-content: center;
	padding-bottom: 16px;
`;

export const BottomSectionPricingWrapper = styled.div`
	${() => ({
		paddingBottom: matchScreen('xs') ? 16 : 0,
	})}
	width: 100%;
	padding-right: 16px;
	display: flex;
	align-items: center;
	min-height: 56px;
`;

export const BottomSectionButtonWrapper = styled.div`
	${() => ({
		paddingLeft: matchScreen('xs') ? 0 : 30,
	})}
	${() => ({
		...marginRight(matchScreen('xs') ? 0 : 23),
	})}
	margin-top: 16px;
	width: 100%;
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
`;

export const ButtonContent = styled.div`
	width: 100%;
`;

export const PriceWrapper = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
`;

export const PlanTitleWrapper = styled.div`
	width: 100%;
	display: flex;
`;

export const TitleWrapper = styled.div<{ isDifferentPlan?: boolean }>`
	max-width: ${({ isDifferentPlan = false }) => (isDifferentPlan ? 45 : 100)}%;
`;

export const IconWrapper = styled.div`
	width: 10%;
	margin-top: -1px;
`;

export const PriceContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-end;
`;

export const VatInfo = styled(Info)`
	${({ theme }) => ({ color: theme.colors.black54 })}
`;

export const SelectNumberTitle = styled(Caption)`
	text-align: center;
	width: 80%;
`;

export const SelectNumberWarning = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 16px;
	flex: 1 0 0;
`;

export const PlanEditWarning = styled.div`
	width: 100%;
	align-self: stretch;
	flex-grow: 0;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	gap: 8px;
	padding: 16px 16px 0;
`;

export const PlanEditWarningTitle = styled(CaptionBold)`
	${({ theme }) => ({
		color: theme.colors.black87,
	})}
	text-align:center;
`;

export const PlanEditWarningDescriptionUnderline = styled.span`
	text-decoration: underline;
	cursor: pointer;
	${({ theme }) => ({
		color: theme.colors.primary,
	})}
`;

export const WarningSubtitle = styled(Caption)`
	${({ theme }) => ({
		color: theme.colors.black54,
	})}
	text-align:center;
`;

export const DeviceAndPlanWrapper = styled.div`
	width: 100%;
`;

export const StrikethroughWrapper = styled.div`
	font-weight: bold;
	text-decoration: line-through;
	display: inline;
`;
