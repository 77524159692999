export interface IValidateTelesalesSmsPassword {
	resourceName: string;
	validate: string;
}

const model: types.models.IInputModel = {
	resourceName: 'response.resourceName',
	validate: 'response.validate',
};

export default model;
