import { BodyM } from '@Components/Typography/Typography.styled';
import styled from 'styled-components';

export const PaymentInfoCardContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 32px 128px;
	gap: 16px;
	border-radius: 8px;
	text-align: center;
	${({ theme }) => ({
		backgroundColor: theme.colors.white,
	})}
`;

export const KnowMoreButtonWrapper = styled(BodyM)`
	cursor: pointer;
	${({ theme }) => ({
		color: theme.colors.primary,
	})}
`;

export const TitleRow = styled.div`
	flex-direction: row;
	display: flex;
	justify-content: center;
	align-items: flex-start;
	gap: 10px;
	max-width: 474px;
`;
