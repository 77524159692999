import { PlanBarType } from '@Components/PlanBar/PlanBarType';
import { isRTL, matchScreen } from '@Utils/layout/layoutSupport';
import styled from 'styled-components';

interface IPlanComparisonProps {
	padding?: number;
	planType?: PlanBarType;
	menuMargin?: number;
	dataFill?: number;
	hbbWfbbProcess?: boolean;
	currentPlanIsPrepaid?: boolean;
}

export const PlanComparisonContainer = styled.div`
	display: flex;
`;

const getMegaMenuWidth = ({ currentPlanIsPrepaid, hbbWfbbProcess }: IPlanComparisonProps) => {
	if (hbbWfbbProcess) {
		return '188px';
	} else if (currentPlanIsPrepaid) {
		return 300;
	} else {
		return 'inherit';
	}
};

export const MenuPanel = styled.div<IPlanComparisonProps>`
	height: fit-content;
	${({ menuMargin, currentPlanIsPrepaid, hbbWfbbProcess }) => ({
		marginTop: menuMargin,
		width: getMegaMenuWidth({ menuMargin, currentPlanIsPrepaid, hbbWfbbProcess }),
		minWidth: hbbWfbbProcess ? getMegaMenuWidth({ menuMargin, currentPlanIsPrepaid, hbbWfbbProcess }) : 'inherit',
	})}
	${({ theme }) =>
		isRTL()
			? {
					borderTopRightRadius: '8px',
					borderBottomRightRadius: '8px',
					borderRight: `1px solid ${theme.colors.black12}`,
			  }
			: {
					borderTopLeftRadius: '8px',
					borderBottomLeftRadius: '8px',
					borderLeft: `1px solid ${theme.colors.black12}`,
			  }}
   	max-width: 219px;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: stretch;
	gap: 0;
	${({ theme, hbbWfbbProcess }) => ({
		maxWidth: hbbWfbbProcess ? 220 : 219,
		padding: matchScreen(['xs']) ? 'unset' : '0 16px',
		backgroundColor: theme.colors.grey100,
		borderTop: `1px solid ${theme.colors.black12}`,
		borderBottom: `1px solid ${theme.colors.black12}`,
	})}
`;

export const StoreCredit = styled.div<IPlanComparisonProps>`
	text-align: center;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: stretch;
`;

export const StoreCreditText = styled.div<IPlanComparisonProps>`
	width: 187px;
	margin: auto;
	padding-top: 16px;
`;

export const StoreCreditIcons = styled.div<IPlanComparisonProps>`
	padding: 16px 0;
	gap: 16px;
	display: flex;
	margin: auto;
	text-align: center;
`;

export const StoreCreditIcon = styled.div<IPlanComparisonProps>`
	width: 24px;
	height: 24px;
	img {
		width: 24px;
		height: 24px;
	}
`;

export const Plans = styled.div<{ hbbWfbbProcess?: boolean }>`
	border-top-left-radius: 8px;
	border-top-right-radius: 8px;
	overflow: hidden;
	display: flex;
	width: 100%;
	${({ hbbWfbbProcess }) => ({
		overflowX: hbbWfbbProcess ? 'scroll' : 'unset',
	})}
`;
