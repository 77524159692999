import React, { FunctionComponent, useEffect } from 'react';
import RadioButton from '@Components/controls/RadioButton/RadioButton';
import Divider from '@Components/Divider/Divider';
import DragAndDrop, { IFileParams } from '@Components/DragAndDrop/DragAndDrop';
import Icon from '@Components/Icon/Icon';
import { BodySBold, MiniDescription, ValueBold } from '@Components/Typography/Typography.styled';
import { setPersistentCheckoutDetails } from '@Redux/modules/persistentCheckoutData/actions';
import { dispatch } from '@Redux/store';
import useScreen from '@Utils/hooks/useScreen';
import useTranslate from '@Utils/hooks/useTranslate';
import documentDetailsValidator from '@Utils/validators/documentDetailsValidator';
import { Formik } from 'formik';
import moment from 'moment';
import { Col, Hidden, Row, Visible } from 'react-grid-system';
import { useSelector } from 'react-redux';
import {
	ContactDetailsWrapper,
	ErrorMessage,
	FormWrapper,
	HeaderWrapper,
	IconContainer,
	IconWrapper,
	ResidentIconWrapper,
	Subtitle,
} from './DocumentDetails.styled';
import TextField from '@Components/controls/TextField/TextField';
import DatePickerField from '@Components/controls/DatePicker/DatePickerField';

export enum DocumentState {
	NATIONAL = '101',
	RESIDENCE = '104',
}
export interface IDocumentDetailsFormikFields {
	customerIdType: DocumentState;
	customerId: string;
	expiryDate: string;
	documentScan: { base64frontSide: IFileParams | null; base64backSide: IFileParams | null };
}

export const UPDATE_PROFILE = 'updateContactDetails';

const DocumentDetails: FunctionComponent<types.cards.IDocumentDetailsProps> = ({ params: { documentDetailsRef } }) => {
	const { translate } = useTranslate();
	const { screen } = useScreen();

	const persistentCheckout = useSelector((state: types.redux.IState) => state.persistentCheckout);

	useEffect(() => {
		if (documentDetailsRef?.current && persistentCheckout.hbbWfbbProcessCheckout) {
			const { setFieldValue } = documentDetailsRef.current;
			const { customerIdType, expiryDate, documentScan, customerId } = persistentCheckout.hbbWfbbProcessCheckout;
			customerIdType && setFieldValue('customerIdType', customerIdType);
			expiryDate && setFieldValue('expiryDate', moment(expiryDate, 'YYYY-MM-DD').format('DD/MM/YYYY'));
			customerId && setFieldValue('customerId', customerId);
			setFieldValue('documentScan', {
				base64frontSide: documentScan?.base64frontSide || null,
				base64backSide: documentScan?.base64backSide || null,
			});
		}
	}, [persistentCheckout]);

	const onSaveDetails = (values: IDocumentDetailsFormikFields) => {
		dispatch(
			setPersistentCheckoutDetails({
				hbbWfbbProcessCheckout: {
					expiryDate: moment(values.expiryDate, 'DD/MM/YYYY').format('YYYY-MM-DD'),
					customerId: values.customerId,
					customerIdType: values.customerIdType,
					customerType: 'CONSUMER',
					documentScan: {
						base64frontSide: values.documentScan.base64frontSide,
						base64backSide: values.documentScan.base64backSide,
					},
				},
			})
		);
	};

	return (
		<ContactDetailsWrapper>
			<Row>
				<Hidden xs>
					<IconWrapper>
						<Icon name="personalInfo" width={40} height={40} />
					</IconWrapper>
				</Hidden>
				<Col>
					<HeaderWrapper>
						<Visible xs>
							<IconWrapper>
								<Icon name="personalInfo" width={40} height={40} />
							</IconWrapper>
						</Visible>
						<div>
							<ValueBold>{translate('document-details.title')}</ValueBold>
							<Divider marginBottom={8} withoutLine />
							<Subtitle>{translate('document-details.provide.details')}</Subtitle>
							<Divider marginBottom={32} withoutLine />
							<BodySBold color="black87">{translate('document-details.1.point')}</BodySBold>
						</div>
					</HeaderWrapper>
					<Formik<IDocumentDetailsFormikFields>
						validationSchema={documentDetailsValidator()}
						validateOnBlur={true}
						initialValues={{
							customerIdType: DocumentState.NATIONAL,
							customerId: '',
							expiryDate: '',
							documentScan: { base64frontSide: null, base64backSide: null },
						}}
						innerRef={documentDetailsRef}
						onSubmit={(values) => {
							onSaveDetails(values);
						}}
					>
						{({ setFieldValue, values, errors, touched, setFieldTouched, handleChange }) => {
							return (
								<FormWrapper>
									<Divider marginBottom={16} withoutLine />
									<RadioButton
										fontColor="black87"
										onClick={(value) => {
											setFieldTouched('customerIdType', true);
											setFieldValue('customerIdType', value);
										}}
										value={DocumentState.NATIONAL}
										selected={values.customerIdType}
										label={translate(`document.details.radio.button.${DocumentState.NATIONAL}`)}
									/>

									<Divider marginBottom={16} withoutLine />
									<RadioButton
										fontColor="black87"
										onClick={(value) => {
											setFieldTouched('customerIdType', true);
											setFieldValue('customerIdType', value);
										}}
										value={DocumentState.RESIDENCE}
										selected={values.customerIdType}
										label={translate(`document.details.radio.button.${DocumentState.RESIDENCE}`)}
									/>

									<Divider marginBottom={16} withoutLine />
									<MiniDescription color="black54">
										{translate('document-details.description.1.point')}
									</MiniDescription>

									<Divider marginBottom={16} withoutLine />

									<BodySBold color="black87">
										{translate('document-details.provide-document-id')}
									</BodySBold>

									<Divider marginBottom={16} withoutLine />
									<TextField
										width={440}
										id="customerId"
										label={translate('document.data.form.document.id')}
										placeholder={translate('document.data.form.document.id')}
										value={values.customerId}
										onChange={(value) => {
											setFieldTouched('customerId', true);
											handleChange('customerId');
											setFieldValue('customerId', value.toString());
										}}
										message={!!errors.customerId && touched.customerId ? errors.customerId : ''}
										error={!!errors.customerId && touched.customerId}
										marginBottom={8}
									/>

									<Divider marginBottom={16} withoutLine />
									<BodySBold color="black87">{translate('document-details.2.point')}</BodySBold>

									<Divider marginBottom={16} withoutLine />

									<DatePickerField
										id="date-picker-document-details"
										name="date-picker-document-details"
										width={440}
										onChange={(value?: string) => {
											setFieldValue('expiryDate', value || '');
										}}
										value={values.expiryDate || ''}
										message={!!errors.expiryDate && touched.expiryDate ? errors.expiryDate : ''}
										error={!!errors.expiryDate && touched.expiryDate}
									/>
									<Divider marginBottom={16} withoutLine />
									<MiniDescription color="black54">
										{translate('document-details.description.2.point')}
									</MiniDescription>

									<Divider marginBottom={32} withoutLine />
									<ResidentIconWrapper>
										<IconContainer>
											<Icon name="resident" width={265} height="auto" />
										</IconContainer>
									</ResidentIconWrapper>

									<BodySBold color="black87">{translate('document-details.3.point')}</BodySBold>
									<Divider marginBottom={16} withoutLine />
									<MiniDescription color="black54">
										{translate('document-details.description.3.point')}
									</MiniDescription>
									<Divider withoutLine marginBottom={16} />
									<Row>
										<Col xs={12} md={6}>
											<DragAndDrop
												onFileSave={(fileParams) => {
													const documentScanObject = {
														...values.documentScan,
														base64frontSide: fileParams,
													};
													setFieldTouched('documentScan', true);
													setFieldValue('documentScan', documentScanObject);
												}}
												onFileDelete={() => {
													const documentScanObject = {
														...values.documentScan,
														base64frontSide: null,
													};
													setFieldValue('documentScan', documentScanObject);
												}}
												name={translate('drag.drop.document.side.front')}
												selectedFileInfo={values.documentScan?.base64frontSide}
											/>
										</Col>
										{screen(['xs', 'sm']) && <Divider withoutLine marginBottom={16} />}
										<Col xs={12} md={6}>
											<DragAndDrop
												onFileSave={(fileParams) => {
													const documentScanObject = {
														...values.documentScan,
														base64backSide: fileParams,
													};
													setFieldTouched('documentScan', true);
													setFieldValue('documentScan', documentScanObject);
												}}
												onFileDelete={() => {
													const documentScanObject = {
														...values.documentScan,
														base64backSide: null,
													};
													setFieldValue('documentScan', documentScanObject);
												}}
												name={translate('drag.drop.document.side.back')}
												selectedFileInfo={values.documentScan?.base64backSide}
											/>
										</Col>
									</Row>
									{errors?.documentScan && touched.documentScan && (
										<ErrorMessage>{errors?.documentScan || ''}</ErrorMessage>
									)}
								</FormWrapper>
							);
						}}
					</Formik>
				</Col>
			</Row>
		</ContactDetailsWrapper>
	);
};

export default DocumentDetails;
