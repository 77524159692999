import { Caption, CaptionBold } from '@Components/Typography/Typography.styled';
import { justifyContent, marginLeft } from '@Utils/layout/layoutSupport';
import styled from 'styled-components';

export const OrderItemContainer = styled.div`
	display: flex;
	flex-direction: row;
	margin-top: 12px;
	align-items: center;
`;

export const ImageContainer = styled.div`
	width: 40px;
	height: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: row;
`;

export const Image = styled.img`
	height: 40px;
`;

export const OrderItemTitle = styled(CaptionBold)`
	${({ theme }) => ({ color: theme.colors.black87 })}
`;

export const OrderItemDescription = styled(Caption)`
	${({ theme }) => ({ color: theme.colors.black54 })}
	margin-top: 4px;
`;

export const OrderItemDescriptionContainer = styled.div`
	${() => marginLeft('8px')}
`;

export const ItemWrapper = styled.div`
	display: flex;
	padding: 8px 0;
`;

export const ItemDescriptionWrapper = styled.div`
	padding: 0 8px;
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	width: calc(100% - 46px);
`;

export const PlanTitleWrapper = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
`;

export const TitleWrapper = styled.div`
	max-width: 70%;
`;

export const IconWrapper = styled.div`
	width: 10%;
	margin-top: -1px;
`;

export const ItemTitle = styled(CaptionBold)``;

export const ItemDescription = styled(Caption)`
	padding-top: 4px;
	${({ theme }) => ({
		color: theme.colors.black54,
	})}
`;

export const ESIMContainer = styled.div`
	margin-top: 8px;
	border-radius: 8px;
	padding: 4px;
	padding-bottom: 0px;
	${({ theme }) => ({
		backgroundColor: theme.colors.grey100,
	})};
	display: flex;
	gap: 4px;
`;

export const ESIMTitleContainer = styled.div``;

export const InsuranceContainer = styled.div`
	${({ theme }) => ({
		backgroundColor: theme.colors.background,
		alignItems: 'center',
	})}
	height: 60px;
	display: flex;
	border-radius: 8px;
	margin-top: 8px;
	margin-bottom: 8px;
	${() => justifyContent('flex-start')}
`;

export const InsuranceInfo = styled.div``;

export const InsuranceTitle = styled(Caption)`
	${({ theme }) => ({
		color: theme.colors.black87,
	})}
`;

export const InsuranceSubtitle = styled(Caption)`
	${({ theme }) => ({
		color: theme.colors.black54,
	})}
`;

export const IconWrapperPlus = styled.div`
	padding: 12px;
`;
