import { Caption, CaptionBigger } from '@Components/Typography/Typography.styled';
import { left } from '@Utils/layout/layoutSupport';
import styled from 'styled-components';

export const TooltipWrapper = styled.div<{ withPin?: boolean }>`
	${({ withPin }) => (withPin ? { display: 'flex', justifyContent: 'center' } : {})}
`;

export const TooltipContainer = styled.div<{
	pinFromLeft?: string;
	width?: number;
	withPin?: boolean;
	isWhiteBackground?: boolean;
	margin?: string;
}>`
	border-radius: 4px;
	position: absolute;
	display: flex;
	flex-direction: row;
	gap: 6px;
	width: ${({ width }) => width}px;
	justify-content: center;
	transform: translate(-40%, 0);
	color: red;
	z-index: 99999;
	${({ theme, withPin, isWhiteBackground, margin }) => ({
		margin: margin ? margin : 'unset',
		backgroundColor: theme.colors[isWhiteBackground ? 'white' : withPin ? 'grey100' : 'support3'],
		top: withPin ? 62 : 'unset',
		transform: withPin ? 'unset' : 'translate(-40%, 0)',
		padding: withPin ? '6px 8px' : '2px 4px',
		boxShadow: withPin
			? '0 4px 2px 0 rgba(0, 0, 0, 0.01), 0 1px 10px 5px rgba(0, 0, 0, 0.02), 0 3px 8px 2px rgba(0, 0, 0, 0.04)'
			: 'unset',
	})};
	:after {
		${({ withPin, theme, pinFromLeft }) =>
			withPin
				? `
		content: '';
		position: absolute;
		top: -6px;
		width: 0;
		height: 0;
		border-left: 6px solid ${theme.colors.transparent};
		border-right: 6px solid ${theme.colors.transparent};
		border-bottom: 6px solid ${theme.colors.grey100};
		clear: both;
		${() => left(!!pinFromLeft ? pinFromLeft : '45%')};
	`
				: ''}
	}
`;

export const LowerPinTooltipContainer = styled.div<{
	pinFromLeft?: string;
	width?: number;
	withPin?: boolean;
	isWhiteBackground?: boolean;
	margin?: string;
}>`
	border-radius: 4px;
	position: absolute;
	display: flex;
	flex-direction: row;
	gap: 6px;
	width: ${({ width }) => width}px;
	justify-content: center;
	transform: translate(-40%, 0);
	color: red;
	z-index: 99999;
	${({ theme, withPin, isWhiteBackground, margin }) => ({
		margin: margin ? margin : 'unset',
		backgroundColor: theme.colors[isWhiteBackground ? 'white' : withPin ? 'grey100' : 'support3'],
		top: withPin ? 62 : 'unset',
		transform: withPin ? 'unset' : 'translate(-40%, 0)',
		padding: withPin ? '6px 8px' : '2px 4px',
		boxShadow: withPin
			? '0px 7px 8px 0px rgba(0, 0, 0, 0.05), 0px 5px 22px 4px rgba(0, 0, 0, 0.04), 0px 12px 17px 2px rgba(0, 0, 0, 0.04)'
			: 'unset',
	})};
	:after {
		${({ withPin, theme, pinFromLeft }) =>
			withPin
				? `
		content: '';
		position: absolute;
		bottom: -6px;
		width: 0;
		height: 0;
		border-left: 6px solid ${theme.colors.transparent};
		border-right: 6px solid ${theme.colors.transparent};
		border-top: 6px solid ${theme.colors.grey100};
		clear: both;
		${() => left(!!pinFromLeft ? pinFromLeft : '45%')};
	`
				: ''}
	}
`;

export const BiggerTooltipText = styled(CaptionBigger)<{ fontColor: types.theme.themeColors }>`
	${({ theme, fontColor }) => ({
		color: theme.colors[fontColor],
	})}
`;

export const TooltipText = styled(Caption)<{ fontColor: types.theme.themeColors }>`
	${({ theme, fontColor }) => ({
		color: theme.colors[fontColor],
	})}
`;
