import { IProductsByPartNumber } from '@ApiModels/productsByPartNumber';
import Divider from '@Components/Divider/Divider';
import Icon from '@Components/Icon/Icon';
import { BodyM, HeadlineBold, TitleBold, BodyMBold } from '@Components/Typography/Typography.styled';
import { setModal } from '@Redux/modules/modal/actions';
import { dispatch } from '@Redux/store';
import useScreen from '@Utils/hooks/useScreen';
import useTranslate from '@Utils/hooks/useTranslate';
import { copyToClipboard } from '@Utils/copyToClipboard/copyToClipboard';
import React, { FunctionComponent, useEffect, useState } from 'react';
import {
	CloseIconWrapper,
	CustomModalContainer,
	MicrosoftCardDetailsWrapper,
	CopyClipboardButton,
	ButtonWrapper,
	TitleWrapper,
	MicrosoftCodeContainer,
	EmailLabelWrapper,
	ErrorLabelWrapper,
	MicrosoftInfoWrapper,
	KeysWrapper,
} from './MicrosoftCardDetails.styled';
import { SecondaryLargeButtonFlex } from '@Components/controls/Button/Button';
import { OrderFlowService } from '@Services/orderFlow/orderFlow';
import { addBusyIndicator, removeBusyIndicator } from '@Redux/modules/busyIndicator/actions';
import BusyIndicator from '@Components/BusyIndicator/BusyIndicator';
import ConditionalRender from '@Components/ConditionalRender/ConditionalRender';
import { IMSKey } from '@ApiModels/msKeysList';
import { CUSTOMER_CARE_PHONE, CUSTOMER_B2B2C_CARE_PHONE } from '@Config/app.config';
import { useSelector } from 'react-redux';
const MICROSOFT_PAGE = 'https://www.office.com/setup365';
const MS_KEY_INDICATOR = 'MS_KEY_INDICATOR';

export interface IMicrosoftCardDetails {
	params: {
		email: string;
		product?: IProductsByPartNumber;
		orderItemId: string;
	};
}

const ModalMicrosoftCardDetails: FunctionComponent<IMicrosoftCardDetails['params']> = ({
	email,
	product,
	orderItemId,
}) => {
	const { screen } = useScreen();
	const { translate } = useTranslate();
	const [msKeys, setMsKeys] = useState<IMSKey[]>([]);
	const [keyError, setKeyError] = useState<boolean>(false);
	const isB2b = useSelector((state: types.redux.IState) => state.api.authentication.isB2b);
	const isB2b2c = useSelector((state: types.redux.IState) => state.api.authentication.isB2b2c);

	const handleClose = () => {
		dispatch(
			setModal({
				type: null,
				data: null,
			})
		);
	};

	useEffect(() => {
		dispatch(addBusyIndicator(MS_KEY_INDICATOR));
		OrderFlowService.getItemMSKey(orderItemId).subscribe(
			(r) => {
				setKeyError(r.returnValue.some((item) => item.activateKey === 'ACTINATION_KEY_GENERATED_ERROR'));
				setMsKeys(r.returnValue);
				dispatch(removeBusyIndicator(MS_KEY_INDICATOR));
			},
			(r) => {
				setKeyError(true);
				dispatch(removeBusyIndicator(MS_KEY_INDICATOR));
			}
		);
	}, []);

	return (
		<CustomModalContainer isXs={screen('xs')} isSm={screen('sm')}>
			<MicrosoftCardDetailsWrapper>
				<TitleWrapper>
					<TitleBold>{translate('gift.card.modal.details')}</TitleBold>
					<CloseIconWrapper onClick={handleClose}>
						<Icon name="close" fill="black" width={24} height={24} />
					</CloseIconWrapper>
				</TitleWrapper>
				<Divider marginBottom={16} />
				<BusyIndicator listener={MS_KEY_INDICATOR} skeleton="orderTracking">
					<>
						<KeysWrapper isXs={screen('xs')} isSm={screen('sm')}>
							{msKeys.map((item) => (
								<ConditionalRender
									key={item.activateKey}
									show={item.activateKey !== 'ACTINATION_KEY_GENERATED_ERROR'}
									onTrue={() => (
										<>
											<HeadlineBold>{item.name}</HeadlineBold>
											<BodyM color="black54">
												{translate(
													'microsoft.modal.subscription',
													item.msDesc?.toLowerCase() ?? ''
												)}
											</BodyM>
											<MicrosoftCodeContainer isXs={screen('xs')} isSm={screen('sm')}>
												<ConditionalRender
													show={screen('xs') || screen('sm')}
													onTrue={() => <TitleBold>{item.activateKey}</TitleBold>}
													onFalse={() => <HeadlineBold>{item.activateKey}</HeadlineBold>}
												/>
												<CopyClipboardButton
													onClick={() => copyToClipboard(item.activateKey ?? ''.toString())}
												>
													{translate('copy.to.clipboard')}
												</CopyClipboardButton>
											</MicrosoftCodeContainer>
											<Divider marginBottom={16} withoutLine />
										</>
									)}
								/>
							))}
						</KeysWrapper>
						<ConditionalRender
							show={keyError}
							onTrue={() => (
								<ErrorLabelWrapper>
									<BodyM>
										{translate(
											'microsoft.modal.error',
											isB2b2c || isB2b ? CUSTOMER_B2B2C_CARE_PHONE : CUSTOMER_CARE_PHONE
										)}
									</BodyM>
								</ErrorLabelWrapper>
							)}
						/>
						<ConditionalRender
							show={msKeys.length > 0}
							onTrue={() => (
								<EmailLabelWrapper isXs={screen('xs')} isSm={screen('sm')}>
									<BodyM>{translate('microsoft.modal.email-info')}</BodyM>
									<BodyMBold>{`\xa0${email}\xa0`}</BodyMBold>
								</EmailLabelWrapper>
							)}
						/>
					</>
				</BusyIndicator>
				<Divider marginBottom={32} marginTop={16} />
				<MicrosoftInfoWrapper>
					<BodyM>{translate('microsoft.visit-page.info.first')}</BodyM>
					<BodyM>{translate('microsoft.visit-page.info.second')}</BodyM>
					<ButtonWrapper>
						<SecondaryLargeButtonFlex
							uniqueId="microsoftCardDetailsVisitPage"
							onClick={() => (window.location.href = MICROSOFT_PAGE)}
						>
							{translate('microsoft.visit-page.button')}
						</SecondaryLargeButtonFlex>
					</ButtonWrapper>
				</MicrosoftInfoWrapper>
			</MicrosoftCardDetailsWrapper>
		</CustomModalContainer>
	);
};

export default ModalMicrosoftCardDetails;
