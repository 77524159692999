import { createAction } from 'redux-actions';

export interface IBuyNowPayLater {
	buyNowPayLaterSessionId: string;
	buyNowPayLaterIframeUrl: string;
	isBuyNowPayLaterIframe: boolean;
}

export const setBuyNowPayLater = createAction('SET_BUY_NOW_PAY_LATER', (action: IBuyNowPayLater) => {
	return action;
});
