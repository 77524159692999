import styled from 'styled-components';
import { ParagraphMBold, DisplayMBold, ButtonM, Subheading } from '@Components/Typography/Typography';

export const BannerPlaceholder = styled.div`
	height: 400px;
	align-self: stretch;
	flex-grow: 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: stretch;
	gap: 10px;
	padding: 42px 24px;
	border: solid 3px var(--warning);
	${({ theme }) => {
		return {
			border: `dotted  3px ${theme.colors.warning}`,
		};
	}}
`;

export const BannerPlaceholderTitle = styled(ParagraphMBold)`
	height: 104px;
	align-self: stretch;
	flex-grow: 0;
	text-align: center;
	${({ theme }) => {
		return {
			color: theme.colors.warning,
		};
	}}
`;

export const AppleBrandStoreProductSection = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin: 68px 0 28px 0;
`;

export const AppleBrandStoreProductSubSection = styled.div`
	margin: 32px 0;
`;

export const AppleBrandStoreProductSubSectionTitle = styled(Subheading)`
	${({ theme }) => {
		return {
			color: theme.colors.black54,
		};
	}}
`;

export const AppleBrandStoreProductSectionTitle = styled(DisplayMBold)`
	${({ theme }) => {
		return {
			color: theme.colors.black87,
		};
	}}
`;

export const AppleBannerWrapper = styled.div`
	width: 100%;
	background-color: white;
	margin: 16px 0px;
	display: flex;
	align-items: center;
	justify-content: center;
`;

export const AppleBrandStoreProductSectionButton = styled(ButtonM)`
	cursor: pointer;
	${({ theme }) => {
		return {
			color: theme.colors.primary,
		};
	}}
`;

export const CarouselWrapper = styled.div`
	margin-right: 16px;
`;

export const EmptyCarouselObject = styled.div`
	margin-right: 16px;
`;

export const CarouselRightIconWrapper = styled.div`
	padding: 3px;
	border-radius: 8px;
	border: 1px solid;
	top: 220px;
	right: 0px;
	position: absolute;
	${({ theme }) => {
		return {
			borderColor: theme.colors.primary,
			backgroundColor: theme.colors.white,
		};
	}}
`;

export const CarouselLeftIconWrapper = styled.div`
	padding: 3px;
	border-radius: 8px;
	position: absolute;
	border: 1px solid;
	top: 220px;
	left: 0px;
	${({ theme }) => {
		return {
			borderColor: theme.colors.primary,
			backgroundColor: theme.colors.white,
		};
	}}
`;

export const ExtraSupportWrapper = styled.div`
	display: flex;
	gap: 16px;
`;

export const ShippingOptionsWrapper = styled.div`
	display: flex;
	gap: 24px;
	margin-bottom: 56px;
`;
