import styled from 'styled-components';
import { matchScreen } from '@Utils/layout/layoutSupport';
import { ValueBold, BodyS } from '@Components/Typography/Typography.styled';

export const CardContainer = styled.div`
	width: 100vw;
	height: 100vh;
	overflow-y: scroll;
`;

export const CardWrapper = styled.div`
	max-width: 1110px;
	margin: auto;
	padding: 0px 16px;
`;

export const Header = styled.div`
	padding-top: 28px;
	padding-bottom: 20px;
`;

export const LogoWrapper = styled.div`
	padding-top: 12px;
	padding-bottom: 4px;
	width: fit-content;
`;

export const ActionWrapper = styled.div`
	display: flex;
	justify-content: flex-end;
	padding: 12px 0;
	cursor: pointer;
`;

export const PlanComparisonWrapper = styled.div`
	padding: 24px 0;
	${() => {
		return {
			marginLeft: matchScreen(['xs']) ? -16 : 0,
			marginRight: matchScreen(['xs']) ? -16 : 0,
		};
	}}
`;

export const RecomendedPlan = styled.div`
	margin-bottom: 20px;
`;

export const OtherPlansScroll = styled.div`
	height: 500px;
	overflow-x: scroll;
	// Firefox
	${() => ({
		overflowX: 'scroll',
		scrollbarWidth: 'thin',
	})}
`;

export const HorizontalPlansScroll = styled.div`
	min-width: 100%;
	margin-left: -16px;
	margin-right: -16px;
	padding: 0px 16px;
	overflow-y: scroll;
	// Firefox
	${() => ({
		overflowX: 'scroll',
		scrollbarWidth: 'thin',
	})}
	display: flex;
	flex-direction: row;
	${() => {
		return {
			height: matchScreen(['xl', 'lg']) ? 300 : 230,
		};
	}}
`;

export const PlanBarWrapper = styled.div`
	cursor: pointer;
	${() => {
		const isSmall = matchScreen(['md', 'sm', 'xs']);
		return {
			minWidth: isSmall ? 272 : 'unset',
			width: isSmall ? 272 : 'unset',
			marginRight: !matchScreen(['xl', 'lg']) ? 16 : 0,
		};
	}}
`;

export const XSPlanSliderTitle = styled(ValueBold)``;
export const XSPlanSliderSubtitle = styled(BodyS)``;
