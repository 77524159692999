import React, { FunctionComponent, useEffect, useState } from 'react';
import { StoreWrapper } from './MegaMenuPrepaid.styled';
import useTranslate from '@Utils/hooks/useTranslate';
import ConditionalRender from '@Components/ConditionalRender/ConditionalRender';
import { useSelector } from 'react-redux';
import externalLinks from '@Utils/externalLinks/externalLinks';
import MegaMenuMultilevelItem, {
	MegaMenuMultilevelItemType,
} from '@Components/MegaMenuMultilevelItem/MegaMenuMultilevelItem';
import { ItemsWrapper, LeftContainer, LeftSectionWrapper, RightContainer } from '@Components/MegaMenu/MegaMenu.styled';
import Divider from '@Components/Divider/Divider';
import { IMenuContent } from '@ApiModels/megaMenuContent';
import { RIGHT_DIVIDER_MARGIN_BOTTOM } from '@Components/MegaMenu/MegaMenu';
import { getLang } from '@Utils/language/language';
import { matchScreen } from '@Utils/layout/layoutSupport';

interface IMegaMenuMobileProps {
	mobileMenu: boolean;
	isMd: boolean;
	activeMenuIndex: number;
	setActiveMenuIndex: (index: number) => void;
}

const MegaMenuPrepaid: FunctionComponent<IMegaMenuMobileProps> = ({
	mobileMenu,
	isMd,
	activeMenuIndex,
	setActiveMenuIndex,
}) => {
	const [content, setContent] = useState<IMenuContent>();
	const language = useSelector((state: types.redux.IState) => state.settings.language);
	const { translate } = useTranslate();
	const isSmallDevice = matchScreen(['xs', 'sm']);

	useEffect(() => {
		return () => {
			setActiveMenuIndex(-1);
		};
	}, []);

	useEffect(() => {
		setContent({
			rows: [
				{
					label: translate('menu.item.get-plans'),
					link: externalLinks('PERSONAL'),
					children: [
						{
							label: translate('menu.prepaid.item.new-hayyak-plans'),
							link: externalLinks('NEW_HAYYAK_PLANS'),
						},
						{
							label: translate('menu.prepaid.item.hdop'),
							link: externalLinks('HAYYAK_D_O_P'),
						},
						{
							label: translate('menu.prepaid.item.tourist-pack'),
							link: externalLinks('TOURIST_PACK'),
						},
						{
							label: translate('menu.prepaid.item.erada'),
							link: externalLinks('PREPAID_ERADA'),
						},
						{
							label: translate('menu.prepaid.item.aman'),
							link: externalLinks('PREPAID_AMAN'),
						},
						{
							label: translate('menu.prepaid.item.payg'),
							link: externalLinks('PREPAID_PAYG'),
						},
					],
				},
				{
					label: translate('menu.prepaid.item.roaming'),
					link: externalLinks('ROAMING_PREPAID'),
					children: [
						{
							label: translate('menu.prepaid.item.jawazak-gcc'),
							link: externalLinks('JAWAZAK_GCC'),
						},
						{
							label: translate('menu.prepaid.item.jawazak-world'),
							link: externalLinks('JAWAZAK_WORLD'),
						},
						{
							label: translate('menu.prepaid.item.jawazak-flight'),
							link: externalLinks('JAWAZAK_FLIGHT'),
						},
						{
							label: translate('menu.prepaid.item.roaming-abroad'),
							link: externalLinks('ROAMING'),
						},
					],
				},
				{
					label: translate('menu.prepaid.item.add-ons'),
					link: externalLinks('ADD_ONS_PREPAID'),
					children: [
						{
							label: translate('menu.prepaid.item.mobile-internet'),
							link: externalLinks('MOBILE_INTERNET'),
						},
						{
							label: translate('menu.prepaid.item.national-calls'),
							link: externalLinks('NATIONAL_CALLS'),
						},
						{
							label: translate('menu.prepaid.item.international-calls'),
							link: externalLinks('INTERNATIONAL_CALLS'),
						},
						{
							label: translate('menu.prepaid.item.nonstop-social'),
							link: externalLinks('NON_STOP_SOCIAL'),
						},
						{
							label: translate('menu.prepaid.item.Marhaba-World'),
							link: externalLinks('MARHABA_WORLD'),
						},
						{
							label: translate('menu.prepaid.item.talk-more'),
							link: externalLinks('TALK_MORE'),
						},
						{
							label: translate('menu.prepaid.item.hayyak-liberty'),
							link: externalLinks('HAYYAK_LIBERTY'),
						},
						{
							label: translate('menu.prepaid.add-ons.item.tourist-pack'),
							link: externalLinks('TOURIST_PACK_PREPAID'),
						},
					],
				},
				{
					label: translate('menu.prepaid.add-ons.item.hayyak-tariff'),
					link: externalLinks('HAYYAK'),
					children: [],
				},
				{
					label: translate('menu.prepaid.add-ons.item.p2p'),
					link: `/${getLang()}/prepaidMigration`,
					children: [],
				},
				{
					label: translate('menu.prepaid.item.transfer-your-number'),
					link: externalLinks('TRANSFER_YOUR_NUMBER'),
					children: [],
				},
				{
					label: translate('menu.prepaid.item.e-sim'),
					link: externalLinks('ESIM'),
					children: [],
				},
				{
					label: translate('menu.prepaid.item.apple-watch-cellular'),
					link: externalLinks('APPLE_WATCH_CELLULAR'),
					children: [],
				},
				{
					label: translate('menu.prepaid.item.promotions'),
					link: externalLinks('PROMOTIONS_PREPAID'),
					children: [],
				},
			],
		});
	}, [language]);

	return (
		<StoreWrapper mobileMenu={mobileMenu} isMd={isMd}>
			<ConditionalRender
				show={activeMenuIndex < 0 || !isSmallDevice}
				onTrue={() => (
					<LeftContainer mobileMenu={mobileMenu} isMd={isMd} isSmallDevice={isSmallDevice}>
						<>
							<MegaMenuMultilevelItem
								type={MegaMenuMultilevelItemType.HEADER}
								name={translate('menu.item.prepaid')}
								iconRight="arrowRightBox"
							/>
							{content?.rows?.map((row, index) => (
								<LeftSectionWrapper key={index}>
									<ItemsWrapper mobileMenu={mobileMenu} key={row.label}>
										<MegaMenuMultilevelItem
											key={index}
											type={
												row?.children?.length > 0
													? MegaMenuMultilevelItemType.ITEM
													: MegaMenuMultilevelItemType.LINK
											}
											onClick={() => {
												row?.children?.length > 0 && setActiveMenuIndex(index);
											}}
											to={row.link}
											name={row.label}
											paddingLeft={16}
											active={activeMenuIndex === index}
											iconRight={row?.children?.length > 0 ? 'arrowRightBox' : undefined}
										/>
									</ItemsWrapper>
								</LeftSectionWrapper>
							))}
						</>
					</LeftContainer>
				)}
			/>
			<ConditionalRender
				show={activeMenuIndex >= 0}
				onTrue={() => (
					<RightContainer mobileMenu={mobileMenu} isMd={isMd} isSmallDevice={isSmallDevice}>
						<>
							<ConditionalRender
								show={!isSmallDevice}
								onTrue={() => <Divider marginBottom={RIGHT_DIVIDER_MARGIN_BOTTOM} withoutLine />}
							/>
							<ConditionalRender
								show={isMd}
								onFalse={() =>
									content?.rows[
										activeMenuIndex
									]?.children?.map(({ label, link, additionalLabel }) => (
										<MegaMenuMultilevelItem
											paddingLeft={16}
											key={label}
											type={MegaMenuMultilevelItemType.SUB_ITEM}
											to={link}
											name={label}
											additionalLabel={additionalLabel}
										/>
									))
								}
								onTrue={() =>
									content?.rows[
										activeMenuIndex
									]?.children?.map(({ label, link, additionalLabel }) => (
										<MegaMenuMultilevelItem
											paddingLeft={16}
											key={label}
											type={MegaMenuMultilevelItemType.SUB_ITEM}
											to={link}
											name={label}
											additionalLabel={additionalLabel}
										/>
									))
								}
							/>
						</>
					</RightContainer>
				)}
			/>
		</StoreWrapper>
	);
};

export default MegaMenuPrepaid;
