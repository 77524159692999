import React, { FunctionComponent, useEffect } from 'react';
import {
	Title,
	RadioWrapper,
	FormWrapper,
	ExistingAccountPromptWrapper,
	InfoTitleWrapper,
	InfoWrapper,
	IconWrapper,
} from './ExistingAccountPrompt.styled';
import RadioButton from '@Components/controls/RadioButton/RadioButton';
import ConditionalRender from '@Components/ConditionalRender/ConditionalRender';
import { Formik } from 'formik';
import { PrimaryMediumButtonFlex } from '@Components/controls/Button/Button';
import TextField from '@Components/controls/TextField/TextField';
import useTranslate from '@Utils/hooks/useTranslate';
import tenantDataValidator from '@Utils/validators/tenantDataValidator';
import Icon from '@Components/Icon/Icon';
import {
	RadioButtonState,
	ITenatFields,
	RegistrationStep,
	GET_TENANT,
} from '@Views/MicrosoftRegistration/MicrosoftRegistration';
import { useCart } from '@Utils/context/CartContext';
import { MicrosoftTenantService } from '@Services/digitalServices/omantelTenant';
import { ResponseResult } from '@ApiModels/getTenantFromOrdersHistory';
import { addBusyIndicator, removeBusyIndicator } from '@Redux/modules/busyIndicator/actions';
import BusyIndicator from '@Components/BusyIndicator/BusyIndicator';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import Divider from '@Components/Divider/Divider';
import useScreen from '@Utils/hooks/useScreen';

interface IExistingAccountPromptProps {
	params: {
		activeRadio: RadioButtonState;
		setActiveRadio: (activeRadio: RadioButtonState) => void;
		handleRadioButtonClick: (value: string) => void;
		handleTenantDataSubmit: (tenantData: ITenatFields) => void;
		setRegistrationStep: (registratonStep: RegistrationStep) => void;
	};
}

const INFO_ICON_SIZE = 24;

const ExistingAccountPrompt: FunctionComponent<IExistingAccountPromptProps> = ({
	params: { activeRadio, setActiveRadio, handleRadioButtonClick, handleTenantDataSubmit },
}) => {
	const isLogged = !!useSelector((state: types.redux.IState) => state.api.authentication.signedIn);
	const { translate } = useTranslate();
	const { cart } = useCart();
	const [tenantData, setTenantData] = React.useState<ITenatFields>({} as ITenatFields);
	const dispatch = useDispatch();
	const busyIndicatorsList = useSelector((state: types.redux.IState) => state.busyIndicator.list);
	const { screen } = useScreen();

	const onSaveDetails = (values: ITenatFields) => {
		handleTenantDataSubmit({ ...values });
		dispatch(removeBusyIndicator(GET_TENANT));
	};

	useEffect(() => {
		if (isLogged) {
			dispatch(addBusyIndicator(GET_TENANT));
			MicrosoftTenantService.getTenantFromOrdersHistory(cart.orderId).subscribe(
				(response) => {
					if (response.result.result === ResponseResult.SUCCESS) {
						setTenantData({
							tenantId: response.result.tenantId,
							tenantName: response.result.tenantName,
						});
						setActiveRadio(RadioButtonState.RADIO_BUTTON_VALUE_YES);
					}
					dispatch(removeBusyIndicator(GET_TENANT));
				},
				() => {
					dispatch(removeBusyIndicator(GET_TENANT));
				}
			);
		}
	}, [isLogged, cart.orderId]);

	return (
		<ExistingAccountPromptWrapper
			isBIActive={busyIndicatorsList.includes(GET_TENANT)}
			sizes={{ isL: screen('lg'), isMd: screen('md'), isSm: screen('sm'), isXs: screen('xs') }}
		>
			<BusyIndicator listener={GET_TENANT}>
				<>
					<Title>{translate('microsoft-automation-proccess.account.do-you-have-ms-account')}</Title>
					<RadioWrapper>
						<RadioButton
							label="Yes"
							selected={activeRadio}
							marginBottom={-8}
							name="existing-microsoft-account-yes"
							onClick={() => handleRadioButtonClick(RadioButtonState.RADIO_BUTTON_VALUE_YES)}
							value={RadioButtonState.RADIO_BUTTON_VALUE_YES}
						/>
					</RadioWrapper>
					<ConditionalRender
						show={activeRadio !== RadioButtonState.INITIAL}
						onTrue={() => <Divider marginBottom={12} withoutLine />}
					/>
					<ConditionalRender
						show={activeRadio === RadioButtonState.RADIO_BUTTON_VALUE_YES}
						onTrue={() => (
							<>
								<Formik<ITenatFields>
									validationSchema={tenantDataValidator()}
									validateOnBlur={true}
									initialValues={{
										tenantName: tenantData?.tenantName ?? '',
										tenantId: tenantData?.tenantId ?? '',
									}}
									onSubmit={(values) => {
										onSaveDetails(values);
									}}
								>
									{({ setFieldValue, values, errors, touched, handleSubmit, setFieldTouched }) => {
										return (
											<>
												<FormWrapper>
													<TextField
														width="100%"
														id="tenantName"
														label="Tenant Name"
														value={values.tenantName}
														onChange={(value) => {
															setFieldTouched('tenantName', true);
															setFieldValue('tenantName', value.toString());
														}}
														message={
															!!errors.tenantName && touched.tenantName
																? errors.tenantName
																: translate(
																		'microsoft-automation-proccess.account.name'
																  )
														}
														error={!!errors.tenantName && touched.tenantName}
														marginBottom={16}
													/>
													<TextField
														width="100%"
														id="tenantId"
														label="Tenant ID"
														value={values.tenantId}
														onChange={(value) => {
															setFieldTouched('tenantId', true);
															setFieldValue('tenantId', value.toString());
														}}
														message={
															!!errors.tenantId && touched.tenantId
																? errors.tenantId
																: translate(
																		'microsoft-automation-proccess.account.company-number'
																  )
														}
														error={!!errors.tenantId && touched.tenantId}
														marginBottom={16}
													/>
												</FormWrapper>
												<PrimaryMediumButtonFlex
													uniqueId="existing-account-prompt.tenant-verify"
													onClick={handleSubmit}
													textAlign="center"
													listener={GET_TENANT}
													disabled={
														(!!errors.tenantName && touched.tenantName) ||
														(!!errors.tenantId && touched.tenantId) ||
														(!touched.tenantId && !values.tenantId) ||
														(!touched.tenantName && !values.tenantName)
													}
												>
													{translate('microsoft-automation-proccess.account.button.verify')}
												</PrimaryMediumButtonFlex>
											</>
										);
									}}
								</Formik>
							</>
						)}
					/>
					<ConditionalRender
						show={activeRadio !== RadioButtonState.INITIAL}
						onTrue={() => <Divider marginBottom={8} withoutLine />}
					/>
					<RadioWrapper>
						<RadioButton
							label="No"
							marginBottom={-12}
							selected={activeRadio}
							name="existing-microsoft-account-no"
							onClick={() => handleRadioButtonClick(RadioButtonState.RADIO_BUTTON_VALUE_NO)}
							value={RadioButtonState.RADIO_BUTTON_VALUE_NO}
						/>
					</RadioWrapper>
					<ConditionalRender
						show={activeRadio === RadioButtonState.RADIO_BUTTON_VALUE_NO}
						onTrue={() => {
							return (
								<InfoWrapper>
									<IconWrapper>
										<Icon
											name="info"
											width={INFO_ICON_SIZE}
											height={INFO_ICON_SIZE}
											fill="black54"
										/>
									</IconWrapper>
									<InfoTitleWrapper>
										{translate('microsoft-automation-proccess.account.no-account')}
									</InfoTitleWrapper>
								</InfoWrapper>
							);
						}}
					/>
				</>
			</BusyIndicator>
		</ExistingAccountPromptWrapper>
	);
};

export default ExistingAccountPrompt;
