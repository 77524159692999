import React, { FunctionComponent, useEffect, useRef } from 'react';
import { ColorDot, ColorDotWrapper, CountryDot, CrossLine, SingleSlide, SliderWrapper } from './Slider.styled';
import { ColorPickerPosition } from '@Components/controls/ColorPicker/ColorPicker.styles';
import ConditionalRender from '@Components/ConditionalRender/ConditionalRender';
import { IColorWithId } from '@ApiModels/productsByCategoryId';
export interface IAvailableColorWithId extends IColorWithId {
	available: boolean;
	thumbnail: string;
	fullImage: string;
	availableCapacity: (string | undefined)[];
	listPrice: string;
	offerPrice: string;
	taxablePrice: string;
	vatPercent: string;
	vatValue: string;
}
interface ISliderProps {
	colors: IAvailableColorWithId[];
	setSelectedColor?: (color: string | null) => void;
	selectedColor?: string | null;
	numberOfItemsOnSlide: number;
	position: ColorPickerPosition;
	itemSize: number;
	itemsSeparator: number;
	numberOfSlides: number;
	activeSlide: number;
	carouselVisible: boolean;
}

const lightColors = ['#faf6f2'];
const Slider: FunctionComponent<ISliderProps> = ({
	colors,
	setSelectedColor,
	selectedColor,
	numberOfItemsOnSlide,
	position,
	itemSize,
	itemsSeparator,
	numberOfSlides,
	activeSlide,
	carouselVisible,
}) => {
	const sliderSize: number = numberOfItemsOnSlide * itemSize + itemsSeparator * (numberOfItemsOnSlide * 2 - 2);
	const sliderWrapperRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		if (sliderWrapperRef.current) {
			if (position === ColorPickerPosition.HORIZONTAL) {
				sliderWrapperRef.current.scrollTo({ top: 0, left: activeSlide * sliderSize, behavior: 'smooth' });
			} else if (position === ColorPickerPosition.VERTICAL) {
				sliderWrapperRef.current.scrollTo({ top: activeSlide * sliderSize, left: 0, behavior: 'smooth' });
			}
		}
	}, [activeSlide, position]);

	const DotElement = ({ index, isLightColor }: { index: number; isLightColor: boolean }) => {
		if (colors[index].color.includes('#')) {
			return (
				<ColorDot
					isLightColor={isLightColor}
					color={colors[index].color}
					unavailable={!colors[index].available}
				/>
			);
		} else if (colors[index].color.includes('svg')) {
			return <CountryDot src={colors[index].color} />;
		}
		return <></>;
	};

	return (
		<SliderWrapper position={position} size={sliderSize} ref={sliderWrapperRef} carouselVisible={carouselVisible}>
			{[...Array(numberOfSlides)].map((_, slideNumber) => (
				<SingleSlide key={slideNumber} position={position} size={sliderSize}>
					{[...Array(numberOfItemsOnSlide)].map((_, itemNumber) => {
						const index = slideNumber * numberOfItemsOnSlide + itemNumber;
						return (
							<ConditionalRender
								key={index}
								show={index < colors.length}
								onTrue={() => (
									<ColorDotWrapper
										selected={selectedColor === colors[index].color}
										separator={itemsSeparator}
										size={itemSize}
										position={position}
										first={itemNumber === 0}
										last={itemNumber === numberOfItemsOnSlide - 1 || index === colors.length - 1}
										onClick={() => {
											setSelectedColor?.(colors[index].color);
										}}
									>
										<DotElement
											index={index}
											isLightColor={
												lightColors ? lightColors.includes(colors[index].color) : false
											}
										/>
										<ConditionalRender
											show={!colors[index].available}
											onTrue={() => <CrossLine />}
										/>
									</ColorDotWrapper>
								)}
							/>
						);
					})}
				</SingleSlide>
			))}
		</SliderWrapper>
	);
};

export default Slider;
