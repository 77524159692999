import { BodySBold } from '@Components/Typography/Typography.styled';
import { marginLeft, right } from '@Utils/layout/layoutSupport';
import styled from 'styled-components';

export const CardWrapper = styled.div`
	padding: 8px 16px;
`;

export const PaymentMethodEmpty = styled.div`
	padding: 48px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	border-radius: 8px;
	${({ theme }) => ({
		backgroundColor: theme.colors.grey50,
	})}
`;

export const PaymentMethods = styled.div``;

export const PaymentToken = styled.div`
	padding: 16px 8px;
	border-radius: 8px;
	margin-bottom: 16px;
	${({ theme }) => ({
		backgroundColor: theme.colors.grey50,
	})}
`;

export const DeleteToken = styled(BodySBold)`
	cursor: pointer;
	text-decoration: underline;
	${({ theme }) => ({
		color: theme.colors.primary,
	})}
	${() => marginLeft(56)}
`;

export const Horizontal = styled.div`
	display: flex;
`;

export const TextAlign = styled.div`
	margin-top: 17px;
	margin-left: 8px;
`;

export const ToggleWrapper = styled.div`
	display: flex;
	margin-top: 12px;
	position: absolute;
	${() => right(32)}
`;

export const ToggleText = styled.div`
	padding: 6px;
`;
