import styled from 'styled-components';

export const MenuItem = styled.div<{ isLast?: boolean }>`
	${({ theme, isLast }) => ({
		padding: `24px 0`,
		borderBottom: isLast ? undefined : `1px solid ${theme.colors.black12}`,
	})}
	width: 200px;
	text-align: center;
`;
