import { BillingType } from '@ApiModels/accounts';
import { ISinglePackage } from '@ApiModels/singlePackage';
import { IDropdownItem } from '@Components/controls/Dropdown/Dropdown';
import { AccountsService } from '@Services/accounts/accountsService';
import PackagesService from '@Services/packages/packages';
import React, { useContext, useEffect, useState } from 'react';
import { createContext } from 'react';
import { useSelector } from 'react-redux';
import useTranslate from '@Utils/hooks/useTranslate';

export interface IUsePrepaidMigration {
	currentIndex: number;
	availableNumbers?: IDropdownItem[];
	choosenNumber: string;
	setChoosenNumber: (value: string) => void;
	nextStep: () => void;
	availablePlans: ISinglePackage[];
	chooseOtherNumber: () => void;
}

export const PrepaidMigrationContext = createContext<IUsePrepaidMigration>({
	currentIndex: 0,
	choosenNumber: '',
	setChoosenNumber: (value) => {},
	nextStep: () => {},
	availablePlans: [],
	chooseOtherNumber: () => {},
});

export const usePrepaidMigration = (): IUsePrepaidMigration => useContext(PrepaidMigrationContext);

const PrepaidMigrationContextProvider = ({ children }: { children: any }): JSX.Element => {
	const isLogged = useSelector((state: types.redux.IState) => state.api.authentication.signedIn);
	const [availableNumbers, setAvailableNumbers] = useState<IDropdownItem[]>();
	const [choosenNumber, setChoosenNumber] = useState('');
	const [currentIndex, setCurrentIndex] = useState(0);
	const [availablePlans, setAvailablePlans] = useState<ISinglePackage[]>([]);
	const { translate } = useTranslate();

	useEffect(() => {
		PackagesService.getPackagesByGroupAndType('PLAN', 'POSTPAID').subscribe(
			(r) => {
				setAvailablePlans(
					r.packages?.filter(({ planType, selfActivate }) => planType === 'BQT' && selfActivate) ?? []
				);
			},
			() => {}
		);
	}, []);

	useEffect(() => {
		if (isLogged) {
			AccountsService.getAccountsForLoggedUser().subscribe(
				({ accounts }) => {
					if (accounts.length) {
						const accountsWithNumber: IDropdownItem[] = [];

						accounts.forEach((account) => {
							if (account) {
								accountsWithNumber.push({
									text: account.accountNum ?? '',
									id: account.accountId?.toString() ?? '',
									hint: translate(
										`plans.selected.number.${account.billingType}.${account.accountType}`
									),
									disabled: !!!(account.billingType === BillingType.PREPAID && account.accountNum),
								});
							}
						});
						accountsWithNumber.sort(function (x, y) {
							return x.disabled === y.disabled ? 0 : x.disabled ? 1 : -1;
						});
						setAvailableNumbers(accountsWithNumber);
					}
				},
				() => {}
			);
		}
	}, [isLogged]);

	const nextStep = () => {
		setCurrentIndex((current) => current + 1);
	};

	const chooseOtherNumber = () => {
		setChoosenNumber('');
		setCurrentIndex(0);
	};

	return (
		<PrepaidMigrationContext.Provider
			value={{
				availableNumbers,
				currentIndex,
				choosenNumber,
				setChoosenNumber,
				nextStep,
				availablePlans,
				chooseOtherNumber,
			}}
		>
			{children}
		</PrepaidMigrationContext.Provider>
	);
};

export default PrepaidMigrationContextProvider;
