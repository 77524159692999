import React, { FunctionComponent, useEffect, useState } from 'react';
import {
	ContactDetailsWrapper,
	TCContent,
	IconWrapper,
	AcceptTermsAndConditionsWrapper,
	AcceptEnhancedTermsAndConditionsWrapper,
	AcceptText,
	HighlightedText,
	HeaderWrapper,
	EnhancedTermsText,
	EnhancedTermsTitle,
	EnhancedTermsWrapper,
	EnhancedTermsTableWrapper,
	EnhancedTermsTextCenter,
	EnhancedTermsTitleCenter,
} from './TermsAndConditions.styled';
import { ValueBold } from '@Components/Typography/Typography.styled';
import useTranslate from '@Utils/hooks/useTranslate';
import { Col, Row, Hidden, Visible } from 'react-grid-system';
import { CircularCheckbox } from '@Components/controls/Checkbox/Checkbox';
import Icon from '@Components/Icon/Icon';
import Divider from '@Components/Divider/Divider';
import { BodyS } from '@Components/Typography/Typography';
import { useCart } from '@Utils/context/CartContext';
import ConditionalRender from '@Components/ConditionalRender/ConditionalRender';
import useScreen from '@Utils/hooks/useScreen';
import { getLang } from '@Utils/language/language';
import { enhancedTermAndCondition } from '@Services/enhancedTermAndCondition/enhancedTermAndCondition';

const URL =
	'https://www.omantel.om/Menu/Terms%26Conditions/!ut/p/z1/04_iUlDgAgL9CCADyEQmiOToR-UllmWmJ5Zk5ucl5uhH6EdGmcUbuBs4ejhZGPlYeBgaGzj6BLk4exmEGHv4Gel76UfhVxCcmqdfkB2oCABwxUX1/';

interface IEnhancedTermsWrapperCompProps {
	arrowRowText?: string;
	columnText1?: string;
	columnText2?: string;
}
interface IEnhancedTermsRenderProps {
	termsData: IEnhancedTerms;
	planDuration?: string;
}

interface IPlanTerminationTableItem {
	planTerminationCol1: string;
	planTerminationCol2: string;
	planTermination24MonthCol1: string;
}

interface IPlanDowngradeInstructions {
	[key: string]: string;
}

interface IEnhancedTerms {
	planTerminationEarlyDowngradeTerms?: string;
	planDowngradeInstructions?: [];
	planEarlyTermination12Month?: string;
	planEarlyTermination24Month?: string;
	planTerminationDowngradePeriod?: string;
	planFees?: string;
	planTerminationTable?: IPlanTerminationTableItem[];
	deviceCommitment?: string;
	deviceSubscribePlan?: string;
	deviceChangeMind?: string;
	deviceCancelSubscription?: string;
	deviceCancelSubscriptionInstructions?: [];
}
interface ITermsAndConditionsProps {
	params: {
		content: string;
		accepted: boolean;
	};
	emmitEvent: (event: string) => void;
}

const TermsAndConditionCheckbox: React.FC<{
	accepted: boolean;
	handleOnChange: () => void;
	TermsAndConditionsLabel: string;
	TermsAndConditionsLink: string;
}> = ({ accepted, handleOnChange, TermsAndConditionsLabel, TermsAndConditionsLink }) => (
	<>
		<CircularCheckbox active={!!accepted} onChange={handleOnChange} />
		<AcceptText>
			<BodyS>
				{TermsAndConditionsLabel}
				<HighlightedText onClick={() => window.open(URL, '_blank')}>{TermsAndConditionsLink}</HighlightedText>
			</BodyS>
		</AcceptText>
	</>
);

const EnhancedTermsWrapperComp: React.FC<IEnhancedTermsWrapperCompProps> = ({ arrowRowText = '' }) => (
	<EnhancedTermsWrapper>
		<IconWrapper>
			{getLang() === 'ar' ? (
				<div style={{ transform: 'rotate(180deg)' }}>
					<Icon name="playArrowIcon" width={8} height={16} />
				</div>
			) : (
				<Icon name="playArrowIcon" width={8} height={16} />
			)}
		</IconWrapper>
		<EnhancedTermsText>{arrowRowText}</EnhancedTermsText>
	</EnhancedTermsWrapper>
);

const EnhancedTermsTableWrapperComp: React.FC<IEnhancedTermsWrapperCompProps> = ({
	columnText1 = '',
	columnText2 = '',
}) => (
	<EnhancedTermsTableWrapper>
		<Col>
			<EnhancedTermsText>{columnText1}</EnhancedTermsText>
		</Col>
		<Col>
			<EnhancedTermsTextCenter>{columnText2}</EnhancedTermsTextCenter>
		</Col>
	</EnhancedTermsTableWrapper>
);

const EnhancedTermsTableTitleWrapperComp: React.FC<IEnhancedTermsWrapperCompProps> = ({
	columnText1 = '',
	columnText2 = '',
}) => (
	<EnhancedTermsTableWrapper>
		<Col>
			<EnhancedTermsTitle>{columnText1}</EnhancedTermsTitle>
		</Col>
		<Col>
			<EnhancedTermsTitleCenter>{columnText2}</EnhancedTermsTitleCenter>
		</Col>
	</EnhancedTermsTableWrapper>
);

const RenderEnhancedDeviceTerms: React.FC<IEnhancedTermsRenderProps> = ({ termsData }) => {
	const {
		deviceCommitment = '',
		deviceCancelSubscription = '',
		deviceCancelSubscriptionInstructions = [],
		deviceChangeMind = '',
		deviceSubscribePlan = '',
	} = termsData;
	return (
		<>
			<Divider marginBottom={24} withoutLine />
			<EnhancedTermsTitle>{deviceCommitment}</EnhancedTermsTitle>
			<Divider marginBottom={8} marginTop={7} />
			<EnhancedTermsText>{deviceSubscribePlan}</EnhancedTermsText>
			<Divider marginBottom={20} withoutLine />
			<EnhancedTermsTitle>{deviceChangeMind}</EnhancedTermsTitle>
			<Divider marginBottom={8} marginTop={7} />
			<EnhancedTermsText>{deviceCancelSubscription}</EnhancedTermsText>
			<Divider marginBottom={20} withoutLine />
			{deviceCancelSubscriptionInstructions.length > 0 &&
				deviceCancelSubscriptionInstructions.map((value, index) => (
					<React.Fragment key={index}>
						<EnhancedTermsWrapperComp arrowRowText={value} />
						<Divider marginBottom={12} withoutLine />
					</React.Fragment>
				))}
		</>
	);
};

const RenderEnhancedPlanTerms: React.FC<IEnhancedTermsRenderProps> = ({ termsData }) => {
	const {
		planTerminationEarlyDowngradeTerms = '',
		planDowngradeInstructions = [],
		planEarlyTermination12Month = '',
		planEarlyTermination24Month = '',
		planTerminationTable = [],
		planFees = '',
		planTerminationDowngradePeriod = '',
	} = termsData;

	const RenderPlanTerminationTable: React.FC<{ is12Month: boolean }> = ({ is12Month }) => {
		return (
			<>
				<Divider marginBottom={20} withoutLine />
				<EnhancedTermsTitle>
					{is12Month ? planEarlyTermination12Month : planEarlyTermination24Month}
				</EnhancedTermsTitle>
				<Divider marginBottom={4} marginTop={7} />
				<Hidden xs>
					<EnhancedTermsTableTitleWrapperComp
						columnText1={planTerminationDowngradePeriod}
						columnText2={planFees}
					/>
					<Divider marginBottom={16} withoutLine />
					{planTerminationTable?.length > 0 &&
						planTerminationTable?.map((row: IPlanTerminationTableItem, index: number) => (
							<React.Fragment key={index}>
								<EnhancedTermsTableWrapperComp
									columnText1={is12Month ? row?.planTerminationCol1 : row?.planTermination24MonthCol1}
									columnText2={row?.planTerminationCol2}
								/>
								{index < planTerminationTable.length - 1 && <Divider marginBottom={8} withoutLine />}
							</React.Fragment>
						))}
				</Hidden>
				<Visible xs>
					<EnhancedTermsTableWrapper>
						<div>
							<EnhancedTermsTitle>{planTerminationDowngradePeriod}</EnhancedTermsTitle>
						</div>
						<div>
							<EnhancedTermsTitleCenter>{planFees}</EnhancedTermsTitleCenter>
						</div>
					</EnhancedTermsTableWrapper>
					<Divider marginBottom={12} withoutLine />
					{planTerminationTable?.length > 0 &&
						planTerminationTable?.map((row: IPlanTerminationTableItem, index: number) => (
							<React.Fragment key={index}>
								<EnhancedTermsTableWrapper>
									<div>
										<EnhancedTermsText>
											{is12Month ? row?.planTerminationCol1 : row?.planTermination24MonthCol1}
										</EnhancedTermsText>
									</div>
									<div>
										<EnhancedTermsText>{row?.planTerminationCol2}</EnhancedTermsText>
									</div>
								</EnhancedTermsTableWrapper>
								{index < planTerminationTable?.length - 1 && <Divider marginBottom={8} withoutLine />}
							</React.Fragment>
						))}
				</Visible>
			</>
		);
	};

	return (
		<>
			<Divider marginBottom={24} withoutLine />
			<EnhancedTermsText>{planTerminationEarlyDowngradeTerms}</EnhancedTermsText>
			<Divider marginBottom={20} withoutLine />
			{planDowngradeInstructions.length > 0 &&
				planDowngradeInstructions.map((value, index) => (
					<React.Fragment key={index}>
						<EnhancedTermsWrapperComp arrowRowText={value} />
						<Divider marginBottom={12} withoutLine />
					</React.Fragment>
				))}
			<RenderPlanTerminationTable is12Month={true} />
			<RenderPlanTerminationTable is12Month={false} />
		</>
	);
};

const TermsAndConditions: FunctionComponent<ITermsAndConditionsProps> = ({
	params: { content, accepted },
	emmitEvent,
}) => {
	const { translate } = useTranslate();
	const { cart } = useCart();
	const [isEnhancedPlanTerms, setIsEnhancedPlanTerms] = useState<boolean>(false);
	const [isEnhancedDeviceTerms, setIsEnhancedDeviceTerms] = useState<boolean>(false);
	const [enhancedTermsData, setEnhancedTermsData] = useState<IEnhancedTerms | undefined>();
	const { screen } = useScreen();

	useEffect(() => {
		let foundInstallmentDuration = false;
		let foundPlanContractDuration = false;
		const DEF_ITEM_TYPES = ['ITEM', 'ITEM_HBB', 'ITEM_WFBB'];
		const PART_NUMBERS = ['BUY_HBB', 'BUY_WFBB'];

		/*
			Show the Device or Plan T&C based on the following conditions:
			1. For any mobile item with a BAQATI number in the cart:
				- `defType` should be one of 'ITEM', 'ITEM_HBB', 'ITEM_WFBB'
				- `installmentDuration` should be defined
				- Device Enahanced T&C will be visible.
			2. For any mobile item with HBB or WFBB in the cart:
				- `defType` should be one of 'ITEM', 'ITEM_HBB', 'ITEM_WFBB'
				- `planContractDuration` should be defined
				- `partNumber` should not be 'BUY_HBB' or 'BUY_WFBB'
				- Device Enahanced T&C will be visible.
			3. In the case of an HBB plan:
				- `PA` and `partNumber` should be 'BUY_HBB'
				- `planContractDuration` should be defined.
				- plan Enahanced T&c will be visible
			4. For accessories:
				- Enahanced T&C will not be visible.
		*/

		if (cart?.items) {
			for (const ele of cart?.items) {
				if (
					(ele?.installmentDuration && DEF_ITEM_TYPES.includes(ele?.defType as string)) ||
					(DEF_ITEM_TYPES.includes(ele?.defType as string) &&
						ele?.plan?.planContractDuration &&
						!PART_NUMBERS.includes(ele?.partNumber))
				) {
					foundInstallmentDuration = true;
					break;
				} else if (
					ele?.plan?.PA === 'BUY_HBB' &&
					ele?.partNumber === 'BUY_HBB' &&
					ele?.plan?.planContractDuration
				) {
					foundPlanContractDuration = true;
				}
			}
		}
		if (foundInstallmentDuration) {
			enhancedTermAndCondition.getEnahancedTermsAndCondition(getLang(), false).subscribe((response: any) => {
				setEnhancedTermsData(response.response.content);
				setIsEnhancedDeviceTerms(foundInstallmentDuration);
			});
		}
		if (!foundInstallmentDuration && foundPlanContractDuration) {
			enhancedTermAndCondition.getEnahancedTermsAndCondition(getLang(), true).subscribe((response: any) => {
				setEnhancedTermsData(response.response.content);
				setIsEnhancedPlanTerms(foundPlanContractDuration);
			});
		}
	}, [cart.items]);

	const handleOnChange = () => {
		emmitEvent && emmitEvent('accept');
	};

	return (
		<ContactDetailsWrapper>
			<Row>
				<Hidden xs>
					<IconWrapper>
						<Icon name="termsAndConditions" width={40} height={40} />
					</IconWrapper>
				</Hidden>
				<Col>
					<HeaderWrapper>
						<Visible xs>
							<IconWrapper>
								<Icon name="termsAndConditions" width={40} height={40} />
							</IconWrapper>
						</Visible>

						<div>
							<Visible xs>
								<Divider marginTop={8} withoutLine />
							</Visible>
							<ValueBold>{translate('terms.and.conditions.title')}</ValueBold>
							<Divider marginBottom={8} withoutLine />
							<TCContent>{content}</TCContent>
						</div>
					</HeaderWrapper>
					<ConditionalRender
						show={isEnhancedDeviceTerms}
						onTrue={() => {
							return <RenderEnhancedDeviceTerms termsData={enhancedTermsData as IEnhancedTerms} />;
						}}
					/>
					<ConditionalRender
						show={isEnhancedPlanTerms}
						onTrue={() => <RenderEnhancedPlanTerms termsData={enhancedTermsData as IEnhancedTerms} />}
					/>
					<Divider marginBottom={24} withoutLine />
					<ConditionalRender
						show={screen('xs')}
						onTrue={() => (
							<AcceptTermsAndConditionsWrapper>
								<TermsAndConditionCheckbox
									accepted={accepted}
									handleOnChange={handleOnChange}
									TermsAndConditionsLabel={translate('terms.and.conditions.text')}
									TermsAndConditionsLink={translate('terms.and.conditions.clickable')}
								/>
							</AcceptTermsAndConditionsWrapper>
						)}
						onFalse={() => (
							<Row>
								<AcceptEnhancedTermsAndConditionsWrapper>
									<TermsAndConditionCheckbox
										accepted={accepted}
										handleOnChange={handleOnChange}
										TermsAndConditionsLabel={translate('terms.and.conditions.text')}
										TermsAndConditionsLink={translate('terms.and.conditions.clickable')}
									/>
								</AcceptEnhancedTermsAndConditionsWrapper>
							</Row>
						)}
					/>
				</Col>
			</Row>
		</ContactDetailsWrapper>
	);
};

export default TermsAndConditions;
