import React, { FunctionComponent, useState, useEffect } from 'react';
import NavigationBar from '@Components/NavigationBar/NavigationBar';
import { DashboardWrapper } from './Dashboard.styled';
import { Route, Switch, Redirect } from 'react-router-dom';
import Store from '@Views/Store/Store';
import ProductPage from '@Views/ProductPage/ProductPage';
import CarInsurancePage from '@Views/CarInsurance/CarInsurance';
import BuySimRedirect from '@Views/BuySimRedirect/BuySimRedirect';
import { Container } from 'react-grid-system';
import Footer from '@Components/Footer/Footer';
import CustomBreadcrumbs from '@Components/CustomBreadcrumbs/CustomBreadcrumbs';
import CartMobile from '@Components/CartMobile/CartMobile';
import Divider from '@Components/Divider/Divider';
import { getLang } from '@Utils/language/language';
import ErrorView from '@Views/Error/Error';
import Map from '@Views/Map/Map';
import Card from '@Components/Card/Card';
import AppleBrandStore from '@Views/AppleBrandStore/AppleBrandStore';
import MicrosoftRegistration from '@Views/MicrosoftRegistration/MicrosoftRegistration';
import InusranceContextProvider from '@Utils/context/InsuranceContext';
import ConditionalRender from '@Components/ConditionalRender/ConditionalRender';
import useAppleBrand from '@Utils/hooks/useAppleBrand';
import { isLargeScreen, matchScreen } from '@Utils/layout/layoutSupport';
import { isIFrameAppleWatch } from '@Config/app.config';
export const NAVBAR_HEIGHT = 100;
export const SMALL_NAVBAR_HEIGHT = 88;
export const MOBILE_NAVBAR_HEIGHT = 56;
export const NAVBAR_HEIGHT_WITH_PADDING = NAVBAR_HEIGHT + 16;
import useMicrosoftAutomationProcess from '@Utils/hooks/useMicrosoftAutomationProcess';
import MicrosoftError from '@Views/MicrosoftError/MicrosoftError';
import BuyNowPayLater from '@Views/BuyNowPayLater/BuyNowPayLater';

const Dashboard: FunctionComponent = () => {
	const [footerHeight, setFooterHeight] = useState<number>(0);
	const { isAppleUrl } = useAppleBrand();
	const isSmallDevice = matchScreen(['xs', 'sm']);
	const { isMicrosoftAutomationProcess } = useMicrosoftAutomationProcess();
	const isCustomBreadcrumbsHided =
		window.location.href.toLocaleLowerCase().includes('microsoft-automation-services') &&
		isMicrosoftAutomationProcess;
	const pathname = window.location.href.toLocaleLowerCase();
	const isBuyNowPayLaterUrl = pathname.includes('buy-now-pay-later');

	useEffect(() => {
		setTimeout(() => {
			window.scrollTo({ top: 0 });
		}, 500);
	});

	return (
		<DashboardWrapper footerHeight={footerHeight}>
			<ConditionalRender
				show={!isIFrameAppleWatch}
				onTrue={() => (
					<>
						<NavigationBar />
						<ConditionalRender
							show={isAppleUrl()}
							onTrue={() => <Card<types.cards.IAppleMenu> card="appleMenu" withoutContainerPadding />}
							onFalse={() => (
								<Divider
									marginBottom={
										NAVBAR_HEIGHT_WITH_PADDING - (isLargeScreen() ? 0 : isSmallDevice ? 40 : 12)
									}
								/>
							)}
						/>
						<ConditionalRender
							show={!isCustomBreadcrumbsHided && !isBuyNowPayLaterUrl}
							onTrue={() => (
								<Container>
									<CustomBreadcrumbs />
								</Container>
							)}
						/>
					</>
				)}
			/>
			<Switch>
				<Route path={['/:lang/store', '/store']}>
					<Store />
				</Route>
				<Route path={['/:lang/apple-brand-store', '/apple-brand-store']}>
					<AppleBrandStore />
				</Route>
				<Route path={['/:lang/microsoft-automation-services', '/microsoft-automation-services']}>
					<MicrosoftRegistration />
				</Route>
				<Route path={['/:lang/microsoft-error', '/microsoft-error']}>
					<MicrosoftError />
				</Route>
				<Route
					path={[
						'/product/car-insurance',
						'/:lang/product/car-insurance',
						'/:lang/product/car-insurance/:seo',
					]}
				>
					<InusranceContextProvider>
						<CarInsurancePage />
					</InusranceContextProvider>
				</Route>
				<Route path={['/:lang/product/buysim', '/product/buysim']}>
					<BuySimRedirect />
				</Route>
				<Route path={['/product/:productId/', '/:lang/product/:productId/', '/:lang/product/:productId/:seo/']}>
					<ProductPage />
				</Route>
				<Route path={['/map', '/:lang/map']}>
					<Map />
				</Route>
				<Route path={[`/${getLang()}/buy-now-pay-later`, `/${getLang()}/buy-now-pay-later-redirect`]}>
					<BuyNowPayLater />
				</Route>
				<Route path="/error">
					<ErrorView />
				</Route>
				<Route>
					<Redirect to={`/${getLang()}/store`} />
				</Route>
			</Switch>
			<ConditionalRender
				show={!isIFrameAppleWatch}
				onTrue={() => (
					<>
						<Footer setFooterHeight={setFooterHeight} />
						<CartMobile />
					</>
				)}
			/>
		</DashboardWrapper>
	);
};

export default Dashboard;
