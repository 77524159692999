import * as Yup from 'yup';
import useTranslate from '@Utils/hooks/useTranslate';

export default (): Yup.AnyObjectSchema => {
	const { translate } = useTranslate();

	const shape = {
		makasibPoints: Yup.string().matches(/^[0-9]*\.?[0-9]*$/, translate('cart.makasib.validator.number.error')),
	};

	return Yup.object().shape(shape);
};
