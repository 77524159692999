import styled from 'styled-components';
import { BodyM, BodySHigher } from '@Components/Typography/Typography';
import { Col, Row } from 'react-grid-system';
import { CLOSE_BUTTON_SIZE } from '@Components/MegaMenu/MegaMenu';
import { marginLeft, matchScreen, paddingRight } from '@Utils/layout/layoutSupport';

export const Container = styled.div`
	padding: 32px 0;
	display: flex;
	flex-direction: column;
	${() => ({
		height: `calc(100% - 64px - 16px - ${CLOSE_BUTTON_SIZE}px)`,
	})}
	width: 100%;
`;

export const ResultsContainer = styled.div<{ isMdXl?: boolean }>`
	display: flex;
	height: 100%;
	overflow-y: auto;
	overflow-x: hidden;
	${(isMdXl) => ({
		maxHeight: isMdXl ? '540px' : '400px',
	})}
`;

export const ResultsWrapper = styled(Col)`
	display: flex;
	gap: 0px;
	flex-direction: column;
	word-wrap: break-word;
`;

export const EmptyStateWrapper = styled(Col)`
	display: flex;
	flex-direction: column;
`;

export const ResultsDescription = styled(BodyM)`
	${({ theme }) => ({
		color: theme.colors.black87,
	})}
`;

export const ContentWrapper = styled(Row)`
	padding: 0;
	width: 100%;
	margin: 0px !important;
	align-content: flex-start;
	${() => {
		return { gap: matchScreen(['xs', 'sm']) ? '15px' : '0px' };
	}}
`;

export const PopularProductsParentWrapper = styled(Col)`
	padding: 0 !important;
	justify-text: left;
	align-text: left;
`;

export const PopularProductsWrapper = styled(Row)``;

export const PopularProductsColWrapper = styled(Col)<{ isXs: boolean; height?: number; header?: boolean }>`
	${({ header }) => {
		if (header) {
			return {
				display: 'flex',
				alignItems: 'center',
			};
		}
	}}
	${({ isXs, height }) => ({
		padding: isXs ? '0 15px' : '0 11px !important',
		height,
	})}
`;

export const SearchButtonsWrapper = styled.div<{ isColumn?: boolean }>`
	display: flex;
	background-color: #f0f0f0;
	height: 36px;
	padding: 2px;
	gap: 2px;
	align-items: center;
	margin-bottom: 24px;
	border-radius: 4px;
	${({ isColumn }) => marginLeft(isColumn ? '0px' : '12px')}
	${({ isColumn }) => ({
		marginTop: isColumn ? '18px' : '0px;',
	})}
`;

export const SearchButton = styled(BodySHigher)<{ isActive?: boolean }>`
	width: 50%;
	height: 36px;
	border-radius: 4px;
	${({ isActive, theme }) => ({
		backgroundColor: isActive ? theme.colors.white : '#F0F0F0',
		color: isActive ? theme.colors.primary : '#000B25',
		cursor: isActive ? 'unset' : 'pointer',
	})}
	&:hover {
		background-color: #fcf5eb;
	}
	display: flex;
	align-items: center;
	justify-content: center;
`;

export const SearchSwitchItemWrapper = styled.div<{ isColumn?: boolean; isMD?: boolean }>`
	${({ isColumn, isMD }) => paddingRight(isColumn || isMD ? '0px' : '12px')}
`;

export const SearchSwitchContainer = styled.div<{ isColumn?: boolean; isMD?: boolean }>`
	display: flex;
	${({ isColumn }) => ({
		flexDirection: isColumn ? 'column' : 'row',
	})}
`;
