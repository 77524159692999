import { IAccount } from '@ApiModels/accounts';
import { ISinglePackage } from '@ApiModels/singlePackage';
import { IUserProfile } from '@ApiModels/userProfile';
import Divider from '@Components/Divider/Divider';
import Icon from '@Components/Icon/Icon';
import { HBB_PRODUCT_ID, WFBB_PRODUCT_ID } from '@Config/app.config';
import { updateBreadcrumb } from '@Redux/modules/breadcrumbs/actions';
import { addBusyIndicator, removeBusyIndicator } from '@Redux/modules/busyIndicator/actions';
import { modalTypes, setModal } from '@Redux/modules/modal/actions';
import { setPersistentCheckoutDetails } from '@Redux/modules/persistentCheckoutData/actions';
import { dispatch } from '@Redux/store';
import { AccountsService } from '@Services/accounts/accountsService';
import { IAddItemsToCartPayload, OrderFlowService } from '@Services/orderFlow/orderFlow';
import PackagesService from '@Services/packages/packages';
import { ProfileService } from '@Services/profile/profileService';
import { errors } from '@Utils/api/errors/errors';
import { useCart } from '@Utils/context/CartContext';
import customerDetailsFromGIS, { getType, IExtractedGISData } from '@Utils/converters/customerDetailsFromGIS';
import useTranslate from '@Utils/hooks/useTranslate';
import { getLang } from '@Utils/language/language';
import { IChangeAddress } from '@Views/Plans/Plans';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { Container } from 'react-grid-system';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { Description, DescWrapper, OmantelMap, Subtitle, Title } from './Map.styled';
import { DISABLE_HBB_WFBB_PROCESS, DISABLE_WFBB } from '@Config/features.config';

const ADD_TO_CART_MAP_BI = 'ADD_TO_CART_MAP_BI';
const GET_PACKAGES_BY_TYPE_BI = 'GET_PACKAGES_BY_TYPE_BI';
const GET_PACKAGES_BY_TYPE_WFBB_BI = 'GET_PACKAGES_BY_TYPE_WFBB_BI';
const GET_USER_PROFILE_MAP_BI = 'GET_USER_PROFILE_MAP_BI';
const GET_ACCOUNT_FOR_LOGGED_USER = 'GET_ACCOUNT_FOR_LOGGED_USER';
export const BUY_HBB = 'BUY_HBB';
export const BUY_WFBB = 'BUY_WFBB';
const HBB_NETWORK_TYPE = ['GPON', 'OBC'];
const REQUEST_TYPE = 'New PSTN + Data';

const Map: FunctionComponent = () => {
	const [plansToBuy, setPlansToBuy] = useState<{ hbbPackages: ISinglePackage[]; wfbbPackages: ISinglePackage[] }>({
		hbbPackages: [],
		wfbbPackages: [],
	});
	const [selectedPlan, setSelectedPlan] = useState<ISinglePackage>();
	const [userProfile, setUserProfile] = useState<IUserProfile>();
	const [userProfileChecked, setUserProfileChecked] = useState<boolean>(false);
	const [currentAccount, setCurrentAccount] = useState<IAccount>();
	const [detailsFromGIS, setDetailsFromGIS] = useState<{
		rawData: string;
		extractedData: IExtractedGISData;
	}>();
	const isLogged = useSelector((state: types.redux.IState) => state.api.authentication.signedIn);
	const hbbWfbbProcessCheckout = useSelector(
		(state: types.redux.IState) => state.persistentCheckout.hbbWfbbProcessCheckout
	);
	const allowedAccountCategories = useSelector((state: types.redux.IState) => state.settings.config.accountCategory);
	const { translate } = useTranslate();
	const history = useHistory();
	const { search } = useLocation();
	const query = new URLSearchParams(search);
	const productId = query.get('product');
	const { cart } = useCart();
	const location = useLocation<{ editAddress: boolean }>();
	const editAddress = !!location.state?.editAddress;
	const allPlans = [...plansToBuy.hbbPackages, ...plansToBuy.wfbbPackages];
	const selectedPlanIsWfbb = ['TARIFF_WFBB', 'TARIFF_WFBB_5G'].includes(selectedPlan?.wfbbPlanType || '');

	useEffect(() => {
		if (selectedPlanIsWfbb && DISABLE_WFBB) {
			history.replace(`/${getLang()}/store`);
		}
	}, [selectedPlanIsWfbb]);

	useEffect(() => {
		if (DISABLE_HBB_WFBB_PROCESS) {
			history.replace(`/${getLang()}/store`);
		}
	}, []);

	useEffect(() => {
		if (isLogged) {
			dispatch(addBusyIndicator(GET_ACCOUNT_FOR_LOGGED_USER));
			AccountsService.getAccountsForLoggedUser().subscribe(
				(response) => {
					setCurrentAccount(response.accounts.find((acc) => acc.isMain));
					dispatch(removeBusyIndicator(GET_ACCOUNT_FOR_LOGGED_USER));
				},
				() => {
					dispatch(removeBusyIndicator(GET_ACCOUNT_FOR_LOGGED_USER));
				}
			);
		}
	}, [isLogged]);

	useEffect(() => {
		if (isLogged && allowedAccountCategories && currentAccount?.accountCategory) {
			const userIsBusiness = !allowedAccountCategories.includes(currentAccount?.accountCategory || '');
			if (userIsBusiness) {
				history.replace(`/${getLang()}/store`);
			}
		}
	}, [isLogged, allowedAccountCategories, currentAccount]);

	useEffect(() => {
		checkProperRoute();
	}, [cart]);

	const checkProperRoute = () => {
		const includesHBBWFBBItem = cart.items.find((item) => [BUY_HBB, BUY_WFBB].includes(item.plan?.PA || ''));
		if (includesHBBWFBBItem && !editAddress) {
			history.push(`/${getLang()}/cart`);
		}
		if (!productId && !editAddress) {
			history.replace(`/${getLang()}/store`);
		}
	};

	useEffect(() => {
		let mapBreadcrumbs: { link: string; label: string }[];
		if (allPlans.find((commercePlan) => commercePlan.partNumber === productId)?.name) {
			mapBreadcrumbs = [
				{
					link: `/map`,
					label: allPlans.find((commercePlan) => commercePlan.partNumber === productId)?.name || '',
				},
				{
					link: `/map`,
					label: translate('map.breadcrubs.map'),
				},
			];
		} else {
			mapBreadcrumbs = [
				{
					link: `/map`,
					label: translate('map.breadcrubs.map'),
				},
			];
		}
		dispatch(updateBreadcrumb(mapBreadcrumbs));
	}, [plansToBuy, editAddress]);

	useEffect(() => {
		if (allPlans.length) {
			const selectedPlan = allPlans.find((commercePlan) => commercePlan.partNumber === productId);
			setSelectedPlan(selectedPlan);
			if (!selectedPlan && !editAddress) {
				history.replace(`/${getLang()}/store`);
			}
		}
	}, [plansToBuy, editAddress]);

	useEffect(() => {
		const getPackages = async () => {
			const hbbPackages = await getHbbPackages();
			const wfbbPackages = await getWfbbPackages();
			if (hbbPackages && wfbbPackages) {
				setPlansToBuy({ hbbPackages, wfbbPackages });
			}
		};
		getPackages().catch(() => {});
	}, []);

	const getHbbPackages = async (): Promise<ISinglePackage[] | null> => {
		dispatch(addBusyIndicator(GET_PACKAGES_BY_TYPE_BI));
		return new Promise((resolve, reject) => {
			PackagesService.getPackagesByGroupAndType('PLAN', 'HBB').subscribe(
				(response) => {
					if (response?.packages && response?.hbbPlansToSubscribe) {
						resolve(response.hbbPlansToSubscribe);
					} else if (!response?.hbbPlansToSubscribe && !editAddress) {
						history.replace(`/${getLang()}/store`);
						reject(null);
					} else {
						reject(null);
					}
					dispatch(removeBusyIndicator(GET_PACKAGES_BY_TYPE_BI));
				},
				(e) => {
					reject(null);
					dispatch(removeBusyIndicator(GET_PACKAGES_BY_TYPE_BI));
				}
			);
		});
	};

	const getWfbbPackages = async (): Promise<ISinglePackage[] | null> => {
		dispatch(addBusyIndicator(GET_PACKAGES_BY_TYPE_WFBB_BI));
		return new Promise((resolve, reject) => {
			PackagesService.getPackagesByGroupAndType('PLAN', 'WFBB').subscribe(
				(response) => {
					if (response?.selfActivatePackages) {
						resolve(response.selfActivatePackages);
					} else {
						reject(null);
					}
					dispatch(removeBusyIndicator(GET_PACKAGES_BY_TYPE_WFBB_BI));
				},
				(e) => {
					reject(null);
					dispatch(removeBusyIndicator(GET_PACKAGES_BY_TYPE_WFBB_BI));
				}
			);
		});
	};

	useEffect(() => {
		dispatch(addBusyIndicator(GET_USER_PROFILE_MAP_BI));
		ProfileService.getUserProfile().subscribe(
			(response) => {
				setUserProfile(response);
				dispatch(removeBusyIndicator(GET_USER_PROFILE_MAP_BI));
				setUserProfileChecked(true);
			},
			(e) => {
				dispatch(removeBusyIndicator(GET_USER_PROFILE_MAP_BI));
				setUserProfileChecked(true);
			}
		);
	}, []);

	useEffect(() => {
		if (typeof window.addEventListener != 'undefined') {
			const listenerFunction = function (e: any) {
				if (typeof e.data != 'undefined') {
					if (typeof e.data.message != 'undefined') {
						if (e.data.message) {
							const extractedData = customerDetailsFromGIS(e.data.message);
							if (extractedData) {
								const data = { rawData: e.data.message, extractedData };
								setDetailsFromGIS(data);
							}
						}
					}
				}
			};
			window.addEventListener('message', listenerFunction);
			return () => {
				window.removeEventListener('message', listenerFunction);
			};
		}
	}, []);

	useEffect(() => {
		if (detailsFromGIS && selectedPlan && userProfileChecked && allPlans.length && !editAddress) {
			const { isHBBAvailable, wfbbAvailable } = checkPlanAvailability({
				detailsFromGIS: detailsFromGIS?.extractedData,
				planToCheck: selectedPlan,
			});

			const plansAvailableInSelectedRegion = plansToBuy.hbbPackages?.filter(
				(plan) =>
					plan.partNumber !== selectedPlan.partNumber &&
					checkPlanAvailability({
						detailsFromGIS: detailsFromGIS?.extractedData,
						planToCheck: plan,
					}).isHBBAvailable
			);
			const wfbbPlansAvailableInSelectedRegion = plansToBuy.wfbbPackages?.filter(
				(plan) =>
					checkPlanAvailability({
						detailsFromGIS: detailsFromGIS?.extractedData,
						planToCheck: plan,
					}).wfbbAvailable
			);

			const addToRedux = ({ withPlan }: { withPlan: boolean }) => {
				const wfbbParams = selectedPlanIsWfbb
					? {
							regionId: detailsFromGIS.extractedData.governorate,
							email: userProfile && userProfile.email,
							operationContext: 'WFBB',
							wfbbType: getType(detailsFromGIS.extractedData),
							productIds: [selectedPlan.partNumber],
							gender: '',
							language: userProfile && userProfile.preferredLanguage,
					  }
					: {};

				const hbbWfbbProcessCheckout = {
					plansSelectedStr: withPlan ? selectedPlan?.name || selectedPlan?.shortDescription || '' : '',
					firstName: userProfile && userProfile.lastName,
					latitude: detailsFromGIS.extractedData.lat,
					longitude: detailsFromGIS.extractedData.long,
					// hbb below
					requestChannel: 'CICM',
					staffRemarks: '',
					operationContext: 'HBB',
					networkType: detailsFromGIS.extractedData.networkType,
					region: detailsFromGIS.extractedData.governorate,
					bandwidth: withPlan ? String(selectedPlan.planDetails?.downloadSpeed?.itemLabel || '') : '',
					cabinet: detailsFromGIS.extractedData.cabinetStr,
					contactNumber1: '',
					contactNumber2: '',
					dp: detailsFromGIS.extractedData.dp,
					emailId: userProfile && userProfile.email,
					exe: detailsFromGIS.extractedData.exchange,
					swatSOSStatus: detailsFromGIS.extractedData.town,
					gisDetails: detailsFromGIS.rawData,
					plansSelected: withPlan
						? `${selectedPlan.provisioningID},${selectedPlan.partNumber},${selectedPlan.planCommitment}`
						: '',
					willyat: detailsFromGIS.extractedData.wilayat,
					wayNumber: detailsFromGIS.extractedData.wayNo,
					buildingNumber: detailsFromGIS.extractedData.buildingNo,
					customerName: userProfile && userProfile.lastName,
					requestType: REQUEST_TYPE,
					partNumber: selectedPlan.partNumber,
					...wfbbParams,
				};

				dispatch(
					setPersistentCheckoutDetails({
						hbbWfbbProcessCheckout,
					})
				);
			};
			if (isHBBAvailable || wfbbAvailable) {
				handleAddToCart();
				addToRedux({ withPlan: true });
			} else if (wfbbPlansAvailableInSelectedRegion.length || plansAvailableInSelectedRegion.length) {
				addToRedux({ withPlan: false });
				history.push(`/${getLang()}/plans`, {
					plans: plansAvailableInSelectedRegion,
					additionalPlans: wfbbPlansAvailableInSelectedRegion,
					userProfile,
					checkedPlan: selectedPlan,
				});
			} else {
				addToRedux({ withPlan: false });
				history.push(`/${getLang()}/contact`, {
					wfbbAvailable,
					selectedWfbbPlan: selectedPlan,
				});
			}
		}
	}, [detailsFromGIS, selectedPlan, userProfile, userProfileChecked, plansToBuy, plansToBuy, editAddress]);

	useEffect(() => {
		if (editAddress && detailsFromGIS) {
			const selectedPlanInRedux = plansToBuy.hbbPackages?.find(
				(plan) => plan.partNumber === hbbWfbbProcessCheckout?.partNumber
			);
			if (selectedPlanInRedux) {
				const { isHBBAvailable } = checkPlanAvailability({
					detailsFromGIS: detailsFromGIS?.extractedData,
					planToCheck: selectedPlanInRedux,
				});

				const plansAvailableInSelectedRegion = plansToBuy.hbbPackages?.filter(
					(plan) =>
						plan.partNumber !== selectedPlanInRedux.partNumber &&
						checkPlanAvailability({
							detailsFromGIS: detailsFromGIS?.extractedData,
							planToCheck: plan,
						}).isHBBAvailable
				);

				const changedAddressOnEditProcess: IChangeAddress = {
					networkType: detailsFromGIS.extractedData.networkType,
					region: detailsFromGIS.extractedData.governorate,
					cabinet: detailsFromGIS.extractedData.cabinetStr,
					dp: detailsFromGIS.extractedData.dp,
					exe: detailsFromGIS.extractedData.exchange,
					swatSOSStatus: detailsFromGIS.extractedData.town,
					gisDetails: detailsFromGIS.rawData,
					latitude: detailsFromGIS.extractedData.lat,
					longitude: detailsFromGIS.extractedData.long,
					willyat: detailsFromGIS.extractedData.wilayat,
					wayNumber: detailsFromGIS.extractedData.wayNo,
					buildingNumber: detailsFromGIS.extractedData.buildingNo,
				};

				const addToRedux = () => {
					dispatch(
						setPersistentCheckoutDetails({
							hbbWfbbProcessCheckout: changedAddressOnEditProcess,
						})
					);
				};

				if (isHBBAvailable) {
					addToRedux();
					history.replace(`${getLang()}/checkout/2`);
				} else if (plansAvailableInSelectedRegion?.length) {
					history.push(`${getLang()}/plans`, {
						plans: plansAvailableInSelectedRegion,
						userProfile,
						changedAddressOnEditProcess,
					});
				} else {
					history.push(`${getLang()}/contact`, {
						selectedWfbbPlan: selectedPlanInRedux,
					});
				}
			}
		}
	}, [editAddress, detailsFromGIS, userProfile, userProfileChecked, plansToBuy]);

	const checkPlanAvailability = ({
		detailsFromGIS,
		planToCheck,
	}: {
		detailsFromGIS: IExtractedGISData;
		planToCheck: ISinglePackage;
	}) => {
		const isHBBAvailableOnMap = HBB_NETWORK_TYPE.includes(detailsFromGIS.networkType || '');
		const isWFBBIndoorAvailableOnMap = detailsFromGIS.itdd5G;
		const isWFBBOutdoorAvailableOnMap = detailsFromGIS.otdd5G;
		const isHBBAvailable = isHBBAvailableOnMap && planToCheck.planType === 'HBB';
		const wfbbAvailable =
			!isWFBBIndoorAvailableOnMap &&
			isWFBBOutdoorAvailableOnMap &&
			['5G', '4G5G'].includes(planToCheck.bandWidthType || '');

		return {
			isHBBAvailable,
			wfbbAvailable,
		};
	};

	const handleAddToCart = () => {
		if (selectedPlan) {
			dispatch(addBusyIndicator(ADD_TO_CART_MAP_BI));

			let payload: IAddItemsToCartPayload = {
				quantity: '1',
				productId: String(selectedPlanIsWfbb ? WFBB_PRODUCT_ID : HBB_PRODUCT_ID),
				xitem_planAction: selectedPlanIsWfbb ? BUY_WFBB : BUY_HBB,
				xitem_productId: selectedPlan.partNumber,
				productName: selectedPlan ? selectedPlan?.name : '',
				payment_plan: translate('product.details.plan.device.only'),
			};

			if (isLogged) {
				const preparedLoginNumber = userProfile?.uid.replace(/[^0-9]+/g, '');
				payload = { ...payload, xitem_login: preparedLoginNumber };
			}

			OrderFlowService.addItemsToCart([payload]).subscribe(
				({ response }) => {
					dispatch(removeBusyIndicator(ADD_TO_CART_MAP_BI));
					dispatch(
						setModal({
							type: modalTypes.ADDED_TO_CART,
							data: { orderId: response.orderItem[0].orderItemId },
							withoutContainer: true,
						})
					);
				},
				(e) => {
					dispatch(removeBusyIndicator(ADD_TO_CART_MAP_BI));
					if (e.response?.errors?.length > 0) {
						if (e.response?.errors[0]?.errorKey === errors.SESSION_GENERIC_USER) {
							dispatch(
								setModal({
									type: modalTypes.LOGIN_SESSION_EXPIRED,
									data: {},
								})
							);
						}
					}
				}
			);
		}
	};

	const getGISMap = () => {
		return getLang() === 'ar' ? '/customer_order_arabic_eshop/' : '/customer_order_english_eshop/';
	};

	return (
		<Container>
			<Title>{translate('map.view.title')}</Title>
			<Subtitle>{translate('map.view.subtitle')}</Subtitle>
			<DescWrapper>
				<Icon name="omantelMapPoint" height={16} width={16} />
				<Description>{translate('map.view.description')}</Description>
			</DescWrapper>
			<OmantelMap src={'https://www.omantel.om/gis' + getGISMap()} frameBorder="0" />
			<Divider withoutLine marginBottom={32} />
		</Container>
	);
};

export default Map;
