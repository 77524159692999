import { ParagraphMBold, TitleBold } from '@Components/Typography/Typography.styled';
import { marginRight } from '@Utils/layout/layoutSupport';
import styled from 'styled-components';

export const UpSellProductsContainer = styled.div`
	display: flex;
	align-items: center;
`;

export const UpSellProductsWrapper = styled.div`
	display: flex;
	flex: 1 1 0px;
`;

export const UpSellCartCardWrapper = styled.div`
	display: flex;
	flex-direction: column;
`;

export const SubtitleWrapper = styled(ParagraphMBold)`
	${({ theme }) => ({
		color: theme.colors.black87,
	})};
	${() => marginRight(16)}
`;

export const TitleWrapper = styled(TitleBold)`
	${({ theme }) => ({
		color: theme.colors.black87,
	})};
	padding: 24px 16px;
`;

export const TitleContainer = styled.div`
	${() => ({
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		marginBottom: '28px',
	})}
`;

export const ButtonsContainer = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
`;

export const IconWrapper = styled.div`
	width: 32px;
	height: 32px;
	${({ theme }) => ({
		border: `1px solid ${theme.colors.black24}`,
		backgroundColor: theme.colors.white,
	})}
	border-radius: 8px;
	display: flex;
	align-items: center;
	justify-content: center;
`;
