import React, { FunctionComponent } from 'react';
import ConditionalRender from '@Components/ConditionalRender/ConditionalRender';
import { BigCurrency, BodyS, BodySBold, Caption, CaptionBold, DisplayMBold } from '@Components/Typography/Typography';
import useTranslate from '@Utils/hooks/useTranslate';
import {
	LeftPanel,
	PlanContainer,
	MidllePanel,
	MOPanel,
	OmrPanel,
	PlanBarContainer,
	PricePanel,
	PricePanelLeft,
	PricePanelRight,
	RightPanel,
	RightPanelCard,
	SpacePanel,
	SaveContainer,
	Internet,
	Minutes,
	InternetAndMinutes,
	Badge,
	BadgeTriangle,
	BadgeRibbon,
	Title,
	IconWrapper,
	VatBarWrapper,
	PriceWithVatRight,
} from './PlanBar.styled';
import { PlanBarType } from './PlanBarType';
import { Moment } from 'moment';
import Icon from '@Components/Icon/Icon';
import PriceWithVat from '@Components/PriceWithVat/PriceWithVat';
import PriceTag from '@Components/PriceTag/PriceTag';
import { roundMoney } from '@Utils/converters/roundMoney';

export interface IPlanBar {
	barType: PlanBarType;
	plan: {
		name?: string;
		validity: string;
		price?: number | string;
		internet?: number | string;
		minutes?: number | string;
		expireDate: string | Moment;
		promotion?: {
			price?: number | string;
			/** Promotion panel under PlanBar. */
			promotion?: boolean;
			/** Promotion badge */
			badge?: boolean;
		};
		currentPlan?: boolean;
		vat: number;
		vatPercent: number;
		hasCommitment: boolean;
		recommendedPlan?: boolean;
		isHbb?: boolean;
	};
	marginTop?: number;
	marginBottom?: number;
	onClick?: () => void;
	active?: boolean;
	alUfuq?: boolean;
	width?: string;
}
const UNLIMITED_SIZE = 24;
const PlanBar: FunctionComponent<IPlanBar> = ({
	barType,
	plan,
	marginTop = 16,
	marginBottom = 16,
	onClick,
	active,
	alUfuq,
	width,
}) => {
	const { translate } = useTranslate();

	const minutesUnlimited = plan?.minutes === 'Unlimited';
	const internetUnlimited = plan?.internet === 'Unlimited';
	const isHbb = plan?.isHbb;

	const getFill = () => {
		if (barType === PlanBarType.BAQATI_INACTIVE) {
			return 'support2';
		} else if (active === undefined) {
			return 'white';
		} else if (alUfuq && active) {
			return 'white';
		} else if (alUfuq && !active) {
			return 'black';
		} else if (!alUfuq && active) {
			return 'white';
		} else if (isHbb) {
			return 'support1';
		} else if (!alUfuq && !active) {
			return 'support2';
		}
	};
	const planMinutes = String(plan?.internet || '');
	const disableValueInternet = planMinutes.includes('GB') || planMinutes.includes('MB') || planMinutes.includes('TB');
	const disableValueMinutes = String(plan?.minutes || '').includes('min');

	return (
		<PlanBarContainer marginTop={marginTop} marginBottom={marginBottom} onClick={onClick} width={width}>
			<PlanContainer barType={barType} saveActive={plan?.promotion?.promotion} width={width}>
				<LeftPanel>
					<Title color="inherit">{plan?.name}</Title>
					<Caption color="inherit">
						{plan?.validity && plan.hasCommitment ? plan?.validity : translate('plan-bar.no.commitment')}
					</Caption>
					<SpacePanel></SpacePanel>
					<InternetAndMinutes>
						<Internet>
							<ConditionalRender
								show={internetUnlimited}
								onTrue={() => (
									<IconWrapper>
										<Icon
											name="unlimited"
											width={UNLIMITED_SIZE}
											height={UNLIMITED_SIZE}
											fill={getFill()}
										/>
									</IconWrapper>
								)}
								onFalse={() => <BodyS color="inherit">{plan?.internet}</BodyS>}
							/>
							{plan?.internet && !disableValueInternet && (
								<BodyS color="inherit">{translate('plan.internet')}</BodyS>
							)}
						</Internet>
						<Minutes>
							<ConditionalRender
								show={minutesUnlimited}
								onTrue={() => (
									<>
										<IconWrapper>
											<Icon
												name="unlimited"
												width={UNLIMITED_SIZE}
												height={UNLIMITED_SIZE}
												fill={getFill()}
											/>
										</IconWrapper>
									</>
								)}
								onFalse={() => <BodyS color="inherit">{plan?.minutes}</BodyS>}
							/>
							{plan?.minutes && !disableValueMinutes && (
								<BodyS color="inherit">{translate('plan.minutes')}</BodyS>
							)}
						</Minutes>
					</InternetAndMinutes>
				</LeftPanel>
				<RightPanelCard>
					<ConditionalRender
						show={!!plan?.vat}
						onTrue={() => (
							<VatBarWrapper>
								<PricePanel>
									<PriceTag price={plan.price} duration={translate('plan-bar.mo')} color="inherit" />
								</PricePanel>
								<PriceWithVatRight>
									<PriceWithVat
										vatColor="inherit"
										pricesWithVat={{
											offerPrice: String(plan.vat),
											listPrice: '',
											taxablePrice: '',
											vatValue: String(plan.vat),
											vatPercent: String(plan.vatPercent),
										}}
									/>
								</PriceWithVatRight>
							</VatBarWrapper>
						)}
						onFalse={() => (
							<PricePanel>
								<PricePanelLeft>
									<DisplayMBold color="inherit">{roundMoney(plan?.price)}</DisplayMBold>
								</PricePanelLeft>
								<MidllePanel></MidllePanel>
								<PricePanelRight>
									<OmrPanel>
										<BigCurrency color="inherit">{translate('omr')}</BigCurrency>
									</OmrPanel>
									<MOPanel>
										<Caption color="inherit">{translate('plan-bar.mo')}</Caption>
									</MOPanel>
								</PricePanelRight>
							</PricePanel>
						)}
					/>
					<ConditionalRender
						show={!!plan?.promotion?.badge && !!plan?.promotion?.price}
						onTrue={() => (
							<Badge>
								<BadgeRibbon>
									<CaptionBold color="white">
										{translate('plan-bar.badge', plan?.promotion?.price?.toString() ?? '0')}
									</CaptionBold>
								</BadgeRibbon>
								<BadgeTriangle></BadgeTriangle>
							</Badge>
						)}
						onFalse={() => (
							<>
								<ConditionalRender
									show={!!plan?.currentPlan || (!plan?.validity && !plan.hasCommitment)}
									onTrue={() => (
										<Caption color="inherit">{translate('plan-bar.current.plan')}</Caption>
									)}
								/>
								<ConditionalRender
									show={
										!!plan?.recommendedPlan &&
										!plan?.currentPlan &&
										!!plan?.validity &&
										!!plan.hasCommitment
									}
									onTrue={() => (
										<Caption color="inherit">{translate('plan-bar.recommended.plan')}</Caption>
									)}
								/>
								<ConditionalRender
									show={!!plan.hasCommitment}
									onTrue={() => (
										<Caption style={{ width: 'max-content' }} color="inherit">
											{translate(
												'plan-bar.will.expire',
												String(plan?.expireDate).replace(/\./g, ' / ')
											)}
										</Caption>
									)}
								/>
							</>
						)}
					/>
				</RightPanelCard>
			</PlanContainer>
			<ConditionalRender
				show={!!plan?.promotion?.promotion}
				onTrue={() => (
					<SaveContainer>
						<LeftPanel>
							<BodySBold color="inherit">{translate('plan-bar.save')}</BodySBold>
						</LeftPanel>
						<RightPanel>
							<PricePanel>
								<PricePanelRight>
									<OmrPanel>
										<BigCurrency color="inherit">{translate('omr')}</BigCurrency>
									</OmrPanel>
								</PricePanelRight>
								<MidllePanel></MidllePanel>
								<PricePanelLeft>
									<DisplayMBold color="inherit">{plan?.promotion?.price}</DisplayMBold>
								</PricePanelLeft>
							</PricePanel>
						</RightPanel>
					</SaveContainer>
				)}
			/>
		</PlanBarContainer>
	);
};

export default PlanBar;
