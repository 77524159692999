import { BodyM, TitleBold, DisplayMBold, Headline, Subheading } from '@Components/Typography/Typography';
import styled from 'styled-components';

export const CardContainer = styled.div`
	padding: 10px 24px;
	display: flex;
	flex-direction: column;
	overflow: hidden;
	height: 557px;
`;

export const CloseIconWrapper = styled.div``;

export const TextBodyM = styled(BodyM)`
	display: inline;
	${({ theme }) => ({
		color: theme.colors.black54,
	})}
`;

export const HeaderWrapper = styled.div`
	display: flex;
	justify-content: center;
	width: 100%;
`;

export const HeaderTitle = styled(TitleBold)`
	${({ theme }) => ({
		color: theme.colors.black87,
	})}
	flex-grow: 1;
	text-align: center;
`;

export const TCTitleWrapper = styled(DisplayMBold)`
	${({ theme }) => ({
		color: theme.colors.black87,
	})}
`;

export const TCSubtitleWrapper = styled(Headline)`
	${({ theme }) => ({
		color: theme.colors.black54,
	})}
`;

export const ContentWrapper = styled.div`
	overflow: auto;
	flex-grow: 1;
`;

export const Content = styled(BodyM)`
	${({ theme }) => ({
		color: theme.colors.black54,
	})}
`;

export const TermsAndConditionsTitle = styled(Subheading)`
	${({ theme }) => ({
		color: theme.colors.black38,
	})}
	text-transform: uppercase;
`;
