import styled from 'styled-components';
import { BodySHigher, CaptionBigger } from '@Components/Typography/Typography';
import { paddingLeft } from '@Utils/layout/layoutSupport';
import theme from '@Utils/theme/theme';

export const CardWrapper = styled.div`
	padding: 7px;
	border-bottom: 1px solid;
	border-radius: 0px;
	display: flex;
	flex-direction: column;
	cursor: pointer;
	background-color: #f9f9f9;
	${({ theme }) => ({
		borderColor: theme.colors.grey300,
	})}
	&:hover {
		border-color: ${theme.colors.primary};
	}
`;

export const InnerWrapper = styled.div`
	display: flex;
`;

export const ImageWrapper = styled.div<{ src: string }>`
	height: 56px;
	width: 56px;
	${({ src }) =>
		src && {
			background: `url(${src})`,
			backgroundSize: 'contain',
			backgroundRepeat: 'no-repeat',
			backgroundPosition: 'center center',
		}}
`;

export const CardContent = styled.div`
	${() => paddingLeft(8)}
	display: flex;
	flex-direction: column;
	flex: 1;
`;

export const ItemTitle = styled(BodySHigher)`
	margin-top -4px;
	height: 56px;
	max-height: 56px;
	${({ theme }) => ({
		color: theme.colors.black87,
	})}
`;

export const PriceWrapper = styled.div`
	margin-top: 16px;
	display: flex;
	align-items: flex-end;
	justify-content: space-between;
`;

export const PriceDescription = styled(CaptionBigger)`
	${({ theme }) => ({
		color: theme.colors.black54,
	})}
`;
