export interface IOpenHours {
	sunday: string;
	otherInfo: string;
	monday: string;
	tuesday: string;
	wednesday: string;
	thursday: string;
	friday: string;
	saturday: string;
}

export interface IOutlet {
	openHours?: IOpenHours;
	branchId: string;
	address: string;
	phone: string;
	city: string;
	governorate: string;
	latitude: string;
	wilayat: string;
	available: boolean;
	longitude: string;
	branchName?: string;
}

export interface IOutletsInformation {
	outlets: IOutlet[];
	resourceName: string;
}

export interface IAvailableOutlets {
	returnValue: IOutletsInformation;
}

export interface IAvailableOutlet {
	returnValue: IOutlet;
}

const model: types.models.IInputModel = {
	returnValue: 'response',
};

export default model;
