import React, { FunctionComponent, useState, useEffect } from 'react';
import {
	AppleBannerWrapper,
	BannerWrapper,
	HeaderWrapper,
	SubTitleWrapper,
	TitleWrapper,
} from '@Components/ExploreMore/ExploreMore.styled';
import Divider from '@Components/Divider/Divider';
import { PrimaryMediumButtonFlex } from '@Components/controls/Button/Button';
import useTranslate from '@Utils/hooks/useTranslate';
import { getLang } from '@Utils/language/language';
import { useHistory } from 'react-router-dom';
import ConditionalRender from '@Components/ConditionalRender/ConditionalRender';
import { IMarketingBanners } from '@ApiModels/marketingBanners';
import { EspotService } from '@Services/espot/espot';
import useScreen from '@Utils/hooks/useScreen';

const ExploreMore: FunctionComponent = () => {
	const { translate } = useTranslate();
	const history = useHistory();
	const [marketingBanners, setMarketingBanners] = useState<IMarketingBanners>({} as IMarketingBanners);
	const { screenClass } = useScreen();

	const goToAppleStore = (): void => {
		history.push(`/${getLang()}/apple-brand-store`);
	};

	useEffect(() => {
		EspotService.getMarketingBanners('AppleBrandedStoreBanners').subscribe(
			(r) => {
				if (r.banners.length > 0) {
					setMarketingBanners(r);
				}
			},
			() => {}
		);
	}, []);

	return (
		<BannerWrapper>
			<HeaderWrapper>
				<TitleWrapper>{translate('product.enjoy.new.possibilities')}</TitleWrapper>
				<Divider withoutLine marginBottom={8} />
				<SubTitleWrapper>{translate('product.explore.apple.world')}</SubTitleWrapper>
			</HeaderWrapper>
			<ConditionalRender
				show={
					!!marketingBanners?.banners?.find((item) =>
						item.img?.includes(`/Product_page/${screenClass.toUpperCase()}`)
					)
				}
				onTrue={() => (
					<AppleBannerWrapper>
						<img
							src={
								marketingBanners?.banners?.find((item) =>
									item.img?.includes(`/Product_page/${screenClass.toUpperCase()}`)
								)?.img
							}
							height="auto"
						/>
					</AppleBannerWrapper>
				)}
			/>
			<PrimaryMediumButtonFlex uniqueId="'go-to-apple-store-at-omantel" onClick={goToAppleStore}>
				{translate('product.apple.store.button')}
			</PrimaryMediumButtonFlex>
			<Divider withoutLine marginBottom={38} />
		</BannerWrapper>
	);
};

export default ExploreMore;
