import React, { FunctionComponent } from 'react';
import { Col } from 'react-grid-system';
import { BottomContentWrapper, DisplayFlex, Rectangular, SkeletonRow } from '@Components/Skeleton/Skeleton.styled';

interface INestedCategoriesSkeleton {
	mobileMenu?: boolean;
}

const NestedCategories: FunctionComponent<INestedCategoriesSkeleton> = ({ mobileMenu }) => {
	return (
		<>
			<Col xs={11}>
				<BottomContentWrapper id="BottomContentWrapper" mobileMenu={mobileMenu} paddingLeft={14}>
					{[1, 2, 3, 4, 5].map((key) => (
						<SkeletonRow key={key} marginBottom={16}>
							<DisplayFlex justifyContent="space-between">
								<Rectangular width={320} height={30} color="grey300" />
							</DisplayFlex>
						</SkeletonRow>
					))}
				</BottomContentWrapper>
			</Col>
		</>
	);
};

export default NestedCategories;
