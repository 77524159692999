import { BodyS, ButtonM, CaptionBigger, ParagraphS, BodySBold } from '@Components/Typography/Typography.styled';
import { isRTL, marginRight, paddingLeft, paddingRight, marginLeft, right } from '@Utils/layout/layoutSupport';
import { Row } from 'react-grid-system';
import styled from 'styled-components';

export const MakasibCardContainer = styled(Row)<{ isXs?: boolean; isSm?: boolean }>`
	${({ isXs, isSm }) => ({
		padding: '2px 0px',
		...paddingRight(isXs || isSm ? 0 : 16),
		...paddingLeft(isXs ? 0 : isSm ? 8 : 16),
	})}
`;

export const AccordionContentWrapper = styled.div<{ isXs?: boolean; isSm?: boolean; isNonLogged?: boolean }>`
	display: flex;
	flex-direction: row;
	margin-top: 32px;
	position: relative;
	z-index: 2;
	${({ isXs, isSm, isNonLogged }) => ({
		...paddingLeft(isXs ? 0 : isSm ? 56 : 72),
		...paddingRight(isXs || isSm ? 0 : 16),
		paddingBottom: isNonLogged ? '0px' : '16px',
		marginBottom: isNonLogged || isXs ? '-32px' : '0px',
	})}
`;

export const PointsMethodsWrapper = styled.div<{ isXlLgMd?: boolean }>`
	display: flex;
	flex-direction: column;
	${({ isXlLgMd }) => ({
		...marginRight(isXlLgMd ? -16 : 0),
	})}
`;

export const BackgroundImageWrapper = styled.div`
	bottom: -20px;
	${() => right(-30)}
	position: absolute;
	padding: inherit;
	z-index: 1;
`;

export const PointsStatusWrapper = styled.div<{ isXl?: boolean }>`
	${({ isXl }) => ({
		...marginLeft(isXl ? -28 : 0),
		width: isXl ? '340px' : '100%',
	})}
	display: flex;
	flex-direction: column;
	gap: 6px;
	margin-bottom: 24px;
`;

export const ExpiringSoonWrapper = styled.div<{ isXl?: boolean; isXs?: boolean }>`
	${() => marginRight(4)}
	display: flex;
	gap: 2px;
	flex-direction: row;
	${({ theme, isXl, isXs }) => ({
		backgroundColor: theme.colors.warning12,
		marginTop: isXl ? '0px' : isXs ? '-12px' : '8px',
		marginBottom: isXs ? '16px' : '0px',
	})}
	align-items: center;
	padding: 8px;
	width: 158px;
	border-radius: 4px;
`;

export const InfoIconWrapper = styled.div`
	margin-top: -2px;
	${() => marginLeft(4)}
	${() => marginRight(2)}
`;

export const RowPointsStatusWrapper = styled.div<{ isXs?: boolean; isSmall?: boolean; isLastElement?: boolean }>`
	display: flex;
	white-space: nowrap;
	${({ isXs, isLastElement }) => ({
		flexDirection: isXs ? 'row' : 'column',
		gap: isXs ? '4px' : '0px',
		marginTop: isXs ? '4px' : '0px',
		marginBottom: isXs ? (isLastElement ? '8px' : '16px') : '0px',
	})}
	${({ isSmall }) => ({
		...marginRight(isSmall ? 0 : 16),
	})}
`;

export const SuccessLabelWrapper = styled(ParagraphS)`
	margin-top: 16px;
	gap: 8px;
	width: 100%;
	align-items: flex-start;
	display: flex;
	flex-direction: row;
	${({ theme }) => ({
		color: theme.colors.positive,
	})}
`;

export const RowInfoContainer = styled.div<{ isXs?: boolean }>`
	display: flex;
	flex-direction: column;
	width: 100%;
	${({ isXs }) => ({
		paddingBottom: isXs ? '8px' : '0px',
	})}
`;

export const XsColumnWrapper = styled.div`
	display: flex;
	flex-direction: column;
`;

export const Divider = styled.div`
	margin: 24px 0px;
	display: inline-block;
	width: 100%;
	height: 1px;
	border-radius: 2px;
	background-color: ${({ theme }) => theme.colors.black12}};
	`;

export const RowPointsWrapper = styled.div<{ isSmall?: boolean; isXs?: boolean }>`
	display: flex;
	flex-direction: row;
	gap: 50px;

	width: 100%;
`;

export const PointsPaymentWrapper = styled.div<{ marginBottom?: string; isXs?: boolean }>`
${({ isXs }) => ({
	padding: isXs ? '16px' : '16px 24px',
})}
display: flex;
flex-direction: column;
border-radius: 8px;
align-items: center;
margin-bottom: ${({ marginBottom }) => marginBottom}}
background-color: ${({ theme }) => theme.colors.grey50}};
`;

export const TitleLabelWrapper = styled.div`
	margin-bottom: 4px;
`;

export const TitleSwitchWrapper = styled.div`
	display: flex;
	width: 100%;
	justify-content: space-between;
	align-items: center;
`;

export const PayFullXsBoldWrapper = styled(BodySBold)<{ isXs?: boolean }>`
	${({ isXs }) => ({
		maxWidth: isXs ? '200px' : '100%',
	})}
`;

export const PayFullXsWrapper = styled(BodyS)<{ isXs?: boolean }>`
	${({ isXs }) => ({
		maxWidth: isXs ? '200px' : '100%',
	})}
`;

export const VerticalDivider = styled.div`
	display: inline-block;
	width: 1px;
	height: 100%;
	border-radius: 2px;
	${() => marginLeft(8)}
	background-color: ${({ theme }) => theme.colors.black12}};
	`;

export const NoMakasibPointsWrapper = styled.div<{ isXs?: boolean }>`
	${({ isXs }) => ({
		...marginLeft(isXs ? 16 : 0),
	})}
	display: flex;
	flex-direction: row;
	align-items: center;
`;

export const PayPointsRowWrapper = styled.div`
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: flex-start;
	margin-top: 28px;
`;

export const FieldWrapper = styled.div`
	display: flex;
	flex-direction: row;
`;

export const Button = styled.div<{ isXs?: boolean }>`
	${({ isXs }) => ({
		width: isXs ? '100%' : '304px',
	})}
	> div {
		width: 100%;
	}
`;

export const MakasibWarningContainer = styled(CaptionBigger)<{ isWarning?: boolean; isBothWarnings?: boolean }>`
	border-radius: 4px;
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 8px 16px;
	gap: 16px;
	${({ isWarning, theme, isBothWarnings }) => ({
		color: isWarning ? theme.colors.warningLight : theme.colors.black54,
		backgroundColor: isWarning ? theme.colors.warning6 : theme.colors.black6,
		marginTop: isBothWarnings ? '8px' : '16px',
	})}
`;

export const TextButton = styled(ButtonM)`
	display: inline-block;
	cursor: pointer;
	text-decoration: underline;
	${() => isRTL() && 'padding-left: 8px'};
	${({ theme }) => ({
		textDecorationColor: theme.colors.primary,
		color: theme.colors.primary,
	})}
`;

export const PayPointsLabelWrapper = styled.div`
	display: flex;
	height: 48px;
	align-items: center;
	white-space: nowrap;
`;

export const ButtonWrapper = styled.div`
	display: flex;
	height: 48px;
	align-items: center;
	> div {
		background-color: transparent;
	}
`;

export const XsButtonWrapper = styled.div`
	width: 100%;
	height: 48px;
	margin-top: 16px;
	margin-bottom: 8px;
	> div {
		background-color: transparent;
	}
`;

export const EqualIconWrapper = styled.div<{ isXs?: boolean }>`
	${({ isXs }) => ({
		margin: isXs ? '0px 12px' : '0px 16px',
	})}
	display: flex;
	height: 48px;
	align-items: center;
`;

export const NoMakasibPointsLabelsWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 8px;
`;

export const IconWrapper = styled.div`
	margin: 0px 30px 0px 16px;
`;

export const EarnPointsWrapper = styled(BodyS)`
	cursor: pointer;
`;
