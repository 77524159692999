import styled from 'styled-components';
import { ParagraphS } from '@Components/Typography/Typography';
import { marginLeft } from '@Utils/layout/layoutSupport';

export const InsuranceOfferCardWraper = styled.div`
	padding: 16px;
`;

export const ImageWrapper = styled.div`
	height: 48px;
	width: 48px;
	overflow: hidden;
	display: flex;
	justify-content: left;
	align-items: flex-start;
`;

export const Image = styled.img`
	height: 48px;
	width: 48px;
`;

export const InsuranceSection = styled.div`
	display: flex;
	align-items: flex-start;
`;

export const SectionData = styled.div`
	display: flex;
	flex-direction: column;
	${() => marginLeft('16px')};
`;

export const SectionSubtitle = styled(ParagraphS)`
	margin: 4px 0;
	${({ theme }) => {
		return {
			color: theme.colors.black54,
		};
	}}
`;
