import React, { FunctionComponent } from 'react';
import { SkeletonRow, Rectangular, SkeletonCardContainer, DisplayFlex } from '@Components/Skeleton/Skeleton.styled';

const ITEM_HEIGHT = 16;

const ContinueToCheckout: FunctionComponent = () => {
	return (
		<SkeletonCardContainer marginBottom={16} width="auto">
			<SkeletonRow marginTop={8}>
				<Rectangular width="100%" height={56} marginBottom={16} />
				<DisplayFlex justifyContent="center">
					<Rectangular width="80%" height={ITEM_HEIGHT} marginBottom={8} />
				</DisplayFlex>
			</SkeletonRow>
		</SkeletonCardContainer>
	);
};

export default ContinueToCheckout;
