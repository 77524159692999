export interface IOrderHistory {
	Order?: IOrder[];
	recordSetCount?: string;
	resourceId?: string;
	recordSetStartNumber?: string;
	resourceName?: string;
	recordSetTotal?: string;
	recordSetComplete?: string;
}

export interface IOrder {
	orderTypeCode?: string;
	totalShippingCharge?: string;
	x_retryOrder?: string;
	resourceId?: string;
	orderId?: string;
	lastUpdateDate?: Date;
	channel?: IChannel;
	orderStatus?: string;
	x_isPurchaseOrderNumberRequired?: string;
	totalShippingChargeCurrency?: string;
	buyerId?: string;
	grandTotalCurrency?: string;
	buyerDistinguishedName?: string;
	x_merchantId?: string;
	x_isPersonalAddressesAllowedForShipping?: string;
	storeNameIdentifier?: string;
	totalProductPriceCurrency?: string;
	totalProductPrice?: string;
	locked?: string;
	placedDate?: Date;
	totalAdjustmentCurrency?: string;
	totalSalesTaxCurrency?: string;
	comments?: string;
	totalSalesTax?: string;
	grandTotal?: string;
	x_authorizationNo?: string;
	storeUniqueID?: string;
	shipAsComplete?: string;
	x_trackingIds?: string;
	totalShippingTax?: string;
	x_orderSubmit?: Date;
	prepareIndicator?: string;
	totalShippingTaxCurrency?: string;
	x_orderItems?: string;
	totalAdjustment?: string;
	x_receiptNumber?: string;
	x_canceledDate?: string;
}

export interface IChannel {
	channelIdentifer?: IChannelIdentifer;
	userData?: null;
	description?: IDescription;
}

export interface IChannelIdentifer {
	channelName?: string;
	uniqueID?: string;
}

export interface IDescription {
	language?: string;
	value?: string;
}

export interface IOrderItems {
	quantity?: string;
	name?: string;
	planName?: string;
	planShortDescription?: string;
	planLongDescription?: string;
}

const model: types.models.IInputModel = {
	Order: 'response.Order',
	total: 'response.recordSetTotal',
};

export default model;
