import styled from 'styled-components';
import { BodyS, CaptionBold, ParagraphMBold, ValueBold } from '@Components/Typography/Typography.styled';
import { matchScreen, paddingLeft, paddingRight } from '@Utils/layout/layoutSupport';

export const CardContainer = styled.div`
	display: flex;
	flex-direction: column;
`;
export const HeaderWrapper = styled.div`
	display: flex;
	align-items: center;
	padding: 12px 16px;
`;

export const HeaderDescriptionWrapper = styled.div`
	flex: 1;
	padding: 0 32px;
`;

export const HeaderTitle = styled(ValueBold)`
	${({ theme }) => ({
		color: theme.colors.black87,
	})}
`;

export const ContentWrapper = styled.div`
	flex: 1;
	padding-top: 16px;
	padding-bottom: 8px;
	${() => (matchScreen(['xs']) ? paddingLeft(16) : paddingLeft(72))}
	${() => paddingRight(16)}
`;

export const AccordionsContainer = styled.div`
	border-radius: 8px;
	${({ theme }) => ({
		backgroundColor: theme.colors.grey50,
	})},
`;

export const AccordionContentWrapper = styled.div`
	padding-top: 16px;
	padding-bottom: 16px;
	${() => paddingRight(16)}
	${() => (matchScreen(['xs']) ? paddingLeft(16) : paddingLeft(64))}
`;

export const LabeledInformationWrapper = styled.div`
	padding-top: 8px;
`;

export const Label = styled(CaptionBold)`
	${({ theme }) => ({
		color: theme.colors.black54,
	})}
`;

export const Data = styled(ParagraphMBold)`
	${({ theme }) => ({
		color: theme.colors.black87,
	})}
	word-break: break-word;
`;

export const SectionHeader = styled(ValueBold)`
	padding-bottom: 7px;
	${({ theme }) => ({
		color: theme.colors.black54,
	})}
`;

export const MapElement = styled.div`
	width: 100%;
	height: 100%;
	${() => ({
		paddingTop: matchScreen('xs') ? 16 : 0,
		minHeight: matchScreen('xs') ? 264 : 0,
	})}
`;

export const DeliveryMethodWrapper = styled.div<{ paddingBottom?: number }>`
	display: flex;
	align-items: center;
	padding: 16px 0 40px;
	${({ paddingBottom }) => ({ paddingBottom })}
`;

export const DescriptionWrapper = styled.div`
	${() => paddingLeft(16)}
`;

export const DividerWrapper = styled.div`
	padding: 0 16px;
`;

export const DeliveryMethodTitle = styled(ValueBold)`
	${({ theme }) => ({
		color: theme.colors.black87,
		marginBottom: 4,
	})}
`;

export const DeliveryMethodDescription = styled(BodyS)`
	${({ theme }) => ({
		color: theme.colors.black54,
	})}
`;

export const DaysWrapper = styled.div`
	margin-top: 4px;
	display: flex;
`;

export const AppointmentSectionHeader = styled(ValueBold)`
	margin-top: 40px;
	padding-bottom: 7px;
	${({ theme }) => ({
		color: theme.colors.black54,
	})}
`;

export const AppointmentDetailsWrapper = styled.div`
	display: flex;
	align-items: center;
	padding: 16px 0 0px;
`;
