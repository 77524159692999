import styled from 'styled-components';
import { BodyM, BodyMBold, BodyS, BodySBold } from '@Components/Typography/Typography.styled';
import { ISegmentProps, ISegmentSize } from '@Components/controls/Segment/Segment';
import { paddingRight } from '@Utils/layout/layoutSupport';

export const SegmentWrapper = styled.div<Partial<ISegmentProps>>`
	display: inline-block;
	${({ theme }) => {
		return {
			background: theme.colors.white,
		};
	}}
	cursor: pointer;
	box-sizing: border-box;
	border-radius: 4px;
	transition: all 0.1s ease-in-out;

	${({ size }) => {
		switch (size) {
			case ISegmentSize.SMALL_WIDE:
				return `width: 100%; min-width: 130px; height: 40px;`;
			case ISegmentSize.BIG:
				return `width: 100%; min-width: 88px; height: 56px;`;
			case ISegmentSize.EXPANDER_SMALL:
				return `width: 100%; min-width: 30px; height: 40px;`;
			case ISegmentSize.EXPANDER_BIG:
				return `width: 100%; min-width: 30px; height: 56px;`;
			default:
				return `width: 100%; min-width: 88px; height: 40px;`;
		}
	}};

	${({ selected, disabled, theme }) => {
		if (disabled) {
			return {
				background: `${theme.colors.black6};`,
				border: `1px solid ${theme.colors.black12};`,
			};
		} else if (selected) {
			return `
				border: 2px solid ${theme.colors.primary};
				&:hover {
					border: 2px solid ${theme.colors.primary};
				};
				&:active {
					border: 2px solid ${theme.colors.primary};
				}
			`;
		} else {
			return `
				border: 1px solid ${theme.colors.black87};
				&:hover {
					border: 2px solid ${theme.colors.black87};
				};
				&:active {
					border: 2px solid ${theme.colors.primary};
				}`;
		}
	}}
	${({ margin }) => {
		return (
			margin &&
			`
			margin-right: ${margin}px;
			margin-bottom:${margin}px;
		`
		);
	}};
`;

export const SegmentContent = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
`;

export const IconWrapper = styled.div<Partial<ISegmentProps>>`
	${({ size }) => {
		return size === ISegmentSize.SMALL ? `width: 16px;height: 16px;` : `width: 24px;height: 24px;`;
	}};
	${() => paddingRight('8px')}
`;

export const Label = styled(BodyM)``;

export const LabelBold = styled(BodyMBold)``;

export const SmallLabel = styled(BodyS)``;

export const SmallLabelBold = styled(BodySBold)``;
