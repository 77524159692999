import { getLanguage } from '@Utils/language/language';
import { translations } from '@Utils/translations/translations';
import * as Yup from 'yup';

export default (isEmail: boolean): Yup.AnyObjectSchema => {
	const lang = getLanguage().toLowerCase();
	return Yup.object().shape({
		fullName: Yup.string().required(translations['delivery.address.required-field'][lang]),
		contact: isEmail
			? Yup.string()
					.email(translations['validate.personal.data.email.must.be.email'][lang])
					.required(translations['delivery.address.required-field'][lang])
			: Yup.string()
					.required(translations['delivery.address.required-field'][lang])
					.length(8, translations['validate.personal.data.oman.format.phone.number'][lang])
					.matches(
						/(90[1-9][0-9]{5})|((9[1-9]|(7[1289]))[0-9]{6})$|(2)[2-6][0-9]{6}$/,
						translations['validate.personal.data.oman.format.phone.number'][lang]
					),
	});
};
