import api from '@Utils/api';
import { forkJoin, Observable, of } from 'rxjs';
import { IProductsByPartNumber } from '@ApiModels/productsByPartNumber';
import { IItem, IProductsByCategoryId } from '@ApiModels/productsByCategoryId';
import { IProductById } from '@ApiModels/productById';
import { IProductBySeoModel } from '@ApiModels/productIdBySeo';
import { ICartItem } from '@ApiModels/viewCart';
import { concatMap, map, share } from 'rxjs/operators';
import { InventoryService } from '@Services/inventory/inventoryService';
import { IGetProductsByParentCategory } from '@Utils/api/endpointsGenerators/products/productsEndpointsGenerators';
import { IRelatedProductByIdItem, IRelatedProducts } from '@ApiModels/relatedProductsById';
import { IGetProductsUnauthorized } from '@ApiModels/getProductsUnauthorized';
import { IValidateOperation } from '@ApiModels/validateOperation';
import { IValidateOTP } from '@ApiModels/validateOTP';
import { ISubmitProductOrder } from '@ApiModels/submitProductOrder';
import { AccountsService } from '@Services/accounts/accountsService';
import { IAvailableOutlets, IAvailableOutlet } from '@ApiModels/getProductOutletsPickup';
import { IProductAvailability } from '@ApiModels/getProductOutletAvailability';
import { IProductInstallment } from '@ApiModels/productInstallment';
import { ISmartVouchersResponse } from '@ApiModels/getSmartVoucher';
import { ICalculateInstallemntRequest, ICalculateInstallemntResponse } from '@ApiModels/calculateInstallment';
import { MAKASIB_POINTS_PRODUCT_ID, PLANS_STORE_ID } from '@Config/app.config';

export enum ItemType {
	ITEM = 'ITEM',
	ITEM_MOBILE = 'ITEM_MOBILE',
	GIFTCARD = 'GIFTCARD',
	DIGITAL_PRODUCT = 'DIGITAL_PRODUCT',
	ITEM_HBB = 'ITEM_HBB',
	ITEM_HBB_INSTALMENTS = 'ITEM_HBB_INSTALMENTS',
	ITEM_WFBB = 'ITEM_WFBB',
	ITEM_ACCESSORY_INSTALMENTS = 'ITEM_ACCESSORY_INSTALMENTS',
	ITEM_ACCESSORY_INSTALMENTS_HBB = 'ITEM_ACCESSORY_INSTALMENTS_HBB',
	ITEM_ACCESSORY_INSTALMENTS_WFBB = 'ITEM_ACCESSORY_INSTALMENTS_WFBB',
	UNKNOWN = 'UNKNOWN',
	EMPTY = '',
	TABLETS_LAPTOPS_INSTALMENTS = 'TABLETS_LAPTOPS_INSTALMENTS',
	TABLETS_LAPTOPS_INSTALMENTS_HBB = 'TABLETS_LAPTOPS_INSTALMENTS_HBB',
	TABLETS_LAPTOPS_INSTALMENTS_WFBB = 'TABLETS_LAPTOPS_INSTALMENTS_WFBB',
	B2B_TABLETS_LAPTOPS_BAQATI = 'B2B_TABLETS_LAPTOPS_BAQATI',
	B2B2C_TABLETS_LAPTOPS_BAQATI = 'B2B2C_TABLETS_LAPTOPS_BAQATI',
	B2B_ITEM_ACCESSORY_BAQATI = 'B2B_ITEM_ACCESSORY_BAQATI',
	B2B2C_ITEM_ACCESSORY_BAQATI = 'B2B2C_ITEM_ACCESSORY_BAQATI',
	DEVICE_INSURANCE_INSTALMENT = 'DEVICE_INSURANCE_INSTALMENT',
	SALES_LEAD_DEVICE = 'SALES_LEAD_DEVICE',
}

export interface IProductByIdSpecificSkusPayload {
	productId: string;
	itemType?: ItemType;
	cacheOff?: boolean;
	customCatalog?: string;
}
export interface IRelatedProductsByIdPayload {
	productId: string;
	itemType?: ItemType;
}

export class ProductsService {
	public static getProductsByCategoryId(categoryId: string): Observable<IProductsByCategoryId> {
		return api.omantelShop.getProductsByCategoryId(categoryId).call();
	}

	public static getProductsByPartNumber(partNumber: string): Observable<IProductsByPartNumber> {
		return api.omantelShop.getProductsByPartNumber(partNumber).call();
	}

	public static getProductOutletAvailability(partNumber: string): Observable<IProductAvailability> {
		return api.omantelShop.getProductOutletAvailability(partNumber).call();
	}

	public static getProductOutletsPickup(partNumber: string): Observable<IAvailableOutlets> {
		return api.omantelShop.getProductOutletsPickup(partNumber).disableCache().call();
	}

	public static getOutletPickup(branchId: string): Observable<IAvailableOutlet> {
		return api.omantelShop.getOutletPickup(branchId).call();
	}

	public static getProductsByParentCategory(config: IGetProductsByParentCategory): Observable<IProductsByCategoryId> {
		return api.omantelShop
			.getProductsByParentCategory(config)
			.call()
			.pipe(
				concatMap((products: IProductsByCategoryId) => {
					if (products.items.length) {
						return forkJoin(
							products.items.map((product, index) => {
								product.place = config.offset + index;
								return InventoryService.getAvailableProductComponents({ product });
							})
						).pipe(
							map((components) => ({ ...products, items: components.map((item) => item.product) })),
							share()
						);
					}
					return of({ contents: [], facets: [], items: [], total: 0 });
				}),
				share()
			);
	}

	public static getProductById({
		productId,
		itemType,
	}: {
		productId: string;
		itemType?: ItemType;
	}): Observable<IProductById> {
		return api.omantelShop
			.getByProductId({
				productId,
				itemType,
				customCatalog: productId === MAKASIB_POINTS_PRODUCT_ID ? PLANS_STORE_ID : undefined,
			})
			.call();
	}

	public static getProductByIdSpecifiedSkus({
		productId,
		itemType,
		cacheOff = false,
	}: IProductByIdSpecificSkusPayload): Observable<IProductById> {
		return api.omantelShop.getByProductId({ productId, itemType, cacheOff }).call();
	}

	public static getRelatedProductsByIdWithInventory({
		productId,
		itemType,
	}: IRelatedProductsByIdPayload): Observable<{
		relatedProductsWithInventory: IItem[];
		relatedProducts: IRelatedProductByIdItem[];
	}> {
		return api.omantelShop
			.getRelatedProductsById({ productId, itemType })
			.call()
			.pipe(
				concatMap((products: IRelatedProducts) => {
					if (products.relatedProducts.length) {
						return forkJoin(
							products.relatedProducts.map((product, index) => {
								return InventoryService.getAvailableProductComponents({ product });
							})
						).pipe(
							map((components) => ({
								...products,
								relatedProductsWithInventory: components.map((item) => item.product),
							})),
							share()
						);
					}
					return of({ relatedProductsWithInventory: [], relatedProducts: [] });
				}),
				share()
			);
	}

	public static getRelatedProductsById({
		productId,
		itemType,
	}: IRelatedProductsByIdPayload): Observable<IRelatedProducts> {
		return api.omantelShop.getRelatedProductsById({ productId, itemType }).call();
	}

	public static getProductIdBySeo(seo: string): Observable<IProductBySeoModel> {
		return api.omantelShop.getProductIdBySeo(seo).call();
	}

	public static getProductsByIdList(idList: string[]): Observable<IProductById[]> {
		return forkJoin(
			idList.map((id) => this.getProductByIdSpecifiedSkus({ productId: id, itemType: ItemType.ITEM }))
		);
	}

	public static getAllProductsFromCart(cartItems: ICartItem[]): Observable<IProductsByPartNumber[]> {
		return forkJoin(cartItems.map((item) => this.getProductsByPartNumber(item.partNumber)));
	}

	public static getProductsUnauthorized(phoneNumber: string): Observable<IGetProductsUnauthorized> {
		return api.omantelApi.getProductsUnauthorized(phoneNumber).call();
	}

	public static validateOperation(operationContext?: string): Observable<IValidateOperation> {
		return api.omantelApi.validateOperation(operationContext ? operationContext : 'PRETOPOSTVAL').call();
	}

	public static validateOTP(payload: Omit<types.payloads.IValidateOTP, 'msisdn'>): Observable<IValidateOTP> {
		return AccountsService.getMainAccount().pipe(
			concatMap((account) => {
				if (account.accountNum) {
					return api.omantelApi.validateOTP({ msisdn: account.accountNum, ...payload }).call();
				}
				throw new Error('No main account');
			})
		);
	}

	public static submitProductOrder(payload: types.payloads.ISubmitProductOrder): Observable<ISubmitProductOrder> {
		return api.omantelApi.submitProductOrder(payload).call();
	}

	public static getProductInstallment(productId: string): Observable<IProductInstallment> {
		return api.omantelShop.getProductInstallment(productId).call();
	}

	public static getSmartVoucher(crNumber: string, phoneNumber: string): Observable<ISmartVouchersResponse> {
		return api.microApi.getSmartVoucher(crNumber, phoneNumber).call();
	}

	public static calculateInstallment(
		request: ICalculateInstallemntRequest
	): Observable<ICalculateInstallemntResponse> {
		return api.microApi.calculateInstallment(request).call();
	}
}
