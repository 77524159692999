import { Caption, CaptionBold } from '@Components/Typography/Typography.styled';
import { marginRight, matchScreen } from '@Utils/layout/layoutSupport';
import styled from 'styled-components';

export const DeliveryMethodContainer = styled.div<{ selected?: boolean; disabled?: boolean }>`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	border-radius: 8px;
	margin-bottom: 16px;
	${() => (matchScreen('xs') ? marginRight(10) : marginRight(16))};
	${({ theme, disabled }) => ({
		backgroundColor: disabled ? '#5a5a5a0f' : theme.colors.grey50,
		opacity: disabled ? 0.7 : 1,
		cursor: disabled ? undefined : 'pointer',
		width: matchScreen('xs') ? '120px' : '132px',
		padding: matchScreen('xs') ? '16px 10px' : '16px',
	})};
	${({ selected, theme }) =>
		selected &&
		`
		padding: 14px 8px;
        border: 2px solid ${theme.colors.primary}
		`};
	height: 132px;
	&:hover {
		${({ theme, disabled }) => ({
			backgroundColor: disabled ? '#5a5a5a0f' : theme.colors.primary6,
		})};
	}
`;

export const TitleWrapper = styled(CaptionBold)`
	${({ theme }) => ({
		color: theme.colors.black87,
	})};
	width: 75%;
`;

export const SubtitleWrapper = styled(Caption)`
	${({ theme }) => ({
		color: theme.colors.black54,
	})}
`;

export const IconWrapper = styled.div`
	div {
		display: block;
	}
`;
