import useScreen from '@Utils/hooks/useScreen';
import React from 'react';
import { CardContainer, CardContent } from './Card.styled';

export const cards = {
	addedToCart: require('./cards/AddedToCart/AddedToCart'),
	cartItems: require('./cards/CartItems/CartItems'),
	cartPaymentSummary: require('./cards/CartPaymentSummary/CartPaymentSummary'),
	categories: require('./cards/CategoryCard/CategoryCard'),
	checkoutInfo: require('./cards/CheckoutInfo/CheckoutInfo'),
	checkoutNextStep: require('./cards/CheckoutNextStep/CheckoutNextStep'),
	checkoutSummary: require('./cards/CheckoutSummary/CheckoutSummary'),
	checkoutSummaryTryAgain: require('./cards/CheckoutSummary/CheckoutSummaryTryAgain/CheckoutSummaryTryAgain'),
	continueToCheckout: require('./cards/ContinueToCheckout/ContinueToCheckout'),
	deliveryAddress: require('./cards/DeliveryAddress/DeliveryAddress'),
	product: require('./cards/ProductCard/ProductCard'),
	upSellCartProducts: require('./cards/UpSellCartCard/UpSellCartCard'),
	filter: require('./cards/FilterCard/FilterCard'),
	productConfigurator: require('./cards/ProductConfigurator/ProductConfigurator'),
	description: require('./cards/Description/Description'),
	paymentMethod: require('./cards/PaymentMethod/PaymentMethod'),
	paymentSummary: require('./cards/PaymentSummary/PaymentSummary'),
	businessPaymentSummary: require('./cards/BusinessPaymentSummary/BusinessPaymentSummary'),
	productCart: require('./cards/ProductCartCard/ProductCartCard'),
	notifyAvailability: require('./cards/NotifyAvailability/NotifyAvailability'),
	order: require('./cards/Order/Order'),
	orderHistory: require('./cards/OrderHistory/OrderHistory'),
	guestOrderHistory: require('./cards/GuestOrderHistory/GuestOrderHistory'),
	productAvailability: require('./cards/ProductAvailability/ProductAvailability'),
	popularProduct: require('./cards/PopularProduct/PopularProduct'),
	planSelector: require('./cards/PlanSelector/PlanSelector'),
	plan: require('./cards/Plan/Plan'),
	deletePaymentToken: require('./cards/DeletePaymentToken/DeletePaymentToken'),
	genericError: require('./cards/GenericError/GenericError'),
	login: require('./cards/Login/Login'),
	loginSessionExpired: require('./cards/LoginSessionExpired/LoginSessionExpired'),
	loginPrompt: require('./cards/LoginPrompt/LoginPrompt'),
	imagePreview: require('./cards/ImagePreview/ImagePreview'),
	shippingMethod: require('./cards/ShippingMethod/ShippingMethod'),
	digitalProductShippingMethod: require('./cards/DigitalProductShippingMethod/DigitalProductShippingMethod'),
	contactDetails: require('./cards/ContactDetails/ContactDetails'),
	documentDetails: require('./cards/DocumentDetails/DocumentDetails'),
	simDocumentDetails: require('./cards/SimDocumentDetails/SimDocumentDetails'),
	address: require('./cards/Address/Address'),
	termsAndConditions: require('./cards/TermsAndConditions/TermsAndConditions'),
	paymentData: require('./cards/PaymentData/PaymentData'),
	orderSummary: require('./cards/OrderSummary/OrderSummary'),
	paymentDetails: require('./cards/PaymentDetails/PaymentDetails'),
	shippingDetails: require('./cards/ShippingDetails/ShippingDetails'),
	showOnMap: require('./cards/Map/MapCard'),
	planDetails: require('./cards/PlanDetails/PlanDetails'),
	upsell: require('./cards/UpsellCard/Upsell'),
	giftCardCodes: require('./cards/GiftCardCodes/GiftCardCodes'),
	addressBook: require('./cards/AddressBook/AddressBook'),
	pickupOrder: require('./cards/PickupOrder/PickupOrder'),
	deliveryMethod: require('./cards/DeliveryMethod/DeliveryMethod'),
	generalError: require('./cards/GeneralError/GeneralError'),
	imageLink: require('./cards/ImageLink/ImageLink'),
	insuranceStepper: require('./cards/InsuranceStepper/InsuranceStepper'),
	insuranceType: require('./cards/InsuranceType/InsuranceType'),
	insuranceInsurer: require('./cards/InsuranceInsurer/InsuranceInsurer'),
	insuranceVehicle: require('./cards/InsuranceVehicle/InsuranceVehicle'),
	insuranceVehicleValue: require('./cards/InsuranceVehicleValue/InsuranceVehicleValue'),
	insuranceVehicleDetails: require('./cards/InsuranceVehicleDetails/InsuranceVehicleDetails'),
	insuranceOffer: require('./cards/InsuranceOffer/InsuranceOffer'),
	insuranceSelectedOfferValue: require('./cards/InsuranceSelectedOfferValue/InsuranceSelectedOfferValue'),
	insuranceSelectedOfferDetails: require('./cards/InsuranceSelectedOfferDetails/InsuranceSelectedOfferDetails'),
	insuranceInsurerContact: require('./cards/InsuranceInsurerContact/InsuranceInsurerContact'),
	insuranceSummary: require('./cards/InsuranceSummary/InsuranceSummary'),
	insuranceBima: require('./cards/InsuranceBima/InsuranceBima'),
	insuranceOffersLoader: require('./cards/InsuranceOffersLoader/InsuranceOffersLoader'),
	telesalesDescription: require('./cards/TelesalesDescription/TelesalesDescription'),
	telesalesLogin: require('./cards/LoginCard/LoginCard'),
	telesalesOtp: require('./cards/TelesalesOtp/TelesalesOtp'),
	insuranceVehicleRollup: require('./cards/InsuranceVehicleRollup/InsuranceVehicleRollup'),
	insuranceTermsAndConditions: require('./cards/InsuranceTermsAndConditions/InsuranceTermsAndConditions'),
	OTP: require('./cards/OTP/OTP'),
	outletAvailable: require('./cards/OutletAvailable/OutletAvailable'),
	outletPickup: require('./cards/OutletPickup/OutletPickup'),
	bookAppointment: require('./cards/BookAppointment/BookAppointment'),
	pickupBooking: require('./cards/PickupBooking/PickupBooking'),
	appleMenu: require('./cards/AppleMenu/AppleMenu'),
	appleLobMenuItem: require('./cards/AppleLobMenuItem/AppleLobMenuItem'),
	imageWithTitleAndButton: require('./cards/ImageWithTitleAndButton/ImageWithTitleAndButton'),
	microsoftRegistration: require('./cards/MicrosoftRegistration/MicrosoftRegistration'),
	payByMakasibCard: require('./cards/PayByMakasibCard/PayByMakasibCard'),
	eVoucherCard: require('./cards/EVoucherCard/EVoucherCard'),
	paymentBNPLInfo: require('./cards/PaymentBNPLInfo/PaymentBNPLInfo'),
	salesLeadForm: require('./cards/SalesLeadForm/SalesLeadForm'),
};

export type CardNames = keyof typeof cards;

export default function <T extends types.cards.ICardProps>({
	card,
	params,
	onEvent,
	bordered = false,
	marginBottom,
	marginTop,
	withoutContainer = false,
	withoutContainerPadding = false,
	fullHeight = false,
	showShadowOnHover = false,
	overflow,
}: T): JSX.Element | null {
	function emmitEvent(eventName: string, data: any) {
		!!onEvent && onEvent(eventName, data);
	}

	const content = () => {
		if (cards[card]) {
			const CardContent = cards[card].default;
			return <CardContent params={params} emmitEvent={emmitEvent} />;
		} else {
			return null;
		}
	};

	const { screen } = useScreen();

	return withoutContainer ? (
		content()
	) : (
		<CardContainer
			bordered={bordered}
			marginBottom={marginBottom}
			marginTop={marginTop}
			fullHeight={fullHeight}
			isXs={screen(['xs'])}
			showShadowOnHover={showShadowOnHover}
		>
			<CardContent fullHeight={fullHeight} withoutContainerPadding={withoutContainerPadding} overflow={overflow}>
				{content()}
			</CardContent>
		</CardContainer>
	);
}
