import React, { FunctionComponent } from 'react';
import Card from '@Components/Card/Card';
import useScreen from '@Utils/hooks/useScreen';
import { getResponsiveModalStyles } from '@Utils/layout/layoutSupport';
import { ModalContainer } from '@Components/Modal/subComponents/ModalContainer/ModalContiner.styled';

const ModalAddedToCart: FunctionComponent<types.cards.IAddedToCartCardProps['params']> = ({ orderId }) => {
	const { screenClass } = useScreen();

	return (
		<ModalContainer styles={getResponsiveModalStyles(screenClass)}>
			<Card<types.cards.IAddedToCartCardProps> params={{ orderId }} card="addedToCart" withoutContainer />
		</ModalContainer>
	);
};

export default ModalAddedToCart;
