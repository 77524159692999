import { Subheading, HeadlineBold, BodyS } from '@Components/Typography/Typography';
import { TABLE_CONTAINER_WIDTH } from '@Views/Comparison/Comparison.styled';
import styled from 'styled-components';

export const CombarisonOverflowBarContainer = styled.div<{
	top: number;
	overflowEnabled?: boolean;
	leftPosition?: number;
}>`
	display: flex;
	min-width: 100%;
	transition: top 0.6s ease-in-out;
	${({ theme, top, overflowEnabled, leftPosition = 0 }) => ({
		backgroundColor: theme.colors.white,
		top,
		left: overflowEnabled ? 0 : -leftPosition,
	})};
	position: fixed;
	z-index: 3;
	box-shadow: 0 7px 8px 0 rgba(0, 0, 0, 0.05), 0 5px 22px 4px rgba(0, 0, 0, 0.04), 0 12px 17px 2px rgba(0, 0, 0, 0.04);
`;

export const SubheaderWrapper = styled(Subheading)`
	text-transform: uppercase;
	${({ theme }) => ({
		color: theme.colors.black54,
	})}
`;

export const SwitchContainer = styled.div`
	display: flex;
	flex-direction: row;
	margin-top: 4px;
	align-items: center;
	margin-bottom: 16px;
`;

export const TitleWrapper = styled(HeadlineBold)`
	${({ theme }) => ({
		color: theme.colors.black87,
	})}
	margin-top: 4px;
`;

export const HighlightText = styled(BodyS)`
	${({ theme }) => ({
		color: theme.colors.black87,
	})}
	margin: 0 16px;
`;

export const ContentContainer = styled.div<{ paddingLeft: number }>`
	display: flex;
	flex-direction: row;
	${({ paddingLeft }) => ({ paddingLeft })}
`;

export const TitleContainer = styled.div<{ overflowEnabled?: boolean; shadowWidth?: number }>`
	min-width: ${TABLE_CONTAINER_WIDTH}px;
	padding-top: 14px;
	${({ overflowEnabled, shadowWidth, theme }) => ({
		position: overflowEnabled ? 'fixed' : 'relative',
		boxShadow: `${shadowWidth}px 0 ${shadowWidth}px 0 rgba(0, 0, 0, 0.04)`,
		backgroundColor: theme.colors.white,
	})}
`;

export const ProductsContainer = styled.div<{ paddingLeft: number; overflowEnabled?: boolean }>`
	${({ paddingLeft, overflowEnabled, theme }) => ({
		paddingLeft: overflowEnabled ? TABLE_CONTAINER_WIDTH - paddingLeft : 0,
		backgroundColor: theme.colors.white,
	})};
	display: flex;
	flex-direction: row;
	margin-bottom: 16px;
	padding-top: 16px;
`;
