import styled from 'styled-components';
import { ScreenClass } from 'react-grid-system';

export enum ViewType {
	LIST = 'LIST',
	GRID = 'GRID',
}

export enum ProductType {
	UNAVAILABLE = 'UNAVAILABLE',
	AVAILABLE = 'AVAILABLE',
	PREORDER = 'PREORDER',
	SALES_LEAD = 'SALES LEAD',
}

export enum TileSize {
	BIG = 'BIG',
	REGULAR = 'REGULAR',
}

export enum PriceType {
	SINGLE = 'SINGLE',
	GROUP = 'GROUP',
}
export interface IWithScreenClass {
	screenClass: ScreenClass;
}

export const ProductCardWrapper = styled.div`
	cursor: pointer;
`;

export const RelatedProductsWrapper = styled.div<IWithScreenClass>`
	${({ screenClass }) => {
		return {
			paddingLeft: ['sm', 'md'].includes(screenClass) ? 16 : 0,
			paddingRight: ['sm', 'md'].includes(screenClass) ? 16 : 0,
			marginLeft: ['sm', 'md'].includes(screenClass) ? -32 : ['lg', 'xl'].includes(screenClass) ? 0 : -16,
			marginRight: ['sm', 'md'].includes(screenClass) ? -32 : ['lg', 'xl'].includes(screenClass) ? 0 : -16,
		};
	}}
	display: flex;
	overflow-x: scroll;

	::-webkit-scrollbar {
		display: none;
	}
	> .single-product-xs {
		min-width: 260px;
		max-width: 260px;
	}
	> .single-product-sm {
		min-width: 245px;
		max-width: 245px;
	}
	> .single-product-md {
		min-width: 260px;
		max-width: 260px;
	}
	> .single-product-lg {
		min-width: 290px;
		max-width: 290px;
	}
	> .single-product-xl {
		min-width: 255px;
		max-width: 255px;
	}
`;
export const SingleProductWrapper = styled.div<IWithScreenClass>`
	${({ screenClass }) => {
		return {
			paddingLeft: ['xl', 'lg'].includes(screenClass) ? 0 : 16,
		};
	}}
	:not(:first-child) {
		${({ screenClass }) => {
			return {
				paddingLeft: ['xl'].includes(screenClass) ? 32 : 16,
			};
		}}
	}

	:last-child {
		${({ screenClass }) => {
			return {
				paddingRight: ['xs'].includes(screenClass) ? 16 : ['sm', 'md'].includes(screenClass) ? 32 : 0,
			};
		}}
	}
`;
