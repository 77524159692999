import React, { FunctionComponent } from 'react';
import { BodyS } from '@Components/Typography/Typography.styled';
import {
	UnavailableProductWrapper,
	UnavailableProductTagWrapper,
} from '@Components/Card/cards/ProductCard/subcomponents/UnavailableProductTag/UnavailableProductTag.styled';
import useTranslate from '@Utils/hooks/useTranslate';

interface IUnavailableProductTagProps {
	withColorPicker?: boolean;
}

const UnavailableProductTag: FunctionComponent<IUnavailableProductTagProps> = ({ withColorPicker }) => {
	const { translate } = useTranslate();
	return (
		<UnavailableProductWrapper withColorPicker={withColorPicker}>
			<UnavailableProductTagWrapper>
				<BodyS color="black54">{translate('product.card.product.unavailable')}</BodyS>
			</UnavailableProductTagWrapper>
		</UnavailableProductWrapper>
	);
};

export default UnavailableProductTag;
