import { IOutlet } from '@ApiModels/getProductOutletsPickup';
import { ProductsService } from '@Services/productsService/productsService';
import React, { createContext, useContext, useState } from 'react';
import { AppointmentService } from '@Services/outletAppointment/outletAppointment';
import moment from 'moment';
import { useBuyNowPayLater } from '@Utils/context/BuyNowPayLaterContext';

interface IUseOutlet {
	alertVisibility: boolean;
	selectedOutlet: IOutlet;
	availableOutlets: IOutlet[];
	bookingPickupAppointment: string;
	ticketNumber: string;
	disabledDates: string[];
	getAvailableOutlets: (partId: (string | undefined)[]) => void;
	setSelectedOutlet: (value: IOutlet) => void;
	setAvailableOutlets: (value: IOutlet[]) => void;
	setTicketNumber: (value: string) => void;
	setAlertVisibility: (value: boolean) => void;
	setUserContactDetails: (value: IUserContactDetails) => void;
	cancelCurrentAppointment: () => Promise<string>;
	bookNewAppointment: (bookingPickupAppointment: string) => Promise<string>;
}

interface IUserContactDetails {
	firstName: string;
	middleName: string;
	lastName: string;
	phoneNumber: string;
}

const PICKUP_OUTLET_BOOKING_SERVICE_ID = '2703';
export const ERROR_CODES = ['ERROR_APPOINTMENT_DAILY_LIMIT_EXCEEDED', 'ERROR_BOOKING_APPOINTMENT'];

export const EMPTY_OUTLET: IOutlet = {
	openHours: {
		sunday: '',
		otherInfo: 'closed',
		monday: '',
		tuesday: '',
		wednesday: '',
		thursday: '',
		friday: '',
		saturday: '',
	},
	branchId: '',
	address: '',
	phone: '',
	city: '',
	governorate: '',
	latitude: '',
	wilayat: '',
	available: false,
	longitude: '',
	branchName: '',
};

export const OutletContext = createContext({
	alertVisibility: false,
	selectedOutlet: EMPTY_OUTLET,
	availableOutlets: [EMPTY_OUTLET],
	bookingPickupAppointment: '',
	ticketNumber: '',
	disabledDates: [] as string[],
	getAvailableOutlets: (partId: (string | undefined)[]) => {},
	setSelectedOutlet: (value: IOutlet) => {},
	setAvailableOutlets: (value: IOutlet[]) => {},
	setTicketNumber: (value: string) => {},
	setAlertVisibility: (value: boolean) => {},
	setUserContactDetails: (value: IUserContactDetails) => {},
	cancelCurrentAppointment: () => Promise.resolve(''),
	bookNewAppointment: (bookingPickupAppointment: string) => Promise.resolve(''),
});

export const useOutlet = (): IUseOutlet => useContext(OutletContext);
const OutletContextProvider = ({ children }: { children: any }): any => {
	const [selectedOutlet, setSelectedOutlet] = useState<IOutlet>(EMPTY_OUTLET);
	const [availableOutlets, setAvailableOutlets] = useState<IOutlet[]>([]);
	const [bookingPickupAppointment, setBookingPickupAppointment] = useState<string>('');
	const [ticketNumber, setTicketNumber] = useState<string>('');
	const [bookingPickupAppointmentId, setBookingPickupAppointmentId] = useState<string>('');
	const [userContactDetails, setUserContactDetails] = useState<IUserContactDetails>();
	const [alertVisibility, setAlertVisibility] = useState<boolean>(false);

	const [disabledDates, setDisabledDates] = useState<string[]>([]);
	const { isBuyNowPayLaterInCart } = useBuyNowPayLater();

	const getSameOutletsInArrays = (array1: IOutlet[], array2: IOutlet[]) => {
		return array1.filter((object1) => {
			return array2.some((object2) => {
				return (
					object1.branchId === object2.branchId && object1.available === true && object2.available === true
				);
			});
		});
	};

	const getAvailableOutlets = (partId: (string | undefined)[]) => {
		let outlets: IOutlet[] = [];
		if (isBuyNowPayLaterInCart) {
			setAvailableOutlets([]);
		} else {
			partId.forEach((id, index) => {
				if (index === 0 && !!id) {
					ProductsService.getProductOutletsPickup(id).subscribe(
						(r) => {
							outlets = r.returnValue.outlets;
							setAvailableOutlets(outlets);
						},
						() => {}
					);
				} else if (outlets.length !== 0 && !!id) {
					ProductsService.getProductOutletsPickup(id).subscribe(
						(r) => {
							outlets = getSameOutletsInArrays(outlets, r.returnValue.outlets);
							setAvailableOutlets(outlets);
						},
						() => {}
					);
				}
			});
		}
	};

	const cancelCurrentAppointment = () =>
		new Promise((resolve: (value: string) => void, reject: (value: string) => void) => {
			AppointmentService.cancelAppointment({ appointmentId: bookingPickupAppointmentId }).subscribe(
				(response) => {
					setTicketNumber('');
					setBookingPickupAppointment('');
					setBookingPickupAppointmentId('');
					resolve(response.returnValue.resultDescription);
				},
				(e) => {
					reject(e);
				}
			);
		});

	const bookNewAppointment = (bookingPickupAppointment: string) =>
		new Promise((resolve: (str: string) => void, reject: (str: string) => void) => {
			const payload = {
				branchId: selectedOutlet.branchId,
				branchName: selectedOutlet.branchName ?? '',
				serviceId: PICKUP_OUTLET_BOOKING_SERVICE_ID,
				fullName: `${userContactDetails?.firstName} ${userContactDetails?.middleName ?? ''} ${
					userContactDetails?.lastName
				}`,
				contactNumber: userContactDetails?.phoneNumber ?? '',
				serviceNumber: userContactDetails?.phoneNumber ?? '',
				appointmentDate: moment(bookingPickupAppointment).format('YYYY-MM-DD HH:mm'),
			};
			AppointmentService.bookAppointment(payload).subscribe(
				(r) => {
					setBookingPickupAppointment(bookingPickupAppointment);
					setTicketNumber(r.returnValue.ticketNumber);
					AppointmentService.getAppointments({
						contactNumber: userContactDetails?.phoneNumber ?? '',
					}).subscribe(
						(appointmentsResponse) => {
							const appointment = appointmentsResponse.returnValue.find(
								(appointment) => appointment.ticketNumber === r.returnValue.ticketNumber
							);
							if (appointment) {
								setBookingPickupAppointmentId(appointment.id);
								resolve(appointment.id);
							} else reject(userContactDetails?.phoneNumber ?? '');
						},
						(e) => {
							reject(e);
						}
					);
				},
				(r) => {
					if (ERROR_CODES.includes(r.response.code)) {
						setDisabledDates([...disabledDates, bookingPickupAppointment]);
						reject(r.response.code);
					}
				}
			);
		});

	return (
		<OutletContext.Provider
			value={{
				selectedOutlet,
				availableOutlets,
				bookingPickupAppointment,
				ticketNumber,
				alertVisibility,
				disabledDates,
				getAvailableOutlets,
				setSelectedOutlet,
				setAvailableOutlets,
				setTicketNumber,
				setAlertVisibility,
				setUserContactDetails,
				cancelCurrentAppointment,
				bookNewAppointment,
			}}
		>
			{children}
		</OutletContext.Provider>
	);
};

export default OutletContextProvider;
