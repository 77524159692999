import styled from 'styled-components';

export const ContentWrapper = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	> div {
		width: 88px;
	}
`;
