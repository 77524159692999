import React, { FunctionComponent } from 'react';
import { KeywordWrapper, Label, IconWrapper } from './Keyword.styled';
import Icon from '@Components/Icon/Icon';
import useTranslate from '@Utils/hooks/useTranslate';

interface IKeyword {
	keyword: string;
	onClick: () => void;
}

const Keyword: FunctionComponent<IKeyword> = ({ keyword, onClick }) => {
	const { translate } = useTranslate();

	return (
		<KeywordWrapper>
			<Label>{translate('keyword', keyword)}</Label>
			<IconWrapper onClick={onClick}>
				<Icon name="clearInput" fill="black87" />
			</IconWrapper>
		</KeywordWrapper>
	);
};

export default Keyword;
