import { PrimaryCircularLoader } from '@Components/CircularLoader/CircularLoader';
import ConditionalRender from '@Components/ConditionalRender/ConditionalRender';
import { AuthenticationService } from '@Services/authentication/authenticationService';
import { PaymentService } from '@Services/payment/payment';
import { useBuyNowPayLater } from '@Utils/context/BuyNowPayLaterContext';
import { useCart } from '@Utils/context/CartContext';
import { getLang } from '@Utils/language/language';
import React, { FunctionComponent, useEffect } from 'react';
import { useHistory } from 'react-router';
import { LoaderContainer } from './BuyNowPayLater.styled';
import { setBuyNowPayLater } from '@Redux/modules/buyNowPayLater/actions';
import { useDispatch, useSelector } from 'react-redux';
import { Subscription } from 'rxjs';
import { isDisabledBnplCr7985 } from '@Config/features.config';
import { remoteConfigSelector } from '@Redux/modules/settings/remoteConfig/selectors';

const ThawaniRoute = () => {
	const { buyNowPayLaterIframeUrl } = useBuyNowPayLater();
	window.location.href = buyNowPayLaterIframeUrl;
	return null;
};

const BuyNowPayLater: FunctionComponent = () => {
	const pathname = window.location.href.toLocaleLowerCase();
	const history = useHistory();
	const { cart } = useCart();
	const preorderDate = cart?.items[0]?.plan?.preorderDate;
	const dispatch = useDispatch();
	const { creditRating } = useSelector((state: types.redux.IState) => state.api.personalData);
	const remoteFeatureConfig = useSelector(remoteConfigSelector);
	const DISABLE_BNPL_CR_ODF_7985 = isDisabledBnplCr7985(remoteFeatureConfig);
	// mock for skip the iframe
	// useEffect(() => {
	// 	if (pathname.includes('/buy-now-pay-later') && !pathname.includes('/buy-now-pay-later-redirect')) {
	// 		AuthenticationService.retriveBuyNowPayLaterSession(cart.orderId).subscribe(() => {
	// 			history.push(`/${getLang()}/buy-now-pay-later-redirect`);
	// 		});
	// 	}
	// }, [pathname]);

	useEffect(() => {
		let subscription: Subscription | undefined;
		if (pathname.includes('/buy-now-pay-later-redirect') && !!cart.orderId) {
			subscription = AuthenticationService.retriveBuyNowPayLaterSession(cart.orderId).subscribe(
				(r) => {
					if (r.session.BNPLSI.includes('approved')) {
						PaymentService.doCheckout().subscribe(
							(r) => {
								dispatch(
									setBuyNowPayLater({
										buyNowPayLaterSessionId: '',
										buyNowPayLaterIframeUrl: '',
										isBuyNowPayLaterIframe: false,
									})
								);
								history.push(
									`/${getLang()}/payment/success?orderId=${r.orderId}${
										preorderDate ? '&preorder=' + preorderDate : ''
									}&bnpl=true`
								);
							},
							() => {
								history.push(`/${getLang()}/payment/failure`);
							}
						);
					} else {
						history.push(`/${getLang()}/buy-now-pay-later`);
					}
				},
				() => {
					history.push(`/${getLang()}/buy-now-pay-later`);
				}
			);
		}
		return () => {
			subscription?.unsubscribe();
		};
	}, [pathname, cart]);

	// 	window.location.href = buyNowPayLaterIframeUrl;
	// return null
	return (
		<ConditionalRender
			show={!pathname.includes('/buy-now-pay-later-redirect')}
			onTrue={() => <ThawaniRoute />}
			onFalse={() => (
				<>
					<LoaderContainer>
						<PrimaryCircularLoader />
					</LoaderContainer>
				</>
			)}
		/>
	);
};

export default BuyNowPayLater;
