import React, { FunctionComponent } from 'react';
import { BottomContentWrapper, DisplayFlex, Rectangular, SkeletonRow } from '@Components/Skeleton/Skeleton.styled';

const SearchResultSkeleton: FunctionComponent = () => {
	return (
		<DisplayFlex divHeight="240">
			<BottomContentWrapper height={300}>
				{[1, 2, 3, 4].map((key) => (
					<SkeletonRow key={key} marginBottom={16}>
						<DisplayFlex justifyContent="space-between">
							<Rectangular width="100%" height={76} color="grey300" />
						</DisplayFlex>
					</SkeletonRow>
				))}
			</BottomContentWrapper>
		</DisplayFlex>
	);
};
export default SearchResultSkeleton;
