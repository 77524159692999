import { BodyS, ParagraphS, ParagraphSBold, TitleBold } from '@Components/Typography/Typography.styled';
import { marginLeft, matchScreen } from '@Utils/layout/layoutSupport';
import styled from 'styled-components';

export const HeaderContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-top: 8px;
`;

export const OTPCardContainer = styled.div<{ isXS: boolean }>`
	background: white;
	max-width: 100%;
	${({ isXS }) => ({
		padding: isXS ? '16px' : '24px 40px',
	})}
	${() =>
		matchScreen('xs') && {
			height: '100%',
		}}
`;

export const IconButton = styled.div`
	cursor: pointer;
	padding: 8px;
`;

export const TitleWrapper = styled(TitleBold)`
	${({ theme }) => ({
		color: theme.colors.black87,
	})}
	margin-top: 40px;
`;

export const SubheaderWrapper = styled(ParagraphS)`
	${({ theme }) => ({
		color: theme.colors.black87,
	})}
`;

export const SubheaderBoldWrapper = styled(ParagraphSBold)`
	padding: 0 4px;
	${({ theme }) => ({
		color: theme.colors.black87,
	})};
`;

export const SubheaderContainer = styled.p`
	margin-top: 16px;
	width: 90%;
	p {
		display: inline;
	}
`;

export const OTPInputsContainer = styled.div`
	margin-top: 16px;
	position: relative;
`;

export const Horizontal = styled.div`
	margin-top: 16px;
	display: flex;
	gap: 8px;
`;

export const ResendButtonContainer = styled.div`
	margin-top: -16px;
	${() => marginLeft(-16)}
	position: relative;
`;

export const ButtonsContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	margin-top: 28px;
	z-index: 20;
	position: relative;
`;

export const BackgroundContainer = styled.div`
	position: absolute;
	bottom: 0;
	right: 0;
`;

export const TermsContainer = styled.div`
	display: flex;
	position: relative;
	align-items: center;
	margin-top: 12px;
`;

export const TermsText = styled(BodyS)`
	${({ theme }) => ({
		color: theme.colors.black87,
	})};
	margin: 0 8px;
`;

export const TermsButton = styled.span`
	${({ theme }) => ({
		color: theme.colors.primary,
	})}
	cursor: pointer;
	text-decoration: underline;
`;
