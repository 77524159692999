import React from 'react';
import {
	CardContainer,
	CloseIconWrapper,
	HeaderWrapper,
	HeaderTitle,
	TCTitleWrapper,
	TCSubtitleWrapper,
	ContentWrapper,
	Content,
	TermsAndConditionsTitle,
} from './InsuranceTermsAndConditions.styled';
import useTranslate from '@Utils/hooks/useTranslate';
import Icon from '@Components/Icon/Icon';
// import { BodyM, TitleBold } from '@Components/Typography/Typography';
import { useDispatch } from 'react-redux';
import { setModal } from '@Redux/modules/modal/actions';
import Divider from '@Components/Divider/Divider';

const InsuranceTermsAndConditions = ({
	params: { vehicleInformation, selectedProvider, content },
}: types.cards.IInsuranceTermsAndConditionsCardProps): JSX.Element => {
	const { translate } = useTranslate();
	const dispatch = useDispatch();

	const handleClose = () => {
		dispatch(
			setModal({
				type: null,
				data: null,
			})
		);
	};

	return (
		<CardContainer>
			<HeaderWrapper>
				<HeaderTitle>{translate('product.additional.section.title.terms.and.conditions')}</HeaderTitle>
				<CloseIconWrapper onClick={handleClose}>
					<Icon name="close" fill="black" width={24} height={24} />
				</CloseIconWrapper>
			</HeaderWrapper>
			<Divider marginBottom={10} marginTop={10} />
			<TCTitleWrapper>{selectedProvider?.name}</TCTitleWrapper>
			<TCSubtitleWrapper>
				{vehicleInformation?.manufacturer}, {vehicleInformation?.model}
			</TCSubtitleWrapper>
			<Divider marginBottom={30} withoutLine />
			<TermsAndConditionsTitle>{translate('terms.and.conditions.title')}</TermsAndConditionsTitle>
			<Divider marginBottom={30} withoutLine />
			<ContentWrapper>
				<Content>{content}</Content>
			</ContentWrapper>
		</CardContainer>
	);
};

export default InsuranceTermsAndConditions;
