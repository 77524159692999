import React, { ComponentType } from 'react';
import { GoogleMap, Marker } from 'react-google-maps';
import MarkerIcon from '@Assets/images/marker.png';
import withGoogleMap from 'react-google-maps/lib/withGoogleMap';
import { compose, withProps } from 'recompose';

interface IMapProps {
	mapElement: JSX.Element;
	zoom: number;
	position?: google.maps.LatLngLiteral;
	markerPosition?: google.maps.LatLngLiteral;
	onClick?: (event: google.maps.MapMouseEvent) => void;
	mapsRef?: any;
	height?: number;
	locked?: boolean;
	googleMapURL?: string;
	containerElement?: JSX.Element;
	zoomControl?: boolean;
	markerArray?: google.maps.LatLngLiteral[];
}

export const MAX_ZOOM = 18;
export const MIN_ZOOM = 2;
export const DEFAULT_MAP_POSITION = { lat: 23.5985043, lng: 58.4448154 };

const Map: ComponentType<IMapProps> = ({
	zoom,
	position = DEFAULT_MAP_POSITION,
	mapsRef,
	markerPosition,
	onClick,
	locked,
	zoomControl,
	markerArray,
}) => {
	return (
		<GoogleMap
			defaultZoom={16}
			zoom={zoom}
			defaultCenter={position}
			defaultMapTypeId="roadmap"
			options={{
				mapTypeControl: false,
				streetViewControl: false,
				zoomControl: !!zoomControl ? zoomControl : false,
				fullscreenControl: false,
				minZoom: MIN_ZOOM,
				maxZoom: MAX_ZOOM,
				clickableIcons: false,
				gestureHandling: locked ? 'none' : undefined,
			}}
			onClick={(event) => {
				onClick?.(event);
			}}
			ref={mapsRef}
		>
			{markerPosition && (
				<Marker
					position={markerPosition}
					icon={{ url: MarkerIcon, scaledSize: { width: 32, height: 39, equals: () => false } }}
					animation={google.maps.Animation.DROP}
					noRedraw={false}
				/>
			)}
			{markerArray &&
				markerArray.map((marker) => (
					<Marker
						key={marker.lat}
						position={marker}
						icon={{ url: MarkerIcon, scaledSize: { width: 32, height: 39, equals: () => false } }}
						animation={2}
						noRedraw={false}
					/>
				))}
		</GoogleMap>
	);
};

export default compose<IMapProps, IMapProps>(withProps({ loadingElement: <></> }), withGoogleMap)(Map);
