import styled from 'styled-components';

export const CardWrapper = styled.div`
	padding: 8px 16px;
`;

export const EmptyShippment = styled.div`
	align-self: stretch;
	flex-grow: 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 32px 40px;
	border-radius: 8px;
	${({ theme }) => ({
		backgroundColor: theme.colors.grey50,
	})}
`;

export const EmptyShippmentIcons = styled.div`
	display: flex;
	justify-content: space-between;
	width: 250px;
`;

export const ButtonContent = styled.div`
	div {
		width: 200px;
	}
`;

export const Orders = styled.div``;
export const Order = styled.div``;
