import React, { FunctionComponent } from 'react';
import { SkeletonRow, Rectangular } from '@Components/Skeleton/Skeleton.styled';

const ProductTitleSkeleton: FunctionComponent = () => {
	return (
		<SkeletonRow marginBottom={16}>
			<Rectangular width={180} height={46} color="grey300" />
		</SkeletonRow>
	);
};

export default ProductTitleSkeleton;
