import { ActionFunction1, createAction } from 'redux-actions';

export interface ISetRemoteConfigAction {
	payload: any;
}

const setRemoteConfig: ActionFunction1<ISetRemoteConfigAction, any> = createAction(
	'SET_REMOTE_CONFIG',
	(action: ISetRemoteConfigAction) => action
);

export { setRemoteConfig };
