import ConditionalRender from '@Components/ConditionalRender/ConditionalRender';
import { isRTL } from '@Utils/layout/layoutSupport';
import React, { FunctionComponent } from 'react';
import sanitizeHtml, { AllowedAttribute } from 'sanitize-html';
import { JawwyBanner, LongDescWrapper } from './LongDescription.styled';

interface ILongDescription {
	description?: string;
	allowedAttributes?: { [index: string]: AllowedAttribute[] } | boolean;
	isJawwyTv?: boolean;
}

const LongDescription: FunctionComponent<ILongDescription> = ({ description, allowedAttributes, isJawwyTv }) => {
	return (
		<>
			<LongDescWrapper
				dangerouslySetInnerHTML={{
					__html: `${sanitizeHtml(description ?? '', { allowedAttributes })}`,
				}}
			></LongDescWrapper>
			<ConditionalRender
				show={!!isJawwyTv}
				onTrue={() => (
					<JawwyBanner
						src={`https://store.omantel.om/wcsstore/eshop//eshop2/icons/banner/apple-tv-boxes/${
							isRTL() ? 'ar' : 'en'
						}/apple-tv-boxes.png`}
					/>
				)}
			/>
		</>
	);
};

export default LongDescription;
