import { ENV } from '@Config/app.config';
import { ICategories } from '@ApiModels/flatCategories';
import { IMarketingBanners } from '@ApiModels/marketingBanners';
import { Mocks } from '@Assets/json';

interface IMenuResponseData extends types.models.IInputModel, ICategories {
	_contents: any;
	contents: any;
}
let menuResponseMock: IMenuResponseData = {} as IMenuResponseData;

let restBannersResponseMock: IMarketingBanners = {} as IMarketingBanners;
let tradeInBannersResponseMock: IMarketingBanners = {} as IMarketingBanners;

menuResponseMock = ENV === 'STG' ? Mocks.menuResponseMockSTG : Mocks.menuResponseMockPROD;

restBannersResponseMock = ENV === 'STG' ? Mocks.bannersResponseMockSTG : Mocks.bannersResponseMockPROD;
tradeInBannersResponseMock = ENV === 'STG' ? Mocks.tradInBannersResponseMockSTG : Mocks.tradInBannersResponseMockPROD;

export { menuResponseMock, restBannersResponseMock, tradeInBannersResponseMock };
