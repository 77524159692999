import api from '@Utils/api';
import { Observable } from 'rxjs';
import { ITypeaheadSuggestions } from '@ApiModels/typeaheadProductsBySearchTerm';
import { AjaxResponse } from 'rxjs/ajax';

export class TypeaheadService {
	static getProductSuggestionsBySearchTerm(searchTerm: string): Observable<ITypeaheadSuggestions> {
		return api.omantelShop.getProductSuggestionsBySearchTerm(searchTerm).call();
	}

	static getProductSuggestionsBySearchTermFromPortal(
		searchTerm: string,
		results?: number,
		scope?: string
	): Observable<AjaxResponse> {
		return api.omantelPortal
			.getProductSuggestionsBySearchTermFromPortal(
				searchTerm,
				(results = 5),
				(scope = 'com.ibm.lotus.search.ALL_SOURCES')
			)
			.call();
	}
}
