import { addBusyIndicator, removeBusyIndicator } from '@Redux/modules/busyIndicator/actions';
import { AddressType, setCheckoutDetails, clearCheckoutDetails } from '@Redux/modules/checkout/actions';
import { dispatch } from '@Redux/store';
import { CommerceProfileService } from '@Services/profile/commerceProfileService';
import { ProfileService } from '@Services/profile/profileService';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

interface IUpdateProfileFunction {
	billingAddress?: types.redux.IState['checkout']['billingAddress'];
	shipmentAddress?: types.redux.IState['checkout']['shipmentAddress'];
	contactDetails?: types.redux.IState['checkout']['contactDetails'];
	updateShipping?: boolean;
}

interface IUseCommerceProfileHook {
	billingAddress: types.redux.IState['checkout']['billingAddress'];
	shipmentAddress: types.redux.IState['checkout']['shipmentAddress'];
	contactDetails: types.redux.IState['checkout']['contactDetails'];
	updateProfile: (values: IUpdateProfileFunction, onFinish?: (addressId: string) => void) => void;
	shipmentAddressIsSameAsBilling: boolean;
	setSelectedAddressType: (type: AddressType) => void;
	addressType: AddressType;
	removeCheckoutDetails: () => void;
}

interface IUseCommerceProfileHookProps {
	busyIndicatorListener?: string;
	afterUpdate?: () => void;
}

export default ({ busyIndicatorListener, afterUpdate }: IUseCommerceProfileHookProps = {}): IUseCommerceProfileHook => {
	const billingAddress = useSelector((state: types.redux.IState) => state.checkout.billingAddress);
	const shipmentAddress = useSelector((state: types.redux.IState) => state.checkout.shipmentAddress);
	const contactDetails = useSelector((state: types.redux.IState) => state.checkout.contactDetails);
	const isSignedIn = useSelector((state: types.redux.IState) => state.api.authentication.signedIn);
	const addressType = useSelector((state: types.redux.IState) => state.checkout.shipmentSameOrDiffrent ?? 'same');
	const shipmentAddressIsSameAsBilling = addressType === 'same';
	const telesales = useSelector((state: types.redux.IState) => state.api.authentication.telesales);

	const getUserProfileFromOmantelApi = () => {
		ProfileService.getUserProfile(false, telesales).subscribe(
			(response) => {
				const { firstName, lastName, email, uid } = response;
				dispatch(
					setCheckoutDetails({
						contactDetails: {
							title: '',
							firstName,
							middleName: '',
							lastName,
							email,
							phoneNumber: uid,
						},
					})
				);
				busyIndicatorListener && dispatch(removeBusyIndicator(busyIndicatorListener));
			},
			() => {
				busyIndicatorListener && dispatch(removeBusyIndicator(busyIndicatorListener));
			}
		);
	};

	useEffect(() => {
		busyIndicatorListener && dispatch(addBusyIndicator(busyIndicatorListener));
		CommerceProfileService.getUserProfile().subscribe(
			({ contactDetails }) => {
				if (contactDetails) {
					dispatch(
						setCheckoutDetails({
							contactDetails,
						})
					);
					busyIndicatorListener && dispatch(removeBusyIndicator(busyIndicatorListener));
				} else if (isSignedIn) {
					getUserProfileFromOmantelApi();
				} else {
					busyIndicatorListener && dispatch(removeBusyIndicator(busyIndicatorListener));
				}
			},
			() => {
				if (isSignedIn) {
					getUserProfileFromOmantelApi();
				} else {
					busyIndicatorListener && dispatch(removeBusyIndicator(busyIndicatorListener));
				}
			}
		);
	}, []);

	const updateProfile = (
		{ billingAddress, contactDetails, updateShipping }: IUpdateProfileFunction,
		onFinish?: (addressId: string) => void
	) => {
		const addressType =
			billingAddress || contactDetails ? (!!updateShipping ? 'Shipping' : 'ShippingAndBilling') : 'Shipping';
		busyIndicatorListener && dispatch(addBusyIndicator(busyIndicatorListener));

		CommerceProfileService.updateUserProfile(addressType, {
			...billingAddress,
			addressType,
			nickName: addressType,
			...contactDetails,
		}).subscribe(
			(response) => {
				busyIndicatorListener && dispatch(removeBusyIndicator(busyIndicatorListener));
				dispatch(
					setCheckoutDetails({
						contactDetails,
					})
				);
				afterUpdate?.();
				onFinish?.(response.addressId);
			},
			() => {
				busyIndicatorListener && dispatch(removeBusyIndicator(busyIndicatorListener));
			}
		);
	};

	const setSelectedAddressType = (type: AddressType) => {
		dispatch(
			setCheckoutDetails({
				shipmentSameOrDiffrent: type,
			})
		);
	};

	const removeCheckoutDetails = () => {
		dispatch(clearCheckoutDetails());
	};

	return {
		billingAddress,
		shipmentAddress,
		contactDetails,
		updateProfile,
		shipmentAddressIsSameAsBilling,
		setSelectedAddressType,
		addressType,
		removeCheckoutDetails,
	};
};
