import * as Yup from 'yup';
import useTranslate from '@Utils/hooks/useTranslate';

export default (): Yup.AnyObjectSchema => {
	const { translate } = useTranslate();

	const shape = {
		cvv: Yup.string()
			.min(3, translate('validation.creditCards.securityCode.min'))
			.matches(/\d{3}/, translate('validation.creditCards.securityCode.format'))
			.required(translate('validation.creditCards.securityCode.required')),
		expire: Yup.string()
			.matches(/[0][1-9]|[1][0-2](\/[0-9][0-9])/, translate('validation.creditCards.expDate'))
			.test(
				translate('validation.creditCards.expired'),
				translate('validation.creditCards.expired'),
				(expDate) => {
					if (!expDate) {
						return false;
					}
					const now = new Date();
					const monthToday = now.getMonth() + 1;
					const yearToday = now.getFullYear().toString().substr(-2);
					const [expMonth, expYear] = expDate.split('/');
					if (Number(expYear) < Number(yearToday)) {
						return false;
					} else if (Number(expMonth) < monthToday && Number(expYear) <= Number(yearToday)) {
						return false;
					}
					return true;
				}
			)
			.required(translate('validation.creditCards.expDate.required')),
		name: Yup.string()
			.trim()
			.matches(/^[^0-9]+$/, translate('validation.creditCards.name.letters'))
			.matches(/\D+ \D+/, translate('validation.creditCards.name.format'))
			.required(translate('validation.creditCards.name.required')),
		cardNumber: Yup.string()
			.matches(
				/((^4[0-9]\d{2})|(^5[1-5]\d{2}) \d{4} \d{4} \d{4})|(^3[47]\d{2} \d{4} \d{4} \d{3})/,
				translate('validation.creditCards.numbers')
			) // visa, mastercard, amex
			.required(translate('validation.creditCards.numbers.required')),
		savePaymentToken: Yup.boolean(),
	};

	return Yup.object().shape(shape);
};
