import { Action, ActionFunction1, createAction } from 'redux-actions';

export interface IOrderHistory {
	payload: boolean;
}

export const updateOrderHistory: ActionFunction1<
	IOrderHistory['payload'],
	Action<IOrderHistory['payload']>
> = createAction('UPDATE_CART', (action: IOrderHistory['payload']) => action);
