import styled from 'styled-components';
import { BodyM, DisplayMBold, TitleBold } from '@Components/Typography/Typography';

export const CartViewConatiner = styled.div`
	margin-bottom: 24px;
`;

export const CartViewTitle = styled(DisplayMBold)`
	${({ theme }) => ({
		color: theme.colors.black87,
		marginBottom: `24px`,
	})}
`;

export const FixedCheckoutCard = styled.div`
	width: 100%;
	position: fixed;
	bottom: 0;
	z-index: 79;
	${({ theme }) => ({
		backgroundColor: theme.colors.white,
	})};
	box-shadow: 0 7px 8px 0 rgba(0, 0, 0, 0.05), 0 0 22px 4px rgba(0, 0, 0, 0.04), 0 12px 17px 2px rgba(0, 0, 0, 0.04);
`;

export const ButtonWrapper = styled.div`
	padding: 16px;
	width: 288px;
	align-self: center;
`;

export const EmptyStateTitle = styled(DisplayMBold)`
	text-align: center;
	padding: 24px 0;
	${({ theme }) => ({
		color: theme.colors.black87,
	})}
`;
export const EmptyStateDescription = styled(BodyM)`
	text-align: center;
	padding: 0 16px;
	${({ theme }) => ({
		color: theme.colors.black54,
	})}
`;

export const StickyWrapper = styled.div`
	position: sticky;
	top: 154px;
	padding-bottom: 8px;
`;

export const TitleWrapper = styled(TitleBold)`
	${({ theme }) => ({
		color: theme.colors.black87,
	})};
	padding: 24px 16px;
`;

export const CardContainer = styled.div`
	${({ theme }) => {
		const styles = {
			background: theme.colors.white,
			borderRadius: '8px',
			marginBottom: 10,
			width: '100%',
			zIndex: 5,
		};
		return styles;
	}}
`;
