import React from 'react';
import { useInsurance } from '@Utils/context/InsuranceContext';
import useTranslate from '@Utils/hooks/useTranslate';
import Icon from '@Components/Icon/Icon';
import {
	ValueWrapper,
	ValueRangeWrapper,
	IconWrapper,
	DisplayMBoldWrapper,
	HeadlineBoldWrapper,
	BodySWrapper,
	HeaderWrapper,
	BigCurrencyWrapper,
	VehicleValueWrapper,
	VehicleValueCardWrapper,
} from './InsuranceVehicleValue.styled';

const InsuranceVehicleValueCard = (): JSX.Element => {
	const { vehicleInformation } = useInsurance();

	const { translate } = useTranslate();

	return (
		<>
			<VehicleValueCardWrapper>
				<IconWrapper>
					<Icon name="vehicleValue" width="40" height="40" fill="black54" />
				</IconWrapper>
				<VehicleValueWrapper>
					<ValueWrapper>
						<HeadlineBoldWrapper>{translate('omr')}</HeadlineBoldWrapper>
						<DisplayMBoldWrapper>{vehicleInformation?.vehicleValue}</DisplayMBoldWrapper>
					</ValueWrapper>
					<BodySWrapper>{translate('insurance.vehicle.value.label')}</BodySWrapper>
					<ValueRangeWrapper>
						<BigCurrencyWrapper>{translate('omr')}</BigCurrencyWrapper>
						<HeaderWrapper>
							{vehicleInformation?.estimatedValueFrom}-{vehicleInformation?.estimatedValueTo}
						</HeaderWrapper>
					</ValueRangeWrapper>
					<BodySWrapper>{translate('insurance.vehicle.value.estimates.label')}</BodySWrapper>
				</VehicleValueWrapper>
			</VehicleValueCardWrapper>
		</>
	);
};

export default InsuranceVehicleValueCard;
