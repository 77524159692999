import theme from './theme';

type themeColors = types.theme.themeColors;

const themeColor = (themeColorName?: themeColors, defaultColor?: themeColors): types.color => {
	if (themeColorName && theme.colors.hasOwnProperty(themeColorName)) {
		return theme.colors[themeColorName];
	}
	const _defaultColor = defaultColor || ('black' as themeColors);
	return theme.colors[_defaultColor];
};

export default themeColor;
