import styled from 'styled-components';
import { Subheading } from '@Components/Typography/Typography';

export const FiltersWrapper = styled.div`
	display: flex;
	flex-direction: column;
`;

export const SubheadingWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-direction: row;
	margin-bottom: 16px;
`;

export const SubheaderText = styled(Subheading)`
	text-transform: uppercase;
	padding: 8px;
	${({ theme }) => ({
		color: theme.colors.black54,
	})}
`;
