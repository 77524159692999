import styled from 'styled-components';
import { NAVBAR_HEIGHT } from '@Layouts/Dashboard/Dashboard';
import { isLargeScreen, matchScreen } from '@Utils/layout/layoutSupport';

export const MenuWrapper = styled.div`
	${({ theme }) => {
		const isSmallDevice = matchScreen(['xs', 'sm']);
		const marginTop = isLargeScreen() ? 0 : isSmallDevice ? 44 : 12;
		return {
			backgroundColor: theme.colors.black2,
			marginTop: `${NAVBAR_HEIGHT - marginTop}px`,
		};
	}}
	width:100%;
	height: 40px;
	overflow: hidden;
`;

export const AppleMenuElement = styled.div`
	margin-right: 72px;
	margin-top: 12px;
	cursor: pointer;
	white-space: normal;
`;

export const ElementsWrapper = styled.div`
	display: flex;
	flex-direction: row;
	width: 100%;
	height: 100%;
	padding-bottom: 17px;
	overflow-x: scroll;
	box-sizing: content-box;
`;
