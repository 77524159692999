import localforage from 'localforage';
import { applyMiddleware, compose, createStore, Dispatch, Store } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { persistReducer, persistStore } from 'redux-persist';
import { createWhitelistFilter } from 'redux-persist-transform-filter';
import thunk from 'redux-thunk';
import rootReducer from './rootReducer';
const middlewares = [thunk];

const whiteListFilterApi = createWhitelistFilter('api', ['cache', 'authentication', 'headers']);
const whiteListFilterSettings = createWhitelistFilter('settings', ['language', 'layout', 'viewType', 'config']);
const whiteListFilterConfigurator = createWhitelistFilter('configurator');
const whiteListFilterPersistentCheckout = createWhitelistFilter('persistentCheckout');

const persistConfig = {
	key: 'root',
	storage: localforage,
	transform: [
		whiteListFilterApi,
		whiteListFilterSettings,
		whiteListFilterConfigurator,
		whiteListFilterPersistentCheckout,
	],
	whitelist: [
		'api',
		'settings',
		'translation',
		'paymentTokens',
		'configurator',
		'settingsCheckout',
		'persistentCheckout',
		'config',
		'buyNowPayLater',
	],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store: Store<types.redux.IState> = createStore(
	persistedReducer,
	compose(applyMiddleware(...middlewares), composeWithDevTools() ? composeWithDevTools() : compose)
);

// persist store
export const persistor = persistStore(store, undefined);

export const dispatch: Dispatch = (action) => {
	return store.dispatch(action);
};

export default store;
