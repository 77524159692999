import { BillingType, IAccount } from '@ApiModels/accounts';
import BusyIndicator from '@Components/BusyIndicator/BusyIndicator';
import { addBusyIndicator, removeBusyIndicator } from '@Redux/modules/busyIndicator/actions';
import { AccountsService } from '@Services/accounts/accountsService';
import { UsageService } from '@Services/usage/usage';
import useTranslate from '@Utils/hooks/useTranslate';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { Col } from 'react-grid-system';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import {
	LeftSectionWrapper,
	UnlimitedContainer,
	ValueWrapper,
	LoggedInfoBoxWrapper,
	SmallTitleWrapper,
	MegaMenuCustomButton,
	LoggedButtonsWrapper,
	MakasibLabelWrapper,
	TierLabelWrapper,
	PointsWrapper,
	ButtonLabelWrapper,
	EmailOrderWrapper,
	CheckyourEmailWrapper,
	ButtonIconWrapper,
	StoreOrdersWrapper,
} from '@Components/MegaMenu/subcomponents/MyOmantel/MegaMenuMyOmantel.styled';
import ConditionalRender from '@Components/ConditionalRender/ConditionalRender';
import Icon from '@Components/Icon/Icon';
import { MakasibService } from '@Services/makasib/makasib';
import BillsService from '@Services/bills/bills';
import externalLinks from '@Utils/externalLinks/externalLinks';
import { setPersonalData } from '@Redux/modules/api/personalData/actions';
import useScreen from '@Utils/hooks/useScreen';
import { BiggerHeadlineBold, BodyBoldL, ParagraphM } from '@Components/Typography/Typography.styled';
import { AuthenticationService } from '@Services/authentication/authenticationService';
import { getLang } from '@Utils/language/language';
import { updateCart } from '@Redux/modules/cart/actions';

interface ILoggedContentProps {
	forceClose: () => void;
}

const GET_ACCOUNT_BI = 'getCurrentAccount';
const GET_USAGE_BI = 'getUsage';
const MAKASIB_BI = 'getMakasibAccount';
const BILLS_BI = 'getBills';
const LOGOUT_BI = 'logout';

const LoggedContent: FunctionComponent<ILoggedContentProps> = ({ forceClose }) => {
	const history = useHistory();
	const { translate } = useTranslate();
	const dispatch = useDispatch();
	const [currentAccount, setCurrentAccount] = useState<IAccount | undefined>(undefined);
	const [tierName, setTierName] = useState('');
	const personalData = useSelector((state: types.redux.IState) => state.api.personalData);
	const { screen } = useScreen();

	useEffect(() => {
		dispatch(addBusyIndicator(GET_ACCOUNT_BI));
		AccountsService.getAccountsForLoggedUser().subscribe(
			({ accounts }) => {
				setCurrentAccount(accounts.find((acc) => acc.isMain));
				dispatch(removeBusyIndicator(GET_ACCOUNT_BI));
			},
			() => {
				dispatch(removeBusyIndicator(GET_ACCOUNT_BI));
			}
		);
	}, []);

	const getMakasibTier = (makasibPoints: number) => {
		if (makasibPoints < 3000) {
			return { tier: 'SILVER', nextTier: 3000 - makasibPoints };
		} else if (makasibPoints < 12000) {
			return { tier: 'GOLD', nextTier: 12000 - makasibPoints };
		} else if (makasibPoints < 20000) {
			return { tier: 'Platinium', nextTier: 20000 - makasibPoints };
		} else if (makasibPoints > 20000) {
			return { tier: 'Al-Ufuq', nextTier: 1 };
		} else {
			return { tier: 'UNKNOWN', nextTier: 1 };
		}
	};

	const getMakasibColor = (tier: string) => {
		if (tier === 'GOLD') return 'linear-gradient(180deg, #FFC74F 0%, #B37A00 100%)';
		else if (tier === 'Platinium') return 'linear-gradient(180deg, #B8B8B8 0%, #5A5A5A 99.91%)';
		else if (tier === 'Al-Ufuq') return 'linear-gradient(0deg, rgba(0, 0, 0, 0.87) 0%, #5A5A5A 100%)';
		else return '#909090';
	};

	const getMakasibBorder = (tier: string) => {
		if (tier === 'GOLD') return '#B37A00';
		else if (tier === 'Platinium') return '#5A5A5A';
		else if (tier === 'Al-Ufuq') return '#5A5A5A';
		else return '#909090';
	};

	const handleClickLink = (path: string) => {
		window.open(path);
	};

	useEffect(() => {
		if (currentAccount) {
			dispatch(addBusyIndicator(GET_USAGE_BI));
			UsageService.getUsage().subscribe(
				(usage) => {
					dispatch(removeBusyIndicator(GET_USAGE_BI));
					const _usageData = usage?.totals.find(
						(total) => total.type === 'DATA' && total.group === 'GENERAL'
					);
					if (_usageData) {
						if (!_usageData.unlimited) {
							const { unit, value } = _usageData.totalBalance;
							dispatch(setPersonalData({ usageData: `${value} ${unit}` }));
						} else {
							dispatch(setPersonalData({ usageData: 'unlimited' }));
						}
					}
					dispatch(setPersonalData({ usage }));
				},
				() => {
					dispatch(removeBusyIndicator(GET_USAGE_BI));
				}
			);
			if (currentAccount.accountNum) {
				dispatch(addBusyIndicator(MAKASIB_BI));
				MakasibService.getLoyaltyAccountOperation(currentAccount.accountNum).subscribe(
					({ rewardAmount, tier, pointsToExpire }) => {
						dispatch(removeBusyIndicator(MAKASIB_BI));
						if (rewardAmount) {
							dispatch(
								setPersonalData({ makasibPoints: rewardAmount, expiryMakasibPoints: pointsToExpire })
							);
						}
						if (tier) {
							setTierName(tier.name);
						}
					},
					() => {
						dispatch(removeBusyIndicator(MAKASIB_BI));
					}
				);
			}
			if (currentAccount?.billingType === BillingType.POSTPAID) {
				dispatch(addBusyIndicator(BILLS_BI));
				BillsService.getBillsHistoryForAccounts().subscribe(
					({ totalUnpaid }) => {
						dispatch(setPersonalData({ bills: totalUnpaid }));
						dispatch(removeBusyIndicator(BILLS_BI));
					},
					() => {
						dispatch(removeBusyIndicator(BILLS_BI));
					}
				);
			}
		}
	}, [currentAccount]);

	const handleLogout = () => {
		dispatch(addBusyIndicator(LOGOUT_BI));
		AuthenticationService.logout().subscribe(
			() => {
				dispatch(removeBusyIndicator(LOGOUT_BI));
				history.push(`/${getLang()}/store`);
			},
			() => {
				dispatch(removeBusyIndicator(LOGOUT_BI));
				history.push(`/${getLang()}/store`);
			},
			() => {
				dispatch(updateCart(true));
			}
		);
	};

	return (
		<LeftSectionWrapper>
			<Col xl={3} lg={3} md={3} sm={3}>
				<LoggedInfoBoxWrapper isXS={screen(['xs'])}>
					<BusyIndicator listener={GET_ACCOUNT_BI} skeleton="subheading">
						<>
							<SmallTitleWrapper>
								{translate(
									currentAccount?.billingType === BillingType.PREPAID
										? 'megamenu.my-omantel.balance'
										: 'megamenu.my-omantel.bill'
								)}
							</SmallTitleWrapper>
							<BusyIndicator
								listener={[GET_ACCOUNT_BI, GET_USAGE_BI, BILLS_BI]}
								skeleton="displayMBoldMyOmantel"
							>
								<ConditionalRender
									show={!screen(['sm'])}
									onTrue={() => (
										<ConditionalRender
											show={currentAccount?.billingType === BillingType.PREPAID}
											onTrue={() =>
												personalData.usage && (
													<BiggerHeadlineBold color="primary">
														{`${translate('omr')}\xa0`}
														{personalData.usage.balanceAvailableForPurchase}
													</BiggerHeadlineBold>
												)
											}
											onFalse={() => (
												<BiggerHeadlineBold color="primary">
													{`${translate('omr')}\xa0`}
													{personalData.bills ?? 0}
												</BiggerHeadlineBold>
											)}
										/>
									)}
									onFalse={() => (
										<ConditionalRender
											show={currentAccount?.billingType === BillingType.PREPAID}
											onTrue={() =>
												personalData.usage && (
													<BodyBoldL color="primary">
														{`${translate('omr')}\xa0`}
														{personalData.usage.balanceAvailableForPurchase}
													</BodyBoldL>
												)
											}
											onFalse={() => (
												<BodyBoldL color="primary">
													{`${translate('omr')}\xa0`}
													{personalData.bills ?? 0}
												</BodyBoldL>
											)}
										/>
									)}
								/>
							</BusyIndicator>
						</>
					</BusyIndicator>
				</LoggedInfoBoxWrapper>
			</Col>
			<Col xl={3} lg={3} md={3} sm={3}>
				<LoggedInfoBoxWrapper isXS={screen(['xs'])}>
					<SmallTitleWrapper>{translate('megamenu.my-omantel.data')}</SmallTitleWrapper>
					<BusyIndicator listener={[GET_ACCOUNT_BI, GET_USAGE_BI]} skeleton="displayMBoldMyOmantel">
						<ConditionalRender
							show={screen(['sm'])}
							onTrue={() => (
								<ConditionalRender
									show={!!personalData.usageData}
									onTrue={() => (
										<ConditionalRender
											show={personalData.usageData === 'unlimited'}
											onTrue={() => (
												<UnlimitedContainer>
													<Icon
														name="unlimited"
														width={!screen(['sm']) ? 40 : 28}
														height={!screen(['sm']) ? 40 : 28}
														fill="primary"
													/>
													<ValueWrapper icon>
														<BodyBoldL color="primary">
															{translate('megamenu.my-omantel.data-unlimited')}
														</BodyBoldL>
													</ValueWrapper>
												</UnlimitedContainer>
											)}
											onFalse={() => (
												<BodyBoldL color="primary">{personalData.usageData}</BodyBoldL>
											)}
										/>
									)}
									onFalse={() => (
										<BodyBoldL color="primary">
											{translate('megamenu.my-omantel.no-data')}
										</BodyBoldL>
									)}
								/>
							)}
							onFalse={() => (
								<ConditionalRender
									show={!!personalData.usageData}
									onTrue={() => (
										<ConditionalRender
											show={personalData.usageData === 'unlimited'}
											onTrue={() => (
												<UnlimitedContainer>
													<Icon name="unlimited" width={40} height={40} fill="primary" />
													<ValueWrapper icon>
														<BiggerHeadlineBold color="primary">
															{translate('megamenu.my-omantel.data-unlimited')}
														</BiggerHeadlineBold>
													</ValueWrapper>
												</UnlimitedContainer>
											)}
											onFalse={() => (
												<BiggerHeadlineBold color="primary">
													{personalData.usageData}
												</BiggerHeadlineBold>
											)}
										/>
									)}
									onFalse={() => (
										<BiggerHeadlineBold color="primary">
											{translate('megamenu.my-omantel.no-data')}
										</BiggerHeadlineBold>
									)}
								/>
							)}
						/>
					</BusyIndicator>
				</LoggedInfoBoxWrapper>
			</Col>
			<Col xl={3} lg={3} md={3} sm={3}>
				<LoggedInfoBoxWrapper isXS={screen(['xs'])}>
					<SmallTitleWrapper>{translate('megamenu.my-omantel.makasib')}</SmallTitleWrapper>
					<BusyIndicator listener={[GET_ACCOUNT_BI, MAKASIB_BI]} skeleton="displayMBoldMyOmantel">
						<ConditionalRender
							show={screen(['sm'])}
							onTrue={() => <BodyBoldL color="primary">{personalData.makasibPoints}</BodyBoldL>}
							onFalse={() => (
								<BiggerHeadlineBold color="primary">{personalData.makasibPoints}</BiggerHeadlineBold>
							)}
						/>
					</BusyIndicator>
				</LoggedInfoBoxWrapper>

				<MakasibLabelWrapper isXS={screen(['xs'])} isSM={screen(['sm'])}>
					<BusyIndicator listener={[GET_ACCOUNT_BI, MAKASIB_BI]} skeleton="subheading">
						<ConditionalRender
							show={getMakasibTier(Number(personalData.makasibPoints)).tier !== 'UNKNOWN'}
							onTrue={() => (
								<>
									<TierLabelWrapper
										makasibColor={getMakasibColor(
											getMakasibTier(Number(personalData.makasibPoints)).tier
										)}
										makasibBorder={getMakasibBorder(
											getMakasibTier(Number(personalData.makasibPoints)).tier
										)}
									>
										{getMakasibTier(Number(personalData.makasibPoints)).tier}
									</TierLabelWrapper>
									<ConditionalRender
										show={
											getMakasibTier(Number(personalData.makasibPoints)).tier !== 'Al-Ufuq' &&
											getMakasibTier(Number(personalData.makasibPoints)).tier !== 'UNKNOWN'
										}
										onTrue={() => (
											<PointsWrapper>
												{translate('payment.summary.next-tier')}
												{getMakasibTier(Number(personalData.makasibPoints)).nextTier}
											</PointsWrapper>
										)}
									/>
								</>
							)}
						/>
					</BusyIndicator>
				</MakasibLabelWrapper>
			</Col>
			<Col xl={3} lg={3} md={3} sm={3}>
				<LoggedButtonsWrapper isXS={screen(['xs'])}>
					<BusyIndicator listener={[LOGOUT_BI]} skeleton="circularLoader">
						<MegaMenuCustomButton
							customWidth="100%"
							primaryBackground
							height="20px"
							onClick={() => {
								window.open(externalLinks('ACCOUNT'));
							}}
						>
							<ButtonLabelWrapper>
								<ParagraphM color="white">
									{translate('megamenu.my-omantel.my-account.button')}
								</ParagraphM>
							</ButtonLabelWrapper>
						</MegaMenuCustomButton>
					</BusyIndicator>
					<BusyIndicator listener={[LOGOUT_BI]} skeleton="circularLoader">
						<MegaMenuCustomButton customWidth="100%" onClick={handleLogout} height="20px">
							<ParagraphM color="primary">{translate('megamenu.my-omantel.log-out.button')}</ParagraphM>
						</MegaMenuCustomButton>
					</BusyIndicator>
				</LoggedButtonsWrapper>
			</Col>

			<EmailOrderWrapper isXs={screen('xs')}>
				<CheckyourEmailWrapper
					margin="0px 24px 0px 0px"
					onClick={() => handleClickLink(`https://mail.omantel.net.om`)}
				>
					<ButtonIconWrapper>
						<Icon name="sms" height={16} width={16} />
					</ButtonIconWrapper>
					<ParagraphM color="primary">{translate('megamenu.my-omantel.check-email')}</ParagraphM>
				</CheckyourEmailWrapper>
				<StoreOrdersWrapper
					onClick={() => {
						forceClose();
						history.push(`/${getLang()}/account/orders/inprogress`);
					}}
				>
					<ButtonIconWrapper>
						<Icon name="shop" height={16} width={16} />
					</ButtonIconWrapper>
					<ParagraphM color="primary">{translate('megamenu.my-omantel.store-orders.button')}</ParagraphM>
				</StoreOrdersWrapper>
			</EmailOrderWrapper>
		</LeftSectionWrapper>
	);
};

export default LoggedContent;
