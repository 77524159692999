import styled, { CSSProperties } from 'styled-components';
import { IButtonStyleProps } from './Button';

export enum ButtonVariant {
	DEFAULT = 'DEFAULT',
	PRIMARY = 'PRIMARY',
	SECONDARY = 'SECONDARY',
	SECONDARY_NO_BORDER = 'SECONDARY_NO_BORDER',
	TERTIARY = 'TERTIARY',
	QUATERNARY = 'QUATERNARY',
	QUATERNARY_NEGATIVE = 'QUATERNARY_NEGATIVE',
	QUATERNARY_DISABLED = 'QUATERNARY_DISABLED',
	WARNING = 'WARNING',
	PRIMARY_ROUNDED = 'PRIMARY_ROUNDED',
	PRIMARY_ROUNDED_NEGATIVE = 'PRIMARY_ROUNDED_NEGATIVE',
	QUATERNARY_NO_BACKGROUND = 'QUATERNARY_NO_BACKGROUND',
}

export enum ButtonWidth {
	FIXED = 'FIXED',
	AUTO = 'AUTO',
	FLEX = 'FLEX',
}

export enum ButtonSize {
	LARGE = 'LARGE',
	MEDIUM = 'MEDIUM',
}

interface IButtonWrapperProps extends IButtonStyleProps {
	disabled?: boolean;
	underlayColor?: types.theme.themeColors;
	textAlign?: CSSProperties['textAlign'];
	underlayLoadingColor?: types.theme.themeColors;
}

interface IButtonContainerProps extends IButtonStyleProps {
	isLoading?: boolean;
	sideMargin?: number;
	rightPadding?: number;
	leftPadding?: number;
}

export const ButtonWrapper = styled.div<IButtonWrapperProps>`
	display: inline-block;
	${({ variant }) => {
		return {
			borderRadius: variant === ButtonVariant.PRIMARY_ROUNDED ? 100 : 4,
		};
	}}
	${({ textAlign }) => ({ textAlign })}
	${({ width }) => width === ButtonWidth.FIXED && { width: 180 }}
	${({ width }) => width === ButtonWidth.FLEX && { display: 'flex' }}
	${({ variant, theme, underlayColor = 'white' }) => {
		switch (variant) {
			case ButtonVariant.WARNING:
				return {
					backgroundColor: theme.colors.warning,
				};
			case ButtonVariant.PRIMARY:
				return {
					backgroundColor: theme.colors.primary,
				};
			case ButtonVariant.PRIMARY_ROUNDED:
				return {
					backgroundColor: theme.colors.primary,
				};
			case ButtonVariant.SECONDARY:
				return {
					backgroundColor: theme.colors[underlayColor],
				};
			case ButtonVariant.SECONDARY_NO_BORDER:
				return {
					backgroundColor: theme.colors[underlayColor],
				};
			case ButtonVariant.TERTIARY:
				return {
					backgroundColor: theme.colors.transparent,
				};
			case ButtonVariant.PRIMARY_ROUNDED_NEGATIVE:
				return {
					backgroundColor: theme.colors.transparent,
				};
			case ButtonVariant.QUATERNARY:
				return {
					backgroundColor: theme.colors.green,
				};
			case ButtonVariant.QUATERNARY_NEGATIVE:
				return {
					backgroundColor: theme.colors.transparent,
				};
			case ButtonVariant.QUATERNARY_DISABLED:
				return {
					backgroundColor: theme.colors.mintGreen,
				};
			case ButtonVariant.QUATERNARY_NO_BACKGROUND:
				return {
					backgroundColor: 'inherit',
				};
			default:
				return {
					backgroundColor: theme.colors.support2,
				};
		}
	}}
	${({ disabled, variant, theme, underlayLoadingColor }) => {
		if (disabled && (variant === ButtonVariant.DEFAULT || variant === ButtonVariant.PRIMARY)) {
			return {
				backgroundColor: underlayLoadingColor ? theme.colors[underlayLoadingColor] : theme.colors.black12,
			};
		}
	}}
`;

export const ButtonContainer = styled.button<IButtonContainerProps>`
	transition: all 0.1s ease-in-out;
	outline: none;
	background-color: transparent;
	border-radius: 4px;
	padding-top: 0;
	padding-bottom: 0;
	${({ variant, theme }) => {
		return {
			border:
				variant === ButtonVariant.SECONDARY ||
				variant === ButtonVariant.PRIMARY_ROUNDED_NEGATIVE ||
				variant === ButtonVariant.QUATERNARY_NO_BACKGROUND
					? `solid 1.5px ${theme.colors.primary}`
					: 'none',
			borderRadius:
				variant === ButtonVariant.PRIMARY_ROUNDED || variant === ButtonVariant.PRIMARY_ROUNDED_NEGATIVE
					? 100
					: 4,
		};
	}}
	${({ size }) => (size === ButtonSize.LARGE ? { height: 56 } : { height: 40 })}
	${({ width, variant, rightPadding, leftPadding }) => {
		if (width === ButtonWidth.FIXED) {
			return { width: '100%' };
		}
		if (width === ButtonWidth.FLEX && variant === ButtonVariant.SECONDARY_NO_BORDER) {
			return { padding: 0 };
		}
		return {
			paddingLeft: !!leftPadding ? leftPadding : 16,
			paddingRight: !!rightPadding ? rightPadding : 16,
		};
	}}
	${({ width }) => width === ButtonWidth.FLEX && { width: '100%' }}
	${({ sideMargin }) => sideMargin && { margin: `0 ${sideMargin}px` }}

	&:hover {
		${({ variant, disabled, isLoading, theme }) => {
			if (!disabled && !isLoading) {
				switch (variant) {
					case ButtonVariant.SECONDARY:
					case ButtonVariant.TERTIARY || ButtonVariant.QUATERNARY_NEGATIVE:
						return {
							backgroundColor: theme.colors.primary6,
						};
					default:
						return {
							backgroundColor: theme.colors.white15,
						};
				}
			}
		}}
	}
	&:active {
		${({ variant, disabled, isLoading, theme }) => {
			if (!disabled && !isLoading) {
				switch (variant) {
					case ButtonVariant.SECONDARY:
					case ButtonVariant.TERTIARY || ButtonVariant.QUATERNARY_NEGATIVE:
						return {
							backgroundColor: theme.colors.primary15,
						};
					default:
						return {
							backgroundColor: theme.colors.white30,
						};
				}
			}
		}}
	}
	${({ disabled, variant, theme }) =>
		(variant === ButtonVariant.SECONDARY || variant === ButtonVariant.QUATERNARY_NO_BACKGROUND) &&
		disabled && { borderColor: theme.colors.black12 }}
	${({ disabled }) => (disabled ? { cursor: 'auto' } : { cursor: 'pointer' })}
`;
