import React from 'react';
import { ContentWrapper } from './SegmentSelector.styled';
import ConditionalRender from '@Components/ConditionalRender/ConditionalRender';
import Segment, { ISegmentSize } from '@Components/controls/Segment/Segment';

interface ISegmentSelectorProps<T> {
	setMethod: (paymentMethod: T) => void;
	show: boolean;
	leftDisabled?: boolean;
	rightDisabled?: boolean;
	leftSegmentName: string;
	rightSegmentName: string;
	leftSegmentValue: T;
	rightSegmentValue: T;
	selectedValue: T;
	size?: ISegmentSize;
}
const SegmentSelector = <T,>({
	setMethod,
	show,
	leftDisabled = false,
	rightDisabled = false,
	leftSegmentName,
	rightSegmentName,
	leftSegmentValue,
	rightSegmentValue,
	selectedValue,
	size = ISegmentSize.BIG,
}: ISegmentSelectorProps<T>): JSX.Element => {
	return (
		<ConditionalRender
			show={show}
			onTrue={() => (
				<ContentWrapper>
					<Segment
						id={leftSegmentName}
						label={leftSegmentName}
						selected={leftSegmentValue === selectedValue}
						disabled={leftDisabled}
						onClick={() => {
							setMethod(leftSegmentValue);
						}}
						size={size}
						margin={8}
					/>
					<Segment
						id={rightSegmentName}
						label={rightSegmentName}
						selected={rightSegmentValue === selectedValue}
						disabled={rightDisabled}
						onClick={() => {
							setMethod(rightSegmentValue);
						}}
						size={size}
					/>
				</ContentWrapper>
			)}
		/>
	);
};

export default SegmentSelector;
