interface IInputModel {
	_response: {
		result: {
			requestId: string;
			returnCode: string;
			returnDesc: string;
		};
	};
}

export interface ISubmitProductOrder {
	isOK: boolean;
}

const model: types.models.IInputModel = {
	_response: ['response.submitProductOrderResponse', {}],
	isOK: (model: IInputModel) => model._response.result.returnDesc === 'Success',
};

export default model;
