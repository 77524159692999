import { BodySBold } from '@Components/Typography/Typography';
import useTranslate from '@Utils/hooks/useTranslate';
import React, { FunctionComponent } from 'react';
import { MenuItem } from './PlanMenuItem.styled';

export interface IPlanMenuItemProps {
	title: string;
	isLast?: boolean;
}

const PlanMenuItem: FunctionComponent<IPlanMenuItemProps> = ({ title, isLast }) => {
	const { translate } = useTranslate();
	return (
		<MenuItem isLast={isLast}>
			<BodySBold color="black54">{translate(title)}</BodySBold>
		</MenuItem>
	);
};
export default PlanMenuItem;
