import { Moment } from 'moment';

export interface ICommitmentDetail {
	name: string;
	status: string;
	totalPeriods: number;
	pendingPeriods: number;
	commitmentPenaltyAmount: number;
	startDate: string | Moment;
	endDate?: string | Moment;
	rate: number;
	totalAmount: number;
	remainingAmount: number;
	arborProductId: string;
	arborProductDesc: string;
}

export interface IDetail {
	subscriberNo: string;
	accountCode: string;
	commitmentDetails: ICommitmentDetail[];
}
export interface IModel {
	_getContractDetailsResponse: IDetail[];
	_detail: IDetail;
}

const model: types.models.IInputModel = {
	_getContractDetailsResponse: 'response.getContractDetailsResponse.details',
	_detail: (model: IModel) => model._getContractDetailsResponse[0],
	commitmentDetails: (model: IModel) => model._detail.commitmentDetails,
	subscriberNo: (model: IModel) => model._detail.subscriberNo,
	accountCode: (model: IModel) => model._detail.accountCode,
};

export default model;
