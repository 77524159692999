import React, { FunctionComponent } from 'react';
import { SkeletonRow, Rectangular } from '@Components/Skeleton/Skeleton.styled';

const OutletAvailability: FunctionComponent = () => {
	return (
		<SkeletonRow>
			<Rectangular width="100%" height={36} />
		</SkeletonRow>
	);
};

export default OutletAvailability;
