import RxQuery from '@Utils/api/rxQuery/rxQuery';
import endpoints from '@Utils/api/endpoints';

const eCommerce = () => new RxQuery('eCommerce');

export const getContentBySearchTerm = (term: string): RxQuery => {
	return eCommerce().setEndpoint(endpoints.eCommerce.getContentBySearchTerm(term)).setType('packages');
};

export const getPackages = (group: types.ecommerce.PackageGroup, type: types.ecommerce.PackageType): RxQuery => {
	return eCommerce()
		.withErrorHandling()
		.setEndpoint(endpoints.eCommerce.getByGroupAndType({ group, type }))
		.setType('packages');
};
