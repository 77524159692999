import styled from 'styled-components';
import { BodyM, Title } from '@Components/Typography/Typography.styled';

export interface ISizes {
	isL?: boolean;
	isMd?: boolean;
	isSm?: boolean;
	isXs?: boolean;
}

export const ProductUnavailableWrapper = styled.div<{ sizes?: ISizes }>`
	${({ sizes }) => ({
		padding: sizes?.isL
			? '48px 80px'
			: sizes?.isMd
			? '48px 90px'
			: sizes?.isSm
			? '48px 60px'
			: sizes?.isXs
			? '32px 16px'
			: '48px 128px 48px 128px',
	})}
	display: flex;
	flex-direction: column;
	align-items: center;
`;

export const ButtonWrapper = styled.div<{ isXs?: boolean }>`
	width: 240px;
	${({ isXs }) => ({
		width: isXs ? '100%' : '240px',
	})}
`;

export const ErrorTitle = styled(Title)`
	margin-top: 16px;
	margin-bottom: 24px;
	text-align: center;
	${({ theme }) => ({
		color: theme.colors.warning,
	})}
`;

export const SubTitle = styled(BodyM)<{ margin?: string }>`
	text-align: center;
	${({ theme, margin }) => ({
		color: theme.colors.black87,
		margin: margin ? margin : '0px',
	})}
`;
