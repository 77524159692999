export interface IMarketingBanners {
	banners: ISingleMarketingBanner[];
}

export interface IMarketingContentDescription {
	language: string;
	marketingText: string;
}

export interface ISingleMarketingBanner {
	title?: string;
	description?: string;
	btnTitle?: string;
	img?: string;
	url?: string;
	topBanner?: boolean;
	timerLabel?: string;
	timerFinishTime?: string;
	isMainStoreBanner?: boolean;
}

export interface IMarketingContentDescription2 {}

export interface IProperty {
	baseMarketingKey: string;
	baseMarketingValue: string;
}

interface IAttachementAsset {
	attachmentAssetStoreId: string;
	attachmentLocalAsset: string;
	attachmentAssetId: string;
	attachmentAssetPath: string;
}

export interface IBaseMarketingSpotActivityData {
	activityIdentifierID: string;
	activityPriority: string;
	baseMarketingSpotActivityID: string;
	baseMarketingSpotActivityName: string;
	baseMarketingSpotDataType: string;
	contentClickAction: string;
	contentFormatId: string;
	contentFormatName: string;
	contentId: string;
	contentName: string;
	contentStoreId: string;
	marketingContentDescription: IMarketingContentDescription[];
	MarketingContentDescription: IMarketingContentDescription2[];
	properties: IProperty[];
	attachmentAsset?: IAttachementAsset[];
	contentUrl?: string;
}

export interface IDefaultContentDisplaySequence {
	displaySequence: string;
	resultFormat: string;
	resultId: string;
}

export interface IMarketingSpotData {
	baseMarketingSpotActivityData: IBaseMarketingSpotActivityData[];
	behavior: string;
	defaultContentDisplaySequence: IDefaultContentDisplaySequence[];
	eSpotName: string;
	marketingSpotIdentifier: string;
	nextTimeLimit: string;
}

export interface IModel {
	_MarketingSpotData: IMarketingSpotData[];
	_banners: IBaseMarketingSpotActivityData[];
}

const model: types.models.IInputModel = {
	_MarketingSpotData: 'response.MarketingSpotData',
	_banners: (model: IModel) => model._MarketingSpotData[0].baseMarketingSpotActivityData,
	banners: (model: IModel) => {
		const temp: ISingleMarketingBanner[] = [];
		model?._banners?.map((banner) => {
			let marketingText: ISingleMarketingBanner = {};
			try {
				marketingText = JSON.parse(banner?.marketingContentDescription[0]?.marketingText) || '';
			} catch {}
			banner?.attachmentAsset?.map((attachment) => {
				temp.push(({
					img: attachment ? attachment.attachmentAssetPath : marketingText?.img,
					url: banner?.contentUrl ?? marketingText?.url,
					topBanner: marketingText?.topBanner,
					timerLabel: marketingText?.timerLabel,
					timerFinishTime: marketingText?.timerFinishTime,
					isMainStoreBanner: marketingText?.isMainStoreBanner,
				} as unknown) as ISingleMarketingBanner);
			});
			if (marketingText?.img) {
				temp.push(({
					img: marketingText?.img,
					url: banner?.contentUrl ?? marketingText?.url,
					topBanner: marketingText?.topBanner,
					timerLabel: marketingText?.timerLabel,
					timerFinishTime: marketingText?.timerFinishTime,
					isMainStoreBanner: marketingText?.isMainStoreBanner,
				} as unknown) as ISingleMarketingBanner);
			}
		});
		return temp;
	},
};

export default model;
