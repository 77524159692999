export interface IInventory {
	attrval: string;
	attr: string;
	sku: string;
	value: string;
	thumbnail: string;
	fullImage: string;
	listPrice: string;
	offerPrice: string;
	taxablePrice: string;
	vatPercent: string;
	vatValue: string;
}

export interface IInventoryByProductId {
	inventory: { [id: string]: IInventory[] };
}

const model: types.models.IInputModel = {
	inventory: 'response.inventory',
};

export default model;
