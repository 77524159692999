import styled from 'styled-components';
import { HeadlineBold, Status, BodyM, BodyMBold } from '@Components/Typography/Typography.styled';

export interface ISizes {
	isL?: boolean;
	isMd?: boolean;
	isSm?: boolean;
	isXs?: boolean;
}

export const ProviderWrapper = styled.div<{ sizes?: ISizes }>`
	${({ sizes }) => ({
		padding: sizes?.isL
			? '48px 80px'
			: sizes?.isMd
			? '48px 90px'
			: sizes?.isSm
			? '48px 60px'
			: sizes?.isXs
			? '32px 16px'
			: '48px 96px 48px 96px',
	})}
`;

export const ChangeProviderWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 24px;
	align-items: center;
`;

export const Title = styled(HeadlineBold)`
	padding: 0 25px;
	text-align: center;
	${({ theme }) => ({
		color: theme.colors.black87,
	})}
`;

export const IconWrapper = styled.div``;

export const WarningContainer = styled.div`
	width: 210px;
	border-radius: 4px;
	gap: 16px;
	align-self: center;
	${({ theme }) => ({ backgroundColor: theme.colors.warning6 })}
	display: flex;
	padding: 8px 8px 8px 16px;
	align-items: center;
`;

export const LoaderWrapper = styled.div`
	width: 64px;
	height: 64px;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 8px;
	${({ theme }) => ({ backgroundColor: theme.colors.primary6 })}
`;

export const StatusWrapper = styled(Status)`
	text-align: center;
`;

export const SubTitle = styled(BodyM)`
	text-align: center;
	line-height: 1.41;
	${({ theme }) => ({
		color: theme.colors.black87,
	})}
`;

export const SubTitleBold = styled(BodyMBold)`
	display: inline;
	${({ theme }) => ({
		color: theme.colors.black87,
	})}
`;

export const BlockSubTitleBold = styled(BodyMBold)`
	${({ theme }) => ({
		color: theme.colors.black87,
	})}
`;

export const ButtonsWrapper = styled.div<{ isXs?: boolean }>`
	margin-top: 8px;
	${({ isXs }) => ({
		width: isXs ? '100%' : '392px',
	})}
	display: flex;
	flex-direction: column;
	gap: 16px;
`;

export const WaitingProviderChangeWrapper = styled.div`
	align-items: center;
	display: flex;
	flex-direction: column;
	gap: 24px;
`;
