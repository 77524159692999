import { getLanguage } from '@Utils/language/language';
import { isUserSignedIn } from '@Redux/modules/api/authentication/selectors';
import { DISABLE_NEW_ADDRESS_DROPDOWN } from '@Config/features.config';
import { ADDRESS_DROPDOWN_CMPNTID } from '@Config/app.config';
interface IFunctionalEndpointConfig extends types.endpoints.functionalEndpointConfig {}

export const getRegions: IFunctionalEndpointConfig = () => {
	const cmpntid = DISABLE_NEW_ADDRESS_DROPDOWN
		? ADDRESS_DROPDOWN_CMPNTID.GOVERNORATES_CMPNTID
		: ADDRESS_DROPDOWN_CMPNTID.GOVERNORATES_CMPNTID_2023;
	return {
		url: `wcm/${
			isUserSignedIn() ? 'myconnect' : 'connect'
		}/omantel%20design/articles?source=library&srv=cmpnt&cmpntid=${cmpntid}&subtype=json`,
	};
};

export const getCities: IFunctionalEndpointConfig = () => {
	const cmpntid = DISABLE_NEW_ADDRESS_DROPDOWN
		? ADDRESS_DROPDOWN_CMPNTID.CITIES_CMPNTID
		: ADDRESS_DROPDOWN_CMPNTID.CITIES_CMPNTID_2023;
	return {
		url: `wcm/${
			isUserSignedIn() ? 'myconnect' : 'connect'
		}/omantel%20design/articles?srv=cmpnt&subtype=json&cmpntid=${cmpntid}&source=library`,
	};
};

export const getTermsAndConditionsContent: IFunctionalEndpointConfig = () => {
	return {
		url: `wcm/${
			isUserSignedIn() ? 'myconnect' : 'connect'
		}/Omantel_${getLanguage()}/z6_000000000000000000000000a0/contract_activation_tc/buy-car-insurance`,
	};
};
