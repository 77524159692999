import React, { FunctionComponent } from 'react';
import { AvailabilityWrapper, IconWrapper, DescriptionWrapper } from './Availability.styled';
import { Row } from 'react-grid-system';
import Icon from '@Components/Icon/Icon';
import { Caption, Subheading } from '@Components/Typography/Typography';
import useTranslate from '@Utils/hooks/useTranslate';
import ConditionalRender from '@Components/ConditionalRender/ConditionalRender';
import useTimer from '@Utils/hooks/useTimer';
import { getLang } from '@Utils/language/language';
import { isSoonPeorderDate } from '@Utils/date/moment';
export interface IAvailabilityProps {
	onlyOnline?: boolean;
	inStock?: boolean;
	timeLeftToOrder?: string;
	giftCard: boolean;
	preorder: { preorder: boolean; preorderDate?: string; launchDate?: string };
	isSalesLead?: boolean;
}

const ICON_SIZE = 32;

const Availability: FunctionComponent<IAvailabilityProps> = ({
	onlyOnline,
	inStock,
	timeLeftToOrder,
	giftCard,
	preorder,
	isSalesLead,
}) => {
	const { translate } = useTranslate();

	const { time } = useTimer(preorder.launchDate ?? '0');

	const getIconName = () => {
		if (isSalesLead) {
			return 'success';
		} else if (!inStock) {
			return 'warning';
		} else if (preorder.preorder) {
			return getLang() === 'ar' ? 'preorder_ar' : 'preorder_en';
		} else if (onlyOnline) {
			return 'roaming';
		} else if (inStock && !timeLeftToOrder) {
			return 'success';
		} else if (inStock && timeLeftToOrder) {
			return 'success';
		}
	};

	const getTitle = () => {
		if (isSalesLead) {
			return translate('availability.in.stock');
		} else if (!inStock) {
			return translate('availability.out.of.stock.title');
		} else if (preorder.preorder) {
			return translate('availability.preorder.title');
		} else if (inStock) {
			return translate('availability.in.stock');
		}
	};

	const getSubtitle = () => {
		if (giftCard) {
			return translate('availability.gift');
		} else if (isSalesLead) {
			return translate('availability.selected.product');
		} else if (!inStock) {
			return translate('availability.out.of.stock');
		} else if (preorder.preorder) {
			return isSoonPeorderDate(preorder.preorderDate ?? '')
				? translate('availability.preorder.subtitle.second-version')
				: translate('availability.preorder.subtitle', preorder.preorderDate || '-');
		} else if (onlyOnline) {
			return translate('availability.only.online');
		} else if (inStock && !timeLeftToOrder) {
			return translate('availability.selected.product');
		} else if (inStock && timeLeftToOrder) {
			return translate('availability.time.left.to.order', timeLeftToOrder);
		}
	};

	return (
		<AvailabilityWrapper>
			<Row>
				<IconWrapper>
					<Icon
						name={getIconName()}
						fill={preorder.preorder ? 'support2' : 'primary'}
						height={ICON_SIZE}
						width={ICON_SIZE}
					/>
				</IconWrapper>
				<DescriptionWrapper>
					<ConditionalRender
						show={!onlyOnline}
						onTrue={() => <Subheading textTransform="uppercase">{getTitle()}</Subheading>}
					/>
					<Caption color="black54">{getSubtitle()}</Caption>
				</DescriptionWrapper>
			</Row>
		</AvailabilityWrapper>
	);
};
export default Availability;
