import { IPointsConversionElement } from '@ApiModels/getMakasibPointsCalculation';

const between = (x: number, min: number, max: number) => {
	return x >= min && x <= max;
};

export const convertPriceToPoints = (converterArray: IPointsConversionElement[], price: number): number => {
	const item = converterArray?.find((item) => price > item.from && price <= item.to);
	return item?.pointsValueWithMarkUp ? Math.ceil(price / item.pointsValueWithMarkUp) : 0;
};

export const convertPointsToPrice = (converterArray: IPointsConversionElement[], points: number): number => {
	const value = converterArray.reverse().map((item, index) => {
		const price = (points * item.pointsValueWithMarkUp).toFixed(3);
		if (between(convertPriceToPoints(converterArray, Number(price)), points - 1, points + 1)) {
			return Number(price);
		} else return 0;
	});

	return Math.max(...value);
};
