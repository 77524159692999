import styled from 'styled-components';
import { BodyS, BodySBold } from '@Components/Typography/Typography.styled';
import { ISegmentDropdownProps, ISingleSegmentItemProps } from './MultipleSegmentSelector';

export const ContentWrapper = styled.div`
	display: flex;
	justify-content: space-around;
	width: 100%;
	margin-bottom: 15px;
`;

export const DropdownWrapper = styled.div`
	position: relative;
	display: inline-block;
`;

export const DropdownMainContainer = styled.div`
	position: relative;
`;

export const DropdownContainer = styled.div<ISegmentDropdownProps>`
	display: block;
	position: absolute;
	min-width: 120px;
	overflow: auto;
	z-index: 1;
	right: 0;
	box-shadow: 0 7px 8px 0 rgba(0, 0, 0, 0.05), 0 0 22px 4px rgba(0, 0, 0, 0.04), 0 12px 17px 2px rgba(0, 0, 0, 0.04);
	border-radius: 8px;
	text-align: center;
	overflow-y: hidden;
	transition: max-height 0.2s ease-in-out;
	margin-top: 5px;

	${({ theme }) => {
		return `background-color: ${theme.colors.white};`;
	}}

	${({ open }) => {
		if (open) {
			return `
				max-height: 100vh;
			`;
		} else {
			return `
				max-height: 0;
				padding: 0;
			`;
		}
	}}
`;

export const DropdownItem = styled.div<Partial<ISingleSegmentItemProps>>`
	padding: 12px 16px;
	text-decoration: none;
	display: block;
	cursor: pointer;
	transition: background-color 0.4s;

	&:first-child {
		margin-top: 12px;
	}

	&:last-child {
		margin-bottom: 12px;
	}

	${({ theme, selected }) => {
		if (selected) {
			return `
				background-color: ${theme.colors.primary6};
			`;
		}

		return `
			&:hover {
				background-color: ${theme.colors.primary6};
			}
		`;
	}}
`;

export const Label = styled(BodyS)``;
export const LabelBold = styled(BodySBold)``;
