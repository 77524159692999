import React, { FunctionComponent } from 'react';
import { SkeletonRow, Rectangular, DisplayFlex, SkeletonColumn } from '@Components/Skeleton/Skeleton.styled';
import Divider from '@Components/Divider/Divider';
import { HeadlineBold } from '@Components/Typography/Typography.styled';
import useTranslate from '@Utils/hooks/useTranslate';

const OrderTrackingDetailsSkeleton: FunctionComponent = () => {
	const { translate } = useTranslate();

	const skeleton = (marginTop: number) => {
		return (
			<SkeletonRow marginTop={marginTop} marginBottom={12}>
				<DisplayFlex>
					<Rectangular width={50} height={50} />
					<SkeletonColumn marginLeft={20}>
						<Rectangular width={200} height={16} marginBottom={16} />
						<Rectangular width={160} height={16} marginBottom={16} />
					</SkeletonColumn>
				</DisplayFlex>
			</SkeletonRow>
		);
	};

	return (
		<>
			<Divider color="transparent" marginBottom={32} />
			<HeadlineBold>{translate('account.management.payment.shipment.status')}</HeadlineBold>
			<Divider color="transparent" marginBottom={24} />
			{skeleton(20)}
			{skeleton(10)}
			{skeleton(10)}
		</>
	);
};

export default OrderTrackingDetailsSkeleton;
