import React, { FunctionComponent, useEffect, useState } from 'react';
import { StoreWrapper } from './MegaMenuStore.styled';
import useTranslate from '@Utils/hooks/useTranslate';
import ConditionalRender from '@Components/ConditionalRender/ConditionalRender';
import { useSelector } from 'react-redux';
import MegaMenuMultilevelItem, {
	MegaMenuMultilevelItemType,
} from '@Components/MegaMenuMultilevelItem/MegaMenuMultilevelItem';
import { ItemsWrapper, LeftContainer, LeftSectionWrapper, RightContainer } from '@Components/MegaMenu/MegaMenu.styled';
import Divider from '@Components/Divider/Divider';
import { addBusyIndicator, removeBusyIndicator } from '@Redux/modules/busyIndicator/actions';
import { CategoriesService } from '@Services/categories/categories';
import { dispatch } from '@Redux/store';
import { INestedCategories } from '@ApiModels/nestedCategories';
import { getLang } from '@Utils/language/language';
import { clearPriceFilter } from '@Redux/modules/priceFilter/actions';
import { clearFilters } from '@Redux/modules/filters/actions';
import BusyIndicator from '@Components/BusyIndicator/BusyIndicator';
import { RIGHT_DIVIDER_MARGIN_BOTTOM } from '@Components/MegaMenu/MegaMenu';
import { matchScreen } from '@Utils/layout/layoutSupport';

const CATEGORIES_BI = 'GET_NESTED_CATEGORIES_BI';

interface IMegaMenuMobileProps {
	mobileMenu: boolean;
	isMd: boolean;
	activeMenuIndex: number;
	setActiveMenuIndex: (index: number) => void;
	setMegaMenuContext: (index: string) => void;
	setMegaMenuOpen: (index: boolean) => void;
	setMenuExpanded: (index: boolean) => void;
}

const MegaMenuStore: FunctionComponent<IMegaMenuMobileProps> = ({
	mobileMenu,
	isMd,
	activeMenuIndex,
	setActiveMenuIndex,
	setMegaMenuContext,
	setMegaMenuOpen,
	setMenuExpanded,
}) => {
	const [content, setContent] = useState<INestedCategories>();
	const language = useSelector((state: types.redux.IState) => state.settings.language);
	const isSmallDevice = matchScreen(['xs', 'sm']);

	const { translate } = useTranslate();

	useEffect(() => {
		return () => {
			setActiveMenuIndex(-1);
		};
	}, []);

	useEffect(() => {
		dispatch(addBusyIndicator(CATEGORIES_BI));
		CategoriesService.getNestedCategories().subscribe(
			(r) => {
				r.rows.map((parentCategory, index) => {
					const parentChildren = r.rows[index].children;
					if (
						parentChildren &&
						parentChildren.length > 0 &&
						parentChildren[parentChildren.length - 1]?.id !== parentCategory.id
					) {
						r.rows[index].children.push({
							...parentCategory,
							name: `${translate('megamenu.store.prefix') + ' ' + parentCategory.name}...`,
						});
					}
				});
				setContent(r);
				dispatch(removeBusyIndicator(CATEGORIES_BI));
			},
			() => {
				dispatch(removeBusyIndicator(CATEGORIES_BI));
			}
		);
	}, [language]);

	const handleCategorySelect = () => {
		dispatch(clearFilters());
		dispatch(clearPriceFilter());
	};

	return (
		<StoreWrapper mobileMenu={!!mobileMenu} isMd={isMd}>
			<ConditionalRender
				show={activeMenuIndex < 0 || !isSmallDevice}
				onTrue={() => (
					<LeftContainer mobileMenu={mobileMenu} isMd={isMd} isSmallDevice={isSmallDevice}>
						<MegaMenuMultilevelItem
							type={MegaMenuMultilevelItemType.HEADER}
							name={translate('menu.item.Store')}
							iconRight="arrowRightBox"
							onClick={() => {
								setMegaMenuOpen(false);
								setMegaMenuContext('');
								setActiveMenuIndex(-1);
								setMenuExpanded(false);
							}}
							to={`/${getLang()}/store`}
						/>
						<BusyIndicator
							listener={[CATEGORIES_BI]}
							skeleton="nestedCategories"
							skeletonProps={{ mobileMenu }}
						>
							<>
								{content?.rows?.map((row, index) => (
									<LeftSectionWrapper key={index}>
										<ItemsWrapper mobileMenu={mobileMenu} key={row.name}>
											<MegaMenuMultilevelItem
												key={index}
												type={
													row?.children?.length > 0
														? MegaMenuMultilevelItemType.ITEM
														: MegaMenuMultilevelItemType.LINK
												}
												onClick={() => {
													handleCategorySelect();
													row?.children?.length > 0 && setActiveMenuIndex(index);
												}}
												to={`/${getLang()}/store/${row.identifier}`}
												name={row.name}
												paddingLeft={16}
												active={activeMenuIndex === index}
												iconRight={row?.children?.length > 0 ? 'arrowRightBox' : undefined}
												id={row.id}
											/>
										</ItemsWrapper>
									</LeftSectionWrapper>
								))}
							</>
						</BusyIndicator>
					</LeftContainer>
				)}
			/>
			<ConditionalRender
				show={activeMenuIndex >= 0}
				onTrue={() => (
					<RightContainer mobileMenu={mobileMenu} isMd={isMd} isSmallDevice={isSmallDevice}>
						<>
							<ConditionalRender
								show={!isSmallDevice}
								onTrue={() => <Divider marginBottom={RIGHT_DIVIDER_MARGIN_BOTTOM} withoutLine />}
							/>
							{content?.rows[activeMenuIndex]?.children?.map(({ name, identifier, id }) => (
								<MegaMenuMultilevelItem
									paddingLeft={16}
									key={name}
									type={MegaMenuMultilevelItemType.SUB_ITEM}
									to={`/${getLang()}/store/${identifier}`}
									name={name}
									subCategory={true}
									id={id}
								/>
							))}
						</>
					</RightContainer>
				)}
			/>
		</StoreWrapper>
	);
};

export default MegaMenuStore;
