import { ParagraphM } from '@Components/Typography/Typography';
import styled from 'styled-components';

export const CardWrapper = styled.div`
	padding: 8px 16px;
`;
export const Title = styled(ParagraphM)`
	text-align: center;
	margin-bottom: 16px;
`;

export const Button = styled.div`
	width: 100%;
	> div {
		width: 100%;
	}
`;
