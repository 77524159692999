import { PlanBarType } from '@Components/PlanBar/PlanBarType';
import {
	Caption,
	CaptionBigger,
	DisplayL,
	DisplayMBold,
	Label,
	ParagraphM,
	TitleBold,
} from '@Components/Typography/Typography.styled';
import { isRTL, matchScreen } from '@Utils/layout/layoutSupport';
import styled from 'styled-components';

interface IPlanComparisonProps {
	padding?: number;
	planType?: PlanBarType;
	menuMargin?: number;
	dataFill?: number;
}

interface IPlanIndexProps {
	index: number;
	max: number;
}

export const PlanWrapper = styled.div<IPlanIndexProps>`
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	${({ index, max }) =>
		isRTL()
			? {
					marginRight: index > 0 ? '-1px' : 'none',
					borderBottomRightRadius: index === 0 ? '8px' : 'none',
					borderBottomLeftRadius: index === max - 1 ? '8px' : 'none',
			  }
			: {
					marginLeft: index > 0 ? '-1px' : 'none',
					borderBottomLeftRadius: index === 0 ? '8px' : 'none',
					borderBottomRightRadius: index === max - 1 ? '8px' : 'none',
			  }}
	overflow: hidden;
`;

export const PlanHeader = styled.div<IPlanComparisonProps>`
	padding-top: 16px;
	padding-bottom: 12px;
	text-align: center;
	height: 96px;
	box-sizing: border-box;
	${({ planType, theme }) => {
		switch (planType) {
			case PlanBarType.BAQATI:
				return {
					backgroundImage: `linear-gradient(to top, ${theme.colors.support2}, ${theme.colors.support4})`,
					color: theme.colors.white,
				};
			case PlanBarType.BAQATI_AL_UFUQ:
				return {
					backgroundImage: `linear-gradient(to top, ${theme.colors.black87}, ${theme.colors.support3})`,
					color: theme.colors.white,
				};
			default:
				return {};
		}
	}}
`;

export const PlanHeaderTitle = styled(TitleBold)`
	color: inherit;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
	padding-left: 16px;
	padding-right: 16px;
`;

export const PlanHeaderCommitment = styled(Caption)`
	${({ theme }) => ({
		color: theme.colors.white70,
	})}
`;

export const PlanHeaderType = styled(Label)`
	display: inline-block;
	padding: 2px 4px;
	align-items: center;
	border-radius: 4px;
	margin-top: 8px;
	${({ theme }) => ({
		color: theme.colors.support3,
		backgroundColor: theme.colors.white,
	})}
`;

export const PlanItems = styled.div<IPlanComparisonProps>`
	padding: 0 16px;
	background-color: white;
	${({ theme }) => ({
		border: matchScreen('xs') ? 'none' : `1px solid ${theme.colors.black12}`,
	})}
`;

export const PlanItem = styled.div<IPlanComparisonProps>`
	:not(:last-child) {
		${({ theme }) => ({
			borderBottom: matchScreen('xs') ? 'none' : `1px solid ${theme.colors.black12}`,
		})}
	}
	${({ padding }) => ({
		padding: `${padding ?? '10'}px 0`,
	})}
	${({ padding }) => ({
		paddingTop: matchScreen('xs') ? 44 : `${padding ?? '12'}px 0`,
	})}
	display: flex;
	flex-direction: column;
	align-items: center;
`;

export const ApplyButtonContainer = styled.div`
	padding: 16px 0;
`;

export const PlanItemValue = styled.div`
	height: 32px;
	display: flex;
	align-items: flex-end;
	justify-content: center;
`;

export const PlanDataItem = styled.div<{ padding?: number }>`
	${({ theme }) => ({
		borderBottom: matchScreen('xs') ? 'none' : `0px solid ${theme.colors.black12}`,
	})}
	${({ padding }) => ({
		paddingTop: matchScreen('xs') ? 0 : padding ?? 0,
		paddingBottom: matchScreen('xs') ? 16 : padding ?? 0,
	})}
	${({ theme }) => ({
		borderBottom: matchScreen('xs') ? 'none' : `1px solid ${theme.colors.black12}`,
	})}
`;

export const PlanDataItemHBB = styled.div<{ withCaption?: boolean }>`
	${({ theme }) => ({
		borderBottom: matchScreen('xs') ? 'none' : `0px solid ${theme.colors.black12}`,
	})}
	${({ withCaption }) => ({
		paddingTop: matchScreen('xs') ? 0 : withCaption ? 5 : 0,
		paddingBottom: matchScreen('xs') ? 0 : withCaption ? 6 : 0,
	})}
`;

export const Unlimited = styled.div`
	display: flex;
	justify-content: center;
`;

export const Empty = styled.div`
	height: 32px;
	display: flex;
	align-items: center;
	justify-content: center;
`;

export const EmptyLine = styled.div`
	width: 16px;
	height: 2px;
	border-radius: 2px;
	display: inline-block;
	${({ theme }) => ({
		backgroundColor: theme.colors.black38,
	})}
`;

export const PlanButton = styled.div``;

export const RowTitle = styled(CaptionBigger)`
	${({ theme }) => ({
		color: theme.colors.black54,
	})}
	text-align: center
`;

export const SubtitleWrapper = styled.div`
	${({ theme }) => ({
		color: theme.colors.black87,
		width: '100%',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		paddingBottom: 16,
	})}
`;

export const RowWrapper = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 16px;
`;

export const CaptionWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
`;

export const InfoWrapper = styled.div`
	width: 90%;
	& > p {
		text-align: center;
		& > span {
			font-weight: 900;
		}
	}
	margin-top: 24px;
`;

export const ErrorTitle = styled(DisplayMBold)`
	margin-top: 24px;
	${({ theme }) => ({
		color: theme.colors.black87,
	})}
	text-align: center;
`;

export const ErrorText = styled(ParagraphM)`
	margin-top: 16px;
	${({ theme }) => ({
		color: theme.colors.black87,
	})}
	text-align: center;
`;

export const SuccessTitle = styled(DisplayL)`
	margin-top: 24px;
	${({ theme }) => ({
		color: theme.colors.black87,
	})}
`;

export const SuccessText = styled(ParagraphM)`
	margin-bottom: 24px;
	${({ theme }) => ({
		color: theme.colors.black87,
	})}
	text-align: center;
`;
