import styled from 'styled-components';
import { DisplayMBold, ParagraphS, Info, ParagraphMBold } from '@Components/Typography/Typography';
import { IScrollStateProps } from '@Components/CartFlyout/CartFlyout';
import { marginRight, right, textRight, marginLeft, matchScreen, isLargeScreen } from '@Utils/layout/layoutSupport';
import { NAVBAR_HEIGHT } from '@Layouts/Dashboard/Dashboard';
import { ADDITIONAL_BAR_HEIGHT, ADDITIONAL_SMALL_BAR_HEIGHT } from '@Components/TelemarketerTopBar/TelemarketerTopBar';

interface IContainerProps {
	open: boolean;
	height: number;
	alignment: number;
	isTelemarketer?: boolean;
}

export const CartFlyoutContainer = styled.div<IContainerProps>`
	position: fixed;
	${({ isTelemarketer }) => {
		const isSmallDevice = matchScreen(['xs', 'sm']);
		const isXsSmMd = matchScreen(['xs', 'sm', 'md']);
		const top =
			(isTelemarketer
				? (isXsSmMd ? ADDITIONAL_SMALL_BAR_HEIGHT : ADDITIONAL_BAR_HEIGHT) + NAVBAR_HEIGHT
				: NAVBAR_HEIGHT) - (isLargeScreen() ? 0 : isSmallDevice ? 44 : 12);
		return {
			top,
			width: isSmallDevice ? '302px' : '320px',
		};
	}}
	z-index: 80;
	transition: all 0.3s ease-in-out 0.15s;
	overflow: hidden;
	border-radius: 0 0 8px 8px;
	max-width: 100%;
	max-height: calc(100vh - 100px);
	${({ alignment }) => right(`${alignment}px`)}
	${({ open, height, theme }) => ({
		height: open ? height : 0,
		background: theme.colors.white,
		boxShadow: open
			? '0 7px 8px 0 rgba(0, 0, 0, 0.05), 0 5px 22px 4px rgba(0, 0, 0, 0.04), 0 12px 17px 2px rgba(0, 0, 0, 0.04)'
			: 'none',
	})};
`;

export const ContentWrapper = styled.div`
	display: flex;
	flex-direction: column;
	height: 100%;
`;

export const EmptyStateWrapper = styled.div`
	display: flex;
	flex-direction: column;
	padding: 16px;
	justify-content: flex-end;
	height: 168px;
`;

export const InnerButtonWrapper = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	${() => marginRight(-16)}
`;

export const IconWrapper = styled.div`
	${() => marginLeft(5.33)}
	border: 1.5px solid;
	border-radius: 16px;
	${({ theme }) => ({
		borderColor: theme.colors.primary,
	})}
	padding: 1.78px;
`;

export const HeaderWrapper = styled.div`
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	padding: 16px;
	${() => marginRight(-16)}
`;

export const HeaderButtonWrapper = styled.div`
	max-width: 160px;
	${() => marginRight(32)}
`;

export const ItemsList = styled.div<{ scrollState: IScrollStateProps; items: number }>`
	flex: 1 1 auto;
	overflow-y: scroll;
	padding: 0 4px 0 16px;
	position: relative;

	// Firefox
	${() => ({
		scrollbarWidth: 'thin',
		scrollbarColor: `transparent transparent`,
	})}

	// Chrome, Opera, Etc.
	&::-webkit-scrollbar-thumb {
		border-radius: 12px;
		transition: all 0.2s ease-in-out;
		opacity: 0;
		box-shadow: inset 0 0 10px 10px rgb(0 0 0 / 0%);
		border: solid 4px transparent;
	}

	&::before {
		content: '';
		position: sticky;
		top: 0;
		left: 0;
		width: 100%;
		height: 40px;
		transition: all 0.3s ease-in-out;
		background: linear-gradient(white, rgba(255, 255, 255, 0));
		${({ scrollState }) => ({
			opacity: !scrollState.top || (!scrollState.top && !scrollState.bottom) ? 1 : 0,
		})}
	}
	&::after {
		content: '';
		position: sticky;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 40px;
		${({ scrollState }) => ({
			opacity: !scrollState.bottom || (!scrollState.top && !scrollState.bottom) ? 1 : 0,
		})}
		transition: all 0.3s ease-in-out;
		background: linear-gradient(rgba(255, 255, 255, 0), white);
	}
	&:hover {
		${({ theme }) => ({
			scrollbarWidth: 'thin',
			scrollbarColor: `${theme.colors.grey300} transparent`,
		})}
		::-webkit-scrollbar-thumb {
			transition: all 0.2s ease-in-out;
			opacity: 1;
			box-shadow: inset 0 0 10px 10px rgb(0 0 0 / 12%);
		}
		&::before {
			opacity: 0;
			height: 0;
		}
		&::after {
			opacity: 0;
			height: 0;
		}
	}
`;

export const SummaryWrapper = styled.div`
	flex: 0 0 auto;
	z-index: 100;
	padding: 8px 16px 16px;
	box-shadow: 0 7px 8px 0 rgba(0, 0, 0, 0.05), 0 0 22px 4px rgba(0, 0, 0, 0.04), 0 12px 17px 2px rgba(0, 0, 0, 0.04);
`;

export const CostWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

export const VatWrapper = styled.div``;

export const Vat = styled(Info)`
	margin-top: -4px;
	margin-bottom: 4px;
	${() => textRight()}
	${({ theme }) => ({
		color: theme.colors.black54,
	})}
`;

export const CostTitle = styled(ParagraphS)`
	${({ theme }) => ({
		color: theme.colors.black54,
	})}
`;

export const ButtonWrapper = styled.div`
	padding-top: 12px;
`;

export const EmptyStateTitle = styled(DisplayMBold)`
	text-align: center;
	padding: 16px 0;
	${({ theme }) => ({
		color: theme.colors.black87,
	})}
`;
export const EmptyStateDescription = styled(ParagraphMBold)`
	text-align: center;
`;

export const ShoppingBagImageWrapper = styled.div`
	position: absolute;
	left: calc(50% - 37.92px / 2 + 0.08px);
	top: 27.43px;
`;

export const ShoppingBagShadowWrapper = styled.div`
	position: absolute;
	left: calc(50% - 48.77px / 2 + 0.08px);
	top: 48.9px;
`;
