import React, { forwardRef, useEffect, useMemo, useRef, useState } from 'react';
import {
	ExpandableTitleItem,
	IconItem,
	IconsWrapper,
	ItemsBar,
	LeftSideItems,
	LogoLinkWrapper,
	NavigationBarContent,
	NavigationBarWrapper,
	RightSideItems,
	CartItemsCounterWrapper,
	CartItemsCounter,
	NavigationBarContainer,
	MenuItemLink,
	PaymentIconWrapper,
	IconLinkItem,
} from './NavigationBar.styled';
import { Container } from 'react-grid-system';
import Icon from '@Components/Icon/Icon';
import { useSelector } from 'react-redux';
import { getLang } from '@Utils/language/language';
import MegaMenu, { MegaMenuContext } from '@Components/MegaMenu/MegaMenu';
import CartFlyout from '@Components/CartFlyout/CartFlyout';
import ConditionalRender from '@Components/ConditionalRender/ConditionalRender';
import MobileMenu from '@Components/MobileMenu/MobileMenu';
import useTranslate from '@Utils/hooks/useTranslate';
import useScreen from '@Utils/hooks/useScreen';
import externalLinks from '@Utils/externalLinks/externalLinks';
import { useCart } from '@Utils/context/CartContext';
import { COMPARISON_PATH } from '@Components/ComparisonBar/ComparisonBar';
import { usePrepaidMigration } from '@Utils/context/PrepaidMigrationContext';
import ChoosenAccountBar from './ChoosenAccountBar/ChoosenAccountBar';
import { MegaMenuMultilevelItemType } from '@Components/MegaMenuMultilevelItem/MegaMenuMultilevelItem';
import useAppleBrand from '@Utils/hooks/useAppleBrand';
import { matchScreen } from '@Utils/layout/layoutSupport';
import { BodySHigher } from '@Components/Typography/Typography.styled';
import OptionsBar from '@Components/OptionsBar/OptionsBar';
import { hasCookie, NO_HEADER_FOOTER_COOKIE } from '@Utils/cookie/cookie';
import { MAKASIB_POINTS_PRODUCT_ID, MAKASIB_VOUCHER_PRODUCT_ID } from '@Config/app.config';

const HIDE_MENU_OFFSET = 60;
interface ISingleItem {
	label: string;
	itemType: MegaMenuMultilevelItemType;
	redirect?: string;
}

export interface IExpandableItem extends ISingleItem {
	itemType: MegaMenuMultilevelItemType.ITEM;
	to?: string;
	iconRight?: types.iconNames;
	iconLeft?: types.iconNames;
}

export interface ILinkItem extends ISingleItem {
	itemType: MegaMenuMultilevelItemType.LINK;
	to?: string;
}
const ICONS_SIZE = 24;

const EXPANDABLE_MENU_ITEMS = ['store', 'postpaid', 'prepaid', 'homeservices', 'services'];

const NavigationBar = forwardRef<HTMLDivElement>((_, ref) => {
	const [megaMenuOpen, setMegaMenuOpen] = useState(false);
	const [cartFlyoutOpen, setCartFlyoutOpen] = useState(false);
	const [megaMenuContext, setMegaMenuContext] = useState('');
	const [menuExpanded, setMenuExpanded] = useState(false);
	const [menuVisibility, setMenuVisibility] = useState<{ position: number; hide: boolean }>({
		position: 0,
		hide: false,
	});
	const { translate } = useTranslate();
	const { screen } = useScreen();
	const cartIconRef = useRef<HTMLDivElement>(null);
	const itemsBarRef = useRef<HTMLDivElement>(null);
	const isXsSm = screen(['xs', 'sm']);
	const isMd = screen(['md']);
	const isXlSmXs = screen(['xxl', 'xl', 'sm', 'xs']);
	const isLargeDevice = matchScreen(['xxl', 'xl']);
	const isLogged = !!useSelector((state: types.redux.IState) => state.api.authentication.signedIn);
	const { choosenNumber, currentIndex } = usePrepaidMigration();
	const [activeMenuIndex, setActiveMenuIndex] = useState<number>(-1);

	useEffect(() => {
		!megaMenuOpen && setMegaMenuContext('');
	}, [megaMenuOpen]);
	const { isAppleUrl } = useAppleBrand();

	const menuItems: (IExpandableItem | ILinkItem)[] = [
		{
			itemType: MegaMenuMultilevelItemType.ITEM,
			label: 'Store',
			to: `/${getLang()}/store`,
			iconRight: 'arrowRightBox',
		},
		{
			itemType: MegaMenuMultilevelItemType.ITEM,
			label: 'postpaid',
			to: `/${getLang()}/store`,
			iconRight: 'arrowRightBox',
		},
		{
			itemType: MegaMenuMultilevelItemType.ITEM,
			label: 'prepaid',
			to: `/${getLang()}/store`,
			iconRight: 'arrowRightBox',
		},
		{
			itemType: MegaMenuMultilevelItemType.ITEM,
			label: 'homeservices',
			to: `/${getLang()}/store`,
			iconRight: 'arrowRightBox',
		},
		{
			itemType: MegaMenuMultilevelItemType.ITEM,
			label: 'services',
			to: `/${getLang()}/store`,
			iconRight: 'arrowRightBox',
		},
		{
			itemType: MegaMenuMultilevelItemType.LINK,
			label: 'Makasib',
			to: externalLinks('MAKASIB'),
		},
	];

	const { cart, cartItemsLength } = useCart();

	useEffect(() => {
		if (isXsSm && !location.pathname.includes(COMPARISON_PATH)) {
			const handleScrool = () => {
				setMenuVisibility((prevValues) => {
					return {
						position: window.scrollY,
						hide: prevValues.position >= HIDE_MENU_OFFSET && prevValues.position < window.scrollY,
					};
				});
			};

			window.addEventListener('scroll', handleScrool);
		}
	}, [isXsSm]);

	const handleMegaMenuOnClick = (context: string) => {
		if (isXsSm) setCartFlyoutOpen(false);
		megaMenuContext === '' && setMegaMenuOpen(true);
		megaMenuContext !== '' && context.toLowerCase() === megaMenuContext && setMegaMenuOpen(!megaMenuOpen);
		setMegaMenuContext(context.toLowerCase());
	};

	const handleCartOnMouseEnter = () => {
		setCartFlyoutOpen(true);
	};

	const handleCartOnMouseLeave = () => {
		setCartFlyoutOpen(false);
	};

	const handleOpenMenuOnSmallScreen = (context: string) => {
		if (context === 'hamburgerMenu') {
			setMegaMenuContext('');
			setMegaMenuOpen(false);
		}
		setMenuExpanded((expanded) => !expanded);
	};

	const handleRedirectToOmantel = () => {
		window.location.href = externalLinks('OMANTEL');
	};

	const showChoosenNumberBar = useMemo(() => !!choosenNumber && currentIndex === 1, [choosenNumber, currentIndex]);

	const handleCloseMobileMenu = () => {
		setMenuExpanded(!open);
	};

	return (
		<ConditionalRender
			show={!hasCookie(NO_HEADER_FOOTER_COOKIE)}
			onTrue={() => (
				<NavigationBarContainer>
					<NavigationBarWrapper
						menuExpanded={menuExpanded}
						hideShadow={true}
						isAppleMenuVisible={isAppleUrl()}
					>
						<ConditionalRender show={!isXsSm} onTrue={() => <OptionsBar />} />
						<Container fluid={isXsSm || isMd} style={{ padding: isXsSm ? '0 30px' : '0 15px' }}>
							<ConditionalRender
								show={!isXsSm}
								onTrue={() => (
									<div ref={ref}>
										<NavigationBarContent>
											<LogoLinkWrapper onClick={handleRedirectToOmantel}>
												<Icon
													name="omantelLogo"
													width={isXlSmXs ? 64 : 48}
													height={isXlSmXs ? 32 : 24}
												/>
											</LogoLinkWrapper>
											<ItemsBar ref={itemsBarRef}>
												<LeftSideItems>
													<ConditionalRender
														show={!isXsSm}
														onTrue={() => (
															<>
																{menuItems.map((item) => {
																	if (
																		item.itemType ===
																		MegaMenuMultilevelItemType.ITEM
																	) {
																		if (!!item.to || !!item.redirect) {
																			return (
																				<ExpandableTitleItem
																					megaMenuOpen={megaMenuOpen}
																					key={item.label}
																					active={
																						item.label.toLowerCase() ===
																						megaMenuContext
																					}
																					onClick={() =>
																						handleMegaMenuOnClick(
																							item.label
																						)
																					}
																				>
																					<MenuItemLink
																						active={
																							item.label.toLowerCase() ===
																							megaMenuContext
																						}
																					>
																						{translate(
																							`menu.item.${item.label}`
																						)}
																					</MenuItemLink>
																				</ExpandableTitleItem>
																			);
																		}
																	} else if (!!item.redirect) {
																		return (
																			<ExpandableTitleItem
																				megaMenuOpen={megaMenuOpen}
																				key={item.label}
																				active={
																					item.label.toLowerCase() ===
																					megaMenuContext
																				}
																				href={item.redirect}
																			>
																				<MenuItemLink
																					active={
																						item.label.toLowerCase() ===
																						megaMenuContext
																					}
																				>
																					{translate(
																						`menu.item.${item.label}`
																					)}
																				</MenuItemLink>
																			</ExpandableTitleItem>
																		);
																	} else if (!!item.to) {
																		return (
																			<ExpandableTitleItem
																				megaMenuOpen={megaMenuOpen}
																				key={item.label}
																				active={
																					item.label.toLowerCase() ===
																					megaMenuContext
																				}
																				href={item.to}
																			>
																				<MenuItemLink
																					active={
																						item.label.toLowerCase() ===
																						megaMenuContext
																					}
																				>
																					{translate(
																						`menu.item.${item.label}`
																					)}
																				</MenuItemLink>
																			</ExpandableTitleItem>
																		);
																	}
																})}
															</>
														)}
													/>
												</LeftSideItems>
												<RightSideItems>
													<IconsWrapper>
														<IconItem
															customBackground="intSecondaryDefault"
															onClick={() => handleMegaMenuOnClick('search')}
														>
															<Icon
																name="searchFromPortal"
																width={ICONS_SIZE}
																height={ICONS_SIZE}
															/>
														</IconItem>
														<ConditionalRender
															show={isLargeDevice}
															onTrue={() => (
																<>
																	<PaymentIconWrapper
																		href={externalLinks('PERSONAL_BILL_PAYMENT')}
																	>
																		<Icon
																			name="payBill"
																			width={ICONS_SIZE}
																			height={ICONS_SIZE}
																			fill="primary"
																		/>
																		<BodySHigher color="black87">
																			{translate('megamenu.search.pay-bill')}
																		</BodySHigher>
																	</PaymentIconWrapper>
																	<PaymentIconWrapper href={externalLinks('TOP_UP')}>
																		<Icon
																			name="topUp"
																			width={ICONS_SIZE}
																			height={ICONS_SIZE}
																			fill="primary"
																		/>
																		<BodySHigher color="black87">
																			{translate('megamenu.search.top-up')}
																		</BodySHigher>
																	</PaymentIconWrapper>
																</>
															)}
														/>
														<IconLinkItem to={`/${getLang()}/cart`}>
															<IconItem
																customBackground="intSecondaryDefault"
																ref={cartIconRef}
																onMouseLeave={handleCartOnMouseLeave}
																onMouseEnter={() => handleCartOnMouseEnter()}
															>
																<Icon
																	name="bag"
																	width={ICONS_SIZE}
																	height={ICONS_SIZE}
																/>
																<ConditionalRender
																	show={!!cartItemsLength}
																	onTrue={() => (
																		<CartItemsCounterWrapper>
																			<CartItemsCounter>
																				{cartItemsLength -
																					cart.items.filter(
																						(item) =>
																							item.productId ===
																								MAKASIB_POINTS_PRODUCT_ID ||
																							item.productId ===
																								MAKASIB_VOUCHER_PRODUCT_ID
																					)?.length ?? 0}
																			</CartItemsCounter>
																		</CartItemsCounterWrapper>
																	)}
																/>
															</IconItem>
														</IconLinkItem>
														<IconItem
															customBackground="intSecondaryDefault"
															onClick={() => handleMegaMenuOnClick('myOmantel')}
														>
															<Icon
																name={isLogged ? 'phantomUser' : 'notLoggedPerson'}
																width={isLogged ? 36 : ICONS_SIZE}
																height={isLogged ? 36 : ICONS_SIZE}
															/>
														</IconItem>
													</IconsWrapper>
												</RightSideItems>
											</ItemsBar>
										</NavigationBarContent>
									</div>
								)}
								onFalse={() => (
									<div ref={ref}>
										<NavigationBarContent>
											<ItemsBar ref={itemsBarRef}>
												<LogoLinkWrapper onClick={handleRedirectToOmantel}>
													<Icon
														name="omantelLogo"
														width={isXlSmXs ? 64 : 48}
														height={isXlSmXs ? 32 : 24}
													/>
												</LogoLinkWrapper>
												<IconItem
													onClick={() => {
														setMenuExpanded(false);
														handleMegaMenuOnClick('search');
													}}
												>
													<Icon
														name="searchFromPortal"
														width={ICONS_SIZE}
														height={ICONS_SIZE}
													/>
												</IconItem>
												<ConditionalRender
													show={!screen(['xs', 'sm'])}
													onTrue={() => (
														<IconLinkItem to={`/${getLang()}/cart`}>
															<IconItem
																customBackground={
																	cartItemsLength > 0
																		? 'intSecondaryDefault'
																		: undefined
																}
																ref={cartIconRef}
																onMouseLeave={handleCartOnMouseLeave}
																onMouseEnter={() => handleCartOnMouseEnter()}
															>
																<Icon
																	name="bag"
																	width={ICONS_SIZE}
																	height={ICONS_SIZE}
																/>
																<ConditionalRender
																	show={!!cartItemsLength}
																	onTrue={() => (
																		<CartItemsCounterWrapper>
																			<CartItemsCounter>
																				{cartItemsLength -
																					cart.items.filter(
																						(item) =>
																							item.productId ===
																								MAKASIB_POINTS_PRODUCT_ID ||
																							item.productId ===
																								MAKASIB_VOUCHER_PRODUCT_ID
																					)?.length ?? 0}
																			</CartItemsCounter>
																		</CartItemsCounterWrapper>
																	)}
																/>
															</IconItem>
														</IconLinkItem>
													)}
													onFalse={() => (
														<IconItem
															customBackground={
																cartItemsLength > 0 ? 'intSecondaryDefault' : undefined
															}
															ref={cartIconRef}
															onClick={() => {
																setMenuExpanded(false);
																setMegaMenuOpen(false);
																setMegaMenuContext('');
																setCartFlyoutOpen(!cartFlyoutOpen);
															}}
														>
															<Icon name="bag" width={ICONS_SIZE} height={ICONS_SIZE} />
															<ConditionalRender
																show={!!cartItemsLength}
																onTrue={() => (
																	<CartItemsCounterWrapper>
																		<CartItemsCounter>
																			{cartItemsLength -
																				cart.items.filter(
																					(item) =>
																						item.productId ===
																							MAKASIB_POINTS_PRODUCT_ID ||
																						item.productId ===
																							MAKASIB_VOUCHER_PRODUCT_ID
																				)?.length ?? 0}
																		</CartItemsCounter>
																	</CartItemsCounterWrapper>
																)}
															/>
														</IconItem>
													)}
												/>
												<IconItem
													onClick={() => {
														setMenuExpanded(false);
														handleMegaMenuOnClick('myOmantel');
													}}
												>
													<Icon
														name={isLogged ? 'phantomUser' : 'notLoggedPerson'}
														width={isLogged ? 36 : ICONS_SIZE}
														height={isLogged ? 36 : ICONS_SIZE}
													/>
												</IconItem>
												<IconItem
													customBackground="intSecondaryDefault"
													onClick={() => handleOpenMenuOnSmallScreen('hamburgerMenu')}
												>
													<Icon name="hamburgerMenu" width={24} height={24} fill="primary" />
												</IconItem>
											</ItemsBar>
										</NavigationBarContent>
									</div>
								)}
							/>
						</Container>
						<ConditionalRender show={showChoosenNumberBar} onTrue={() => <ChoosenAccountBar />} />
					</NavigationBarWrapper>
					<ConditionalRender
						show={!screen(['xs', 'sm'])}
						onTrue={() => (
							<MegaMenu
								open={megaMenuOpen}
								context={megaMenuContext as MegaMenuContext}
								mobileMenu={false}
								isMd={isMd}
								itemsBarRef={itemsBarRef}
								setMegaMenuOpen={setMegaMenuOpen}
								setMegaMenuContext={setMegaMenuContext}
								activeMenuIndex={activeMenuIndex}
								setActiveMenuIndex={setActiveMenuIndex}
								handleCloseMobileMenu={handleCloseMobileMenu}
								setMenuExpanded={() => {}}
							/>
						)}
						onFalse={() => (
							<ConditionalRender
								show={!EXPANDABLE_MENU_ITEMS.includes(megaMenuContext)}
								onTrue={() => (
									<MegaMenu
										open={megaMenuOpen}
										context={megaMenuContext as MegaMenuContext}
										mobileMenu={true}
										isMd={isMd}
										itemsBarRef={itemsBarRef}
										setMegaMenuOpen={setMegaMenuOpen}
										setMegaMenuContext={setMegaMenuContext}
										activeMenuIndex={activeMenuIndex}
										setActiveMenuIndex={setActiveMenuIndex}
										handleCloseMobileMenu={handleCloseMobileMenu}
										setMenuExpanded={() => {}}
									/>
								)}
							/>
						)}
					/>
					<CartFlyout open={cartFlyoutOpen} cart={cart} setOpen={setCartFlyoutOpen} />
					<MobileMenu
						setMegaMenuOpen={setMegaMenuOpen}
						open={menuExpanded}
						setMenuExpanded={setMenuExpanded}
						items={menuItems}
						activeMenuIndex={activeMenuIndex}
						setActiveMenuIndex={setActiveMenuIndex}
					/>
				</NavigationBarContainer>
			)}
			onFalse={() => <></>}
		/>
	);
});

export default NavigationBar;
