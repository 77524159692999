import immerHandleActions from '@Redux/utils/immerHandleActions';
import { setLanguage } from './actions';
import { getBrowserLanguage } from '@Utils/language/language';

interface ISetLanguageAction extends types.redux.settings.ISetLanguageAction {}

const reducers = {
	[setLanguage.toString()](_state: types.redux.settings.language, action: ISetLanguageAction) {
		return action.payload;
	},
};

const init = getBrowserLanguage();

export default immerHandleActions<types.redux.settings.language>(reducers, init);
