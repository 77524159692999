export interface IAppointment {
	id: string;
	branchName: string;
	serviceName: string;
	appointmentDate: string;
	ticketNumber: string;
}

export interface IGetAppointments {
	returnValue: IAppointment[];
}

const model: types.models.IInputModel = {
	returnValue: 'response.getAppointmentsResponse.appointments',
};

export default model;
