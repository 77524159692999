import useTranslate from '@Utils/hooks/useTranslate';
import * as Yup from 'yup';

export default (): Yup.AnyObjectSchema => {
	const { translate } = useTranslate();
	return Yup.object().shape({
		password: Yup.string()
			.min(8)
			.matches(/[a-zA-Z]/, translate('login.form.password.error.matches'))
			.required(translate('login.form.password.error.required')),
		login: Yup.string().required(translate('login.form.error.phone.required')),
	});
};
