import React, { FunctionComponent } from 'react';
import { LogoLinkWrapper, NavigationBarWrapper, BackButtonWrapper } from './CheckoutBar.styled';
import { Col, Container, Hidden, Row } from 'react-grid-system';
import Icon from '@Components/Icon/Icon';
import { useHistory } from 'react-router';
import { TertiaryLargeButtonAuto } from '@Components/controls/Button/Button';
import useTranslate from '@Utils/hooks/useTranslate';
import CheckoutStepper from '@Components/CheckoutStepper/CheckoutStepper';
import { setModal } from '@Redux/modules/modal/actions';
import { dispatch } from '@Redux/store';
import { deleteWCTokens } from '@Redux/modules/api/headers/actions';
import { getLang } from '@Utils/language/language';
import { logOut, setLoginType } from '@Redux/modules/api/authentication/actions';
import { invalidateAllCache } from '@Redux/modules/api/cache/actions';
import { updateCart } from '@Redux/modules/cart/actions';
import { clearCheckoutDetails } from '@Redux/modules/checkout/actions';
import useTelesales from '@Utils/hooks/useTeleasales';

const CheckoutBar: FunctionComponent = () => {
	const history = useHistory();
	const { translate } = useTranslate();
	const { isTelesales } = useTelesales();

	const goBackTelesales = () => {
		dispatch(setLoginType(undefined));
		dispatch(deleteWCTokens());
		dispatch(invalidateAllCache());
		dispatch(logOut());
		dispatch(clearCheckoutDetails());
		dispatch(
			setModal({
				type: null,
				data: null,
			})
		);
		dispatch(updateCart(true));
		history.push(`/${getLang()}/store`);
	};
	return (
		<NavigationBarWrapper>
			<Container>
				<Row align="center">
					<Col md={4}>
						<Row align="center">
							<LogoLinkWrapper to="/">
								<Icon name="omantelLogo" width={96} height={48} />
							</LogoLinkWrapper>
							<BackButtonWrapper>
								<TertiaryLargeButtonAuto
									uniqueId="checkout-bar.go-back"
									onClick={() => {
										isTelesales ? goBackTelesales() : history.goBack();
									}}
								>
									{translate('checkout-bar.go-back')}
								</TertiaryLargeButtonAuto>
							</BackButtonWrapper>
						</Row>
					</Col>
					<Col md={8}>
						<Hidden xs sm>
							<Row align="center" justify="end">
								<CheckoutStepper />
							</Row>
						</Hidden>
					</Col>
				</Row>
			</Container>
		</NavigationBarWrapper>
	);
};

export default CheckoutBar;
