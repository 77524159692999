import { BodyS, Caption, Subheading, DisplayMBoldH1, BodySBold, SubheadingH2 } from '@Components/Typography/Typography';
import styled from 'styled-components';
import { paddingLeft, right } from '@Utils/layout/layoutSupport';

export const BodySWrapper = styled(BodySBold)`
	cursor: pointer;
	text-decoration: underline;
`;

export const CardWrapper = styled.div`
	padding: 8px 16px;
`;

export const ReviewsQuantity = styled(BodyS)`
	margin: 10px 0 8px 0;
`;

export const ProductTitle = styled(DisplayMBoldH1)``;

export const ProducerCode = styled.div``;

export const RatingWrapper = styled.div`
	margin-top: 16px;
`;

export const CaptionWrapper = styled(Caption)`
	margin-left: 4px;
	cursor: pointer;
	text-decoration: underline;
`;

export const CheckAvailabilityWrapper = styled.div`
	display: flex;
	justify-content: center;
	margin-top: 10px;
`;

export const OutletPickupWrapper = styled.div<{ IsOutletAvailable?: boolean }>`
	display: flex;
	flex-direction: column;
	gap: 24px;
	${({ IsOutletAvailable }) => ({
		cursor: IsOutletAvailable ? 'pointer' : 'unset',
	})};
`;

export const IconWrapper = styled.div`
	margin-right: 18px;
`;

export const ColorPickerWrapper = styled.div`
	margin-bottom: 6px;
`;

export const ColorPickerTitle = styled(Subheading)`
	padding-bottom: 16px;
`;

export const CapacityPickerWrapper = styled.div`
	margin-bottom: 8px;
`;

export const CapacityPickerTitle = styled(Subheading)`
	padding-top: 18px;
	padding-bottom: 16px;
`;

export const AddToCompareContainer = styled.div`
	display: flex;
	gap: 4px;
`;

export const AddToCompareButton = styled(Caption)`
	${({ theme }) => ({
		color: theme.colors.primary,
	})};
	cursor: pointer;
`;

export const AddToCompareText = styled(Caption)`
	${({ theme }) => ({
		color: theme.colors.black87,
	})};
`;

export const DescriptionWrapper = styled.div`
	display: flex;
	${() => paddingLeft('16px')}
	align-items: flex-start;
	justify-content: center;
	max-width: 85%;
	flex-direction: column;
	gap: 4px;
`;

export const DividerWrapper = styled.div`
	margin-top: 24px;
	margin-bottom: 24px;
`;

export const OutletIconWrapper = styled.div`
	${() => paddingLeft('16px')}
`;

export const ChevronIconWrapper = styled.div`
	margin-top: 8px;
	position: absolute;
	${() => right('24px')}
`;

export const AppleProductTitle = styled(SubheadingH2)`
	text-decoration: underline;
`;
