import React, { FunctionComponent } from 'react';

const skeletons = {
	product: require('./skeletons/Product/Product'),
	searchResult: require('./skeletons/SearchResults/SearchResults'),
	productConfiguration: require('./skeletons/ProductConfiguration/ProductConfiguration'),
	shortDescription: require('./skeletons/ShortDescription/ShortDescription'),
	paymentSummary: require('./skeletons/PaymentSummary/PaymentSummary'),
	paymentSummaryWithPlan: require('./skeletons/PaymentSummary/PaymentSummaryWithPlan'),
	planSelection: require('./skeletons/PlanSelection/PlanSelection'),
	segmentSelection: require('./skeletons/SegmentSelection/SegmentSelection'),
	categoriesStore: require('./skeletons/CategoriesStore/CategoriesStore'),
	nestedCategories: require('./skeletons/NestedCategories/NestedCategories'),
	popularProducts: require('./skeletons/PopularProducts/PopularProducts'),
	paymentTokens: require('./skeletons/PaymentTokens/PaymentTokens'),
	categoryTitle: require('./skeletons/CategoryTitle/CategoryTitle'),
	orderTracking: require('./skeletons/OrderTracking/OrderTracking'),
	planBar: require('./skeletons/PlanBar/PlanBar'),
	orderTrackingDetails: require('./skeletons/OrderTracking/OrderTrackingDetails'),
	loginPrompt: require('./skeletons/LoginCard/LoginCard'),
	productCart: require('./skeletons/ProductCart/ProductCart'),
	cart: require('./skeletons/Cart/Cart'),
	subheading: require('./skeletons/Subheading/Subheading'),
	displayMBold: require('./skeletons/DisplayMBold/DisplayMBold'),
	cartItem: require('./skeletons/CartItem/CartItem'),
	upsellProduct: require('./skeletons/UpsellProduct/UpsellProduct'),
	category: require('./skeletons/Category/Category'),
	continueToCheckout: require('./skeletons/ContinueToCheckout/ContinueToCheckout'),
	empty: require('./skeletons/Empty/Empty'),
	deliveryAddress: require('./skeletons/DeliveryAddress/DeliveryAddress'),
	checkout4: require('./skeletons/Checkout4/Checkout4'),
	productTitle: require('./skeletons/ProductTitle/ProductTitle'),
	comparisonCards: require('./skeletons/ComparisonCards/ComparisonCards'),
	telesalesCart: require('./skeletons/TelesalesCart/TelesalesCart'),
	outletAvailability: require('./skeletons/OutletAvailability/OutletAvailability'),
	productCarousel: require('./skeletons/ProductCarousel/ProductCarousel'),
	upSellCart: require('./skeletons/UpSellCartItems/UpSellCartItems'),
	cardInstallmentPrice: require('./skeletons/CardInstallmentPrice/CardInstallmentPrice'),
	circularLoader: require('./skeletons/CircularLoader/CircularLoader'),
	smallCircularLoader: require('./skeletons/SmallCircularLoader/SmallCircularLoader'),
	searchListResults: require('./skeletons/SearchListResults/SearchListResults'),
	displayMBoldMyOmantel: require('./skeletons/DisplayMBoldMyOmantel/DisplayMBoldMyOmantel'),
	commitions: require('./skeletons/Commitions/Commitions'),
	planType: require('./skeletons/PlanType/PlanType'),
};

export type Skeleton = keyof typeof skeletons;

interface ISkeletonProps {
	type: Skeleton;
}

const Skeleton: FunctionComponent<ISkeletonProps> = ({ type, ...props }) => {
	if (skeletons[type]) {
		const Skeleton = skeletons[type].default;
		return <Skeleton {...props} />;
	} else {
		return null;
	}
};

export default Skeleton;
