import React, { FunctionComponent } from 'react';
import {
	SkeletonColumn,
	SkeletonRow,
	SkeletonCardContainer,
	Rectangular,
	DisplayFlex,
	Circle,
} from '@Components/Skeleton/Skeleton.styled';

const HORIZONTAL_MARGIN = 16;
const CIRCLE_ITEMS = 3;
const STORAGE_ITEMS = 6;
const CIRCLE_SIZE = 28;
const STORAGE_WIDTH = 88;
const STORAGE_HEIGHT = 40;
const STORAGE_MARGIN = 8;
const ITEM_HEIGHT = 16;

const ProductConfiguration: FunctionComponent = () => {
	return (
		<SkeletonCardContainer>
			<SkeletonRow marginTop={8}>
				<Rectangular width={100} height={ITEM_HEIGHT} marginBottom={4} />
				<Rectangular width="80%" height={34} />
			</SkeletonRow>
			<SkeletonRow marginTop={12}>
				<Rectangular width={150} height={ITEM_HEIGHT} marginBottom={8} />
				<Rectangular width={200} height={ITEM_HEIGHT} />
			</SkeletonRow>
			<SkeletonRow marginTop={24}>
				<Rectangular width={150} height={ITEM_HEIGHT} marginBottom={16} />
				<SkeletonColumn>
					<DisplayFlex flexDirection="row">
						<Circle marginBottom={HORIZONTAL_MARGIN} width={28} height={28} marginRight={16} />
						{[...Array(Math.floor(Math.random() * Math.floor(CIRCLE_ITEMS)))].map((_, index) => {
							return (
								<Circle
									key={index}
									marginBottom={HORIZONTAL_MARGIN}
									width={CIRCLE_SIZE}
									height={CIRCLE_SIZE}
									marginRight={16}
								/>
							);
						})}
					</DisplayFlex>
				</SkeletonColumn>
			</SkeletonRow>
			<SkeletonRow marginTop={12}>
				<Rectangular width={150} height={ITEM_HEIGHT} />
				<SkeletonColumn>
					<DisplayFlex flexDirection="row" style={{ flexWrap: 'wrap' }}>
						<Rectangular
							width={STORAGE_WIDTH}
							height={STORAGE_HEIGHT}
							marginRight={STORAGE_MARGIN}
							marginTop={STORAGE_MARGIN}
						/>
						{[...Array(Math.floor(Math.random() * Math.floor(STORAGE_ITEMS)))].map((_, index) => {
							return (
								<Rectangular
									key={index}
									width={STORAGE_WIDTH}
									height={STORAGE_HEIGHT}
									marginRight={STORAGE_MARGIN}
									marginTop={STORAGE_MARGIN}
								/>
							);
						})}
					</DisplayFlex>
				</SkeletonColumn>
			</SkeletonRow>
			<SkeletonRow marginTop={12}>
				<DisplayFlex flexDirection="row">
					<Circle marginBottom={HORIZONTAL_MARGIN} width={36} height={36} marginRight={16} />
					<SkeletonColumn>
						<DisplayFlex flexDirection="column">
							<Rectangular width={80} height={ITEM_HEIGHT} marginBottom={6} />
							<Rectangular width={150} height={12} />
						</DisplayFlex>
					</SkeletonColumn>
				</DisplayFlex>
			</SkeletonRow>
		</SkeletonCardContainer>
	);
};

export default ProductConfiguration;
