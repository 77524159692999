import styled from 'styled-components';

interface IPaymentWrapperProps {
	footerHeight?: number;
}

export const PaymentWrapper = styled.div<IPaymentWrapperProps>`
	position: relative;
	${({ footerHeight, theme }) => ({
		backgroundColor: theme.colors.background,
		paddingBottom: footerHeight,
	})}
`;
