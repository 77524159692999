import React, { FunctionComponent } from 'react';
import { Col } from 'react-grid-system';
import {
	SkeletonColumn,
	SkeletonRow,
	SkeletonGridCardContainer,
	Rectangular,
	DisplayFlex,
	Circle,
} from '@Components/Skeleton/Skeleton.styled';

const HORIZONTAL_MARGIN = 16;
const CIRCLE_ITEMS = 3;

const GridProductSkeleton: FunctionComponent = () => {
	return (
		<Col lg={4} md={6} sm={12}>
			<SkeletonGridCardContainer marginTop={10} marginBottom={32}>
				<SkeletonRow marginTop={8}>
					<Rectangular width={155} height={15} marginBottom={4} />
					<Rectangular width={204} height={15} />
				</SkeletonRow>

				<SkeletonRow marginBottom={47} marginTop={30}>
					<DisplayFlex flexDirection="row" alignItems="center">
						<SkeletonColumn>
							<DisplayFlex flexDirection="column">
								<Circle marginBottom={HORIZONTAL_MARGIN} />
								{[...Array(Math.floor(Math.random() * Math.floor(CIRCLE_ITEMS)))].map((_, index) => {
									return <Circle key={index} marginBottom={HORIZONTAL_MARGIN} />;
								})}
							</DisplayFlex>
						</SkeletonColumn>
						<SkeletonColumn>
							<Rectangular width={131} height={176} marginRight={32} />
						</SkeletonColumn>
					</DisplayFlex>
				</SkeletonRow>

				<SkeletonRow>
					<DisplayFlex>
						<SkeletonColumn justifyContent="flex-end" alignItems="flex-end">
							<SkeletonRow marginBottom={27}>
								<DisplayFlex justifyContent="space-between">
									<Rectangular width={60} height={15} />
									<DisplayFlex>
										<Rectangular width={61} height={24} />
										<Rectangular width={26} height={15} marginLeft={4} />
									</DisplayFlex>
								</DisplayFlex>
							</SkeletonRow>
							<SkeletonRow>
								<Rectangular width="100%" height={40} />
							</SkeletonRow>
						</SkeletonColumn>
					</DisplayFlex>
				</SkeletonRow>
			</SkeletonGridCardContainer>
		</Col>
	);
};

export default GridProductSkeleton;
