import React, { FunctionComponent } from 'react';
import { ListItem } from './DropdownItem.styled';
import ConditionalRender from '@Components/ConditionalRender/ConditionalRender';
import { Value, ValueBold } from '@Components/Typography/Typography.styled';
import { IDropdownItem } from '@Components/controls/Dropdown/Dropdown';

export const DROPDOWN_ITEM_HEIGHT = 48;

interface IDropdownItemProps {
	item: IDropdownItem;
	handleClickItem: (item: IDropdownItem) => void;
	selectedItem: IDropdownItem | null;
	active: boolean;
	setActive: () => void;
}

const DropdownItem: FunctionComponent<IDropdownItemProps> = ({
	item,
	handleClickItem,
	selectedItem,
	active,
	setActive,
}) => {
	const color: types.theme.themeColors = !!item.disabled ? 'black38' : 'black87';

	const Label = !!selectedItem && item.id === selectedItem.id ? ValueBold : Value;

	const handleOnMouseEnter = () => {
		if (!item.disabled) {
			setActive();
		}
	};

	const handleOnClick = () => {
		handleClickItem(item);
	};

	return (
		<ListItem
			key={item.id}
			onClick={handleOnClick}
			disabled={!!item.disabled}
			active={active}
			onMouseEnter={handleOnMouseEnter}
		>
			<ConditionalRender
				show={!!item.prefix}
				onTrue={() => (
					<div className="data-container">
						<Value className="prefix" color="black38">
							{item.prefix}
						</Value>
						<Label color={color}>{item.text}</Label>
					</div>
				)}
				onFalse={() => <Label color={color}>{item.text}</Label>}
			/>
			<ConditionalRender show={!!item.hint} onTrue={() => <Value color="black38">{item.hint}</Value>} />
		</ListItem>
	);
};

export default DropdownItem;
