import React, { FunctionComponent } from 'react';
import { SkeletonRow, Rectangular } from '@Components/Skeleton/Skeleton.styled';

const CategoryTitle: FunctionComponent = () => {
	return (
		<SkeletonRow marginTop={16} marginBottom={24}>
			<Rectangular width={360} height={40} color="grey300" />
		</SkeletonRow>
	);
};

export default CategoryTitle;
