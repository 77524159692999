import React, { useCallback, useMemo } from 'react';
import { ButtonWrapper, CategoryCardContainer, CategoryTitle } from './CategoryCard.styled';
import ConditionalRender from '@Components/ConditionalRender/ConditionalRender';
import { SecondaryMediumButtonFixed } from '@Components/controls/Button/Button';
import { useHistory, useLocation } from 'react-router-dom';
import { ISingleCategory } from '@ApiModels/flatCategories';
import useTranslate from '@Utils/hooks/useTranslate';
import BusyIndicator from '@Components/BusyIndicator/BusyIndicator';
import { GET_CATEGORIES_BI } from '@Views/Store/Store';
import MegaMenuItem, { MegaMenuItemType } from '@Components/MegaMenuItem/MegaMenuItem';
import capitalizeFirstLetter from '@Utils/converters/capitalizeFirstLetter';
import { getLang } from '@Utils/language/language';

const CategoryCard = ({
	params: { categories, selectedCategory, onClick },
}: types.cards.ICategoryCardProps): JSX.Element => {
	const history = useHistory();
	const { translate } = useTranslate();
	const location = useLocation();

	const query = useMemo(() => {
		return location.search;
	}, [location.search]);

	const lastSelectedCategoryId = selectedCategory[selectedCategory.length - 1];

	const parentCategory = useMemo(() => {
		return categories.find((cat: ISingleCategory) => cat.id === selectedCategory[selectedCategory.length - 2]);
	}, [categories, selectedCategory]);

	const currentCategory = useMemo(() => {
		return categories.find((cat: ISingleCategory) => cat.id === lastSelectedCategoryId);
	}, [categories, lastSelectedCategoryId]);

	const getTitle = (name: string) => {
		// TODO: change it to number of filtered items when ready
		return capitalizeFirstLetter(name);
	};

	const handleGoBack = useCallback(() => {
		history.push(
			`/${getLang()}/store${parentCategory?.identifier ? `/${parentCategory?.identifier}` : ''}${query ?? ''}`
		);
		onClick((state) => {
			return state.slice(0, state.length - 1);
		});
	}, [parentCategory, query]);

	const renderCategories = useMemo(() => {
		return categories
			.filter((category) => category.parentId === lastSelectedCategoryId)
			.map(({ name, id, level, identifier }) => {
				const handleOnClick = () => {
					onClick((state) => {
						state[level] = id;
						return [...state];
					});
				};
				return (
					<MegaMenuItem
						key={id}
						onClick={handleOnClick}
						type={MegaMenuItemType.ITEM}
						name={getTitle(name)}
						iconLeft="chevronRight"
						to={`/${getLang()}/store/${identifier}${query ?? ''}`}
						subCategory={level !== 1}
					/>
				);
			});
	}, [categories, lastSelectedCategoryId, query]);

	return (
		<CategoryCardContainer>
			<ConditionalRender
				show={selectedCategory.length > 1}
				onTrue={() => (
					<>
						<ButtonWrapper>
							<SecondaryMediumButtonFixed
								uniqueId="store.categories.back-to"
								onClick={() => {
									handleGoBack();
								}}
							>
								{translate(
									'store.categories.back-to',
									parentCategory?.name ?? translate('store.categories.all-categories')
								)}
							</SecondaryMediumButtonFixed>
						</ButtonWrapper>
						<ConditionalRender
							show={!!currentCategory?.hasChildren}
							onTrue={() => <CategoryTitle>{currentCategory?.name}</CategoryTitle>}
						/>
					</>
				)}
			/>
			<BusyIndicator listener={GET_CATEGORIES_BI} skeleton="categoriesStore">
				<>{renderCategories}</>
			</BusyIndicator>
		</CategoryCardContainer>
	);
};

export default CategoryCard;
