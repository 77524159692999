import React, { createContext, useContext, useMemo, useEffect } from 'react';
import { useCart } from '@Utils/context/CartContext';
import { isDisableBnpl } from '@Config/features.config';
import { CreditRating } from '@Config/app.config';
import { setBuyNowPayLater } from '@Redux/modules/buyNowPayLater/actions';
import { useDispatch, useSelector } from 'react-redux';
import { remoteConfigSelector } from '@Redux/modules/settings/remoteConfig/selectors';

const OMANI_NATIONALITY = 'Omani';
const BNPL_PLAN_ACTIONS = ['BNPL_BAQATI', 'BNPL_HBB', 'BNPL_WFBB'];

interface IBuyNowPayLater {
	isBuyNowPayLaterFlow: boolean;
	isBuyNowPayLaterIframe: boolean;
	isBuyNowPayLaterInCart: boolean;
	canIaddBuyNowPayLaterToCart: boolean;
	buyNowPayLaterIframeUrl: string;
	buyNowPayLaterSessionId: string;
	buyNowPayLaterItemDuration: string;
}

export const BuyNowPayLaterContext = createContext({
	isBuyNowPayLaterFlow: false,
	isBuyNowPayLaterIframe: false,
	isBuyNowPayLaterInCart: false,
	canIaddBuyNowPayLaterToCart: false,
	buyNowPayLaterIframeUrl: '',
	buyNowPayLaterSessionId: '',
	buyNowPayLaterItemDuration: '0',
});

export const useBuyNowPayLater = (): IBuyNowPayLater => useContext(BuyNowPayLaterContext);
const BuyNowPayLaterContextProvider = ({ children }: { children: any }): any => {
	const { buyNowPayLaterSessionId, buyNowPayLaterIframeUrl, isBuyNowPayLaterIframe } = useSelector(
		(state: types.redux.IState) => state.buyNowPayLater
	);
	const telemarketer = useSelector((state: types.redux.IState) => state.api.authentication.telemarketer);
	const { nationality, creditRating } = useSelector((state: types.redux.IState) => state.api.personalData);
	const isLogged = useSelector((state: types.redux.IState) => state.api.authentication.signedIn);
	const isExpApproved = useSelector((state: types.redux.IState) => state.selectedItems.isExpApproved);
	const remoteFeatureConfig = useSelector(remoteConfigSelector);
	const DISABLE_BUY_NOW_PAY_LATER: boolean = isDisableBnpl(remoteFeatureConfig);
	const { cart } = useCart();
	const dispatch = useDispatch();

	const isBuyNowPayLaterInCart = useMemo(() => {
		const bnplAction = cart.items.some((item) => BNPL_PLAN_ACTIONS.includes(item.PA || ''));
		return !!isLogged && bnplAction;
	}, [isLogged, cart.items]);

	const buyNowPayLaterItemDuration = useMemo(() => {
		const bnplItem = cart.items.find((item) => BNPL_PLAN_ACTIONS.includes(item.PA || ''));
		return bnplItem?.installmentDuration || '0';
	}, [isLogged, cart.items]);

	const isBuyNowPayLaterFlow = useMemo(() => {
		return (
			!DISABLE_BUY_NOW_PAY_LATER &&
			!!isLogged &&
			!!nationality &&
			nationality?.includes(OMANI_NATIONALITY) &&
			!!creditRating &&
			creditRating !== CreditRating.GREEN &&
			!telemarketer &&
			!isExpApproved
		);
	}, [nationality, creditRating, isLogged, isExpApproved]);

	useEffect(() => {
		if (
			(!isLogged || cart.items.length === 0) &&
			!!buyNowPayLaterSessionId &&
			!!buyNowPayLaterIframeUrl &&
			isBuyNowPayLaterIframe
		) {
			dispatch(
				setBuyNowPayLater({
					buyNowPayLaterSessionId: '',
					buyNowPayLaterIframeUrl: '',
					isBuyNowPayLaterIframe: false,
				})
			);
		}
	}, [isLogged, cart.items, buyNowPayLaterSessionId, buyNowPayLaterIframeUrl, isBuyNowPayLaterIframe]);

	const canIaddBuyNowPayLaterToCart = useMemo(() => {
		return !!isLogged && cart.items.length === 0;
	}, [isLogged, cart]);

	return (
		<BuyNowPayLaterContext.Provider
			value={{
				buyNowPayLaterIframeUrl,
				isBuyNowPayLaterFlow,
				isBuyNowPayLaterIframe,
				isBuyNowPayLaterInCart,
				canIaddBuyNowPayLaterToCart,
				buyNowPayLaterSessionId,
				buyNowPayLaterItemDuration,
			}}
		>
			{children}
		</BuyNowPayLaterContext.Provider>
	);
};

export default BuyNowPayLaterContextProvider;
