import { Digit } from '@Components/Typography/Typography.styled';
import styled from 'styled-components';

export const CounterSectionContainer = styled.div`
	display: flex;
	flex-direction: column;
`;

export const DigitWrapper = styled(Digit)`
	${({ theme }) => ({
		color: theme.colors.white,
	})}
`;

export const DigitContainer = styled.div<{ isXS: boolean; isMD: boolean }>`
	${({ isXS, isMD }) => {
		if (isXS) {
			return { padding: '2px 3px 0px 3px' };
		}
		if (isMD) {
			return { padding: '6px 4.5px 4.5px 4.5px', marginBottom: 4 };
		}
		return { padding: '10px 7px 7px 7px', marginBottom: 8 };
	}}
	${({ theme }) => ({
		backgroundColor: theme.colors.primary,
		border: `1px solid ${theme.colors.primary}`,
	})};
	box-shadow: 0 4px 12px -6px rgba(0, 0, 0, 0.2);
	border-radius: 5px;
	display: flex;
	align-items: center;
	justify-content: center;
`;

export const Row = styled.div<{ isXS: boolean }>`
	display: flex;
	${({ isXS }) => (isXS ? 'gap: 2px;' : 'gap: 4px;')}
	width: 100%;
	justify-content: center;
	& > p {
		text-transform: uppercase;
	}
`;
