import styled from 'styled-components';
import { MAX_DROPDOWN_HEIGHT, LIST_VERTICAL_PADDING } from './Dropdown';
import { isRTL, left } from '@Utils/layout/layoutSupport';

export enum DropdownType {
	SIMPLE = 'SIMPLE',
	REGULAR = 'REGULAR',
}

export enum DropdownHeight {
	AUTO = 'AUTO',
	SCROLL = 'SCROLL',
}

export enum LabelType {
	BASIC = 'BASIC',
	FLOATING = 'FLOATING',
}

interface IListWrapperProps {
	dropdownHeight: DropdownHeight;
	open: boolean;
	dropdownListWidth?: string | number;
	overflow?: boolean;
}

interface IHeaderWrapperProps {
	dropdownType: DropdownType;
	error: boolean;
	disabled: boolean;
	labelType: LabelType;
	backgroundColor: types.theme.themeColors;
	dropdownWidth?: number | string;
	open: boolean;
	withoutLabel: boolean;
}

interface IIconWrapperProps {
	dropdownType: DropdownType;
	open: boolean;
	hovered: boolean;
}

interface ILabelWrapperProps {
	labelType: LabelType;
	active: boolean;
	hovered: boolean;
}

interface IFloatingLabelProps {
	active: boolean;
	disabled: boolean;
	error: boolean;
}

export const Wrapper = styled.div<{ dropdownWidth?: string | number }>`
	display: inline-block;
	max-width: 100%;
	${({ dropdownWidth }) => {
		return { width: !!dropdownWidth ? dropdownWidth : 'unset' };
	}}
`;

export const InputWrapper = styled.div`
	display: flex;
`;

export const HeaderWrapper = styled.div<IHeaderWrapperProps>`
	position: relative;
	border-radius: 4px;
	box-sizing: border-box;
	background-color: ${({ theme, backgroundColor }) => theme.colors[backgroundColor]};
	transition: all 0.1s ease-in-out;
	max-width: 100%;
	display: flex;
	${({ disabled, dropdownType, theme }) =>
		disabled &&
		dropdownType === DropdownType.REGULAR && {
			borderColor: `${theme.colors.black12}!important`,
			backgroundColor: theme.colors.black6,
		}}
	${({ disabled }) => !disabled && { cursor: 'pointer' }}
	${({ dropdownType, theme, dropdownWidth = 320 }) =>
		dropdownType === DropdownType.SIMPLE
			? {
					padding: isRTL() ? '0 8px 0 24px' : '0 24px 0 8px',
					height: 40,
					minWidth: 134,
			  }
			: {
					width: dropdownWidth,
					height: 56,
					padding: isRTL() ? '0 8px 0 40px' : '0 40px 0 8px',
					border: `solid 1px ${theme.colors.black54}`,
			  }}
	${({ labelType, dropdownType, withoutLabel }) =>
		labelType === LabelType.FLOATING
			? {
					flexDirection: 'column',
					alignItems: 'flex-start',
					justifyContent: dropdownType === DropdownType.REGULAR && !withoutLabel ? 'flex-end' : 'center',
					paddingBottom: dropdownType === DropdownType.REGULAR ? 8 : 0,
			  }
			: { alignItems: 'center' }}
	
	${({ withoutLabel }) =>
		withoutLabel &&
		` 
			& > p {
				margin-top: 10px;
			}
	`}
	&:hover {
		${({ dropdownType, disabled, theme, open }) =>
			!disabled &&
			(dropdownType === DropdownType.SIMPLE
				? { backgroundColor: theme.colors.primary6 }
				: !open && {
						borderWidth: `2px`,
						borderColor: theme.colors.black87,
						padding: isRTL() ? '0 7px 7px 40px' : '0 40px 7px 7px',
				  })}
	}
	&:active {
		${({ dropdownType, disabled, theme }) =>
			!disabled &&
			(dropdownType === DropdownType.SIMPLE
				? { backgroundColor: theme.colors.primary15 }
				: { borderColor: theme.colors.primary })}
	}
	${({ error, dropdownType, theme }) =>
		error && dropdownType === DropdownType.REGULAR && { borderColor: theme.colors.warning, borderWidth: 1.5 }}
	${({ open, theme, dropdownType }) =>
		open && dropdownType === DropdownType.REGULAR && { borderColor: theme.colors.primary, borderWidth: `2px` }}
`;

export const ListWrapper = styled.div<IListWrapperProps>`
	background-color: ${({ theme }) => theme.colors.white};
	box-shadow: 0 7px 8px 0 rgba(0, 0, 0, 0.05), 0 5px 22px 4px rgba(0, 0, 0, 0.04), 0 12px 17px 2px rgba(0, 0, 0, 0.04);
	border-radius: 4px;
	transition: height 0.1s ease-in-out;
	position: absolute;
	z-index: 2;
	box-sizing: border-box;
	${() => ({ padding: `${LIST_VERTICAL_PADDING}px 0` })}
	${({ dropdownListWidth = 240 }) => {
		return { width: dropdownListWidth };
	}}
	${({ dropdownHeight, overflow }) =>
		dropdownHeight === DropdownHeight.SCROLL
			? {
					maxHeight: !!overflow ? '350px' : MAX_DROPDOWN_HEIGHT,
					overflow: 'auto',
			  }
			: {
					maxHeight: 'auto',
					overflow: 'hidden',
			  }}
	${({ open }) => !open && { height: 0, maxHeight: 0, padding: 0 }}
`;

export const MessageWrapper = styled.div`
	margin-top: 8px;
`;

export const LabelWrapper = styled.div<ILabelWrapperProps>`
	${({ labelType, active, hovered }) => {
		return labelType === LabelType.BASIC
			? {
					paddingRight: isRTL() ? 0 : 16,
					paddingLeft: isRTL() ? 16 : 0,
			  }
			: {
					transition: 'all 0.1s ease-in',
					position: 'absolute',
					top: active ? (hovered ? 6 : 7) : hovered ? 13.5 : 14,
			  };
	}}
	${({ hovered }) => {
		return left(hovered ? 7 : 8);
	}}
`;

export const DropdownFloatingLabel = styled.div<IFloatingLabelProps>`
	transition: all 0.1s ease-in-out;
	${({ active, theme }) => (active ? { ...theme.typography.captionBold } : { ...theme.typography.floatingLabel })}
	color: ${({ disabled, error, theme }) => {
		if (disabled) {
			return theme.colors.black38;
		}
		if (error) {
			return theme.colors.warning;
		}
		return theme.colors.black54;
	}}
`;

export const IconWrapper = styled.div<IIconWrapperProps>`
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	transform-origin: center;
	transition: all 0.1s ease-in-out;
	${({ dropdownType, hovered }) => {
		if (isRTL()) {
			return dropdownType === DropdownType.SIMPLE ? { left: 0 } : { left: hovered ? '7px' : '8px' };
		} else {
			return dropdownType === DropdownType.SIMPLE ? { right: 0 } : { right: hovered ? '7px' : '8px' };
		}
	}}

	${({ open }) => open && { transform: 'translateY(-50%) rotate(180deg)' }}
`;

export const InputIconWrapper = styled.div<IIconWrapperProps>`
	position: absolute;
	transform: translateY(-50%);
	transform-origin: center;
	transition: all 0.1s ease-in-out;
	margin-top: 30px;
	${({ dropdownType }) => {
		if (isRTL()) {
			return dropdownType === DropdownType.SIMPLE ? { left: 0 } : { left: '24px' };
		} else {
			return dropdownType === DropdownType.SIMPLE ? { right: 0 } : { right: '24px' };
		}
	}}
	
}}
	${({ open }) => open && { transform: 'translateY(-50%) rotate(180deg)' }}
`;

export const DropdownContainer = styled.div<{ dropdownWidth?: number | string }>`
	display: inline-block;
	max-width: 100%;
	&:focus {
		outline: none;
	}
	${({ dropdownWidth }) => {
		return { width: !!dropdownWidth ? dropdownWidth : 'unset' };
	}}
`;
