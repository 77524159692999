import { UPDATE_PROFILE } from '@Components/Card/cards/ContactDetails/ContactDetails';
import ConditionalRender from '@Components/ConditionalRender/ConditionalRender';
import { PrimaryLargeButtonFlex, SecondaryLargeButtonFlex } from '@Components/controls/Button/Button';
import Icon from '@Components/Icon/Icon';

import useTranslate from '@Utils/hooks/useTranslate';
import { SET_ORDER_BI } from '@Views/Checkout/Checkout';
import React, { FunctionComponent } from 'react';
import { Col, Hidden, Row, Visible } from 'react-grid-system';
import { useParams } from 'react-router-dom';
import {
	CheckoutNextStepCardContainer,
	HighlightedText,
	NextStep,
	NextStepTitle,
	NextStepWrapper,
	SecureTransactionText,
	SecureTransactionWrapper,
	SetOrderDescription,
} from './CheckoutNextStep.styled';

const ICON_SIZE = 24;

const CheckoutNextStep: FunctionComponent<types.cards.ICheckoutNextStep> = ({
	emmitEvent,
	params: { buttonDisabled, onlyGiftCardOrDigitals, isBuyHbbWfbbProcess, isPrepaidProcess },
}) => {
	const { translate } = useTranslate();
	const { step } = useParams<{ step: string }>();
	const nextStep = Number(step ?? 0) + 1;

	const handleNextStepClick = () => {
		emmitEvent && emmitEvent('nextStep');
	};

	const handleSetOrderClick = () => {
		emmitEvent && emmitEvent('setOrder');
	};

	const Button = () => (
		<SecondaryLargeButtonFlex
			uniqueId="'checkout-next-step.next"
			onClick={handleNextStepClick}
			disabled={buttonDisabled}
			listener={UPDATE_PROFILE}
		>
			{translate('checkout-next-step.next')}
		</SecondaryLargeButtonFlex>
	);

	const SetOrderButton = () => (
		<PrimaryLargeButtonFlex
			uniqueId="checkout-next-step.set-order"
			onClick={handleSetOrderClick}
			listener={SET_ORDER_BI}
		>
			{translate('checkout-next-step.set-order')}
		</PrimaryLargeButtonFlex>
	);

	const SecureTransaction = () => (
		<SecureTransactionWrapper>
			<Icon name="lock" fill="black54" width={ICON_SIZE} height={ICON_SIZE} />
			<SecureTransactionText color="black54">
				{translate('product.cart-card.transaction.part-1')}
				<HighlightedText color="black54">{translate('product.cart-card.transaction.part-2')}</HighlightedText>
			</SecureTransactionText>
		</SecureTransactionWrapper>
	);

	const NextStepDescription = () => (
		<NextStepWrapper>
			<NextStepTitle isPrepaidProcess>{translate('checkout-next-step.next-step')} </NextStepTitle>
			<NextStep isPrepaidProcess>
				{translate(
					`checkout-bar.step-${onlyGiftCardOrDigitals || isBuyHbbWfbbProcess ? nextStep + 1 : nextStep}`
				)}
			</NextStep>
		</NextStepWrapper>
	);

	return (
		<CheckoutNextStepCardContainer>
			<Visible xl lg xs>
				<Col xl={12} lg={12} xs={12}>
					<ConditionalRender
						show={nextStep > (onlyGiftCardOrDigitals ? 3 : 4)}
						onTrue={() => (
							<>
								<SetOrderDescription>
									{translate('checkout-next-step.set-order.description')}
								</SetOrderDescription>
								<SetOrderButton />
							</>
						)}
						onFalse={() => (
							<>
								<NextStepDescription />
								<Button />
							</>
						)}
					/>
				</Col>
			</Visible>
			<Hidden xs sm md>
				<Col xl={12} lg={12}>
					<SecureTransaction />
				</Col>
			</Hidden>
			<Visible sm md>
				<Col md={12} sm={12}>
					<ConditionalRender
						show={nextStep > (onlyGiftCardOrDigitals ? 3 : 4)}
						onTrue={() => (
							<Row>
								<Col md={6} sm={6}>
									<SetOrderDescription>
										{translate('checkout-next-step.set-order.description')}
									</SetOrderDescription>

									<SecureTransaction />
								</Col>
								<Col md={6} sm={6}>
									<SetOrderButton />
								</Col>
							</Row>
						)}
						onFalse={() => (
							<Row>
								<Col md={6} sm={6}>
									<NextStepDescription />
									<SecureTransaction />
								</Col>
								<Col md={6} sm={6}>
									<Button />
								</Col>
							</Row>
						)}
					/>
				</Col>
			</Visible>
		</CheckoutNextStepCardContainer>
	);
};

export default CheckoutNextStep;
