import styled from 'styled-components';

export const Background = styled.div<{ color: types.theme.themeColors }>`
	${({ theme, color }) => {
		return {
			background: theme.colors[color],
			height: '100%',
			left: 0,
			position: 'fixed',
			top: 0,
			width: '100%',
			zIndex: 100,
		};
	}}
`;

export const Content = styled.div<{ withoutPadding?: boolean }>`
	${() => ({
		position: 'absolute',
		left: '50%',
		top: '50%',
		transform: 'translate(-50%, -50%)',
		zIndex: 5,
		maxWidth: '100%',
	})};
	overflow: hidden;
`;
