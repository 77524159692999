import { IGiftCardByOrderId } from '@ApiModels/userGiftCards';
import ConditionalRender from '@Components/ConditionalRender/ConditionalRender';
import Divider from '@Components/Divider/Divider';
import { BodySBold, ButtonM, HeadlineBold } from '@Components/Typography/Typography.styled';
import { copyToClipboard } from '@Utils/copyToClipboard/copyToClipboard';
import useTranslate from '@Utils/hooks/useTranslate';
import moment from 'moment';
import React, { FunctionComponent, useState } from 'react';
import { CopyToClipboard, CopyToClipboardClick, SerialNum, SerialNumAndExpiryDate } from './GiftCardCode.styled';

interface IGiftCardCopyToClipboard {
	giftCard: IGiftCardByOrderId;
}

const GiftCardCodeCopyToClipboard: FunctionComponent<IGiftCardCopyToClipboard> = ({ giftCard }) => {
	const { translate } = useTranslate();
	const [copy, setCopy] = useState<boolean>(false);
	const [copyValue, setCopyValue] = useState<string>('');

	const copyGiftCardCode = (value: string) => {
		if (copyToClipboard(value)) {
			setCopy(true);
			setCopyValue(value);
			setTimeout(() => {
				setCopy(false);
				setCopyValue('');
			}, 1200);
		}
	};

	return (
		<>
			<CopyToClipboard>
				<HeadlineBold>{giftCard.code}</HeadlineBold>
				<CopyToClipboardClick onClick={() => copyGiftCardCode(giftCard.code)}>
					<ConditionalRender
						show={!!copy && copyValue === giftCard.code}
						onTrue={() => <ButtonM color="positive">{translate('copy.to.clipboard.success')}</ButtonM>}
						onFalse={() => <ButtonM color="primary">{translate('copy.to.clipboard')}</ButtonM>}
					/>
				</CopyToClipboardClick>
			</CopyToClipboard>
			<Divider marginBottom={8} withoutLine />
			<SerialNumAndExpiryDate>
				<SerialNum>{translate('gift.card.serial', giftCard.serial ?? '-')}</SerialNum>
				<BodySBold color="black12">|</BodySBold>
				<SerialNum>
					{translate('gift.card.expiry.date', moment(giftCard.expiryDate).format('DD MMM YYYY') ?? '-')}
				</SerialNum>
			</SerialNumAndExpiryDate>
		</>
	);
};

export default GiftCardCodeCopyToClipboard;
