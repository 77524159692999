import { ScreenClass, useScreenClass } from 'react-grid-system';

interface IUseScreenClass {
	screen: (matchClass: ScreenClass | ScreenClass[]) => boolean;
	screenClass: ScreenClass;
}

const useScreen = (): IUseScreenClass => {
	const screenClass = useScreenClass() as ScreenClass;

	const screen = (matchClass: ScreenClass | ScreenClass[]) => {
		if (Array.isArray(matchClass)) {
			return matchClass.includes(screenClass);
		}
		return screenClass === matchClass;
	};
	return { screen, screenClass };
};

export default useScreen;
