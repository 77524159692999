export interface IInventoryByPartNumber {
	inventory: {
		availableQuantity: string;
		onlineStoreName: string;
		productId: string;
		unitOfMeasure: string;
		inventoryStatus: string;
		onlineStoreId: string;
	}[];
}

const model: types.models.IInputModel = {
	inventory: ['response.InventoryAvailability', []],
};

export default model;
