import React, { FunctionComponent } from 'react';
import { SkeletonRow, SkeletonCardContainer, Rectangular } from '@Components/Skeleton/Skeleton.styled';

const DESCRIPTION_LINES = 4;

const ShortDescription: FunctionComponent = () => {
	return (
		<SkeletonCardContainer marginTop={16}>
			<SkeletonRow marginTop={8} marginBottom={8}>
				<Rectangular width={80} height={16} marginBottom={16} />
				<Rectangular width="100%" height={17} marginBottom={2} />
				{[...Array(Math.floor(Math.random() * Math.floor(DESCRIPTION_LINES)))].map((_, index) => {
					return <Rectangular key={index} width="100%" height={17} marginBottom={2} />;
				})}
			</SkeletonRow>
		</SkeletonCardContainer>
	);
};

export default ShortDescription;
