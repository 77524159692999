import styled from 'styled-components';
import { matchScreen } from '@Utils/layout/layoutSupport';

export const ModalWrapper = styled.div`
	${() => {
		return {
			width: matchScreen(['xl', 'lg']) ? 600 : '100vw',
		};
	}}
`;
