import { MiniDescription, ParagraphMBold } from '@Components/Typography/Typography.styled';
import styled from 'styled-components';

export const PickupOrderContainer = styled.div`
	padding: 16px;
	background-image: linear-gradient(to right, #fa184e, #ff7e16);
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

export const TitleWrapper = styled(ParagraphMBold)`
	${({ theme }) => ({
		color: theme.colors.white,
	})}
`;

export const DescriptionWrapper = styled(MiniDescription)`
	${({ theme }) => ({
		color: theme.colors.white70,
	})}
`;

export const TitleContainer = styled.div``;
