import React, { FunctionComponent } from 'react';
import { SubTitle, ProductUnavailableWrapper, ButtonWrapper, ErrorTitle } from './ProductUnavailable.styled';
import { PrimaryLargeButtonFlex } from '@Components/controls/Button/Button';
import useTranslate from '@Utils/hooks/useTranslate';
import { useHistory } from 'react-router-dom';
import { getLang } from '@Utils/language/language';
import Icon from '@Components/Icon/Icon';
import useScreen from '@Utils/hooks/useScreen';

const ERROR_ICON_SIZE = 48;

const ProductUnavailable: FunctionComponent = () => {
	const { translate } = useTranslate();
	const history = useHistory();

	const { screen } = useScreen();

	return (
		<ProductUnavailableWrapper
			sizes={{ isL: screen('lg'), isMd: screen('md'), isSm: screen('sm'), isXs: screen('xs') }}
		>
			<Icon height={ERROR_ICON_SIZE} width={ERROR_ICON_SIZE} fill="warning" name="error" />
			<ErrorTitle color="positive">
				{translate('microsoft-automation-proccess.product-unavailable.main-title')}
			</ErrorTitle>
			<SubTitle>{translate('microsoft-automation-proccess.product-unavailable-sub-title-1')}</SubTitle>
			<SubTitle margin="0px 0px 32px">
				{translate('microsoft-automation-proccess.product-unavailable-sub-title-2')}
			</SubTitle>

			<ButtonWrapper isXs={screen('xs')}>
				<PrimaryLargeButtonFlex
					leftPadding={-8}
					rightPadding={-8}
					uniqueId="product-unavailable.go-to-microsoft-products"
					onClick={() => {
						history.push(`/${getLang()}/store/software`);
					}}
				>
					{translate('microsoft-automation-proccess.go-to-microsoft-products')}
				</PrimaryLargeButtonFlex>
			</ButtonWrapper>
		</ProductUnavailableWrapper>
	);
};

export default ProductUnavailable;
