import React from 'react';
import useTranslate from '@Utils/hooks/useTranslate';
import { Row, Col } from 'react-grid-system';
import {
	InsuranceOfferCardWraper,
	InsuranceSection,
	SectionData,
	SectionSubtitle,
} from './InsuranceSelectedOfferDetails.styled';
import { useInsurance, INSURANCE_TYPE } from '@Utils/context/InsuranceContext';
import Icon from '@Components/Icon/Icon';
import { ParagraphMBold } from '@Components/Typography/Typography';
import Divider from '@Components/Divider/Divider';

const InsuranceSelectedOfferDetails = (): JSX.Element => {
	const { vehicleInformation, insuranceType, selectedProvider } = useInsurance();
	const { translate } = useTranslate();

	const ICON_SIZE = 40;

	return (
		<InsuranceOfferCardWraper>
			<Row>
				<Col>
					<InsuranceSection>
						<Icon name="vehicle" width={ICON_SIZE} height={ICON_SIZE} />
						<SectionData>
							<ParagraphMBold>{`${vehicleInformation?.manufacturer}, ${vehicleInformation?.model}`}</ParagraphMBold>
							<SectionSubtitle>{`${vehicleInformation?.vehiclePlateCharCode}/${vehicleInformation?.vehicleNo}`}</SectionSubtitle>
						</SectionData>
					</InsuranceSection>
				</Col>
			</Row>
			<Divider marginBottom={12} withoutLine />
			<Row>
				<Col>
					<InsuranceSection>
						<Icon name="shield" width={ICON_SIZE} height={ICON_SIZE} />
						<SectionData>
							<ParagraphMBold>{translate('insurance.offer.benefits.label')}</ParagraphMBold>
							<Row>
								<Col>
									<SectionSubtitle>
										{insuranceType === INSURANCE_TYPE.FULL_COMPREHENSIVE
											? translate('insurance.type.choice1')
											: translate('insurance.type.choice2')}
									</SectionSubtitle>
								</Col>
							</Row>
							{selectedProvider?.features?.map(({ name }, index) => (
								<Row key={`insuranceOfferFeature-${index}`}>
									<Col>
										<SectionSubtitle>{name}</SectionSubtitle>
									</Col>
								</Row>
							))}
						</SectionData>
					</InsuranceSection>
				</Col>
			</Row>
		</InsuranceOfferCardWraper>
	);
};

export default InsuranceSelectedOfferDetails;
