import styled from 'styled-components';
import { Col, Row } from 'react-grid-system';
import { Caption } from '@Components/Typography/Typography';
export const CartItemsContainer = styled(Col)``;

export const HeaderRow = styled(Row)`
	${({ theme }) => ({
		borderBottom: '1px solid',
		borderColor: theme.colors.black12,
	})}
`;

export const HeaderText = styled(Caption)<{ alignRight?: boolean }>`
	${({ theme }) => ({
		color: theme.colors.black54,
		marginBottom: '7px',
	})};
	${({ alignRight }) => alignRight && `text-align: right`}
`;
