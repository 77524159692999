import api from '@Utils/api';
import { Observable } from 'rxjs';
import { IInventory, IInventoryByProductId } from '@ApiModels/inventoryByProductId';
import { IInventoryBySKUId } from '@ApiModels/inventoryBySKUId';
import { map, share } from 'rxjs/operators';
import { IItem } from '@ApiModels/productsByCategoryId';
import { IInventoryByPartNumber } from '@ApiModels/inventoryByPartNumber';
import { IAvailableColorWithId } from '@Components/controls/ColorPicker/subcomponents/Slider';
import { IAvailableValueItem } from '@Components/MultiSegmentPicker/MultiSegmentPicker';
import { roundMoney } from '@Utils/converters/roundMoney';
import { DEF_ITEM_TYPE, getAdditionalFilterAttributes } from '@ApiModels/utils/attributeGetters';
import { IPriceByCatEntry } from '@ApiModels/priceByCatEntry';
import { IProductByIdItem } from '@ApiModels/productById';
import { IInstallmentAmountResponse } from '@ApiModels/installmentAmount';
import { IBuyNowPayLaterInstallmentResponse } from '@ApiModels/buyNowPayLaterInstallment';
export interface IProductComponents {
	availableColors: IAvailableColorWithId[];
	availableCapacity: IAvailableValueItem[];
	inventory: IInventoryByProductId['inventory'];
	product: IItem;
}

interface IAvailableProductComponents {
	product: IItem;
	items?: IProductByIdItem[];
}

export interface IInstallmentAmountPayload {
	mobilePhone: string;
	creditRating?: string;
	accountNo: string;
	emfConfigId: string;
	productId: string;
	serviceCode: string;
	voucherAmount: string;
	installmentDuration: string;
	isDisabledBnplCr7985: boolean;
}

export interface IBuyNowPayLaterInstallmentPayload {
	productId: string;
	installmentDuration: string;
	bnplProductPrice?: string;
	mobilePhone?: string;
	creditRating?: string;
	isDisabledBnplCr7985?: boolean;
	accountNo?: string;
	emfConfigId?: string;
	serviceCode?: string;
}
export class InventoryService {
	public static getInventoryBySKUId(SKUId: string): Observable<IInventoryBySKUId> {
		return api.omantelShop.getInventoryBySKUId(SKUId).call();
	}

	public static getInventoryByPartNumber(partNumber: string): Observable<IInventoryByPartNumber> {
		return api.omantelShop.getInventoryByPartNumber(partNumber).call();
	}

	public static getInventoryByProductId(productId: string): Observable<IInventoryByProductId> {
		return api.omantelShop.getInventoryByProductId(productId).call();
	}

	public static getCatEntry({ catEntryId }: { catEntryId: string }): Observable<IPriceByCatEntry> {
		return api.omantelShop.getCatEntry({ catEntryId }).call();
	}

	public static getInstallmentAmount({
		mobilePhone,
		creditRating,
		accountNo,
		emfConfigId,
		productId,
		serviceCode,
		voucherAmount,
		installmentDuration,
		isDisabledBnplCr7985,
	}: IInstallmentAmountPayload): Observable<IInstallmentAmountResponse> {
		const payload: any = {
			mobilePhone,
			accountNo,
			emfConfigId,
			productId,
			serviceCode,
			voucherAmount,
			installmentDuration,
		};

		if (!isDisabledBnplCr7985) {
			payload.creditRating = creditRating;
		}
		return api.omantelShop.getInstallmentAmount(payload).call();
	}

	public static getBuyNowPayLaterInstallmentAmount({
		productId,
		installmentDuration,
		bnplProductPrice,
		mobilePhone,
		creditRating,
		isDisabledBnplCr7985,
		accountNo,
		emfConfigId,
		serviceCode,
	}: IBuyNowPayLaterInstallmentPayload): Observable<IBuyNowPayLaterInstallmentResponse> {
		const payload: any = {
			productId,
			installmentDuration,
		};

		if (!isDisabledBnplCr7985) {
			payload.bnplProductPrice = bnplProductPrice;
			payload.mobilePhone = mobilePhone;
			payload.creditRating = creditRating;
			payload.accountNo = accountNo;
			payload.emfConfigId = emfConfigId;
			payload.serviceCode = serviceCode;
		}

		return api.omantelShop.getBuyNowPayLaterInstallmentAmount(payload).call();
	}

	public static getAvailableProductComponents({
		product,
		items,
	}: IAvailableProductComponents): Observable<IProductComponents> {
		return this.getInventoryByProductId(product.uniqueID).pipe(
			map(({ inventory }) => {
				const flatColorsInventory: IInventory[] = [];
				const flatCapacityInventory: IInventory[] = [];

				const _inventory: any = {};
				product.inventory = inventory;

				if ((product.isItem || product.isGiftCard) && !product.colorAttributeId) {
					const attrId = product.attributes?.find((attr) => attr.identifier === DEF_ITEM_TYPE)?.id;
					if (attrId && inventory?.[attrId]?.[0]?.value) {
						if (Number(inventory[attrId][0].value) > 0) {
							product.availability = true;
						}
						product.price = Number(inventory[attrId][0].offerPrice ?? 0);
					}
					return {
						availableCapacity: [],
						availableColors: [],
						product,
						inventory,
					};
				}

				try {
					if (product.colorAttributeId && inventory[product.colorAttributeId]) {
						_inventory[product.colorAttributeId] = [...inventory[product.colorAttributeId]]
							?.slice()
							?.sort((a, b) => {
								if (Number(a.offerPrice) > Number(b.offerPrice)) return 1;
								if (Number(a.offerPrice) === Number(b.offerPrice)) return 0;
								return -1;
							})
							.map((item) => {
								const index = flatColorsInventory.findIndex((inv) => item.attrval === inv.attrval);
								if (Number(item.value) < 0) {
									item.value = '0';
								}
								if (index >= 0) {
									flatColorsInventory[index].value = String(
										Number(flatColorsInventory[index].value) + Number(item.value)
									);
								} else {
									flatColorsInventory.push({ ...item });
								}
								return item;
							});
					}
					if (product.capacityAttributeId && inventory[product.capacityAttributeId]) {
						_inventory[product.capacityAttributeId] = [...inventory[product.capacityAttributeId]]?.map(
							(item) => {
								const index = flatCapacityInventory.findIndex((inv) => item.attrval === inv.attrval);
								if (index >= 0) {
									flatCapacityInventory[index].value = String(
										Number(flatCapacityInventory[index].value) + Number(item.value)
									);
								} else {
									flatCapacityInventory.push({ ...item });
								}
								return item;
							}
						);
					}
				} catch (e) {
					console.log(e);
				}

				const availableCapacity = product[
					product.isGiftCard || product.isDigitalProduct ? 'availableValues' : 'availableCapacity'
				].map((item) => ({
					id: item.id,
					label: item.value,
					available: Number(flatCapacityInventory.find((inv) => inv.attrval === item.id)?.value) > 0,
					price: (() => {
						const {
							offerPrice = '0',
							listPrice = '0',
							taxablePrice = '0',
							vatPercent = '0',
							vatValue = '0',
						} = flatCapacityInventory.find((inv) => inv.attrval === item.id) || {};
						return {
							offerPrice,
							listPrice,
							taxablePrice,
							vatPercent,
							vatValue,
						};
					})(),
				}));

				const labelType = product.attributes?.find((attribute) => attribute.identifier === 'DEVICE_LABEL_TYPE');
				const isAppleWatch = labelType?.values[0].identifier === 'APPLE_WATCH';

				const availableColors = product[
					product.isGiftCard || product.isDigitalProduct ? 'availableCountries' : 'colors'
				].map((item) => {
					const availableSomeCountry = product.isGiftCard
						? flatColorsInventory.some((item) => Number(item.value || '') > 0)
						: false;

					const inventoryProduct = flatColorsInventory.find((inv) => inv.attrval === item.id);
					const {
						listPrice = '0',
						offerPrice = '0',
						taxablePrice = '0',
						vatValue = '0',
						vatPercent = '0',
						thumbnail = '',
						fullImage = '',
					} = inventoryProduct || {};
					product.price = Number(offerPrice);
					if (Number(inventoryProduct?.value) > 0 && !isAppleWatch) {
						product.availability = true;
					} else if (isAppleWatch) product.availability = true;
					return {
						...item,
						listPrice,
						offerPrice,
						taxablePrice,
						vatValue: roundMoney(vatValue),
						vatPercent,
						available: availableSomeCountry || Number(inventoryProduct?.value) > 0,
						thumbnail,
						fullImage,
						availableCapacity: product[
							product.isGiftCard || product.isDigitalProduct ? 'availableValues' : 'availableCapacity'
						].map((capacity) => {
							const color = inventory[product.colorAttributeId ?? '']?.find((inv) => {
								if (items && items?.length > 0 && isAppleWatch) {
									const findedItem = items.find((item) => item.partNumber === inv.sku);
									const findedColorName = findedItem?.attributes.find(
										(attribute) => attribute.identifier === 'DEF_Color'
									)?.values[0].identifier;
									return inv.attrval === item.id && findedColorName === capacity.parsedIdentifier;
								} else {
									let isMatchedCapacity =
										inv.attrval === item.id &&
										(inv.sku.includes(capacity.parsedIdentifier.replaceAll(' ', '')) ||
											inv.sku.includes('Huawei_Mate_XS_2'));
									if (isMatchedCapacity) {
										return true;
									} else {
										if (inventory[product.colorAttributeId ?? ''].length === 1) {
											isMatchedCapacity = inv.attrval === item.id;
										}
										return isMatchedCapacity;
									}
								}
							});
							if (Number(color?.value) > 0) {
								return capacity.id;
							}
						}),
					};
				});

				const additionalFilterAttributes = getAdditionalFilterAttributes({
					attributes: product.attributes ?? [],
					available: availableColors.some((col) => col.available),
				});
				try {
					if (additionalFilterAttributes && product.attributes) {
						product.attributes.push(additionalFilterAttributes);
					}
				} catch {}

				product.availableColors = availableColors;

				return { availableColors, availableCapacity, inventory: _inventory, product };
			}),
			share()
		);
	}
}
