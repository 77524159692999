import React, { FunctionComponent } from 'react';
import { useSelector } from 'react-redux';
import Skeleton, { Skeleton as SkeletonType } from '@Components/Skeleton/Skeleton';
import CircularLoader from '@Components/CircularLoader/CircularLoader';

interface IBusyIndicatorProps {
	listener: string | string[] | boolean | undefined;
	color?: types.theme.themeColors;
	backgroundColor?: types.theme.themeColors;
	children?: JSX.Element;
	size?: number;
	skeleton?: SkeletonType;
	skeletonProps?: Record<string, any>;
	withoutLoader?: boolean;
}

const defaultColor = 'primary' as types.theme.themeColors;
const defaultBackgroundColor = 'black24' as types.theme.themeColors;
const defaultSize = 32;

const BusyIndicator: FunctionComponent<IBusyIndicatorProps> = ({
	listener,
	color = defaultColor,
	backgroundColor = defaultBackgroundColor,
	children,
	size = defaultSize,
	skeleton,
	skeletonProps,
	withoutLoader,
}) => {
	const busyIndicatorsList = useSelector((state: types.redux.IState) => state.busyIndicator.list);
	let isActive = false;
	if (Array.isArray(listener)) {
		isActive = listener.some((v) => busyIndicatorsList.includes(v));
	} else if (typeof listener === 'boolean') {
		isActive = listener;
	} else if (listener) {
		isActive = busyIndicatorsList.includes(listener);
	}

	let Loader = null;

	if (skeleton) {
		Loader = <Skeleton type={skeleton} {...skeletonProps} />;
	} else if (!withoutLoader) {
		Loader = <CircularLoader backgroundColor={backgroundColor} barColor={color} size={size} />;
	}
	return isActive ? Loader : children || <></>;
};

export default BusyIndicator;
