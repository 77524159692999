import ConditionalRender from '@Components/ConditionalRender/ConditionalRender';
import { PrimaryLargeButtonFlex, SecondaryLargeButtonFlex } from '@Components/controls/Button/Button';
import Divider from '@Components/Divider/Divider';
import Icon from '@Components/Icon/Icon';
import { setModal } from '@Redux/modules/modal/actions';
import useScreen from '@Utils/hooks/useScreen';
import React, { FunctionComponent, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import {
	ButtonsContainer,
	ContentContainer,
	ContentTopContainer,
	HeaderContainer,
	IconButton,
	IconContainer,
	StatusModalContainer,
} from './Status.styled';

export interface IStatusModalProps {
	status: 'success' | 'error';
	body?: JSX.Element;
	primaryButtonText?: string;
	onClickPrimary?: () => void;
	secondaryButtonText?: string;
	onClickSecondary?: () => void;
	icon?: types.iconNames;
	iconFill?: types.theme.themeColors;
}

const Status: FunctionComponent<IStatusModalProps> = ({
	status,
	body,
	primaryButtonText,
	onClickPrimary,
	secondaryButtonText,
	onClickSecondary,
	icon,
	iconFill,
}) => {
	const dispatch = useDispatch();
	const isSuccess = useMemo(() => status === 'success', [status]);

	const { screen } = useScreen();
	const isXS = screen(['xs']);

	return (
		<StatusModalContainer isXS={isXS}>
			<HeaderContainer isXS={isXS}>
				<IconContainer>
					<ConditionalRender show={!isXS} onTrue={() => <Icon name="omantelLogo" width={96} height={48} />} />
				</IconContainer>
				<IconButton
					onClick={() => {
						dispatch(setModal({ closeAllModals: true, type: null }));
					}}
				>
					<Icon name="close" width={24} height={24} fill="black54" />
				</IconButton>
			</HeaderContainer>
			<ContentContainer>
				<ContentTopContainer isXS={isXS}>
					<Icon
						name={icon ?? (isSuccess ? 'success' : 'error')}
						width={isXS ? 56 : 88}
						height={isXS ? 56 : 88}
						fill={iconFill ?? (isSuccess ? 'positive' : 'warning')}
					/>
					{body}
				</ContentTopContainer>
				<ButtonsContainer>
					{primaryButtonText && onClickPrimary && (
						<PrimaryLargeButtonFlex uniqueId="status-modal.primary" onClick={onClickPrimary}>
							{primaryButtonText}
						</PrimaryLargeButtonFlex>
					)}
					{secondaryButtonText && onClickSecondary && (
						<>
							<Divider withoutLine marginBottom={16} />
							<SecondaryLargeButtonFlex uniqueId="status-modal.secondary" onClick={onClickSecondary}>
								{secondaryButtonText}
							</SecondaryLargeButtonFlex>
						</>
					)}
				</ButtonsContainer>
			</ContentContainer>
		</StatusModalContainer>
	);
};

export default Status;
