import styled from 'styled-components';
import { ValueBold } from '@Components/Typography/Typography';
import { isRTL } from '@Utils/layout/layoutSupport';

export const ContentContainer = styled.div`
	padding-top: 16px;
	width: 100%;
`;

export const Title = styled(ValueBold)`
	${({ theme }) => ({
		color: theme.colors.black87,
	})}
	margin-bottom: 16px;
`;

export const RadioButtonsWrapper = styled.div`
	${() => ({
		marginLeft: isRTL() ? -16 : 0,
		marginRight: isRTL() ? -16 : 0,
	})}
	margin-bottom: 16px;
	margin-top: 16px;
	display: flex;
	justify-content: flex-start;
`;

export const ButtonTextWrapper = styled.div`
	margin-top: -4px;
	height: 100%;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
`;

export const ButtonWrapper = styled.div`
	height: 48px;
	display: flex;
	justify-content: flex-end;
`;
