import React, { FunctionComponent } from 'react';
import { DescriptionWrapper, TelesalesDetailsWrapper, TextBodyS } from './TelesalesDescription.styled';
import { Col, Row } from 'react-grid-system';
import { ParagraphMBold } from '@Components/Typography/Typography';
import useTranslate from '@Utils/hooks/useTranslate';
import Icon from '@Components/Icon/Icon';
import Divider from '@Components/Divider/Divider';
import ConditionalRender from '@Components/ConditionalRender/ConditionalRender';
import { ButtonContent } from '@Components/Card/cards/OrderHistory/OrderHistory.styled';
import { PrimaryLargeButtonFixed } from '@Components/controls/Button/Button';
import { useHistory } from 'react-router-dom';
import { getLang } from '@Utils/language/language';
import { useCart } from '@Utils/context/CartContext';
import useTelesales from '@Utils/hooks/useTeleasales';

const TelesalesDescription: FunctionComponent<types.cards.ITelesalesDescriptionProps> = ({
	params: { description },
}): JSX.Element => {
	const { translate } = useTranslate();
	const { isTelesales } = useTelesales();
	const { cartItemsLength } = useCart();
	const history = useHistory();
	const goBackToStore = (): void => {
		history.push(`/${getLang()}/store`);
	};
	return (
		<TelesalesDetailsWrapper>
			<Row align="center">
				<Col lg={1} md={1} sm={1} xs={3}>
					<Row justify="center">
						<Icon name="voice" width={48} height={48} />
					</Row>
				</Col>
				<Col>
					<ParagraphMBold>{translate('telesales-checkout.view.voice.title')}</ParagraphMBold>
					<Divider marginBottom={8} withoutLine />
					<DescriptionWrapper>
						<TextBodyS>{description}</TextBodyS>
					</DescriptionWrapper>
				</Col>
				<ConditionalRender
					show={cartItemsLength === 0 && isTelesales}
					onTrue={() => (
						<ButtonContent>
							<PrimaryLargeButtonFixed
								uniqueId="telesales-checkout.view.go.to.store"
								onClick={goBackToStore}
							>
								{translate('telesales-checkout.view.go.to.store.button')}
							</PrimaryLargeButtonFixed>
						</ButtonContent>
					)}
				/>
			</Row>
		</TelesalesDetailsWrapper>
	);
};

export default TelesalesDescription;
