import React, { useState, FunctionComponent, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Row, Col } from 'react-grid-system';
import { Caption } from '@Components/Typography/Typography';
import {
	CardWrapper,
	Title,
	PriceDescriptionWrapper,
	DescriptionTitle,
	PriceContainer,
	MessageContainer,
	Message,
	SummaryWrapper,
	PriceSummaryRow,
	SummaryContainer,
	AdditionalInfoTitle,
} from './PaymentSummary.styled';
import SegmentSelector from '@Components/SegmentSelector/SegmentSelector';
import useTranslate from '@Utils/hooks/useTranslate';
import PriceTag from '@Components/PriceTag/PriceTag';
import Divider from '@Components/Divider/Divider';
import ConditionalRender from '@Components/ConditionalRender/ConditionalRender';
import PriceWithVat from '@Components/PriceWithVat/PriceWithVat';
import VatMessageBar from '@Components/PriceWithVat/VatMessageBar';
import { ISegmentSize } from '@Components/controls/Segment/Segment';
import { IProductByIdItem } from '@ApiModels/productById';
import {
	CHECK_IF_CAN_GET_NEW_PLAN,
	GET_ACCOUNT_FOR_LOGGED_USER,
	GET_CUSTOMER_PRODUCTS,
	GET_HIGHER_PLAN,
	GET_PLANS,
	GET_RECOMMENDED_PLAN,
	INFINITE_LOADING,
	NOT_CHOSEN_NUMBER,
	PlanType,
	VALIDATE_CHANGED_INSTALLMENT_SKUS,
} from '../Plan/Plan';
import { matchScreen } from '@Utils/layout/layoutSupport';
import MessageBar from '@Components/MessageBar/MessageBar';
import { addBusyIndicator, removeBusyIndicator } from '@Redux/modules/busyIndicator/actions';
import { InventoryService } from '@Services/inventory/inventoryService';
import BusyIndicator from '@Components/BusyIndicator/BusyIndicator';
import {
	GET_COMPONENTS_FROM_INVENTORY_BI,
	GET_PRICE_BY_CAT_ENTRY_BI,
	GET_PRODUCT_DETAILS_BI,
} from '@Views/ProductPage/ProductPage';
import { setSelectedItems } from '@Redux/modules/selectedItems/actions';
import moment from 'moment';
import { JAWWY_PRODUCT_PARTNUMBER_ID_PART } from '@Config/app.config';

export enum PaymentMethod {
	UPFRONT = 'UPFRONT',
	MONTHLY = 'MONTHLY',
}

export const GET_PRICE_BY_CAT_ENTRY_PAYMENT_SUMMARY_BI = 'GET_PRICE_BY_CAT_ENTRY_PAYMENT_SUMMARY_BI';

const PaymentSummary: FunctionComponent<types.cards.IPaymentSummaryCardProps> = ({
	params: {
		isDevice,
		regularPrice,
		showAvailablePaymentMethods,
		discountWithMakasib,
		link = '/',
		isLogged,
		pricesWithVat,
		availableSKU,
		productAllSKU,
		planType,
		setInstallmentPeriod,
		installmentPeriod,
		singleSku = false,
		onPlanSelector = false,
		selectedProductName,
	},
}): JSX.Element => {
	const [paymentMethod, setPaymentMethod] = useState<PaymentMethod>(PaymentMethod.UPFRONT);
	const [period12, setPeriod12] = useState<boolean>(false);
	const [period24, setPeriod24] = useState<boolean>(false);
	const [upfrontValue, setUpfrontValue] = useState<string>('0');
	const [installmentValue, setInstallmentValue] = useState<string>('0');
	const selectedSKU = useSelector((state: types.redux.IState) => state.selectedItems?.details);
	const currentPlan = useSelector((state: types.redux.IState) => state.selectedItems?.currentPlan);
	const { translate } = useTranslate();
	const dispatch = useDispatch();
	useEffect(() => {
		const skus: IProductByIdItem[] = availableSKU ?? [];
		const selected = skus?.find((sku: IProductByIdItem) => {
			return (
				(sku.planDetails.planId === selectedSKU?.planDetails.planId &&
					sku.installmentPeriod === installmentPeriod) ||
				singleSku
			);
		});

		if (selected?.id) {
			dispatch(addBusyIndicator(GET_PRICE_BY_CAT_ENTRY_PAYMENT_SUMMARY_BI));
			InventoryService.getCatEntry({ catEntryId: selected.id }).subscribe(
				(response) => {
					const { listPrice, comParamInstallmentValue } = response.price;
					dispatch(removeBusyIndicator(GET_PRICE_BY_CAT_ENTRY_PAYMENT_SUMMARY_BI));
					setInstallmentValue(comParamInstallmentValue || '-');
					setUpfrontValue(listPrice || '-');
				},
				() => {
					const currentSKU = availableSKU?.find((sku: IProductByIdItem) => {
						return (
							(sku.planDetails.planId === selectedSKU?.planDetails.planId &&
								sku.installmentPeriod === installmentPeriod) ||
							singleSku
						);
					});
					setInstallmentValue(currentSKU?.installmentValue || '-');
					setUpfrontValue(String(currentSKU?.priceDisplay || '-'));
					dispatch(removeBusyIndicator(GET_PRICE_BY_CAT_ENTRY_PAYMENT_SUMMARY_BI));
				}
			);
		}
	}, [availableSKU, installmentPeriod, selectedSKU, pricesWithVat]);

	const pricesWithVatUpfrontInstallment = useMemo(() => {
		const skus: IProductByIdItem[] = availableSKU ?? [];
		const offerPrice = String(
			skus.find((sku: IProductByIdItem) => {
				return (
					(sku.planDetails.planId === selectedSKU?.planDetails.planId &&
						sku.installmentPeriod === installmentPeriod) ||
					singleSku
				);
			})?.priceOffer
		);
		return { ...pricesWithVat, offerPrice: offerPrice.length === 0 ? '-' : offerPrice };
	}, [availableSKU, installmentPeriod, selectedSKU, pricesWithVat]);

	const setPaymentPeriod = (_period: '12' | '24' | undefined) => {
		setInstallmentPeriod && setInstallmentPeriod(_period);
	};

	useEffect(() => {
		setSelectedPaymentMethod(
			planType === PlanType.DEVICE_ONLY ? ('UPFRONT' as PaymentMethod) : ('MONTHLY' as PaymentMethod)
		);
	}, [planType]);

	const setSelectedPaymentMethod = (method: PaymentMethod) => {
		dispatch(setSelectedItems({ productPaymentType: method }));
		setPaymentMethod(method);
	};

	useEffect(() => {
		if (productAllSKU && productAllSKU.length > 0) {
			const period12: IProductByIdItem[] = [];
			const period24: IProductByIdItem[] = [];
			const skus: IProductByIdItem[] = productAllSKU;
			skus.forEach((sku: IProductByIdItem) => {
				if (sku.planDetails.planId === selectedSKU?.planDetails.planId) {
					if (sku.installmentValue !== undefined) {
						if (sku.installmentPeriod === '12') {
							period12.push(sku);
						}
						if (sku.installmentPeriod === '24') {
							period24.push(sku);
						}
					}
				}
			});

			setPeriod12(period12.length > 0);
			setPeriod24(period24.length > 0);
		} else {
			setPeriod12(false);
			setPeriod24(false);
		}
	}, [productAllSKU, currentPlan, selectedSKU]);

	return (
		<BusyIndicator
			listener={[
				GET_PRODUCT_DETAILS_BI,
				GET_ACCOUNT_FOR_LOGGED_USER,
				GET_RECOMMENDED_PLAN,
				INFINITE_LOADING,
				NOT_CHOSEN_NUMBER,
				GET_PLANS,
				GET_COMPONENTS_FROM_INVENTORY_BI,
				GET_HIGHER_PLAN,
				CHECK_IF_CAN_GET_NEW_PLAN,
				VALIDATE_CHANGED_INSTALLMENT_SKUS,
				GET_CUSTOMER_PRODUCTS,
				GET_PRICE_BY_CAT_ENTRY_BI,
				GET_PRICE_BY_CAT_ENTRY_PAYMENT_SUMMARY_BI,
			]}
			skeleton={planType === 'DEVICE_ONLY' ? 'paymentSummary' : 'paymentSummaryWithPlan'}
		>
			<CardWrapper>
				<Row>
					<Title textTransform="uppercase" color="black87">
						{translate(isDevice ? 'payment.summary.title.device.payment' : 'payment.summary.title.payment')}
					</Title>
				</Row>

				<Row>
					<SegmentSelector<PaymentMethod>
						show={showAvailablePaymentMethods}
						setMethod={setSelectedPaymentMethod}
						leftDisabled={false}
						rightDisabled={!currentPlan?.commerceAttributes?.duration}
						leftSegmentName={translate('button.selector.upfront.button')}
						rightSegmentName={translate('button.selector.monthly.button')}
						leftSegmentValue={PaymentMethod.UPFRONT}
						rightSegmentValue={PaymentMethod.MONTHLY}
						selectedValue={paymentMethod}
					/>
					{showAvailablePaymentMethods && <Divider marginBottom={24} marginTop={16} />}
				</Row>
				<ConditionalRender
					show={paymentMethod === PaymentMethod.UPFRONT && !onPlanSelector}
					onTrue={() => (
						<>
							<Row justify="between">
								<PriceDescriptionWrapper>
									<DescriptionTitle color="black87" fontWeight="bold">
										{translate(
											isDevice
												? 'payment.summary.description.device.title'
												: 'payment.summary.description.title'
										)}
									</DescriptionTitle>
								</PriceDescriptionWrapper>
								<PriceTag price={pricesWithVat?.listPrice ?? selectedSKU?.priceOffer ?? regularPrice} />
							</Row>
							<Row justify="between">
								<Caption color="black87">{translate('payment.summary.description.subtitle')}</Caption>
								<PriceWithVat pricesWithVat={pricesWithVat} />
							</Row>
						</>
					)}
				/>
				<ConditionalRender
					show={paymentMethod === PaymentMethod.MONTHLY || onPlanSelector}
					onTrue={() => (
						<>
							<Row>
								<Col lg={12} sm={onPlanSelector ? 6 : 12}>
									<SummaryContainer
										addMarginRight={matchScreen(['md', 'sm']) && onPlanSelector ? 16 : 0}
									>
										<Row justify="between">
											<ConditionalRender
												show={!!onPlanSelector}
												onTrue={() => (
													<ConditionalRender
														show={matchScreen(['xl', 'lg', 'xs'])}
														onTrue={() => (
															<MessageContainer>
																<Message>{`${selectedProductName}:`}</Message>
																<Message>
																	{translate('payment.summary.prices.may.change')}
																</Message>
															</MessageContainer>
														)}
													/>
												)}
												onFalse={() => (
													<VatMessageBar vat={+pricesWithVat?.vatPercent}></VatMessageBar>
												)}
											/>
										</Row>
										<Row>
											<SegmentSelector<'12' | '24' | undefined>
												show={true}
												setMethod={setPaymentPeriod}
												leftDisabled={!period12}
												rightDisabled={!period24}
												leftSegmentName={translate('payment.summary.installments.period', '12')}
												rightSegmentName={translate(
													'payment.summary.installments.period',
													'24'
												)}
												leftSegmentValue="12"
												rightSegmentValue="24"
												selectedValue={installmentPeriod}
												size={ISegmentSize.SMALL}
											/>
											{!onPlanSelector && <Divider marginBottom={24} withoutLine />}
										</Row>
										<Row>
											<ConditionalRender
												show={matchScreen(['md', 'sm']) && onPlanSelector}
												onTrue={() => (
													<>
														<Divider
															withoutLine
															marginBottom={matchScreen('sm') ? 6 : 22}
														/>
														<MessageContainer>
															<Message>{`${selectedProductName}:`}</Message>
															<Message>
																{translate('payment.summary.prices.may.change')}
															</Message>
														</MessageContainer>
													</>
												)}
											/>
										</Row>
									</SummaryContainer>
								</Col>
								<Col lg={12} sm={onPlanSelector ? 6 : 12} xs={12}>
									<SummaryContainer
										addMarginLeft={matchScreen(['md', 'sm']) && onPlanSelector ? 16 : 0}
									>
										<Row>
											<SummaryWrapper withBorder={!!onPlanSelector}>
												<PriceSummaryRow>
													<PriceDescriptionWrapper>
														<DescriptionTitle color="black87" fontWeight="bold">
															{translate('payment.summary.description.subtitle')}
														</DescriptionTitle>
														{!onPlanSelector && (
															<Caption color="black87">
																{translate('payment.summary.one.time.payment')}
															</Caption>
														)}
													</PriceDescriptionWrapper>
													<PriceContainer>
														<PriceTag size="small" price={upfrontValue ?? '-'} />
														<PriceWithVat pricesWithVat={pricesWithVatUpfrontInstallment} />
													</PriceContainer>
												</PriceSummaryRow>
												<Divider
													marginBottom={matchScreen(['sm', 'md']) && onPlanSelector ? 8 : 24}
													withoutLine
												/>
												<PriceSummaryRow>
													<PriceDescriptionWrapper>
														<DescriptionTitle color="black87" fontWeight="bold">
															{translate(
																selectedSKU?.productPartNumber.includes(
																	JAWWY_PRODUCT_PARTNUMBER_ID_PART
																)
																	? 'cart.payment.summary.item.subscription'
																	: 'payment.summary.description.installments'
															)}
														</DescriptionTitle>
														{!onPlanSelector && (
															<Caption color="black87">
																{translate(
																	'plan.contract.duration',
																	installmentPeriod ?? ''
																)}
															</Caption>
														)}
													</PriceDescriptionWrapper>
													<PriceTag
														size="small"
														price={installmentValue ?? selectedSKU?.installmentValue ?? '0'}
													/>
												</PriceSummaryRow>
											</SummaryWrapper>
										</Row>
									</SummaryContainer>
								</Col>
							</Row>
							<ConditionalRender
								show={!onPlanSelector}
								onTrue={() => (
									<Row>
										<Divider marginBottom={24} marginTop={24} />
										<AdditionalInfoTitle textTransform="uppercase">
											{translate('payment.summary.title.device.commitment')}
										</AdditionalInfoTitle>
										<MessageBar
											font="caption"
											width="100%"
											message={translate('payment.summary.will.expire')}
											importantMessage={moment()
												.add(installmentPeriod || '12', 'months')
												.format('MMM YYYY')}
											contentFill="support4"
											backgroundColor="grey100"
										/>
									</Row>
								)}
							/>
						</>
					)}
				/>

				{/* TODO Uncomment on P2. (MINT-5726 Makasib discount information should be hidden on P1)
			<Row justify={'center'} align={'center'}>
				<IconWrapper>
					<Icon name={'makasib'} fill={'black54'} width={ICON} height={ICON} />
				</IconWrapper>
				<Subtitle color={'black54'}>{translate('payment.summary.payment.caption.1')}</Subtitle>
				<Subtitle color={'primary'}>{translate('payment.summary.payment.caption.2', discountWithMakasib)}</Subtitle>
				<Subtitle color={'black54'}>{translate('payment.summary.payment.caption.3')}</Subtitle>
				<LinkWrapper to={link}>
					<Caption color={'primary'}>{translate('payment.summary.payment.caption.4')}</Caption>
				</LinkWrapper>
			</Row> */}
			</CardWrapper>
		</BusyIndicator>
	);
};

export default PaymentSummary;
