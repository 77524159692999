import { SecondaryLargeButtonAuto } from '@Components/controls/Button/Button';
import { TitleBold } from '@Components/Typography/Typography.styled';
import { marginRight, matchScreen } from '@Utils/layout/layoutSupport';
import styled from 'styled-components';

export const AddedToCartCardContainer = styled.div`
	width: 100%;
	display: flex;
	flex: 1;
	flex-direction: column;
	justify-content: space-between;
	padding: 20px;
	border-radius: 8px;
	${({ theme }) => ({
		backgroundColor: theme.colors.white,
		borderRadius: matchScreen('xs') ? 0 : 8,
	})}
`;

export const TitleWrapper = styled(TitleBold)`
	${({ theme }) => ({ color: theme.colors.black87 })}
`;

export const TitleContainer = styled.div`
	${() => ({
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
	})}
`;

export const SecondaryButtonWrapper = styled(SecondaryLargeButtonAuto)`
	${() => marginRight(20)}
`;

export const ButtonsContainer = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
`;
