import Card from '@Components/Card/Card';
import { TileSize } from '@Components/Card/cards/ProductCard/ProductCard.styled';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { Col, Row } from 'react-grid-system';
import { EspotService } from '@Services/espot/espot';
import { ItemType, ProductsService } from '@Services/productsService/productsService';
import { IItem } from '@ApiModels/productsByCategoryId';
import { addBusyIndicator, removeBusyIndicator } from '@Redux/modules/busyIndicator/actions';
import { useDispatch } from 'react-redux';
import ConditionalRender from '@Components/ConditionalRender/ConditionalRender';

const GET_POPULAR_PRODUCTS_BI = 'GET_POPULAR_PRODUCTS_BI';

const PopularProducts: FunctionComponent = () => {
	const [popularProducts, setPopularProducts] = useState<IItem[]>([]);
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(addBusyIndicator(GET_POPULAR_PRODUCTS_BI));
		EspotService.getPopularProducts().subscribe(
			(r) => {
				r.products.slice(0, 4).map((product) => {
					ProductsService.getProductById({
						productId: product.uniqueID,
						itemType: ItemType.UNKNOWN,
					}).subscribe((r) => {
						setPopularProducts((state) => [...state, r.item]);
					});
				});
			},
			() => {},
			() => {
				dispatch(removeBusyIndicator(GET_POPULAR_PRODUCTS_BI));
			}
		);
	}, []);

	return (
		<Row>
			<ConditionalRender
				show={popularProducts.length >= 4}
				onTrue={() => (
					<>
						{popularProducts
							.filter((item, index, sortedProducts) => {
								const iphone14ProFirstOccurrenceIndex = sortedProducts.findIndex(
									(product) => product.name === 'iPhone 14 Pro'
								);
								if (index === iphone14ProFirstOccurrenceIndex) return true;
								else
									return (
										item.name !== 'iPhone 14 Pro' &&
										item.inventory &&
										Object.keys(item.inventory).length !== 0
									);
							})
							.map((product, index) => (
								<Col xs={12} sm={6} lg={3} key={index}>
									<Card<types.cards.IProductCardProps>
										card="product"
										params={{
											product,
											tileSize: TileSize.BIG,
											iconsHidden: true,
										}}
										marginTop={0}
										marginBottom={10}
									/>
								</Col>
							))}
					</>
				)}
			/>
		</Row>
	);
};

export default PopularProducts;
