import { CaptionBigger } from '@Components/Typography/Typography.styled';
import styled from 'styled-components';
interface IColorProps {
	mainColor: types.theme.themeColors;
}
interface ICheckedProps {
	active: boolean;
}

export const StepContainer = styled.div<ICheckedProps>`
	display: flex;
	padding: 10px;
	border-radius: 8px;
	align-items: center;
	justify-content: center;
	${({ active }) => ({
		backgroundColor: active ? '#f4f8ec' : 'inherit',
	})};
`;

export const StepDescription = styled(CaptionBigger)<IColorProps>`
	${({ theme }) => ({
		color: theme.colors.black54,
		marginTop: 4,
	})}
	align-self: center;
	text-align: center;
`;

export const StepDescriptionActive = styled(CaptionBigger)<IColorProps>`
	${({ theme, mainColor }) => ({
		color: theme.colors[mainColor],
		marginTop: 4,
	})};
	align-self: center;
	text-align: center;
`;

export const CheckIconContainer = styled.div`
	position: absolute;
	${({ theme }) => ({
		backgroundColor: theme.colors.green,
		borderRadius: 8,
		top: 10,
		right: 28,
		padding: 1,
		width: 12,
	})};
`;

export const IconContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
`;
