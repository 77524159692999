import styled, { CSSProperties } from 'styled-components';
import {
	DisplayMBold,
	Subheading,
	ParagraphM,
	BodyMExtraBold,
	BodyS,
	BodySBold,
	CaptionBigger,
} from '@Components/Typography/Typography';
import { marginRight, marginLeft } from '@Utils/layout/layoutSupport';
interface ICardContainerProps {
	elevated?: boolean;
	bordered?: boolean;
	marginBottom?: number;
	marginTop?: number;
	fullHeight?: boolean;
}
interface ICardContentProps {
	fullHeight?: boolean;
	withoutContainerPadding: boolean;
}

interface IContentSectionContainerProps {
	isLargeScreen?: boolean;
	isSmallScreen?: boolean;
}

interface IFooterWraperProps {
	isSmallScreen?: boolean;
	isXs?: boolean;
}

export const BackgroundImage = styled.img`
	position: absolute;
	width: 100%;
`;

export const CardContainer = styled.div<ICardContainerProps>`
	${({ theme, marginBottom = 10, marginTop = 0, fullHeight = false }) => {
		return {
			background: theme.colors.white,
			borderRadius: '8px',
			flex: fullHeight ? 1 : undefined,
			left: 0,
			marginBottom: marginBottom || 0,
			marginTop: marginTop || 0,
			padding: 0,
			width: '100%',
			zIndex: 5,
		};
	}}
`;

export const CardContent = styled.div<types.IStyledProps & ICardContentProps>`
	${({ withoutContainerPadding, fullHeight = false }) => {
		return {
			borderRadius: '8px',
			flex: fullHeight ? 1 : undefined,
			padding: withoutContainerPadding ? 0 : `16px`,
			overflow: 'hidden',
		};
	}}
`;

export const FooterWraper = styled.div<IFooterWraperProps>`
	display: flex;
	margin-bottom: 8px;
	justify-content: end;
	${({ isSmallScreen = false }) => {
		return {
			flexDirection: isSmallScreen ? 'column' : 'row',
			alignItems: isSmallScreen ? 'center' : 'right',
		};
	}}
`;

export const FooterSteperInfo = styled(BodyS)`
	padding-bottom: 5px;
	${({ theme }) => {
		return {
			color: theme.colors.black54,
		};
	}}
`;

export const FooterSteperNextStep = styled(BodySBold)`
	${({ theme }) => {
		return {
			color: theme.colors.black54,
		};
	}}
`;

export const BackButtonWraper = styled.div<IFooterWraperProps>`
	${({ isXs = false }) => {
		const margin = marginRight(isXs ? '0' : 'auto');
		return {
			marginTop: isXs ? '10px' : '0',
			marginBottom: isXs ? '10px' : '0',
			...margin,
		};
	}}
`;

export const ImageWrapper = styled.div`
	background-image: url(car_insurance.webp);
	box-shadow: inset 555px 0px 45px 0px rgba(0, 0, 0, 0.5);
	background-size: cover;
	margin: 8px 0px;
	height: 488px;
`;

export const ContainerWrapper = styled.div`
	padding: 24px;
`;

export const TitleWrapper = styled(DisplayMBold)`
	color: white;
	margin-bottom: 30px;
	width: 300px;
`;

export const MainTitleWrapper = styled(DisplayMBold)`
	display: flex;
	align-items: center;
	align-content: center;
	gap: 30px;
	margin-bottom: 30px;
`;

export const TitleMwrapper = styled(BodyMExtraBold)`
	color: white;
	margin-bottom: 30px;
	width: 300px;
`;

export const SubtitleWrapper = styled(DisplayMBold)`
	${({ theme }) => {
		return {
			color: theme.colors.black87,
		};
	}}
`;

export const SubheadingWrapper = styled(Subheading)<IFooterWraperProps>`
	${({ isXs, theme }) => {
		return {
			color: theme.colors.black54,
			margin: isXs ? '190px 0' : '40px 0',
		};
	}}
`;

export const ParagraphMwrapper = styled(ParagraphM)`
	${({ theme }) => {
		return {
			color: theme.colors.black54,
			marginTop: '10px',
		};
	}}
`;

export const CardWrapper = styled.div<{ styles: CSSProperties }>`
	margin-left: auto;
	${({ styles }) => ({
		...styles,
	})}
`;

export const ButtonWrapper = styled.div`
	margin-left: auto;
	color: green;
	margin-bottom: 80px;
	margin-top: 20px;
`;

export const NextStepInfoWrapper = styled.div<IFooterWraperProps>`
	display: flex;
	flex-direction: column;
	${({ isXs = false }) => {
		const margin = marginRight(isXs ? 0 : '20px');
		return {
			alignItems: isXs ? 'center' : 'flex-end',
			marginBottom: isXs ? '15px' : 0,
			...margin,
		};
	}}
`;

export const IconWrapper = styled.div`
	${() => marginRight('16px')};
`;

export const BodyMWrapper = styled(BodyS)`
	${({ theme }) => {
		return {
			color: theme.colors.black54,
		};
	}}
`;

export const OfferMessageWrapper = styled.div`
	display: flex;
	align-items: center;
	padding: 16px;
	border-radius: 4px;
	margin-bottom: 16px;
	${({ theme }) => {
		return {
			backgroundColor: theme.colors.white,
			border: `1px solid ${theme.colors.primary}`,
		};
	}}
`;

export const SectionHeadContainer = styled(CaptionBigger)`
	display: flex;
	gap: 20px;
`;

export const SectionContentContainer = styled.div<IContentSectionContainerProps>`
	${({ isLargeScreen = false, isSmallScreen = false }) => (
		marginRight(isLargeScreen ? '160px' : isSmallScreen ? '0' : '52px'),
		marginLeft(isLargeScreen ? '52px' : isSmallScreen ? '0' : '52px')
	)}
`;
