import React, { FunctionComponent } from 'react';
import { IReview } from '@ApiModels/productById';
import { StarRatingWrapper, IconWrapper, RatingLabelWrapper, LabelWrapper } from './Reviews.styled';
import Icon from '@Components/Icon/Icon';
import { BodyS, DisplayMBoldH1, BodyM } from '@Components/Typography/Typography.styled';
import ConditionalRender from '@Components/ConditionalRender/ConditionalRender';
import Divider from '@Components/Divider/Divider';

interface IReviews {
	items?: IReview[];
}

const ICON_SIZE = 16;

const reviews = 4;
const rating = 4.4;

export const StarRating: FunctionComponent<{ rating: number; reviews?: number }> = (rating) => (
	<StarRatingWrapper>
		<IconWrapper>
			<Icon height={ICON_SIZE} width={ICON_SIZE} name={rating.rating < 0.5 ? 'starOutline' : 'starFilled'} />
		</IconWrapper>
		<IconWrapper>
			<Icon height={ICON_SIZE} width={ICON_SIZE} name={rating.rating < 1.5 ? 'starOutline' : 'starFilled'} />
		</IconWrapper>
		<IconWrapper>
			<Icon height={ICON_SIZE} width={ICON_SIZE} name={rating.rating < 2.5 ? 'starOutline' : 'starFilled'} />
		</IconWrapper>
		<IconWrapper>
			<Icon height={ICON_SIZE} width={ICON_SIZE} name={rating.rating < 3.5 ? 'starOutline' : 'starFilled'} />
		</IconWrapper>
		<IconWrapper>
			<Icon height={ICON_SIZE} width={ICON_SIZE} name={rating.rating < 4.5 ? 'starOutline' : 'starFilled'} />
		</IconWrapper>
		<ConditionalRender
			show={!!rating.reviews}
			onTrue={() => (
				<RatingLabelWrapper>
					<BodyS color="primary">{`(${rating.reviews} reviews)`}</BodyS>
				</RatingLabelWrapper>
			)}
		/>
	</StarRatingWrapper>
);

const Reviews: FunctionComponent<IReviews> = ({ items }) => {
	return (
		<div>
			<LabelWrapper>
				<DisplayMBoldH1>{rating}</DisplayMBoldH1>
			</LabelWrapper>
			<LabelWrapper>
				<BodyM>Overall rating</BodyM>
			</LabelWrapper>
			<LabelWrapper>
				<StarRating rating={rating} reviews={reviews} />
			</LabelWrapper>
			<LabelWrapper>
				<BodyM>{`Based on ${reviews} reviews`}</BodyM>
			</LabelWrapper>
			<Divider marginTop={16} marginBottom={16} />
			<Divider marginTop={16} marginBottom={16} />
		</div>
	);
};

export default Reviews;
