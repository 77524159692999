import styled from 'styled-components';
import { Value } from '@Components/Typography/Typography.styled';

export const ProductAvailabilityItemContainer = styled.div``;

export const ProductAvailabilityItemWrapper = styled.div`
	padding: 16px 0;
	display: flex;
	justify-content: space-between;
`;

export const ProductAvailabilityPlaceName = styled(Value)`
	${({ theme }) => ({
		color: theme.colors.black87,
	})}
`;

export const ProductAvailable = styled.div`
	${({ theme }) => ({
		color: theme.colors.positive,
	})}
	display: inline-block;
`;

export const ProductUnavailable = styled.div`
	display: inline-block;
	${({ theme }) => ({
		color: theme.colors.warning,
	})}
`;

export const IconWrapper = styled.div`
	display: inline-block;
	transform: translate(8px, 4px);
`;

export const ChevronWrapper = styled.div`
	display: inline-block;
	transform: translate(8px, 6px);
`;
