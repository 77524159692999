import { Action, ActionFunction0, ActionFunction1, createAction } from 'redux-actions';

export interface ISetPriceFilterAction {
	payload: { min?: number; max?: number; active?: boolean };
}

export const setPriceFilter: ActionFunction1<
	ISetPriceFilterAction['payload'],
	Action<ISetPriceFilterAction['payload']>
> = createAction('SET_PRICE_FILTER', (action: ISetPriceFilterAction['payload']) => action);

export const clearPriceFilter: ActionFunction0<Action<void>> = createAction('CLEAR_PRICE_FILTER', () => {});
