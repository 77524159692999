import { STORE_ID } from '@Config/app.config';
import { getLanguageCode } from '@Utils/language/language';

export const getPopularProducts = (): types.endpoints.IEndpointConfig => {
	return {
		url: `/wcs/resources/store/${STORE_ID}/espot/megaMenuPopularItems?langId=${getLanguageCode()}`,
	};
};

export const getSearchMenuList = (categoryName: string): types.endpoints.IEndpointConfig => {
	return {
		url: `/wcs/resources/store/${STORE_ID}/espot/${categoryName}?langId=${getLanguageCode()}`,
	};
};

export const getRecommendedForYou = (): types.endpoints.IEndpointConfig => {
	return {
		url: `/wcs/resources/store/${STORE_ID}/espot/RecommendedForYou?langId=${getLanguageCode()}`,
	};
};

export const getSimilarItems = (category: string): types.endpoints.IEndpointConfig => {
	return {
		url: `/wcs/resources/store/${STORE_ID}/espot/SimilarItems_${category}?langId=${getLanguageCode()}`,
	};
};

export const getMarketingBanners = (categoryName: string): types.endpoints.IEndpointConfig => {
	return {
		url: `/wcs/resources/store/${STORE_ID}/espot/${categoryName}?langId=${getLanguageCode()}`,
	};
};
