import React, { FunctionComponent, RefObject, useEffect, useState } from 'react';
import OtpSingleInput from '@Components/OtpInputs/OtpSingleInput/OtpSingleInput';
import { InputWrapper } from '@Components/OtpInputs/OtpInputs.styled';
import { isRTL } from '@Utils/layout/layoutSupport';
import useScreen from '@Utils/hooks/useScreen';

export interface IOtpInput {
	onChange: (otpCode: string) => void;
	inputRefs: RefObject<HTMLInputElement>[];
	otpArrayCode: string[];
	error?: boolean;
	disabled?: boolean;
}
const OtpInputs: FunctionComponent<IOtpInput> = ({ onChange, otpArrayCode, inputRefs, error, disabled }) => {
	const [activeInput, setActiveInput] = useState<number>(0);
	const { screen } = useScreen();
	const isXS = screen('xs');

	useEffect(() => {
		for (let pos = 0; pos < inputRefs.length; pos++) {
			otpArrayCode[pos] = otpArrayCode[pos];
		}
		handleOtpChange();
	}, [otpArrayCode.toString()]);

	useEffect(() => {
		inputRefs?.[activeInput]?.current?.focus();
	}, [activeInput]);

	const handleOtpChange = () => {
		onChange(otpArrayCode.toString());
	};

	const changeCodeAtFocus = (value: string) => {
		otpArrayCode[activeInput] = value;
		handleOtpChange();
	};

	const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		otpArrayCode[activeInput] = event.target.value;
		handleOtpChange();
	};

	const focusInput = (input: number) => {
		const newActiveInput = Math.max(Math.min(inputRefs.length - 1, input), 0);
		setActiveInput(newActiveInput);
	};

	const focusNextInput = () => {
		focusInput(activeInput + 1);
	};

	const focusPrevInput = () => {
		focusInput(activeInput - 1);
	};

	const handleOnInput = () => {
		focusNextInput();
	};

	const handleOnKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
		if (e.key === 'Backspace') {
			e.preventDefault();
			changeCodeAtFocus('');
			focusPrevInput();
		} else if (e.key === 'Delete') {
			e.preventDefault();
			changeCodeAtFocus('');
		} else if (e.key === 'ArrowLeft') {
			e.preventDefault();
			focusPrevInput();
		} else if (e.key === 'ArrowRight') {
			e.preventDefault();
			focusNextInput();
		} else if (e.key === ' ' || e.key === 'Spacebar' || e.key === 'Space') {
			e.preventDefault();
		}
	};
	const handleOnPaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
		e.preventDefault();
		let nextActiveInput = activeInput;
		const pastedData = e.clipboardData
			.getData('text/plain')
			.slice(0, inputRefs.length - activeInput)
			.split('');
		for (let pos = 0; pos < inputRefs.length; ++pos) {
			if (pos >= activeInput && pastedData.length > 0) {
				otpArrayCode[pos] = pastedData.shift() as string;
				nextActiveInput++;
			}
		}
		setActiveInput(nextActiveInput);
		focusInput(nextActiveInput);
		handleOtpChange();
	};
	const _renderInputs = (): JSX.Element[] => {
		return inputRefs.map((_, index) => {
			let inputIndex = index;
			if (isRTL()) {
				inputIndex = inputRefs.length - 1 - index;
			}
			return (
				<OtpSingleInput
					key={inputIndex}
					inputRef={inputRefs[inputIndex]}
					value={otpArrayCode[inputIndex]}
					onChange={handleOnChange}
					onInput={handleOnInput}
					onKeyDown={handleOnKeyDown}
					onFocus={(e: React.ChangeEvent<HTMLInputElement>) => {
						setActiveInput(inputIndex);
						e.target.select();
					}}
					onPaste={handleOnPaste}
					error={error}
					disabled={disabled}
				/>
			);
		});
	};
	return (
		<>
			<InputWrapper isXS={isXS}>{_renderInputs()}</InputWrapper>
		</>
	);
};
export default OtpInputs;
