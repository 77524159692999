import styled from 'styled-components';

interface IDashboardWrapperProps {
	footerHeight?: number;
}

export const CartWrapper = styled.div<IDashboardWrapperProps>`
	position: relative;
	${({ footerHeight, theme }) => ({
		backgroundColor: theme.colors.background,
		paddingBottom: footerHeight,
	})}
`;

export const CartContainer = styled.div`
	padding-top: 8px;
`;
