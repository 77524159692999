import styled from 'styled-components';
import { BodyM, DisplayMBold } from '@Components/Typography/Typography';
import { marginLeft, matchScreen } from '@Utils/layout/layoutSupport';

export const CardContainer = styled.div`
	padding: 24px;
	display: flex;
	flex-direction: column;
	max-width: 100%;
	width: ${() => (matchScreen('xs') ? 'auto' : '600px')};
`;

export const ImageWrapper = styled.div`
	display: flex;
	align-items: flex-start;
	padding-bottom: 24px;
`;

export const CardTitle = styled(DisplayMBold)`
	padding-bottom: 8px;
	${({ theme }) => ({
		color: theme.colors.black87,
	})}
`;

export const CardDescription = styled(BodyM)`
	display: inline-block;
	${({ theme }) => ({
		color: theme.colors.black54,
	})};
	padding-bottom: 24px;
`;

export const ActionWrapper = styled.div`
	display: flex;
	justify-content: flex-end;
	padding-top: 64px;
`;

export const ButtonWrapper = styled.div`
	${() => marginLeft(16)}
`;
