import {
	extractAttributeValue,
	extractPrice,
	extractAttributeUnitOfMeasure,
	extractAttributeValuesAll,
	parsers,
	getExtractedUnit,
	getExtractedValue,
	extractPlanDetails,
	extractPayGDetails,
	IPlanDetail,
} from '@Utils/ecommerce/services/extract';

export interface IInputCommerceAttributeValues {
	value: string;
	unitOfMeasure: string;
	currency: string;
	usage: string;
}
export interface IInputCommerceAttribute {
	name: string;
	values: IInputCommerceAttributeValues[];
}
export type BandwidthType = '4G' | '5G' | '4G5G';

type uniqueID = string;

interface IAttribute {
	usage: string;
	storeDisplay: boolean;
	sequence: string;
	facetable: boolean;
	values: IValue[];
	searchable: boolean;
	identifier: string;
	groupName: string;
	comparable: boolean;
	name: string;
	uniqueID: uniqueID;
	displayable: boolean;
}

interface IValue {
	sequence: string;
	value: string;
	unitOfMeasure: string;
	userData: any[];
	identifier: string;
	uniqueID: uniqueID;
}

interface IAllInPackage {
	attributes: IAttribute[];
}

export interface ISinglePackage {
	all?: IAllInPackage;
	_entry: types.ecommerce.ICatalogEntryView[];
	name: string;
	shortDescription: string;
	longDescription: string;
	buyable: boolean;
	uniqueID: string;
	commerceUniqueIdToActivate?: string;
	commerceUniqueIdToRenew?: string;
	parentCatalogGroupID?: string[];
	partNumber: string;
	partNumberRaw: string;
	selfDeactivate: boolean;
	selfActivate: boolean;
	isUpgradeable: boolean;
	reneval: string;
	roamingType: string;
	duration: number;
	durationLabel?: string;
	description: string;
	mbbPackageSize: string;
	autorenewal: boolean;
	color: types.color;
	dataReadable: string;
	dataValue: number;
	dataUnit: string;
	speedReadable: string;
	speedValue: number;
	speedUnit: string;
	nightDataReadable: string;
	nightDataValue: number;
	nightDataUnit: string;
	voiceReadable: string;
	voiceValue: number;
	voiceUnit: string;
	nightDataValidity: string;
	price: number;
	postpaidPlanPrice: number;
	priceCurrency: string;
	promoProductId: string;
	promoVoucherIds: string[];
	hayyakPlus: boolean;
	multiactivate: boolean;
	excludedBy: string[];
	requiredProducts: string[];
	place: string;
	planType: string;
	planRelated: string;
	fullImage: string;
	thumbnail: string;
	planDetails: IPlanDetail;
	payGDetails: any[];
	doubleDataIncluded: string;
	whatsappPackageSize: string;
	extendedBy: string;
	extendedByLabel: string;
	destination: string;
	iconMain: string;
	iconAdd: string;
	contractId: string;
	baqatPlus: boolean;
	attributes: IAttribute[];
	toolbarShortDescription: string;
	includedData: string;
	includedMinutes: string;
	alUfuq: boolean;
	unlCallNumbersCount: string;
	isHybrid: boolean;
	convergentEligible: boolean;
	prepaidUpsell: boolean;
	hierarchyIndex: number;
	groupID: string;
	productGroupToRemove: string;
	promoAppStoreCredit: string;
	promoAppStoreProductId: string;
	promoVoucherValue: string;
	promoVoucherValueNumber: string;
	findOutMoreLink?: string;
	isBaqatiStandardPackage?: boolean;
	wfbbPlanType?: 'TARIFF_WFBB' | 'TARIFF_WFBB_5G';
	wfbbCommitment?: string;
	planPrice?: string;
	simPrice?: string;
	offerPrice?: string;
	serviceCharge?: number;
	leftIcon: string;
	freeOfChargePeriods: number;
	packageBillingType: string;
	newLineProd: string;
	newEsimPrice: string;
	newEsimServiceCode: string;
	newEsimTariffCode: string;
	newLineDepositPrice: string;
	newLineDepositServiceCode: string;
	newLineDepositTariffCode: string;
	voucherTariffCode?: string;
	voucherServiceCode?: string;
	freePromoService?: string;
	extraData?: string;
	extraDataValidity?: string;
	vatPercent?: string;
	vatValue?: string;
	usage?: string;
	primaryOffer: boolean;
	isHBB: boolean;
	provisioningID: string;
	planCommitment: string;
	cantBeUpgraded: string;
	bandWidthType?: BandwidthType;
}

const model: types.models.IInputModel = {
	_entry: 'entry',
	_root_: (model: ISinglePackage) => {
		const _entryAsArray = Array.isArray(model._entry) ? model._entry : [model._entry];
		const packages = _entryAsArray.map((catalogEntryView: types.ecommerce.ICatalogEntryView) => {
			const dataReadable = extractAttributeValue(catalogEntryView, 'LOC_DATA_PACKAGE_SIZE');
			const nightDataReadable = extractAttributeValue(catalogEntryView, 'LOC_EXTRA_DATA_PACKAGE_SIZE');
			const voiceReadable = extractAttributeValue(catalogEntryView, 'LOC_MIN_PACKAGE_SIZE');
			const speedReadable = extractAttributeValue(catalogEntryView, 'PLAN_SPEED');
			const freeOfChargePeriods = extractAttributeValue(catalogEntryView, 'FREE_OF_CHARGE_PERIODS');

			return {
				alUfuq: extractAttributeValue(catalogEntryView, 'AL_UFUQ') === 'TRUE',
				all: catalogEntryView,
				autorenewal: extractAttributeValue(catalogEntryView, 'RENEWAL') === 'AUTO',
				backgroundUrl: extractAttributeValue(catalogEntryView, 'BACKGROUND_URL'),
				baqatPlus: extractAttributeValue(catalogEntryView, 'BAQAT_PLUS') === 'TRUE' || false,
				buyable: parsers.boolean(catalogEntryView.buyable),
				color: extractAttributeValue(catalogEntryView, 'HD_BG_COLOUR'),
				commerceUniqueIdToActivate: extractAttributeValue(catalogEntryView, 'ACT_THIS'),
				commerceUniqueIdToRenew: extractAttributeValue(catalogEntryView, 'ACT_THIS_ON_RENEW'),
				contractId: extractAttributeValue(catalogEntryView, 'CONTRACT_TO_ACTIVATE'),
				convergentEligible: parsers.boolean(extractAttributeValue(catalogEntryView, 'CONVERGENT_ELIGIBLE')),
				dataReadable,
				dataUnit: getExtractedUnit(dataReadable),
				dataValue: getExtractedValue(dataReadable),
				speedReadable,
				speedValue: getExtractedValue(speedReadable),
				speedUnit: getExtractedUnit(speedReadable),
				definitionSource: extractAttributeValue(catalogEntryView, 'DEFINITION_SOURCE'),
				description: extractAttributeValue(catalogEntryView, 'PRODUCT_DESCRIPTION'),
				destination: extractAttributeValue(catalogEntryView, 'DESTINATION'),
				mbbPackageSize: extractAttributeValue(catalogEntryView, 'MBB_PACKAGE_SIZE'),
				doubleDataIncluded:
					catalogEntryView.doubleDataIncluded ||
					extractAttributeValue(catalogEntryView, 'DOUBLE_DATA_INCLUDED'),
				doubleDataUnit: extractAttributeUnitOfMeasure(catalogEntryView, 'DOUBLE_DATA_INCLUDED'),
				duration:
					parsers.duration(
						extractAttributeValue(catalogEntryView, ['DURATION_CATEGORY', 'DURATION', 'PACKAGE_VALIDITY'])
					) || extractAttributeValue(catalogEntryView, ['CONTRACT_DURATION']),
				durationLabel: extractAttributeValue(catalogEntryView, 'DURATION_LABEL'),
				excludedBy: extractAttributeValuesAll(catalogEntryView, 'EXC_PROD').filter(
					(e: string) => !!e && e.replace(/[A-z]/g, '')
				),
				extendedBy: catalogEntryView.extendedBy || extractAttributeValue(catalogEntryView, 'IS_EXTENDED_BY'),
				extendedByLabel:
					catalogEntryView.extendedByLabel || extractAttributeValue(catalogEntryView, 'IS_EXTENDED_BY_LABEL'),
				findOutMoreLink:
					catalogEntryView.findOutMoreLink || extractAttributeValue(catalogEntryView, 'FIND_OUT_MORE_LINK'),
				fullImage: catalogEntryView.fullImage || extractAttributeValue(catalogEntryView, 'fullImage'),
				freeOfChargePeriods: freeOfChargePeriods ? parseFloat(freeOfChargePeriods) : 0,
				groupID: extractAttributeValue(catalogEntryView, 'GROUP_ID'),
				hayyakPlus: parsers.boolean(extractAttributeValue(catalogEntryView, 'HAYYAK_PLUS')),
				hayyakUpsell: catalogEntryView.hayyakUpsell || extractAttributeValue(catalogEntryView, 'HAYYAK_UPSELL'),
				hierarchyIndex: parseFloat(extractAttributeValue(catalogEntryView, 'HIERARCHY_INDEX') || 0),
				iconAdd: extractAttributeValue(catalogEntryView, 'ICON_ADD'),
				iconMain: extractAttributeValue(catalogEntryView, 'ICON_MAIN'),
				includedData: extractAttributeValue(catalogEntryView, 'INCLUDED_DATA'),
				includedMinutes: extractAttributeValue(catalogEntryView, 'INCLUDED_MINUTES'),
				isBaqatiStandardPackage: parsers.boolean(extractAttributeValue(catalogEntryView, 'BQT')),
				isHybrid: parsers.boolean(extractAttributeValue(catalogEntryView, 'HYBRID_OFFER')),
				longDescription:
					catalogEntryView.longDescription || extractAttributeValue(catalogEntryView, 'longDescription'),
				multiactivate: parsers.boolean(extractAttributeValue(catalogEntryView, 'MULTI_ACTIVATE', false)),
				name: catalogEntryView.name,
				nightDataReadable,
				nightDataUnit: getExtractedUnit(nightDataReadable),
				nightDataValidity: extractAttributeValue(catalogEntryView, 'LOC_EXTRA_PACKAGE_LABEL'),
				nightDataValue: getExtractedValue(nightDataReadable),
				parents: (catalogEntryView.parentCatalogGroupID ?? []).map((parent: string) => {
					if (parent.includes('_')) {
						return parent.split('_')[1];
					} else {
						return parent;
					}
				}),
				partNumber: catalogEntryView.partNumber,
				partNumberRaw: !!catalogEntryView.partNumber && catalogEntryView.partNumber.replace(/[A-z]/g, ''),
				payGDetails: extractPayGDetails(catalogEntryView),
				place: extractAttributeValue(catalogEntryView, 'DEFINITION_SOURCE'),
				planDetails: extractPlanDetails(catalogEntryView),
				planRelated: extractAttributeValue(catalogEntryView, 'PLAN_RELATED'),
				planType: extractAttributeValue(catalogEntryView, 'PLAN_TYPE'),
				price:
					extractAttributeValue(catalogEntryView, 'SUBSCRIPTION_RATE') ||
					extractPrice(catalogEntryView).price,
				offerPrice: extractPrice({ ...catalogEntryView, usage: 'Offer' }).price,
				priceCurrency: extractPrice(catalogEntryView as any).currency,
				productGroupToRemove: extractAttributeValue(catalogEntryView, 'PRODUCT_GROUP_TO_REMOVE'),
				promoAppStoreCredit: extractAttributeValue(catalogEntryView, 'PROMO_APPSTORE_CREDIT'),
				promoAppStoreProductId: extractAttributeValue(catalogEntryView, 'PROMO_APPSTORE_CREDIT_ID'),
				promoProductId: extractAttributeValue(catalogEntryView, 'PROMO_PRODUCT_ID'),
				promoVoucherIds: extractAttributeValuesAll(catalogEntryView, 'PROMO_VOUCHER_ID'),
				promoVoucherValue: extractAttributeValuesAll(catalogEntryView, 'PROMO_VOUCHER_VALUE'),
				promoVoucherValueNumber: extractAttributeValue(catalogEntryView, 'PROMO_VOUCHER_VALUE'),
				reneval: extractAttributeValue(catalogEntryView, 'RENEWAL'),
				requiredProducts: extractAttributeValuesAll(catalogEntryView, 'REQ_PROD').filter(
					(e: string) => !!e && e.replace(/[A-z]/g, '')
				),
				roamingType: extractAttributeValue(catalogEntryView, 'ROAMING_TYPE'),
				selfActivate: parsers.boolean(extractAttributeValue(catalogEntryView, 'SELF_ACTIVATE', true)),
				selfDeactivate: parsers.boolean(extractAttributeValue(catalogEntryView, 'SELF_DEACTIVATE', false)),
				isUpgradeable: parsers.boolean(extractAttributeValue(catalogEntryView, 'UPGRADEABLE')),
				shortDescription:
					catalogEntryView.shortDescription || extractAttributeValue(catalogEntryView, 'shortDescription'),
				thumbnail: catalogEntryView.thumbnail || extractAttributeValue(catalogEntryView, 'thumbnail'),
				toolbarShortDescription:
					catalogEntryView.toolbarShortDescription ||
					extractAttributeValue(catalogEntryView, 'TOOLBAR_SHORT_DESC'),
				uniqueID: catalogEntryView.uniqueID,
				unlCallNumbersCount: extractAttributeValue(catalogEntryView, 'UNL_NUM_COUNT'),
				vasTag: extractAttributeValue(catalogEntryView, 'TAG'),
				voiceReadable,
				voiceUnit: getExtractedUnit(voiceReadable),
				voiceValue: getExtractedValue(voiceReadable),
				whatsappPackageSize:
					catalogEntryView.whatsappPackageSize ||
					extractAttributeValue(catalogEntryView, 'WHATSAPP_PACKAGE_SIZE'),
				whatsappPackageUnit: extractAttributeUnitOfMeasure(catalogEntryView, 'WHATSAPP_PACKAGE_SIZE'),
				wfbbPlanType: extractAttributeValue(catalogEntryView, 'WFBB_PLAN_TYPE'),
				wfbbCommitment:
					extractAttributeValue(catalogEntryView, 'COMMITMENT_WFBB_5G') ||
					extractAttributeValue(catalogEntryView, 'COMMITMENT_WFBB'),
				planPrice: extractAttributeValue(catalogEntryView, 'PLAN_PRICE'),
				simPrice: extractAttributeValue(catalogEntryView, 'SIM_PRICE'),
				serviceCharge: extractAttributeValue(catalogEntryView, 'SERVICE_CHARGE'),
				packageBillingType: extractAttributeValue(catalogEntryView, 'BILLING_TYPE'),
				newLineProd: extractAttributeValuesAll(catalogEntryView, 'NEW_LINE_PROD'),
				newEsimPrice: extractAttributeValue(catalogEntryView, 'NEW_ESIM_PRICE'),
				newEsimServiceCode: extractAttributeValue(catalogEntryView, 'NEW_ESIM_SERVICE_CODE'),
				newEsimTariffCode: extractAttributeValue(catalogEntryView, 'NEW_ESIM_TARIFF_CODE'),
				newLineDepositPrice: extractAttributeValue(catalogEntryView, 'NEW_LINE_DEP_PRICE'),
				newLineDepositServiceCode: extractAttributeValue(catalogEntryView, 'NEW_LINE_DEP_SERVICE_CODE'),
				newLineDepositTariffCode: extractAttributeValue(catalogEntryView, 'NEW_LINE_DEP_TARIFF_CODE'),
				voucherTariffCode: extractAttributeValue(catalogEntryView, 'VOUCHER_TARIFF_CODE'),
				voucherServiceCode: extractAttributeValue(catalogEntryView, 'VOUCHER_SERVICE_CODE'),
				freePromoService: extractAttributeValue(catalogEntryView, 'FREE_PROMO_SERVICE'),
				extraData: extractAttributeValue(catalogEntryView, 'EXTRA_DATA'),
				extraDataValidity: extractAttributeValue(catalogEntryView, 'EXTRA_DATA_VALIDITY'),
				vatPercent: extractAttributeValue(catalogEntryView, 'VAT_PERCENT'),
				vatValue: extractAttributeValue(catalogEntryView, 'VAT_VALUE'),
				primaryOffer: extractAttributeValue(catalogEntryView, 'PRIMARY_OFFER') === 'TRUE',
				isHBB: extractAttributeValue(catalogEntryView, 'HBB') === 'TRUE',
				provisioningID: extractAttributeValue(catalogEntryView, 'PROVISIONING_ID'),
				planCommitment: extractAttributeValue(catalogEntryView, 'PLAN_COM_ID'),
				cantBeUpgraded: extractAttributeValue(catalogEntryView, 'ESHOP_UPGRADEABLE') === 'FALSE',
				bandWidthType: extractAttributeValue(catalogEntryView, 'BANDWIDTH_TYPE'),
			};
		});
		return packages;
	},
};

export default model;
