import React, { FunctionComponent, useMemo } from 'react';
import { ChipWrapper, ChipContent, Label, IconWrapper } from './TagChip.styled';
import Icon from '@Components/Icon/Icon';

interface ITagChipProps {
	label: string;
	onClick: () => void;
	selected: boolean;
	disabled?: boolean;
	iconName?: types.iconNames;
}

const TagChip: FunctionComponent<ITagChipProps> = ({ label, selected, disabled, onClick, iconName }: ITagChipProps) => {
	const handleOnClick = () => {
		if (!disabled) {
			onClick();
		}
	};

	const getColor: types.color = useMemo(() => {
		if (disabled) {
			return 'black38';
		} else if (selected) {
			return 'white';
		} else {
			return 'primary';
		}
	}, [selected, disabled]);

	return (
		<ChipWrapper onClick={handleOnClick} selected={selected} disabled={!!disabled}>
			<ChipContent>
				<Label selected={selected} disabled={!!disabled}>
					{label}
				</Label>
				{!!iconName && (
					<IconWrapper>
						<Icon name={iconName} fill={getColor} height={16} width={16} />
					</IconWrapper>
				)}
			</ChipContent>
		</ChipWrapper>
	);
};

export default TagChip;
