import { Action, ActionFunction1, createAction } from 'redux-actions';
import { SortingTypes } from './reducer';

export interface ISetSortingMethodAction {
	payload: { type: SortingTypes; value: string };
}

export const setSortingMethod: ActionFunction1<
	ISetSortingMethodAction['payload'],
	Action<ISetSortingMethodAction['payload']>
> = createAction('SET_SORTING_METHOD', (action: ISetSortingMethodAction['payload']) => action);
