import { IInsuranceOfferProvider, IVehicleInsurance } from '@ApiModels/vehicleInsurance';
import InsuranceService from '@Services/insurance/insurance';
import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { FormikErrors, FormikTouched } from 'formik';
import { IFormikInsurerFields } from '@Views/CarInsurance/CarInsurance';
import { IVehiclePlateChars } from '@ApiModels/vehiclePlateChars';
import { useHistory } from 'react-router-dom';
import { getLang } from '@Utils/language/language';
import { useDispatch } from 'react-redux';
import { modalTypes, setModal } from '@Redux/modules/modal/actions';
import useTranslate from '@Utils/hooks/useTranslate';
import { TitleWrapper, SubtitleWrapper } from '@Components/Card/cards/InsuranceSummary/InsuranceSummary.styled';
import { IDropdownItem } from '@Components/controls/Dropdown/Dropdown';
import { useSelector } from 'react-redux';
interface IStep {
	description: string;
	iconName: types.iconNames;
}
import { FIRST_STEPPER_STEP, NUMBER_OF_STEPS } from '@Config/app.config';

export enum INSURANCE_TYPE {
	FULL_COMPREHENSIVE = 'FULL_COMPREHENSIVE',
	THIRD_PARTY = 'THIRD_PARTY',
}

type InsuranceProvider = 'BIMA' | 'NLG';

export type InsuranceType = INSURANCE_TYPE.FULL_COMPREHENSIVE | INSURANCE_TYPE.THIRD_PARTY;
export interface IVechicleFields {
	chars: string;
	vehicleNumber: string;
	vehicleValue: string;
}
export interface IInsurerFields extends IVechicleFields {
	licenseNumber: string;
	contactNumber: string;
}
export interface IInsuranceOwner {
	firstName: string;
	lastName: string;
	email: string;
}
export interface IOwnerDataFormErrors {
	errors: FormikErrors<IFormikInsurerFields>;
	touched: FormikTouched<IFormikInsurerFields>;
}
interface IUseInsurance extends IInsurerFields, IInsuranceOwner {
	steps: IStep[];
	nextStep: () => void;
	prevStep: () => void;
	activeIndex: number;
	setType: (type: InsuranceProvider) => void;
	type: InsuranceProvider;
	insuranceType: InsuranceType;
	inputFiltering: string;
	setInputFiltering: (value: string) => void;
	setInsuranceType: (type: InsuranceType) => void;
	isBimaInsurance: boolean;
	setLicenseNumber: (number: IInsurerFields['licenseNumber']) => void;
	setContactNumber: (number: IInsurerFields['contactNumber']) => void;
	setChars: (number: IVechicleFields['chars']) => void;
	setVehicleNumber: (number: IVechicleFields['vehicleNumber']) => void;
	checkValidation: number;
	setCheckValidation: (number: number) => void;
	insuranceOfferProviders: IVehicleInsurance['insuranceOfferProviders'];
	setInsuranceOfferProviders: (providers: IVehicleInsurance['insuranceOfferProviders']) => void;
	vehicleInformation: IVehicleInsurance['vehicleInformation'];
	setVehicleInformation: (info: IVehicleInsurance['vehicleInformation']) => void;
	submitting: boolean;
	setSubmitting: (value: boolean) => void;
	mainColor: types.theme.themeColors;
	setVehicleValue: (value: IVechicleFields['vehicleValue']) => void;
	selectedProvider: IInsuranceOfferProvider;
	setSelectedReferenceNo: (value: string) => void;
	selectedReferenceNo: string;
	setSelectedTrackId: (value: string) => void;
	selectedTrackId: string;
	setFirstName: (value: string) => void;
	setLastName: (value: string) => void;
	setEmail: (value: string) => void;
	getInsurance: () => void;
	clearData: () => void;
	paymentErrorMessage: IVehicleInsurance['paymentErrorMessage'];
	buyable: IVehicleInsurance['buyable'];
	offersMessages: IVehicleInsurance['offersMessages'];
	ownerDataFormErrors: IOwnerDataFormErrors;
	setOwnerDataFormErrors: (ownerDataFormErrors: IOwnerDataFormErrors) => void;
	allPlateChars: IVehiclePlateChars | undefined;
	setActiveIndex: (value: number) => void;
	plateDropdownItem: IDropdownItem;
	setPlateDropdownItem: (value: IDropdownItem) => void;
}

export const InsuranceContext = createContext<IUseInsurance>({
	nextStep: () => {},
	prevStep: () => {},
	activeIndex: FIRST_STEPPER_STEP,
	steps: new Array(0),
	setType: () => {},
	type: 'BIMA' as InsuranceProvider,
	insuranceType: INSURANCE_TYPE.FULL_COMPREHENSIVE as InsuranceType,
	setInsuranceType: () => {},
	isBimaInsurance: false,
	licenseNumber: '',
	contactNumber: '',
	inputFiltering: '',
	chars: '',
	vehicleNumber: '',
	vehicleInformation: {} as IVehicleInsurance['vehicleInformation'],
	setLicenseNumber: () => {},
	setContactNumber: () => {},
	setInputFiltering: () => {},
	setChars: () => {},
	setVehicleNumber: () => {},
	checkValidation: 0,
	setCheckValidation: () => {},
	insuranceOfferProviders: new Array(0),
	setInsuranceOfferProviders: () => {},
	setVehicleInformation: () => {},
	submitting: false,
	selectedProvider: {} as IInsuranceOfferProvider,
	setSubmitting: () => {},
	mainColor: 'primary',
	vehicleValue: '',
	setVehicleValue: () => {},
	setSelectedReferenceNo: () => {},
	selectedReferenceNo: '',
	setSelectedTrackId: () => {},
	selectedTrackId: '',
	firstName: '',
	lastName: '',
	email: '',
	setFirstName: () => {},
	setLastName: () => {},
	setEmail: () => {},
	getInsurance: () => {},
	clearData: () => {},
	buyable: false,
	paymentErrorMessage: '',
	offersMessages: [],
	ownerDataFormErrors: { errors: {}, touched: {} },
	setOwnerDataFormErrors: () => {},
	allPlateChars: {} as IVehiclePlateChars,
	setActiveIndex: () => {},
	plateDropdownItem: {} as IDropdownItem,
	setPlateDropdownItem: () => {},
});

export const useInsurance = (): IUseInsurance => useContext(InsuranceContext);

const NLG_STEPS: { description: string; iconName: types.iconNames }[] = [
	{
		description: 'insurance.step.details',
		iconName: 'details',
	},
	{
		description: 'insurance.step.vehicle-details',
		iconName: 'vehicleDetails',
	},
	{
		description: 'insurance.step.quotes',
		iconName: 'quotes',
	},
	{
		description: 'insurance.step.summary',
		iconName: 'summary',
	},
];

const BIMA_STEPS: { description: string; iconName: types.iconNames }[] = [
	{
		description: 'insurance.step.details',
		iconName: 'details',
	},
	{
		description: 'insurance.step.vehicle-details',
		iconName: 'vehicleDetails',
	},
	{
		description: 'insurance.step.quotes',
		iconName: 'quotes',
	},
	{
		description: 'insurance.step.summary',
		iconName: 'summary',
	},
];

const InusranceContextProvider = ({ children }: { children: any }): JSX.Element => {
	const dispatch = useDispatch();
	const { translate } = useTranslate();
	const [activeIndex, setActiveIndex] = useState(FIRST_STEPPER_STEP);
	const [type, setType] = useState<InsuranceProvider>('BIMA');
	const [insuranceType, setInsuranceType] = useState<InsuranceType>(INSURANCE_TYPE.FULL_COMPREHENSIVE);
	const [licenseNumber, setLicenseNumber] = useState('');
	const [contactNumber, setContactNumber] = useState('');
	const [chars, setChars] = useState('');
	const [inputFiltering, setInputFiltering] = useState('');
	const [vehicleNumber, setVehicleNumber] = useState('');
	const [checkValidation, setCheckValidation] = useState(0);
	const [insuranceOfferProviders, setInsuranceOfferProviders] = useState<
		IVehicleInsurance['insuranceOfferProviders']
	>([]);
	const [vehicleInformation, setVehicleInformation] = useState<IVehicleInsurance['vehicleInformation']>(
		{} as IVehicleInsurance['vehicleInformation']
	);
	const [buyable, setBuyable] = useState<IVehicleInsurance['buyable']>(false);
	const [offersMessages, setOffersMessages] = useState<IVehicleInsurance['offersMessages']>([]);
	const [paymentErrorMessage, setPaymentErrorMessage] = useState<IVehicleInsurance['paymentErrorMessage']>('');
	const [submitting, setSubmitting] = useState(false);
	const [vehicleValue, setVehicleValue] = useState('');
	const [selectedProvider, setSelectedProvider] = useState<IInsuranceOfferProvider>({} as IInsuranceOfferProvider);
	const [selectedReferenceNo, setSelectedReferenceNo] = useState('');
	const [selectedTrackId, setSelectedTrackId] = useState('');
	const [firstName, setFirstName] = useState('');
	const [lastName, setLastName] = useState('');
	const [email, setEmail] = useState('');
	const [steps, setSteps] = useState<IStep[]>(BIMA_STEPS);
	const [ownerDataFormErrors, setOwnerDataFormErrors] = useState<IOwnerDataFormErrors>({ errors: {}, touched: {} });
	const [allPlateChars, setAllPlateChars] = useState<IVehiclePlateChars | undefined>();
	const [plateDropdownItem, setPlateDropdownItem] = useState<IDropdownItem>({} as IDropdownItem);
	const history = useHistory();
	const isUserSignedIn = useSelector((state: types.redux.IState) => state.api?.authentication.signedIn);

	useEffect(() => {
		if (insuranceType === INSURANCE_TYPE.THIRD_PARTY && vehicleInformation?.vehicleValue) {
			setVehicleValue(vehicleInformation?.vehicleValue);
		}
	}, [insuranceType, vehicleInformation]);

	useEffect(() => {
		if (isUserSignedIn) {
			getVehiclePlateChars();
		}
	}, [isUserSignedIn]);

	useEffect(() => {
		if (selectedReferenceNo && insuranceOfferProviders) {
			const findedProvider = insuranceOfferProviders.find(({ offers }) =>
				offers.some(({ referenceNo }) => referenceNo === selectedReferenceNo)
			);
			if (!!findedProvider) {
				setSelectedProvider(findedProvider);
				nextStep();
				history.push(`/${getLang()}/product/car-insurance/bima/summary`);
			}
		}
	}, [selectedReferenceNo, insuranceOfferProviders]);

	useEffect(() => {
		if (type === 'BIMA') {
			setSteps(BIMA_STEPS);
		} else if (type === 'NLG') {
			setSteps(NLG_STEPS);
		}
	}, [type]);

	const nextStep = () => {
		if (activeIndex < NUMBER_OF_STEPS) {
			setActiveIndex(activeIndex + 1);
		}
	};

	const prevStep = () => {
		if (activeIndex > FIRST_STEPPER_STEP) {
			setActiveIndex(activeIndex - 1);
		}
	};

	const isBimaInsurance = useMemo(() => type === 'BIMA', [type]);
	const mainColor: types.theme.themeColors = isBimaInsurance ? 'green' : 'secondary';

	const getVehiclePlateChars = () => {
		InsuranceService.getVehiclePlateChars().subscribe((chars) => {
			setAllPlateChars(chars);
		});
	};

	const getErrorMsg = (error: any): string => {
		if (error?.response?.detailedCode && error?.response?.details) {
			return error?.response?.details;
		} else {
			return translate('car-insurance.api.error.message');
		}
	};

	const getInsurance = () => {
		setSubmitting(true);
		history.push(`/${getLang()}/product/car-insurance/bima/vehicle-details`);
		nextStep();
		InsuranceService.getVehicleInsurance({
			insuranceType,
			insuredLicense: licenseNumber,
			insuredContactNo: contactNumber,
			vehiclePlateCharCode: chars,
			vehicleNo: vehicleNumber,
			vehicleValue,
			insurerName: isBimaInsurance ? 'BIMA' : 'NLG',
		}).subscribe(
			({ insuranceOfferProviders, vehicleInformation, buyable, offersMessages, paymentErrorMessage }) => {
				setInsuranceOfferProviders(insuranceOfferProviders);
				setVehicleInformation(vehicleInformation);
				setBuyable(buyable);
				setOffersMessages(offersMessages);
				setPaymentErrorMessage(paymentErrorMessage);
				setSubmitting(false);
			},
			(e) => {
				setSubmitting(false);
				setActiveIndex(0);
				dispatch(
					setModal({
						data: {
							status: 'error',
							body: (
								<>
									<TitleWrapper>{translate('car-insurance.api.error')}</TitleWrapper>
									<SubtitleWrapper>{getErrorMsg(e)}</SubtitleWrapper>
								</>
							),
							secondaryButtonText: translate('insurance.step.back'),
							onClickSecondary: () => {
								dispatch(setModal({ closeAllModals: true, type: null }));
							},
						},
						type: modalTypes.STATUS,
						withoutContainer: true,
					})
				);
			},
			() => {
				setSubmitting(false);
			}
		);
	};

	const clearData = () => {
		setLicenseNumber('');
		setContactNumber('');
		setChars('');
		setVehicleNumber('');
		setVehicleValue('');
		setFirstName('');
		setLastName('');
		setEmail('');
		setSelectedReferenceNo('');
		setActiveIndex(0);
	};

	return (
		<InsuranceContext.Provider
			value={{
				nextStep,
				activeIndex,
				prevStep,
				inputFiltering,
				setInputFiltering,
				steps,
				setType,
				type,
				insuranceType,
				setInsuranceType,
				isBimaInsurance,
				licenseNumber,
				contactNumber,
				chars,
				vehicleNumber,
				setLicenseNumber,
				setContactNumber,
				setChars,
				setVehicleNumber,
				checkValidation,
				setCheckValidation,
				insuranceOfferProviders,
				setInsuranceOfferProviders,
				vehicleInformation,
				setVehicleInformation,
				submitting,
				setSubmitting,
				mainColor,
				vehicleValue,
				setVehicleValue,
				selectedProvider,
				setSelectedReferenceNo,
				selectedReferenceNo,
				setSelectedTrackId,
				selectedTrackId,
				firstName,
				lastName,
				email,
				setFirstName,
				setLastName,
				setEmail,
				getInsurance,
				clearData,
				buyable,
				paymentErrorMessage,
				offersMessages,
				ownerDataFormErrors,
				setOwnerDataFormErrors,
				allPlateChars,
				setActiveIndex,
				plateDropdownItem,
				setPlateDropdownItem,
			}}
		>
			{children}
		</InsuranceContext.Provider>
	);
};

export default InusranceContextProvider;
