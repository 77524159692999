import { IKeyFeaturesItem } from '@ApiModels/productById';
import React, { FunctionComponent } from 'react';
import { Col } from 'react-grid-system';
import Feature from './Feature/Feature';
import { FeaturesWrapper } from './Features.styled';

interface IFeatures {
	features: IKeyFeaturesItem[];
}

const Features: FunctionComponent<IFeatures> = ({ features }) => {
	return (
		<FeaturesWrapper>
			{features.map((f, index) => {
				return (
					<Col xs={12} sm={6} key={index}>
						<Feature title={f.title} value={f.value} img={f.img} />
					</Col>
				);
			})}
		</FeaturesWrapper>
	);
};

export default Features;
