const errorCodes = new Map([
	['ERROR_TIMEOUT', { translationKey: 'error.timeout', generalError: true, formField: [] }],
	['WRONG_RESPONSE', { translationKey: 'error.wrong.response', generalError: true, formField: [] }],
	['AN_ERROR_OCCURED', { translationKey: 'error.occured', generalError: true, formField: [] }],
	[
		'PASSWORD_TOO_SHORT',
		{ translationKey: 'error.password.too.short', generalError: false, formField: ['password'] },
	],
]);

export default errorCodes;
