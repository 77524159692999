import Icon from '@Components/Icon/Icon';
import useTranslate from '@Utils/hooks/useTranslate';
import React, { FunctionComponent } from 'react';
import { DescriptionWrapper, PickupOrderContainer, TitleContainer, TitleWrapper } from './PickupOrder.styled';

const ICON_SIZE = 40;

const PickupOrder: FunctionComponent = () => {
	const { translate } = useTranslate();
	return (
		<PickupOrderContainer>
			<TitleContainer>
				<TitleWrapper>{translate('pickup-order.title')}</TitleWrapper>
				<DescriptionWrapper>{translate('pickup-order.subtitle')}</DescriptionWrapper>
			</TitleContainer>
			<Icon name="locationBuild" width={ICON_SIZE} height={ICON_SIZE} fill="white" />
		</PickupOrderContainer>
	);
};

export default PickupOrder;
