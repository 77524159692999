import { BodyS, ButtonM } from '@Components/Typography/Typography';
import styled from 'styled-components';
import { matchScreen } from '@Utils/layout/layoutSupport';

export const Horizontal = styled.div`
	display: flex;
	gap: 8px;
`;

export const CardContainer = styled.div`
	width: ${() => (matchScreen(['xs', 'sm']) ? 'auto' : '730px')};
`;

export const DescriptionWrapper = styled.div`
	display: flex;
	justify-content: flex-start;
	align-items: center;
`;

export const Description = styled(BodyS)`
	line-height: 1.6;
`;

export const DescriptionError = styled(BodyS)`
	line-height: 1.6;
	${({ theme }) => ({
		color: theme.colors.warning,
	})}
`;

export const BGContainer = styled.div`
	overflow: hidden;
	transform: translateX(100px);
	> div {
		width: 550px;
		height: 655px;
	}
	position: absolute;
	top: 40px;
	right: 0;
	opacity: 0.2;
`;

export const CardWrapper = styled.div`
	padding: ${() => (matchScreen(['xs']) ? '15px 16px' : '40px 56px')};
`;

export const LogoWrapper = styled.div`
	padding: 0px 15px;
`;

export const Button = styled.div`
	> div {
		width: 100%;
	}
`;

export const TextButtonWrapper = styled.div`
    display: flex;
    justify-content: center;
    padding 10px 0
`;

export const TextButton = styled(ButtonM)`
	cursor: pointer;
	${({ theme }) => ({
		textDecorationColor: theme.colors.primary,
		color: theme.colors.primary,
	})}
`;

export const Footer = styled.div`
	${({ theme }) => ({ backgroundColor: theme.colors.grey100 })}
	padding: 24px 40px;
`;

export const Header = styled.div`
	display: flex;
	justify-content: space-between;
	width: 100%;
`;
