import { ENV } from '@Config/app.config';

const LINKS = {
	REGISTER: 'https://omantel.om/Menu/Register',
	FORGOT_PASSWORD: 'https://omantel.om/Menu/Reminf',
	OMANTEL: 'https://omantel.om',
	AROUND_ME: 'https://omantel.om/Personal/Around%20Me',
	ACCOUNT: 'https://omantel.om/Account/',
	PROMOTIONS: 'https://omantel.om/Personal/promotions',
	MAKASIB: 'https://authoring.omantel.om/Personal/Makasib',
	PERSONAL_5G: 'https://omantel.om/Personal/5g',
	HAYYAK_PLANS: 'https://omantel.om/Personal/mobile/hayyak/Hayyak-Plus',
	PREPAID_PAYG: 'https://authoring.omantel.om/Personal/mobile/prepaid#payg',
	OMANTEL_5G: 'https://authoring.omantel.om/Personal/mobile/Omantel-5G-mobility',
	HAYYAK: 'https://authoring.omantel.om/Personal/mobile/hayyak',
	HDO: 'https://omantel.om/Personal/mobile/Hayyak-Data-only-Plan',
	BAQATI: 'https://authoring.omantel.om/Personal/mobile/new-baqati',
	BAQATI_POSTPAID: 'https://authoring.omantel.om/Personal/mobile/New-Baqati',
	BAQATI_AL_UFUQ: 'https://authoring.omantel.om/Personal/mobile/New-Baqati/baqati-al-ufuq',
	JAWAZAK: 'https://omantel.om/Personal/mobile/Roaming',
	ROAMING: 'https://authoring.omantel.om/Personal/mobile/Roaming/Abroad',
	ROAMING_HEADER:
		'https://authoring.omantel.om/!ut/p/z0/04_Sj9CPykssy0xPLMnMz0vMAfIjo8ziDdwNHD2cLIx8DDxCnAzMLMxdTVxcXT093Mz1C7IdFQF_QuHr/',
	ROAMING_PREPAID: 'https://authoring.omantel.om/Personal/mobile/Roaming',
	ADDONS: 'https://omantel.om/Personal/mobile/ServicesAndAddons',
	MOBILE_APP: 'https://omantel.om/Personal/mobile/ServicesAndAddons',
	GET_OUR_SERVICES: 'https://authoring.omantel.om/Menu/order-services',
	TRANSFER_YOUR_NUMBER: 'https://authoring.omantel.om/Menu/mnp-all',
	NUMBER_AUCTION: 'https://authoring.omantel.om/Personal/Promotions/number-auction',
	ESIM: 'https://authoring.omantel.om/Personal/mobile/eSIM',
	APPLE_WATCH_CELLULAR: 'https://authoring.omantel.om/Personal/Promotions/Apple-Watchs-Cellular',
	APPLE_TV: 'https://authoring.omantel.om/Personal/Promotions/Promo%20Details/Apple%20TV',
	PROMOTIONS_POSTPAID: 'https://authoring.omantel.om/Personal/promotions#postpaid',
	PROMOTIONS_PREPAID: 'https://authoring.omantel.om/Personal/promotions#prepaid',
	PROMOTIONS_HOME_INTERNET: 'https://authoring.omantel.om/Personal/promotions#home-internet',
	EFLOOS: 'https://omantel.om/Personal/Promotions/efloos',
	ULTRA_FAST: 'https://authoring.omantel.om/Personal/AtHome/basic/ultra-fast',
	BASIC: 'https://authoring.omantel.om/Personal/AtHome/basic',
	WIRELESS: 'https://authoring.omantel.om/Personal/AtHome/5G-Home-offer',
	AFAAQ: 'https://authoring.omantel.om/Personal/AtHome/Afaaq',
	HOME_FAMILY_PLAN: 'https://omantel.om/Personal/AtHome/basic/family-plan',
	FIXED_LINE: 'https://authoring.omantel.om/Personal/AtHome/Fixed-Line',
	SAHL: 'https://authoring.omantel.om/Personal/AtHome/Fixed-Line/Sahl-Fixed',
	SMART_HOMES: 'https://authoring.omantel.om/Personal/AtHome/Fixed/smart-home',
	FIXED_SERVICES: 'https://authoring.omantel.om/Personal/AtHome/Fixed',
	OMANTEL_TV: 'https://authoring.omantel.om/Personal/AtHome/omantel-tv',
	GAMING: 'https://authoring.omantel.om/Personal/Promotions/Promo%20Details/HBB-Gaming',
	GAMING_HEADER:
		'https://authoring.omantel.om/Personal/!ut/p/z1/04_Sj9CPykssy0xPLMnMz0vMAfIjo8ziLY2MLDxMTAx93D0MzQwcQ92Cndydgw39fUz0w_Wj9KOQlfi7OboYOBoaWJqbmrgZGFgYQhUY4ACOBvrBqXn6BdnZaY6OiooAof0PcA!!/#',
	OSN: 'https://authoring.omantel.om/Personal/promotions/promotion-details/osn',
	SHAHID: 'https://authoring.omantel.om/Personal/Promotions/Shahid%20VIP',
	JAWWY_TV: 'https://authoring.omantel.om/Personal/promotions/promotion-details/jawaytv',
	JAWWY_TV_HOME:
		'https://authoring.omantel.om/!ut/p/z0/04_Sj9CPykssy0xPLMnMz0vMAfIjo8ziDdwNHD2cLIx8DDxCnAzMLMxdTVxcXUPDQkz1C7IdFQHv_K11/',
	GE_FORCE_EVERYWHERE:
		'https://authoring.omantel.om/!ut/p/z0/04_Sj9CPykssy0xPLMnMz0vMAfIjo8ziDdwNHD2cLIx8_E3dHA3MDLx8fU2DzINCQkz0C7IdFQGuH6zr/',
	MICROSOFT_PRODUCTS: 'https://omantel.om/business/small-medium-enterprise/ict/software-as-service/microsoft-product',
	ADDITIONAL_SERVICES: 'https://omantel.om/Personal/AtHome/Additional-Services',
	TRACK_HBB: 'https://authoring.omantel.om/Personal/AtHome/TrackYourHBBRequest',
	ADDITIONAL_SERVICES_HOME: 'https://authoring.omantel.om/Personal/AtHome/Additional%20Services',
	HOME: 'https://omantel.om/Personal/AtHome',
	SME: 'https://authoring.omantel.om/business/small-medium-enterprise',
	ENTERPRISE_GOVERNMENT: 'https://authoring.omantel.om/business/enterprise-government',
	MOBILE: 'https://omantel.om/Personal/mobile/',
	CAREERS: 'https://omantel.om/About%20us/Careers',
	ABOUT_US: 'https://authoring.omantel.om/About%20us',
	CSR: 'https://authoring.omantel.om/csr/overview',
	CARER:
		'https://authoring.omantel.om/!ut/p/z0/04_Sj9CPykssy0xPLMnMz0vMAfIjo8ziDdwNHD2cLIx8_E3dHA3MDLx8fU2DzI0NfI31C7IdFQGtzVYb/',
	MEDIA_CENTRE: 'https://authoring.omantel.om/About%20us/Media%20Center',
	INNOVATION_LABS: 'https://authoring.omantel.om/innovation-labs',
	PAYMENT_CHANNELS: 'https://authoring.omantel.om/About%20us/AboutOmantel/Payment-Channels',
	COVERAGE: 'https://authoring.omantel.om/Personal/mobile/Coverage',
	INVESTORS: 'https://authoring.omantel.om/Investors',
	I_SUPPlIER: 'https://authoring.omantel.om/Personal/Promotions/iSupplier',
	PRIVACY_POLICY: 'https://authoring.omantel.om/Footer2/Privacy%20Policy',
	WHISTLE_BLOWING_POLICY: 'https://authoring.omantel.om/Personal/Promotions/Whistleblowing%20Policy',
	COPYRIGHTS: 'https://authoring.omantel.om/Footer2/Copyrights',
	REGULATORY: 'https://authoring.omantel.om/About%20us/regulatory',
	SITE_MAP: 'https://authoring.omantel.om/Footer2/SiteMap',
	BUSINESS: 'https://omantel.om/business',
	PERSONAL: 'https://authoring.omantel.om/Personal',
	PERSONAL_SERVICES: 'https://authoring.omantel.om/Personal/5g',
	PERSONAL_BILL_PAYMENT: 'https://authoring.omantel.om/Personal/bill-payment',
	TOP_UP: 'https://authoring.omantel.om/Personal/topup',
	HAYYAK_PLUS: 'https://www.omantel.om/Personal/mobile/hayyak/Hayyak-Plus',
	NEW_BAQATI: 'https://www.omantel.om/Personal/mobile/New-Baqati',
	ERADA_BAQATI: 'https://authoring.omantel.om/Personal/mobile/New-Baqati/New-Baqati-Addon/Erada-Baqati',
	NEW_HAYYAK_PLANS: 'https://authoring.omantel.om/Personal/mobile/prepaid#New-Hayyak-Plans',
	HAYYAK_D_O_P: 'https://authoring.omantel.om/Personal/mobile/prepaid#Hayyak-Data-Only',
	TOURIST_PACK: 'https://authoring.omantel.om/Personal/mobile/prepaid#Tourist-Packs',
	ERADA: 'https://authoring.omantel.om/Personal/mobile/hayyak/Hayyak-Plus/Add-ons/erada',
	PREPAID_ERADA: 'https://authoring.omantel.om/Personal/mobile/prepaid#Erada-Plans',
	AMAN:
		'https://authoring.omantel.om/!ut/p/z0/04_Sj9CPykssy0xPLMnMz0vMAfIjo8ziDdwNHD2cLIx8_E3dHA3MDLx8fU2DzIM8XM31C7IdFQEe3oWH/',
	INTERNET_PLANS: 'https://www.omantel.om/Personal/mobile/New-Baqati/New-Baqati-Addon/Erada-Baqati',
	MESH: 'https://authoring.omantel.om/Personal/Promotions/store_offer/WI-FI-mash',
	GAMIN_ADD_ON:
		'https://authoring.omantel.om/!ut/p/z0/04_Sj9CPykssy0xPLMnMz0vMAfIjo8ziDdwNHD2cLIx83C2NXQ0Cw9zdg0xdAn2NDIz0C7IdFQEwc8q6/',
	GE_FORCE_NOW:
		'https://www.omantel.om/!ut/p/z1/04_iUlDg4tKPAFJABjKBwtGPykssy0xPLMnMz0vM0Y_Qj4wyizdwN3D0cLIw8vE3dXM0MDPw8vU1DTIPCgkx0ffSj8KvIDg1T78gO1ARAMe6BG0!/',
	PREPAID_AMAN: 'https://authoring.omantel.om/Personal/mobile/prepaid#Aman-Plans',
	JAWAZAK_GCC: 'https://authoring.omantel.om/Personal/mobile/Roaming/jawazak-gcc',
	JAWAZAK_WORLD: 'https://authoring.omantel.om/Personal/mobile/Roaming/Jawazak-World',
	JAWAZAK_FLIGHT: 'https://authoring.omantel.om/Personal/mobile/Roaming/jawazak-in-flight',
	ADD_ONS_PREPAID: 'https://authoring.omantel.om/Personal/mobile/hayyak/add-ons',
	MOBILE_INTERNET: 'https://authoring.omantel.om/Personal/mobile/hayyak/Mobile%20Internet',
	NATIONAL_CALLS: 'https://authoring.omantel.om/Personal/mobile/hayyak/add-ons/National-Calls',
	INTERNATIONAL_CALLS: 'https://authoring.omantel.om/Personal/mobile/hayyak/services/International-Calls',
	NON_STOP_SOCIAL: 'https://authoring.omantel.om/Personal/mobile/hayyak/add-ons/unlimited-social',
	MARHABA_WORLD: 'https://authoring.omantel.om/Personal/mobile/hayyak/add-ons/Marhaba-World',
	YOU_TUBE_PASS: 'https://authoring.omantel.om/Personal/mobile/hayyak/Hayyak-Plus/Add-ons/YouTubePass',
	TALK_MORE: 'https://authoring.omantel.om/Personal/mobile/hayyak/add-ons/hayyak-talk',
	HAYYAK_LIBERTY: 'https://authoring.omantel.om/Personal/mobile/hayyak/add-ons/hayyak-liberty',
	TOURIST_PACK_PREPAID: 'https://authoring.omantel.om/Personal/mobile/prepaid#Tourist-Packs',
	PERSONAL_MOBILE_APP: 'https://authoring.omantel.om/Personal/mobile/mobile-app',
	VALUE_ADDED_SERVICES: 'https://authoring.omantel.om/Personal/mobile/vas',
	CALL_ME_BACK: 'https://authoring.omantel.om/Personal/mobile/hayyak/services/Call-me-Back',
	EMERGENCY_SERVICES: 'https://authoring.omantel.om/Personal/mobile/hayyak/services/Emergency-Service',
	VOICE_MAIL: 'https://authoring.omantel.om/Personal/mobile/hayyak/services/Voice-Mail',
	FAMILY_AND_FRIENDS: 'https://authoring.omantel.om/Personal/mobile/hayyak/services/Family-and-Friends',
	MONEY_TRANSFER: 'https://authoring.omantel.om/Personal/mobile/hayyak/services/Money-Transfer',
	HAYYAK_INTERNET_GIFTING: 'https://authoring.omantel.om/Personal/mobile/hayyak/add-ons/hayyak-internet-gifting',
	PERMIT_SERVICE: 'http://authoring.omantel.om/om/permit-service',
	DCB_NETFLIX:
		'https://authoring.omantel.om/!ut/p/z0/04_Sj9CPykssy0xPLMnMz0vMAfIjo8ziDdwNHD2cLIx8DAxCDA3MPIP9Db2DnYzdgw31C7IdFQHA_AvC/',
	HOME_INTERNET: 'https://portal.omantel.om/Personal/AtHome/5G-Home-offer',
	CONTACT_US: 'https://www.omantel.om/Footer2/Contact%20Us',
	WHOLESALE: 'https://authoring.omantel.om/wholesale',
	PLAN_TERMS:
		'https://www.omantel.om/Menu/Terms%26Conditions/!ut/p/z1/04_iUlDgAgL9CCADyEQmiOToR-UllmWmJ5Zk5ucl5uhH6EdGmcUbuBs4ejhZGPlYeBgaGzj6BLk4exmEGHv4Gel76UfhVxCcmqdfkB2oCABwxUX1/',
};

export default (key: keyof typeof LINKS): string => {
	if (ENV === 'TST' || (ENV === 'STG' && !LINKS[key].includes('authoring'))) {
		return LINKS[key].replace('omantel.om', 'testportal.omantel.om');
	} else if (LINKS[key].includes('authoring') && ENV === 'PROD') {
		return LINKS[key].replace('authoring.omantel.om', 'omantel.om');
	}
	return LINKS[key];
};
