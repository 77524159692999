import { BodyM, Subheading, Caption } from '@Components/Typography/Typography';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { marginLeft, marginRight } from '@Utils/layout/layoutSupport';

export const CardWrapper = styled.div`
	padding: 8px 16px;
`;

export const Title = styled(Subheading)`
	padding-bottom: 16px;
`;

export const AdditionalInfoTitle = styled(Subheading)`
	padding-bottom: 16px;
	${({ theme }) => {
		return { color: theme.colors.black54 };
	}}
`;

export const DescriptionTitle = styled(BodyM)`
	padding-bottom: 4px;
`;

export const PriceDescriptionWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
`;

export const PriceContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-end;
`;

export const Subtitle = styled(Caption)`
	margin-right: 4px;
`;

export const IconWrapper = styled.div`
	margin-right: 6px;
	margin-bottom: 2px;
`;

export const LinkWrapper = styled(Link)`
	color: ${({ theme }) => {
		return theme.colors.primary;
	}};
`;

export const MessageContainer = styled.div`
	width: 100%;
	display: flex;
	padding: 8px 16px;
	margin-bottom: 8px;
	border-radius: 8px;
	${({ theme }) => ({ backgroundColor: theme.colors.grey100 })};
	flex-direction: column;
	align-items: center;
`;

export const Message = styled(Caption)`
	${({ theme }) => ({ color: theme.colors.black54 })}
	${() => marginLeft(16)}
	text-align:center;
`;

export const SummaryWrapper = styled.div<{ withBorder: boolean }>`
	${({ theme, withBorder }) => {
		const border = withBorder
			? {
					border: `1px solid ${theme.colors.black12}`,
					borderRadius: '8px',
					padding: 16,
			  }
			: {};
		return {
			width: '100%',
			...border,
		};
	}}
`;
export const PriceSummaryRow = styled.div`
	display: flex;
	justify-content: space-between;
`;

export const SummaryContainer = styled.div<{ addMarginLeft?: number; addMarginRight?: number }>`
	${({ addMarginLeft = 0 }) => marginLeft(addMarginLeft)}
	${({ addMarginRight = 0 }) => marginRight(addMarginRight)}
`;
