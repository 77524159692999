import { IFormInitialValues } from '@ApiModels/salesLead';
import ConditionalRender from '@Components/ConditionalRender/ConditionalRender';
import { PrimaryLargeButtonFlex } from '@Components/controls/Button/Button';
import RadioButton from '@Components/controls/RadioButton/RadioButton';
import TextField from '@Components/controls/TextField/TextField';
import Divider from '@Components/Divider/Divider';
import Icon from '@Components/Icon/Icon';
import { BodyS } from '@Components/Typography/Typography';
import { removeBusyIndicator } from '@Redux/modules/busyIndicator/actions';
import { dispatch } from '@Redux/store';
import { OrderFlowService } from '@Services/orderFlow/orderFlow';
import { ProfileService } from '@Services/profile/profileService';
import useTranslate from '@Utils/hooks/useTranslate';
import { getLang } from '@Utils/language/language';
import { SubTitle, TitleContent } from '@Views/Contact/Contact.styled';
import { Formik } from 'formik';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import { ButtonTextWrapper, ButtonWrapper, CardWrapper, RadioButtonsWrapper, Title } from './SalesLeadForm.styled';

const SalesLeadForm: FunctionComponent<types.cards.IPlanCardProps> = ({ params: { productDetails, isLogged } }) => {
	const { translate } = useTranslate();
	const history = useHistory();

	const [isOmantelCustomer, setIsOmantelCustomer] = useState<boolean>(false);
	const [dataSend, setDataSend] = useState<boolean>(false);
	const [valuesInput, setValuesInput] = useState<any>();
	const validationSchema = Yup.object().shape({
		firstName: Yup.string().required('First Name is required'),
		lastName: Yup.string().required('Last Name is required'),
		contactNumber: Yup.string()
			.required(translate('validate.personal.data.contact.number.required'))
			.length(8, translate('validate.personal.data.oman.format.phone.number'))
			.matches(
				/(90[1-9][0-9]{5})|((9[1-9]|(7[1289]))[0-9]{6})$|(2)[2-6][0-9]{6}$|77[0-9]{6}$/,
				translate('validate.personal.data.oman.format.phone.number')
			),
		emailAddress: Yup.string().email('Invalid email address').required('Email Address is required'),
		isOmantelCustomer: Yup.boolean().required(),
		crNumber: Yup.string().when('isOmantelCustomer', {
			is: true,
			then: Yup.string().required('CR Number is required'),
			otherwise: Yup.string(),
		}),
		comments: Yup.string().matches(/^[^\s][a-zA-Z0-9\s,.]*$/, translate('validate.comment')),
	});
	const [initialValues, setInitialValues] = useState<IFormInitialValues>({
		firstName: '',
		lastName: '',
		contactNumber: '',
		emailAddress: '',
		crNumber: '',
		isOmantelCustomer: false,
		comments: '',
	});
	const [contactNumber, setContactNumber] = useState<string>('');
	const [crNumber, setCrNumber] = useState<string>('');
	const [referenceNumber, setReferenceNumber] = useState<string | null>(null);

	const APPLY_NOW_BI = 'APPLY_NOW_BI';

	const handleGoToHomepage = () => {
		history.push(``);
	};

	const getContactNumber = () => {
		ProfileService.getEbuUserProfile().subscribe((response) => {
			setContactNumber(response.contactNumber1);
		});
	};

	const getCrNumber = () => {
		ProfileService.getCorpAccountsForCurrentUser().subscribe((response) => {
			if (response.accounts.length > 0) {
				setCrNumber(response.accounts[0].documentId);
			}
		});
	};

	useEffect(() => {
		ProfileService.getUserProfile().subscribe((response) => {
			if (response) {
				getContactNumber();
				if (response.documentType === '105') getCrNumber();
				setInitialValues({
					firstName: response.firstName,
					lastName: response.lastName,
					contactNumber,
					emailAddress: response.email,
					crNumber,
					isOmantelCustomer: true,
					comments: '',
				});
			}
		});
	}, [isLogged, crNumber, contactNumber]);

	return (
		<CardWrapper>
			<ConditionalRender
				show={dataSend}
				onTrue={() => (
					<>
						<Icon name="success" height={96} width={96} />
						<Divider withoutLine marginBottom={24} />
						<TitleContent>{translate('contact.title.success.thank.you')}</TitleContent>
						<Divider withoutLine marginBottom={8} />
						<SubTitle>{translate('contact.request.sent.subtitle')}</SubTitle>
						<Divider withoutLine marginBottom={8} />
						<SubTitle>
							{translate('contact.request.reference.number', referenceNumber ? referenceNumber : '')}
						</SubTitle>
						<Divider withoutLine marginBottom={32} />
						<PrimaryLargeButtonFlex uniqueId="go.back.button" onClick={handleGoToHomepage}>
							{translate('go.back.button.primary')}
						</PrimaryLargeButtonFlex>
					</>
				)}
				onFalse={() => (
					<Formik
						enableReinitialize={true}
						validationSchema={validationSchema}
						initialValues={
							initialValues
								? initialValues
								: {
										firstName: '',
										lastName: '',
										contactNumber: '',
										emailAddress: '',
										crNumber: '',
										isOmantelCustomer: false,
										comments: '',
								  }
						}
						onSubmit={(values) => {
							setValuesInput(values);
							const payload = {
								offer: 'eshop_business_saleslead',
								phone: values.contactNumber,
								email: values.emailAddress,
								language: getLang(),
								name: `${values.firstName} ${values.lastName}`,
								message: `{"productName":"${productDetails?.name}", "email":"${
									values.emailAddress
								}", "crNumber":"${values.crNumber}", "comments":"${
									values.comments
								}","existingCustomer":"${
									values.isOmantelCustomer
										? translate('product.sales-existing.user.yes')
										: translate('product.sales-existing.user.no')
								}"}`,
								returnReference: true,
								sendEmailToSender: true,
								sendSms: true,
							};
							OrderFlowService.directContact(payload).subscribe(
								({ response }) => {
									if (response && response.ReferenceNumber) {
										setReferenceNumber(response.ReferenceNumber);
									}
									setDataSend(true);
									dispatch(removeBusyIndicator(APPLY_NOW_BI));
								},
								() => {
									dispatch(removeBusyIndicator(APPLY_NOW_BI));
								}
							);
						}}
					>
						{({ values, setFieldValue, handleSubmit, errors, touched }) => (
							<>
								<Divider marginBottom={10} withoutLine />
								<Title>{translate('product.cart-card.sales-lead.form.request-device')}</Title>
								<ConditionalRender
									show={!isLogged}
									onTrue={() => (
										<>
											<BodyS color="primary">
												{translate('product.cart-card.sales-lead.form.log-in')}
											</BodyS>
											<Divider marginBottom={10} withoutLine />
										</>
									)}
								/>
								<TextField
									id="firstName"
									label={translate('product.cart-card.sales-lead.form.firstName')}
									value={values.firstName}
									onChange={(value) => setFieldValue('firstName', value)}
									error={!!errors.firstName && !!touched.firstName}
									message={
										!!errors.firstName && !!touched.firstName ? errors.firstName.toString() : ''
									}
								/>
								<Divider marginBottom={10} withoutLine />
								<TextField
									id="lastName"
									label={translate('product.cart-card.sales-lead.form.lastName')}
									value={values.lastName}
									onChange={(value) => setFieldValue('lastName', value)}
									error={!!errors.lastName && !!touched.lastName}
									message={!!errors.lastName && !!touched.lastName ? errors.lastName.toString() : ''}
								/>
								<Divider marginBottom={10} withoutLine />
								<TextField
									id="contactNumber"
									label={translate('product.cart-card.sales-lead.form.contactNumber')}
									value={values.contactNumber}
									onChange={(value) => setFieldValue('contactNumber', value)}
									error={!!errors.contactNumber && !!touched.contactNumber}
									message={
										!!errors.contactNumber && touched.contactNumber
											? errors.contactNumber.toString()
											: ''
									}
								/>
								<Divider marginBottom={10} withoutLine />
								<TextField
									id="emailAddress"
									label={translate('product.cart-card.sales-lead.form.email')}
									value={values.emailAddress}
									onChange={(value) => setFieldValue('emailAddress', value)}
									error={!!errors.emailAddress && !!touched.emailAddress}
									message={
										!!errors.emailAddress && touched.emailAddress
											? errors.emailAddress.toString()
											: ''
									}
								/>
								<Divider marginBottom={10} withoutLine />
								<TextField
									id="crNumber"
									label={translate('product.cart-card.sales-lead.form.crNumber')}
									value={values.crNumber}
									onChange={(value) => setFieldValue('crNumber', value)}
									error={!!errors.crNumber && !!touched.crNumber}
									message={!!errors.crNumber && touched.crNumber ? errors.crNumber.toString() : ''}
								/>
								<Divider marginBottom={10} withoutLine />
								<TextField
									maxlength={100}
									id="comments"
									label={translate('product.cart-card.sales-lead.form.comments')}
									value={values.comments}
									onChange={(value) => setFieldValue('comments', value)}
									error={!!errors.comments && !!touched.comments}
									message={!!errors.comments && touched.comments ? errors.comments : ''}
								/>
								<ConditionalRender
									show={!isLogged}
									onTrue={() => (
										<>
											<Divider marginBottom={10} withoutLine />
											<Title>
												{translate('product.cart-card.sales-lead.form.isOmantelCustomer')}
											</Title>
											<RadioButtonsWrapper>
												<RadioButton
													label={translate('product.cart-card.sales-lead.form.yes')}
													selected={isOmantelCustomer}
													onClick={() => {
														setIsOmantelCustomer(true);
														setFieldValue('isOmantelCustomer', true);
													}}
													name="radio"
													value={true}
													marginRight={16}
													disabled={isLogged}
												/>
												<RadioButton
													label={translate('product.cart-card.sales-lead.form.no')}
													selected={isOmantelCustomer}
													onClick={() => {
														setIsOmantelCustomer(false);
														setFieldValue('isOmantelCustomer', false);
													}}
													name="radio"
													value={false}
													marginRight={16}
													disabled={isLogged}
												/>
											</RadioButtonsWrapper>
										</>
									)}
								/>

								<Divider marginBottom={24} marginTop={24} />

								<ButtonWrapper>
									<PrimaryLargeButtonFlex
										type="submit"
										uniqueId="submit.button"
										listener={APPLY_NOW_BI}
										onClick={() => {
											handleSubmit();
										}}
									>
										<ButtonTextWrapper>{translate('submit.button')}</ButtonTextWrapper>
									</PrimaryLargeButtonFlex>
								</ButtonWrapper>
							</>
						)}
					</Formik>
				)}
			/>
		</CardWrapper>
	);
};

export default SalesLeadForm;
