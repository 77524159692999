import { IFilter } from '@ApiModels/productsByCategoryId';
import immerHandleActions from '@Redux/utils/immerHandleActions';
import {
	setFilters,
	ISetFiltersAction,
	toggleFilter,
	IToggleFilterAction,
	clearFilters,
	enableFilter,
} from './actions';

export const init: IFilter[] = [];

type draft = typeof init;

const reducers = {
	[setFilters.toString()](draft: draft, action: ISetFiltersAction) {
		return action.payload;
	},
	[toggleFilter.toString()](draft: draft, action: IToggleFilterAction) {
		for (const filter of draft) {
			for (const entry of filter.entry) {
				if (entry.extendedData.uniqueId === action.payload.extendedData.uniqueId) {
					entry.enabled = !entry.enabled;
					return draft;
				}
			}
		}
	},
	[enableFilter.toString()](draft: draft, action: IToggleFilterAction) {
		for (const filter of draft) {
			for (const entry of filter.entry) {
				if (entry.extendedData.uniqueId === action.payload.extendedData.uniqueId) {
					entry.enabled = true;
					return draft;
				}
			}
		}
	},
	[clearFilters.toString()](draft: draft) {
		for (const filter of draft) {
			for (const entry of filter.entry) {
				entry.enabled = false;
			}
		}
		return draft;
	},
};

export default immerHandleActions(reducers, init);
