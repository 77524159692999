import React, { FunctionComponent, useState, useMemo, useEffect } from 'react';
import { IProductById } from '@ApiModels/productById';
import { PrimaryMediumButtonFlex } from '@Components/controls/Button/Button';
import Divider from '@Components/Divider/Divider';
import Icon from '@Components/Icon/Icon';
import { modalTypes, setModal } from '@Redux/modules/modal/actions';
import useTranslate from '@Utils/hooks/useTranslate';
import { useDispatch, useSelector } from 'react-redux';
import {
	CloseButtonContainer,
	Container,
	TitleWrapper,
	SubtitleWrapper,
	ButtonContainer,
	InsuranceDescriptionContainer,
	Instruction,
	ContainerInstruction,
	PaymentSubtitle,
	InstructionSubtitle,
	PaymentDetailsContainer,
	VatWrapper,
	Vat,
	PriceTitle,
	TopContainer,
	ContentWrapper,
	ScrollContent,
} from './AdditionalProduct.styled';
import SegmentSelector from '@Components/SegmentSelector/SegmentSelector';
import { ISegmentSize } from '@Components/controls/Segment/Segment';
import PriceTag from '@Components/PriceTag/PriceTag';
import { OrderFlowService } from '@Services/orderFlow/orderFlow';
import { errors } from '@Utils/api/errors/errors';
import { InventoryService } from '@Services/inventory/inventoryService';
import { addBusyIndicator, removeBusyIndicator } from '@Redux/modules/busyIndicator/actions';
import { IPriceByCatEntry } from '@ApiModels/priceByCatEntry';
import BusyIndicator from '@Components/BusyIndicator/BusyIndicator';
import { ItemType } from '@Services/productsService/productsService';
import { IAddItemsToCartPayload } from '@Services/orderFlow/orderFlow';
import ConditionalRender from '@Components/ConditionalRender/ConditionalRender';

const GET_PRICE_BY_CAT_ENTRY_INSURANCE_MODAL_BI = 'GET_PRICE_BY_CAT_ENTRY_INSURANCE_MODAL_BI';

export enum PaymentMethod {
	UPFRONT_PAYMENT = 'UPFRONT_PAYMENT',
	INSTALLMENTS_PAYMENT = 'INSTALLMENTS_PAYMENT',
}

const REQUIRED_PRODUCT = 'REQ_PROD';
const COM_PARAM_INSTALLMENT_VALUE = 'COM_PARAM_INSTALLMENT_VALUE';

const AdditionalProductModal: FunctionComponent<{
	additionalProduct: IProductById;
	isDeviceInsuranceInstallment: boolean;
	phoneNumber: string;
	planId: string;
	paymentMethod?: PaymentMethod;
}> = ({ additionalProduct, phoneNumber, planId, paymentMethod: devicePaymentMethod }) => {
	const dispatch = useDispatch();
	const { translate } = useTranslate();
	const [protectionPeriod, setProtectionPeriod] = useState<'12' | '24'>('12');
	const [paymentMethod, setPaymentMethod] = useState<PaymentMethod>(
		devicePaymentMethod ?? PaymentMethod.UPFRONT_PAYMENT
	);
	const [loading, setLoading] = useState(false);
	const [priceFromCatEntry, setPriceFromCatEntry] = useState<IPriceByCatEntry['price']>();
	const isB2b = useSelector((state: types.redux.IState) => state.api.authentication.isB2b);
	const isB2b2c = useSelector((state: types.redux.IState) => state.api.authentication.isB2b2c);
	const isBaqatiPlan = useSelector((state: types.redux.IState) => state.selectedItems.isBaqatiPlan);

	const { period12, period24 } = useMemo(() => {
		return { period12: !!additionalProduct.items?.[0], period24: !!additionalProduct.items?.[1] };
	}, [additionalProduct]);

	const { nonInstallmentItems, installmentItems } = useMemo(() => {
		const _installmentItems = additionalProduct.items?.filter((item) => {
			const requiredProductAttribute = item?.attributes?.find(
				(attribute) => attribute.identifier === REQUIRED_PRODUCT
			);
			const plansForInstallments = requiredProductAttribute?.values[0]?.value.split(',');
			const planForInstallment = plansForInstallments?.find((plan) => plan === planId);
			return !!planForInstallment;
		});
		const _nonInstallmentItems = additionalProduct.items?.filter((item) => {
			const requiredProductAttribute = item?.attributes?.find(
				(attribute) => attribute.identifier === REQUIRED_PRODUCT
			);
			return !requiredProductAttribute;
		});
		return {
			nonInstallmentItems: _nonInstallmentItems,
			installmentItems: _installmentItems,
		};
	}, [additionalProduct]);

	const selectedProduct = useMemo(() => {
		if (
			(protectionPeriod === '12' || protectionPeriod === '24') &&
			paymentMethod === PaymentMethod.UPFRONT_PAYMENT
		) {
			return nonInstallmentItems?.find((item) => item.duration === protectionPeriod);
		} else if (protectionPeriod === '12' && paymentMethod === PaymentMethod.INSTALLMENTS_PAYMENT) {
			return installmentItems?.find((item) => item.installmentPeriod === protectionPeriod);
		}
	}, [additionalProduct, protectionPeriod, paymentMethod, nonInstallmentItems, installmentItems]);

	const isDeviceInsuranceInstallment = useMemo(() => {
		const requiredProductAttribute = selectedProduct?.attributes?.find(
			(attribute) => attribute.identifier === REQUIRED_PRODUCT
		);
		const plansForInstallments = requiredProductAttribute?.values[0]?.value.split(',');
		const planForInstallment = plansForInstallments?.find((plan) => plan === planId);
		return !!planForInstallment && !isB2b && !isB2b2c;
	}, [planId, selectedProduct]);

	useEffect(() => {
		if (selectedProduct?.id) {
			dispatch(addBusyIndicator(GET_PRICE_BY_CAT_ENTRY_INSURANCE_MODAL_BI));
			InventoryService.getCatEntry({ catEntryId: selectedProduct.id }).subscribe(
				(response) => {
					setPriceFromCatEntry(response.price);
					dispatch(removeBusyIndicator(GET_PRICE_BY_CAT_ENTRY_INSURANCE_MODAL_BI));
				},
				() => {
					dispatch(removeBusyIndicator(GET_PRICE_BY_CAT_ENTRY_INSURANCE_MODAL_BI));
				}
			);
		}
	}, [selectedProduct]);

	const handleSetToMyOrder = () => {
		setLoading(true);
		let payload: IAddItemsToCartPayload = {
			quantity: '1',
			productId: selectedProduct?.id || '',
		};
		if (isDeviceInsuranceInstallment) {
			payload = {
				...payload,
				xitem_planAction: ItemType.DEVICE_INSURANCE_INSTALMENT,
				xitem_login: phoneNumber,
			};
		}
		OrderFlowService.addItemsToCart([payload]).subscribe(
			() => {
				setLoading(false);
				dispatch(setModal({ type: null, data: null }));
			},
			(e) => {
				setLoading(false);
				dispatch(setModal({ type: null, data: null }));
				if (e.response?.errors?.length > 0) {
					if (e.response?.errors[0]?.errorKey === errors.ITEM_OUT_OF_STOCK) {
						dispatch(
							setModal({
								type: modalTypes.GENERIC_ERROR,
								data: {
									icon: 'warning',
									iconFill: 'primary',
									title: translate('product.cart-card.modal.out-of-stock.title'),
									description: translate('product.cart-card.modal.out-of-stock.description'),
								},
							})
						);
					} else if (e.response?.errors[0]?.errorKey === errors.SESSION_GENERIC_USER) {
						dispatch(
							setModal({
								type: modalTypes.LOGIN_SESSION_EXPIRED,
								data: {},
							})
						);
					}
				}
			}
		);
	};

	const insuranceDescription = [
		{ number: 1, description: translate('insurance.description.1') },
		{ number: 2, description: translate('insurance.description.2') },
		{ number: 3, description: translate('insurance.description.3') },
		{ number: 4, description: translate('insurance.description.4') },
		{ number: 5, description: translate('insurance.description.5') },
		{ number: 6, description: translate('insurance.description.6') },
		{ number: 7, description: translate('insurance.description.7') },
	];

	return (
		<Container>
			<ContentWrapper>
				<TopContainer>
					<CloseButtonContainer>
						<Icon
							name="close"
							fill="black87"
							width={24}
							height={24}
							onClick={() => {
								dispatch(setModal({ type: null, data: null }));
							}}
						/>
					</CloseButtonContainer>
					<TitleWrapper>{translate('additional.product.modal.title')}</TitleWrapper>
					<Divider marginTop={16} />
				</TopContainer>
				<ScrollContent>
					<Divider withoutLine marginTop={16} />
					<SubtitleWrapper>{translate('additional.product.select.your.protection')}</SubtitleWrapper>
					<Divider marginBottom={16} withoutLine />
					<SegmentSelector<'12' | '24'>
						show={true}
						setMethod={setProtectionPeriod}
						leftDisabled={!period12}
						rightDisabled={
							!period24 ||
							(paymentMethod === PaymentMethod.INSTALLMENTS_PAYMENT && isDeviceInsuranceInstallment)
						}
						leftSegmentName={translate('additional.product.insurance', '12')}
						rightSegmentName={translate('additional.product.insurance', '24')}
						leftSegmentValue="12"
						rightSegmentValue="24"
						selectedValue={protectionPeriod}
						size={ISegmentSize.SMALL}
					/>
					<Divider marginBottom={16} withoutLine />
					<InsuranceDescriptionContainer>
						<SubtitleWrapper>{translate('insurance.description.title')}</SubtitleWrapper>
						<Divider marginBottom={16} withoutLine />
						{insuranceDescription.map(({ description, number }) => {
							return (
								<ContainerInstruction key={`instruction${number}`}>
									<Instruction>{`${number}. `}</Instruction>
									<Instruction padding>{description}</Instruction>
								</ContainerInstruction>
							);
						})}
						<InstructionSubtitle>
							{translate('select-protection-modal.instruction.subtitle')}
						</InstructionSubtitle>
					</InsuranceDescriptionContainer>
					<Divider withoutLine marginTop={16} />
					<ConditionalRender
						show={
							protectionPeriod !== '24' && !!planId && !!installmentItems && installmentItems.length > 0
						}
						onTrue={() => (
							<>
								<SubtitleWrapper>
									{translate('additional.product.how.would.you.like.to.pay')}
								</SubtitleWrapper>
								<Divider marginBottom={16} withoutLine />
								<SegmentSelector
									show={true}
									setMethod={setPaymentMethod}
									leftSegmentName={translate('additional.product.payment.upfront')}
									rightSegmentName={translate('additional.product.payment.installments')}
									leftSegmentValue={PaymentMethod.UPFRONT_PAYMENT}
									rightSegmentValue={PaymentMethod.INSTALLMENTS_PAYMENT}
									selectedValue={paymentMethod}
									size={ISegmentSize.SMALL}
								/>
							</>
						)}
					/>
					<PaymentDetailsContainer>
						<PriceTitle>
							{paymentMethod === PaymentMethod.INSTALLMENTS_PAYMENT
								? translate('payment-summary.installments-device', protectionPeriod)
								: translate('select-protection-modal.payment.upfront')}
						</PriceTitle>
						<VatWrapper>
							<BusyIndicator
								listener={[GET_PRICE_BY_CAT_ENTRY_INSURANCE_MODAL_BI]}
								skeleton="displayMBold"
							>
								<>
									<PriceTag
										price={
											paymentMethod === PaymentMethod.INSTALLMENTS_PAYMENT
												? Number(selectedProduct?.installmentValue || '0')
												: Number(priceFromCatEntry?.offerPrice || '0')
										}
										durationColor="black54"
										duration={
											paymentMethod === PaymentMethod.INSTALLMENTS_PAYMENT
												? translate('select-protection-modal.payment.mo')
												: ''
										}
									/>
									<Divider marginBottom={-8} withoutLine />
									<Vat>
										{translate(
											'select-protection-modal.payment.vat',
											priceFromCatEntry?.vatPercent ?? ''
										)}
									</Vat>
								</>
							</BusyIndicator>
						</VatWrapper>
					</PaymentDetailsContainer>
					<PaymentSubtitle>{translate('select-protection-modal.payment.subtitle')}</PaymentSubtitle>
					<Divider marginBottom={16} withoutLine />
				</ScrollContent>
				<ButtonContainer>
					<PrimaryMediumButtonFlex
						onClick={handleSetToMyOrder}
						listener={loading}
						uniqueId="select-protection-modal.select"
						disabled={loading}
					>
						{translate('select-protection-modal.select')}
					</PrimaryMediumButtonFlex>
				</ButtonContainer>
			</ContentWrapper>
		</Container>
	);
};

export default AdditionalProductModal;
