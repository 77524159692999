import Icon from '@Components/Icon/Icon';
import React, { FunctionComponent } from 'react';
import { DeliveryMethodContainer, IconWrapper, SubtitleWrapper, TitleWrapper } from './DeliveryMethod.styled';

const ICON_SIZE = 40;

const DeliveryMethod: FunctionComponent<types.cards.IDeliveryMethodCardProps> = ({
	params: { title, subtitle, icon, selected, disabled },
	emmitEvent,
}) => {
	return (
		<DeliveryMethodContainer
			selected={selected}
			onClick={() => {
				!disabled && emmitEvent?.('click');
			}}
			disabled={disabled}
		>
			<IconWrapper>
				<Icon name={icon} width={ICON_SIZE} height={ICON_SIZE} fill={disabled ? 'black24' : 'primary'} />
			</IconWrapper>
			<TitleWrapper>{title}</TitleWrapper>
			<SubtitleWrapper>{subtitle}</SubtitleWrapper>
		</DeliveryMethodContainer>
	);
};

export default DeliveryMethod;
