import React from 'react';
import { useInsurance } from '@Utils/context/InsuranceContext';
import useTranslate from '@Utils/hooks/useTranslate';
import Icon from '@Components/Icon/Icon';
import {
	InfoWrapper,
	ParagraphMBoldWrapper,
	ParagraphSWrapper,
	BodyMWrapper,
	IconWrapper,
	VehicleDetailsWrapper,
	VehicleDetailsCardWrapper,
} from './InsuranceVehicleDetails.styled';

const InsuranceVehicleDetailsCard = (): JSX.Element => {
	const { vehicleInformation } = useInsurance();

	const { translate } = useTranslate();

	return (
		<>
			<VehicleDetailsCardWrapper>
				<IconWrapper>
					<Icon name="vehicle" width="40" height="40" fill="black54" />
				</IconWrapper>
				<VehicleDetailsWrapper>
					<ParagraphMBoldWrapper>{`${vehicleInformation?.manufacturer}, ${vehicleInformation?.model}`}</ParagraphMBoldWrapper>
					<ParagraphSWrapper>{`${vehicleInformation?.vehiclePlateCharCode}/${vehicleInformation?.vehicleNo}`}</ParagraphSWrapper>
					<ParagraphSWrapper>
						{vehicleInformation?.numberOfPassengers}
						{translate('insurance.vehicle.seats')}
					</ParagraphSWrapper>
					<ParagraphSWrapper>
						{vehicleInformation?.numberOfDoors}
						{translate('insurance.vehicle.doors')}
					</ParagraphSWrapper>
					<ParagraphSWrapper>
						{vehicleInformation?.mfgYear}
						{translate('insurance.vehicle.mfg.year')}
					</ParagraphSWrapper>
					<ParagraphSWrapper>
						{vehicleInformation?.classificationTitle}
						{translate('vehicle-details.clasification')}
					</ParagraphSWrapper>
				</VehicleDetailsWrapper>
			</VehicleDetailsCardWrapper>
			<InfoWrapper>
				<IconWrapper>
					<Icon name="info" width="24" height="24" fill="black54" />
				</IconWrapper>
				<BodyMWrapper>{translate('insurance.vehicle.details.info')}</BodyMWrapper>
			</InfoWrapper>
		</>
	);
};

export default InsuranceVehicleDetailsCard;
