import React, { FunctionComponent } from 'react';
import Card from '@Components/Card/Card';

const ModalImagePreviewCard: FunctionComponent<types.cards.IImagePreviewCardProps['params']> = ({
	images,
	selectedImageIndex,
	availableColors,
	pickedColor,
	backgroundTitle,
}) => {
	return (
		<Card<types.cards.IImagePreviewCardProps>
			card="imagePreview"
			params={{ images, selectedImageIndex, availableColors, pickedColor, backgroundTitle }}
			withoutContainerPadding
		/>
	);
};

export default ModalImagePreviewCard;
