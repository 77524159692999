import styled from 'styled-components';
import { BodyM, BodyMBold } from '@Components/Typography/Typography';
import theme from '@Utils/theme/theme';
import { marginRight } from '@Utils/layout/layoutSupport';
interface IRadioButtonContainerProps {
	selected: boolean;
	disabled: boolean;
	color?: types.theme.themeColors;
}
interface IContainerProps {
	marginRight: number;
	marginBottom: number;
}

export const RadioButtonWrapper = styled.div<IContainerProps>`
	display: flex;
	flex-direction: row;
	align-items: center;
	cursor: pointer;
	${({ marginRight, marginBottom }) => {
		return { marginRight, marginBottom };
	}};
`;

export const Label = styled(BodyM)<{ fontColor?: types.theme.themeColors }>`
	${({ theme, fontColor = 'black' }) => ({ fontColor: theme.colors[fontColor] })};
`;

export const LabelSelected = styled(BodyMBold)<{ fontColor?: types.theme.themeColors }>`
	${({ theme, fontColor = 'black' }) => ({ fontColor: theme.colors[fontColor] })};
`;

export const Mark = styled.span<IRadioButtonContainerProps>`
	display: inline-block;
	position: relative;
	width: 20px;
	height: 20px;
	box-sizing: border-box;
	left: 0;
	border-radius: 50%;
	vertical-align: middle;
	&::after {
		content: '';
		display: block;
		width: 0;
		height: 0;
		border-radius: 50%;
		${({ theme, color }) => ({
			backgroundColor: color ? theme.colors[color] : theme.colors.primary,
		})};
		opacity: 0;
		left: 50%;
		top: 50%;
		position: absolute;
		transition: all 0.1s ease-in-out;
	}
	${() => marginRight('5px')};

	${({ selected, disabled, color }) =>
		!disabled
			? {
					background: selected ? 'transparent' : theme.colors.white,
					border: selected
						? ` 2px solid ${color ? theme.colors[color] : theme.colors.primary}`
						: `thin solid ${theme.colors.black6}`,
			  }
			: {
					background: selected ? 'transparent' : theme.colors.black12,
					border: selected ? ` 2px solid ${theme.colors.black12}` : `thin solid ${theme.colors.black24}`,
			  }}
	&:hover {
		&::after {
			marginRight
		}
	}
	&:focus,
	:active {
		&::after {
			${({ disabled }) =>
				!disabled && {
					background: theme.colors.primary15,
					opacity: 1,
				}}
		}
	}
`;

export const Input = styled.input`
	position: absolute;
	visibility: hidden;
	display: none;
	&:checked + ${Mark} {
		&::after {
			width: 10px;
			height: 10px;
			opacity: 1;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
		}
	}
`;
