import { BodyS, ParagraphMBold } from '@Components/Typography/Typography.styled';
import styled from 'styled-components';

export type LineStatus = 'done' | 'inProgress' | 'waiting';

export const TrackingStepperContainer = styled.div``;

export const StatusContainer = styled.div`
	display: flex;
`;

export const StatusWrapper = styled(ParagraphMBold)`
	${({ theme }) => ({
		color: theme.colors.black87,
	})};
	margin: 4px 4px 0 4px;
`;

export const IconContainer = styled.div<{ backgroundColor?: types.theme.themeColors; inactive?: boolean }>`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 20px;
	height: 20px;
	margin: 4px 6px 6px 6px;
	border-radius: 50%;
	${({ theme, backgroundColor = 'transparent' }) => ({
		backgroundColor: theme.colors[backgroundColor],
	})};
	${({ inactive, theme }) => inactive && `border: 3px solid ${theme.colors.black24}; width: 14px; height: 14px`}
`;

export const DescriptionWrapper = styled(BodyS)<{ withMargin?: boolean }>`
	${({ theme }) => ({
		color: theme.colors.black54,
	})};
	${({ withMargin }) => (withMargin ? 'margin: 0 36px' : 'margin: 0 4px')}
`;

export const LineContainer = styled.div`
	width: 20px;
	display: flex;
	margin: 0 6px;
	justify-content: center;
`;

export const Line = styled.div<{ status: LineStatus }>`
	border-width: 0 0 0 1px;
	border-style: solid;
	${({ status, theme }) => {
		switch (status) {
			case 'done': {
				return `border-color: ${theme.colors.positive}`;
			}
			case 'inProgress': {
				return `border-color: ${theme.colors.primary}`;
			}
			case 'waiting': {
				return `border-style: dashed; border-color ${theme.colors.black24}`;
			}
		}
	}};
	height: 88px;
`;

export const DescriptionContainer = styled.div<{ isLast?: boolean }>`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	${({ isLast }) => `height: ${isLast ? '32px' : '92px'}`}
`;
