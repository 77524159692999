import { CaptionBigger, DisplayMBoldH1 } from '@Components/Typography/Typography.styled';
import styled from 'styled-components';

export const Title = styled(DisplayMBoldH1)<{ isLogged?: boolean }>`
	${({ theme, isLogged }) => ({
		color: theme.colors.primary,
		marginTop: isLogged ? 228 : 188,
	})}
	margin-bottom: 32px;
`;

export const CaptionWrapper = styled(CaptionBigger)`
	${({ theme }) => ({
		color: theme.colors.black54,
	})}
`;
