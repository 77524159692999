import { IKeyFeaturesItem, IProductByIdItem } from '@ApiModels/productById';
import { IItem } from '@ApiModels/productsByCategoryId';
import BusyIndicator from '@Components/BusyIndicator/BusyIndicator';
import { PriceType, ProductType, TileSize } from '@Components/Card/cards/ProductCard/ProductCard.styled';
import GridProductCard from '@Components/Card/cards/ProductCard/subcomponents/GridProductCard/GridProductCard';
import { IPricesWithVat } from '@Components/Card/cards/ProductCard/subcomponents/PriceSection/PriceSection';
import ComparisonOverflowBar from '@Components/ComparisonOverflowBar/ComparisonOverflowBar';
import { SecondaryMediumButtonAuto } from '@Components/controls/Button/Button';
import PrimaryToggle from '@Components/controls/Toggle/Toggle';
import { addBusyIndicator, removeBusyIndicator } from '@Redux/modules/busyIndicator/actions';
import { addToCompare, updateThumbnail } from '@Redux/modules/comparison/actions';
import { ProductsService } from '@Services/productsService/productsService';
import useTranslate from '@Utils/hooks/useTranslate';
import { getLang } from '@Utils/language/language';
import React, { FunctionComponent, useEffect, useRef, useState, useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import useScreen from '@Utils/hooks/useScreen';
import {
	ComparisonItemsContainer,
	HighlightText,
	ComparisonRow,
	SubheaderWrapper,
	SwitchContainer,
	TitleWrapper,
	HeadlineWrapper,
	KeyText,
	ComparisonTableContainer,
	ComparisonDivider,
	ValueText,
	ValuesColumns,
	ProductCardWrapper,
	ItemColWrapper,
	ComparisonContainer,
	DifferenceColorWrapper,
	TABLE_CONTAINER_WIDTH,
	LimitationText,
} from './Comparison.styled';
import ConditionalRender from '@Components/ConditionalRender/ConditionalRender';
import { matchScreen } from '@Utils/layout/layoutSupport';

interface IComparisonProps {
	paddingLeft: number;
}

interface IComparisonKey {
	text: string;
	attribute: string;
}

const COMPARISON_PRODUCTS_BI = 'getComparisonProducts';

export interface IProduct {
	product: IItem;
	keyFeatures: IKeyFeaturesItem[];
	pricesWithVat: IPricesWithVat;
}

const Comparison: FunctionComponent<IComparisonProps> = ({ paddingLeft }) => {
	const { translate } = useTranslate();
	const [highlightDifferences, setHighlightDifferences] = useState(false);
	const [products, setProducts] = useState<IProduct[]>([]);
	const [height, setHeight] = useState(400);
	const keyFeaturesRowRef = useRef<HTMLDivElement>(null);
	const valuesColRef = useRef<HTMLDivElement>(null);
	const comparisonContainerRef = useRef<HTMLDivElement>(null);
	const { search } = useLocation();
	const query = new URLSearchParams(search);
	const history = useHistory();
	const comparisonIds = query.get('id')?.split(',');
	const [leftPosition, setLeftPosition] = useState(0);
	const [topPosition, setTopPosition] = useState(0);
	const comparisonList: types.redux.IState['comparisonList'] = useSelector(
		(state: types.redux.IState) => state.comparisonList
	);
	const { screen } = useScreen();
	const dispatch = useDispatch();
	const overflowEnabled = false; // screen(['sm', 'md']);
	const isXS = screen('xs');
	const isSmallDevice = matchScreen(['xs', 'sm']);
	const isLogged = useSelector((state: types.redux.IState) => state.api.authentication.signedIn);

	useEffect(() => {
		if (comparisonIds?.length) {
			comparisonIds.forEach((id) => {
				if (!comparisonList.find(({ id: _id }) => _id === id)) {
					dispatch(addToCompare({ id, thumbnailUrl: '' }));
				}
			});
		}
	}, []);

	useEffect(() => {
		if (comparisonList?.length) {
			const idList = comparisonList.map((item) => item.id);
			dispatch(addBusyIndicator(COMPARISON_PRODUCTS_BI));
			ProductsService.getProductsByIdList(idList).subscribe(
				(products) => {
					const getPricesWithVat = (items?: IProductByIdItem[]) => {
						const lowestPriceItem = items?.sort((a, b) => (a.priceOffer < b.priceOffer ? -1 : 0))[0];
						return {
							offerPrice: String(lowestPriceItem?.priceOffer ?? 0),
							listPrice: String(lowestPriceItem?.priceDisplay ?? 0),
							taxablePrice: '0',
							vatValue: '0',
							vatPercent: String(lowestPriceItem?.vatPercent ?? 0),
						};
					};
					setProducts(
						products.map(({ item, items, keyFeatures = [] }) => {
							if (
								!comparisonList.find(({ id }) => item.uniqueID === id)?.thumbnailUrl &&
								item.thumbnail
							) {
								dispatch(updateThumbnail({ id: item.uniqueID, thumbnailUrl: item.thumbnail }));
							}
							return {
								product: item,
								keyFeatures,
								pricesWithVat: getPricesWithVat(items),
							};
						})
					);
				},
				() => {},
				() => {
					dispatch(removeBusyIndicator(COMPARISON_PRODUCTS_BI));
				}
			);
		} else if (!comparisonIds) {
			history.goBack();
		}
	}, [comparisonList]);

	const comparisonKeys = useMemo(() => {
		const keys: IComparisonKey[] = [];
		products.forEach(({ keyFeatures }) => {
			keyFeatures.forEach((key) => {
				if (!keys.find((el) => el.attribute === key.id)) {
					keys.push({
						text: key.title,
						attribute: key.id ?? '',
					});
				}
			});
		});
		return keys;
	}, [products]);

	const setRef = useCallback((node: any) => {
		setHeight(node?.offsetHeight ?? 0);
	}, []);

	const checkDifference = (key: IComparisonKey['attribute']) => {
		let checkValue = false;
		products.forEach(({ keyFeatures }, index) => {
			if (
				products[index + 1] &&
				keyFeatures.find(({ id }) => id === key)?.value !==
					products[index + 1]?.keyFeatures?.find(({ id }) => id === key)?.value
			) {
				checkValue = true;
			}
		});
		return checkValue;
	};

	useEffect(() => {
		const scrollListener = () => {
			setLeftPosition(window.scrollX);
			setTopPosition(window.scrollY);
		};

		window.addEventListener('scroll', scrollListener, true);
		window.addEventListener('wheel', scrollListener, true);

		return () => {
			window.removeEventListener('scroll', scrollListener);
			window.removeEventListener('wheel', scrollListener);
		};
	}, [overflowEnabled]);

	const title = useMemo(
		() => (
			<ComparisonRow $paddingLeft={paddingLeft} height={isXS ? 0 : height} background="background">
				<SubheaderWrapper isXS={isXS}>{translate('comparison.compare')}</SubheaderWrapper>
				<TitleWrapper>{translate('comparison.title')}</TitleWrapper>
				<SwitchContainer>
					<PrimaryToggle
						active={highlightDifferences}
						onChange={() => {
							setHighlightDifferences(!highlightDifferences);
						}}
					/>
					<HighlightText>{translate('comparison.differences')}</HighlightText>
				</SwitchContainer>
				<SecondaryMediumButtonAuto
					onClick={() => {
						history.push(`/${getLang()}/store/Smartphones`);
					}}
					uniqueId="comparison.select-products"
					underlayColor="transparent"
				>
					{translate('comparison.button.select-products')}
				</SecondaryMediumButtonAuto>
				<LimitationText>{translate('comparison.limitation')}</LimitationText>
			</ComparisonRow>
		),
		[paddingLeft, height, highlightDifferences, isXS, isLogged]
	);

	const keyFeatures = useMemo(
		() => (
			<>
				{title}
				<BusyIndicator listener={COMPARISON_PRODUCTS_BI} withoutLoader>
					<>
						<ComparisonRow
							$paddingLeft={paddingLeft}
							className="row"
							ref={keyFeaturesRowRef}
							bottomBorder
							background="white"
						>
							<HeadlineWrapper>{translate('comparison.key-features')}</HeadlineWrapper>
						</ComparisonRow>
						{comparisonKeys.map((key, index) => (
							<ComparisonRow $paddingLeft={paddingLeft} key={index} className="row">
								<KeyText>{key.text}</KeyText>
							</ComparisonRow>
						))}
					</>
				</BusyIndicator>
			</>
		),
		[comparisonKeys, height, paddingLeft, highlightDifferences]
	);

	return (
		<>
			<ConditionalRender show={isXS} onTrue={() => title} />
			<ComparisonContainer ref={comparisonContainerRef}>
				<ComparisonOverflowBar
					highlightDifferences={highlightDifferences}
					setHighlightDifferences={(val) => {
						setHighlightDifferences(val);
					}}
					products={products}
					top={topPosition >= (isXS ? 680 : 340) ? (isLogged ? 136 : 96) : isSmallDevice ? -56 : -24}
					overflowEnabled={overflowEnabled}
					leftPosition={leftPosition}
					paddingLeft={paddingLeft}
				/>
				<ConditionalRender
					show={!isXS}
					onTrue={() => (
						<ComparisonTableContainer
							width={paddingLeft + TABLE_CONTAINER_WIDTH}
							shadowWidth={overflowEnabled ? (leftPosition / 2 > 4 ? 4 : leftPosition / 2) : 0}
							top={overflowEnabled ? topPosition : 0}
							overflowEnabled={overflowEnabled}
						>
							{keyFeatures}
						</ComparisonTableContainer>
					)}
				/>
				<BusyIndicator
					listener={COMPARISON_PRODUCTS_BI}
					skeleton="comparisonCards"
					skeletonProps={{ marginLeft: paddingLeft + TABLE_CONTAINER_WIDTH + 8 }}
				>
					<ValuesColumns ref={valuesColRef}>
						{products.map(({ product, keyFeatures, pricesWithVat }, index) => (
							<ItemColWrapper
								$paddingLeft={index === 0 && !isXS ? paddingLeft + TABLE_CONTAINER_WIDTH : 0}
								key={`tel-${index}`}
							>
								<ProductCardWrapper ref={index === 0 ? setRef : null}>
									<GridProductCard
										product={product}
										thumbnailUrl={product.thumbnail}
										tileSize={TileSize.BIG}
										productType={ProductType.AVAILABLE}
										priceType={PriceType.GROUP}
										availableColors={[]}
										handleSeeDetails={() => {
											history.push(`/product${product.seo}`);
										}}
										pricesWithVat={pricesWithVat}
										withoutColorPicker
										withContainer
										comparisonView
									/>
								</ProductCardWrapper>
								<ComparisonItemsContainer>
									<ComparisonDivider height={keyFeaturesRowRef.current?.offsetHeight} />
									{comparisonKeys.map(({ attribute, text }, _index) => (
										<ComparisonRow key={_index} className="row" withoutPadding>
											<DifferenceColorWrapper
												isDiffrent={highlightDifferences && checkDifference(attribute)}
												isXS={isXS}
											>
												<ConditionalRender
													show={!!isXS}
													onTrue={() => <KeyText>{text}</KeyText>}
												/>
												<ValueText>
													{keyFeatures.find(({ id = '' }) => id === attribute)?.value ?? '-'}
												</ValueText>
											</DifferenceColorWrapper>
										</ComparisonRow>
									))}
								</ComparisonItemsContainer>
							</ItemColWrapper>
						))}
						<ItemColWrapper $paddingLeft={0} fullWidth>
							<ComparisonRow height={height} background="background" />
							<ComparisonItemsContainer>
								<ComparisonDivider height={keyFeaturesRowRef.current?.offsetHeight} />
								{comparisonKeys.map(({ attribute }, _index) => (
									<ComparisonRow key={_index} className="row" withoutPadding>
										<DifferenceColorWrapper
											isDiffrent={highlightDifferences && checkDifference(attribute)}
										>
											<ValueText hideText> - </ValueText>
										</DifferenceColorWrapper>
									</ComparisonRow>
								))}
							</ComparisonItemsContainer>
						</ItemColWrapper>
					</ValuesColumns>
				</BusyIndicator>
			</ComparisonContainer>
		</>
	);
};

export default Comparison;
