import { BodyS, BodySBold, ParagraphS, ParagraphSBold, ValueBold } from '@Components/Typography/Typography.styled';
import { marginLeft, matchScreen, right, paddingRight, paddingLeft } from '@Utils/layout/layoutSupport';
import styled from 'styled-components';

export const ShippingMethodCardContainer = styled.div`
	padding: 16px;
	${() => ({
		...paddingRight(matchScreen('xs') ? '0px' : '16px'),
		...paddingLeft(matchScreen('xs') ? '0px' : '16px'),
		...marginLeft(matchScreen('xs') ? '-4px' : '0'),
	})}
`;

export const TitleWrapper = styled(ValueBold)`
	${({ theme }) => ({
		color: theme.colors.black87,
	})}
`;

export const DescriptionWrapper = styled(BodyS)`
	${({ theme }) => ({
		color: theme.colors.black54,
	})}
	margin-top: 8px;
	margin-bottom: 24px;
`;

export const RadioButtonWrapper = styled.div`
	margin-bottom: 16px;
	${() => marginLeft('-15px')}
`;

export const AddressBookElement = styled.div`
	padding: 16px;
	border-radius: 8px;
	margin-bottom: 24px;
	${({ theme }) => ({
		backgroundColor: theme.colors.grey50,
	})}
`;

export const Horizontal = styled.div`
	display: flex;
	overflow-wrap: break-word;
	${() => ({
		flexDirection: matchScreen(['xs', 'sm']) ? 'column' : 'row',
	})}
`;

export const Underline = styled(BodySBold)`
	text-decoration: underline;
	cursor: pointer;
`;

export const Separator = styled.div`
	width: 16px;
`;

export const ToggleWrapper = styled.div`
	display: flex;
	position: absolute;
	flex-direction: column;
	${() => right(36)}
`;

export const ToggleText = styled.div`s
	padding: 6px;
`;

export const PickupInfo = styled(ParagraphS)`
	${({ theme }) => ({
		color: theme.colors.black54,
	})};
	display: flex;
	flex-wrap: wrap;
	margin-top: 16px;
`;

export const BoldText = styled(ParagraphSBold)`
	${({ theme }) => ({
		color: theme.colors.black54,
	})};
	margin: 0 4px;
`;

export const ShippingInfoLabelWrapper = styled.div`
	margin-left: -85px;

	${({}) => ({
		marginLeft: matchScreen('xl')
			? '-85px'
			: matchScreen('lg') || matchScreen('md')
			? '-75px'
			: matchScreen('sm')
			? '-58px'
			: '-16px',
	})};
`;

export const PickupSelectorContainer = styled.div`
	display: flex;
	align-items: center;
`;

export const PickupOrderInfo = styled(ParagraphS)`
	${({ theme }) => ({
		color: theme.colors.black54,
	})};
	width: 50%;
`;

export const AddressTitle = styled(ValueBold)`
	${({ theme }) => ({
		color: theme.colors.black87,
	})}
	max-width:90%;
`;

export const OutletLocalisationWrapper = styled.div`
	margin-top: 16px;
	${() => paddingRight('16px')}
`;
