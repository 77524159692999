import { IWhatsInTheBoxItem } from '@ApiModels/productById';
import React, { FunctionComponent } from 'react';
import BoxContent from './BoxContent/BoxContent';
import { WhatsInTheBoxContainer } from './WhatsInTheBox.styled';

interface IWhatsInTheBox {
	items: IWhatsInTheBoxItem[];
	marginTop?: number;
	marginBottom?: number;
}

const WhatsInTheBox: FunctionComponent<IWhatsInTheBox> = ({ items, marginTop = 0, marginBottom = 0 }) => {
	return (
		<WhatsInTheBoxContainer marginTop={marginTop} marginBottom={marginBottom}>
			{items.map((item, index) => {
				if (item.visible) {
					return <BoxContent key={index} name={item.name} img={item.img} />;
				}
			})}
		</WhatsInTheBoxContainer>
	);
};

export default WhatsInTheBox;
