export interface IReservationModel {
	reservationId: string;
	bonuAmount: number | null;
	voucherExpirationDate: string | null;
}

const model: types.models.IInputModel = {
	voucherReservationResponse: ['response', {}],
};

export default model;
