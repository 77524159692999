import React, { useEffect, useState } from 'react';
import { useInsurance, INSURANCE_TYPE } from '@Utils/context/InsuranceContext';
import useTranslate from '@Utils/hooks/useTranslate';
import { InsuranceTitle, InsuranceSubtitle, Hint } from '@Utils/styles/cardStyles';
import { ICON_SIZE } from '@Config/app.config';
import { Row, Col } from 'react-grid-system';
import Icon from '@Components/Icon/Icon';
import TextField from '@Components/controls/TextField/TextField';
import { useFormikContext } from 'formik';
import { IFormikCarInsuranceFields } from '@Views/CarInsurance/CarInsurance';
import ConditionalRender from '@Components/ConditionalRender/ConditionalRender';
import { IDropdownItem, RegularDropdown } from '@Components/controls/Dropdown/Dropdown';
import { DropdownHeight, LabelType } from '@Components/controls/Dropdown/Dropdown.styled';
import { SectionHeadContainer, SectionContentContainer } from '@Views/CarInsurance/CarInsurance.styled';
import { isLargeScreen, isSmallScreen } from '@Utils/layout/layoutSupport';

const InsuranceVehicleCard = (): JSX.Element => {
	const {
		vehicleNumber,
		setVehicleNumber,
		vehicleValue,
		setVehicleValue,
		setChars,
		allPlateChars,
		insuranceType,
		plateDropdownItem,
		setPlateDropdownItem,
		inputFiltering,
		setInputFiltering,
	} = useInsurance();
	const { translate } = useTranslate();
	const { touched, errors, setFieldTouched, setFieldValue } = useFormikContext<IFormikCarInsuranceFields>();
	const [plateChars, setPlateChars] = useState<IDropdownItem[]>([]);

	useEffect(() => {
		if (allPlateChars?.chars)
			setPlateChars(
				allPlateChars.chars.map((item) => {
					return { text: item.name, id: item.code } as IDropdownItem;
				})
			);
	}, [allPlateChars]);

	return (
		<>
			<SectionHeadContainer>
				<Icon name="insuranceVehicle" width={ICON_SIZE} height={ICON_SIZE} fill="black54" />
				<InsuranceTitle>{translate('insurance.vehicle.title')}</InsuranceTitle>
			</SectionHeadContainer>
			<SectionContentContainer isLargeScreen={isLargeScreen()} isSmallScreen={isSmallScreen()}>
				<InsuranceSubtitle>{translate('insurance.vehicle.plates')}</InsuranceSubtitle>
				<Row>
					<Col xs={4}>
						<RegularDropdown
							dropdownHeight={DropdownHeight.SCROLL}
							inputFiltering={true}
							inputValue={inputFiltering}
							setInputValue={(value: string) => (
								setInputFiltering(value), setFieldTouched('input', true)
							)}
							items={plateChars}
							setSelectedItem={(selectedItem: IDropdownItem) => {
								setPlateDropdownItem(selectedItem);
								setFieldTouched('chars', true);
								setFieldValue('chars', selectedItem.text);
								setChars(selectedItem.text);
							}}
							selectedItem={plateDropdownItem}
							label={translate('insurance.vehicle.plates.chars.placeholder')}
							labelType={LabelType.FLOATING}
							message={
								!!errors.chars && touched.inputFiltering
									? translate('insurance.vehicle.plates.chars.validation.error')
									: ''
							}
							error={!!errors.chars && touched.inputFiltering}
							placeholder={translate('insurance.vehicle.plates.chars.placeholder')}
							overflow
						/>
					</Col>
					<Col>
						<TextField
							id="vehicleNumber"
							label={translate('insurance.vehicle.plates.number.placeholder')}
							placeholder={translate('insurance.vehicle.plates.number.placeholder')}
							value={vehicleNumber}
							onChange={(value) => {
								setFieldTouched('vehicleNumber', true);
								setFieldValue('vehicleNumber', value.toString());
								setVehicleNumber(value.toString());
							}}
							marginBottom={8}
							message={
								!!errors.vehicleNumber && touched.vehicleNumber
									? translate('insurance.vehicle.value.validation.error')
									: ''
							}
							error={!!errors.vehicleNumber && touched.vehicleNumber}
							width="100%"
							name="vehicleNumber"
						/>
					</Col>
				</Row>
				<ConditionalRender
					show={insuranceType === INSURANCE_TYPE.FULL_COMPREHENSIVE}
					onTrue={() => (
						<Row>
							<Col>
								<InsuranceSubtitle>{translate('insurance.vehicle.value')}</InsuranceSubtitle>
								<TextField
									id="vehicleValue"
									label={translate('insurance.vehicle.value.placeholder')}
									placeholder={translate('insurance.vehicle.value.placeholder')}
									value={vehicleValue}
									onChange={(value) => {
										setVehicleValue(value.toString());
										setFieldTouched('vehicleValue', true);
										setFieldValue('vehicleValue', value.toString());
									}}
									marginBottom={8}
									message={
										!!errors.vehicleValue && touched.vehicleValue
											? translate('insurance.vehicle.value.validation.error')
											: ''
									}
									error={!!errors.vehicleValue && touched.vehicleValue}
									width="100%"
									name="vehicleValue"
								/>
								<ConditionalRender
									show={!errors.vehicleValue}
									onTrue={() => <Hint>{translate('insurance.vehicle.value.hint')}</Hint>}
								/>
							</Col>
						</Row>
					)}
				/>
			</SectionContentContainer>
		</>
	);
};

export default InsuranceVehicleCard;
