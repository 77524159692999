export const cardNumberFormat = (value: string): string => {
	const v = value.replace(/\s+/g, '').replace(/[^0-9]/gi, '');
	const matches = v.match(/\d{4,16}/g);
	const match = (matches && matches[0]) || '';
	const parts = [];

	for (let i = 0, len = match.length; i < len; i += 4) {
		parts.push(match.substring(i, i + 4));
	}
	return parts.join(' ') || value;
};

export const expDateFormat = (value: string): string => {
	const v = value.replace(/\s+/g, '').replace(/[^0-9]/gi, '');
	const matches = v.match(/\d{2,4}/g);
	const match = (matches && matches[0]) || '';
	const parts = [];

	for (let i = 0, len = match.length; i < len; i += 2) {
		parts.push(match.substring(i, i + 2));
	}
	return parts.join('/') || value;
};

export const cvvFormat = (value: string): string => {
	return value.replace(/\s+/g, '').replace(/[^0-9]/gi, '');
};

export const nameFormat = (value: string): string => {
	return value.replace(/[^a-z0-9 ]/gi, '');
};

export const displayCardNumber = (value: string): string => {
	return `···· ${value.slice(value.length - 4, value.length)}`;
};

export const getCardType = (cardNumber: string): 'visa' | 'mastercard' | 'AMERICAN_EXPRESS' | undefined => {
	const visa = new RegExp('^4[0-9]{12}(?:[0-9]{3})?$');
	const mastercard = new RegExp('^5[1-5][0-9]{14}$');
	const mastercard2 = new RegExp('^2[2-7][0-9]{14}$');
	const amex = new RegExp('^3[47][0-9]{13}$');
	if (visa.test(cardNumber.replaceAll(' ', ''))) {
		return 'visa';
	}
	if (mastercard.test(cardNumber.replaceAll(' ', '')) || mastercard2.test(cardNumber.replaceAll(' ', ''))) {
		return 'mastercard';
	}
	if (amex.test(cardNumber.replaceAll(' ', ''))) {
		return 'AMERICAN_EXPRESS';
	}
};
