import { matchScreen } from '@Utils/layout/layoutSupport';
import styled from 'styled-components';

export enum TabsVariant {
	PRIMARY = 'PRIMARY',
	SECONDARY = 'SECONDARY',
}

export enum LabelWidth {
	FIXED = 'FIXED',
	AUTO = 'AUTO',
}

interface ITabsHeaderProps {
	variant: TabsVariant;
	justifyContent: types.justifyContent;
}

export const TabsWrapper = styled.div``;

export const TabsHeader = styled.div<ITabsHeaderProps>`
	display: flex;
	align-items: center;
	::-webkit-scrollbar {
		display: none;
	}
	${({ justifyContent }) => ({ justifyContent })}
	${({ variant }) => variant === TabsVariant.SECONDARY && { margin: '0 -4px' }}
	${() => {
		return {
			overflowX: matchScreen(['xs', 'sm']) ? 'scroll' : 'hidden',
		};
	}}
`;

export const ContentWrapper = styled.div``;
