import React, { FunctionComponent } from 'react';
import { SkeletonCardContainer, Rectangular, SkeletonRow } from '@Components/Skeleton/Skeleton.styled';

const Category: FunctionComponent = () => {
	const ITEM_HEIGHT = 20;

	return (
		<SkeletonRow marginTop={18} marginBottom={8}>
			<SkeletonCardContainer>
				<SkeletonRow marginTop={8} marginBottom={-8}>
					<Rectangular width="80%" height={ITEM_HEIGHT} marginBottom={16} />
					<Rectangular width="50%" height={ITEM_HEIGHT} marginBottom={16} />
					<Rectangular width="70%" height={ITEM_HEIGHT} marginBottom={16} />
				</SkeletonRow>
			</SkeletonCardContainer>
		</SkeletonRow>
	);
};

export default Category;
