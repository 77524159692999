import React, { FunctionComponent } from 'react';
import Card from '@Components/Card/Card';
import { LoginModalContainer } from './Login.styled';

const ModalLoginCard: FunctionComponent<types.cards.ILoginCardProps['params']> = (params) => {
	return (
		<LoginModalContainer>
			<Card<types.cards.ILoginCardProps> card="login" params={params} withoutContainerPadding />
		</LoginModalContainer>
	);
};

export default ModalLoginCard;
