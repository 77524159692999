import styled from 'styled-components';
import { Subheading } from '@Components/Typography/Typography';

export const OwnerNameTitle = styled(Subheading)`
	text-transform: uppercase;
	margin-bottom: 14px;
	${({ theme }) => {
		return {
			color: theme.colors.black54,
		};
	}};
`;
