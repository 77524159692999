import React, { FunctionComponent } from 'react';
import { CardWrapper, Title } from './Description.styled';
import { Row } from 'react-grid-system';
import { BodyM } from '@Components/Typography/Typography';
import useTranslate from '@Utils/hooks/useTranslate';

const Description: FunctionComponent<types.cards.IDescriptionCardProps> = ({
	params: { title, description },
}): JSX.Element => {
	const { translate } = useTranslate();

	return (
		<CardWrapper>
			<Row>
				<Title textTransform="uppercase" color="black87">
					{title || translate('short.description.about.title')}
				</Title>
			</Row>
			<Row>
				<BodyM color="black87">{description}</BodyM>
			</Row>
		</CardWrapper>
	);
};

export default Description;
