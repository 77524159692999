import styled from 'styled-components';
import { ParagraphM } from '@Components/Typography/Typography';

export const IconWrapper = styled.div`
	margin-bottom: 20px;
`;

export const CardElementsWraper = styled.div`
	box-shadow: inset 0px 134px 0px 0px #f4f8ec;
	padding: 16px;
`;

export const InsuranceDescription = styled(ParagraphM)`
	${({ theme }) => {
		return {
			color: theme.colors.black54,
			marginBottom: '15px',
		};
	}}
`;
