import React, { FunctionComponent } from 'react';
import { AppleLobMenuItem, AppleLobMenuItemTitle } from './AppleLobMenuItem.styled';
import useTranslate from '@Utils/hooks/useTranslate';
import useScreen from '@Utils/hooks/useScreen';
import Image from '@Components/Image/Image';
import { getLang } from '@Utils/language/language';
import { useHistory } from 'react-router-dom';
import { BodyMBold, BodySBold } from '@Components/Typography/Typography.styled';

const AppleLobMenuItemCard: FunctionComponent<types.cards.IAppleLobMenuItemProps> = ({
	params: { imagePath, categoryName, routingPath },
}) => {
	const { translate } = useTranslate();
	const { screen } = useScreen();
	const isSmall = screen(['xs', 'sm']);
	const history = useHistory();

	return (
		<AppleLobMenuItem
			onClick={() => {
				history.push(`/${getLang()}${routingPath}`);
			}}
		>
			<Image src={imagePath} width={isSmall ? 64 : 80} height={isSmall ? 64 : 80}></Image>

			<AppleLobMenuItemTitle isSmall={isSmall}>
				{isSmall ? <BodySBold>{categoryName}</BodySBold> : <BodyMBold>{categoryName}</BodyMBold>}
			</AppleLobMenuItemTitle>
		</AppleLobMenuItem>
	);
};
export default AppleLobMenuItemCard;
