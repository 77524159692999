import styled from 'styled-components';
import themeColor from '@Utils/theme/themeColor';
import * as cssTypes from 'csstype';
export interface ITypographyCustomProps {
	color?: types.theme.themeColors;
	textTransform?: cssTypes.Property.TextTransform;
	fontWeight?: cssTypes.Property.FontWeight;
	textCut?: boolean;
}

const common = styled.p<types.IStyledProps & ITypographyCustomProps>`
	margin: 0;
	${({ textTransform = 'none', fontWeight = 'normal', color, textCut }) => {
		const textCuts = textCut
			? {
					overflow: 'hidden',
					whiteSpace: 'nowrap' as cssTypes.Property.WhiteSpace,
					textOverflow: 'ellipsis',
			  }
			: {};
		return {
			textTransform,
			fontWeight,
			color: themeColor(color),
			...textCuts,
		};
	}}
`;

const h1common = styled.h1<types.IStyledProps & ITypographyCustomProps>`
	margin: 0;
	${({ textTransform = 'none', fontWeight = 'normal', color, textCut }) => {
		const textCuts = textCut
			? {
					overflow: 'hidden',
					whiteSpace: 'nowrap' as cssTypes.Property.WhiteSpace,
					textOverflow: 'ellipsis',
			  }
			: {};
		return {
			textTransform,
			fontWeight,
			color: themeColor(color),
			...textCuts,
		};
	}}
`;

const h3common = styled.h3<types.IStyledProps & ITypographyCustomProps>`
	${({ textTransform = 'none', fontWeight = 'normal', color, textCut }) => {
		const textCuts = textCut
			? {
					overflow: 'hidden',
					whiteSpace: 'nowrap' as cssTypes.Property.WhiteSpace,
					textOverflow: 'ellipsis',
			  }
			: {};
		return {
			margin: 0,
			textTransform,
			fontWeight,
			color: themeColor(color),
			...textCuts,
		};
	}}
`;

const h2common = styled.h2<types.IStyledProps & ITypographyCustomProps>`
	${({ textTransform = 'none', fontWeight = 'normal', color, textCut }) => {
		const textCuts = textCut
			? {
					overflow: 'hidden',
					whiteSpace: 'nowrap' as cssTypes.Property.WhiteSpace,
					textOverflow: 'ellipsis',
			  }
			: {};
		return {
			margin: 0,
			textTransform,
			fontWeight,
			color: themeColor(color),
			...textCuts,
		};
	}}
`;

export const DisplayL = styled(common)`
	${(props) => props.theme.typography.displayL}
`;

export const DisplayMBold = styled(common)`
	${(props) => props.theme.typography.displayMBold}
`;

export const DisplayMBoldH1 = styled(h1common)`
	${(props) => props.theme.typography.displayMBold}
`;

export const BodyMH3 = styled(h3common)`
	${(props) => props.theme.typography.bodyM}
`;

export const DisplayM = styled(common)`
	${(props) => props.theme.typography.displayM}
`;

export const HeadlineBold = styled(common)`
	${(props) => props.theme.typography.headlineBold}
`;

export const BiggerHeadlineBold = styled(common)`
	${(props) => props.theme.typography.biggerHeadlineBold}
`;

export const Headline = styled(common)`
	${(props) => props.theme.typography.headline}
`;

export const HeadlineL = styled(common)`
	${(props) => props.theme.typography.headlineL}
`;

export const Header = styled(common)`
	${(props) => props.theme.typography.header}
`;

export const TitleBold = styled(common)`
	${(props) => props.theme.typography.titleBold}
`;

export const Title = styled(common)`
	${(props) => props.theme.typography.title}
`;

export const ValueBold = styled(common)`
	${(props) => props.theme.typography.valueBold}
`;

export const Tabs = styled(common)`
	${(props) => props.theme.typography.tabs}
`;

export const Value = styled(common)`
	${(props) => props.theme.typography.value}
`;

export const ButtonL = styled(common)`
	${(props) => props.theme.typography.buttonL}
`;

export const BodyMExtraBold = styled(common)`
	${(props) => props.theme.typography.bodyMExtraBold}
`;

export const ParagraphMExtraBold = styled(common)`
	${(props) => props.theme.typography.paragraphMExtraBold}
`;

export const BodyMBold = styled(common)`
	${(props) => props.theme.typography.bodyMBold}
`;

export const ParagraphMBold = styled(common)`
	${(props) => props.theme.typography.paragraphMBold}
`;

export const DigitXsSm = styled(common)`
	${(props) => props.theme.typography.digitXsSm}
`;

export const BodyM = styled(common)`
	${(props) => props.theme.typography.bodyM}
`;

export const ParagraphM = styled(common)`
	${(props) => props.theme.typography.paragraphM}
`;

export const ParagraphMHigher = styled(common)`
	${(props) => props.theme.typography.paragraphMHigher}
`;

export const ParagraphL = styled(common)`
	${(props) => props.theme.typography.paragraphL}
`;

export const FloatingLabel = styled(common)`
	${(props) => props.theme.typography.floatingLabel}
`;

export const ButtonM = styled(common)`
	${(props) => props.theme.typography.buttonM}
`;

export const DiscountS = styled(common)`
	${(props) => props.theme.typography.discountS}
`;

export const BodySBold = styled(common)`
	${(props) => props.theme.typography.bodySBold}
`;

export const ParagraphSBold = styled(common)`
	${(props) => props.theme.typography.paragraphSBold}
`;

export const BodyS = styled(common)`
	${(props) => props.theme.typography.bodyS}
`;

export const BodySHigher = styled(common)`
	${(props) => props.theme.typography.bodySHigher}
`;

export const BodySBoldHigher = styled(common)`
	${(props) => props.theme.typography.bodySBoldHigher}
`;

export const ParagraphS = styled(common)`
	${(props) => props.theme.typography.paragraphS}
`;

export const ButtonMBold = styled(common)`
	${(props) => props.theme.typography.buttonMBold}
`;

export const TitleAlbertBold = styled(common)`
	${(props) => props.theme.typography.titleAlbertBold}
`;

export const Subheading = styled(common)`
	${(props) => props.theme.typography.subheading}
`;

export const SubheadingH2 = styled(h2common)`
	${(props) => props.theme.typography.subheading}
`;

export const CaptionBold = styled(common)`
	${(props) => props.theme.typography.captionBold}
`;

export const Label = styled(common)`
	${(props) => props.theme.typography.label}
`;

export const SmallCaption = styled(common)`
	${(props) => props.theme.typography.smallCaption}
`;
export const Caption = styled(common)`
	${(props) => props.theme.typography.caption}
`;

export const CaptionBigger = styled(common)`
	${(props) => props.theme.typography.captionBigger}
`;

export const CaptionBiggerBold = styled(common)`
	${(props) => props.theme.typography.captionBiggerBold}
`;

export const CaptionHigher = styled(common)`
	${(props) => props.theme.typography.captionHigher}
`;

export const CaptionHigherBold = styled(common)`
	${(props) => props.theme.typography.captionHigherBold}
`;

export const SmallCurrency = styled(common)`
	${(props) => props.theme.typography.smallCurrency}
`;

export const SmallCurrencyVat = styled(common)`
	${(props) => props.theme.typography.smallCurrencyVat}
`;

export const BigCurrency = styled(common)`
	${(props) => props.theme.typography.bigCurrency}
`;

export const Discount = styled(common)`
	${(props) => props.theme.typography.discount}
`;
export const MiniUnit = styled(common)`
	${(props) => props.theme.typography.miniUnit}
`;

export const MiniUnitRegular = styled(common)`
	${(props) => props.theme.typography.miniUnitRegular}
`;

export const SmallDuration = styled(common)`
	${(props) => props.theme.typography.smallDuration}
`;

export const MiniDurationBold = styled(common)`
	${(props) => props.theme.typography.miniDurationBold}
`;

export const MiniDuration = styled(common)`
	${(props) => props.theme.typography.miniDuration}
`;

export const Counter = styled(common)`
	${(props) => props.theme.typography.counter}
`;

export const Info = styled(common)`
	${(props) => props.theme.typography.info}
`;

export const InfoBold = styled(common)`
	${(props) => props.theme.typography.infoBold}
`;

export const MiniCurrency = styled(common)`
	${(props) => props.theme.typography.miniCurrency}
`;

export const MiniDescription = styled(common)`
	${(props) => props.theme.typography.miniDescription}
`;

export const Digit = styled(common)`
	${(props) => props.theme.typography.digit}
`;

export const DigitSM = styled(common)`
	${(props) => props.theme.typography.digitSM}
`;

export const DigitXS = styled(common)`
	${(props) => props.theme.typography.digitXS}
`;

export const CounterDescription = styled(common)`
	${(props) => props.theme.typography.counterDescription}
`;

export const ComparisonCurrency = styled(common)`
	${(props) => props.theme.typography.comparisonCurrency}
`;

export const Status = styled(common)`
	${(props) => props.theme.typography.status}
`;

export const StatusBold = styled(common)`
	${(props) => props.theme.typography.statusBold}
`;

export const HeadlineS = styled(common)`
	${(props) => props.theme.typography.headlineS}
`;

export const BodyXLBold = styled(common)`
	${(props) => props.theme.typography.bodyXLBold}
`;

export const Description = styled(common)`
	${(props) => props.theme.typography.description}
`;

export const BodyBoldL = styled(common)`
	${(props) => props.theme.typography.bodyBoldL}
`;

export const BodyLBoldPP = styled(common)`
	${(props) => props.theme.typography.bodyLBoldPP}
`;

export const BodyLBold = styled(common)`
	${(props) => props.theme.typography.bodyLBold}
`;
export const BodyL = styled(common)`
	${(props) => props.theme.typography.bodyL}
`;

export const BodySemiLBold = styled(common)`
	${(props) => props.theme.typography.bodySemiLBold}
`;
