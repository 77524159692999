import {
	setConfigurator,
	clearConfigurator,
	IConfigurator,
	setOpenOutletModal,
	setOutletAvailability,
} from './actions';
import immerHandleActions from '@Redux/utils/immerHandleActions';

const init = {
	selectedCapacity: undefined,
};

type draft = typeof init;

export interface IConfiguratorDraft extends draft {}

const reducers = {
	[setConfigurator.toString()](draft: draft, action: IConfigurator) {
		return { ...draft, ...action.payload };
	},
	[clearConfigurator.toString()]() {
		return init;
	},
	[setOpenOutletModal.toString()](draft: draft, action: IConfigurator) {
		return { ...draft, ...action.payload };
	},
	[setOutletAvailability.toString()](draft: draft, action: IConfigurator) {
		return { ...draft, ...action.payload };
	},
};

export default immerHandleActions(reducers, init);
