import { BodyS, Title } from '@Components/Typography/Typography.styled';
import { justifyContent, paddingLeft } from '@Utils/layout/layoutSupport';
import styled from 'styled-components';

export const TopBar = styled.div`
	width: 100%;
	padding: 16px 0px;
	${({ theme }) => ({
		backgroundColor: theme.colors.background,
	})}
`;

export const BarContent = styled.div`
	display: flex;
	align-items: center;
	flex-wrap: no-wrap;
	gap: 8px;
	${() => justifyContent('flex-start')}
`;

export const Change = styled(BodyS)`
	display: inline;
	cursor: pointer;
	text-decoration: underline;
	${() => paddingLeft(8)}
	${({ theme }) => ({
		color: theme.colors.primary,
	})}
`;

export const BarTitle = styled(Title)`
	${({ theme }) => ({
		color: theme.colors.black87,
	})}
`;

export const ContentWrapper = styled.div`
	overflow-x: scroll;
`;

export const Button = styled.div`
	width: 100%;
	> div {
		width: 100%;
	}
`;

export const InfoBar = styled.div`
	display: flex;
	align-items: center;
	flex-wrap: no-wrap;
	gap: 8px;
	padding: 8px;
	border-radius: 6px;
	${() => justifyContent('flex-start')}
	${({ theme }) => ({
		backgroundColor: theme.colors.primary,
	})}
`;
