import { justifyContent, matchScreen } from '@Utils/layout/layoutSupport';
import styled from 'styled-components';
import { ADDITIONAL_BAR_HEIGHT, ADDITIONAL_SMALL_BAR_HEIGHT } from './TelemarketerTopBar';
import { Title } from '@Components/Typography/Typography.styled';

export enum ItemType {
	LINK = 'LINK',
	EXPANDABLE = 'EXPANDABLE',
}

interface IBarWrapper {}

export const TitleWrapper = styled(Title)`
	${({ theme }) => ({ color: theme.colors.white })}
`;
export const BarContainer = styled.div<{ warning: boolean }>`
	${({ theme, warning }) => {
		const isXsSmMd = matchScreen(['xs', 'sm', 'md']);
		return {
			backgroundColor: theme.colors[warning ? 'warning' : 'bluePrimary'],
			minHeight: isXsSmMd ? ADDITIONAL_SMALL_BAR_HEIGHT : ADDITIONAL_BAR_HEIGHT,
		};
	}};
	transition: all 0.2s ease-in-out;
	position: relative;
	width: 100%;
	position: fixed;
	z-index: 100;
	top: 0;
	left: 0;
`;

export const BarWrapper = styled.div<IBarWrapper>`
	${() => {
		const isXsSmMd = matchScreen(['xs', 'sm', 'md']);
		return { minHeight: isXsSmMd ? ADDITIONAL_SMALL_BAR_HEIGHT : ADDITIONAL_BAR_HEIGHT };
	}};
	width: 100%;
	display: flex;
	align-items: center;
	z-index: 110;
	justify-content: space-between;
	gap: 16px;
`;

export const FormContent = styled.div`
	display: flex;
	gap: 16px;
	align-items: center;
`;

export const LoggedTelesaleInfo = styled.div`
	display: flex;
	flex-direction: column;
`;

export const LogOutWrapper = styled.div`
	width: 100;
`;

export const TeleInfoWrapper = styled.div`
	display: flex;
	gap: 8px;
	${() => justifyContent('flex-end')};
`;
export const SuccessInfoWrapper = styled.div`
	display: flex;
	align-items: center;
	gap: 8px;
`;
export const SuccessButtonWrapper = styled.div`
	display: flex;
	justify-content: flex-end;
`;

export const BlockedScreen = styled.div<{ blocked: boolean }>`
	${({ theme, blocked }) => ({
		backgroundColor: theme.colors.black38,
		display: blocked ? 'unset' : 'none',
	})};
	position: fixed;
	z-index: 60;
	height: calc(
		100vh -
			${() => {
				const isXsSmMd = matchScreen(['xs', 'sm', 'md']);
				return isXsSmMd ? ADDITIONAL_SMALL_BAR_HEIGHT : ADDITIONAL_BAR_HEIGHT;
			}}px
	);
	width: 100vw;
`;
