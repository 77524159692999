import React, { useEffect, useMemo, useState } from 'react';
import { getLang } from '@Utils/language/language';
import { useHistory, useLocation } from 'react-router-dom';
import { IAddSimToCartPayload, OrderFlowService } from '@Services/orderFlow/orderFlow';
import { dispatch } from '@Redux/store';
import { modalTypes, setModal } from '@Redux/modules/modal/actions';
import { errors } from '@Utils/api/errors/errors';
import { HAYYAK_PRODUCT_ID, POSTPAID_PLAN } from '@Config/app.config';
import { useSelector } from 'react-redux';
import PackagesService from '@Services/packages/packages';
import { ISinglePackage } from '@ApiModels/singlePackage';
import { ProfileService } from '@Services/profile/profileService';
import useTranslate from '@Utils/hooks/useTranslate';
import { useCart } from '@Utils/context/CartContext';

const BuySimRedirect = (): JSX.Element => {
	const { translate } = useTranslate();
	const { search } = useLocation();
	const history = useHistory();
	const isUserSignedIn = useSelector((state: types.redux.IState) => state.api?.authentication.signedIn);
	const [plansToBuy, setPlansToBuy] = useState<ISinglePackage[]>([]);
	const [selectedPlan, setSelectedPlan] = useState<ISinglePackage>();
	const query = new URLSearchParams(search);
	const { cart } = useCart();

	const prepaidInCart = useMemo(() => {
		return !!cart.items.find((item) => [POSTPAID_PLAN].includes(item.plan?.PA || ''));
	}, [cart]);

	const gotoHomePage = () => {
		history.push(`/${getLang()}/store`);
	};

	const getUserProfile = async (): Promise<string> => {
		return new Promise((resolve) => {
			ProfileService.getUserProfile().subscribe(
				(response) => {
					resolve(response.uid);
				},
				() => {
					resolve('');
				}
			);
		});
	};

	const addSimToCart = async () => {
		const loggedUserPhoneNumber = await getUserProfile();
		const preparedLoginNumber = loggedUserPhoneNumber.replace(/[^0-9]+/g, '');
		const payload: IAddSimToCartPayload = {
			quantity: '1',
			xitem_planAction: POSTPAID_PLAN,
			xitem_documentId: 'E0324111',
			xitem_documentType: '102',
			xitem_productId: selectedPlan ? selectedPlan?.partNumber : '',
			productId: HAYYAK_PRODUCT_ID,
			xitem_login: preparedLoginNumber,
			productName: selectedPlan ? selectedPlan?.name : '',
			payment_plan: translate('product.details.plan.device.only'),
		};

		OrderFlowService.addItemsToCart([payload]).subscribe(
			() => {
				history.push(`/${getLang()}/cart`);
			},
			(e) => {
				if (e.response?.errors?.length > 0 && e.response?.errors[0]?.errorKey === errors.SESSION_GENERIC_USER) {
					dispatch(
						setModal({
							type: modalTypes.LOGIN_SESSION_EXPIRED,
							data: {
								onContinue: () => {
									addSimToCart().catch((e) => {
										gotoHomePage();
									});
								},
								onAbort: () => {
									gotoHomePage();
								},
							},
						})
					);
				} else {
					gotoHomePage();
				}
			}
		);
	};

	useEffect(() => {
		PackagesService.getPackagesByGroupAndType('PLAN', 'MOBILE_PREPAID_NEW_ESIM').subscribe(
			(response) => {
				if (response?.selfActivatePackages) {
					setPlansToBuy(response.selfActivatePackages);
				} else {
					gotoHomePage();
				}
			},
			(e) => {
				gotoHomePage();
			}
		);
	}, []);

	useEffect(() => {
		if (prepaidInCart) {
			history.push(`/${getLang()}/cart`);
		} else if (plansToBuy.length > 0) {
			const productParam = query.get('product') || '';
			const findedPlan = plansToBuy.find((plan) => plan.partNumber === productParam);
			if (!!findedPlan) {
				setSelectedPlan(findedPlan);
			} else {
				gotoHomePage();
			}
		}
	}, [plansToBuy]);

	useEffect(() => {
		if (!!selectedPlan) {
			if (isUserSignedIn) {
				addSimToCart().catch((e) => {
					gotoHomePage();
				});
			} else {
				dispatch(
					setModal({
						data: {
							onContinue: () => {
								addSimToCart().catch((e) => {
									gotoHomePage();
								});
							},
							onAbort: () => {
								gotoHomePage();
							},
						},
						type: modalTypes.LOGIN,
					})
				);
			}
		}
	}, [selectedPlan]);

	return <></>;
};

export default BuySimRedirect;
