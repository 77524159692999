import React, { FunctionComponent } from 'react';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';

interface IContentCarouselProps {
	items: any[];
	paddingLeft?: number;
	paddingRight?: number;
	responsive?: Record<number, { items: number }>;
	nextButton?: JSX.Element;
	prevButton?: JSX.Element;
	disableButtons?: boolean;
}

const defaultResponsive = {
	0: { items: 1 },
	576: { items: 2 },
	768: { items: 2 },
	992: { items: 3 },
	1200: { items: 4 },
};

const ContentCarousel: FunctionComponent<IContentCarouselProps> = ({
	items,
	paddingLeft,
	paddingRight,
	responsive = defaultResponsive,
	nextButton,
	prevButton,
	disableButtons = false,
}) => (
	<AliceCarousel
		infinite={!disableButtons}
		items={items}
		paddingLeft={paddingLeft}
		paddingRight={paddingRight}
		responsive={responsive}
		disableDotsControls
		disableButtonsControls={disableButtons}
		controlsStrategy="reponsive"
		renderNextButton={() => nextButton}
		renderPrevButton={() => prevButton}
		mouseTracking={true}
	/>
);

export default ContentCarousel;
