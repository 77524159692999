import {
	CAPACITY_ATTRIBUTE,
	COLOR_ATTRIBUTE,
	COUNTRY_ATTRIBUTE,
	DEVICE_PROTECTION_PROMO,
	DIGITAL_PRODUCT_ITEM_TYPE,
	DURATION,
	ESHOP_ADDITIONAL_SERVICE,
	ESIM_CAPABLE,
	getAttributeValue,
	getPrice,
	GIFT_CARD_ITEM_TYPE,
	GIFT_CARD_VALUES_ATTRIBUTE,
	ITEM_TYPE_ATTRIBUTE,
	LAUNCH_DATE_ATTRIBUTE,
	MANUFACTURER_ATTRIBUTE,
} from './utils/attributeGetters';
import changeReplacementCharacter from '@Utils/converters/changeReplacementCharacter';

export type StringBoolean = 'true' | 'false';

export interface IValue {
	id: string | string[];
	identifier: string | string[];
	sequence: string | string[];
	value: string | string[];
	image1: string | string[];
	image1path?: string | string[];
	uniqueID?: string;
	[key: string]: any;
}

export interface IAttribute<T> {
	comparable: StringBoolean;
	displayable: StringBoolean;
	facetable: StringBoolean;
	id: string;
	identifier: string;
	key: string;
	merchandisable: StringBoolean;
	name: string;
	searchable: StringBoolean;
	storeDisplay: StringBoolean;
	usage: string;
	values: T[];
	swatchable: StringBoolean;
	uniqueID?: string;
}

export interface IPrice {
	currency: string;
	description: string;
	usage: string;
	value: string | number;
}

export interface ISeo {
	href: string;
}

interface IItem {
	attributtes: IAttribute<IValue>[];
	buyable: StringBoolean;
	fullImage: string;
	hasSingleSKU: boolean;
	id: string;
	name: string;
	parent: string;
	parentCatalogEntryID: string;
	parentCatalogGroupID: string;
	partNumber: string;
	price: IPrice[];
	seo: ISeo;
	shortDescription: string;
	storeID: string;
	thumbnail: string;
	type: string;
}

interface IContent {
	attributes: IAttribute<IValue>[];
	buyable: string;
	dissalowRecurringOrder: string;
	fullImage: string;
	hasSingleSKU: boolean;
	id: string;
	items: IItem[];
	longDescription: string;
	name: string;
	numberOfSKUs: number;
	parentCatalogGroupID: string;
	partNumber: string;
	price: IPrice[];
	seo: ISeo;
	shortDescription: string;
	storeID: string;
	thumbnail: string;
	type: string;
}

interface IModel {
	_contents: IContent[];
}

export interface IProductsByPartNumber {
	name: string;
	price: number;
	monthlyPrice: number;
	longDescription: string;
	thumbnailUrl: string;
	capacity: string;
	color: string;
	manufacturer: string;
	id: string;
	isGiftCard: boolean;
	country?: string;
	giftCardValue?: string;
	isDigitalProduct?: boolean;
	insurancePromotion?: boolean;
	launchDate?: string;
	esimCapable: boolean;
	additionalService: string;
	duration: string;
	partNumber: string;
	seo: ISeo;
}

const model: types.models.IInputModel = {
	_contents: 'response.contents',
	name: (model: IModel) => changeReplacementCharacter(model._contents[0].name),
	longDescription: (model: IModel) => model._contents[0].longDescription,
	price: (model: IModel) => getPrice(model._contents[0].price, 'Offer'),
	thumbnailUrl: (model: IModel) => model._contents[0].thumbnail,
	capacity: (model: IModel) => getAttributeValue(model._contents[0], CAPACITY_ATTRIBUTE),
	color: (model: IModel) => getAttributeValue(model._contents[0], COLOR_ATTRIBUTE),
	insurancePromotion: (model: IModel) => getAttributeValue(model._contents[0], DEVICE_PROTECTION_PROMO) === 'TRUE',
	manufacturer: (model: IModel) => getAttributeValue(model._contents[0], MANUFACTURER_ATTRIBUTE),
	id: (model: IModel) => model._contents[0].id,
	isGiftCard: (model: IModel) => getAttributeValue(model._contents[0], ITEM_TYPE_ATTRIBUTE) === GIFT_CARD_ITEM_TYPE,
	isDigitalProduct: (model: IModel) =>
		getAttributeValue(model._contents[0], ITEM_TYPE_ATTRIBUTE) === DIGITAL_PRODUCT_ITEM_TYPE,
	giftCardValue: (model: IModel) => getAttributeValue(model._contents[0], GIFT_CARD_VALUES_ATTRIBUTE),
	country: (model: IModel) => getAttributeValue(model._contents[0], COUNTRY_ATTRIBUTE),
	launchDate: (model: IModel) => getAttributeValue(model._contents[0], LAUNCH_DATE_ATTRIBUTE),
	esimCapable: (model: IModel) => getAttributeValue(model._contents[0], ESIM_CAPABLE) === 'TRUE',
	additionalService: (model: IModel) => getAttributeValue(model._contents[0], ESHOP_ADDITIONAL_SERVICE),
	duration: (model: IModel) => getAttributeValue(model._contents[0], DURATION),
	partNumber: (model: IModel) => model._contents[0].partNumber,
	seo: (model: IModel) => model._contents[0].seo,
};

export default model;
