import { setTimer, ISetTimerAction } from './actions';
import immerHandleActions from '@Redux/utils/immerHandleActions';

const init = {
	activated: false,
	time: 120000,
};

type draft = typeof init;

const reducers = {
	[setTimer.toString()](draft: draft, action: ISetTimerAction) {
		const { time, activated } = action.payload;
		draft.time = time;
		if (activated !== undefined) {
			draft.activated = activated;
		}
		return draft;
	},
};

export default immerHandleActions(reducers, init);
