import React, { FunctionComponent } from 'react';
import Card from '@Components/Card/Card';
import { TermsAndConditionsModalContainer } from './TermsAndConditions.styled';
import { IInsuranceOfferProvider, IVehicleInsurance } from '@ApiModels/vehicleInsurance';
export interface ITermsAndConditionsModalProps {
	vehicleInformation: IVehicleInsurance['vehicleInformation'];
	selectedProvider: IInsuranceOfferProvider;
	content: string;
}

const TermsAndConditions: FunctionComponent<ITermsAndConditionsModalProps> = ({
	vehicleInformation,
	selectedProvider,
	content,
}) => {
	return (
		<TermsAndConditionsModalContainer>
			<Card<types.cards.IInsuranceTermsAndConditionsCardProps>
				card="insuranceTermsAndConditions"
				params={{ vehicleInformation, selectedProvider, content }}
			/>
		</TermsAndConditionsModalContainer>
	);
};

export default TermsAndConditions;
