export enum ResponseResult {
	SUCCESS = 'SUCCESS',
}

export interface IResponseResultGetTenantFromOrdersHistory {
	result: {
		result: ResponseResult;
		tenantId: string;
		tenantName: string;
	};
}

const model: types.models.IInputModel = {
	result: 'response.response',
};

export default model;
