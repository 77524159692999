import { Observable } from 'rxjs';
import api from '@Utils/api';
import { getLanguage } from '@Utils/language/language';
import { IGetBranches } from '@ApiModels/getBranches';
import { IGetServices } from '@ApiModels/getServices';
import { IGetDates } from '@ApiModels/getDates';
import { IBookAppointment } from '@ApiModels/bookAppointment';
import { ICancelAppointment } from '@ApiModels/cancelAppointment';
import { IGetHours } from '@ApiModels/getHours';
import { IGetAppointment } from '@ApiModels/getAppointment';
import { IGetAppointments } from '@ApiModels/getAppointments';

export class AppointmentService {
	public static getBranches(payload: types.payloads.IGetBranchesPayload): Observable<IGetBranches> {
		const language: string = getLanguage().toLowerCase();
		return api.omantelApi.getBranches({ ...payload, language }).call();
	}

	public static getServices(payload: types.payloads.IGetServicesPayload): Observable<IGetServices> {
		const language: string = getLanguage().toLowerCase();
		return api.omantelApi.getServices({ ...payload, language }).call();
	}

	public static getDates(payload: types.payloads.IGetDatesPayload): Observable<IGetDates> {
		return api.omantelApi.getDates(payload).call();
	}

	public static getHours(payload: types.payloads.IGetHoursPayload): Observable<IGetHours> {
		return api.omantelApi.getHours(payload).call();
	}

	public static bookAppointment(payload: types.payloads.IBookAppointmentPayload): Observable<IBookAppointment> {
		const language: string = getLanguage().toLowerCase();
		return api.omantelApi.bookAppointment({ ...payload, language }).call();
	}

	public static cancelAppointment(payload: types.payloads.ICancelAppointmentPayload): Observable<ICancelAppointment> {
		return api.omantelApi.cancelAppointment(payload).call();
	}

	public static getAppointment(payload: types.payloads.IGetAppointmentPayload): Observable<IGetAppointment> {
		return api.omantelApi.getAppointment(payload).call();
	}

	public static getAppointments(payload: types.payloads.IGetAppointmentsPayload): Observable<IGetAppointments> {
		return api.omantelApi.getAppointments(payload).call();
	}
}

export default AppointmentService;
