import styled from 'styled-components';
import { IStepperAdditionalProps } from './Stepper';

export enum StepperState {
	DONE = 'DONE',
	UNDONE = 'UNDONE',
	ACTIVE = 'ACTIVE',
}

export const StepperWrapper = styled.div<IStepperAdditionalProps>`
	${({ size }) => ({ width: size, height: size })}
	${({ state, bgColor, theme }) => {
		switch (state) {
			case StepperState.UNDONE:
				return {
					border: `solid 1px ${theme.colors.black12}`,
					backgroundColor: bgColor,
				};
			case StepperState.ACTIVE:
				return {
					border: `solid 1.5px ${theme.colors.primary}`,
					backgroundColor: bgColor,
				};
			case StepperState.DONE:
				return {
					backgroundColor: theme.colors.primary,
				};
		}
	}}
	box-sizing: border-box;
	border-radius: 50%;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	position: relative;
`;

export const IconWrapper = styled.div`
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
`;
