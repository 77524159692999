import { BodyM, BodyMBold, BodyS, DisplayMBold, HeadlineBold, Subheading } from '@Components/Typography/Typography';
import styled from 'styled-components';
import { NAVBAR_HEIGHT } from '@Layouts/Dashboard/Dashboard';
import { isLargeScreen, left, matchScreen, paddingLeft } from '@Utils/layout/layoutSupport';
import { MiniDescription } from '@Components/Typography/Typography.styled';

export const TABLE_CONTAINER_WIDTH = 280;

export const ComparisonContainer = styled.div`
	display: flex;
	flex-direction: row;
`;

export const ComparisonItemsContainer = styled.div`
	${({ theme }) => `
		.row:nth-child(2n-1) {
		background-color: ${theme.colors.grey50};
	}
		.row:nth-child(2n) {
		background-color: ${theme.colors.white};
	};`}
`;

export const ComparisonTableContainer = styled.div<{
	width?: number;
	shadowWidth?: number;
	top: number;
	overflowEnabled?: boolean;
}>`
	${({ theme, shadowWidth }) => `
	.row:nth-child(2n) {
		background-color: ${theme.colors.grey50};
	};
	.row:nth-child(2n-1) {
		background-color: ${theme.colors.white};
	};
	box-shadow: ${shadowWidth}px 0 ${shadowWidth}px 0 rgba(0, 0, 0, 0.04);
	`}
	z-index: 2;
	${() => left(0)}
	${({ width, top, overflowEnabled }) => {
		const isSmallScreen = matchScreen(['xs', 'sm']);
		return {
			width,
			top: -top + NAVBAR_HEIGHT - (isLargeScreen() ? 0 : isSmallScreen ? 56 : 12) + 1,
			position: overflowEnabled ? 'fixed' : 'absolute',
		};
	}}
`;

export const ComparisonRow = styled.div<{
	$paddingLeft?: number;
	bottomBorder?: boolean;
	height?: number;
	withoutPadding?: boolean;
	background?: types.theme.themeColors;
}>`
	${({ bottomBorder, theme, height, withoutPadding }) => {
		const padding = withoutPadding ? 0 : 18;
		return {
			paddingTop: `${padding}px`,
			paddingBottom: `${bottomBorder ? padding - 1 : padding}px`,
			borderBottom: bottomBorder ? '1px solid' : undefined,
			borderColor: theme.colors.black12,
			height: height ? `${height - 2 * padding}px` : undefined,
		};
	}}
	${({ $paddingLeft }) => paddingLeft($paddingLeft ?? 0)}
	&&& {
		${({ theme, background }) => ({ backgroundColor: background ? theme.colors[background] : undefined })}
	}
`;

export const DifferenceColorWrapper = styled.div<{ isDiffrent: boolean; isXS?: boolean }>`
	${({ isXS }) => ({
		display: 'flex',
		flexDirection: 'column',
		flex: 1,
		alignItems: isXS ? 'flex-start' : 'center',
		justifyContent: 'center',
		paddingTop: isXS ? '14px' : '18px',
		paddingBottom: isXS ? '14px' : '18px',
		gap: '4px',
		paddingLeft: isXS ? '16px' : undefined,
	})}
	${({ isDiffrent, theme }) => isDiffrent && `& > p { color: ${theme.colors.primary}; font-weight: 700 }`}
`;

export const SubheaderWrapper = styled(Subheading)<{ isXS?: boolean }>`
	text-transform: uppercase;
	${({ theme, isXS }) => ({
		color: theme.colors.black87,
		paddingTop: isXS ? '16px' : '66px',
	})}
`;

export const TitleWrapper = styled(DisplayMBold)`
	${({ theme }) => ({
		color: theme.colors.black87,
		marginTop: '8px',
	})}
`;

export const SwitchContainer = styled.div`
	display: flex;
	flex-direction: row;
	margin-top: 16px;
	align-items: center;
	margin-bottom: 40px;
`;

export const HighlightText = styled(BodyS)`
	${({ theme }) => ({
		color: theme.colors.black87,
	})}
	margin: 0 16px;
`;

export const HeadlineWrapper = styled(HeadlineBold)`
	${({ theme }) => ({
		color: theme.colors.black87,
	})}
`;

export const KeyText = styled(BodyMBold)`
	${({ theme }) => ({
		color: `${theme.colors.black87} !important;`,
	})}
	margin: 0
`;

export const ComparisonDivider = styled.div<{ height?: number }>`
	${({ height, theme }) => ({
		height: height ? `${height - 1}px` : undefined,
		zIndex: -1,
		borderBottom: '1px solid',
		borderColor: theme.colors.black12,
		backgroundColor: theme.colors.white,
	})}
	width: 100%;
`;

export const ValueText = styled(BodyM)<{ hideText?: boolean }>`
	${({ theme }) => ({
		color: theme.colors.black87,
	})};
	${({ hideText }) => hideText && `visibility: hidden`};
	text-align: center;
`;

export const ValuesColumns = styled.div`
	display: flex;
	flex-direction: row;
	width: 100%;
`;

export const ProductCardWrapper = styled.div`
	padding: 24px 8px;
	min-width: 200px;
`;

export const ItemColWrapper = styled.div<{ $paddingLeft: number; fullWidth?: boolean }>`
	padding: 0;
	${({ fullWidth }) => (fullWidth ? `width: 100%` : `min-width: ${TABLE_CONTAINER_WIDTH}px`)};
	${({ $paddingLeft }) => paddingLeft($paddingLeft)}
`;

export const LimitationText = styled(MiniDescription)`
	${({ theme }) => ({
		color: theme.colors.black54,
	})};
	margin-top: 40px;
	width: 75%;
`;
