import styled from 'styled-components';

export const PlanWrapper = styled.div`
	z-index: 6;
	width: 100%;
`;

export const DisplayNoneWrapper = styled.div<{ isEbu?: boolean }>`
	${({ isEbu }) => ({
		display: isEbu ? '' : 'none',
	})}
`;
