import React, { FunctionComponent } from 'react';
import { SkeletonRow, Rectangular } from '@Components/Skeleton/Skeleton.styled';

const DeliveryAddressSekeleton: FunctionComponent = () => {
	return (
		<>
			<SkeletonRow marginTop={8} marginRight={16}>
				<Rectangular width="100%" height={390} marginBottom={16} />
			</SkeletonRow>
			<SkeletonRow marginTop={8}>
				<Rectangular width="100%" height={48} marginBottom={20} />
				<Rectangular width="100%" height={48} marginBottom={20} />
				<Rectangular width="100%" height={48} marginBottom={20} />
				<Rectangular width="100%" height={48} marginBottom={20} />
				<Rectangular width="100%" height={48} marginBottom={20} />
				<Rectangular width="100%" height={48} marginBottom={20} />
			</SkeletonRow>
		</>
	);
};

export default DeliveryAddressSekeleton;
