import styled from 'styled-components';
import { ParagraphMBold, BodySHigher, ParagraphS, BodyLBoldPP } from '@Components/Typography/Typography.styled';
import { isRTL, marginLeft, matchScreen } from '@Utils/layout/layoutSupport';
import theme from '@Utils/theme/theme';

export const ItemTextWrapper = styled(BodySHigher)<{ padding: number; paddingLeft: number; isButton: boolean }>`
	display: flex;
	flex-grow: 1;
`;

export const SubItemTextWrapper = styled(BodySHigher)`
	display: flex;
	flex-grow: 1;
	text-align: justify;
`;

export const SubItemTextSearchWrapper = styled(ParagraphS)`
	display: flex;
	flex-grow: 1;
	margin-left: 8px;
`;

export const ActiveItemTextWrapper = styled(BodySHigher)`
	display: flex;
	flex-grow: 1;
	${({ theme }) => ({
		color: theme.colors.primary,
	})}
`;

export const ActiveItemSearchWrapper = styled(BodySHigher)`
	display: flex;
	flex-grow: 1;
	text-align: left;
`;

export const LinkItemTextLabelWrapper = styled(BodySHigher)`
	display: flex;
`;

export const HeaderTextWrapper = styled(ParagraphMBold)`
	display: flex;
	flex-grow: 1;
`;

export const SearchHeaderTextWrapper = styled(BodyLBoldPP)`
	display: flex;
	flex-grow: 1;
`;

export const IconWrapper = styled.div<types.IStyledProps & { rotate: boolean }>`
	${({ rotate }) => isRTL() && rotate && 'transform: rotate(180deg)'}
`;

export const ItemWrapper = styled.button<{
	active?: boolean;
	isHeader?: boolean;
	isItem?: boolean;
	isLink?: boolean;
	isSubItem?: boolean;
	additionalLabel?: string;
}>`
	display: flex;
	justify-content: space-between;
	align-items: center;
	${({ active, isHeader }) => ({
		backgroundColor: !isHeader && active ? theme.colors.white : 'transparent',
	})}
	${({ active, isHeader, isSubItem, additionalLabel }) => ({
		backgroundColor: !isHeader && active ? theme.colors.white : 'transparent',
		height: isHeader ? '56px' : '36px',
		borderRadius: !isSubItem ? (isRTL() ? '0 8px 8px 0' : '8px 0 0 8px') : '',
		width: '100%',
		justifyContent: additionalLabel && isSubItem ? 'flex-start' : 'space-between',
	})}
	${({ isSubItem }) => {
		const isSmallDevice = matchScreen(['xs', 'sm']);

		if (isSmallDevice) {
			return {
				borderRadius: 'unset',
			};
		} else {
			return {
				borderRadius: !isSubItem ? (isRTL() ? '0 8px 8px 0' : '8px 0 0 8px') : '',
			};
		}
	}}
	padding: 8px 15px;
	${({ isHeader }) => {
		if (isHeader) return { padding: '8px 0 8px 15px' };
	}}
	&:hover {
		${({ isHeader }) => ({
			backgroundColor: !isHeader ? '#FAEEDE' : '',
			cursor: !isHeader ? 'pointer' : '',
		})}
		${({ isSubItem }) => {
			const isSmallDevice = matchScreen(['xs', 'sm']);

			if (isSmallDevice) {
				return {
					borderRadius: 'unset',
				};
			} else {
				return {
					borderRadius: !isSubItem ? (isRTL() ? '0 8px 8px 0' : '8px 0 0 8px') : '',
				};
			}
		}}}
	}
	border: none;
	outline: none;
`;

export const LabelWrapper = styled.div`
	border-radius: 4px;
	padding: 0px 8px;
	width: 39px;
	height: 16px;
	${({ theme }) => ({
		color: theme.colors.primary,
		border: `1px solid ${theme.colors.primary}`,
	})}
	${() => marginLeft(8)}
`;

export const Label = styled.span`
	${({ theme }) => ({
		color: theme.colors.primary,
		...theme.typography.captionBigger,
	})}
`;
