import immerHandleActions from '@Redux/utils/immerHandleActions';
import { setCheckoutTimer, clearCheckoutTimer } from './actions';

interface ISetCheckoutTimerAction extends types.redux.settings.ISetCheckoutTimerAction {}

const init = {
	checkoutTimer: undefined,
};

type draft = typeof init;

export interface ICheckoutTimerDraft extends draft {}

const reducers = {
	[setCheckoutTimer.toString()](
		_state: types.redux.settings.ISetCheckoutTimerAction,
		action: ISetCheckoutTimerAction
	) {
		return { ..._state, ...action.payload };
	},
	[clearCheckoutTimer.toString()](_state: types.redux.settings.ISetCheckoutTimerAction) {
		return init;
	},
};

export default immerHandleActions<types.redux.settings.ISetCheckoutTimerAction['payload']>(reducers, init);
