export type RemoteConfigurationResponse = {
	_response: RemoteConfigurationItemResponse[];
	sidebar: RemoteConfigurationItemResponse[];
};

export type RemoteConfigurationItemResponse = {
	key: string;
	value: boolean;
};

const model: types.models.IInputModel = {
	_response: ['response', {}],
	sidebar: (model: RemoteConfigurationResponse) => {
		const sidebarConfiguration: { [key: string]: boolean } = {};

		model._response?.map((el: RemoteConfigurationItemResponse) => {
			sidebarConfiguration[el?.key] = el?.value;
		});

		return sidebarConfiguration;
	},
};

export default model;
