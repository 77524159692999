import React, { FunctionComponent } from 'react';
import { SkeletonColumn, SkeletonCardContainer, Rectangular, DisplayFlex } from '@Components/Skeleton/Skeleton.styled';

const MARGIN = 8;

const ShortDescription: FunctionComponent = () => {
	return (
		<SkeletonCardContainer marginTop={16}>
			<Rectangular width={80} height={16} marginBottom={16} marginTop={MARGIN} />
			<DisplayFlex marginTop={24} marginBottom={MARGIN} justifyContent="space-between">
				<SkeletonColumn>
					<Rectangular width={120} height={17} marginBottom={6} />
					<Rectangular width={60} height={16} />
				</SkeletonColumn>
				<DisplayFlex alignItems="flex-start" justifyContent="flex-end">
					<Rectangular width={46} height={36} />
					<Rectangular width={30} height={16} marginLeft={4} />
				</DisplayFlex>
			</DisplayFlex>
		</SkeletonCardContainer>
	);
};

export default ShortDescription;
