import { BodyM, BodyMBold, ParagraphMBold, TitleBold } from '@Components/Typography/Typography.styled';
import { marginLeft, marginRight, matchScreen, right } from '@Utils/layout/layoutSupport';
import styled from 'styled-components';

export const SelectNumberModalContainer = styled.div`
	${({ theme }) => ({
		backgroundColor: theme.colors.white,
		width: matchScreen('xs') ? '87vw' : '420px',
	})};
	border-radius: 8px;
	padding: 24px;
`;

export const TitleWrapper = styled(TitleBold)`
	${({ theme }) => ({
		color: theme.colors.black87,
	})};
	width: 100%;
	text-align: center;
`;

export const CloseButtonContainer = styled.div`
	position: absolute;
	top: 24px;
	${() => right(24)}
`;

export const SubtitleContainer = styled.div`
	display: flex;
	justify-content: space-between;
	margin-bottom: 8px;
`;

export const SubtitleWrapper = styled(ParagraphMBold)`
	${({ theme }) => ({
		color: theme.colors.black87,
	})};
	${() => marginRight(16)}
`;

export const NumberContainer = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 22px 0;
`;

export const PrefixWrapper = styled(BodyM)`
	${({ theme }) => ({
		color: theme.colors.black54,
	})};
	${() => marginLeft(8)}
`;

export const NumberWrapper = styled(BodyMBold)`
	${({ theme }) => ({
		color: theme.colors.black87,
	})}
`;

export const LeftNumberContainer = styled.div`
	display: flex;
	align-items: center;
	gap: 8px;
`;

export const ButtonContainer = styled.div`
	display: flex;
	justify-content: flex-end;
	margin-top: 64px;
`;
