import { InsuranceType } from '@Utils/context/InsuranceContext';

export interface IInsuranceOfferProvider {
	name: string;
	thumbnailImageUrl: string;
	offers: {
		title: string;
		amount: string;
		referenceNo: string;
		trackId: string;
	}[];
	features: {
		name: string;
		isMandatory: boolean;
	}[];
}

export interface IVehicleInsurance {
	vehicleInformation: {
		typeOfInsurance: InsuranceType;
		manufacturer: string;
		model: string;
		mfgYear: string;
		vehiclePlateCharCode: string;
		vehicleNo: string;
		vehicleValue: string;
		estimatedValueFrom: string;
		estimatedValueTo: string;
		highValueLimit: string;
		numberOfPassengers: string;
		numberOfDoors: string;
		classificationCode: string;
		classificationTitle: string;
		driverAge: string;
	};
	insuranceOfferProviders: IInsuranceOfferProvider[];
	buyable: boolean;
	offersMessages: string[];
	paymentErrorMessage: string;
}

const model: types.models.IInputModel = {
	insuranceOfferProviders: 'response.getVehicleInsuranceOffersResponse.response.insuranceOfferProviders',
	vehicleInformation: 'response.getVehicleInsuranceOffersResponse.response.vehicleInformation',
	buyable: ['response.getVehicleInsuranceOffersResponse.response.buyable', false],
	offersMessages: ['response.getVehicleInsuranceOffersResponse.response.offersMessages', []],
	paymentErrorMessage: ['response.getVehicleInsuranceOffersResponse.response.paymentErrorMessage', ''],
};

export interface IInsuranceTCContent {
	content: string;
}

export default model;
