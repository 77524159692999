import { BodyMBold, BodyS, BodySBold } from '@Components/Typography/Typography';
import styled from 'styled-components';

export const GetESIMContainer = styled.div`
	${({ theme }) => ({
		backgroundColor: theme.colors.grey100,
	})};
	border-radius: 8px;
	padding: 16px;
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

export const TitleContainer = styled.div`
	display: flex;
	gap: 16px;
`;

export const TitleColumn = styled.div`
	display: flex;
	flex-direction: column;
	gap: 4px;
	justify-content: center;
`;

export const TitleWrapper = styled(BodyMBold)`
	${({ theme }) => ({
		color: theme.colors.black87,
	})}
`;

export const SubtitleWrapper = styled(BodyS)`
	${({ theme }) => ({
		color: theme.colors.black87,
	})}
`;

export const ButtonsContainer = styled.div`
	margin-top: 20px;
	display: flex;
	gap: 24px;
`;

export const ButtonUnderline = styled(BodySBold)`
	${({ theme }) => ({
		color: theme.colors.primary,
	})}
	text-decoration: underline;
	cursor: pointer;
`;
