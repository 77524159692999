export default {
	PACKAGES: {
		MOBILE_PREPAID_NEW_ESIM: {
			PLAN: {
				AUTH: '45107',
				PROD: '45107',
				STG: '65114',
				TST: '65114',
			},
			COMMITMENT: {
				AUTH: '3074457345616719169',
				PROD: '52501',
				STG: '3074457345616719169',
				TST: '3074457345616719169',
			},
		},
		POSTPAID: {
			PLAN: {
				AUTH: '72115',
				PROD: '72115',
				STG: '72115',
				TST: '45113',
			},
			COMMITMENT: {
				AUTH: '3074457345616719168',
				PROD: '52001',
				STG: '3074457345616719168',
				TST: '3074457345616719168',
			},
		},
		HBB: {
			PLAN: {
				AUTH: '37111',
				PROD: '37111',
				STG: '72114',
				TST: '72114',
			},
			COMMITMENT: {
				AUTH: '3074457345616719169',
				PROD: '52501',
				STG: '3074457345616719169',
				TST: '3074457345616719169',
			},
		},
		WFBB: {
			PLAN: {
				AUTH: '31001',
				PROD: '31001',
				STG: '3074457345616703668',
				TST: '62613',
			},
			COMMITMENT: {
				AUTH: '3074457345616719169',
				PROD: '52501',
				STG: '3074457345616719169',
				TST: '3074457345616719169',
			},
		},
	},
};
