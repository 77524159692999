import moment from 'moment';
import { useEffect, useState } from 'react';

interface IUseTimer {
	time: string;
	days: string;
	hours: string;
	minutes: string;
	seconds: string;
}

const useTimer = (date?: string): IUseTimer => {
	const [time, setTime] = useState('');
	const [days, setDays] = useState('00');
	const [hours, setHours] = useState('00');
	const [minutes, setMinutes] = useState('00');
	const [seconds, setSeconds] = useState('00');

	useEffect(() => {
		let time: NodeJS.Timeout;
		if (moment(date).isValid()) {
			time = setInterval(() => {
				const duration = moment.duration(moment().diff(moment(date)));
				const _days = String(duration.days() * -1);
				const _hours = moment(duration.hours(), 'hours').format('HH');
				const _minutes = moment(duration.minutes(), 'minutes').format('mm');
				const _seconds = moment(duration.seconds(), 'seconds').format('ss');
				setTime(`${_days}:${_hours}:${_minutes}:${_seconds}`);
				setDays(_days);
				setHours(_hours);
				setMinutes(_minutes);
				setSeconds(_seconds);
			}, 1000);
		}

		return () => clearInterval(time);
	}, []);

	return { time, days, hours, minutes, seconds };
};

export default useTimer;
