import styled from 'styled-components';
import { MOBILE_NAVBAR_HEIGHT, NAVBAR_HEIGHT, SMALL_NAVBAR_HEIGHT } from '@Layouts/Dashboard/Dashboard';
import { Col, Container } from 'react-grid-system';
import { isRTL, matchScreen } from '@Utils/layout/layoutSupport';
import { ADDITIONAL_BAR_HEIGHT, ADDITIONAL_SMALL_BAR_HEIGHT } from '@Components/TelemarketerTopBar/TelemarketerTopBar';
import { ParagraphM } from '@Components/Typography/Typography.styled';
import theme from '@Utils/theme/theme';
import { MegaMenuContext } from '@Components/MegaMenu/MegaMenu';

interface IContainerProps {
	open: boolean;
	height?: number | string;
	minHeight?: number | string;
	activeMenuIndex: number;
	context?: MegaMenuContext;
	mobileMenu: boolean;
	isLogged: boolean;
	prepaidChosenNumber: boolean;
	telemarketer: boolean;
}

interface IOptionButtonProps {
	active?: boolean;
}

export const MegaMenuContainer = styled.div<IContainerProps>`
	position: fixed;
	width: 100%;
	overflow: hidden;
	${({ open, mobileMenu, telemarketer, prepaidChosenNumber, minHeight, context }) => {
		const isHamburger = ['store', 'postpaid', 'prepaid', 'homeservices', 'services'].includes(context || '');
		const isLargeScreen = matchScreen(['xxl', 'xl']) || false;
		const isXsSmMd = matchScreen(['xs', 'sm', 'md']) || false;
		return {
			top: mobileMenu
				? isHamburger
					? 0
					: telemarketer
					? MOBILE_NAVBAR_HEIGHT + ADDITIONAL_SMALL_BAR_HEIGHT
					: MOBILE_NAVBAR_HEIGHT
				: isXsSmMd
				? telemarketer
					? ADDITIONAL_SMALL_BAR_HEIGHT + SMALL_NAVBAR_HEIGHT
					: SMALL_NAVBAR_HEIGHT
				: (isLargeScreen ? NAVBAR_HEIGHT : SMALL_NAVBAR_HEIGHT) +
				  (telemarketer ? ADDITIONAL_BAR_HEIGHT : 0) +
				  (prepaidChosenNumber ? 48 : 0),
			zIndex: mobileMenu ? 999 : 9,
			minHeight: open ? minHeight : 0,
			boxShadow: open
				? '0 7px 8px 0 rgba(0, 0, 0, 0.05), 0 0 0 0 rgba(0, 0, 0, 0), 0 12px 17px 2px rgba(0, 0, 0, 0.04)'
				: 'none',
		};
	}}
	${({ theme, activeMenuIndex, context, isLogged }) => {
		const isSmallDevice = matchScreen(['xs', 'sm']);
		const isXs = matchScreen(['xs']);
		if (['store', 'postpaid', 'prepaid', 'homeservices', 'services'].includes(context || '')) {
			if (isSmallDevice) return { background: theme.colors.white };
			else {
				return {
					background: `linear-gradient(to right, ${
						isRTL()
							? activeMenuIndex >= 0
								? theme.colors.white
								: 'rgba(249, 249, 249)'
							: 'rgba(249, 249, 249)'
					} 50%, ${
						isRTL()
							? 'rgba(249, 249, 249)'
							: activeMenuIndex >= 0
							? theme.colors.white
							: 'rgba(249, 249, 249)'
					} 50%)`,
				};
			}
		} else if (['search'].includes(context || '')) {
			return {
				background: 'rgba(249, 249, 249)',
			};
		} else {
			if (['myomantel'].includes(context || '')) {
				if (isLogged) {
					return {
						background: 'rgba(249, 249, 249)',
					};
				} else if (isXs) {
					return {
						background: `linear-gradient(to bottom, rgba(249, 249, 249) 63.5%, ${theme.colors.white} 36.5%)`,
					};
				}
			}
			return {
				background: `linear-gradient(to right, ${isRTL() ? theme.colors.white : 'rgba(249, 249, 249)'} 50%, ${
					isRTL() ? 'rgba(249, 249, 249)' : theme.colors.white
				} 50%)`,
			};
		}
	}}
	${({ context }) => {
		const isSmallDevice = matchScreen(['xs', 'sm']);
		if (['store', 'postpaid', 'prepaid', 'homeservices', 'services'].includes(context || '')) {
			if (isSmallDevice) {
				return {
					background: theme.colors.white,
				};
			}
		}
	}}
	${({ context }) => {
		const isXlLg = matchScreen(['xl', 'lg']);
		if (['store', 'postpaid', 'prepaid', 'homeservices', 'services'].includes(context || '')) {
			if (isXlLg) {
				return {
					content: '',
				};
			}
		} else {
			return {
				content: '',
			};
		}
	}}
	&:before {
		content: '';
		position: absolute;
		right: 0;
		top: 0;
		height: 100%;
		width: 100%;
		${({ context, activeMenuIndex, isLogged }) => {
			const isXlLg = matchScreen(['xl', 'lg']);
			const isSmallDevice = matchScreen(['xs', 'sm']);
			const isXs = matchScreen(['xs']);

			if (['store', 'postpaid', 'prepaid', 'homeservices', 'services'].includes(context || '')) {
				if (isXlLg) {
					return {
						background: `transparent url(${
							activeMenuIndex >= 0
								? isRTL()
									? 'arabicWhitePattern.png'
									: 'arabicWhitePattern.png'
								: isRTL()
								? 'arabicGreyPattern.png'
								: 'arabicGreyPattern.png'
						}) no-repeat 100% 100%`,
						height: '100%',
					};
				} else if (isSmallDevice) {
					return {
						width: '0',
						height: '0',
					};
				}
			} else if (['search'].includes(context || '')) {
				return {
					background: `transparent url(${
						isRTL() ? 'arabicGreyPattern.png' : 'arabicGreyPattern.png'
					}) no-repeat 100% 100%`,
					height: '100%',
				};
			} else {
				if (['myomantel'].includes(context || '')) {
					if (isLogged) {
						return {
							background: `transparent url(${
								isRTL() ? 'arabicGreyPattern.png' : 'arabicGreyPattern.png'
							}) no-repeat 100% 100%`,
							height: '100%',
						};
					} else if (!isLogged) {
						if (isXs) {
							return {};
						} else {
							return {
								background: `transparent url(${
									isRTL() ? 'arabicWhitePattern.png' : 'arabicWhitePattern.png'
								}) no-repeat 100% 100%`,
								height: '100%',
							};
						}
					}
				}
				return {
					background: `transparent url(${
						isRTL() ? 'arabicWhitePattern.png' : 'arabicWhitePattern.png'
					}) no-repeat 100% 100%`,
					height: '100%',
				};
			}
		}}
		${() => isRTL() && 'transform: scaleX(-1)'}
	}
`;

interface IContentProps {
	open: boolean;
	activeMenuIndex: number;
	isMd?: boolean;
	withAccent?: boolean;
	extendMore?: boolean;
	height?: number | string;
	beforeHeight?: number | string;
	context?: MegaMenuContext;
}

export const Content = styled.div<IContentProps>`
	transition: opacity 0.3s ease-in-out;
	height: 100%;
	background: transparent;
	${({ isMd, extendMore, withAccent, height }) =>
		isMd && withAccent && extendMore
			? {
					height: height || '100%',
			  }
			: {}}
`;

export const PaymentIconWrapper = styled.a`
	width: 100%;
	height: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 8px;
	border-radius: 4px;
	background: ${({ theme }) => theme.colors.primary};
	& > p {
		color: #ffffff;
	}
	&:hover {
		background: #f59616;
	}
`;

export const LeftContainer = styled.div<{ mobileMenu: boolean; isMd: boolean; isSmallDevice: boolean }>`
	padding-top: 8px;
	padding-bottom: 8px;
	display: flex;
	flex-direction: column;
	${({ isMd, isSmallDevice }) =>
		isMd
			? {
					height: 'calc(100% - 68px - 32px)',
					width: '50%',
			  }
			: isSmallDevice
			? { width: '100%' }
			: { width: '320px' }}
`;

export const AdditionalTitleTextWrapper = styled(ParagraphM)`
	cursor: pointer;
	${({ theme }) => ({ color: theme.colors.primary })}
`;

export const AdditionalTitleContainer = styled.div`
	display: flex;
	gap: 5px;
	align-items: center;
	padding: 8px 15px;
	border-radius: 4px;
	&:hover {
		${({ theme }) => {
			return {
				backgroundColor: theme.colors.primary6,
				cursor: 'pointer',
			};
		}}
	}
	&:active {
		${({ theme }) => {
			return {
				backgroundColor: theme.colors.primary15,
			};
		}}
	}
`;

export const ItemsWrapper = styled(Col)<{ mobileMenu: boolean }>`
	${({ mobileMenu }) => ({
		width: mobileMenu ? '100%' : '30%',
		paddingLeft: 16,
		paddingRight: mobileMenu ? 16 : 0,
	})}
`;

export const RightContainer = styled.div<{ mobileMenu: boolean; isMd: boolean; isSmallDevice: boolean }>`
	padding-top: 8px;
	padding-bottom: 8px;
	display: flex;
	flex-direction: column;
	${({ theme, isMd }) => ({
		backgroundColor: theme.colors.white,
		width: isMd ? '50%' : '320px',
	})}
	${({ isMd, isSmallDevice }) =>
		isMd
			? {
					height: 'calc(100% - 68px - 32px)',
					width: '50%',
			  }
			: isSmallDevice
			? { width: '100%' }
			: { width: '320px' }}
`;

export const LeftSectionWrapper = styled.div`
	width: 100%;
`;

export const ContentContainer = styled(Container)<{
	extendMore?: boolean;
	mobileMenu: boolean;
	withAccent: boolean;
	isMd?: boolean;
}>`
	height: 100%;
	position: relative;
`;

export const MobileMenuButtonsWrapper = styled.div`
	background-color: #f9f9f9;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 16px 30px;
	gap: 16px;
	z-index: 202;
`;

export const LanguageButton = styled.button<IOptionButtonProps>`
	cursor: pointer;
	border: none;
	background-color: transparent;
	outline: none;
	color: ${({ theme }) => theme.colors.primary};
	${(props) => props.theme.typography.paragraphS}
`;

export const LanguageButtonWrapper = styled.div`
	cursor: pointer;
	display: flex;
	width: 100%;
	height: 40px;
	align-items: center;
	justify-content: center;
	&:hover {
		background-color: #fcf5eb;
	}
`;
