import styled from 'styled-components';
import { BodyMBold, BodyM, Caption, Subheading, Info, DisplayMBold } from '@Components/Typography/Typography';
import { marginLeft, marginRight } from '@Utils/layout/layoutSupport';

interface ICostWrapperProps {
	fullWidth?: boolean;
	withoutPadding?: boolean;
}
interface ICardWraperProps {
	expanded?: boolean;
}
export const Image = styled.img`
	height: 30px;
	width: 30px;
	${() => marginRight('8px')};
`;

export const TitleWrapper = styled(DisplayMBold)`
	margin: 24px 0px;
	text-align: center;
	${({ theme }) => ({
		color: theme.colors.black87,
	})}
`;

export const SubtitleWrapper = styled(BodyM)`
	margin: 8px 40px;
	text-align: center;
	${({ theme }) => {
		return {
			color: theme.colors.black87,
		};
	}};
`;

export const HeaderText = styled(Subheading)`
	${({ theme }) => ({
		color: theme.colors.black87,
	})}
	text-transform: uppercase;
	margin-bottom: 16px;
`;

export const HeaderWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	padding-bottom: 8px;
`;

export const CostWrapper = styled.div<ICostWrapperProps>`
	${({ fullWidth, withoutPadding }) => ({
		width: fullWidth ? '100%' : 'auto',
		paddingBottom: withoutPadding ? 0 : '12px',
	})}
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

export const CostTitle = styled(BodyMBold)`
	${({ theme }) => ({
		color: theme.colors.black87,
	})}
`;

export const ButtonWrapper = styled.div`
	padding-top: 4px;
	padding-bottom: 16px;
`;

export const SecureTransactionWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 15px;
`;

export const SecureTransactionText = styled(Caption)`
	${() => marginLeft(8)}
	${({ theme }) => ({
		color: theme.colors.black54,
	})}
`;

export const ButtonContent = styled.div<ICardWraperProps>`
	${({ expanded }) => !expanded && { flexGrow: 1 }}
`;

export const PriceWrapper = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
`;

export const PriceContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-end;
`;

export const VatInfo = styled(Info)`
	${({ theme }) => ({ color: theme.colors.black54 })}
`;

export const HighlightedText = styled.span`
	${({ theme }) => ({
		color: theme.colors.primary,
	})}
`;

export const InsuranceProvider = styled(Subheading)`
	${({ theme }) => ({
		color: theme.colors.black87,
	})}
	margin-bottom: 5px;
`;

export const InsuranceProviderWrapper = styled(Subheading)`
	display: flex;
	align-items: center;
	${({ theme }) => {
		return {
			color: theme.colors.black54,
		};
	}};
`;

export const InsuranceOfferDetails = styled(Caption)`
	${({ theme }) => ({
		color: theme.colors.black54,
	})}
`;

export const TCLink = styled.span`
	${({ theme }) => ({
		color: theme.colors.primary,
		textDecoration: 'underline',
		cursor: 'pointer',
	})}
`;

export const TCWrapper = styled.div`
	display: flex;
	margin: 15px 0;
	gap: 10px;
`;

export const CardWrapper = styled.div<ICardWraperProps>`
	${({ expanded }) => !expanded && { display: 'flex', gap: 30, justifyContent: 'space-between' }}
`;

export const PriceTCWrapper = styled.div<ICardWraperProps>`
	${({ expanded }) => !expanded && { flexGrow: 1 }}
`;
