import React, { FunctionComponent, useState, useEffect } from 'react';
import {
	HeadTitle,
	AccountCreationProcessWrapper,
	ChangeProviderWrapper,
	ButtonWrapper,
	SubTitle,
	WaitingProviderChangeWrapper,
	SubTitleBold,
	LoaderWrapper,
	SuccessTitle,
	ErrorTitle,
} from './AccountCreationProcess.styled';
import ConditionalRender from '@Components/ConditionalRender/ConditionalRender';
import useTranslate from '@Utils/hooks/useTranslate';
import Icon from '@Components/Icon/Icon';
import CircularLoader from '@Components/CircularLoader/CircularLoader';
import { SecondaryLargeButtonFlex } from '@Components/controls/Button/Button';
import { useHistory } from 'react-router-dom';
import { getLang } from '@Utils/language/language';
import { MicrosoftTenantService } from '@Services/digitalServices/omantelTenant';
import { useCart } from '@Utils/context/CartContext';
import { ResponseResult } from '@ApiModels/createTenant';
import { IAccountCreationFormFields } from '@Views/MicrosoftRegistration/MicrosoftRegistration';
import useScreen from '@Utils/hooks/useScreen';

const SUCCESS_ICON_SIZE = 48;

interface IAccountCreationProcessProps {
	params: {
		microsoftAccountData: IAccountCreationFormFields;
	};
}

const AccountCreationProcess: FunctionComponent<IAccountCreationProcessProps> = ({
	params: { microsoftAccountData },
}) => {
	const [isTenantCreatingInProgress, setIsTenantCreatingInProgress] = useState(true);
	const [isTenantCreated, setIsTenantCreated] = useState(false);
	const history = useHistory();
	const { translate } = useTranslate();
	const { cart } = useCart();
	const { screen } = useScreen();

	const contryCodes = [
		{ text: 'Afghanistan', value: 'AF' },
		{ text: 'Albania', value: 'AL' },
		{ text: 'Algeria', value: 'DZ' },
		{ text: 'Armenia', value: 'AM' },
		{ text: 'Azerbaijan', value: 'AZ' },
		{ text: 'Bahrain', value: 'BH' },
		{ text: 'Belarus', value: 'BY' },
		{ text: 'Bosnia & Herzegovina', value: 'BA' },
		{ text: 'British Indian Ocean Territory', value: 'IO' },
		{ text: 'Egypt', value: 'EG' },
		{ text: 'Georgia', value: 'GE' },
		{ text: 'Iraq', value: 'IQ' },
		{ text: 'Jordan', value: 'JO' },
		{ text: 'Kazakhstan', value: 'KZ' },
		{ text: 'Saudi Arabia', value: 'SA' },
		{ text: 'Kuwait', value: 'KW' },
		{ text: 'Kyrgyzstan', value: 'KG' },
		{ text: 'Lebanon', value: 'LB' },
		{ text: 'Lesotho', value: 'LS' },
		{ text: 'Libya', value: 'LY' },
		{ text: 'North Macedonia', value: 'MK' },
		{ text: 'Mauritania', value: 'MR' },
		{ text: 'Moldova', value: 'MD' },
		{ text: 'Mongolia', value: 'MN' },
		{ text: 'Montenegro', value: 'ME' },
		{ text: 'Morocco', value: 'MA' },
		{ text: 'Mozambique', value: 'MZ' },
		{ text: 'Oman', value: 'OM' },
		{ text: 'Pakistan', value: 'PK' },
		{ text: 'Palestine', value: 'PS' },
		{ text: 'Qatar', value: 'QA' },
		{ text: 'Serbia', value: 'RS' },
		{ text: 'South Africa', value: 'ZA' },
		{ text: 'Eswatini', value: 'SZ' },
		{ text: 'Tajikistan', value: 'TJ' },
		{ text: 'Tunisia', value: 'TN' },
		{ text: 'Turkey', value: 'TR' },
		{ text: 'Turkmenistan', value: 'TM' },
		{ text: 'United Arab Emirates', value: 'AE' },
		{ text: 'Ukraine', value: 'UA' },
		{ text: 'Uzbekistan', value: 'UZ' },
		{ text: 'Yemen', value: 'YE' },
		{ text: 'Zimbabwe', value: 'ZW' },
	];

	function getCountryCode(companyCountry: string): string {
		return contryCodes.find((country) => country.text === companyCountry)?.value || '';
	}

	useEffect(() => {
		if (isTenantCreatingInProgress && cart.orderId) {
			const {
				firstName,
				lastName,
				phoneNumber,
				email,
				companyPostalCode,
				companyCountry,
				companyCity,
				companyState,
				companyName,
				companyAddress,
				domainName,
				companyId,
			} = microsoftAccountData;
			MicrosoftTenantService.createTenant(cart.orderId, {
				firstName,
				lastName,
				postalCode: companyPostalCode,
				country: getCountryCode(companyCountry),
				city: companyCity,
				addressLine1: companyAddress,
				addressLine2: 'test',
				state: companyState,
				phoneNumber,
				qualificationID: '1',
				companyName,
				primaryDomain: domainName,
				primaryDomainDomain: 'pl',
				specialQualification: 'SpecialQualification',
				emailAddress: email,
			}).subscribe(
				(response) => {
					switch (response.result) {
						case ResponseResult.SUCCESS:
							setIsTenantCreated(true);
							setIsTenantCreatingInProgress(false);
							break;
						case ResponseResult.FAULT_CREATE_TENANT:
							setIsTenantCreatingInProgress(false);
							break;
						default:
							break;
					}
				},
				() => {
					setIsTenantCreatingInProgress(false);
				}
			);
		}
	}, [isTenantCreatingInProgress, cart.orderId]);

	return (
		<AccountCreationProcessWrapper
			sizes={{ isL: screen('lg'), isMd: screen('md'), isSm: screen('sm'), isXs: screen('xs') }}
		>
			<ConditionalRender
				show={isTenantCreatingInProgress}
				onTrue={() => {
					return (
						<WaitingProviderChangeWrapper>
							<HeadTitle>
								{translate('microsoft-automation-proccess.account-creation-process.progress.part-1')}
							</HeadTitle>
							<LoaderWrapper>
								<CircularLoader backgroundColor="transparent" barColor="primary" size={32} />
							</LoaderWrapper>
							<SubTitle>
								{translate('microsoft-automation-proccess.account-creation-process.progress.part-2')}
							</SubTitle>
							<SubTitle>
								{translate('microsoft-automation-proccess.account-creation-process.progress.part-3')}
								<SubTitleBold>{microsoftAccountData.email}.</SubTitleBold>
							</SubTitle>
						</WaitingProviderChangeWrapper>
					);
				}}
				onFalse={() => (
					<>
						<ConditionalRender
							show={isTenantCreated}
							onTrue={() => (
								<ChangeProviderWrapper>
									<Icon height={SUCCESS_ICON_SIZE} width={SUCCESS_ICON_SIZE} name="success" />
									<SuccessTitle>
										{translate(
											'microsoft-automation-proccess.account-creation-process.success.part-1'
										)}
									</SuccessTitle>
									<SubTitle>
										{translate(
											'microsoft-automation-proccess.account-creation-process.success.part-2'
										)}
									</SubTitle>
									<SubTitle>
										{translate(
											'microsoft-automation-proccess.account-creation-process.success.part-3'
										)}
										<SubTitleBold>{microsoftAccountData.email}</SubTitleBold>
									</SubTitle>
									<ButtonWrapper>
										<SecondaryLargeButtonFlex
											uniqueId="tenant-verification-success.go-to-checkout"
											onClick={() => {
												history.push(`/${getLang()}/checkout/1`);
											}}
											textAlign="center"
										>
											{translate(
												'microsoft-automation-proccess.account-creation-process.success.go-to-checkout'
											)}
										</SecondaryLargeButtonFlex>
									</ButtonWrapper>
								</ChangeProviderWrapper>
							)}
							onFalse={() => (
								<ChangeProviderWrapper>
									<Icon
										height={SUCCESS_ICON_SIZE}
										width={SUCCESS_ICON_SIZE}
										fill="warning"
										name="error"
									/>
									<ErrorTitle>
										{translate(
											'microsoft-automation-proccess.account-creation-process.failure.part-1'
										)}
									</ErrorTitle>
									<SubTitle>
										{translate(
											'microsoft-automation-proccess.account-creation-process.failure.part-2'
										)}
									</SubTitle>
									<SubTitle>
										{translate(
											'microsoft-automation-proccess.account-creation-process.failure.part-3'
										)}
									</SubTitle>
									<ButtonWrapper>
										<SecondaryLargeButtonFlex
											uniqueId="tenant-verification-success.go-to-checkout"
											onClick={() => setIsTenantCreatingInProgress(true)}
											textAlign="center"
										>
											{translate(
												'microsoft-automation-proccess.account-creation-process.failure.try-again'
											)}
										</SecondaryLargeButtonFlex>
									</ButtonWrapper>
								</ChangeProviderWrapper>
							)}
						/>
					</>
				)}
			/>
		</AccountCreationProcessWrapper>
	);
};

export default AccountCreationProcess;
