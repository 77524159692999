import { BodySBold } from '@Components/Typography/Typography.styled';
import styled from 'styled-components';

export const ChoosenAccountBarContainer = styled.div`
	height: 48px;
	width: 100%;
	${({ theme }) => ({
		backgroundColor: theme.colors.grey100,
		boxShadow: `0 7px 8px 0 rgba(0, 0, 0, 0.05), 0 5px 22px 4px ${theme.colors.black4}, 0 12px 17px 2px ${theme.colors.black4}`,
	})}
`;

export const DetailsContainer = styled.div<{ isXS?: boolean }>`
	display: flex;
	align-items: center;
	height: 48px;
	${({ isXS }) => isXS && 'justify-content: space-between'}
`;

export const NumberContainer = styled.div`
	& > p {
		display: inline;
	}
`;

export const LinkWrapper = styled(BodySBold)`
	${({ theme }) => ({
		color: theme.colors.primary,
	})}
	margin: 2px 24px 0 24px;
	text-decoration: underline;
	cursor: pointer;
	user-select: none;
`;
