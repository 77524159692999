import { STORE_ID } from '@Config/app.config';
import { getLanguageCode } from '@Utils/language/language';

interface IFunctionalEndpointConfig extends types.endpoints.functionalEndpointConfig {}

export const createBuyNowPayLaterSession: IFunctionalEndpointConfig = () => {
	return {
		method: 'POST',
		url: `wcs/resources/store/${STORE_ID}/bnpl/createSession?langId=${getLanguageCode()}`,
	};
};

export const retriveBuyNowPayLaterSession: IFunctionalEndpointConfig = () => {
	return {
		method: 'POST',
		url: `wcs/resources/store/${STORE_ID}/bnpl/retriveSession`,
	};
};
