import React, { FunctionComponent } from 'react';
import Card from '@Components/Card/Card';

const ModalProductAvailabilityCard: FunctionComponent<types.cards.IProductAvailabilityCardProps['params']> = ({
	cities,
	availablePlaces,
	product,
}) => {
	return (
		<Card<types.cards.IProductAvailabilityCardProps>
			card="productAvailability"
			params={{ cities, availablePlaces, product }}
			withoutContainerPadding
		/>
	);
};

export default ModalProductAvailabilityCard;
