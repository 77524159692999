import React from 'react';
import useTranslate from '@Utils/hooks/useTranslate';
import { Row } from 'react-grid-system';
import {
	InsuranceOfferCardWraper,
	Image,
	ImageWrapper,
	InsuranceTitleWrapper,
	ChipsWrapper,
	SectionTitle,
} from './InsuranceOffer.styled';
import { useInsurance } from '@Utils/context/InsuranceContext';
import MarkChip from '@Components/controls/MarkChip/MarkChip';
import { BodyMBold } from '@Components/Typography/Typography.styled';
import { roundMoney } from '@Utils/converters/roundMoney';
import { modalTypes, setModal } from '@Redux/modules/modal/actions';
import { TitleWrapper } from '@Components/Card/cards/InsuranceSummary/InsuranceSummary.styled';
import { useDispatch } from 'react-redux';
import { getLang } from '@Utils/language/language';
import { useHistory } from 'react-router-dom';
import { VerticalDivider } from '@Components/controls/MarkChip/MarkChip.styled';

const InsuranceOfferProvidersCard = ({
	params: { name, thumbnailImageUrl, offers, features },
}: types.cards.IInsuranceProviderOffersCardProps): JSX.Element => {
	const { translate } = useTranslate();
	const { setSelectedReferenceNo, setSelectedTrackId, buyable, paymentErrorMessage } = useInsurance();
	const dispatch = useDispatch();
	const history = useHistory();

	const handleButtonClick = (referenceNo: string, trackId: string) => {
		if (buyable) {
			setSelectedReferenceNo(referenceNo);
			setSelectedTrackId(trackId);
		} else {
			dispatch(
				setModal({
					data: {
						status: 'error',
						body: <TitleWrapper>{paymentErrorMessage}</TitleWrapper>,
						primaryButtonText: translate('insurance.not-buyable.back'),
						onClickPrimary: () => {
							dispatch(setModal({ closeAllModals: true, type: null }));
						},
						secondaryButtonText: translate('insurance.not-buyable.homepage'),
						onClickSecondary: () => {
							dispatch(setModal({ closeAllModals: true, type: null }));
							history.push(`/${getLang()}/store/`);
						},
					},
					type: modalTypes.STATUS,
					withoutContainer: true,
				})
			);
		}
	};

	return (
		<InsuranceOfferCardWraper>
			<Row>
				<InsuranceTitleWrapper>
					<ImageWrapper>
						<Image src={thumbnailImageUrl} />
					</ImageWrapper>
					<BodyMBold>{name}</BodyMBold>
				</InsuranceTitleWrapper>
			</Row>
			<Row>
				<SectionTitle>{translate('insurance.offer.cover.label')}</SectionTitle>
			</Row>
			<Row>
				<ChipsWrapper>
					{features.map((feature, index) => (
						<MarkChip
							key={`insuranceOfferFeature-${index}`}
							label={feature?.name}
							iconName="check"
							disabled={feature?.isMandatory}
							type="cover"
						/>
					))}
				</ChipsWrapper>
			</Row>
			<Row>
				<SectionTitle>{translate('insurance.offer.region.label')}</SectionTitle>
			</Row>
			<Row>
				<ChipsWrapper>
					{offers.map(({ amount, referenceNo, trackId, title }, index) => (
						<>
							<MarkChip
								key={`insuranceOfferPrice-${index}`}
								type="region"
								label={[roundMoney(amount), translate('omr'), title.split(' ')[0]]}
								disabled={false}
								labelClickable={true}
								onClick={() => handleButtonClick(referenceNo, trackId)}
								iconName="offerRight"
							/>
							<VerticalDivider />
						</>
					))}
				</ChipsWrapper>
			</Row>
		</InsuranceOfferCardWraper>
	);
};

export default InsuranceOfferProvidersCard;
