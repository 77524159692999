import React, { FunctionComponent } from 'react';
import {
	ImageWrapper,
	ListProductCardWrapper,
	ContentWrapper,
	SectionWrapper,
	ColorPickerWrapper,
	IconsSectionWrapper,
	Image,
	LoaderContainer,
	BrandWrapper,
	TitleWrapper,
	Badge,
	BadgeTriangle,
	BadgeRibbon,
	BadgeWrapper,
	TagWrapper,
} from '@Components/Card/cards/ProductCard/subcomponents/ListCategoryCard/ListProductCard.styled';

import { Col, Row } from 'react-grid-system';
import ConditionalRender from '@Components/ConditionalRender/ConditionalRender';
import UnavailableProductTag from '@Components/Card/cards/ProductCard/subcomponents/UnavailableProductTag/UnavailableProductTag';
import { BodyM, BodySBold, Caption, CaptionBold } from '@Components/Typography/Typography';
import ColorPicker from '@Components/controls/ColorPicker/ColorPicker';
import { ColorPickerPosition } from '@Components/controls/ColorPicker/ColorPicker.styles';
import PriceSection, {
	IPricesWithVat,
} from '@Components/Card/cards/ProductCard/subcomponents/PriceSection/PriceSection';
import ActionSection from '@Components/Card/cards/ProductCard/subcomponents/ActionSection/ActionSection';
import IconsSection from '@Components/Card/cards/ProductCard/subcomponents/IconsSection/IconsSection';
import { PriceType, ProductType, TileSize } from '@Components/Card/cards/ProductCard/ProductCard.styled';
import { IItem } from '@ApiModels/productsByCategoryId';
import { IAvailableColorWithId } from '@Components/controls/ColorPicker/subcomponents/Slider';
import { PrimaryCircularLoader } from '@Components/CircularLoader/CircularLoader';
import useTranslate from '@Utils/hooks/useTranslate';
import Divider from '@Components/Divider/Divider';
import Icon from '@Components/Icon/Icon';
import { getLang } from '@Utils/language/language';

interface IListProductCardProps {
	product: IItem;
	selectedColor?: string | null;
	setSelectedColor?: (color: string | null) => void;
	tileSize: TileSize;
	productType: ProductType;
	priceType: PriceType;
	iconsHidden?: boolean;
	availableColors?: IAvailableColorWithId[];
	thumbnailUrl: string;
	layoutChange?: boolean;
	isLoading?: boolean;
	pricesWithVat: IPricesWithVat;
	withoutColorPicker?: boolean;
}

const ListProductCard: FunctionComponent<IListProductCardProps> = ({
	product,
	selectedColor,
	setSelectedColor,
	tileSize,
	productType,
	priceType,
	iconsHidden,
	availableColors,
	thumbnailUrl,
	layoutChange = true,
	isLoading = false,
	pricesWithVat,
	withoutColorPicker,
}) => {
	const { translate } = useTranslate();
	return (
		<ListProductCardWrapper>
			{productType === ProductType.PREORDER && (
				<BadgeWrapper>
					<Badge>
						<BadgeRibbon>
							<CaptionBold color="white">{translate('list.product.card.badge')}</CaptionBold>
						</BadgeRibbon>
						<BadgeTriangle></BadgeTriangle>
					</Badge>
				</BadgeWrapper>
			)}
			<ConditionalRender
				show={!!product.isDigitalProduct && !!product.availableValues?.length}
				onTrue={() => (
					<TagWrapper>
						<Icon
							name={`subscription${getLang().toUpperCase()}` as types.iconNames}
							width={64}
							height={64}
						/>
					</TagWrapper>
				)}
			/>
			<Row>
				<Col xs={12} lg={layoutChange ? 8 : 12}>
					<ContentWrapper>
						<ImageWrapper>
							{isLoading ? (
								<LoaderContainer>
									<PrimaryCircularLoader />
								</LoaderContainer>
							) : (
								<Image src={thumbnailUrl} />
							)}
							<ConditionalRender
								show={productType === ProductType.UNAVAILABLE}
								onTrue={() => <UnavailableProductTag />}
							/>
						</ImageWrapper>
						<SectionWrapper>
							<div>
								<TitleWrapper>
									<BrandWrapper color="black87">{product.manufacturer ?? ''}</BrandWrapper>
									<BodyM color="black87">{product.name}</BodyM>
								</TitleWrapper>
								<ConditionalRender
									show={!!availableColors?.length && !withoutColorPicker}
									onTrue={() => (
										<ColorPickerWrapper>
											<ColorPicker
												colors={availableColors ?? []}
												setSelectedColor={setSelectedColor}
												selectedColor={selectedColor}
												position={ColorPickerPosition.HORIZONTAL}
											/>
										</ColorPickerWrapper>
									)}
								/>
							</div>
							<ConditionalRender
								show={!!product.additionalInfo}
								onTrue={() => (
									<div>
										<Caption color="black54">
											{translate(product.additionalInfo?.label ?? '')}
										</Caption>
										<Divider withoutLine marginTop={4} />
										<BodySBold color="black54">{product.additionalInfo?.value}</BodySBold>
									</div>
								)}
							/>
						</SectionWrapper>
					</ContentWrapper>
				</Col>
				<Col xs={12} lg={layoutChange ? 4 : 12}>
					<SectionWrapper>
						<IconsSectionWrapper>
							<ConditionalRender show={!iconsHidden} onTrue={() => <IconsSection product={product} />} />
						</IconsSectionWrapper>
						<div>
							<PriceSection
								pricesWithVat={pricesWithVat}
								priceType={priceType}
								tileSize={tileSize}
								discount={product.discount}
								productType={productType}
							/>
							<Divider withoutLine marginBottom={16} />
							<ActionSection productType={productType} product={product} iconsHidden />
						</div>
					</SectionWrapper>
				</Col>
			</Row>
		</ListProductCardWrapper>
	);
};

export default ListProductCard;
