import styled from 'styled-components';
import { Subheading, Header, BigCurrency } from '@Components/Typography/Typography.styled';
import { marginRight, paddingLeft, paddingRight, borderRadius } from '@Utils/layout/layoutSupport';
interface IProps {
	disabled: boolean;
	type: string;
}

export const MarkChipWrapper = styled.div<{ labelClickable?: boolean }>`
	${({ labelClickable }) => ({ cursor: labelClickable ? 'pointer' : undefined })}
	display: flex;
	flex-wrap: wrap;
`;

export const ChipWrapper = styled.div<IProps>`
	transition: all 0.1s ease-in-out;
	height: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-bottom: 5px;
	${({ disabled, type, theme }) => {
		const radiusBorder = type === 'cover' ? { borderRadius: '4px' } : borderRadius('4px', '0px', '0px', '4px');
		const margin = marginRight(type === 'cover' ? '6px' : 0);
		return {
			border: `1px solid ${disabled ? theme.colors.black24 : theme.colors.green}`,
			...margin,
			...radiusBorder,
		};
	}}
`;

export const ChipContent = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 8px;
`;

export const Label = styled(Subheading)`
	${({ theme }) => {
		const margin = marginRight(5);
		return {
			color: theme.colors.black87,
			...margin,
		};
	}}
`;

export const Price = styled(Header)`
	${() => paddingLeft('8px')};
	${({ theme }) => {
		const margin = marginRight(5);
		return {
			color: theme.colors.black87,
			...margin,
		};
	}}
`;

export const Currency = styled(BigCurrency)`
	${() => paddingRight('8px')};
	${({ theme }) => {
		const margin = marginRight(5);
		return {
			color: theme.colors.black87,
			...margin,
		};
	}}
`;

export const Region = styled(Subheading)`
	padding: 8px 8px 8px 8px;
	${() => paddingRight('0px')}
	${({ theme }) => {
		const margin = marginRight(5);
		return {
			color: theme.colors.white,
			...margin,
		};
	}}
`;
export const RegionWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	${() => borderRadius('0px', '4px', '4px', '0px')};
	margin-bottom: 5px;
	${({ theme }) => {
		return {
			backgroundColor: theme.colors.green,
		};
	}};
`;

export const IconWrapper = styled.div<IProps>`
	${({ theme, disabled }) => ({
		backgroundColor: disabled ? theme.colors.black24 : theme.colors.green,
		borderRadius: 24,
		padding: 3,
		width: 16,
	})};
`;

export const VerticalDivider = styled.div`
	width: 8px;
	${({ theme }) => ({ backgroundColor: theme.colors.white24 })}
`;
