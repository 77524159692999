import { FileTypes } from '@Components/DragAndDrop/DragAndDrop';
import Icon from '@Components/Icon/Icon';
import { MiniDescription } from '@Components/Typography/Typography.styled';
import useTranslate from '@Utils/hooks/useTranslate';
import React, { FunctionComponent } from 'react';
import { Container, FormatName, PreviewButton } from './FilePreview.styled';

interface IFilePreview {
	icon?: types.iconNames;
	iconSize?: number;
	fileName: string;
	format: string;
	base64File: string;
}

const FilePreview: FunctionComponent<IFilePreview> = ({
	icon = 'file',
	iconSize = 48,
	fileName,
	format,
	base64File,
}) => {
	const { translate } = useTranslate();

	const handlePreviewFile = () => {
		const newTab = window.open();
		const docTemplate = `<!DOCTYPE html><html><head><title>${
			fileName || 'Document preview'
		}</title></head><body><img src="${base64File}" height="100%"  ></body></html>`;

		switch (format) {
			case FileTypes['application/pdf']:
				newTab?.document.write(
					`<!DOCTYPE html><html><head><title>${
						fileName || 'Document preview'
					}</title></head><body styles="height:100%;"><iframe width='100%' height='1200px' src='
							${encodeURI(base64File)}'></iframe></body></html>`
				);
				break;
			case FileTypes['image/jpeg']:
				newTab?.document.write(docTemplate);
				newTab?.document.close();
				break;
			case FileTypes['image/png']:
				newTab?.document.write(docTemplate);
				newTab?.document.close();
				break;
			default:
				console.error('Unsupported file type!');
				break;
		}
	};

	return (
		<Container>
			<Icon name={icon} width={iconSize} height={iconSize} />
			<FormatName iconSize={iconSize} color="white">
				{format.split('/')[1]}
			</FormatName>

			<MiniDescription>{fileName}</MiniDescription>
			<PreviewButton onClick={handlePreviewFile}>{translate('file.preview.name.button')}</PreviewButton>
		</Container>
	);
};

export default FilePreview;
