import { ActionFunction1, Action, createAction } from 'redux-actions';

export interface IBusyIndicatorAction {
	payload: string;
}

export const addBusyIndicator: ActionFunction1<
	IBusyIndicatorAction['payload'],
	Action<IBusyIndicatorAction['payload']>
> = createAction('ADD_BUSY_INDICATOR', (action: IBusyIndicatorAction['payload']) => action);
export const removeBusyIndicator: ActionFunction1<
	IBusyIndicatorAction['payload'],
	Action<IBusyIndicatorAction['payload']>
> = createAction('REMOVE_BUSY_INDICATOR', (action: IBusyIndicatorAction['payload']) => action);
export const clearAllBusyIndicators: ActionFunction1<
	IBusyIndicatorAction['payload'],
	Action<IBusyIndicatorAction['payload']>
> = createAction('CLEAR_ALL_BUSY_INDICATORS');
