import { IMakasibPoints, setMakasibPoints } from './actions';
import immerHandleActions from '@Redux/utils/immerHandleActions';

const init = {
	isTouched: false,
	vouchers: [],
	makasibDiscount: 0,
};

const reducers = {
	[setMakasibPoints.toString()](draft: IMakasibPoints, action: { payload: IMakasibPoints }) {
		const { isTouched, vouchers, makasibDiscount } = action.payload;
		if (isTouched) {
			draft.isTouched = isTouched;
		}
		if (vouchers) {
			draft.vouchers = vouchers;
		}
		if (makasibDiscount) {
			draft.makasibDiscount = makasibDiscount;
		}
		return draft;
	},
};

export default immerHandleActions(reducers, init);
