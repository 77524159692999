import styled from 'styled-components';
import { ITimeRangeItemProps } from './TimeRangeItem';
import { BodySBold, CaptionBigger } from '@Components/Typography/Typography';

export const TimeRangeItemWrapper = styled.div<ITimeRangeItemProps>`
	height: 48px;
	border-radius: 4px;
	flex-grow: 1;

	${({ theme, selected, state }) => ({
		border:
			state === 'Disabled'
				? `solid 1px ${theme.colors.black12};`
				: selected
				? `solid 1.5px ${theme.colors.primary};`
				: `solid 1px ${theme.colors.black87};`,
		backgroundColor: state === 'Disabled' ? theme.colors.black6 : theme.colors.white,
		cursor: state === 'Default' ? 'pointer' : 'auto',
	})};
`;

export const LabelsWrapper = styled.div`
	flex-grow: 0;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	gap: 4px;
	padding: 0;
	padding: 6px;
`;

export const Hours = styled(CaptionBigger)<ITimeRangeItemProps>`
	margin: 0;
	padding: 0;
	${({ theme, state }) => ({ color: state === 'Disabled' ? theme.colors.black38 : theme.colors.black87 })}
`;

export const TimeOfDay = styled(BodySBold)<ITimeRangeItemProps>`
	margin: 0;
	padding: 0;
	${({ theme, state }) => ({ color: state === 'Disabled' ? theme.colors.black38 : theme.colors.black87 })}
`;
