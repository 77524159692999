import React, { FunctionComponent } from 'react';
import {
	SkeletonRow,
	Rectangular,
	DisplayFlex,
	SkeletonColumn,
	RightFlexWrapper,
} from '@Components/Skeleton/Skeleton.styled';

const PlanBarSkeleton: FunctionComponent = () => {
	return (
		<SkeletonRow marginTop={32} marginBottom={26}>
			<DisplayFlex justifyContent="space-beetwen">
				<SkeletonColumn>
					<Rectangular width={120} height={20} marginLeft={16} marginBottom={2} marginTop={2} />
					<Rectangular width={100} height={12} marginLeft={16} marginBottom={2} marginTop={2} />
				</SkeletonColumn>
				<DisplayFlex justifyContent="flex-end">
					<DisplayFlex justifyContent="space-beetwen">
						<Rectangular width={60} height={34} marginBottom={3} marginTop={3} />
						<DisplayFlex
							flexWrap="wrap"
							justifyContent="flex-end"
							alignItems="flex-end"
							marginLeft={2}
							marginRight={16}
						>
							<Rectangular width={32} height={12} marginLeft={16} marginBottom={4} marginTop={4} />
							<Rectangular width={18} height={12} marginLeft={16} marginBottom={2} marginTop={2} />
						</DisplayFlex>
					</DisplayFlex>
				</DisplayFlex>
			</DisplayFlex>
			<DisplayFlex justifyContent="space-beetwen" alignItems="flex-end" marginTop={4}>
				<DisplayFlex>
					<Rectangular width={60} height={15} marginLeft={16} marginBottom={1} marginRight={8} />
					<Rectangular width={50} height={15} marginBottom={1} />
				</DisplayFlex>
				<RightFlexWrapper>
					<Rectangular width={70} height={12} marginBottom={2} marginTop={2} marginRight={16} />
					<Rectangular width={140} height={12} marginBottom={2} marginTop={2} marginRight={16} />
				</RightFlexWrapper>
			</DisplayFlex>
		</SkeletonRow>
	);
};

export default PlanBarSkeleton;
