import React, { FunctionComponent, KeyboardEvent, useEffect, useMemo, useRef, useState, useCallback } from 'react';
import {
	DropdownContainer,
	DropdownFloatingLabel,
	DropdownHeight,
	DropdownType,
	HeaderWrapper,
	IconWrapper,
	LabelType,
	LabelWrapper,
	ListWrapper,
	MessageWrapper,
	Wrapper,
	InputWrapper,
	InputIconWrapper,
} from './Dropdown.styled';
import { Caption, Value, ValueBold } from '@Components/Typography/Typography.styled';
import ConditionalRender from '@Components/ConditionalRender/ConditionalRender';
import Icon from '@Components/Icon/Icon';
import theme from '@Utils/theme/theme';
import DropdownItem, { DROPDOWN_ITEM_HEIGHT } from './subcomponents/DropdownItem/DropdownItem';
import { KeyboardEventKey } from '@Utils/constants/keyboardEventKey';
import { Formik, FormikProps } from 'formik';
import TextField from '../TextField/TextField';

export const MAX_DROPDOWN_HEIGHT = 188;

export const LIST_VERTICAL_PADDING = 8;

export interface IDropdownItem {
	text: string;
	id: string;
	value?: string;
	disabled?: boolean;
	hint?: string;
	prefix?: string;
}

interface IInput {
	fieldValue: string | number;
}

interface IBaseDropdownProps {
	items: IDropdownItem[];
	selectedItem: IDropdownItem | null;
	setSelectedItem: (item: IDropdownItem) => void;
	label?: string;
	placeholder?: string;
	dropdownHeight?: DropdownHeight;
	labelType?: LabelType;
	disabled?: boolean;
	inputFiltering?: boolean;
	inputValue?: string;
	setInputValue?: (value: string) => void;
	error?: boolean;
	message?: string;
	backgroundColor?: types.theme.themeColors;
	dropdownWidth?: number | string;
	dropdownListWidth?: number | string;
	textFieldWidth?: number | string;
	minSearchTextLength?: number;
	maxDisplayedData?: number;
	overflow?: boolean;
	autoScroll?: boolean;
}

interface IAdditionalDropdownProps {
	dropdownType: DropdownType;
}

interface IDropdownProps extends IBaseDropdownProps, IAdditionalDropdownProps {}

const Dropdown: FunctionComponent<IDropdownProps> = ({
	dropdownType,
	dropdownHeight = DropdownHeight.SCROLL,
	message,
	error,
	items,
	selectedItem,
	setSelectedItem,
	inputValue,
	setInputValue,
	disabled,
	labelType = LabelType.BASIC,
	label,
	placeholder,
	backgroundColor = 'white',
	dropdownWidth,
	inputFiltering,
	dropdownListWidth,
	textFieldWidth,
	minSearchTextLength,
	maxDisplayedData,
	autoScroll = true,
	overflow,
}) => {
	const [open, setOpen] = useState<boolean>(false);
	const [activeItem, setActiveItem] = useState(0);
	const wrapperRef = useRef<HTMLDivElement>(null);
	const dropdownRef = useRef<HTMLDivElement>(null);
	const [hovered, setHovered] = useState(false);
	const [filteredItems, setFilteredItems] = useState<IDropdownItem[]>([]);
	const formikRef = useRef<FormikProps<IInput>>(null);

	const initialValues: IInput = {
		fieldValue:
			selectedItem?.text && selectedItem?.text !== null ? selectedItem.text : !!inputValue ? inputValue : '',
	};

	const labelColor: types.theme.themeColors = useMemo(() => {
		if (disabled) {
			return 'black24';
		}
		if (error) {
			return 'warning';
		}
		return 'black54';
	}, [error, disabled]);

	const valueColor: types.theme.themeColors = useMemo(() => {
		if (disabled) {
			return 'black38';
		}
		return 'black87';
	}, [error, disabled, labelType]);

	const displayedItems = useMemo(() => {
		const data =
			filteredItems.length === 0 && formikRef.current?.values.fieldValue.toString() === ''
				? items
				: filteredItems;
		return !!maxDisplayedData ? (data || []).slice(0, maxDisplayedData) : data;
	}, [filteredItems, formikRef?.current?.values?.fieldValue, items]);

	const handleToggle = () => {
		if (!disabled) {
			setOpen((state) => !state);
		}
	};

	const handleClickOutside = (event: MouseEvent) => {
		if (wrapperRef?.current && !wrapperRef?.current?.contains(event.target as HTMLDivElement)) {
			setOpen(false);
		}
	};

	const handleClickItem = (item: IDropdownItem) => {
		if (!item.disabled) {
			if (!!inputFiltering) {
				formikRef.current?.setFieldValue('fieldValue', item.text);
				onInputChangeFiltering({ fieldValue: item.text });
			}
			setSelectedItem(item);
			setOpen(false);
		}
	};

	const handleArrowDown = () => {
		if (!open && !disabled) {
			setOpen(true);
		} else {
			let index = activeItem + 1;
			while (index < items.length && items[index].disabled) {
				index++;
			}
			if (index < items.length) {
				setActiveItem(index);
			}
		}
	};

	const handleArrowUp = () => {
		let index = activeItem - 1;
		while (index >= 0 && items[index].disabled) {
			index--;
		}
		if (index >= 0) {
			setActiveItem(index);
		}
	};

	const handleEnter = () => {
		if (!disabled && items.length > 0 && activeItem < items.length) {
			setSelectedItem(items[activeItem]);
			setOpen(false);
			setActiveItem(0);
		}
	};

	const handleEscape = () => {
		setOpen(false);
		setActiveItem(0);
	};

	const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
		event.preventDefault();
		switch (event.key) {
			case KeyboardEventKey.ARROW_DOWN:
				handleArrowDown();
				break;
			case KeyboardEventKey.ARROW_UP:
				handleArrowUp();
				break;
			case KeyboardEventKey.ENTER:
				handleEnter();
				break;
			case KeyboardEventKey.ESCAPE:
				handleEscape();
				break;
			default:
				return;
		}
	};

	useEffect(() => {
		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	});

	const onInputChangeFiltering = useCallback(
		(values: IInput) => {
			setFilteredItems(
				items.filter((obj) => {
					if (
						obj.text
							.replace(' ', '')
							.toLowerCase()
							.includes(values.fieldValue.toString().replace(' ', '').toLowerCase())
					) {
						return obj;
					}
				})
			);
		},
		[formikRef.current?.values.fieldValue]
	);

	useEffect(() => {
		if (dropdownHeight === DropdownHeight.SCROLL && dropdownRef.current) {
			const scrollTop = dropdownRef.current.scrollTop;
			const bottomPosition = activeItem * DROPDOWN_ITEM_HEIGHT;
			const topPosition = (activeItem + 1) * DROPDOWN_ITEM_HEIGHT + LIST_VERTICAL_PADDING;
			if (bottomPosition < scrollTop) {
				dropdownRef.current.scrollTop = bottomPosition;
			} else if (topPosition > scrollTop + MAX_DROPDOWN_HEIGHT) {
				dropdownRef.current.scrollTop = topPosition - MAX_DROPDOWN_HEIGHT;
			}
		}
	}, [activeItem, dropdownHeight]);

	return (
		<Wrapper dropdownWidth={dropdownWidth}>
			<DropdownContainer
				ref={wrapperRef}
				tabIndex={0}
				onKeyDown={!!inputFiltering ? () => {} : handleKeyDown}
				dropdownWidth={dropdownWidth}
			>
				<ConditionalRender
					show={!!inputFiltering}
					onTrue={() => (
						<>
							<InputWrapper
								onClick={handleToggle}
								onMouseEnter={() => {
									setHovered(true);
								}}
								onMouseLeave={() => {
									if (formikRef.current?.values.fieldValue && setInputValue) {
										setInputValue(formikRef.current?.values.fieldValue.toString());
									}
									setHovered(false);
								}}
							>
								<Formik initialValues={initialValues} onSubmit={() => {}} innerRef={formikRef}>
									{({ values, setFieldValue }) => (
										<>
											<TextField
												width={textFieldWidth}
												error={!!error}
												label={label}
												placeholder={label}
												onChange={(value) => {
													setFieldValue('fieldValue', value);
													if (
														!!minSearchTextLength &&
														String(value)?.length >= minSearchTextLength
													) {
														onInputChangeFiltering({ fieldValue: value });
													} else {
														onInputChangeFiltering({ fieldValue: '' });
													}

													setSelectedItem({} as IDropdownItem);
													setOpen(true);
												}}
												value={values.fieldValue}
												id="fieldValue"
											/>
											<InputIconWrapper dropdownType={dropdownType} open={open} hovered={hovered}>
												<Icon
													name="arrowDown"
													fill={
														(!disabled || dropdownType === DropdownType.SIMPLE
															? theme.colors.black87
															: theme.colors.black24) as types.color
													}
													height={24}
													width={24}
												/>
											</InputIconWrapper>
										</>
									)}
								</Formik>
							</InputWrapper>
							<ListWrapper
								dropdownHeight={dropdownHeight}
								dropdownListWidth={dropdownListWidth}
								open={open}
								ref={dropdownRef}
								overflow
							>
								{displayedItems.map((item, index) => (
									<DropdownItem
										item={item}
										handleClickItem={handleClickItem}
										selectedItem={selectedItem}
										key={`${item.id}${index}`}
										active={activeItem === index}
										setActive={() => (autoScroll ? setActiveItem(index) : {})}
									/>
								))}
							</ListWrapper>
						</>
					)}
					onFalse={() => (
						<>
							<HeaderWrapper
								onClick={handleToggle}
								dropdownType={dropdownType}
								error={!!error}
								disabled={!!disabled}
								labelType={labelType}
								backgroundColor={backgroundColor}
								dropdownWidth={dropdownWidth}
								open={open}
								onMouseEnter={() => {
									setHovered(true);
								}}
								onMouseLeave={() => {
									setHovered(false);
								}}
								withoutLabel={!label}
							>
								<ConditionalRender
									show={
										dropdownType === DropdownType.SIMPLE ||
										labelType === LabelType.FLOATING ||
										(!selectedItem && !placeholder)
									}
									onTrue={() => (
										<LabelWrapper
											labelType={labelType}
											active={!!selectedItem || !!placeholder}
											hovered={hovered}
										>
											<ConditionalRender
												show={labelType === LabelType.BASIC}
												onTrue={() => <Value color={labelColor}>{label}</Value>}
												onFalse={() => (
													<DropdownFloatingLabel
														active={!!selectedItem || !!placeholder}
														error={!!error}
														disabled={!!disabled}
													>
														{label}
													</DropdownFloatingLabel>
												)}
											/>
										</LabelWrapper>
									)}
								/>
								<ConditionalRender
									show={!!selectedItem}
									onTrue={() => <ValueBold color={valueColor}>{selectedItem?.text}</ValueBold>}
								/>
								<ConditionalRender
									show={!selectedItem && !!placeholder}
									onTrue={() => <Value color="black38">{placeholder}</Value>}
								/>
								<IconWrapper dropdownType={dropdownType} open={open} hovered={hovered}>
									<Icon
										name="arrowDown"
										fill={
											(!disabled || dropdownType === DropdownType.SIMPLE
												? theme.colors.black87
												: theme.colors.black24) as types.color
										}
										height={24}
										width={24}
									/>
								</IconWrapper>
							</HeaderWrapper>
							<ListWrapper
								dropdownHeight={dropdownHeight}
								dropdownListWidth={dropdownListWidth}
								open={open}
								ref={dropdownRef}
								overflow
							>
								{items.map((item, index) => (
									<DropdownItem
										item={item}
										handleClickItem={handleClickItem}
										selectedItem={selectedItem}
										key={`${item.id}${index}`}
										active={activeItem === index}
										setActive={() => setActiveItem(index)}
									/>
								))}
							</ListWrapper>
						</>
					)}
				/>
			</DropdownContainer>
			<ConditionalRender
				show={!!message}
				onTrue={() => (
					<MessageWrapper>
						<Caption color={error ? 'warning' : 'black54'}>{message}</Caption>
					</MessageWrapper>
				)}
			/>
		</Wrapper>
	);
};

export const SimpleDropdown: FunctionComponent<IBaseDropdownProps> = (props) => (
	<Dropdown {...props} dropdownType={DropdownType.SIMPLE} />
);

export const RegularDropdown: FunctionComponent<IBaseDropdownProps> = (props) => (
	<Dropdown {...props} dropdownType={DropdownType.REGULAR} />
);

export default Dropdown;
