import styled from 'styled-components';
import { BodyS, ValueBold } from '@Components/Typography/Typography';
import { Row } from 'react-grid-system';

export const SecureCheckoutWrapper = styled.div`
	padding-bottom: 40px;
`;

export const HeaderWrapper = styled.div`
	display: flex;
	padding: 24px 32px 8px;
`;

export const HeaderDescriptionWrapper = styled.div`
	flex: 1;
	padding: 0 32px;
`;

export const HeaderTitle = styled(ValueBold)`
	margin-bottom: 8px;
	${({ theme }) => ({
		color: theme.colors.black87,
	})}
`;

export const HeaderSubtitle = styled(BodyS)`
	${({ theme }) => ({
		color: theme.colors.black54,
	})}
`;

export const ContentWrapper = styled(Row)<{ isSmallScreen: boolean }>`
	flex: 1;
	${({ isSmallScreen }) => ({
		padding: isSmallScreen ? '0 6.25%' : '0 12.5%',
	})}
`;
