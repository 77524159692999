import styled from 'styled-components';
import { marginLeft as layoutMarginLeft, paddingLeft } from '@Utils/layout/layoutSupport';
import { BodyS, Value, Caption } from '@Components/Typography/Typography.styled';
import theme from '@Utils/theme/theme';

interface IAccordion {
	accordionHeight: number;
	isPrepaid?: boolean;
	isTitleExpandable?: boolean;
	customAccordionHeight?: string;
}

interface IAccordionDescription {
	subtitleColor: types.theme.themeColors;
	titleLeftMargin?: number;
	subtitleTopMargin?: string;
}

export const ProductBoxWrapper = styled.div<{ marginRight?: number; isIconExpandable?: boolean }>`
	${({ marginRight }) => ({
		marginRight: marginRight ?? 0,
	})}
	width: 100%;
	&:hover {
		${({ isIconExpandable }) => ({
			backgroundColor: isIconExpandable ? 'unset' : theme.colors.primary6,
		})}
	}
`;

export const ChildernCollapseWrapper = styled.div<{
	expanded: boolean;
	minHeight: number;
	marginLeft?: number;
	isIconExpandable?: boolean;
}>`
	${({ expanded, minHeight = 0, isIconExpandable }) => {
		return {
			maxHeight: expanded ? 2000 : minHeight ?? 0,
			cursor: isIconExpandable ? 'unset' : 'pointer',
		};
	}}
	${({ marginLeft = 0 }) => layoutMarginLeft(marginLeft)}
	overflow: hidden;
	transition: all 0.5s;
`;

export const IconWrapper = styled.div`
	cursor: pointer;
	margin-left: 4px;
`;

export const AccordionWrapper = styled.div<{ withoutPadding: boolean; isIconExpandable?: boolean }>`
	display: flex;
	flex-direction: row;
	align-items: center;
	${({ withoutPadding, isIconExpandable }) => {
		return {
			paddingLeft: withoutPadding ? 0 : 16,
			paddingRight: withoutPadding ? 0 : 16,
			cursor: isIconExpandable ? 'unset' : 'pointer',
		};
	}}
	transition: all 0.1s;
`;

export const AccordionContent = styled.div<IAccordion>`
	flex: 1;
	display: flex;
	align-items: center;
	padding: '16px 0px';
	${({ accordionHeight, isTitleExpandable, customAccordionHeight }) => {
		return {
			height: customAccordionHeight ? customAccordionHeight : accordionHeight,
			minHeight: accordionHeight,
			cursor: isTitleExpandable ? 'pointer' : 'unset',
		};
	}}
`;

export const DescriptionWrapper = styled.div<{ withoutPadding: boolean }>`
	${({ withoutPadding }) => paddingLeft(withoutPadding ? 0 : 16)}
`;

export const AccordionTitle = styled(Value)<{
	expanded: boolean;
	titleColor: types.theme.themeColors;
	constantMargin: boolean;
	extraBold: boolean;
	isOnlyTitle: boolean;
	titleBold: boolean;
	titleLeftMargin?: number;
}>`
	transition: all 0.1s;
	${({ theme, expanded, extraBold, titleBold }) =>
		extraBold
			? { ...theme.typography.bodyMExtraBold }
			: expanded || titleBold
			? { ...theme.typography.valueBold }
			: { ...theme.typography.value }}
	${({ titleLeftMargin }) => layoutMarginLeft(titleLeftMargin ? titleLeftMargin : 0)}
	${({ theme, expanded, titleColor, constantMargin, isOnlyTitle }) => ({
		color: theme.colors[titleColor],
		marginBottom: constantMargin ? 0 : expanded ? 0 : isOnlyTitle ? 0 : 8,
	})}
`;

export const AccordionDescription = styled(BodyS)<IAccordionDescription>`
	${({ theme, subtitleColor, subtitleTopMargin }) => ({
		color: theme.colors[subtitleColor],
		marginTop: subtitleTopMargin ? subtitleTopMargin : 0,
	})}
	${({ titleLeftMargin }) => layoutMarginLeft(titleLeftMargin ? titleLeftMargin : 0)}
`;

export const AccordionOutletDescription = styled(BodyS)<{ subtitleColor: types.theme.themeColors }>`
	margin-top: 8px;
	${({ theme, subtitleColor }) => ({
		color: theme.colors[subtitleColor],
	})}
`;

export const AccordionOutletDescriptionSmall = styled(Caption)<{ subtitleColor: types.theme.themeColors }>`
	margin-top: 8px;
	${({ theme, subtitleColor }) => ({
		color: theme.colors[subtitleColor],
	})}
`;

export const AccordionDescriptionSmall = styled(Caption)<{ subtitleColor: types.theme.themeColors }>`
	${({ theme, subtitleColor }) => ({
		color: theme.colors[subtitleColor],
	})}
`;
