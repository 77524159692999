import { getLang } from '@Utils/language/language';

export interface IProductSuggestionsBySearchTermFromPortalPayload {
	searchTerm: string;
	results?: number;
	scope?: string;
}

export const login = (): types.endpoints.IEndpointConfig => ({
	method: 'POST',
	url: 'auth/secureauth',
});

export const relogin = (): types.endpoints.IEndpointConfig => ({
	method: 'POST',
	url: 'auth/ltpaidentity',
});

export const getProductSuggestionsBySearchTermFromPortal = ({
	searchTerm,
	results,
	scope,
}: IProductSuggestionsBySearchTermFromPortalPayload): types.endpoints.IEndpointConfig => {
	return {
		method: 'GET',
		url: `contenthandler/!ut/p/digest!ZrShuTx7nFu4prtcgz8pGw/searchfeed/search?queryLang=${getLang()}&locale=${getLang()}&resultLang=${getLang()}&query=${searchTerm}&scope=${scope}&start=0&results=${results}&querySuggestionEnabled=true`,
	};
};
