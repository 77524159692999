interface IInputModel {
	_response: {
		masterMsisdn: string;
		msisdn?: string;
	};
}

export interface IValidateOperation {
	masterMsisdn: string;
}

const model: types.models.IInputModel = {
	_response: ['response.validateOperationResponse', {}],
	masterMsisdn: (model: IInputModel) => model._response.msisdn ?? model._response.masterMsisdn,
};

export default model;
