import React, { FunctionComponent, useEffect } from 'react';
import Icon from '@Components/Icon/Icon';
import { ViewChangeButtonContainer, ViewChangeContainer } from './ViewChangeButton.styled';
import { setViewType } from '@Redux/modules/settings/viewType/actions';
import { dispatch } from '@Redux/store';
import { getViewType } from '@Redux/modules/settings/viewType/selectors';
import useScreen from '@Utils/hooks/useScreen';
import ConditionalRender from '@Components/ConditionalRender/ConditionalRender';

const availableButtons: types.redux.settings.viewType[] = ['GRID', 'LIST'];
const ICON_SIZE = 24;

const ViewChangeButton: FunctionComponent = () => {
	const { screen, screenClass } = useScreen();

	useEffect(() => {
		if (['xs', 'sm', 'md'].includes(screenClass)) {
			dispatch(setViewType('GRID'));
		}
	}, [screenClass]);

	const changeView = (type: types.redux.settings.viewType) => {
		dispatch(setViewType(type));
	};

	const getButton = (type: types.redux.settings.viewType, index: number) => {
		const active = getViewType() === type;
		return (
			<ViewChangeButtonContainer key={index} onClick={() => changeView(type)}>
				<Icon
					name={`view${type}` as types.iconNames}
					width={ICON_SIZE}
					height={ICON_SIZE}
					fill={active ? 'primary' : 'black54'}
				/>
			</ViewChangeButtonContainer>
		);
	};

	return (
		<ConditionalRender
			show={screen(['xl', 'lg'])}
			onTrue={() => (
				<ViewChangeContainer>
					{availableButtons.map((button, index) => getButton(button, index))}
				</ViewChangeContainer>
			)}
		/>
	);
};

export default ViewChangeButton;
