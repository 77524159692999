import styled from 'styled-components';

interface IDashboardWrapperProps {
	footerHeight?: number;
}

export const Content = styled.div<IDashboardWrapperProps>`
	position: relative;
	${({ footerHeight, theme }) => ({
		backgroundColor: theme.colors.white,
		paddingBottom: footerHeight,
		minHeight: window.innerHeight - (footerHeight ?? 0),
	})}
`;
