import React, { FunctionComponent } from 'react';
import {
	SkeletonRow,
	SkeletonCardContainer,
	Rectangular,
	DisplayFlex,
	Circle,
	FlexGrow,
	BottomContentWrapper,
} from '@Components/Skeleton/Skeleton.styled';
import { Col, Row } from 'react-grid-system';

const VERTICAL_MARGIN = 16;
const HORIZONTAL_MARGIN = 16;
const CONTAINER_HEIGHT = 172;
const CIRCLE_ITEMS = 4;

const ListProductSkeleton: FunctionComponent = () => {
	return (
		<SkeletonCardContainer
			marginTop={10}
			marginBottom={10}
			marginLeft={HORIZONTAL_MARGIN}
			marginRight={HORIZONTAL_MARGIN}
		>
			<Row>
				<Col xs={12} lg={8}>
					<DisplayFlex>
						<FlexGrow flexGrow={0} marginRight={HORIZONTAL_MARGIN}>
							<Rectangular width={124} height={CONTAINER_HEIGHT} marginBottom={1} marginLeft={12} />
						</FlexGrow>
						<FlexGrow flexGrow={2} marginLeft={HORIZONTAL_MARGIN} paddingTop={20}>
							<DisplayFlex marginBottom={VERTICAL_MARGIN} flexDirection="column" divHeight="100%">
								<DisplayFlex marginBottom={25}>
									<Rectangular width={80} height={15} />
								</DisplayFlex>
								<DisplayFlex>
									<Circle marginRight={HORIZONTAL_MARGIN} />
									<Circle marginRight={HORIZONTAL_MARGIN} />
									{[...Array(Math.floor(Math.random() * Math.floor(CIRCLE_ITEMS)))].map(
										(_, index) => {
											return <Circle key={index} marginRight={HORIZONTAL_MARGIN} />;
										}
									)}
								</DisplayFlex>
								<DisplayFlex flexDirection="column" justifyContent="flex-end" divHeight="100%">
									<Rectangular width={155} height={15} marginBottom={16} />
									<Rectangular width={204} height={15} />
								</DisplayFlex>
							</DisplayFlex>
						</FlexGrow>
					</DisplayFlex>
				</Col>
				<Col xs={12} lg={4}>
					<DisplayFlex divHeight="172">
						<BottomContentWrapper justifyContent="flex-end" alignItems="flex-end">
							<SkeletonRow marginBottom={VERTICAL_MARGIN}>
								<DisplayFlex justifyContent="space-between">
									<Rectangular width={60} height={15} />
									<DisplayFlex>
										<Rectangular width={61} height={24} />
										<Rectangular width={26} height={15} marginLeft={4} />
									</DisplayFlex>
								</DisplayFlex>
							</SkeletonRow>
							<SkeletonRow>
								<Rectangular width="100%" height={40} />
							</SkeletonRow>
						</BottomContentWrapper>
					</DisplayFlex>
				</Col>
			</Row>
		</SkeletonCardContainer>
	);
};

export default ListProductSkeleton;
