export interface ISimDetails {
	phoneNumber: string;
	simSerialNo: string;
	eSIM?: boolean | null;
}

const model: types.models.IInputModel = {
	accounts: 'response.getSimDetailsResponse.accounts',
};

export default model;
