import React, { FunctionComponent } from 'react';
import {
	LabelWrapper,
	PreorderWrapper,
	PriceContainer,
	PriceSectionWrapper,
} from '@Components/Card/cards/ProductCard/subcomponents/PriceSection/PriceSection.styled';
import PriceTag from '@Components/PriceTag/PriceTag';
import { Caption, Discount } from '@Components/Typography/Typography.styled';
import ConditionalRender from '@Components/ConditionalRender/ConditionalRender';
import { PriceType, ProductType, TileSize } from '@Components/Card/cards/ProductCard/ProductCard.styled';
import useTranslate from '@Utils/hooks/useTranslate';
import PriceWithVat from '@Components/PriceWithVat/PriceWithVat';

export interface IPricesWithVat {
	offerPrice: string | undefined;
	listPrice: string;
	taxablePrice: string;
	vatPercent: string;
	vatValue: string;
}
interface IPriceSectionProps {
	pricesWithVat: IPricesWithVat;
	discount?: number;
	priceType: PriceType;
	preorderStartDate?: string;
	tileSize: TileSize;
	marginBottom?: number;
	productType?: string;
}

const PriceSection: FunctionComponent<IPriceSectionProps> = ({
	pricesWithVat,
	discount,
	priceType,
	preorderStartDate,
	tileSize,
	productType,
}) => {
	const { translate } = useTranslate();
	return (
		<PriceSectionWrapper>
			<ConditionalRender
				show={!!preorderStartDate && tileSize === TileSize.BIG}
				onTrue={() => (
					<PreorderWrapper>
						<Caption color="black54">{translate('product.card.preorder.starts')}</Caption>
						&nbsp;
						<Caption color="black87">{preorderStartDate}</Caption>
					</PreorderWrapper>
				)}
				onFalse={() => <div></div>}
			/>
			<ConditionalRender
				show={productType !== ProductType.SALES_LEAD}
				onTrue={() => (
					<PriceContainer>
						<LabelWrapper>
							<Caption color="black54">
								{translate(
									priceType === PriceType.SINGLE
										? 'product.card.price'
										: 'product.card.price.starts.from'
								)}
							</Caption>
							<ConditionalRender
								show={!!discount}
								onTrue={() => (
									<Discount color="primary">
										{translate('product.card.discount', discount || 0)}
									</Discount>
								)}
							/>
							<PriceTag
								price={!!pricesWithVat.listPrice ? pricesWithVat.listPrice : 0}
								size="small"
								color={discount ? 'primary' : 'black87'}
							/>
						</LabelWrapper>
						<PriceWithVat pricesWithVat={pricesWithVat} />
					</PriceContainer>
				)}
			/>
		</PriceSectionWrapper>
	);
};

export default PriceSection;
