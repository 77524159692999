import React, { FunctionComponent } from 'react';
import {
	Label,
	SegmentWrapper,
	SegmentContent,
	IconWrapper,
	LabelBold,
	SmallLabelBold,
	SmallLabel,
} from './Segment.styled';
import ConditionalRender from '@Components/ConditionalRender/ConditionalRender';
import Icon from '@Components/Icon/Icon';

export enum ISegmentSize {
	BIG = 'Big',
	SMALL = 'Small',
	SMALL_WIDE = 'SmallWide',
	EXPANDER_SMALL = 'ExpanderSmall',
	EXPANDER_BIG = 'ExpanderBig',
}

export interface ISegmentProps {
	label: string;
	id: string;
	selected: boolean;
	clickSelected?: boolean;
	disabled?: boolean;
	size?: ISegmentSize;
	icon?: types.iconNames;
	onClick: () => void;
	margin?: number;
}

const Segment: FunctionComponent<ISegmentProps> = ({
	label,
	selected,
	disabled,
	size = ISegmentSize.SMALL,
	icon,
	onClick,
	margin,
	clickSelected = false,
}: ISegmentProps) => {
	const handleOnClick = () => {
		if ((!disabled && !selected) || clickSelected) {
			onClick();
		}
	};

	const isSmall = size === ISegmentSize.SMALL;

	const iconSize = isSmall ? 16 : 24;

	return (
		<SegmentWrapper
			onClick={handleOnClick}
			size={size}
			icon={icon}
			disabled={disabled}
			selected={selected}
			margin={margin}
		>
			<SegmentContent>
				{icon && (
					<IconWrapper size={size}>
						<Icon name={icon} width={iconSize} height={iconSize} />
					</IconWrapper>
				)}
				<ConditionalRender
					show={isSmall}
					onTrue={() => (
						<ConditionalRender
							show={selected}
							onTrue={() => (
								<SmallLabelBold color={disabled ? 'black38' : 'black87'}>
									{label !== '1 TB' ? label.replace('1 T', '1 TB') : label}
								</SmallLabelBold>
							)}
							onFalse={() => (
								<SmallLabel color={disabled ? 'black38' : 'black87'}>
									{label !== '1 TB' ? label.replace('1 T', '1 TB') : label}
								</SmallLabel>
							)}
						/>
					)}
					onFalse={() => (
						<ConditionalRender
							show={selected}
							onTrue={() => (
								<LabelBold color={disabled ? 'black38' : 'black87'}>
									{label !== '1 TB' ? label.replace('1 T', '1 TB') : label}
								</LabelBold>
							)}
							onFalse={() => (
								<Label color={disabled ? 'black38' : 'black87'}>
									{label !== '1 TB' ? label.replace('1 T', '1 TB') : label}
								</Label>
							)}
						/>
					)}
				/>
			</SegmentContent>
		</SegmentWrapper>
	);
};

export default Segment;
