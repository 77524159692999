import api from '@Utils/api';
import { Observable, of } from 'rxjs';
import { ICategories } from '@ApiModels/flatCategories';
import { INestedCategories } from '@ApiModels/nestedCategories';
import { menuResponseMock } from '@Utils/mocks/index';
import { ENABLE_ESHOP_LITE } from '@Config/features.config';
export class CategoriesService {
	public static getFlatCategories(): Observable<types.models.IInputModel & ICategories> {
		if (ENABLE_ESHOP_LITE) {
			return of(menuResponseMock);
		} else {
			return api.omantelShop.getFlatCategories().call();
		}
	}

	public static getNestedCategories(): Observable<INestedCategories> {
		return api.omantelShop.getNestedCategories().call();
	}
}
