import React, { FunctionComponent } from 'react';
import {
	Title,
	ProviderWrapper,
	ChangeProviderWrapper,
	IconWrapper,
	WarningContainer,
	StatusWrapper,
	SubTitle,
	WaitingProviderChangeWrapper,
	SubTitleBold,
	LoaderWrapper,
	ButtonsWrapper,
	BlockSubTitleBold,
} from './ChangeProvider.styled';
import ConditionalRender from '@Components/ConditionalRender/ConditionalRender';
import useTranslate from '@Utils/hooks/useTranslate';
import Icon from '@Components/Icon/Icon';
import { PrimaryLargeButtonFlex, SecondaryLargeButtonFlexNoBorder } from '@Components/controls/Button/Button';
import { CaptionBigger } from '@Components/Typography/Typography.styled';
import CircularLoader from '@Components/CircularLoader/CircularLoader';
import { RegistrationStep, RadioButtonState } from '@Views/MicrosoftRegistration/MicrosoftRegistration';
import useScreen from '@Utils/hooks/useScreen';

interface IChangeProviderProps {
	params: {
		isProviderChangeInProgress: boolean;
		setIsProviderChangeInProgress: (isProviderChangeInProgress: boolean) => void;
		setRegistrationStep: (registratonStep: RegistrationStep) => void;
		handleRadioButtonClick: (activeRadio: RadioButtonState) => void;
	};
}

const WARNING_ICON_SIZE = 64;
const WARNING_RED_ICON_SIZE = 24;
const MICROSOFT_WEBSITE = 'http://microsoft.com';

const ChangeProvider: FunctionComponent<IChangeProviderProps> = ({
	params: { isProviderChangeInProgress, setIsProviderChangeInProgress, setRegistrationStep, handleRadioButtonClick },
}) => {
	const { translate } = useTranslate();
	const { screen } = useScreen();

	return (
		<ProviderWrapper sizes={{ isL: screen('lg'), isMd: screen('md'), isSm: screen('sm'), isXs: screen('xs') }}>
			<ConditionalRender
				show={!isProviderChangeInProgress}
				onTrue={() => {
					return (
						<ChangeProviderWrapper>
							<Title>{translate('microsoft-automation-proccess.change-provider.title')}</Title>
							<IconWrapper>
								<Icon
									name="warning"
									width={WARNING_ICON_SIZE}
									height={WARNING_ICON_SIZE}
									fill="primary"
								/>
							</IconWrapper>
							<SubTitle>{translate('microsoft-automation-proccess.different-provider.part-1')}</SubTitle>
							<SubTitle>
								{translate('microsoft-automation-proccess.different-provider.part-2')}
								<SubTitleBold>
									{translate('microsoft-automation-proccess.different-provider.part-3')}
								</SubTitleBold>
								{translate('microsoft-automation-proccess.different-provider.part-4')}
							</SubTitle>
							<ButtonsWrapper isXs={screen('xs')}>
								<PrimaryLargeButtonFlex
									uniqueId="change-provider.different-provider"
									onClick={() => {
										setIsProviderChangeInProgress(true);
										window.open(MICROSOFT_WEBSITE);
									}}
									textAlign="center"
								>
									{translate('microsoft-automation-proccess.change-provider.button.change-provider')}
								</PrimaryLargeButtonFlex>
								<SecondaryLargeButtonFlexNoBorder
									uniqueId="change-provider.assign-to-different-account"
									onClick={() => {
										handleRadioButtonClick(RadioButtonState.RADIO_BUTTON_VALUE_YES);
										setRegistrationStep(RegistrationStep.EXISTING_ACCOUNT_PROMPT);
									}}
									textAlign="center"
								>
									{translate(
										'microsoft-automation-proccess.change-provider.button.assign-to-different-account'
									)}
								</SecondaryLargeButtonFlexNoBorder>
							</ButtonsWrapper>
						</ChangeProviderWrapper>
					);
				}}
				onFalse={() => {
					return (
						<WaitingProviderChangeWrapper>
							<Title>{translate('microsoft-automation-proccess.change-provider.part-1')}</Title>
							<LoaderWrapper>
								<CircularLoader backgroundColor="transparent" barColor="primary" size={32} />
							</LoaderWrapper>
							<StatusWrapper color="primary">
								{translate('microsoft-automation-proccess.change-provider.part-2')}
							</StatusWrapper>
							<SubTitle>
								<BlockSubTitleBold>
									{translate('microsoft-automation-proccess.change-provider.part-3')}
								</BlockSubTitleBold>
								{translate('microsoft-automation-proccess.change-provider.part-4')}
							</SubTitle>
							<WarningContainer>
								<Icon
									height={WARNING_RED_ICON_SIZE}
									width={WARNING_RED_ICON_SIZE}
									name="warningRedFilled"
								/>
								<CaptionBigger color="warning">
									{translate('microsoft-automation-proccess.change-provider.part-5')}
								</CaptionBigger>
							</WarningContainer>
						</WaitingProviderChangeWrapper>
					);
				}}
			></ConditionalRender>
		</ProviderWrapper>
	);
};

export default ChangeProvider;
