import React, { Fragment, FunctionComponent, useMemo } from 'react';
import {
	ItemWrapper,
	IconWrapper,
	ItemTextWrapper,
	HeaderTextWrapper,
	ActiveItemTextWrapper,
} from '@Components/MegaMenuItem/MegaMenuItem.styled';
import Icon from '@Components/Icon/Icon';
import { Link } from 'react-router-dom';
import { RedirectItem } from '@Components/NavigationBar/NavigationBar.styled';

export enum MegaMenuItemType {
	HEADER = 'header',
	ITEM = 'item',
}

interface IMegaMenuItem {
	name: string;
	onClick?: () => void;
	active?: boolean;
	iconLeft?: types.iconNames;
	iconRight?: types.iconNames;
	type: MegaMenuItemType;
	paddingTop?: number;
	paddingLeft?: number;
	to?: string;
	subCategory?: boolean;
}

const MegaMenuItem: FunctionComponent<IMegaMenuItem> = ({
	name,
	onClick,
	iconLeft,
	iconRight,
	active,
	type,
	paddingTop = 8,
	paddingLeft = 0,
	to = '',
	subCategory,
}) => {
	const TextWrapper = useMemo(() => {
		if (type === MegaMenuItemType.HEADER) {
			return HeaderTextWrapper;
		} else if (type === MegaMenuItemType.ITEM && active) {
			return ActiveItemTextWrapper;
		} else {
			return ItemTextWrapper;
		}
	}, [active, type]);

	const className = useMemo(() => {
		if (type === MegaMenuItemType.HEADER) {
			return 'menuCategory';
		} else if (!subCategory) {
			return 'menuCategory';
		} else if (subCategory) {
			return 'menuSubCategory';
		} else {
			return 'menuSubCategory';
		}
	}, [active, type]);
	const LinkComponent = ({ children }: { children: JSX.Element }) => {
		if (to) {
			return to.includes('http') ? (
				<RedirectItem href={to}>{children}</RedirectItem>
			) : (
				<Link to={to} replace={to.includes('http')}>
					{children}
				</Link>
			);
		}
		return <Fragment>{children}</Fragment>;
	};

	return (
		<LinkComponent>
			<ItemWrapper
				paddingTop={paddingTop}
				paddingLeft={paddingLeft}
				onClick={() => {
					onClick?.();
				}}
				isButton={!!onClick}
			>
				<IconWrapper rotate={iconLeft === 'chevronRight' || iconLeft === 'chevronLeft'}>
					<Icon name={iconLeft} fill="primary" />
				</IconWrapper>
				<TextWrapper className={className}>{name}</TextWrapper>
				<IconWrapper rotate={iconLeft === 'chevronRight' || iconLeft === 'chevronLeft'}>
					<Icon name={iconRight} />
				</IconWrapper>
			</ItemWrapper>
		</LinkComponent>
	);
};

export default MegaMenuItem;
