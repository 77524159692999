import ProductUnavailable from '@Components/Card/cards/MicrosoftRegistration/subcomponents/ProductUnavailable/ProductUnavailable';
import React, { FunctionComponent } from 'react';
import { Col, Container, Row } from 'react-grid-system';
import { CardWrapper } from './MicrosoftError.styled';

const MicrosoftError: FunctionComponent = () => {
	return (
		<Container>
			<Row justify="center">
				<Col width="759px">
					<CardWrapper>
						<ProductUnavailable />
					</CardWrapper>
				</Col>
			</Row>
		</Container>
	);
};

export default MicrosoftError;
