import React, { useState } from 'react';
import Divider from '@Components/Divider/Divider';
import {
	ShortContentWrapper,
	InformationWrapper,
	ProductAvailableWrapper,
	DividerWrapper,
	IconWrapper,
	LabelWrapper,
	LongContentWrapper,
	LongContentLabelWrapper,
	HourLabelWrapper,
	MapWrapper,
	MapElement,
	SmallLabelWrapper,
	WilayatLabelWrapper,
} from './OutletInformation.styled';
import { ParagraphMBold, BodyS, BodySBold } from '@Components/Typography/Typography.styled';
import ConditionalRender from '@Components/ConditionalRender/ConditionalRender';
import useTranslate from '@Utils/hooks/useTranslate';
import Icon from '@Components/Icon/Icon';
import { Col, Row, Hidden, Visible } from 'react-grid-system';
import Map from '@Components/Map/Map';
import { useSelector, useDispatch } from 'react-redux';
import { setOpenOutletModal } from '@Redux/modules/configurator/actions';

interface IWeekDays {
	sunday?: string;
	monday?: string;
	wednesday?: string;
	tuesday?: string;
	thursday?: string;
	friday?: string;
	saturday?: string;
}

interface IOutletInformation {
	outletName: string;
	isProductAvailable: boolean;
	openHours?: IWeekDays;
	id: string;
	latitude: string;
	longitude: string;
	wilayat: string;
	city: string;
	address: string;
}

const CHEVRON_ICON_SIZE = 24;

const OutletInformation = (params: IOutletInformation) => {
	const [outletDetailsOpen, setOutletDetailsOpen] = useState<boolean>(false);
	const { translate } = useTranslate();
	const outletsOpen = useSelector((state: types.redux.IState) => state.configurator.outlets);
	const dispatch = useDispatch();

	const markerPosition = { lat: Number(params.latitude), lng: Number(params.longitude) };

	return (
		<InformationWrapper>
			<ShortContentWrapper>
				<Hidden xs sm>
					<ParagraphMBold>{params.outletName}</ParagraphMBold>
				</Hidden>
				<Visible xs sm>
					<SmallLabelWrapper>
						<BodyS>{params.outletName}</BodyS>
					</SmallLabelWrapper>
				</Visible>
				<ProductAvailableWrapper>
					<ConditionalRender
						show={params.isProductAvailable}
						onTrue={() => (
							<>
								<LabelWrapper>
									<BodyS color="positive">
										{translate('outlet-available.modal.product-available-label')}
									</BodyS>
								</LabelWrapper>
								<Hidden xs>
									<IconWrapper positive={true}>
										<Icon name="success" height={24} width={24} />
									</IconWrapper>
								</Hidden>
								{outletDetailsOpen ? (
									<Icon
										name="chevronUp"
										width={CHEVRON_ICON_SIZE}
										height={CHEVRON_ICON_SIZE}
										onClick={() => {
											setOutletDetailsOpen(false);
											if (outletsOpen) {
												dispatch(
													setOpenOutletModal({
														outlets: [
															...outletsOpen.filter((item) => item.id !== params.id),
															{ id: params.id, isOpen: false },
														],
													})
												);
											}
										}}
									/>
								) : (
									<Icon
										name="chevronDown"
										width={CHEVRON_ICON_SIZE}
										height={CHEVRON_ICON_SIZE}
										onClick={() => {
											setOutletDetailsOpen(true);
											if (outletsOpen) {
												dispatch(
													setOpenOutletModal({
														outlets: [
															...outletsOpen.filter((item) => item.id !== params.id),
															{ id: params.id, isOpen: true },
														],
													})
												);
											}
										}}
									/>
								)}
							</>
						)}
						onFalse={() => (
							<Hidden xs>
								<LabelWrapper>
									<BodyS color="warning">
										{translate('outlet-available.modal.product-unavailable-label')}
									</BodyS>
								</LabelWrapper>
								<IconWrapper positive={false}>
									<Icon name="error" height={24} width={24} fill="warning" />
								</IconWrapper>
							</Hidden>
						)}
					/>
				</ProductAvailableWrapper>
			</ShortContentWrapper>
			<ConditionalRender
				show={outletDetailsOpen}
				onTrue={() => (
					<LongContentWrapper>
						<Col xs={12} sm={12} md={5} lg={5} xl={5}>
							<Visible xs sm>
								<Row>
									<LongContentLabelWrapper>
										<Hidden xs sm>
											<BodySBold>{translate('outlet-available.modal.location-label')}</BodySBold>
										</Hidden>
										<Visible xs sm>
											<BodyS>{translate('outlet-available.modal.location-label')}</BodyS>
										</Visible>
									</LongContentLabelWrapper>
								</Row>
								<Row>
									<MapWrapper>
										<Map
											mapElement={<MapElement />}
											zoom={17}
											zoomControl={true}
											position={markerPosition}
											markerPosition={markerPosition}
											containerElement={<MapElement />}
											locked={false}
										/>
									</MapWrapper>
								</Row>
							</Visible>

							<Row>
								<Col xs={5} sm={5} lg={12} md={12} xl={12}>
									<Row>
										<LongContentLabelWrapper>
											<Hidden xs sm>
												<BodySBold>
													{translate('outlet-available.modal.willayat-label')}
												</BodySBold>
											</Hidden>
											<Visible xs sm>
												<WilayatLabelWrapper>
													<BodyS>{translate('outlet-available.modal.willayat-label')}</BodyS>
												</WilayatLabelWrapper>
											</Visible>
										</LongContentLabelWrapper>
									</Row>
									<Row>
										<BodyS>{params.wilayat}</BodyS>
									</Row>
									<Row>
										<LongContentLabelWrapper>
											<Hidden xs sm>
												<BodySBold>
													{translate('outlet-available.modal.address-label')}
												</BodySBold>
											</Hidden>
											<Visible xs sm>
												<BodyS>{translate('outlet-available.modal.address-label')}</BodyS>
											</Visible>
										</LongContentLabelWrapper>
									</Row>
									<Row>
										<BodyS>{params.address}</BodyS>
									</Row>

									<Hidden sm>
										<Row>
											<LongContentLabelWrapper>
												<Hidden xs>
													<BodySBold>
														{translate('outlet-available.modal.hours-label')}
														{'\xa0'}
													</BodySBold>
												</Hidden>
												<Visible xs>
													<BodyS>
														{translate('outlet-available.modal.hours-label')}
														{'\xa0'}
													</BodyS>
												</Visible>
											</LongContentLabelWrapper>
										</Row>
										<Row>
											<HourLabelWrapper>
												<BodyS>
													{translate('outlet-available.modal.sunday-label')}
													{'\xa0'}
												</BodyS>
												<ConditionalRender
													show={!!params?.openHours?.sunday}
													onTrue={() => <BodyS>{params?.openHours?.sunday}</BodyS>}
													onFalse={() => (
														<BodyS color="warning">
															{translate('outlet-available.modal.closed-label')}
														</BodyS>
													)}
												/>
											</HourLabelWrapper>
										</Row>
										<Row>
											<HourLabelWrapper>
												<BodyS>
													{translate('outlet-available.modal.monday-label')}
													{'\xa0'}
												</BodyS>
												<ConditionalRender
													show={!!params?.openHours?.monday}
													onTrue={() => <BodyS>{params?.openHours?.monday}</BodyS>}
													onFalse={() => (
														<BodyS color="warning">
															{translate('outlet-available.modal.closed-label')}
														</BodyS>
													)}
												/>
											</HourLabelWrapper>
										</Row>
										<Row>
											<HourLabelWrapper>
												<BodyS>
													{translate('outlet-available.modal.tuesday-label')}
													{'\xa0'}
												</BodyS>
												<ConditionalRender
													show={!!params?.openHours?.tuesday}
													onTrue={() => <BodyS>{params?.openHours?.tuesday}</BodyS>}
													onFalse={() => (
														<BodyS color="warning">
															{translate('outlet-available.modal.closed-label')}
														</BodyS>
													)}
												/>
											</HourLabelWrapper>
										</Row>
										<Row>
											<HourLabelWrapper>
												<BodyS>
													{translate('outlet-available.modal.wednesday-label')}
													{'\xa0'}
												</BodyS>
												<ConditionalRender
													show={!!params?.openHours?.wednesday}
													onTrue={() => <BodyS>{params?.openHours?.wednesday}</BodyS>}
													onFalse={() => (
														<BodyS color="warning">
															{translate('outlet-available.modal.closed-label')}
														</BodyS>
													)}
												/>
											</HourLabelWrapper>
										</Row>
										<Row>
											<HourLabelWrapper>
												<BodyS>
													{translate('outlet-available.modal.thursday-label')}
													{'\xa0'}
												</BodyS>
												<ConditionalRender
													show={!!params?.openHours?.thursday}
													onTrue={() => <BodyS>{params?.openHours?.thursday}</BodyS>}
													onFalse={() => (
														<BodyS color="warning">
															{translate('outlet-available.modal.closed-label')}
														</BodyS>
													)}
												/>
											</HourLabelWrapper>
										</Row>
										<Row>
											<HourLabelWrapper>
												<BodyS>
													{translate('outlet-available.modal.friday-label')}
													{'\xa0'}
												</BodyS>
												<ConditionalRender
													show={!!params?.openHours?.friday}
													onTrue={() => <BodyS>{params?.openHours?.friday}</BodyS>}
													onFalse={() => (
														<BodyS color="warning">
															{translate('outlet-available.modal.closed-label')}
														</BodyS>
													)}
												/>
											</HourLabelWrapper>
										</Row>
										<Row>
											<HourLabelWrapper>
												<BodyS>
													{translate('outlet-available.modal.saturday-label')}
													{'\xa0'}
												</BodyS>
												<ConditionalRender
													show={!!params?.openHours?.saturday}
													onTrue={() => <BodyS>{params?.openHours?.saturday}</BodyS>}
													onFalse={() => (
														<BodyS color="warning">
															{translate('outlet-available.modal.closed-label')}
														</BodyS>
													)}
												/>
											</HourLabelWrapper>
										</Row>
									</Hidden>
								</Col>

								<Visible sm>
									<Col xs={7} sm={7}>
										<Row>
											<LongContentLabelWrapper>
												<BodyS>
													{translate('outlet-available.modal.hours-label')}
													{'\xa0'}
												</BodyS>
											</LongContentLabelWrapper>
										</Row>
										<Row>
											<HourLabelWrapper>
												<BodyS>
													{translate('outlet-available.modal.sunday-label')}
													{'\xa0'}
												</BodyS>
												<ConditionalRender
													show={!!params?.openHours?.sunday}
													onTrue={() => <BodyS>{params?.openHours?.sunday}</BodyS>}
													onFalse={() => (
														<BodyS color="warning">
															{translate('outlet-available.modal.closed-label')}
														</BodyS>
													)}
												/>
											</HourLabelWrapper>
										</Row>
										<Row>
											<HourLabelWrapper>
												<BodyS>
													{translate('outlet-available.modal.monday-label')}
													{'\xa0'}
												</BodyS>
												<ConditionalRender
													show={!!params?.openHours?.monday}
													onTrue={() => <BodyS>{params?.openHours?.monday}</BodyS>}
													onFalse={() => (
														<BodyS color="warning">
															{translate('outlet-available.modal.closed-label')}
														</BodyS>
													)}
												/>
											</HourLabelWrapper>
										</Row>
										<Row>
											<HourLabelWrapper>
												<BodyS>
													{translate('outlet-available.modal.tuesday-label')}
													{'\xa0'}
												</BodyS>
												<ConditionalRender
													show={!!params?.openHours?.tuesday}
													onTrue={() => <BodyS>{params?.openHours?.tuesday}</BodyS>}
													onFalse={() => (
														<BodyS color="warning">
															{translate('outlet-available.modal.closed-label')}
														</BodyS>
													)}
												/>
											</HourLabelWrapper>
										</Row>
										<Row>
											<HourLabelWrapper>
												<BodyS>
													{translate('outlet-available.modal.wednesday-label')}
													{'\xa0'}
												</BodyS>
												<ConditionalRender
													show={!!params?.openHours?.wednesday}
													onTrue={() => <BodyS>{params?.openHours?.wednesday}</BodyS>}
													onFalse={() => (
														<BodyS color="warning">
															{translate('outlet-available.modal.closed-label')}
														</BodyS>
													)}
												/>
											</HourLabelWrapper>
										</Row>
										<Row>
											<HourLabelWrapper>
												<BodyS>
													{translate('outlet-available.modal.thursday-label')}
													{'\xa0'}
												</BodyS>
												<ConditionalRender
													show={!!params?.openHours?.thursday}
													onTrue={() => <BodyS>{params?.openHours?.thursday}</BodyS>}
													onFalse={() => (
														<BodyS color="warning">
															{translate('outlet-available.modal.closed-label')}
														</BodyS>
													)}
												/>
											</HourLabelWrapper>
										</Row>
										<Row>
											<HourLabelWrapper>
												<BodyS>
													{translate('outlet-available.modal.friday-label')}
													{'\xa0'}
												</BodyS>
												<ConditionalRender
													show={!!params?.openHours?.friday}
													onTrue={() => <BodyS>{params?.openHours?.friday}</BodyS>}
													onFalse={() => (
														<BodyS color="warning">
															{translate('outlet-available.modal.closed-label')}
														</BodyS>
													)}
												/>
											</HourLabelWrapper>
										</Row>
										<Row>
											<HourLabelWrapper>
												<BodyS>
													{translate('outlet-available.modal.saturday-label')}
													{'\xa0'}
												</BodyS>
												<ConditionalRender
													show={!!params?.openHours?.saturday}
													onTrue={() => <BodyS>{params?.openHours?.saturday}</BodyS>}
													onFalse={() => (
														<BodyS color="warning">
															{translate('outlet-available.modal.closed-label')}
														</BodyS>
													)}
												/>
											</HourLabelWrapper>
										</Row>
									</Col>
								</Visible>
							</Row>
						</Col>

						<Hidden xs sm>
							<Col md={7} lg={7} xl={7}>
								<Row>
									<LongContentLabelWrapper>
										<BodySBold>{translate('outlet-available.modal.location-label')}</BodySBold>
									</LongContentLabelWrapper>
								</Row>
								<Row>
									<MapWrapper>
										<Map
											mapElement={<MapElement />}
											zoom={17}
											zoomControl={true}
											position={markerPosition}
											markerPosition={markerPosition}
											containerElement={<MapElement />}
											locked={false}
										/>
									</MapWrapper>
								</Row>
							</Col>
						</Hidden>
					</LongContentWrapper>
				)}
			/>
			<DividerWrapper>
				<Divider />
			</DividerWrapper>
		</InformationWrapper>
	);
};

export default OutletInformation;
