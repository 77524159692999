import React, { FunctionComponent, useState } from 'react';
import { DatePickerComponent, DatePickerWrapper, DatePickerContainer, Message } from './DatePickerField.styled';
import ConditionalRender from '@Components/ConditionalRender/ConditionalRender';
import moment from 'moment';
import { safeLocale } from '@Utils/date/moment';
import { getLanguage } from '@Utils/language/language';

const DATE_PICKER_DATE_FORMAT = 'd/M/yyyy';
const DEFAULT_FORMAT = 'DD/MM/YYYY';

export interface IDatePickerFieldProps {
	id: string;
	disabled?: boolean;
	error?: boolean;
	message?: string;
	onChange: (value: string) => void;
	value: string | number;
	typography?: keyof types.ITypography;
	width?: number | string;
	height?: number;
	name?: string;
	format?: string;
}

export interface IStringArray {
	[index: number]: string;
}

const DatePickerField: FunctionComponent<IDatePickerFieldProps> = ({
	id,
	disabled,
	error,
	message,
	onChange,
	value,
	typography = 'valueBold',
	width = 320,
	height,
	name,
	format = DEFAULT_FORMAT,
}: IDatePickerFieldProps) => {
	const [active, setActive] = useState(false);

	const dateFromValue = value ? moment(value, format).toDate() : undefined;

	const handleOnFocus = () => {
		setActive(true);
	};

	const onHandleBlur = () => {
		setActive(false);
	};

	const handleCalendarValueDate = (dateVal: Date) => {
		const formettedDate = moment(dateVal).format(format);
		onChange(moment(dateVal).isValid() ? formettedDate : '');
	};

	const handleOnClose = () => {
		setActive(false);
	};

	return (
		<DatePickerContainer width={width} onBlur={onHandleBlur}>
			<DatePickerWrapper
				active={active}
				disabled={!!disabled}
				onClick={handleOnFocus}
				onBlur={onHandleBlur}
				error={!!error}
				message={!!message}
				height={height}
			>
				<DatePickerComponent
					calendarIcon={null}
					clearIcon={null}
					format={DATE_PICKER_DATE_FORMAT}
					onChange={handleCalendarValueDate}
					value={dateFromValue}
					locale={safeLocale(getLanguage())}
					className="calendar-date-picker"
					typography={typography}
					showLeadingZeros={true}
					onCalendarClose={handleOnClose}
				/>
			</DatePickerWrapper>
			<ConditionalRender show={!!message} onTrue={() => <Message error={!!error}>{message}</Message>} />
		</DatePickerContainer>
	);
};

export default DatePickerField;
