import React, { FunctionComponent } from 'react';
import { SkeletonRow, Rectangular } from '@Components/Skeleton/Skeleton.styled';

const Commitions: FunctionComponent = () => {
	return (
		<SkeletonRow>
			<Rectangular width="100%" height={48} />
		</SkeletonRow>
	);
};

export default Commitions;
