import { IPointsConversionElement } from '@ApiModels/getMakasibPointsCalculation';
import Accordion from '@Components/Accordion/Accordion';
import ConditionalRender from '@Components/ConditionalRender/ConditionalRender';
import { PrimaryLargeButtonFixed, SecondaryLargeButtonFlex } from '@Components/controls/Button/Button';
import TextField from '@Components/controls/TextField/TextField';
import PrimaryToggle from '@Components/controls/Toggle/Toggle';
import Divider from '@Components/Divider/Divider';
import Icon from '@Components/Icon/Icon';
import { BodyS, BodySBold, FloatingLabel, ValueBold } from '@Components/Typography/Typography.styled';
import { IAddItemsToCartPayload, OrderFlowService } from '@Services/orderFlow/orderFlow';
import { convertPointsToPrice, convertPriceToPoints } from '@Utils/converters/makasibPointsCalculation';
import { roundMoney } from '@Utils/converters/roundMoney';
import { AuthenticationService } from '@Services/authentication/authenticationService';
import useTranslate from '@Utils/hooks/useTranslate';
import makasibPoints from '@Utils/validators/makasibPoints';
import { Formik, FormikProps } from 'formik';
import React, { FunctionComponent, useEffect, useMemo, useRef, useState } from 'react';
import externalLinks from '@Utils/externalLinks/externalLinks';
import { Col } from 'react-grid-system';
import { useSelector } from 'react-redux';
import {
	AccordionContentWrapper,
	MakasibCardContainer,
	PointsMethodsWrapper,
	PointsPaymentWrapper,
	PointsStatusWrapper,
	TitleSwitchWrapper,
	TitleLabelWrapper,
	XsButtonWrapper,
	VerticalDivider,
	InfoIconWrapper,
	XsColumnWrapper,
	NoMakasibPointsWrapper,
	EarnPointsWrapper,
	NoMakasibPointsLabelsWrapper,
	IconWrapper,
	PayPointsRowWrapper,
	SuccessLabelWrapper,
	FieldWrapper,
	EqualIconWrapper,
	PayPointsLabelWrapper,
	ButtonWrapper,
	Button,
	TextButton,
	BackgroundImageWrapper,
	MakasibWarningContainer,
	RowInfoContainer,
	RowPointsStatusWrapper,
	RowPointsWrapper,
	PayFullXsBoldWrapper,
	PayFullXsWrapper,
	ExpiringSoonWrapper,
} from './PayByMakasibCard.styled';
import { useCart } from '@Utils/context/CartContext';
import { setMakasibPoints } from '@Redux/modules/makasibPoints/actions';
import { dispatch } from '@Redux/store';
import { MAKASIB_POINTS_PRODUCT_ID, MAKASIB_VOUCHER_PRODUCT_ID } from '@Config/app.config';
import TagManager from 'react-gtm-module';
import { ProfileService } from '@Services/profile/profileService';
import useScreen from '@Utils/hooks/useScreen';
import Tooltip from '@Components/Tooltip/Tooltip';

import { getUserId } from '@Redux/modules/api/authentication/selectors';
interface IFormikFields {
	makasibPoints: string;
}

const EXPIRY_ICON_SIZE = 16;
const WARNING_ICON_SIZE = 24;
const ACTIVE_WARNING_ICON_SIZE = 20;
const MAKASIB_PORTAL_LINK =
	'https://portal.omantel.om/Personal/Makasib/Details/!ut/p/z1/jY_fCoIwFMbvfQp7gp2tqfNyM53GYMKybDfhRYhQ1kUEvX3DIEoSOxfnD_w-zvch6_m-56HaDbd8tq8D2b65d21z6y59c0I12tvwABJ4LhhROhUMuNpEJZF0acoA7QYgJoTllGKlM74CjiGOApoBMIzsP3qYKA5z-vUcYI69e2IH7G2TaeFsVlvKC1oSnYRjQOY4dEBmhEwM1oqOgR9BX06mk1zPVf1Iu6Lli8UTi9RBGw!!/?1dmy&urile=wcm%3apath%3a%2FOmantel_EN%2FZ6_000000000000000000000000A0%2FZ6_9228H441LOFAD0A109754F0081%2FZ6_9228H441LGH160AUFSBGCS1OL4%2FZ6_9228H441L8OBD0AUV4AI4P2OC6%2FZ6_0G0AHB82LOEB80ALT7P2G43SP5%2FZ6_0G0AHB82LO6E80AD0I8URN34G6%2FSecond';

const PayByMakasibCard: FunctionComponent<types.cards.ICartMakasibCardProps> = ({
	params: {
		hasGiftCards,
		discoundablePrice,
		hasDigitalProduct,
		setNonLoggedVoucherAccordion,
		setNonLoggedMakasibAccordion,
		nonLoggedMakasibAccordion,
	},
}) => {
	const { translate } = useTranslate();
	const [payAmount, setPayAmount] = useState<boolean>(false);
	const [payFull, setPayFull] = useState<boolean>(false);
	const isLogged = useSelector((state: types.redux.IState) => !!state.api.authentication.signedIn);
	const personalData = useSelector((state: types.redux.IState) => state.api.personalData);
	const [pointsCalculations, setPointsCalculations] = useState<IPointsConversionElement[]>([]);
	const [amountForPay, setAmountForPay] = useState<number | string>();
	const [login, setLogin] = useState<string | number>('');
	const [password, setPassword] = useState<string | number>('');
	const [loading, setLoading] = useState<boolean>(false);
	const [makasibLoading, setMakasibLoading] = useState<boolean>(false);
	const [isError, setIsError] = useState<boolean>(false);
	const [errorMsg, setErrorMsg] = useState<string>('');
	const [maxPaymentAmount, setMaxPaymentAmount] = useState<number>(0);
	const [success, setSuccess] = useState<boolean>(false);
	const isSignedIn = useSelector((state: types.redux.IState) => state.api.authentication.signedIn);
	const { cart, cartItemsDeviceInstallmentSum, cartItemsPlanPriceSum } = useCart();
	const { screen } = useScreen();
	const isXL = screen('xl');
	const formikRef = useRef<FormikProps<IFormikFields>>(null);

	const makasibItem = useMemo(() => {
		return cart.items.find((item) => item.productId === MAKASIB_POINTS_PRODUCT_ID);
	}, [cart.items]);

	const vouchersValue = useMemo(() => {
		let counter = 0;
		cart.items
			.filter((item) => item.productId === MAKASIB_VOUCHER_PRODUCT_ID)
			.forEach((item) => (counter = counter + Number(item.usedVoucherValueInOMR)));
		return counter;
	}, [cart.items]);

	useEffect(() => {
		dispatch(setMakasibPoints({ isTouched: false }));
	}, []);

	useEffect(() => {
		if (nonLoggedMakasibAccordion) {
			setNonLoggedVoucherAccordion(false);
		}
	}, [nonLoggedMakasibAccordion]);

	const getUserProfile = async (): Promise<string> => {
		return new Promise((resolve) => {
			ProfileService.getUserProfile().subscribe(
				(response) => {
					resolve(response.uid);
				},
				() => {
					resolve('');
				}
			);
		});
	};

	const periodPrice = cartItemsPlanPriceSum + cartItemsDeviceInstallmentSum;

	useEffect(() => {
		if (Number(amountForPay) === 0) {
			removeMakasibPoints();
		}
	}, []);

	useEffect(() => {
		if (isSignedIn) {
			OrderFlowService.getMakasibPointsCalculation().subscribe(
				(r: { nontelecomPointsConversion: IPointsConversionElement[] }) => {
					setPointsCalculations(r.nontelecomPointsConversion);
				},
				() => {}
			);
		}
	}, [isSignedIn]);

	const removeMakasibPoints = () => {
		if (!!makasibItem) {
			OrderFlowService.removeSingleCartItem(makasibItem?.orderItemId).subscribe(
				() => {
					TagManager.dataLayer({
						dataLayer: {
							Product_Name: makasibItem?.name,
							event: 'Remove item from cart',
							Logged_in: isSignedIn ? 'Yes' : 'No',
							userId: getUserId(),
						},
					});
				},
				() => {}
			);
		}
	};

	const handleAddMakasibPoints = async (omrValue: string | number) => {
		const loggedUserPhoneNumber = await getUserProfile();
		const preparedLoginNumber = loggedUserPhoneNumber.replace(/[^0-9]+/g, '');
		setMakasibLoading(true);
		const payload: IAddItemsToCartPayload = {
			quantity: '1',
			productId: MAKASIB_POINTS_PRODUCT_ID,
			xitem_planAction: 'MAKASIB_REDEEM',
			xitem_login: preparedLoginNumber,
			xitem_makasibPointsValueInOMR: String(omrValue),
			xitem_makasibPointsQuantity: String(
				convertPriceToPoints(pointsCalculations, Number(omrValue)) > Number(personalData.makasibPoints)
					? Number(personalData.makasibPoints)
					: convertPriceToPoints(pointsCalculations, Number(omrValue))
			),
		};

		if (!!makasibItem) {
			setSuccess(false);
			OrderFlowService.removeSingleCartItem(!!makasibItem ? makasibItem?.orderItemId : '', false).subscribe(
				() => {
					TagManager.dataLayer({
						dataLayer: {
							Product_Name: makasibItem?.name,
							event: 'Remove item from cart',
							Logged_in: isSignedIn ? 'Yes' : 'No',
							userId: getUserId(),
						},
					});
					OrderFlowService.addItemsToCart([payload]).subscribe(
						({}) => {
							setSuccess(true);
							setMakasibLoading(false);
						},
						(e) => {
							setMakasibLoading(false);
						}
					);
				},
				() => {}
			);
		} else {
			OrderFlowService.addItemsToCart([payload]).subscribe(
				({}) => {
					setSuccess(true);
					setMakasibLoading(false);
				},
				() => {
					setMakasibLoading(false);
				}
			);
		}
	};

	const _signIn = () => {
		setLoading(true);
		setIsError(false);
		setErrorMsg('');
		AuthenticationService.login(login.toString(), password.toString()).subscribe(
			() => {
				setLoading(false);
				location.reload();
			},
			() => {
				setIsError(true);
				setLoading(false);
				setErrorMsg(translate('modal.login.invalid.credentials'));
			}
		);
	};

	const forgotPassword = () => {
		window.open(externalLinks('FORGOT_PASSWORD'));
	};

	useEffect(() => {
		if (!!makasibItem && !!formikRef && maxPaymentAmount > 0) {
			if (-Number(makasibItem.price) === maxPaymentAmount) {
				setPayFull(true);
				setSuccess(true);
				setAmountForPay(-Number(makasibItem.price));
			} else {
				setTimeout(() => formikRef.current?.setFieldValue('makasibPoints', -Number(makasibItem.price)), 100);
				setAmountForPay(-Number(makasibItem.price));
				setPayAmount(true);
				setSuccess(true);
			}
		}
	}, [maxPaymentAmount]);

	useEffect(() => {
		if (!cart.items.some((item) => item.productId === MAKASIB_POINTS_PRODUCT_ID)) {
			if (maxPaymentAmount <= 0) {
				setPayAmount(false);
			}
			setPayFull(false);
			setAmountForPay('');
			setTimeout(() => formikRef.current?.setFieldValue('makasibPoints', ''), 100);
			setSuccess(false);
		}
	}, [cart.items, maxPaymentAmount]);

	useEffect(() => {
		if (
			!!personalData.makasibPoints &&
			Number(personalData.makasibPoints) > 0 &&
			discoundablePrice > 0 &&
			maxPaymentAmount === 0 &&
			vouchersValue > 0
		) {
			setMaxPaymentAmount(
				Math.abs(discoundablePrice - vouchersValue) <
					convertPointsToPrice(pointsCalculations, Number(personalData.makasibPoints))
					? Number((discoundablePrice - vouchersValue).toFixed(3))
					: convertPointsToPrice(pointsCalculations, Number(personalData.makasibPoints))
			);
		} else {
			if (
				!!personalData.makasibPoints &&
				Number(personalData.makasibPoints) > 0 &&
				discoundablePrice - vouchersValue > 0
			) {
				setMaxPaymentAmount(
					discoundablePrice - vouchersValue <
						convertPointsToPrice(pointsCalculations, Number(personalData.makasibPoints))
						? Number((discoundablePrice - vouchersValue).toFixed(3))
						: convertPointsToPrice(pointsCalculations, Number(personalData.makasibPoints))
				);
			} else {
				setMaxPaymentAmount(0);
			}
		}
	}, [cart, personalData, discoundablePrice, isLogged, hasGiftCards, pointsCalculations]);

	const getAccordionSubtitle = () => {
		if (!!makasibItem) {
			return translate(
				'cart.makasib.accordion.subtitle',
				roundMoney(Number(-makasibItem.price)),
				convertPriceToPoints(pointsCalculations, Number(-makasibItem.price)) >
					Number(personalData.makasibPoints)
					? Number(personalData.makasibPoints)
					: convertPriceToPoints(pointsCalculations, Number(-makasibItem.price))
			);
		} else if (hasGiftCards || hasDigitalProduct) {
			return translate('cart.makasib.gift-card.warning');
		} else return undefined;
	};

	const makasibError: string | undefined =
		!!amountForPay && (maxPaymentAmount < Number(amountForPay) || Number(amountForPay) > 1000) && !success
			? translate('cart.makasib.errors.up-amount', maxPaymentAmount > 1000 ? 1000 : maxPaymentAmount)
			: !!amountForPay &&
			  convertPriceToPoints(pointsCalculations, Number(amountForPay)) > Number(personalData.makasibPoints) &&
			  !success
			? translate('cart.makasib.errors.lower.amount')
			: undefined;

	return (
		<>
			<MakasibCardContainer isXs={screen(['xs'])} isSm={screen(['sm'])}>
				<ConditionalRender
					show={isLogged}
					onTrue={() => (
						<ConditionalRender
							show={!!personalData.makasibPoints && Number(personalData.makasibPoints) > 0}
							onTrue={() => (
								<Accordion
									subtitleTopMargin="8px"
									subtitleColor="black54"
									titleBold
									iconColor="black87"
									title={translate('cart.makasib.section.title')}
									subtitle={getAccordionSubtitle()}
									icon={!screen(['xs']) ? 'makasibPoints' : undefined}
									constantMargin
									customAccordionHeight="100%"
									iconSize={40}
									isTitleExpandable
									titleLeftMargin={screen(['sm']) ? 0 : screen(['xs']) ? -16 : 16}
								>
									<AccordionContentWrapper isXs={screen(['xs'])} isSm={screen(['sm'])}>
										<Col xl={8.2} lg={12} md={12}>
											<PointsMethodsWrapper isXlLgMd={screen(['xl', 'lg', 'md'])}>
												<PointsPaymentWrapper marginBottom="16px" isXs={screen(['xs'])}>
													<TitleSwitchWrapper>
														<ConditionalRender
															show={payAmount}
															onTrue={() => (
																<BodySBold
																	color={
																		maxPaymentAmount === 0 ? 'black38' : 'black87'
																	}
																>
																	{translate('cart.makasib.method.partial')}
																</BodySBold>
															)}
															onFalse={() => (
																<BodyS
																	color={
																		maxPaymentAmount === 0 ? 'black38' : 'black87'
																	}
																>
																	{translate('cart.makasib.method.partial')}
																</BodyS>
															)}
														/>
														<PrimaryToggle
															disabled={maxPaymentAmount === 0}
															pointerDisabled={maxPaymentAmount === 0}
															active={payAmount}
															onChange={() => {
																if (payAmount) {
																	removeMakasibPoints();
																	setPayAmount(!payAmount), setPayFull(false);
																	setSuccess(false);
																} else {
																	if (payFull) {
																		removeMakasibPoints();
																	}
																	setPayAmount(!payAmount), setPayFull(false);
																	setSuccess(false);
																}
															}}
														/>
													</TitleSwitchWrapper>
													<ConditionalRender
														show={payAmount}
														onTrue={() => (
															<Formik<IFormikFields>
																initialValues={{ makasibPoints: '' }}
																onSubmit={() =>
																	handleAddMakasibPoints(amountForPay ?? 0)
																}
																validationSchema={makasibPoints()}
																innerRef={formikRef}
															>
																{({
																	setFieldValue,
																	values: { makasibPoints },
																	errors: { makasibPoints: makasibPointsError },
																	handleSubmit,
																}) => (
																	<>
																		<PayPointsRowWrapper>
																			<FieldWrapper>
																				<TextField
																					width={
																						screen(['xs'])
																							? '132px'
																							: '142px'
																					}
																					rightText={translate('omr')}
																					id="makasibPoints"
																					onChange={(e) => {
																						if (
																							!(
																								e
																									.toString()
																									.split('.')[1] &&
																								e
																									.toString()
																									.split('.')[1]
																									.length > 3
																							)
																						) {
																							setFieldValue(
																								'makasibPoints',
																								e
																							);
																							setAmountForPay(e);
																						}
																						setSuccess(false);

																						dispatch(
																							setMakasibPoints({
																								isTouched: true,
																							})
																						);
																					}}
																					error={
																						!!makasibPointsError ||
																						!!makasibError
																					}
																					message={
																						makasibPointsError ||
																						makasibError
																					}
																					value={makasibPoints}
																					placeholder={translate(
																						'cart.makasib.amount.placeholder'
																					)}
																					label={translate(
																						'cart.makasib.amount.placeholder'
																					)}
																				/>
																				<EqualIconWrapper isXs={screen(['xs'])}>
																					<Icon
																						name="equal"
																						width={22}
																						height={11}
																					/>
																				</EqualIconWrapper>
																				<ConditionalRender
																					show={
																						!!makasibPoints &&
																						Number(makasibPoints) > 0 &&
																						Number(makasibPoints) < 1000 &&
																						!(
																							!!makasibError ||
																							!!makasibPointsError
																						)
																					}
																					onTrue={() => (
																						<PayPointsLabelWrapper>
																							<FloatingLabel>{`${convertPriceToPoints(
																								pointsCalculations,
																								Number(makasibPoints)
																							)} pts`}</FloatingLabel>
																						</PayPointsLabelWrapper>
																					)}
																				/>
																			</FieldWrapper>
																			<ConditionalRender
																				show={
																					!makasibError &&
																					!makasibPointsError &&
																					!screen(['xs']) &&
																					!success
																				}
																				onTrue={() => (
																					<>
																						<ButtonWrapper>
																							<SecondaryLargeButtonFlex
																								disabled={
																									!(
																										!!makasibPoints &&
																										Number(
																											makasibPoints
																										) > 0
																									) ||
																									!!makasibError ||
																									!!makasibPointsError
																								}
																								leftPadding={16}
																								rightPadding={16}
																								listener={
																									makasibLoading
																								}
																								type="submit"
																								uniqueId="prepaid-to-postpaid.check-offers"
																								onClick={() =>
																									handleSubmit()
																								}
																							>
																								{translate(
																									'cart.makasib.apply.button'
																								)}
																							</SecondaryLargeButtonFlex>
																						</ButtonWrapper>
																					</>
																				)}
																			/>
																		</PayPointsRowWrapper>
																		<ConditionalRender
																			show={
																				!makasibError &&
																				!makasibPointsError &&
																				screen(['xs']) &&
																				!success
																			}
																			onTrue={() => (
																				<XsButtonWrapper>
																					<SecondaryLargeButtonFlex
																						disabled={
																							!(
																								!!makasibPoints &&
																								Number(makasibPoints) >
																									0
																							) ||
																							!!makasibError ||
																							!!makasibPointsError
																						}
																						leftPadding={8}
																						rightPadding={8}
																						listener={makasibLoading}
																						type="submit"
																						uniqueId="prepaid-to-postpaid.check-offers"
																						onClick={() => handleSubmit()}
																					>
																						{translate(
																							'cart.makasib.apply.button'
																						)}
																					</SecondaryLargeButtonFlex>
																				</XsButtonWrapper>
																			)}
																		/>
																	</>
																)}
															</Formik>
														)}
													/>
													<ConditionalRender
														show={success && payAmount}
														onTrue={() => (
															<SuccessLabelWrapper>
																<Icon
																	name="check"
																	height={WARNING_ICON_SIZE}
																	width={WARNING_ICON_SIZE}
																	fill="positive"
																/>
																{translate('cart.makasib.points.success')}
															</SuccessLabelWrapper>
														)}
													/>
												</PointsPaymentWrapper>
												<PointsPaymentWrapper isXs={screen(['xs'])}>
													<TitleSwitchWrapper>
														<ConditionalRender
															show={payFull}
															onTrue={() => (
																<PayFullXsBoldWrapper
																	isXs={screen(['xs'])}
																	color={
																		maxPaymentAmount === 0 ? 'black38' : 'black87'
																	}
																>
																	{maxPaymentAmount === 0
																		? translate(
																				'cart.makasib.method.fully-disabled'
																		  )
																		: translate(
																				'cart.makasib.method.fully',
																				`${maxPaymentAmount} ${translate(
																					'omr'
																				)}`,
																				`${
																					convertPriceToPoints(
																						pointsCalculations,
																						maxPaymentAmount
																					) <
																					Number(personalData.makasibPoints)
																						? convertPriceToPoints(
																								pointsCalculations,
																								maxPaymentAmount
																						  )
																						: Number(
																								personalData.makasibPoints
																						  )
																				}`
																		  )}
																</PayFullXsBoldWrapper>
															)}
															onFalse={() => (
																<PayFullXsWrapper
																	isXs={screen(['xs'])}
																	color={
																		maxPaymentAmount === 0 ? 'black38' : 'black87'
																	}
																>
																	{maxPaymentAmount === 0
																		? translate(
																				'cart.makasib.method.fully-disabled'
																		  )
																		: translate(
																				'cart.makasib.method.fully',
																				`${maxPaymentAmount} ${translate(
																					'omr'
																				)}`,
																				`${
																					convertPriceToPoints(
																						pointsCalculations,
																						maxPaymentAmount
																					) <
																					Number(personalData.makasibPoints)
																						? convertPriceToPoints(
																								pointsCalculations,
																								maxPaymentAmount
																						  )
																						: Number(
																								personalData.makasibPoints
																						  )
																				}`
																		  )}
																</PayFullXsWrapper>
															)}
														/>
														<PrimaryToggle
															disabled={maxPaymentAmount === 0}
															active={payFull}
															pointerDisabled={maxPaymentAmount === 0}
															onChange={() => {
																if (!payFull) {
																	setPayFull(!payFull);
																	setPayAmount(false);
																	setAmountForPay(maxPaymentAmount);
																	void handleAddMakasibPoints(maxPaymentAmount);
																} else {
																	removeMakasibPoints();
																	setSuccess(false);
																	setPayFull(!payFull);
																	setPayAmount(false);
																}
															}}
														/>
													</TitleSwitchWrapper>
													<ConditionalRender
														show={success && payFull}
														onTrue={() => (
															<SuccessLabelWrapper>
																<Icon
																	name="check"
																	height={WARNING_ICON_SIZE}
																	width={WARNING_ICON_SIZE}
																	fill="positive"
																/>
																{translate('cart.makasib.points.success')}
															</SuccessLabelWrapper>
														)}
													/>
												</PointsPaymentWrapper>

												<ConditionalRender
													show={hasGiftCards || hasDigitalProduct}
													onTrue={() => (
														<MakasibWarningContainer isWarning={!!makasibError}>
															<Icon
																fill={!!makasibError ? 'warning' : 'black87'}
																name={!!makasibError ? 'errorFilled' : 'info'}
																height={
																	!!makasibError
																		? ACTIVE_WARNING_ICON_SIZE
																		: WARNING_ICON_SIZE
																}
																width={
																	!!makasibError
																		? ACTIVE_WARNING_ICON_SIZE
																		: WARNING_ICON_SIZE
																}
															/>
															{translate('cart.makasib.gift-card.warning')}
														</MakasibWarningContainer>
													)}
												/>
												<ConditionalRender
													show={periodPrice > 0}
													onTrue={() => (
														<MakasibWarningContainer
															isWarning={!!makasibError}
															isBothWarnings={
																periodPrice > 0 && (hasGiftCards || hasDigitalProduct)
															}
														>
															<Icon
																fill={!!makasibError ? 'warning' : 'black87'}
																name={!!makasibError ? 'errorFilled' : 'info'}
																height={
																	!!makasibError
																		? ACTIVE_WARNING_ICON_SIZE
																		: WARNING_ICON_SIZE
																}
																width={
																	!!makasibError
																		? ACTIVE_WARNING_ICON_SIZE
																		: WARNING_ICON_SIZE
																}
															/>
															{translate('cart.makasib.monthly.warning')}
														</MakasibWarningContainer>
													)}
												/>

												<ConditionalRender
													show={screen(['lg', 'md', 'sm'])}
													onTrue={() => (
														<RowInfoContainer>
															<Divider marginBottom={24} marginTop={24} />
															<RowPointsWrapper
																isXs={screen(['xs'])}
																isSmall={screen(['xs', 'sm'])}
															>
																<RowPointsStatusWrapper isXs={screen(['xs'])}>
																	<TitleLabelWrapper>
																		<BodySBold color="black54">
																			{translate('cart.makasib.points-now.label')}
																		</BodySBold>
																	</TitleLabelWrapper>
																	<BodySBold>{`${Number(
																		personalData.makasibPoints
																	)}`}</BodySBold>
																	<ConditionalRender
																		show={
																			!!personalData.expiryMakasibPoints &&
																			Number(personalData.expiryMakasibPoints) > 0
																		}
																		onTrue={() => (
																			<ExpiringSoonWrapper isXl={screen(['xl'])}>
																				<InfoIconWrapper>
																					<Tooltip
																						isWhiteBackground
																						withPin
																						width={150}
																						text={translate(
																							'cart.makasib.expiry.tooltip',
																							personalData.expiryMakasibPoints ??
																								''
																						)}
																					>
																						<Icon
																							height={EXPIRY_ICON_SIZE}
																							width={EXPIRY_ICON_SIZE}
																							name="msgInfoBackground"
																							fill="warning100"
																						/>
																					</Tooltip>
																				</InfoIconWrapper>
																				<BodyS color="warning100">
																					{translate(
																						'cart.makasib.expiring.points'
																					)}
																				</BodyS>
																				<BodySBold color="warning100">
																					{personalData.expiryMakasibPoints}
																				</BodySBold>
																			</ExpiringSoonWrapper>
																		)}
																	/>
																</RowPointsStatusWrapper>
																<ConditionalRender
																	show={
																		!!amountForPay &&
																		Number(amountForPay) > 0 &&
																		!makasibError
																	}
																	onTrue={() => (
																		<>
																			<RowPointsStatusWrapper
																				isXs={screen(['xs'])}
																				isSmall={screen(['xs', 'sm'])}
																			>
																				<TitleLabelWrapper>
																					<BodySBold color="black54">
																						{translate(
																							'cart.makasib.points-left.label'
																						)}
																					</BodySBold>
																				</TitleLabelWrapper>
																				<BodySBold>{`${
																					Number(personalData.makasibPoints) -
																						convertPriceToPoints(
																							pointsCalculations,
																							Number(amountForPay)
																						) <
																					0
																						? '0'
																						: Number(
																								personalData.makasibPoints
																						  ) -
																						  convertPriceToPoints(
																								pointsCalculations,
																								Number(amountForPay)
																						  )
																				}`}</BodySBold>
																			</RowPointsStatusWrapper>
																		</>
																	)}
																/>
															</RowPointsWrapper>
														</RowInfoContainer>
													)}
												/>

												<ConditionalRender
													show={screen(['xs'])}
													onTrue={() => (
														<RowInfoContainer isXs={screen(['xs'])}>
															<Divider marginBottom={24} marginTop={24} />
															<RowPointsWrapper
																isXs={screen(['xs'])}
																isSmall={screen(['xs', 'sm'])}
															>
																<XsColumnWrapper>
																	<BodySBold color="black54">
																		{translate('cart.makasib.points-now.label')}
																	</BodySBold>
																	<RowPointsStatusWrapper isXs={screen(['xs'])}>
																		<BodySBold>{`${Number(
																			personalData.makasibPoints
																		)}`}</BodySBold>
																	</RowPointsStatusWrapper>

																	<ConditionalRender
																		show={
																			!!personalData.expiryMakasibPoints &&
																			Number(personalData.expiryMakasibPoints) > 0
																		}
																		onTrue={() => (
																			<ExpiringSoonWrapper
																				isXl={screen(['xl'])}
																				isXs={screen(['xs'])}
																			>
																				<InfoIconWrapper>
																					<Tooltip
																						isWhiteBackground
																						withPin
																						width={150}
																						text={translate(
																							'cart.makasib.expiry.tooltip',
																							personalData.expiryMakasibPoints ??
																								''
																						)}
																					>
																						<Icon
																							height={EXPIRY_ICON_SIZE}
																							width={EXPIRY_ICON_SIZE}
																							name="msgInfoBackground"
																							fill="warning100"
																						/>
																					</Tooltip>
																				</InfoIconWrapper>

																				<BodyS color="warning100">
																					{translate(
																						'cart.makasib.expiring.points'
																					)}
																				</BodyS>
																				<BodySBold color="warning100">
																					{personalData.expiryMakasibPoints}
																				</BodySBold>
																			</ExpiringSoonWrapper>
																		)}
																	/>
																</XsColumnWrapper>
																<XsColumnWrapper>
																	<ConditionalRender
																		show={
																			!!amountForPay &&
																			Number(amountForPay) > 0 &&
																			!makasibError
																		}
																		onTrue={() => (
																			<>
																				<BodySBold color="black54">
																					{translate(
																						'cart.makasib.points-left.label'
																					)}
																				</BodySBold>
																				<RowPointsStatusWrapper
																					isXs={screen(['xs'])}
																					isLastElement
																				>
																					<BodySBold>{`${
																						Number(
																							personalData.makasibPoints
																						) -
																							convertPriceToPoints(
																								pointsCalculations,
																								Number(amountForPay)
																							) <
																						0
																							? '0'
																							: Number(
																									personalData.makasibPoints
																							  ) -
																							  convertPriceToPoints(
																									pointsCalculations,
																									Number(amountForPay)
																							  )
																					}`}</BodySBold>
																				</RowPointsStatusWrapper>
																			</>
																		)}
																	/>
																</XsColumnWrapper>
															</RowPointsWrapper>
														</RowInfoContainer>
													)}
												/>
											</PointsMethodsWrapper>
										</Col>
										<ConditionalRender
											show={isXL}
											onTrue={() => (
												<>
													<Col>
														<VerticalDivider />
													</Col>
													<Col xl={2.8}>
														<PointsStatusWrapper isXl={screen(['xl'])}>
															<BodySBold color="black54">
																{translate('cart.makasib.points-now.label')}
															</BodySBold>
															<BodySBold>{`${Number(
																personalData.makasibPoints
															)}`}</BodySBold>
															<ConditionalRender
																show={
																	!!personalData.expiryMakasibPoints &&
																	Number(personalData.expiryMakasibPoints) > 0
																}
																onTrue={() => (
																	<ExpiringSoonWrapper isXl={screen(['xl'])}>
																		<InfoIconWrapper>
																			<Tooltip
																				isWhiteBackground
																				withPin
																				width={150}
																				text={translate(
																					'cart.makasib.expiry.tooltip',
																					personalData.expiryMakasibPoints ??
																						''
																				)}
																			>
																				<Icon
																					height={EXPIRY_ICON_SIZE}
																					width={EXPIRY_ICON_SIZE}
																					name="msgInfoBackground"
																					fill="warning100"
																				/>
																			</Tooltip>
																		</InfoIconWrapper>
																		<BodyS color="warning100">
																			{translate('cart.makasib.expiring.points')}
																		</BodyS>
																		<BodySBold color="warning100">
																			{personalData.expiryMakasibPoints}
																		</BodySBold>
																	</ExpiringSoonWrapper>
																)}
															/>
														</PointsStatusWrapper>
														<ConditionalRender
															show={
																!!amountForPay &&
																Number(amountForPay) > 0 &&
																!makasibError
															}
															onTrue={() => (
																<>
																	<PointsStatusWrapper isXl={screen(['xl'])}>
																		<BodySBold color="black54">
																			{translate(
																				'cart.makasib.points-left.label'
																			)}
																		</BodySBold>
																		<BodySBold>{`${
																			Number(personalData.makasibPoints) -
																				convertPriceToPoints(
																					pointsCalculations,
																					Number(amountForPay)
																				) <
																			0
																				? '0'
																				: Number(personalData.makasibPoints) -
																				  convertPriceToPoints(
																						pointsCalculations,
																						Number(amountForPay)
																				  )
																		}`}</BodySBold>
																	</PointsStatusWrapper>
																</>
															)}
														/>
													</Col>
												</>
											)}
										/>
									</AccordionContentWrapper>
								</Accordion>
							)}
							onFalse={() => (
								<NoMakasibPointsWrapper isXs={screen(['xs'])}>
									<ConditionalRender
										show={!screen(['xs'])}
										onTrue={() => (
											<IconWrapper>
												<Icon name="makasibPoints" width={40} height={40} />
											</IconWrapper>
										)}
									/>
									<NoMakasibPointsLabelsWrapper>
										<ValueBold>{translate('cart.makasib.section.title')}</ValueBold>
										<BodyS color="black54">{translate('cart.makasib.no-points.label')}</BodyS>
										<EarnPointsWrapper
											color="primary"
											onClick={() => (window.location.href = MAKASIB_PORTAL_LINK)}
										>
											{translate('cart.makasib.no-points.earn')}
										</EarnPointsWrapper>
									</NoMakasibPointsLabelsWrapper>
								</NoMakasibPointsWrapper>
							)}
						/>
					)}
					onFalse={() => (
						<Accordion
							titleBold
							title={translate('cart.makasib.section.title')}
							constantMargin
							iconSize={40}
							customAccordionState={nonLoggedMakasibAccordion}
							enableSubtitleOnExpand={!!nonLoggedMakasibAccordion}
							subtitleTopMargin="8px"
							subtitleColor="black54"
							subtitle={
								(hasGiftCards || hasDigitalProduct) && !nonLoggedMakasibAccordion
									? translate('cart.makasib.gift-card.warning')
									: translate('cart.makasib.log-in')
							}
							isTitleExpandable
							setIsAccordionExpanded={setNonLoggedMakasibAccordion}
							titleLeftMargin={screen(['sm']) ? 0 : screen(['xs']) ? -16 : 16}
							icon={!screen(['xs']) ? 'makasibPoints' : undefined}
						>
							<AccordionContentWrapper isXs={screen(['xs'])} isSm={screen(['sm'])} isNonLogged>
								<Col>
									<TextField
										id="login"
										name="login"
										type="text"
										width={screen(['xs']) ? '100%' : '304px'}
										label={translate('modal.login.phone.number.or.email')}
										placeholder={translate('modal.login.phone.number.or.email')}
										value={login}
										onChange={(login) => {
											setLogin(login);
										}}
										marginBottom={8}
										onEnter={() => _signIn()}
										error={isError}
									/>
									<TextField
										id="1"
										name="password"
										placeholder={translate('modal.login.password')}
										label={translate('modal.login.password')}
										width={screen(['xs']) ? '100%' : '304px'}
										value={password}
										onChange={(password) => {
											setPassword(password);
										}}
										marginBottom={8}
										message={errorMsg}
										error={isError}
										type="password"
										onEnter={() => _signIn()}
									/>
									<Divider marginBottom={8} withoutLine />
									<Button isXs={screen(['xs'])}>
										<PrimaryLargeButtonFixed
											uniqueId="modal.login.sign.in"
											listener={loading}
											onClick={() => _signIn()}
										>
											{translate('modal.login.sign.in')}
										</PrimaryLargeButtonFixed>
									</Button>
									<Divider marginBottom={16} withoutLine />
									<TextButton onClick={() => forgotPassword()}>
										{translate('modal.login.forgot.password')}
									</TextButton>
									<Divider marginBottom={40} withoutLine />
								</Col>
							</AccordionContentWrapper>
						</Accordion>
					)}
				/>
			</MakasibCardContainer>
			<ConditionalRender
				show={screen(['xl', 'lg', 'md']) && !isLogged && !!nonLoggedMakasibAccordion}
				onTrue={() => (
					<Col>
						<BackgroundImageWrapper>
							<img title="MakasibPattern" src="makasibWatermark.png" width="100%" height="100%" />
						</BackgroundImageWrapper>
					</Col>
				)}
			/>
		</>
	);
};

export default PayByMakasibCard;
