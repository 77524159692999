export interface IService {
	id: number;
	departmentId: number;
	serviceGroupId: number;
	branchId: string;
	name: string;
	availableForAppointment: boolean;
	availableForWalkin: boolean;
}

export interface IGetServices {
	returnValue: IService[];
}

const model: types.models.IInputModel = {
	returnValue: 'response.getServicesResponse.services',
};

export default model;
