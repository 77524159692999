import React, { FunctionComponent } from 'react';
import { CardWrapper } from './MicrosoftRegistration.styled';
import ExistingAccountPrompt from './subcomponents/ExistingAccountPrompt/ExistingAccountPrompt';
import { IMicrosoftRegistrationProps, RegistrationStep } from '@Views/MicrosoftRegistration/MicrosoftRegistration';
import TenatVerificationSuccess from './subcomponents/TenatVerificationSuccess/TenatVerificationSuccess';
import ChangeProvider from './subcomponents/ChangeProvider/ChangeProvider';
import AccountCreationForm from './subcomponents/AccountCreationForm/AccountCreationForm';
import AccountCreationProcess from './subcomponents/AccountCreationProcess/AccountCreationProcess';
import ProductUnavailable from './subcomponents/ProductUnavailable/ProductUnavailable';

const MicrosoftRegistration: FunctionComponent<IMicrosoftRegistrationProps> = ({
	params: {
		activeRadio,
		setActiveRadio,
		handleRadioButtonClick,
		tenantData,
		handleTenantDataSubmit,
		isProviderChangeInProgress,
		setIsProviderChangeInProgress,
		registratonStep,
		setRegistrationStep,
		accountCreationStep,
		formikRef,
		formSteps,
		setIsButtonDisabled,
	},
}) => {
	const existingAccountPrompt = () => (
		<ExistingAccountPrompt
			params={{
				activeRadio,
				setActiveRadio,
				handleRadioButtonClick,
				handleTenantDataSubmit,
				setRegistrationStep,
			}}
		/>
	);
	const changeProvider = () => (
		<ChangeProvider
			params={{
				isProviderChangeInProgress,
				setIsProviderChangeInProgress,
				setRegistrationStep,
				handleRadioButtonClick,
			}}
		/>
	);
	const tenatVerificationSuccess = () => (
		<TenatVerificationSuccess params={{ tenantName: tenantData.tenantName, tenantId: tenantData.tenantId }} />
	);
	const accountCreationForm = () => (
		<AccountCreationForm
			params={{
				accountCreationStep,
				formikRef,
				formSteps,
				setIsButtonDisabled,
			}}
		/>
	);
	const accountCreationProcess = () => (
		<AccountCreationProcess params={{ microsoftAccountData: formikRef.current.values }} />
	);

	const getStepContent = () => {
		switch (registratonStep) {
			case RegistrationStep.EXISTING_ACCOUNT_PROMPT:
				return existingAccountPrompt();
			case RegistrationStep.TENANT_VERIFICATION_SUCCESS:
				return tenatVerificationSuccess();
			case RegistrationStep.PRODUCT_UNAVAILABLE:
				return <ProductUnavailable />;
			case RegistrationStep.CHANGE_PROVIDER:
				return changeProvider();
			case RegistrationStep.ACCOUNT_CREATION_FORM:
				return accountCreationForm();
			case RegistrationStep.ACCOUNT_CREATION_PROCESS:
				return accountCreationProcess();
			default:
				return <></>;
		}
	};

	return <CardWrapper>{getStepContent()}</CardWrapper>;
};

export default MicrosoftRegistration;
