import React, { FunctionComponent, useEffect, useState } from 'react';
import { StoreWrapper } from './MegaMenuPostpaid.styled';
import useTranslate from '@Utils/hooks/useTranslate';
import ConditionalRender from '@Components/ConditionalRender/ConditionalRender';
import { useSelector } from 'react-redux';
import externalLinks from '@Utils/externalLinks/externalLinks';
import MegaMenuMultilevelItem, {
	MegaMenuMultilevelItemType,
} from '@Components/MegaMenuMultilevelItem/MegaMenuMultilevelItem';
import { ItemsWrapper, LeftContainer, LeftSectionWrapper, RightContainer } from '@Components/MegaMenu/MegaMenu.styled';
import { IMenuContent } from '@ApiModels/megaMenuContent';
import { RIGHT_DIVIDER_MARGIN_BOTTOM } from '@Components/MegaMenu/MegaMenu';
import Divider from '@Components/Divider/Divider';
import { matchScreen } from '@Utils/layout/layoutSupport';

interface IMegaMenuMobileProps {
	mobileMenu: boolean;
	isMd: boolean;
	activeMenuIndex: number;
	setActiveMenuIndex: (index: number) => void;
}

const MegaMenuPostpaid: FunctionComponent<IMegaMenuMobileProps> = ({
	mobileMenu,
	isMd,
	activeMenuIndex,
	setActiveMenuIndex,
}) => {
	const [content, setContent] = useState<IMenuContent>();
	const language = useSelector((state: types.redux.IState) => state.settings.language);
	const { translate } = useTranslate();
	const isSmallDevice = matchScreen(['xs', 'sm']);

	useEffect(() => {
		return () => {
			setActiveMenuIndex(-1);
		};
	}, []);

	useEffect(() => {
		setContent({
			rows: [
				{
					label: translate('menu.item.get-plans'),
					link: externalLinks('PERSONAL'),
					children: [
						{
							label: translate('menu.postpaid.item.new-baqati'),
							link: externalLinks('BAQATI_POSTPAID'),
						},
						{
							label: translate('menu.postpaid.item.al-ufuq'),
							link: externalLinks('BAQATI_AL_UFUQ'),
						},
						{
							label: translate('menu.postpaid.item.erada-plans'),
							link: externalLinks('ERADA_BAQATI'),
						},
						{
							label: translate('menu.postpaid.item.aman'),
							link: externalLinks('AMAN'),
						},
					],
				},
				{
					label: translate('menu.postpaid.item.roaming'),
					link: externalLinks('ROAMING_HEADER'),
					children: [
						{
							label: translate('menu.postpaid.item.jawazak-gcc'),
							link: externalLinks('JAWAZAK_GCC'),
						},
						{
							label: translate('menu.postpaid.item.jawazak-world'),
							link: externalLinks('JAWAZAK_WORLD'),
						},
						{
							label: translate('menu.postpaid.item.jawazak-flight'),
							link: externalLinks('JAWAZAK_FLIGHT'),
						},
						{
							label: translate('menu.postpaid.item.roaming-abroad'),
							link: externalLinks('ROAMING'),
						},
					],
				},
				{
					label: translate('menu.postpaid.item.transfer-your-number'),
					link: externalLinks('TRANSFER_YOUR_NUMBER'),
					children: [],
				},
				{
					label: translate('menu.postpaid.item.e-sim'),
					link: externalLinks('ESIM'),
					children: [],
				},
				{
					label: translate('menu.postpaid.item.apple-watch-cellular'),
					link: externalLinks('APPLE_WATCH_CELLULAR'),
					children: [],
				},
				{
					label: translate('menu.postpaid.item.promotions'),
					link: externalLinks('PROMOTIONS_POSTPAID'),
					children: [],
				},
			],
		});
	}, [language]);

	return (
		<StoreWrapper mobileMenu={!!mobileMenu} isMd={isMd}>
			<ConditionalRender
				show={activeMenuIndex < 0 || !isSmallDevice}
				onTrue={() => (
					<LeftContainer mobileMenu={mobileMenu} isMd={isMd} isSmallDevice={isSmallDevice}>
						<>
							<MegaMenuMultilevelItem
								type={MegaMenuMultilevelItemType.HEADER}
								name={translate('menu.item.postpaid')}
								iconRight="arrowRightBox"
							/>
							{content?.rows?.map((row, index) => (
								<LeftSectionWrapper key={index}>
									<ItemsWrapper mobileMenu={mobileMenu} key={row.label}>
										<MegaMenuMultilevelItem
											key={index}
											type={
												row?.children?.length > 0
													? MegaMenuMultilevelItemType.ITEM
													: MegaMenuMultilevelItemType.LINK
											}
											onClick={() => {
												row?.children?.length > 0 && setActiveMenuIndex(index);
											}}
											to={row.link}
											name={row.label}
											paddingLeft={16}
											active={activeMenuIndex === index}
											iconRight={row?.children?.length > 0 ? 'arrowRightBox' : undefined}
										/>
									</ItemsWrapper>
								</LeftSectionWrapper>
							))}
						</>
					</LeftContainer>
				)}
			/>
			<ConditionalRender
				show={activeMenuIndex >= 0}
				onTrue={() => (
					<RightContainer mobileMenu={mobileMenu} isMd={isMd} isSmallDevice={isSmallDevice}>
						<ConditionalRender
							show={!isSmallDevice}
							onTrue={() => <Divider marginBottom={RIGHT_DIVIDER_MARGIN_BOTTOM} withoutLine />}
						/>
						{content?.rows[activeMenuIndex]?.children?.map(({ label, link, additionalLabel }) => (
							<MegaMenuMultilevelItem
								paddingLeft={16}
								key={label}
								type={MegaMenuMultilevelItemType.SUB_ITEM}
								to={link}
								name={label}
								additionalLabel={additionalLabel}
							/>
						))}
					</RightContainer>
				)}
			/>
		</StoreWrapper>
	);
};

export default MegaMenuPostpaid;
