import { SubheadingH2 } from '@Components/Typography/Typography';
import styled from 'styled-components';

export const CategoryCardContainer = styled.div`
	padding: 8px;
`;

export const ButtonWrapper = styled.div`
	padding: 8px;
	div {
		width: 100%;
	}
`;

export const CategoryTitle = styled(SubheadingH2)`
	text-transform: uppercase;
	padding: 24px 8px 8px;
	${({ theme }) => ({
		color: theme.colors.black54,
	})}
`;
