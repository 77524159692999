import React, { FunctionComponent, useEffect, useState, useRef } from 'react';
import { Container, Row, Col } from 'react-grid-system';
import useScreen from '@Utils/hooks/useScreen';
import { ButtonWrapper, ButtonsWrapper } from './MicrosoftRegistration.styled';
import { useHistory } from 'react-router-dom';
import Card from '@Components/Card/Card';
import { QuaternaryMediumButtonFlex, PrimaryMediumButtonFlex } from '@Components/controls/Button/Button';
import { getLang } from '@Utils/language/language';
import ConditionalRender from '@Components/ConditionalRender/ConditionalRender';
import AccountCreationStepper from '@Components/AccountCreationStepper/AccountCreationStepper';
import { MicrosoftTenantService } from '@Services/digitalServices/omantelTenant';
import { ResponseResult as IResponseResultGetTenant } from '@ApiModels/getTenant';
import { useCart } from '@Utils/context/CartContext';
import { FormikErrors, FormikProps } from 'formik';
import { addBusyIndicator, removeBusyIndicator } from '@Redux/modules/busyIndicator/actions';
import { useDispatch } from 'react-redux';
import Divider from '@Components/Divider/Divider';

export interface ITenatFields {
	tenantName: string;
	tenantId: string;
}

export enum RadioButtonState {
	RADIO_BUTTON_VALUE_YES = 'RADIO_BUTTON_VALUE_YES',
	RADIO_BUTTON_VALUE_NO = 'RADIO_BUTTON_VALUE_NO',
	INITIAL = 'INITIAL',
}

export enum ButtonType {
	NEXT = 'NEXT',
	BACK = 'BACK',
}

export enum RegistrationStep {
	EXISTING_ACCOUNT_PROMPT = 'EXISTING_ACCOUNT_PROMPT',
	TENANT_VERIFICATION_SUCCESS = 'TENANT_VERIFICATION_SUCCESS',
	PRODUCT_UNAVAILABLE = 'PRODUCT_UNAVAILABLE',
	CHANGE_PROVIDER = 'CHANGE_PROVIDER',
	ACCOUNT_CREATION_FORM = 'ACCOUNT_CREATION_FORM',
	ACCOUNT_CREATION_PROCESS = 'ACCOUNT_CREATION_PROCESS',
}

export enum AaccountCreationFormStep {
	INITIAL = 0,
	CONTACT_DETAILS = 1,
	COMPANY_DETAILS = 2,
	TENANT_INFO = 3,
}

export interface IAccountCreationFormFields {
	[key: string]: string;
}

export interface IAccountCreationFormTouchedFields {
	[key: string]: boolean;
}

export interface IFormStep {
	label: string;
	fields: IAccountCreationFormFields[];
}

export const formSteps: IFormStep[] = [
	{
		label: 'contactDetails',
		fields: [
			{
				id: 'firstName',
				label: 'First Name',
			},
			{
				id: 'lastName',
				label: 'Last Name',
			},
			{
				id: 'email',
				label: 'E-mail address',
			},
			{
				id: 'phoneNumber',
				label: 'Phone Number',
			},
		],
	},
	{
		label: 'companyDetailsFields',
		fields: [
			{
				id: 'companyName',
				label: 'Full Company Legal Name',
			},
			{
				id: 'companyId',
				label: 'Company Registration ID',
			},
			{
				id: 'companyAddress',
				label: 'Street and house/flat number',
			},
			{
				id: 'companyState',
				label: 'State',
			},
			{
				id: 'companyPostalCode',
				label: 'Postal Code',
			},
			{
				id: 'companyCity',
				label: 'City',
			},
			{
				id: 'companyCountry',
				label: 'Country',
			},
		],
	},
	{
		label: 'tenantInfoFields',
		fields: [
			{
				id: 'domainName',
				label: 'Domain Name',
			},
		],
	},
];

export const GET_TENANT = 'GET_TENANT';
export interface IMicrosoftRegistrationProps {
	params: {
		activeRadio: RadioButtonState;
		setActiveRadio: (activeRadio: RadioButtonState) => void;
		handleRadioButtonClick: (value: string) => void;
		tenantData: ITenatFields;
		isTenantValid: boolean;
		handleTenantDataSubmit: (tenantData: ITenatFields) => void;
		isProviderChangeInProgress: boolean;
		setIsProviderChangeInProgress: (isProviderChangeInProgress: boolean) => void;
		isProviderChanged: boolean;
		setIsProviderChanged: (isProviderChanged: boolean) => void;
		registratonStep: RegistrationStep;
		setRegistrationStep: (registratonStep: RegistrationStep) => void;
		accountCreationStep: AaccountCreationFormStep;
		setAccountCreationStep: (accountCreationStep: AaccountCreationFormStep) => void;
		formikRef: React.MutableRefObject<FormikProps<IAccountCreationFormFields>>;
		formSteps: IFormStep[];
		setIsButtonDisabled: (isButtonDisabled: boolean) => void;
	};
}

const MicrosoftRegistration: FunctionComponent = () => {
	const [activeRadio, setActiveRadio] = useState(RadioButtonState.INITIAL);
	const [isTenantValid, setIsTenantValid] = useState(false);
	const [tenantData, setTenantData] = useState<ITenatFields>({} as ITenatFields);
	const [isProviderChangeInProgress, setIsProviderChangeInProgress] = useState(false);
	const [isProviderChanged, setIsProviderChanged] = useState(false);
	const [registratonStep, setRegistrationStep] = useState(RegistrationStep.EXISTING_ACCOUNT_PROMPT);
	const [accountCreationStep, setAccountCreationStep] = useState(AaccountCreationFormStep.INITIAL);
	const [isButtonDisabled, setIsButtonDisabled] = useState(false);
	const { screen } = useScreen();
	const history = useHistory();
	const { cart } = useCart();
	const formikRef = useRef<FormikProps<IAccountCreationFormFields>>(null);
	const dispatch = useDispatch();

	function handleRadioButtonClick(radioButtonValue: RadioButtonState): void {
		setActiveRadio(radioButtonValue);
	}

	function handleTenantDataSubmit(tenantData: ITenatFields) {
		setTenantData(tenantData);
	}

	useEffect(() => {
		if (tenantData.tenantName && tenantData.tenantId && cart.orderId) {
			dispatch(addBusyIndicator(GET_TENANT));
			MicrosoftTenantService.getTenant(tenantData.tenantId, tenantData.tenantName, cart.orderId).subscribe(
				(response) => {
					switch (response.result) {
						case IResponseResultGetTenant.SUCCESS || IResponseResultGetTenant.SUBSCRIPCTIONS_NOT_FOUND:
							setRegistrationStep(RegistrationStep.TENANT_VERIFICATION_SUCCESS);
							break;
						case IResponseResultGetTenant.TENANT_NOT_FOUND:
							setRegistrationStep(RegistrationStep.CHANGE_PROVIDER);
							break;
						case IResponseResultGetTenant.PRODUCT_NOT_AVAILIBLE ||
							IResponseResultGetTenant.CATALOG_NOT_FOUND:
							setRegistrationStep(RegistrationStep.PRODUCT_UNAVAILABLE);
							break;
						default:
							break;
					}
					dispatch(removeBusyIndicator(GET_TENANT));
				},
				() => {
					dispatch(removeBusyIndicator(GET_TENANT));
				}
			);
		}
	}, [tenantData, cart.orderId]);

	const [firstChangeProviderResponse, setFirstChangeProviderResponse] = useState(false);

	const changeProvider = () => {
		MicrosoftTenantService.changeTenantProvider(cart.orderId).subscribe(
			(response) => {
				setIsProviderChangeInProgress(false);
				setFirstChangeProviderResponse(false);
				switch (response.result) {
					case IResponseResultGetTenant.SUCCESS:
						setRegistrationStep(RegistrationStep.TENANT_VERIFICATION_SUCCESS);
						break;
					case IResponseResultGetTenant.TENANT_NOT_FOUND:
						setRegistrationStep(RegistrationStep.CHANGE_PROVIDER);
						break;
					default:
						break;
				}
			},
			() => {
				if (!firstChangeProviderResponse) {
					setFirstChangeProviderResponse(true);
				} else {
					setIsProviderChangeInProgress(false);
					setFirstChangeProviderResponse(false);
				}
			}
		);
	};

	useEffect(() => {
		if ((isProviderChangeInProgress && cart.orderId) || firstChangeProviderResponse) {
			changeProvider();
		}
	}, [isProviderChangeInProgress, cart.orderId, firstChangeProviderResponse]);

	useEffect(() => {
		if (!cart.orderId) {
			history.push(`/${getLang()}/store`);
		}
	}, []);

	const validateFormStep = (step: AaccountCreationFormStep | RegistrationStep) => {
		if (formikRef.current) {
			formikRef.current
				.validateForm()
				.then((errors: FormikErrors<IAccountCreationFormFields>) => {
					if (Object.keys(errors).length === 0) {
						if (step !== RegistrationStep.ACCOUNT_CREATION_PROCESS) {
							setAccountCreationStep(step as AaccountCreationFormStep);
						} else {
							setRegistrationStep(RegistrationStep.ACCOUNT_CREATION_PROCESS);
						}
					} else {
						const touched = {} as IAccountCreationFormTouchedFields;
						formSteps[accountCreationStep > 0 ? accountCreationStep - 1 : 0].fields.forEach((field) => {
							touched[field.id] = true;
						});
						if (formikRef.current) {
							formikRef.current.setTouched(touched);
						}
					}
				})
				.catch(() => {});
		}
	};

	const getView = (buttonType: ButtonType) => {
		{
			if (accountCreationStep === AaccountCreationFormStep.INITIAL && buttonType === ButtonType.NEXT) {
				setRegistrationStep(RegistrationStep.ACCOUNT_CREATION_FORM);
				setAccountCreationStep(AaccountCreationFormStep.CONTACT_DETAILS);
				setIsButtonDisabled(true);
			} else if (accountCreationStep === AaccountCreationFormStep.INITIAL && buttonType === ButtonType.BACK) {
				history.push(`/${getLang()}/store`);
			}
			if (accountCreationStep === AaccountCreationFormStep.CONTACT_DETAILS && buttonType === ButtonType.NEXT) {
				validateFormStep(AaccountCreationFormStep.COMPANY_DETAILS);
			} else if (
				accountCreationStep === AaccountCreationFormStep.CONTACT_DETAILS &&
				buttonType === ButtonType.BACK
			) {
				setAccountCreationStep(AaccountCreationFormStep.INITIAL);
				setRegistrationStep(RegistrationStep.EXISTING_ACCOUNT_PROMPT);
				setIsButtonDisabled(false);
			}
			if (accountCreationStep === AaccountCreationFormStep.COMPANY_DETAILS && buttonType === ButtonType.NEXT) {
				validateFormStep(AaccountCreationFormStep.TENANT_INFO);
			} else if (
				accountCreationStep === AaccountCreationFormStep.COMPANY_DETAILS &&
				buttonType === ButtonType.BACK
			) {
				setAccountCreationStep(AaccountCreationFormStep.CONTACT_DETAILS);
			}
			if (accountCreationStep === AaccountCreationFormStep.TENANT_INFO && buttonType === ButtonType.NEXT) {
				validateFormStep(RegistrationStep.ACCOUNT_CREATION_PROCESS);
			} else if (accountCreationStep === AaccountCreationFormStep.TENANT_INFO && buttonType === ButtonType.BACK) {
				setAccountCreationStep(AaccountCreationFormStep.COMPANY_DETAILS);
			}
		}
	};

	return (
		<Container>
			<Row justify="center">
				<Col width="759px">
					<ConditionalRender
						show={
							activeRadio === RadioButtonState.RADIO_BUTTON_VALUE_NO &&
							registratonStep === RegistrationStep.ACCOUNT_CREATION_FORM
						}
						onTrue={() => <AccountCreationStepper step={accountCreationStep} />}
						onFalse={() => <Divider withoutLine marginBottom={50} />}
					/>
					<Card
						card="microsoftRegistration"
						withoutContainerPadding
						params={{
							activeRadio,
							setActiveRadio,
							handleRadioButtonClick,
							tenantData,
							isTenantValid,
							handleTenantDataSubmit,
							isProviderChangeInProgress,
							setIsProviderChangeInProgress,
							isProviderChanged,
							setIsProviderChanged,
							registratonStep,
							setRegistrationStep,
							accountCreationStep,
							setAccountCreationStep,
							formikRef,
							formSteps,
							setIsButtonDisabled,
						}}
					/>
					<ConditionalRender
						show={
							activeRadio === RadioButtonState.RADIO_BUTTON_VALUE_NO &&
							(registratonStep === RegistrationStep.ACCOUNT_CREATION_FORM ||
								registratonStep === RegistrationStep.EXISTING_ACCOUNT_PROMPT)
						}
						onTrue={() => (
							<ButtonsWrapper isXs={screen('xs')}>
								<ButtonWrapper isXs={screen('xs')}>
									<QuaternaryMediumButtonFlex
										uniqueId="microsoft-registration.stepper.back"
										onClick={() => getView(ButtonType.BACK)}
										textAlign="center"
									>
										Back
									</QuaternaryMediumButtonFlex>
								</ButtonWrapper>
								<ButtonWrapper isXs={screen('xs')}>
									<PrimaryMediumButtonFlex
										uniqueId="microsoft-registration.stepper.next"
										onClick={() => getView(ButtonType.NEXT)}
										textAlign="center"
										disabled={isButtonDisabled}
									>
										Next
									</PrimaryMediumButtonFlex>
								</ButtonWrapper>
							</ButtonsWrapper>
						)}
					/>
				</Col>
			</Row>
		</Container>
	);
};

export default MicrosoftRegistration;
