import { setActivePaymentToken, IPaymentToken } from './actions';
import immerHandleActions from '@Redux/utils/immerHandleActions';

const init = {
	activePaymentToken: '',
};

type draft = typeof init;

const reducers = {
	[setActivePaymentToken.toString()](draft: draft, action: { payload: IPaymentToken }) {
		const { activePaymentToken } = action.payload;
		draft.activePaymentToken = activePaymentToken;
		return draft;
	},
};

export default immerHandleActions(reducers, init);
