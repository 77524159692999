import { IAddress } from '@ApiModels/commerceProfile';
import BusyIndicator from '@Components/BusyIndicator/BusyIndicator';
import Card from '@Components/Card/Card';
import {
	HeaderWrapper,
	IconWrapper,
	ShippingWrapper,
} from '@Components/Card/cards/TermsAndConditions/TermsAndConditions.styled';
import ConditionalRender from '@Components/ConditionalRender/ConditionalRender';
import { PrimaryMediumButtonFixed } from '@Components/controls/Button/Button';
import { SquareCheckbox } from '@Components/controls/Checkbox/Checkbox';
import RadioButton from '@Components/controls/RadioButton/RadioButton';
import Toggle from '@Components/controls/Toggle/Toggle';
import Divider from '@Components/Divider/Divider';
import Icon from '@Components/Icon/Icon';
import {
	setActiveAddressBook,
	setNewAddressBook,
	setRefreshAddressBook,
	setOutletPickupFocus,
} from '@Redux/modules/addressBook/actions';
import { isUserSignedIn } from '@Redux/modules/api/authentication/selectors';
import { setCheckoutDetails } from '@Redux/modules/checkout/actions';
import { dispatch } from '@Redux/store';
import { useCart } from '@Utils/context/CartContext';
import useAddressBook, { GET_ADDRESS_BOOK_BI } from '@Utils/hooks/useAddressBook';
import useTranslate from '@Utils/hooks/useTranslate';
import { BUY_HBB, BUY_WFBB } from '@Views/Map/Map';
import moment from 'moment';
import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { Col, Hidden, Row, Visible } from 'react-grid-system';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import AddressBookDetails from '../AddressBook/AddressBookDetails';
import {
	AddressBookElement,
	AddressTitle,
	BoldText,
	DescriptionWrapper,
	Horizontal,
	PickupInfo,
	PickupSelectorContainer,
	RadioButtonWrapper,
	Separator,
	ShippingMethodCardContainer,
	TitleWrapper,
	ToggleWrapper,
	Underline,
	ToggleText,
	OutletLocalisationWrapper,
	ShippingInfoLabelWrapper,
} from './ShippingMethod.styled';
import { Caption } from '@Components/Typography/Typography';
import { POSTPAID_PLAN } from '@Config/app.config';
import { useOutlet, EMPTY_OUTLET } from '@Utils/context/OutletContext';
import { DISABLE_PICKUP_FROM_OUTLET } from '@Config/features.config';
import { useBuyNowPayLater } from '@Utils/context/BuyNowPayLaterContext';

const ICON_SIZE = 40;

enum SelectedAddressType {
	ADRESS_BOOK = 'ADRESS_BOOK',
	DIFFERENT = 'DIFFERENT',
	PICKUP = 'PICKUP',
	OUTLET = 'OUTLET',
	SAME_AS_INTERNET_INSTALLATION = 'SAME_AS_INTERNET_INSTALLATION',
}

const ShippingMethod: FunctionComponent<types.cards.IShippingMethodCardProps> = ({
	params: {
		hasDevice,
		orderIncludesPlan,
		onlyPickupItems,
		launchDate,
		hbbWfbbInternetInstallationAddress,
		additionalLandmark,
		saveAddress,
		setAdditionalLandmark,
		setSaveAddress,
		isBuyHbbWfbbProcess,
		isPrepaidPurchase,
	},
}) => {
	const { translate } = useTranslate();
	const isLogged = useSelector((state: types.redux.IState) => state.api.authentication.signedIn);
	const { selectedOutlet, availableOutlets, setSelectedOutlet, setAlertVisibility } = useOutlet();
	const [addressType, setAddressType] = useState<SelectedAddressType>(
		!!selectedOutlet.branchId
			? SelectedAddressType.OUTLET
			: availableOutlets.filter((item) => {
					return item.available === true;
			  }).length > 0
			? SelectedAddressType.OUTLET
			: isLogged
			? SelectedAddressType.ADRESS_BOOK
			: SelectedAddressType.DIFFERENT
	);
	const activeAddressBook = useSelector((state: types.redux.IState) => state.addressBook.activeAddressBook);
	const newAddressBook = useSelector((state: types.redux.IState) => state.addressBook.newAddressBook);
	const hbbWfbbProcessCheckout = useSelector(
		(state: types.redux.IState) => state.persistentCheckout.hbbWfbbProcessCheckout
	);
	const [lastModifiedAddressBook, setLastModifiedAddressBook] = useState<IAddress[]>([]);
	const isGuest = useSelector((state: types.redux.IState) => state.api.authentication.loginType === 'guest');
	const { isBuyNowPayLaterInCart } = useBuyNowPayLater();
	const {
		addressBook,
		addNewAddress,
		getTitle,
		removeAddress,
		editAddress,
		updateShippingAddress,
		addNewAddressWithoutModal,
		selectedAddress,
	} = useAddressBook();
	const { cart } = useCart();
	const history = useHistory();

	const hbbWfbbItem = useMemo(() => cart.items.find((item) => [BUY_HBB, BUY_WFBB].includes(item.plan?.PA || '')), [
		cart,
	]);

	const isPrepaidProcess = useMemo(() => !!cart.items.find((item) => [POSTPAID_PLAN].includes(item.plan?.PA || '')), [
		cart,
	]);

	const hasDeviceWithHbbWfbbItem = hasDevice && !!hbbWfbbItem;

	const EnterAddressBtn = () => (
		<PrimaryMediumButtonFixed
			uniqueId="shipping-method.enter-address"
			onClick={() => {
				addNewAddress();
			}}
		>
			{translate('shipping-method.enter-address')}
		</PrimaryMediumButtonFixed>
	);

	const setToggleState = (address: IAddress, isChecked: boolean) => {
		updateShippingAddress(isChecked ? address : undefined);
		dispatch(
			setActiveAddressBook({
				activeAddressBook: isChecked ? address.addressId : undefined,
			})
		);
	};

	const handleEditButton = () => {
		history.push('/map', { editAddress: true });
	};
	useEffect(() => {
		if (addressType === SelectedAddressType.PICKUP) {
			dispatch(
				setCheckoutDetails({
					pickup: true,
				})
			);
		} else {
			dispatch(
				setCheckoutDetails({
					pickup: false,
				})
			);
		}
	}, [addressType]);

	useEffect(() => {
		if (addressType === SelectedAddressType.OUTLET) {
			dispatch(setOutletPickupFocus({ outletPickupFocus: true }));
		} else {
			dispatch(setOutletPickupFocus({ outletPickupFocus: false }));
		}
	}, []);

	useEffect(() => {
		if (addressBook.length === 1 && addressType === SelectedAddressType.ADRESS_BOOK) {
			updateShippingAddress(addressBook[0]);
			dispatch(
				setActiveAddressBook({
					activeAddressBook: addressBook[0].addressId,
				})
			);
		} else {
			setLastModifiedAddressBook(addressBook);
			let newEditedAddress: IAddress | string = '';
			newEditedAddress = addressBook.find((newAddress) => newAddress.addressId === activeAddressBook) || '';
			if (activeAddressBook && !newEditedAddress) {
				addressBook.forEach((address) => {
					newEditedAddress =
						lastModifiedAddressBook.find((newAddress) => newAddress.addressId === address.addressId)
							?.addressId || '';
					if (!newEditedAddress) {
						if (newAddressBook) {
							newEditedAddress =
								addressBook.find((newAddress) => newAddress.addressId === newAddressBook) || '';
							if (!newEditedAddress) {
								dispatch(setNewAddressBook({ newAddressBook: address.addressId }));
							}
						}
						dispatch(setActiveAddressBook({ activeAddressBook: address.addressId }));
					}
				});
			}
		}
	}, [addressBook, addressType]);

	useEffect(() => {
		const sameAddress =
			String(selectedAddress?.markerPosition?.lat) === String(hbbWfbbProcessCheckout?.latitude) &&
			String(selectedAddress?.markerPosition?.lng) === String(hbbWfbbProcessCheckout?.longitude);
		if (
			!sameAddress &&
			hbbWfbbInternetInstallationAddress &&
			addressType === SelectedAddressType.SAME_AS_INTERNET_INSTALLATION
		) {
			addNewAddressWithoutModal(hbbWfbbInternetInstallationAddress);
		}
	}, [addressBook, addressType, hbbWfbbInternetInstallationAddress]);

	useEffect(() => {
		if (addressType === SelectedAddressType.DIFFERENT) {
			const sameAddress =
				String(selectedAddress?.markerPosition?.lat) === String(hbbWfbbProcessCheckout?.latitude) &&
				String(selectedAddress?.markerPosition?.lng) === String(hbbWfbbProcessCheckout?.longitude);
			if (sameAddress && hasDeviceWithHbbWfbbItem) {
				dispatch(
					setNewAddressBook({
						activeAddressBook: undefined,
					})
				);
			}
			const filtered = addressBook.filter((_add) => _add.addressId === newAddressBook);
			if (filtered.length > 0) {
				filtered.forEach((_address) => {
					if (newAddressBook === _address.addressId) {
						updateShippingAddress(_address);
						dispatch(
							setActiveAddressBook({
								activeAddressBook: _address.addressId,
							})
						);
					}
				});
			} else {
				dispatch(
					setActiveAddressBook({
						activeAddressBook: undefined,
					})
				);
				updateShippingAddress(undefined);
			}
		} else if (addressType === SelectedAddressType.ADRESS_BOOK) {
			dispatch(setRefreshAddressBook({}));
			if (addressBook.length > 1) {
				addressBook.forEach((_address) => {
					if (newAddressBook === _address.addressId) {
						dispatch(
							setActiveAddressBook({
								activeAddressBook: _address.addressId,
							})
						);
					}
					if (hasDeviceWithHbbWfbbItem) {
						if (activeAddressBook === _address.addressId) {
							updateShippingAddress(_address);
						}
					}
				});
			} else if (addressBook.length === 1) {
				updateShippingAddress(addressBook[0]);
				dispatch(
					setActiveAddressBook({
						activeAddressBook: addressBook[0].addressId,
					})
				);
			}
		}
	}, [addressType, newAddressBook]);

	const showSaveAddressCheckbox = (): boolean => {
		if (isUserSignedIn() && !isGuest) {
			return true;
		}
		return false;
	};

	const filterAddresses = (filter: string | undefined) => {
		let data = addressBook;
		if (!!filter) {
			data = addressBook.filter((_addres) => _addres.addressId === filter);
			if (data.length <= 0) {
				setNewAddressBook({ newAddressBook: undefined });
			}
		}

		return data.map((address, index) => (
			<BusyIndicator listener={GET_ADDRESS_BOOK_BI} key={index} skeleton="orderTracking">
				<AddressBookElement key={index}>
					<Horizontal>
						<IconWrapper>
							<Icon name="location" fill="black54" width={32} height={32} />
						</IconWrapper>
						<AddressTitle>{getTitle(address)}</AddressTitle>
						{addressBook.length > 1 && !filter && (
							<ToggleWrapper>
								<ConditionalRender
									show={!isPrepaidPurchase}
									onTrue={() => (
										<ToggleText>
											<Caption color="black54">
												{activeAddressBook === address.addressId
													? translate('secure-checkout.payment-tokens.selected')
													: ''}
											</Caption>
										</ToggleText>
									)}
								/>
								<Toggle
									active={address.addressId === activeAddressBook}
									onChange={(event) =>
										setToggleState(address, !(address.addressId === activeAddressBook))
									}
								/>
							</ToggleWrapper>
						)}
					</Horizontal>
					<ConditionalRender
						show={
							activeAddressBook === address.addressId ||
							(addressType === SelectedAddressType.DIFFERENT && newAddressBook === address.addressId)
						}
						onTrue={() => (
							<>
								<Divider withoutLine marginTop={24} />
								<AddressBookDetails
									address={address}
									removeAddress={(nickName) => {
										removeAddress(nickName ?? '');
									}}
									editAddress={() => {
										editAddress(address);
									}}
								/>
							</>
						)}
					/>
					<Divider withoutLine marginBottom={26} />
					<Horizontal>
						<ConditionalRender
							show={isPrepaidProcess}
							onTrue={() => (
								<>
									<PrimaryMediumButtonFixed
										uniqueId="shipping-method.edit-address"
										onClick={() => {
											editAddress(address);
										}}
									>
										{translate('address.edit.address')}
									</PrimaryMediumButtonFixed>
									<Separator />
									<PrimaryMediumButtonFixed
										uniqueId="shipping-method.edit-address"
										onClick={() => {
											removeAddress(address.nickName ?? '');
										}}
									>
										{translate('modal.delete-payment-token.delete')}
									</PrimaryMediumButtonFixed>
								</>
							)}
							onFalse={() => (
								<>
									<Separator />
									<Separator />
									<Separator />
									<Underline color="primary" onClick={() => editAddress(address)}>
										{translate('address.edit.address')}
									</Underline>
									<Separator />
									<Underline color="primary" onClick={() => removeAddress(address.nickName ?? '')}>
										{translate('modal.delete-payment-token.delete')}
									</Underline>
								</>
							)}
						/>
					</Horizontal>
				</AddressBookElement>
			</BusyIndicator>
		));
	};

	const differentAddress = () => {
		if (newAddressBook) {
			return <RadioButtonWrapper>{filterAddresses(newAddressBook)}</RadioButtonWrapper>;
		} else {
			return <Row>{EnterAddressBtn()}</Row>;
		}
	};

	const pickUpOutlet = () => {
		return <Card card="outletPickup" params={{ selectedOutlet, setSelectedOutlet }} />;
	};

	const pickupSelector = useMemo(
		() =>
			!isPrepaidPurchase ? (
				<PickupSelectorContainer>
					<Card<types.cards.IDeliveryMethodCardProps>
						card="deliveryMethod"
						params={{
							title: translate('shipping-method.deliver.address.title'),
							subtitle: translate('shipping-method.deliver.address.subtitle'),
							icon: 'location',
							selected:
								addressType === SelectedAddressType.DIFFERENT ||
								addressType === SelectedAddressType.ADRESS_BOOK,
						}}
						onEvent={() => {
							setAddressType(SelectedAddressType.DIFFERENT);
							setSelectedOutlet(EMPTY_OUTLET);
							dispatch(setOutletPickupFocus({ outletPickupFocus: false }));
							setAlertVisibility(false);
						}}
						withoutContainer
					/>
					{/* remove comment when pickup will be available */}
					{/* <Card<types.cards.IDeliveryMethodCardProps>
					card="deliveryMethod"
					params={{
						title: translate('shipping-method.deliver.pickup.title'),
						subtitle: translate('shipping-method.deliver.pickup.subtitle'),
						icon: 'locationBuild',
						selected: addressType === SelectedAddressType.PICKUP,
						disabled: !onlyPickupItems,
					}}
					onEvent={() => {
						setAddressType(SelectedAddressType.PICKUP);
					}}
					withoutContainer
				/>
				<ConditionalRender
					show={!onlyPickupItems}
					onTrue={() => <PickupOrderInfo>{translate('shipping-method.pickup.info')}</PickupOrderInfo>}
				/> */}
					<ConditionalRender
						show={!DISABLE_PICKUP_FROM_OUTLET}
						onTrue={() => (
							<Card<types.cards.IDeliveryMethodCardProps>
								card="deliveryMethod"
								params={{
									title: translate('shipping-method.deliver.outlet.title'),
									subtitle: translate('shipping-method.deliver.outlet-pickup.subtitle'),
									icon: 'locationBuild',
									selected: addressType === SelectedAddressType.OUTLET,
									disabled:
										availableOutlets.filter((item) => {
											return item.available === true;
										}).length === 0 || isBuyNowPayLaterInCart,
								}}
								onEvent={() => {
									setAddressType(SelectedAddressType.OUTLET);
									dispatch(setOutletPickupFocus({ outletPickupFocus: true }));
								}}
								withoutContainer
							/>
						)}
					/>
				</PickupSelectorContainer>
			) : (
				<></>
			),
		[onlyPickupItems, addressType]
	);

	const radioDifferent = () => (
		<RadioButton
			label={translate('shipping-method.address-type.diffrent')}
			selected={addressType}
			onClick={() => {
				setAddressType(SelectedAddressType.DIFFERENT);
				dispatch(setOutletPickupFocus({ outletPickupFocus: false }));
			}}
			name="radio"
			value={SelectedAddressType.DIFFERENT}
			marginRight={16}
		/>
	);
	const radioAddressBook = () => (
		<RadioButton
			label={translate('shipping-method.select.from.address.book')}
			selected={addressType}
			onClick={() => {
				setAddressType(SelectedAddressType.ADRESS_BOOK);
				dispatch(setOutletPickupFocus({ outletPickupFocus: false }));
			}}
			name="radio"
			value={SelectedAddressType.ADRESS_BOOK}
			marginRight={16}
		/>
	);

	return (
		<ShippingMethodCardContainer>
			<ConditionalRender
				show={!!hbbWfbbItem || !!hasDeviceWithHbbWfbbItem || !!isBuyHbbWfbbProcess}
				onTrue={() => (
					<Row>
						<Hidden xs>
							<Col lg={1} md={1} sm={1}>
								<Row align="center" justify="center">
									<Icon
										name={hbbWfbbItem ? 'house' : 'shipping'}
										fill="black38"
										width={ICON_SIZE}
										height={ICON_SIZE}
									/>
								</Row>
							</Col>
						</Hidden>
						<Col lg={11} md={11} sm={11}>
							<ShippingWrapper>
								<Row>
									<HeaderWrapper>
										<Visible xs>
											<IconWrapper>
												<Icon
													name={hbbWfbbItem ? 'house' : 'shipping'}
													width={ICON_SIZE}
													height={ICON_SIZE}
												/>
											</IconWrapper>
										</Visible>
										<div>
											<TitleWrapper>{translate('shipping-method.title.hbb.wfbb')}</TitleWrapper>
											<DescriptionWrapper>
												{translate('shipping-method.title.hbb.wfbb.desc')}
											</DescriptionWrapper>
										</div>
									</HeaderWrapper>
								</Row>
								<ConditionalRender
									show={!!hbbWfbbItem}
									onTrue={() => (
										<>
											{hbbWfbbInternetInstallationAddress && (
												<AddressBookDetails
													address={hbbWfbbInternetInstallationAddress}
													isHbbWfbbProcess={!!hbbWfbbItem}
													editLandmark={setAdditionalLandmark}
													editAddress={() => {}}
													onEditButtonClick={handleEditButton}
													divider={0}
												/>
											)}
										</>
									)}
								/>
								<ConditionalRender
									show={showSaveAddressCheckbox()}
									onTrue={() => (
										<SquareCheckbox
											active={!!saveAddress}
											onChange={() => {
												setSaveAddress && setSaveAddress(!saveAddress);
											}}
											disabled={false}
											label={translate('shipping.method.save-address')}
											labelClickable
										/>
									)}
								/>
							</ShippingWrapper>
						</Col>
					</Row>
				)}
			/>
			<ConditionalRender show={!isPrepaidProcess} onTrue={() => <Divider withoutLine marginBottom={50} />} />
			<ConditionalRender
				show={!!hasDeviceWithHbbWfbbItem || !isBuyHbbWfbbProcess}
				onTrue={() => (
					<Row>
						<Hidden xs>
							<Col lg={1} md={1} sm={1}>
								<Row align="center" justify="center">
									<Icon
										name={!!hasDeviceWithHbbWfbbItem ? 'deliveryBus' : 'shipping'}
										fill="black38"
										width={ICON_SIZE}
										height={ICON_SIZE}
									/>
								</Row>
							</Col>
						</Hidden>
						<Col lg={11} md={11} sm={11}>
							<ShippingWrapper>
								<Row>
									<HeaderWrapper>
										<Visible xs>
											<IconWrapper>
												<Icon
													name={!!hasDeviceWithHbbWfbbItem ? 'deliveryBus' : 'shipping'}
													width={ICON_SIZE}
													height={ICON_SIZE}
												/>
											</IconWrapper>
										</Visible>
										<div>
											<TitleWrapper>
												{translate(
													hasDeviceWithHbbWfbbItem
														? 'shipping-method.title.hbb.wfbb.device'
														: orderIncludesPlan && !isPrepaidPurchase
														? 'shipping-method.title'
														: 'shipping-method.title.without.plan'
												)}
											</TitleWrapper>
											<DescriptionWrapper>
												{translate(
													hasDeviceWithHbbWfbbItem
														? 'shipping-method.title.hbb.wfbb.desc.device'
														: isPrepaidPurchase
														? 'shipping-method.description-sim'
														: 'shipping-method.description'
												)}
											</DescriptionWrapper>
										</div>
									</HeaderWrapper>
								</Row>
								<ConditionalRender
									show={!!hasDeviceWithHbbWfbbItem}
									onTrue={() => (
										<Row>
											<RadioButton
												label={translate('shipping-method.same.as.internet.installation')}
												selected={addressType}
												onClick={() => {
													setAddressType(SelectedAddressType.SAME_AS_INTERNET_INSTALLATION);
												}}
												name="radio"
												value={SelectedAddressType.SAME_AS_INTERNET_INSTALLATION}
												marginRight={0}
											/>
											<Divider withoutLine marginBottom={16} />
										</Row>
									)}
								/>
								<ConditionalRender
									show={
										addressBook?.length > 0 &&
										(addressBook[0].wilayat !== '' || addressBook[0].way !== '')
									}
									onTrue={() => (
										<>
											<Row>{!hbbWfbbItem && pickupSelector}</Row>
											<ConditionalRender
												show={addressType === SelectedAddressType.PICKUP}
												onTrue={() => (
													<Row>
														<PickupInfo>
															{translate('shipping-method.pickup-info-1')}
															<BoldText>
																{translate('shipping.method.omante.hq')}
															</BoldText>
															{translate('shipping-method.pickup-info-2')}
															<BoldText>
																{moment(launchDate).format('DD MMM YYYY')}.
															</BoldText>
															{translate('shipping-method.pickup-info-3')}
														</PickupInfo>
													</Row>
												)}
												onFalse={() => (
													<ConditionalRender
														show={addressType !== SelectedAddressType.OUTLET}
														onTrue={() => (
															<>
																<ConditionalRender
																	show={
																		addressType !== SelectedAddressType.DIFFERENT ||
																		!!newAddressBook
																	}
																	onTrue={() => (
																		<ShippingInfoLabelWrapper>
																			<Row>
																				<Hidden xs>
																					<Col lg={1} md={1} sm={1}>
																						<Row
																							align="center"
																							justify="center"
																						>
																							<Icon
																								name="location"
																								fill="black38"
																								width={ICON_SIZE}
																								height={ICON_SIZE}
																							/>
																						</Row>
																					</Col>
																				</Hidden>
																				<Col lg={11} md={11} sm={11}>
																					<HeaderWrapper>
																						<Visible xs>
																							<IconWrapper>
																								<Icon
																									name={
																										!!hasDeviceWithHbbWfbbItem
																											? 'deliveryBus'
																											: 'shipping'
																									}
																									width={ICON_SIZE}
																									height={ICON_SIZE}
																								/>
																							</IconWrapper>
																						</Visible>
																						<div>
																							<TitleWrapper>
																								{translate(
																									'shipping-method.deliver.address.title'
																								)}
																							</TitleWrapper>
																							<DescriptionWrapper>
																								{translate(
																									'shipping-method.deliver.address.label-subtitle'
																								)}
																							</DescriptionWrapper>
																						</div>
																					</HeaderWrapper>
																				</Col>
																			</Row>
																		</ShippingInfoLabelWrapper>
																	)}
																/>
																<RadioButtonWrapper>
																	<ConditionalRender
																		show={!!isLogged}
																		onTrue={() => (
																			<>
																				{radioAddressBook()}
																				<Divider
																					withoutLine
																					marginBottom={18}
																				/>
																			</>
																		)}
																	/>
																	{addressType === SelectedAddressType.ADRESS_BOOK &&
																		filterAddresses(undefined)}
																	<ConditionalRender
																		show={!!isLogged}
																		onTrue={() => <>{radioDifferent()}</>}
																	/>
																</RadioButtonWrapper>
																<ConditionalRender
																	show={addressType === SelectedAddressType.DIFFERENT}
																	onTrue={() => differentAddress()}
																/>
															</>
														)}
													/>
												)}
											/>
										</>
									)}
									onFalse={() => (
										<>
											<Row>{pickupSelector}</Row>

											<ConditionalRender
												show={
													addressType === SelectedAddressType.DIFFERENT ||
													addressType === SelectedAddressType.ADRESS_BOOK
												}
												onTrue={() => differentAddress()}
												onFalse={() => (
													<Row>
														<ConditionalRender
															show={addressType !== SelectedAddressType.OUTLET}
															onTrue={() => (
																<>
																	<RadioButtonWrapper>
																		<ConditionalRender
																			show={!!isLogged}
																			onTrue={() => (
																				<>
																					{radioAddressBook()}
																					<Divider
																						withoutLine
																						marginBottom={18}
																					/>
																				</>
																			)}
																		/>
																		{addressType ===
																			SelectedAddressType.ADRESS_BOOK &&
																			filterAddresses(undefined)}
																		<ConditionalRender
																			show={!!isLogged}
																			onTrue={() => <>{radioDifferent()}</>}
																		/>
																	</RadioButtonWrapper>
																	<ConditionalRender
																		show={
																			addressType ===
																			SelectedAddressType.DIFFERENT
																		}
																		onTrue={() => differentAddress()}
																	/>
																</>
															)}
														/>
													</Row>
												)}
											/>
										</>
									)}
								/>
							</ShippingWrapper>
						</Col>
					</Row>
				)}
			/>
			<ConditionalRender
				show={addressType === SelectedAddressType.OUTLET}
				onTrue={() => (
					<OutletLocalisationWrapper>
						<Row>
							<Hidden xs>
								<Col lg={1} md={1} sm={1}>
									<Row align="center" justify="center">
										<Icon
											name="locationBuild"
											fill="black38"
											width={ICON_SIZE}
											height={ICON_SIZE}
										/>
									</Row>
								</Col>
							</Hidden>
							<Col lg={11} md={11} sm={11}>
								<ShippingWrapper>
									<Row>
										<HeaderWrapper>
											<Visible xs>
												<IconWrapper>
													<Icon
														name="locationBuild"
														fill="black38"
														width={ICON_SIZE}
														height={ICON_SIZE}
													/>
												</IconWrapper>
											</Visible>
											<div>
												<TitleWrapper>
													{translate('shipping-method.outlet.label-1')}
												</TitleWrapper>
												<DescriptionWrapper>
													{translate('shipping-method.outlet.label-2')}
												</DescriptionWrapper>
											</div>
										</HeaderWrapper>
									</Row>
								</ShippingWrapper>
								<Row>{pickUpOutlet()}</Row>
							</Col>
						</Row>
					</OutletLocalisationWrapper>
				)}
			/>
		</ShippingMethodCardContainer>
	);
};

export default ShippingMethod;
