import React, { useState } from 'react';
import { useInsurance, INSURANCE_TYPE } from '@Utils/context/InsuranceContext';
import {
	DetailsButtonWraper,
	InsuranceSection,
	SectionData,
	InsuranceSectionTitle,
	SectionSubtitle,
	IconWrapper,
	InfoWrapper,
	BodyMWrapper,
	CarIconWrapper,
	InsuranceFirstSection,
} from './InsuranceVehicleRollup.styled';
import useTranslate from '@Utils/hooks/useTranslate';
import { Row, Col, Visible } from 'react-grid-system';
import Icon from '@Components/Icon/Icon';
import { ParagraphMBold, BigCurrency } from '@Components/Typography/Typography';
import { QuaternaryNegativeMediumButtonFlex } from '@Components/controls/Button/Button';
import ConditionalRender from '@Components/ConditionalRender/ConditionalRender';
import { isLargeScreen } from '@Utils/layout/layoutSupport';

const ICON_SIZE = 40;

const InsuranceVehicleRollup = (): JSX.Element => {
	const { insuranceType, vehicleInformation, licenseNumber, vehicleValue } = useInsurance();
	const [isCardRolledUp, setIsCardRolledUp] = useState(false);
	const { translate } = useTranslate();
	const largeScreen = isLargeScreen();

	const toggleCardRollup = () => {
		setIsCardRolledUp(!isCardRolledUp);
	};

	const DetailsButton = (
		<DetailsButtonWraper isLargeScreen={largeScreen}>
			<QuaternaryNegativeMediumButtonFlex
				uniqueId="insurance-stepper.back-button"
				onClick={() => {
					toggleCardRollup();
				}}
			>
				{isCardRolledUp ? translate('close') : translate('insurance.step.details')}
			</QuaternaryNegativeMediumButtonFlex>
		</DetailsButtonWraper>
	);

	return (
		<>
			<Row>
				<Col>
					<InsuranceFirstSection>
						<Icon name="vehicle" width={ICON_SIZE} height={ICON_SIZE} />
						<SectionData>
							<ParagraphMBold>{`${vehicleInformation?.manufacturer}, ${vehicleInformation?.model}`}</ParagraphMBold>
							<SectionSubtitle>{`${vehicleInformation?.vehiclePlateCharCode}/${vehicleInformation?.vehicleNo}`}</SectionSubtitle>
						</SectionData>
						<Visible sm md xl xxl>
							{DetailsButton}
						</Visible>
					</InsuranceFirstSection>
				</Col>
			</Row>
			<Visible xs lg>
				<Row>
					<Col>{DetailsButton}</Col>
				</Row>
			</Visible>
			<ConditionalRender
				show={isCardRolledUp}
				onTrue={() => (
					<>
						<Row>
							<Col>
								<InsuranceSectionTitle>{translate('insurance.type.title')}</InsuranceSectionTitle>
								<InsuranceSection>
									<Icon name="details" width={ICON_SIZE} height={ICON_SIZE} />
									<SectionData>
										<ParagraphMBold>
											{insuranceType === INSURANCE_TYPE.FULL_COMPREHENSIVE
												? translate('insurance.type.choice1')
												: translate('insurance.type.choice2')}
										</ParagraphMBold>
									</SectionData>
								</InsuranceSection>
							</Col>
						</Row>
						<Row>
							<Col>
								<InsuranceSectionTitle>{translate('insurance.insurer.insured')}</InsuranceSectionTitle>
								<InsuranceSection>
									<Icon name="insurer" width={ICON_SIZE} height={ICON_SIZE} />
									<SectionData>
										<ParagraphMBold>
											{vehicleInformation?.driverAge} {translate('insurance.insurer.insured')}
										</ParagraphMBold>
										<SectionSubtitle>{licenseNumber}</SectionSubtitle>
									</SectionData>
								</InsuranceSection>
							</Col>
						</Row>
						<Row>
							<Col>
								<InsuranceSectionTitle>
									{translate('insurance.vehicle.details.title')}
								</InsuranceSectionTitle>
								<InsuranceSection>
									<Icon name="vehicle" width={ICON_SIZE} height={ICON_SIZE} />
									<SectionData>
										<ParagraphMBold>
											{`${vehicleInformation?.manufacturer}, ${vehicleInformation?.model}`}
										</ParagraphMBold>
										<SectionSubtitle>{`${vehicleInformation?.vehiclePlateCharCode}/${vehicleInformation?.vehicleNo}`}</SectionSubtitle>
										<SectionSubtitle>
											{vehicleInformation?.numberOfPassengers}
											{translate('insurance.vehicle.seats')}
										</SectionSubtitle>
										<SectionSubtitle>
											{vehicleInformation?.numberOfDoors}
											{translate('insurance.vehicle.doors')}
										</SectionSubtitle>
										<SectionSubtitle>
											{vehicleInformation?.mfgYear}
											{translate('insurance.vehicle.mfg.year')}
										</SectionSubtitle>
										<SectionSubtitle>
											{vehicleInformation?.classificationTitle}
											{translate('vehicle-details.clasification')}
										</SectionSubtitle>
									</SectionData>
								</InsuranceSection>
							</Col>
						</Row>
						<ConditionalRender
							show={!!vehicleValue && vehicleValue !== '0'}
							onTrue={() => (
								<Row>
									<Col>
										<InsuranceSectionTitle>
											{translate('insurance.vehicle.value.placeholder')}
										</InsuranceSectionTitle>
										<InsuranceSection>
											<CarIconWrapper>
												<Icon name="vehicleValue" width={34} height={34} fill="white" />
											</CarIconWrapper>
											<SectionData>
												<ParagraphMBold>{vehicleValue}</ParagraphMBold>
												<BigCurrency>{translate('omr')}</BigCurrency>
											</SectionData>
										</InsuranceSection>
									</Col>
								</Row>
							)}
						/>
						<Row>
							<Col>
								<InfoWrapper>
									<IconWrapper>
										<Icon name="info" width="24" height="24" fill="black54" />
									</IconWrapper>
									<BodyMWrapper>{translate('insurance.vehicle.details.info')}</BodyMWrapper>
								</InfoWrapper>
							</Col>
						</Row>
					</>
				)}
			/>
		</>
	);
};

export default InsuranceVehicleRollup;
