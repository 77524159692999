import React, { ReactNode } from 'react';

interface IErrorState {
	hasError: boolean;
}

export default class ErrorBoundary extends React.Component<unknown, IErrorState> {
	constructor(props: unknown) {
		super(props);
		this.state = { hasError: false };
	}

	static getDerivedStateFromError(): IErrorState {
		window.location.href = '/error';
		return { hasError: true };
	}

	render(): ReactNode | null {
		if (this.state.hasError) return null;
		return this.props.children;
	}
}
