import styled from 'styled-components';
import { Caption, ParagraphSBold } from '@Components/Typography/Typography.styled';
import { marginLeft, marginRight } from '@Utils/layout/layoutSupport';

type ITextFieldWrapperProps = types.IStyledProps & {
	active: boolean;
	disabled: boolean;
	error: boolean;
	marginBottom?: number;
	marginTop?: number;
	message?: boolean;
	height?: number;
	noBorder?: boolean;
	borderColor?: types.theme.themeColors;
	hoverColor?: types.theme.themeColors;
	inActive?: boolean;
	boxShadow?: boolean;
	padding?: string;
};

type TextField = { width: string | number };

export const TextFieldContainer = styled.div<TextField>`
	${({ width }) => ({
		width,
	})}
	max-width: 100%;
`;

export const TextFieldWrapper = styled.div<ITextFieldWrapperProps>`
	display: inline-block;
	box-sizing: border-box;
	border-radius: 4px;
	transition: all 0.1s ease-in-out;
	height: 56px;
	width: 100%;
	max-width: 100%;
	cursor: text;
	${({ theme, marginBottom, message, height, borderColor, boxShadow, marginTop }) => ({
		borderColor: borderColor ? theme.colors[borderColor] : theme.colors.black54,
		background: theme.colors.white,
		marginBottom: message ? 8 : marginBottom,
		marginTop: marginTop ? marginTop : 0,
		height: height ?? '56px',
		boxShadow: `0 0 0 1px ${boxShadow ? theme.colors.theme300 : undefined}`,
	})};

	${({ active, disabled, theme, error, noBorder, borderColor, inActive, padding, hoverColor }) => {
		if (disabled) {
			return `
					cursor: default;
					padding: ${padding ? padding : '8px'};
					background: ${theme.colors.black6};
					border: 1px solid ${theme.colors.black12};
				`;
		} else if (error) {
			return `
					padding:${padding ? padding : '7px'};
					border: 2px solid ${theme.colors.warning};
				`;
		} else if (inActive) {
			return `
					padding: ${padding ? padding : '8px'};
					border: ${noBorder ? 'none' : `1px solid ${borderColor ? theme.colors[borderColor] : theme.colors.black54}`};
				`;
		} else if (active) {
			return `
					padding: ${padding ? padding : '7px'};
					border: 2px solid;
					border-color: ${theme.colors.primary};
					&:hover {
						border: 2px solid ${theme.colors[hoverColor || 'primary']};
					};
					&:active {
						border: 2px solid ${theme.colors[hoverColor || 'primary']};
					}`;
		} else {
			return `
					padding: ${padding ? padding : '8px'};
					border: ${noBorder ? 'none' : `1px solid ${borderColor ? theme.colors[borderColor] : theme.colors.black54}`};
					&:hover {
						padding: ${padding ? padding : '7px'};
						border: 2px solid ${theme.colors[hoverColor || 'primary']};
					};
					&:active {
						padding: ${padding ? padding : '7px'};
						border: 2px solid ${theme.colors.primary};
					}`;
		}
	}};
`;

export const TextFieldContent = styled.div`
	position: relative;
	display: flex;
	height: 100%;
`;

export const IconWrapperLeft = styled.div`
	align-self: center;
	cursor: pointer;
	width: 24px;
	height: 24px;
	${() => marginRight(8)}
`;

export const IconWrapperRight = styled.div`
	align-self: center;
	cursor: pointer;
	width: 24px;
	height: 24px;
	${() => marginLeft(8)}
`;

export const VerticalDivider = styled.div`
	display: inline-block;
	width: 1px;
	height: 100%;
	${() => marginRight(4)}
	border-radius: 2px;
	background-color: ${({ theme }) => theme.colors.black12}};
	`;

export const RightTextWrapper = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	jusitfy-content: center;
`;

export const RightTextClickWrapper = styled.div<{ cursorPointer?: boolean }>`
	${() => marginRight(8)}
	${({ cursorPointer }) => ({
		cursor: cursorPointer ? 'pointer' : 'default',
	})}
`;

type InputProps = types.IStyledProps & {
	typography: keyof types.ITypography;
	placeholderTypography?: keyof types.ITypography;
	active: boolean;
	noLabel: boolean;
	placeholderColor?: types.theme.themeColors;
};

export const TextFieldInput = styled.input<InputProps>`
	border: none;
	outline: none;
	width: 100%;
	transition: all 0.1s ease-in-out;
	padding: 0;
	background: transparent;
	${({ noLabel }) => ({
		alignSelf: noLabel ? 'center' : 'flex-end',
	})}
	::placeholder {
		transition: all 0.1s ease-in-out;
		${({ theme, placeholderTypography, placeholderColor }) => ({
			...theme.typography[placeholderTypography || ('value' as keyof types.ITypography)],
			color: theme.colors[placeholderColor || 'black38'],
		})}
	}
	${({ theme, typography, disabled }) => ({
		...theme.typography[typography],
		color: disabled ? theme.colors.black54 : theme.colors.black87,
	})}

	::placeholder {
		${({ active, noLabel }) => ({
			opacity: active || noLabel ? 1 : 0,
		})}
	}
`;

type ILabelProps = types.IStyledProps & { active: boolean; value: string | number; error: boolean; disabled: boolean };

export const Label = styled.label<ILabelProps>`
	position: absolute;
	transition: all 0.1s ease-in-out;
	user-select: none;
	${({ theme, error }) => ({
		color: error ? theme.colors.warning : theme.colors.black54,
	})}
	${({ value, active }) => (value || value === 0 || active ? `top: 0;` : `top:50%; transform: translateY(-50%);`)}
	${({ value, active, theme }) =>
		value || value === 0 || active ? theme.typography.label : theme.typography.floatingLabel}
`;

export const StaticLabel = styled(ParagraphSBold)<{ active: boolean; value: string | number }>`
	::after {
		content: attr(data-value);
		position: absolute;
		right: 0;
		transform: translateY(-50%);
		top: 50%;
		${({ value, active }) => (value || value === 0 || active ? `top: 70%;` : `top:50%;`)}
		${({ theme }) => {
			return {
				color: theme.colors.black24,
			};
		}}
	}
`;

type IMessageProps = types.IStyledProps & { error: boolean; marginBottom?: number };

export const TextFieldMessage = styled(Caption)<IMessageProps>`
	${({ error, marginBottom, theme }) => {
		return {
			color: error ? theme.colors.warning : theme.colors.black54,
			marginBottom,
		};
	}}
`;
