import React, { FunctionComponent } from 'react';
import { Col, Row } from 'react-grid-system';
import { BodyMBold, BodyM } from '@Components/Typography/Typography';
import { ColumnWrapper, TableRow } from './TechnicalSpecification.styled';
import ConditionalRender from '@Components/ConditionalRender/ConditionalRender';
import { ITechnicalSpecification } from '@ApiModels/productById';
import { ESHOP_MARKETING_TAG, DEVICE_LABEL_TYPE, COMMITMENT_BAQATI_CONFIG } from '@Config/app.config';
interface ITechnicalSpecificationItems {
	items?: ITechnicalSpecification[];
}

const TechnicalSpecification: FunctionComponent<ITechnicalSpecificationItems> = ({ items }) => {
	return (
		<div>
			{items?.map((item, index) => (
				<ConditionalRender
					key={index}
					show={
						item.name !== ESHOP_MARKETING_TAG &&
						item.name !== DEVICE_LABEL_TYPE &&
						item.name !== COMMITMENT_BAQATI_CONFIG
					}
					onTrue={() => (
						<TableRow>
							<Row>
								<Col>
									<ColumnWrapper>
										<BodyMBold>{item.name}</BodyMBold>
									</ColumnWrapper>
								</Col>
								<Col>
									<ColumnWrapper>
										<BodyM>{item.description}</BodyM>
									</ColumnWrapper>
								</Col>
							</Row>
						</TableRow>
					)}
				/>
			))}
		</div>
	);
};

export default TechnicalSpecification;
