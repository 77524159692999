import React, { FunctionComponent, useState, useEffect, useRef } from 'react';

import {
	OutletPickupWrapper,
	MapWrapper,
	MapElement,
	OutletWrapper,
	RadioButtonWrapper,
	OutletLabelsWrapper,
	OutletSmallContainerWrapper,
	LongContentLabelWrapper,
	HourLabelWrapper,
	OutletLongContainerWrapper,
	LongContentHourWrapper,
	OutletSmallWrapper,
} from './OutletPickup.styles';
import useTranslate from '@Utils/hooks/useTranslate';
import Map from '@Components/Map/Map';
import { Col, Row, Hidden, Visible } from 'react-grid-system';
import RadioButton from '@Components/controls/RadioButton/RadioButton';
import { ParagraphSBold, ParagraphMBold, ParagraphM, ParagraphS } from '@Components/Typography/Typography.styled';
import ConditionalRender from '@Components/ConditionalRender/ConditionalRender';
import { useOutlet } from '@Utils/context/OutletContext';
import { GoogleMap } from 'react-google-maps';

interface IMap {
	lat: number;
	lng: number;
}

const OutletPickup: FunctionComponent = () => {
	const { translate } = useTranslate();

	const { selectedOutlet, setSelectedOutlet, availableOutlets } = useOutlet();
	const mapsRef = useRef<GoogleMap>();
	const [markerList, setMarkerList] = useState<{ lat: number; lng: number }[]>([]);
	const latlng = { lat: 20.614328, lng: 56.545284 };
	const [position, setPosition] = useState<{ lat: number; lng: number }>(latlng);
	const [zoom, setZoom] = useState<number>(5.3);

	useEffect(() => {
		setMarkerList(
			availableOutlets.map((item) => {
				return { lat: Number(item.latitude), lng: Number(item.longitude) };
			})
		);
	}, []);

	useEffect(() => {
		if (!!selectedOutlet.branchId) {
			setPosition({ lat: Number(selectedOutlet.latitude), lng: Number(selectedOutlet.longitude) });
			setZoom(16);
		}
	}, [selectedOutlet]);

	useEffect(() => {
		mapsRef.current?.panTo(position);
	}, [position]);

	return (
		<OutletPickupWrapper>
			<Col xs={11} sm={11} lg={6} md={6} xl={6}>
				{availableOutlets.map((item) => (
					<ConditionalRender
						key={item.branchId}
						show={item.available}
						onTrue={() => (
							<OutletWrapper selected={selectedOutlet === item} key={item.branchId}>
								<OutletSmallContainerWrapper>
									<Row>
										<RadioButtonWrapper>
											<RadioButton
												fontColor="black87"
												onClick={() => {
													setSelectedOutlet(item);
												}}
												value={!!item}
												selected={selectedOutlet === item}
												label=""
												name="outletInformation"
											/>
										</RadioButtonWrapper>
										<OutletLabelsWrapper>
											<ConditionalRender
												show={selectedOutlet === item}
												onTrue={() => (
													<>
														<ParagraphMBold>{item.branchName}</ParagraphMBold>
														<ParagraphSBold>{item.city}</ParagraphSBold>
													</>
												)}
												onFalse={() => (
													<OutletSmallWrapper>
														<ParagraphM>{item.branchName}</ParagraphM>
														<ParagraphS>{item.city}</ParagraphS>
													</OutletSmallWrapper>
												)}
											/>
										</OutletLabelsWrapper>
									</Row>
								</OutletSmallContainerWrapper>
								<ConditionalRender
									show={selectedOutlet === item}
									onTrue={() => (
										<OutletLongContainerWrapper>
											<Row>
												<LongContentLabelWrapper>
													<ParagraphM>
														{translate('outlet-available.modal.address-label')}
													</ParagraphM>
												</LongContentLabelWrapper>
											</Row>
											<Row>
												<ParagraphS>{item.address}</ParagraphS>
											</Row>
											<Row>
												<LongContentLabelWrapper>
													<ParagraphM>
														{translate('outlet-available.modal.phone-label')}
													</ParagraphM>
												</LongContentLabelWrapper>
											</Row>
											<Row>
												<ParagraphS>{item.phone}</ParagraphS>
											</Row>
											<Row>
												<LongContentHourWrapper>
													<ParagraphM>
														{translate('outlet-available.modal.hours-label')}
														{'\xa0'}
													</ParagraphM>
												</LongContentHourWrapper>
											</Row>
											<Row>
												<HourLabelWrapper>
													<ParagraphS>
														{translate('outlet-available.modal.sunday-label')}
														{'\xa0'}
													</ParagraphS>
													<ConditionalRender
														show={!!item.openHours?.sunday}
														onTrue={() => <ParagraphS>{item.openHours?.sunday}</ParagraphS>}
														onFalse={() => (
															<ParagraphS color="warning">
																{translate('outlet-available.modal.closed-label')}
															</ParagraphS>
														)}
													/>
												</HourLabelWrapper>
											</Row>
											<Row>
												<HourLabelWrapper>
													<ParagraphS>
														{translate('outlet-available.modal.monday-label')}
														{'\xa0'}
													</ParagraphS>
													<ConditionalRender
														show={!!item.openHours?.monday}
														onTrue={() => <ParagraphS>{item.openHours?.monday}</ParagraphS>}
														onFalse={() => (
															<ParagraphS color="warning">
																{translate('outlet-available.modal.closed-label')}
															</ParagraphS>
														)}
													/>
												</HourLabelWrapper>
											</Row>
											<Row>
												<HourLabelWrapper>
													<ParagraphS>
														{translate('outlet-available.modal.tuesday-label')}
														{'\xa0'}
													</ParagraphS>
													<ConditionalRender
														show={!!item.openHours?.tuesday}
														onTrue={() => (
															<ParagraphS>{item.openHours?.tuesday}</ParagraphS>
														)}
														onFalse={() => (
															<ParagraphS color="warning">
																{translate('outlet-available.modal.closed-label')}
															</ParagraphS>
														)}
													/>
												</HourLabelWrapper>
											</Row>
											<Row>
												<HourLabelWrapper>
													<ParagraphS>
														{translate('outlet-available.modal.wednesday-label')}
														{'\xa0'}
													</ParagraphS>
													<ConditionalRender
														show={!!item.openHours?.wednesday}
														onTrue={() => (
															<ParagraphS>{item.openHours?.wednesday}</ParagraphS>
														)}
														onFalse={() => (
															<ParagraphS color="warning">
																{translate('outlet-available.modal.closed-label')}
															</ParagraphS>
														)}
													/>
												</HourLabelWrapper>
											</Row>
											<Row>
												<HourLabelWrapper>
													<ParagraphS>
														{translate('outlet-available.modal.thursday-label')}
														{'\xa0'}
													</ParagraphS>
													<ConditionalRender
														show={!!item.openHours?.thursday}
														onTrue={() => (
															<ParagraphS>{item.openHours?.thursday}</ParagraphS>
														)}
														onFalse={() => (
															<ParagraphS color="warning">
																{translate('outlet-available.modal.closed-label')}
															</ParagraphS>
														)}
													/>
												</HourLabelWrapper>
											</Row>
											<Row>
												<HourLabelWrapper>
													<ParagraphS>
														{translate('outlet-available.modal.friday-label')}
														{'\xa0'}
													</ParagraphS>
													<ConditionalRender
														show={!!item.openHours?.friday}
														onTrue={() => <ParagraphS>{item.openHours?.friday}</ParagraphS>}
														onFalse={() => (
															<ParagraphS color="warning">
																{translate('outlet-available.modal.closed-label')}
															</ParagraphS>
														)}
													/>
												</HourLabelWrapper>
											</Row>
											<Row>
												<HourLabelWrapper>
													<ParagraphS>
														{translate('outlet-available.modal.saturday-label')}
														{'\xa0'}
													</ParagraphS>
													<ConditionalRender
														show={!!item.openHours?.saturday}
														onTrue={() => (
															<ParagraphS>{item.openHours?.saturday}</ParagraphS>
														)}
														onFalse={() => (
															<ParagraphS color="warning">
																{translate('outlet-available.modal.closed-label')}
															</ParagraphS>
														)}
													/>
												</HourLabelWrapper>
											</Row>
										</OutletLongContainerWrapper>
									)}
								/>
							</OutletWrapper>
						)}
					/>
				))}
				<Visible xs sm>
					<Row>
						<MapWrapper>
							<Map
								mapElement={<MapElement />}
								zoom={zoom}
								zoomControl={true}
								mapsRef={mapsRef}
								position={position}
								markerArray={markerList}
								containerElement={<MapElement />}
								locked={false}
							/>
						</MapWrapper>
					</Row>
				</Visible>
			</Col>
			<Hidden xs sm>
				<Col lg={6} md={6} xl={6}>
					<MapWrapper>
						<Map
							mapElement={<MapElement />}
							zoom={zoom}
							zoomControl={true}
							position={position}
							mapsRef={mapsRef}
							markerArray={markerList}
							containerElement={<MapElement />}
							locked={false}
						/>
					</MapWrapper>
				</Col>
			</Hidden>
		</OutletPickupWrapper>
	);
};

export default OutletPickup;
