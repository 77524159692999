import React, { FunctionComponent, useState } from 'react';
import { Col, Row } from 'react-grid-system';
import useTranslate from '@Utils/hooks/useTranslate';
import {
	CardWrapper,
	Wrapper,
	HeaderWrapper,
	SubHeaderWrapper,
	ChevronWrapper,
	TextButton,
	BGContainer,
} from '@Components/Card/cards/LoginCard/LoginCard.styled';
import Icon from '@Components/Icon/Icon';
import { BodyS, ParagraphMBold } from '@Components/Typography/Typography.styled';
import Divider from '@Components/Divider/Divider';
import ConditionalRender from '@Components/ConditionalRender/ConditionalRender';
import TextField from '@Components/controls/TextField/TextField';
import { PrimaryLargeButtonFixed } from '@Components/controls/Button/Button';
import { useDispatch, useSelector } from 'react-redux';
import { setModal } from '@Redux/modules/modal/actions';
import { AuthenticationService } from '@Services/authentication/authenticationService';
import externalLinks from '@Utils/externalLinks/externalLinks';
import { Button } from '@Views/Plans/Plans.styled';
import { logOut, setLoginType } from '@Redux/modules/api/authentication/actions';
import { deleteWCTokens } from '@Redux/modules/api/headers/actions';
import { invalidateAllCache } from '@Redux/modules/api/cache/actions';
import { clearCheckoutDetails } from '@Redux/modules/checkout/actions';
import { updateCart } from '@Redux/modules/cart/actions';
import { getLang } from '@Utils/language/language';
import { useHistory } from 'react-router-dom';
import { addBusyIndicator } from '@Redux/modules/busyIndicator/actions';
import { GET_TELESALES_CART_BI } from '../../../../App';

const LoginCard: FunctionComponent<types.cards.ILoginCardProps> = ({ params: { onContinue } }): JSX.Element => {
	const { translate } = useTranslate();
	const [loginOpen, setLoginOpen] = useState<boolean>(false);
	const CHEVRON_ICON_SIZE = 24;
	const [login, setLogin] = useState<string | number>('');
	const [password, setPassword] = useState<string | number>('');
	const [loading, setLoading] = useState<boolean>(false);
	const [isError, setIsError] = useState<boolean>(false);
	const [errorMsg, setErrorMsg] = useState<string>('');
	const telesales = useSelector((state: types.redux.IState) => state.api.authentication.telesales);
	const dispatch = useDispatch();
	const history = useHistory();

	const handleClose = () => {
		dispatch(setLoginType(undefined));
		dispatch(deleteWCTokens());
		dispatch(invalidateAllCache());
		dispatch(logOut());
		dispatch(clearCheckoutDetails());
		dispatch(
			setModal({
				type: null,
				data: null,
			})
		);
		dispatch(updateCart(true));
		history.push(`/${getLang()}/store`);
	};

	const _signIn = () => {
		dispatch(addBusyIndicator(GET_TELESALES_CART_BI));
		setLoading(true);
		setIsError(false);
		setErrorMsg('');
		AuthenticationService.login(login.toString(), password.toString(), telesales).subscribe(
			() => {
				setLoading(false);
				!telesales && handleClose();
				onContinue?.();
			},
			() => {
				setIsError(true);
				setLoading(false);
				setErrorMsg(translate('modal.login.invalid.credentials'));
			}
		);
	};

	const forgotPassword = () => {
		window.open(externalLinks('FORGOT_PASSWORD'));
	};

	return (
		<Wrapper>
			<Row>
				<ChevronWrapper lg={1} md={1} sm={1} xs={3}>
					{loginOpen ? (
						<Icon
							name="chevronUp"
							width={CHEVRON_ICON_SIZE}
							height={CHEVRON_ICON_SIZE}
							onClick={() => {
								setLoginOpen(false);
							}}
						/>
					) : (
						<Icon
							name="chevronDown"
							width={CHEVRON_ICON_SIZE}
							height={CHEVRON_ICON_SIZE}
							onClick={() => setLoginOpen(true)}
						/>
					)}
				</ChevronWrapper>
				<Col>
					<HeaderWrapper>
						<ParagraphMBold>{translate('telesales-checkout.login.title')}</ParagraphMBold>
					</HeaderWrapper>
					<Divider marginBottom={4} withoutLine />
					<SubHeaderWrapper>
						<BodyS>{translate('telesales-checkout.login.subtitle')}</BodyS>
					</SubHeaderWrapper>
					<ConditionalRender
						show={loginOpen}
						onTrue={() => (
							<>
								<CardWrapper>
									<BGContainer>
										<Icon name="loginBg" width={430} height={900} />
									</BGContainer>
									<Row>
										<Col lg={6} md={6} sm={6} xs={9}>
											<TextField
												id="login"
												name="login"
												type="text"
												placeholder={translate('modal.login.phone.number.or.email')}
												value={login}
												onChange={(login) => {
													setLogin(login);
												}}
												marginBottom={8}
												error={isError}
												onEnter={() => _signIn()}
												width="100%"
											/>
											<TextField
												id="1"
												name="password"
												placeholder={translate('modal.login.password')}
												value={password}
												onChange={(password) => {
													setPassword(password);
												}}
												marginBottom={8}
												message={errorMsg}
												error={isError}
												type="password"
												onEnter={() => _signIn()}
												width="100%"
											/>
											<Divider marginBottom={8} withoutLine />
											<Button>
												<PrimaryLargeButtonFixed
													uniqueId="modal.login.sign.in"
													listener={loading}
													onClick={() => _signIn()}
												>
													{translate('modal.login.sign.in')}
												</PrimaryLargeButtonFixed>
											</Button>
											<Divider marginBottom={16} withoutLine />
											<TextButton onClick={() => forgotPassword()}>
												{translate('modal.login.forgot.password')}
											</TextButton>
											<Divider marginBottom={40} withoutLine />
										</Col>
									</Row>
								</CardWrapper>
							</>
						)}
					/>
				</Col>
			</Row>
		</Wrapper>
	);
};

export default LoginCard;
