import RxQuery from '@Utils/api/rxQuery/rxQuery';
import endpoints from '@Utils/api/endpoints';

const wcm = () => new RxQuery('wcm');

export const getCities = (): RxQuery => {
	return wcm().setEndpoint(endpoints.wcm.getCities()).setTimeout(1200000);
};

export const getRegions = (): RxQuery => {
	return wcm().setEndpoint(endpoints.wcm.getRegions()).setTimeout(1200000);
};

export const getTermsAndConditions = (): RxQuery => {
	return wcm().setEndpoint(endpoints.wcm.getTermsAndConditionsContent()).setTimeout(1200000);
};

export const getRemoteConfig = (): RxQuery => {
	return wcm().setEndpoint(endpoints.wcm.remoteConfig());
};

export const getEnahancedTermsAndCondition = (lang: string, isTCPlan: boolean): RxQuery => {
	return wcm().setEndpoint(endpoints.wcm.enhancedTermAndCondition({ lang, isTCPlan }));
};
