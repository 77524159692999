interface IService {
	id: string;
	departmentId: string;
	serviceGroupId: string;
	name: string;
}

export interface IAppointment {
	service: IService;
	ticketNumber: string;
	appointmentDate: string;
}

export interface IBookAppointment {
	returnValue: IAppointment;
}

const model: types.models.IInputModel = {
	returnValue: 'response.bookAppointmentResponse.appointment',
};

export default model;
