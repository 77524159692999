import styled from 'styled-components';
import { BodyM, BodyMBold, Title } from '@Components/Typography/Typography.styled';

export interface ISizes {
	isL?: boolean;
	isMd?: boolean;
	isSm?: boolean;
	isXs?: boolean;
}

export const TenatVerificationSuccessWrapper = styled.div<{ sizes?: ISizes }>`
	${({ sizes }) => ({
		padding: sizes?.isL
			? '48px 80px'
			: sizes?.isMd
			? '48px 90px'
			: sizes?.isSm
			? '48px 60px'
			: sizes?.isXs
			? '32px 16px'
			: '48px 128px 48px 128px',
	})}
	display: flex;
	flex-direction: column;
	align-items: center;
`;

export const TenatDataWrapper = styled.div`
	padding: 24px;
	border-radius: 8px;
	display: flex;
	gap: 32px;
	width: 100%;
	${({ theme }) => ({
		backgroundColor: theme.colors.grey50,
	})}
`;

export const ButtonWrapper = styled.div`
	width: 240px;
`;

export const Label = styled(BodyM)`
	margin-bottom: 16px;
	${({ theme }) => ({
		color: theme.colors.black54,
	})}
`;

export const TenatData = styled(BodyMBold)`
	margin-bottom: 16px;
	${({ theme }) => ({
		color: theme.colors.black87,
	})}
`;

export const MainTitle = styled(Title)`
	margin-top: 16px;
	margin-bottom: 24px;
	${({ theme }) => ({
		color: theme.colors.positive,
	})}
`;

export const SubTitle = styled(BodyM)`
	margin: 24px 0;
	${({ theme }) => ({
		color: theme.colors.black87,
	})}
`;
