const normalizeFont = (
	fontFamily: string,
	fontSize: number,
	lineHeight: number,
	letterSpacing?: number
): types.ITypographyParams => {
	// TODO extend function when languages will be added
	return {
		fontFamily,
		fontSize: `${fontSize}px`,
		letterSpacing: `${letterSpacing ? letterSpacing : 0}px`,
		lineHeight: `${lineHeight}px`,
	};
};

export default normalizeFont;
