import styled from 'styled-components';
import { Caption } from '@Components/Typography/Typography.styled';
import DatePicker from 'react-date-picker';

type IDatePickerWrapperProps = types.IStyledProps & {
	active: boolean;
	disabled: boolean;
	error: boolean;
	marginBottom?: number;
	message?: boolean;
	height?: number;
	noBorder?: boolean;
};

type DatePickerField = { width: string | number };

export const DatePickerContainer = styled.div<DatePickerField>`
	${({ width }) => ({
		width,
	})}
	max-width: 100%;
`;

export const DatePickerWrapper = styled.div<IDatePickerWrapperProps>`
	display: inline-block;
	box-sizing: border-box;
	border-radius: 4px;
	transition: all 0.1s ease-in-out;
	height: 56px;
	width: 100%;
	max-width: 100%;
	cursor: text;
	${({ theme, marginBottom, message, height }) => ({
		borderColor: theme.colors.black54,
		background: theme.colors.white,
		marginBottom: message ? 8 : marginBottom,
		height: height ?? '56px',
	})};

	${({ active, disabled, theme, error, noBorder }) => {
		if (disabled) {
			return `
					cursor: default;
					padding: 8px;
					background: ${theme.colors.black6};
					border: 1px solid ${theme.colors.black12};
				`;
		} else if (error) {
			return `
					padding: 7px;
					border: 2px solid ${theme.colors.warning};
				`;
		} else if (active) {
			return `
					padding: 7px;
					border: 2px solid;
					border-color: ${theme.colors.primary};
					&:hover {
						border: 2px solid ${theme.colors.primary};
					};
					&:active {
						border: 2px solid ${theme.colors.primary};
					}`;
		} else {
			return `
					padding: 8px;
					border: ${noBorder ? 'none' : `1px solid ${theme.colors.black54}`};
					&:hover {
						padding: 7px;
						border: 2px solid ${theme.colors.black87};
					};
					&:active {
						padding: 7px;
						border: 2px solid ${theme.colors.primary};
					}`;
		}
	}};
`;

export const DatePickerComponent = styled(DatePicker)<{ typography: keyof types.ITypography }>`
	position: relative;
	display: flex;
	height: 100%;
	width: 100%;
	> div {
		border: none;
	}
	input.react-date-picker__inputGroup__input:invalid {
		background: none;
		border-radius: 4px;
	}
	span {
		${({ theme, typography }) => theme.typography[typography]}
	}
	input {
		${({ theme, typography }) => theme.typography[typography]}
		outline:none;
	}
`;

type IMessageProps = types.IStyledProps & { error: boolean; marginBottom?: number };

export const Message = styled(Caption)<IMessageProps>`
	${({ error, marginBottom, theme }) => {
		return {
			color: error ? theme.colors.warning : theme.colors.black54,
			marginBottom,
		};
	}}
`;
