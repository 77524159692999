import { BodyM, MiniDescription } from '@Components/Typography/Typography.styled';
import { isRTL, marginLeft } from '@Utils/layout/layoutSupport';
import styled from 'styled-components';

export const ComparisonBarContainer = styled.div<{ bottom: number; width: number; isXs: boolean; marginLeft?: number }>`
	width: inherit;
	height: ${({ isXs }) => (isXs ? 102 : 56)}px;
	padding: 0px 16px 0px 8px;
	position: fixed;
	transition: all 0.3s ease-in-out 0s;
	${({ theme, bottom, width, marginLeft }) => ({
		backgroundColor: theme.colors.white,
		boxShadow: `0 7px 8px 0 rgba(0, 0, 0, 0.05), 0 5px 22px 4px rgba(0, 0, 0, 0.04), 12px -2px 17px 2px ${theme.colors.black6}`,
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'flex-end',
		paddingBottom: '12px',
		bottom: `${bottom}px`,
		width: `${width}px`,
		marginLeft,
	})};
	max-width: 920px;
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
	z-index: 10;
`;

export const Thumbnail = styled.img`
	height: 32px;
	transition: all 0.3s ease-in-out 0s;
`;

export const ThumbnailsContainer = styled.div`
	transition: all 0.3s ease-in-out 0s;
	display: flex;
	top: 8px;
	flex-direction: row;
	position: absolute;
	transform: translateX(-50%);
	left: 50%;
`;

export const ThumbnailWrapper = styled.div<{ touchscreen?: boolean }>`
	padding: 8px;
	height: 40px;
	position: relative;
	transition: all 0.3s ease-in-out 0.15s;
	& > div {
		transition: all 0.1s ease-in-out 0.1s;
		opacity: 0;
	}
	&:hover {
		& > img {
			opacity: 0.2;
		}
		${({ touchscreen }) =>
			!touchscreen &&
			`
			top: -15px;
			& > div {
			transform: rotate(45deg);
			opacity: 1;
			}
		`}
	}
`;

export const ErrorContainer = styled.div<{ showError?: boolean }>`
	position: absolute;
	top: 0;
	z-index: -1;
	opacity: 0;
	margin: auto;
	${() => marginLeft(`calc(50% - ${isRTL() ? 150 : 215}px)`)}
	padding: 8px 16px;
	border-radius: 4px;
	display: flex;
	align-items: center;
	transition: all 0.3s ease-in-out 0s;
	${({ theme }) => ({ backgroundColor: theme.colors.primary })};
	${({ showError }) => showError && `top: -100%; opacity: 1`}
`;

export const ErrorText = styled(BodyM)`
	margin-top: 4px;
	margin-left: 4px;
	${({ theme }) => ({ color: theme.colors.white })}
`;

export const IconContainer = styled.div<{ touchscreen: boolean }>`
	${({ touchscreen }) =>
		touchscreen &&
		`
		position: absolute;
		top: calc(50% - 10px);
		left: calc(50% - 6px);

		&&& {
			opacity: 1;
		}
		& > div {
			opacity: 1;
			transform: rotate(45deg);
		}
	`}
	${({ theme }) => ({
		backgroundColor: theme.colors.white,
		borderRadius: '50%',
	})}
`;

export const TopThumbnailsContainer = styled.div`
	width: 100%;
	display: flex;
	position: absolute;
	top: 0;
	flex-direction: row;
	align-items: center;
	justify-content: center;
`;

export const DescriptionWrapper = styled(MiniDescription)`
	${({ theme }) => ({
		color: theme.colors.black87,
	})}
	padding-bottom: 12px;
`;

export const ComparisonArrowContainer = styled.div`
	padding: 16px 8px 0 8px;
`;

export const ButtonWrapper = styled.div`
	display: inline-flex;
	justify-content: flex-end;
`;
