import styled from 'styled-components';

export const CardWrapper = styled.div`
	padding: 16px;
	width: 80vw;
	max-width: 800px;
`;

export const ActionWrapper = styled.div`
	display: flex;
	justify-content: flex-end;
	cursor: pointer;
`;
