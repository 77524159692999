import styled from 'styled-components';
import { DisplayM, DisplayMBold, DisplayMBoldH1, Subheading, Value } from '@Components/Typography/Typography';
import { Row } from 'react-grid-system';
import { paddingLeft, right, left } from '@Utils/layout/layoutSupport';

export const SectionName = styled(Subheading)`
	text-transform: uppercase;
	padding: 8px;
	${({ theme }) => ({
		color: theme.colors.black54,
	})}
`;

export const SortingMethodsRow = styled(Row)<types.IStyledProps>`
	padding: 0 16px;
`;

export const SearchingWrapper = styled.div<types.IStyledProps>`
	display: flex;
	margin-top: 40px;
	margin-bottom: 48px;
`;

export const ControlsWrapper = styled.div<types.IStyledProps>`
	display: flex;
`;

export const SearchingTitle = styled(DisplayMBoldH1)<types.IStyledProps>`
	${({ theme }) => ({
		color: theme.colors.black87,
	})}
`;

export const SearchingFound = styled(DisplayM)<types.IStyledProps>`
	${({ theme }) => ({
		color: theme.colors.black54,
	})}
	${() => paddingLeft(16)}
`;

// Empty state styles
export const EmptyStateInfoWrapper = styled.div<types.IStyledProps>`
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-bottom: 64px;
`;

export const EmptyStateTitle = styled(DisplayMBold)<types.IStyledProps>`
	margin-bottom: 16px;
	${({ theme }) => ({
		color: theme.colors.black87,
	})}
`;

export const EmptyStateDescription = styled(Value)<types.IStyledProps>`
	margin-bottom: 40px;
	${({ theme }) => ({
		color: theme.colors.black54,
	})}
`;

export const SubmitWrapper = styled(Row)<types.IStyledProps>`
	width: 100%;
`;

export const SearchInputWrapper = styled.div<types.IStyledProps>`
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
`;

export const CategoryTitleWrapper = styled.div<types.IStyledProps>`
	display: flex;
	margin-top: 16px;
	margin-bottom: 24px;
`;

export const BannerButtonWrapper = styled.div<{ isXS?: boolean }>`
	position: absolute;
	${({ isXS }) => right(isXS ? 26 : 46)}
	top: 28px;
`;

export const BannerLabelWrapper = styled.div<{ isXSSMMD?: boolean; isXS?: boolean }>`
	position: absolute;
	display: flex;
	align-items: center;
	justify-content: center;
	${({ isXS }) => left(isXS ? 26 : 46)}
	${({ isXSSMMD }) => ({
		top: isXSSMMD ? '26px' : '36px',
		flexDirection: isXSSMMD ? 'column' : 'row',
		gap: isXSSMMD ? '4px' : '8px',
	})}
`;

export const AppleBannerWrapper = styled.div`
	position: relative;
	width: 100%;
	background-color: white;
	margin: 16px 0px;
	display: flex;
	align-items: center;
	justify-content: center;
`;
