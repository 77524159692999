// TODO extend when languages will be added

export interface ISingleFontAttributes {
	bold: string;
	extraBold: string;
	light: string;
	regular: string;
	medium: string;
}

export interface ISingleFont {
	albert: ISingleFontAttributes;
	alvar: ISingleFontAttributes;
	plex: ISingleFontAttributes;
}

export interface IFonts {
	ar: ISingleFont;
	en: ISingleFont;
}

const fonts: IFonts = {
	en: {
		albert: {
			bold: 'FSAlbert-Bold',
			extraBold: 'FSAlbert-ExtraBold',
			light: 'FSAlbert-Light',
			regular: 'FSAlbert-Regular',
			medium: 'FSAlbert-Regular',
		},
		alvar: {
			bold: 'FSAlvar-Bold',
			extraBold: 'FSAlvar-Bold',
			light: 'FSAlvar-Light',
			regular: 'FSAlvar-Regular',
			medium: 'FSAlvar-Regular',
		},
		plex: {
			bold: 'IBMPlexSans-Bold',
			extraBold: 'IBMPlexSans-Bold',
			light: 'IBMPlexSans-Light',
			regular: 'IBMPlexSans-Regular',
			medium: 'IBMPlexSans-Medium',
		},
	},
	ar: {
		albert: {
			bold: 'FSAlbert-Bold',
			extraBold: 'FSAlbert-ExtraBold',
			light: 'FSAlbert-Light',
			regular: 'FSAlbert-Regular',
			medium: 'FSAlbert-Regular',
		},
		alvar: {
			bold: 'FSAlvar-Bold',
			extraBold: 'FSAlvar-Bold',
			light: 'FSAlvar-Light',
			regular: 'FSAlvar-Regular',
			medium: 'FSAlvar-Regular',
		},
		plex: {
			bold: 'IBMPlexSansArabic-Bold',
			extraBold: 'IBMPlexSansArabic-Bold',
			light: 'IBMPlexSansArabic-Light',
			regular: 'IBMPlexSansArabic-Regular',
			medium: 'IBMPlexSansArabic-Medium',
		},
	},
};

export default fonts;
