import useScreen from '@Utils/hooks/useScreen';
import useTranslate from '@Utils/hooks/useTranslate';
import React, { FunctionComponent } from 'react';
import { BannerCounterContainer } from './BannerCounter.styled';
import CounterSection from './subcomponents/CounterSection';

interface IBannerCounterProps {
	days: string;
	hours: string;
	minutes: string;
	seconds: string;
}

const BannerCounter: FunctionComponent<IBannerCounterProps> = ({ days, hours, minutes, seconds }) => {
	const { translate } = useTranslate();
	const { screen } = useScreen();
	const isXS = screen('xs');

	return (
		<BannerCounterContainer isXS={isXS}>
			<CounterSection digits={days} title={translate('counter.days')} />
			<CounterSection digits={hours} title={translate('counter.hours')} />
			<CounterSection digits={minutes} title={translate('counter.minutes')} />
			<CounterSection digits={seconds} title={translate('counter.seconds')} />
		</BannerCounterContainer>
	);
};

export default BannerCounter;
