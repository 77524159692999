import { IPriceByCatEntry } from '@ApiModels/priceByCatEntry';

export interface IInstallmentAmountResponse extends IPriceByCatEntry {
	resourceName: string;
}

const model: types.models.IInputModel = {
	price: ['response', []],
};

export default model;
