import { IAvailableColorWithId } from '@Components/controls/ColorPicker/subcomponents/Slider';
import { IAvailableValueItem } from '@Components/MultiSegmentPicker/MultiSegmentPicker';
import { IInventoryByProductId } from './inventoryByProductId';
import { IAttribute, IValue, IPrice, ISeo, StringBoolean } from './productsByPartNumber';
import { getItems } from './utils/attributeGetters';

export interface IContent {
	attributes?: IAttribute<IValue>[];
	buyable: string;
	hasSingleSKU: boolean;
	id: string;
	name: string;
	parentCatalogGroupID: string;
	partNumber: string;
	price: IPrice[];
	seo?: ISeo;
	longDescription: string;
	shortDescription: string;
	storeID: string;
	thumbnail: string;
	type: string;
}

interface IBreadCrumpTrailEntryView {
	label: string;
	seo: ISeo;
	type: string;
	value: string;
}

export interface IEntry {
	label: string;
	value: string;
	count: string;
	extendedData: {
		uniqueId: string;
	};
}

export interface IExtendedData {
	allValuesReturned: StringBoolean;
	allowMultipleValueSelection: StringBoolean;
	displaySequence: string;
	displayable: StringBoolean;
	fname: string;
	max_display: string;
	maximumValuesToDisplay: string;
	name: string;
	propertyId: string;
	propertyvalue: string;
	sortorder: string;
	zero_display: StringBoolean;
}

export interface IFacet {
	entry: IEntry[];
	extendedData: IExtendedData;
	name: string;
	value: string;
}

export interface IProductsByCategoryId {
	contents: IContent[];
	items: IItem[];
	total: number;
	facets: IFacet[];
}

export interface IColorWithId {
	color: string;
	identifier: string;
	id: string;
	value: string;
}

export interface IValueWithId {
	value: string;
	id: string;
	parsedIdentifier: string;
}

export interface IItem {
	attributes?: IAttribute<IValue>[];
	price: number;
	discount?: number;
	colors: IColorWithId[];
	availableCountries: IColorWithId[];
	availableValues: IValueWithId[];
	availableCapacity: IValueWithId[];
	additionalInfo?: {
		label: string;
		value: string;
	};
	colorAttributeId?: string;
	capacityAttributeId?: string;
	thumbnail: string;
	name: string;
	parentCatalogGroupID?: string | string[];
	uniqueID: string;
	shortDescription: string;
	longDescription: string;
	manufacturer?: string;
	itemType?: string | string[];
	isGiftCard?: boolean;
	isRelated?: boolean;
	isUpsell?: boolean;
	id?: string;
	vatPercent?: string;
	priceDisplay?: number;
	priceOffer?: number;
	preorder: boolean;
	preorderDate?: string;
	isDigitalProduct?: boolean;
	isItem?: boolean;
	isItemHBBInstallments?: boolean;
	planAvailable?: boolean;
	instalmentValue?: string;
	fullImage?: string;
	seo?: string;
	availability?: boolean;
	availableCapacities?: IAvailableValueItem[];
	availableColors?: IAvailableColorWithId[];
	inventory?: IInventoryByProductId['inventory'];
	place?: number;
	pickupOrderAvailable?: boolean;
	launchDate?: string;
	allItemType?: string[];
	parents?: string[];
	productTextInstallmentValue?: string;
	partNumber?: string;
}

export interface IFilterEntry extends IEntry {
	enabled: boolean;
	parsedValue: string;
}

export interface IFilter {
	entry: IFilterEntry[];
	extendedData: IExtendedData;
	name: string;
	value: string;
}

interface IModel extends IProductsByCategoryId {
	_contents: IContent[];
	_breadCrumpTrailEntryView: IBreadCrumpTrailEntryView[];
	facets: IFacet[];
	_total: number | null;
}

const model: types.models.IInputModel = {
	_contents: ['response.contents', []],
	_breadCrumpTrailEntryView: 'response.breadCrumpTrailEntryView',
	facets: 'response.facets',
	_total: 'response.total',
	total: (model: IModel) => model?._total ?? 0,
	contents: (model: IModel) => model?._contents ?? [],
	items: (model: IModel): IItem[] => {
		return getItems(model._contents ?? []);
	},
};

export default model;
