import { ActionFunction1, createAction, Action } from 'redux-actions';

export interface ISetViewType {
	payload: types.redux.settings.viewType;
}

export const setViewType: ActionFunction1<ISetViewType['payload'], Action<ISetViewType['payload']>> = createAction(
	'SET_VIEW_TYPE',
	(action: ISetViewType['payload']) => action
);
