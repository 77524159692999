import { BillingType, IAccount } from '@ApiModels/accounts';
import { PrimaryMediumButtonFixed } from '@Components/controls/Button/Button';
import Checkbox from '@Components/controls/Checkbox/Checkbox';
import { CheckboxShape } from '@Components/controls/Checkbox/Checkbox.styled';
import Divider from '@Components/Divider/Divider';
import Icon from '@Components/Icon/Icon';
import { setModal } from '@Redux/modules/modal/actions';
import { OrderFlowService } from '@Services/orderFlow/orderFlow';
import useTranslate from '@Utils/hooks/useTranslate';
import React, { FunctionComponent, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
	CloseButtonContainer,
	SelectNumberModalContainer,
	SubtitleContainer,
	TitleWrapper,
	SubtitleWrapper,
	NumberContainer,
	PrefixWrapper,
	NumberWrapper,
	LeftNumberContainer,
	ButtonContainer,
} from './SelectNumber.styled';

const SelectNumberModal: FunctionComponent<{ orderItemId: string; accounts: IAccount[] }> = ({
	orderItemId,
	accounts,
}) => {
	const dispatch = useDispatch();
	const { translate } = useTranslate();
	const [selectedNumber, setSelectedNumber] = useState('');
	const [loading, setLoading] = useState(false);

	const Number = ({ accountNum, billingType }: { accountNum?: string; billingType?: BillingType }) => {
		return (
			<NumberContainer>
				<LeftNumberContainer>
					<Checkbox
						active={selectedNumber === accountNum}
						shape={CheckboxShape.SQUARE}
						onChange={() => {
							if (selectedNumber === accountNum) {
								setSelectedNumber('');
							} else {
								setSelectedNumber(accountNum ?? '');
							}
						}}
						size={18}
					/>
					<PrefixWrapper>+968</PrefixWrapper>
					<NumberWrapper> {accountNum}</NumberWrapper>
				</LeftNumberContainer>
				<PrefixWrapper>{billingType === 'PREPAID' ? 'Hayyak' : 'Baqati'}</PrefixWrapper>
			</NumberContainer>
		);
	};

	return (
		<SelectNumberModalContainer>
			<CloseButtonContainer>
				<Icon
					name="close"
					fill="black87"
					width={24}
					height={24}
					onClick={() => {
						dispatch(setModal({ type: null, data: null }));
					}}
				/>
			</CloseButtonContainer>
			<TitleWrapper>{translate('select-number-modal.title')}</TitleWrapper>
			<Divider marginTop={16} marginBottom={16} />
			<SubtitleContainer>
				<SubtitleWrapper>{translate('select-number-modal.subtitle')}</SubtitleWrapper>
				<Icon name="eSimColor" width={80} height={64} />
			</SubtitleContainer>
			{accounts.map(({ accountNum, billingType }) => (
				<Number key={accountNum} accountNum={accountNum} billingType={billingType} />
			))}
			<ButtonContainer>
				<PrimaryMediumButtonFixed
					onClick={() => {
						setLoading(true);
						OrderFlowService.manageOrderItemESim({ orderItemId, esimNo: selectedNumber }).subscribe(
							() => {
								dispatch(setModal({ type: null, data: null }));
							},
							() => {},
							() => {
								setLoading(false);
							}
						);
					}}
					listener={loading}
					uniqueId="select-number-modal.select"
					disabled={!selectedNumber || loading}
				>
					{translate('get-esim.button.select')}
				</PrimaryMediumButtonFixed>
			</ButtonContainer>
		</SelectNumberModalContainer>
	);
};

export default SelectNumberModal;
