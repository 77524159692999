import React, { FunctionComponent } from 'react';
import { Rectangular, SkeletonCardContainer } from '@Components/Skeleton/Skeleton.styled';
import { Container } from './ComparisonCards.styled';

const ComparisonCards: FunctionComponent<{ marginLeft: number }> = ({ marginLeft }) => {
	return (
		<Container marginLeft={marginLeft}>
			<SkeletonCardContainer width="260">
				<Rectangular width={240} height={380} />
			</SkeletonCardContainer>
			<SkeletonCardContainer width="260" marginLeft={8}>
				<Rectangular width={240} height={380} />
			</SkeletonCardContainer>
			<SkeletonCardContainer width="260" marginLeft={8}>
				<Rectangular width={240} height={380} />
			</SkeletonCardContainer>
		</Container>
	);
};

export default ComparisonCards;
