import styled from 'styled-components';
import { BodyMBold } from '@Components/Typography/Typography';
import { LabelWidth } from '@Components/controls/Tabs/Tabs.styled';

interface IContainerProps {
	labelWidth: LabelWidth;
	active: boolean;
	disabled: boolean;
}

interface ILabelWrapperProps {
	labelWidth: LabelWidth;
	active: boolean;
	disabled: boolean;
}

interface ILabelProps {
	active: boolean;
	disabled: boolean;
}

export const Label = styled(BodyMBold)<ILabelProps>`
	${({ active, theme }) => (active ? { color: theme.colors.white } : { color: theme.colors.primary })}
	${({ disabled, theme }) => disabled && { color: theme.colors.black38 }}
`;

export const LabelWrapper = styled.div<ILabelWrapperProps>`
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	box-sizing: border-box;
	border-radius: 20px;
	transition: all 0.1s ease-in-out;
	border: 1px solid
		${({ theme }) => {
			return theme.colors.primary;
		}};
	${({ labelWidth }) => labelWidth === LabelWidth.AUTO && { padding: '0 16px' }}
	${({ disabled, active, theme }) =>
		disabled && (active ? { borderColor: theme.colors.black12 } : { borderColor: theme.colors.black38 })}
	&:hover {
		${({ active, disabled, theme }) => active && !disabled && { backgroundColor: theme.colors.white15 }}
		& p {
			${({ active, disabled }) => !active && !disabled && { opacity: 0.85 }}
		}
	}
	&:active {
		${({ active, disabled, theme }) => active && !disabled && { backgroundColor: theme.colors.white30 }}
		& p {
			${({ active, disabled }) => !active && !disabled && { opacity: 0.75 }}
		}
	}
`;

export const Container = styled.div<IContainerProps>`
	height: 40px;
	border-radius: 20px;
	transition: all 0.1s ease-in-out;
	margin: 0 4px;
	${({ disabled, active, theme }) =>
		disabled &&
		(active
			? { backgroundColor: `${theme.colors.black12}!important` }
			: { backgroundColor: `${theme.colors.white}!important` })}
	${({ labelWidth }) => labelWidth === LabelWidth.FIXED && { width: 128 }}
	${({ disabled }) => !disabled && { cursor: 'pointer' }}
	${({ active, theme }) => (active ? { backgroundColor: theme.colors.primary } : { backgroundColor: theme.colors.white })}
	&:hover {
		${({ active, disabled, theme }) => !active && !disabled && { backgroundColor: theme.colors.primary6 }}
	}
	&:active {
		${({ active, disabled, theme }) => !active && !disabled && { backgroundColor: theme.colors.primary15 }}
	}
`;
