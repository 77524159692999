import { ISingleMarketingBanner } from '@ApiModels/marketingBanners';
import BannerCounter from '@Components/BannerCounter/BannerCounter';
import ConditionalRender from '@Components/ConditionalRender/ConditionalRender';
import useScreen from '@Utils/hooks/useScreen';
import useTimer from '@Utils/hooks/useTimer';
import moment from 'moment';
import React, { FunctionComponent } from 'react';
import { Col, Row } from 'react-grid-system';
import {
	MarketingBannerContainer,
	MarketingBannerImage,
	MarketingBannerImageVertical,
	MarketingBannerWrapper,
	TimerContainer,
} from './MarketingBanner.styled';
import TagManager from 'react-gtm-module';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getUserId } from '@Redux/modules/api/authentication/selectors';

export enum MarketingBannerType {
	HORIZONTAL_FULL_WIDTH = 'HORIZONTAL_FULL_WIDTH',
	HORIZONTAL_MEDIUM = 'HORIZONTAL_MEDIUM',
	VERTICAL = 'VERTICAL',
}

interface IMarketingBanner {
	banner: ISingleMarketingBanner;
	marginTop?: number;
	marginBottom?: number;
	withoutButton?: boolean;
	type: MarketingBannerType;
	isTradeIn?: boolean;
}

const TAG_MANAGER_CLICK_BANNER = 'click Banner';

const MarketingBanner: FunctionComponent<IMarketingBanner> = ({
	banner,
	type,
	isTradeIn = false,
	marginTop = 16,
	marginBottom = 16,
	withoutButton,
}) => {
	const { days, hours, minutes, seconds } = useTimer(banner.timerFinishTime);
	const { screen } = useScreen();
	const isXS = screen('xs');
	const isMD = screen(['md', 'sm']);
	const isSignedIn = useSelector((state: types.redux.IState) => state.api.authentication.signedIn);
	const history = useHistory();

	return (
		<MarketingBannerContainer
			type={type}
			marginTop={marginTop}
			marginBottom={marginBottom}
			onClick={() => {
				TagManager.dataLayer({
					dataLayer: {
						Logged_in: isSignedIn ? 'Yes' : 'No',
						complete_Link: banner.url,
						event: TAG_MANAGER_CLICK_BANNER,
						userId: getUserId(),
					},
				});
				if (banner.url) {
					if (isTradeIn) {
						window.open(banner.url, '_blank');
					} else {
						const { pathname } = new URL(banner.url);
						history.push(pathname);
					}
				}
			}}
		>
			<ConditionalRender
				show={
					!!banner?.img &&
					(type === MarketingBannerType.HORIZONTAL_FULL_WIDTH ||
						type === MarketingBannerType.HORIZONTAL_MEDIUM)
				}
				onTrue={() => (
					<Row>
						<Col>
							<MarketingBannerWrapper type={type}>
								<MarketingBannerImage type={type}>
									<img src={banner?.img} width={516} />
								</MarketingBannerImage>
							</MarketingBannerWrapper>
						</Col>
					</Row>
				)}
			/>
			<ConditionalRender
				show={!!banner.timerFinishTime && moment(banner.timerFinishTime).isAfter(moment())}
				onTrue={() => (
					<TimerContainer isXS={isXS} isMD={isMD}>
						<BannerCounter days={days} hours={hours} minutes={minutes} seconds={seconds} />
					</TimerContainer>
				)}
			/>
			<ConditionalRender
				show={type === MarketingBannerType.VERTICAL}
				onTrue={() => (
					<MarketingBannerWrapper type={type}>
						<MarketingBannerImageVertical img={banner?.img}></MarketingBannerImageVertical>
					</MarketingBannerWrapper>
				)}
			/>
		</MarketingBannerContainer>
	);
};

export default MarketingBanner;
