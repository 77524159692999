export enum ResponseResult {
	SUCCESS = 'SUCCESS',
	FAULT_CREATE_TENANT = 'FAULT_CREATE_TENANT',
}

export interface IResponseResult {
	result: ResponseResult;
}

export interface ITenant {
	firstName: string;
	lastName: string;
	postalCode: string;
	country: string;
	city: string;
	addressLine1: string;
	addressLine2: string;
	state: string;
	phoneNumber: string;
	qualificationID: string;
	companyName: string;
	primaryDomain: string;
	primaryDomainDomain: string;
	specialQualification: string;
	emailAddress: string;
}

const model: types.models.IInputModel = {
	result: 'response.response.result',
};

export default model;
