import { ICartItem } from './viewCart';

export interface IOrderDetails {
	x_shipmentNumber?: string;
	totalShippingCharge?: string;
	x_retryOrder?: string;
	orderTypeCode?: string;
	resourceId?: string;
	orderId?: string;
	lastUpdateDate?: Date;
	channel?: IChannel;
	x_shippingGeoCode?: string;
	orderStatus?: string;
	x_isPurchaseOrderNumberRequired?: string;
	totalShippingChargeCurrency?: string;
	buyerId?: string;
	grandTotalCurrency?: string;
	buyerDistinguishedName?: string;
	x_merchantId?: string;
	recordSetCount?: string;
	paymentInstruction?: IPaymentInstruction[];
	x_isPersonalAddressesAllowedForShipping?: string;
	storeNameIdentifier?: string;
	totalProductPriceCurrency?: string;
	totalProductPrice?: string;
	x_paymentDate?: string;
	locked?: string;
	placedDate?: Date;
	recordSetComplete?: string;
	totalAdjustmentCurrency?: string;
	totalSalesTaxCurrency?: string;
	comments?: string;
	totalSalesTax?: string;
	orderItem?: IOrderItem[];
	grandTotal?: string;
	x_authorizationNo?: string;
	x_shipmentTrackUrl?: string;
	storeUniqueID?: string;
	recordSetStartNumber?: string;
	resourceName?: string;
	recordSetTotal?: string;
	shipAsComplete?: string;
	x_trackingIds?: string;
	x_orderSubmit?: Date;
	totalShippingTax?: string;
	prepareIndicator?: string;
	totalShippingTaxCurrency?: string;
	totalAdjustment?: string;
	x_receiptNumber?: string;
	quantity: string;
	x_outletPickupOrder?: string;
	x_noShipment?: string;
	x_outletPickupBookingAppointment?: string;
	x_canceledDate?: string;
}

export interface IChannel {
	channelIdentifer?: IChannelIdentifer;
	userData?: null;
	description?: IDescription;
}

export interface IChannelIdentifer {
	channelName?: string;
	uniqueID?: string;
}

export interface IDescription {
	language?: string;
	value?: string;
}

export interface IOrderItem {
	zipCode?: string;
	personTitle?: string;
	country?: string;
	xitem_field2?: string;
	unitUom?: string;
	shippingChargeCurrency?: string;
	lastUpdateDate?: Date;
	postalCode?: string;
	phone2?: string;
	availableDate?: Date;
	phone2Publish?: string;
	salesTax?: string;
	phone1Publish?: string;
	xitem_thumbnail?: string;
	phone1?: string;
	addressId?: string;
	correlationGroup?: string;
	email2?: string;
	email1?: string;
	fax2?: string;
	shippingCharge?: string;
	fax1?: string;
	state?: string;
	orderItemPrice?: string;
	shipModeLanguage?: string;
	unitPrice?: string;
	productId?: string;
	organizationName?: string;
	shipModeCode?: string;
	shipModeDescription?: string;
	nickName?: string;
	firstName?: string;
	xitem_attributes?: string;
	totalAdjustment?: ITotalAdjustment;
	orderItemInventoryStatus?: string;
	lastName?: string;
	city?: string;
	expectedShipDate?: Date;
	xitem_isPersonalAddressesAllowedForShipping?: string;
	shippingTax?: string;
	orderItemStatus?: string;
	offerID?: string;
	currency?: string;
	createDate?: Date;
	salesTaxCurrency?: string;
	quantity?: string;
	fulfillmentCenterId?: string;
	orderItemId?: string;
	shipModeId?: string;
	orderItemFulfillmentStatus?: string;
	isExpedited?: string;
	addressLine?: string[];
	shippingTaxCurrency?: string;
	stateOrProvinceName?: string;
	carrier?: string;
	UOM?: string;
	freeGift?: string;
	xitem_name?: string;
	unitQuantity?: string;
	contractId?: string;
	middleName?: string;
	partNumber?: string;
	xitem_planContractDuration?: string;
	xitem_planTaxablePrice?: string;
	xitem_planCommitment?: string;
	xitem_planVatValue?: string;
	xitem_planMonthy?: string;
	xitem_planLongDescription?: string;
	xitem_planShortDescription?: string;
	xitem_planName?: string;
	x_canceledDate?: string;
}

export interface ITotalAdjustment {
	currency?: string;
	value?: string;
}

export interface IPaymentInstruction {
	piAmount?: string;
	piId?: string;
	piCurrency?: string;
	protocolData?: IProtocolDatum[];
	xpaym_policyId?: string;
	xpaym_punchoutPayment?: string;
	piDescription?: string;
	piLanguage?: string;
	payMethodId?: string;
	piStatus?: string;
	xpaym_tokenization?: string;
}

export interface IProtocolDatum {
	name?: string;
	value?: string;
}

export interface IOrderDetailsResponse {
	response: IOrderDetails;
}

export interface IOrderCoordinates {
	lng: number;
	lat: number;
}

export interface IOrderDetailsItem {
	paymentDate?: string;
	deliveryDate: string;
	shipmentNumber: string;
	shipmentTrackUrl: string;
	coordinates?: IOrderCoordinates;
	items: ICartItem[];
	upfrontPaymentSum: number;
	period: '12' | '24';
	periodPaymentSum: number;
	vat: number;
	vatPercent: number;
	isPickup?: boolean;
	email?: string;
	data?: string;
	isOutletPickup?: boolean;
	isShipment?: boolean;
	outletPickupBookingAppointment?: string;
	branchId?: string;
	ticketNumber?: string;
	x_canceledDate?: string;
}

const model: types.models.IInputModel = {
	response: 'response',
};

export default model;
