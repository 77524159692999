export interface IAppointment {
	resultCode: number;
	resultDescription: string;
}

export interface ICancelAppointment {
	returnValue: IAppointment;
}

const model: types.models.IInputModel = {
	returnValue: 'response.cancelAppointmentResponse',
};

export default model;
