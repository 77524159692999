import React, { FunctionComponent } from 'react';
import { IKeyFeaturesItem } from '@ApiModels/productById';
import { BodyM, BodyMBold } from '@Components/Typography/Typography';
import { FeatureWrapper, IconWraper, DescriptionWraper } from './Feature.styled';

const Feature: FunctionComponent<IKeyFeaturesItem> = ({ title, value, img }) => {
	return (
		<FeatureWrapper>
			<IconWraper>{img && <img src={img} />}</IconWraper>
			<DescriptionWraper>
				<BodyM>{title}</BodyM>
				<BodyMBold>{value}</BodyMBold>
			</DescriptionWraper>
		</FeatureWrapper>
	);
};

export default Feature;
