import React, { FunctionComponent } from 'react';
import {
	ContactDetailsWrapper,
	Subtitle,
	IconWrapper,
	AddressText,
	BodySWrapper,
	EditAddressWrapper,
	IconAddressWrapper,
	HeaderWrapper,
} from './Address.styled';
import { ValueBold } from '@Components/Typography/Typography.styled';
import useTranslate from '@Utils/hooks/useTranslate';
import { Col, Row, Hidden, Visible } from 'react-grid-system';
import Icon from '@Components/Icon/Icon';
import Divider from '@Components/Divider/Divider';
import ConditionalRender from '@Components/ConditionalRender/ConditionalRender';
import { PrimaryMediumButtonFixed } from '@Components/controls/Button/Button';
import { modalTypes, setModal } from '@Redux/modules/modal/actions';
import { useDispatch } from 'react-redux';
import useCommerceProfile from '@Utils/hooks/useCommerceProfile';

const Address: FunctionComponent<types.cards.IAddressCardProps> = ({ params: { onOpenModal } }) => {
	const { translate } = useTranslate();
	const dispatch = useDispatch();
	const { billingAddress } = useCommerceProfile();

	const getAddress = () => {
		if (billingAddress?.wilayat && billingAddress.way) {
			let address = `${billingAddress?.wilayat} ${billingAddress?.way}`;
			if (billingAddress?.house) {
				address += `, ${translate('address.apt')} ${billingAddress?.house}`;
				if (billingAddress.apartment) {
					address += `/${billingAddress?.apartment}`;
				}
			}
			address += `, ${billingAddress?.city}`;

			return address;
		}
		return '';
	};

	return (
		<ContactDetailsWrapper>
			<Row>
				<Hidden xs>
					<IconWrapper>
						<Icon name="billingAddress" width={40} height={40} />
					</IconWrapper>
				</Hidden>
				<Col>
					<HeaderWrapper>
						<Visible xs>
							<IconWrapper>
								<Icon name="billingAddress" width={40} height={40} />
							</IconWrapper>
						</Visible>
						<div>
							<ValueBold>{translate('address.billing.address')}</ValueBold>
							<Divider marginBottom={8} withoutLine />
							<Subtitle>{translate('address.billing.address.subtitle')}</Subtitle>
						</div>
					</HeaderWrapper>
					<ConditionalRender
						show={!!billingAddress?.wilayat && !!billingAddress.way}
						onTrue={() => (
							<EditAddressWrapper>
								<IconAddressWrapper>
									<Icon name="billingAddress" width={32} height={32} />
									<AddressText>{getAddress()}</AddressText>
								</IconAddressWrapper>
								<BodySWrapper
									onClick={() => {
										onOpenModal?.();
										dispatch(
											setModal({
												type: modalTypes.DELIVERY_ADDRESS,
												data: { type: 'billingAddress' },
												withoutContainer: true,
											})
										);
									}}
									color="primary"
								>
									{translate('address.edit.address')}
								</BodySWrapper>
							</EditAddressWrapper>
						)}
						onFalse={() => (
							<>
								<Divider marginBottom={24} withoutLine />
								<PrimaryMediumButtonFixed
									uniqueId="open.billing.address.modal"
									onClick={() => {
										onOpenModal?.();
										dispatch(
											setModal({
												type: modalTypes.DELIVERY_ADDRESS,
												data: { type: 'billingAddress' },
												withoutContainer: true,
											})
										);
									}}
								>
									{translate('addres.enter.address')}
								</PrimaryMediumButtonFixed>
							</>
						)}
					/>
				</Col>
			</Row>
		</ContactDetailsWrapper>
	);
};

export default Address;
