import styled from 'styled-components';

export const OutletAvailableWrapper = styled.div<{ heightFixed: string }>`
	display: flex;
	flex-direction: column;
	border-radius: 8px;
	padding: 24px 16px 0;
	${({ theme, heightFixed }) => ({
		backgroundColor: theme.colors.white,
		height: heightFixed,
	})};
`;

export const TitleWrapper = styled.div`
	display: flex;
	flex-direction: row;
	margin-bottom: 16px;
	justify-content: center;
`;

export const ContentWrapper = styled.div`
	display: flex;
	width: 100%;
	margin-top: 16px;
`;

export const DeviceInfoWrapper = styled.div<{ isSmallScreen?: boolean; isSM?: boolean }>`
	display: flex;
	align-content: center;
	margin: 16px 0px 0px 16px;
	${({ isSmallScreen, isSM }) => ({
		flexDirection: isSmallScreen ? 'row' : 'column',
		marginBottom: isSmallScreen && !isSM ? '32px' : '0',
		marginTop: isSmallScreen ? '8px' : '16px',
		marginLeft: isSM ? '0px' : '16px',
	})};
`;

export const OutletsWrapper = styled.div<{ height?: string }>`
	display: flex;
	flex-direction: column;
	flex-shrink: 0;
	max-height: 480px;
	${({ height }) => ({
		maxHeight: height,
	})};
	overflow-y: scroll;
	overflow-x: hidden;
	width: 100%;
	margin-top: 16px;
`;

export const ImageWrapper = styled.div<{ isSmallScreen?: boolean }>`
	${({ isSmallScreen }) => ({
		height: isSmallScreen ? '80px' : '168px',
		width: isSmallScreen ? '80px' : '168px',
		marginBottom: isSmallScreen ? '24px' : '0',
	})};
	display: flex;
	align-content: center;
	justify-content: center;
`;

export const Image = styled.img`
	max-height: 168px;
	max-width: 168px;
	height: auto;
	width: auto;
`;

export const SingleInfoWrapper = styled.div`
	display: flex;
	flex-direction: column;
	margin-top: 16px;
`;

export const AttributeWrapper = styled.div`
	display: flex;
	width: 90%;
	margin-top: 8px;
`;

export const ColorDotWrapper = styled.div`
	width: 28px;
	height: 28px;
	display: inline-block;
	padding: 4px;
	box-sizing: border-box;
	border-radius: 50%;
	overflow: hidden;
`;

export const CloseIconWrapper = styled.div`
	cursor: pointer;
	position: absolute;
	right: 16px;
`;

export const SmallResolutionInfoWrapper = styled.div<{ isSM?: boolean }>`
	display: flex;
	flex-direction: column;

	${({ isSM }) => ({
		marginLeft: isSM ? '16px' : '-16px;',
	})};
`;

export const SmallResolutionLabelwrapper = styled.div`
	margin-bottom: 4px;
`;
