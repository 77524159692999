export interface INestedCategories {
	_rows?: IContent[];
	rows: IContent[];
}

export interface IContent {
	children: IContent[];
	id: string;
	identifier: string;
	links: Record<string, any>;
	name: string;
	seo: Record<string, any>;
	sequence: string;
	storeID: string;
}

const model: types.models.IInputModel = {
	_rows: 'response.contents',
	rows: (model: INestedCategories) => {
		return model?._rows?.find((cat: IContent) => cat.identifier === 'eshop')?.children;
	},
};

export default model;
