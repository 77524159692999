import React, { FunctionComponent } from 'react';
import { PaginationContainer, Counter, CounterWrapper, LoaderWrapper } from './Pagination.styled';
import Divider from '@Components/Divider/Divider';
import useTranslate from '@Utils/hooks/useTranslate';
import BusyIndicator from '@Components/BusyIndicator/BusyIndicator';
import { GET_NEXT_PRODUCTS_BI } from '@Views/Store/Store';

export interface IPaginationProps {
	totalItems: number;
	displayedItems: number;
}

const Pagination: FunctionComponent<IPaginationProps> = ({ totalItems, displayedItems }) => {
	const { translate } = useTranslate();

	return (
		<>
			<PaginationContainer>
				<Divider />
				<CounterWrapper>
					<Counter>{translate('pagination.counter', displayedItems, totalItems)}</Counter>
				</CounterWrapper>
				<LoaderWrapper>
					<BusyIndicator listener={[GET_NEXT_PRODUCTS_BI]} />
				</LoaderWrapper>
			</PaginationContainer>
		</>
	);
};

export default Pagination;
