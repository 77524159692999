interface IProductBySeo {
	identifier: string;
	tokenName: string;
	tokenExternalValue: string;
	language: string;
	id: number;
	page: {
		imageAlternateDescription: string;
		name: string;
		title: string;
		type: string;
		metaDescription: string;
		metaKeyword: string;
	};
	storeId: string;
	tokenValue: string;
	status: number;
}

interface IModel {
	_contents: IProductBySeo[];
}

export interface IProductBySeoModel extends IModel {
	tokenValue: string;
}

const getIdBySeo = (contents: IProductBySeo[]) => {
	return contents[0].tokenValue;
};

const model: types.models.IInputModel = {
	_contents: 'response.contents',
	tokenValue: (model: IModel) => {
		return getIdBySeo(model._contents);
	},
};

export default model;
