import styled from 'styled-components';

export const AppleLobMenuWrapper = styled.div`
	width: 100%;
	max-height: 200px;
	margin-top: 40px;
	margin-bottom: 8px;
`;

export const ElementsWrapper = styled.div`
	display: flex;
	padding-bottom: 17px;
`;

export const CarouselWrapper = styled.div`
	margin: 8px;
`;

export const CarouselRightIconWrapper = styled.div`
	padding: 3px;
	border-radius: 8px;
	border: 1px solid;
	top: 70px;
	right: 0px;
	position: absolute;
	${({ theme }) => {
		return {
			borderColor: theme.colors.primary,
			backgroundColor: theme.colors.white,
		};
	}}
`;

export const CarouselLeftIconWrapper = styled.div`
	padding: 3px;
	border-radius: 8px;
	position: absolute;
	border: 1px solid;
	top: 70px;
	left: 0px;
	${({ theme }) => {
		return {
			borderColor: theme.colors.primary,
			backgroundColor: theme.colors.white,
		};
	}}
`;
