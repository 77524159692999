import Divider from '@Components/Divider/Divider';
import React, { FunctionComponent } from 'react';
import { IContainerProps, TitleContainer, TitleWrapper } from './TitleWithLine.styled';

interface ITitleWithLineProps extends IContainerProps {
	title: string;
}

const TitleWithLine: FunctionComponent<ITitleWithLineProps> = ({ title, marginTop, marginBottom }) => {
	return (
		<TitleContainer marginBottom={marginBottom} marginTop={marginTop}>
			<TitleWrapper>{title}</TitleWrapper>
			<Divider color="black12" />
		</TitleContainer>
	);
};

export default TitleWithLine;
