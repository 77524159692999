import styled from 'styled-components';
import { TitleBold, Subheading, BodyMBold } from '@Components/Typography/Typography';

export const CardContainer = styled.div`
	padding: 16px;
	display: flex;
	flex-direction: column;
	width: 70vw;
	min-width: 300px;
	max-width: 800px;
`;

export const CartTitle = styled(TitleBold)`
	padding-top: 8px;
	padding-bottom: 16px;
	text-align: center;
	${({ theme }) => ({
		color: theme.colors.black87,
	})}
`;

export const ActionWrapper = styled.div`
	display: flex;
	justify-content: flex-end;
`;

export const ContentWrapper = styled.div`
	padding: 16px 0;
`;

export const DropdownWrapper = styled.div`
	display: inline-block;
	div {
		max-width: 239px;
	}
`;

export const ProductWrapper = styled.div``;

export const ImgWrapper = styled.div`
	padding: 16px 0;
	height: 160px;
	img {
		max-width: 100%;
		max-height: 100%;
		transform: translate(50%);
	}
`;

export const ProductSubheading = styled(Subheading)`
	${({ theme }) => ({
		color: theme.colors.black54,
	})}
	margin-top: 8px;
	margin-bottom: 8px;
`;

export const ProductDescription = styled(BodyMBold)`
	margin-bottom: 8px;
`;
