export interface IProcessOrder {
	orderId: string;
	resultCode: number;
	paymentApproved: boolean;
}

const model: types.models.IInputModel = {
	orderId: 'response.orderId',
	resultCode: 'response.resultCode',
	paymentApproved: 'response.paymentApproved',
};

export default model;
