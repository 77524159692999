import styled from 'styled-components';
import * as cssTypes from 'csstype';

export const TitleWrapper = styled.div``;

export const StoreWrapper = styled.div<{ mobileMenu: boolean; isMd: boolean }>`
	${({ mobileMenu, isMd }) => {
		return isMd
			? { height: '100%', width: '100%' }
			: mobileMenu
			? {
					overflowY: 'scroll' as cssTypes.Property.OverflowY,
					overflowX: 'hidden' as cssTypes.Property.OverflowY,
					width: '100%',
					height: 'fit-content',
			  }
			: { height: '100%' };
	}}
	${({ theme }) => ({
		color: theme.colors.grey50,
	})}
	display: flex;
	::-webkit-scrollbar {
		display: none;
	}
`;
