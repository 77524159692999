import api from '@Utils/api';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export interface IRegion {
	key: string;
	region: string;
	city: string;
}

interface ICity {
	key: string;
	name_en: string;
	name_ar: string;
	zip_code: string;
	areas?: IArea[];
}

interface IArea {
	key: string;
	name_en: string;
	name_ar: string;
	zip_code: string;
}

export interface ICities {
	key: string;
	name_ar: string;
	name_en: string;
	cities: ICity[];
}

export interface IGovernorate {
	key: string;
	governorate: string;
}

export class AddressesService {
	private static convertGovernorateToPickedArray = (regions: IRegion[]): IGovernorate[] => {
		const uniqueGovernorates: IGovernorate[] = [];

		regions.forEach((region: IRegion) => {
			if (!uniqueGovernorates.find(({ governorate }) => governorate === region.region)) {
				uniqueGovernorates.push({ key: region.region, governorate: region.region });
			}
		});

		return uniqueGovernorates;
	};

	public static getRegions(): Observable<IRegion[]> {
		return api.wcm
			.getRegions()
			.call()
			.pipe(
				map(({ response }) => {
					if (Array.isArray(response)) {
						return response;
					}
					return [];
				})
			);
	}

	public static getCities(): Observable<ICities[]> {
		return api.wcm
			.getCities()
			.call()
			.pipe(
				map(({ response }) => {
					if (Array.isArray(response)) {
						return response;
					}
					return [];
				})
			);
	}

	public static getGovernorates(): Observable<IGovernorate[]> {
		return api.wcm
			.getRegions()
			.call()
			.pipe(
				map(({ response }) => {
					if (Array.isArray(response)) {
						return this.convertGovernorateToPickedArray(response);
					}
					return [];
				})
			);
	}
}
