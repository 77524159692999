import * as Yup from 'yup';
import useTranslate from '@Utils/hooks/useTranslate';
import { AaccountCreationFormStep } from '@Views/MicrosoftRegistration/MicrosoftRegistration';

const nameRegex = /^^[A-Za-zżźćńółęąśŻŹĆĄŚĘŁÓŃء-ي\-\s]+$/;
const domainRegex = /^^[0-9A-Za-z-\\.]+$/;
const digitsRegex = /^[0-9-]*$/;
const alphanumericalRegex = /^[a-zA-Z0-9-\s]*$/;

export default (accountCreationStep: AaccountCreationFormStep): Yup.AnyObjectSchema => {
	const { translate } = useTranslate();

	const contactDetails = {
		firstName: Yup.string()
			.matches(nameRegex, translate('validate.personal.data.first.name.must.be.string'))
			.required(translate('validate.personal.data.first.name.required')),
		lastName: Yup.string()
			.matches(nameRegex, translate('validate.personal.data.last.name.must.be.string'))
			.required(translate('validate.personal.data.last.name.required')),
		email: Yup.string()
			.email(translate('validate.personal.data.email.must.be.email'))
			.required(translate('validate.personal.data.email.required')),
		phoneNumber: Yup.string()
			.required(translate('validate.personal.data.phone.number.required'))
			.length(8, translate('validate.personal.data.oman.format.phone.number'))
			.matches(
				/(90[1-9][0-9]{5})|((9[1-9]|(7[1289]))[0-9]{6})$|(2)[2-6][0-9]{6}$/,
				translate('validate.personal.data.oman.format.phone.number')
			),
	};
	const comapnyDetails = {
		companyName: Yup.string()
			.matches(alphanumericalRegex, translate('microsoft-automation-proccess.validate.company-name-match'))
			.required(translate('microsoft-automation-proccess.validate.company-name-required')),
		companyId: Yup.string()
			.matches(digitsRegex, translate('microsoft-automation-proccess.validate.company-id-match'))
			.required(translate('microsoft-automation-proccess.validate.company-id-required')),
		companyAddress: Yup.string()
			.matches(alphanumericalRegex, translate('microsoft-automation-proccess.validate.company-address-match'))
			.required(translate('microsoft-automation-proccess.validate.company-address-required')),
		companyState: Yup.string()
			.matches(nameRegex, translate('microsoft-automation-proccess.validate.company-state-match'))
			.required(translate('microsoft-automation-proccess.validate.company-state-required')),
		companyPostalCode: Yup.string()
			.matches(digitsRegex, translate('microsoft-automation-proccess.validate.company-postal-code-match'))
			.required(translate('microsoft-automation-proccess.validate.company-postal-code-required')),
		companyCity: Yup.string()
			.matches(nameRegex, translate('microsoft-automation-proccess.validate.company-city-match'))
			.required(translate('microsoft-automation-proccess.validate.company-city-required')),
		companyCountry: Yup.string().required(
			translate('microsoft-automation-proccess.validate.company-country-required')
		),
	};

	const tenantDetails = {
		domainName: Yup.string()
			.matches(domainRegex, translate('microsoft-automation-proccess.validate.domain-name-match'))
			.required(translate('microsoft-automation-proccess.validate.domain-name-required')),
	};

	const getFieldsValidator = () => {
		switch (accountCreationStep) {
			case AaccountCreationFormStep.CONTACT_DETAILS:
				return contactDetails;
			case AaccountCreationFormStep.COMPANY_DETAILS:
				return comapnyDetails;
			case AaccountCreationFormStep.TENANT_INFO:
				return tenantDetails;
			default:
				return {};
		}
	};

	return Yup.object().shape({ ...getFieldsValidator() });
};
