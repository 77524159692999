import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { BreadcrumbsProvider } from 'react-breadcrumbs-dynamic';
import { PersistGate } from 'redux-persist/integration/react';
import { BrowserRouter } from 'react-router-dom';
import store, { persistor } from '@Redux/store';
import { Provider } from 'react-redux';
import theme from '@Utils/theme/theme';
import { ThemeProvider } from 'styled-components';
import GlobalStyles from './App.styled';
import SEO from '@Components/SEO/SEO';
import ComparisonBar from '@Components/ComparisonBar/ComparisonBar';
import Modal from '@Components/Modal/Modal';
import LayoutWrapper from '@Components/LayoutWrapper/LayoutWrapper';
import CartContextProvider from '@Utils/context/CartContext';
import DeviceInsuranceContextProvider from '@Utils/context/DeviceInsuranceContext';

ReactDOM.render(
	<React.StrictMode>
		<Provider store={store}>
			<PersistGate persistor={persistor}>
				<ThemeProvider theme={theme}>
					<BrowserRouter>
						<BreadcrumbsProvider>
							<CartContextProvider>
								<DeviceInsuranceContextProvider>
									<LayoutWrapper>
										<Modal />
										<SEO />
										<GlobalStyles />
										<App />
										<ComparisonBar />
									</LayoutWrapper>
								</DeviceInsuranceContextProvider>
							</CartContextProvider>
						</BreadcrumbsProvider>
					</BrowserRouter>
				</ThemeProvider>
			</PersistGate>
		</Provider>
	</React.StrictMode>,
	document.getElementById('root')
);
