export enum BillingType {
	POSTPAID = 'POSTPAID',
	PREPAID = 'PREPAID',
	DEFAULT = '',
}

export enum AccountType {
	MOBILE = 'MOBILE',
	FIX = 'FIX',
	INTERNET = 'INTERNET',
	WFBB = 'WFBB',
	DEFAULT = '',
}

export interface IAccount {
	id?: number;
	accountId?: number;
	accountNum?: string;
	description?: string;
	isMain?: boolean;
	billingType?: BillingType;
	createdAt?: Date;
	accountType?: AccountType;
	adslLogin?: null | string;
	activatedAt?: Date;
	accountPlan?: null;
	accountCategory?: string;
}

export interface IAccounts {
	accounts: IAccount[];
}

const model: types.models.IInputModel = {
	accounts: 'response.getAccountsForLoggedUserResponse.accounts',
};

export default model;
