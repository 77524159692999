import { marginRight } from '@Utils/layout/layoutSupport';
import styled from 'styled-components';

export const PriceSectionWrapper = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
`;

export const LabelWrapper = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	width: 100%;
`;

export const PreorderWrapper = styled.div`
	display: flex;
	${() => marginRight('64px')}
`;

export const PriceContainer = styled.div<{ isDiscount?: boolean }>`
	${({ isDiscount }) => ({
		marginTop: isDiscount ? '16px' : '2px',
	})}
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	flex-grow: 1;
`;
