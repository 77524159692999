import { IPricesWithVat } from '@Components/Card/cards/ProductCard/subcomponents/PriceSection/PriceSection';
import { roundMoney } from '@Utils/converters/roundMoney';
import useTranslate from '@Utils/hooks/useTranslate';
import React, { FunctionComponent } from 'react';
import { VatContainer, VatInfoBoldWrapper, VatInfoWrapper } from './PriceWithVat.styled';

interface IPricesWithVatProps {
	pricesWithVat?: IPricesWithVat;
	vatColor?: types.theme.themeColors;
	onlyVatPrice?: boolean;
}

const PriceWithVat: FunctionComponent<IPricesWithVatProps> = ({ pricesWithVat, vatColor, onlyVatPrice }) => {
	const { translate } = useTranslate();
	return pricesWithVat ? (
		<VatContainer>
			<VatInfoWrapper vatColor={vatColor}>
				{translate(
					onlyVatPrice ? 'price-section.info.only-vat' : 'price-section.info',
					!!pricesWithVat.vatPercent ? pricesWithVat.vatPercent : '0'
				)}
			</VatInfoWrapper>
			<VatInfoBoldWrapper vatColor={vatColor}>{`${translate('omr')} ${roundMoney(
				onlyVatPrice ? pricesWithVat.vatValue : !!pricesWithVat.offerPrice ? pricesWithVat.offerPrice : 0
			)}`}</VatInfoBoldWrapper>
		</VatContainer>
	) : null;
};

export default PriceWithVat;
