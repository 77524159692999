import React from 'react';
import { useInsurance, INSURANCE_TYPE } from '@Utils/context/InsuranceContext';
import { InsuranceTypeChoice } from './InsuranceType.styled';
import { InsuranceTitle, InsuranceSubtitle } from '@Utils/styles/cardStyles';
import useTranslate from '@Utils/hooks/useTranslate';
import Icon from '@Components/Icon/Icon';
import RadioButton from '@Components/controls/RadioButton/RadioButton';
import { ICON_SIZE } from '@Config/app.config';
import { SectionHeadContainer, SectionContentContainer } from '@Views/CarInsurance/CarInsurance.styled';
import { isLargeScreen, isSmallScreen } from '@Utils/layout/layoutSupport';

const InsuranceTypeCard = (): JSX.Element => {
	const { setInsuranceType, insuranceType, activeIndex, steps } = useInsurance();

	const { translate } = useTranslate();

	return (
		<>
			<SectionHeadContainer>
				<Icon name="insuranceType" width={ICON_SIZE} height={ICON_SIZE} fill="black54" />
				<InsuranceTitle>{translate('insurance.type.title')}</InsuranceTitle>
			</SectionHeadContainer>
			<SectionContentContainer isLargeScreen={isLargeScreen()} isSmallScreen={isSmallScreen()}>
				<InsuranceSubtitle>{translate('insurance.type.subtitle')}</InsuranceSubtitle>
				<InsuranceTypeChoice isSmallScreen={isSmallScreen()}>
					<RadioButton
						fontColor="black87"
						onClick={() => setInsuranceType(INSURANCE_TYPE.FULL_COMPREHENSIVE)}
						value={INSURANCE_TYPE.FULL_COMPREHENSIVE}
						selected={insuranceType}
						label={translate('insurance.type.choice1')}
						name="insuranceType"
						color="green"
						marginBottom={20}
					/>
					<RadioButton
						fontColor="black87"
						onClick={() => setInsuranceType(INSURANCE_TYPE.THIRD_PARTY)}
						value={INSURANCE_TYPE.THIRD_PARTY}
						selected={insuranceType}
						label={translate('insurance.type.choice2')}
						name="insuranceType"
						color="green"
						marginBottom={20}
					/>
				</InsuranceTypeChoice>
			</SectionContentContainer>
		</>
	);
};

export default InsuranceTypeCard;
