import React, { FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react';
import {
	ItemType,
	BarContainer,
	BarWrapper,
	FormContent,
	LoggedTelesaleInfo,
	BlockedScreen,
	TeleInfoWrapper,
	TitleWrapper,
	SuccessInfoWrapper,
	SuccessButtonWrapper,
	LogOutWrapper,
} from './TelemarketerTopBar.styled';
import { Container, Hidden } from 'react-grid-system';
import { useSelector, useDispatch } from 'react-redux';
import useTranslate from '@Utils/hooks/useTranslate';
import useScreen from '@Utils/hooks/useScreen';
import ConditionalRender from '@Components/ConditionalRender/ConditionalRender';
import Tooltip from '@Components/Tooltip/Tooltip';
import Icon from '@Components/Icon/Icon';
import { Formik, FormikHelpers, FormikProps } from 'formik';
import telesalesFormValidator from '@Utils/validators/telesalesFormValidator';
import { setPersistentCheckoutDetails } from '@Redux/modules/persistentCheckoutData/actions';
import TextField from '@Components/controls/TextField/TextField';
import { PrimaryLargeButtonFixed } from '@Components/controls/Button/Button';
import { BodyM, BodyMBold } from '@Components/Typography/Typography.styled';
import { addBusyIndicator, removeBusyIndicator } from '@Redux/modules/busyIndicator/actions';
import { MOVE_ORDER_BI } from '@Components/Card/cards/ContinueToCheckout/ContinueToCheckout';
import { useHistory } from 'react-router-dom';
import { useCart } from '@Utils/context/CartContext';
import { telesalesSaveInProgress } from '@Redux/modules/telesales/actions';
import { getLang } from '@Utils/language/language';
import { OrderFlowService } from '@Services/orderFlow/orderFlow';
import { updateCart } from '@Redux/modules/cart/actions';
import { AuthenticationService } from '@Services/authentication/authenticationService';
import { TertiaryMediumButtonAuto } from '@Components/controls/Button/Button';
import useContactPhoneNumber from '@Utils/hooks/useContactPhoneNumber';

export const ADDITIONAL_BAR_HEIGHT = 72;
export const ADDITIONAL_SMALL_BAR_HEIGHT = 100;

interface ISingleItem {
	label: string;
	itemType: ItemType;
	redirect?: string;
}

export interface IExpandableItem extends ISingleItem {
	itemType: ItemType.EXPANDABLE;
	to?: string;
}

export interface ILinkItem extends ISingleItem {
	itemType: ItemType.LINK;
	to?: string;
}

export interface ITelesalesFormikFields {
	phoneNumber?: string;
	email?: string;
}

const FORM_WIDTH_EMAIL = 280;
const FORM_WIDTH_PHONE = 156;
const LOGOUT_BI = 'logout';

const TelemarketerTopBar: FunctionComponent = () => {
	const { contactPhoneNumber, getContactPhoneNumber } = useContactPhoneNumber();
	const [number, setNumber] = useState<string | undefined>(undefined);
	const [email, setEmail] = useState<string | undefined>(undefined);
	const [numberIsValid, setNumberIsValid] = useState<boolean>(false);
	const [emailIsValid, setEmailIsValid] = useState<boolean>(false);
	const [isBlocked, setIsBlocked] = useState<boolean>(false);
	const [errorMessage, setErrorMessage] = useState<string>();
	const dispatch = useDispatch();
	const { translate } = useTranslate();
	const { screen, screenClass } = useScreen();
	const isLogged = !!useSelector((state: types.redux.IState) => state.api.authentication.signedIn);
	const telemarketer = useSelector((state: types.redux.IState) => state.api.authentication.telemarketer);
	const language = useSelector((state: types.redux.IState) => state.settings.language);
	const telesalesInformation = useSelector(
		(state: types.redux.IState) => state.persistentCheckout.telesalesInformation
	);
	const saveInProgress = useSelector((state: types.redux.IState) => state.telesales.saveInProgress);
	const { cart, loading } = useCart();
	const history = useHistory();

	const onSubmitDetails = (values: ITelesalesFormikFields, helpers: FormikHelpers<ITelesalesFormikFields>) => {
		helpers.setSubmitting(false);
		setErrorMessage(undefined);
		if (
			cart.items.length > 0 &&
			telesalesInformation?.orderId &&
			telesalesInformation?.phoneNumber &&
			telesalesInformation?.email &&
			!!contactPhoneNumber
		) {
			helpers.setSubmitting(true);
			const { phoneNumber, email, orderId } = telesalesInformation;
			dispatch(telesalesSaveInProgress(true));
			dispatch(addBusyIndicator(MOVE_ORDER_BI));
			OrderFlowService.moveOrder({ phoneNumber, contactNumber: contactPhoneNumber, email, orderId }).subscribe(
				() => {
					setTimeout(() => {
						dispatch(
							setPersistentCheckoutDetails({
								telesalesInformation: {
									orderId: undefined,
									email: '',
									phoneNumber: '',
									contactNumber: '',
								},
							})
						);
						dispatch(telesalesSaveInProgress(false));
						dispatch(removeBusyIndicator(MOVE_ORDER_BI));
						helpers.setSubmitting(false);
						dispatch(
							setPersistentCheckoutDetails({
								telesalesInformation: {
									orderId: undefined,
									phoneNumber: '',
									email: '',
									contactNumber: '',
								},
							})
						);
						history.push(`/${getLang()}/store`);
						dispatch(updateCart(true));
					}, 2000);
				},
				(e) => {
					dispatch(
						setPersistentCheckoutDetails({
							telesalesInformation: {
								orderId: undefined,
								email: '',
								phoneNumber: '',
								contactNumber: '',
							},
						})
					);
					dispatch(telesalesSaveInProgress(false));
					dispatch(updateCart(true));
					helpers.setSubmitting(false);
					dispatch(removeBusyIndicator(MOVE_ORDER_BI));
				}
			);
		} else {
			dispatch(telesalesSaveInProgress(false));
			setErrorMessage(translate('error.please.add.to.cart'));
		}
	};

	const handleLogout = () => {
		dispatch(addBusyIndicator(LOGOUT_BI));
		AuthenticationService.logout().subscribe(
			() => {},
			() => {},
			() => {
				dispatch(removeBusyIndicator(LOGOUT_BI));
				history.push(`/${getLang()}/store`);
				dispatch(updateCart(true));
			}
		);
	};

	const handleRef = useCallback((ref: FormikProps<ITelesalesFormikFields>) => {
		if (ref !== null) {
			const phoneLength = ref?.values?.phoneNumber?.length || 0;
			const emailLength = ref?.values?.email?.length || 0;
			setIsBlocked(
				!(phoneLength > 0) ||
					!!ref.errors.phoneNumber?.length ||
					!(emailLength > 0) ||
					!!ref.errors.email?.length
			);
			if (!ref.errors.phoneNumber?.length && ref.values.phoneNumber?.length) {
				setNumber(ref.values.phoneNumber);
			}
			if (!ref.errors.email?.length && ref.values.email?.length) {
				setEmail(ref.values.email);
			}
			setNumberIsValid(!ref.errors.phoneNumber?.length);
			setEmailIsValid(!ref.errors.email?.length);
		}
	}, []);

	useEffect(() => {
		if (cart.items.length > 0) {
			setErrorMessage(undefined);
		}
	}, [cart, loading]);

	useEffect(() => {
		if (!!number && numberIsValid && getContactPhoneNumber) {
			getContactPhoneNumber(number);
		}
	}, [numberIsValid, number]);

	useEffect(() => {
		dispatch(
			setPersistentCheckoutDetails({
				telesalesInformation: {
					phoneNumber: numberIsValid && !isBlocked ? number : '',
					email: emailIsValid ? email : '',
					contactNumber: contactPhoneNumber,
				},
			})
		);
	}, [numberIsValid, emailIsValid, isBlocked, email, number, contactPhoneNumber]);

	const formWidthEmail = useMemo(() => {
		if (screen(['xxl', 'xl'])) {
			return FORM_WIDTH_EMAIL;
		} else if (screen(['lg'])) {
			return 200;
		} else if (screen(['md'])) {
			return 150;
		} else {
			return 100;
		}
	}, [screenClass]);

	const formWidthPhone = useMemo(() => {
		if (screen(['xxl', 'xl'])) {
			return FORM_WIDTH_PHONE;
		} else if (screen(['lg'])) {
			return 130;
		} else if (screen(['md'])) {
			return 100;
		} else {
			return 75;
		}
	}, [screenClass]);

	return (
		<ConditionalRender
			show={!!isLogged && !!telemarketer}
			onTrue={() => (
				<ConditionalRender
					show={!saveInProgress}
					onTrue={() => (
						<>
							<BarContainer warning={!numberIsValid && number !== undefined}>
								<Container fluid={!screen(['xxl', 'xl'])}>
									<BarWrapper>
										<Formik<ITelesalesFormikFields>
											innerRef={handleRef}
											validationSchema={telesalesFormValidator()}
											initialValues={{
												phoneNumber: telesalesInformation?.phoneNumber,
												email: telesalesInformation?.email,
											}}
											onSubmit={(values, helpers) => {
												onSubmitDetails(values, helpers);
											}}
										>
											{({
												setFieldValue,
												values,
												errors,
												handleSubmit,
												isSubmitting,
												touched,
											}) => {
												return (
													<FormContent>
														<Hidden xs>
															<Tooltip
																text={translate('telemarketer.bar.tooltip')}
																width={208}
															>
																<Icon
																	name="msgInfoBackground"
																	width={24}
																	height={24}
																	fill="white"
																/>
															</Tooltip>
														</Hidden>
														<Tooltip
															showWhenTextAvailable
															withPin
															text={!!errors.phoneNumber ? errors.phoneNumber : ''}
															disable={!errors.phoneNumber}
															icon={errors.phoneNumber ? 'warning' : undefined}
															error={!!errors.phoneNumber}
														>
															<TextField
																width={formWidthPhone}
																id="telesalesClientPhoneNumber"
																label={translate('telesales.phone.number.label')}
																placeholder={translate(
																	'telesales.phone.number.placeholder'
																)}
																value={values?.phoneNumber || ''}
																onChange={(value) => {
																	setFieldValue(
																		'phoneNumber',
																		value.toString().replace(/[a-zA-Z]/, '')
																	);
																	setErrorMessage(undefined);
																}}
																error={!!errors.phoneNumber}
															/>
														</Tooltip>
														<Tooltip
															showWhenTextAvailable
															withPin
															text={!!errors.email ? errors.email : ''}
															disable={!errors.email}
															width={200}
															icon={errors.email ? 'warning' : undefined}
															error={!!errors.email}
														>
															<TextField
																width={formWidthEmail}
																id="telesalesClientEmail"
																label={translate('telesales.email.label')}
																placeholder={translate('telesales.email.placeholder')}
																value={values?.email || ''}
																onChange={(value) => {
																	setFieldValue('email', value.toString());
																	setErrorMessage(undefined);
																}}
																error={touched.email && !!errors.email}
															/>
														</Tooltip>
														<Tooltip
															showWhenTextAvailable
															withPin
															text={errorMessage || ''}
															disable={!errorMessage}
															icon={errorMessage ? 'warning' : undefined}
															error={!!errorMessage}
															width={200}
														>
															<PrimaryLargeButtonFixed
																uniqueId="telesales.submit.button"
																underlayLoadingColor={
																	saveInProgress ? 'primaryLoading' : 'black12'
																}
																onClick={handleSubmit}
																disabled={
																	(!telesalesInformation?.orderId && !emailIsValid) ||
																	isSubmitting ||
																	!values.phoneNumber ||
																	!values.email ||
																	!!errors.phoneNumber ||
																	!!errors.email
																}
																listener={MOVE_ORDER_BI}
															>
																{translate('telemarketer.bar.button.save.send')}
															</PrimaryLargeButtonFixed>
														</Tooltip>
													</FormContent>
												);
											}}
										</Formik>
										<LoggedTelesaleInfo>
											<TeleInfoWrapper>
												<BodyM color="white">{translate('telesales-field-logged-as')}</BodyM>
												<BodyMBold color="white">
													{translate('telesales-field-logged-as.telemarketer')}
												</BodyMBold>
											</TeleInfoWrapper>
											<ConditionalRender
												show={!!telesalesInformation?.orderId}
												onTrue={() => (
													<TeleInfoWrapper>
														<BodyM color="white">
															{translate('telesales-field-order.number')}
														</BodyM>
														<BodyMBold color="white">
															{telesalesInformation?.orderId || ''}
														</BodyMBold>
													</TeleInfoWrapper>
												)}
											/>
										</LoggedTelesaleInfo>
										<ConditionalRender
											show={isLogged}
											onTrue={() => (
												<LogOutWrapper>
													<TertiaryMediumButtonAuto
														uniqueId="telemarketer.bar.button.logOut"
														onClick={handleLogout}
														listener={LOGOUT_BI}
														textAlign={language === 'AR' ? 'start' : 'end'}
													>
														<BodyM color="white">
															{translate('telemarketer.bar.button.logOut')}
														</BodyM>
													</TertiaryMediumButtonAuto>
												</LogOutWrapper>
											)}
										/>
									</BarWrapper>
								</Container>
							</BarContainer>
							<BlockedScreen blocked={isBlocked} />
						</>
					)}
					onFalse={() => (
						<BarContainer warning={!numberIsValid && number !== undefined}>
							<Container fluid={!screen(['xxl', 'xl'])}>
								<BarWrapper>
									<SuccessInfoWrapper>
										<Icon name="checkCircle" width={32} height={32} fill="white" />
										<TitleWrapper>
											{translate('telesales-success-info', String(number || ''))}
										</TitleWrapper>
									</SuccessInfoWrapper>
									<SuccessButtonWrapper>
										<PrimaryLargeButtonFixed
											uniqueId="telesales.submit.button"
											onClick={() => {}}
											disabled={true}
											underlayLoadingColor="primaryLoading"
											listener={MOVE_ORDER_BI}
										>
											{translate('telemarketer.bar.button.save.send')}
										</PrimaryLargeButtonFixed>
									</SuccessButtonWrapper>
									<LoggedTelesaleInfo>
										<TeleInfoWrapper>
											<BodyM color="white">{translate('telesales-field-logged-as')}</BodyM>
											<BodyMBold color="white">
												{translate('telesales-field-logged-as.telemarketer')}
											</BodyMBold>
										</TeleInfoWrapper>
										<ConditionalRender
											show={!!telesalesInformation?.orderId}
											onTrue={() => (
												<TeleInfoWrapper>
													<BodyM color="white">
														{translate('telesales-field-order.number')}
													</BodyM>
													<BodyMBold color="white">
														{telesalesInformation?.orderId || ''}
													</BodyMBold>
												</TeleInfoWrapper>
											)}
										/>
									</LoggedTelesaleInfo>
								</BarWrapper>
							</Container>
						</BarContainer>
					)}
				/>
			)}
		/>
	);
};

export default TelemarketerTopBar;
