import {
	BigCurrency,
	Caption,
	DisplayMBold,
	Header,
	HeadlineBold,
	MiniDuration,
	MiniUnit,
	SmallCurrency,
	SmallDuration,
	BodyM,
	MiniDurationBold,
	BodyMBold,
} from '@Components/Typography/Typography';
import styled from 'styled-components';
import { paddingRight, textRight } from '@Utils/layout/layoutSupport';
import { MiniUnitRegular } from '@Components/Typography/Typography.styled';

type IColorProps = types.IStyledProps & { color: types.theme.themeColors };

interface IPrepaid {
	isPrepaid?: boolean;
}

export const PriceTagWrapper = styled.div<IPrepaid>`
	display: inline-flex;
	flex-direction: row;
	${({ isPrepaid }) => ({
		alignSelf: isPrepaid ? 'flex-end' : 'unset',
	})}
`;

export const DiscountPriceWrapper = styled.div<IPrepaid>`
	display: inline-flex;
	flex-direction: row;
	width: 100%;
`;

export const MainWrapper = styled.div<{ flexDirection?: string }>`
	display: inline-flex;
	flex-direction: ${({ flexDirection }) => flexDirection};
`;

export const SmallPrice = styled(HeadlineBold)<IColorProps>`
	${({ color, theme }) => ({
		color: theme.colors[color],
	})}
`;

export const BigPrice = styled(DisplayMBold)<IColorProps>`
	${({ color, theme }) => ({
		color: theme.colors[color],
	})}
`;

export const SmallCurrencyWrapper = styled(SmallCurrency)<IColorProps>`
	padding: 4px;
	padding-right: 0;
	text-wrap: nowrap;
	${({ color, theme }) => ({
		color: theme.colors[color],
	})}
`;

export const BigCurrencyWrapper = styled(BigCurrency)<IColorProps>`
	padding-left: 4px;
	${({ color, theme }) => ({
		color: theme.colors[color],
	})}
`;

export const DiscountWrapper = styled.div`
	position: relative;
	display: inline-flex;
	flex-direction: row;
	padding-right: 8px;

	&:before {
		position: absolute;
		content: '';
		left: 0;
		top: 40%;
		right: 8px;
		border-top: 2px solid;
		border-color: ${({ theme }) => theme.colors.grey300};
		transform: skewY(-20deg);
	}
`;

export const DiscountStrikethroughWrapper = styled.div<IColorProps>`
	text-decoration: line-through;
	${({ color, theme }) => ({
		color: theme.colors[color],
	})}
`;

export const Multiplier = styled(BigCurrency)`
	${({ theme }) => ({
		color: theme.colors.black54,
	})}
	text-align: right;
`;

export const AdditionalInfoWrapper = styled.div`
	${() => paddingRight(2)}
`;

export const AdditionalCost = styled(Caption)`
	${({ theme }) => ({
		color: theme.colors.black54,
	})}
	text-align: right;
`;

export const DurationWrapper = styled(Caption)<IColorProps>`
	${({ color, theme }) => ({
		color: theme.colors[color],
	})}
	text-align: right;
`;

export const SmallDurationWrapper = styled(SmallDuration)<IColorProps>`
	${({ theme, color }) => ({
		color: theme.colors[color],
	})}
	text-align: right;
	margin-top: -5px;
`;

export const MiniPrice = styled(Header)<IColorProps>`
	${({ color, theme }) => ({
		color: theme.colors[color],
	})}
	text-align: right;
`;

export const MiniDiscountPrice = styled(BodyM)<IColorProps>`
	${({ color, theme }) => ({
		color: theme.colors[color],
	})}
	margin-top: 2px;
	margin-right: 3px;
	text-align: right;
`;

export const MiniPriceRegular = styled(BodyM)<IColorProps>`
	${({ color, theme }) => ({
		color: theme.colors[color],
	})}
	${() => textRight()}
`;

export const MiniPriceRegularBold = styled(BodyMBold)<IColorProps>`
	${({ color, theme }) => ({
		color: theme.colors[color],
	})}
	${() => textRight()}
`;

export const MiniCurrencyWrapper = styled(MiniUnit)<IColorProps>`
	${({ color, theme }) => ({
		color: theme.colors[color],
	})}
`;

export const MiniCurrencyWrapperRegular = styled(MiniUnitRegular)<IColorProps>`
	${({ color, theme }) => ({
		color: theme.colors[color],
	})}
`;

export const MiniCurrencyBoldWrapperRegular = styled(MiniDurationBold)<IColorProps>`
	${({ color, theme }) => ({
		color: theme.colors[color],
	})}
`;

export const MiniDurationWrapper = styled(MiniDuration)<IColorProps>`
	${({ theme, color }) => ({
		color: theme.colors[color],
	})}
	text-align: right;
	margin-right: 0;
`;
