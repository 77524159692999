import { useEffect, useState } from 'react';
import { AccountsService } from '@Services/accounts/accountsService';
import PackagesService from '@Services/packages/packages';
import { ICartItem, IViewCart } from '@ApiModels/viewCart';
import { IProductOutput } from '@ApiModels/customerProducts';
import { Subscription } from 'rxjs';
import { useCart } from '@Utils/context/CartContext';

interface IUsePlansProps {
	onLoadStart?: () => any;
	onLoadEnd?: () => any;
}

interface IUsePlansOutput {
	planOfSpecificAccount?: IProductOutput;
	cartItemWithPlan?: ICartItem;
	cart: IViewCart;
	isLoading: boolean;
}

export default function usePlans(
	{ onLoadStart, onLoadEnd }: IUsePlansProps = {},
	dependencies: any[] = []
): IUsePlansOutput {
	const [cartItemWithPlan, setCartItemWithPlan] = useState<ICartItem>();
	const [planOfSpecificAccount, setPlanOfSpecificAccount] = useState<IProductOutput>();
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const { cart, loading: cartLoading } = useCart();

	useEffect(() => {
		onLoadStart?.();
		const cartItemWithPLan = cart.items.find((item) => item.withPlan);
		setCartItemWithPlan && setCartItemWithPlan(cartItemWithPLan);
	}, [...dependencies, cart]);

	useEffect(() => {
		let subscription: Subscription | undefined;
		if (cartItemWithPlan) {
			AccountsService.getAccountsForLoggedUser().subscribe(
				(response) => {
					const buyerAccount = response.accounts.find(
						(account) => account.accountNum === cartItemWithPlan?.plan?.mobilePhone
					);
					if (buyerAccount?.accountNum && buyerAccount.billingType) {
						subscription = PackagesService.getCustomerProducts({
							phoneNumber: buyerAccount?.accountNum,
							billingType: buyerAccount?.billingType,
							productsExtendedInfo: true,
						}).subscribe(
							(response) => {
								setPlanOfSpecificAccount(response.activeProduct);
								onLoadEnd?.();
								setIsLoading(false);
							},
							(e) => {
								onLoadEnd?.();
								setIsLoading(false);
							}
						);
					}
				},
				() => {
					onLoadEnd?.();
					setIsLoading(false);
				}
			);
		}
		return () => subscription?.unsubscribe();
	}, [cartItemWithPlan]);

	return {
		planOfSpecificAccount,
		cartItemWithPlan,
		cart,
		isLoading: isLoading || cartLoading,
	};
}
