export type UsageGroup = 'GENERAL' | 'ROAMING' | 'SPECIAL' | 'BONUS' | 'GSM_BASIC_CONS_DATA_ONLY' | 'TARIFF_WFBB_5G';
export type UsageType = 'UNKNOWN' | 'DATA' | 'BALANCE' | 'VOICE' | 'SMS';

export interface IUsageValue {
	value: number;
	unit: string;
}

interface ITotalProtoype {
	group: UsageGroup;
	type: UsageType;
	unlimited: boolean;
	unit?: string;
}

interface IInputTotal extends ITotalProtoype {
	totalBalance?: number;
	totalConsumption?: number;
	totalPackageSize?: number;
	totalExtraUsage?: number;
}

interface ITotal extends ITotalProtoype {
	totalBalance: IUsageValue;
	totalConsumption: IUsageValue;
	totalPackageSize: IUsageValue;
	totalExtraUsage: IUsageValue;
	usedPercentage: number;
}

interface IBalance {
	mainBalance?: number;
	balanceAvailableForPurchase?: number;
	postpaidBalance?: number;
	prepaidBalance?: number;
	postpaidCreditLimit?: number;
}

interface IInputCommerceAttributeValues {
	value: string;
	unitOfMeasure: string;
	currency: string;
	usage: string;
}
interface IInputCommerceAttribute {
	name: string;
	values: IInputCommerceAttributeValues[];
}

interface IPackagePrototype {
	packageId: string;
	name: string;
	type: UsageType;
	group: UsageGroup;
	usageType: string;
	commerceUniqueId?: string;
	commerceUniqueIdToActivate?: any;
	commerceUniqueIdToRenew?: any;
	limited: boolean;
	renewable: boolean;
	validFrom: string;
	validTo: string;
	status?: string;
	bundleStatus?: string;
	unit?: string;
	payg: boolean;
	plan?: any;
	daysLeft?: any;
	creditAvailable: boolean;
}

interface IInputPackage extends IPackagePrototype {
	balance?: number;
	consumption?: number;
	packageSize?: number;
	totalPackageSize?: number;
	commerceAttributes?: IInputCommerceAttribute[];
}

export interface IUsage {
	_packages: IInputPackage[];
	_totals: IInputTotal[];
	totals: ITotal[];
	balance: IBalance;
	bonusBalance: number;
	mainBalance: number;
	balanceAvailableForPurchase: number;
}

const usedPercentage = (numerator: number | undefined, denominator: number | undefined): number => {
	return ((numerator || 0) * 100) / (denominator || 0);
};

const round = (value: number, precision: number) => {
	const multiplier = Math.pow(10, precision || 0);
	return Math.round(value * multiplier) / multiplier;
};

const usageUnitReadable = (value?: number, unit?: string): IUsageValue => {
	value = value || 0;
	if (unit === 'MB' && value >= 1000) {
		unit = 'GB';
	}
	if (!unit) {
		return { value: round(value, 1), unit: '' };
	}
	if (!!unit && unit === 'GB') {
		return { value: round(value / 1024, 1), unit };
	} else {
		return { value: round(value, 1), unit };
	}
};

const totalUsedPercentage = (total: IInputTotal) => usedPercentage(total.totalConsumption, total.totalPackageSize);

const inputTotalToParsedTotal: (input: IInputTotal) => ITotal = (input: IInputTotal) => {
	const tmpOutput: any = {};
	Object.assign(tmpOutput, input);
	const output: ITotal = tmpOutput as ITotal;
	output.totalBalance = usageUnitReadable(input.totalBalance, input.unit);
	output.totalConsumption = usageUnitReadable(input.totalConsumption, input.unit);
	output.totalPackageSize = usageUnitReadable(input.totalPackageSize, input.unit);
	output.totalExtraUsage = usageUnitReadable(input.totalExtraUsage, input.unit);
	output.usedPercentage = totalUsedPercentage(input);
	return output;
};

const model: types.models.IInputModel = {
	_packages: ['response.getUsageResponse.packages', []],
	_totals: ['response.getUsageResponse.totals', []],
	balance: [
		'response.getUsageResponse.balance',
		{
			mainBalance: undefined,
		},
	],
	bonusBalance(model: IUsage): number | undefined {
		if (model._totals) {
			const total = model._totals.find((total) => total.group === 'BONUS' && total.type === 'BALANCE');
			if (total) {
				return total.totalBalance;
			}
		}
		return undefined;
	},
	mainBalance(model: IUsage): number | undefined {
		return model.balance.mainBalance;
	},
	balanceAvailableForPurchase(model: IUsage): number {
		return model.balance.balanceAvailableForPurchase || 0;
	},
	prepaidBalance(model: IUsage): number {
		return model.balance.prepaidBalance || 0;
	},
	postpaidBalance(model: IUsage): number {
		return model.balance.postpaidBalance || 0;
	},
	totals(model: IUsage): ITotal[] {
		return model._totals.map(inputTotalToParsedTotal);
	},
};

export default model;
