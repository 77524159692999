import Card from '@Components/Card/Card';
import React, { FunctionComponent } from 'react';
import { Col, Container } from 'react-grid-system';

const ErrorView: FunctionComponent = () => {
	return (
		<Container>
			<Col md={8} offset={{ md: 2 }}>
				<Card card="generalError" marginBottom={32} />
			</Col>
		</Container>
	);
};

export default ErrorView;
