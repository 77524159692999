import styled from 'styled-components';
import { matchScreen, paddingRight, paddingLeft } from '@Utils/layout/layoutSupport';
import { BodyS } from '@Components/Typography/Typography.styled';

export const ContactDetailsWrapper = styled.div`
	${() => ({
		padding: matchScreen('xs') ? 0 : 8,
	})}
	${() => paddingLeft(matchScreen('xs') ? 0 : 32)}
	${() => paddingRight(matchScreen('xs') ? 0 : 16)}
`;

export const HeaderWrapper = styled.div`
	display: flex;
`;

export const Subtitle = styled(BodyS)`
	${({ theme }) => ({
		color: theme.colors.black54,
	})}
`;

export const IconWrapper = styled.div`
	${() => paddingRight(16)}
`;

export const ButtonsWrapper = styled.div`
	margin-top: 32px;
`;

export const FormWrapper = styled.div`
	${() =>
		matchScreen('xs') && {
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'left',
		}}
`;
export const DatePickerFieldWrapper = styled.div`
	margin-bottom: 16px;
`;
