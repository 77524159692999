import { addBusyIndicator, removeBusyIndicator, clearAllBusyIndicators, IBusyIndicatorAction } from './actions';
import immerHandleActions from '@Redux/utils/immerHandleActions';

const list: string[] = ['always'];
const init = {
	list,
};

type draft = typeof init;

function _busyIndicatorExists(draft: draft, busyIndicator: string) {
	return draft.list.includes(busyIndicator);
}

const reducers = {
	[addBusyIndicator.toString()](draft: draft, action: IBusyIndicatorAction) {
		const busyIndicatorToAdd = action.payload;
		if (!_busyIndicatorExists(draft, busyIndicatorToAdd)) {
			draft.list.push(busyIndicatorToAdd);
		}
		return draft;
	},
	[removeBusyIndicator.toString()](draft: draft, action: IBusyIndicatorAction) {
		const busyIndicatorToRemove = action.payload;
		draft.list = draft.list.filter((existingBUsyIndicator) => existingBUsyIndicator !== busyIndicatorToRemove);
		return draft;
	},
	[clearAllBusyIndicators.toString()](draft: draft) {
		draft.list = list;
		return draft;
	},
};

export default immerHandleActions(reducers, init);
