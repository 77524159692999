import { IInputCommerceAttribute, IInputCommerceAttributeValues } from '@ApiModels/singlePackage';

interface IListOfElements {
	itemLabel: string;
	ecommerceLabel: string | string[];
}
export interface IPlanDetail {
	[key: string]: {
		itemLabel: string;
		itemUnit: string;
		itemValue: string | number;
		period: string;
		title: string;
		titleAsAttribute?: string;
		isBenefit?: string;
	};
}

const placeholders = [
	'PLAN_PLACEHOLDER_1',
	'PLAN_PLACEHOLDER_2',
	'PLAN_PLACEHOLDER_3',
	'PLAN_PLACEHOLDER_4',
	'PLAN_PLACEHOLDER_5',
	'PLAN_PLACEHOLDER_6',
];

export const getExtractedValue = (inputString: string): string | number => {
	if (typeof inputString === 'string') {
		const value = inputString.replace(/[A-z]/g, '').trim();
		return value ? parseFloat(value) || value : '-';
	} else {
		return '-';
	}
};

export const getExtractedUnit = (input: string, defaultValue = '-'): string => {
	if (typeof input === 'string') {
		const unit = input.replace(/[0-9]/g, '').trim();
		return unit ? unit : defaultValue;
	} else {
		return defaultValue;
	}
};

interface IOutputAttributeItem {
	identifier: string;
	values: IInputCommerceAttributeValues;
}

interface IOutputAttributeItems {
	attributes: IOutputAttributeItem[];
}

export const convertGetUsageItemsToEcommerce = (items: IInputCommerceAttribute[]): IOutputAttributeItems => {
	const output = {
		attributes: [],
	};
	items.forEach((item) => {
		output.attributes.push({
			identifier: item.name as never,
			values: item.values as never,
		} as never);
	});
	return output;
};

interface IFinalPrice {
	currency?: string;
	price?: string | number;
}

export const extractPrice = (
	item: types.ecommerce.ICatalogEntryView,
	defaultPrice?: string,
	defaultCurrency?: string
): IFinalPrice => {
	const defaultFinalPrice = {
		currency: defaultCurrency || 'OMR',
		price: defaultPrice,
	};

	for (const singleAttribute of item.attributes) {
		if (!!singleAttribute && singleAttribute.identifier === 'price') {
			item = { price: singleAttribute.values as any } as any;
			break;
		}
	}

	if (item && item.price) {
		let orginalPrice = '';
		item.price.forEach((singlePrice: { usage: string; value: string }) => {
			// try get price with usage attribute
			if (item?.usage && singlePrice.usage && singlePrice.usage === item?.usage && singlePrice.value) {
				orginalPrice = singlePrice.value;
			} else if (
				// try get price with 'Display' usage
				(singlePrice.usage && singlePrice.usage === 'Display' && singlePrice.value) ||
				// if none, try get any value
				(orginalPrice === '' && singlePrice.value)
			) {
				orginalPrice = singlePrice.value;
			}
		});

		const splittedPrice = orginalPrice.split('.');
		const price = splittedPrice[1] !== '0' ? orginalPrice : splittedPrice[0];
		const currency = item.price[0] && item.price[0].currency ? item.price[0].currency : 'OMR';

		const finalPrice = {
			currency,
			price: parseFloat(price) || price,
		};

		if (price && currency) {
			return finalPrice;
		} else {
			return defaultFinalPrice;
		}
	} else {
		return defaultFinalPrice;
	}
};

export const parsers = {
	boolean: (input: any): boolean => [true, 'TRUE', 'true', 1, '1'].includes(input),
	duration: (input: string): number | undefined => {
		let _input = input;
		if (String(input).startsWith('7')) {
			_input = 'Weekly';
		}
		if (String(input).startsWith('30')) {
			_input = 'Monthly';
		}
		switch (_input) {
			case 'Daily':
			case '1':
				return 1;
			case 'Weekly':
			case '7':
				return 7;
			case 'Monthly':
			case '30':
				return 30;
			case 'Special':
				return -1;
			default:
				return undefined;
		}
	},
	perioidName: (shortcut: string): string => {
		switch (shortcut) {
			case 'MON':
				return 'Month';
			case 'WEE':
				return 'Week';
			default:
				return '';
		}
	},
};

export const extractAttributeValue = (
	item: types.ecommerce.ICatalogEntryView,
	attributeName: string | string[],
	defaultOnNotFound: any = undefined
): any => {
	let value = defaultOnNotFound;
	const { attributes } = item;

	function findAttributeValue(attributeName: string) {
		for (const singleAttribute of attributes) {
			if (!!singleAttribute && singleAttribute.identifier === attributeName) {
				value = singleAttribute.values[0].value;
				break;
			}
		}
	}

	if (item && item.attributes && attributeName) {
		if (Array.isArray(attributeName)) {
			attributeName.forEach((attributeName) => {
				findAttributeValue(attributeName);
			});
		} else {
			findAttributeValue(attributeName);
		}
	} else {
		value = false;
	}
	return value;
};

export const extractAttributeUnitOfMeasure = (
	item: types.ecommerce.ICatalogEntryView,
	attributeName: string | string[]
): any => {
	let unitOfMeasure;
	const { attributes } = item;

	function findAttributeValue(attributeName: string) {
		for (const singleAttribute of attributes) {
			if (!!singleAttribute && singleAttribute.identifier === attributeName) {
				unitOfMeasure = singleAttribute.values[0].unitOfMeasure;
				break;
			}
		}
	}

	if (item && item.attributes && attributeName) {
		if (Array.isArray(attributeName)) {
			attributeName.forEach((attributeName) => {
				findAttributeValue(attributeName);
			});
		} else {
			findAttributeValue(attributeName);
		}
	} else {
		unitOfMeasure = null;
	}
	return unitOfMeasure;
};

export const extractAttributeValuesAll = (item: types.ecommerce.ICatalogEntryView, attributeName: string): string[] => {
	const allValues: any[] = [];
	if (item && item?.attributes && attributeName) {
		item?.attributes.forEach((singleAttribute: { identifier: string; values: { value: string }[] }) => {
			if (!!singleAttribute && singleAttribute?.identifier === attributeName) {
				allValues.push(singleAttribute?.values[0].value);
			}
		});
		return allValues;
	} else {
		return [];
	}
};

export const extractPlanDetails = (catalogEntryView: types.ecommerce.ICatalogEntryView): Record<string, any> => {
	let outputValues: any = {};

	const listOfElements: IListOfElements[] = [
		{ itemLabel: 'includedData', ecommerceLabel: ['LOC_DATA_PACKAGE_SIZE', 'INCLUDED_DATA'] },
		{ itemLabel: 'includedMinutes', ecommerceLabel: ['LOC_MIN_PACKAGE_SIZE', 'INCLUDED_MINUTES'] },
		{ itemLabel: 'packageSize', ecommerceLabel: ['INCLUDED_SMS', 'LOC_SMS_PACKAGE_SIZE'] },
		{ itemLabel: 'whatsapPackage', ecommerceLabel: 'WHATSAPP_PACKAGE_SIZE' },
		{ itemLabel: 'doubleData', ecommerceLabel: 'DOUBLE_DATA_INCLUDED' },
		{ itemLabel: 'roamingData', ecommerceLabel: 'INCLUDED_ROAMING_DATA' },
		{ itemLabel: 'roamingRecMinutes', ecommerceLabel: 'INCLUDED_ROAM_REC_MIN' },
		{ itemLabel: 'callDiscount', ecommerceLabel: 'INT_CALL_DISCOUNT_SIZE' },
		{ itemLabel: 'promoData', ecommerceLabel: 'PROMO_DATA' },
		{ itemLabel: 'promoNationalGcc', ecommerceLabel: 'PROMO_DATA_NAT_PLUS_GCC' },
		{ itemLabel: 'promoMinutes', ecommerceLabel: 'PROMO_MINUTES' },
		{ itemLabel: 'promoAppStore', ecommerceLabel: 'PROMO_APPSTORE_CREDIT' },
		{ itemLabel: 'promoGoBizData', ecommerceLabel: 'PROMO_GOBIZ_DATA' },
		{ itemLabel: 'promoRoamingMinutes', ecommerceLabel: 'PROMO_ROAM_REC_MIN' },
		{ itemLabel: 'intlMinutes', ecommerceLabel: 'INCLUDED_INT_MINUTES' },
		{ itemLabel: 'data', ecommerceLabel: 'DATA_INCLUDED' },
		{ itemLabel: 'speed', ecommerceLabel: 'PLAN_SPEED' },
		{ itemLabel: 'dataSharing', ecommerceLabel: 'DATA_SHARING' },
		{ itemLabel: 'downloadSpeed', ecommerceLabel: ['HBB_DOWN_SPEED', 'PLAN_SPEED'] },
		{ itemLabel: 'uploadSpeed', ecommerceLabel: ['HBB_UP_SPEED', 'WFBB_UP_SPEED'] },
		{ itemLabel: 'hbbDataIncluded', ecommerceLabel: 'HBB_USAGE_INC' },
	];

	const extractPlan = (planAttribute: IListOfElements) => {
		let planExtractedAttribute: any;
		let selectedAttribute: string = '';

		if (Array.isArray(planAttribute)) {
			planAttribute.forEach((singlePlanAttribute) => {
				if (planExtractedAttribute) {
					planExtractedAttribute = planExtractedAttribute;
					selectedAttribute = selectedAttribute;
				} else {
					planExtractedAttribute = extractAttributeValue(
						catalogEntryView,
						singlePlanAttribute.ecommerceLabel
					);
					selectedAttribute = singlePlanAttribute.ecommerceLabel as string;
				}
			});
		} else {
			planExtractedAttribute = extractAttributeValue(catalogEntryView, planAttribute.ecommerceLabel);
			selectedAttribute = planAttribute.ecommerceLabel as string;
		}

		if (planExtractedAttribute) {
			const planAttributeIncludedUnit = extractAttributeUnitOfMeasure(
				catalogEntryView,
				planAttribute.ecommerceLabel
			);
			const tempObj = {
				[planAttribute.itemLabel]: {
					itemLabel: extractAttributeValue(catalogEntryView, selectedAttribute),
					itemUnit: getExtractedUnit(planExtractedAttribute),
					itemValue: getExtractedValue(planExtractedAttribute),
					period: parsers.perioidName(planAttributeIncludedUnit),
					titleAsAttribute: extractAttributeValue(catalogEntryView, `${selectedAttribute}_TITLE`),
					title: `package-name.${selectedAttribute}`,
				},
			};
			outputValues = { ...outputValues, ...tempObj };
		}
	};

	listOfElements.forEach(extractPlan);

	for (const placeholder of placeholders) {
		const planPlaceholder: string = extractAttributeValue(catalogEntryView, placeholder);

		if (planPlaceholder) {
			const placeholderMeasure = extractAttributeUnitOfMeasure(catalogEntryView, placeholder);
			const tempObj: IPlanDetail = {
				[placeholder]: {
					isBenefit: extractAttributeValue(catalogEntryView, placeholder + '_BENEFIT'),
					itemLabel: extractAttributeValue(catalogEntryView, placeholder + '_EXTRALABEL'),
					itemUnit: planPlaceholder.split(' ')[1],
					itemValue: planPlaceholder.split(' ')[0],
					period: parsers.perioidName(placeholderMeasure),
					title: extractAttributeValue(catalogEntryView, placeholder + '_LABEL'),
				},
			};
			outputValues = { ...outputValues, ...tempObj };
		}
	}
	return outputValues;
};

interface IPayGDetail {
	itemUnit: string;
	itemValue: string;
	title: string;
}

export const extractPayGDetails = (singlePackage: types.ecommerce.ICatalogEntryView): IPayGDetail[] => {
	let arrayOfValues: any[] = [];

	const data: string = extractAttributeValue(singlePackage, 'LOC_DATA_COST');
	const minutes: string = extractAttributeValue(singlePackage, 'LOC_MIN_COST');
	const sms: string = extractAttributeValue(singlePackage, 'LOC_SMS_COST');
	const mms: string = extractAttributeValue(singlePackage, 'LOC_MMS_COST');
	if (data) {
		const tempObj = {
			itemUnit: data.split(' ')[1],
			itemValue: data.split(' ')[0],
			title: 'package-name.national-data',
		};
		arrayOfValues = [...arrayOfValues, tempObj];
	}
	if (minutes) {
		const tempObj = {
			itemUnit: minutes.split(' ')[1],
			itemValue: minutes.split(' ')[0],
			title: 'package-name.national-minutes',
		};
		arrayOfValues = [...arrayOfValues, tempObj];
	}
	if (sms) {
		const tempObj = {
			itemUnit: sms.split(' ')[1],
			itemValue: sms.split(' ')[0],
			title: 'package-name.national-sms',
		};
		arrayOfValues = [...arrayOfValues, tempObj];
	}
	if (mms) {
		const tempObj = {
			itemUnit: mms.split(' ')[1],
			itemValue: mms.split(' ')[0],
			title: 'package-name.national-mms',
		};
		arrayOfValues = [...arrayOfValues, tempObj];
	}
	return arrayOfValues;
};
