import React, { FunctionComponent, useEffect, useState } from 'react';
import {
	CardContainer,
	ActionWrapper,
	CartTitle,
	DropdownWrapper,
	ContentWrapper,
	ProductWrapper,
	ProductSubheading,
	ImgWrapper,
	ProductDescription,
} from '@Components/Card/cards/ProductAvailability/ProductAvailability.styled';
import useTranslate from '@Utils/hooks/useTranslate';
import { setModal } from '@Redux/modules/modal/actions';
import { useDispatch } from 'react-redux';
import Divider from '@Components/Divider/Divider';
import Icon from '@Components/Icon/Icon';
import { Col, Row } from 'react-grid-system';
import { IDropdownItem, RegularDropdown } from '@Components/controls/Dropdown/Dropdown';
import { DropdownHeight, LabelType } from '@Components/controls/Dropdown/Dropdown.styled';
import { HorizontalColorPicker } from '@Components/controls/ColorPicker/ColorPicker';
import ConditionalRender from '@Components/ConditionalRender/ConditionalRender';
import ProductAvailabilityItem from './ProductAvailabilityItem/ProductAvailabilityItem';
import { IAvailableColorWithId } from '@Components/controls/ColorPicker/subcomponents/Slider';
import { InventoryService } from '@Services/inventory/inventoryService';
import Image from '@Components/Image/Image';

const ProductAvailabilityCard: FunctionComponent<types.cards.IProductAvailabilityCardProps> = ({
	params: { cities, availablePlaces, product },
}) => {
	const [selectedDropdownItem, setSelectedDropdownItem] = useState<IDropdownItem | null>(null);
	const [availableColors, setAvailableColors] = useState<IAvailableColorWithId[]>([]);
	const { translate } = useTranslate();
	const dispatch = useDispatch();
	const [selectedColor, setSelectedColor] = useState<string | null>(null);
	const handleClose = () => {
		dispatch(
			setModal({
				type: null,
				data: null,
			})
		);
	};

	useEffect(() => {
		InventoryService.getAvailableProductComponents({ product }).subscribe(
			(response) => {
				setAvailableColors(response.availableColors);
			},
			() => {}
		);
	}, [selectedColor]);

	return (
		<CardContainer>
			<CartTitle>
				<Row>
					<Col xs={11}>{translate('product-availability-card.title')}</Col>
					<Col>
						<ActionWrapper onClick={handleClose}>
							<Icon name="close" fill="black" />
						</ActionWrapper>
					</Col>
				</Row>
			</CartTitle>
			<Divider color="black12" />
			<ContentWrapper>
				<Row>
					<Col md={4}>
						<ConditionalRender
							show={!!cities}
							onTrue={() => (
								<DropdownWrapper>
									<RegularDropdown
										dropdownHeight={DropdownHeight.SCROLL}
										items={cities}
										setSelectedItem={setSelectedDropdownItem}
										selectedItem={selectedDropdownItem}
										label={translate('product-availability-card.select.city')}
										labelType={LabelType.FLOATING}
										placeholder={translate('product-availability-card.select.city')}
									/>
								</DropdownWrapper>
							)}
						/>
						<ProductWrapper>
							<ImgWrapper>
								<Image src={product.thumbnail} />
							</ImgWrapper>
							<ProductSubheading>
								{translate('product-availability-card.product.device')}
							</ProductSubheading>
							<ProductDescription>{product.name}</ProductDescription>
							<ProductSubheading>
								{translate('product-availability-card.product.storage')}
							</ProductSubheading>
							<ProductDescription>{product.name}</ProductDescription>
							<ProductSubheading>
								{translate('product-availability-card.product.color')}
								{product.name}
							</ProductSubheading>
							<HorizontalColorPicker
								colors={availableColors}
								setSelectedColor={setSelectedColor}
								selectedColor={selectedColor}
							/>
						</ProductWrapper>
					</Col>
					<Col>
						<ConditionalRender
							show={!!availablePlaces}
							onTrue={() => {
								return availablePlaces.map((place) => (
									<ProductAvailabilityItem
										key={place.id}
										place={place.place}
										available={place.available}
									/>
								));
							}}
						/>
					</Col>
				</Row>
			</ContentWrapper>
		</CardContainer>
	);
};
export default ProductAvailabilityCard;
