import styled from 'styled-components';
import { Tabs } from '@Components/Typography/Typography';
import { LabelWidth } from '@Components/controls/Tabs/Tabs.styled';

interface ILabelWrapperProps {
	labelWidth: LabelWidth;
	active: boolean;
	disabled: boolean;
}

interface ILabelProps {
	active: boolean;
	disabled: boolean;
}

export const Label = styled(Tabs)<ILabelProps>`
	transition: all 0.1s ease-in-out;
	color: ${({ active, disabled, theme }) => {
		if (disabled) {
			return theme.colors.black38;
		}
		if (active) {
			return theme.colors.primary;
		}
		return theme.colors.black87;
	}};
	white-space: nowrap;
`;

export const LabelWrapper = styled.div<ILabelWrapperProps>`
	height: 56px;
	display: flex;
	align-items: center;
	justify-content: center;
	box-sizing: border-box;
	border-bottom: solid 2px transparent;
	transition: all 0.1s ease-in-out;
	${({ disabled }) => !disabled && { cursor: 'pointer' }}
	${({ labelWidth }) => (labelWidth === LabelWidth.FIXED ? { width: 120 } : { padding: '0 16px' })}
	${({ active, disabled, theme }) =>
		active && (disabled ? { borderColor: theme.colors.black38 } : { borderColor: theme.colors.primary })}
	&:hover {
		${({ disabled }) => !disabled && { opacity: 0.85 }}
		& > p {
			${({ active, disabled, theme }) => !active && !disabled && { color: theme.colors.primary }}
		}
	}
	&:active {
		${({ disabled }) => !disabled && { opacity: 0.75 }}
	}
`;
