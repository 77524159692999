import { convertToArray } from '@Utils/converters/convertToArray';
import { roundMoney } from '@Utils/converters/roundMoney';
import moment from 'moment';
import { AccountType } from './accounts';

export interface IInvoices {
	invoiceNo: string;
	invoiceDate: string;
	dueDate: string;
	invoiceAmount: string;
	invoiceStatus: string;
	invoiceType: string;
	adjustment: number;
	balanceDue: string;
	disputeAmount: string;
	outstandingAmount: string;
	daysTillPayment: number;
}

interface IBillData {
	billingId: string;
	accountType: AccountType;
	invoices: IInvoices[];
}

export interface IBillsHistory {
	fromDate?: string;
	toDate?: string;
	billsData: IBillData[];
	totalUnpaid: string;
}

interface IBillsHistoryModel extends IBillsHistory {
	_billsHistory: IBillsHistory;
}

const getTotalAmount = (billsData: IBillData[] | undefined) => {
	if (billsData) {
		let totalAmount: number = 0;
		billsData.forEach((billData) => {
			billData.invoices.forEach((invoice) => {
				totalAmount = Number(invoice.balanceDue) + totalAmount;
			});
		});
		return roundMoney(totalAmount);
	}
	return '0';
};

const model = {
	_billsHistory: 'response.getBillsHistoryForAccountsResponse',
	billsData: (model: IBillsHistoryModel): IBillData[] => {
		if (model?._billsHistory) {
			const billsDataArray = convertToArray(model?._billsHistory?.billsData);
			return billsDataArray.map((billData) => {
				const _billData = { ...billData };
				_billData.invoices = billData.invoices.map((invoice) => {
					const _invoice: any = {};
					_invoice.invoiceAmount = roundMoney(invoice.invoiceAmount);
					_invoice.balanceDue = roundMoney(invoice.balanceDue);
					_invoice.outstandingAmount = roundMoney(invoice.outstandingAmount);
					_invoice.disputeAmount = roundMoney(invoice.disputeAmount);
					_invoice.daysTillPayment =
						invoice.invoiceStatus === 'closed' ? 1 : moment(invoice.dueDate).diff(moment(), 'days');
					return _invoice;
				});
				return _billData;
			});
		}
		return [];
	},
	fromDate: 'response.getBillsHistoryForAccountsResponse.fromDate',
	toDate: 'response.getBillsHistoryForAccountsResponse.toDate',
};

export default model;
