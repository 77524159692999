import {
	CAPACITY_ATTRIBUTE,
	COLOR_ATTRIBUTE,
	COUNTRY_ATTRIBUTE,
	getAttributeValue,
	getAttributeValueAsString,
	getAvailableCapacity,
	getAvailableColors,
	getAvailableCountries,
	getAvailableValues,
	getPrice,
	GIFT_CARD_VALUES_ATTRIBUTE,
	ITEM_TYPE_ATTRIBUTE,
	MANUFACTURER_ATTRIBUTE,
	PREORDER,
	PREORDER_DATE,
	VAT_PERCENT,
} from '@ApiModels/utils/attributeGetters';
import { IContents } from '@ApiModels/productById';
import { IItem } from '@ApiModels/productsByCategoryId';

export const GIFTCARD = 'GIFTCARD';
const DIGITAL = 'DIGITAL_PRODUCT';
const ITEM_HBB_INSTALMENTS = 'ITEM_HBB_INSTALMENTS';
export const ITEMS_LIST = [GIFTCARD, DIGITAL, ITEM_HBB_INSTALMENTS];

interface IRelatedProduct extends IContents {
	associationType: 'REPLACEMENT' | 'UPSELL';
}

interface ICatalogEntryView {
	merchandisingAssociations: IRelatedProduct[];
}

interface IModel {
	_catalogEntryView: ICatalogEntryView[];
}

export interface IRelatedProducts {
	relatedProducts: IRelatedProductByIdItem[];
}

export interface IRelatedProductByIdItem extends IItem {
	isRelated: boolean;
	isUpsell: boolean;
	manufacturer?: string;
	uniqueID: string;
	seo: string;
}

const getRelatedItems = (contents: IRelatedProduct[]): IRelatedProductByIdItem[] => {
	return contents.map((item) => {
		const { name, thumbnail, id, price, attributes = [], longDescription, shortDescription, uniqueID } = item;
		const colors = getAvailableColors(attributes);
		const availableCountries = getAvailableCountries(attributes);
		const itemType = getAttributeValue(item, ITEM_TYPE_ATTRIBUTE) as string;
		const isGiftCard = itemType === GIFTCARD;

		return {
			attributes,
			name,
			price: getPrice(price, 'Offer'),
			colors,
			availableCountries,
			availableValues: getAvailableValues(attributes),
			selectedColor: '',
			availableCapacity: getAvailableCapacity(attributes),
			additionalInfo: (() => {
				const capacities = getAvailableCapacity(attributes);
				if (capacities.length) {
					return {
						label: 'product-card.capacity',
						value: `${capacities.map((item, index) => `${index > 0 ? ' ' : ''}${item.value}`)}`,
					};
				}
			})(),
			colorAttributeId: attributes.find((attribute) => {
				if (isGiftCard) {
					return attribute.identifier === COUNTRY_ATTRIBUTE;
				}
				return attribute.identifier === COLOR_ATTRIBUTE;
			})?.key,
			capacityAttributeId: attributes.find((attribute) => attribute.identifier === CAPACITY_ATTRIBUTE)?.id,
			thumbnail,
			id: uniqueID,
			uniqueID,
			longDescription,
			shortDescription,
			manufacturer: getAttributeValue(item, MANUFACTURER_ATTRIBUTE) as string,
			itemType,
			isGiftCard,
			isRelated: item.associationType === 'REPLACEMENT',
			isUpsell: item.associationType === 'UPSELL',
			seo: item?.seo?.href,
			colorId: attributes.find((attr) => attr.identifier === COLOR_ATTRIBUTE)?.values[0].id,
			capacityId: attributes.find((attributte) => attributte.identifier === CAPACITY_ATTRIBUTE)?.values[0].id,
			valueId: attributes.find((attr) => attr.identifier === GIFT_CARD_VALUES_ATTRIBUTE)?.values[0].id,
			countryId: attributes.find((attr) => attr.identifier === COUNTRY_ATTRIBUTE)?.values[0].id,
			vatPercent: attributes.find((attr) => attr.identifier === VAT_PERCENT)?.values[0].value,
			priceDisplay: getPrice(item.price, 'Display'),
			priceOffer: getPrice(item.price, 'Offer'),
			preorder: getAttributeValueAsString(item, PREORDER) === 'TRUE',
			preorderDate: getAttributeValueAsString(item, PREORDER_DATE),
		};
	});
};

const model: types.models.IInputModel = {
	_catalogEntryView: ['response.catalogEntryView', []],
	relatedProducts: (model: IModel) => {
		if (model._catalogEntryView[0]?.merchandisingAssociations) {
			return getRelatedItems(model._catalogEntryView[0].merchandisingAssociations);
		} else return [];
	},
};

export default model;
