import styled from 'styled-components';
import { ParagraphMBold, BodyM, BodySBold } from '@Components/Typography/Typography';

export const ImageWithTitleAndButtonWrapper = styled.div<{ direction: 'row' | 'column' }>`
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
	${({ direction }) => ({
		flexDirection: direction,
		gap: direction === 'column' ? 0 : 24,
	})}
`;

export const ImageWithTitleAndButtonTitle = styled(ParagraphMBold)`
	${({ theme }) => {
		return {
			color: theme.colors.black87,
		};
	}}
`;

export const ImageWithTitleAndButtonDescription = styled(BodyM)<{ direction: 'row' | 'column' }>`
	${({ direction, theme }) => ({
		marginTop: direction === 'column' ? 16 : 0,
		color: theme.colors.black54,
	})}
`;

export const TextWrapper = styled.div<{ direction: 'row' | 'column' }>`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	${({ direction }) => ({
		padding: direction === 'column' ? 16 : 0,
	})}
`;

export const ImageWithTitleAndButtonLink = styled(BodySBold)`
	cursor: pointer;
	text-decoration: underline;
	margin-top: 24px;
	${({ theme }) => {
		return {
			color: theme.colors.primary,
		};
	}}
`;
