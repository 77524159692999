import React, { FunctionComponent } from 'react';

import useTranslate from '@Utils/hooks/useTranslate';
import { PaymentInfoCardContainer, TitleRow } from './PaymentBNPLInfo.styles';
import Icon from '@Components/Icon/Icon';
import { BodyM } from '@Components/Typography/Typography.styled';

const ICON_SIZE = 24;

const PaymentBNPLINfo: FunctionComponent = ({}) => {
	const { translate } = useTranslate();

	return (
		<PaymentInfoCardContainer>
			<TitleRow>
				<Icon name="msgInfo" fill="black54" height={ICON_SIZE} width={ICON_SIZE} />
				<BodyM>{translate('checkout-summary.success.bnpl.title')}</BodyM>
			</TitleRow>
		</PaymentInfoCardContainer>
	);
};

export default PaymentBNPLINfo;
