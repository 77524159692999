import ConditionalRender from '@Components/ConditionalRender/ConditionalRender';
import Icon from '@Components/Icon/Icon';
import React, { FunctionComponent, useState } from 'react';
import {
	BiggerTooltipText,
	TooltipContainer,
	TooltipText,
	TooltipWrapper,
	LowerPinTooltipContainer,
} from './Tooltip.styled';

interface ITooltipProps {
	children: JSX.Element;
	text: string;
	width?: number;
	disable?: boolean;
	showWhenTextAvailable?: boolean;
	withPin?: boolean;
	margin?: string;
	icon?: types.iconNames;
	iconSize?: number;
	error?: boolean;
	isWhiteBackground?: boolean;
	isLowerPinTooltip?: boolean;
	pinFromLeft?: string;
}

const Tooltip: FunctionComponent<ITooltipProps> = ({
	children,
	text,
	width,
	disable,
	showWhenTextAvailable,
	withPin,
	icon,
	margin = '10px 0px 0px',
	iconSize = 12,
	error,
	pinFromLeft,
	isWhiteBackground,
	isLowerPinTooltip = false,
}) => {
	const [show, setShow] = useState(false);
	return (
		<TooltipWrapper
			withPin={withPin}
			onMouseEnter={() => {
				setShow(!disable);
			}}
			onMouseLeave={() => {
				setShow(false);
			}}
		>
			{children}
			<ConditionalRender
				show={show || (!!showWhenTextAvailable && !!text)}
				onTrue={() => (
					<ConditionalRender
						show={!isLowerPinTooltip}
						onTrue={() => (
							<TooltipContainer
								pinFromLeft={pinFromLeft}
								margin={margin}
								width={width}
								withPin={withPin}
								isWhiteBackground={isWhiteBackground}
							>
								<Icon fill="warning" name={icon} width={iconSize} height={iconSize} />
								<TooltipText fontColor={error ? 'warning' : withPin ? 'black54' : 'white'}>
									{text}
								</TooltipText>
							</TooltipContainer>
						)}
						onFalse={() => (
							<LowerPinTooltipContainer
								pinFromLeft={pinFromLeft}
								margin={margin}
								width={width}
								withPin={withPin}
								isWhiteBackground={isWhiteBackground}
							>
								<Icon fill="warning" name={icon} width={iconSize} height={iconSize} />
								<BiggerTooltipText fontColor={error ? 'warning' : withPin ? 'black54' : 'white'}>
									{text}
								</BiggerTooltipText>
							</LowerPinTooltipContainer>
						)}
					/>
				)}
			/>
		</TooltipWrapper>
	);
};

export default Tooltip;
