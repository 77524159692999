import TabSelector from '@Components/controls/TabSelector/TabSelector';
import Divider from '@Components/Divider/Divider';
import PlanComparison from '@Components/MigrationPlanComparison/PlanComparison';
import { usePrepaidMigration } from '@Utils/context/PrepaidMigrationContext';
import useTranslate from '@Utils/hooks/useTranslate';
import { getBarTypeByPlan } from '@Utils/plans/barType';
import React, { FunctionComponent, useState } from 'react';
import { Container } from 'react-grid-system';
import { useSelector } from 'react-redux';
import { CaptionWrapper, Title } from './Plans.styled';

const Plans: FunctionComponent = () => {
	const { availablePlans } = usePrepaidMigration();
	const [currentTab, setCurrentTab] = useState(0);
	const { translate } = useTranslate();
	const isLogged = useSelector((state: types.redux.IState) => state.api.authentication.signedIn);

	const getPlansToCompare = () => {
		return availablePlans
			.filter(({ duration }) => (currentTab === 0 && duration) || (currentTab === 1 && !duration))
			.map((plan, index) => {
				return {
					id: plan.uniqueID,
					type: getBarTypeByPlan(plan),
					name: plan.shortDescription,
					contractCommitment: plan.duration
						? translate('plan.selector.commitment', String(plan.duration))
						: translate('plan.selector.no.commitment'),
					isCurrentPlan: false,
					isRecommendedPlan: false,
					monthlyPayment: plan.price,
					localAndGccData:
						plan.planDetails?.includedData?.itemValue === '-'
							? 'Unlimited'
							: plan.planDetails?.includedData?.itemValue,
					localMinutes:
						plan.planDetails?.includedMinutes?.itemValue === '-'
							? 'Unlimited'
							: plan.planDetails?.includedMinutes?.itemValue,
					internationalMinutes: plan.planDetails?.intlMinutes?.itemValue,
					roamingRecievingMinutes: plan.planDetails?.roamingRecMinutes?.itemValue,
					worldRoamingData: plan.planDetails?.roamingData?.itemValue,
					dataSharing: plan.planDetails?.dataSharing?.itemValue,
					storeCredit: plan.planDetails?.promoAppStore?.itemValue,
					keepThisPlan: false,
					selectPlan: true,
					dataIncluded:
						plan.planDetails?.data?.itemValue || plan?.planDetails?.hbbDataIncluded?.itemValue === '-'
							? 'Unlimited'
							: plan.planDetails?.hbbDataIncluded?.itemValue,
					downloadSpeed: plan.planDetails?.downloadSpeed?.itemValue,
					downloadSpeedUnit: plan.planDetails?.downloadSpeed?.itemUnit === 'Gbps' ? 'GB' : 'MB',
					uploadSpeed: plan.planDetails?.uploadSpeed?.itemValue,
					freeMinutesToOmantel:
						plan.planDetails?.includedMinutes?.itemValue === '-'
							? 'Unlimited'
							: plan.planDetails?.includedMinutes?.itemValue,
					intlCallsDiscount: plan.planDetails?.callDiscount?.itemValue,
					planVatPercent: plan.vatPercent,
					planVatValue: plan.vatValue,
					partNumber: plan.partNumber,
					duration: plan.duration,
				};
			});
	};
	return (
		<>
			<Container>
				<Title isLogged={isLogged}>{translate('migration-plans.title')}</Title>
			</Container>
			<TabSelector
				tabs={[{ tabName: translate('migration-plans.tab-1') }]}
				onSelectTabIndex={(index) => {
					setCurrentTab(index);
				}}
				currentTab={currentTab}
			/>
			<Container>
				<PlanComparison plans={getPlansToCompare()} />
				<Divider marginBottom={16} marginTop={32} />
				<CaptionWrapper>{translate('prepaid-migration.caption')}</CaptionWrapper>
				<Divider marginBottom={40} withoutLine />
			</Container>
		</>
	);
};

export default Plans;
