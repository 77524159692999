import styled from 'styled-components';
import { BodyS } from '@Components/Typography/Typography';

export const PaginationContainer = styled.div`
	padding: 24px 0 64px;
	display: flex;
	flex-direction: column;
	flex: 1;
`;

export const CounterWrapper = styled.div`
	padding: 12px 0;
`;

export const Counter = styled(BodyS)`
	${({ theme }) => ({
		color: theme.colors.black54,
	})}
`;

export const LoaderWrapper = styled.div`
	padding: 12px 0;
	display: flex;
	flex: 1;
	align-items: center;
	justify-content: center;
`;
