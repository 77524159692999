export interface ITodayWorkingShift {
	startDate: string;
	endDate: string;
}

export interface IBranch {
	id: number;
	name: string;
	cityId: number;
	departmentId: number;
	latitude: number;
	longitude: number;
	isWorkingNow: boolean;
	distance: number;
	identity: string;
	todayWorkingShifts: ITodayWorkingShift[];
}

export interface IGetBranches {
	returnValue: IBranch[];
}

const model: types.models.IInputModel = {
	returnValue: 'response.getBranchesResponse.branches',
};

export default model;
