import ConditionalRender from '@Components/ConditionalRender/ConditionalRender';
import React, { FunctionComponent } from 'react';
import { ImageContainer, Img, IImageContainerProps, IImgProps, ActiveDot } from './Image.styled';

interface IIconProps extends IImageContainerProps, IImgProps {}

const Image: FunctionComponent<IIconProps> = (props) => {
	const { src, margin, padding, width, height, imageHeight, onClick, selected, previewMode } = props;
	return (
		<ImageContainer
			margin={margin}
			padding={padding}
			width={width}
			height={height}
			onClick={onClick}
			selected={selected}
			previewMode={previewMode}
		>
			<ConditionalRender show={!!previewMode} onTrue={() => <ActiveDot selected={!!selected} />} />
			<Img src={src} previewMode={previewMode} imageHeight={imageHeight} />
		</ImageContainer>
	);
};

export default Image;
