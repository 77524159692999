/**
 * Select Type of Bar. Custom theme for diferent types.
 */
export enum PlanBarType {
	/**
	 * White background, black (support3) text.
	 */
	BAQATI_INACTIVE_NO_COMMITMENT = 'BAQATI_INACTIVE_NO_COMMITMENT',
	/**
	 * White background, purple text.
	 */
	BAQATI_INACTIVE = 'BAQATI_INACTIVE',
	/**
	 * Purple gradient background, white text.
	 */
	BAQATI = 'BAQATI',
	/**
	 * Black gradient background, white text.
	 */
	BAQATI_AL_UFUQ_INACTIVE = 'BAQATI_AL_UFUQ_INACTIVE',
	/**
	 * White background, black87 text.
	 */
	BAQATI_AL_UFUQ = 'BAQATI_AL_UFUQ',
	/**
	 * White background, blue text.
	 */
	UNLIMITED_INACTIVE = 'UNLIMITED_INACTIVE',
	/**
	 * Blue gradient background, white text.
	 */
	UNLIMITED = 'UNLIMITED',
}
