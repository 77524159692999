import React, { FunctionComponent } from 'react';
import Card from '@Components/Card/Card';

const ModalNotifyAvailability: FunctionComponent<types.cards.INotifyAvailabilityCardProps['params']> = ({
	phoneNumber,
	email,
}) => {
	return (
		<Card<types.cards.INotifyAvailabilityCardProps>
			card="notifyAvailability"
			params={{ phoneNumber, email }}
			withoutContainerPadding
		/>
	);
};

export default ModalNotifyAvailability;
