import styled from 'styled-components';

export const BoxContentContainer = styled.div`
	width: 160px;
	height: 132px;
	text-align: center;
`;
export const IconWrapper = styled.div`
	margin-bottom: 16px;
	img {
		height: 64px;
		width: 64px;
		${({ theme }) => ({
			fill: theme.colors.black54,
		})}
	}
`;
