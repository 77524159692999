export enum ResponseResult {
	SUCCESS = 'SUCCESS',
	SUBSCRIPCTIONS_NOT_FOUND = 'SUBSCRIPCTIONS_NOT_FOUND',
	CATALOG_NOT_FOUND = 'CATALOG_NOT_FOUND',
	TENANT_NOT_FOUND = 'TENANT_NOT_FOUND',
	PRODUCT_NOT_AVAILIBLE = 'PRODUCT_NOT_AVAILIBLE',
}

export interface IResponseResult {
	result: ResponseResult;
}

const model: types.models.IInputModel = {
	result: 'response.response.result',
};

export default model;
