import {
	BodyS,
	BodySBold,
	ButtonM,
	Caption,
	ParagraphS,
	CaptionBigger,
} from '@Components/Typography/Typography.styled';
import { isRTL, paddingLeft, paddingRight, marginLeft, marginRight, right } from '@Utils/layout/layoutSupport';
import { Row } from 'react-grid-system';
import styled from 'styled-components';

export const EVoucherCardContainer = styled(Row)<{ isXs?: boolean; isSm?: boolean }>`
	${({ isXs, isSm }) => ({
		padding: '2px 0px',
		...paddingRight(isXs || isSm ? 0 : 16),
		...paddingLeft(isXs ? 0 : isSm ? 8 : 16),
	})}
`;

export const BackgroundImageWrapper = styled.div`
	bottom: -20px;
	${() => right(-30)}
	position: absolute;
	padding: inherit;
	z-index: 1;
`;

export const AccordionContentWrapper = styled.div<{ isSm?: boolean; isXs?: boolean }>`
	padding-bottom: 16px;
	${({ isSm, isXs }) => ({
		marginTop: isXs ? '16px' : '24px',
		...paddingLeft(isSm ? 72 : isXs ? 16 : 88),
	})}
	${() => paddingRight(16)}
`;

export const NonLoggedAccordionContentWrapper = styled.div<{ isXs?: boolean; isSm?: boolean; isNonLogged?: boolean }>`
	display: flex;
	flex-direction: row;
	margin-top: 32px;
	position: relative;
	z-index: 2;
	${({ isXs, isSm, isNonLogged }) => ({
		...paddingLeft(isXs ? 0 : isSm ? 56 : 72),
		...paddingRight(isXs || isSm ? 0 : 16),
		paddingBottom: isNonLogged ? '0px' : '16px',
		marginBottom: isNonLogged || isXs ? '-32px' : '0px',
	})}
`;

export const Button = styled.div<{ isXs?: boolean }>`
	${({ isXs }) => ({
		width: isXs ? '100%' : '304px',
	})}
	> div {
		width: 100%;
	}
`;

export const VoucherLabelsWrapper = styled.div<{ margin?: string }>`
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 8px;
	${({ margin }) => ({
		margin: margin ? margin : '0',
	})}
`;

export const RemoveItemWrapper = styled(BodySBold)`
	${({ theme }) => ({
		color: theme.colors.primary,
	})}
	margin-top: 8px;
	text-decoration: underline;
	cursor: pointer;
`;

export const VoucherCodeWrapper = styled(BodySBold)`
	border-radius: 4px;
	padding: 8px 12px;
	justify-content: center;
	align-items: center;
	width: fit-content;
	${({ theme }) => ({
		background: theme.colors.primary,
		color: theme.colors.white,
	})}
`;

export const ExpiryDateWrapper = styled(BodyS)`
	border-radius: 4px;
	padding: 8px 12px;
	justify-content: center;
	align-items: center;
	width: fit-content;
	${({ theme }) => ({
		background: theme.colors.warning12,
		color: theme.colors.primary,
	})}
`;

export const VoucherWarningContainer = styled(CaptionBigger)`
	border-radius: 4px;
	margin-top: 16px;
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 8px 16px;
	gap: 16px;
	${({ theme }) => ({
		color: theme.colors.black54,
		backgroundColor: theme.colors.black6,
	})}
	p {
		display: inline;
	}
`;

export const PriceWrapper = styled.div`
	display: flex;
	height: 100%;
	flex-direction: row;
	align-items: center;
	justify-content: flex-end;
`;

export const EmptyLine = styled.div`
	width: 16px;
	height: 2px;
	border-radius: 2px;
	margin-top: 10px;
	${({ theme }) => ({ backgroundColor: theme.colors.primary })}
`;

export const ListItem = styled.li`
	margin: 0;
	padding: 0;
`;

export const HeaderRow = styled(Row)`
	margin-top: 40px;
`;

export const LineDivider = styled.div<{ margin?: string }>`
	${({ theme, margin }) => ({
		borderBottom: '1px solid',
		borderColor: theme.colors.black12,
		margin: margin ? margin : '0px 12px',
	})}
	width: 100%;
`;

export const VoucherRow = styled(Row)<{ margin?: string }>`
	${({ margin }) => ({
		margin: margin ? margin : '0',
	})}
`;

export const VoucherInfoRow = styled.div<{ isXl?: boolean }>`
	display: flex;
	${({ isXl }) => ({
		flexDirection: isXl ? 'row' : 'column',
		marginTop: isXl ? '0' : '8px',
	})}
	gap: 8px;
`;

export const HeaderText = styled(Caption)<{ alignRight?: boolean; alignLeft?: boolean }>`
	${({ theme }) => ({
		color: theme.colors.black54,
		marginBottom: '7px',
	})}
	${({ alignRight }) => alignRight && `text-align: right`}
	${({ alignLeft }) => alignLeft && `text-align: left`}
`;

export const VoucherLimitInfo = styled(BodyS)`
	${({ theme }) => ({
		color: theme.colors.black54,
		marginTop: '10px',
	})}
`;

export const List = styled.ul`
	margin: 0;
	padding: 0 0 0 15px;
`;

export const DescriptionContainer = styled(Row)<{ margin?: string }>`
	${({ margin }) => ({ margin: margin ? margin : '0' })}
`;

export const DescriptionWrapper = styled(ParagraphS)`
	${({ theme }) => ({ color: theme.colors.black87 })}
	margin-bottom: 4px;
`;

export const VerticalDivider = styled.div`
	display: inline-block;
	width: 1px;
	height: 100%;
	border-radius: 2px;
	background-color: ${({ theme }) => theme.colors.black12}};
	`;

export const StatusInfoWrapper = styled.div<{ isXl?: boolean; isXs?: boolean }>`
	display: flex;
	flex-direction: column;
	gap: 8px;
	${({ isXl, isXs }) => ({
		marginBottom: isXl ? '24px' : '0',
		...marginLeft(isXl ? -16 : 28),
		...marginRight(isXl ? 0 : isXs ? 24 : 16),
	})}
`;

export const LogInLabelWrapper = styled(BodyS)`
	margin-bottom: 24px;
`;

export const TextButton = styled(ButtonM)`
	display: inline-block;
	cursor: pointer;
	text-decoration: underline;
	${() => isRTL() && 'padding-left: 8px'};
	${({ theme }) => ({
		textDecorationColor: theme.colors.primary,
		color: theme.colors.primary,
	})}
`;
