import styled from 'styled-components';
import { matchScreen, paddingLeft } from '@Utils/layout/layoutSupport';
import { isRTL } from '@Utils/layout/layoutSupport';
import { ParagraphM } from '@Components/Typography/Typography.styled';
import { MOBILE_NAVBAR_HEIGHT } from '@Layouts/Dashboard/Dashboard';
import { ADDITIONAL_BAR_HEIGHT, ADDITIONAL_SMALL_BAR_HEIGHT } from '@Components/TelemarketerTopBar/TelemarketerTopBar';

export const BackIconWrapper = styled.div`
	${() => paddingLeft(matchScreen('xs') ? 15 : 30)}
`;

export const BackTitleWrapper = styled(ParagraphM)`
	${({ theme }) => ({
		color: theme.colors.primary,
	})}
`;

export const MenuContentWrapper = styled.div<{ open: boolean; telemarketer: boolean | undefined }>`
	${({ open, telemarketer }) => {
		const isXsSmMd = matchScreen(['xs', 'sm', 'md']);
		const transform = isRTL()
			? open
				? 'translate(0%, 0)'
				: 'translate(100%, 0)'
			: open
			? 'translate(0%, 0)'
			: 'translate(-100%, 0)';
		return {
			transform,
			opacity: open ? 1 : 0,
			visibility: open ? 'visible' : 'hidden',
			inset: isRTL() ? 'unset' : 0,
			top: `${
				MOBILE_NAVBAR_HEIGHT +
				(telemarketer ? (isXsSmMd ? ADDITIONAL_SMALL_BAR_HEIGHT : ADDITIONAL_BAR_HEIGHT) : 0)
			}px`,
		};
	}}
	height: fit-content;
	width: 100%;
	position: fixed;
	background-color: white;
	z-index: 202;
`;

export const MenuBackground = styled.div<{ open: boolean; telemarketer: boolean | undefined }>`
	${({ open }) => {
		return {
			opacity: open ? 0.64 : 0,
			visibility: open ? 'visible' : 'hidden',
		};
	}}
	position: fixed;
	background: rgb(0, 0, 0);
	transition: all 300ms ease-in-out;
	pointer-events: auto;
	inset: 0px;
	z-index: 201;
	${({ telemarketer }) => {
		const isXsSmMd = matchScreen(['xs', 'sm', 'md']);
		return {
			top: `${
				MOBILE_NAVBAR_HEIGHT +
				(telemarketer ? (isXsSmMd ? ADDITIONAL_SMALL_BAR_HEIGHT : ADDITIONAL_BAR_HEIGHT) : 0)
			}px`,
		};
	}}
`;

export const MenuSubContent = styled.div<{ open: boolean }>`
	${({ open }) => {
		const transform = isRTL()
			? open
				? 'translate(0%, 0)'
				: 'translate(100%, 0)'
			: open
			? 'translate(0%, 0)'
			: 'translate(-100%, 0)';
		return {
			transform,
			visibility: open ? 'visible' : 'hidden',
		};
	}}
	width: 100%;
	position: fixed;
	top: 88px;
	${() => {
		const isSmallDevice = matchScreen(['xs', 'sm']);
		const rtl = isRTL();
		return isSmallDevice && rtl ? { top: '121px' } : {};
	}}
	height: fit-content;
	z-index: 202;
`;

export const ListElement = styled.div`
	padding-left: 16px;
	padding-right: 16px;
	box-sizing: border-box;
	margin 4px 0;
`;

export const BackButtonWrapper = styled.div`
	cursor: pointer;
	background: #fcf3e6;
	display: flex;
	height: 56px;
	align-items: center;
	justify-content: flex-start;
	gap: 4px;
`;
