import useTranslate from '@Utils/hooks/useTranslate';
import * as Yup from 'yup';

export default (): Yup.AnyObjectSchema => {
	const maxContactNumber = 11;
	const minContactNumber = 8;

	const { translate } = useTranslate();
	return Yup.object().shape({
		phoneNumber: Yup.string()
			.matches(/[0-9]+/g, translate('telesales.error.name.only.numbers'))
			.required(translate('telesales.error.phone.required'))
			.test(
				'len',
				translate('telesales-number-error-less', maxContactNumber),
				(val) => !!val && val.toString().length <= maxContactNumber
			)
			.test(
				'len',
				translate('telesales-number-error-more', minContactNumber),
				(val) => !!val && val.toString().length >= minContactNumber
			),
		email: Yup.string()
			.required(translate('telesales.email.error.required'))
			.email(translate('telesales.email.error.email')),
	});
};
