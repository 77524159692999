import styled from 'styled-components';
import { DROPDOWN_ITEM_HEIGHT } from './DropdownItem';

interface IListItemProps {
	disabled: boolean;
	active: boolean;
}

export const ListItem = styled.div<IListItemProps>`
	padding: 0 16px;
	transition: all 0.1s ease-in-out;
	display: flex;
	justify-content: space-between;
	align-items: center;
	${() => ({ height: DROPDOWN_ITEM_HEIGHT })}
	${({ disabled }) => !disabled && { cursor: 'pointer' }}
	${({ active, theme }) => active && { backgroundColor: `${theme.colors.primary15}!important` }}
	&:hover {
		${({ disabled, theme }) => !disabled && { backgroundColor: theme.colors.primary6 }}
	}
	&:active {
		${({ disabled, theme }) => !disabled && { backgroundColor: theme.colors.primary15 }}
	}

	& .data-container {
		& p {
			display: inline-block;
			&.prefix {
				margin-right: 3px;
			}
		}
	}
`;
