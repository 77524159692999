import React, { FunctionComponent } from 'react';
import useTranslate from '@Utils/hooks/useTranslate';
import { ButtonContent, CardWrapper, EmptyShippment, EmptyShippmentIcons, Order, Orders } from './OrderHistory.styled';
import ConditionalRender from '@Components/ConditionalRender/ConditionalRender';
import { Col, Hidden, Row } from 'react-grid-system';
import Icon from '@Components/Icon/Icon';
import { BodyS, BodySBold, ValueBold } from '@Components/Typography/Typography.styled';
import Divider from '@Components/Divider/Divider';
import { PrimaryLargeButtonFixed } from '@Components/controls/Button/Button';
import { useHistory } from 'react-router-dom';
import OrderItem from './OrderItem/OrderItem';
import BusyIndicator from '@Components/BusyIndicator/BusyIndicator';
import { getLang } from '@Utils/language/language';

export enum OrderHistoryType {
	ORDERS_IN_PROGRESS = 'ORDERS_IN_PROGRESS',
	ORDER_HISTORY = 'ORDER_HISTORY',
	REQUEST_COMPLETED = 'REQUEST_COMPLETED',
	FULFILLMENT_IN_PROGRESS = 'FULFILLMENT_IN_PROGRESS',
	READY_TO_PICKUP = 'READY_TO_PICKUP',
	COLLECTED = 'COLLECTED',
}

export const GET_ORDER_HISTORY_BI = 'GET_ORDER_HISTORY_BI';
const ICON_SIZE = 40;
const ICON_SIZE_EMPTY_STATE = 48;

const OrderHistory: FunctionComponent<types.cards.IOrderHistoryCardProps> = ({
	params: { type, orders },
}): JSX.Element => {
	const { translate } = useTranslate();
	const history = useHistory();

	const goBackToStore = (): void => {
		history.push(`/${getLang()}/store`);
	};

	const availableOrders = (): boolean => {
		if (orders && orders?.filter((o) => o.type === type).length > 0) {
			return true;
		}
		return false;
	};

	return (
		<CardWrapper>
			<Row>
				<Hidden xs sm md>
					<Col xs={1}>
						<ConditionalRender
							show={
								type === OrderHistoryType.ORDERS_IN_PROGRESS ||
								type === OrderHistoryType.FULFILLMENT_IN_PROGRESS
							}
							onTrue={() => <Icon name="shipping" width={ICON_SIZE} height={ICON_SIZE} />}
						/>
						<ConditionalRender
							show={type === OrderHistoryType.ORDER_HISTORY}
							onTrue={() => <Icon name="termsAndConditions" width={ICON_SIZE} height={ICON_SIZE} />}
						/>
					</Col>
				</Hidden>
				<Col>
					<Divider color="transparent" marginBottom={8} />
					<ConditionalRender
						show={
							type === OrderHistoryType.ORDERS_IN_PROGRESS ||
							type === OrderHistoryType.FULFILLMENT_IN_PROGRESS
						}
						onTrue={() => (
							<ValueBold color="black87">{translate('account.management.orders.in.')}</ValueBold>
						)}
					/>
					<ConditionalRender
						show={type === OrderHistoryType.ORDER_HISTORY}
						onTrue={() => (
							<ValueBold color="black87">{translate('account.management.order.history')}</ValueBold>
						)}
					/>
					<BusyIndicator listener={GET_ORDER_HISTORY_BI} skeleton="orderTracking">
						<>
							<Divider color="transparent" marginBottom={44} />
							<ConditionalRender
								show={!availableOrders()}
								onTrue={() => (
									<EmptyShippment>
										<BodySBold color="black38">
											{translate('account.management.orders.no')}
										</BodySBold>
										<Divider color="transparent" marginBottom={8} />
										<BodyS color="black38">
											{translate('account.management.orders.go.to.store')}
										</BodyS>
										<Divider color="transparent" marginBottom={32} />
										<EmptyShippmentIcons>
											<Icon
												name="device"
												width={ICON_SIZE_EMPTY_STATE}
												height={ICON_SIZE_EMPTY_STATE}
											/>
											<Icon
												name="promotion2"
												width={ICON_SIZE_EMPTY_STATE}
												height={ICON_SIZE_EMPTY_STATE}
											/>
											<Icon
												name="headphones"
												width={ICON_SIZE_EMPTY_STATE}
												height={ICON_SIZE_EMPTY_STATE}
											/>
										</EmptyShippmentIcons>
										<Divider color="transparent" marginBottom={32} />
										<ButtonContent>
											<PrimaryLargeButtonFixed
												uniqueId="account.management.orders.go.to.store"
												onClick={goBackToStore}
											>
												{translate('account.management.orders.go.to.store.button')}
											</PrimaryLargeButtonFixed>
										</ButtonContent>
									</EmptyShippment>
								)}
							/>
							<ConditionalRender
								show={!!orders && orders.length > 0}
								onTrue={() => (
									<Orders>
										{orders
											? orders
													.filter((o) => o.type === type)
													.map((item, index) => (
														<Order key={`${item.orderId}`}>
															<OrderItem
																key={`${index}_${item.orderId}`}
																orderId={item.orderId}
																date={item.date}
																item={item.item}
																type={item.type}
																status={item.status}
																isOrderHistoryView={true}
															/>
															<Divider color="transparent" marginBottom={16} />
														</Order>
													))
											: ''}
									</Orders>
								)}
							/>
						</>
					</BusyIndicator>
				</Col>
			</Row>
		</CardWrapper>
	);
};

export default OrderHistory;
