import { IHbbOrderPayload, IWfbbOrderPayload } from '@Services/orderFlow/orderFlow';
import { getLang } from '@Utils/language/language';

export default function (inputObject: IHbbOrderPayload, isWfbb: boolean): IHbbOrderPayload | IWfbbOrderPayload {
	if (isWfbb) {
		return {
			orderId: inputObject.orderId || '',
			operationContext: inputObject.operationContext || '',
			productIds: [inputObject.partNumber || ''],
			gender: inputObject.gender || '',
			dateOfBirth: inputObject.birthDate || '',
			language: inputObject.language || getLang(),
			firstName: inputObject.firstName || '',
			middleName: inputObject.middleName || '',
			lastName: inputObject.customerName || '',
			surname: inputObject.customerName || '',
			fatherName: inputObject.fatherName || '',
			grandfatherName: inputObject.grandfatherName || '',
			regionId: inputObject.region || '',
			email: inputObject.emailId || '',
			contactNumber: inputObject.contactNumber1 || inputObject.contactNumber1 || '',
			countryCode: 'OMN',
			willayatId: inputObject.willyat || '',
			wfbbType: inputObject.wfbbType || '',
			longitude: inputObject.longitude || '',
			latitude: inputObject.latitude || '',
			documentId: inputObject.customerId || '',
			documentType: inputObject.customerIdType || '',
			expiryDate: inputObject.expiryDate || '',
			documentScan: inputObject.documentScan,
		};
	} else {
		return inputObject;
	}
}
