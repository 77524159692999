import React from 'react';
import useTranslate from '@Utils/hooks/useTranslate';
import { LoaderWrapper, InsuranceTitle, InsuranceSubtitle } from './InsuranceOffersLoader.styled';
import Lottie from 'lottie-react';
import animation from '@Assets/animations/carInsurance.json';
const InsuranceOffersLoaderCard = (): JSX.Element => {
	const { translate } = useTranslate();

	return (
		<LoaderWrapper>
			<Lottie animationData={animation} autoPlay loop />
			<InsuranceTitle>{translate('insurance.collecting-data.title')}</InsuranceTitle>
			<InsuranceSubtitle>{translate('insurance.collecting-data.subtitle')}</InsuranceSubtitle>
		</LoaderWrapper>
	);
};

export default InsuranceOffersLoaderCard;
