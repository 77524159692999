import fonts, { IFonts } from '@Theme/fonts';
import normalizeFont from '@Utils/fonts/normalizeFont';
import { DefaultTheme } from 'styled-components';
import { getTypographyLang } from '@Utils/language/language';

const theme: DefaultTheme = {
	colors: {
		primary: 'rgba(255,120,0,1.00)',
		primaryLoading: 'rgba(255,188,128,1.00)',
		primary15: 'rgba(255,228,204,1.00)',
		primary6: 'rgba(255,242,230,1.00)',
		primary24: 'rgba(255,215,179,1.00)',
		primaryGradient: 'rgba(253, 180, 82, 1)',
		primaryHover: 'rgba(255,134,27,1.00)',
		primaryPressed: 'rgba(234,110,0,1.00)',
		secondary: 'rgba(255,188,128,1.00)',
		intSecondaryDefault: 'rgba(255,242,230,1.00)',
		accent: 'rgba(0, 76, 151)',
		accent6: 'rgba(0, 76, 151, 0.06)',
		positive: 'rgba(0, 191, 179)',
		warning: 'rgba(206, 0, 88)',
		warning6: 'rgba(206, 0, 88, 0.06)',
		warning12: 'rgba(250, 181, 113, 0.12)',
		warning100: 'rgba(226, 132, 66, 1)',
		warningLight: 'rgb(233, 0, 145)',
		support1: 'rgba(0, 133, 202)',
		support2: 'rgba(95, 37, 159)',
		support2Gradient: 'rgba(95, 37, 159)',
		support3: 'rgba(90, 90, 90)',
		support4: 'rgba(152, 76, 205)',
		support5: 'rgba(0, 160, 243)',
		grey300: 'rgba(224, 224, 224)',
		grey100: 'rgba(245, 245, 245)',
		grey50: 'rgba(250, 250, 250)',
		grey: 'rgba(128,128,128)',
		background: 'rgba(243, 244, 249)',
		dark: 'rgba(0, 11, 37)',
		dark57: 'rgba(0, 11, 37, 0.57)',
		dark64: 'rgba(0, 11, 37, 0.64)',
		black: 'rgba(0, 0, 0)',
		black87: 'rgba(0, 0, 0, 0.87)',
		black54: 'rgba(0, 0, 0, 0.54)',
		black38: 'rgba(0, 0, 0, 0.38)',
		black24: 'rgba(0, 0, 0, 0.24)',
		black12: 'rgba(0, 0, 0, 0.12)',
		black6: 'rgba(0, 0, 0, 0.06)',
		black4: 'rgba(0, 0, 0, 0.04)',
		black2: 'rgba(0, 0, 0, 0.02)',
		white: 'rgba(255, 255, 255)',
		white70: 'rgba(255, 255, 255, 0.70)',
		white50: 'rgba(255, 255, 255, 0.50)',
		white30: 'rgba(255, 255, 255, 0.30)',
		white24: 'rgba(255, 255, 255, 0.24)',
		white15: 'rgba(255, 255, 255, 0.15)',
		white12: 'rgba(255, 255, 255, 0.12)',
		white6: 'rgba(255, 255, 255, 0.06)',
		transparent: 'rgba(255, 255, 255, 0)',
		inherit: 'inherit',
		violet: '#5F259F',
		green: '#84ae31',
		transparentGreen: 'rgba(132, 174, 49, 0.5)',
		mintGreen: '#f4f8ec',
		bluePrimary: 'rgba(61, 88, 167, 1)',
		theme300: 'rgba(204, 204, 204)',
		orange: '#FF7800',
	},
	typography: {
		discountS: normalizeFont(fonts[getTypographyLang() as keyof IFonts].alvar.regular, 16, 20),
		displayL: normalizeFont(fonts[getTypographyLang() as keyof IFonts].alvar.bold, 48, 56),
		displayMBold: normalizeFont(fonts[getTypographyLang() as keyof IFonts].alvar.bold, 34, 40),
		displayM: normalizeFont(fonts[getTypographyLang() as keyof IFonts].alvar.regular, 34, 40),
		headlineBold: normalizeFont(fonts[getTypographyLang() as keyof IFonts].alvar.bold, 24, 32),
		biggerHeadlineBold: normalizeFont(fonts[getTypographyLang() as keyof IFonts].alvar.bold, 26, 40),
		headlineL: normalizeFont(fonts[getTypographyLang() as keyof IFonts].alvar.regular, 28, 32),
		headline: normalizeFont(fonts[getTypographyLang() as keyof IFonts].alvar.regular, 24, 32),
		header: normalizeFont(fonts[getTypographyLang() as keyof IFonts].alvar.bold, 17, 20),
		titleBold: normalizeFont(fonts[getTypographyLang() as keyof IFonts].alvar.bold, 20, 24),
		title: normalizeFont(fonts[getTypographyLang() as keyof IFonts].alvar.regular, 20, 24),
		titleAlbertBold: normalizeFont(fonts[getTypographyLang() as keyof IFonts].albert.bold, 20, 24),
		valueBold: normalizeFont(fonts[getTypographyLang() as keyof IFonts].albert.bold, 18, 24),
		tabs: normalizeFont(fonts[getTypographyLang() as keyof IFonts].alvar.bold, 18, 20),
		value: normalizeFont(fonts[getTypographyLang() as keyof IFonts].albert.regular, 18, 24),
		buttonL: normalizeFont(fonts[getTypographyLang() as keyof IFonts].alvar.regular, 18, 20),
		bodyMExtraBold: normalizeFont(fonts[getTypographyLang() as keyof IFonts].albert.extraBold, 17, 20),
		paragraphMExtraBold: normalizeFont(fonts[getTypographyLang() as keyof IFonts].albert.extraBold, 17, 24),
		bodyMBold: normalizeFont(fonts[getTypographyLang() as keyof IFonts].albert.bold, 17, 20),
		paragraphMBold: normalizeFont(fonts[getTypographyLang() as keyof IFonts].albert.bold, 17, 24),
		paragraphMPlexBold: normalizeFont(fonts[getTypographyLang() as keyof IFonts].plex.bold, 17, 24),
		bodyM: normalizeFont(fonts[getTypographyLang() as keyof IFonts].albert.regular, 17, 20),
		paragraphM: normalizeFont(fonts[getTypographyLang() as keyof IFonts].albert.regular, 17, 24),
		paragraphMHigher: normalizeFont(fonts[getTypographyLang() as keyof IFonts].albert.regular, 17, 28),
		paragraphMPlex: normalizeFont(fonts[getTypographyLang() as keyof IFonts].plex.regular, 17, 24),
		paragraphL: normalizeFont(fonts[getTypographyLang() as keyof IFonts].albert.regular, 19, 32),
		floatingLabel: normalizeFont(fonts[getTypographyLang() as keyof IFonts].albert.bold, 16, 24, 1.28),
		buttonM: normalizeFont(fonts[getTypographyLang() as keyof IFonts].alvar.regular, 16, 20),
		buttonMBold: normalizeFont(fonts[getTypographyLang() as keyof IFonts].alvar.bold, 16, 20),
		buttonMPlexMedium: normalizeFont(fonts[getTypographyLang() as keyof IFonts].plex.medium, 16, 20),
		bodySBold: normalizeFont(fonts[getTypographyLang() as keyof IFonts].albert.bold, 15, 16),
		bodySBoldHigher: normalizeFont(fonts[getTypographyLang() as keyof IFonts].albert.bold, 15, 20),
		paragraphSBold: normalizeFont(fonts[getTypographyLang() as keyof IFonts].albert.bold, 15, 24),
		bodyS: normalizeFont(fonts[getTypographyLang() as keyof IFonts].albert.regular, 15, 16),
		bodySHigher: normalizeFont(fonts[getTypographyLang() as keyof IFonts].albert.regular, 15, 20),
		bodySPlexMedium: normalizeFont(fonts[getTypographyLang() as keyof IFonts].plex.medium, 15, 20),
		paragraphS: normalizeFont(fonts[getTypographyLang() as keyof IFonts].albert.regular, 15, 24),
		paragraphSPlex: normalizeFont(fonts[getTypographyLang() as keyof IFonts].plex.regular, 15, 24),
		paragraphSPlexBold: normalizeFont(fonts[getTypographyLang() as keyof IFonts].plex.bold, 15, 24),
		paragraphSPlexMedium: normalizeFont(fonts[getTypographyLang() as keyof IFonts].plex.medium, 15, 24),
		subheading: normalizeFont(fonts[getTypographyLang() as keyof IFonts].albert.bold, 14, 16, 1.26),
		captionBold: normalizeFont(fonts[getTypographyLang() as keyof IFonts].albert.bold, 12, 16),
		captionBiggerBold: normalizeFont(fonts[getTypographyLang() as keyof IFonts].albert.bold, 13, 16),
		captionHigher: normalizeFont(fonts[getTypographyLang() as keyof IFonts].albert.regular, 13, 18),
		captionHigherBold: normalizeFont(fonts[getTypographyLang() as keyof IFonts].albert.bold, 13, 18),
		label: normalizeFont(fonts[getTypographyLang() as keyof IFonts].albert.bold, 12, 16, 0.96),
		smallCaption: normalizeFont(fonts[getTypographyLang() as keyof IFonts].albert.regular, 11, 16),
		caption: normalizeFont(fonts[getTypographyLang() as keyof IFonts].albert.regular, 12, 16),
		captionBigger: normalizeFont(fonts[getTypographyLang() as keyof IFonts].albert.regular, 13, 16),
		captionPlexBigger: normalizeFont(fonts[getTypographyLang() as keyof IFonts].plex.regular, 13, 16),
		captionPlexBiggerMedium: normalizeFont(fonts[getTypographyLang() as keyof IFonts].plex.medium, 13, 16),
		captionPlexXs: normalizeFont(fonts[getTypographyLang() as keyof IFonts].plex.regular, 11, 16),
		captionPlexXsMedium: normalizeFont(fonts[getTypographyLang() as keyof IFonts].plex.medium, 11, 16),
		captionXs: normalizeFont(fonts[getTypographyLang() as keyof IFonts].albert.regular, 10, 16),
		smallCurrency: normalizeFont(fonts[getTypographyLang() as keyof IFonts].alvar.bold, 10, 12),
		smallCurrencyVat: normalizeFont(fonts[getTypographyLang() as keyof IFonts].alvar.regular, 10, 12),
		smallCurrencyPlexVat: normalizeFont(fonts[getTypographyLang() as keyof IFonts].plex.regular, 10, 12),
		bigCurrency: normalizeFont(fonts[getTypographyLang() as keyof IFonts].alvar.bold, 12, 20),
		labelSmallPlexMedium: normalizeFont(fonts[getTypographyLang() as keyof IFonts].plex.medium, 10, 16),
		discount: normalizeFont(fonts[getTypographyLang() as keyof IFonts].albert.bold, 10, 16),
		miniUnit: normalizeFont(fonts[getTypographyLang() as keyof IFonts].alvar.bold, 9, 14),
		miniUnitRegular: normalizeFont(fonts[getTypographyLang() as keyof IFonts].alvar.regular, 9, 14),
		counter: normalizeFont(fonts[getTypographyLang() as keyof IFonts].alvar.bold, 8, 12),
		smallDuration: normalizeFont(fonts[getTypographyLang() as keyof IFonts].albert.regular, 10, 10),
		miniDurationBold: normalizeFont(fonts[getTypographyLang() as keyof IFonts].albert.bold, 9, 8),
		miniDuration: normalizeFont(fonts[getTypographyLang() as keyof IFonts].albert.regular, 8, 8),
		info: normalizeFont(fonts[getTypographyLang() as keyof IFonts].albert.regular, 10, 12),
		infoBold: normalizeFont(fonts[getTypographyLang() as keyof IFonts].albert.bold, 10, 12),
		miniCurrency: normalizeFont(fonts[getTypographyLang() as keyof IFonts].alvar.regular, 17, 20),
		miniDescription: normalizeFont(fonts[getTypographyLang() as keyof IFonts].alvar.regular, 13, 16),
		digit: normalizeFont(fonts[getTypographyLang() as keyof IFonts].alvar.bold, 25, 24),
		digitSM: normalizeFont(fonts[getTypographyLang() as keyof IFonts].alvar.bold, 20, 23),
		digitXS: normalizeFont(fonts[getTypographyLang() as keyof IFonts].alvar.bold, 14, 22),
		digitXsSm: normalizeFont(fonts[getTypographyLang() as keyof IFonts].albert.regular, 14, 20),
		digitXsSmPlexMedium: normalizeFont(fonts[getTypographyLang() as keyof IFonts].plex.medium, 14, 20),
		counterDescription: normalizeFont(fonts[getTypographyLang() as keyof IFonts].albert.bold, 12, 12, 1.08),
		comparisonCurrency: normalizeFont(fonts[getTypographyLang() as keyof IFonts].alvar.bold, 13, 20),
		status: normalizeFont(fonts[getTypographyLang() as keyof IFonts].albert.regular, 20, 32),
		statusBold: normalizeFont(fonts[getTypographyLang() as keyof IFonts].albert.bold, 20, 32),
		headlineS: normalizeFont(fonts[getTypographyLang() as keyof IFonts].plex.regular, 14, 20),
		bodyXLBold: normalizeFont(fonts[getTypographyLang() as keyof IFonts].albert.bold, 72, 80),
		bodyBoldL: normalizeFont(fonts[getTypographyLang() as keyof IFonts].alvar.bold, 19, 28),
		bodyLBoldPP: normalizeFont(fonts[getTypographyLang() as keyof IFonts].albert.bold, 19, 28),
		bodyL: normalizeFont(fonts[getTypographyLang() as keyof IFonts].albert.regular, 19, 28),
		bodyLBold: normalizeFont(fonts[getTypographyLang() as keyof IFonts].albert.bold, 56, 64),
		bodyLPlexMedium: normalizeFont(fonts[getTypographyLang() as keyof IFonts].plex.bold, 19, 28),
		bodySemiLBold: normalizeFont(fonts[getTypographyLang() as keyof IFonts].albert.bold, 40, 48),
		description: normalizeFont(fonts[getTypographyLang() as keyof IFonts].albert.regular, 20, 24),
		paragraphLPlexBold: normalizeFont(fonts[getTypographyLang() as keyof IFonts].plex.bold, 19, 28),
		paragraphLPlex: normalizeFont(fonts[getTypographyLang() as keyof IFonts].plex.regular, 19, 32),
		paragraphLPlexMedium: normalizeFont(fonts[getTypographyLang() as keyof IFonts].plex.medium, 19, 32),
		nonLoggedParagraphPlexMedium: normalizeFont(fonts[getTypographyLang() as keyof IFonts].plex.medium, 19, 36),
		bodySPlex: normalizeFont(fonts[getTypographyLang() as keyof IFonts].plex.regular, 15, 16),
		bodySPlexSmallerMedium: normalizeFont(fonts[getTypographyLang() as keyof IFonts].plex.medium, 15, 16),
		bodySPlexLight: normalizeFont(fonts[getTypographyLang() as keyof IFonts].plex.regular, 15, 20),
		bodySPlexLightBold: normalizeFont(fonts[getTypographyLang() as keyof IFonts].plex.bold, 15, 20),
		headlinePlexBold: normalizeFont(fonts[getTypographyLang() as keyof IFonts].plex.bold, 26, 40),
		labelPlex: normalizeFont(fonts[getTypographyLang() as keyof IFonts].plex.regular, 12, 16),
		labelPlexMedium: normalizeFont(fonts[getTypographyLang() as keyof IFonts].plex.medium, 12, 16),
		titlePlex: normalizeFont(fonts[getTypographyLang() as keyof IFonts].plex.bold, 17, 36),
	},
};

export default theme;
