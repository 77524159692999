export interface IMSKey {
	msDesc: string;
	name: string;
	activateKey: string;
}

export interface IMSKeysResponse {
	returnValue: IMSKey[];
}

const model: types.models.IInputModel = {
	returnValue: 'response.items',
};

export default model;
