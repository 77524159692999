import React, { FunctionComponent, useCallback } from 'react';
import Card from '@Components/Card/Card';
import useScreen from '@Utils/hooks/useScreen';
import { ModalContainer } from '@Components/Modal/subComponents/ModalContainer/ModalContiner.styled';
import { useSelector } from 'react-redux';

const ModalOutletAvailable: FunctionComponent<types.cards.IOutletAvailableCardProps['params']> = ({
	productDetails,
	chosenColor,
	chosenCapacity,
	outletsInformation,
}) => {
	const { screenClass } = useScreen();
	const outletsFocusOpen = useSelector((state: types.redux.IState) => state.configurator.outlets);

	const isFocusOnOpenModal = useCallback(() => {
		if (outletsFocusOpen?.find((item) => item.isOpen === true)) {
			return true;
		} else {
			return false;
		}
	}, [outletsFocusOpen]);

	const fixedWidth = () => {
		if (screenClass.includes('xs')) {
			return '288px';
		} else if (screenClass.includes('sm')) {
			return '516px';
		} else if (screenClass.includes('md')) {
			return '656px';
		} else if (screenClass.includes('lg')) {
			return '930px';
		} else {
			return '930px';
		}
	};

	const fixedHeight = () => {
		if (screenClass.includes('xs')) {
			return isFocusOnOpenModal() ? '513px' : '389px';
		} else if (screenClass.includes('sm')) {
			return isFocusOnOpenModal() ? '600px' : '417px';
		} else if (screenClass.includes('md')) {
			return isFocusOnOpenModal() ? '600px' : '461px;';
		} else if (screenClass.includes('lg')) {
			return '560px';
		} else {
			return '560px';
		}
	};

	return (
		<ModalContainer
			styles={{
				width: fixedWidth(),
				height: fixedHeight(),
				position: 'absolute',
				left: '50%',
				top: '50%',
				transform: 'translate(-50%, -50%)',
				zIndex: 5,
			}}
		>
			<Card<types.cards.IOutletAvailableCardProps>
				params={{ productDetails, chosenColor, chosenCapacity, outletsInformation }}
				card="outletAvailable"
				withoutContainer
			/>
		</ModalContainer>
	);
};

export default ModalOutletAvailable;
