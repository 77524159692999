import React, { FunctionComponent } from 'react';
import { TimeRangeItemWrapper, TimeOfDay, Hours, LabelsWrapper } from './TimeRangeItem.styled';

export interface ITimeRangeItemProps {
	state: string;
	selected: boolean;
}

export interface ITimeRangeItemExtendedProps extends ITimeRangeItemProps {
	slotId: number;
	timeOfDayLabel: string;
	hoursRangelabel: string;
	onClick: (timeRange: number) => void;
}

const TiemRangeItem: FunctionComponent<ITimeRangeItemExtendedProps> = ({
	slotId,
	state,
	selected,
	timeOfDayLabel,
	hoursRangelabel,
	onClick,
}) => {
	return (
		<TimeRangeItemWrapper selected={selected} state={state} onClick={() => onClick(slotId)}>
			<LabelsWrapper>
				<TimeOfDay selected={selected} state={state}>
					{timeOfDayLabel}
				</TimeOfDay>
				<Hours selected={selected} state={state}>
					{hoursRangelabel}
				</Hours>
			</LabelsWrapper>
		</TimeRangeItemWrapper>
	);
};

export default TiemRangeItem;
