import React, { FunctionComponent, useState } from 'react';
import {
	CancelOrderWrapper,
	CloseIconWrapper,
	TitleWrapper,
	CancelWrapper,
	IconWrapper,
	TitleLabelWrapper,
	ButtonsWrapper,
	SmallerLabelWrapper,
	CancelOrderButtonContainer,
	DismissButtonContainer,
	CustomModalContainer,
	LoaderWrapper,
} from './CancelOrder.styled';
import Icon from '@Components/Icon/Icon';
import Divider from '@Components/Divider/Divider';
import { setModal } from '@Redux/modules/modal/actions';
import { useDispatch, useSelector } from 'react-redux';
import useTranslate from '@Utils/hooks/useTranslate';
import { PrimaryMediumButtonFlex, TertiaryMediumButtonFlex } from '@Components/controls/Button/Button';
import { OrderFlowService } from '@Services/orderFlow/orderFlow';
import { updateOrderHistory } from '@Redux/modules/orderHistory/actions';
import { useHistory } from 'react-router-dom';
import { getLang } from '@Utils/language/language';
import useScreen from '@Utils/hooks/useScreen';
import ConditionalRender from '@Components/ConditionalRender/ConditionalRender';
import { PrimaryCircularLoader } from '@Components/CircularLoader/CircularLoader';
import { CUSTOMER_CARE_PHONE, CUSTOMER_B2B2C_CARE_PHONE } from '@Config/app.config';

export interface ICancelOrder {
	params: {
		data: string;
	};
}

const ErrorCodes = ['949997'];

enum CancelModalType {
	CANCEL_ORDER = 'CANCEL_ORDER',
	SUCCESS = 'SUCCESS',
	ERROR = 'ERROR',
	GENERIC_ERROR = 'GENERIC_ERROR',
	LOADER = 'LOADER',
}

const IconSize = 96;

const ModalCancelOrder: FunctionComponent<ICancelOrder['params']> = ({ data }) => {
	const dispatch = useDispatch();
	const [modalState, setModalState] = useState<CancelModalType>(CancelModalType.CANCEL_ORDER);
	const { translate } = useTranslate();
	const orderUpdated = useSelector((state: types.redux.IState) => state.orderHistory?.orderUpdated);
	const history = useHistory();
	const { screen } = useScreen();
	const isB2b = useSelector((state: types.redux.IState) => state.api.authentication.isB2b);
	const isB2b2c = useSelector((state: types.redux.IState) => state.api.authentication.isB2b2c);

	const handleClose = () => {
		dispatch(
			setModal({
				type: null,
				data: null,
			})
		);
		setModalState(CancelModalType.CANCEL_ORDER);
	};

	const cancelOrder = () => {
		setModalState(CancelModalType.LOADER);
		OrderFlowService.cancelOrder({ orderId: data }).subscribe(
			(r) => {
				OrderFlowService.makePaymentRefund({ orderId: data }).subscribe(
					(response) => {
						OrderFlowService.refundPaymentLog(
							{
								success: true,
								refundResponse: !!response ? JSON.stringify(response, null, 2) : 'success',
								refundPayload: data,
							},
							{ orderId: data }
						).subscribe(),
							dispatch(updateOrderHistory(!orderUpdated));
						history.push(`/${getLang()}/account/orders/history`);
						setModalState(CancelModalType.SUCCESS);
					},
					(response) => {
						OrderFlowService.refundPaymentLog(
							{
								success: false,
								refundResponse: !!response ? JSON.stringify(response, null, 2) : 'success',
								refundPayload: data,
							},
							{ orderId: data }
						).subscribe(),
							dispatch(updateOrderHistory(!orderUpdated));
						setModalState(CancelModalType.ERROR);
					}
				);
			},
			(r) => {
				if (ErrorCodes.includes(r.response.errors[0].errorCode)) {
					setModalState(CancelModalType.ERROR);
				} else {
					setModalState(CancelModalType.GENERIC_ERROR);
				}
			}
		);
	};

	const content = (step: string) => {
		if (step === CancelModalType.CANCEL_ORDER) {
			return (
				<CancelWrapper>
					<IconWrapper>
						<Icon name="warning" fill="primary" width={IconSize} height={IconSize} />
					</IconWrapper>
					<TitleLabelWrapper>{translate('cancel-order.modal.first-step.title')}</TitleLabelWrapper>
					<SmallerLabelWrapper isXs={screen('xs')}>
						{translate('cancel-order.modal.first-step.subtitle')}
					</SmallerLabelWrapper>
					<ButtonsWrapper isXs={screen('xs')}>
						<DismissButtonContainer>
							<TertiaryMediumButtonFlex
								uniqueId="do.not.cancel.order.button"
								onClick={() => handleClose()}
							>
								{translate('cancel-order.modal.first-step.button-back')}
							</TertiaryMediumButtonFlex>
						</DismissButtonContainer>
						<CancelOrderButtonContainer>
							<PrimaryMediumButtonFlex uniqueId="cancel.my.order.button" onClick={() => cancelOrder()}>
								{translate('cancel-order.modal.first-step.button-cancel')}
							</PrimaryMediumButtonFlex>
						</CancelOrderButtonContainer>
					</ButtonsWrapper>
				</CancelWrapper>
			);
		} else if (step === CancelModalType.SUCCESS) {
			return (
				<CancelWrapper>
					<IconWrapper>
						<Icon name="success" width={IconSize} height={IconSize} />
					</IconWrapper>
					<TitleLabelWrapper>{translate('cancel-order.modal.success.title')}</TitleLabelWrapper>
					<SmallerLabelWrapper isXs={screen('xs')}>
						{translate('cancel-order.modal.success.subtitle')}
					</SmallerLabelWrapper>
				</CancelWrapper>
			);
		} else if (step === CancelModalType.ERROR) {
			return (
				<CancelWrapper>
					<IconWrapper>
						<Icon name="error" fill="warning" width={IconSize} height={IconSize} />
					</IconWrapper>
					<TitleLabelWrapper>{translate('cancel-order.modal.error.title')}</TitleLabelWrapper>
					<SmallerLabelWrapper isXs={screen('xs')}>
						{translate('cancel-order.modal.error.subtitle')}
					</SmallerLabelWrapper>
				</CancelWrapper>
			);
		} else if (step === CancelModalType.GENERIC_ERROR) {
			return (
				<CancelWrapper>
					<IconWrapper>
						<Icon name="error" fill="warning" width={IconSize} height={IconSize} />
					</IconWrapper>
					<TitleLabelWrapper>{translate('cancel-order.modal.generic-error.title')}</TitleLabelWrapper>
					<SmallerLabelWrapper isXs={screen('xs')}>
						{translate(
							'cancel-order.modal.generic-error.subtitle',
							isB2b2c || isB2b ? CUSTOMER_B2B2C_CARE_PHONE : CUSTOMER_CARE_PHONE
						)}
					</SmallerLabelWrapper>
				</CancelWrapper>
			);
		} else if (step === CancelModalType.LOADER) {
			return (
				<CancelWrapper>
					<IconWrapper>
						<Icon name="waiting" fill="black54" width={IconSize} height={IconSize} />
					</IconWrapper>
					<TitleLabelWrapper>{translate('cancel-order.modal.loading.title')}</TitleLabelWrapper>
					<SmallerLabelWrapper isXs={screen('xs')}>
						{translate('cancel-order.modal.loading.subtitle')}
					</SmallerLabelWrapper>
					<LoaderWrapper>
						<PrimaryCircularLoader />
					</LoaderWrapper>
				</CancelWrapper>
			);
		}
	};

	return (
		<CustomModalContainer isXs={screen('xs')} isSm={screen('sm')}>
			<CancelOrderWrapper>
				<ConditionalRender
					show={modalState !== CancelModalType.LOADER}
					onTrue={() => (
						<>
							<TitleWrapper>
								<CloseIconWrapper onClick={handleClose}>
									<Icon name="close" fill="black" width={24} height={24} />
								</CloseIconWrapper>
							</TitleWrapper>
							<Divider />
						</>
					)}
				/>
				{content(modalState)}
			</CancelOrderWrapper>
		</CustomModalContainer>
	);
};

export default ModalCancelOrder;
