import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addBusyIndicator, removeBusyIndicator } from '@Redux/modules/busyIndicator/actions';
import { CategoriesService } from '@Services/categories/categories';
import { IContent } from '@ApiModels/flatCategories';
import useTranslate from '@Utils/hooks/useTranslate';
import { updateBreadcrumb } from '@Redux/modules/breadcrumbs/actions';
import { GET_CATEGORIES_BI } from '@Views/Store/Store';
import { getLang } from '@Utils/language/language';

interface IUseBreadcrumbs {
	updateBreadcrumbs: (items: types.redux.breadcrumbs.IBreadcrumb[]) => void;
	getBreadcrumbsFromCategory: (path: string[] | string) => types.redux.breadcrumbs.IBreadcrumb[];
	getProductCategory: (path: string[] | string | undefined) => string;
}

const useBreadcrumbs = (): IUseBreadcrumbs => {
	const [categories, setCategories] = useState<IContent | null>(null);
	const language = useSelector((state: types.redux.IState) => state.settings.language);
	const dispatch = useDispatch();
	const { translate } = useTranslate();

	useEffect(() => {
		dispatch(addBusyIndicator(GET_CATEGORIES_BI));
		CategoriesService.getFlatCategories().subscribe(
			(r) => {
				setCategories(r.contents);
				dispatch(removeBusyIndicator(GET_CATEGORIES_BI));
			},
			() => {
				dispatch(removeBusyIndicator(GET_CATEGORIES_BI));
			}
		);
	}, [language]);

	const updateBreadcrumbs = (items: types.redux.breadcrumbs.IBreadcrumb[]) => {
		dispatch(updateBreadcrumb(items));
	};

	const getBreadcrumbsFromCategory = (path: string[] | string): types.redux.breadcrumbs.IBreadcrumb[] => {
		const pathArray: string[] = Array.isArray(path) ? path : path.split('_').filter((part) => !!part);

		return pathArray.map((item) => {
			const tempCat = categories?.children?.find((category) => category.id === item);
			if (!tempCat) {
				return {
					link: `/${getLang()}/store`,
					label: translate('breadcrumbs.store'),
				};
			}
			return {
				link: `/${getLang()}/store/${tempCat?.identifier}`,
				label: tempCat?.name,
			};
		});
	};

	const getProductCategory = (parentCatalogGroupID: string[] | string | undefined) => {
		if (parentCatalogGroupID) {
			let _parentCatalogGroupID: string = '';
			if (Array.isArray(parentCatalogGroupID) && parentCatalogGroupID.length >= 2) {
				_parentCatalogGroupID = parentCatalogGroupID[parentCatalogGroupID.length - 2];
			} else if (Array.isArray(parentCatalogGroupID) && parentCatalogGroupID.length === 1) {
				_parentCatalogGroupID = parentCatalogGroupID[0];
			} else if (typeof parentCatalogGroupID === 'string') {
				const parentCategories = parentCatalogGroupID.split('/');
				_parentCatalogGroupID = parentCategories[parentCategories.length - 2];
			}
			const productCategory = categories?.children.find((category) => category.id === _parentCatalogGroupID);
			return productCategory?.name ? productCategory.name.toUpperCase() : '';
		} else {
			return '';
		}
	};

	return {
		updateBreadcrumbs,
		getBreadcrumbsFromCategory,
		getProductCategory,
	};
};

export default useBreadcrumbs;
