import ConditionalRender from '@Components/ConditionalRender/ConditionalRender';
import { SecondaryMediumButtonFixed, TertiaryMediumButtonAuto } from '@Components/controls/Button/Button';
import Divider from '@Components/Divider/Divider';
import Icon from '@Components/Icon/Icon';
import { MiniDescription, TitleBold } from '@Components/Typography/Typography.styled';
import useTranslate from '@Utils/hooks/useTranslate';
import React, { FunctionComponent, useRef, useState } from 'react';
import { DragAndDropZone, ErrorMessage, Loader, SubTitleButton } from './DragAndDrop.styled';

export interface IFileParams {
	base64File: string;
	lastModified: number;
	lastModifiedDate?: string;
	name: string;
	size: number;
	type: string;
	webkitRelativePath?: string;
}

export interface IDragAndDropProps {
	onFileSave: (params: IFileParams) => void;
	onFileDelete: () => void;
	selectedFileInfo?: IFileParams | null;
	icon?: types.iconNames;
	iconOnHover?: types.iconNames;
	name?: string;
	maxFileSizeKb?: number;
}

export enum FileTypes {
	'application/pdf' = 'application/pdf',
	'image/jpeg' = 'image/jpeg',
	'image/png' = 'image/png',
}

const ICON_SIZE = 80;

const DragAndDrop: FunctionComponent<IDragAndDropProps> = ({
	onFileSave,
	onFileDelete,
	icon = 'folderClosed',
	iconOnHover = 'folderOpen',
	name,
	selectedFileInfo,
	maxFileSizeKb = 1024,
}) => {
	const [mouseIn, setMouseIn] = useState<boolean>();
	const [error, setError] = useState<string>();
	const { translate } = useTranslate();
	const inputRef = useRef<HTMLInputElement>(null);
	const fileSizeBytes = maxFileSizeKb * 1000;

	const handleMouseOver = (e: React.DragEvent<HTMLDivElement>) => {
		e.preventDefault();
		e.stopPropagation();
		setMouseIn(true);
	};

	const handleMouseLeave = (e: React.DragEvent<HTMLDivElement>) => {
		e.preventDefault();
		e.stopPropagation();
		setMouseIn(false);
	};

	const handleOnDrop = (e: React.DragEvent<HTMLDivElement>) => {
		e.preventDefault();
		e.stopPropagation();
		setMouseIn(false);
		setError('');

		const selectedFile = e?.dataTransfer?.files[0];
		const { lastModified, name, size, type } = selectedFile;
		if (Object.values(FileTypes).includes(type as any)) {
			if (size < fileSizeBytes) {
				const fileReader = new FileReader();
				fileReader.onload = function (fileLoadedEvent) {
					const base64File = fileLoadedEvent?.target?.result;
					onFileSave?.({
						base64File: (base64File || '')?.toString(),
						lastModified,
						name,
						size,
						type,
					});
				};
				fileReader.readAsDataURL(selectedFile);
			} else {
				setError(translate('drag.drop.file.too.big'));
			}
		} else {
			setError(translate('drag.drop.file.not.supported'));
		}
	};

	const handleButtonAdd = (e: React.DragEvent<HTMLDivElement>) => {
		inputRef?.current?.click();
	};

	const hendleInputOnChange = (e: { target: { files: any } }) => {
		const selectedFile = e?.target?.files[0];
		const { lastModified, lastModifiedDate, name, size, type, webkitRelativePath } = selectedFile;
		if (Object.values(FileTypes).includes(type)) {
			if (size < fileSizeBytes) {
				const fileReader = new FileReader();
				fileReader.onload = function (fileLoadedEvent) {
					const base64File = fileLoadedEvent?.target?.result;
					onFileSave?.({
						base64File: (base64File || '')?.toString(),
						lastModified,
						lastModifiedDate,
						name,
						size,
						type,
						webkitRelativePath,
					});
				};
				fileReader.readAsDataURL(selectedFile);
			} else {
				setError(translate('drag.drop.file.too.big'));
			}
		} else {
			setError(translate('drag.drop.file.not.supported'));
		}
	};

	const handlePreviewFile = () => {
		const newTab = window.open();
		const docTemplate = `<!DOCTYPE html><html><head><title>${
			selectedFileInfo?.name || 'Document preview'
		}</title></head><body><img src="${selectedFileInfo?.base64File}" height="100%"  ></body></html>`;

		switch (selectedFileInfo?.type) {
			case FileTypes['application/pdf']:
				newTab?.document.write(
					`<!DOCTYPE html><html><head><title>${
						selectedFileInfo?.name || 'Document preview'
					}</title></head><body styles="height:100%;"><iframe width='100%' height='1200px' src='
						${encodeURI(selectedFileInfo.base64File)}'></iframe></body></html>`
				);
				break;
			case FileTypes['image/jpeg']:
				newTab?.document.write(docTemplate);
				newTab?.document.close();
				break;
			case FileTypes['image/png']:
				newTab?.document.write(docTemplate);
				newTab?.document.close();
				break;
			default:
				console.error('Unsupported file type!');
				break;
		}
	};

	const handleDeleteFile = () => {
		onFileDelete?.();
		if (inputRef && inputRef.current && inputRef.current.value) {
			inputRef.current.value = '';
		}
	};

	const handleTryAgain = () => {
		setError('');
		if (inputRef && inputRef.current && inputRef.current.value) {
			inputRef.current.value = '';
		}
	};

	return (
		<DragAndDropZone
			onDragOver={handleMouseOver}
			onDragLeave={handleMouseLeave}
			onDrop={handleOnDrop}
			mouseIn={!!mouseIn}
		>
			<input type="file" id="file" ref={inputRef} onChange={hendleInputOnChange} style={{ display: 'none' }} />
			<TitleBold color="black54">{name || translate('drag.drop.document.side')}</TitleBold>
			<Divider withoutLine marginBottom={16} />
			<Icon
				name={!!error ? 'error' : selectedFileInfo?.name ? 'success' : mouseIn ? iconOnHover : icon}
				width={ICON_SIZE}
				height={ICON_SIZE}
				fill={error ? 'warning' : undefined}
			/>
			<Divider withoutLine marginBottom={16} />
			<ConditionalRender
				show={!!error}
				onTrue={() => (
					<>
						<ErrorMessage>{error}</ErrorMessage>
						<Divider withoutLine marginBottom={16} />
						<TertiaryMediumButtonAuto uniqueId="tryAgainUploadFile" onClick={handleTryAgain}>
							{translate('drag.drop.try.again')}
						</TertiaryMediumButtonAuto>
					</>
				)}
				onFalse={() => (
					<ConditionalRender
						show={!!selectedFileInfo}
						onTrue={() => (
							<>
								<MiniDescription>{selectedFileInfo?.name}</MiniDescription>
								<Loader />
								<div>
									<TertiaryMediumButtonAuto uniqueId="previewFile" onClick={handlePreviewFile}>
										{translate('drag.drop.preview')}
									</TertiaryMediumButtonAuto>
									<TertiaryMediumButtonAuto uniqueId="deleteFile" onClick={handleDeleteFile}>
										{translate('drag.drop.delete')}
									</TertiaryMediumButtonAuto>
								</div>
							</>
						)}
						onFalse={() => (
							<>
								<SubTitleButton>{translate('drag.drop.document.drop.file')}</SubTitleButton>
								<Divider withoutLine marginBottom={16} />
								<SecondaryMediumButtonFixed
									underlayColor="background"
									uniqueId="addFile"
									onClick={handleButtonAdd}
								>
									{translate('drag.drop.document.browse.file')}
								</SecondaryMediumButtonFixed>
							</>
						)}
					/>
				)}
			/>
		</DragAndDropZone>
	);
};
export default DragAndDrop;
