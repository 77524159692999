import { BodyM } from '@Components/Typography/Typography.styled';
import useTranslate from '@Utils/hooks/useTranslate';
import React, { FunctionComponent } from 'react';
import {
	VatMessageBarCircle,
	VatMessageBarPercent,
	VatMessageBarValue,
	VatMessageBarWrapper,
} from './PriceWithVat.styled';

interface IVatMessageBar {
	vat: number;
}

const VatMessageBar: FunctionComponent<IVatMessageBar> = ({ vat }) => {
	const { translate } = useTranslate();
	return vat ? (
		<VatMessageBarWrapper>
			<VatMessageBarCircle>
				<VatMessageBarValue>{translate('message.box.vat.percent', String(vat))}</VatMessageBarValue>
				<VatMessageBarPercent>{translate('message.box.vat.percent.vat')}</VatMessageBarPercent>
			</VatMessageBarCircle>
			<BodyM color="black54">{translate('message.box.vat', String(vat))}</BodyM>
		</VatMessageBarWrapper>
	) : null;
};

export default VatMessageBar;
