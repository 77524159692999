import { Action, ActionFunction1, createAction } from 'redux-actions';

export interface IUpdateBreadcrumbsAction {
	payload: types.redux.breadcrumbs.IBreadcrumb[];
}

export const updateBreadcrumb: ActionFunction1<
	IUpdateBreadcrumbsAction['payload'],
	Action<IUpdateBreadcrumbsAction['payload']>
> = createAction('UPDATE_BREADCRUMBS', (action: IUpdateBreadcrumbsAction['payload']) => action);
