import React, { FunctionComponent } from 'react';
import Icon from '@Components/Icon/Icon';
import { ViewChangeButtonContainer, ViewChangeContainer, Counter } from './ViewFiltersButton.styled';
import useScreen from '@Utils/hooks/useScreen';
import ConditionalRender from '@Components/ConditionalRender/ConditionalRender';
import useTranslate from '@Utils/hooks/useTranslate';

const ICON_SIZE = 24;
interface IViewFiltersButton {
	open: boolean;
	onFilterClick: (open: boolean) => void;
	counter: number;
}

const ViewFiltersButton: FunctionComponent<IViewFiltersButton> = ({ open, onFilterClick, counter }) => {
	const { screen } = useScreen();
	const { translate } = useTranslate();
	const active = !!counter;

	const handleViewChange = () => {
		onFilterClick(!open);
	};

	return (
		<ConditionalRender
			show={screen(['xs', 'sm', 'md'])}
			onTrue={() => (
				<ViewChangeContainer>
					<ViewChangeButtonContainer onClick={handleViewChange}>
						<div style={{ display: 'flex', alignItems: 'center' }}>
							<Icon
								name={active ? 'filterActive' : 'filter'}
								width={ICON_SIZE}
								height={ICON_SIZE}
								fill={active ? 'primary' : 'black54'}
							/>
							<ConditionalRender
								show={!screen(['xs'])}
								onTrue={() => (
									<Counter color={active ? 'primary' : 'black54'}>
										{counter
											? translate('view.filters.button.counter', counter)
											: translate('view.filters.button.filter')}
									</Counter>
								)}
							/>
						</div>
					</ViewChangeButtonContainer>
				</ViewChangeContainer>
			)}
		/>
	);
};

export default ViewFiltersButton;
