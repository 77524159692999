export function maskProfileLogin(login: string) {
	const loginLength = login.length;
	if (loginLength >= 5) {
		const mask = login
			.split('')
			.map((char, index) => {
				if (index >= loginLength - 3) {
					return char;
				} else {
					return '*';
				}
			})
			.join('');
		return mask;
	} else {
		const mask = login
			.split('')
			.map(() => '*')
			.join('');
		return mask;
	}
}
