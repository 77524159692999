import styled from 'styled-components';
import { IToggleAdditionalProps } from './Toggle';

interface ISliderProps {
	active: boolean;
	height: number;
	disabled?: boolean;
}

interface IDotProps extends IToggleAdditionalProps {
	active: boolean;
}

interface IWrapperProps extends IToggleAdditionalProps {
	active: boolean;
	disabled?: boolean;
}

export const Input = styled.input<{ pointerDisabled?: boolean }>`
	width: 100%;
	height: 100%;
	margin: 0;
	opacity: 0;
	${({ pointerDisabled }) => ({
		cursor: pointerDisabled ? 'unset' : 'pointer',
	})}
	position: absolute;
	left: 0;
	top: 0;
	z-index: 2;
`;

export const Wrapper = styled.div<IWrapperProps>`
	display: inline-block;
	border: solid 1px ${({ theme }) => theme.colors.black12};
	transition: all 0.1s ease-in-out;
	${({ width, height }) => ({
		width,
		height,
		borderRadius: height / 2,
	})}
	${({ disabled, active, theme }) => {
		if (disabled) {
			return active
				? { backgroundColor: theme.colors.black6, borderColor: 'transparent' }
				: { backgroundColor: theme.colors.black6, borderColor: theme.colors.black24 };
		}
		return active
			? { borderColor: 'transparent', backgroundColor: theme.colors.primary }
			: { backgroundColor: theme.colors.white };
	}}
`;

export const Slider = styled.div<ISliderProps>`
	display: inline-block;
	width: 100%;
	height: 100%;
	position: relative;
	transition: all 0.1s ease-in-out;
	${({ height }) => ({
		borderRadius: height / 2,
	})}
	&:hover {
		${({ active, disabled, theme }) =>
			!disabled &&
			(active ? { backgroundColor: theme.colors.white15 } : { backgroundColor: theme.colors.primary6 })}
	}
	&:active {
		${({ active, disabled, theme }) =>
			!disabled &&
			(active ? { backgroundColor: theme.colors.white30 } : { backgroundColor: theme.colors.primary15 })}
	}
`;

export const Dot = styled.div<IDotProps>`
	display: inline-block;
	background-color: ${({ theme }) => {
		return theme.colors.white;
	}};
	position: absolute;
	top: 1px;
	left: 1px;
	transition: all 0.1s ease-in-out;
	z-index: 1;
	transform: translateX(0px);
	box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.08),
		0 0 2px 0
			${({ theme }) => {
				return theme.colors.black12;
			}},
		0 0 2px 0 rgba(0, 0, 0, 0.04);
	${({ active, width, height }) => active && { transform: `translateX(${width - height}px)` }}
	${({ height }) => ({
		height: height - 2,
		width: height - 2,
		borderRadius: (height - 2) / 2,
	})}
`;
