export default {
	flatCategories: require('./flatCategories'),
	guestLogIn: require('./guestLogIn'),
	nestedCategories: require('./nestedCategories'),
	productsByPartNumber: require('./productsByPartNumber'),
	productsByCategoryId: require('./productsByCategoryId'),
	productById: require('./productById'),
	relatedProductsById: require('./relatedProductsById'),
	productIdBySeo: require('./productIdBySeo'),
	popularProducts: require('./popularProducts'),
	inventoryBySKUId: require('./inventoryBySKUId'),
	inventoryByProductId: require('./inventoryByProductId'),
	inventoryByPartNumber: require('./inventoryByPartNumber'),
	typeaheadProductsBySearchTerm: require('./typeaheadProductsBySearchTerm'),
	accountsForLoggedUser: require('./accounts'),
	viewCart: require('./viewCart'),
	authentication: require('./authentication'),
	userProfile: require('./userProfile'),
	ebuUserProfile: require('./ebuUserProfile'),
	paymentTokens: require('./paymentTokens'),
	packages: require('./packages'),
	singlePackage: require('./singlePackage'),
	recommendedPlan: require('./recommendedPlan'),
	orderHistory: require('./orderHistory'),
	orderDetails: require('./orderDetails'),
	customerProducts: require('./customerProducts'),
	commerceProfile: require('./commerceProfile'),
	validateOffer: require('./validateOffer'),
	preCheckout: require('./preCheckout'),
	checkout: require('./checkout'),
	chooseShipment: require('./chooseShipment'),
	choosePaymentMethod: require('./choosePaymentMethod'),
	processOrder: require('./processOrder'),
	usage: require('./usage'),
	makasibLoyaltyAccount: require('./makasibLoyaltyAccount'),
	billsHistoryForAccounts: require('./billsHistoryForAccounts'),
	userGiftCards: require('./userGiftCards'),
	marketingBanners: require('./marketingBanners'),
	createNotificationRequest: require('./createNotificationRequest'),
	simDetails: require('./simDetails'),
	priceByCatEntry: require('./priceByCatEntry'),
	installmentAmount: require('./installmentAmount'),
	buyNowPayLaterInstallment: require('./buyNowPayLaterInstallment'),
	tracking: require('./tracking'),
	setHbbWfbbOrder: require('./setHbbWfbbOrder'),
	contractDetails: require('./contractDetails'),
	relatedParties: require('./relatedParties'),
	vehicleInsurance: require('./vehicleInsurance'),
	vehiclePlateChars: require('./vehiclePlateChars'),
	validateOtpDocumentId: require('./validateOtpDocumentId'),
	validateOtpSmsPassword: require('./validateOtpSmsPassword'),
	generateOtp: require('./generateOtp'),
	getProductsUnauthorized: require('./getProductsUnauthorized'),
	submitProductOrder: require('./submitProductOrder'),
	productInstallment: require('./productInstallment'),
	validateOperation: require('./validateOperation'),
	validateOTP: require('./validateOTP'),
	validateResource: require('./validateResource'),
	getBranches: require('./getBranches'),
	getServices: require('./getServices'),
	getDates: require('./getDates'),
	getHours: require('./getHours'),
	bookAppointment: require('./bookAppointment'),
	cancelAppointment: require('./cancelAppointment'),
	getAppointment: require('./getAppointment'),
	getAppointments: require('./getAppointments'),
	getProductOutletAvailability: require('./getProductOutletAvailability'),
	getProductOutletsPickup: require('./getProductOutletsPickup'),
	searchMenuList: require('./searchMenuList'),
	getSmartVoucher: require('./getSmartVoucher'),
	calculateInstallment: require('./calculateInstallment'),
	msKeysList: require('./msKeysList'),
	getTenant: require('./getTenant'),
	createTenant: require('./createTenant'),
	getTenantFromOrdersHistory: require('./getTenantFromOrdersHistory'),
	validateMakasibOtp: require('./validateMakasibOtp'),
	getMakasibPointsCalculation: require('./getMakasibPointsCalculation'),
	manageMakasibVoucherReservation: require('./manageMakasibVoucherReservation'),
	corpAccounts: require('./corpAccounts'),
	createBuyNowPayLaterSession: require('./createBuyNowPayLaterSession'),
	retriveBuyNowPayLaterSession: require('./retriveBuyNowPayLaterSession'),
	remoteConfig: require('./remoteConfig'),
};
