import React, { useState, useMemo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Col, Row, Container, Visible } from 'react-grid-system';
import ConditionalRender from '@Components/ConditionalRender/ConditionalRender';
import Icon from '@Components/Icon/Icon';
import Card from '@Components/Card/Card';
import Button from '@Components/controls/Button/Button';
import { ButtonSize, ButtonVariant, ButtonWidth } from '@Components/controls/Button/Button.styled';
import { Formik, Form } from 'formik';
import {
	FooterWraper,
	FooterSteperInfo,
	FooterSteperNextStep,
	ImageWrapper,
	ContainerWrapper,
	ButtonWrapper,
	SubtitleWrapper,
	TitleWrapper,
	CardWrapper,
	SubheadingWrapper,
	ParagraphMwrapper,
	NextStepInfoWrapper,
	BackButtonWraper,
	IconWrapper,
	BodyMWrapper,
	OfferMessageWrapper,
	MainTitleWrapper,
} from './CarInsurance.styled';
import { useInsurance, INSURANCE_TYPE } from '@Utils/context/InsuranceContext';
import useTranslate from '@Utils/hooks/useTranslate';
import { HeadlineBold } from '@Components/Typography/Typography';
import Divider from '@Components/Divider/Divider';
import { dispatch } from '@Redux/store';
import { modalTypes, setModal } from '@Redux/modules/modal/actions';
import { getLang } from '@Utils/language/language';
import useBreadcrumbs from '@Utils/hooks/useBreadcrumbs';
import carInsuranceValidator from '@Utils/validators/carInsuranceValidator';
import carOwnerValidator from '@Utils/validators/carOwnerDataValidator';
import {
	FIRST_STEPPER_STEP,
	SECOND_STEPPER_STEP,
	THIRD_STEPPER_STEP,
	LAST_STEPPER_STEP,
	VEHICLE_MAX_VALUE,
} from '@Config/app.config';
import { useHistory } from 'react-router-dom';
import { ScreenClass } from 'react-grid-system';
import { CSSProperties } from 'styled-components';
import useScreen from '@Utils/hooks/useScreen';
import { matchScreen } from '@Utils/layout/layoutSupport';
import { isSmallScreen } from '@Utils/layout/layoutSupport';
import ExpandableBox from '@Components/ExpandableBox/ExpandableBox';
import { BottomCartWrapper } from '@Components/CartMobile/CartMobile.styled';
export interface IFormikCarInsuranceFields {
	licenseNumber: string;
	contactNumber: string;
	inputFiltering: string;
	chars: string;
	vehicleNumber: string;
	vehicleValue: string;
}
export interface IFormikInsurerFields {
	firstName: string;
	lastName: string;
	email: string;
}
const CarInsurance = (): JSX.Element => {
	const history = useHistory();
	const { translate } = useTranslate();
	const { updateBreadcrumbs } = useBreadcrumbs();
	const [nextLocation, setNextLocation] = useState<string>(history.location.pathname);
	const isUserSignedIn = useSelector((state: types.redux.IState) => state.api?.authentication.signedIn);
	const {
		prevStep,
		nextStep,
		activeIndex,
		steps,
		licenseNumber,
		selectedProvider,
		selectedReferenceNo,
		insuranceOfferProviders,
		submitting,
		insuranceType,
		offersMessages,
		inputFiltering,
		contactNumber,
		chars,
		vehicleNumber,
		vehicleValue,
		clearData,
		getInsurance,
		firstName,
		lastName,
		email,
		setActiveIndex,
	} = useInsurance();
	const isSm = matchScreen(['sm']);
	const isXs = matchScreen(['xs']);
	const smallScreen = isSmallScreen();
	const [expanded, setExpanded] = useState<boolean>(false);
	const { screen, screenClass } = useScreen();
	const getBrowserPath = (subpath: string) => {
		return `/${getLang()}/product/car-insurance${subpath}`;
	};

	const getBrowserPathLastItem = (path: string) => {
		return path.substring(path.lastIndexOf('/') + 1);
	};

	const handleOnClick = () => {
		if (isUserSignedIn) {
			history.push(`/${getLang()}/product/car-insurance/details`);
		} else {
			dispatch(
				setModal({
					data: {
						onContinue: () => {
							history.push(`/${getLang()}/product/car-insurance/details`);
						},
						withoutFooter: true,
					},
					type: modalTypes.LOGIN,
				})
			);
		}
	};

	const getCardWidth = (currentScreenClass: ScreenClass): CSSProperties => {
		if (currentScreenClass.includes('lg') || currentScreenClass.includes('xl')) {
			return { width: '326px' } as CSSProperties;
		} else if (currentScreenClass.includes('md')) {
			return { width: '306px' } as CSSProperties;
		} else if (currentScreenClass.includes('sm')) {
			return { width: '286px' } as CSSProperties;
		} else {
			return { width: '100%' } as CSSProperties;
		}
	};

	useEffect(() => {
		const unlisten = history.listen((location) => {
			setNextLocation(location.pathname);
			if (history.action === 'POP') {
				dispatch(setModal({ closeAllModals: true, type: null }));
				switch (getBrowserPathLastItem(location.pathname)) {
					case 'details':
						setActiveIndex(FIRST_STEPPER_STEP);
						break;
					case 'vehicle-details':
						setActiveIndex(SECOND_STEPPER_STEP);
						break;
					case 'quotes':
						setActiveIndex(THIRD_STEPPER_STEP);
						break;
					case 'summary':
						setActiveIndex(LAST_STEPPER_STEP);
						break;
					default:
						break;
				}
			}
		});
		return unlisten;
	}, []);

	useEffect(() => {
		const lastPathSegment = getBrowserPathLastItem(nextLocation);
		if (lastPathSegment === 'car-insurance') {
			updateBreadcrumbs([
				{
					link: `/${getLang()}/store/`,
					label: translate('breadcrumbs.store'),
				},
				{
					link: `/${getLang()}/product/car-insurance`,
					label: translate('car-insurance.breadcrumb'),
				},
			]);
			clearData();
		}
		if (['details', 'vehicle-details', 'quotes', 'summary'].includes(lastPathSegment))
			updateBreadcrumbs([
				{
					link: `/${getLang()}/store/`,
					label: translate('breadcrumbs.store'),
				},
				{
					link: `/${getLang()}/product/car-insurance`,
					label: translate('car-insurance.breadcrumb'),
				},
				{
					link: `/${getLang()}/product/car-insurance/bima/details`,
					label: translate('insurance.landingpage.bima.title'),
				},
			]);
		if (!isUserSignedIn && lastPathSegment !== 'car-insurance') {
			dispatch(
				setModal({
					data: {
						onContinue: () => {
							history.push(getBrowserPath('/bima/details'));
						},
						withoutFooter: true,
					},
					type: modalTypes.LOGIN,
				})
			);
		}
	}, [nextLocation]);

	useEffect(() => {
		if (!isUserSignedIn) {
			history.push(getBrowserPath(''));
		}
	}, [isUserSignedIn]);

	const FooterBackButton = (
		<ConditionalRender
			show={activeIndex > FIRST_STEPPER_STEP}
			onTrue={() => (
				<BackButtonWraper isXs={isXs}>
					<Button
						uniqueId="insurance-stepper.back-button"
						onClick={() => {
							prevStep();
							history.goBack();
						}}
						variant={ButtonVariant.QUATERNARY_NEGATIVE}
						size={ButtonSize.MEDIUM}
						width={ButtonWidth.FLEX}
					>
						{translate('insurance.step.back')}
					</Button>
				</BackButtonWraper>
			)}
		/>
	);

	const stepperFooter = () => (
		<FooterWraper isSmallScreen={smallScreen}>
			<ConditionalRender show={!isXs} onTrue={() => <>{FooterBackButton}</>} />
			<ConditionalRender
				show={activeIndex < LAST_STEPPER_STEP}
				onTrue={() => (
					<NextStepInfoWrapper isXs={isXs}>
						<FooterSteperInfo>{translate('insurance.stepper.next-step.label')}</FooterSteperInfo>
						<FooterSteperNextStep>{translate(steps[activeIndex + 1]?.description)}</FooterSteperNextStep>
					</NextStepInfoWrapper>
				)}
			/>
			<ConditionalRender
				show={activeIndex < THIRD_STEPPER_STEP}
				onTrue={() => (
					<Button
						uniqueId={`insurance-stepper.continue-${activeIndex}`}
						onClick={() => {
							if (activeIndex === SECOND_STEPPER_STEP) {
								history.push(getBrowserPath('/bima/quotes'));
								nextStep();
							}
						}}
						variant={submitting ? ButtonVariant.QUATERNARY_DISABLED : ButtonVariant.QUATERNARY}
						size={ButtonSize.MEDIUM}
						width={isXs ? ButtonWidth.AUTO : ButtonWidth.FIXED}
						textAlign="center"
						disabled={submitting}
						listener={submitting}
					>
						{translate('insurance.stepper.button')}
					</Button>
				)}
			/>
			<ConditionalRender show={isXs} onTrue={() => <>{FooterBackButton}</>} />
		</FooterWraper>
	);

	const stepContent = useMemo(() => {
		switch (activeIndex) {
			case FIRST_STEPPER_STEP:
				return (
					<>
						<Formik<IFormikCarInsuranceFields>
							initialValues={{
								licenseNumber,
								contactNumber,
								chars,
								vehicleNumber,
								vehicleValue,
								inputFiltering,
							}}
							onSubmit={getInsurance}
							validationSchema={carInsuranceValidator(
								insuranceType === INSURANCE_TYPE.FULL_COMPREHENSIVE,
								VEHICLE_MAX_VALUE
							)}
						>
							{({}) => (
								<Form>
									<Card card="insuranceType" marginBottom={10} />
									<Card card="insuranceInsurer" marginBottom={10} />
									<Card card="insuranceVehicle" marginBottom={10} overflow="visible" />
									{stepperFooter()}
								</Form>
							)}
						</Formik>
					</>
				);
			case SECOND_STEPPER_STEP:
				return (
					<>
						<ConditionalRender
							show={insuranceType === INSURANCE_TYPE.FULL_COMPREHENSIVE}
							onTrue={() => (
								<Card card="insuranceVehicleValue" marginBottom={10} withoutContainerPadding />
							)}
						/>
						<Card card="insuranceVehicleDetails" marginBottom={10} />
						{stepperFooter()}
					</>
				);
			case THIRD_STEPPER_STEP:
				return (
					<>
						{insuranceOfferProviders.map(({ name, thumbnailImageUrl, offers, features }, index) => (
							<Card<types.cards.IInsuranceProviderOffersCardProps>
								card="insuranceOffer"
								params={{
									name,
									thumbnailImageUrl,
									offers,
									features,
								}}
								key={`insuranceOfferProviders-${index}`}
								marginBottom={10}
							/>
						))}
						{stepperFooter()}
					</>
				);
			case LAST_STEPPER_STEP:
				return (
					<>
						<Row justify="center">
							<Col>
								<Card<types.cards.IInsuranceSelectedOfferValueCardProps>
									card="insuranceSelectedOfferValue"
									params={{
										selectedProvider,
										selectedReferenceNo,
									}}
									marginBottom={10}
									withoutContainerPadding
								/>
								<Card card="insuranceSelectedOfferDetails" marginBottom={10} withoutContainerPadding />
								<Formik<IFormikInsurerFields>
									initialValues={{ firstName, lastName, email }}
									onSubmit={() => {
										return;
									}}
									validationSchema={carOwnerValidator()}
								>
									{({}) => (
										<Form>
											<Card card="insuranceInsurerContact" marginBottom={10} />
										</Form>
									)}
								</Formik>
								{stepperFooter()}
							</Col>
							<Visible lg xl xxl>
								<Col sm={4}>
									<Card<types.cards.IInsuranceSummaryCardProps>
										card="insuranceSummary"
										marginBottom={10}
										params={{
											expanded,
										}}
									/>
								</Col>
							</Visible>
							<Visible xs sm md>
								<BottomCartWrapper>
									<ExpandableBox
										title={translate('product.whats.in.the.box')}
										expandAvailable={screen(['xs', 'sm', 'md'])}
										minHeight={screen(['xs', 'sm', 'md']) ? 180 : 88}
										bottomMode={true}
										getExpandedValue={setExpanded}
									>
										<Card<types.cards.IInsuranceSummaryCardProps>
											card="insuranceSummary"
											marginBottom={10}
											params={{
												expanded,
											}}
										/>
									</ExpandableBox>
								</BottomCartWrapper>
							</Visible>
						</Row>
					</>
				);
			default:
				return null;
		}
	}, [activeIndex, insuranceOfferProviders, insuranceType, offersMessages, getInsurance]);

	const getBimaCard = () => (
		<Col>
			<CardWrapper styles={getCardWidth(screenClass)}>
				<Card<types.cards.IInsuranceBimaCardProps>
					card="insuranceBima"
					withoutContainerPadding
					params={{
						withButton: true,
					}}
				/>
			</CardWrapper>
		</Col>
	);

	const getOffersMessages = () => (
		<>
			{offersMessages.map((offersMessage, index) => (
				<OfferMessageWrapper key={`offer-${index}`}>
					<IconWrapper>
						<Icon name="warningFilled" width="24" height="24" />
					</IconWrapper>
					<BodyMWrapper>{offersMessage}</BodyMWrapper>
				</OfferMessageWrapper>
			))}
		</>
	);

	return (
		<Container>
			<ConditionalRender
				show={nextLocation === getBrowserPath('')}
				onTrue={() => (
					<>
						<ImageWrapper>
							<ContainerWrapper>
								<Row>
									<Col style={{ marginBottom: '100px' }}>
										<TitleWrapper>{translate('insurance.title')}</TitleWrapper>
										<Row>
											<Col sm={4}>
												<Icon
													name="landingPageCar"
													width={56}
													height={56}
													justifycontent="flex-start"
												/>
											</Col>
											<ConditionalRender show={isSm} onTrue={() => getBimaCard()} />
										</Row>
									</Col>
									<ConditionalRender show={!isSm} onTrue={() => getBimaCard()} />
								</Row>
							</ContainerWrapper>
						</ImageWrapper>
						<SubheadingWrapper isXs={isXs}>
							{translate('insurance.landingpage.bima.details')}
						</SubheadingWrapper>
						<MainTitleWrapper>
							<SubtitleWrapper>{translate('insurance.landingpage.bima.title')}</SubtitleWrapper>
							<Divider />
						</MainTitleWrapper>
						<Row>
							<Col>
								<HeadlineBold>{translate('insurance.landingpage.bima.heading')}</HeadlineBold>
								<ParagraphMwrapper>
									{translate('insurance.landingpage.bima.paragraph')}
								</ParagraphMwrapper>
							</Col>
						</Row>
						<Row>
							<ButtonWrapper>
								<Button
									uniqueId="insurance-bima-welcome-button"
									onClick={handleOnClick}
									variant={ButtonVariant.QUATERNARY_NEGATIVE}
									size={ButtonSize.MEDIUM}
									width={ButtonWidth.FIXED}
								>
									{translate('insurance.action')}
								</Button>
							</ButtonWrapper>
						</Row>
					</>
				)}
				onFalse={() => (
					<Row>
						<Col sm={4}>
							<Visible lg xl>
								<Card<types.cards.IInsuranceBimaCardProps>
									card="insuranceBima"
									withoutContainerPadding
									params={{
										withButton: false,
									}}
									marginBottom={15}
								/>
								<ConditionalRender
									show={activeIndex === THIRD_STEPPER_STEP}
									onTrue={() => <Card card="insuranceVehicleRollup" marginBottom={10} />}
								/>
							</Visible>
						</Col>
						<Col lg={8}>
							<Card card="insuranceStepper" marginBottom={10} withoutContainerPadding />
							<Visible xs sm md>
								<ConditionalRender
									show={activeIndex === THIRD_STEPPER_STEP}
									onTrue={() => <Card card="insuranceVehicleRollup" marginBottom={10} />}
								/>
							</Visible>
							<ConditionalRender
								show={activeIndex === THIRD_STEPPER_STEP}
								onTrue={() => <>{getOffersMessages()}</>}
							/>
							<ConditionalRender
								show={submitting}
								onTrue={() => (
									<>
										<Card card="insuranceOffersLoader" marginBottom={10} withoutContainerPadding />
										{stepperFooter()}
									</>
								)}
								onFalse={() => <>{stepContent}</>}
							/>
						</Col>
					</Row>
				)}
			/>
		</Container>
	);
};
export default CarInsurance;
