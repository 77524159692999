import useTranslate from '@Utils/hooks/useTranslate';
import * as Yup from 'yup';
import compareDates from '@Utils/date/compareDates';
import moment from 'moment';

export default (): Yup.AnyObjectSchema => {
	const { translate } = useTranslate();
	return Yup.object().shape({
		customerIdType: Yup.string().required(translate('document.data.form.document.type.required')),
		customerId: Yup.string().required(translate('document.data.form.document.id.required')),
		expiryDate: Yup.string()
			.required(translate('document.data.form.expiry.required'))
			.max(10, translate('document-details.fill-date-properly'))
			.min(10, translate('document-details.fill-date-properly'))
			.matches(/[0-9]/, translate('document-details.name.only.number'))
			.test('invalid-expiry-date', translate('document-details.invalid-date'), (expiryDate) => {
				const dateFormat = new RegExp('[0-9]{2}/[0-9]{2}/[0-9]{4}');
				if (!dateFormat.test(expiryDate || '')) return false;
				if (!moment(expiryDate, 'DD/MM/YYYY').isValid()) return false;

				const first = moment(expiryDate, 'DD/MM/YYYY').format('YYYY-MM-DD');
				let second = moment(moment().format('DD/MM/YYYY'), 'DD/MM/YYYY').format('YYYY-MM-DD');
				if (compareDates(first, second).first.isSmaller) return false;
				second = moment(moment().format('DD/MM/YYYY'), 'DD/MM/YYYY').add(20, 'years').format('YYYY-MM-DD');

				return !compareDates(first, second).first.isGreater;
			}),
		documentScan: Yup.object().test('required', translate('document.data.form.both'), function () {
			return (
				this?.parent?.documentScan?.base64backSide?.base64File &&
				this?.parent?.documentScan?.base64frontSide?.base64File
			);
		}),
	});
};
