import styled from 'styled-components';
import { BodyM, DisplayMBold } from '@Components/Typography/Typography';

export const LoaderWrapper = styled.div`
	${({ theme }) => {
		return {
			backgroundColor: theme.colors.mintGreen,
		};
	}}
	display: flex;
	flex-direction: column;
	padding-bottom: 24px;
	align-items: center;
`;

export const InsuranceTitle = styled(DisplayMBold)`
	margin-top: 5px;
	text-align: center;
	${({ theme }) => {
		return {
			color: theme.colors.black,
		};
	}};
`;

export const InsuranceSubtitle = styled(BodyM)`
	margin: 20px 0;
	text-align: center;
	${({ theme }) => {
		return {
			color: theme.colors.black54,
		};
	}}
`;
