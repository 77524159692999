// eslint-disable-next-line @typescript-eslint/no-unsafe-call
import React, { useState, FunctionComponent, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Row } from 'react-grid-system';
import { Caption } from '@Components/Typography/Typography';
import {
	CardWrapper,
	Title,
	PriceDescriptionWrapper,
	DescriptionTitle,
	AdditionalInfoTitle,
	MessageBarContainer,
	BoldWrapper,
	StrikethroughWrapper,
	VatMessageBarCircle,
	VatMessageBarPercent,
	VatMessageBarValue,
} from './BusinessPaymentSummary.styled';
import useTranslate from '@Utils/hooks/useTranslate';
import PriceTag from '@Components/PriceTag/PriceTag';
import Divider from '@Components/Divider/Divider';
import ConditionalRender from '@Components/ConditionalRender/ConditionalRender';
import { ProductsService } from '@Services/productsService/productsService';
import { PlanType } from '../Plan/Plan';
import MessageBar from '@Components/MessageBar/MessageBar';
import BusyIndicator from '@Components/BusyIndicator/BusyIndicator';
import { setSelectedItems } from '@Redux/modules/selectedItems/actions';
import moment from 'moment';
import MultipleSegmentSelector, {
	ISingleSegmentProps,
} from '@Components/MultipleSegmentSelector/MultipleSegmentSelector';
import { roundMoney } from '@Utils/converters/roundMoney';
import { IInstallment } from '@ApiModels/getSmartVoucher';
import { ICalculatedProduct } from '@ApiModels/calculateInstallment';
import { addBusyIndicator, removeBusyIndicator } from '@Redux/modules/busyIndicator/actions';
import voucher from '@Assets/images/voucher.png';
import { Subscription } from 'rxjs';
import { JAWWY_PRODUCT_PARTNUMBER_ID_PART } from '@Config/app.config';

export enum PaymentMethod {
	UPFRONT = 'UPFRONT',
	MONTHLY = 'MONTHLY',
}

export const GET_PRICE_BY_CAT_ENTRY_PAYMENT_SUMMARY_BI = 'GET_PRICE_BY_CAT_ENTRY_PAYMENT_SUMMARY_BI';
export const PRODUCT_PAYMENT_LOADING = 'PRODUCT_PAYMENT_LOADING';

const configuration = {
	emfConfigId: '13',
	orderType: 'SMART_VOUCHER',
	subscriptionType: 'Voice',
	packageGroup: 'DEVICE_CORP_INSTALLMENT',
};

const BusinessPaymentSummary: FunctionComponent<types.cards.IPaymentSummaryCardProps> = ({
	params: { isDevice, planType, onPlanSelector = false },
}): JSX.Element => {
	const [paymentMethod, setPaymentMethod] = useState<PaymentMethod>(PaymentMethod.UPFRONT);
	const selectedSKU = useSelector((state: types.redux.IState) => state.selectedItems?.details);
	const selectedAccount = useSelector((state: types.redux.IState) => state.selectedItems?.selectedAccount);
	const selectedCommitment = useSelector(
		(state: types.redux.IState) => state.selectedItems?.selectedCommitmentPeriod
	);
	const [selectedInstallment, setSelectedInstallment] = useState<IInstallment | undefined>();
	const { translate } = useTranslate();
	const dispatch = useDispatch();

	const [selectedPaymentType, setSelectedPaymentType] = useState<string | undefined>();
	const [calculatedProduct, setCalculatedProduct] = useState<ICalculatedProduct | undefined>();
	const [availablePaymentMethods, setAvailablePaymentMethods] = useState<ISingleSegmentProps<string>[]>([]);

	useEffect(() => {
		if (selectedCommitment) {
			setAvailablePaymentMethods(
				(selectedCommitment?.installments || [])
					.map((installment) => {
						return {
							name: installment?.duration
								? translate('plan.duration', installment?.duration || 0)
								: translate('product.cart-card.upfront'),
							value: String(installment?.duration),
							productId: installment?.productOfferId,
							disabled: selectedCommitment?.installments?.length <= 1,
						};
					})
					.sort((in1, in2) => (Number(in1?.value) > Number(in2?.value) ? 1 : -1))
			);
		}
	}, [selectedCommitment]);

	useEffect(() => {
		if (availablePaymentMethods?.length) {
			setPaymentData(availablePaymentMethods?.[0]?.value);
		}
	}, [availablePaymentMethods]);

	useEffect(() => {
		setSelectedPaymentMethod(
			planType === PlanType.DEVICE_ONLY ? ('UPFRONT' as PaymentMethod) : ('MONTHLY' as PaymentMethod)
		);
	}, [planType]);

	const setSelectedPaymentMethod = (value: string) => {
		dispatch(setSelectedItems({ selectedPaymentMethod: value }));
		setSelectedPaymentType(value);
	};

	useEffect(() => {
		let subscription: Subscription | undefined;
		if (selectedInstallment && selectedSKU) {
			dispatch(addBusyIndicator(PRODUCT_PAYMENT_LOADING));
			dispatch(setSelectedItems({ selectedInstallment }));
			subscription = ProductsService.calculateInstallment({
				accountNo: String(selectedAccount?.accountId),
				emfConfigId: configuration?.emfConfigId,
				orderType: configuration?.orderType,
				subscriptionType: configuration?.subscriptionType,
				packageGroup: configuration?.packageGroup,
				productId: String(selectedInstallment?.productOfferId),
				serviceCode: selectedSKU?.partNumber?.split('##')?.[0],
				tariffType: selectedSKU?.partNumber?.split('##')?.[0],
				commitmentAmount: String(selectedCommitment?.deviceDiscount),
				upfrontCollection: String(selectedCommitment?.upFrontPayment),
				commitmentDuration: String(selectedCommitment?.commitmentDuration),
				installmentDuration: String(selectedInstallment?.duration),
			}).subscribe((res) => {
				setCalculatedProduct(res?.calculatedProduct);
				dispatch(setSelectedItems({ calculatedProduct: res?.calculatedProduct }));
				dispatch(removeBusyIndicator(PRODUCT_PAYMENT_LOADING));
			});
		}
		return () => {
			subscription?.unsubscribe();
		};
	}, [selectedInstallment, selectedAccount, selectedSKU]);

	const setPaymentData = (value: string) => {
		setSelectedPaymentMethod(value);
		if (value === '0') {
			setPaymentMethod(PaymentMethod.UPFRONT);
		} else {
			setPaymentMethod(PaymentMethod.MONTHLY);
		}
		const foundData = availablePaymentMethods.find((pm) => pm?.value === value);
		setSelectedInstallment({
			duration: Number(foundData?.value),
			productOfferId: foundData?.productId,
		});
	};

	return (
		<BusyIndicator
			listener={[PRODUCT_PAYMENT_LOADING]}
			skeleton={planType === 'DEVICE_ONLY' ? 'paymentSummary' : 'paymentSummaryWithPlan'}
		>
			<CardWrapper>
				<Row>
					<Title textTransform="uppercase" color="black87">
						{translate(isDevice ? 'payment.summary.title.device.payment' : 'payment.summary.title.payment')}
					</Title>
				</Row>
				<ConditionalRender
					show={
						!(
							paymentMethod === PaymentMethod.UPFRONT &&
							Number(calculatedProduct?.device?.saleDetails?.totalAmount) <= 0
						)
					}
					onTrue={() => (
						<>
							<Row>
								<MultipleSegmentSelector<string>
									segments={availablePaymentMethods}
									selectedValue={selectedPaymentType}
									onChange={(value: string) => {
										setPaymentData(value);
									}}
								/>
							</Row>
						</>
					)}
				/>

				<ConditionalRender
					show={!!selectedInstallment && !!calculatedProduct}
					onTrue={() => (
						<>
							<ConditionalRender
								show={
									paymentMethod === PaymentMethod.UPFRONT &&
									!onPlanSelector &&
									!!calculatedProduct?.device?.saleDetails?.totalAmount
								}
								onTrue={() => (
									<>
										<MessageBarContainer
											color="grey50"
											hasMargin={Number(calculatedProduct?.device?.saleDetails?.totalAmount) > 0}
										>
											<Row className="row-container" justify="between">
												<PriceDescriptionWrapper align="flex-start">
													<DescriptionTitle color="black87" fontWeight="bold">
														{translate('product.cart-card.upfront-payment')}
													</DescriptionTitle>
												</PriceDescriptionWrapper>
												<PriceDescriptionWrapper>
													<PriceTag
														price={
															calculatedProduct?.device?.saleDetails?.taxableAmount || 0
														}
														duration={translate('plan-bar.mo')}
														size="medium"
													/>
													<Caption color="black54">
														{translate(
															'product.cart-card.vat-value',
															String(
																calculatedProduct?.device?.saleDetails?.vatPercentage
															)
														)}{' '}
														<StrikethroughWrapper>{`${roundMoney(
															calculatedProduct?.device?.saleDetails?.standardPrice || 0
														)} ${translate('omr')}`}</StrikethroughWrapper>
													</Caption>

													<Caption color="primary">
														{translate('product.cart-card.after-discount')}{' '}
														<BoldWrapper>{`${roundMoney(
															calculatedProduct?.device?.saleDetails?.totalAmount || 0
														)} ${translate('omr')}`}</BoldWrapper>
													</Caption>
													<Caption color="primary" fontWeight="bold"></Caption>
												</PriceDescriptionWrapper>
											</Row>
										</MessageBarContainer>
									</>
								)}
							/>

							<ConditionalRender
								show={
									paymentMethod === PaymentMethod.MONTHLY &&
									!onPlanSelector &&
									!!calculatedProduct?.device?.saleDetails?.totalAmount
								}
								onTrue={() => (
									<>
										<MessageBarContainer color="grey50">
											<Row className="row-container" justify="between">
												<PriceDescriptionWrapper align="flex-start">
													<VatMessageBarCircle>
														<VatMessageBarValue>
															{translate('message.box.vat.percent', String(5))}
														</VatMessageBarValue>
														<VatMessageBarPercent>
															{translate('message.box.vat.percent.vat')}
														</VatMessageBarPercent>
													</VatMessageBarCircle>
													<Caption color="black54">
														{translate('message.box.vat.monthly', String(5))}
													</Caption>
												</PriceDescriptionWrapper>
												<PriceDescriptionWrapper>
													<PriceTag
														price={calculatedProduct?.device?.saleDetails?.totalAmount || 0}
														size="medium"
													/>
												</PriceDescriptionWrapper>
											</Row>
										</MessageBarContainer>

										<MessageBarContainer color="white">
											<Row className="row-container" justify="between">
												<PriceDescriptionWrapper align="flex-start">
													<DescriptionTitle color="black87" fontWeight="bold">
														{translate(
															selectedSKU?.productPartNumber.includes(
																JAWWY_PRODUCT_PARTNUMBER_ID_PART
															)
																? 'cart.payment.summary.item.subscription'
																: 'payment.summary.description.installments'
														)}
													</DescriptionTitle>
													<Caption color="black54">
														{translate(
															'payment.summary.installments.period',
															String(
																calculatedProduct?.device?.deviceCommitments
																	?.installmentMonths
															)
														)}
													</Caption>
												</PriceDescriptionWrapper>
												<PriceDescriptionWrapper>
													<PriceTag
														size="medium"
														discountColor="black54"
														duration={translate('plan-bar.mo')}
														discount={
															(Number(
																calculatedProduct?.device?.saleDetails?.standardPrice
															) || 0) /
															(Number(
																calculatedProduct?.device?.deviceCommitments
																	?.installmentMonths
															) || 0)
														}
														price={
															(Number(
																calculatedProduct?.device?.saleDetails?.standardPrice
															) || 0) /
															(Number(
																calculatedProduct?.device?.deviceCommitments
																	?.installmentMonths
															) || 0)
														}
													/>
													<Caption color="primary">
														{translate('product.cart-card.after-discount')}{' '}
														<BoldWrapper>{`${roundMoney(
															calculatedProduct?.device?.deviceCommitments
																?.installmentAmount || 0
														)} ${translate('omr')}`}</BoldWrapper>
													</Caption>
												</PriceDescriptionWrapper>
											</Row>
										</MessageBarContainer>
									</>
								)}
							/>

							<ConditionalRender
								show={!calculatedProduct?.device?.saleDetails?.totalAmount}
								onTrue={() => (
									<MessageBarContainer color="grey50">
										<Row className="row-container" justify="between">
											<div className="img-container">
												<img title="credit icon" src={voucher} />
											</div>
											<PriceDescriptionWrapper>
												<DescriptionTitle color="black87" fontWeight="bold">
													{translate('product.cart-card.upfront-payment')}
												</DescriptionTitle>
												<Caption color="black54">
													{translate('product.cart-card.no-pay1')}
												</Caption>
												<Caption color="black54">
													{translate('product.cart-card.no-pay2')}
												</Caption>
											</PriceDescriptionWrapper>
											<PriceDescriptionWrapper>
												<PriceTag
													discount={Number(
														calculatedProduct?.device?.saleDetails?.standardPrice || 0
													)}
													price={calculatedProduct?.device?.saleDetails?.standardPrice || 0}
												/>
												<Caption color="primary">
													{translate('product.cart-card.after-discount')}{' '}
													<BoldWrapper>{`${roundMoney(
														calculatedProduct?.device?.saleDetails?.totalAmount || 0
													)} ${translate('omr')}`}</BoldWrapper>
												</Caption>
											</PriceDescriptionWrapper>
										</Row>
									</MessageBarContainer>
								)}
							/>

							<ConditionalRender
								show={true}
								onTrue={() => (
									<Row>
										<Divider marginBottom={24} marginTop={24} />
										<AdditionalInfoTitle textTransform="uppercase">
											{translate('payment.summary.title.device.commitment')}
										</AdditionalInfoTitle>
										<MessageBar
											font="caption"
											width="100%"
											message={translate('payment.summary.will.expire')}
											importantMessage={moment()
												.add(selectedInstallment?.duration || 0, 'months')
												.format('MMM YYYY')}
											contentFill="support4"
											backgroundColor="grey100"
										/>
									</Row>
								)}
							/>
						</>
					)}
				/>
			</CardWrapper>
		</BusyIndicator>
	);
};

export default BusinessPaymentSummary;
