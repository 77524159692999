export interface IAuth {
	LTPAToken: string;
	groups: string[];
}

export interface ILTPAToken {
	login: IAuth;
}

const model: types.models.IInputModel = {
	login: 'response',
};

export default model;
