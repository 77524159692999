import React, { FunctionComponent } from 'react';
import { Row } from 'react-grid-system';
import { Button, CardWrapper, Title } from './LoginPrompt.styled';
import useTranslate from '@Utils/hooks/useTranslate';
import { PrimaryLargeButtonFixed } from '@Components/controls/Button/Button';
import { useDispatch } from 'react-redux';
import { modalTypes, setModal } from '@Redux/modules/modal/actions';

const LoginPrompt: FunctionComponent<types.cards.ILoginPromptCardProps> = ({}): JSX.Element => {
	const dispatch = useDispatch();
	const { translate } = useTranslate();

	const openLoginModal = () => {
		dispatch(
			setModal({
				type: modalTypes.LOGIN,
			})
		);
	};

	return (
		<CardWrapper>
			<Row>
				<Title>{translate('product.details.login.prompt')}</Title>
				<Button>
					<PrimaryLargeButtonFixed
						uniqueId="product.details.login.prompt.button"
						onClick={() => openLoginModal()}
					>
						{translate('product.details.login.prompt.button')}
					</PrimaryLargeButtonFixed>
				</Button>
			</Row>
		</CardWrapper>
	);
};

export default LoginPrompt;
