import { BodyM, BodyMBold, ButtonM } from '@Components/Typography/Typography.styled';
import { matchScreen } from '@Utils/layout/layoutSupport';
import styled from 'styled-components';

interface ISmallScreen {
	isXs?: boolean;
	isSm?: boolean;
}

export const CheckoutSummaryCardContainer = styled.div`
	padding: 32px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 32px 10%;
	border-radius: 8px;
	text-align: center;
	${({ theme }) => ({
		backgroundColor: theme.colors.white,
	})}
`;

export const HorizontalContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 2px;
	${() => {
		return { flexDirection: matchScreen('xs') ? 'column' : 'row' };
	}}
`;

export const OrderLink = styled(BodyM)`
	cursor: pointer;
	text-decoration: underline;
	${({ theme }) => ({
		color: theme.colors.primary,
	})}
`;

export const CopyClipboardButton = styled(ButtonM)`
	cursor: pointer;
	${({ theme }) => ({
		color: theme.colors.primary,
	})};
`;

export const ButtonWrapper = styled.div`
	width: 240px;
	margin-top: 16px;
`;

export const MicrosoftInfoWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	text-align: center;
	align-items: center;
	width: 100%;
`;

export const EmailLabelWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
`;

export const SoftwareCodeWrapper = styled.div<ISmallScreen>`
	${({ isXs, isSm }) => ({
		flexDirection: isXs || isSm ? 'column' : 'row',
	})};
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
`;

export const ErrorLabelWrapper = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	text-align: center;
	align-items: center;
	margin 8px 0px;
	justify-content: center;
`;

export const ColumnLabelWrapper = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin-top: 24px;
`;

export const MicrosoftCodeContainer = styled.div<ISmallScreen>`
	${({ theme, isXs, isSm }) => ({
		backgroundColor: theme.colors.primary6,
		border: `solid 1px ${theme.colors.primary}`,
		padding: isXs || isSm ? '16px 8px 8px 8px' : '8px 8px 8px 16px',
		flexDirection: isXs || isSm ? 'column' : 'row',
	})};
	display: flex;
	align-text: center;
	justify-content: space-between;
	align-items: center;
	gap: 8px;
	width: 100%;
	max-width: 600px;
	border-radius: 4px;
	margin: 16px 0px 40px;
`;

export const LoaderWrapper = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-bottom: 40px;
	margin-top: 40px;
`;

export const ButtonContent = styled.div`
	> div {
		width: 240px;
	}
`;

export const OrderNumberWrapper = styled(BodyMBold)<{ clickable?: boolean }>`
	${({ theme, clickable }) => ({ color: theme.colors.black87, cursor: clickable ? 'pointer' : undefined })}
	padding: 0 4px;
`;

export const GiftCardContainer = styled.div`
	width: 100%;
	max-width: 600px;
`;

export const MailLink = styled.a`
	padding: 4px;
	text-decoration: none;
`;

export const MailWrapper = styled.div`
	display: flex;
	align-items: center;
`;

export const MessageContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;
	max-width: 430px;
	margin-top: 16px;
	p {
		display: inline;
	}
`;

export const CheckoutSummaryParagraph = styled(BodyM)`
	padding: 8px;
`;

export const MicrosoftAutomationInfoWrapper = styled(BodyM)`
	padding: 8px;
`;

export const TenantDasboardLink = styled.a`
	cursor: pointer;
	text-decoration: underline;
	${({ theme }) => ({
		color: theme.colors.primary,
	})}
`;
