import React, { FunctionComponent, useState, useEffect, useMemo } from 'react';
import {
	DateTimePickerWrapper,
	CalendarDaysInnerWrapper,
	CalendarDaysOuterWrapper,
	TimeRangeItemsWrapper,
	MonthsTitle,
	AvailableSlots,
	HoursItemsInnerWrapper,
	HoursItemsOuterWrapper,
	SlotsWrapper,
	ButtonsWrapper,
	MonthsTitleWrapper,
	UnavailableAppointmentWrapper,
	UnavailableAppointmentInfo,
	WarningContainer,
} from './DateTimePicker.styled';
import DayItem from './subcomponents/DayItem/DayItem';
import HourItem from './subcomponents/HourItem/HourItem';
import TimeRangeItem from './subcomponents/TimeRangeItem/TimeRangeItem';
import Divider from '@Components/Divider/Divider';
import Icon from '@Components/Icon/Icon';
import {
	PrimaryMediumButtonFixed,
	SecondaryMediumButtonFixedNoBorder,
	SecondaryMediumButtonFlexNoBorder,
} from '@Components/controls/Button/Button';
import { useDispatch } from 'react-redux';
import { setModal } from '@Redux/modules/modal/actions';
import ConditionalRender from '@Components/ConditionalRender/ConditionalRender';
import moment from 'moment';
import useTranslate from '@Utils/hooks/useTranslate';
import useScreen from '@Utils/hooks/useScreen';
import { ERROR_CODES } from '@Utils/context/OutletContext';
import { CaptionBigger } from '@Components/Typography/Typography.styled';
import BusyIndicator from '@Components/BusyIndicator/BusyIndicator';
import { addBusyIndicator, removeBusyIndicator } from '@Redux/modules/busyIndicator/actions';

const CHEVRON_ICON_SIZE = 24;
const CALENDAR_ICON_SIZE = 56;
const GET_PICKUP_BOOKING_HOURS = 'GET_PICKUP_BOOKING_HOURS';
interface IDateTimePickerProps {
	availableDays: string[];
	availableHours: string[];
	disabledDates: string[];
	getHours: (date: string) => void;
	findFirstAvailableAppointment: () => void;
	bookingPickupAppointment: string;
	cancelCurrentAppointment: () => Promise<string>;
	bookNewAppointment: (bookingPickupAppointment: string) => Promise<string>;
}

interface IDay {
	date: string;
	state: string;
	selected: boolean;
	today: boolean;
}

interface ISlot {
	timeOfDayLabel: string;
	hoursRangelabel: string;
	hours: string[];
	state: string;
	selected: boolean;
}

interface IHour {
	hour: string;
	selected: boolean;
	index: number;
}

const DateTimePicker: FunctionComponent<IDateTimePickerProps> = ({
	availableDays,
	availableHours,
	disabledDates,
	getHours,
	findFirstAvailableAppointment,
	bookingPickupAppointment,
	cancelCurrentAppointment,
	bookNewAppointment,
}) => {
	const { screen } = useScreen();
	const isXS = screen('xs');
	const isSM = screen('sm');
	const dispatch = useDispatch();
	const { translate } = useTranslate();
	const [isDaySlotsAvailable, setIsDaySlotsAvailable] = useState(true);
	const [visibleDays, setVisibleDays] = useState<IDay[]>([]);
	const [visibleHours, setVisibleHours] = useState<IHour[]>([]);
	const [allDays, setAllDays] = useState<IDay[]>([]);
	const [allHours, setAllHours] = useState<IHour[]>([]);
	const [visibleDaysIndex, setVisibleDaysIndex] = useState(0);
	const [visibleHoursIndex, setVisibleHoursIndex] = useState(0);
	const [visibleDaysQuantity, setVisibleDaysQuantity] = useState(7);
	const [visibleHoursQuantity, setVisibleHoursQuantity] = useState(5);
	const [isTodayDateVisible, setIsTodayDateVisible] = useState(true);
	const [selectedDay, setSelectedDay] = useState<IDay>(visibleDays[visibleDaysIndex]);
	const [availableSlots, setAvailableSlots] = useState<ISlot[]>([]);
	const [selectedSlot, setSelectedSlot] = useState<ISlot>(availableSlots[0]);
	const [selectedHour, setSelectedHour] = useState<string>('');
	const [isDailyLimitExceeded, setIsDailyLimitExceeded] = useState(false);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		if (isXS) {
			setVisibleDaysQuantity(4);
			setVisibleHoursQuantity(3);
		}
		if (isSM) {
			setVisibleDaysQuantity(6);
		}
		if (!isXS && !isSM) {
			setVisibleDaysQuantity(7);
			setVisibleHoursQuantity(5);
		}
	}, [isXS, isSM]);

	const timeRanges = [
		{ morning: '7AM - 12AM', hourStartRange: '7', hourEndRange: '11' },
		{ afternoon: '12AM - 6PM', hourStartRange: '12', hourEndRange: '17' },
		{ evening: '6PM - 12PM', hourStartRange: '18', hourEndRange: '23' },
	];

	const getVisibleDays = (index: number) => {
		const newVisibleDays = [...allDays].slice(index, visibleDaysQuantity + index);
		setVisibleDays(newVisibleDays);
	};

	const getVisibleHours = (index: number) => {
		const newVisibleHours = [...allHours].slice(index, visibleHoursQuantity + index);
		setVisibleHours(newVisibleHours);
	};

	const getDatesTitle = useMemo(() => {
		const firstMonth = moment(availableDays[0]).format('MMMM');
		const lastMonth = moment(availableDays[availableDays.length - 1]).format('MMMM');
		const firstYear = moment(availableDays[0]).format('YYYY');
		const lastYear = moment(availableDays[availableDays.length - 1]).format('YYYY');
		return firstMonth === lastMonth
			? `${firstMonth} ${firstYear}`
			: `${firstMonth} ${firstYear !== lastYear ? firstYear : ''} - ${lastMonth} ${lastYear}`;
	}, [availableDays]);

	const getDayOfMonth = (visibleDay: string) => {
		const dayOfMonth = moment(visibleDay).format('DD');
		return dayOfMonth[0] === '0' ? dayOfMonth.slice(1, 2) : dayOfMonth;
	};

	const setDaySelected = (selectedDay: string) => {
		const newSelectedDayIndex = allDays.findIndex((day) => {
			return day.date === selectedDay;
		});
		if (newSelectedDayIndex > -1 && allDays[newSelectedDayIndex].state === 'Default') {
			const currentSelectedDayIndex = allDays.findIndex((day) => {
				return day.selected === true;
			});
			const newAllDays = [...allDays];
			if (currentSelectedDayIndex > -1) newAllDays[currentSelectedDayIndex].selected = false;
			newAllDays[newSelectedDayIndex].selected = true;
			setAllDays(newAllDays);
			setSelectedDay(newAllDays[newSelectedDayIndex]);
		}
	};

	const setSlotSelected = (slotId: number, newSlots?: ISlot[]) => {
		const currentSlots = newSlots ?? availableSlots;
		if (currentSlots.length > 0) {
			const selectedSlotId = currentSlots.findIndex((slot) => {
				return slot.selected === true;
			});
			if (currentSlots[slotId].state === 'Default') {
				const newAvailableSlots = [...currentSlots];
				if (selectedSlotId > -1) newAvailableSlots[selectedSlotId].selected = false;
				newAvailableSlots[slotId].selected = true;
				setAvailableSlots(newAvailableSlots);
				setSelectedSlot(newAvailableSlots[slotId]);
				const newAllHours = newAvailableSlots[slotId].hours.map((hour, index) => {
					return {
						selected: false,
						hour,
						index,
					};
				});
				setAllHours(newAllHours);
				setVisibleHoursIndex(0);
				setHourSelected(0, newAllHours);
			}
		}
	};

	const setHourSelected = (hourId: number, newHours?: IHour[]) => {
		const currentHours = newHours ?? allHours;
		const currentSelectedHourId = currentHours.findIndex((hour) => {
			return hour.selected === true;
		});
		const newAllHours = [...currentHours];
		if (currentSelectedHourId > -1) newAllHours[currentSelectedHourId].selected = false;
		newAllHours[hourId].selected = true;
		setAllHours(newAllHours);
		setSelectedHour(newAllHours[hourId].hour);
	};

	useEffect(() => {
		if (selectedDay) {
			setIsDailyLimitExceeded(false);
			setLoading(true);
			dispatch(addBusyIndicator(GET_PICKUP_BOOKING_HOURS));
			getHours(selectedDay.date);
		}
	}, [selectedDay]);

	useEffect(() => {
		const isTodayVisible = visibleDays.findIndex((visibleDay) => visibleDay.date === moment().format('YYYY-MM-DD'));
		setIsTodayDateVisible(isTodayVisible >= 0);
	}, [visibleDays]);

	useEffect(() => {
		getVisibleDays(visibleDaysIndex);
	}, []);

	useEffect(() => {
		getVisibleDays(visibleDaysIndex);
	}, [visibleDaysIndex, allDays]);

	useEffect(() => {
		getVisibleHours(visibleHoursIndex);
	}, [visibleHoursIndex, allHours]);

	const getDayItems = useMemo(() => {
		return visibleDays.map((visibleDay, index) => {
			return (
				<DayItem
					date={visibleDay.date}
					state={visibleDay.state}
					selected={visibleDay.selected}
					today={visibleDay.today}
					key={`${visibleDay.date}_${index}`}
					dayOfMonth={getDayOfMonth(visibleDay.date)}
					dayOfWeek={moment(visibleDay.date).format('dddd').slice(0, 3)}
					onDayClick={setDaySelected}
				/>
			);
		});
	}, [visibleDays]);

	const getFormattedAppointment = (selectedDate: string, selectedHour: string) => {
		const formattedDay = moment(selectedDate).format('D MMM YYYY');
		const formattedHour = moment(selectedDate + ' ' + selectedHour).format('h:mm A');
		return formattedDay + ' ' + formattedHour;
	};

	const bookAppointment = () => {
		setLoading(true);
		bookNewAppointment(getFormattedAppointment(selectedDay.date, selectedHour))
			.then(() => {
				dispatch(
					setModal({
						type: null,
						data: null,
					})
				);
				setLoading(false);
			})
			.catch((errorCode) => {
				if (ERROR_CODES.includes(errorCode)) setIsDailyLimitExceeded(true);
				setLoading(false);
			});
	};

	useEffect(() => {
		if (availableDays.length > 0) {
			const todayDate = moment().format('YYYY-MM-DD');
			let availableWithMissingDays = availableDays.map((availableDay) => {
				return {
					date: availableDay,
					state: 'Default',
					selected: false,
					today: moment(availableDay).format('YYYY-MM-DD') === todayDate.toString(),
				};
			});
			if (
				moment(availableWithMissingDays[0].date).format('YYYY-MM-DD') !== todayDate &&
				availableWithMissingDays.findIndex((item) => moment(item.date).format('YYYY-MM-DD') === todayDate) ===
					-1
			) {
				availableWithMissingDays.unshift({
					date: moment(todayDate).format('YYYY-MM-DD'),
					state: 'Disabled',
					selected: false,
					today: true,
				});
			}
			const missingDays: IDay[] = [];
			availableWithMissingDays.forEach((availableDay, index) => {
				let missignDaysQuantity: number = 0;
				if (index !== availableWithMissingDays.length - 1) {
					missignDaysQuantity = moment(availableWithMissingDays[index + 1].date).diff(
						moment(availableDay.date),
						'days'
					);
				}
				if (missignDaysQuantity > 1) {
					for (let index = 0; index < missignDaysQuantity - 1; index++) {
						missingDays.push({
							date: moment(availableDay.date)
								.add(index + 1, 'days')
								.format('YYYY-MM-DD'),
							state: 'Disabled',
							selected: false,
							today: false,
						});
					}
				}
			});
			if (missingDays.length > 0) {
				availableWithMissingDays = availableWithMissingDays
					.concat(missingDays)
					.sort((a, b) => moment(a.date).diff(moment(b.date)));
			}
			const firstAvailableDayIndex = availableWithMissingDays.findIndex((day) => {
				return day.state === 'Default';
			});
			availableWithMissingDays[firstAvailableDayIndex].selected = true;
			setAllDays(availableWithMissingDays);
			setSelectedDay(availableWithMissingDays[firstAvailableDayIndex]);
		}
	}, [availableDays]);

	useEffect(() => {
		if (allDays.length > 0 && disabledDates.length > 0) {
			const daysIndexesToDisable: number[] = [];
			disabledDates.forEach((disabledDate) => {
				const findedDay = allDays.findIndex((day) => {
					day.date === moment(disabledDate).format('YYYY-MM-DD');
				});
				if (findedDay > 0) daysIndexesToDisable.push(findedDay);
			});
			if (daysIndexesToDisable.length > 0) {
				const newAllDays = [...allDays];
				daysIndexesToDisable.forEach((dayIndex) => {
					newAllDays[dayIndex].state = 'Disabled';
				});
				setAllDays(newAllDays);
			}
		}
	}, [allDays, disabledDates]);

	useEffect(() => {
		const newAvailableSlots: ISlot[] = timeRanges.map((timeRange) => {
			const timeOfDayLabel = Object.keys(timeRange)[0];
			return {
				timeOfDayLabel: timeOfDayLabel.charAt(0).toUpperCase() + timeOfDayLabel.slice(1),
				hoursRangelabel: Object.values(timeRange)[0] ?? '',
				hours: [],
				state: 'Disabled',
				selected: false,
			};
		});

		availableHours.forEach((availableHour) => {
			const hour = availableHour.split(':')[0];
			timeRanges.forEach((timeRange, index) => {
				if (Number(timeRange.hourStartRange) <= Number(hour) && Number(hour) <= Number(timeRange.hourEndRange))
					newAvailableSlots[index].hours.push(availableHour);
			});
		});
		newAvailableSlots.forEach((slot) => {
			if (slot.hours.length > 0) slot.state = 'Default';
		});

		const isSlotAvailable = newAvailableSlots.some((slot) => {
			return slot.hours.length > 0;
		});
		setIsDaySlotsAvailable(isSlotAvailable);
		const firstavailableSlot = newAvailableSlots.findIndex((slot) => {
			return slot.state === 'Default';
		});
		if (firstavailableSlot > -1) {
			newAvailableSlots[firstavailableSlot].selected = true;
			setSlotSelected(firstavailableSlot, newAvailableSlots);
		}
		setAvailableSlots(newAvailableSlots);
		setLoading(false);
		dispatch(removeBusyIndicator(GET_PICKUP_BOOKING_HOURS));
	}, [availableHours]);

	return (
		<DateTimePickerWrapper>
			<MonthsTitleWrapper isTodayDateVisible={isTodayDateVisible}>
				<ConditionalRender
					show={!isTodayDateVisible}
					onTrue={() => (
						<SecondaryMediumButtonFlexNoBorder
							uniqueId="modal.pickup.booking.back.to.today"
							onClick={() => setVisibleDaysIndex(0)}
						>
							{translate('pickup-from-outlet.booking-modal.back-to-today.button')}
						</SecondaryMediumButtonFlexNoBorder>
					)}
				/>
				<MonthsTitle isTodayDateVisible={isTodayDateVisible}>{getDatesTitle}</MonthsTitle>
			</MonthsTitleWrapper>
			<CalendarDaysOuterWrapper>
				<Icon
					name="chevronLeft"
					fill={visibleDaysIndex === 0 ? 'black12' : 'black'}
					width={CHEVRON_ICON_SIZE}
					height={CHEVRON_ICON_SIZE}
					onClick={() => {
						if (visibleDaysIndex > 0) setVisibleDaysIndex(visibleDaysIndex - 1);
					}}
				/>
				<CalendarDaysInnerWrapper>{getDayItems}</CalendarDaysInnerWrapper>
				<Icon
					name="chevronRight"
					fill={
						visibleDaysIndex === availableDays.length - visibleDaysQuantity &&
						visibleDays.length >= visibleDaysQuantity
							? 'black12'
							: 'black'
					}
					width={CHEVRON_ICON_SIZE}
					height={CHEVRON_ICON_SIZE}
					onClick={() => {
						if (visibleDaysIndex < availableDays.length - visibleDaysQuantity)
							setVisibleDaysIndex(visibleDaysIndex + 1);
					}}
				/>
			</CalendarDaysOuterWrapper>
			<Divider />
			<ConditionalRender
				show={isDaySlotsAvailable}
				onTrue={() => (
					<>
						<BusyIndicator listener={GET_PICKUP_BOOKING_HOURS}>
							<ConditionalRender
								show={isDailyLimitExceeded}
								onTrue={() => (
									<WarningContainer>
										<Icon height={32} width={32} name="warningRedFilled" />
										<CaptionBigger color="warning">
											{translate(
												'pickup.appointment.error.message',
												moment(selectedDay.date).format('DD MMM YYYY')
											)}
										</CaptionBigger>
									</WarningContainer>
								)}
								onFalse={() => (
									<>
										<TimeRangeItemsWrapper isXs={isXS}>
											{availableSlots.map((availableSlot, index) => (
												<TimeRangeItem
													state={availableSlot.state}
													selected={availableSlot.selected}
													key={`${availableSlot.timeOfDayLabel}_${index}`}
													timeOfDayLabel={availableSlot.timeOfDayLabel}
													hoursRangelabel={availableSlot.hoursRangelabel}
													slotId={index}
													onClick={setSlotSelected}
												/>
											))}
										</TimeRangeItemsWrapper>
										<HoursItemsOuterWrapper>
											<AvailableSlots isXs={isXS}>
												{translate('pickup-from-outlet.booking-modal.available-label')}{' '}
												{selectedSlot && selectedSlot.timeOfDayLabel.toLocaleLowerCase()}{' '}
												{translate('pickup-from-outlet.booking-modal.slots-label')}{' '}
												{selectedSlot && selectedSlot.hours.length}
											</AvailableSlots>
											<HoursItemsInnerWrapper>
												<Icon
													name="chevronLeft"
													fill={visibleHoursIndex === 0 ? 'black12' : 'black'}
													width={CHEVRON_ICON_SIZE}
													height={CHEVRON_ICON_SIZE}
													onClick={() => {
														if (visibleHoursIndex > 0)
															setVisibleHoursIndex(visibleHoursIndex - 1);
													}}
												/>
												<SlotsWrapper>
													{visibleHours &&
														visibleHours.map((visibleHour, index) => (
															<HourItem
																selected={visibleHour.selected}
																hour={visibleHour.hour}
																key={`${visibleHour.hour}_${index}`}
																onClick={setHourSelected}
																hourId={visibleHour.index}
															/>
														))}
												</SlotsWrapper>
												<Icon
													name="chevronRight"
													fill={
														selectedSlot &&
														visibleHoursIndex === allHours.length - visibleHoursQuantity &&
														visibleHours.length >= visibleHoursQuantity
															? 'black12'
															: 'black'
													}
													width={CHEVRON_ICON_SIZE}
													height={CHEVRON_ICON_SIZE}
													onClick={() => {
														if (
															selectedSlot &&
															visibleHoursIndex < allHours.length - visibleHoursQuantity
														)
															setVisibleHoursIndex(visibleHoursIndex + 1);
													}}
												/>
											</HoursItemsInnerWrapper>
										</HoursItemsOuterWrapper>
									</>
								)}
							/>
						</BusyIndicator>
						<ButtonsWrapper isXs={isXS}>
							<SecondaryMediumButtonFixedNoBorder
								uniqueId="modal.pickup.booking.cancel"
								disabled={loading}
								onClick={() => {
									dispatch(
										setModal({
											type: null,
											data: null,
										})
									);
								}}
							>
								{translate('pickup-from-outlet.booking-modal.cancel')}
							</SecondaryMediumButtonFixedNoBorder>
							<PrimaryMediumButtonFixed
								uniqueId="modal.pickup.booking.book"
								listener={loading}
								onClick={() => {
									if (!!bookingPickupAppointment) {
										cancelCurrentAppointment()
											.then(() => {
												bookAppointment();
											})
											.catch(() => {});
									} else {
										bookAppointment();
									}
								}}
							>
								{translate('book-appointment.outlet.book-button')}
							</PrimaryMediumButtonFixed>
						</ButtonsWrapper>
					</>
				)}
				onFalse={() => (
					<ConditionalRender
						show={!loading}
						onTrue={() => (
							<UnavailableAppointmentWrapper>
								<Icon
									name="calendar"
									fill="black24"
									width={CALENDAR_ICON_SIZE}
									height={CALENDAR_ICON_SIZE}
									onClick={() => {}}
								/>
								<UnavailableAppointmentInfo>
									{translate('pickup-from-outlet.unavailable-appointments')}{' '}
									{selectedDay && moment(selectedDay.date).format('dddd')},{' '}
									{selectedDay && getDayOfMonth(selectedDay.date)}
									{translate('pickup-from-outlet.th-of')}
									{selectedDay && moment(selectedDay.date).format('MMMM')},{' '}
									{selectedDay && moment(selectedDay.date).format('YYYY')}
									{'. '}
									{translate('pickup-from-outlet.different-day')}
								</UnavailableAppointmentInfo>
								<SecondaryMediumButtonFlexNoBorder
									uniqueId="modal.pickup.booking.find.available.appointment"
									onClick={() => findFirstAvailableAppointment()}
								>
									{translate('pickup-from-outlet.find-first-available-day')}
								</SecondaryMediumButtonFlexNoBorder>
							</UnavailableAppointmentWrapper>
						)}
					/>
				)}
			/>
		</DateTimePickerWrapper>
	);
};

export default DateTimePicker;
