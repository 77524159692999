interface IInputModel {
	_response: {
		returnValue: string;
	};
}

export interface IValidateOTP {
	isOK: boolean;
}

const model: types.models.IInputModel = {
	_response: ['response.arguments', {}],
	isOK: (model: IInputModel) => model._response.returnValue === 'true',
};

export default model;
