import React, { useEffect } from 'react';
import { useInsurance } from '@Utils/context/InsuranceContext';
import useTranslate from '@Utils/hooks/useTranslate';
import { Row, Col } from 'react-grid-system';
import TextField from '@Components/controls/TextField/TextField';
import { useFormikContext } from 'formik';
import { IFormikInsurerFields } from '@Views/CarInsurance/CarInsurance';
import { OwnerNameTitle } from './InsuranceInsurerContact.styled';

const InsuranceInsurerContact = (): JSX.Element => {
	const { firstName, lastName, setOwnerDataFormErrors, email, setFirstName, setLastName, setEmail } = useInsurance();

	const { translate } = useTranslate();

	const { touched, errors, setFieldTouched, setFieldValue } = useFormikContext<IFormikInsurerFields>();

	useEffect(() => {
		setOwnerDataFormErrors({ errors, touched });
	}, [errors, touched]);

	return (
		<>
			<Row align="center" justify="center">
				<Col>
					<OwnerNameTitle>{translate('insurance.insurer.contact.owner-name.label')}</OwnerNameTitle>
					<TextField
						id="firstName"
						placeholder={translate('insurance.insurer.name.placeholder')}
						label={translate('insurance.insurer.name.placeholder')}
						value={firstName}
						onChange={(value) => {
							setFieldTouched('firstName', true);
							setFieldValue('firstName', value.toString());
							setFirstName(value.toString());
						}}
						marginBottom={8}
						message={
							!!errors.firstName && touched.firstName
								? translate('validate.personal.data.first.name.required')
								: ''
						}
						error={!!errors.firstName && touched.firstName}
						width="100%"
						name="firstName"
					/>
					<TextField
						id="lastName"
						placeholder={translate('insurance.insurer.last-name.placeholder')}
						label={translate('insurance.insurer.last-name.placeholder')}
						value={lastName}
						onChange={(value) => {
							setFieldTouched('lastName', true);
							setFieldValue('lastName', value.toString());
							setLastName(value.toString());
						}}
						marginBottom={16}
						message={
							!!errors.lastName && touched.lastName
								? translate('validate.personal.data.last.name.required')
								: ''
						}
						error={!!errors.lastName && touched.lastName}
						width="100%"
						name="lastName"
					/>
					<TextField
						id="email"
						placeholder={translate('notify.me.label.EMAIL')}
						label={translate('notify.me.label.EMAIL')}
						value={email}
						onChange={(value) => {
							setFieldTouched('email', true);
							setFieldValue('email', value.toString());
							setEmail(value.toString());
						}}
						marginBottom={16}
						message={
							!!errors.email && touched.email ? translate('insurance.owner.data.incorrect.email') : ''
						}
						error={!!errors.email && touched.email}
						width="100%"
						name="email"
					/>
				</Col>
			</Row>
		</>
	);
};

export default InsuranceInsurerContact;
