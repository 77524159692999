import React, { FunctionComponent } from 'react';
import { HourSlot, Label } from './HourItem.styled';

export interface IHourItemProps {
	selected: boolean;
}

interface IHourItemExtendedProps extends IHourItemProps {
	hourId: number;
	hour: string;
	onClick: (hourId: number) => void;
}

const HourItem: FunctionComponent<IHourItemExtendedProps> = ({ selected, hour, hourId, onClick }) => {
	return (
		<HourSlot selected={selected} onClick={() => onClick(hourId)}>
			<Label selected={selected}>{hour}</Label>
		</HourSlot>
	);
};

export default HourItem;
