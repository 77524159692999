import styled from 'styled-components';
import { NAVBAR_HEIGHT } from '@Layouts/Dashboard/Dashboard';
import { Header, MiniDescription, HeadlineL } from '@Components/Typography/Typography.styled';
import { isLargeScreen, matchScreen, paddingLeft } from '@Utils/layout/layoutSupport';

export const OmantelMap = styled.iframe`
	width: 100%;
	${() => {
		const isSmallDevice = matchScreen(['xs', 'sm']);
		return {
			height: 660,
			maxHeight: `calc(100vh - ${NAVBAR_HEIGHT - (isLargeScreen() ? 0 : isSmallDevice ? 56 : 12)})`,
		};
	}}
`;

export const Title = styled(HeadlineL)`
	width: 100%;
	padding-bottom: 24px;
	${({ theme }) => ({
		color: theme.colors.primary,
	})}
`;

export const Subtitle = styled(Header)`
	width: 100%;
	padding-bottom: 24px;
	${({ theme }) => ({
		color: theme.colors.black54,
	})}
`;

export const Description = styled(MiniDescription)`
	width: 100%;
	${({ theme }) => ({
		color: theme.colors.black87,
	})}
	${() => paddingLeft(8)}
`;

export const DescWrapper = styled.div`
	width: 100%;
	padding-bottom: 24px;
	display: flex;
`;
