interface IInputObj {
	[key: string]: any;
}

export default function (obj: IInputObj, ...paths: (string | number)[]): boolean {
	if (obj === Object(obj)) {
		for (const path of paths) {
			if (!obj.hasOwnProperty(path)) {
				return false;
			}
			obj = obj[path];
		}
		return true;
	}
	return false;
}
