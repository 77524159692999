import { updateHeader, IHeader, deleteWCTokens } from './actions';
import immerHandleActions from '@Redux/utils/immerHandleActions';

export interface IXOmtHeaders {
	[name: string]: string | undefined;
}

export const INITIAL_HEADERS: IXOmtHeaders = {
	'Content-Type': 'application/json; charset=utf-8',
	Accept: 'application/json',
	'Access-Control-Allow-Origin': '*',
};

const reducers = {
	[updateHeader as any](draft: IXOmtHeaders, action: { payload: IHeader }) {
		const { name, value } = action.payload;
		if (draft[name] !== value) {
			draft[name] = value;
		}
		return draft;
	},
	[deleteWCTokens.toString()](draft: IXOmtHeaders) {
		delete draft.WCToken;
		delete draft.WCTrustedToken;
		delete draft.WCPersonalization;
		return draft;
	},
};

export default immerHandleActions(reducers, INITIAL_HEADERS);
