import Divider from '@Components/Divider/Divider';
import Footer from '@Components/Footer/Footer';
import NavigationBar from '@Components/NavigationBar/NavigationBar';
import { NAVBAR_HEIGHT } from '@Layouts/Dashboard/Dashboard';
import PrepaidMigrationContextProvider from '@Utils/context/PrepaidMigrationContext';
import Contact from '@Views/Contact/Contact';
import Plans from '@Views/Plans/Plans';
import PrepaidMigration from '@Views/PrepaidMigration/PrepaidMigration';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { Route, Switch } from 'react-router-dom';
import { CONTACT_PATH, PLANS_PATH, PREPAID_MIGRATION_PATH } from '../../App';
import { Content } from './NoBreadcrumbsLayout.styled';
import { isLargeScreen, matchScreen } from '@Utils/layout/layoutSupport';

const NAVBAR_HEIGHT_WITH_PADDING = NAVBAR_HEIGHT;

const NoBreadcrumbsLayout: FunctionComponent = () => {
	const [footerHeight, setFooterHeight] = useState<number>(0);
	const isSmallDevice = matchScreen(['xs', 'sm']);

	useEffect(() => {
		setTimeout(() => {
			window.scrollTo({ top: 0 });
		}, 500);
	});

	return (
		<Content footerHeight={footerHeight}>
			<PrepaidMigrationContextProvider>
				<NavigationBar />
				<Divider marginBottom={NAVBAR_HEIGHT_WITH_PADDING - (isLargeScreen() ? 0 : isSmallDevice ? 56 : 12)} />
				<Switch>
					<Route path={CONTACT_PATH}>
						<Contact />
					</Route>
					<Route path={PLANS_PATH}>
						<Plans />
					</Route>
					<Route path={PREPAID_MIGRATION_PATH}>
						<PrepaidMigration />
					</Route>
				</Switch>
				<Footer setFooterHeight={setFooterHeight} />
			</PrepaidMigrationContextProvider>
		</Content>
	);
};

export default NoBreadcrumbsLayout;
