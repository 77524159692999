import { STORE_ID } from '@Config/app.config';
import { getLanguageCode } from '@Utils/language/language';

export const getProfile = (): types.endpoints.IEndpointConfig => {
	return {
		url: `/wcs/resources/store/${STORE_ID}/person/@self`,
	};
};

export const updateProfile = (nickName: string): types.endpoints.IEndpointConfig => {
	return {
		method: 'PUT',
		url: `/wcs/resources/store/${STORE_ID}/person/@self/contact/${nickName}?langId=${getLanguageCode()}`,
	};
};

export const addContactToProfile = (): types.endpoints.IEndpointConfig => {
	return {
		method: 'POST',
		url: `/wcs/resources/store/${STORE_ID}/person/@self/contact?langId=${getLanguageCode()}`,
	};
};

export const deleteUserAddress = (nickName: string): types.endpoints.IEndpointConfig => {
	return {
		method: 'DELETE',
		url: `/wcs/resources/store/${STORE_ID}/person/@self/contact/${nickName}`,
	};
};

export const getOmantelIdentity = (): types.endpoints.IEndpointConfig => {
	return {
		method: 'POST',
		url: `/wcs/resources/store/${STORE_ID}/omantelidentity`,
	};
};
