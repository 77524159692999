import React, { Dispatch, FunctionComponent, SetStateAction, useEffect, useState } from 'react';
import TextField from '@Components/controls/TextField/TextField';
import {
	Container,
	ResultsContainer,
	ResultsDescription,
	ResultsWrapper,
	EmptyStateWrapper,
	ContentWrapper,
	PopularProductsWrapper,
	PopularProductsColWrapper,
	PopularProductsParentWrapper,
	SearchButtonsWrapper,
	SearchButton,
	SearchSwitchItemWrapper,
	SearchSwitchContainer,
} from '@Components/MegaMenu/subcomponents/Search/MegaMenuSearch.styled';
import useTranslate from '@Utils/hooks/useTranslate';
import { TypeaheadService } from '@Services/typeahead/typeahead';
import useDebounceValue from '@Utils/hooks/useDebounceValue';
import ConditionalRender from '@Components/ConditionalRender/ConditionalRender';
import BusyIndicator from '@Components/BusyIndicator/BusyIndicator';
import { useDispatch } from 'react-redux';
import { addBusyIndicator, removeBusyIndicator } from '@Redux/modules/busyIndicator/actions';
import { ITypeaheadProductsBySearchTerm } from '@ApiModels/typeaheadProductsBySearchTerm';
import ITypeaheadDataBySearchTerm from '@ApiModels/typeaheadDataBySearchTerm';

import { Col, Row } from 'react-grid-system';
import { useHistory } from 'react-router-dom';
import { EspotService } from '@Services/espot/espot';
import Card from '@Components/Card/Card';
import { ISinglePopularProduct } from '@ApiModels/popularProducts';
import useScreen from '@Utils/hooks/useScreen';
import { getLang } from '@Utils/language/language';
import { IFilterEntry } from '@ApiModels/productsByCategoryId';
import { clearFilters, enableFilter } from '@Redux/modules/filters/actions';
import { clearPriceFilter } from '@Redux/modules/priceFilter/actions';

import { XMLParser } from 'fast-xml-parser';
import MegaMenuMultilevelItem, {
	MegaMenuMultilevelItemType,
} from '@Components/MegaMenuMultilevelItem/MegaMenuMultilevelItem';
import { ISearchMenuList } from '@ApiModels/searchMenuList';
import { BodyLBoldPP } from '@Components/Typography/Typography.styled';
import { ENABLE_ESHOP_LITE } from '@Config/features.config';

interface IMegaMenuSearchProps {
	setFocus: Dispatch<SetStateAction<boolean>>;
	forceClose: () => void;
	mobileMenu: boolean;
}

const MAX_DISPLAY_ELEMENTS = 7;
const SEARCH_MENU_FEATURED = 'SEARCH_MENU_FEATURED';
const SEARCH_MENU_OFFER = 'SEARCH_MENU_OFFER';
const TYPEAHEAD_BI = 'GET_TYPEAHEAD_BI';
const GET_POPULAR_PRODUCTS_BI = 'GET_POPULAR_PRODUCTS_BI';

const MegaMenuSearch: FunctionComponent<IMegaMenuSearchProps> = ({ setFocus, forceClose }) => {
	const [typeaheadResults, setTypeaheadResults] = useState<
		(ITypeaheadProductsBySearchTerm | ITypeaheadDataBySearchTerm)[] | null
	>(null);
	const [searchInput, setSearchInput] = useState<string | number>('');
	const [featured, setFeatured] = useState<ISearchMenuList>(([] as unknown) as ISearchMenuList);
	const [offer, setOffer] = useState<ISearchMenuList>(([] as unknown) as ISearchMenuList);
	const [popularProducts, setPopularProducts] = useState<ISinglePopularProduct[]>([]);
	const [searchEngine, setSearchEngine] = useState<'store' | 'portal'>('store');
	const { screen } = useScreen();
	const { translate } = useTranslate();

	const dispatch = useDispatch();
	const history = useHistory();
	const debouncedSearchInput = useDebounceValue(searchInput.toString(), 500);

	const handleChange = (e: string | number) => {
		setSearchInput(e);
	};

	const handleOnFocus = () => {
		setFocus(true);
	};

	const handleOnBlur = () => {
		setFocus(false);
	};

	const handleOnEnter = () => {
		setFocus(false);
		forceClose();
		history.push(`/${getLang()}/store?search=${searchInput}`);
		setSearchInput('');
	};

	const handleClearInput = () => {
		setSearchInput('');
	};

	useEffect(() => {
		dispatch(addBusyIndicator(SEARCH_MENU_FEATURED));
		EspotService.getSearchMenuList('SearchMenuFeatured').subscribe(
			(r) => {
				dispatch(removeBusyIndicator(SEARCH_MENU_FEATURED));
				setFeatured(r);
			},
			() => {
				dispatch(removeBusyIndicator(SEARCH_MENU_FEATURED));
			}
		);
	}, []);

	useEffect(() => {
		dispatch(addBusyIndicator(SEARCH_MENU_OFFER));
		EspotService.getSearchMenuList('SearchMenuOffer').subscribe(
			(r) => {
				dispatch(removeBusyIndicator(SEARCH_MENU_OFFER));
				setOffer(r);
			},
			() => {
				dispatch(removeBusyIndicator(SEARCH_MENU_OFFER));
			}
		);
	}, []);

	useEffect(() => {
		const getTypeaheadProducts = (input: string) => {
			searchEngine === 'store'
				? TypeaheadService.getProductSuggestionsBySearchTerm(input).subscribe(
						(r) => {
							dispatch(removeBusyIndicator(TYPEAHEAD_BI));
							setTypeaheadResults(r.items);
						},
						() => {
							dispatch(removeBusyIndicator(TYPEAHEAD_BI));
						}
				  )
				: TypeaheadService.getProductSuggestionsBySearchTermFromPortal(input).subscribe(
						(response) => {
							const searchResults = response.response?.documentElement;
							if (searchResults) {
								const atomSearchResult = new XMLParser({
									parseTagValue: true,
									processEntities: true,
									parseAttributeValue: true,
									ignoreAttributes: false,
								}).parse(new XMLSerializer().serializeToString(searchResults));
								const atomEntries: [] = atomSearchResult['atom:feed']['atom:entry'];
								if (atomEntries) {
									const entriesFromPortal: ITypeaheadDataBySearchTerm[] = [];
									atomEntries.forEach((item) =>
										entriesFromPortal.push({
											uniqueID: item['atom:id'],
											title: item['atom:title']['#text'],
											summary: item['atom:summary']['#text'],
											link: `https://portal.omantel.om/${item['atom:link'][0]['@_href']}`,
										})
									);
									setTypeaheadResults(entriesFromPortal);
								}
								dispatch(removeBusyIndicator(TYPEAHEAD_BI));
							}
						},
						() => {
							dispatch(removeBusyIndicator(TYPEAHEAD_BI));
						}
				  );
			return;
		};
		if (debouncedSearchInput.toString().length >= 3) {
			dispatch(addBusyIndicator(TYPEAHEAD_BI));
			getTypeaheadProducts(debouncedSearchInput);
		} else {
			setTypeaheadResults(null);
		}
	}, [debouncedSearchInput]);

	useEffect(() => {
		if (!ENABLE_ESHOP_LITE) {
			dispatch(addBusyIndicator(GET_POPULAR_PRODUCTS_BI));
			EspotService.getPopularProducts().subscribe(
				(r) => {
					dispatch(removeBusyIndicator(GET_POPULAR_PRODUCTS_BI));
					setPopularProducts(r.products);
				},
				() => {
					dispatch(removeBusyIndicator(GET_POPULAR_PRODUCTS_BI));
				}
			);
		}
	}, []);

	const handleOnClick = (link: string) => {
		forceClose();
		history.push(link);

		const filter = link.split('filter=')[1] || false;
		if (filter) {
			dispatch(clearFilters());
			dispatch(clearPriceFilter());
			enableQueryFilter(filter);
		}
	};

	const enableQueryFilter = (uniqueId: string) => {
		const entry: IFilterEntry = {
			enabled: true,
			parsedValue: '',
			label: '',
			value: '',
			count: '',
			extendedData: {
				uniqueId,
			},
		};
		dispatch(enableFilter(entry));
	};

	const handleSearchOnClick = (search: string) => {
		if (searchEngine === 'store') {
			setSearchInput(search);
			forceClose();
			history.push(`/${getLang()}/store?search=${search}`);
		}
		if (searchEngine === 'portal') {
			forceClose();
			window.location.href = search;
		}
	};

	const SearchResults = () => (
		<ResultsWrapper xs={12} md={12}>
			{typeaheadResults &&
				typeaheadResults.map((item: ITypeaheadProductsBySearchTerm | ITypeaheadDataBySearchTerm, index) => {
					if (index <= MAX_DISPLAY_ELEMENTS && searchEngine === 'store' && 'name' in item) {
						return (
							<MegaMenuMultilevelItem
								key={item.uniqueID}
								name={item.name}
								isSearch
								onClick={() => handleSearchOnClick(item.name)}
								type={MegaMenuMultilevelItemType.ITEM}
							/>
						);
					}
					if (index <= MAX_DISPLAY_ELEMENTS && searchEngine === 'portal' && 'summary' in item) {
						return (
							<MegaMenuMultilevelItem
								key={item.uniqueID}
								name={item.title}
								isSearch
								onClick={() => handleSearchOnClick(item.link)}
								type={MegaMenuMultilevelItemType.ITEM}
							/>
						);
					}
				})}
		</ResultsWrapper>
	);

	const EmptyState = () => (
		<EmptyStateWrapper xs={12} md={12}>
			<ResultsDescription>{translate('megamenu.search.no-results')}</ResultsDescription>
		</EmptyStateWrapper>
	);

	const RecommendedSearchResults = () => (
		<Row>
			<Col xs={8} lg={4}>
				<BusyIndicator listener={[SEARCH_MENU_FEATURED]} skeleton="searchListResults">
					<>
						<MegaMenuMultilevelItem
							key="featured"
							isSearch
							name={translate('megamenu.search.featured')}
							type={MegaMenuMultilevelItemType.HEADER}
							paddingTop={0}
						/>
						{featured?.list?.map((item) => (
							<MegaMenuMultilevelItem
								key={item.label}
								isSearch
								name={item.label || ''}
								onClick={() => {
									if (item.link) handleOnClick(item.link);
								}}
								type={MegaMenuMultilevelItemType.SUB_ITEM}
								paddingTop={item.isHeader ? 0 : 8}
								to={item.link}
							/>
						))}
					</>
				</BusyIndicator>
			</Col>
			<Col hidden={screen(['xs'])} xs={6} md={6}>
				<BusyIndicator listener={[SEARCH_MENU_OFFER]} skeleton="searchListResults">
					<>
						<MegaMenuMultilevelItem
							key="offer"
							isSearch
							name={translate('megamenu.search.offer')}
							type={MegaMenuMultilevelItemType.HEADER}
							paddingTop={0}
						/>
						{offer?.list?.map((item) => (
							<MegaMenuMultilevelItem
								key={item.label}
								isSearch
								name={item.label || ''}
								onClick={() => {
									if (item.link) handleOnClick(item.link);
								}}
								type={MegaMenuMultilevelItemType.SUB_ITEM}
								paddingTop={item.isHeader ? 0 : 8}
								to={item.link}
							/>
						))}
					</>
				</BusyIndicator>
			</Col>
		</Row>
	);

	const PopularProductCard = ({ productDetails }: { productDetails: ISinglePopularProduct }) => (
		<Card
			card="popularProduct"
			withoutContainerPadding
			marginBottom={8}
			params={{ productDetails, forceClose, isSearch: true }}
		/>
	);

	const SearchOption = () => (
		<SearchButtonsWrapper isColumn={screen(['xs', 'sm', 'md'])}>
			<SearchButton isActive={searchEngine === 'portal'} onClick={() => setSearchEngine('portal')}>
				{translate('megamenu.search.tab.portal')}
			</SearchButton>
			<SearchButton isActive={searchEngine === 'store'} onClick={() => setSearchEngine('store')}>
				{translate('megamenu.search.tab.store')}
			</SearchButton>
		</SearchButtonsWrapper>
	);

	const ContentColumn = () => (
		<PopularProductsParentWrapper xs={12} md={6}>
			<BusyIndicator listener={[TYPEAHEAD_BI]} skeleton="searchResult">
				<ConditionalRender
					show={!typeaheadResults}
					onTrue={() => <RecommendedSearchResults />}
					onFalse={() => (
						<ConditionalRender
							show={Array.isArray(typeaheadResults) && typeaheadResults?.length > 0}
							onTrue={() => <SearchResults />}
							onFalse={() => <EmptyState />}
						/>
					)}
				/>
			</BusyIndicator>
		</PopularProductsParentWrapper>
	);
	const ProductsColumn = () => (
		<PopularProductsParentWrapper xs={12} md={6}>
			<PopularProductsWrapper>
				<PopularProductsColWrapper xs={12} isXs height={56} header={true}>
					<BodyLBoldPP>{translate('megamenu.search.popular-products')}</BodyLBoldPP>
				</PopularProductsColWrapper>
				<PopularProductsColWrapper xs={12} sm={6} md={12} lg={6} isXs>
					<BusyIndicator listener={[GET_POPULAR_PRODUCTS_BI]} skeleton="popularProducts">
						<>
							{popularProducts.slice(0, screen(['xs', 'sm']) ? 2 : 4).map((productDetails) => (
								<PopularProductCard key={productDetails.uniqueID} productDetails={productDetails} />
							))}
						</>
					</BusyIndicator>
				</PopularProductsColWrapper>
				<ConditionalRender
					show={!screen(['xs', 'md'])}
					onTrue={() => (
						<PopularProductsColWrapper xs={12} sm={6} md={6} isXs>
							<BusyIndicator listener={[GET_POPULAR_PRODUCTS_BI]} skeleton="popularProducts">
								<>
									{popularProducts
										.slice(screen(['sm']) ? 2 : 4, screen(['sm']) ? 4 : 8)
										.map((productDetails) => (
											<PopularProductCard
												key={productDetails.uniqueID}
												productDetails={productDetails}
											/>
										))}
								</>
							</BusyIndicator>
						</PopularProductsColWrapper>
					)}
				/>
			</PopularProductsWrapper>
		</PopularProductsParentWrapper>
	);

	return (
		<Container>
			<SearchSwitchContainer isColumn={screen(['xs', 'sm', 'md'])} isMD={screen(['md'])}>
				<Col lg={6} md={8} xl={4}>
					<SearchSwitchItemWrapper isColumn={screen(['xs', 'sm', 'md'])} isMD={screen(['md'])}>
						<TextField
							onFocus={handleOnFocus}
							onBlur={handleOnBlur}
							onChange={(e) => handleChange(e)}
							value={searchInput}
							id="search"
							placeholder={translate('megamenu.search.input.placeholder')}
							rightIcon={searchInput ? 'clearInput' : undefined}
							leftIcon="searchBig"
							onIconClick={handleClearInput}
							width={screen(['xs', 'sm']) ? '100%' : 'unset'}
							height={40}
							onEnter={handleOnEnter}
							leftIconFill="white"
							typography="paragraphM"
							borderColor="grey300"
							hoverColor="primary"
						/>
					</SearchSwitchItemWrapper>
				</Col>
				<Col lg={6} md={8} xl={5}>
					<SearchOption />
				</Col>
			</SearchSwitchContainer>
			<ResultsContainer isMdXl={screen(['md', 'lg', 'xl'])}>
				<ContentWrapper>
					<ConditionalRender
						show={screen(['xs', 'sm'])}
						onTrue={() => (
							<>
								<ConditionalRender
									show={
										(screen(['xs']) && !debouncedSearchInput && !ENABLE_ESHOP_LITE) ||
										(!screen(['xs']) && !ENABLE_ESHOP_LITE)
									}
									onTrue={() => <ProductsColumn />}
								/>
								<ContentColumn />
							</>
						)}
						onFalse={() => (
							<>
								<ContentColumn />
								<ConditionalRender
									show={!ENABLE_ESHOP_LITE}
									onTrue={() => <ProductsColumn />}
								></ConditionalRender>
							</>
						)}
					/>
				</ContentWrapper>
			</ResultsContainer>
		</Container>
	);
};

export default MegaMenuSearch;
