import styled from 'styled-components';
import { CaptionBold } from '@Components/Typography/Typography.styled';
import { left } from '@Utils/layout/layoutSupport';

export const StepTitle = styled(CaptionBold)`
	${({ theme }) => ({
		color: theme.colors.black87,
	})}
	text-align: center;
	margin-top: 12px;
`;

export const StepContainer = styled.div<{ isXs: boolean }>`
	${({ isXs }) => ({
		width: isXs ? 66 : 146,
		justifyContent: isXs ? 'center' : 'flex-end',
	})}

	align-items: center;
	justify-content: flex-end;
	margin-top: 8px;
`;

export const StepWrapper = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
`;

export const StepDivider = styled.div<{ isXs: boolean; active?: boolean }>`
	position: relative;
	top: -11px;
	border-top: 1px solid;
	border-color: ${({ theme, active }) => theme.colors[active ? 'primary' : 'black12']};
	${({ isXs }) => ({
		width: isXs ? 32 : 114,
	})}
	${({ isXs }) => left(isXs ? 50 : 89)}
`;

export const StepperWrapper = styled.div`
	display: flex;
	justify-content: center;
	margin-bottom: 32px;
`;
