import { setBuyNowPayLater, IBuyNowPayLater } from './actions';
import immerHandleActions from '@Redux/utils/immerHandleActions';

const init = {
	buyNowPayLaterSessionId: '',
	buyNowPayLaterIframeUrl: '',
	isBuyNowPayLaterIframe: false,
};

const reducers = {
	[setBuyNowPayLater.toString()](draft: IBuyNowPayLater, action: { payload: IBuyNowPayLater }) {
		const { buyNowPayLaterSessionId, buyNowPayLaterIframeUrl, isBuyNowPayLaterIframe } = action.payload;
		draft.buyNowPayLaterSessionId = buyNowPayLaterSessionId;
		draft.buyNowPayLaterIframeUrl = buyNowPayLaterIframeUrl;
		draft.isBuyNowPayLaterIframe = isBuyNowPayLaterIframe;
		return draft;
	},
};

export default immerHandleActions(reducers, init);
