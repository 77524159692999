import React, { FunctionComponent } from 'react';
import { Circle, DisplayFlex, Rectangular, SkeletonCardContainer } from '@Components/Skeleton/Skeleton.styled';

const PaymentTokens: FunctionComponent = () => {
	return (
		<SkeletonCardContainer>
			<DisplayFlex marginLeft={16} marginBottom={16}>
				<Circle width={24} height={24} />
				<Rectangular width={112} height={24} marginLeft={16} />
			</DisplayFlex>
			<Rectangular width="100%" height={64} marginBottom={16} />
			<DisplayFlex marginLeft={16} marginBottom={16}>
				<Circle width={24} height={24} />
				<Rectangular width={112} height={24} marginLeft={16} />
			</DisplayFlex>
		</SkeletonCardContainer>
	);
};

export default PaymentTokens;
