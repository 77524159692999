import React, { FunctionComponent, useEffect, useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import useTranslate from '@Utils/hooks/useTranslate';
import { setModal } from '@Redux/modules/modal/actions';
import { useDispatch } from 'react-redux';
import { Col, Row } from 'react-grid-system';
import {
	ActionWrapper,
	CardContainer,
	CardWrapper,
	Header,
	LogoWrapper,
	PlanComparisonWrapper,
	RecomendedPlan,
	OtherPlansScroll,
	PlanBarWrapper,
	HorizontalPlansScroll,
	XSPlanSliderTitle,
	XSPlanSliderSubtitle,
} from './PlanSelector.styled';
import Icon from '@Components/Icon/Icon';
import PlanComparison, { IPlanComparison } from '@Components/PlanComparison/PlanComparison';
import { Subheading } from '@Components/Typography/Typography';
import PlanBar from '@Components/PlanBar/PlanBar';
import moment from 'moment';
import { ISinglePackage } from '@ApiModels/singlePackage';
import useScreen from '@Utils/hooks/useScreen';
import ConditionalRender from '@Components/ConditionalRender/ConditionalRender';
import Divider from '@Components/Divider/Divider';
import { getBarType, getBarTypeByPlan } from '@Utils/plans/barType';
import BusyIndicator from '@Components/BusyIndicator/BusyIndicator';
import Card from '@Components/Card/Card';
import {
	CHECK_IF_CAN_GET_NEW_PLAN,
	EVENT_UPGRADE_NOW,
	GET_ACCOUNT_FOR_LOGGED_USER,
	GET_CUSTOMER_PRODUCTS,
	GET_HIGHER_PLAN,
	GET_PLANS,
	GET_RECOMMENDED_PLAN,
	INFINITE_LOADING,
	NOT_CHOSEN_NUMBER,
	PlanType,
	VALIDATE_CHANGED_INSTALLMENT_SKUS,
} from '../Plan/Plan';
import { GET_COMPONENTS_FROM_INVENTORY_BI, GET_PRODUCT_DETAILS_BI } from '@Views/ProductPage/ProductPage';

const PlanSelectorCard: FunctionComponent<types.cards.IPlanSelectorCardProps> = ({
	params: {
		plansToCompare,
		otherPlans,
		recommendedPlan,
		isProductCardRoute,
		setSelectedPlan,
		defaultSelectedPlanIndex,
		productAllSKU,
		selectedFilters,
		currentPlanIsHBB,
		currentPlanIsPrepaid,
		pricesWithVat,
		setSelectedPlanInstallment,
		selectedProductName,
		emmitEvent,
	},
}) => {
	const [selectedPlanToCompare, setSelectedPlanToCompare] = useState<ISinglePackage>();
	const [selectedPlanIndex, setSelectedPlanIndex] = useState<number | undefined>(defaultSelectedPlanIndex);
	const [selectedPlanId, setSelectedPlanId] = useState<string>();
	const [tempPlansToCompare, setTempPlansToCompare] = useState<ISinglePackage[]>([...plansToCompare]);
	const [installmentPeriod, setInstallmentPeriod] = useState<'12' | '24'>();
	const planType = useSelector((state: types.redux.IState) => state.selectedItems.planType as PlanType);
	const isLogged = !!useSelector((state: types.redux.IState) => state.api.authentication.signedIn);
	const currentPlan = plansToCompare[0];

	const planSelectorFilters = {
		selectedColorId: selectedFilters.selectedColorId,
		selectedValueId: selectedFilters.selectedValueId,
		installmentPeriod: installmentPeriod || selectedFilters.installmentPeriod || '24',
		selectedPlanId:
			selectedPlanId ||
			(selectedPlanIndex && otherPlans[selectedPlanIndex + 1].partNumberRaw) ||
			recommendedPlan.partNumberRaw,
	};

	const { translate } = useTranslate();
	const dispatch = useDispatch();
	const { screen } = useScreen();

	useEffect(() => {
		if (plansToCompare && selectedPlanToCompare) {
			setTempPlansToCompare([currentPlan, selectedPlanToCompare]);
		}
	}, [selectedPlanToCompare]);

	const setOnPlanSelection = (columnIndex: number) => {
		const selectedPlan = tempPlansToCompare[columnIndex];
		setSelectedPlan(tempPlansToCompare[columnIndex], selectedPlanIndex);
		if (columnIndex === 1) {
			setSelectedPlanInstallment?.(installmentPeriod);
		}
		handleClose();
		emmitEvent?.(EVENT_UPGRADE_NOW, currentPlan.hierarchyIndex !== selectedPlan.hierarchyIndex);
	};

	const handleClose = () => {
		dispatch(
			setModal({
				type: null,
				data: null,
			})
		);
	};

	const getPlansToCompare = (): IPlanComparison[] => {
		return tempPlansToCompare.map((plan, index) => {
			return {
				id: plan.uniqueID,
				type: getBarTypeByPlan(plan),
				name: plan.shortDescription,
				contractCommitment: plan.duration
					? translate('plan.selector.commitment', String(plan.duration))
					: translate('plan.selector.no.commitment'),
				isCurrentPlan: index === 0,
				isRecommendedPlan: index > 0,
				monthlyPayment: plan.price,
				localAndGccData:
					plan.planDetails?.includedData?.itemValue === '-'
						? 'Unlimited'
						: plan.planDetails?.includedData?.itemValue,
				localMinutes:
					plan.planDetails?.includedMinutes?.itemValue === '-'
						? 'Unlimited'
						: plan.planDetails?.includedMinutes?.itemValue,
				internationalMinutes: plan.planDetails?.intlMinutes?.itemValue,
				roamingRecievingMinutes: plan.planDetails?.roamingRecMinutes?.itemValue,
				worldRoamingData: plan.planDetails?.roamingData?.itemValue,
				dataSharing: plan.planDetails?.dataSharing?.itemValue,
				storeCredit: plan.planDetails?.promoAppStore?.itemValue,
				keepThisPlan: index === 0,
				selectPlan: index > 0,
				dataIncluded:
					plan.planDetails?.data?.itemValue || plan?.planDetails?.hbbDataIncluded?.itemValue === '-'
						? 'Unlimited'
						: plan.planDetails?.hbbDataIncluded?.itemValue,
				downloadSpeed: plan.planDetails?.downloadSpeed?.itemValue,
				uploadSpeed: plan.planDetails?.uploadSpeed?.itemValue,
				freeMinutesToOmantel:
					plan.planDetails?.includedMinutes?.itemValue === '-'
						? 'Unlimited'
						: plan.planDetails?.includedMinutes?.itemValue,
				intlCallsDiscount: plan.planDetails?.callDiscount?.itemValue,
				planVatPercent: plan.vatPercent,
				planVatValue: plan.vatValue,
				socialMediaDataValue: plan.planDetails?.PLAN_PLACEHOLDER_1?.itemValue,
				socialMediaDataUnit: plan.planDetails?.PLAN_PLACEHOLDER_1?.itemUnit,
			};
		});
	};

	const handleSelectedPlanToCompare = (plan: ISinglePackage, index?: number) => {
		setSelectedPlanToCompare(plan);
		setSelectedPlanIndex(index);
		setSelectedPlanId(plan.partNumberRaw);
	};

	const otherPlansItems = () => {
		return otherPlans
			.filter((plan) => plan.partNumberRaw !== recommendedPlan.partNumberRaw)
			.map((plan, index: number) => {
				const showBadge = index !== selectedPlanIndex;

				const priceDiscount = productAllSKU.find(
					(sku) =>
						sku.colorId === selectedFilters.selectedColorId &&
						sku.capacityId === selectedFilters.selectedValueId &&
						sku.installmentPeriod === selectedFilters.installmentPeriod &&
						sku.planDetails.planId === plan.partNumberRaw
				)?.priceDiscount;

				return (
					<PlanBarWrapper
						key={index}
						onClick={() => {
							handleSelectedPlanToCompare(plan, index);
						}}
					>
						<PlanBar
							key={index}
							barType={getBarType(plan, index, selectedPlanIndex)}
							plan={{
								name: plan?.shortDescription,
								validity: translate('plan.contract.duration', String(plan?.duration ?? '')),
								price: String(plan?.price ?? ''),
								internet:
									plan?.planDetails?.includedData?.itemValue === '-'
										? 'Unlimited'
										: plan?.planDetails?.includedData?.itemValue,
								minutes:
									plan?.planDetails?.includedMinutes?.itemValue === '-'
										? 'Unlimited'
										: plan?.planDetails?.includedMinutes?.itemValue,
								expireDate: plan?.duration
									? moment().add(plan?.duration, 'M').format('DD.MM.YYYY')
									: '',
								promotion: {
									promotion: !!priceDiscount && !showBadge,
									price: priceDiscount,
									badge: showBadge,
								},
								vat: Number(plan?.price || 0) + Number(plan?.vatValue || 0),
								vatPercent: Number(plan?.vatPercent || 0),
								hasCommitment: !!plan?.duration,
								recommendedPlan: false,
								isHbb: plan?.planType === 'HBB',
							}}
							active={selectedPlanIndex === index}
							alUfuq={!!plan?.alUfuq}
						/>
					</PlanBarWrapper>
				);
			});
	};

	const recommendedPlanItem = () => {
		const showBadge = selectedPlanIndex !== undefined;
		const priceDiscount = productAllSKU.find(
			(sku) =>
				sku.colorId === selectedFilters.selectedColorId &&
				sku.capacityId === selectedFilters.selectedValueId &&
				sku.installmentPeriod === selectedFilters.installmentPeriod &&
				sku.planDetails.planId === recommendedPlan.partNumberRaw
		)?.priceDiscount;
		return (
			<PlanBarWrapper>
				<PlanBar
					onClick={() => {
						handleSelectedPlanToCompare(recommendedPlan);
					}}
					barType={getBarTypeByPlan(recommendedPlan, selectedPlanIndex !== undefined)}
					plan={{
						name: recommendedPlan?.shortDescription,
						validity: translate('plan.contract.duration', String(recommendedPlan?.duration ?? '')),
						price: String(recommendedPlan?.price ?? ''),
						internet:
							recommendedPlan?.planDetails?.includedData?.itemValue === '-'
								? 'Unlimited'
								: recommendedPlan?.planDetails?.includedData?.itemValue,
						minutes:
							recommendedPlan?.planDetails?.includedMinutes?.itemValue === '-'
								? 'Unlimited'
								: recommendedPlan?.planDetails?.includedMinutes?.itemValue,
						expireDate: recommendedPlan?.duration
							? moment().add(recommendedPlan?.duration, 'M').format('DD.MM.YYYY')
							: '',
						promotion: {
							promotion: !!priceDiscount && !showBadge,
							price: priceDiscount,
							badge: showBadge,
						},
						vat: Number(recommendedPlan?.price || 0) + Number(recommendedPlan?.vatValue || 0),
						vatPercent: Number(recommendedPlan?.vatPercent || 0),
						hasCommitment: !!recommendedPlan?.duration,
						recommendedPlan: true,
						isHbb: recommendedPlan?.planType === 'HBB',
					}}
					active={selectedPlanIndex === undefined}
					alUfuq={!!recommendedPlan?.alUfuq}
				/>
			</PlanBarWrapper>
		);
	};

	const PaymentSummary = () => {
		return (
			<BusyIndicator
				listener={[
					GET_PRODUCT_DETAILS_BI,
					GET_ACCOUNT_FOR_LOGGED_USER,
					GET_RECOMMENDED_PLAN,
					INFINITE_LOADING,
					NOT_CHOSEN_NUMBER,
					GET_PLANS,
					GET_COMPONENTS_FROM_INVENTORY_BI,
					GET_HIGHER_PLAN,
					CHECK_IF_CAN_GET_NEW_PLAN,
					VALIDATE_CHANGED_INSTALLMENT_SKUS,
					GET_CUSTOMER_PRODUCTS,
				]}
				skeleton={planType === 'DEVICE_ONLY' ? 'paymentSummary' : 'paymentSummaryWithPlan'}
			>
				<>
					<Card<types.cards.IPaymentSummaryCardProps>
						card="paymentSummary"
						params={{
							showAvailablePaymentMethods: false, // payment method selector is disabled, see: MINT-6075
							link: '/product',
							isLogged,
							pricesWithVat,
							availableSKU,
							productAllSKU,
							planType,
							setInstallmentPeriod: (installmentPeriod?: '12' | '24') => {
								setInstallmentPeriod(installmentPeriod);
							},
							installmentPeriod: installmentPeriod || selectedFilters.installmentPeriod || '12',
							singleSku: true,
							onPlanSelector: true,
							selectedProductName,
						}}
						marginTop={16}
					/>
				</>
			</BusyIndicator>
		);
	};

	const availableSKU = useMemo(() => {
		return productAllSKU.filter((sku) => {
			const matchSelectedColor =
				sku.colorId === planSelectorFilters.selectedColorId ||
				sku.countryId === planSelectorFilters.selectedColorId;
			const matchSelectedValue =
				sku.capacityId === planSelectorFilters.selectedValueId ||
				sku.valueId === planSelectorFilters.selectedValueId;
			const matchSelectedPlan = sku.planDetails.planId === planSelectorFilters.selectedPlanId;
			const matchSelectedPeriod = sku.installmentPeriod === planSelectorFilters.installmentPeriod;

			return matchSelectedColor && matchSelectedValue && matchSelectedPlan && matchSelectedPeriod;
		});
	}, [planSelectorFilters]);

	return (
		<CardContainer>
			<CardWrapper>
				<Header>
					<Row>
						<Col xs={9}>
							<LogoWrapper>
								<Icon name="omantelLogo" width={96} height={48} />
							</LogoWrapper>
						</Col>
						<Col>
							<ActionWrapper onClick={handleClose}>
								<Icon width={40} height={40} name="close" fill="black" />
							</ActionWrapper>
						</Col>
					</Row>
				</Header>
				<Row>
					<ConditionalRender
						show={screen(['xs', 'sm', 'md'])}
						onTrue={() => (
							<Col>
								<Row>
									<PaymentSummary />
								</Row>
								<Row>
									<Divider withoutLine marginBottom={8} />
									<Col>
										<XSPlanSliderTitle>
											{translate('plan-selector.select.your.plan')}
										</XSPlanSliderTitle>
										<Divider withoutLine marginBottom={8} />
										<XSPlanSliderSubtitle color="black54">
											{translate('plan-selector.subtitle.xs')}
										</XSPlanSliderSubtitle>
										<Divider withoutLine marginBottom={8} />
										<HorizontalPlansScroll>
											{recommendedPlanItem()}
											{otherPlansItems()}
										</HorizontalPlansScroll>
									</Col>
								</Row>
							</Col>
						)}
					/>
					<Col lg={8} md={12}>
						<PlanComparisonWrapper>
							<PlanComparison
								plans={getPlansToCompare()}
								isProductCardRoute
								onPlanSelection={setOnPlanSelection}
								currentPlanIsHBB={currentPlanIsHBB}
								currentPlanIsPrepaid={currentPlanIsPrepaid}
							/>
						</PlanComparisonWrapper>
					</Col>
					<ConditionalRender
						show={screen(['lg', 'xl'])}
						onTrue={() => (
							<Col>
								<Row>
									<PaymentSummary />
								</Row>
								<Divider marginBottom={16} withoutLine />
								<Row>
									<Col>
										<RecomendedPlan>
											<Subheading>
												{translate('plan-selector.recommended.plan').toUpperCase()}
											</Subheading>
										</RecomendedPlan>
										{recommendedPlanItem()}
									</Col>
								</Row>
								<Row>
									<Col>
										<ConditionalRender
											show={
												otherPlans.filter(
													(plan) => plan.partNumberRaw !== recommendedPlan.partNumberRaw
												).length > 0
											}
											onTrue={() => (
												<>
													<Subheading>
														{translate('plan-selector.see.other.plans').toUpperCase()}
													</Subheading>
													<OtherPlansScroll>{otherPlansItems()}</OtherPlansScroll>
												</>
											)}
										/>
									</Col>
								</Row>
							</Col>
						)}
					/>
				</Row>
			</CardWrapper>
		</CardContainer>
	);
};
export default PlanSelectorCard;
