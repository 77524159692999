import valueInObjectPathString from '@Utils/objectsValidation/valueInObjectPathString';
import { AjaxResponse } from 'rxjs/ajax';
import Models from '@Utils/api/models/index';

type Response = AjaxResponse | types.models.IInputModel;
type Model = keyof typeof Models;

export default function (
	response: Response | Record<string, unknown>,
	modelName: Model
): types.models.IOutputModel | any {
	const model = Models[modelName].default;
	let output = {} as types.models.IOutputModel | any;
	try {
		if (model && response === Object(response)) {
			// eslint-disable-next-line guard-for-in
			for (const modelKey in model) {
				const modelKeyParam = typeof model[modelKey];
				const modelParamIsArray = Array.isArray(model[modelKey]);

				if (modelKeyParam === 'string' || modelParamIsArray) {
					const modelParamValue = model[modelKey] as types.models.objectPath | types.models.arrayPath;
					let path;
					let defaultValue;

					if (modelParamIsArray) {
						path = modelParamValue[0];
						defaultValue = modelParamValue[1];
					} else {
						path = modelParamValue;
					}

					output[modelKey] = valueInObjectPathString(response, path as string, defaultValue);
				} else if (modelKeyParam === 'function') {
					const resolver = model[modelKey] as types.models.modelResolver;
					output[modelKey] = resolver(output);
				}
			}
			let root = {};
			// eslint-disable-next-line guard-for-in
			for (const modelKey in output) {
				if (modelKey === '_root_') {
					root = output[modelKey][0];
				}
				if (modelKey.startsWith('_')) {
					delete output[modelKey];
				}
			}
			output = {
				...output,
				...root,
			};
		}
	} catch (e) {
		console.log(e);
	}

	return output;
}
