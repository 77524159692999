import { ButtonM } from '@Components/Typography/Typography';
import styled from 'styled-components';
import { isRTL, matchScreen } from '@Utils/layout/layoutSupport';

export const CardContainer = styled.div`
	width: ${() => (matchScreen(['xs', 'sm']) ? 'auto' : '800px')};
`;

export const BGContainer = styled.div`
	overflow: hidden;
	transform: translateX(100px);
	> div {
		width: 500px;
		height: 380px;
	}
	position: absolute;
	top: 40px;
	right: 0;
	opacity: 0.2;
`;

export const CardWrapper = styled.div`
	padding: 25px 40px;
`;

export const LogoWrapper = styled.div`
	padding: 0px 15px;
`;

export const Button = styled.div<{ isXs?: boolean }>`
	${({ isXs }) => ({
		width: isXs ? '100%' : '320px',
	})}
	> div {
		width: 100%;
	}
`;

export const TextButton = styled(ButtonM)`
	display: inline-block;
	cursor: pointer;
	text-decoration: underline;
	${() => isRTL() && 'padding-left: 8px'};
	${({ theme }) => ({
		textDecorationColor: theme.colors.primary,
		color: theme.colors.primary,
	})}
`;
