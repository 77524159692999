import { TitleBold } from '@Components/Typography/Typography';
import styled from 'styled-components';
import { PlanBarType } from './PlanBarType';
import { isRTL, marginRight, marginLeft, floatRight } from '@Utils/layout/layoutSupport';

interface IPlanBarContainer {
	marginTop?: number;
	marginBottom?: number;
	barType?: PlanBarType;
	saveActive?: boolean;
	width?: string;
}

export const PlanBarContainer = styled.div<IPlanBarContainer>`
	${({ marginTop, marginBottom }) => ({
		marginTop,
		marginBottom,
	})}
`;

export const PlanContainer = styled.div<IPlanBarContainer>`
	${({ saveActive }) =>
		saveActive === true ? { borderTopLeftRadius: '8px', borderTopRightRadius: '8px' } : { borderRadius: '8px' }}
	padding: 16px 16px 12px 16px;
	display: flex;
	justify-content: space-between;
	${({ width }) => ({
		width,
	})}
	${({ barType, theme }) => {
		switch (barType) {
			case PlanBarType.BAQATI_INACTIVE_NO_COMMITMENT:
				return {
					border: `1px solid ${theme.colors.support3}`,
					backgroundColor: theme.colors.white,
					color: theme.colors.support3,
				};
			case PlanBarType.BAQATI_INACTIVE:
				return {
					border: `1px solid ${theme.colors.support2}`,
					backgroundColor: theme.colors.white,
					color: theme.colors.support2,
				};
			case PlanBarType.BAQATI:
				return {
					backgroundImage: `linear-gradient(to top, ${theme.colors.support2}, ${theme.colors.support4})`,
					color: theme.colors.white,
				};
			case PlanBarType.BAQATI_AL_UFUQ_INACTIVE:
				return {
					border: `1px solid ${theme.colors.black87}`,
					backgroundColor: theme.colors.white,
					color: theme.colors.black87,
				};
			case PlanBarType.BAQATI_AL_UFUQ:
				return {
					backgroundImage: `linear-gradient(to top, ${theme.colors.black87}, ${theme.colors.support3})`,
					color: theme.colors.white,
				};
			case PlanBarType.UNLIMITED_INACTIVE:
				return {
					backgroundColor: theme.colors.white,
					color: theme.colors.support1,
					border: `1px solid ${theme.colors.support1}`,
				};
			case PlanBarType.UNLIMITED:
				return {
					backgroundImage: `linear-gradient(to top, ${theme.colors.support1}, ${theme.colors.support5})`,
					color: theme.colors.white,
				};
			default:
				return {};
		}
	}}
`;

export const InternetAndMinutes = styled.div`
	display: flex;
`;
export const Internet = styled.div`
	margin-right: 4px;
	display: flex;
	align-items: center;
`;
export const Minutes = styled.div`
	margin-left: 4px;
	display: flex;
	align-items: center;
`;

export const SaveContainer = styled.div<IPlanBarContainer>`
	border-bottom-left-radius: 8px;
	border-bottom-right-radius: 8px;
	padding: 10px 16px;
	display: flex;
	justify-content: space-between;
	${({ theme }) => ({
		backgroundImage: `linear-gradient(to top, ${theme.colors.primary}, ${theme.colors.primaryGradient})`,
		color: theme.colors.white,
	})}
`;

export const SpacePanel = styled(TitleBold)`
	height: 16px;
`;

export const Title = styled(TitleBold)`
	text-overflow: ellipsis;
	overflow: hidden;
`;

export const VatBarWrapper = styled.div`
	display: block;
`;

export const PricePanel = styled.div`
	display: flex;
	justify-content: flex-end;
`;

export const PriceWithVatRight = styled.div`
	${() => floatRight()}
`;

export const PricePanelLeft = styled.div`
	width: 55px;
	display: flex;
	justify-content: flex-end;
`;

export const PricePanelRight = styled.div`
	max-width: 30px;
`;

export const OmrPanel = styled.div`
	height: 20px;
`;

export const MidllePanel = styled.div`
	margin: 4px;
`;

export const MOPanel = styled.div`
	height: 16px;
	${() => (isRTL() ? { float: 'left' } : { float: 'right' })}
`;

export const LeftPanel = styled.div`
	${() =>
		isRTL()
			? {
					float: 'left',
					paddingLeft: '16px',
			  }
			: {
					float: 'right',
					paddingRight: '16px',
			  }}
	width: calc(100% - 142px);
`;

export const RightPanel = styled.div`
	${() => (isRTL() ? { float: 'left' } : { float: 'right' })}
	text-align: right;
`;

export const RightPanelCard = styled.div`
	${() => (isRTL() ? { float: 'left' } : { float: 'right' })}
	text-align: right;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
`;

export const Badge = styled.div`
	display: flex;
	position: relative;
	${() => (isRTL() ? { marginLeft: '-29px' } : { marginRight: '-29px' })}
	margin-top: 10px;
`;

export const BadgeRibbon = styled.div`
	${({ theme }) => ({
		backgroundImage: `linear-gradient(to top, ${theme.colors.primary}, ${theme.colors.primaryGradient})`,
		color: theme.colors.white,
	})}
	${() =>
		isRTL()
			? {
					borderTopRightRadius: '16px',
					borderBottomRightRadius: '16px',
					padding: '4px 16px 4px 20px',
			  }
			: {
					borderTopLeftRadius: '16px',
					borderBottomLeftRadius: '16px',
					padding: '4px 20px 4px 16px',
			  }}
`;

const TRIANGLE_SIZE = 12;
export const BadgeTriangle = styled.div`
	width: 0;
	height: 0;
	${({ theme }) => ({
		borderBottom: `${TRIANGLE_SIZE}px solid ${theme.colors.primary}`,
	})}
	align-content: flex-end;
	margin-top: -${TRIANGLE_SIZE}px;
	${() =>
		isRTL()
			? {
					marginRight: `-${TRIANGLE_SIZE}px`,
					borderLeft: `${TRIANGLE_SIZE}px solid transparent`,
			  }
			: {
					marginLeft: `-${TRIANGLE_SIZE}px`,
					borderRight: `${TRIANGLE_SIZE}px solid transparent`,
			  }}
`;

export const IconWrapper = styled.div`
	${() => marginRight(2)}
	${() => marginLeft(2)}
	margin-bottom: 2px;
`;
