import React, { FunctionComponent } from 'react';
import useTranslate from '@Utils/hooks/useTranslate';
import { useSelector } from 'react-redux';
import {
	Container,
	FinalItem,
	FirstItem,
	FirstWrapper,
	IconWrapper,
	Item,
} from '@Components/CustomBreadcrumbs/CustomBreadcrumbs.styled';
import Icon from '@Components/Icon/Icon';
import capitalizeFirstLetter from '@Utils/converters/capitalizeFirstLetter';
import externalLinks from '@Utils/externalLinks/externalLinks';
import { getLang } from '@Utils/language/language';
import { isRTL } from '@Utils/layout/layoutSupport';

const CustomBreadcrumbs: FunctionComponent = () => {
	const { translate } = useTranslate();
	const breadcrumbs = useSelector((state: types.redux.IState) => state.breadcrumbs);

	const BreadcrumbItem = ({ item, index }: { item: types.redux.breadcrumbs.IBreadcrumb; index: number }) => {
		if (index + 1 === breadcrumbs.items.length) {
			return <FinalItem>{item.label}</FinalItem>;
		} else {
			if (item.link.includes('/en/') && getLang() === 'ar') {
				return <Item to={item.link.replace('/en/', '/ar/')}>{capitalizeFirstLetter(item.label)}</Item>;
			} else if (item.link.includes('/ar/') && getLang() === 'en') {
				return <Item to={item.link.replace('/ar/', '/en/')}>{capitalizeFirstLetter(item.label)}</Item>;
			}
			return <Item to={item.link}>{capitalizeFirstLetter(item.label)}</Item>;
		}
	};

	const redirectToOmantel = () => {
		window.location.href = externalLinks('OMANTEL');
		return null;
	};

	return (
		<Container>
			<FirstWrapper>
				<Icon name="home" height={16} width={16} />
			</FirstWrapper>
			<FirstItem onClick={redirectToOmantel}>{translate('breadcrumbs.home')}</FirstItem>
			{breadcrumbs.items.map((breadcrumb, index) => (
				<React.Fragment key={index}>
					<IconWrapper>
						<Icon name={isRTL() ? 'chevronLeft' : 'chevronRight'} fill="primary" height={16} width={16} />
					</IconWrapper>
					<BreadcrumbItem item={breadcrumb} index={index} />
				</React.Fragment>
			))}
		</Container>
	);
};

export default CustomBreadcrumbs;
