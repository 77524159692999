import moment from '@Utils/date/moment';
import { Moment } from 'moment';

type inputDate = Moment | Date | string | number;

export default (firstDate: inputDate, secondDate?: inputDate): Record<string, Record<string, Moment | boolean>> => {
	// if second argument is undefined then compare first one with current time
	const now = secondDate === undefined ? moment().valueOf() : undefined;
	function _compare(): number {
		const secondDateOrNow = secondDate || now;
		return moment(firstDate).diff(secondDateOrNow);
	}

	return {
		currentTime: {
			isEqual: !!now && _compare() === 0,
			isGreater: !!now && _compare() < 0,
			isSmaller: !!now && _compare() > 0,
			value: moment(now),
		},
		first: {
			isEqual: _compare() === 0,
			isGreater: _compare() > 0,
			isSmaller: _compare() < 0,
			value: moment(firstDate),
		},
		second: {
			isEqual: !now && _compare() === 0,
			isGreater: !now && _compare() < 0,
			isSmaller: !now && _compare() > 0,
			value: moment(secondDate),
		},
	};
};
