import { isUserSignedIn } from '@Redux/modules/api/authentication/selectors';

type IFunctionalEndpointConfig = types.endpoints.functionalEndpointConfig;

export const enhancedTermAndCondition: IFunctionalEndpointConfig = ({ lang, isTCPlan }) => {
	return {
		url: `wcm/${isUserSignedIn() ? 'myconnect' : 'connect'}/ebu_${lang}/eshop_content/${
			isTCPlan ? 'TC_PLAN' : 'TC_DEVICE'
		}`,
	};
};
