import React, { FunctionComponent } from 'react';
import ColorPicker from '@Components/controls/ColorPicker/ColorPicker';
import { ColorPickerPosition } from '@Components/controls/ColorPicker/ColorPicker.styles';
import { PriceType, ProductType, TileSize } from '@Components/Card/cards/ProductCard/ProductCard.styled';
import ConditionalRender from '@Components/ConditionalRender/ConditionalRender';
import PriceSection, {
	IPricesWithVat,
} from '@Components/Card/cards/ProductCard/subcomponents/PriceSection/PriceSection';
import ActionSection from '@Components/Card/cards/ProductCard/subcomponents/ActionSection/ActionSection';
import UnavailableProductTag from '@Components/Card/cards/ProductCard/subcomponents/UnavailableProductTag/UnavailableProductTag';
import {
	BrandWrapper,
	ContentWrapper,
	Image,
	MarketingTagImage,
	ImageWrapper,
	LoaderContainer,
	TitleWrapper,
	TagWrapper,
	ProductNameWrapper,
	TitleWrapperParent,
	Container,
	PreorderTagWrapper,
} from '@Components/Card/cards/ProductCard/subcomponents/GridProductCard/GridProductCard.styled';
import { IItem } from '@ApiModels/productsByCategoryId';
import { IAvailableColorWithId } from '@Components/controls/ColorPicker/subcomponents/Slider';
import { PrimaryCircularLoader } from '@Components/CircularLoader/CircularLoader';
import Divider from '@Components/Divider/Divider';
import Icon from '@Components/Icon/Icon';
import { getLang } from '@Utils/language/language';
import useScreen from '@Utils/hooks/useScreen';
import InstalmentPriceSection from '../InstalmentPriceSection/InstalmentPriceSection';
import useAppleBrand from '@Utils/hooks/useAppleBrand';
import { ENABLE_ESHOP_LITE } from '@Config/features.config';

const APPLE_WATCH = 'apple watch';
const APPLE_HERO_ITEMS = ['iphone', 'apple watch', 'ipad'];
interface IGridProductCardProps {
	product: IItem;
	selectedColor?: string | null;
	setSelectedColor?: (color: string | null) => void;
	tileSize: TileSize;
	productType: ProductType;
	priceType: PriceType;
	preorderStartDate?: string;
	iconsHidden?: boolean;
	withoutColorPicker?: boolean;
	availableColors?: IAvailableColorWithId[];
	thumbnailUrl?: string;
	isLoading?: boolean;
	handleSeeDetails?: () => void;
	pricesWithVat: IPricesWithVat;
	withContainer?: boolean;
	comparisonView?: boolean;
	showInstalment?: boolean;
	eshopMarketingTag?: string;
}

const GridProductCard: FunctionComponent<IGridProductCardProps> = ({
	product,
	selectedColor,
	setSelectedColor,
	tileSize,
	productType,
	priceType,
	preorderStartDate,
	iconsHidden,
	withoutColorPicker,
	availableColors,
	thumbnailUrl,
	isLoading = false,
	handleSeeDetails,
	pricesWithVat,
	withContainer,
	comparisonView,
	eshopMarketingTag,
	showInstalment = true,
}) => {
	const { screen } = useScreen();
	const isLG = screen('lg');
	const { isAppleUrl, isBrandStore, isAppleAccessories } = useAppleBrand();
	const isAppleWatch = product.name.toLowerCase().includes(APPLE_WATCH);

	return (
		<Container withContainer={withContainer}>
			{productType === ProductType.PREORDER && (
				<PreorderTagWrapper>
					<Icon name={getLang() === 'ar' ? 'preorder_ar' : 'preorder_en'} height={60} width={60} />
				</PreorderTagWrapper>
			)}
			<ConditionalRender
				show={!!product.isDigitalProduct && !!product.availableValues?.length}
				onTrue={() => (
					<TagWrapper right={22}>
						<Icon
							name={`subscription${getLang().toUpperCase()}` as types.iconNames}
							width={64}
							height={64}
						/>
					</TagWrapper>
				)}
			/>
			{typeof eshopMarketingTag === 'string' && productType !== ProductType.PREORDER && (
				<TagWrapper>
					<MarketingTagImage
						src={`https://store.omantel.om/wcsstore/eshop//eshop2/icons/tags/${getLang().toUpperCase()}/${eshopMarketingTag.toLowerCase()}.png`}
						onError={(event) => ((event.target as HTMLImageElement).style.display = 'none')}
						title={`${eshopMarketingTag.toLowerCase()} tag icon`}
					/>
				</TagWrapper>
			)}
			<TitleWrapperParent>
				<TitleWrapper>
					<ProductNameWrapper isLG={isLG} color="black87">
						<BrandWrapper color="black87">{product.manufacturer ?? ''}</BrandWrapper>
						{product.name}
					</ProductNameWrapper>
				</TitleWrapper>
			</TitleWrapperParent>
			<ContentWrapper>
				<ConditionalRender
					show={!!availableColors?.length && !withoutColorPicker}
					onTrue={() => (
						<ColorPicker
							colors={availableColors ?? []}
							setSelectedColor={setSelectedColor}
							selectedColor={selectedColor}
							position={ColorPickerPosition.VERTICAL}
							numberOfItemsOnSlide={3}
						/>
					)}
				/>
				<ImageWrapper tileSize={tileSize}>
					{isLoading ? (
						<LoaderContainer>
							<PrimaryCircularLoader />
						</LoaderContainer>
					) : (
						<Image src={thumbnailUrl} isAppleWatch={isAppleWatch} />
					)}
				</ImageWrapper>
				<ConditionalRender
					show={productType === ProductType.UNAVAILABLE}
					onTrue={() => <UnavailableProductTag withColorPicker={!!availableColors?.length} />}
				/>
			</ContentWrapper>
			<PriceSection
				pricesWithVat={pricesWithVat}
				discount={product.discount}
				priceType={priceType}
				preorderStartDate={undefined}
				tileSize={tileSize}
				productType={productType}
			/>
			<ConditionalRender
				show={
					((!ENABLE_ESHOP_LITE && isAppleUrl()) || (!ENABLE_ESHOP_LITE && isBrandStore())) &&
					!isAppleAccessories() &&
					!!showInstalment &&
					(APPLE_HERO_ITEMS.some((str) => product.name.toLowerCase().includes(str)) ||
						product.manufacturer?.toLowerCase() === APPLE_HERO_ITEMS[1])
				}
				onTrue={() => (
					<>
						<Divider marginTop={18} marginBottom={4} />
						<InstalmentPriceSection
							productTextInstallmentValue={product.productTextInstallmentValue}
							priceType={priceType}
							tileSize={tileSize}
						/>
					</>
				)}
			/>
			<Divider marginBottom={18} withoutLine />
			<ActionSection
				onClick={handleSeeDetails}
				productType={productType}
				product={product}
				iconsHidden={iconsHidden}
				comparisonView={comparisonView}
			/>
		</Container>
	);
};

export default GridProductCard;
