import React, { FunctionComponent } from 'react';
import { useRouter } from '@Utils/router/useRouter';
import useTranslate from '@Utils/hooks/useTranslate';

import { Helmet } from 'react-helmet';
import { getLang } from '@Utils/language/language';

const SEO: FunctionComponent = () => {
	const router = useRouter();
	const { translate } = useTranslate();

	const getTitle = () => {
		switch (router.pathname) {
			case '/store': {
				return translate('html.title.route.store');
			}
			case `/${getLang()}/store`: {
				return translate('html.title.route.store');
			}
			case '/promotions': {
				return translate('html.title.route.promotions');
			}
			case '/test': {
				return translate('html.title.route.test');
			}
			default:
				return translate('html.title.route.default');
		}
	};

	const getMetaData = () => {
		let metadata = translate('html.meta-data');
		switch (router.pathname) {
			case '/store': {
				metadata = `${metadata} ${translate('html.meta-data.route.store')}`;
				break;
			}
			case `/${getLang()}store`: {
				metadata = `${metadata} ${translate('html.meta-data.route.store')}`;
				break;
			}
			case '/promotions': {
				metadata = `${metadata} ${translate('html.meta-data.route.promotions')}`;
				break;
			}
		}
		return metadata;
	};

	return (
		<div className="page">
			<Helmet>
				<meta charSet="utf-8" />
				<title>{getTitle()}</title>
				<meta name="description" content={getMetaData()} />
			</Helmet>
		</div>
	);
};

export default SEO;
