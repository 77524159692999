import React, { FunctionComponent, useState, useEffect, useRef } from 'react';
import {
	ProductBoxWrapper,
	ChildernCollapseWrapper,
	AccordionContent,
	AccordionDescription,
	AccordionTitle,
	AccordionWrapper,
	DescriptionWrapper,
	IconWrapper,
	AccordionDescriptionSmall,
	AccordionOutletDescription,
	AccordionOutletDescriptionSmall,
} from './Accordion.styled';

import Icon from '@Components/Icon/Icon';
import ConditionalRender from '@Components/ConditionalRender/ConditionalRender';

export interface IExpandableBox {
	subtitleTopMargin?: string;
	isPrepaid?: boolean;
	title: string;
	subtitle?: string;
	pickUpAppointment?: string;
	icon?: types.iconNames;
	titleColor?: types.theme.themeColors;
	subtitleColor?: types.theme.themeColors;
	iconColor?: types.theme.themeColors;
	isExpanded?: boolean;
	children?: React.ReactNode;
	expandAvailable?: boolean;
	minHeight?: number;
	marginLeft?: number;
	bottomMode?: boolean;
	withLine?: boolean;
	isTitleExpandable?: boolean;
	setBoxHeight?: (height: number) => void;
	typography?: keyof types.ITypography;
	iconExpand?: types.iconNames;
	iconHide?: types.iconNames;
	displayExpand?: boolean;
	smallSubtitle?: boolean;
	enableSubtitleOnExpand?: boolean;
	constantMargin?: boolean;
	extraBold?: boolean;
	withoutPadding?: boolean;
	accordionHeight?: number;
	iconSize?: number;
	withoutContainerPadding?: boolean;
	forceState?: boolean;
	callback?: () => void;
	customAccordionState?: boolean;
	setIsAccordionExpanded?: (value: boolean) => void;
	titleBold?: boolean;
	isIconExpandable?: boolean;
	titleLeftMargin?: number;
	customAccordionHeight?: string;
}

const Accordion: FunctionComponent<IExpandableBox> = ({
	subtitleTopMargin = '0',
	titleLeftMargin = 0,
	isPrepaid = false,
	icon,
	title = '',
	titleColor = 'black87',
	subtitleColor = 'black87',
	iconColor = 'black38',
	subtitle = '',
	customAccordionHeight,
	pickUpAppointment = '',
	isExpanded = false,
	children,
	isTitleExpandable = false,
	minHeight = 0,
	customAccordionState,
	setBoxHeight,
	setIsAccordionExpanded,
	marginLeft,
	iconExpand = 'chevronUp',
	iconHide = 'chevronDown',
	displayExpand = true,
	smallSubtitle = false,
	enableSubtitleOnExpand = false,
	constantMargin = false,
	extraBold = false,
	withoutPadding = false,
	withoutContainerPadding = false,
	accordionHeight = 60,
	iconSize = 32,
	forceState = false,
	callback,
	titleBold = false,
	isIconExpandable = false,
}) => {
	const [expanded, setExpanded] = useState<boolean>(!!isExpanded);
	const boxRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		setTimeout(() => {
			setBoxHeight && setBoxHeight(boxRef.current?.getBoundingClientRect().height ?? 0);
		}, 500);

		const handleResize = () => {
			setBoxHeight && setBoxHeight(boxRef.current?.getBoundingClientRect().height ?? 0);
		};
		window.addEventListener('resize', handleResize);
	}, [expanded, boxRef]);

	const handleExpand = () => {
		if (!forceState) {
			setExpanded((expanded) => !expanded);
		}
		if (setIsAccordionExpanded) {
			if (!expanded) {
				setIsAccordionExpanded(!expanded);
			} else {
				setTimeout(() => {
					setIsAccordionExpanded(!expanded);
				}, 300);
			}
		}
		if (callback) callback();
	};

	useEffect(() => {
		if (customAccordionState === false && setIsAccordionExpanded) {
			setExpanded(false);
		}
	}, [customAccordionState]);

	return (
		<ProductBoxWrapper
			onClick={isIconExpandable || isTitleExpandable ? undefined : handleExpand}
			isIconExpandable={isIconExpandable || isTitleExpandable}
		>
			<AccordionWrapper
				withoutPadding={withoutPadding || withoutContainerPadding}
				isIconExpandable={isIconExpandable || isTitleExpandable}
			>
				<AccordionContent
					customAccordionHeight={customAccordionHeight}
					accordionHeight={accordionHeight}
					isPrepaid={isPrepaid}
					onClick={isTitleExpandable ? handleExpand : undefined}
					isTitleExpandable={isTitleExpandable}
				>
					{icon && <Icon name={icon} fill={iconColor} width={iconSize} height={iconSize} />}
					<DescriptionWrapper withoutPadding={withoutPadding}>
						<AccordionTitle
							constantMargin={constantMargin}
							extraBold={extraBold}
							expanded={expanded}
							titleColor={titleColor}
							isOnlyTitle={!subtitle}
							titleBold={titleBold}
							titleLeftMargin={titleLeftMargin}
						>
							{title}
						</AccordionTitle>
						{subtitle && (
							<ConditionalRender
								show={!expanded || enableSubtitleOnExpand}
								onTrue={() => (
									<ConditionalRender
										show={!!smallSubtitle}
										onFalse={() => (
											<AccordionDescription
												subtitleColor={subtitleColor}
												titleLeftMargin={titleLeftMargin}
												subtitleTopMargin={subtitleTopMargin}
											>
												{subtitle}
											</AccordionDescription>
										)}
										onTrue={() => (
											<AccordionDescriptionSmall subtitleColor={subtitleColor}>
												{subtitle}
											</AccordionDescriptionSmall>
										)}
									/>
								)}
							/>
						)}
						{pickUpAppointment && (
							<ConditionalRender
								show={!expanded || enableSubtitleOnExpand}
								onTrue={() => (
									<ConditionalRender
										show={!!smallSubtitle}
										onFalse={() => (
											<AccordionOutletDescription subtitleColor={subtitleColor}>
												{pickUpAppointment}
											</AccordionOutletDescription>
										)}
										onTrue={() => (
											<AccordionOutletDescriptionSmall subtitleColor={subtitleColor}>
												{pickUpAppointment}
											</AccordionOutletDescriptionSmall>
										)}
									/>
								)}
							/>
						)}
					</DescriptionWrapper>
				</AccordionContent>
				<ConditionalRender
					show={displayExpand}
					onTrue={() => (
						<IconWrapper onClick={isIconExpandable || isTitleExpandable ? handleExpand : undefined}>
							<Icon name={expanded ? iconExpand : iconHide} width={24} height={24} fill="black87" />
						</IconWrapper>
					)}
				/>
			</AccordionWrapper>
			<ChildernCollapseWrapper
				ref={boxRef}
				expanded={expanded}
				minHeight={minHeight}
				marginLeft={marginLeft}
				isIconExpandable={isIconExpandable || isTitleExpandable}
			>
				{children}
			</ChildernCollapseWrapper>
		</ProductBoxWrapper>
	);
};

export default Accordion;
