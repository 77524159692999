import { setToast, ISetToastAction } from './actions';
import immerHandleActions from '@Redux/utils/immerHandleActions';

const init = {
	active: false,
	error: false,
	label: '',
	toastAction: {
		label: '',
		onPress: () => {},
	},
};

type draft = typeof init;

export interface IToastDraft extends draft {}

const reducers = {
	[setToast.toString()](draft: draft, action: ISetToastAction) {
		const { active = true, error = false, label, toastAction } = action.payload;
		draft.active = active;
		draft.error = error;
		draft.label = label;
		draft.toastAction = toastAction;
		return draft;
	},
};

export default immerHandleActions(reducers, init);
