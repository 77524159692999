import { IItem } from '@ApiModels/productsByCategoryId';
import { DEF_ITEM_TYPE, GIFT_CARD_ITEM_TYPE } from '@ApiModels/utils/attributeGetters';
import { ProductType } from '@Components/Card/cards/ProductCard/ProductCard.styled';
import { ProductsService } from '@Services/productsService/productsService';

export function getOnlyAvailableItems(products: IItem[], isRelatedProduct: boolean): IItem[] {
	return products.filter((product) => checkIItemAvailability(product, isRelatedProduct));
}

export function getInstallmentValue(id: string): Promise<string | undefined> {
	return new Promise((resolve) => {
		ProductsService.getProductInstallment(id).subscribe(
			(r) => {
				resolve(r.installmentValue || undefined);
			},
			() => {
				resolve(undefined);
			}
		);
	});
}

export function checkIItemAvailability(product: IItem, isRelatedProduct: boolean): boolean {
	const { availableColors, colors } = product;
	let selectedColor = '';
	let productType: ProductType = ProductType.AVAILABLE;
	const isGiftCard = Array.isArray(product.itemType)
		? product.itemType?.includes(GIFT_CARD_ITEM_TYPE)
		: product.itemType === GIFT_CARD_ITEM_TYPE;
	if (
		availableColors?.length &&
		availableColors.find((color) => color.color === selectedColor)?.available &&
		!product.preorder
	) {
		productType = ProductType.AVAILABLE;
	} else if (!availableColors?.length && (product.isItem || isGiftCard)) {
		const inventoryId = product.attributes?.find((attr) => attr.identifier === DEF_ITEM_TYPE)?.id;
		if (inventoryId && product.inventory) {
			const productInventory = product.inventory[inventoryId];
			if (productInventory?.some((inv) => !!Number(inv.value) && Number(inv.value) > 0)) {
				productType = ProductType.AVAILABLE;
			} else {
				productType = ProductType.UNAVAILABLE;
			}
		}
	} else {
		productType = ProductType.UNAVAILABLE;
	}

	const _color = availableColors?.find((color) => color.color === selectedColor);

	if (_color) {
		if (!product.preorder) {
			_color.available ? (productType = ProductType.AVAILABLE) : (productType = ProductType.UNAVAILABLE);
		}
	} else if (availableColors?.length) {
		const index = availableColors.findIndex((color) => color.available);
		if (!product.preorder) {
			index >= 0 ? (productType = ProductType.AVAILABLE) : (productType = ProductType.UNAVAILABLE);
		}
		if ((colors[index] ?? colors[0])?.color) {
			selectedColor = (colors[index] ?? colors[0])?.color;
		}
	}

	if (product.preorder) {
		productType = ProductType.PREORDER;
	}

	if (isRelatedProduct && !product.availableCapacity.length && !product.availableColors?.length) {
		const defItemType = product?.attributes?.find((attr) => attr.identifier === 'DEF_ITEM_TYPE')?.uniqueID;
		if (product?.inventory && product?.inventory[defItemType || 0]) {
			const inventory = product?.inventory[defItemType || 0][0];
			const { value } = inventory;
			if (Number(value) > 0) {
				productType = ProductType.AVAILABLE;
			}
		}
	}
	return productType === ProductType.AVAILABLE || productType === ProductType.PREORDER;
}
