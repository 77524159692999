import { createAction } from 'redux-actions';

export interface ISetTimerAction {
	payload: {
		time: number;
		activated?: boolean;
	};
}

export const setTimer = createAction('SET_TIMER', (action: ISetTimerAction['payload']) => action);
