import styled from 'styled-components';
import { ValueBold, HeadlineBold, ParagraphM, BodyMExtraBold } from '@Components/Typography/Typography';

interface IAdditionalInfoContainer {
	marginTop: number;
	marginBottom: number;
}

export const AdditionalInfoContainer = styled.div<IAdditionalInfoContainer>`
	${({ marginTop, marginBottom }) => ({
		marginTop,
		marginBottom,
	})}
	width: 100%;
`;

export const AdditionalInfoContainerTabContainer = styled.div<IAdditionalInfoContainer>`
	${({ marginTop, marginBottom }) => ({
		marginTop,
		marginBottom,
	})}
`;

export const FAQContainer = styled.div`
	width: 100%;
`;

export const AccordionContentWrapper = styled.div`
	padding-bottom: 16px;
`;

export const SectionHeader = styled(ValueBold)`
	padding-bottom: 7px;
	${({ theme }) => ({
		color: theme.colors.black54,
	})}
`;

export const SectionContent = styled(ParagraphM)`
	${({ theme }) => ({
		color: theme.colors.black87,
	})}
`;
export const SectionContentDescription = styled(ParagraphM)`
	${({ theme }) => ({
		color: theme.colors.black87,
		marginLeft: -16,
	})}
`;

export const ReturnPolicySection = styled(ParagraphM)`
	${({ theme }) => ({
		color: theme.colors.black87,
	})}
`;

export const SectionTitle = styled(HeadlineBold)`
	padding-top: 56px;
	padding-bottom: 24px;
`;

export const FooterSubtitle = styled(ParagraphM)`
	${({ theme }) => ({
		color: theme.colors.black87,
	})}
`;

export const ReturnPolicyTitle = styled(BodyMExtraBold)`
	padding-top: 32px;
	padding-bottom: 16px;
`;
