import styled from 'styled-components';

export const CheckoutSummaryTryAgainCardContainer = styled.div`
	padding: 32px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 32px;
	border-radius: 8px;
	${({ theme }) => ({
		backgroundColor: theme.colors.white,
	})}
`;

export const ButtonContent = styled.div`
	> div {
		width: 240px;
	}
`;
