export interface IGiftCard {
	productId?: string;
	code: string;
	serial?: string;
	expiryDate?: string;
	name?: string;
}

export interface IGiftCardByOrderId extends IGiftCard {
	itemId?: string;
	status?: string;
}

export interface IUserGiftCards {
	userGiftCards: IGiftCard[];
}

export interface IUserGiftCardsByOrderId {
	userGiftCardsByOrderId: IGiftCardByOrderId[];
}

export const model: types.models.IInputModel = {
	userGiftCards: 'response.getUserGiftCardsResponse',
	userGiftCardsByOrderId: 'response.getUserGiftCardsByOrderIdResponse',
};

export default model;
