import React from 'react';
import { useInsurance } from '@Utils/context/InsuranceContext';
import useTranslate from '@Utils/hooks/useTranslate';
import Icon from '@Components/Icon/Icon';
import { InsuranceTitle, InsuranceSubtitle, Hint } from '@Utils/styles/cardStyles';
import TextField from '@Components/controls/TextField/TextField';
import { ICON_SIZE } from '@Config/app.config';
import { useFormikContext } from 'formik';
import { IFormikCarInsuranceFields } from '@Views/CarInsurance/CarInsurance';
import ConditionalRender from '@Components/ConditionalRender/ConditionalRender';
import { SectionHeadContainer, SectionContentContainer } from '@Views/CarInsurance/CarInsurance.styled';
import { isLargeScreen, isSmallScreen } from '@Utils/layout/layoutSupport';

const InsuranceInsurerCard = (): JSX.Element => {
	const { licenseNumber, setLicenseNumber, contactNumber, setContactNumber } = useInsurance();

	const { translate } = useTranslate();

	const { touched, errors, setFieldTouched, setFieldValue } = useFormikContext<IFormikCarInsuranceFields>();

	return (
		<>
			<SectionHeadContainer>
				<Icon name="insurer" width={ICON_SIZE} height={ICON_SIZE} fill="black54" />
				<InsuranceTitle>{translate('insurance.insurer.title')}</InsuranceTitle>
			</SectionHeadContainer>
			<SectionContentContainer isLargeScreen={isLargeScreen()} isSmallScreen={isSmallScreen()}>
				<InsuranceSubtitle>{translate('insurance.insurer.license')}</InsuranceSubtitle>
				<TextField
					id="licenseNumber"
					label={translate('insurance.insurer.license.placeholder')}
					placeholder={translate('insurance.insurer.license.placeholder')}
					value={licenseNumber}
					onChange={(value) => {
						setFieldTouched('licenseNumber', true);
						setFieldValue('licenseNumber', value.toString());
						setLicenseNumber(value.toString());
					}}
					marginBottom={8}
					message={
						!!errors.licenseNumber && touched.licenseNumber
							? translate('insurance.insurer.license.validation.error')
							: ''
					}
					error={!!errors.licenseNumber && touched.licenseNumber}
					width="100%"
					name="licenseNumber"
				/>
				<InsuranceSubtitle>{translate('insurance.insurer.contact')}</InsuranceSubtitle>
				<TextField
					id="contactNumber"
					label={translate('insurance.insurer.contact.placeholder')}
					placeholder={translate('insurance.insurer.contact.placeholder')}
					value={contactNumber}
					onChange={(value) => {
						setFieldTouched('contactNumber', true);
						setFieldValue('contactNumber', value.toString());
						setContactNumber(value.toString());
					}}
					marginBottom={8}
					message={
						!!errors.contactNumber && touched.contactNumber
							? translate('insurance.insurer.contact.hint')
							: ''
					}
					error={!!errors.contactNumber && touched.contactNumber}
					width="100%"
					name="contactNumber"
				/>
				<ConditionalRender
					show={!errors.contactNumber}
					onTrue={() => <Hint>{translate('insurance.insurer.contact.hint')}</Hint>}
				/>
			</SectionContentContainer>
		</>
	);
};

export default InsuranceInsurerCard;
