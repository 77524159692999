import styled from 'styled-components';
import { DisplayMBold, Title } from '@Components/Typography/Typography.styled';

export const BannerWrapper = styled.div<{ marginRight?: number }>`
	${({ theme }) => ({
		backgroundColor: theme.colors.white,
		border: `2px solid ${theme.colors.primary}`,
	})}
	display: flex;
	flex-direction: column;
	align-items: center;
	border-radius: 8px;
`;

export const HeaderWrapper = styled.div`
	display: flex;
	align-items: center;
	padding-top: 38px;
	flex-direction: column;
`;

export const TitleWrapper = styled(Title)`
	${({ theme }) => ({
		color: theme.colors.primary,
	})}
`;

export const AppleBannerWrapper = styled.div`
	width: 100%;
	overflow: hidden;
	background-color: white;
	margin: 16px 0px;
	display: flex;
	align-items: center;
	justify-content: center;
`;

export const SubTitleWrapper = styled(DisplayMBold)`
	${({ theme }) => ({
		color: theme.colors.black87,
	})}
`;
