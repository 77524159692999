import Divider from '@Components/Divider/Divider';
import Icon from '@Components/Icon/Icon';
import { setModal } from '@Redux/modules/modal/actions';
import useTranslate from '@Utils/hooks/useTranslate';
import React, { FunctionComponent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useAddressBook from '@Utils/hooks/useAddressBook';
import {
	ContentContainer,
	DeliveryAddressCardContainer,
	DeliveryAddressCardTitle,
	DeliveryAddressHeader,
	DeliveryAddressBottom,
	MapDescription,
	TextFieldsContainer,
	MapButton,
	ZoomContainer,
	CurrentPositionButtonContainer,
	FullMapContainer,
	MapElement,
	MapContainerElement,
	ButtonWrapper,
	AreaInformationColumn,
	MissingAreaReminderWrapper,
	SingleInformationWrapper,
} from './DeliveryAddress.styled';
import { GoogleMap } from 'react-google-maps';
import {
	PrimaryMediumButtonFixed,
	PrimaryMediumButtonFlex,
	SecondaryMediumButtonFixed,
} from '@Components/controls/Button/Button';
import ConditionalRender from '@Components/ConditionalRender/ConditionalRender';
import TextField from '@Components/controls/TextField/TextField';
import TextArea from '@Components/controls/TextArea/TextArea';
import Checkbox from '@Components/controls/Checkbox/Checkbox';
import { CheckboxShape } from '@Components/controls/Checkbox/Checkbox.styled';
import AutoCompleteList from './subcomponents/AutoCompleteList';
import debounce from 'lodash.debounce';
import { Formik, FormikProps } from 'formik';
import { addBusyIndicator, removeBusyIndicator } from '@Redux/modules/busyIndicator/actions';
import BusyIndicator from '@Components/BusyIndicator/BusyIndicator';
import { AddressesService, ICities, IGovernorate, IRegion } from '@Services/addresses/addressesService';
import { IDropdownItem, RegularDropdown } from '@Components/controls/Dropdown/Dropdown';
import { LabelType } from '@Components/controls/Dropdown/Dropdown.styled';
import { setCheckoutDetails } from '@Redux/modules/checkout/actions';
import Map, { DEFAULT_MAP_POSITION, MAX_ZOOM, MIN_ZOOM } from '@Components/Map/Map';
import { CommerceProfileService, IUpdateProfilePayloadInput } from '@Services/profile/commerceProfileService';
import { Col, Hidden, Row, Visible } from 'react-grid-system';
import Tooltip from '@Components/Tooltip/Tooltip';
import useCommerceProfile from '@Utils/hooks/useCommerceProfile';
import { AddressType } from '@ApiModels/commerceProfile';
import { isUserSignedIn } from '@Redux/modules/api/authentication/selectors';
import { setNewAddressBook, setRefreshAddressBook } from '@Redux/modules/addressBook/actions';
import { DISABLE_NEW_ADDRESS_DROPDOWN } from '@Config/features.config';
import { Caption, ParagraphMBold } from '@Components/Typography/Typography.styled';
import { matchScreen } from '@Utils/layout/layoutSupport';

interface IFormikFields {
	organization: string;
	way: string;
	house: string;
	apartment: string;
	landmark: string;
}

const ZOOM_ICON_SIZE = 24;
const CURRENT_POSITION_BI = 'getCurrentPosition';
const SAVE_ADDRESS_BI = 'saveProfileAddress';
const GET_CITIES_BI = 'getCities';
const GET_REGIONS_BI = 'getRegions';
const GET_GOVERNORATES_BI = 'getGovernorates';

const GOVERNORATE = 'GOVERNORATE';
const WILAYAT = 'WILAYAT';
const CITY = 'CITY';

const DeliveryAddressCard: FunctionComponent<types.cards.IDeliveryAddressCardProps> = ({
	params: { type, action = 'update', nickName, address, isIncorrectArea },
}) => {
	const { translate } = useTranslate();
	const savedAddress =
		action === 'create' ? undefined : address ?? useSelector((state: types.redux.IState) => state.checkout[type]);
	const isGuest = useSelector((state: types.redux.IState) => state.api.authentication.loginType === 'guest');
	const dispatch = useDispatch();
	const [fullMap, setFullMap] = useState(!savedAddress);
	const [saveAddress, setSaveAddress] = useState(isGuest);
	const [position, setPosition] = useState(DEFAULT_MAP_POSITION);
	const [currentPosition, setCurrentPosition] = useState(DEFAULT_MAP_POSITION);
	const [markerPosition, setMarkerPosition] = useState<google.maps.LatLngLiteral | undefined>(undefined);
	const [isMarkerPositionUserGenerated, setIsMarkerPositionUserGenerated] = useState(false);
	const [zoom, setZoom] = useState(16);
	const [search, setSearch] = useState('');
	const [predictions, setPredictions] = useState<google.maps.places.AutocompletePrediction[]>([]);
	const [selectedPrediction, setSelectedPrediction] = useState<
		google.maps.places.AutocompletePrediction | undefined
	>();
	const [selectedGovernorate, setSelectedGovernorate] = useState<IDropdownItem | null>(null);
	const [selectedWilayat, setSelectedWilayat] = useState<IDropdownItem | null>(null);
	const [selectedCity, setSelectedCity] = useState<IDropdownItem | null>(null);
	const [currentPositionDisabled, setCurrentPositionDisabled] = useState(false);
	const [zipCode, setZipCode] = useState('');
	const [selectedArea, setSelectedArea] = useState<IDropdownItem | null>(null);
	const [cities, setCities] = useState<ICities[]>([]);
	const [regions, setRegions] = useState<IRegion[]>([]);
	const [governorates, setGovernorates] = useState<IGovernorate[]>([]);
	const [validate, setValidate] = useState(false);
	const { contactDetails } = useCommerceProfile();

	const mapsRef = useRef<GoogleMap>();
	const autocomplete = useRef<google.maps.places.AutocompleteService>();
	const placeService = useRef<google.maps.places.PlacesService>();
	const geocoderService = useRef<google.maps.Geocoder>();
	const formikRef = useRef<FormikProps<IFormikFields>>(null);
	const getCurrentPosition = () => {
		setPosition(currentPosition);
		mapsRef.current?.panTo(currentPosition);
	};
	const { updateShippingAddress } = useAddressBook();

	const ascendingSort = (a: IDropdownItem, b: IDropdownItem) => {
		const textA = a.text.toUpperCase();
		const textB = b.text.toUpperCase();
		if (textA < textB) {
			return -1;
		}
		if (textA > textB) {
			return 1;
		}
		return 0;
	};

	const areas = useMemo(() => {
		return (
			cities
				.find((city) => city.key === selectedWilayat?.id)
				?.cities.find((item) => item.key === selectedCity?.id)
				?.areas?.map((area) => ({
					text: area.name_en,
					id: area.key,
				}))
				.sort(ascendingSort) ?? []
		);
	}, [cities, selectedWilayat, selectedCity]);

	useEffect(() => {
		navigator.geolocation.getCurrentPosition(
			(position) => {
				const _position = { lat: position.coords.latitude, lng: position.coords.longitude };
				if (savedAddress?.markerPosition) {
					setPosition(savedAddress.markerPosition);
					setMarkerPosition(savedAddress.markerPosition);
					mapsRef.current?.panTo(savedAddress.markerPosition);
				} else {
					mapsRef.current?.panTo(_position);
				}
				setCurrentPosition(_position);
			},
			() => {
				if (savedAddress?.markerPosition) {
					setMarkerPosition(savedAddress.markerPosition);
					setTimeout(() => {
						if (savedAddress?.markerPosition) {
							mapsRef.current?.panTo(savedAddress.markerPosition);
						}
					}, 500);
				}
				setCurrentPositionDisabled(true);
			}
		);
	}, []);

	useEffect(() => {
		dispatch(addBusyIndicator(GET_CITIES_BI));
		dispatch(addBusyIndicator(GET_REGIONS_BI));
		dispatch(addBusyIndicator(GET_GOVERNORATES_BI));

		AddressesService.getCities().subscribe(
			(response) => {
				dispatch(removeBusyIndicator(GET_CITIES_BI));
				setCities(response);
			},
			() => {
				dispatch(removeBusyIndicator(GET_CITIES_BI));
			}
		);
		AddressesService.getRegions().subscribe(
			(response) => {
				setRegions(response);
				dispatch(removeBusyIndicator(GET_REGIONS_BI));
			},
			() => {
				dispatch(removeBusyIndicator(GET_REGIONS_BI));
			}
		);
		AddressesService.getGovernorates().subscribe(
			(response) => {
				setGovernorates(response);
				dispatch(removeBusyIndicator(GET_GOVERNORATES_BI));
			},
			() => {
				dispatch(removeBusyIndicator(GET_GOVERNORATES_BI));
			}
		);
	}, []);

	useEffect(() => {
		if (!fullMap && markerPosition) {
			mapsRef.current?.panTo(markerPosition);
		}
	}, [fullMap, markerPosition]);

	useEffect(() => {
		if (!fullMap && !!selectedGovernorate) {
			resetDropdowns(GOVERNORATE);
		}
	}, [selectedGovernorate]);

	useEffect(() => {
		if (!fullMap && !!selectedWilayat) {
			resetDropdowns(WILAYAT);
		}
	}, [selectedWilayat]);

	useEffect(() => {
		if (!fullMap && !!selectedCity) {
			resetDropdowns(CITY);
		}
	}, [selectedCity]);

	const clearInputs = () => {
		formikRef.current?.setFieldValue('landmark', '');
		formikRef.current?.setFieldValue('way', '');
		formikRef.current?.setFieldValue('house', '');
		formikRef.current?.setFieldValue('apartment', '');
		setValidate(false);
	};

	const resetDropdowns = (dropdownId: string) => {
		switch (dropdownId) {
			case GOVERNORATE:
				setSelectedWilayat(null);
				setSelectedCity(null);
				setSelectedArea(null);
				break;
			case WILAYAT:
				setSelectedCity(null);
				setSelectedArea(null);
				break;
			case CITY:
				setSelectedArea(null);
				break;
			default:
				setSelectedGovernorate(null);
				setSelectedWilayat(null);
				setSelectedCity(null);
				setSelectedArea(null);
		}
		if (!savedAddress) clearInputs();
	};

	const _setPredictions = useCallback(
		debounce((search: string) => {
			if (search) {
				autocomplete.current
					?.getPlacePredictions(
						{ input: search, componentRestrictions: { country: 'om' } },
						(_predictions): void => {
							if (Array.isArray(_predictions)) {
								setPredictions(_predictions);
							} else {
								setPredictions([]);
							}
						}
					)
					?.then(
						() => {},
						() => {}
					);
			} else {
				setPredictions([]);
			}
		}, 250),
		[]
	);

	useEffect(() => {
		if (window.google && !autocomplete.current) {
			autocomplete.current = new google.maps.places.AutocompleteService();
		}
		if (window.google && !placeService.current) {
			placeService.current = new google.maps.places.PlacesService(document.createElement('div'));
		}
		if (window.google && !geocoderService.current) {
			geocoderService.current = new google.maps.Geocoder();
		}
	}, [window.google]);

	useEffect(() => {
		if (selectedPrediction) {
			placeService.current?.getDetails({ placeId: selectedPrediction.place_id }, (response) => {
				const { geometry } = response || {};
				if (geometry) {
					setPosition({ lat: geometry?.location?.lat() ?? 0, lng: geometry?.location?.lng() ?? 0 });
					setSearch(selectedPrediction.description ?? '');
					setPredictions([]);
					setSelectedPrediction(undefined);
				}
			});
		}
	}, [selectedPrediction]);

	useEffect(() => {
		mapsRef.current?.panTo(position);
	}, [position]);

	const findZipCode = (city: string): string => {
		const findedCity = cities.find(({ cities }) => cities.find(({ key }) => key === city));
		if (findedCity) {
			const cityToSelect = findedCity.cities.find(({ key }) => key === city);
			const areaToselect = cityToSelect?.areas?.find(({ key }) => key === selectedArea?.id);
			setZipCode(areaToselect?.zip_code ?? '');
			return areaToselect?.zip_code ?? '';
		}
		return '';
	};

	const findedGovernorate = (governorate: string | undefined, city: string | undefined, way: string | undefined) => {
		if (city) {
			const findedGovernorate = governorates.find((item) => item.key === governorate);
			const findedWilayat = cities.find(({ cities }) => cities.find(({ key }) => key === city));
			if (findedGovernorate) {
				setSelectedGovernorate({
					id: findedGovernorate?.key ?? '',
					text: findedGovernorate?.governorate ?? '',
				});
				if (findedWilayat) {
					setSelectedWilayat({ id: findedWilayat?.key ?? '', text: findedWilayat?.name_en ?? '' });
					const cityToSelect = findedWilayat.cities.find(({ key }) => key === city);
					if (cityToSelect) {
						setSelectedCity({
							id: cityToSelect?.key,
							text: cityToSelect?.name_en,
						});
						setZipCode(cityToSelect.zip_code ?? '');
					}
					if (way) {
						formikRef.current?.setFieldValue('way', way);
					}
				}
			}
			if (!findedGovernorate && regions.length > 0 && !savedAddress) {
				resetDropdowns('ALL');
			}
			return findedGovernorate;
		}
	};

	useEffect(() => {
		geocoderService.current
			?.geocode({ location: markerPosition }, (response) => {
				const address = response?.find((item) => item.types.includes('route'));
				if (address) {
					const { address_components, formatted_address } = address;
					setSearch(formatted_address);
					const way = address_components.find((item) => item.types.includes('route'))?.long_name ?? '';
					const governorate =
						address_components.find(({ types }) => types.includes('administrative_area_level_1'))
							?.long_name ?? '';
					const city =
						address_components
							.find(({ types }) => types.includes('locality') && types.includes('political'))
							?.long_name.replace(/Sib/g, DISABLE_NEW_ADDRESS_DROPDOWN ? 'Seeb' : 'Al Seeb')
							.replace(/Matrah/g, 'Muttrah')
							.replace(/Al Khaburah/g, 'Al Khaboura') ?? '';
					const _governorate = governorate.replace(/Governorate/g, '').trim();
					if (city && (!savedAddress || isMarkerPositionUserGenerated)) {
						findedGovernorate(_governorate, city, way);
					} else if (_governorate && !savedAddress) {
						const _findedGovernorate = governorates.find((gov) => gov.key === _governorate);
						if (_findedGovernorate) {
							setSelectedGovernorate({
								id: _findedGovernorate.key,
								text: _findedGovernorate.governorate,
							});
						} else if (regions.length > 0 && !savedAddress) {
							resetDropdowns('ALL');
						}
					} else if (regions.length > 0 && !savedAddress) {
						resetDropdowns('ALL');
					}
				} else if (regions.length > 0 && !savedAddress) {
					resetDropdowns('ALL');
				}
			})
			?.then(
				() => {},
				() => {
					if (action !== 'create') {
						findedGovernorate(savedAddress?.governorate, savedAddress?.city, savedAddress?.way);
					}
				}
			);
	}, [markerPosition, cities, regions, governorates, savedAddress]);

	const setMarker = (event: google.maps.MapMouseEvent) => {
		const lat = event.latLng?.lat();
		const lng = event.latLng?.lng();
		if (lat && lng && fullMap) {
			setIsMarkerPositionUserGenerated(true);
			resetDropdowns('ALL');
			clearInputs();
			setMarkerPosition({ lat, lng });
		}
	};

	const onSave = () => {
		if (selectedArea && selectedCity && selectedGovernorate && selectedWilayat) {
			const { organization = '', way, house, apartment, landmark } = formikRef.current?.values || {};
			const governorate = selectedGovernorate?.text ?? '';
			const wilayat = selectedWilayat?.text ?? '';
			const city = selectedCity?.text ?? '';
			const area = selectedArea?.text ?? '';
			setIsMarkerPositionUserGenerated(false);
			const closeModal = () => {
				dispatch(
					setModal({
						data: null,
						type: null,
					})
				);
			};

			addBusyIndicator(SAVE_ADDRESS_BI);
			const _type: AddressType = type === 'billingAddress' ? 'ShippingAndBilling' : 'Shipping';
			const payload = {
				addressType: _type,
				organization,
				way,
				house,
				apartment,
				landmark,
				governorate,
				markerPosition,
				city,
				wilayat,
				area,
				zipCode: findZipCode(city),
				...contactDetails,
			};

			if (action === 'create') {
				const p: IUpdateProfilePayloadInput = payload;
				if (payload.markerPosition) {
					const { lat, lng } = payload.markerPosition;
					p.geographicalShippingCode = `{"longitude": "${lng}", "latitude":"${lat}"}`;
				}
				p.nickName = `${p.governorate?.substring(0, 3).toUpperCase()}${Math.random()
					.toString(36)
					.substring(8)}`;
				CommerceProfileService.addContactToProfile(p).subscribe(
					(response) => {
						dispatch(
							setNewAddressBook({
								newAddressBook: response.addressId,
							})
						);
						updateShippingAddress({
							addressId: response.addressId,
							...payload,
							zipCode: findZipCode(city),
							way: payload?.way ?? '',
						});
						removeBusyIndicator(SAVE_ADDRESS_BI);
						dispatch(setRefreshAddressBook({}));
						closeModal();
					},
					() => {
						removeBusyIndicator(SAVE_ADDRESS_BI);
					}
				);
			} else if (action === 'update') {
				let t = _type.valueOf();
				if (nickName && nickName.length > 0) {
					t = nickName;
				}
				CommerceProfileService.updateUserProfile(t, payload).subscribe(
					(response) => {
						dispatch(
							setCheckoutDetails({
								[type]: {
									organization,
									way,
									house,
									apartment,
									landmark,
									governorate,
									wilayat,
									city,
									area,
									markerPosition,
									addressId: response.addressId,
									zipCode: findZipCode(city),
								},
							})
						);
						removeBusyIndicator(SAVE_ADDRESS_BI);
						dispatch(setRefreshAddressBook({}));
						closeModal();
					},
					() => {
						removeBusyIndicator(SAVE_ADDRESS_BI);
					}
				);
			}
		} else {
			setValidate(true);
		}
	};

	const showSaveAddressCheckbox = (): boolean => {
		if (isUserSignedIn() && !isGuest) {
			if (action === 'create') {
				return false;
			} else if (nickName && nickName?.length > 0) {
				return false;
			} else {
				return true;
			}
		}
		return false;
	};

	useEffect(() => {
		if (savedAddress) {
			if (action === 'create') {
				formikRef.current?.setFieldValue('landmark', '');
				formikRef.current?.setFieldValue('way', '');
				formikRef.current?.setFieldValue('house', '');
				formikRef.current?.setFieldValue('apartment', '');
				formikRef.current?.setFieldValue('organization', '');
			} else {
				findedGovernorate(savedAddress.governorate, savedAddress.city, savedAddress.way);
				setSelectedArea(areas.find((item) => item.text === savedAddress.area) ?? null);
				formikRef.current?.setFieldValue('landmark', savedAddress.landmark);
				formikRef.current?.setFieldValue('way', savedAddress.way);
				formikRef.current?.setFieldValue('house', savedAddress.house);
				formikRef.current?.setFieldValue('apartment', savedAddress.apartment);
				formikRef.current?.setFieldValue('organization', savedAddress.organization);
			}
		}
	}, [savedAddress, action]);

	useEffect(() => {
		if (savedAddress) {
			const area = cities
				.find((item) => item.name_en === selectedWilayat?.id)
				?.cities.find((item) => item.key === selectedCity?.id)
				?.areas?.find((item) => item.name_en === savedAddress.area);
			if (!!savedAddress.area && action !== 'create' && !!area) {
				setSelectedArea({ text: area?.name_en ?? '', id: area?.key ?? '' });
			}
		}
	}, [savedAddress, selectedWilayat, selectedCity]);

	return (
		<DeliveryAddressCardContainer>
			<DeliveryAddressHeader>
				<DeliveryAddressCardTitle>{translate(`deliver-address.${type}`)}</DeliveryAddressCardTitle>
				<Icon
					name="close"
					fill="black87"
					onClick={() => {
						dispatch(
							setModal({
								data: null,
								type: null,
							})
						);
					}}
				/>
			</DeliveryAddressHeader>
			<Divider marginTop={23} marginBottom={16} />
			<ContentContainer>
				<BusyIndicator
					listener={[CURRENT_POSITION_BI, GET_CITIES_BI, GET_REGIONS_BI, GET_GOVERNORATES_BI]}
					skeleton="deliveryAddress"
				>
					<>
						<Map
							mapElement={<MapElement fullMap={fullMap} />}
							zoom={zoom}
							position={position}
							mapsRef={mapsRef}
							markerPosition={markerPosition}
							onClick={setMarker}
							containerElement={<MapContainerElement />}
							locked={!fullMap}
						/>
						<CurrentPositionButtonContainer fullMap={fullMap}>
							<TextField
								onChange={(val) => {
									setSearch(val as string);
									_setPredictions(val as string);
								}}
								id="search_input"
								value={search}
								rightIcon={search ? 'clearInput' : undefined}
								leftIcon="search"
								width="100%"
								onIconClick={() => {
									setSearch('');
									setPredictions([]);
								}}
								height={48}
								placeholder={translate('delivery.address.search')}
								noBorder={true}
							/>
							<ConditionalRender
								show={!!predictions.length}
								onTrue={() => (
									<AutoCompleteList
										predictions={predictions}
										onSelect={(prediction: google.maps.places.AutocompletePrediction) => {
											setSelectedPrediction(prediction);
										}}
									/>
								)}
							/>
							<Tooltip
								text={translate('deliver-address.disabled-position')}
								width={280}
								disable={!currentPositionDisabled}
							>
								<MapButton
									onClick={() => {
										if (!currentPositionDisabled) {
											getCurrentPosition();
										}
									}}
								>
									<Icon
										name="currentPosition"
										width={ZOOM_ICON_SIZE}
										height={ZOOM_ICON_SIZE}
										fill={currentPositionDisabled ? 'black24' : 'black54'}
									/>
								</MapButton>
							</Tooltip>
						</CurrentPositionButtonContainer>
						<Hidden xs sm>
							<ConditionalRender
								show={fullMap}
								onTrue={() => (
									<ZoomContainer fullMap={fullMap}>
										<MapButton
											onClick={() => {
												if (zoom < MAX_ZOOM) {
													setZoom(zoom + 1);
												}
											}}
										>
											<Icon
												name="plus"
												width={ZOOM_ICON_SIZE}
												height={ZOOM_ICON_SIZE}
												fill="black54"
											/>
										</MapButton>
										<Divider withoutLine marginBottom={8} />
										<MapButton
											onClick={() => {
												if (zoom > MIN_ZOOM) {
													setZoom(zoom - 1);
												}
											}}
										>
											<Icon
												name="minus"
												width={ZOOM_ICON_SIZE}
												height={ZOOM_ICON_SIZE}
												fill="black54"
											/>
										</MapButton>
									</ZoomContainer>
								)}
							/>
						</Hidden>

						<TextFieldsContainer fullMap={fullMap}>
							<Formik<IFormikFields>
								initialValues={{
									organization: savedAddress?.organization ?? '',
									way: savedAddress?.way ?? '',
									house: savedAddress?.house ?? '',
									apartment: savedAddress?.apartment ?? '',
									landmark: savedAddress?.landmark ?? '',
								}}
								onSubmit={() => {}}
								innerRef={formikRef}
							>
								{({ values, handleBlur, setFieldValue }) => (
									<>
										<ConditionalRender
											show={!isIncorrectArea}
											onTrue={() => (
												<>
													<TextField
														onChange={(value) => setFieldValue('organization', value)}
														label={translate('deliver-address.text-field.organization')}
														id="organization"
														value={values.organization}
														marginBottom={8}
														maxlength={100}
														onBlur={() => handleBlur('organization')}
													/>
													<RegularDropdown
														items={governorates
															.map((gov) => ({ text: gov.governorate, id: gov.key }))
															.sort(ascendingSort)}
														selectedItem={selectedGovernorate}
														setSelectedItem={setSelectedGovernorate}
														label={translate('deliver-address.text-field.governorate')}
														labelType={LabelType.FLOATING}
														message={
															!selectedGovernorate && validate
																? translate('delivery.address.required-field')
																: ''
														}
														error={!selectedGovernorate && validate}
														dropdownListWidth="100%"
													/>
													<Divider withoutLine marginBottom={8} />
													<RegularDropdown
														items={regions
															.filter(
																({ region }) => region === selectedGovernorate?.text
															)
															.map(({ key }) => ({ text: key, id: key }))
															.sort(ascendingSort)}
														selectedItem={selectedWilayat}
														setSelectedItem={setSelectedWilayat}
														label={translate('deliver-address.text-field.wilayat')}
														labelType={LabelType.FLOATING}
														disabled={!selectedGovernorate}
														message={
															!selectedWilayat && validate
																? translate('delivery.address.required-field')
																: ''
														}
														error={!selectedWilayat && validate}
														dropdownListWidth="100%"
													/>
													<Divider withoutLine marginBottom={8} />
													<RegularDropdown
														items={
															cities
																.find((city) => city.key === selectedWilayat?.id)
																?.cities.map((item) => ({
																	text: item.name_en,
																	id: item.key,
																}))
																.sort(ascendingSort) ?? []
														}
														selectedItem={selectedCity}
														setSelectedItem={setSelectedCity}
														label={translate('deliver-address.text-field.city')}
														labelType={LabelType.FLOATING}
														disabled={!selectedWilayat}
														message={
															!selectedCity && validate
																? translate('delivery.address.required-field')
																: ''
														}
														error={!selectedCity && validate}
														dropdownListWidth="100%"
													/>
												</>
											)}
											onFalse={() => (
												<>
													<MissingAreaReminderWrapper>
														{translate('delivery.area.missing-reminder')}
													</MissingAreaReminderWrapper>
												</>
											)}
										/>
										<Divider withoutLine marginBottom={8} />
										<RegularDropdown
											items={areas}
											selectedItem={selectedArea}
											setSelectedItem={setSelectedArea}
											label={translate('deliver-address.text-field.area')}
											labelType={LabelType.FLOATING}
											disabled={!selectedCity}
											message={
												!selectedArea && validate
													? translate('delivery.address.required-field')
													: ''
											}
											error={!selectedArea && validate}
											dropdownListWidth="100%"
										/>
										<Divider withoutLine marginBottom={8} />
										<ConditionalRender
											show={!isIncorrectArea}
											onTrue={() => (
												<>
													<TextField
														onChange={(value) => setFieldValue('way', value)}
														label={translate('deliver-address.text-field.way')}
														id="way"
														value={values.way}
														marginBottom={8}
														maxlength={64}
														onBlur={() => handleBlur('way')}
													/>
													<TextField
														onChange={(value) => setFieldValue('house', value)}
														label={translate('deliver-address.text-field.house')}
														id="house"
														value={values.house}
														marginBottom={8}
														maxlength={16}
														onBlur={() => handleBlur('house')}
													/>
													<TextField
														onChange={(value) => setFieldValue('apartment', value)}
														label={translate('deliver-address.text-field.apartment')}
														id="apartment"
														value={values.apartment}
														marginBottom={8}
														maxlength={16}
														onBlur={() => handleBlur('apartment')}
													/>
													<TextArea
														onChange={(value) => setFieldValue('landmark', value)}
														label={translate('deliver-address.text-field.landmark')}
														id="landmark"
														value={values.landmark}
														marginBottom={10}
														maxLength={64}
													/>
												</>
											)}
											onFalse={() => (
												<AreaInformationColumn>
													<SingleInformationWrapper>
														<Caption color="black54">
															{translate('deliver-address.text-field.governorate')}
														</Caption>
														<ParagraphMBold>{selectedGovernorate?.text}</ParagraphMBold>
													</SingleInformationWrapper>
													<SingleInformationWrapper>
														<Caption color="black54">
															{translate('deliver-address.text-field.wilayat')}
														</Caption>
														<ParagraphMBold>{selectedWilayat?.text}</ParagraphMBold>
													</SingleInformationWrapper>
													<SingleInformationWrapper>
														<Caption color="black54">
															{translate('deliver-address.text-field.city')}
														</Caption>
														<ParagraphMBold>{selectedCity?.text}</ParagraphMBold>
													</SingleInformationWrapper>
													<SingleInformationWrapper>
														<Caption color="black54">
															{translate('deliver-address.text-field.way')}
														</Caption>
														<ParagraphMBold>{values.way}</ParagraphMBold>
													</SingleInformationWrapper>
													<SingleInformationWrapper>
														<ConditionalRender
															show={!!values.house}
															onTrue={() => (
																<>
																	<Caption color="black54">
																		{translate(
																			'deliver-address.text-field.house-info'
																		)}
																	</Caption>
																	<ParagraphMBold>{values.house}</ParagraphMBold>
																</>
															)}
														/>
													</SingleInformationWrapper>
												</AreaInformationColumn>
											)}
										/>
										<ConditionalRender
											show={!!isIncorrectArea && !fullMap && matchScreen('xs')}
											onTrue={() => (
												<Map
													mapElement={
														<MapElement
															fullMap={fullMap}
															isIncorrectArea={isIncorrectArea}
														/>
													}
													zoom={zoom}
													position={position}
													mapsRef={mapsRef}
													markerPosition={markerPosition}
													onClick={setMarker}
													containerElement={<MapContainerElement />}
													locked={!fullMap}
												/>
											)}
										/>
									</>
								)}
							</Formik>
							<ConditionalRender
								show={showSaveAddressCheckbox()}
								onTrue={() => (
									<Checkbox
										active={saveAddress}
										onChange={() => {
											setSaveAddress(!saveAddress);
										}}
										shape={CheckboxShape.CIRCULAR}
										size={20}
										label={translate('deliver-address.save-address')}
										labelClickable
									/>
								)}
							/>

							<Divider withoutLine marginBottom={8} />
						</TextFieldsContainer>
					</>
				</BusyIndicator>
			</ContentContainer>
			<DeliveryAddressBottom isIncorrectArea={!!isIncorrectArea && !fullMap}>
				<ConditionalRender
					show={fullMap}
					onTrue={() => (
						<FullMapContainer>
							<Row justify="center">
								<Col xs={12} sm={9}>
									<Row>
										<MapDescription>{translate('deliver-address.map-description')}</MapDescription>
									</Row>
								</Col>
								<Col xs={12} sm={3}>
									<ButtonWrapper>
										<Visible xs>
											<PrimaryMediumButtonFlex
												uniqueId="deliver-address.next"
												onClick={() => {
													setFullMap(false);
												}}
												disabled={!markerPosition}
											>
												{translate('deliver-address.next')}
											</PrimaryMediumButtonFlex>
										</Visible>
										<Hidden xs>
											<PrimaryMediumButtonFlex
												uniqueId="deliver-address.next"
												onClick={() => {
													setFullMap(false);
												}}
												disabled={!markerPosition}
											>
												{translate('deliver-address.next')}
											</PrimaryMediumButtonFlex>
										</Hidden>
									</ButtonWrapper>
								</Col>
							</Row>
						</FullMapContainer>
					)}
					onFalse={() => (
						<>
							<ConditionalRender
								show={!isIncorrectArea}
								onTrue={() => (
									<SecondaryMediumButtonFixed
										uniqueId="deliver-address.view-map"
										onClick={() => {
											setFullMap(true);
										}}
									>
										{translate('deliver-address.view-map')}
									</SecondaryMediumButtonFixed>
								)}
							/>
							<PrimaryMediumButtonFixed
								uniqueId="deliver-address.save"
								onClick={onSave}
								listener={SAVE_ADDRESS_BI}
							>
								{translate('deliver-address.save')}
							</PrimaryMediumButtonFixed>
						</>
					)}
				/>
			</DeliveryAddressBottom>
		</DeliveryAddressCardContainer>
	);
};

export default DeliveryAddressCard;
