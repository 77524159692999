import styled from 'styled-components';
import { Subheading, ParagraphS, BodyM } from '@Components/Typography/Typography';
import { marginLeft } from '@Utils/layout/layoutSupport';

interface ISectionDataProps {
	isLargeScreen?: boolean;
}

export const DetailsButtonWraper = styled.div<ISectionDataProps>`
	${() => marginLeft('auto')};
	${({ isLargeScreen }) =>
		isLargeScreen ? { alignSelf: 'center' } : { display: 'flex', justifyContent: 'flex-end' }}
`;

export const InsuranceSection = styled.div`
	display: flex;
	align-items: flex-start;
`;

export const InsuranceFirstSection = styled.div`
	display: flex;
	align-items: center;
`;

export const SectionData = styled.div`
	display: flex;
	flex-direction: column;
	${() => marginLeft('16px')}
`;

export const InsuranceSectionTitle = styled(Subheading)`
	margin: 36px 0 10px 0;
	text-transform: uppercase;
	${({ theme }) => {
		return {
			color: theme.colors.black54,
		};
	}}
`;

export const SectionSubtitle = styled(ParagraphS)`
	margin: 4px 0;
	${({ theme }) => {
		return {
			color: theme.colors.black54,
		};
	}}
`;

export const IconWrapper = styled.div`
	margin-right: 16px;
`;

export const InfoWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 32px;
	padding: 8px 16px;
	border-radius: 4px;
	${({ theme }) => {
		return {
			backgroundColor: theme.colors.black6,
		};
	}}
`;

export const BodyMWrapper = styled(BodyM)`
	${({ theme }) => {
		return {
			color: theme.colors.black54,
		};
	}}
`;

export const CarIconWrapper = styled.div`
	${({ theme }) => {
		return {
			backgroundColor: theme.colors.green,
		};
	}}
	padding: 4px;
	border-radius: 8px;
`;
