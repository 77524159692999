import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const NavigationBarWrapper = styled.div`
	background-color: ${({ theme }) => theme.colors.white};
	box-shadow: 0 7px 8px 0 rgba(0, 0, 0, 0.05), 0 5px 22px 4px rgba(0, 0, 0, 0.04), 0 12px 17px 2px rgba(0, 0, 0, 0.04);
	z-index: 99;
	position: relative;
	padding-top: 16px;
	padding-bottom: 16px;
	margin-bottom: 32px;
`;

export const LogoLinkWrapper = styled(Link)`
	margin-top: 12px;
`;

export const BackButtonWrapper = styled.div`
	margin-top: 16px;
	margin-left: 20px;
`;
