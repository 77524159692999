import styled from 'styled-components';

export const ButtonsWrapper = styled.div<{ isXs?: boolean }>`
	margin-top: 24px;
	display: flex;
	width: 100%;
	margin-bottom: 108px;
	${({ isXs }) => ({
		flexDirection: isXs ? 'column' : 'row',
		gap: isXs ? '16px' : '0px',
		justifyContent: isXs ? 'unset' : 'space-between',
	})}
`;

export const ButtonWrapper = styled.div<{ isXs?: boolean }>`
	${({ isXs }) => ({
		width: isXs ? '100%' : '160px',
	})}
`;
