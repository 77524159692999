import ConditionalRender from '@Components/ConditionalRender/ConditionalRender';
import { PrimaryLargeButtonFlex, PrimaryMediumButtonFixed } from '@Components/controls/Button/Button';
import { RegularDropdown } from '@Components/controls/Dropdown/Dropdown';
import { DropdownHeight, LabelType } from '@Components/controls/Dropdown/Dropdown.styled';
import TextField from '@Components/controls/TextField/TextField';
import Divider from '@Components/Divider/Divider';
import Icon from '@Components/Icon/Icon';
import { IStatusModalProps } from '@Components/Modal/modals/Status/Status';
import { addBusyIndicator, removeBusyIndicator } from '@Redux/modules/busyIndicator/actions';
import { modalTypes, setModal } from '@Redux/modules/modal/actions';
import { ProductsService } from '@Services/productsService/productsService';
import { usePrepaidMigration } from '@Utils/context/PrepaidMigrationContext';
import externalLinks from '@Utils/externalLinks/externalLinks';
import useScreen from '@Utils/hooks/useScreen';
import useTranslate from '@Utils/hooks/useTranslate';
import phoneNumber from '@Utils/validators/phoneNumber';
import { Formik } from 'formik';
import React, { FunctionComponent, useState } from 'react';
import { Col, Row } from 'react-grid-system';
import { useDispatch, useSelector } from 'react-redux';
import {
	ContainerWrapper,
	ErrorTextWrapper,
	ErrorTitle,
	ErrorTitleXS,
	IconContainer,
	InputRow,
	Subtitle,
	SubtitleXS,
	Title,
	TitleXS,
	WarningContainer,
} from './ChooseNumber.styled';

interface IFormikFields {
	phoneNumber: string;
}

const CHECK_NUMBER_BI = 'checkPrepaidNumber';

const ChooseNumber: FunctionComponent = () => {
	const [disabled, setDisabled] = useState(false);
	const { screen } = useScreen();
	const isXs = screen(['xs']);
	const { translate } = useTranslate();
	const isLogged = useSelector((state: types.redux.IState) => !!state.api.authentication.signedIn);
	const dispatch = useDispatch();

	const { availableNumbers, setChoosenNumber, nextStep, choosenNumber } = usePrepaidMigration();

	const onSubmit = ({ phoneNumber }: IFormikFields) => {
		setDisabled(true);
		if (isLogged) {
			nextStep();
		} else {
			const ErrorWrapper = isXs ? ErrorTitleXS : ErrorTitle;
			const onError = (bodyText: string) => {
				const data: IStatusModalProps = {
					status: 'error',
					body: <ErrorWrapper>{bodyText}</ErrorWrapper>,
					primaryButtonText: translate('choose-number.check'),
					onClickPrimary: () => {
						dispatch(setModal({ closeAllModals: true, type: null }));
					},
					secondaryButtonText: translate('choose-number.back'),
					onClickSecondary: () => {
						window.location.href = externalLinks('OMANTEL');
					},
				};
				setDisabled(false);
				dispatch(setModal({ type: modalTypes.STATUS, withoutContainer: true, data }));
			};
			dispatch(addBusyIndicator(CHECK_NUMBER_BI));
			ProductsService.getProductsUnauthorized(phoneNumber).subscribe(
				({ isPrepaid }) => {
					if (isPrepaid) {
						setChoosenNumber(phoneNumber);
						nextStep();
						setDisabled(false);
					} else {
						onError(translate('choose-number.error-not-prepaid'));
					}
					dispatch(removeBusyIndicator(CHECK_NUMBER_BI));
				},
				() => {
					dispatch(removeBusyIndicator(CHECK_NUMBER_BI));
					onError(translate('choose-number.error'));
				}
			);
		}
	};

	const TitleWrapper = isXs ? TitleXS : Title;
	const SubtitleWrapper = isXs ? SubtitleXS : Subtitle;
	const ButtonWrapper = isXs ? PrimaryMediumButtonFixed : PrimaryLargeButtonFlex;

	return (
		<ContainerWrapper isXs={isXs}>
			<TitleWrapper>{translate('choose-number.title')}</TitleWrapper>
			<SubtitleWrapper>{translate('choose-number.subtitle')}</SubtitleWrapper>
			<Formik<IFormikFields>
				initialValues={{ phoneNumber: '' }}
				onSubmit={onSubmit}
				validationSchema={phoneNumber()}
			>
				{({
					setFieldValue,
					values: { phoneNumber },
					errors: { phoneNumber: phoneNumberError },
					handleSubmit,
				}) => (
					<InputRow>
						<Col xs={12} sm={8} md={6} lg={4}>
							<ConditionalRender
								show={!isLogged}
								onTrue={() => (
									<TextField
										width="100%"
										id="phoneNumber"
										onChange={(e) => {
											setFieldValue('phoneNumber', e);
										}}
										error={!!phoneNumberError}
										message={phoneNumberError}
										value={phoneNumber}
										placeholder={translate('choose-number.text-field.placeholder')}
										disabled={disabled}
									/>
								)}
								onFalse={() => (
									<RegularDropdown
										dropdownHeight={DropdownHeight.SCROLL}
										items={availableNumbers ?? []}
										setSelectedItem={(selectedItem) => {
											setFieldValue('phoneNumber', selectedItem.text);
											setChoosenNumber(selectedItem.text);
										}}
										disabled={!availableNumbers?.length}
										selectedItem={choosenNumber ? { id: choosenNumber, text: choosenNumber } : null}
										labelType={LabelType.FLOATING}
										placeholder={translate('choose-number.dropdown.placeholder')}
										dropdownWidth="100%"
									/>
								)}
							/>
						</Col>
						<Col xs={8} sm={4} md={4} lg={3} xl={2}>
							<ConditionalRender show={isXs} onTrue={() => <Divider marginTop={16} withoutLine />} />
							<ButtonWrapper
								uniqueId="prepaid-to-postpaid.check-offers"
								onClick={handleSubmit}
								disabled={disabled}
								listener={CHECK_NUMBER_BI}
							>
								{translate('choose-number.button')}
							</ButtonWrapper>
						</Col>
					</InputRow>
				)}
			</Formik>
			<Row>
				<Col xs={12} sm={12} md={10} lg={7} xl={6}>
					<ConditionalRender
						show={isLogged && !availableNumbers?.length}
						onTrue={() => (
							<WarningContainer>
								<IconContainer>
									<Icon name="warning" fill="warning" width={24} height={24} />
								</IconContainer>
								<ErrorTextWrapper color="warning">
									{translate('choose-number.warning')}
								</ErrorTextWrapper>
							</WarningContainer>
						)}
						onFalse={() => <Divider marginBottom={32} withoutLine />}
					/>
				</Col>
			</Row>
		</ContainerWrapper>
	);
};

export default ChooseNumber;
