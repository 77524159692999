import { left } from '@Utils/layout/layoutSupport';
import styled from 'styled-components';

export const UnavailableProductWrapper = styled.div<{ withColorPicker?: boolean }>`
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	background-color: rgba(255, 255, 255, 0.8);
	display: flex;
	align-items: center;
	justify-content: center;
	${({ withColorPicker }) => withColorPicker && left(30)}
`;

export const UnavailableProductTagWrapper = styled.div`
	padding: 4px 16px;
	border-radius: 8px;
	${({ theme }) => ({ backgroundColor: theme.colors.grey300 })}
`;
