export type ICorpAccount = {
	accountId: string;
	accountName: string;
	documentId: string;
	documentType: string;
};

export type ICorpAccounts = {
	response: any;
	accounts: ICorpAccount[];
};

const model = {
	response: 'response',
	accounts: 'response.getCorpAccountsForCurrentUserResponse.accounts',
};

export default model;
