import React, { FunctionComponent, useState } from 'react';
import { Col, Row } from 'react-grid-system';
import { BGContainer, Button, CardContainer, CardWrapper, LogoWrapper, TextButton } from './LoginSessionExpired.styled';
import useTranslate from '@Utils/hooks/useTranslate';
import Icon from '@Components/Icon/Icon';
import { BodyM, TitleBold } from '@Components/Typography/Typography';
import TextField from '@Components/controls/TextField/TextField';
import { PrimaryLargeButtonFixed, PrimaryLargeButtonFlex } from '@Components/controls/Button/Button';
import { useDispatch } from 'react-redux';
import { setModal } from '@Redux/modules/modal/actions';
import { AuthenticationService } from '@Services/authentication/authenticationService';
import Divider from '@Components/Divider/Divider';
import externalLinks from '@Utils/externalLinks/externalLinks';
import ConditionalRender from '@Components/ConditionalRender/ConditionalRender';
import useScreen from '@Utils/hooks/useScreen';

const LoginSessionExpired: FunctionComponent<types.cards.ILoginSessionExpiredCardProps> = (): JSX.Element => {
	const [login, setLogin] = useState<string | number>('');
	const [password, setPassword] = useState<string | number>('');
	const [loading, setLoading] = useState<boolean>(false);
	const [isError, setIsError] = useState<boolean>(false);
	const [errorMsg, setErrorMsg] = useState<string>('');
	const { screen } = useScreen();

	const { translate } = useTranslate();
	const dispatch = useDispatch();

	const handleClose = () => {
		dispatch(
			setModal({
				type: null,
				data: null,
			})
		);
	};

	const _signIn = () => {
		setLoading(true);
		setIsError(false);
		setErrorMsg('');
		AuthenticationService.login(login.toString(), password.toString()).subscribe(
			() => {
				setLoading(false);
				handleClose();
				location.reload();
			},
			() => {
				setIsError(true);
				setLoading(false);
				setErrorMsg(translate('modal.login.invalid.credentials'));
			}
		);
	};

	const forgotPassword = () => {
		window.open(externalLinks('FORGOT_PASSWORD'));
	};

	return (
		<CardContainer>
			<BGContainer>
				<Icon name="loginBg" width={460} height={520} />
			</BGContainer>
			<CardWrapper>
				<Row>
					<LogoWrapper>
						<Icon name="omantelLogo" width={96} height={48} />
					</LogoWrapper>
				</Row>
				<Row>
					<Divider marginBottom={40} withoutLine />
				</Row>
				<Row>
					<Col>
						<TitleBold>{translate('generic.user.session.error')}</TitleBold>
						<Divider marginBottom={8} withoutLine />
						<BodyM color="black54">{translate('generic.user.session.error.description')}</BodyM>
					</Col>
					<Col>
						<TextField
							id="login"
							name="login"
							type="text"
							placeholder={translate('modal.login.phone.number.or.email')}
							value={login}
							onChange={(login) => {
								setLogin(login);
							}}
							marginBottom={8}
							onEnter={() => _signIn()}
							error={isError}
						/>
						<TextField
							id="1"
							name="password"
							placeholder={translate('modal.login.password')}
							value={password}
							onChange={(password) => {
								setPassword(password);
							}}
							marginBottom={8}
							message={errorMsg}
							error={isError}
							type="password"
							onEnter={() => _signIn()}
						/>
						<Divider marginBottom={8} withoutLine />
						<Button isXs={screen(['xs'])}>
							<ConditionalRender
								show={!screen(['xs'])}
								onTrue={() => (
									<PrimaryLargeButtonFixed
										uniqueId="modal.login.sign.in"
										listener={loading}
										onClick={() => _signIn()}
									>
										{translate('modal.login.sign.in')}
									</PrimaryLargeButtonFixed>
								)}
								onFalse={() => (
									<PrimaryLargeButtonFlex
										uniqueId="modal.login.sign.in"
										listener={loading}
										onClick={() => _signIn()}
									>
										{translate('modal.login.sign.in')}
									</PrimaryLargeButtonFlex>
								)}
							/>
						</Button>
						<Divider marginBottom={16} withoutLine />
						<TextButton onClick={() => forgotPassword()}>
							{translate('modal.login.forgot.password')}
						</TextButton>
						<Divider marginBottom={40} withoutLine />
					</Col>
				</Row>
			</CardWrapper>
		</CardContainer>
	);
};

export default LoginSessionExpired;
