import React, { FunctionComponent, useMemo } from 'react';
import Card from '@Components/Card/Card';
import useScreen from '@Utils/hooks/useScreen';
import { getResponsiveModalStyles } from '@Utils/layout/layoutSupport';
import { ModalContainer } from '@Components/Modal/subComponents/ModalContainer/ModalContiner.styled';

const ModalTelesalesOtpCard: FunctionComponent<types.cards.IOtpCardProps['params']> = ({ otpRef }) => {
	const { screenClass } = useScreen();
	const styles = useMemo(() => getResponsiveModalStyles(screenClass), [screenClass]);
	return (
		<ModalContainer styles={styles}>
			<Card<types.cards.IOtpCardProps> card="telesalesOtp" params={{ otpRef }} withoutContainerPadding />
		</ModalContainer>
	);
};

export default ModalTelesalesOtpCard;
