import React, { FunctionComponent, useMemo } from 'react';
import {
	CheckboxShape,
	Input,
	CheckboxWrapper,
	CheckboxContainer,
	IconWrapper,
	Wrapper,
	LabelWrapper,
} from './Checkbox.styled';
import Icon from '@Components/Icon/Icon';
import ConditionalRender from '@Components/ConditionalRender/ConditionalRender';
import { BodySBold, BodyS } from '@Components/Typography/Typography.styled';

export interface ICheckboxBaseProps {
	active: boolean;
	onChange: (event: React.FormEvent<HTMLInputElement>) => void;
	disabled?: boolean;
	label?: string;
	count?: string;
	labelClickable?: boolean;
	reverseLabel?: boolean;
}

export interface ICheckboxAdditionalProps {
	shape: CheckboxShape;
	size: number;
}

export interface ICheckboxProps extends ICheckboxBaseProps, ICheckboxAdditionalProps {}

const Checkbox: FunctionComponent<ICheckboxProps> = ({
	active,
	onChange,
	disabled,
	shape,
	size,
	label,
	labelClickable,
	count,
	reverseLabel,
}) => {
	const Label = active ? BodySBold : BodyS;

	const labelColor: types.theme.themeColors = disabled ? 'black38' : 'black87';

	const getIconColor: types.color = useMemo(() => {
		if (disabled) {
			return 'black38';
		}
		return 'white';
	}, [disabled]);

	return (
		<Wrapper
			onClick={() => {
				if (labelClickable) {
					onChange({} as React.FormEvent<HTMLInputElement>);
				}
			}}
			reverseLabel={reverseLabel}
		>
			<CheckboxWrapper active={active} shape={shape} disabled={disabled} size={size}>
				<CheckboxContainer active={active} shape={shape} size={size} disabled={disabled}>
					<Input
						type="checkbox"
						checked={active}
						onChange={() => {
							if (!labelClickable) {
								onChange({} as React.FormEvent<HTMLInputElement>);
							}
						}}
						disabled={disabled}
					/>
					<ConditionalRender
						show={active}
						onTrue={() => (
							<IconWrapper>
								<Icon name="check" fill={getIconColor} height={16} width={16} />
							</IconWrapper>
						)}
					/>
				</CheckboxContainer>
			</CheckboxWrapper>
			<ConditionalRender
				show={!!label}
				onTrue={() => (
					<LabelWrapper labelClickable={labelClickable}>
						<Label color={labelColor}>{label}</Label>
						{count && <Label color={labelColor}>{` (${count})`}</Label>}
					</LabelWrapper>
				)}
			/>
		</Wrapper>
	);
};

export const CircularCheckbox: FunctionComponent<ICheckboxBaseProps> = (props) => (
	<Checkbox {...props} shape={CheckboxShape.CIRCULAR} size={22} />
);

export const SquareCheckbox: FunctionComponent<ICheckboxBaseProps> = (props) => (
	<Checkbox {...props} shape={CheckboxShape.SQUARE} size={20} />
);

export default Checkbox;
