import Accordion from '@Components/Accordion/Accordion';
import {
	AccordionContentWrapper,
	AccordionsContainer,
	CardContainer,
	ContentWrapper,
	Data,
	DeliveryMethodDescription,
	DeliveryMethodTitle,
	DeliveryMethodWrapper,
	DescriptionWrapper,
	DividerWrapper,
	HeaderDescriptionWrapper,
	HeaderTitle,
	HeaderWrapper,
	Label,
	LabeledInformationWrapper,
	MapElement,
	SectionHeader,
	AppointmentSectionHeader,
	DaysWrapper,
	AppointmentDetailsWrapper,
} from '@Components/Card/cards/ShippingDetails/ShippingDetails.styled';
import ConditionalRender from '@Components/ConditionalRender/ConditionalRender';
import Divider from '@Components/Divider/Divider';
import FilePreview from '@Components/FilePreview/FilePreview';
import Icon from '@Components/Icon/Icon';
import Map from '@Components/Map/Map';
import { ParagraphM } from '@Components/Typography/Typography.styled';
import { ICheckout } from '@Redux/modules/checkout/actions';
import { ItemType, ProductsService } from '@Services/productsService/productsService';
import { useCart } from '@Utils/context/CartContext';
import useAddressBook from '@Utils/hooks/useAddressBook';
import useCommerceProfile from '@Utils/hooks/useCommerceProfile';
import useTranslate from '@Utils/hooks/useTranslate';
import { BUY_HBB, BUY_WFBB } from '@Views/Map/Map';
import moment from 'moment';
import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { Col, Row } from 'react-grid-system';
import { useSelector } from 'react-redux';
import { DocumentState } from '../DocumentDetails/DocumentDetails';
import { POSTPAID_PLAN } from '@Config/app.config';
import { useOutlet } from '@Utils/context/OutletContext';
import { isSoonPeorderDate } from '@Utils/date/moment';

const ShippingDetails: FunctionComponent = () => {
	const { translate } = useTranslate();
	const { contactDetails } = useCommerceProfile();
	const [hasGiftCards, setHasGiftCards] = useState<boolean>(false);
	const [hasDevice, setHasDevice] = useState(false);
	const { cart, cartItemsLength } = useCart();
	const pickupDelivery = useSelector((state: types.redux.IState) => state.checkout.pickup);
	const hbbWfbbProcessCheckout = useSelector(
		(state: types.redux.IState) => state.persistentCheckout.hbbWfbbProcessCheckout
	);
	const prepaidProcessCheckout = useSelector(
		(state: types.redux.IState) => state.persistentCheckout.prepaidProcessCheckout
	);
	const [launchDate, setLaunchDate] = useState('');

	const preorderDate = cart?.items[0]?.plan?.preorderDate;
	const { selectedAddress, getTitle } = useAddressBook();

	const hbbWfbbItem = useMemo(() => {
		return cart.items.find((item) => [BUY_HBB, BUY_WFBB].includes(item.plan?.PA || ''));
	}, [cart]);

	const { selectedOutlet, bookingPickupAppointment } = useOutlet();
	const pickUpOutlet = !!selectedOutlet.branchId;

	const otherDeviceIncart = cart.items.length > 1;

	useEffect(() => {
		cart.items.map((cartItem) => {
			ProductsService.getProductById({ productId: cartItem.productId, itemType: ItemType.UNKNOWN }).subscribe(
				({ item }) => {
					if (item?.isGiftCard || item?.isDigitalProduct) {
						setHasGiftCards(true);
					} else if (item && !item?.isDigitalProduct) {
						setHasDevice(true);
					}
					if (pickupDelivery) {
						setLaunchDate(moment(item.launchDate).format('DD MMM YYYY'));
					}
				},
				() => {}
			);
		});
	}, [cart]);

	const LabeledInformation = ({ label, data }: { label: string; data?: string | number }) => (
		<LabeledInformationWrapper>
			<Label>{label}</Label>
			<Data>{String(data || '')}</Data>
		</LabeledInformationWrapper>
	);

	function getShippingAddressSubtitle(shipmentAddress: ICheckout['payload']['shipmentAddress']) {
		const args = [];

		if (pickupDelivery) {
			return translate('shipping-details.accordion.shipping-data.subtitle-pickup');
		}

		if (shipmentAddress?.city) {
			args.push(shipmentAddress.city);
		}
		if (shipmentAddress?.area) {
			args.push(shipmentAddress.area);
		}
		if (shipmentAddress?.way) {
			args.push(shipmentAddress.way);
		}
		if (shipmentAddress?.house) {
			args.push(shipmentAddress.house);
		}
		if (shipmentAddress?.apartment) {
			args.push('/' + shipmentAddress.apartment);
		}
		return translate(`shipping-details.accordion.shipping-data.subtitle-${args.length}`, ...args);
	}

	const personTitle = (title: string) => {
		if (title === '1') {
			return translate('contact.details.dropdown.mr');
		} else {
			return translate('contact.details.dropdown.ms');
		}
	};

	function getContactDetailsSubtitle(contactDetails: ICheckout['payload']['contactDetails']) {
		const args = [];

		if (contactDetails?.firstName) {
			args.push(contactDetails.firstName);
		}
		if (contactDetails?.middleName) {
			args.push(contactDetails.middleName);
		}
		if (contactDetails?.lastName) {
			args.push(contactDetails.lastName);
		}
		return translate(`shipping-details.accordion.contact-data.subtitle-${args.length}`, ...args);
	}

	function getHbbWfbbContactDetailsSubtitle() {
		const args = [];

		if (hbbWfbbProcessCheckout?.firstName) {
			args.push(hbbWfbbProcessCheckout.firstName);
		}
		if (hbbWfbbProcessCheckout?.middleName) {
			args.push(hbbWfbbProcessCheckout.middleName);
		}
		if (hbbWfbbProcessCheckout?.lastName) {
			args.push(hbbWfbbProcessCheckout.lastName);
		}
		return translate(`shipping-details.accordion.contact-data.subtitle-${args.length}`, ...args);
	}

	function getInternetInstallationAddressSubtitle() {
		const args = [];

		if (hbbWfbbProcessCheckout?.region) {
			args.push(hbbWfbbProcessCheckout.region);
		}
		if (hbbWfbbProcessCheckout?.wayNumber && hbbWfbbProcessCheckout?.wayNumber !== 'null') {
			args.push(hbbWfbbProcessCheckout.wayNumber);
		}
		if (hbbWfbbProcessCheckout?.buildingNumber && hbbWfbbProcessCheckout?.buildingNumber !== 'null') {
			args.push(hbbWfbbProcessCheckout.buildingNumber);
		}
		return translate(`shipping-details.accordion.contact-data.subtitle-${args.length}`, ...args);
	}

	function getProvidedDocumentSubtitle() {
		return translate(
			`shipping-details.accordion.provided.document.subtitle-${
				hbbWfbbProcessCheckout?.customerIdType === DocumentState.NATIONAL
					? DocumentState.NATIONAL
					: DocumentState.RESIDENCE
			}`,
			hbbWfbbProcessCheckout?.expiryDate || ''
		);
	}

	function getPrepaidProvidedDocumentSubtitle() {
		return translate(
			`sim-order-details.accordion.provided.document.subtitle-${
				hbbWfbbProcessCheckout?.customerIdType === DocumentState.NATIONAL
					? DocumentState.NATIONAL
					: DocumentState.RESIDENCE
			}`,
			hbbWfbbProcessCheckout?.expiryDate || ''
		);
	}

	const orderIncludesPlan = cart.items.some(
		(item) => item.withPlan && ![BUY_HBB, BUY_WFBB].includes(item.plan?.PA || '')
	);

	const orderIncludesPrepaid = cart.items.some(
		(item) => item.withPlan && [POSTPAID_PLAN].includes(item.plan?.PA || '')
	);

	return (
		<CardContainer>
			<HeaderWrapper>
				{orderIncludesPrepaid || pickUpOutlet ? (
					<Icon name="order" fill="black38" width={40} height={40} />
				) : (
					<Icon name="shipping" fill="black38" width={40} height={40} />
				)}
				<HeaderDescriptionWrapper>
					<HeaderTitle>
						{orderIncludesPrepaid || pickUpOutlet
							? translate('order-details.title')
							: translate('shipping-details.title')}
					</HeaderTitle>
				</HeaderDescriptionWrapper>
			</HeaderWrapper>
			<ContentWrapper>
				<AccordionsContainer>
					<ConditionalRender
						show={!!cartItemsLength}
						onTrue={() => (
							<Accordion
								subtitleColor={orderIncludesPrepaid ? 'black54' : 'black87'}
								isPrepaid={orderIncludesPrepaid}
								title={translate(
									hbbWfbbItem || (orderIncludesPrepaid && !otherDeviceIncart)
										? 'shipping-details.accordion.contact-data.personal.info'
										: orderIncludesPlan
										? 'shipping-details.accordion.contact-data.title'
										: 'shipping-details.accordion.contact-data.title.without.billing'
								)}
								subtitle={
									hbbWfbbItem
										? getHbbWfbbContactDetailsSubtitle()
										: getContactDetailsSubtitle(contactDetails)
								}
								icon="person"
								accordionHeight={80}
							>
								<AccordionContentWrapper>
									<SectionHeader>
										{translate('shipping-details.accordion.contact-data.section-header')}
									</SectionHeader>
									<Divider marginBottom={8} />
									<ConditionalRender
										show={!!hbbWfbbItem}
										onTrue={() => (
											<>
												<ConditionalRender
													show={!!hbbWfbbProcessCheckout?.title}
													onTrue={() => (
														<LabeledInformation
															label={translate(
																'shipping-details.accordion.contact-data.data.title'
															)}
															data={personTitle(String(hbbWfbbProcessCheckout?.title))}
														/>
													)}
												/>
												<ConditionalRender
													show={
														!!hbbWfbbProcessCheckout?.firstName &&
														!!hbbWfbbProcessCheckout?.lastName
													}
													onTrue={() => (
														<LabeledInformation
															label={translate(
																'shipping-details.accordion.contact-data.data.name'
															)}
															data={`${String(
																hbbWfbbProcessCheckout?.firstName
															)} ${String(hbbWfbbProcessCheckout?.lastName)}`}
														/>
													)}
												/>
												<ConditionalRender
													show={!!hbbWfbbProcessCheckout?.emailId}
													onTrue={() => (
														<LabeledInformation
															label={translate(
																'shipping-details.accordion.contact-data.data.email'
															)}
															data={String(hbbWfbbProcessCheckout?.emailId)}
														/>
													)}
												/>
												<ConditionalRender
													show={!!hbbWfbbProcessCheckout?.contactNumber1}
													onTrue={() => (
														<LabeledInformation
															label={translate(
																'shipping-details.accordion.contact-data.data.phoneNumber'
															)}
															data={hbbWfbbProcessCheckout?.contactNumber1}
														/>
													)}
												/>
											</>
										)}
										onFalse={() => {
											return (
												<ConditionalRender
													show={!!cartItemsLength}
													onTrue={() => (
														<>
															<ConditionalRender
																show={!!contactDetails?.title}
																onTrue={() => (
																	<LabeledInformation
																		label={translate(
																			'shipping-details.accordion.contact-data.data.title'
																		)}
																		data={personTitle(
																			String(contactDetails?.title)
																		)}
																	/>
																)}
															/>
															<ConditionalRender
																show={
																	!!contactDetails?.firstName &&
																	!!contactDetails?.lastName
																}
																onTrue={() => (
																	<LabeledInformation
																		label={translate(
																			'shipping-details.accordion.contact-data.data.name'
																		)}
																		data={`${String(
																			contactDetails?.firstName
																		)} ${String(contactDetails?.lastName)}`}
																	/>
																)}
															/>
															<ConditionalRender
																show={!!contactDetails?.email}
																onTrue={() => (
																	<LabeledInformation
																		label={translate(
																			'shipping-details.accordion.contact-data.data.email'
																		)}
																		data={String(contactDetails?.email)}
																	/>
																)}
															/>
															<ConditionalRender
																show={!!contactDetails?.phoneNumber}
																onTrue={() => (
																	<LabeledInformation
																		label={translate(
																			'shipping-details.accordion.contact-data.data.phoneNumber'
																		)}
																		data={contactDetails?.phoneNumber}
																	/>
																)}
															/>
														</>
													)}
												/>
											);
										}}
									/>
									<ConditionalRender
										show={orderIncludesPlan && !orderIncludesPrepaid}
										onTrue={() => (
											<>
												<Divider withoutLine marginTop={40} />
												<SectionHeader>
													{translate(
														'shipping-details.accordion.billing-data.section-header'
													)}
												</SectionHeader>
												<Divider marginBottom={8} />
												<Row>
													<Col xs={12} sm={6}>
														<ConditionalRender
															show={!!selectedAddress?.governorate}
															onTrue={() => (
																<LabeledInformation
																	label={translate(
																		'shipping-details.billing.contact-data.data.governorate'
																	)}
																	data={selectedAddress?.governorate}
																/>
															)}
														/>
														<ConditionalRender
															show={!!selectedAddress?.wilayat}
															onTrue={() => (
																<LabeledInformation
																	label={translate(
																		'shipping-details.billing.contact-data.data.wilayat'
																	)}
																	data={selectedAddress?.wilayat}
																/>
															)}
														/>
														<ConditionalRender
															show={!!selectedAddress?.city}
															onTrue={() => (
																<LabeledInformation
																	label={
																		!!selectedAddress?.area
																			? translate(
																					'shipping-details.billing.contact-data.data.city-area'
																			  )
																			: translate(
																					'shipping-details.billing.contact-data.data.city'
																			  )
																	}
																	data={
																		!!selectedAddress?.area
																			? `${selectedAddress?.city} / ${selectedAddress?.area}`
																			: selectedAddress?.city
																	}
																/>
															)}
														/>
														<ConditionalRender
															show={!!selectedAddress?.way}
															onTrue={() => (
																<LabeledInformation
																	label={translate(
																		'shipping-details.billing.contact-data.data.way'
																	)}
																	data={selectedAddress?.way}
																/>
															)}
														/>
														<ConditionalRender
															show={!!selectedAddress?.house}
															onTrue={() => (
																<LabeledInformation
																	label={translate(
																		'deliver-address.text-field.house-info'
																	)}
																	data={
																		selectedAddress?.house ??
																		'' +
																			(selectedAddress?.house &&
																			selectedAddress?.apartment
																				? '/' + selectedAddress.apartment
																				: '')
																	}
																/>
															)}
														/>
													</Col>

													<Col xs={12} sm={6}>
														<Map
															mapElement={<MapElement />}
															zoom={17}
															position={selectedAddress?.markerPosition}
															markerPosition={selectedAddress?.markerPosition}
															containerElement={<MapElement />}
															locked
														/>
													</Col>
													<Col sm={12} md={12}>
														<ConditionalRender
															show={!!selectedAddress?.landmark}
															onTrue={() => (
																<>
																	<Divider withoutLine marginTop={40} />
																	<LabeledInformation
																		label={translate(
																			'shipping-details.billing.contact-data.data.landmark'
																		)}
																		data={selectedAddress?.landmark}
																	/>
																</>
															)}
														/>
													</Col>
												</Row>
											</>
										)}
									/>
								</AccordionContentWrapper>
							</Accordion>
						)}
					/>
					<DividerWrapper>
						<Divider />
					</DividerWrapper>
					<ConditionalRender
						show={!!hbbWfbbItem}
						onTrue={() => (
							<>
								<Accordion
									title={translate('shipping-details.accordion.provided.document')}
									subtitle={getProvidedDocumentSubtitle()}
									icon="document"
								>
									<AccordionContentWrapper>
										<SectionHeader>
											{translate(
												`shipping-details.accordion.provided-document.section-header-${
													hbbWfbbProcessCheckout?.customerIdType === DocumentState.NATIONAL
														? DocumentState.NATIONAL
														: DocumentState.RESIDENCE
												}`
											)}
										</SectionHeader>
										<Divider marginBottom={8} />
										<ConditionalRender
											show={!!hbbWfbbProcessCheckout?.expiryDate}
											onTrue={() => (
												<LabeledInformation
													label={translate(
														'shipping-details.accordion.contact-data.data.expiry-date'
													)}
													data={hbbWfbbProcessCheckout?.expiryDate}
												/>
											)}
										/>
										<Divider marginBottom={24} withoutLine />
										<SectionHeader>
											{translate(
												`shipping-details.accordion.provided-document.section-header-documents`
											)}
										</SectionHeader>
										<Divider marginBottom={8} />
										{(Object.keys(hbbWfbbProcessCheckout?.documentScan || {}) as (
											| 'base64backSide'
											| 'base64frontSide'
										)[]).map((key: 'base64backSide' | 'base64frontSide', index) => (
											<FilePreview
												key={`file-preview-${index}`}
												format={hbbWfbbProcessCheckout?.documentScan?.[key]?.type || ''}
												fileName={hbbWfbbProcessCheckout?.documentScan?.[key]?.name || ''}
												base64File={
													hbbWfbbProcessCheckout?.documentScan?.[key]?.base64File || ''
												}
											/>
										))}
									</AccordionContentWrapper>
								</Accordion>
								<DividerWrapper>
									<Divider />
								</DividerWrapper>
							</>
						)}
					/>
					<ConditionalRender
						show={!!orderIncludesPrepaid}
						onTrue={() => (
							<>
								<Accordion
									subtitleColor={orderIncludesPrepaid ? 'black54' : 'black87'}
									isPrepaid={orderIncludesPrepaid}
									title={translate('shipping-details.accordion.provided.document')}
									subtitle={getPrepaidProvidedDocumentSubtitle()}
									icon="document"
								>
									<AccordionContentWrapper>
										<SectionHeader>
											{translate(
												`sim-order-details.accordion.provided-document.section-header-${
													prepaidProcessCheckout?.customerIdType === DocumentState.NATIONAL
														? DocumentState.NATIONAL
														: DocumentState.RESIDENCE
												}`
											)}
										</SectionHeader>
										<Divider marginBottom={8} />
										<LabeledInformation
											label={translate(
												'sim-order-details.accordion.contact-data.data.civil-number'
											)}
											data={prepaidProcessCheckout?.customerId}
										/>

										<Divider marginBottom={24} withoutLine />
									</AccordionContentWrapper>
								</Accordion>
								<DividerWrapper>
									<Divider />
								</DividerWrapper>
							</>
						)}
					/>
					<ConditionalRender
						show={orderIncludesPrepaid}
						onTrue={() => (
							<Accordion
								subtitleColor={orderIncludesPrepaid ? 'black54' : 'black87'}
								isPrepaid={orderIncludesPrepaid}
								title={translate('sim-order-details.accordion.shipping-data.title')}
								subtitle={selectedAddress ? getTitle(selectedAddress) : ''}
								icon="locationPin"
							>
								<AccordionContentWrapper>
									<Divider withoutLine marginTop={40} />
									<SectionHeader>
										{translate('shipping-details.accordion.billing-data.section-header')}
									</SectionHeader>
									<Divider marginBottom={8} />
									<Row>
										<Col xs={12} sm={6}>
											<ConditionalRender
												show={!!selectedAddress?.governorate}
												onTrue={() => (
													<LabeledInformation
														label={translate(
															'shipping-details.billing.contact-data.data.governorate'
														)}
														data={selectedAddress?.governorate}
													/>
												)}
											/>
											<ConditionalRender
												show={!!selectedAddress?.wilayat}
												onTrue={() => (
													<LabeledInformation
														label={translate(
															'shipping-details.billing.contact-data.data.wilayat'
														)}
														data={selectedAddress?.wilayat}
													/>
												)}
											/>
											<ConditionalRender
												show={!!selectedAddress?.city}
												onTrue={() => (
													<LabeledInformation
														label={
															!!selectedAddress?.area
																? translate(
																		'shipping-details.billing.contact-data.data.city-area'
																  )
																: translate(
																		'shipping-details.billing.contact-data.data.city'
																  )
														}
														data={
															!!selectedAddress?.area
																? `${selectedAddress?.city} / ${selectedAddress?.area}`
																: selectedAddress?.city
														}
													/>
												)}
											/>
											<ConditionalRender
												show={!!selectedAddress?.way}
												onTrue={() => (
													<LabeledInformation
														label={translate(
															'shipping-details.billing.contact-data.data.way'
														)}
														data={selectedAddress?.way}
													/>
												)}
											/>
											<ConditionalRender
												show={!!selectedAddress?.house}
												onTrue={() => (
													<LabeledInformation
														label={translate('deliver-address.text-field.house-info')}
														data={
															selectedAddress?.house ??
															'' +
																(selectedAddress?.house && selectedAddress?.apartment
																	? '/' + selectedAddress.apartment
																	: '')
														}
													/>
												)}
											/>
										</Col>

										<Col xs={12} sm={6}>
											<Map
												mapElement={<MapElement />}
												zoom={17}
												position={selectedAddress?.markerPosition}
												markerPosition={selectedAddress?.markerPosition}
												containerElement={<MapElement />}
												locked
											/>
										</Col>
										<Col sm={12} md={12}>
											<ConditionalRender
												show={!!selectedAddress?.landmark}
												onTrue={() => (
													<>
														<Divider withoutLine marginTop={40} />
														<LabeledInformation
															label={translate(
																'shipping-details.billing.contact-data.data.landmark'
															)}
															data={selectedAddress?.landmark}
														/>
													</>
												)}
											/>
										</Col>
									</Row>
								</AccordionContentWrapper>
							</Accordion>
						)}
					/>
					<ConditionalRender
						show={!!hbbWfbbItem}
						onTrue={() => (
							<Accordion
								title={translate('shipping-details.accordion.title.delivery.details')}
								subtitle={getInternetInstallationAddressSubtitle()}
								icon="locationPinOutlined"
								accordionHeight={80}
							>
								<AccordionContentWrapper>
									<SectionHeader>
										{translate('shipping-details.accordion.internet-installation.section-header')}
									</SectionHeader>
									<Divider marginBottom={8} />
									<Row>
										<Col xs={12} sm={6}>
											<ConditionalRender
												show={!!hbbWfbbProcessCheckout?.region}
												onTrue={() => (
													<LabeledInformation
														label={translate(
															'shipping-details.billing.shipping-data.data.governorate'
														)}
														data={hbbWfbbProcessCheckout?.region}
													/>
												)}
											/>
											<ConditionalRender
												show={!!hbbWfbbProcessCheckout?.willyat}
												onTrue={() => (
													<LabeledInformation
														label={translate(
															'shipping-details.billing.shipping-data.data.wilayat'
														)}
														data={hbbWfbbProcessCheckout?.willyat}
													/>
												)}
											/>
											<ConditionalRender
												show={!!hbbWfbbProcessCheckout?.swatSOSStatus}
												onTrue={() => (
													<LabeledInformation
														label={translate(
															'shipping-details.billing.shipping-data.data.city'
														)}
														data={hbbWfbbProcessCheckout?.swatSOSStatus}
													/>
												)}
											/>
											<ConditionalRender
												show={
													!!hbbWfbbProcessCheckout?.wayNumber &&
													hbbWfbbProcessCheckout?.wayNumber !== 'null'
												}
												onTrue={() => (
													<LabeledInformation
														label={translate(
															'shipping-details.billing.shipping-data.data.way'
														)}
														data={hbbWfbbProcessCheckout?.wayNumber}
													/>
												)}
											/>
											<ConditionalRender
												show={
													!!hbbWfbbProcessCheckout?.buildingNumber &&
													hbbWfbbProcessCheckout?.buildingNumber !== 'null'
												}
												onTrue={() => (
													<LabeledInformation
														label={translate(
															'shipping-details.billing.shipping-data.data.house'
														)}
														data={hbbWfbbProcessCheckout?.buildingNumber}
													/>
												)}
											/>
										</Col>

										<Col xs={12} sm={6}>
											{hbbWfbbProcessCheckout?.latitude && hbbWfbbProcessCheckout?.longitude && (
												<Map
													mapElement={<MapElement />}
													zoom={17}
													position={{
														lat: Number(hbbWfbbProcessCheckout.latitude),
														lng: Number(hbbWfbbProcessCheckout.longitude),
													}}
													markerPosition={{
														lat: Number(hbbWfbbProcessCheckout.latitude),
														lng: Number(hbbWfbbProcessCheckout.longitude),
													}}
													containerElement={<MapElement />}
													locked
												/>
											)}
										</Col>

										<Col sm={12} md={12}>
											<ConditionalRender
												show={!!hbbWfbbProcessCheckout?.landmark}
												onTrue={() => (
													<>
														<Divider withoutLine marginTop={40} />
														<LabeledInformation
															label={translate(
																'shipping-details.billing.contact-data.data.landmark'
															)}
															data={hbbWfbbProcessCheckout?.landmark}
														/>
													</>
												)}
											/>
										</Col>
									</Row>
								</AccordionContentWrapper>
							</Accordion>
						)}
					/>

					<ConditionalRender
						show={hasDevice && !pickUpOutlet}
						onTrue={() => (
							<Accordion
								title={translate('shipping-details.accordion.shipping-data.title')}
								subtitle={getShippingAddressSubtitle(selectedAddress)}
								subtitleColor="black54"
								icon="locationPinOutlined"
								accordionHeight={80}
							>
								<AccordionContentWrapper>
									<SectionHeader>
										{translate('shipping-details.accordion.delivery-method.section-header')}
									</SectionHeader>
									<Divider marginBottom={8} />

									<DeliveryMethodWrapper paddingBottom={pickupDelivery ? 16 : undefined}>
										<Icon name="location" fill="black38" width={32} height={32} />
										<DescriptionWrapper>
											<DeliveryMethodTitle>
												{translate(
													pickupDelivery
														? 'shipping-details.accordion.delivery-method.title.pickup'
														: 'shipping-details.accordion.delivery-method.title'
												)}
											</DeliveryMethodTitle>
											<ConditionalRender
												show={!pickupDelivery}
												onTrue={() => (
													<DeliveryMethodDescription>
														{translate(
															'shipping-details.accordion.delivery-method.description'
														)}
													</DeliveryMethodDescription>
												)}
											/>

											<ConditionalRender
												show={!!preorderDate && !pickupDelivery}
												onTrue={() => (
													<DeliveryMethodDescription>
														{isSoonPeorderDate(preorderDate ?? '')
															? translate('availability.preorder.subtitle.second-version')
															: translate(
																	'shipping-details.accordion.delivery-method.preorder',
																	preorderDate || '-'
															  )}
													</DeliveryMethodDescription>
												)}
											/>
											<ConditionalRender
												show={!!launchDate && !!pickupDelivery}
												onTrue={() => (
													<DeliveryMethodDescription>
														{translate(
															'shipping-details.accordion.delivery-method.pickup',
															launchDate || '-'
														)}
													</DeliveryMethodDescription>
												)}
											/>
										</DescriptionWrapper>
									</DeliveryMethodWrapper>

									<ConditionalRender
										show={!pickupDelivery}
										onTrue={() => (
											<>
												<SectionHeader>
													{translate(
														'shipping-details.accordion.delivery-address.section-header'
													)}
												</SectionHeader>
												<Divider marginBottom={8} />
												<Row>
													<Col xs={12} sm={6}>
														<ConditionalRender
															show={!!selectedAddress?.governorate}
															onTrue={() => (
																<LabeledInformation
																	label={translate(
																		'shipping-details.billing.shipping-data.data.governorate'
																	)}
																	data={selectedAddress?.governorate}
																/>
															)}
														/>
														<ConditionalRender
															show={!!selectedAddress?.wilayat}
															onTrue={() => (
																<LabeledInformation
																	label={translate(
																		'shipping-details.billing.shipping-data.data.wilayat'
																	)}
																	data={selectedAddress?.wilayat}
																/>
															)}
														/>
														<ConditionalRender
															show={!!selectedAddress?.city}
															onTrue={() => (
																<LabeledInformation
																	label={
																		!!selectedAddress?.area
																			? translate(
																					'shipping-details.billing.contact-data.data.city-area'
																			  )
																			: translate(
																					'shipping-details.billing.contact-data.data.city'
																			  )
																	}
																	data={
																		!!selectedAddress?.area
																			? `${selectedAddress?.city} / ${selectedAddress?.area}`
																			: selectedAddress?.city
																	}
																/>
															)}
														/>
														<ConditionalRender
															show={!!selectedAddress?.way}
															onTrue={() => (
																<LabeledInformation
																	label={translate(
																		'shipping-details.billing.shipping-data.data.way'
																	)}
																	data={selectedAddress?.way}
																/>
															)}
														/>
														<ConditionalRender
															show={!!selectedAddress?.house}
															onTrue={() => (
																<LabeledInformation
																	label={translate(
																		'deliver-address.text-field.house-info'
																	)}
																	data={
																		(selectedAddress?.house ?? '') +
																		(selectedAddress?.house &&
																		selectedAddress?.apartment
																			? '/' + selectedAddress.apartment
																			: '')
																	}
																/>
															)}
														/>
													</Col>

													<Col xs={12} sm={6}>
														<Map
															mapElement={<MapElement />}
															zoom={17}
															position={selectedAddress?.markerPosition}
															markerPosition={selectedAddress?.markerPosition}
															containerElement={<MapElement />}
															locked
														/>
													</Col>

													<Col sm={12} md={12}>
														<ConditionalRender
															show={!!selectedAddress?.landmark}
															onTrue={() => (
																<>
																	<Divider withoutLine marginTop={40} />
																	<LabeledInformation
																		label={translate(
																			'shipping-details.billing.contact-data.data.landmark'
																		)}
																		data={selectedAddress?.landmark}
																	/>
																</>
															)}
														/>
													</Col>
												</Row>
											</>
										)}
									/>
								</AccordionContentWrapper>
							</Accordion>
						)}
					/>

					<ConditionalRender
						show={pickUpOutlet}
						onTrue={() => (
							<Accordion
								title={translate('sim-order-details.accordion.shipping-data.title')}
								subtitle={`${translate(
									'shipping-details.subtitle.delivery-method.title.outlet-pickup'
								)}${'\xa0'}${selectedOutlet.branchName}`}
								subtitleColor="black54"
								pickUpAppointment={
									!!bookingPickupAppointment
										? `${translate(
												'shipping-details.accordion.appointment.outlet-appointment-description'
										  )}\xa0${moment(bookingPickupAppointment).format('D MMM YYYY, h:mm A')}`
										: ''
								}
								icon="locationPinOutlined"
								accordionHeight={104}
							>
								<AccordionContentWrapper>
									<SectionHeader>
										{translate('shipping-details.accordion.delivery-method.section-header')}
									</SectionHeader>
									<Divider marginBottom={8} />

									<DeliveryMethodWrapper paddingBottom={pickupDelivery ? 16 : undefined}>
										<Icon name="location" fill="black38" width={32} height={32} />
										<DescriptionWrapper>
											<DeliveryMethodTitle>
												{translate(
													'shipping-details.accordion.delivery-method.title.outlet-pickup'
												)}
											</DeliveryMethodTitle>
											<DeliveryMethodDescription>
												{translate('shipping-details.accordion.outlet.description')}
											</DeliveryMethodDescription>
											<ConditionalRender
												show={!!preorderDate && !pickupDelivery}
												onTrue={() => (
													<DeliveryMethodDescription>
														{isSoonPeorderDate(preorderDate ?? '')
															? translate('availability.preorder.subtitle.second-version')
															: translate(
																	'shipping-details.accordion.delivery-method.preorder',
																	preorderDate || '-'
															  )}
													</DeliveryMethodDescription>
												)}
											/>
										</DescriptionWrapper>
									</DeliveryMethodWrapper>

									<SectionHeader>
										{translate('shipping-details.accordion.outlet-pickup.section-header')}
									</SectionHeader>
									<Divider marginBottom={8} />
									<Row>
										<Col xs={12} sm={6}>
											<LabeledInformation
												label={translate(
													'shipping-details.billing.shipping-data.data.outlet-located'
												)}
												data={!!selectedOutlet.branchName ? selectedOutlet.branchName : ''}
											/>

											<LabeledInformation
												label={translate('shipping-details.billing.shipping-data.data.wilayat')}
												data={!!selectedOutlet.wilayat ? selectedOutlet.wilayat : ''}
											/>

											<LabeledInformation
												label={translate('shipping-details.billing.shipping-data.data.address')}
												data={!!selectedOutlet.address ? selectedOutlet.address : ''}
											/>

											<LabeledInformation
												label={translate(
													'shipping-details.billing.shipping-data.data.phone-number'
												)}
												data={!!selectedOutlet.phone ? selectedOutlet.phone : ''}
											/>

											<LabeledInformationWrapper>
												<Label>
													{translate(
														'shipping-details.billing.shipping-data.data.opening-hours'
													)}
												</Label>
												<DaysWrapper>
													<Data>
														{translate('outlet-available.modal.sunday-label')}
														{'\xa0'}
													</Data>
													<ParagraphM
														color={
															!!selectedOutlet?.openHours?.sunday ? 'black87' : 'warning'
														}
													>
														{!!selectedOutlet?.openHours?.sunday
															? selectedOutlet.openHours.sunday
															: translate('outlet-available.modal.closed-label')}
													</ParagraphM>
												</DaysWrapper>
												<DaysWrapper>
													<Data>
														{translate('outlet-available.modal.monday-label')}
														{'\xa0'}
													</Data>
													<ParagraphM
														color={
															!!selectedOutlet?.openHours?.monday ? 'black87' : 'warning'
														}
													>
														{!!selectedOutlet?.openHours?.monday
															? selectedOutlet?.openHours?.monday
															: translate('outlet-available.modal.closed-label')}
													</ParagraphM>
												</DaysWrapper>
												<DaysWrapper>
													<Data>
														{translate('outlet-available.modal.tuesday-label')}
														{'\xa0'}
													</Data>
													<ParagraphM
														color={
															!!selectedOutlet?.openHours?.tuesday ? 'black87' : 'warning'
														}
													>
														{!!selectedOutlet?.openHours?.tuesday
															? selectedOutlet?.openHours?.tuesday
															: translate('outlet-available.modal.closed-label')}
													</ParagraphM>
												</DaysWrapper>
												<DaysWrapper>
													<Data>
														{translate('outlet-available.modal.wednesday-label')}
														{'\xa0'}
													</Data>
													<ParagraphM
														color={
															!!selectedOutlet?.openHours?.wednesday
																? 'black87'
																: 'warning'
														}
													>
														{!!selectedOutlet?.openHours?.wednesday
															? selectedOutlet?.openHours?.wednesday
															: translate('outlet-available.modal.closed-label')}
													</ParagraphM>
												</DaysWrapper>
												<DaysWrapper>
													<Data>
														{translate('outlet-available.modal.thursday-label')}
														{'\xa0'}
													</Data>
													<ParagraphM
														color={
															!!selectedOutlet?.openHours?.thursday
																? 'black87'
																: 'warning'
														}
													>
														{!!selectedOutlet?.openHours?.thursday
															? selectedOutlet?.openHours?.thursday
															: translate('outlet-available.modal.closed-label')}
													</ParagraphM>
												</DaysWrapper>
												<DaysWrapper>
													<Data>
														{translate('outlet-available.modal.friday-label')}
														{'\xa0'}
													</Data>
													<ParagraphM
														color={
															!!selectedOutlet?.openHours?.friday ? 'black87' : 'warning'
														}
													>
														{!!selectedOutlet?.openHours?.friday
															? selectedOutlet?.openHours?.friday
															: translate('outlet-available.modal.closed-label')}
													</ParagraphM>
												</DaysWrapper>
												<DaysWrapper>
													<Data>
														{translate('outlet-available.modal.saturday-label')}
														{'\xa0'}
													</Data>
													<ParagraphM
														color={
															!!selectedOutlet?.openHours?.saturday
																? 'black87'
																: 'warning'
														}
													>
														{!!selectedOutlet?.openHours?.saturday
															? selectedOutlet?.openHours?.saturday
															: translate('outlet-available.modal.closed-label')}
													</ParagraphM>
												</DaysWrapper>
											</LabeledInformationWrapper>
										</Col>

										<Col xs={12} sm={6}>
											<Map
												mapElement={<MapElement />}
												zoom={17}
												position={selectedAddress?.markerPosition}
												markerPosition={selectedAddress?.markerPosition}
												containerElement={<MapElement />}
												locked
											/>
										</Col>

										<Col sm={12} md={12}>
											<ConditionalRender
												show={!!selectedAddress?.landmark}
												onTrue={() => (
													<>
														<Divider withoutLine marginTop={40} />
														<LabeledInformation
															label={translate(
																'shipping-details.billing.contact-data.data.landmark'
															)}
															data={selectedAddress?.landmark}
														/>
													</>
												)}
											/>
										</Col>
									</Row>
									<ConditionalRender
										show={!!bookingPickupAppointment}
										onTrue={() => (
											<>
												<AppointmentSectionHeader>
													{translate('shipping-details.accordion.appointment.section-header')}
												</AppointmentSectionHeader>
												<Divider marginBottom={8} />
												<AppointmentDetailsWrapper>
													<Icon name="deliveryDate" fill="black38" width={32} height={32} />
													<DescriptionWrapper>
														<DeliveryMethodTitle>
															{translate(
																'shipping-details.accordion.delivery-method.title.outlet-pickup-appointment'
															)}
														</DeliveryMethodTitle>
														<DeliveryMethodDescription>
															{translate(
																'shipping-details.accordion.appointment.outlet-in'
															)}
															{'\xa0'}
															{selectedOutlet.branchName}
															{'\xa0'}
															{translate(
																'shipping-details.accordion.appointment.outlet-on'
															)}
															{'\xa0'}
															{bookingPickupAppointment}
														</DeliveryMethodDescription>
													</DescriptionWrapper>
												</AppointmentDetailsWrapper>
											</>
										)}
									/>
								</AccordionContentWrapper>
							</Accordion>
						)}
					/>

					<ConditionalRender
						show={hasGiftCards && !!contactDetails?.email}
						onTrue={() => (
							<>
								<DividerWrapper>
									<Divider />
								</DividerWrapper>
								<Accordion
									title={translate('shipping-details.accordion.digital-shipping-data.title')}
									subtitle={contactDetails?.email ?? ''}
									icon="messages"
									displayExpand={false}
								/>
							</>
						)}
					/>
				</AccordionsContainer>
			</ContentWrapper>
		</CardContainer>
	);
};

export default ShippingDetails;
