// generate sample js object with id and text, 30 records

import React, { FunctionComponent, useEffect, useMemo } from 'react';
import {
	FormWrapper,
	AccountCreationFormWrapper,
	Title,
	HintWrapper,
	HintIcon,
	HintText,
	SubTitle,
	SubTitleBold,
	InfoWrapper,
} from './AccountCreationForm.styled';
import { Formik, FormikProps } from 'formik';
import TextField from '@Components/controls/TextField/TextField';
import useTranslate from '@Utils/hooks/useTranslate';
import microsoftAccountDataValidator from '@Utils/validators/microsoftAccountDataValidator';
import {
	AaccountCreationFormStep,
	IAccountCreationFormFields,
	IFormStep,
} from '@Views/MicrosoftRegistration/MicrosoftRegistration';
import { IDropdownItem, RegularDropdown } from '@Components/controls/Dropdown/Dropdown';
import Icon from '@Components/Icon/Icon';
import ConditionalRender from '@Components/ConditionalRender/ConditionalRender';
import useScreen from '@Utils/hooks/useScreen';
interface IAccountCreationFormProps {
	params: {
		accountCreationStep: AaccountCreationFormStep;
		formikRef: React.MutableRefObject<FormikProps<IAccountCreationFormFields>>;
		formSteps: IFormStep[];
		setIsButtonDisabled: (isButtonDisabled: boolean) => void;
	};
}
const ICON_SIZE = 13;

const EXAMPLE_TENANT_NAME = 'tenantname.omnimicrosoft.com';

const AccountCreationForm: FunctionComponent<IAccountCreationFormProps> = ({
	params: { accountCreationStep, formikRef, formSteps, setIsButtonDisabled },
}) => {
	const { translate } = useTranslate();
	const countries = [
		{ id: '1', text: 'Afghanistan' },
		{ id: '2', text: 'Albania' },
		{ id: '3', text: 'Algeria' },
		{ id: '4', text: 'Armenia' },
		{ id: '5', text: 'Azerbaidjan' },
		{ id: '6', text: 'Bahrain' },
		{ id: '7', text: 'Belarus' },
		{ id: '8', text: 'Bosnia & Herzegovina' },
		{ id: '9', text: 'British Indian Ocean Territory' },
		{ id: '10', text: 'Egypt' },
		{ id: '11', text: 'Georgia' },
		{ id: '12', text: 'Iraq' },
		{ id: '13', text: 'Jordan' },
		{ id: '14', text: 'Kazakhstan' },
		{ id: '15', text: 'KSA' },
		{ id: '16', text: 'Kuwait' },
		{ id: '17', text: 'Kyrghyzstan' },
		{ id: '18', text: 'Lebanon' },
		{ id: '19', text: 'Lesotho' },
		{ id: '20', text: 'Libya' },
		{ id: '21', text: 'Macedonia' },
		{ id: '22', text: 'Mauritania' },
		{ id: '23', text: 'Moldovia' },
		{ id: '24', text: 'Mongolia' },
		{ id: '25', text: 'Montenegro' },
		{ id: '26', text: 'Morocco' },
		{ id: '27', text: 'Mozambique' },
		{ id: '28', text: 'Oman' },
		{ id: '29', text: 'Pakistan' },
		{ id: '30', text: 'Palestine' },
		{ id: '31', text: 'Qatar' },
		{ id: '32', text: 'Serbia' },
		{ id: '33', text: 'South Africa' },
		{ id: '34', text: 'Swaziland' },
		{ id: '35', text: 'Tajikistan' },
		{ id: '36', text: 'Tunisia' },
		{ id: '37', text: 'Turkey' },
		{ id: '38', text: 'Turkmenistan' },
		{ id: '39', text: 'UAE' },
		{ id: '40', text: 'Ukraine' },
		{ id: '41', text: 'Uzbekistan' },
		{ id: '42', text: 'Yemen' },
		{ id: '43', text: 'Zimbabwe' },
	];

	const { screen } = useScreen();

	const getTitle = () => {
		switch (accountCreationStep) {
			case AaccountCreationFormStep.CONTACT_DETAILS:
				return translate('microsoft-automation-proccess.stepper.step-1');
			case AaccountCreationFormStep.COMPANY_DETAILS:
				return translate('microsoft-automation-proccess.stepper.step-2');
			case AaccountCreationFormStep.TENANT_INFO:
				return translate('microsoft-automation-proccess.stepper.step-3');
			default:
				break;
		}
	};
	const getSelectedItem = (text?: string) => {
		const selectedItem = countries.find((item) => item.text === text);
		return selectedItem ?? null;
	};

	const getTouchedFields = () => {
		let touchedFieldsSum = 0;
		formSteps.forEach((stepFromFields, index) => {
			if ((accountCreationStep > 0 ? accountCreationStep - 1 : 0) >= index) {
				touchedFieldsSum += stepFromFields.fields.length;
			}
		});
		return touchedFieldsSum;
	};

	const fields = useMemo(() => {
		let fields = 0;
		formSteps.slice(0, accountCreationStep).forEach((item) => (fields += item.fields.length));
		return fields;
	}, [accountCreationStep]);

	return (
		<AccountCreationFormWrapper
			sizes={{ isL: screen('lg'), isMd: screen('md'), isSm: screen('sm'), isXs: screen('xs') }}
		>
			<Title>{getTitle()}</Title>
			<Formik<IAccountCreationFormFields>
				validationSchema={microsoftAccountDataValidator(accountCreationStep)}
				validateOnBlur={true}
				initialValues={{
					companyAddress: '',
					companyCity: '',
					companyCountry: '',
					companyId: '',
					companyName: '',
					companyPostalCode: '',
					companyState: '',
					domainName: '',
					email: '',
					firstName: '',
					lastName: '',
					phoneNumber: '',
				}}
				onSubmit={() => {}}
				innerRef={formikRef}
			>
				{({ setFieldValue, values, errors, touched, setFieldTouched }) => {
					useEffect(() => {
						const additionalConditions =
							(accountCreationStep === 2 &&
								(Object.keys(touched).length === 7 || Object.keys(touched).length === 8) &&
								(getTouchedFields() === 11 || getTouchedFields() === 12)) ||
							(accountCreationStep === 3 &&
								(Object.keys(touched).length === 8 || Object.keys(touched).length === 7));
						const isButtonDisabled =
							Object.keys(errors).filter((item) =>
								formSteps[accountCreationStep - 1].fields.some((field) => field.id === item)
							).length > 0 ||
							(additionalConditions
								? false
								: getTouchedFields() !==
								  (Object.keys(touched).length > fields
										? fields
										: Object.keys(touched).length +
										  (!!values?.companyCountry ? 1 : 0) -
										  (accountCreationStep !== 3 && (!!values?.domainName || !!touched?.domainName)
												? 1
												: 0)));
						setIsButtonDisabled(isButtonDisabled);
					}, [errors, values, touched, accountCreationStep]);

					return (
						<FormWrapper isSmall={screen(['xs', 'sm'])}>
							{formSteps[accountCreationStep > 0 ? accountCreationStep - 1 : 0].fields.map((field) => {
								const textField = () => (
									<TextField
										key={field.id}
										width={
											field.id === 'companyPostalCode' || field.id === 'companyCity'
												? '48%'
												: '100%'
										}
										id={field.id}
										marginTop={field.id === 'domainName' ? 20 : 0}
										label={translate('microsoft-automation-proccess.form.field.' + field.id)}
										value={values[field.id]}
										onChange={(value) => {
											setFieldTouched(field.id, true);
											setFieldValue(field.id, value.toString());
										}}
										message={!!errors[field.id] && touched[field.id] ? errors[field.id] : ''}
										error={!!errors[field.id] && touched[field.id]}
										marginBottom={field.id === 'domainName' ? 0 : 16}
										isDomain={field.id === 'domainName'}
										staticLabel={field.id === 'domainName' ? '.onmicrosoft.com' : undefined}
									/>
								);
								const dropdown = () => (
									<RegularDropdown
										items={countries}
										setSelectedItem={(selectedItem: IDropdownItem) => {
											setFieldValue(field.id, selectedItem.text);
										}}
										selectedItem={getSelectedItem(values[field.id])}
										label={translate('microsoft-automation-proccess.form.field.' + field.id)}
										dropdownWidth="100%"
										message={!!errors[field.id] && touched[field.id] ? errors[field.id] : ''}
										error={!!errors[field.id] && touched[field.id]}
									/>
								);
								return (
									<ConditionalRender
										key={field.id}
										show={field.id !== 'companyCountry'}
										onTrue={() => (
											<ConditionalRender
												show={field.id === 'domainName'}
												onTrue={() => (
													<InfoWrapper>
														<SubTitle>
															{translate(
																'microsoft-automation-proccess.account-creation-form.info.part-1'
															)}
															<SubTitleBold>{EXAMPLE_TENANT_NAME}</SubTitleBold>
														</SubTitle>
														<SubTitle>
															{translate(
																'microsoft-automation-proccess.account-creation-form.info.part-2'
															)}
															<SubTitleBold>
																{translate(
																	'microsoft-automation-proccess.account-creation-form.info.part-3'
																)}
															</SubTitleBold>
														</SubTitle>
														{textField()}
													</InfoWrapper>
												)}
												onFalse={() => textField()}
											/>
										)}
										onFalse={() => dropdown()}
									/>
								);
							})}
							<ConditionalRender
								show={accountCreationStep === 1}
								onTrue={() => (
									<HintWrapper>
										<HintIcon>
											<Icon
												height={ICON_SIZE}
												width={ICON_SIZE}
												fill="primary"
												name="msgInfoBackground"
											/>
										</HintIcon>
										<HintText>
											The data you provide in this form will be also used for the Microsoft
											Customer Agreement
										</HintText>
									</HintWrapper>
								)}
							/>
						</FormWrapper>
					);
				}}
			</Formik>
		</AccountCreationFormWrapper>
	);
};

export default AccountCreationForm;
