import { ButtonM } from '@Components/Typography/Typography.styled';
import styled from 'styled-components';

interface ISmallScreen {
	isXs?: boolean;
	isSm?: boolean;
}

export const MicrosoftCardDetailsWrapper = styled.div`
	display: flex;
	flex-direction: column;
	border-radius: 8px;
	padding: 24px;
	${({ theme }) => ({
		backgroundColor: theme.colors.white,
	})};
`;

export const CustomModalContainer = styled.div<ISmallScreen>`
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	z-index: 5;
	${({ isXs, isSm }) => {
		return {
			width: !isXs && !isSm ? 730 : isXs ? '95%' : '80%',
			maxHeight: isXs || isSm ? '650px' : '800px',
		};
	}}
`;

export const TitleWrapper = styled.div`
	display: flex;
	flex-direction: row;
	margin-bottom: 16px;
	justify-content: space-between;
`;

export const KeysWrapper = styled.div<ISmallScreen>`
	display: flex;
	flex-direction: column;

	overflow-y: auto;
	${({ isXs, isSm }) => {
		return {
			maxHeight: isXs || isSm ? '250px' : '500px',
		};
	}}
`;

export const MicrosoftCodeContainer = styled.div<ISmallScreen>`
	${({ theme, isXs, isSm }) => ({
		backgroundColor: theme.colors.primary6,
		border: `solid 1px ${theme.colors.primary}`,
		padding: isXs || isSm ? '16px 4px 16px 8px' : '8px 8px 8px 16px',
		flexDirection: isXs || isSm ? 'column' : 'row',
	})};
	display: flex;
	justify-content: space-between;
	align-text: center;
	align-items: center;
	gap: 8px;
	border-radius: 4px;
	margin: 24px 0px;
`;

export const CopyClipboardButton = styled(ButtonM)`
	cursor: pointer;
	${({ theme }) => ({
		color: theme.colors.primary,
	})};
`;

export const ButtonWrapper = styled.div`
	width: 240px;
	margin-top: 16px;
`;

export const MicrosoftInfoWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	text-align: center;
	align-items: center;
	width: 100%;
`;

export const ErrorLabelWrapper = styled.div`
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	margin 8px 0px;
	justify-content: center;
`;

export const EmailLabelWrapper = styled.div<ISmallScreen>`
	margin-top: 8px;
	display: flex;
	${({ isXs, isSm }) => {
		return {
			flexDirection: isXs || isSm ? 'column' : 'row',
		};
	}}
	text-align: center;
	align-items: center;
	justify-content: center;
	width: 100%;
`;

export const CloseIconWrapper = styled.div`
	cursor: pointer;
`;
