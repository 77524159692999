import React, { FunctionComponent } from 'react';
import { SkeletonCardContainer, Rectangular, SkeletonColumn, DisplayFlex } from '@Components/Skeleton/Skeleton.styled';

const MARGIN = 8;

const PaymentSummaryWithPlan: FunctionComponent = () => {
	return (
		<SkeletonCardContainer marginTop={16}>
			<Rectangular width="100%" height={32} marginBottom={16} marginTop={MARGIN} />
			<DisplayFlex marginTop={18} marginBottom={MARGIN} justifyContent="space-between">
				<SkeletonColumn>
					<Rectangular width="65%" height={24} marginBottom={6} />
					<Rectangular width="40%" height={12} marginBottom={6} />
				</SkeletonColumn>
				<DisplayFlex alignItems="flex-start" justifyContent="flex-end">
					<Rectangular width={100} height={38} marginBottom={6} />
				</DisplayFlex>
			</DisplayFlex>
			<DisplayFlex marginTop={8} marginBottom={MARGIN} justifyContent="space-between">
				<SkeletonColumn>
					<Rectangular width="65%" height={24} marginBottom={6} />
					<Rectangular width="40%" height={12} marginBottom={6} />
				</SkeletonColumn>
				<DisplayFlex alignItems="flex-start" justifyContent="flex-end">
					<Rectangular width={100} height={38} marginBottom={6} />
				</DisplayFlex>
			</DisplayFlex>
		</SkeletonCardContainer>
	);
};

export default PaymentSummaryWithPlan;
