import React from 'react';
import ConditionalRender from '@Components/ConditionalRender/ConditionalRender';
import Icon from '@Components/Icon/Icon';
import { FunctionComponent } from 'react';
import { MessageBarContainer, MessageWrapper, MessageWrapperBold, DoubleText } from './MessageBar.styled';

const ICON_SIZE = 21;

interface IMessageBarProps {
	backgroundColor?: types.theme.themeColors;
	icon?: types.iconNames;
	contentFill?: types.theme.themeColors;
	message: string;
	iconSize?: number;
	importantMessage?: string;
	width?: string;
	font?: keyof types.ITypography;
	importantMessageFirst?: boolean;
	textAlign?: 'left' | 'center' | 'right';
}

const MessageBar: FunctionComponent<IMessageBarProps> = ({
	backgroundColor = 'warning',
	icon,
	message,
	contentFill = 'white',
	iconSize = 21,
	importantMessage,
	width = 'unset',
	font = 'bodyM',
	importantMessageFirst = false,
	textAlign = 'center',
}) => {
	return (
		<MessageBarContainer backgroundColor={backgroundColor} width={width}>
			<ConditionalRender
				show={!!icon}
				onTrue={() => <Icon name={icon} width={iconSize} height={iconSize} fill={contentFill} />}
			/>
			<ConditionalRender
				show={!!importantMessage}
				onTrue={() => (
					<DoubleText textAlign={textAlign}>
						<ConditionalRender
							show={importantMessageFirst}
							onTrue={() => (
								<MessageWrapperBold color={contentFill}>{importantMessage}</MessageWrapperBold>
							)}
						/>
						<MessageWrapper font={font} color={contentFill}>
							{message}
						</MessageWrapper>
						<ConditionalRender
							show={!importantMessageFirst}
							onTrue={() => (
								<MessageWrapperBold color={contentFill}>{importantMessage}</MessageWrapperBold>
							)}
						/>
					</DoubleText>
				)}
				onFalse={() => (
					<MessageWrapper font={font} color={contentFill}>
						{message}
					</MessageWrapper>
				)}
			/>
		</MessageBarContainer>
	);
};

export default MessageBar;
