import ConditionalRender from '@Components/ConditionalRender/ConditionalRender';
import { CounterDescription, Digit, DigitSM, DigitXS, InfoBold } from '@Components/Typography/Typography.styled';
import useScreen from '@Utils/hooks/useScreen';
import React, { FunctionComponent, useMemo } from 'react';
import { CounterSectionContainer, DigitContainer, Row } from './CounterSection.styled';

interface ICounterSection {
	digits: string;
	title: string;
}

const CounterSection: FunctionComponent<ICounterSection> = ({ digits, title }) => {
	const { screen } = useScreen();
	const isXS = screen('xs');
	const isMD = screen(['md', 'sm']);

	const DigitWrapper = useMemo(() => {
		if (isXS) {
			return DigitXS;
		}
		if (isMD) {
			return DigitSM;
		}
		return Digit;
	}, [isXS, isMD]);

	const TitleWrapper = useMemo(() => {
		if (isMD) {
			return InfoBold;
		}
		return CounterDescription;
	}, [isMD]);

	return (
		<CounterSectionContainer>
			<Row isXS={isXS}>
				<DigitContainer isXS={isXS} isMD={isMD}>
					<DigitWrapper color="white">{digits[1] ? digits[0] : '0'}</DigitWrapper>
				</DigitContainer>
				<DigitContainer isXS={isXS} isMD={isMD}>
					<DigitWrapper color="white">{digits[1] ? digits[1] : digits[0]}</DigitWrapper>
				</DigitContainer>
			</Row>
			<ConditionalRender
				show={!isXS}
				onTrue={() => (
					<Row isXS={isXS}>
						<TitleWrapper color="black87">{title}</TitleWrapper>
					</Row>
				)}
			/>
		</CounterSectionContainer>
	);
};

export default CounterSection;
