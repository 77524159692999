import { IDeviceDimension, IKeyFeaturesItem } from '@ApiModels/productById';
import ConditionalRender from '@Components/ConditionalRender/ConditionalRender';
import React, { FunctionComponent } from 'react';
import { Col, Row } from 'react-grid-system';
import Dimensions from './Dimensions/Dimensions';
import Features from './Features/Features';

interface IKeyFeatures {
	features: IKeyFeaturesItem[];
	dimensions?: IDeviceDimension;
}

const KeyFeatures: FunctionComponent<IKeyFeatures> = ({ features, dimensions }) => {
	return (
		<Row>
			<Col>
				<Features features={features} />
			</Col>
			<ConditionalRender
				show={!!dimensions}
				onTrue={() => (
					<Col xs={12} md={4}>
						<Dimensions dimensions={dimensions} />
					</Col>
				)}
			/>
		</Row>
	);
};

export default KeyFeatures;
