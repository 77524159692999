import React, { useState, useRef, useEffect, FunctionComponent, useMemo } from 'react';
import {
	SliderWrapper,
	IconWrapper,
	PreviewImageWrapper,
	Slider,
	SliderItemsWrapper,
	ImagePreview,
	BigImageWrapper,
	Placeholder,
	ContentContainer,
	SliderContainer,
	LoaderContainer,
} from './Carousel.styled';
import ConditionalRender from '@Components/ConditionalRender/ConditionalRender';
import Icon from '@Components/Icon/Icon';
import Image from '@Components/Image/Image';
import { modalTypes, setModal } from '@Redux/modules/modal/actions';
import { useDispatch } from 'react-redux';
import { IAvailableColorWithId } from '@Components/controls/ColorPicker/subcomponents/Slider';
import useScreen from '@Utils/hooks/useScreen';
import { PrimaryCircularLoader } from '@Components/CircularLoader/CircularLoader';

interface ICarouselProps {
	isLoading?: boolean;
	images?: string[];
	selectedPhotoIndex?: number;
	selectedPhotoSlide?: number;
	itemsToSlide?: number;
	itemsOnSlide?: number;
	showBigImage?: boolean;
	enableModalPreview?: boolean;
	availableColors?: IAvailableColorWithId[];
	previewMode?: boolean;
	pickedColor?: string | null;
	backgroundTitle?: string;
	photoContainerSize?: number;
	imageSize?: number;
	enableModalPreviewOnSliderPhotoClick?: boolean;
	sliderArrowsEnabled?: boolean;
}
interface IArrowProps {
	size?: number;
	hidden?: boolean;
}

const ARROW_PADDING = 16;
const SPACE_BETWEEN_SLIDES = 16;
const DEFAULT_PHOTO_INDEX = 0;
const DEFAULT_PHOTO_SLIDE = 0;
const DEFAULT_ITEMS_TO_SLIDE = 1;

const Carousel: FunctionComponent<ICarouselProps> = ({
	isLoading = false,
	images = [],
	selectedPhotoIndex = DEFAULT_PHOTO_INDEX,
	selectedPhotoSlide = DEFAULT_PHOTO_SLIDE,
	itemsToSlide = DEFAULT_ITEMS_TO_SLIDE,
	itemsOnSlide = images.length,
	showBigImage = true,
	enableModalPreview,
	availableColors = [],
	previewMode,
	pickedColor,
	backgroundTitle,
	photoContainerSize,
	imageSize,
	enableModalPreviewOnSliderPhotoClick,
	sliderArrowsEnabled = true,
}: ICarouselProps) => {
	const carouselRef = useRef<HTMLDivElement | null>(null);
	const [activeSlide, setActiveSlide] = useState<number>(selectedPhotoSlide);
	const [activeIndex, setActiveIndex] = useState<number>(selectedPhotoIndex);
	const numberOfSlides: number = Math.ceil(images.length / itemsOnSlide);
	const dispatch = useDispatch();
	const { screenClass } = useScreen();

	const getPhotoContainerSize: number = useMemo(() => {
		if (['md', 'lg'].includes(screenClass) && !previewMode) {
			return 98;
		} else if (['xs', 'sm'].includes(screenClass) && !previewMode) {
			return 216;
		} else {
			return 128;
		}
	}, [screenClass]);

	const photoContainer = photoContainerSize ?? getPhotoContainerSize;
	const slideWithSpace = photoContainer + SPACE_BETWEEN_SLIDES;

	const prevSlide = () => {
		if (carouselRef?.current) {
			setActiveSlide((activePrev) => (activePrev > 0 ? activePrev - 1 : 0));
		}
	};

	const nextSlide = () => {
		if (carouselRef?.current) {
			const { clientWidth } = carouselRef?.current;
			const slidesPerSlider = Math.floor((clientWidth + SPACE_BETWEEN_SLIDES) / slideWithSpace);
			const slides = Math.ceil(images.length / slidesPerSlider);
			setActiveSlide((activePrev) => (activePrev < slides - (itemsToSlide - 1) ? activePrev + 1 : activePrev));
		}
	};

	const prevImage = () => {
		if (activeIndex >= 1) {
			setActiveIndex((index) => index - 1);
		}
	};

	const nextImage = () => {
		if (activeIndex < images.length - 1) {
			setActiveIndex((index) => index + 1);
		}
	};

	useEffect(() => {
		if (carouselRef?.current) {
			carouselRef?.current?.scrollTo({
				top: 0,
				left: activeSlide * slideWithSpace * itemsToSlide,
				behavior: 'smooth',
			});
		}
	}, [activeSlide]);

	const onSlideClick = (index: number) => {
		setActiveIndex(index);
		enableModalPreviewOnSliderPhotoClick && !previewMode && onPreviewClick(activeIndex);
	};

	const onPreviewClick = (selectedImageIndex: number) => {
		dispatch(
			setModal({
				data: {
					images,
					selectedImageIndex,
					availableColors,
					pickedColor,
					backgroundTitle,
				},
				type: modalTypes.IMAGE_PREVIEW,
			})
		);
	};

	const LeftArrow = ({ size = 24, hidden }: IArrowProps) => (
		<ConditionalRender
			show={!!hidden}
			onTrue={() => <Placeholder size={size + (previewMode ? 0 : ARROW_PADDING)} />}
			onFalse={() => (
				<IconWrapper
					paddingRight={previewMode ? 0 : ARROW_PADDING}
					onClick={previewMode ? prevImage : prevSlide}
				>
					<Icon name="chevronLeft" fill="black87" height={size} width={size} />
				</IconWrapper>
			)}
		/>
	);

	const RightArrow = ({ size = 24, hidden }: IArrowProps) => (
		<ConditionalRender
			show={!!hidden}
			onTrue={() => <Placeholder size={size} />}
			onFalse={() => (
				<IconWrapper onClick={previewMode ? nextImage : nextSlide}>
					<Icon name="chevronRight" fill="black87" height={size} width={size} />
				</IconWrapper>
			)}
		/>
	);

	return (
		<ContentContainer previewMode={!!previewMode}>
			<ConditionalRender
				show={showBigImage}
				onTrue={() => (
					<>
						<BigImageWrapper previewMode={!!previewMode}>
							{previewMode && <LeftArrow size={40} hidden={activeIndex === 0} />}

							<PreviewImageWrapper
								previewMode={!!previewMode}
								smallImage={['xs', 'sm', 'md', 'lg'].includes(screenClass)}
							>
								{isLoading && (
									<LoaderContainer>
										<PrimaryCircularLoader />
									</LoaderContainer>
								)}
								{!isLoading && activeIndex >= 0 && (
									<ImagePreview
										previewMode={!!previewMode}
										selectable={!!enableModalPreview}
										onClick={() => enableModalPreview && onPreviewClick(activeIndex)}
										height="100%"
										src={images[activeIndex]}
									/>
								)}
							</PreviewImageWrapper>

							{previewMode && <RightArrow size={40} hidden={activeIndex === images.length - 1} />}
						</BigImageWrapper>
					</>
				)}
			/>
			<ConditionalRender
				show={!isLoading}
				onTrue={() => (
					<SliderContainer>
						<SliderWrapper>
							{!previewMode && sliderArrowsEnabled && <LeftArrow hidden={activeSlide === 0} />}
							<Slider
								ref={carouselRef}
								width={slideWithSpace * itemsOnSlide}
								center={images.length === 1}
								previewMode={!!previewMode}
							>
								<SliderItemsWrapper>
									{images.map((item, index) => {
										return (
											<Image
												key={index}
												margin="0 16px 0 0"
												width={photoContainer}
												height={photoContainer}
												src={item}
												imageHeight={imageSize ?? '90%'}
												onClick={() => {
													onSlideClick(index);
												}}
												selected={index === activeIndex}
												previewMode={!!previewMode}
											/>
										);
									})}
								</SliderItemsWrapper>
							</Slider>
							{!previewMode && sliderArrowsEnabled && (
								<RightArrow hidden={activeSlide === numberOfSlides - 1} />
							)}
						</SliderWrapper>
					</SliderContainer>
				)}
			/>
		</ContentContainer>
	);
};

export default Carousel;
