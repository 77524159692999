export interface IMakasibOtpPayload {
	msisdn: string;
	token: string;
}

interface IInputModel {
	_response: {
		resourceName: string;
		validate: boolean;
	};
}

export interface IMakasibOtpResponse {
	resourceName?: string;
	validate: boolean;
}

const model: types.models.IInputModel = {
	_response: ['response', {}],
	validate: (model: IInputModel) => model._response.validate,
	resourceName: (model: IInputModel) => model._response.resourceName,
};

export default model;
