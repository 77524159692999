import { matchScreen } from '@Utils/layout/layoutSupport';
import styled from 'styled-components';

export interface IImgProps {
	src: string;
	imageHeight?: number | string;
	previewMode?: boolean;
}
export interface IImageContainerProps {
	margin?: string;
	padding?: string;
	width?: number;
	height?: number;
	onClick?: () => void;
	selected?: boolean;
	previewMode?: boolean;
}

export const ImageContainer = styled.div<types.IStyledProps & IImageContainerProps>`
	${({
		margin = '0 0 0 0',
		padding = '0 0 0 0',
		width = 128,
		height = 128,
		theme,
		selected = false,
		previewMode,
	}) => {
		const withShadowStyles = previewMode
			? {
					backgroundColor: theme.colors.white,
					borderRadius: 8,
					boxShadow:
						'0 7px 8px 0 rgba(0, 0, 0, 0.05), 0 5px 22px 4px rgba(0, 0, 0, 0.04), 0 12px 17px 2px rgba(0, 0, 0, 0.04)',
			  }
			: {};
		const withBackground =
			!previewMode && matchScreen(['sm', 'xs'])
				? {
						backgroundColor: theme.colors.white,
						borderRadius: 8,
				  }
				: {};
		return {
			alignItems: 'center',
			justifyContent: 'center',
			display: 'flex',
			margin,
			padding,
			width,
			height,
			...withBackground,
			...withShadowStyles,
		};
	}}
	position:relative;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
`;

export const Img = styled.img<IImgProps>`
	${({ previewMode, imageHeight }) => {
		if (previewMode) {
			return {
				height: 88,
				width: 'auto',
			};
		} else {
			return {
				height: imageHeight ?? '100%',
				width: 'auto',
			};
		}
	}}
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
`;

export const ActiveDot = styled.div<{ selected: boolean }>`
	${({ theme, selected }) => {
		return {
			backgroundColor: theme.colors.primary,
			opacity: selected ? 1 : 0,
		};
	}};
	position: absolute;
	bottom: 8px;
	left: 61px;
	height: 6px;
	width: 6px;
	border-radius: 4px;
	transition: opacity 0.3s ease-in-out;
`;
