import styled from 'styled-components';

export const AppleLobMenuItem = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-content: center;
	align-items: center;
	cursor: pointer;
`;

export const AppleLobMenuItemTitle = styled.div<{ isSmall?: boolean }>`
	text-align: center;
	${({ isSmall }) => {
		return {
			marginTop: isSmall ? '16px' : '20px',
		};
	}}
`;
