import ConditionalRender from '@Components/ConditionalRender/ConditionalRender';
import { IGetOrderTrackingResponse, OrderFlowService } from '@Services/orderFlow/orderFlow';
import React, { FunctionComponent, useEffect, useMemo, useState, useRef } from 'react';
import { OrderTrackingContainer, PickupInfo } from './OrderTracking.styled';
import TrackingStepper from './TrackingStepper/TrackingStepper';
import TitleWithLine from '@Components/TitleWithLine/TitleWithLine';
import useTranslate from '@Utils/hooks/useTranslate';
import Divider from '@Components/Divider/Divider';
import Icon from '@Components/Icon/Icon';
import { OrderHistoryType } from '@Components/Card/cards/OrderHistory/OrderHistory';
import { IOrderDetailsItem, IOrderItem } from '@ApiModels/orderDetails';
import {
	PaymentDetails,
	PaymentDetailsDesc,
	PaymentDetailsTitle,
	Underline,
	Link,
	WaitingDescriptionContainer,
	WaitingDescriptionWrapper,
	WaitingTitleWrapper,
	WaitingWrapper,
} from '@Components/Card/cards/OrderHistory/OrderItem/OrderItem.styled';
import { ProductsService } from '@Services/productsService/productsService';
import moment from 'moment';
import { BodyS } from '@Components/Typography/Typography';
import CartItems from '@Components/Card/cards/CartItems/CartItems';
import Accordion from '@Components/Accordion/Accordion';
import { Col, Row } from 'react-grid-system';
import {
	Data,
	Label,
	LabeledInformationWrapper,
	MapElement,
	DaysWrapper,
} from '@Components/Card/cards/ShippingDetails/ShippingDetails.styled';
import { ParagraphM } from '@Components/Typography/Typography.styled';
import Map from '@Components/Map/Map';
import { IOutlet } from '@ApiModels/getProductOutletsPickup';
import { GoogleMap } from 'react-google-maps';

interface IOrderTracking {
	orderId: string;
	detailsItem: IOrderDetailsItem;
	type: OrderHistoryType;
	isOrderHistoryView?: boolean;
	status: string;
	hbbWfbbOrderDetails?: IOrderItem;
	isWFBB?: boolean;
}
const ICON_SIZE = 40;
const WAITING_ICON_SIZE = 48;

const OrderTracking: FunctionComponent<IOrderTracking> = ({
	orderId,
	detailsItem,
	type,
	isOrderHistoryView,
	status,
	hbbWfbbOrderDetails,
	isWFBB,
}) => {
	const [orderTracking, setOrderTracking] = useState<IGetOrderTrackingResponse[]>();
	const [launchDate, setLaunchDate] = useState<string | null>(null);
	const [showWaitingMessage, setShowWaitingMessage] = useState<boolean>(false);
	const [pickupOutlet, setPickupOutlet] = useState<IOutlet>({} as IOutlet);
	const { translate } = useTranslate();
	const mapsRef = useRef<GoogleMap>();

	const LabeledInformation = ({ label, data }: { label: string; data?: string | number }) => (
		<LabeledInformationWrapper>
			<Label>{label}</Label>
			<Data>{String(data || '')}</Data>
		</LabeledInformationWrapper>
	);

	useEffect(() => {
		const item = detailsItem.items.find((it) => it.plan?.addShipMode === 'PICKUP' && it.plan.launchDate);
		if (item) {
			ProductsService.getProductsByPartNumber(item.partNumber).subscribe(
				(product) => {
					if (product.launchDate) {
						setLaunchDate(moment(product.launchDate).format('DD MMM YYYY'));
					}
				},
				() => {}
			);
		}
	}, [detailsItem]);

	useEffect(() => {
		const includesDeviceProducts = detailsItem.items.some((prod) => !prod.isDigital);
		OrderFlowService.getOrderTracking({ orderId }).subscribe(
			(response) => {
				if (!!response?.length) {
					response.forEach((tracking) => {
						if (!tracking.trackingUrl) {
							if (
								((type === OrderHistoryType.ORDERS_IN_PROGRESS && includesDeviceProducts) ||
									type === OrderHistoryType.FULFILLMENT_IN_PROGRESS) &&
								!detailsItem.isOutletPickup
							)
								setShowWaitingMessage(hbbWfbbOrderDetails ? !tracking?.trackingNumber : true);
						}
					});
					setOrderTracking(response);
				} else {
					if (
						((type === OrderHistoryType.ORDERS_IN_PROGRESS && includesDeviceProducts) ||
							type === OrderHistoryType.FULFILLMENT_IN_PROGRESS) &&
						!detailsItem.isOutletPickup
					)
						setShowWaitingMessage(true);
				}
			},
			() => {
				if (
					((type === OrderHistoryType.ORDERS_IN_PROGRESS && includesDeviceProducts) ||
						type === OrderHistoryType.FULFILLMENT_IN_PROGRESS) &&
					!detailsItem.isOutletPickup
				)
					setShowWaitingMessage(true);
			}
		);
	}, []);

	const digitalProducts = useMemo(() => detailsItem.items.filter(({ isDigital }) => isDigital), [detailsItem]);

	useEffect(() => {
		if (!!detailsItem.isOutletPickup && !!detailsItem.branchId)
			ProductsService.getOutletPickup(detailsItem.branchId).subscribe(
				(response) => {
					if (!!response) setPickupOutlet(response.returnValue);
				},
				() => {}
			);
	}, [detailsItem]);

	const reloadMap = () => {
		setTimeout(() => {
			mapsRef.current?.panTo({
				lat: Number(pickupOutlet.latitude),
				lng: Number(pickupOutlet.longitude),
			});
		}, 1000);
	};

	return (
		<OrderTrackingContainer>
			<ConditionalRender
				show={showWaitingMessage}
				onFalse={() => (
					<>
						{orderTracking?.map((singleOrderTracking, index) => (
							<>
								<TitleWithLine
									title={
										orderTracking.length > 1
											? translate(
													'account.management.payment.shipment.pack',
													`${index + 1}/${orderTracking.length}`
											  )
											: translate(
													!!hbbWfbbOrderDetails
														? 'account.management.payment.shipment.hbb.wfbb.product'
														: !!detailsItem?.isOutletPickup
														? 'order.summary.section.physical.products.title'
														: 'account.management.payment.shipment.products'
											  )
									}
									marginBottom={16}
								/>
								<ConditionalRender
									show={!!detailsItem.isPickup}
									onTrue={() => (
										<>
											<Divider color="transparent" marginBottom={40} />
											<PaymentDetails>
												<Icon
													name="locationPin"
													width={ICON_SIZE}
													height={ICON_SIZE}
													fill="black38"
												/>
												<PaymentDetailsDesc>
													<PaymentDetailsTitle>
														{translate('account.management.pickup-address')}
													</PaymentDetailsTitle>
													<PickupInfo>
														{translate('shipping-method.pickup-info-1')}
														Omantel HQ {translate('shipping-method.pickup-info-2')}
														{launchDate}. {translate('shipping-method.pickup-info-3')}
													</PickupInfo>
												</PaymentDetailsDesc>
											</PaymentDetails>
											<ConditionalRender
												show={
													type === OrderHistoryType.ORDERS_IN_PROGRESS ||
													type === OrderHistoryType.FULFILLMENT_IN_PROGRESS
												}
												onTrue={() => (
													<>
														<Divider color="transparent" marginBottom={40} />
														<PaymentDetails>
															<Icon
																name="waiting"
																width={ICON_SIZE}
																height={ICON_SIZE}
																fill="black38"
															/>
															<PaymentDetailsDesc>
																<PaymentDetailsTitle>
																	{translate('account.management.order-in-progress')}
																</PaymentDetailsTitle>
															</PaymentDetailsDesc>
														</PaymentDetails>
													</>
												)}
											/>
										</>
									)}
									onFalse={() => (
										<>
											<TrackingStepper
												key={`orderTracking-${index}`}
												orderTracking={singleOrderTracking}
												isWFBB={isWFBB}
											/>
											<Divider withoutLine marginBottom={32} />
										</>
									)}
								/>
								<ConditionalRender
									show={
										(type === OrderHistoryType.ORDERS_IN_PROGRESS ||
											type === OrderHistoryType.FULFILLMENT_IN_PROGRESS) &&
										!!detailsItem.shipmentNumber &&
										detailsItem.shipmentNumber !== '-' &&
										!detailsItem.isPickup &&
										!detailsItem.isOutletPickup
									}
									onTrue={() => (
										<PaymentDetails>
											<Icon name="shipping" width={ICON_SIZE} height={ICON_SIZE} />
											<PaymentDetailsDesc>
												<PaymentDetailsTitle>
													{translate('account.management.track.shipping')}
												</PaymentDetailsTitle>
												<ConditionalRender
													show={!!singleOrderTracking.trackingNumber}
													onTrue={() => (
														<BodyS color="black54">
															{translate(
																'account.management.your.shipping.id',
																singleOrderTracking.trackingNumber
															)}
														</BodyS>
													)}
												/>
												<Divider color="transparent" marginBottom={16} />
												<ConditionalRender
													show={!!singleOrderTracking.trackingUrl}
													onTrue={() => (
														<Link
															onClick={() => {
																window.open(singleOrderTracking.trackingUrl, '_blank');
															}}
														>
															<Underline color="primary">
																{translate('account.management.track.delivery')}
															</Underline>
														</Link>
													)}
												/>
											</PaymentDetailsDesc>
										</PaymentDetails>
									)}
								/>
								<ConditionalRender
									show={!!detailsItem.isOutletPickup}
									onTrue={() => (
										<>
											<Accordion
												title={translate('order.summary.delivery.method.title')}
												icon="locationBuild"
												iconColor="black38"
												subtitleColor="black54"
												iconSize={42}
												marginLeft={52}
												withoutContainerPadding
												subtitle={translate('order.summary.delivery.method.subtitle')}
												constantMargin
												enableSubtitleOnExpand
												callback={reloadMap}
											>
												<Row>
													<Col xs={12} sm={6}>
														<LabeledInformation
															label={translate(
																'shipping-details.billing.shipping-data.data.outlet-located'
															)}
															data={
																!!pickupOutlet.branchName ? pickupOutlet.branchName : ''
															}
														/>

														<LabeledInformation
															label={translate(
																'shipping-details.billing.shipping-data.data.wilayat'
															)}
															data={!!pickupOutlet.wilayat ? pickupOutlet.wilayat : ''}
														/>

														<LabeledInformation
															label={translate(
																'shipping-details.billing.shipping-data.data.address'
															)}
															data={!!pickupOutlet.address ? pickupOutlet.address : ''}
														/>

														<LabeledInformation
															label={translate(
																'shipping-details.billing.shipping-data.data.phone-number'
															)}
															data={!!pickupOutlet.phone ? pickupOutlet.phone : ''}
														/>

														<LabeledInformationWrapper>
															<Label>
																{translate(
																	'shipping-details.billing.shipping-data.data.opening-hours'
																)}
															</Label>
															<DaysWrapper>
																<Data>
																	{translate('outlet-available.modal.sunday-label')}
																	{'\xa0'}
																</Data>
																<ParagraphM
																	color={
																		!!pickupOutlet?.openHours?.sunday
																			? 'black87'
																			: 'warning'
																	}
																>
																	{!!pickupOutlet?.openHours?.sunday
																		? pickupOutlet.openHours.sunday
																		: translate(
																				'outlet-available.modal.closed-label'
																		  )}
																</ParagraphM>
															</DaysWrapper>
															<DaysWrapper>
																<Data>
																	{translate('outlet-available.modal.monday-label')}
																	{'\xa0'}
																</Data>
																<ParagraphM
																	color={
																		!!pickupOutlet?.openHours?.monday
																			? 'black87'
																			: 'warning'
																	}
																>
																	{!!pickupOutlet?.openHours?.monday
																		? pickupOutlet?.openHours?.monday
																		: translate(
																				'outlet-available.modal.closed-label'
																		  )}
																</ParagraphM>
															</DaysWrapper>
															<DaysWrapper>
																<Data>
																	{translate('outlet-available.modal.tuesday-label')}
																	{'\xa0'}
																</Data>
																<ParagraphM
																	color={
																		!!pickupOutlet?.openHours?.tuesday
																			? 'black87'
																			: 'warning'
																	}
																>
																	{!!pickupOutlet?.openHours?.tuesday
																		? pickupOutlet?.openHours?.tuesday
																		: translate(
																				'outlet-available.modal.closed-label'
																		  )}
																</ParagraphM>
															</DaysWrapper>
															<DaysWrapper>
																<Data>
																	{translate(
																		'outlet-available.modal.wednesday-label'
																	)}
																	{'\xa0'}
																</Data>
																<ParagraphM
																	color={
																		!!pickupOutlet?.openHours?.wednesday
																			? 'black87'
																			: 'warning'
																	}
																>
																	{!!pickupOutlet?.openHours?.wednesday
																		? pickupOutlet?.openHours?.wednesday
																		: translate(
																				'outlet-available.modal.closed-label'
																		  )}
																</ParagraphM>
															</DaysWrapper>
															<DaysWrapper>
																<Data>
																	{translate('outlet-available.modal.thursday-label')}
																	{'\xa0'}
																</Data>
																<ParagraphM
																	color={
																		!!pickupOutlet?.openHours?.thursday
																			? 'black87'
																			: 'warning'
																	}
																>
																	{!!pickupOutlet?.openHours?.thursday
																		? pickupOutlet?.openHours?.thursday
																		: translate(
																				'outlet-available.modal.closed-label'
																		  )}
																</ParagraphM>
															</DaysWrapper>
															<DaysWrapper>
																<Data>
																	{translate('outlet-available.modal.friday-label')}
																	{'\xa0'}
																</Data>
																<ParagraphM
																	color={
																		!!pickupOutlet?.openHours?.friday
																			? 'black87'
																			: 'warning'
																	}
																>
																	{!!pickupOutlet?.openHours?.friday
																		? pickupOutlet?.openHours?.friday
																		: translate(
																				'outlet-available.modal.closed-label'
																		  )}
																</ParagraphM>
															</DaysWrapper>
															<DaysWrapper>
																<Data>
																	{translate('outlet-available.modal.saturday-label')}
																	{'\xa0'}
																</Data>
																<ParagraphM
																	color={
																		!!pickupOutlet?.openHours?.saturday
																			? 'black87'
																			: 'warning'
																	}
																>
																	{!!pickupOutlet?.openHours?.saturday
																		? pickupOutlet?.openHours?.saturday
																		: translate(
																				'outlet-available.modal.closed-label'
																		  )}
																</ParagraphM>
															</DaysWrapper>
														</LabeledInformationWrapper>
													</Col>

													<Col xs={12} sm={6}>
														<Map
															mapElement={<MapElement />}
															zoom={10}
															position={
																({
																	lat: Number(pickupOutlet.latitude),
																	lng: Number(pickupOutlet.longitude),
																} as unknown) as google.maps.LatLngLiteral
															}
															markerPosition={
																({
																	lat: Number(pickupOutlet.latitude),
																	lng: Number(pickupOutlet.longitude),
																} as unknown) as google.maps.LatLngLiteral
															}
															containerElement={<MapElement />}
															locked
														/>
													</Col>
												</Row>
											</Accordion>
											<Divider withoutLine marginBottom={32} />
											<ConditionalRender
												show={!!detailsItem?.outletPickupBookingAppointment}
												onTrue={() => (
													<>
														<Accordion
															title={translate(
																'shipping-details.accordion.delivery-method.title.outlet-pickup-appointment'
															)}
															icon="deliveryDate"
															iconColor="black38"
															subtitleColor="black54"
															iconSize={42}
															marginLeft={52}
															withoutContainerPadding
															subtitle={`${translate(
																'shipping-details.accordion.appointment.outlet-in'
															)}
															${pickupOutlet.branchName}
															${translate('shipping-details.accordion.appointment.outlet-on')}
															${detailsItem?.outletPickupBookingAppointment ?? ''}`}
															constantMargin
															enableSubtitleOnExpand
														>
															<LabeledInformation
																label={translate(
																	'order.summary.appointment.outlet-appointment-ticket-number.title'
																)}
																data={detailsItem.ticketNumber}
															/>
														</Accordion>
														<Divider withoutLine marginBottom={32} />
													</>
												)}
											/>
										</>
									)}
								/>
								<Divider withoutLine marginBottom={40} />
								<ConditionalRender
									show={type === OrderHistoryType.ORDER_HISTORY && status === 'S'}
									onTrue={() => (
										<PaymentDetails>
											<Icon name="success" width={ICON_SIZE} height={ICON_SIZE} />
											<PaymentDetailsDesc>
												<PaymentDetailsTitle>
													{translate(
														detailsItem.isPickup
															? 'account.management.order.complete'
															: 'account.management.order.delivered'
													)}
												</PaymentDetailsTitle>
												<BodyS color="black54">{detailsItem.deliveryDate}</BodyS>
											</PaymentDetailsDesc>
										</PaymentDetails>
									)}
								/>
								<Divider withoutLine marginBottom={32} />
								<CartItems
									params={{
										cartItems: detailsItem.items.filter(({ orderItemId }) =>
											singleOrderTracking.orderItems.includes(orderItemId)
										),
										priceColor: 'black87',
										tableMode: true,
										addToCart: false,
										withoutItemsLabel: true,
										isOrderHistoryView,
									}}
									card="cartItems"
								/>
								<Divider withoutLine marginBottom={64} />
							</>
						))}
					</>
				)}
				onTrue={() => (
					<>
						<WaitingWrapper>
							<Icon name="waiting" width={WAITING_ICON_SIZE} height={WAITING_ICON_SIZE} fill="black38" />
							<WaitingTitleWrapper>{translate('waiting.tracking.details.title')}</WaitingTitleWrapper>
							<WaitingDescriptionContainer>
								<WaitingDescriptionWrapper>
									{translate('waiting.tracking.details.description-1')}
								</WaitingDescriptionWrapper>
								<WaitingDescriptionWrapper>
									{translate('waiting.tracking.details.description-2')}
								</WaitingDescriptionWrapper>
							</WaitingDescriptionContainer>
						</WaitingWrapper>
					</>
				)}
			/>
			<ConditionalRender
				show={!!digitalProducts.length}
				onTrue={() => (
					<>
						<TitleWithLine
							title={translate('account.management.payment.digital-delivery')}
							marginBottom={16}
						/>
						<Accordion
							title={translate('shipping-details.accordion.digital-shipping-data.title')}
							subtitle={detailsItem?.email ?? ''}
							icon="messages"
							displayExpand={false}
							isExpanded
							forceState
							withoutContainerPadding
							enableSubtitleOnExpand
						/>
						<Divider withoutLine marginBottom={40} />
						<CartItems
							params={{
								cartItems: digitalProducts,
								priceColor: 'black87',
								tableMode: true,
								addToCart: false,
								withoutItemsLabel: true,
								isOrderHistoryView,
								email: detailsItem?.email,
							}}
							card="cartItems"
						/>
						<Divider withoutLine marginBottom={64} />
					</>
				)}
			/>
		</OrderTrackingContainer>
	);
};

export default OrderTracking;
