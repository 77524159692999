export {
	DisplayL,
	DisplayM,
	DisplayMBold,
	HeadlineBold,
	BiggerHeadlineBold,
	Headline,
	HeadlineL,
	Header,
	TitleBold,
	Title,
	ValueBold,
	Tabs,
	Value,
	ButtonL,
	BodyMExtraBold,
	ParagraphMExtraBold,
	BodyMBold,
	ParagraphMBold,
	BodyM,
	ParagraphM,
	ParagraphMHigher,
	ParagraphL,
	FloatingLabel,
	ButtonM,
	DiscountS,
	BodySBold,
	ParagraphSBold,
	BodyS,
	BodySHigher,
	ParagraphS,
	Subheading,
	CaptionBold,
	Label,
	SmallCaption,
	Caption,
	CaptionBigger,
	CaptionBiggerBold,
	CaptionHigher,
	CaptionHigherBold,
	BigCurrency,
	SmallCurrency,
	SmallCurrencyVat,
	Discount,
	MiniUnit,
	SmallDuration,
	MiniDurationBold,
	MiniDuration,
	Counter,
	Info,
	InfoBold,
	MiniCurrency,
	DisplayMBoldH1,
	BodyMH3,
	SubheadingH2,
	MiniDescription,
	StatusBold,
	HeadlineS,
	ComparisonCurrency,
	BodyXLBold,
	Description,
	BodyLBold,
	BodyL,
	BodySemiLBold,
} from './Typography.styled';
