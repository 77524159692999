import React, { FunctionComponent, useMemo } from 'react';
import Button, { TertiaryMediumButtonFixed } from '@Components/controls/Button/Button';
import { ProductType } from '@Components/Card/cards/ProductCard/ProductCard.styled';
import { ButtonSize, ButtonVariant, ButtonWidth } from '@Components/controls/Button/Button.styled';
import {
	ActionSectionWrapper,
	ButtonWrapper,
	CompareCheckboxContainer,
} from '@Components/Card/cards/ProductCard/subcomponents/ActionSection/ActionSection.styled';
import ConditionalRender from '@Components/ConditionalRender/ConditionalRender';
import useTranslate from '@Utils/hooks/useTranslate';
import { IItem } from '@ApiModels/productsByCategoryId';
import Checkbox from '@Components/controls/Checkbox/Checkbox';
import { CheckboxShape } from '@Components/controls/Checkbox/Checkbox.styled';
import { useDispatch, useSelector } from 'react-redux';
import { addToCompare, removeFromCompare } from '@Redux/modules/comparison/actions';
import Divider from '@Components/Divider/Divider';
interface IActionSectionProps {
	product: IItem;
	productType: ProductType;
	iconsHidden?: boolean;
	onClick?: (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
	comparisonView?: boolean;
}

const ActionSection: FunctionComponent<IActionSectionProps> = ({
	productType,
	iconsHidden,
	product,
	onClick,
	comparisonView,
}) => {
	const { translate } = useTranslate();

	const dispatch = useDispatch();
	const isOnList = !!useSelector((state: types.redux.IState) => state.comparisonList).find(
		(item) => item.id === product.uniqueID
	);

	const buttonVariant = useMemo(() => {
		switch (productType) {
			case ProductType.PREORDER:
				return ButtonVariant.DEFAULT;
			case ProductType.AVAILABLE:
				return ButtonVariant.PRIMARY;
			case ProductType.UNAVAILABLE:
				return ButtonVariant.SECONDARY;
			case ProductType.SALES_LEAD:
				return ButtonVariant.PRIMARY;
		}
	}, [productType]);

	const buttonText = () => {
		switch (productType) {
			case ProductType.PREORDER:
				return translate('product.card.preorder.now');
			case ProductType.AVAILABLE:
				return true ? translate('product.card.see.details') : translate('product.card.view.details');
			case ProductType.UNAVAILABLE:
				return translate('product.card.notify.me');
			case ProductType.SALES_LEAD:
				return translate('product.card.request');
		}
	};

	const onPressComparison = () => {
		if (isOnList) {
			dispatch(removeFromCompare({ id: product.uniqueID }));
		} else {
			dispatch(addToCompare({ thumbnailUrl: product.thumbnail, id: product.uniqueID }));
		}
	};

	return (
		<ActionSectionWrapper>
			<ButtonWrapper>
				<Button
					uniqueId="action.section"
					onClick={(e) => {
						onClick?.();
					}}
					variant={buttonVariant}
					size={ButtonSize.MEDIUM}
					width={ButtonWidth.FIXED}
				>
					<>{buttonText()}</>
				</Button>
				<ConditionalRender
					show={!iconsHidden}
					onTrue={() => (
						<>
							<Divider withoutLine marginBottom={16} />
							<ConditionalRender
								show={!comparisonView}
								onTrue={() => (
									<CompareCheckboxContainer
										onClick={(e) => {
											e.preventDefault();
											e.stopPropagation();
										}}
									>
										<Checkbox
											active={isOnList}
											shape={CheckboxShape.SQUARE}
											onChange={onPressComparison}
											size={22}
											label={translate('comparison.compare')}
											labelClickable
										/>
									</CompareCheckboxContainer>
								)}
								onFalse={() => (
									<ButtonWrapper>
										<TertiaryMediumButtonFixed
											uniqueId="remove.from.comparison"
											onClick={onPressComparison}
										>
											{translate('comparison.remove')}
										</TertiaryMediumButtonFixed>
									</ButtonWrapper>
								)}
							/>
						</>
					)}
				/>
			</ButtonWrapper>
		</ActionSectionWrapper>
	);
};

export default ActionSection;
