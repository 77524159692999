import styled from 'styled-components';
import { Value } from '@Components/Typography/Typography.styled';

interface IProps {
	selected: boolean;
	disabled: boolean;
}

export const ChipWrapper = styled.div<IProps>`
	display: inline-block;
	cursor: pointer;
	border-radius: 20px;
	padding: 8px 16px;
	margin: 0 4px;
	transition: all 0.1s ease-in-out;

	${({ selected, disabled, theme }) => {
		if (disabled) {
			if (selected) {
				return {
					background: theme.colors.black12,
				};
			}
			return {
				border: '1px solid',
				borderColor: theme.colors.black12,
			};
		} else if (selected) {
			return {
				border: '1px solid',
				borderColor: theme.colors.primary,
				background: theme.colors.primary,
				'&:hover': {
					opacity: 0.9,
				},
				'&:active': {
					opacity: 0.8,
				},
			};
		} else {
			return {
				border: '1px solid',
				borderColor: theme.colors.primary,
				background: theme.colors.white,
				'&:hover': {
					background: theme.colors.primary6,
				},
				'&:active': {
					background: theme.colors.primary15,
				},
			};
		}
	}}
`;

export const ChipContent = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
`;

export const Label = styled(Value)<IProps>`
	${({ selected, disabled, theme }) => {
		if (disabled) {
			return {
				color: theme.colors.black38,
			};
		} else if (selected) {
			return {
				color: theme.colors.white,
			};
		} else {
			return { color: theme.colors.primary };
		}
	}}
`;

export const IconWrapper = styled.div`
	margin-left: 8px;
`;
