import styled from 'styled-components';

export const PriceSectionWrapper = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
`;

export const LabelWrapper = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	width: 100%;
`;

export const PriceContainer = styled.div`
	margin-top: 2px;
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	flex-grow: 1;
`;

export const MissingInstallmentWrapper = styled.div`
	margin-bottom: 6px;
`;
