import { BodyS, ValueBold } from '@Components/Typography/Typography.styled';
import { marginLeft } from '@Utils/layout/layoutSupport';
import styled from 'styled-components';

export const ShippingMethodCardContainer = styled.div`
	padding: 16px;
`;

export const TitleWrapper = styled(ValueBold)`
	${({ theme }) => ({
		color: theme.colors.black87,
	})}
`;

export const DescriptionWrapper = styled(BodyS)`
	${({ theme }) => ({
		color: theme.colors.black54,
	})}
	margin-top: 8px;
	margin-bottom: 24px;
`;

export const EmailContainer = styled.div`
	padding: 24px 16px;
	${({ theme }) => ({
		backgroundColor: theme.colors.grey50,
	})}
	width: 100%;
	border-radius: 8px;
	display: flex;
	flex-direction: row;
	align-items: center;
`;

export const EmailValue = styled(ValueBold)`
	overflow-wrap: anywhere;
	${({ theme }) => ({
		color: theme.colors.black87,
	})}
	${() => marginLeft('8px')}
`;
