export interface ISearchMenuList {
	list: ISingleSearchMenuList[];
}

export interface IMarketingContentDescription {
	language: string;
	marketingText: string;
}

export interface ISingleSearchMenuList {
	label?: string;
	isHeader?: string;
	link?: string;
	to?: string;
}

export interface IMarketingContentDescription2 {}

export interface IProperty {
	baseMarketingKey: string;
	baseMarketingValue: string;
}

interface IAttachementAsset {
	attachmentAssetStoreId: string;
	attachmentLocalAsset: string;
	attachmentAssetId: string;
	attachmentAssetPath: string;
}

export interface IBaseMarketingSpotActivityData {
	activityIdentifierID: string;
	activityPriority: string;
	baseMarketingSpotActivityID: string;
	baseMarketingSpotActivityName: string;
	baseMarketingSpotDataType: string;
	contentClickAction: string;
	contentFormatId: string;
	contentFormatName: string;
	contentId: string;
	contentName: string;
	contentStoreId: string;
	marketingContentDescription: IMarketingContentDescription[];
	MarketingContentDescription: IMarketingContentDescription2[];
	properties: IProperty[];
	attachmentAsset?: IAttachementAsset[];
	contentUrl?: string;
}

export interface IDefaultContentDisplaySequence {
	displaySequence: string;
	resultFormat: string;
	resultId: string;
}

export interface IMarketingSpotData {
	baseMarketingSpotActivityData: IBaseMarketingSpotActivityData[];
	behavior: string;
	defaultContentDisplaySequence: IDefaultContentDisplaySequence[];
	eSpotName: string;
	marketingSpotIdentifier: string;
	nextTimeLimit: string;
}

export interface IModel {
	_MarketingSpotData: IMarketingSpotData[];
	_list: IBaseMarketingSpotActivityData[];
}

const model: types.models.IInputModel = {
	_MarketingSpotData: 'response.MarketingSpotData',
	_list: (model: IModel) => model._MarketingSpotData[0].baseMarketingSpotActivityData,
	list: (model: IModel) => {
		return model?._list?.map((item) => {
			let marketingText: ISingleSearchMenuList = {};
			try {
				marketingText = JSON.parse(item?.marketingContentDescription[0]?.marketingText) || '';
			} catch {}
			return {
				label: marketingText?.label,
				isHeader: marketingText?.isHeader,
				link: marketingText?.link || marketingText?.to,
			};
		});
	},
};

export default model;
