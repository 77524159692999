import { Observable } from 'rxjs';
import api from '@Utils/api';
import { AjaxResponse } from 'rxjs/ajax';
export class remoteConfig {
	public static getRemoteConfig(): Observable<AjaxResponse> {
		return api.wcm.getRemoteConfig().setType('remoteConfig').disabledWithCredentials().disableCache().call();
	}
}

export default remoteConfig;
