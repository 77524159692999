import { Observable, EMPTY, throwError } from 'rxjs';
import api from '@Utils/api';
import { IPaymentTokens } from '@ApiModels/paymentTokens';
import { IPreCheckout } from '@ApiModels/preCheckout';
import { catchError, concatMap, finalize, map } from 'rxjs/operators';
import { ICheckout } from '@Redux/modules/checkout/actions';
import { IDoCheckout } from '@ApiModels/checkout';
import { IProcessOrder } from '@ApiModels/processOrder';
import * as payloads from '@Utils/api/payloads/omantelApi';
import invalidateCache from '@Utils/api/invalidateCache';
import { dispatch } from '@Redux/store';
import { updateCart } from '@Redux/modules/cart/actions';

export class PaymentService {
	public static getPaymentTokens(): Observable<IPaymentTokens> {
		return api.omantelApi.getPaymentTokensForCurrentUser().forceCall();
	}

	public static removePaymentToken(paymentTokenId: string): Observable<any> {
		return api.omantelApi.removePaymentToken(paymentTokenId).call();
	}

	public static getPaymentInformation(): Observable<any> {
		return api.omantelApi.getPaymentInformation().call();
	}

	public static doPreCheckout(): Observable<IPreCheckout> {
		return api.omantelApi.doPreCheckout().call();
	}

	public static doCheckout(): Observable<IDoCheckout> {
		return this.doPreCheckout().pipe(
			concatMap((r) => {
				if (r.success) {
					return api.omantelApi.doCheckout().call();
				} else {
					return EMPTY;
				}
			}),
			finalize(() => {
				dispatch(updateCart(true));
			})
		);
	}

	public static processOrder({
		totalAmount,
		orderId,
		billingAddress,
		contactDetails,
	}: {
		totalAmount: string;
		orderId: string;
		billingAddress: ICheckout['payload']['billingAddress'];
		contactDetails: ICheckout['payload']['contactDetails'];
	}): Observable<IProcessOrder> {
		return api.omantelApi.processOrder({ totalAmount, orderId, billingAddress, contactDetails }).call();
	}

	public static makePayment({
		makePaymentProps,
		origin,
	}: {
		makePaymentProps: types.payment.IMakePayment;
		origin: string;
		paymentData?: any;
	}): Observable<{ result: any }> {
		return api.omantelApi
			.makePayment()
			.setPayload(payloads.makePaymentPayload(makePaymentProps))
			.call()
			.pipe(
				map((r) => {
					const result = this.getResult(r);
					if (makePaymentProps.paymentData?.card?.savePaymentToken) {
						invalidateCache(['getPaymentTokensForCurrentUser']);
					}
					return { result };
				}),
				catchError((e: any) => {
					return throwError(e);
				})
			);
	}

	private static getResult(r: any) {
		if (r.response.responseJSON) {
			return r.response.responseJSON.NullResult;
		} else {
			return r.response.NullResult;
		}
	}
}
