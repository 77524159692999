import React, { FunctionComponent } from 'react';
import { useSelector } from 'react-redux';
import { AppWrapper } from './LayoutWrapper.styled';
import { setConfiguration } from 'react-grid-system';
import { ADDITIONAL_BAR_HEIGHT, ADDITIONAL_SMALL_BAR_HEIGHT } from '@Components/TelemarketerTopBar/TelemarketerTopBar';
import { matchScreen } from '@Utils/layout/layoutSupport';

const LayoutWrapper: FunctionComponent = ({ children }) => {
	setConfiguration({ maxScreenClass: 'xl' });
	const isXsSmMd = matchScreen(['xs', 'sm', 'md']);
	const direction = useSelector((state: types.redux.IState) => state.settings.layout);
	const telemarketer = useSelector((state: types.redux.IState) => state.api.authentication.telemarketer);

	return (
		<AppWrapper
			direction={direction}
			marginTop={telemarketer ? (isXsSmMd ? ADDITIONAL_SMALL_BAR_HEIGHT : ADDITIONAL_BAR_HEIGHT) : 0}
		>
			{children}
		</AppWrapper>
	);
};

export default LayoutWrapper;
