import styled from 'styled-components';

export const StarRatingWrapper = styled.div`
	display: flex;
	flex-direction: row;
`;

export const IconWrapper = styled.div`
	margin-right: 8px;
`;

export const RatingLabelWrapper = styled.div`
	margin-top: 1px;
`;

export const LabelWrapper = styled.div`
	margin-top: 8px;
`;
