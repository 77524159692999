import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { ISinglePackage } from '@ApiModels/singlePackage';
import { IUserProfile } from '@ApiModels/userProfile';
import BusyIndicator from '@Components/BusyIndicator/BusyIndicator';
import ConditionalRender from '@Components/ConditionalRender/ConditionalRender';
import Divider from '@Components/Divider/Divider';
import Icon from '@Components/Icon/Icon';
import PlanComparison from '@Components/PlanComparison/PlanComparison';
import { BodyM, DisplayMBold, TitleBold } from '@Components/Typography/Typography.styled';
import { HBB_PRODUCT_ID } from '@Config/app.config';
import { addBusyIndicator, removeBusyIndicator } from '@Redux/modules/busyIndicator/actions';
import { modalTypes, setModal } from '@Redux/modules/modal/actions';
import { setPersistentCheckoutDetails } from '@Redux/modules/persistentCheckoutData/actions';
import { dispatch } from '@Redux/store';
import { IAddItemsToCartPayload, OrderFlowService } from '@Services/orderFlow/orderFlow';
import { errors } from '@Utils/api/errors/errors';
import { useCart } from '@Utils/context/CartContext';
import useTranslate from '@Utils/hooks/useTranslate';
import { getLang } from '@Utils/language/language';
import { getBarTypeByPlan } from '@Utils/plans/barType';
import { BUY_HBB, BUY_WFBB } from '@Views/Map/Map';
import { Container } from 'react-grid-system';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { BarContent, BarTitle, Change, InfoBar, TopBar } from './Plans.styled';
import TabSelector from '@Components/controls/TabSelector/TabSelector';

interface IPlansProps {}

export interface IChangeAddress {
	networkType: string;
	region: string;
	cabinet: string;
	dp: string;
	exe: string;
	swatSOSStatus: string;
	gisDetails: string;
	latitude: string;
	longitude: string;
	willyat: string;
	wayNumber: string;
	buildingNumber: string;
}

const ADD_TO_CART_PLANS_BI = 'ADD_TO_CART_PLANS_BI';
const REMOVE_OLD_CART_PLANS_BI = 'REMOVE_OLD_CART_PLANS_BI';
const CART_LOADER_PLANS_BI = 'CART_LOADER_PLANS_BI';

const Plans: FunctionComponent<IPlansProps> = () => {
	const hbbWfbbProcessCheckout = useSelector(
		(state: types.redux.IState) => state.persistentCheckout.hbbWfbbProcessCheckout
	);
	const { translate } = useTranslate();
	const history = useHistory();
	const { cart, loading } = useCart();
	const location = useLocation<{
		plans: ISinglePackage[];
		additionalPlans: ISinglePackage[];
		userProfile: IUserProfile;
		changedAddressOnEditProcess?: IChangeAddress;
		checkedPlan?: ISinglePackage;
	}>();
	const plans = location.state?.plans || [];
	const additionalPlans = location.state?.additionalPlans || [];
	const userProfile = location.state?.userProfile;
	const changedAddressOnEditProcess = location.state?.changedAddressOnEditProcess;
	const checkedPlan = location.state?.checkedPlan;
	const isLogged = useSelector((state: types.redux.IState) => state.api.authentication.signedIn);
	const [currentTab, setCurrentTab] = useState(plans.length ? 0 : 1);

	useEffect(() => {
		if (!plans?.length && !additionalPlans?.length) {
			history.push(`/${getLang()}/store`);
		}
	}, [location.state]);

	const handleChangeAddress = () => {
		history.goBack();
	};

	const plansToCompare = useMemo(() => {
		return [plans, additionalPlans][currentTab].map((plan, index) => {
			return {
				id: plan.uniqueID,
				type: getBarTypeByPlan(plan),
				name: plan.shortDescription,
				contractCommitment: plan.duration
					? translate('plan.selector.commitment', String(plan.duration))
					: translate('plan.selector.no.commitment'),
				isCurrentPlan: false,
				isRecommendedPlan: false,
				monthlyPayment: plan.price,
				localAndGccData:
					plan.planDetails?.includedData?.itemValue === '-'
						? 'Unlimited'
						: plan.planDetails?.includedData?.itemValue,
				localMinutes:
					plan.planDetails?.includedMinutes?.itemValue === '-'
						? 'Unlimited'
						: plan.planDetails?.includedMinutes?.itemValue,
				internationalMinutes: plan.planDetails?.intlMinutes?.itemValue,
				roamingRecievingMinutes: plan.planDetails?.roamingRecMinutes?.itemValue,
				worldRoamingData: plan.planDetails?.roamingData?.itemValue,
				dataSharing: plan.planDetails?.dataSharing?.itemValue,
				storeCredit: plan.planDetails?.promoAppStore?.itemValue,
				keepThisPlan: false,
				selectPlan: true,
				dataIncluded:
					plan.planDetails?.data?.itemValue || plan?.planDetails?.hbbDataIncluded?.itemValue === '-'
						? 'Unlimited'
						: plan.planDetails?.hbbDataIncluded?.itemValue,
				downloadSpeed: plan.planDetails?.downloadSpeed?.itemValue,
				downloadSpeedUnit: plan.planDetails?.downloadSpeed?.itemUnit === 'Gbps' ? 'GB' : 'MB',
				uploadSpeed: plan.planDetails?.uploadSpeed?.itemValue,
				freeMinutesToOmantel:
					plan.planDetails?.includedMinutes?.itemValue === '-'
						? 'Unlimited'
						: plan.planDetails?.includedMinutes?.itemValue,
				intlCallsDiscount: plan.planDetails?.callDiscount?.itemValue,
				planVatPercent: plan.vatPercent,
				planVatValue: plan.vatValue,
				offerPrice: plan?.offerPrice,
				socialMediaDataValue: plan.planDetails?.PLAN_PLACEHOLDER_1?.itemValue,
				socialMediaDataUnit: plan.planDetails?.PLAN_PLACEHOLDER_1?.itemUnit,
			};
		});
	}, [plans, additionalPlans, currentTab]);

	const handlePlanSelection = (planIndex: number) => {
		const leadSales = !plans.length;
		if (leadSales) {
			history.push(`/${getLang()}/contact`, {
				selectedWfbbPlan: additionalPlans[planIndex],
			});
		} else {
			handleAddToCart(planIndex);
		}
	};

	const handleAddToCart = (planIndex: number) => {
		const userWantsToChangeHisPreviousAddress = !!changedAddressOnEditProcess;
		const hbbWfbbItemInCart = cart.items.find((item) => [BUY_HBB, BUY_WFBB].includes(item.plan?.PA || ''));
		if (userWantsToChangeHisPreviousAddress && hbbWfbbItemInCart) {
			dispatch(addBusyIndicator(REMOVE_OLD_CART_PLANS_BI));
			OrderFlowService.removeSingleCartItem(hbbWfbbItemInCart?.orderItemId || '').subscribe(
				() => {
					addToCart(planIndex, changedAddressOnEditProcess);
					dispatch(removeBusyIndicator(REMOVE_OLD_CART_PLANS_BI));
				},
				() => {
					dispatch(removeBusyIndicator(REMOVE_OLD_CART_PLANS_BI));
				}
			);
		} else {
			addToCart(planIndex);
		}
	};

	const addToCart = (planIndex: number, newAddress?: IChangeAddress) => {
		const selectedPlan = plans[planIndex];
		if (selectedPlan) {
			dispatch(addBusyIndicator(ADD_TO_CART_PLANS_BI));

			let payload: IAddItemsToCartPayload = {
				quantity: '1',
				productId: String(HBB_PRODUCT_ID || ''),
				xitem_planAction: BUY_HBB,
				xitem_productId: selectedPlan.partNumber,
				productName: selectedPlan ? selectedPlan?.name : '',
				payment_plan: translate('product.details.plan.device.only'),
			};

			if (isLogged) {
				const preparedLoginNumber = userProfile?.uid.replace(/[^0-9]+/g, '');
				payload = { ...payload, xitem_login: preparedLoginNumber };
			}

			OrderFlowService.addItemsToCart([payload]).subscribe(
				({ response }) => {
					dispatch(removeBusyIndicator(ADD_TO_CART_PLANS_BI));
					dispatch(
						setModal({
							type: modalTypes.ADDED_TO_CART,
							data: { orderId: response.orderItem[0].orderItemId },
							withoutContainer: true,
						})
					);
					dispatch(
						setPersistentCheckoutDetails({
							hbbWfbbProcessCheckout: {
								plansSelectedStr: selectedPlan?.name || selectedPlan?.shortDescription || '',
								bandwidth: String(selectedPlan.planDetails?.downloadSpeed?.itemValue || ''),
								plansSelected: `${selectedPlan.provisioningID},${selectedPlan.partNumber},${selectedPlan.planCommitment}`,
								partNumber: selectedPlan.partNumber,
								...(newAddress || {}),
							},
						})
					);
				},
				(e) => {
					dispatch(removeBusyIndicator(ADD_TO_CART_PLANS_BI));
					if (e.response?.errors?.length > 0) {
						if (e.response?.errors[0]?.errorKey === errors.SESSION_GENERIC_USER) {
							dispatch(
								setModal({
									type: modalTypes.LOGIN_SESSION_EXPIRED,
									data: {},
								})
							);
						}
					}
				}
			);
		}
	};

	const getTitle = () => {
		let addressArr = [];
		addressArr =
			hbbWfbbProcessCheckout?.region && hbbWfbbProcessCheckout?.region !== 'null'
				? [hbbWfbbProcessCheckout?.region]
				: [];
		addressArr =
			hbbWfbbProcessCheckout?.wayNumber && hbbWfbbProcessCheckout?.wayNumber !== 'null'
				? [...addressArr, ',' + hbbWfbbProcessCheckout?.wayNumber]
				: [...addressArr];
		addressArr =
			hbbWfbbProcessCheckout?.buildingNumber && hbbWfbbProcessCheckout?.buildingNumber !== 'null'
				? [...addressArr, ',' + hbbWfbbProcessCheckout?.buildingNumber]
				: [...addressArr];
		return addressArr.join('');
	};

	return (
		<ConditionalRender
			show={!!plans.length || !!additionalPlans.length}
			onTrue={() => (
				<>
					<TopBar>
						<Container>
							<BarContent>
								{hbbWfbbProcessCheckout?.region && (
									<>
										<BarTitle>{translate('contact.bar.title')}</BarTitle>
										<TitleBold>{getTitle()}</TitleBold>
									</>
								)}
								<Change onClick={handleChangeAddress}>{translate('contact.bar.title.change')}</Change>
							</BarContent>
						</Container>
					</TopBar>
					<Container>
						<ConditionalRender
							show={!!checkedPlan}
							onTrue={() => (
								<>
									<Divider withoutLine marginBottom={24} />
									<InfoBar>
										<Icon name="warning" fill="white" height={24} width={24} />
										<BodyM color="white">
											{translate(
												'plans.selected.plan.not.available',
												checkedPlan?.name || checkedPlan?.shortDescription || ''
											)}
										</BodyM>
									</InfoBar>
								</>
							)}
						/>
						<Divider withoutLine marginBottom={40} />
						<DisplayMBold color="primary">{translate('plans.plans.available')}</DisplayMBold>
						<Divider withoutLine marginBottom={40} />
					</Container>
					<TabSelector
						tabs={[
							{ tabName: translate('plans.tab.ultra.fast'), disabled: !plans.length },
							{ tabName: translate('plans.tab.4g.5g'), disabled: !additionalPlans.length },
						]}
						onSelectTabIndex={(index) => {
							setCurrentTab(index);
						}}
						currentTab={currentTab}
					/>
					<Container>
						<BusyIndicator listener={loading ? CART_LOADER_PLANS_BI : ''}>
							<>
								<PlanComparison
									plans={plansToCompare}
									onPlanSelection={handlePlanSelection}
									currentPlanIsHBB
									currentPlanIsPrepaid
									hbbWfbbProcess={true}
								/>
								<Divider marginBottom={48} withoutLine />
							</>
						</BusyIndicator>
					</Container>
				</>
			)}
		/>
	);
};

export default Plans;
