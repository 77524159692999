import React, { FunctionComponent, useEffect, useState } from 'react';
import useTranslate from '@Utils/hooks/useTranslate';
import { useSelector } from 'react-redux';
import externalLinks from '@Utils/externalLinks/externalLinks';
import { IMenuContent } from '@ApiModels/megaMenuContent';
import { ItemsWrapper, LeftContainer, LeftSectionWrapper, RightContainer } from '@Components/MegaMenu/MegaMenu.styled';
import MegaMenuMultilevelItem, {
	MegaMenuMultilevelItemType,
} from '@Components/MegaMenuMultilevelItem/MegaMenuMultilevelItem';
import ConditionalRender from '@Components/ConditionalRender/ConditionalRender';
import { StoreWrapper } from '@Components/MegaMenu/subcomponents/Services/MegaMenuServices.styled';
import Divider from '@Components/Divider/Divider';
import { RIGHT_DIVIDER_MARGIN_BOTTOM } from '@Components/MegaMenu/MegaMenu';
import { matchScreen } from '@Utils/layout/layoutSupport';

interface IMegaMenuMobileProps {
	mobileMenu: boolean;
	isMd: boolean;
	activeMenuIndex: number;
	setActiveMenuIndex: (index: number) => void;
}

const MegaMenuServices: FunctionComponent<IMegaMenuMobileProps> = ({
	mobileMenu,
	isMd,
	activeMenuIndex,
	setActiveMenuIndex,
}) => {
	const [content, setContent] = useState<IMenuContent>();
	const language = useSelector((state: types.redux.IState) => state.settings.language);
	const { translate } = useTranslate();
	const isSmallDevice = matchScreen(['xs', 'sm']);

	useEffect(() => {
		return () => {
			setActiveMenuIndex(-1);
		};
	}, []);

	useEffect(() => {
		setContent({
			rows: [
				{
					label: translate('menu.services.item.5g'),
					link: externalLinks('PERSONAL_SERVICES'),
					children: [],
				},
				{
					label: translate('menu.services.item.mobile-app'),
					link: externalLinks('PERSONAL_MOBILE_APP'),
					children: [],
				},
				{
					label: translate('menu.services.item.value-added-services'),
					link: externalLinks('VALUE_ADDED_SERVICES'),
					children: [],
				},
				{
					label: translate('menu.services.item.auction-number'),
					link: externalLinks('NUMBER_AUCTION'),
					children: [],
				},
				{
					label: translate('menu.services.item.other-services'),
					link: externalLinks('PERSONAL'),
					children: [
						{
							label: translate('menu.services.item.call-me-back'),
							link: externalLinks('CALL_ME_BACK'),
						},
						{
							label: translate('menu.services.item.emergency-services'),
							link: externalLinks('EMERGENCY_SERVICES'),
						},
						{
							label: translate('menu.services.item.voice-mail'),
							link: externalLinks('VOICE_MAIL'),
						},
						{
							label: translate('menu.services.item.family-&-friends'),
							link: externalLinks('FAMILY_AND_FRIENDS'),
						},
						{
							label: translate('menu.services.item.money-transfer'),
							link: externalLinks('MONEY_TRANSFER'),
						},
						{
							label: translate('menu.services.item.Hayyak-Internet-Gifting'),
							link: externalLinks('HAYYAK_INTERNET_GIFTING'),
						},
						{
							label: translate('menu.services.item.dcb-netflix'),
							link: externalLinks('DCB_NETFLIX'),
						},
						{
							label: translate('menu.services.item.permit-services'),
							link: externalLinks('PERMIT_SERVICE'),
						},
					],
				},
				{
					label: translate('menu.services.item.get-our-services'),
					link: externalLinks('GET_OUR_SERVICES'),
					children: [],
				},
			],
		});
	}, [language]);

	return (
		<StoreWrapper mobileMenu={mobileMenu} isMd={isMd}>
			<ConditionalRender
				show={activeMenuIndex < 0 || !isSmallDevice}
				onTrue={() => (
					<LeftContainer mobileMenu={mobileMenu} isMd={isMd} isSmallDevice={isSmallDevice}>
						<>
							<MegaMenuMultilevelItem
								type={MegaMenuMultilevelItemType.HEADER}
								name={translate('menu.item.services')}
								iconRight="arrowRightBox"
							/>
							{content?.rows?.map((row, index) => (
								<LeftSectionWrapper key={index}>
									<ItemsWrapper mobileMenu={mobileMenu} key={row.label}>
										<MegaMenuMultilevelItem
											key={index}
											type={
												row?.children?.length > 0
													? MegaMenuMultilevelItemType.ITEM
													: MegaMenuMultilevelItemType.LINK
											}
											onClick={() => {
												row?.children?.length > 0 && setActiveMenuIndex(index);
											}}
											to={row.link}
											name={row.label}
											paddingLeft={16}
											active={activeMenuIndex === index}
											iconRight={row?.children?.length > 0 ? 'arrowRightBox' : undefined}
										/>
									</ItemsWrapper>
								</LeftSectionWrapper>
							))}
						</>
					</LeftContainer>
				)}
			/>
			<ConditionalRender
				show={activeMenuIndex >= 0}
				onTrue={() => (
					<RightContainer mobileMenu={mobileMenu} isMd={isMd} isSmallDevice={isSmallDevice}>
						<ConditionalRender
							show={!isSmallDevice}
							onTrue={() => <Divider marginBottom={RIGHT_DIVIDER_MARGIN_BOTTOM} withoutLine />}
						/>
						{content?.rows[activeMenuIndex]?.children?.map(({ label, link }) => (
							<MegaMenuMultilevelItem
								paddingLeft={16}
								key={label}
								type={MegaMenuMultilevelItemType.SUB_ITEM}
								to={link}
								name={label}
							/>
						))}
					</RightContainer>
				)}
			/>
		</StoreWrapper>
	);
};

export default MegaMenuServices;
