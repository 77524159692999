import React, { FunctionComponent, useEffect, useState } from 'react';
import Icon from '@Components/Icon/Icon';
import { ActionWrapper, CardWrapper, TitleWrapper, CardHeader } from './PickupBooking.styled';
import { TitleBold } from '@Components/Typography/Typography.styled';
import { setModal } from '@Redux/modules/modal/actions';
import { useDispatch } from 'react-redux';
import BusyIndicator from '@Components/BusyIndicator/BusyIndicator';
import Divider from '@Components/Divider/Divider';
import DateTimePicker from '@Components/DateTimePicker/DateTimePicker';
import { addBusyIndicator, removeBusyIndicator } from '@Redux/modules/busyIndicator/actions';
import { AppointmentService } from '@Services/outletAppointment/outletAppointment';
import useTranslate from '@Utils/hooks/useTranslate';

const GET_PICKUP_BOOKING_DATA = 'GET_PICKUP_BOOKING_DATA';
const ACTION_CLOSE_ICON_SIZE = 24;
const PICKUP_OUTLET_BOOKING_SERVICE_ID = '2703';

const PickupBooking: FunctionComponent<types.cards.IOutletPickupCardProps> = ({
	params: { selectedOutletId, disabledDates, bookingPickupAppointment, cancelCurrentAppointment, bookNewAppointment },
}) => {
	const dispatch = useDispatch();
	const [availableDays, setAvailableDays] = useState<string[]>([]);
	const [availableHours, setAvailableHours] = useState<string[]>([]);
	const { translate } = useTranslate();

	const handleClose = () => {
		dispatch(
			setModal({
				type: null,
				data: null,
			})
		);
	};

	const getHours = (date: string, isFindingAvailableDay?: boolean) => {
		let daysIterator = 0;
		const payloadForGetHours = {
			branchId: selectedOutletId,
			serviceId: PICKUP_OUTLET_BOOKING_SERVICE_ID,
			startDate: date,
		};
		AppointmentService.getHours(payloadForGetHours).subscribe((response) => {
			if (response?.returnValue) {
				if (!isFindingAvailableDay) {
					setAvailableHours(response?.returnValue);
				} else {
					if (response?.returnValue.length > 0) {
						daysIterator = 0;
						const newAvailableDaysIndex = availableDays.findIndex((availableDay) => {
							return availableDay === date;
						});
						if (newAvailableDaysIndex > -1) setAvailableDays(availableDays.slice(newAvailableDaysIndex));
					} else {
						const newDaysItreator = daysIterator++;
						if (availableDays[newDaysItreator]) {
							getHours(availableDays[newDaysItreator], true);
						}
					}
				}
				() => {
					handleClose();
				};
			}
		});
	};

	const findFirstAvailableAppointment = () => {
		getHours(availableDays[0], true);
	};

	useEffect(() => {
		dispatch(addBusyIndicator(GET_PICKUP_BOOKING_DATA));
		const payloadForGetDays = {
			branchId: selectedOutletId,
			serviceId: PICKUP_OUTLET_BOOKING_SERVICE_ID,
		};
		AppointmentService.getDates(payloadForGetDays).subscribe(
			(response) => {
				if (response?.returnValue) {
					setAvailableDays(response.returnValue);
					dispatch(removeBusyIndicator(GET_PICKUP_BOOKING_DATA));
				} else handleClose();
			},
			() => {
				handleClose();
			}
		);
	}, []);

	return (
		<CardWrapper>
			<CardHeader>
				<TitleWrapper marginLeft={ACTION_CLOSE_ICON_SIZE}>
					<TitleBold>{translate('pickup-from-outlet.booking-modal.title')}</TitleBold>
				</TitleWrapper>
				<ActionWrapper onClick={handleClose}>
					<Icon width={ACTION_CLOSE_ICON_SIZE} height={ACTION_CLOSE_ICON_SIZE} name="close" fill="black87" />
				</ActionWrapper>
			</CardHeader>
			<Divider marginBottom={16} />
			<BusyIndicator listener={GET_PICKUP_BOOKING_DATA}>
				<DateTimePicker
					availableDays={availableDays}
					availableHours={availableHours}
					getHours={getHours}
					findFirstAvailableAppointment={findFirstAvailableAppointment}
					disabledDates={disabledDates}
					bookingPickupAppointment={bookingPickupAppointment}
					cancelCurrentAppointment={cancelCurrentAppointment}
					bookNewAppointment={bookNewAppointment}
				/>
			</BusyIndicator>
		</CardWrapper>
	);
};

export default PickupBooking;
