import styled from 'styled-components';

interface IPlanMenuItemProps {
	padding?: number;
}

export const MenuItem = styled.div<IPlanMenuItemProps>`
	${({ padding }) => ({
		padding: `${padding}px 0`,
	})}
	:not(:last-child) {
		${({ theme }) => ({
			borderBottom: `1px solid ${theme.colors.black12}`,
		})}
	}
	text-align: center;
`;
