import { IPersonalData, setPersonalData } from './actions';
import immerHandleActions from '@Redux/utils/immerHandleActions';

const init = {
	bills: undefined,
	usage: undefined,
	usageData: undefined,
	makasibPoints: '0',
	expiryMakasibPoints: '0',
	nationality: undefined,
	creditRating: undefined,
};

const reducers = {
	[setPersonalData.toString()](draft: IPersonalData, action: { payload: IPersonalData }) {
		const {
			bills,
			usage,
			usageData,
			makasibPoints,
			expiryMakasibPoints,
			nationality,
			creditRating,
		} = action.payload;
		if (bills) {
			draft.bills = bills;
		}
		if (usage) {
			draft.usage = usage;
		}
		if (usageData) {
			draft.usageData = usageData;
		}
		if (makasibPoints) {
			draft.makasibPoints = makasibPoints;
		}
		if (expiryMakasibPoints) {
			draft.expiryMakasibPoints = expiryMakasibPoints;
		}
		if (nationality) {
			draft.nationality = nationality;
		}
		if (creditRating) {
			draft.creditRating = creditRating;
		}
		return draft;
	},
};

export default immerHandleActions(reducers, init);
