import { ActionFunction1, createAction } from 'redux-actions';

interface IRequestInProgressAction extends types.redux.api.requestInProgress.IRequestInProgressAction {}

export const pushRequestInProgress: ActionFunction1<any, any> = createAction(
	'REQUEST_IN_PROGRESS_PUSH',
	(action: IRequestInProgressAction) => action
);

export const pullRequestInProgress: ActionFunction1<any, any> = createAction(
	'REQUEST_IN_PROGRESS_PULL',
	(action: IRequestInProgressAction) => action
);
