import styled from 'styled-components';
import PriceTag from '@Components/PriceTag/PriceTag';
import {
	marginLeft,
	marginRight,
	paddingRight,
	paddingLeft,
	matchScreen,
	flexDirection,
} from '@Utils/layout/layoutSupport';
import {
	BodyS,
	BodySBold,
	Caption,
	BodyMBold,
	SmallCurrencyVat,
	ValueBold,
	BodyM,
} from '@Components/Typography/Typography';
import { Col } from 'react-grid-system';

export const CartItemContainer = styled.div`
	margin-top: 16px;
	padding: 0;
`;

export const PriceWrapper = styled(PriceTag)`
	align-self: flex-end;
`;

export const Thumbnail = styled.img`
	width: 100%;
	max-width: 52px;
`;

export const NameWrapper = styled(BodyMBold)`
	${({ theme }) => ({
		color: theme.colors.black87,
	})}
	width:100%;
`;

export const DetailsWrapper = styled(BodyS)`
	${({ theme }) => ({
		color: theme.colors.black54,
	})}
	margin-top: 4px;
`;

export const CarInsuranceDetails = styled(BodyS)`
	${({ theme }) => ({
		color: theme.colors.black54,
	})}
	margin-top: 4px;
`;

export const BuyPrepaidDetails = styled(BodyS)`
	${({ theme }) => ({
		color: theme.colors.black54,
	})}
	margin-top: 4px;
`;

export const VerticalDivider = styled.div`
	display: inline-block;
	width: 1px;
	height: 16px;
	margin: 0 8px;
	margin-top: 4px;
	border-radius: 2px;
	background-color: ${({ theme }) => theme.colors.black24}};
`;

export const EmptyLine = styled.div`
	width: 16px;
	height: 2px;
	border-radius: 2px;
	${({ theme }) => ({ backgroundColor: theme.colors.black38 })}
`;

export const QuantityWrapper = styled.div`
	padding: 16px 0;
`;

export const Minus = styled.div<{ disabled?: boolean }>`
	width: 8px;
	height: 1px;
	border-radius: 2px;
	margin-top: -1px;
	${({ theme, disabled }) => ({
		backgroundColor: theme.colors[disabled ? 'black38' : 'white'],
	})}
`;

export const RemoveItemWrapper = styled(BodySBold)`
	${({ theme }) => ({
		color: theme.colors.primary,
	})}
	margin-top: 12px;
	text-decoration: underline;
	cursor: pointer;
`;

export const OpenPlanDetailsWrapper = styled(BodySBold)`
	${({ theme }) => ({
		color: theme.colors.primary,
	})}
	${() => marginRight(24)}
	margin-top: 12px;
	text-decoration: underline;
	cursor: pointer;
`;

export const OpenPlanDetailsPrepaidWrapper = styled(BodySBold)`
	${({ theme }) => ({
		color: theme.colors.primary,
	})}
	${() => marginRight(24)}
	margin-top: 10px;
	margin-bottom: 20px;
	text-decoration: underline;
	cursor: pointer;
`;

export const CaptionWrapper = styled(Caption)`
	${({ theme }) => ({
		color: theme.colors.black87,
	})}
`;

export const ItemDescriptionWrapper = styled.div`
	display: flex;
	flex-wrap: wrap;
	align-items: start;
	flex-direction: column;
	width: calc(100% - 46px);
`;

export const PlanTitleWrapper = styled.div`
	width: 100%;
	display: flex;
`;

export const TitleWrapper = styled.div`
	${() => ({
		maxWidth: '100%',
	})}
`;

export const ItemTitle = styled(BodyMBold)``;

export const ItemDescription = styled(BodyS)`
	${({ theme }) => ({
		color: theme.colors.black54,
	})}
	padding-top:4px;
`;

export const IconWrapper = styled.div`
	width: 10%;
	margin-top: -1px;
`;

export const PriceContainer = styled.div<{ isSmallScreen: boolean }>`
	${(isSmallScreen) => ({
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-end',
		marginRight: isSmallScreen ? '-28px' : '0px',
	})}
`;

export const TitleContainer = styled.div`
	${() => ({
		flexDirection: 'row',
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		marginTop: '-10px',
		marginRight: '-16px',
	})}
`;

export const DetailsContainer = styled.div<{ displayColumn: boolean }>`
	${({ displayColumn }) => ({
		flexDirection: displayColumn ? 'column' : 'row',
		display: 'flex',
	})}
`;

export const PriceRow = styled.div`
	${() => {
		const isXs = matchScreen(['xs', 'sm']);
		return {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'space-between',
		};
	}}
	${() => flexDirection('row')}
	margin-top: 16px;
`;
export const ButtonWrapper = styled.div`
	display: flex;
`;

export const QtyWrapper = styled.div`
	padding: 16px;
`;

export const VatWrapper = styled.div`
	align-items: flex-end;
	justify-content: flex-end;
	display: flex;
	flex-direction: column;
	${() => marginRight(matchScreen(['xs', 'sm']) ? 20 : 24)}
`;

export const VatPriceAndPercentage = styled.div`
	align-items: flex-end;
	justify-content: flex-end;
	display: flex;
	flex-direction: row;
	align-self: flex-end;
	justify-self: flex-end;
	${({}) => ({
		width: matchScreen(['xs', 'sm']) ? '130%' : '150%',
	})}
	gap: 2px;
`;

export const Vat = styled(SmallCurrencyVat)`
	${({ theme }) => ({
		color: theme.colors.black54,
	})}
	${() => marginLeft(2)}
`;

export const AccordionsContainer = styled.div`
	border-radius: 8px;
	margin-top: 8px;
	${({ theme }) => ({
		backgroundColor: theme.colors.grey50,
	})}
	${() => marginLeft(matchScreen(['xs', 'sm']) ? 0 : matchScreen(['lg', 'md']) ? 48 : 64)}
	${() => marginRight(-16)}
`;

export const AccordionContentWrapper = styled.div`
	padding-bottom: 16px;
	${() => paddingRight(16)}
	${() => paddingLeft(64)}
`;

export const SectionHeader = styled(ValueBold)`
	padding-bottom: 7px;
	${({ theme }) => ({
		color: theme.colors.black54,
	})}
`;

export const SectionContent = styled(Caption)`
	${({ theme }) => ({
		color: theme.colors.black54,
	})}
`;

export const PlanInfoContent = styled(BodyM)`
	${({ theme }) => ({
		color: theme.colors.black54,
	})}
	${() => paddingLeft(16)}
`;

export const PlanInfo = styled.div`
	border-radius: 8px;
	margin-top: 16px;
	margin-bottom: 8px;
	padding: 8px 16px;
	${({ theme }) => ({
		backgroundColor: theme.colors.grey50,
		display: 'flex',
		alignItems: 'center',
	})}
	${() => marginLeft(matchScreen(['xs', 'sm']) ? 0 : 64)}
`;

export const MessageBarCol = styled(Col)`
	margin-top: 16px;
	padding-right: 0 !important;
`;

export const SeeCode = styled(BodySBold)`
	text-decoration: underline;
	cursor: pointer;
	width: fit-content;
	${({ theme }) => ({
		color: theme.colors.primary,
	})}
`;

export const CarInsuranceDetailsWraper = styled.div`
	display: flex;
`;

export const BuyPrepaidDetailsWraper = styled.div<{ smallScreen?: boolean }>`
	display: flex;
	${({ smallScreen }) => ({
		flexDirection: smallScreen ? 'column' : 'row',
	})}
`;
