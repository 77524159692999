import Card from '@Components/Card/Card';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { TileSize } from '@Components/Card/cards/ProductCard/ProductCard.styled';
import { IItem } from '@ApiModels/productsByCategoryId';
import {
	IconWrapper,
	TitleWrapper,
	UpSellProductsContainer,
	UpSellProductsWrapper,
	UpSellCartCardWrapper,
} from '@Components/Card/cards/UpSellCartCard/UpSellCartCard.styled';
import Icon from '@Components/Icon/Icon';
import useTranslate from '@Utils/hooks/useTranslate';
import BusyIndicator from '@Components/BusyIndicator/BusyIndicator';
import { GET_RELATED_PRODUCTS_BI } from '@Views/ProductPage/ProductPage';

const ICON_SIZE = 24;

const UpSellCartCard: FunctionComponent<types.cards.IUpSellCartCardProps> = ({ params: { relatedProducts } }) => {
	const [visibleProducts, setVisibleProducts] = useState<IItem[]>([]);
	const [visibleProductsQuantity] = useState(3);
	const [visibleProductsIndex, setVisibleProductsIndex] = useState(0);
	const { translate } = useTranslate();

	const getVisibleProducts = (index: number) => {
		const newVisibleProducts = [...relatedProducts].slice(index, visibleProductsQuantity + index);
		setVisibleProducts(newVisibleProducts);
	};

	useEffect(() => {
		getVisibleProducts(visibleProductsIndex);
	}, [visibleProductsIndex, relatedProducts.length]);

	const getProductItems = () => {
		return visibleProducts.map((product, index) => {
			return (
				<UpSellProductsWrapper key={index}>
					<Card<types.cards.IProductCardProps>
						card="product"
						params={{
							product,
							tileSize: TileSize.REGULAR,
							iconsHidden: true,
							layoutChange: false,
							isRelatedProduct: true,
							inventory: product?.inventory,
						}}
						marginTop={0}
						marginBottom={10}
					/>
				</UpSellProductsWrapper>
			);
		});
	};

	return (
		<UpSellCartCardWrapper>
			<TitleWrapper>{translate('apple.upSell.title')}</TitleWrapper>
			<UpSellProductsContainer>
				<BusyIndicator listener={GET_RELATED_PRODUCTS_BI} skeleton="upSellCart">
					<>
						<IconWrapper>
							<Icon
								name="chevronLeft"
								fill={visibleProductsIndex === 0 ? 'black12' : 'black'}
								width={ICON_SIZE}
								height={ICON_SIZE}
								onClick={() => {
									if (visibleProductsIndex > 0) setVisibleProductsIndex(visibleProductsIndex - 1);
								}}
							/>
						</IconWrapper>
						{getProductItems()}
						<IconWrapper>
							<Icon
								name="chevronRight"
								fill={
									visibleProductsIndex === relatedProducts.length - visibleProductsQuantity &&
									visibleProducts.length >= visibleProductsQuantity - 1
										? 'black12'
										: 'black'
								}
								width={ICON_SIZE}
								height={ICON_SIZE}
								onClick={() => {
									if (visibleProductsIndex < relatedProducts.length - visibleProductsQuantity)
										setVisibleProductsIndex(visibleProductsIndex + 1);
								}}
							/>
						</IconWrapper>
					</>
				</BusyIndicator>
			</UpSellProductsContainer>
		</UpSellCartCardWrapper>
	);
};

export default UpSellCartCard;
