import React, { FunctionComponent } from 'react';
import {
	ChipWrapper,
	ChipContent,
	Label,
	Price,
	Currency,
	Region,
	IconWrapper,
	RegionWrapper,
	MarkChipWrapper,
} from './MarkChip.styled';
import Icon from '@Components/Icon/Icon';
import ConditionalRender from '@Components/ConditionalRender/ConditionalRender';

interface ITagChipProps {
	label: string[] | string;
	disabled: boolean;
	iconName?: types.iconNames;
	labelClickable?: boolean;
	onClick?: () => void;
	type: string;
}

const MarkChip: FunctionComponent<ITagChipProps> = ({
	label,
	type,
	disabled,
	iconName,
	onClick,
	labelClickable,
}: ITagChipProps) => {
	return (
		<>
			<ConditionalRender
				show={Array.isArray(label)}
				onTrue={() => (
					<MarkChipWrapper labelClickable>
						<ChipWrapper disabled={disabled} onClick={onClick} type={type}>
							<Price>{label[0]}</Price>
							<ConditionalRender show={!!label[1]} onTrue={() => <Currency>{label[1]}</Currency>} />
						</ChipWrapper>

						<RegionWrapper onClick={onClick}>
							<Region>{label[2]}</Region>
							{!!iconName && (
								<IconWrapper disabled={disabled} type={type}>
									<Icon name={iconName} fill="white" height={16} width={16} />
								</IconWrapper>
							)}
						</RegionWrapper>
					</MarkChipWrapper>
				)}
				onFalse={() => (
					<ChipWrapper disabled={disabled} onClick={onClick} type={type}>
						<ChipContent>
							<Label>{label}</Label>
							{!!iconName && (
								<IconWrapper disabled={disabled} type={type}>
									<Icon
										name={iconName}
										fill={disabled ? 'black24' : 'white'}
										height={16}
										width={16}
									/>
								</IconWrapper>
							)}
						</ChipContent>
					</ChipWrapper>
				)}
			/>
		</>
	);
};

export default MarkChip;
