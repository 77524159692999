import styled from 'styled-components';
import { BodyM, BodyMBold, Header } from '@Components/Typography/Typography.styled';
import { isRTL, paddingLeft as layoutPaddingLeft } from '@Utils/layout/layoutSupport';

export const ItemTextWrapper = styled(BodyM)`
	text-align: justify;
	transition: color 0.1s ease-in-out;
	padding: 0 8px;
	${({ theme }) => ({
		color: theme.colors.black54,
	})};
	&:hover {
		${({ theme }) => ({
			color: theme.colors.black87,
		})};
	}
`;

export const ActiveItemTextWrapper = styled(BodyMBold)`
	text-align: justify;
	transition: color 0.1s ease-in-out;
	padding: 0 8px;
	${({ theme }) => ({
		color: theme.colors.black87,
	})};

	&:hover {
		${({ theme }) => ({
			color: theme.colors.primary,
		})};
	}
`;

export const HeaderTextWrapper = styled(Header)`
	text-align: left;
	transition: color 0.1s ease-in-out;
	padding: 0;
	${({ theme }) => ({
		color: theme.colors.black87,
	})};
	&:hover {
		${({ theme }) => ({
			color: theme.colors.primary,
		})};
	}
`;

export const IconWrapper = styled.div<types.IStyledProps & { rotate: boolean }>`
	${({ rotate }) => isRTL() && rotate && 'transform: rotate(180deg)'}
`;

export const ItemWrapper = styled.button<{ paddingTop: number; paddingLeft: number; isButton: boolean }>`
	display: flex;
	align-items: center;
	padding: 8px;
	${({ paddingTop }) => ({ paddingTop })}
	${({ paddingLeft = 0 }) => layoutPaddingLeft(paddingLeft)}
	border: none;
	background-color: transparent;
	${({ isButton, theme }) => (isButton ? `cursor: pointer;` : `& > p { color: ${theme.colors.primary} }`)}
	outline: none;
`;
