export interface ICalculateInstallemntRequest {
	accountNo?: string;
	emfConfigId?: string;
	orderType?: string;
	subscriptionType?: string;
	packageGroup?: string;
	productId?: string;
	serviceCode?: string;
	tariffType?: string;
	commitmentAmount?: string;
	upfrontCollection?: string;
	commitmentDuration?: string;
	installmentDuration?: string;
}

export interface ICalculateInstallemntResponse {
	calculatedProduct?: ICalculatedProduct;
}
export interface ICalculatedProduct {
	device?: IProduct;
	discount?: IProduct;
}

export interface IProduct {
	productId?: string;
	saleDetails?: ICashSaleDetails;
	deviceCommitments?: IDeviceCommitments;
}

export interface ICashSaleDetails {
	tariffAmount?: string;
	standardPrice?: string;
	vatAmount?: string;
	vatPercentage?: string;
	taxableAmount?: string;
	totalAmount?: string;
}

export interface IDeviceCommitments {
	installmentAmount?: string;
	installmentAmountBz?: string;
	installmentMonths?: string;
	upfront?: string;
	discount?: string;
	isOverrides?: string;
}

const model: types.models.IInputModel = {
	_data: 'response',
	calculatedProduct: (model: any) => {
		const deviceProduct = model?._data?.devideCorpInstallment?.products?.[0];
		const cashSaleDetails = deviceProduct?.cashSaleDetails?.[0];
		const productDiscount = model?._data?.deviceDiscount?.products?.[0];

		if (!deviceProduct || !cashSaleDetails) {
			return null;
		}

		return {
			device: {
				productId: deviceProduct?.productId,
				saleDetails: cashSaleDetails,
				deviceCommitments: deviceProduct?.deviceCommitments,
			},
			discount: {
				productId: productDiscount?.productId,
				deviceCommitments: productDiscount?.deviceCommitments,
			},
		};
	},
};

export default model;
