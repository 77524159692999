import styled from 'styled-components';
import { TitleBold } from '@Components/Typography/Typography';
import { matchScreen } from '@Utils/layout/layoutSupport';

export const CardContainer = styled.div`
	height: 100vh;
	width: 100vw;
	overflow-y: scroll;
	overflow-x: hidden;
	${() => {
		return {
			paddingTop: matchScreen('xs') ? 80 : 0,
		};
	}}
`;

export const Header = styled.div`
	margin: auto;
	max-width: 1100px;

	${() => {
		return {
			paddingTop: matchScreen('xs') ? 0 : 28,
			paddingBottom: matchScreen('xs') ? 0 : 60,
		};
	}}
`;

export const LogoWrapper = styled.div`
	padding-top: 12px;
	padding-bottom: 4px;
	width: fit-content;
	padding-left: 16px;
`;

export const ActionWrapper = styled.div`
	display: flex;
	justify-content: flex-end;
	padding: 12px 0;
	padding-right: 16px;
	cursor: pointer;
	width: fit-content;
`;

export const ColorPickerWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
`;

export const CarouselWrapper = styled.div<{ marginTop?: string }>`
	display: flex;
	justify-content: center;
	width: 100%;
	${({ marginTop }) => {
		return { marginTop };
	}}
`;

export const BigBackgroundTitle = styled(TitleBold)<{ padding?: string }>`
	${({ padding }) => {
		return { padding: padding ? padding : '0px' };
	}}
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	wrap: no-wrap;
	white-space: nowrap;
	font-size: 7vw;
	line-height: 140px;
`;

export const Background = styled.div<types.IStyledProps>`
	${({ theme }) => {
		return {
			backgroundColor: theme.colors.background,
			borderTopColor: theme.colors.primary,
			borderTopWidth: 2,
			borderTopStyle: 'solid',
			marginTop: -190,
			width: '100%',
			height: '34%',
		};
	}}
`;

export const ContentWrapper = styled.div<types.IStyledProps>`
	width: 100vw;
	overflow-x: hidden;
	overflow-y: hidden;
`;
