import React, { FunctionComponent } from 'react';
import { ViewType } from '@Components/Card/cards/ProductCard/ProductCard.styled';
import GridProductSkeleton from './subcomponents/GridProductSkeleton';
import ListProductSkeleton from './subcomponents/ListProductSkeleton';

interface IProductSkeletonProps {
	viewType: ViewType;
}
const ITEMS = 9;

const ProductSkeleton: FunctionComponent<IProductSkeletonProps> = ({ viewType = ViewType.GRID }) => {
	const ProductSkeletonView = viewType === ViewType.GRID ? GridProductSkeleton : ListProductSkeleton;

	return (
		<>
			<ProductSkeletonView />
			<ProductSkeletonView />
			<ProductSkeletonView />
			<ProductSkeletonView />
			{[...Array(Math.floor(Math.random() * Math.floor(ITEMS)))].map((_, index) => {
				return <ProductSkeletonView key={index} />;
			})}
		</>
	);
};
export default ProductSkeleton;
