import RxQuery from '@Utils/api/rxQuery/rxQuery';
import endpoints from '@Utils/api/endpoints';

const omantelPortal = () => new RxQuery('omantelPortal');

export const getProductSuggestionsBySearchTermFromPortal = (
	searchTerm: string,
	results: number,
	scope: string
): RxQuery => {
	return omantelPortal()
		.setEndpoint(
			endpoints.omantelPortal.getProductSuggestionsBySearchTermFromPortal({ searchTerm, results, scope })
		)
		.setResponseType('document');
};
