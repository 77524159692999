import { isLargeScreen, matchScreen } from '@Utils/layout/layoutSupport';
import styled from 'styled-components';
import { NAVBAR_HEIGHT } from '@Layouts/Dashboard/Dashboard';

export const LoaderContainer = styled.div`
	position: absolute;
	left: 50%;
	width: 100%;
	height: 100%;
	top: 50%;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
`;

export const BnplIframe = styled.iframe`
	width: 100%;
	frameborder: 0;
	${() => {
		const isSmallDevice = matchScreen(['xs', 'sm']);
		return {
			height: 660,
			maxHeight: `calc(100vh - ${NAVBAR_HEIGHT - (isLargeScreen() ? 0 : isSmallDevice ? 56 : 12)})`,
		};
	}}
`;
