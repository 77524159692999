import { Observable } from 'rxjs';
import api from '@Utils/api';
import { IResponseResult as IResponseResultCreateTenant, ITenant } from '@ApiModels/createTenant';
import { IResponseResult as IResponseResultGetTenant } from '@ApiModels/getTenant';
import { IResponseResultGetTenantFromOrdersHistory } from '@ApiModels/getTenantFromOrdersHistory';

export class MicrosoftTenantService {
	public static getTenant(
		tenantId: string,
		tenantName: string,
		orderId: string
	): Observable<IResponseResultGetTenant> {
		return api.omantelApi.getTenant(tenantId, tenantName, orderId).disableCache().call();
	}
	public static createTenant(orderId: string, tenantData: ITenant): Observable<IResponseResultCreateTenant> {
		return api.omantelApi.createTenant(orderId, tenantData).disableCache().call();
	}
	public static changeTenantProvider(orderId: string): Observable<IResponseResultGetTenant> {
		return api.omantelApi.changeTenantProvider(orderId).disableCache().call();
	}
	public static getTenantFromOrdersHistory(orderId: string): Observable<IResponseResultGetTenantFromOrdersHistory> {
		return api.omantelApi.getTenantFromOrdersHistory(orderId).disableCache().call();
	}
}
