import { createAction } from 'redux-actions';

export interface IAddressBook {
	activeAddressBook?: string;
	newAddressBook?: string;
	refreshAddressBook?: boolean;
	outletPickupFocus?: boolean;
}

export const setActiveAddressBook = createAction('SET_ACTIVE_ADDRESS_BOOK', (action: IAddressBook) => action);
export const setNewAddressBook = createAction('SET_NEW_ADDRESS_BOOK', (action: IAddressBook) => action);
export const setRefreshAddressBook = createAction('SET_REFRESH_ADDRESS_BOOK', (action: IAddressBook) => action);
export const setOutletPickupFocus = createAction('SET_OUTLET_PICKUP_FOCUS', (action: IAddressBook) => action);
