import { CATALOG_ID, CONTRACT_ID, STORE_ID } from '@Config/app.config';
import { getLanguageCode } from '@Utils/language/language';

export const getNestedCategories = (): types.endpoints.IEndpointConfig => {
	return {
		url: `search/resources/api/v2/categories?contractId=${CONTRACT_ID}&depthAndLimit=11%2C11&storeId=${STORE_ID}&langId=${getLanguageCode()}&catalogId=${CATALOG_ID}`,
	};
};

export const getFlatCategories = (): types.endpoints.IEndpointConfig => {
	return {
		url: `search/resources/api/v2/categories?contractId=${CONTRACT_ID}&depthAndLimit=*&storeId=${STORE_ID}&langId=${getLanguageCode()}&catalogId=${CATALOG_ID}`,
	};
};
