import { Tabs } from '@Components/Typography/Typography.styled';
import styled from 'styled-components';

export const TabSelectorContainer = styled.div`
	position: relative;
	${({ theme }) => ({
		borderBottom: `1px solid ${theme.colors.background}`,
	})}
	margin-bottom: 40px;
`;

export const TabItem = styled.div<{ disabled: boolean }>`
	padding-left: 16px;
	padding-right: 16px;
	${({ disabled }) => ({ cursor: disabled ? 'default' : 'pointer' })}
`;

export const TabText = styled(Tabs)<{ active: boolean; disabled: boolean }>`
	${({ theme, active, disabled }) => ({
		color: theme.colors[disabled ? 'black38' : active ? 'primary' : 'black87'],
	})}
`;

export const TabsContainer = styled.div`
	display: flex;
	padding-bottom: 16px;
`;

export const CurrentLine = styled.div<{ left?: number; width?: number }>`
	position: absolute;
	transition: all 0.3s ease-in-out;
	${({ theme, left, width }) => ({
		backgroundColor: theme.colors.primary,
		height: '2px',
		bottom: '-1px',
		left,
		width,
	})}
`;
