import React, { FunctionComponent } from 'react';
import { IconsWrapper } from '@Components/Card/cards/ProductCard/subcomponents/IconsSection/IconsSection.styled';
import { IItem } from '@ApiModels/productsByCategoryId';
import { useDispatch, useSelector } from 'react-redux';
import { addToCompare, removeFromCompare } from '@Redux/modules/comparison/actions';
import { CompareCheckboxContainer } from '../ActionSection/ActionSection.styled';
import Checkbox from '@Components/controls/Checkbox/Checkbox';
import { CheckboxShape } from '@Components/controls/Checkbox/Checkbox.styled';
import useTranslate from '@Utils/hooks/useTranslate';

interface IIconsSectionProps {
	product: IItem;
	marginRight?: number;
}

const IconsSection: FunctionComponent<IIconsSectionProps> = ({ marginRight, product }) => {
	const { translate } = useTranslate();
	const dispatch = useDispatch();
	const isOnList = !!useSelector((state: types.redux.IState) => state.comparisonList).find(
		(item) => item.id === product.uniqueID
	);

	const onPressComparison = () => {
		if (isOnList) {
			dispatch(removeFromCompare({ id: product.uniqueID }));
		} else {
			dispatch(addToCompare({ thumbnailUrl: product.thumbnail, id: product.uniqueID }));
		}
	};

	return (
		<IconsWrapper marginRight={marginRight}>
			<CompareCheckboxContainer
				onClick={(e) => {
					e.preventDefault();
					e.stopPropagation();
				}}
			>
				<Checkbox
					active={isOnList}
					shape={CheckboxShape.SQUARE}
					onChange={onPressComparison}
					size={22}
					label={translate('comparison.compare')}
					labelClickable
					reverseLabel
				/>
			</CompareCheckboxContainer>
			{/* <SingleIconWrapper>
				<Icon name="heartEmpty" fill="black54" height={24} width={24} />
			</SingleIconWrapper> */}
		</IconsWrapper>
	);
};

export default IconsSection;
