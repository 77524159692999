import { ICartItem } from '@ApiModels/viewCart';

const vatCalculate = (item: ICartItem): number => {
	if (item.plan?.PA === 'BUY_WFBB') {
		return Number(item.plan?.planVatValue ?? item.vat) * Number(item.quantity) ?? 0;
	} else {
		return Number(item.plan?.vatValue ?? item.vat) * Number(item.quantity) ?? 0;
	}
};
export { vatCalculate };
