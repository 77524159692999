import React, { FunctionComponent } from 'react';
import {
	ItemWrapper,
	LinkItem,
	NumberItem,
	TextItem,
} from '@Components/Footer/subcomponents/FooterItem/FooterItem.styled';
import ConditionalRender from '@Components/ConditionalRender/ConditionalRender';
import { FooterLink } from '@Components/Footer/subcomponents/FooterLink/FooterLink.styled';
import Icon from '@Components/Icon/Icon';

interface IFooterItemProps {
	label: string;
	number?: string;
	themeColor: types.theme.themeColors;
	typography?: keyof types.ITypography;
	to?: string;
	isExternal?: boolean;
	marginBottom?: number;
	justifyContent?: types.justifyContent;
	leftIcon?: types.iconNames;
}

const ICON_SIZE = 16;

const FooterItem: FunctionComponent<IFooterItemProps> = ({
	typography = 'paragraphS',
	marginBottom,
	to,
	isExternal,
	label,
	themeColor = 'white',
	justifyContent = 'center',
	leftIcon,
	number,
}) => {
	return (
		<ItemWrapper marginBottom={marginBottom} justifyContent={justifyContent}>
			<ConditionalRender
				show={!!leftIcon}
				onTrue={() => <Icon height={ICON_SIZE} width={ICON_SIZE} name={leftIcon} />}
			/>
			<ConditionalRender
				show={!!number}
				onTrue={() => (
					<NumberItem typography="bodyL" color="white">
						{number}
					</NumberItem>
				)}
			/>
			<ConditionalRender
				show={!isExternal && !!to}
				onTrue={() => (
					<LinkItem to={to || ''} typography={typography} themeColor={themeColor}>
						{label}
					</LinkItem>
				)}
				onFalse={() => (
					<ConditionalRender
						show={!!isExternal && !!to}
						onTrue={() => (
							<FooterLink href={to} themeColor={themeColor}>
								{label}
							</FooterLink>
						)}
						onFalse={() => (
							<TextItem typography={typography} themeColor={themeColor}>
								{label}
							</TextItem>
						)}
					/>
				)}
			/>
		</ItemWrapper>
	);
};

export default FooterItem;
