import React, { FunctionComponent } from 'react';
import {
	SkeletonCardContainer,
	Rectangular,
	DisplayFlex,
	SkeletonRow,
	Circle,
} from '@Components/Skeleton/Skeleton.styled';

const SegmentSelection: FunctionComponent = () => {
	const ITEM_HEIGHT = 20;

	return (
		<SkeletonCardContainer>
			<SkeletonRow marginTop={8}>
				<DisplayFlex justifyContent="center">
					<Rectangular width="60%" height={ITEM_HEIGHT} marginBottom={8} />
				</DisplayFlex>
			</SkeletonRow>
			<SkeletonRow marginTop={16}>
				<DisplayFlex justifyContent="center">
					<Circle width={ITEM_HEIGHT * 1.5} height={ITEM_HEIGHT * 1.5} />
				</DisplayFlex>
			</SkeletonRow>
			<SkeletonRow marginTop={16}>
				<DisplayFlex justifyContent="center">
					<Rectangular width="75%" height={ITEM_HEIGHT * 0.6} marginBottom={8} />
				</DisplayFlex>
			</SkeletonRow>
			<SkeletonRow marginTop={24}>
				<DisplayFlex justifyContent="center">
					<Rectangular width="90%" height={ITEM_HEIGHT * 3} marginBottom={8} />
				</DisplayFlex>
			</SkeletonRow>
			<SkeletonRow marginTop={8}>
				<DisplayFlex justifyContent="center">
					<Rectangular width="75%" height={ITEM_HEIGHT * 0.6} marginBottom={8} />
				</DisplayFlex>
			</SkeletonRow>
		</SkeletonCardContainer>
	);
};

export default SegmentSelection;
