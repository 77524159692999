export interface IAppointment {
	id: number;
	branchName: string;
	branchId: string;
	serviceId: string;
	serviceName: string;
	contactNumber: string;
	appointmentDate: string;
	ticketNumber: string;
}

export interface IGetAppointment {
	returnValue: IAppointment;
}

const model: types.models.IInputModel = {
	returnValue: 'response.getAppointmentResponse.appointment',
};

export default model;
