import { usePrepaidMigration } from '@Utils/context/PrepaidMigrationContext';
import { getLang } from '@Utils/language/language';
import React, { FunctionComponent, useMemo } from 'react';
import { Redirect } from 'react-router-dom';
import ChooseNumber from './ChooseNumber/ChooseNumber';
import Plans from './Plans/Plans';
import { PrepaidMigrationContainer } from './PrepaidMigration.styled';

const PrepaidMigration: FunctionComponent = () => {
	const { currentIndex } = usePrepaidMigration();

	const content = useMemo(() => {
		switch (currentIndex) {
			case 0:
				return <ChooseNumber />;
			case 1:
				return <Plans />;
			default:
				return <Redirect to={`/${getLang()}/store`} />;
		}
	}, [currentIndex]);

	return <PrepaidMigrationContainer>{content}</PrepaidMigrationContainer>;
};

export default PrepaidMigration;
