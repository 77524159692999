import modelsResolver from './utils/modelsResolver';
import { ISinglePackage } from './singlePackage';

const PLANS_SHORTCUTS_TO_FILTER = ['BQT'];

export interface IPackage {
	packages?: ISinglePackage[];
	selfActivatePackages: ISinglePackage[];
	plansToSubscribe: ISinglePackage[];
	hbbPlansToSubscribe: ISinglePackage[];
	wfbb5GPlansToSubscribe: ISinglePackage[];
	wfbb4GPlansToSubscribe: ISinglePackage[];
	wfbb4G5GPlansToSubscribe: ISinglePackage[];
}
interface IPackageEntry extends IPackage {
	_entry: string;
}

const model: types.models.IInputModel = {
	_entry: 'response.catalogEntryView',
	packages: (model: ISinglePackage) => {
		if (model._entry) {
			const packages = (model?._entry as [])?.map((singlePackage: any) =>
				modelsResolver({ entry: singlePackage }, 'singlePackage')
			);
			return packages;
		}
	},
	plansToSubscribe: (model: IPackageEntry) => {
		return model.packages?.filter(
			(singlePackage) => PLANS_SHORTCUTS_TO_FILTER.includes(singlePackage.planType) && singlePackage.selfActivate
		);
	},
	selfActivatePackages: (model: IPackageEntry) => {
		return model.packages?.filter((singlePackage) => singlePackage.selfActivate);
	},
	hbbPlansToSubscribe: (model: IPackageEntry) => {
		return model.selfActivatePackages.filter((singlePackage) => singlePackage.primaryOffer);
	},
	wfbb5GPlansToSubscribe: (model: IPackageEntry) => {
		return model.selfActivatePackages.filter((singlePackage) => singlePackage.bandWidthType === '5G');
	},
	wfbb4GPlansToSubscribe: (model: IPackageEntry) => {
		return model.selfActivatePackages.filter(
			(singlePackage) => singlePackage.bandWidthType === '4G' || singlePackage.bandWidthType === '4G5G'
		);
	},
	wfbb4G5GPlansToSubscribe: (model: IPackageEntry) => {
		return model.selfActivatePackages.filter(
			(singlePackage) => singlePackage.bandWidthType === '4G5G' || singlePackage.bandWidthType === '5G'
		);
	},
};

export default model;
