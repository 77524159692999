import React, { FunctionComponent, useEffect, useState } from 'react';
import ContentCarousel from '@Components/ContentCarousel/ContentCarousel';
import AppleLobMenu from '@Components/AppleLobMenu/AppleLobMenu';
import {
	AppleBrandStoreProductSection,
	AppleBrandStoreProductSectionTitle,
	AppleBrandStoreProductSectionButton,
	CarouselWrapper,
	CarouselRightIconWrapper,
	CarouselLeftIconWrapper,
	ShippingOptionsWrapper,
	EmptyCarouselObject,
	AppleBannerWrapper,
} from './AppleBrandStore.styled';
import { Container } from 'react-grid-system';
import useScreen from '@Utils/hooks/useScreen';
import Divider from '@Components/Divider/Divider';
import Card from '@Components/Card/Card';
import { IItem, IProductsByCategoryId } from '@ApiModels/productsByCategoryId';
import { ProductsService } from '@Services/productsService/productsService';
import { TileSize } from '@Components/Card/cards/ProductCard/ProductCard.styled';
import Icon from '@Components/Icon/Icon';
import AppleServices from '@Components/AppleServices/AppleServices';
import { addBusyIndicator, removeBusyIndicator } from '@Redux/modules/busyIndicator/actions';
import BusyIndicator from '@Components/BusyIndicator/BusyIndicator';
import { useDispatch } from 'react-redux';
import { APPLE_BRAND_STORE_CATEGORIES, isIFrameAppleWatch } from '@Config/app.config';
import { getLang } from '@Utils/language/language';
import { useHistory } from 'react-router-dom';
import useTranslate from '@Utils/hooks/useTranslate';
import { checkIItemAvailability, getInstallmentValue } from '@Utils/converters/iItemInventoryAvailability';
import { getOnlyAvailableItems } from '@Utils/converters/iItemInventoryAvailability';
import ConditionalRender from '@Components/ConditionalRender/ConditionalRender';
import { IMarketingBanners } from '@ApiModels/marketingBanners';
import { EspotService } from '@Services/espot/espot';
import { HeadlineBold } from '@Components/Typography/Typography.styled';
import { Subscription } from 'rxjs';
import { ENABLE_ESHOP_LITE } from '@Config/features.config';

const CHEVRON_ICON_SIZE = 24;
const IPHONE_INDICATOR = 'iphoneIndicator';
export const IPHONE_INSTALLMENTS_INDICATOR = 'IPHONE_INSTALLMENTS_INDICATOR';
export const IPAD_INSTALLMENTS_INDICATOR = 'IPAD_INSTALLMENTS_INDICATOR';
const APPLE_WATCH_INDICATOR = 'appleWatchIndicator';
export const APPLE_WATCH_INSTALLMENTS_INDICATOR = 'APPLE_WATCH_INSTALLMENT_INDICATOR';
const APPLE_ACCESORIES_INDICATOR = 'appleAccesoriesIndicator';
const IPAD_INDICATOR = 'ipadIndicator';
const Iphone = 'iPhone';
const Apple_Watch = 'Apple Watch';
const Airpods = 'airpods';
const AppleTV = 'apple tv';
const Ipad = 'iPad';
const GetOnlyAppleBanners = true;

const AppleBrandStore: FunctionComponent = () => {
	const { screen, screenClass } = useScreen();
	const isXsSmMd = screen(['xs', 'sm', 'md']);
	const [marketingBanners, setMarketingBanners] = useState<IMarketingBanners>({} as IMarketingBanners);
	const [iphoneProducts, setIphoneProducts] = useState<IProductsByCategoryId['items']>([]);
	const [ipadProducts, setIpadProducts] = useState<IProductsByCategoryId['items']>([]);
	const [appleWatchProducts, setAppleWatchProducts] = useState<IProductsByCategoryId['items']>([]);
	const [accessoriesProducts, setAccessoriesProducts] = useState<IProductsByCategoryId['items']>([]);
	const dispatch = useDispatch();
	const history = useHistory();
	const { translate } = useTranslate();

	const carouselRWDCapacities = () => {
		if (screen('md')) {
			return 3;
		} else if (screen(['sm', 'xs'])) {
			return 2;
		} else {
			return 4;
		}
	};

	const emptyElementsNeeded = (length: number) => {
		if (length < carouselRWDCapacities()) {
			if (carouselRWDCapacities() - length === 3) {
				return [1, 2, 3];
			} else if (carouselRWDCapacities() - length === 2) {
				return [1, 2];
			} else if (carouselRWDCapacities() - length === 1) {
				return [1];
			} else {
				return [];
			}
		} else {
			return [];
		}
	};

	useEffect(() => {
		if (!isIFrameAppleWatch) {
			dispatch(addBusyIndicator(IPHONE_INDICATOR));
			const subscription: Subscription | undefined = ProductsService.getProductsByParentCategory({
				parentCategory: APPLE_BRAND_STORE_CATEGORIES.IPHONE,
				offset: 0,
				limit: ENABLE_ESHOP_LITE ? '5' : '20',
			}).subscribe(
				({ items }) => {
					setIphoneProducts(items);
					if (!ENABLE_ESHOP_LITE) {
						dispatch(addBusyIndicator(IPHONE_INSTALLMENTS_INDICATOR));
						items.map((value, index) => {
							const fetchInstallment = async () => {
								value.productTextInstallmentValue = await getInstallmentValue(value.uniqueID);
								if (index === items.length - 1) {
									setIphoneProducts(items.filter((it) => it.uniqueID));
									dispatch(removeBusyIndicator(IPHONE_INSTALLMENTS_INDICATOR));
								}
							};
							fetchInstallment().catch(() => {
								dispatch(removeBusyIndicator(IPHONE_INSTALLMENTS_INDICATOR));
							});
						});
					}
					dispatch(removeBusyIndicator(IPHONE_INDICATOR));
				},
				() => {
					dispatch(removeBusyIndicator(IPHONE_INDICATOR));
				}
			);
			EspotService.getMarketingBanners('AppleBrandedStoreBanners', GetOnlyAppleBanners).subscribe(
				(r) => {
					if (r.banners.length > 0) {
						setMarketingBanners(r);
					}
				},
				() => {}
			);
			return () => {
				subscription?.unsubscribe();
			};
		}
	}, []);

	useEffect(() => {
		dispatch(addBusyIndicator(APPLE_WATCH_INDICATOR));
		const subscription: Subscription | undefined = ProductsService.getProductsByParentCategory({
			parentCategory: APPLE_BRAND_STORE_CATEGORIES.APPLE_WATCH,
			offset: 0,
			limit: isIFrameAppleWatch ? '25' : ENABLE_ESHOP_LITE ? '5' : '20',
		}).subscribe(
			({ items }) => {
				setAppleWatchProducts(items.filter((it) => it?.uniqueID));
				if (!ENABLE_ESHOP_LITE) {
					dispatch(addBusyIndicator(APPLE_WATCH_INSTALLMENTS_INDICATOR));
					items.map((value, index) => {
						const fetchInstallment = async () => {
							value.productTextInstallmentValue = await getInstallmentValue(value.uniqueID);
							if (index === items.length - 1) {
								setAppleWatchProducts(items.filter((it) => it.uniqueID));
								dispatch(removeBusyIndicator(APPLE_WATCH_INSTALLMENTS_INDICATOR));
							}
						};
						fetchInstallment().catch(() => {
							dispatch(removeBusyIndicator(APPLE_WATCH_INSTALLMENTS_INDICATOR));
						});
					});
				}
				dispatch(removeBusyIndicator(APPLE_WATCH_INDICATOR));
			},
			() => {
				dispatch(removeBusyIndicator(APPLE_WATCH_INDICATOR));
			}
		);
		return () => {
			subscription?.unsubscribe();
		};
	}, []);

	useEffect(() => {
		if (!isIFrameAppleWatch) {
			dispatch(addBusyIndicator(IPAD_INDICATOR));
			const subscription: Subscription | undefined = ProductsService.getProductsByParentCategory({
				parentCategory: APPLE_BRAND_STORE_CATEGORIES.IPAD,
				offset: 0,
				limit: ENABLE_ESHOP_LITE ? '5' : '20',
			}).subscribe(
				({ items }) => {
					setIpadProducts(items.filter((it) => it?.uniqueID));
					if (!ENABLE_ESHOP_LITE) {
						dispatch(addBusyIndicator(IPAD_INSTALLMENTS_INDICATOR));
						items.map((value, index) => {
							const fetchInstallment = async () => {
								value.productTextInstallmentValue = await getInstallmentValue(value.uniqueID);
								if (index === items.length - 1) {
									setIpadProducts(items.filter((it) => it.uniqueID));
									dispatch(removeBusyIndicator(IPAD_INSTALLMENTS_INDICATOR));
								}
							};
							fetchInstallment().catch(() => {
								dispatch(removeBusyIndicator(IPAD_INSTALLMENTS_INDICATOR));
							});
						});
					}
					dispatch(removeBusyIndicator(IPAD_INDICATOR));
				},
				() => {
					dispatch(removeBusyIndicator(IPAD_INDICATOR));
				}
			);
			return () => {
				subscription?.unsubscribe();
			};
		}
	}, []);

	useEffect(() => {
		if (!isIFrameAppleWatch) {
			dispatch(addBusyIndicator(APPLE_ACCESORIES_INDICATOR));
			const subscription: Subscription | undefined = ProductsService.getProductsByParentCategory({
				parentCategory: APPLE_BRAND_STORE_CATEGORIES.ACCESSORIES,
				offset: 0,
				limit: ENABLE_ESHOP_LITE ? '5' : '40',
			}).subscribe(
				({ items }) => {
					setAccessoriesProducts(items);
					dispatch(removeBusyIndicator(APPLE_ACCESORIES_INDICATOR));
				},
				() => {
					dispatch(removeBusyIndicator(APPLE_ACCESORIES_INDICATOR));
				}
			);
			return () => {
				subscription?.unsubscribe();
			};
		}
	}, []);

	const sort = (a: IItem, b: IItem) => {
		const aAvailable = checkIItemAvailability(a, true);
		const bAvailable = checkIItemAvailability(b, true);
		if (aAvailable && !bAvailable) {
			return -1;
		} else if (!aAvailable && bAvailable) {
			return 1;
		}
		return 0;
	};

	return (
		<Container fluid={isXsSmMd}>
			<ConditionalRender
				show={!isIFrameAppleWatch}
				onTrue={() => (
					<>
						<ConditionalRender
							show={
								!!marketingBanners?.banners?.find((item) =>
									item.img?.includes(`/Brand_store/${screenClass.toUpperCase()}`)
								)
							}
							onTrue={() => (
								<AppleBannerWrapper>
									<img
										src={
											marketingBanners?.banners?.find((item) =>
												item.img?.includes(`/Brand_store/${screenClass.toUpperCase()}`)
											)?.img
										}
										height="auto"
									/>
								</AppleBannerWrapper>
							)}
						/>
						<AppleLobMenu />
						<Divider />
						<AppleBrandStoreProductSection>
							<ConditionalRender
								show={screen(['xs', 'sm'])}
								onTrue={() => <HeadlineBold>{Iphone}</HeadlineBold>}
								onFalse={() => (
									<AppleBrandStoreProductSectionTitle>{Iphone}</AppleBrandStoreProductSectionTitle>
								)}
							/>
							<AppleBrandStoreProductSectionButton
								onClick={() => {
									history.push(`/${getLang()}/store/Apple`);
								}}
							>
								{translate('apple-brand-store.button.shop-all')}
							</AppleBrandStoreProductSectionButton>
						</AppleBrandStoreProductSection>
						<BusyIndicator listener={[IPHONE_INDICATOR]} skeleton="productCarousel">
							<ContentCarousel
								items={iphoneProducts
									.sort(sort)
									.filter((item, index, sortedProducts) => {
										const iphone14ProFirstOccurrenceIndex = sortedProducts.findIndex(
											(product) => product.name === 'iPhone 14 Pro'
										);
										if (index === iphone14ProFirstOccurrenceIndex) return true;
										else
											return (
												item.name !== 'iPhone 14 Pro' &&
												item.inventory &&
												(Object.keys(item.inventory).length !== 0 || item.name === 'BIMA')
											);
									})
									.map((product, index) => (
										<CarouselWrapper key={`appleLobMenuItem_ ${index}`}>
											<Card<types.cards.IProductCardProps>
												card="product"
												params={{
													inventory: product.inventory,
													tileSize: TileSize.BIG,
													preorderStartDate: product.preorderDate,
													product,
													iconsHidden: true,
												}}
												marginTop={8}
												marginBottom={8}
												showShadowOnHover
											/>
										</CarouselWrapper>
									))}
								paddingRight={50}
								disableButtons={screen(['xs', 'sm'])}
								nextButton={
									<CarouselRightIconWrapper>
										<Icon
											name="chevronRight"
											width={CHEVRON_ICON_SIZE}
											height={CHEVRON_ICON_SIZE}
											fill="primary"
										/>
									</CarouselRightIconWrapper>
								}
								prevButton={
									<CarouselLeftIconWrapper>
										<Icon
											name="chevronLeft"
											width={CHEVRON_ICON_SIZE}
											height={CHEVRON_ICON_SIZE}
											fill="primary"
										/>
									</CarouselLeftIconWrapper>
								}
							/>
						</BusyIndicator>
						<AppleBrandStoreProductSection>
							<ConditionalRender
								show={screen(['xs', 'sm'])}
								onTrue={() => <HeadlineBold>{Ipad}</HeadlineBold>}
								onFalse={() => (
									<AppleBrandStoreProductSectionTitle>{Ipad}</AppleBrandStoreProductSectionTitle>
								)}
							/>
							<AppleBrandStoreProductSectionButton
								onClick={() => {
									history.push(`/${getLang()}/store/AppleTablets`);
								}}
							>
								{translate('apple-brand-store.button.shop-all')}
							</AppleBrandStoreProductSectionButton>
						</AppleBrandStoreProductSection>
						<BusyIndicator listener={[IPAD_INDICATOR]} skeleton="productCarousel">
							<ContentCarousel
								items={ipadProducts.sort(sort).map((product, index) => (
									<CarouselWrapper key={`appleLobMenuItem_ ${index}`}>
										<Card<types.cards.IProductCardProps>
											card="product"
											params={{
												inventory: product.inventory,
												tileSize: TileSize.BIG,
												preorderStartDate: product.preorderDate,
												product,
												iconsHidden: true,
											}}
											marginTop={8}
											marginBottom={8}
											showShadowOnHover
										/>
									</CarouselWrapper>
								))}
								paddingRight={50}
								disableButtons={screen(['xs', 'sm'])}
								nextButton={
									<CarouselRightIconWrapper>
										<Icon
											name="chevronRight"
											width={CHEVRON_ICON_SIZE}
											height={CHEVRON_ICON_SIZE}
											fill="primary"
										/>
									</CarouselRightIconWrapper>
								}
								prevButton={
									<CarouselLeftIconWrapper>
										<Icon
											name="chevronLeft"
											width={CHEVRON_ICON_SIZE}
											height={CHEVRON_ICON_SIZE}
											fill="primary"
										/>
									</CarouselLeftIconWrapper>
								}
							/>
						</BusyIndicator>
						<AppleBrandStoreProductSection>
							<ConditionalRender
								show={screen(['xs', 'sm'])}
								onTrue={() => <HeadlineBold>{Apple_Watch}</HeadlineBold>}
								onFalse={() => (
									<AppleBrandStoreProductSectionTitle>
										{Apple_Watch}
									</AppleBrandStoreProductSectionTitle>
								)}
							/>{' '}
							<AppleBrandStoreProductSectionButton
								onClick={() => history.push(`/${getLang()}/store/Apple%20Watch`)}
							>
								{translate('apple-brand-store.button.shop-all')}
							</AppleBrandStoreProductSectionButton>
						</AppleBrandStoreProductSection>{' '}
					</>
				)}
			/>
			<BusyIndicator listener={[APPLE_WATCH_INDICATOR]} skeleton="productCarousel">
				<ContentCarousel
					items={(isIFrameAppleWatch
						? getOnlyAvailableItems(appleWatchProducts.sort(sort), true)
						: appleWatchProducts.sort(sort)
					).map((product, index) => (
						<CarouselWrapper key={`appleLobMenuItem_ ${index}`}>
							<Card<types.cards.IProductCardProps>
								card="product"
								params={{
									inventory: product.inventory,
									tileSize: TileSize.BIG,
									preorderStartDate: product.preorderDate,
									product,
									iconsHidden: true,
								}}
								marginTop={8}
								marginBottom={8}
								showShadowOnHover
							/>
						</CarouselWrapper>
					))}
					paddingRight={50}
					disableButtons={screen(['xs', 'sm']) && !isIFrameAppleWatch}
					nextButton={
						<CarouselRightIconWrapper>
							<Icon
								name="chevronRight"
								width={CHEVRON_ICON_SIZE}
								height={CHEVRON_ICON_SIZE}
								fill="primary"
							/>
						</CarouselRightIconWrapper>
					}
					prevButton={
						<CarouselLeftIconWrapper>
							<Icon
								name="chevronLeft"
								width={CHEVRON_ICON_SIZE}
								height={CHEVRON_ICON_SIZE}
								fill="primary"
							/>
						</CarouselLeftIconWrapper>
					}
				/>
			</BusyIndicator>
			<ConditionalRender
				show={!isIFrameAppleWatch}
				onTrue={() => (
					<>
						<AppleBrandStoreProductSection>
							<ConditionalRender
								show={screen(['xs', 'sm'])}
								onTrue={() => (
									<HeadlineBold>{translate('apple-brand-store.label.accessories')}</HeadlineBold>
								)}
								onFalse={() => (
									<AppleBrandStoreProductSectionTitle>
										{translate('apple-brand-store.label.accessories')}
									</AppleBrandStoreProductSectionTitle>
								)}
							/>
							<AppleBrandStoreProductSectionButton
								onClick={() => history.push(`/${getLang()}/store/AppleAccessories`)}
							>
								{translate('apple-brand-store.button.shop-all')}
							</AppleBrandStoreProductSectionButton>
						</AppleBrandStoreProductSection>
						<BusyIndicator listener={[APPLE_ACCESORIES_INDICATOR]} skeleton="productCarousel">
							<ContentCarousel
								items={[
									...getOnlyAvailableItems(
										accessoriesProducts.filter(
											(product) =>
												!product.name.toLowerCase().includes(Airpods) &&
												!product.name.toLowerCase().includes(AppleTV)
										),
										true
									).map((product, index) => (
										<CarouselWrapper key={`appleLobMenuItem_ ${index}`}>
											<Card<types.cards.IProductCardProps>
												card="product"
												params={{
													inventory: product.inventory,
													tileSize: TileSize.BIG,
													preorderStartDate: product.preorderDate,
													product,
													iconsHidden: true,
													showInstalment: false,
												}}
												marginTop={8}
												marginBottom={8}
												showShadowOnHover
											/>
										</CarouselWrapper>
									)),
									...emptyElementsNeeded(
										getOnlyAvailableItems(
											accessoriesProducts.filter(
												(product) =>
													!product.name.toLowerCase().includes(Airpods) &&
													!product.name.toLowerCase().includes(AppleTV)
											),
											true
										).length
									).map((item) => {
										<EmptyCarouselObject key={item}></EmptyCarouselObject>;
									}),
								]}
								paddingRight={50}
								disableButtons={
									emptyElementsNeeded(
										getOnlyAvailableItems(
											accessoriesProducts.filter(
												(product) =>
													!product.name.toLowerCase().includes(Airpods) &&
													!product.name.toLowerCase().includes(AppleTV)
											),
											true
										).length
									).length !== 0 || screen(['xs', 'sm'])
								}
								nextButton={
									<CarouselRightIconWrapper>
										<Icon
											name="chevronRight"
											width={CHEVRON_ICON_SIZE}
											height={CHEVRON_ICON_SIZE}
											fill="primary"
										/>
									</CarouselRightIconWrapper>
								}
								prevButton={
									<CarouselLeftIconWrapper>
										<Icon
											name="chevronLeft"
											width={CHEVRON_ICON_SIZE}
											height={CHEVRON_ICON_SIZE}
											fill="primary"
										/>
									</CarouselLeftIconWrapper>
								}
							/>
						</BusyIndicator>
						<ConditionalRender
							show={
								getOnlyAvailableItems(
									accessoriesProducts.filter(
										(product) =>
											product.name.toLowerCase().includes(Airpods) ||
											product.name.toLowerCase().includes(AppleTV)
									),
									true
								).length !== 0
							}
							onTrue={() => (
								<>
									<AppleBrandStoreProductSection>
										<ConditionalRender
											show={screen(['xs', 'sm'])}
											onTrue={() => (
												<HeadlineBold>
													{translate('apple-brand-store.label.more-from-apple')}
												</HeadlineBold>
											)}
											onFalse={() => (
												<AppleBrandStoreProductSectionTitle>
													{translate('apple-brand-store.label.more-from-apple')}
												</AppleBrandStoreProductSectionTitle>
											)}
										/>

										<AppleBrandStoreProductSectionButton
											onClick={() => history.push(`/${getLang()}/store/AirPods`)}
										>
											{translate('apple-brand-store.button.shop-all')}
										</AppleBrandStoreProductSectionButton>
									</AppleBrandStoreProductSection>
									<BusyIndicator listener={[APPLE_ACCESORIES_INDICATOR]} skeleton="productCarousel">
										<ContentCarousel
											items={[
												...getOnlyAvailableItems(
													accessoriesProducts.filter(
														(product) =>
															product.name.toLowerCase().includes(Airpods) ||
															product.name.toLowerCase().includes(AppleTV)
													),
													true
												).map((product, index) => (
													<CarouselWrapper key={`appleLobMenuItem_ ${index}`}>
														<Card<types.cards.IProductCardProps>
															card="product"
															params={{
																inventory: product.inventory,
																tileSize: TileSize.BIG,
																preorderStartDate: product.preorderDate,
																product,
																iconsHidden: true,
																showInstalment: false,
															}}
															marginTop={8}
															marginBottom={8}
															showShadowOnHover
														/>
													</CarouselWrapper>
												)),
												...emptyElementsNeeded(
													getOnlyAvailableItems(
														accessoriesProducts.filter(
															(product) =>
																product.name.toLowerCase().includes(Airpods) ||
																product.name.toLowerCase().includes(AppleTV)
														),
														true
													).length
												).map((item) => {
													<EmptyCarouselObject key={item}></EmptyCarouselObject>;
												}),
											]}
											paddingRight={50}
											disableButtons={
												emptyElementsNeeded(
													getOnlyAvailableItems(
														accessoriesProducts.filter(
															(product) =>
																product.name.toLowerCase().includes(Airpods) ||
																product.name.toLowerCase().includes(AppleTV)
														),
														true
													).length
												).length !== 0 || screen(['xs', 'sm'])
											}
											nextButton={
												<CarouselRightIconWrapper>
													<Icon
														name="chevronRight"
														width={CHEVRON_ICON_SIZE}
														height={CHEVRON_ICON_SIZE}
														fill="primary"
													/>
												</CarouselRightIconWrapper>
											}
											prevButton={
												<CarouselLeftIconWrapper>
													<Icon
														name="chevronLeft"
														width={CHEVRON_ICON_SIZE}
														height={CHEVRON_ICON_SIZE}
														fill="primary"
													/>
												</CarouselLeftIconWrapper>
											}
										/>
									</BusyIndicator>
								</>
							)}
						/>
						<Divider marginTop={56} />
						<AppleServices />
						<Divider marginBottom={56} />
						<ConditionalRender
							show={false}
							onTrue={() => (
								<>
									<AppleBrandStoreProductSection>
										<ConditionalRender
											show={screen(['xs', 'sm'])}
											onTrue={() => (
												<HeadlineBold>
													{translate('apple-brand-store.label.shipping-options')}
												</HeadlineBold>
											)}
											onFalse={() => (
												<AppleBrandStoreProductSectionTitle>
													{translate('apple-brand-store.label.shipping-options')}
												</AppleBrandStoreProductSectionTitle>
											)}
										/>
									</AppleBrandStoreProductSection>
									<ShippingOptionsWrapper>
										<Card<types.cards.IImageWithTitleAndButtonProps>
											card="imageWithTitleAndButton"
											params={{
												imagePath: 'mock/[XL] Outlet pickup photo@2x.jpg',
												title: translate('apple-brand-store.label.pickup.title'),
												description: translate('apple-brand-store.label.pickup.description'),
												direction: 'column',
											}}
											withoutContainerPadding
										/>
										<Card<types.cards.IImageWithTitleAndButtonProps>
											card="imageWithTitleAndButton"
											params={{
												imagePath: 'mock/[XL] Home delivery photo@2x.jpg',
												title: translate('apple-brand-store.label.delivery.title'),
												description: translate('apple-brand-store.label.delivery.description'),
												direction: 'column',
											}}
											withoutContainerPadding
										/>
									</ShippingOptionsWrapper>
								</>
							)}
						/>
					</>
				)}
			/>
		</Container>
	);
};

export default AppleBrandStore;
