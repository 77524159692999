import styled from 'styled-components';

export const BottomCartWrapper = styled.div`
	position: fixed;
	left: 0;
	z-index: 10;
	width: 100%;
	border-radius: 16px;
	bottom: 0;
	box-shadow: 0 7px 8px 0 rgba(0, 0, 0, 0.05), 0 0 22px 4px rgba(0, 0, 0, 0.04), 0 12px 17px 2px rgba(0, 0, 0, 0.04);
`;
