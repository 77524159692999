export interface ICategories {
	_contents: IContent[];
	contents: IContent;
}

interface IInputContent {
	children: any[];
	id: string;
	identifier: string;
	links: Record<string, any>;
	name: string;
	seo: Record<string, any>;
	sequence: string;
	storeID: string;
}

export interface IContent {
	children: ISingleCategory[];
	id: string;
	identifier: string;
	links: Record<string, any>;
	name: string;
	seo: Record<string, any>;
	sequence: string;
	storeID: string;
}

export interface ISingleCategory {
	id: string;
	name: string;
	parentId: string;
	seo: Record<string, string>;
	level: number;
	hasChildren?: boolean;
	identifier: string;
}

function flatCategories(nestedCategories: IInputContent, parentId: string, level: number): ISingleCategory[] {
	return nestedCategories.children.flatMap((cat) => {
		const singleCategory = {
			id: cat.id,
			name: cat.name,
			parentId,
			seo: cat.seo,
			level,
			identifier: cat.identifier,
		};
		if (cat.children) {
			return [{ ...singleCategory, hasChildren: true }, ...flatCategories(cat, cat.id, level + 1)];
		}
		return singleCategory;
	});
}

const model: types.models.IInputModel = {
	_contents: 'response.contents',
	contents(model: ICategories) {
		const eshopCategories = model._contents.find((cat: IInputContent) => cat.identifier === 'eshop') as IContent;

		return {
			children: flatCategories(eshopCategories, eshopCategories.id, 1),
			id: eshopCategories.id,
			identifier: eshopCategories.identifier,
			links: eshopCategories.links,
			name: eshopCategories.name,
			seo: eshopCategories.seo,
			sequence: eshopCategories.sequence,
			storeID: eshopCategories.storeID,
		};
	},
};

export default model;
