import { BodyS, BodySBold } from '@Components/Typography/Typography.styled';
import { usePrepaidMigration } from '@Utils/context/PrepaidMigrationContext';
import useScreen from '@Utils/hooks/useScreen';
import useTranslate from '@Utils/hooks/useTranslate';
import React, { FunctionComponent } from 'react';
import { Container } from 'react-grid-system';
import { ChoosenAccountBarContainer, DetailsContainer, LinkWrapper, NumberContainer } from './ChoosenAccountBar.styled';

const ChoosenAccountBar: FunctionComponent = () => {
	const { choosenNumber, chooseOtherNumber } = usePrepaidMigration();
	const { translate } = useTranslate();
	const { screen } = useScreen();
	const isXS = screen('xs');

	return (
		<ChoosenAccountBarContainer>
			<Container>
				<DetailsContainer isXS={isXS}>
					<NumberContainer>
						<BodyS>{translate(isXS ? 'choosen-account-bar.title-xs' : 'choosen-account-bar.title')}</BodyS>
						<BodySBold>{choosenNumber}</BodySBold>
					</NumberContainer>
					<LinkWrapper onClick={chooseOtherNumber}>{translate('choosen-account-bar.button')}</LinkWrapper>
				</DetailsContainer>
			</Container>
		</ChoosenAccountBarContainer>
	);
};

export default ChoosenAccountBar;
