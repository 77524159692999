import React, { FunctionComponent } from 'react';
import { LabelWidth } from '@Components/controls/Tabs/Tabs.styled';
import { LabelWrapper, Label } from './PrimaryLabel.styled';

interface IPrimaryLabelProps {
	label: string;
	labelWidth: LabelWidth;
	active: boolean;
	setActiveItem: () => void;
	disabled?: boolean;
}

const PrimaryLabel: FunctionComponent<IPrimaryLabelProps> = ({
	label,
	labelWidth,
	active,
	disabled,
	setActiveItem,
}) => {
	return (
		<LabelWrapper labelWidth={labelWidth} active={active} disabled={!!disabled} onClick={setActiveItem}>
			<Label active={active} disabled={!!disabled}>
				{label}
			</Label>
		</LabelWrapper>
	);
};

export default PrimaryLabel;
