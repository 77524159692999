import React, { FunctionComponent } from 'react';
import {
	CardContainer,
	ImageWrapper,
	CardTitle,
	CardDescription,
	ActionWrapper,
	ButtonWrapper,
} from './DeletePaymentToken.styled';
import useTranslate from '@Utils/hooks/useTranslate';
import { PrimaryMediumButtonFixed, WarningMediumButtonFixed } from '@Components/controls/Button/Button';
import Icon from '@Components/Icon/Icon';
import { setModal } from '@Redux/modules/modal/actions';
import { useDispatch } from 'react-redux';
import PaymentTokenItem from '@Components/PaymentTokenItem/PaymentTokenItem';
import { PaymentService } from '@Services/payment/payment';
import { addBusyIndicator, removeBusyIndicator } from '@Redux/modules/busyIndicator/actions';

const ICON_SIZE = 96;
const DELETE_PAYMENT_TOKEN = 'DELETE_PAYMENT_TOKEN';

const DeletePaymentToken: FunctionComponent<types.cards.IDeletePaymentTokenCardProps> = ({ params: { token } }) => {
	const { translate } = useTranslate();
	const dispatch = useDispatch();

	const handleClose = () => {
		dispatch(
			setModal({
				type: null,
				data: null,
			})
		);
	};

	const handleOnDelete = () => {
		dispatch(addBusyIndicator(DELETE_PAYMENT_TOKEN));
		PaymentService.removePaymentToken(token.id).subscribe(
			() => {
				dispatch(removeBusyIndicator(DELETE_PAYMENT_TOKEN));
			},
			() => {
				dispatch(removeBusyIndicator(DELETE_PAYMENT_TOKEN));
			}
		);
	};

	return (
		<CardContainer>
			<ImageWrapper>
				<Icon name="warning" width={ICON_SIZE} height={ICON_SIZE} fill="primary" />
			</ImageWrapper>
			<CardTitle>{translate('modal.delete-payment-token.title')}</CardTitle>
			<CardDescription>{translate('modal.delete-payment-token.description')}</CardDescription>
			<PaymentTokenItem token={token} />
			<ActionWrapper>
				<PrimaryMediumButtonFixed uniqueId="delete-payment-token.close.modal" onClick={handleClose}>
					{translate('close')}
				</PrimaryMediumButtonFixed>
				<ButtonWrapper>
					<WarningMediumButtonFixed
						uniqueId="delete-payment-token"
						listener={[DELETE_PAYMENT_TOKEN]}
						onClick={handleOnDelete}
					>
						{translate('modal.delete-payment-token.delete')}
					</WarningMediumButtonFixed>
				</ButtonWrapper>
			</ActionWrapper>
		</CardContainer>
	);
};
export default DeletePaymentToken;
