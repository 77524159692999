import styled from 'styled-components';
import { matchScreen, paddingRight, paddingLeft } from '@Utils/layout/layoutSupport';
import { BodyS, BodySBold, ValueBold } from '@Components/Typography/Typography.styled';

export const ContactDetailsWrapper = styled.div`
	${() => ({
		padding: matchScreen('xs') ? 0 : 8,
	})}
	${() => paddingLeft(matchScreen('xs') ? 0 : 32)}
	${() => paddingRight(matchScreen('xs') ? 0 : 16)}
`;

export const Subtitle = styled(BodyS)`
	${({ theme }) => ({
		color: theme.colors.black54,
	})}
`;

export const IconWrapper = styled.div`
	${() => paddingRight(16)}
`;

export const AddressText = styled(ValueBold)`
	${() => paddingLeft(16)}
`;

export const BodySWrapper = styled(BodySBold)`
	cursor: pointer;
	text-decoration: underline;
`;

export const EditAddressWrapper = styled.div`
	height: 108px;
	padding: 8px 16px;
	margin-top: 32px;
	border-radius: 8px;
	box-sizing: border-box;
	${({ theme }) => ({
		backgroundColor: theme.colors.grey50,
	})}
`;

export const IconAddressWrapper = styled.div`
	height: 60px;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	margin-bottom: 8px;
`;

export const HeaderWrapper = styled.div`
	display: flex;
`;
