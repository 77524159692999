import React, { FunctionComponent } from 'react';
import Icon from '@Components/Icon/Icon';
import {
	ChevronWrapper,
	IconWrapper,
	ProductAvailabilityItemContainer,
	ProductAvailabilityItemWrapper,
	ProductAvailabilityPlaceName,
	ProductAvailable,
	ProductUnavailable,
} from './ProductAvailabilityItem.styled';
import Divider from '@Components/Divider/Divider';
import ConditionalRender from '@Components/ConditionalRender/ConditionalRender';
import useTranslate from '@Utils/hooks/useTranslate';

interface IProductAvailabilityItem {
	place: string;
	available?: boolean;
}

const ProductAvailabilityItem: FunctionComponent<IProductAvailabilityItem> = ({ place, available = false }) => {
	const { translate } = useTranslate();
	const ICON_AVAILABLE = 21;
	const ICON_DOWN = 24;

	return (
		<ProductAvailabilityItemContainer>
			<ProductAvailabilityItemWrapper>
				<ProductAvailabilityPlaceName>{place}</ProductAvailabilityPlaceName>
				<ConditionalRender
					show={available}
					onTrue={() => (
						<ProductAvailable>
							{translate('product-availability-card.product.available')}
							<IconWrapper>
								<Icon name="success" fill="positive" height={ICON_AVAILABLE} width={ICON_AVAILABLE} />
							</IconWrapper>
							<ChevronWrapper>
								<Icon name="chevronDown" height={ICON_DOWN} width={ICON_DOWN} />
							</ChevronWrapper>
						</ProductAvailable>
					)}
					onFalse={() => (
						<ProductUnavailable>
							{translate('product-availability-card.product.unavailable')}
							<IconWrapper>
								<Icon name="error" height={ICON_AVAILABLE} width={ICON_AVAILABLE} />
							</IconWrapper>
							<ChevronWrapper>
								<Icon name="chevronDown" height={ICON_DOWN} width={ICON_DOWN} />
							</ChevronWrapper>
						</ProductUnavailable>
					)}
				/>
			</ProductAvailabilityItemWrapper>
			<Divider color="black12" />
		</ProductAvailabilityItemContainer>
	);
};
export default ProductAvailabilityItem;
