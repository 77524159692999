import {
	BodyMBold,
	InfoBold,
	MiniDurationBold,
	SmallCurrencyVat,
	TitleBold,
} from '@Components/Typography/Typography.styled';
import { textRight, marginRight } from '@Utils/layout/layoutSupport';
import styled from 'styled-components';

export const OrderCardContainer = styled.div``;

export const OrderCardTitle = styled(TitleBold)`
	${({ theme }) => ({ color: theme.colors.black87 })}
	margin-bottom: 24px;
`;

export const PriceTitle = styled(BodyMBold)`
	${({ theme }) => ({ color: theme.colors.black87 })}
`;

export const PriceWrapper = styled.div`
	display: flex;
	justify-content: flex-end;
`;

export const PriceBlockContainer = styled.div`
	margin-top: 16px;
	display: flex;
	flex-direction: column;
`;

export const SmallOMRWrapper = styled(MiniDurationBold)`
	${() => marginRight(2)}
`;

export const PriceAccordion = styled.div`
	display: flex;
	flex-direction: column;
`;

export const PriceHeaderWrapper = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
`;

export const VatAmountContainer = styled(InfoBold)<{ isMakasib?: boolean }>`
	${({ theme, isMakasib }) => ({
		textDecoration: isMakasib ? 'line-through' : 'none',
	})};
`;

export const VatWrapper = styled.div`
	align-self: flex-end;
	justify-self: flex-end;
	display: flex;
	flex-direction: row;
	gap: 2px;
`;

export const Vat = styled(SmallCurrencyVat)`
	${() => textRight()}
	${({ theme }) => ({
		color: theme.colors.black54,
	})}
`;

export const StrikethroughWrapper = styled.div`
	font-weight: bold;
	text-decoration: line-through;
	display: inline;
`;
