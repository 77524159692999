import moment, { Moment, duration as momentDuration, DurationInputArg1, DurationInputArg2, Duration } from 'moment';
import 'moment/locale/ar';
import 'moment/locale/en-gb';
import { getLanguage } from '@Utils/language/language';

type SupportedLocales = 'en-gb' | 'ar';

const locales = new Map<types.redux.settings.language, SupportedLocales>([
	['EN', 'en-gb'],
	['AR', 'ar'],
]);

export const safeLocale = (locale?: types.redux.settings.language): SupportedLocales => {
	if (locale) {
		return locales.get(locale) || 'en-gb';
	} else {
		return 'ar';
	}
};

export default function (
	date?: string | number | Date | Moment | null,
	namedParameters: {
		locale: types.redux.settings.language;
		format?: moment.MomentFormatSpecification;
		strict?: boolean;
	} = { locale: getLanguage() }
): Moment {
	return moment(date || undefined, namedParameters.format, namedParameters.strict).locale(
		safeLocale(namedParameters.locale)
	);
}

export function format(
	date?: string | number | Date | Moment | null,
	namedParameters: {
		locale?: types.redux.settings.language;
		format?: moment.MomentFormatSpecification;
		toFormat?: string;
		strict?: boolean;
	} = { locale: getLanguage() }
): string {
	const usedLocale = namedParameters.locale || getLanguage();
	return moment(date || undefined, namedParameters.format, namedParameters.strict)
		.locale(safeLocale(usedLocale))
		.format(namedParameters.toFormat);
}

export function duration(
	inp?: DurationInputArg1,
	unit?: DurationInputArg2,
	namedParameters: {
		locale: types.redux.settings.language;
	} = { locale: getLanguage() }
): Duration {
	return momentDuration(inp, unit).locale(safeLocale(namedParameters.locale));
}

/**
 * Formats duration as m:ss
 *
 * @param duration in milliseconds
 * @returns formated duration
 */
export function getTimerFormat(duration: number): string {
	const seconds = duration / 1000;
	const min = Math.floor(seconds / 60);
	const sec = Math.floor(seconds % 60);
	return `${min}:${sec < 10 ? '0' + sec.toString() : sec}`;
}

// Converts arabic into english numbers
export const a2e = (s: string): string => s.replace(/[٠-٩]/g, (d) => String('٠١٢٣٤٥٦٧٨٩'.indexOf(d)));

export function isSoonPeorderDate(date: string): boolean {
	const preorderDate = moment(date, 'DD.MM.YYYY');
	const preorderDay = Number(preorderDate.format('D'));
	const preorderMonth = Number(preorderDate.format('M'));
	return preorderMonth === 9 && preorderDay >= 17 && preorderDay <= 23;
}
