import React, { FunctionComponent, useEffect, useState } from 'react';
import {
	CardWrapper,
	ImageWrapper,
	CardContent,
	ItemTitle,
	PriceDescription,
	PriceWrapper,
	InnerWrapper,
} from './PopularProduct.styled';
import { useHistory } from 'react-router-dom';
import useTranslate from '@Utils/hooks/useTranslate';
import PriceTag from '@Components/PriceTag/PriceTag';
import { ItemType, ProductsService } from '@Services/productsService/productsService';
import { getLang } from '@Utils/language/language';
import ConditionalRender from '@Components/ConditionalRender/ConditionalRender';
import { roundMoney } from '@Utils/converters/roundMoney';
import { BodySHigher, CaptionBiggerBold } from '@Components/Typography/Typography.styled';
import useScreen from '@Utils/hooks/useScreen';

const PopularProduct: FunctionComponent<types.cards.IPopularProductProps> = ({
	params: { forceClose, productDetails, isSearch },
}) => {
	const history = useHistory();
	const { translate } = useTranslate();
	const [vatPercent, setVatPercent] = useState<string | undefined>(undefined);
	const { screen } = useScreen();
	useEffect(() => {
		ProductsService.getProductById({ productId: productDetails.uniqueID, itemType: ItemType.UNKNOWN }).subscribe(
			({ items }) => {
				if (items && items?.length > 0) {
					setVatPercent(items[0].vatPercent);
				}
			},
			() => {}
		);
	}, []);

	const handleClick = () => {
		forceClose();
		history.push(`/${getLang()}/product/${productDetails.uniqueID}`);
		location.reload();
	};

	return (
		<CardWrapper onClick={handleClick}>
			<InnerWrapper>
				<ImageWrapper src={productDetails.thumbnail} />
				<CardContent>
					<ItemTitle>
						{productDetails.name.length > (screen('lg') ? 45 : 60)
							? `${productDetails.name.substring(0, screen('lg') ? 45 : 60)}...`
							: productDetails.name}
					</ItemTitle>
					<ConditionalRender
						show={!isSearch}
						onTrue={() => (
							<PriceWrapper>
								<PriceDescription>
									{translate('megamenu.search.popular-product.starts-from')}
								</PriceDescription>
								<PriceTag
									price={productDetails.price}
									size="mini"
									duration={
										vatPercent && translate('megamenu.search.popular-product.vat', vatPercent ?? '')
									}
								/>
							</PriceWrapper>
						)}
					/>
				</CardContent>
			</InnerWrapper>
			<ConditionalRender
				show={!!isSearch}
				onTrue={() => (
					<PriceWrapper>
						<CaptionBiggerBold color="black54">{translate('payment.summary.price')}</CaptionBiggerBold>
						<BodySHigher color="primary">
							{translate('omr')}
							{`\xa0${roundMoney(productDetails.price)}`}
						</BodySHigher>
					</PriceWrapper>
				)}
			/>
		</CardWrapper>
	);
};

export default PopularProduct;
