import { ICommerceProfile } from '@ApiModels/commerceProfile';
import { setCheckoutDetails } from '@Redux/modules/checkout/actions';
import { getCheckoutDetail } from '@Redux/modules/checkout/selectors';
import { dispatch } from '@Redux/store';
import api from '@Utils/api';
import invalidateCache from '@Utils/api/invalidateCache';
import { Observable } from 'rxjs';
import { AjaxError } from 'rxjs/ajax';
import { catchError, map, tap } from 'rxjs/operators';

export type AddressType = 'ShippingAndBilling' | 'Shipping';
export interface IUpdateProfilePayloadInput {
	addressType: AddressType;
	firstName?: string;
	lastName?: string;
	city?: string;
	zipCode?: string;
	governorate?: string;
	phoneNumber?: string;
	organization?: string;
	landmark?: string;
	way?: string;
	house?: string;
	apartment?: string;
	geographicalShippingCode?: string;
	markerPosition?: google.maps.LatLngLiteral;
	nickName?: string;
	wilayat?: string;
	middleName?: string;
	email?: string;
	title?: string;
	area?: string;
}

interface ICommerceProfileResponse {
	addressId: string;
	resourceName: string;
	userId: string;
}

export class CommerceProfileService {
	public static getUserProfile(): Observable<ICommerceProfile> {
		return api.omantelShop.getUserProfile().call();
	}

	public static deleteUserAddress(nickName: string): Observable<any> {
		return api.omantelShop.deleteUserAddress(nickName).call();
	}

	public static updateUserProfile(
		nickName: string,
		profile: IUpdateProfilePayloadInput
	): Observable<ICommerceProfileResponse> {
		if (profile.markerPosition) {
			const { lat, lng } = profile.markerPosition;
			profile.geographicalShippingCode = `{"longitude": "${lng}", "latitude":"${lat}"}`;
		}

		return api.omantelShop
			.updateProfile(nickName, profile)
			.call()
			.pipe(
				catchError(() => {
					profile.nickName = nickName;
					return api.omantelShop.addContactToProfile(profile).call().toPromise();
				}),
				map(({ response }) => {
					const _type = nickName === 'ShippingAndBilling' ? 'billingAddress' : 'shipmentAddress';
					dispatch(
						setCheckoutDetails({
							[_type]: {
								...getCheckoutDetail(_type),
								addressId: response.addressId,
							},
						})
					);
					return response;
				}),
				tap(() => {
					invalidateCache(['getCommerceProfile']);
				})
			);
	}

	public static addContactToProfile(profile: IUpdateProfilePayloadInput): Observable<ICommerceProfileResponse> {
		return api.omantelShop
			.addContactToProfile(profile)
			.call()
			.pipe(
				catchError<AjaxError, Promise<any>>((error) => {
					if (error.response.errors[0].errorCode === '5040') {
						return api.omantelShop
							.updateProfile(profile.nickName ?? '', profile)
							.call()
							.toPromise();
					}
					throw new Error(error);
				}),
				map((response) => {
					return response.response;
				}),
				tap(() => {
					invalidateCache(['getCommerceProfile']);
				})
			);
	}
}
