import { store } from '@Redux/store';
import hasObjectPath from '@Utils/objectsValidation/hasObjectPath';
import compareDates from '@Utils/date/compareDates';

const getApiCacheStore = () => store.getState().api.cache;

interface ICacheRequest extends types.redux.api.cache.ICacheRequest {}
interface ICachedParams extends types.redux.api.cache.ICachedParams {}

export const isRequestCached = (cacheRequest: ICacheRequest): ICachedParams | boolean => {
	const cache = getApiCacheStore();
	const { endpointUrl, payloadHash } = cacheRequest;
	if (hasObjectPath(cache.requests, endpointUrl, payloadHash)) {
		return cache.requests[endpointUrl][payloadHash];
	} else {
		return false;
	}
};

export const isRequestCachedAndNotExpired = (cacheRequest: ICacheRequest): boolean | ICachedParams => {
	const cachedRequest = isRequestCached(cacheRequest);
	if (typeof cachedRequest === 'object' && compareDates(cachedRequest.validTill as any).currentTime.isSmaller) {
		return cachedRequest;
	} else {
		return false;
	}
};
