import styled from 'styled-components';
import { TileSize } from '@Components/Card/cards/ProductCard/ProductCard.styled';
import { BodyMH3, BodyMBold } from '@Components/Typography/Typography.styled';
import { marginRight, right } from '@Utils/layout/layoutSupport';
import { isRTL } from '@Utils/layout/layoutSupport';
interface IImageWrapperProps {
	tileSize: TileSize;
}

export const ImageWrapper = styled.div<IImageWrapperProps>`
	height: 192px;
	width: 100%;
	overflow: hidden;
	display: flex;
	justify-content: center;
	${({ tileSize }) => (tileSize === TileSize.REGULAR ? { alignItems: 'center' } : { alignItems: 'flex-start' })}
	& > img {
		${({ tileSize }) => tileSize === TileSize.REGULAR && { maxWidth: 192 }}
	}
	align-items: flex-end;
`;

export const ContentWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 20px 0;
	position: relative;
`;

export const TitleWrapper = styled.div`
	display: inline;
	vertical-align: sub;
	position: relative;
	z-index: 1;
`;

export const TitleWrapperParent = styled.div`
	height: 40px;
`;

export const Image = styled.img<{ isAppleWatch?: boolean }>`
	${({ isAppleWatch }) => `height: ${isAppleWatch ? '160px' : '184px'}`};
	width: auto;
`;

export const MarketingTagImage = styled.img`
	height: 64px;
	width: auto;
`;

export const LoaderContainer = styled.div`
	position: absolute;
	left: 50%;
	top: 50%;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
`;

export const BrandWrapper = styled(BodyMBold)`
	${() => marginRight(4)}
	display: inline;
`;

export const ProductNameWrapper = styled(BodyMH3)<{ isLG?: boolean }>`
	word-wrap: break-word;
	display: inline;
	${({ isLG }) =>
		`font-size : ${isLG ? '15px' : '17px'};
	line-height: ${isLG ? '15px' : '20px'}
	`};
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 4;
	overflow: hidden;
`;

export const TagWrapper = styled.div<{ right?: number }>`
	position: absolute;
	top: 180px;
	z-index: 1;
	${({ right = 0 }) => (isRTL() ? ` left: ${right}px` : `	right: ${right}px`)};
`;

export const PreorderTagWrapper = styled.div`
	position: absolute;
	top: 180px;
	z-index: 1;
	${() => right(0)};
`;

export const Container = styled.div<{ withContainer?: boolean }>`
	position: relative;
	${({ withContainer, theme }) =>
		withContainer && ` background-color: ${theme.colors.white}; padding: 16px; border-radius: 8px;`}
`;
