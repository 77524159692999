import React, { FunctionComponent, useEffect, useState } from 'react';
import useTranslate from '@Utils/hooks/useTranslate';
import {
	CardWrapper,
	EmptyShippment,
	EmptyShippmentIcons,
	Order,
	ButtonContent,
} from '@Components/Card/cards/GuestOrderHistory/GuestOrderHistory.styled';
import ConditionalRender from '@Components/ConditionalRender/ConditionalRender';
import Icon from '@Components/Icon/Icon';
import { BodyS, BodySBold } from '@Components/Typography/Typography.styled';
import Divider from '@Components/Divider/Divider';
import { PrimaryLargeButtonFixed } from '@Components/controls/Button/Button';
import { useHistory, useLocation } from 'react-router-dom';
import GuestOrderItem from './GuestOrderItem/GuestOrderItem';
import BusyIndicator from '@Components/BusyIndicator/BusyIndicator';
import { getLang } from '@Utils/language/language';
import { dispatch } from '@Redux/store';
import { addBusyIndicator, removeBusyIndicator } from '@Redux/modules/busyIndicator/actions';
import { OrderFlowService } from '@Services/orderFlow/orderFlow';
import {
	IOrderCoordinates,
	IOrderDetails,
	IOrderDetailsItem,
	IOrderDetailsResponse,
	IOrderItem,
} from '@ApiModels/orderDetails';
import moment from 'moment';
import { ICartItem } from '@ApiModels/viewCart';
import {
	DEF_ITEM_TYPE,
	DIGITAL_PRODUCT_ITEM_TYPE,
	getAttributeValueAsString,
	getTariffIncludedFromAttributes,
	GIFT_CARD_ITEM_TYPE,
} from '@ApiModels/utils/attributeGetters';
import { CartItemType } from '@Components/Card/cards/CartItems/subcomponents/CartItem';
import { getLoginType } from '@Redux/modules/api/authentication/selectors';
export enum OrderHistoryType {
	ORDERS_IN_PROGRESS = 'ORDERS_IN_PROGRESS',
	ORDER_HISTORY = 'ORDER_HISTORY',
}

export const GET_ORDER_HISTORY_BI = 'GET_ORDER_HISTORY_BI';
const ICON_SIZE_EMPTY_STATE = 48;

const GuestOrderHistory: FunctionComponent<types.cards.IGuestOrderHistoryCardProps> = ({}): JSX.Element => {
	const { translate } = useTranslate();
	const history = useHistory();
	const { search } = useLocation();
	const query = new URLSearchParams(search);
	const [details, setDetails] = useState<IOrderDetails | undefined>(undefined);
	const [detailsItem, setDetailsItem] = useState<IOrderDetailsItem>({
		paymentDate: '',
		deliveryDate: '',
		shipmentNumber: '',
		shipmentTrackUrl: '',
		items: [],
		upfrontPaymentSum: 0,
		period: '12',
		periodPaymentSum: 0,
		vat: 0,
		vatPercent: 0,
		email: '',
	});
	const COM_PARAM_INSTALLMENT_VALUE = 'COM_PARAM_INSTALLMENT_VALUE';
	const [orderExist, setOrderExist] = useState<boolean>(true);
	const setVatPercent = (items: IOrderItem[] | undefined): number => {
		let vatPercent = 0;
		items?.map((_item) => {
			const v = JSON.parse(_item?.xitem_field2 ?? '');
			const p = Number(v.vatPercent);
			if (vatPercent < p) {
				vatPercent = p;
			}
		});
		return vatPercent;
	};

	const setUpData = (data: IOrderDetails) => {
		const productItem = data.orderItem?.find((orderItem) => {
			const parsedAdditionalProductData = orderItem?.xitem_field2 ? JSON.parse(orderItem?.xitem_field2) : '';
			return 'branchId' in parsedAdditionalProductData;
		});
		const additionalProductData = !!productItem?.xitem_field2 ? JSON.parse(productItem?.xitem_field2) : undefined;
		setDetailsItem({
			paymentDate: data.x_paymentDate ? moment(data.x_paymentDate).format('DD MMM YYYY') : undefined,
			deliveryDate: '',
			shipmentNumber: data.x_shipmentNumber ?? '',
			shipmentTrackUrl: data.x_shipmentTrackUrl ?? '',
			coordinates: getCoordinates(data.x_shippingGeoCode ?? ''),
			items: setUpCartItems(data.orderItem ?? []),
			upfrontPaymentSum: setUpUpfrontPaymentSum(data.orderItem ?? []),
			period: '12',
			periodPaymentSum: setPeriodPaymentSum(data.orderItem ?? []),
			vat: setVat(data.orderItem ?? []),
			vatPercent: setVatPercent(data.orderItem ?? []),
			isPickup: data.orderItem?.some((orderItem) => orderItem.shipModeCode === 'Pickup'),
			email: data?.orderItem?.[0]?.email1,
			data: moment(data.placedDate).format('DD MMM YYYY') ?? '',
			isOutletPickup: !!data.x_outletPickupOrder && data.x_outletPickupOrder === 'true',
			isShipment: !!data.x_noShipment && data.x_noShipment === 'true',
			outletPickupBookingAppointment: !!additionalProductData ? additionalProductData.appointment : '',
			branchId: !!additionalProductData ? additionalProductData.branchId : '',
			ticketNumber: !!additionalProductData ? additionalProductData.ticketNumber : '',
		});
	};

	const setVat = (items: IOrderItem[] | undefined): number => {
		let _vat = 0;
		items?.map((_item) => {
			const v = JSON.parse(_item?.xitem_field2 ?? '');
			_vat += Number(_item.quantity) * Number(v.vatValue);
		});
		return _vat;
	};

	const setUpCartItems = (items: IOrderItem[] | undefined): ICartItem[] => {
		const cartItems = items?.map((_item) => {
			let qty: number = 0;
			if (_item.quantity) {
				qty = +_item.quantity;
			}
			const attributes: { attrIdentifier: string; attrValue: string; attrName: string }[] = JSON.parse(
				_item.xitem_attributes ?? ''
			);
			const defItemType = attributes?.find(({ attrIdentifier }) => attrIdentifier === DEF_ITEM_TYPE)?.attrValue;
			const parsedFiled = JSON.parse(_item.xitem_field2 ?? '');
			const plan = {
				...parsedFiled,
				longDesc: _item.xitem_planLongDescription,
				priceMonthly: _item.xitem_planMonthy,
				planName: _item.xitem_planName,
				shortDesc: _item.xitem_planShortDescription,
				thumbnail: _item.xitem_thumbnail,
				planDetails: getTariffIncludedFromAttributes(parsedFiled.productId),
				installmentValue: getAttributeValueAsString(parsedFiled, COM_PARAM_INSTALLMENT_VALUE) ?? 0,
				planTaxablePrice: _item.xitem_planTaxablePrice,
				planContractDuration: _item.xitem_planContractDuration,
				planMonthy: _item.xitem_planMonthy,
				planVatValue: _item.xitem_planVatValue,
			};

			return {
				price: String(Number(_item.unitPrice ?? '0') * Number(_item?.quantity ?? 1) ?? ''),
				unitPrice: '',
				currency: _item.currency ?? 'OMR',
				salesTax: _item.salesTax ?? '',
				partNumber: _item.partNumber ?? '',
				productId: _item.productId ?? '',
				orderItemId: _item.orderItemId ?? '',
				quantity: qty,
				freeGift: /true/i.test(_item.freeGift ?? 'false'),
				type: CartItemType.ORDER_HISTORY,
				vat: setVat([_item]),
				vatPercent: setVatPercent([_item]),
				withPlan: !!_item.xitem_planName,
				plan,
				isDigital: defItemType === GIFT_CARD_ITEM_TYPE || defItemType === DIGITAL_PRODUCT_ITEM_TYPE,
			};
		});
		return !!cartItems ? cartItems : [];
	};

	const setPeriodPaymentSum = (items: IOrderItem[] | undefined): number => {
		let periodPaymentSum = 0;
		items?.map((_item) => {
			let price: number = 0;
			const parsedFiled = JSON.parse(_item.xitem_field2 ?? '');
			if (_item.xitem_planMonthy) {
				price = +_item.xitem_planMonthy + Number(parsedFiled?.IV);
				periodPaymentSum += price;
			}
		});
		return periodPaymentSum;
	};

	const setUpUpfrontPaymentSum = (items: IOrderItem[] | undefined): number => {
		let upfrontPaymentSum = 0;
		items?.map((_item) => {
			let price: number = 0;
			if (_item.orderItemPrice) {
				price = +_item.orderItemPrice;
				upfrontPaymentSum += price;
			}
		});
		return upfrontPaymentSum;
	};

	const getCoordinates = (data: string): IOrderCoordinates | undefined => {
		if (data.length > 0) {
			const coordinates = JSON.parse(data);
			const lat = +coordinates.latitude;
			const lng = +coordinates.longitude;
			return { lat, lng };
		}
	};

	const getOrderId = () => {
		return query.get('orderId') || '';
	};

	const getOrderDetails = () => {
		dispatch(addBusyIndicator(GET_ORDER_HISTORY_BI));
		OrderFlowService.getOrderDetails(getOrderId()).subscribe(
			(orderDetails: IOrderDetailsResponse) => {
				setOrderExist(true);
				setDetails(orderDetails.response);
				setUpData(orderDetails.response);
				dispatch(removeBusyIndicator(GET_ORDER_HISTORY_BI));
			},
			() => {
				setDetails(undefined);
				setOrderExist(false);
				dispatch(removeBusyIndicator(GET_ORDER_HISTORY_BI));
			}
		);
	};

	const getTypeByStatus = (status: string) => {
		switch (status) {
			case 'X':
				return OrderHistoryType.ORDER_HISTORY;
			case 'S':
				return OrderHistoryType.ORDER_HISTORY;
			default:
				return OrderHistoryType.ORDERS_IN_PROGRESS;
		}
	};

	useEffect(() => {
		getOrderDetails();
	}, []);

	const goBackToStore = (): void => {
		history.push(`/${getLang()}/store`);
	};

	return (
		<ConditionalRender
			show={getLoginType() === 'guest'}
			onTrue={() => (
				<CardWrapper>
					<BusyIndicator listener={GET_ORDER_HISTORY_BI} skeleton="orderTracking">
						<>
							<ConditionalRender
								show={!orderExist}
								onTrue={() => (
									<EmptyShippment>
										<BodySBold color="black38">
											{translate('account.management.orders.no')}
										</BodySBold>
										<Divider color="transparent" marginBottom={8} />
										<BodyS color="black38">
											{translate('account.management.orders.go.to.store')}
										</BodyS>
										<Divider color="transparent" marginBottom={32} />
										<EmptyShippmentIcons>
											<Icon
												name="device"
												width={ICON_SIZE_EMPTY_STATE}
												height={ICON_SIZE_EMPTY_STATE}
											/>
											<Icon
												name="promotion2"
												width={ICON_SIZE_EMPTY_STATE}
												height={ICON_SIZE_EMPTY_STATE}
											/>
											<Icon
												name="headphones"
												width={ICON_SIZE_EMPTY_STATE}
												height={ICON_SIZE_EMPTY_STATE}
											/>
										</EmptyShippmentIcons>
										<Divider color="transparent" marginBottom={32} />
										<ButtonContent>
											<PrimaryLargeButtonFixed
												uniqueId="account.management.orders.go.to.store"
												onClick={goBackToStore}
											>
												{translate('account.management.orders.go.to.store.button')}
											</PrimaryLargeButtonFixed>
										</ButtonContent>
									</EmptyShippment>
								)}
							/>
							<ConditionalRender
								show={orderExist}
								onTrue={() => (
									<Order>
										<GuestOrderItem
											details={details}
											detailsItem={detailsItem}
											type={getTypeByStatus(details?.orderStatus || '')}
											status={details?.orderStatus}
										/>
										<Divider color="transparent" marginBottom={16} />
									</Order>
								)}
							/>
						</>
					</BusyIndicator>
				</CardWrapper>
			)}
			onFalse={() => <>{goBackToStore()}</>}
		/>
	);
};

export default GuestOrderHistory;
