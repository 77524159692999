import React, { FunctionComponent, MouseEvent, useState } from 'react';
import { ColorPickerPosition, ColorPickerWrapper, IconWrapper } from './ColorPicker.styles';
import ConditionalRender from '@Components/ConditionalRender/ConditionalRender';
import Icon from '@Components/Icon/Icon';
import Slider, { IAvailableColorWithId } from './subcomponents/Slider';

export const ITEMS_SEPARATOR = 4;

export const ITEM_SIZE = 28;

interface IAdditionalColorPickerProps {
	position: ColorPickerPosition;
}
interface IBaseColorPickerProps {
	colors: IAvailableColorWithId[];
	setSelectedColor?: (color: string | null) => void;
	selectedColor?: string | null;
	numberOfItemsOnSlide?: number;
}

interface IColorPickerProps extends IBaseColorPickerProps, IAdditionalColorPickerProps {}

const ColorPicker: FunctionComponent<IColorPickerProps> = ({
	colors,
	selectedColor,
	setSelectedColor,
	position,
	numberOfItemsOnSlide = colors.length,
}) => {
	const [activeSlide, setActiveSlide] = useState<number>(0);
	const carouselVisible: boolean = numberOfItemsOnSlide < colors.length;
	const numberOfSlides: number = Math.ceil(colors.length / numberOfItemsOnSlide);

	const nextSlide = () => {
		if (activeSlide < numberOfSlides - 1) {
			setActiveSlide((slide) => slide + 1);
		}
	};

	const prevSlide = () => {
		if (activeSlide > 0) {
			setActiveSlide((slide) => slide - 1);
		}
	};

	if (!colors.length) {
		return null;
	}

	return (
		<ColorPickerWrapper
			position={position}
			separator={ITEMS_SEPARATOR}
			onClick={(e: MouseEvent<HTMLDivElement>) => {
				e.preventDefault();
				e.stopPropagation();
			}}
		>
			<ConditionalRender
				show={carouselVisible}
				onTrue={() => (
					<IconWrapper hidden={activeSlide === 0} onClick={prevSlide}>
						<Icon
							name={position === ColorPickerPosition.HORIZONTAL ? 'chevronLeft' : 'chevronUp'}
							fill="black87"
							height={24}
							width={24}
						/>
					</IconWrapper>
				)}
			/>
			<Slider
				position={position}
				itemSize={ITEM_SIZE}
				itemsSeparator={ITEMS_SEPARATOR}
				numberOfItemsOnSlide={numberOfItemsOnSlide || colors.length}
				setSelectedColor={setSelectedColor}
				selectedColor={selectedColor}
				colors={colors}
				numberOfSlides={numberOfSlides}
				activeSlide={activeSlide}
				carouselVisible={carouselVisible}
			/>
			<ConditionalRender
				show={carouselVisible}
				onTrue={() => (
					<IconWrapper hidden={activeSlide === numberOfSlides - 1} onClick={nextSlide}>
						<Icon
							name={position === ColorPickerPosition.HORIZONTAL ? 'chevronRight' : 'chevronDown'}
							fill="black87"
							height={24}
							width={24}
						/>
					</IconWrapper>
				)}
			/>
		</ColorPickerWrapper>
	);
};

export const VerticalColorPicker: FunctionComponent<IBaseColorPickerProps> = (props) => (
	<ColorPicker {...props} position={ColorPickerPosition.VERTICAL} />
);

export const HorizontalColorPicker: FunctionComponent<IBaseColorPickerProps> = (props) => (
	<ColorPicker {...props} position={ColorPickerPosition.HORIZONTAL} />
);

export default ColorPicker;
