import React, { FunctionComponent, useMemo } from 'react';
import Card from '@Components/Card/Card';
import { getResponsiveModalStyles } from '@Utils/layout/layoutSupport';
import useScreen from '@Utils/hooks/useScreen';
import { ModalContainer } from '@Components/Modal/subComponents/ModalContainer/ModalContiner.styled';

const ModalDeliveryAddress: FunctionComponent<types.cards.IDeliveryAddressCardProps['params']> = ({
	type,
	action,
	nickName,
	address,
	isIncorrectArea,
}) => {
	const { screenClass } = useScreen();
	const styles = useMemo(() => getResponsiveModalStyles(screenClass), [screenClass]);
	return (
		<ModalContainer styles={styles}>
			<Card<types.cards.IDeliveryAddressCardProps>
				card="deliveryAddress"
				params={{ type, action, nickName, address, isIncorrectArea }}
				fullHeight={true}
			/>
		</ModalContainer>
	);
};

export default ModalDeliveryAddress;
