import styled from 'styled-components';
import { BodySBold } from '@Components/Typography/Typography';

import { marginRight } from '@Utils/layout/layoutSupport';

interface IInsuranceTypeChoiceProps {
	isSmallScreen?: boolean;
}

export const InsuranceTypeChoice = styled(BodySBold)<IInsuranceTypeChoiceProps>`
	display: flex;
	& > div {
		${() => marginRight('15px')}
	}
	${({ isSmallScreen = false }) => {
		return {
			flexDirection: isSmallScreen ? 'column' : 'row',
		};
	}}
`;
