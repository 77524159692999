import styled from 'styled-components';
import { TitleBold, Subheading, CaptionBigger } from '@Components/Typography/Typography';

export const InsuranceTitle = styled(TitleBold)`
	margin-top: 5px;
`;

export const InsuranceSubtitle = styled(Subheading)`
	margin: 20px 0;
	${({ theme }) => {
		return {
			color: theme.colors.black54,
		};
	}}
`;

export const Hint = styled(CaptionBigger)`
	${({ theme }) => {
		return {
			color: theme.colors.black54,
		};
	}}
`;
