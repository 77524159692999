import { Subheading, TitleBold, CaptionBold, CaptionHigher } from '@Components/Typography/Typography';
import { paddingRight, marginLeft } from '@Utils/layout/layoutSupport';
import styled from 'styled-components';

interface IWithPlanContainerProps {
	hasBottomMargin?: boolean;
}

export const CardWrapper = styled.div`
	padding: 8px 16px;
	margin-bottom: -24px;
`;

export const Title = styled(Subheading)`
	padding-bottom: 16px;
`;

export const AdditionalInfoTitle = styled(Subheading)`
	padding-bottom: 16px;
	${({ theme }) => {
		return { color: theme.colors.black54 };
	}}
`;

export const WithPlanContainer = styled.div<IWithPlanContainerProps>`
	margin-top: 16px;
	${(hasBottomMargin) => {
		return {
			marginBottom: hasBottomMargin ? '16px' : '0px',
		};
	}}
`;

export const VatWrapper = styled.div`
	display: flex;
	flex-direction: row;
	gap: 2px;
`;

export const InstallmentsButtonsWrapper = styled.div<{ isColumn?: boolean }>`
	display: flex;
	width: 100%;
	${({ isColumn }) => ({
		flexDirection: isColumn ? 'column' : 'row',
	})}
	gap: 8px;
	margin-top: 16px;
	${() => marginLeft(-3)}
`;

export const UpfrontInstallmentButton = styled.div<{
	selected?: boolean;
	disabled?: boolean;
	isColumn?: boolean;
	isSM?: boolean;
}>`
	display: flex;
	padding: 25px 8px;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	box-sizing: border-box;
	cursor: pointer;
	border-radius: 8px;
	${({ theme, selected, disabled, isColumn, isSM }) => ({
		border: selected ? `2px solid ${theme.colors.primary}` : `1px solid ${theme.colors.black12}`,
		cursor: disabled ? 'unset' : 'pointer',
		backgroundColor: disabled ? theme.colors.black6 : 'unset',
		width: isColumn ? '100%' : isSM ? '33%' : '132px',
		height: isColumn ? '80px' : '92px',
	})}
	&:hover {
		${({ theme, selected, disabled }) => ({
			border: selected
				? `2px solid ${theme.colors.primary}`
				: disabled
				? `1px solid ${theme.colors.black12}`
				: `1px solid ${theme.colors.black87}`,
		})}
	}
`;

export const SpaceBetweenContainer = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
`;

export const PlanContainer = styled.div<{ marginTop?: number }>`
	${({ marginTop = 32 }) => ({
		marginTop,
	})}
`;

export const PlanBarWrapper = styled.div<{ isUpgradeAvailable?: boolean }>`
	margin-top: 8px;
	width: 100%;
	${({ isUpgradeAvailable }) => ({
		marginBottom: !isUpgradeAvailable ? '8px' : '0px',
	})}
`;

export const ExpirationWrapper = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;
`;

export const IconWrapper = styled.div`
	padding: 16px;
	> div {
		margin: 0;
		position: relative;
		top: 50%;
		left: 50%;
		-ms-transform: translate(-50%, -50%);
		transform: translate(-50%, -50%);
	}
`;

export const ExpireContainer = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: stretch;
	gap: 4px;
	padding: 10px 12px;
	border-radius: 8px;
	text-align: center;
	${({ theme }) => ({
		backgroundColor: theme.colors.black6,
	})}
`;

export const PlanEditWarning = styled.div`
	width: 100%;
	margin-bottom: 16px;
	align-self: stretch;
	flex-grow: 0;
	display: flex;
	flex-direction: column;
	text-align: center;
	justify-content: flex-start;
	align-items: center;
	gap: 24px;
	padding: 24px;
	border-radius: 4px;
	${({ theme }) => ({
		backgroundColor: theme.colors.grey50,
	})}
`;

export const PlanEditWarningTitle = styled(TitleBold)``;

export const PlanEditWarningDescriptionUnderline = styled.span`
	text-decoration: underline;
	cursor: pointer;
	${({ theme }) => ({
		color: theme.colors.primary,
	})}
	${() => paddingRight(6)}
`;

export const BillingTypeWrapper = styled.div`
	display: felx;
	align-items: center;
`;

export const PlanBarSubtitle = styled(CaptionBold)`
	margin-top: -6px;
	${({ theme }) => ({
		color: theme.colors.black54,
	})};
`;

export const UpgradeContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	min-height: 280px;
	width: 100%;
`;

export const MarginContainer = styled.div`
	margin-top: 20px;
`;

export const CaptionHigherStyled = styled(CaptionHigher)`
	${({ theme }) => ({
		color: theme.colors.black54,
	})};
`;

export const OuterBanner = styled.div`
	width: 100%;
	height: 64px;
	align-items: center;
	margin: 24px 0 0 0;
	border-radius: 8px;
`;
export const BannerImg = styled.img`
	width: 100%;
	height: 100%;
`;
