import api from '@Utils/api';
import { Observable } from 'rxjs';
import { IProductsByCategoryId } from '@ApiModels/productsByCategoryId';
import { tap } from 'rxjs/operators';
import { setConfig } from '@Redux/modules/settings/config/actions';
import { dispatch } from '@Redux/store';
import { IForm, ControlState } from '@Redux/modules/settings/config/actions';

export interface IProductByIdSpecificSkusPayload {
	productId: string;
	itemType?: string;
	cacheOff?: boolean;
}
export interface IRelatedProductsByIdPayload {
	productId: string;
	itemType?: string;
}

export class ConfigService {
	public static getRemoteConfig(categoryId: string): Observable<IProductsByCategoryId> {
		return api.omantelShop
			.getRemoteConfig(categoryId)
			.call()
			.pipe(
				tap((r) => {
					const forms: IForm[] = r.contents.map((item) => {
						const controls = item.attributes?.map((control) => {
							return {
								id: control.id,
								identifier: control.identifier,
								name: control.name,
								value: control.values[0]?.value as ControlState,
							};
						});
						return {
							id: item.id,
							code: item.partNumber,
							name: item.name,
							controls,
						};
					});
					if (forms.length) dispatch(setConfig({ forms }));
				})
			);
	}
}
