type IStatus = {
	sequence: string;
	statusTime: string;
};
interface ITracking {
	DELIVERED: IStatus;
	REQUEST_FROM_SUPPLIER: IStatus;
	ORDER_RECEIVED: IStatus;
	PREPARED: IStatus;
	SHIPPED: IStatus;
	INSTALLATION_COMPLETED: IStatus;
	REQUEST_RECEIVED: IStatus;
	REQUEST_REJECTED: IStatus;
	INSTALLATION: IStatus;
	PROCCESSING: IStatus;
	FULFILLMENT_IN_PROGRESS: IStatus;
	REQUEST_COMPLETED: IStatus;
	REQUEST_ACCEPTED: IStatus;
	READY_TO_PICKUP: IStatus;
	COLLECTED: IStatus;
}

export type TrackingKeys = keyof ITracking;

export interface IGetOrderTracking {
	[trackingNumber: string]: {
		trackingNumber: string;
		orderItems: string[];
		tracking: ITracking;
		trackingUrl: string;
	};
}

const model: types.models.IInputModel = {
	orderTracking: 'response.response',
};

export default model;
