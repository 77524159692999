import immerHandleActions from '@Redux/utils/immerHandleActions';
import { setSortingMethod, ISetSortingMethodAction } from './actions';

export interface ISortingMethods extends draft {}

export enum SortingMethodsVariants {
	PRICE_DESCENDING = 'PRICE_DESCENDING',
	PRICE_ASSCENDING = 'PRICE_ASSCENDING',
	NEWEST_ARRIVALS = 'NEWEST_ARRIVALS',
	RECOMMENDED = 'RECOMMENDED',
}

export const init = {
	categories: SortingMethodsVariants.RECOMMENDED as string,
	products: SortingMethodsVariants.RECOMMENDED as string,
};

type draft = typeof init;

export type SortingTypes = keyof typeof init;

const reducers = {
	[setSortingMethod.toString()](draft: draft, action: ISetSortingMethodAction) {
		const { type, value } = action.payload;
		draft[type] = value;
		return draft;
	},
};

export default immerHandleActions(reducers, init);
