import { flexDirection } from '@Utils/layout/layoutSupport';
import styled from 'styled-components';
import { ButtonM } from '@Components/Typography/Typography';

interface IButtonProps {
	activeIndex?: number;
}

export const InsuranceStepperContainer = styled.div`
	padding: 8px;
`;

export const StepsContainer = styled.div`
	width: 100%;
	justify-content: space-between;
	padding-bottom: 12px;
	padding-horizontal: 16px;
	${() => flexDirection('row')};
`;

export const Divider = styled.div`
	flex: 1;
	margin-left: 10px;
	${({ theme }) => ({
		backgroundColor: theme.colors.grey300,
		maxWidth: 1,
		height: 48,
	})}
`;

export const BackButtonWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
`;

export const ButtonWrapper = styled.div`
	flex-grow: 1;
	display: flex;
	justify-content: center;
`;

export const Button = styled(ButtonM)<IButtonProps>`
	cursor: pointer;
	${({ theme, activeIndex = 1 }) => ({
		color: activeIndex === 0 ? theme.colors.grey : theme.colors.green,
	})}
`;
