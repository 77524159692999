import React, { FunctionComponent } from 'react';
import { SkeletonRow, Rectangular, DisplayFlex, SkeletonColumn } from '@Components/Skeleton/Skeleton.styled';

const OrderTrackingSkeleton: FunctionComponent = () => {
	return (
		<SkeletonRow marginTop={28} marginBottom={8}>
			<DisplayFlex>
				<Rectangular width={50} height={50} />
				<SkeletonColumn marginLeft={20}>
					<Rectangular width={200} height={16} marginBottom={16} />
					<Rectangular width={160} height={16} marginBottom={16} />
				</SkeletonColumn>
				<Rectangular width={200} height={16} marginTop={16} />
			</DisplayFlex>
		</SkeletonRow>
	);
};

export default OrderTrackingSkeleton;
