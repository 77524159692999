import React, { FunctionComponent, useState } from 'react';
import NavigationBar from '@Components/NavigationBar/NavigationBar';
import { Route, Switch } from 'react-router-dom';
import Footer from '@Components/Footer/Footer';
import CartMobile from '@Components/CartMobile/CartMobile';
import Divider from '@Components/Divider/Divider';
import { Col, Row, Container } from 'react-grid-system';
import { GuestWrapper } from './Guest.styled';
import Card from '@Components/Card/Card';
import { isLargeScreen, matchScreen } from '@Utils/layout/layoutSupport';

export const NAVBAR_HEIGHT = 100;
export const NAVBAR_HEIGHT_WITH_PADDING = 100;

const Guest: FunctionComponent = () => {
	const [footerHeight, setFooterHeight] = useState<number>(0);
	const isSmallDevice = matchScreen(['xs', 'sm']);

	return (
		<GuestWrapper footerHeight={footerHeight} minheight={window.outerHeight}>
			<NavigationBar />
			<Divider marginBottom={NAVBAR_HEIGHT_WITH_PADDING - (isLargeScreen() ? 0 : isSmallDevice ? 56 : 12)} />
			<Container>
				<Row>
					<Col>
						<Switch>
							<Route path={['/guest/orders', '/:lang/guest/orders']}>
								<Card<types.cards.IGuestOrderHistoryCardProps>
									card="guestOrderHistory"
									withoutContainer={true}
								/>
							</Route>
						</Switch>
						<Divider color="transparent" marginBottom={72} />
					</Col>
				</Row>
			</Container>
			<Footer setFooterHeight={setFooterHeight} />
			<CartMobile />
		</GuestWrapper>
	);
};

export default Guest;
