import React, { FunctionComponent } from 'react';
import { SkeletonCardContainer, Rectangular, DisplayFlex, SkeletonRow } from '@Components/Skeleton/Skeleton.styled';

const SegmentSelection: FunctionComponent = () => {
	const ITEM_HEIGHT = 20;

	return (
		<SkeletonCardContainer marginTop={16}>
			<SkeletonRow marginTop={8}>
				<Rectangular width="30%" height={ITEM_HEIGHT} marginBottom={8} />
				<DisplayFlex justifyContent="space-between">
					<Rectangular width="48%" height={56} />
					<Rectangular width="48%" height={56} />
				</DisplayFlex>
			</SkeletonRow>
		</SkeletonCardContainer>
	);
};

export default SegmentSelection;
