import React, { FunctionComponent } from 'react';
import {
	SkeletonCardContainer,
	Rectangular,
	DisplayFlex,
	SkeletonRow,
	PlaceHolder,
} from '@Components/Skeleton/Skeleton.styled';

const TelesalesCartSkeleton: FunctionComponent = () => {
	const ITEM_HEIGHT = 16;
	return (
		<>
			<SkeletonCardContainer marginBottom={24} width="100%">
				<SkeletonRow marginTop={8}>
					<DisplayFlex justifyContent="space-between">
						<Rectangular width="35%" height={ITEM_HEIGHT} marginBottom={8} />
						<PlaceHolder width="20%" />
						<Rectangular width="20%" height={ITEM_HEIGHT} marginBottom={8} />
						<PlaceHolder width="5%" />
						<Rectangular width="20%" height={ITEM_HEIGHT} marginBottom={8} />
					</DisplayFlex>
				</SkeletonRow>
				<SkeletonRow marginTop={18}>
					<DisplayFlex justifyContent="space-between">
						<Rectangular width="20%" height={75} marginBottom={8} />
						<PlaceHolder width={20} />
						<SkeletonRow marginBottom={24}>
							<Rectangular width="70%" height={24} marginBottom={8} />
							<Rectangular width="40%" height={16} marginBottom={8} />
							<Rectangular width="20%" height={16} />
						</SkeletonRow>
						<Rectangular width="25%" height={24} marginTop={16} marginBottom={8} />
						<PlaceHolder width="25%" />
						<Rectangular width="25%" height={24} marginTop={16} marginBottom={8} />
					</DisplayFlex>
				</SkeletonRow>
			</SkeletonCardContainer>
			<SkeletonCardContainer marginBottom={24} width="100%">
				<SkeletonRow marginTop={8}>
					<DisplayFlex justifyContent="space-between">
						<Rectangular width="35%" height={ITEM_HEIGHT} marginBottom={8} />
						<PlaceHolder width="20%" />
						<Rectangular width="20%" height={ITEM_HEIGHT} marginBottom={8} />
						<PlaceHolder width="5%" />
						<Rectangular width="20%" height={ITEM_HEIGHT} marginBottom={8} />
					</DisplayFlex>
				</SkeletonRow>
				<SkeletonRow marginTop={18}>
					<DisplayFlex justifyContent="space-between">
						<Rectangular width="20%" height={75} marginBottom={8} />
						<PlaceHolder width={20} />
						<SkeletonRow marginBottom={24}>
							<Rectangular width="70%" height={24} marginBottom={8} />
							<Rectangular width="40%" height={16} marginBottom={8} />
							<Rectangular width="20%" height={16} />
						</SkeletonRow>
						<Rectangular width="25%" height={24} marginTop={16} marginBottom={8} />
						<PlaceHolder width="25%" />
						<Rectangular width="25%" height={24} marginTop={16} marginBottom={8} />
					</DisplayFlex>
				</SkeletonRow>
			</SkeletonCardContainer>
		</>
	);
};

export default TelesalesCartSkeleton;
