import styled from 'styled-components';
import { ICheckboxAdditionalProps } from './Checkbox';

export enum CheckboxShape {
	CIRCULAR = 'CIRCULAR',
	SQUARE = 'SQUARE',
}

interface IWrapperProps extends ICheckboxAdditionalProps {
	active: boolean;
	disabled?: boolean;
}

interface ICheckboxContainerProps extends ICheckboxAdditionalProps {
	active: boolean;
	disabled?: boolean;
}

export const CheckboxWrapper = styled.div<IWrapperProps>`
	box-sizing: border-box;
	transition: all 0.1s ease-in-out;
	border: solid 1px ${({ theme }) => theme.colors.black12};
	${({ size }) => ({ width: size, height: size })}
	${({ shape, size }) => (shape === CheckboxShape.CIRCULAR ? { borderRadius: size / 2 } : { borderRadius: 2 })}
	${({ disabled, active, theme }) => {
		if (disabled) {
			return active
				? { backgroundColor: theme.colors.black12, borderColor: 'transparent' }
				: { backgroundColor: theme.colors.black6, borderColor: theme.colors.black12 };
		}
		return active
			? { borderColor: 'transparent', backgroundColor: theme.colors.primary }
			: { backgroundColor: theme.colors.white };
	}}
`;

export const CheckboxContainer = styled.div<ICheckboxContainerProps>`
	display: inline-block;
	width: 100%;
	height: 100%;
	transition: all 0.1s ease-in-out;
	position: relative;
	${({ shape, size }) => (shape === CheckboxShape.CIRCULAR ? { borderRadius: size / 2 } : { borderRadius: 2 })}
	&:hover {
		${({ active, disabled, theme }) =>
			!disabled &&
			(active ? { backgroundColor: theme.colors.white15 } : { backgroundColor: theme.colors.primary6 })}
	}
	&:active {
		${({ active, disabled, theme }) =>
			!disabled &&
			(active ? { backgroundColor: theme.colors.white30 } : { backgroundColor: theme.colors.primary15 })}
	}
`;

export const Input = styled.input`
	width: 100%;
	height: 100%;
	margin: 0;
	opacity: 0;
	position: relative;
	z-index: 1;
	${({ disabled }) => !disabled && { cursor: 'pointer' }}
`;

export const IconWrapper = styled.div`
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
`;

export const Wrapper = styled.div<{ reverseLabel?: boolean }>`
	display: flex;
	${({ reverseLabel }) => `flex-direction: ${reverseLabel ? 'row-reverse' : 'row'}`};
	align-items: center;
	gap: 8px;
`;

export const LabelWrapper = styled.div<{ labelClickable?: boolean }>`
	${({ labelClickable }) => ({ cursor: labelClickable ? 'pointer' : undefined })}
	display: flex;
	flex-direction: row;
	gap: 4px;
	white-space: nowrap;
`;
