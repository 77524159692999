import { useLocation } from 'react-router-dom';

interface IUseTelesales {
	personalizationId?: string;
	token?: string;
	trustedToken?: string;
	isTelesales: boolean;
}

const useTelesales = (): IUseTelesales => {
	const { search } = useLocation();

	const query = new URLSearchParams(search);
	const personalizationId = encodeURIComponent(query.get('p') as string);
	const token = encodeURIComponent(query.get('t') as string);
	const trustedToken = encodeURIComponent(query.get('tt') as string);
	const isTelesales = !!(query.get('p') && query.get('t') && query.get('tt'));

	return { personalizationId, token, trustedToken, isTelesales };
};

export default useTelesales;
