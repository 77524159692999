import React, { FunctionComponent } from 'react';
import { BottomContentWrapper, DisplayFlex, Rectangular, SkeletonRow } from '@Components/Skeleton/Skeleton.styled';

const SearchListResultsSkeleton: FunctionComponent = () => {
	return (
		<DisplayFlex divHeight="240">
			<BottomContentWrapper marginTop={24} paddingLeft={15} paddingRight={15}>
				{[1, 2, 3, 4].map((key) => (
					<SkeletonRow key={key} marginBottom={16}>
						<DisplayFlex justifyContent="space-between">
							<Rectangular width={160} height={20} color="grey300" />
						</DisplayFlex>
					</SkeletonRow>
				))}
			</BottomContentWrapper>
		</DisplayFlex>
	);
};
export default SearchListResultsSkeleton;
