import BusyIndicator from '@Components/BusyIndicator/BusyIndicator';
import ConditionalRender from '@Components/ConditionalRender/ConditionalRender';
import Stepper from '@Components/controls/Stepper/Stepper';
import { StepperState } from '@Components/controls/Stepper/Stepper.styled';
import { useCart } from '@Utils/context/CartContext';
import useScreen from '@Utils/hooks/useScreen';
import useTelesales from '@Utils/hooks/useTeleasales';
import useTranslate from '@Utils/hooks/useTranslate';
import React, { FunctionComponent, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { StepContainer, StepDivider, StepTitle, StepWrapper } from './CheckoutStepper.styled';

const STEP_SIZE = 24;

const CheckoutStepper: FunctionComponent = () => {
	const { step } = useParams<{ step: string }>();
	const { screen } = useScreen();
	const onlyGiftCardOrDigitals = useSelector((state: types.redux.IState) => state.checkout.onlyGiftCardOrDigitals);
	const { translate } = useTranslate();
	const { cart, loading, cartHbbWfbbItem } = useCart();
	const isXs = screen('xs');
	const [isTelesales] = useState(useTelesales().isTelesales);

	const steps = useMemo(() => {
		const hbbWfbbWithoutOtherItem = !!cartHbbWfbbItem && cart.items.length === 1;
		return [
			isTelesales ? translate('checkout-bar.confirmation') : undefined,
			translate('checkout-bar.step-1'),
			!onlyGiftCardOrDigitals
				? translate(hbbWfbbWithoutOtherItem ? 'checkout-bar.step-4.other-title' : 'checkout-bar.step-2')
				: undefined,
			!hbbWfbbWithoutOtherItem ? translate('checkout-bar.step-3') : undefined,
			translate('checkout-bar.step-4'),
		].filter((step) => !!step);
	}, [cart, onlyGiftCardOrDigitals]);

	return (
		<ConditionalRender
			show={!!cart.items.length}
			onTrue={() => (
				<BusyIndicator listener={loading ? 'always' : undefined}>
					<>
						{steps.map((_step, index) => {
							const stepNumber = Number(step ?? 0);
							const getStepperState = () => {
								if (stepNumber === index + (isTelesales ? 0 : 1)) {
									return 'ACTIVE';
								} else if (stepNumber > index + (isTelesales ? 0 : 1)) {
									return 'DONE';
								}
								return 'UNDONE';
							};
							return (
								<StepContainer key={index} isXs={isXs}>
									<StepWrapper>
										<Stepper
											size={STEP_SIZE}
											value={index + 1}
											state={StepperState[getStepperState()]}
										/>
									</StepWrapper>
									{!(index + 1 === steps.length) && (
										<StepDivider active={stepNumber >= index + 2} isXs={isXs} />
									)}
									<StepTitle>{_step}</StepTitle>
								</StepContainer>
							);
						})}
					</>
				</BusyIndicator>
			)}
		/>
	);
};

export default CheckoutStepper;
