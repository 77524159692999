import React, { FunctionComponent } from 'react';
import { Input, Slider, Dot, Wrapper } from './Toggle.styled';

export interface IToggleAdditionalProps {
	width: number;
	height: number;
}

export interface IToggleBaseProps {
	active: boolean;
	onChange: (event: React.FormEvent<HTMLInputElement>) => void;
	disabled?: boolean;
	pointerDisabled?: boolean;
}

export interface IToggleProps extends IToggleAdditionalProps, IToggleBaseProps {}

const Toggle: FunctionComponent<IToggleProps> = ({ active, onChange, disabled, width, height, pointerDisabled }) => {
	return (
		<Wrapper width={width} height={height} active={active} disabled={disabled}>
			<Slider active={active} height={height} disabled={disabled}>
				<Input
					type="checkbox"
					defaultChecked={active}
					onChange={onChange}
					disabled={disabled}
					pointerDisabled={pointerDisabled}
				/>
				<Dot active={active} height={height} width={width} />
			</Slider>
		</Wrapper>
	);
};

const PrimaryToggle: FunctionComponent<IToggleBaseProps> = (props) => <Toggle {...props} width={44} height={24} />;

export default PrimaryToggle;
