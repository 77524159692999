import { useMemo } from 'react';
import { useParams, useLocation, useHistory, useRouteMatch } from 'react-router-dom';
import queryString from 'query-string';

export function useRouter(): Record<string, any> {
	const params = useParams();
	const location = useLocation();
	const history = useHistory();
	const match = useRouteMatch();
	const qs: queryString.ParsedQuery = queryString.parse(location.search);
	return useMemo(() => {
		return {
			push: (path: string) => history.push(path),
			replace: (path: string) => history.replace(path),
			pull: () => history.goBack(),
			pathname: location.pathname,
			query: {
				queryString: qs,
				...params,
			},
			match,
			location,
			history,
		};
	}, [params, match, location, history]);
}
