import { Info, InfoBold } from '@Components/Typography/Typography.styled';
import { marginLeft } from '@Utils/layout/layoutSupport';
import styled from 'styled-components';

export const VatContainer = styled.div`
	display: flex;
	flex-direction: row;
`;

export const VatInfoWrapper = styled(Info)<{ vatColor?: types.theme.themeColors }>`
	${({ theme, vatColor }) => ({
		color: vatColor ?? theme.colors.black38,
	})}
`;

export const VatInfoBoldWrapper = styled(InfoBold)<{ vatColor?: types.theme.themeColors }>`
	${({ theme, vatColor }) => ({
		color: vatColor ?? theme.colors.black38,
	})}
	${() => marginLeft(2)}
`;

export const VatMessageBarWrapper = styled.div`
	${({ theme }) => ({
		backgroundColor: theme.colors.grey50,
		color: theme.colors.black54,
	})}
	height: 40px;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	gap: 16px;
	padding: 8px 16px;
	border-radius: 4px;
	margin-bottom: 24px;
	width: 100%;
`;

export const VatMessageBarCircle = styled.div`
	width: 42px;
	display: flex;
	position: relative;
`;

export const VatMessageBarValue = styled.div`
	height: 20px;
	width: 20px;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	${({ theme }) => ({
		border: `2px solid ${theme.colors.black38}`,
		color: theme.colors.black54,
	})}
	z-index: 1;
	font-size: 10px;
`;

export const VatMessageBarPercent = styled.div`
	height: 24px;
	width: 24px;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	right: 0px;
	font-size: 10px;
	${({ theme }) => ({
		color: theme.colors.white,
		backgroundColor: theme.colors.grey,
	})}
	z-index: 1;
`;
