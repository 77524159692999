import Card from '@Components/Card/Card';
import { PrimaryLargeButtonFlex, SecondaryLargeButtonFlex } from '@Components/controls/Button/Button';
import Divider from '@Components/Divider/Divider';
import Icon from '@Components/Icon/Icon';
import { setModal } from '@Redux/modules/modal/actions';
import { useCart } from '@Utils/context/CartContext';
import useTranslate from '@Utils/hooks/useTranslate';
import { getLang } from '@Utils/language/language';
import { isRTL } from '@Utils/layout/layoutSupport';
import React, { FunctionComponent } from 'react';
import { Col, Row, Visible } from 'react-grid-system';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { AddedToCartCardContainer, TitleContainer, TitleWrapper } from './AddedToCart.styled';
import { EXCLUDE_CART_ITEMS } from '@Components/GetInsurance/GetInsurance';

const CLOSE_ICON_SIZE = 24;

const AddedToCart: FunctionComponent<types.cards.IAddedToCartCardProps> = ({ params: { orderId } }) => {
	const { translate } = useTranslate();
	const dispatch = useDispatch();
	const history = useHistory();

	const { cart, loading } = useCart();
	const item = cart.items.find((it) => it.orderItemId === orderId);
	const itemWithInsurance = cart.items.find((it) => !EXCLUDE_CART_ITEMS.includes(it.partNumber));

	const handleContinueShopping = () => {
		closeModal();
	};

	const closeModal = () => {
		dispatch(
			setModal({
				type: null,
				data: null,
			})
		);
	};

	return (
		<AddedToCartCardContainer>
			<div>
				<TitleContainer>
					<TitleWrapper>{translate('added-to-card.title')}</TitleWrapper>
					<Icon
						name="close"
						fill="black87"
						width={CLOSE_ICON_SIZE}
						height={CLOSE_ICON_SIZE}
						onClick={closeModal}
					/>
				</TitleContainer>
				<Visible xs>
					<Divider />
				</Visible>
				<Card<types.cards.ICartItemsCardProps>
					card="cartItems"
					params={{
						cartItems:
							item && !EXCLUDE_CART_ITEMS.includes(item.partNumber)
								? [item]
								: itemWithInsurance
								? [itemWithInsurance]
								: [],
						withoutQuantityButtons: true,
						tableMode: false,
						addToCart: true,
						disablePreorder: true,
						loading: !!loading,
					}}
				/>
			</div>
			<Row>
				<Col md={4} sm={6} offset={{ md: isRTL() ? 0 : 4 }} style={{ padding: '8px' }}>
					<SecondaryLargeButtonFlex onClick={handleContinueShopping} uniqueId="close.modal">
						{translate('added-to-card.continue')}
					</SecondaryLargeButtonFlex>
				</Col>
				<Col md={4} sm={6} style={{ padding: '8px' }}>
					<PrimaryLargeButtonFlex
						uniqueId="close.modal.push.to.cart"
						onClick={() => {
							closeModal();
							history.push(`/${getLang()}/cart`);
						}}
					>
						{translate('added-to-card.go-to-cart')}
					</PrimaryLargeButtonFlex>
				</Col>
			</Row>
		</AddedToCartCardContainer>
	);
};

export default AddedToCart;
