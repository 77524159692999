import styled from 'styled-components';
import { DisplayMBold, TitleBold } from '@Components/Typography/Typography';
import { matchScreen, paddingLeft, right } from '@Utils/layout/layoutSupport';
export const SectionName = styled(DisplayMBold)`
	${({ theme }) => ({
		color: theme.colors.black87,
		padding: 16,
	})}
	${() => paddingLeft(16)}
`;

export const MobileSectionName = styled(TitleBold)`
	${({ theme }) => ({
		color: theme.colors.black87,
		padding: 16,
	})}
`;

export const WarningContainerTooltip = styled.div`
	width: 0;
	bottom: 92px;
	${() => right(135)};
	position: absolute;
	height: 0;
	border-left: 5px solid transparent;
	border-right: 5px solid transparent;
	border-bottom: 5px solid #f5f5f5;
`;

export const WarningContainer = styled.div`
	border-radius: 4px;
	position: absolute;
	gap: 8px;
	align-self: stretch;
	background-color: #f5f5f5;
	display: flex;
	padding: 8px;
	height: 36px;
	width: 250px;
	${() => right(10)};
	bottom: 40px;
	box-shadow: 0px 2px 8px -5px rgba(0, 0, 0, 0.5);
`;

export const StickyWrapper = styled.div`
	position: sticky;
	top: 16px;
	padding-bottom: 112px;
	display: flex;
	${() => (matchScreen(['sm', 'xs']) ? { flexDirection: 'column-reverse' } : { flexDirection: 'column' })}
`;

export const BottomSection = styled.div`
	background-color: white;
	position: fixed;
	bottom: -1px;
	display: flex;
	justify-content: center;
	width: 100%;
	z-index: 10;
`;
