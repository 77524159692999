import styled from 'styled-components';
import { TitleBold } from '@Components/Typography/Typography';

export const CardContainer = styled.div`
	padding: 16px 24px;
	display: flex;
	flex-direction: column;
	width: 70vw;
	min-width: 300px;
	max-width: 800px;
`;

export const CartTitle = styled(TitleBold)`
	padding-top: 8px;
	padding-bottom: 16px;
	${({ theme }) => ({
		color: theme.colors.black87,
	})}
`;

export const ActionWrapper = styled.div`
	display: flex;
	justify-content: flex-end;
`;

export const ContentWrapper = styled.div`
	padding: 24px 0;
`;

export const PriceCountry = styled.div`
	display: inline-flex;
	gap: 8px;
`;

export const EmailCodes = styled.div`
	display: flex;
	justify-content: center;
`;
