import React, { useState, useEffect } from 'react';
import useTranslate from '@Utils/hooks/useTranslate';
import { Row, Col } from 'react-grid-system';
import {
	InsuranceOfferCardWraper,
	Image,
	ImageWrapper,
	TitleWrapper,
	ValueWrapper,
	CurrencyWrapper,
	SubheadingWrapper,
	PriceWrapper,
} from './InsuranceSelectedOfferValue.styled';
import { roundMoney } from '@Utils/converters/roundMoney';

const InsuranceSelectedOfferValue = ({
	params: { selectedProvider, selectedReferenceNo },
}: types.cards.IInsuranceSelectedOfferValueCardProps): JSX.Element => {
	const { translate } = useTranslate();
	const [insuranceValue, setInsuranceValue] = useState('');

	useEffect(() => {
		const selectedOffer = selectedProvider?.offers?.find(({ referenceNo }) => referenceNo === selectedReferenceNo);
		if (selectedOffer) setInsuranceValue(selectedOffer.amount);
	}, [selectedProvider, selectedReferenceNo]);

	return (
		<InsuranceOfferCardWraper>
			<Row align="center" justify="center">
				<Col sm={2}>
					<ImageWrapper>
						<Image src={selectedProvider?.thumbnailImageUrl} />
					</ImageWrapper>
				</Col>
				<Col>
					<TitleWrapper>{selectedProvider?.name}</TitleWrapper>
				</Col>
			</Row>
			<PriceWrapper>
				<CurrencyWrapper>{translate('omr')}</CurrencyWrapper>
				<ValueWrapper>{roundMoney(insuranceValue)} </ValueWrapper>
			</PriceWrapper>
			<SubheadingWrapper>{translate('insurance.offer.total-amount.label')}</SubheadingWrapper>
		</InsuranceOfferCardWraper>
	);
};

export default InsuranceSelectedOfferValue;
