import { BillingType } from '@ApiModels/accounts';
export interface IRecommendedPlan {
	billingType: BillingType;
	createdAt: string;
	eformId: string;
	msisdn: string;
	productId: string;
	recommendedPlan: string;
	vs: string;
	legacyProductId?: string;
	legacyEformId?: string;
}

export interface IRecommendedPlans {
	recommendedPlans: IRecommendedPlan[];
}

const model = {
	recommendedPlans: ['response.getRecommendedPlanResponse.recommendedPlan', []],
};

export default model;
