import styled from 'styled-components';
import { TABLE_CONTAINER_WIDTH } from '@Views/Comparison/Comparison.styled';
import { marginLeft, marginRight } from '@Utils/layout/layoutSupport';
import { BodySBold, BodyS } from '@Components/Typography/Typography';
import { MiniDescription } from '@Components/Typography/Typography.styled';

export const ProductContainer = styled.div`
	width: ${TABLE_CONTAINER_WIDTH - 16}px;
	padding: 8px;
	display: flex;
	flex-direction: row;
`;

export const BrandWrapper = styled(BodySBold)`
	${() => marginRight(4)}
	display: inline;
`;

export const TitleWrapper = styled.div`
	display: inline;
	vertical-align: sub;
`;

export const ProductNameWrapper = styled(BodyS)`
	word-wrap: break-word;
	display: inline;
`;

export const PriceWrapper = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	width: 100%;
	align-items: center;
	.duration {
		${({ theme }) => ({
			color: theme.colors.black54,
		})}
	}
`;

export const InfoContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	width: 100%;
	height: 56px;
	padding: 4px;
	${() => marginLeft(12)}
`;

export const TextWrapper = styled(MiniDescription)`
	${({ theme }) => ({
		color: theme.colors.black54,
	})}
`;
