import ConditionalRender from '@Components/ConditionalRender/ConditionalRender';
import { PrimaryMediumButtonFlex } from '@Components/controls/Button/Button';
import Icon from '@Components/Icon/Icon';
import { IStatusModalProps } from '@Components/Modal/modals/Status/Status';
import PlanBar from '@Components/PlanBar/PlanBar';
import { PlanBarType } from '@Components/PlanBar/PlanBarType';
import PriceTag from '@Components/PriceTag/PriceTag';
import PriceWithVat from '@Components/PriceWithVat/PriceWithVat';
import { TitleBold } from '@Components/Typography/Typography';
import { ComparisonCurrency, Status } from '@Components/Typography/Typography.styled';
import { addBusyIndicator, removeBusyIndicator } from '@Redux/modules/busyIndicator/actions';
import { modalTypes, setModal } from '@Redux/modules/modal/actions';
import { AccountsService } from '@Services/accounts/accountsService';
import { AuthenticationService } from '@Services/authentication/authenticationService';
import { ProductsService } from '@Services/productsService/productsService';
import { ProfileService } from '@Services/profile/profileService';
import { usePrepaidMigration } from '@Utils/context/PrepaidMigrationContext';
import externalLinks from '@Utils/externalLinks/externalLinks';
import useScreen from '@Utils/hooks/useScreen';
import useTranslate from '@Utils/hooks/useTranslate';
import moment from 'moment';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AjaxError } from 'rxjs/ajax';
import { IMigrationPlanComparison } from '../PlanComparison';
import {
	ApplyButtonContainer,
	Empty,
	EmptyLine,
	ErrorText,
	ErrorTitle,
	InfoWrapper,
	PlanDataItem,
	PlanHeader,
	PlanHeaderCommitment,
	PlanHeaderTitle,
	PlanHeaderType,
	PlanItem,
	PlanItems,
	PlanItemValue,
	PlanWrapper,
	RowTitle,
	RowWrapper,
	SuccessText,
	SuccessTitle,
	Unlimited,
} from './Plan.styled';
import { CUSTOMER_CARE_PHONE, CUSTOMER_B2B2C_CARE_PHONE } from '@Config/app.config';

interface IPlanProps {
	plan: IMigrationPlanComparison;
	max: number;
	index: number;
}

const VALIDATE_OPERATION_BI = 'validateOperation';

const Plan: FunctionComponent<IPlanProps> = ({ plan, max, index }) => {
	const { translate } = useTranslate();
	const { screen } = useScreen();
	const isMDLower = screen(['xs', 'sm', 'md']);
	const dispatch = useDispatch();
	const isLogged = useSelector((state: types.redux.IState) => state.api.authentication.signedIn);
	const { choosenNumber } = usePrepaidMigration();
	const [isMaster, setIsMaster] = useState(false);
	const isB2b = useSelector((state: types.redux.IState) => state.api.authentication.isB2b);
	const isB2b2c = useSelector((state: types.redux.IState) => state.api.authentication.isB2b2c);

	const RowTitleXS = ({ title }: { title: string }) => {
		return (
			<ConditionalRender
				show={isMDLower}
				onTrue={() => (
					<RowWrapper>
						<RowTitle>{translate(title)}</RowTitle>
					</RowWrapper>
				)}
			/>
		);
	};

	useEffect(() => {
		if (isLogged) {
			ProfileService.getUserProfile().subscribe(
				({ isMaster }) => {
					setIsMaster(isMaster);
				},
				() => {}
			);
		}
	}, [isLogged]);

	const onError = (e?: AjaxError) => {
		dispatch(setModal({ closeAllModals: true, type: null }));

		let title = '';
		let text = '';
		let primaryButtonText = '';
		let linkName: 'CONTACT_US' | 'AROUND_ME' | 'ACCOUNT' = 'CONTACT_US';

		switch (e?.response?.code) {
			case 'BILLS_OUTSTANDING_AMOUNT_EXCEEDED': {
				title = translate('migration-plan-selector.error.title-1');
				text = translate('migration-plan-selector.error.BILLS_OUTSTANDING_AMOUNT_EXCEEDED');
				primaryButtonText = translate('migration-plan-selector.pay-bills');
				linkName = 'ACCOUNT';
				break;
			}
			case 'REGISTERED_DOCUMENT_EXPIRED': {
				title = translate('migration-plan-selector.error.title-1');
				text = translate('migration-plan-selector.error.REGISTERED_DOCUMENT_EXPIRED');
				primaryButtonText = translate('migration-plan-selector.outlet');
				linkName = 'AROUND_ME';
				break;
			}
			default: {
				title = translate('migration-plan-selector.error.title');
				text = translate(
					'migration-plan-selector.error.text',
					isB2b2c || isB2b ? CUSTOMER_B2B2C_CARE_PHONE : CUSTOMER_CARE_PHONE
				);
				primaryButtonText = translate('migration-plan-selector.contact');
			}
		}

		const data: IStatusModalProps = {
			status: 'error',
			body: (
				<>
					<ErrorTitle>{title}</ErrorTitle>
					<ErrorText>{text}</ErrorText>
				</>
			),
			primaryButtonText,
			onClickPrimary: () => {
				window.location.href = externalLinks(linkName);
			},
		};
		dispatch(setModal({ type: modalTypes.STATUS, withoutContainer: true, data }));
	};

	const onSuccess = () => {
		dispatch(setModal({ closeAllModals: true, type: null }));
		const data: IStatusModalProps = {
			status: 'success',
			body: (
				<>
					<SuccessTitle>{translate('migration-plan-selector.success.title')}</SuccessTitle>
					<SuccessText>{translate('migration-plan-selector.success.text')}</SuccessText>
					<PlanBar
						barType={PlanBarType.BAQATI}
						plan={{
							name: plan.name,
							expireDate: moment().add(plan.duration, 'm').format('DD.MM.YYYY'),
							validity: plan.contractCommitment,
							price: Number(plan.monthlyPayment ?? 0),
							vat: Number(plan?.monthlyPayment ?? 0) + Number(plan.planVatValue ?? 0),
							vatPercent: Number(plan.planVatPercent ?? 0),
							hasCommitment: !!plan.duration,
							minutes: plan.localMinutes,
							internet: plan.localAndGccData,
						}}
						width={isMDLower ? undefined : '100%'}
					/>
				</>
			),
			secondaryButtonText: translate('migration-plan-selector.go-home'),
			onClickSecondary: () => {
				window.location.href = externalLinks('OMANTEL');
			},
		};
		dispatch(setModal({ type: modalTypes.STATUS, withoutContainer: true, data }));
	};

	const sendOtp = () => {
		dispatch(addBusyIndicator(VALIDATE_OPERATION_BI));
		AccountsService.getAccountsForLoggedUser().subscribe(
			(accounts) => {
				if (accounts.accounts.find(({ accountNum }) => accountNum === choosenNumber)) {
					if (!isMaster) {
						ProductsService.validateOperation().subscribe(
							({ masterMsisdn }) => {
								const data: types.cards.IOTPCardProps['params'] = {
									msisdn: masterMsisdn,
									onClickResend: ProductsService.validateOperation(),
									onConfirm: ProductsService.submitProductOrder({
										productId: plan.partNumber,
										configurationProduct: 'PRE_TO_POST',
										subscriberNo: choosenNumber,
									}),
									onConfirmError: onError,
									onConfirmSuccess: onSuccess,
									operationId: '18',
								};
								dispatch(setModal({ type: modalTypes.OTP, withoutContainer: true, data }));
								dispatch(removeBusyIndicator(VALIDATE_OPERATION_BI));
							},
							() => {
								dispatch(removeBusyIndicator(VALIDATE_OPERATION_BI));
							}
						);
					} else {
						ProductsService.submitProductOrder({
							productId: plan.partNumber,
							configurationProduct: 'PRE_TO_POST',
							subscriberNo: choosenNumber,
						}).subscribe(
							() => {
								onSuccess();
								dispatch(removeBusyIndicator(VALIDATE_OPERATION_BI));
							},
							(e) => {
								onError(e);
								dispatch(removeBusyIndicator(VALIDATE_OPERATION_BI));
							}
						);
					}
				} else {
					dispatch(removeBusyIndicator(VALIDATE_OPERATION_BI));
					const data: IStatusModalProps = {
						iconFill: 'primary',
						status: 'success',
						icon: 'warning',
						primaryButtonText: translate('migration-plan-selector.log-out'),
						onClickPrimary: () => {
							AuthenticationService.logout().subscribe(
								() => {
									dispatch(setModal({ closeAllModals: true, type: null }));
								},
								() => {}
							);
						},
						secondaryButtonText: translate('migration-plan-selector.go-home'),
						onClickSecondary: () => {
							window.location.href = externalLinks('OMANTEL');
						},
						body: (
							<>
								<InfoWrapper>
									<Status>
										{translate('migration-plan-selector.not-linked')}
										<span>{choosenNumber}.</span>
									</Status>
								</InfoWrapper>
								<InfoWrapper>
									<Status>{translate('migration-plan-selector.log-off')}</Status>
								</InfoWrapper>
							</>
						),
					};
					dispatch(setModal({ type: modalTypes.STATUS, withoutContainer: true, data }));
				}
			},
			() => {
				dispatch(removeBusyIndicator(VALIDATE_OPERATION_BI));
			}
		);
	};

	const PlanTableRows = () => {
		return (
			<>
				<RowTitleXS title="plan-comparison.monthly.payment" />
				<PlanItem>
					<ConditionalRender
						show={!!plan.monthlyPayment}
						onTrue={() => (
							<>
								<PriceTag price={plan.monthlyPayment} duration={translate('plan-bar.mo')} />
								<PriceWithVat
									pricesWithVat={{
										offerPrice: String(
											Number(plan?.monthlyPayment ?? 0) + Number(plan.planVatValue ?? 0)
										),
										listPrice: '',
										taxablePrice: '0',
										vatValue: String(plan.planVatValue),
										vatPercent: String(plan.planVatPercent ?? 0),
									}}
								/>
							</>
						)}
						onFalse={() => (
							<Empty>
								<EmptyLine></EmptyLine>
							</Empty>
						)}
					/>
				</PlanItem>
				<RowTitleXS title="plan-comparison.local.gcc.data" />
				<ConditionalRender
					show={!!plan.localAndGccData}
					onTrue={() =>
						plan.localAndGccData !== 'Unlimited' ? (
							<PlanDataItem padding={16}>
								<PlanItemValue>
									<TitleBold>{plan.localAndGccData}</TitleBold>
									<ComparisonCurrency>{translate('gb.simple')}</ComparisonCurrency>
								</PlanItemValue>
							</PlanDataItem>
						) : (
							<PlanDataItem padding={16}>
								<Unlimited>
									<Icon name="unlimited" fill="black87" height={32} width={32} />
								</Unlimited>
							</PlanDataItem>
						)
					}
					onFalse={() => (
						<PlanItem padding={40}>
							<Empty>
								<EmptyLine></EmptyLine>
							</Empty>
						</PlanItem>
					)}
				/>
				<RowTitleXS title="plan-comparison.local.minutes" />
				<PlanItem padding={16}>
					<ConditionalRender
						show={!!plan.localMinutes}
						onTrue={() =>
							plan.localMinutes !== 'Unlimited' ? (
								<PlanItemValue>
									<TitleBold>{plan.localMinutes}</TitleBold>
									<ComparisonCurrency>{translate('min')}</ComparisonCurrency>
								</PlanItemValue>
							) : (
								<Unlimited>
									<Icon name="unlimited" fill="black87" height={32} width={32} />
									<TitleBold>*</TitleBold>
								</Unlimited>
							)
						}
						onFalse={() => (
							<Empty>
								<EmptyLine></EmptyLine>
							</Empty>
						)}
					/>
				</PlanItem>
				<RowTitleXS title="plan-comparison.international.minutes" />
				<PlanItem padding={16}>
					<ConditionalRender
						show={!!plan.internationalMinutes}
						onTrue={() =>
							plan.internationalMinutes !== 'Unlimited' ? (
								<PlanItemValue>
									<TitleBold>{plan.internationalMinutes}</TitleBold>
									<ComparisonCurrency>{translate('min')}</ComparisonCurrency>
								</PlanItemValue>
							) : (
								<Unlimited>
									<Icon name="unlimited" fill="black54" height={32} width={32} />
								</Unlimited>
							)
						}
						onFalse={() => (
							<Empty>
								<EmptyLine></EmptyLine>
							</Empty>
						)}
					/>
				</PlanItem>
				<RowTitleXS title="plan-comparison.roaming.recieving.minutes" />
				<PlanItem padding={16}>
					<ConditionalRender
						show={!!plan.roamingRecievingMinutes}
						onTrue={() =>
							plan.roamingRecievingMinutes !== 'Unlimited' ? (
								<PlanItemValue>
									<TitleBold>{plan.roamingRecievingMinutes}</TitleBold>
									<ComparisonCurrency>{translate('min')}</ComparisonCurrency>
								</PlanItemValue>
							) : (
								<Unlimited>
									<Icon name="unlimited" fill="black54" height={32} width={32} />
								</Unlimited>
							)
						}
						onFalse={() => (
							<Empty>
								<EmptyLine></EmptyLine>
							</Empty>
						)}
					/>
				</PlanItem>
				<RowTitleXS title="plan-comparison.world.roaming.data" />
				<PlanItem padding={16}>
					<ConditionalRender
						show={!!plan.worldRoamingData}
						onTrue={() =>
							plan.worldRoamingData !== 'unlimited' ? (
								<PlanItemValue>
									<TitleBold>{plan.worldRoamingData}</TitleBold>
									<ComparisonCurrency>{translate('gb.simple')}</ComparisonCurrency>
								</PlanItemValue>
							) : (
								<Unlimited>
									<Icon name="unlimited" fill="black54" height={32} width={32} />
								</Unlimited>
							)
						}
						onFalse={() => (
							<Empty>
								<EmptyLine></EmptyLine>
							</Empty>
						)}
					/>
				</PlanItem>
				<RowTitleXS title="plan-comparison.data.sharing" />
				<PlanItem padding={16}>
					<ConditionalRender
						show={!!plan.dataSharing}
						onTrue={() =>
							plan.dataSharing !== 'unlimited' ? (
								<PlanItemValue>
									<TitleBold>{plan.dataSharing}</TitleBold>
									<ComparisonCurrency>{translate('gb.simple')}</ComparisonCurrency>
								</PlanItemValue>
							) : (
								<Unlimited>
									<Icon name="unlimited" fill="black54" height={32} width={32} />
								</Unlimited>
							)
						}
						onFalse={() => (
							<Empty>
								<EmptyLine></EmptyLine>
							</Empty>
						)}
					/>
				</PlanItem>
				<ApplyButtonContainer>
					<PrimaryMediumButtonFlex
						uniqueId="plan-select"
						onClick={() => {
							if (isLogged) {
								sendOtp();
							} else {
								const data: types.cards.ILoginCardProps['params'] = {
									withoutFooter: true,
									onContinue: sendOtp,
								};
								dispatch(
									setModal({
										type: modalTypes.LOGIN,
										data,
									})
								);
							}
						}}
						listener={VALIDATE_OPERATION_BI}
					>
						{translate('migration-plan-selector.apply')}
					</PrimaryMediumButtonFlex>
				</ApplyButtonContainer>
			</>
		);
	};

	return (
		<PlanWrapper index={index} max={max} className="PlanWrapper">
			<PlanHeader planType={plan.type}>
				<PlanHeaderTitle>{plan.name.replace('BAQATI ', '')}</PlanHeaderTitle>
				<PlanHeaderCommitment>{plan.contractCommitment}</PlanHeaderCommitment>
				<ConditionalRender
					show={!!plan.isRecommendedPlan}
					onTrue={() => <PlanHeaderType>{translate('plan-comparison.recommended.plan')}</PlanHeaderType>}
				/>
			</PlanHeader>
			<PlanItems>
				<PlanTableRows />
			</PlanItems>
		</PlanWrapper>
	);
};

export default Plan;
