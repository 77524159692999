import immerHandleActions from '@Redux/utils/immerHandleActions';
import { ISettingsConfig, ISettingsConfigData, setConfig } from './actions';

const init = {
	accountCategory: null,
	forms: [],
};

const reducers = {
	[setConfig.toString()](_state: ISettingsConfig, action: ISettingsConfigData) {
		return { ..._state, ...action.payload };
	},
};

export default immerHandleActions<ISettingsConfig>(reducers, init);
