import { BodyMBold, BodySBold } from '@Components/Typography/Typography.styled';
import { isRTL } from '@Utils/layout/layoutSupport';
import styled, { CSSProperties } from 'styled-components';

export const OrderItemWrapper = styled.div`
	padding: 16px;
	border-radius: 8px;
	${({ theme }) => ({
		backgroundColor: theme.colors.grey50,
	})}
`;

export const IcoWrapper = styled.div`
	position: absolute;
`;

export const Status = styled.div`
	margin-top: 4px;
`;

export const StatusWrapper = styled.div`
	padding-top: 8px;
	${() =>
		isRTL()
			? {
					float: 'left',
			  }
			: {
					float: 'right',
			  }}
`;

export const Horizontal = styled.div`
	display: flex;
	gap: 8px;
`;

export const PaymentDetails = styled.div`
	display: flex;
	gap: 18px;
	width: 100%;
`;

export const PaymentDetailsDesc = styled.div<{ justifyContent?: CSSProperties['justifyContent'] }>`
	display: flex;
	flex-direction: column;
	${({ justifyContent = 'center' }) => ({ justifyContent })};
`;

export const Link = styled.div`
	cursor: pointer;
	width: fit-content;
	p {
		display: inline;
	}
`;

export const Underline = styled(BodySBold)`
	text-decoration: underline;
`;

export const PaymentDetailsTitle = styled(BodyMBold)`
	${({ theme }) => ({
		color: theme.colors.black87,
	})};
	margin-bottom: 4px;
`;

export const PaymentAndDeliveryContainer = styled.div`
	display: flex;
	margin-bottom: 64px;
`;
