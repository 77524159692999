import { ISinglePackage, IInputCommerceAttribute } from '@ApiModels/singlePackage';
import modelsResolver from '@ApiModels/utils/modelsResolver';
import { convertGetUsageItemsToEcommerce } from '@Utils/ecommerce/services/extract';
import { Moment } from 'moment';
import { format } from '@Utils/date/moment';

export const TARIFF_PACKAGE = 'TARIFF_PACKAGE';
const DATA_TARIFF_SB = 'DATA_TARIFF_SB';
const TARIFF_WFBB = 'TARIFF_WFBB';
const TARIFF_WFBB_5G = 'TARIFF_WFBB_5G';
const DATA_PROVISIOINING = 'DATA_PROVISIOINING';
const HBB_COMMITMENT = 'HBB_COMMITMENT';
const BAQATI_DATA_CONTRACT = 'BAQATI_DATA_CONTRACT';
const BAQATI_DATA_CONTRACT_DUMMY = 'BAQATI_DATA_CONTRACT_DUMMY';
const BAQATI_COMMITMENT = 'BAQATI_COMMITMENT';
export interface IProductEntry {
	productId: string;
	legacyProductId: string;
	name: string;
	startDate: string;
	endDate: string;
	group: string;
	commerceAttributes?: IInputCommerceAttribute[];
}

export interface IProductOutput {
	productId: string;
	legacyProductId: string;
	name: string;
	startDate: string | Moment;
	endDate: string | Moment | null;
	group: string;
	commerceAttributes?: ISinglePackage;
}

export interface ICustomerProducts {
	_products: IProductEntry[];
	products: IProductOutput[];
	activeProduct: IProductOutput;
	activeProvisioning: IProductOutput;
	activePlanCommitment: IProductOutput;
	isHBB: boolean;
	isWFBB5G?: boolean;
	isWFBB4G?: boolean;
	isBaquati?: boolean;
	oldDeviceCommitmentId?: string;
	oldDeviceCommitmentCanBeExtended?: boolean;
	sendDeviceCommitment?: boolean;
	userHaveActive4G5GPlan: boolean;
	activeDeviceAccessoriesInstallmentId?: string;
	activeMobileDeviceInstallmentId?: string;
}

const inputPackageToParsedPackage = (input: IProductEntry) => {
	const tmpOutput: any = {};
	Object.assign(tmpOutput, input);
	const output: IProductOutput = tmpOutput as IProductOutput;
	output.commerceAttributes = {} as IProductOutput['commerceAttributes'];

	if (input.commerceAttributes) {
		const inputCommerceAttributes = input.commerceAttributes.filter(
			(commerceAttribute) => commerceAttribute.values
		);
		const inputCommerceAttributesAsEcommerce = convertGetUsageItemsToEcommerce(inputCommerceAttributes);
		output.commerceAttributes = modelsResolver(
			{ entry: inputCommerceAttributesAsEcommerce },
			'singlePackage'
		) as ISinglePackage;
	}
	output.startDate = format(input.startDate, { format: 'YYYY-MM-DD HH:mm:ss', toFormat: 'DD.MM.YYYY' });
	output.endDate = input.endDate ? format(input.endDate, { format: 'YYYY-MM-DD', toFormat: 'DD.MM.YYYY' }) : null;

	return output;
};

const getActiveProduct = (products: IProductOutput[], productGroups: string[]) => {
	return products.find((product) => productGroups.includes(product.group));
};

const model = {
	_products: ['response.getCustomerProductsResponse.products', []],
	products: (model: ICustomerProducts): IProductOutput[] => {
		return model._products.map(inputPackageToParsedPackage);
	},
	activeProduct: (model: ICustomerProducts): IProductOutput | undefined => {
		return getActiveProduct(model.products, [TARIFF_PACKAGE, DATA_TARIFF_SB, TARIFF_WFBB, TARIFF_WFBB_5G]);
	},
	activeProvisioning: (model: ICustomerProducts): IProductOutput | undefined => {
		return getActiveProduct(model.products, [DATA_PROVISIOINING]);
	},
	activePlanCommitment: (model: ICustomerProducts): IProductOutput | undefined => {
		return getActiveProduct(model.products, [HBB_COMMITMENT || 'TARIFF_WFBB_5G' || 'TARIFF_WFBB']);
	},
	isHBB: (model: ICustomerProducts): boolean => {
		return !!getActiveProduct(model.products, [DATA_TARIFF_SB]);
	},
	isWFBB5G: (model: ICustomerProducts): boolean => {
		return !!getActiveProduct(model.products, [TARIFF_WFBB_5G]);
	},
	isWFBB4G: (model: ICustomerProducts): boolean => {
		return !!getActiveProduct(model.products, [TARIFF_WFBB]);
	},
	isBaquati: (model: ICustomerProducts): boolean => {
		return !!getActiveProduct(model.products, [
			BAQATI_DATA_CONTRACT,
			BAQATI_DATA_CONTRACT_DUMMY,
			BAQATI_COMMITMENT,
		]);
	},
	userHaveActive4G5GPlan: (model: ICustomerProducts): boolean => {
		return getActiveProduct(model.products, [TARIFF_WFBB_5G])?.commerceAttributes?.bandWidthType === '4G5G';
	},
};

export default model;
