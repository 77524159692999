import { ICartItem, IInsuranceCartItem, ICartItemPlan } from '@ApiModels/viewCart';

export const isVoucherPlanAction = (cartItem: ICartItem | IInsuranceCartItem | ICartItemPlan | undefined): boolean => {
	return (
		[
			'B2B_WITH_VOUCHER_INSTALL',
			'B2B_WITH_VOUCHER_UPFRONT',
			'B2B2C_WITH_VOUCHER_INSTALL',
			'B2B2C_WITH_VOUCHER_UPFRONT',
		].indexOf(cartItem?.PA || '') !== -1
	);
};
