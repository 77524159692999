import { matchScreen, paddingRight, right } from '@Utils/layout/layoutSupport';
import styled from 'styled-components';
import { ParagraphMBold } from '@Components/Typography/Typography.styled';

export const CardWrapper = styled.div`
	padding: 8px 16px;
`;

export const AddressBookEmpty = styled.div`
	padding: 48px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	border-radius: 8px;
	${({ theme }) => ({
		backgroundColor: theme.colors.grey50,
	})}
`;

export const AddNewAddressButtonWrapper = styled.div`
	padding: 16px;
	width: 200px;
	align-self: center;
`;

export const IconWrapper = styled.div<{ width: number }>`
	${({ width }) => ({
		width: `${width}px`,
	})}
	${() => paddingRight(16)}
`;

export const AddressBookElement = styled.div`
	padding: 16px;
	border-radius: 8px;
	margin-bottom: 24px;
	${({ theme }) => ({
		backgroundColor: theme.colors.grey50,
	})}
`;

export const Horizontal = styled.div`
	display: flex;
	${() => ({
		flexDirection: matchScreen(['xs', 'sm']) ? 'column' : 'row',
	})}
`;

export const Column = styled.div`
	display: flex;
	flex-direction: row;
`;

export const TextAlign = styled.div`
	margin-top: 6px;
	${() => ({
		maxWidth: matchScreen(['xs', 'sm', 'md']) ? '230px' : '340px',
	})}
`;

export const ToggleWrapper = styled.div`
	display: flex;
	margin-top: 6px;
	position: absolute;
	${() => right(56)}
`;

export const ToggleText = styled.div`
	padding: 6px;
`;

export const ChevronWrapper = styled.div`
	display: flex;
	margin-top: 8px;
	position: absolute;
	cursor: pointer;
	${() => right(24)}
`;

export const MapWrapper = styled.div`
	${() => ({
		height: matchScreen('xs') ? 160 : 230,
	})}
`;

export const MapElement = styled.div`
	width: 100%;
	height: 100%;
	${() => ({
		paddingTop: matchScreen('xs') ? 16 : 0,
	})}
`;

export const AddressValue = styled(ParagraphMBold)`
	${({ theme }) => ({
		color: theme.colors.black87,
	})}
	word-wrap: break-word;
`;
