import immerHandleActions from '@Redux/utils/immerHandleActions';
import { setPriceFilter, ISetPriceFilterAction, clearPriceFilter } from './actions';

export const init: ISetPriceFilterAction['payload'] = {
	active: true,
};

type draft = typeof init;

const reducers = {
	[setPriceFilter.toString()](draft: draft, action: ISetPriceFilterAction) {
		if (action.payload.max) {
			draft.max = action.payload.max;
		} else if (action.payload.max === 0) {
			draft.max = undefined;
		}
		if (action.payload.min) {
			draft.min = action.payload.min;
		} else if (action.payload.min === 0) {
			draft.min = 0;
		}
		if (action.payload.active !== undefined) {
			draft.active = action.payload.active;
		}
		return draft;
	},
	[clearPriceFilter.toString()](draft: draft) {
		draft.max = undefined;
		draft.min = undefined;
		draft.active = true;
		return draft;
	},
};

export default immerHandleActions(reducers, init);
