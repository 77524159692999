import { createAction } from 'redux-actions';

export interface IHeader {
	name: string;
	value?: string;
}

export const updateHeader = createAction('UPDATE_HEADER', (action: IHeader) => action);

export const deleteWCTokens = createAction('DELETE_WC_TOKENS', () => {});
