import styled from 'styled-components';
import { ParagraphMBold, BodyS, BodyM } from '@Components/Typography/Typography';
export interface IDateTimePickerProps {
	isTodayDateVisible: boolean;
}

export const DateTimePickerWrapper = styled.div`
	align-self: stretch;
	flex-grow: 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 24px;
	padding: 16px 0 0;
`;

export const MonthsTitle = styled(ParagraphMBold)<IDateTimePickerProps>`
	${({ theme, isTodayDateVisible }) => ({
		color: theme.colors.black87,
		paddingRight: isTodayDateVisible ? 0 : 103,
	})}
	text-align: center;
	flex-grow: 1;
`;

export const CalendarDaysInnerWrapper = styled.div`
	flex-grow: 0;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: flex-start;
	gap: 8px;
	padding: 0;
`;

export const CalendarDaysOuterWrapper = styled.div`
	flex-grow: 0;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	gap: 25px;
	padding: 0;
`;

export const TimeRangeItemsWrapper = styled.div<{ isXs: boolean }>`
	height: 48px;
	align-self: stretch;
	flex-grow: 0;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	gap: 8px;
	padding: 0 96px;
	${({ isXs }) => ({
		padding: isXs ? 0 : '0 96px',
	})}
`;

export const AvailableSlots = styled(BodyS)<{ isXs: boolean }>`
	${({ theme }) => ({
		color: theme.colors.primary,
	})}
	text-align: left;
	align-self: flex-start;
	height: 24px;
	${({ isXs }) => ({
		paddingLeft: isXs ? 0 : '60px',
	})}
`;

export const HoursItemsOuterWrapper = styled.div`
	align-self: stretch;
	flex-grow: 0;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	padding-bottom: 32px;
`;

export const SlotsWrapper = styled.div`
	flex-grow: 0;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	gap: 8px;
	padding: 0;
`;

export const HoursItemsInnerWrapper = styled.div`
	flex-grow: 0;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	gap: 24px;
	padding: 0;
`;

export const ButtonsWrapper = styled.div<{ isXs: boolean }>`
	display: flex;
	width: 100%;
	gap: 16px;
	justify-content: flex-end;
	${({ isXs }) => ({
		flexDirection: isXs ? 'column-reverse' : 'row',
		alignItems: isXs ? 'center' : 'flex-end',
	})}
`;

export const MonthsTitleWrapper = styled.div<IDateTimePickerProps>`
	display: flex;
	justify-content: flex-start;
	align-self: flex-start;
	padding: 0 49px;
	gap: 16px;
	align-self: stretch;
	min-height: 40px;
	${({ isTodayDateVisible }) => ({
		alignItems: isTodayDateVisible ? 'center' : 'baseline',
	})}
`;

export const UnavailableAppointmentWrapper = styled.div`
	padding: 29px 34px 32px 34px;
	display: flex;
	flex-direction: column;
	gap: 20px;
`;

export const UnavailableAppointmentInfo = styled(BodyM)`
	${({ theme }) => ({
		color: theme.colors.black38,
	})}
`;

export const WarningContainer = styled.div`
	border-radius: 4px;
	gap: 8px;
	align-self: stretch;
	${({ theme }) => ({ backgroundColor: theme.colors.warning6 })}
	display: flex;
	padding: 8px;
	margin: 0 49px;
`;
