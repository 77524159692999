import React, { FunctionComponent, useEffect, useState, useMemo } from 'react';
import { IDropdownItem, RegularDropdown } from '@Components/controls/Dropdown/Dropdown';
import {
	ContactDetailsWrapper,
	Subtitle,
	IconWrapper,
	ButtonsWrapper,
	HeaderWrapper,
	FormWrapper,
	DatePickerFieldWrapper,
} from './ContactDetails.styled';
import { DropdownHeight, LabelType } from '@Components/controls/Dropdown/Dropdown.styled';
import TextField from '@Components/controls/TextField/TextField';
import Divider from '@Components/Divider/Divider';
import { ValueBold } from '@Components/Typography/Typography.styled';
import useTranslate from '@Utils/hooks/useTranslate';
import personalDataValidator from '@Utils/validators/personalDataValidator';
import { Col, Row, Hidden, Visible } from 'react-grid-system';
import Icon from '@Components/Icon/Icon';
import { Formik } from 'formik';
import ConditionalRender from '@Components/ConditionalRender/ConditionalRender';
import { PrimaryMediumButtonAuto, TertiaryMediumButtonAuto } from '@Components/controls/Button/Button';
import useCommerceProfile from '@Utils/hooks/useCommerceProfile';
import { useHistory } from 'react-router';
import { getLang } from '@Utils/language/language';
import { dispatch } from '@Redux/store';
import { setPersistentCheckoutDetails } from '@Redux/modules/persistentCheckoutData/actions';
import DatePickerField from '@Components/controls/DatePicker/DatePickerField';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { ControlState } from '@Redux/modules/settings/config/actions';
export interface IFormikFields {
	title?: string;
	firstName: string;
	middleName: string;
	lastName: string;
	email: string;
	phoneNumber: string;
	birthDate: string;
	fatherName: string;
	grandfatherName: string;
}

export const UPDATE_PROFILE = 'updateContactDetails';
const FIELDS: (keyof IFormikFields)[] = [
	'birthDate',
	'email',
	'fatherName',
	'firstName',
	'grandfatherName',
	'lastName',
	'middleName',
	'phoneNumber',
	'title',
];
const OTP_ = 'OTP_';
const CHECKOUT_FORM = 'CHECKOUT_FORM';
const ContactDetails: FunctionComponent<types.cards.IContactDetailsProps> = ({
	params: {
		contactDetailsRef,
		withButtons,
		personTitleId,
		setPersonTitleId,
		orderIncludesPlan,
		isHbbWfbbProcess,
		isWfbbProcess,
	},
}) => {
	const isB2b = useSelector((state: types.redux.IState) => state.api.authentication.isB2b);
	const { translate } = useTranslate();
	const history = useHistory();
	const hbbWfbbProcessCheckout = useSelector(
		(state: types.redux.IState) => state.persistentCheckout.hbbWfbbProcessCheckout
	);
	const forms = useSelector((state: types.redux.IState) => state.settings.config.forms);
	const { contactDetails, updateProfile } = useCommerceProfile({
		busyIndicatorListener: UPDATE_PROFILE,
		afterUpdate: () => {
			if (history.location.pathname !== '/account/personal/data') {
				history.push(`/${getLang()}/checkout/2`);
			}
		},
	});
	const [displayButtons, setDisplayButtons] = useState(false);
	const [_personTitleId, _setPersonTitleId] = useState(contactDetails?.title ?? '');

	const dropdownArray = [
		{ id: '1', text: translate('contact.details.dropdown.mr') },
		{ id: '2', text: translate('contact.details.dropdown.ms') },
	];
	const contactDetailsControls = useMemo(() => {
		const checkoutForm = forms?.find((form) => form.code === CHECKOUT_FORM);
		const controls: { [identifier: string]: string } = {};
		checkoutForm?.controls?.forEach((control) => {
			return (controls[control.identifier] = control.value);
		});
		return controls;
	}, forms);

	const getSelectedItem = (id?: string) => {
		const selectedItem = dropdownArray.find((item) => item.id === (id ?? personTitleId ?? _personTitleId));
		return selectedItem ?? null;
	};

	useEffect(() => {
		if (contactDetailsRef?.current && contactDetails) {
			const { setFieldValue } = contactDetailsRef.current;
			const { title, firstName, middleName, lastName, email, phoneNumber } = contactDetails;
			(setPersonTitleId ?? _setPersonTitleId)(title);
			setFieldValue('title', getSelectedItem(title)?.text);
			setFieldValue('title', title);
			setFieldValue('firstName', firstName);
			setFieldValue('middleName', middleName);
			setFieldValue('lastName', lastName);
			setFieldValue('email', email);
			setFieldValue('phoneNumber', phoneNumber.replace(OTP_, ''));
			if (hbbWfbbProcessCheckout) {
				if (!!hbbWfbbProcessCheckout.birthDate)
					setFieldValue(
						'birthDate',
						hbbWfbbProcessCheckout.birthDate && hbbWfbbProcessCheckout.operationContext === 'WFBB'
							? moment(hbbWfbbProcessCheckout.birthDate, 'YYYY-MM-DD').format('DD/MM/YYYY')
							: hbbWfbbProcessCheckout.birthDate
					);
				if (!!hbbWfbbProcessCheckout.birthDate) setFieldValue('fatherName', hbbWfbbProcessCheckout.fatherName);
				if (!!hbbWfbbProcessCheckout.birthDate)
					setFieldValue('grandfatherName', hbbWfbbProcessCheckout.grandfatherName);
			}
		}
	}, [contactDetails]);

	const shouldDisplayButtons = (value: string, field: keyof IFormikFields) => {
		let output = false;

		if (withButtons) {
			FIELDS.filter((_field) => _field !== field).forEach((field) => {
				if (contactDetails?.[field] !== contactDetailsRef?.current?.values[field]) {
					output = true;
				}
			});

			if (contactDetails?.[field] !== value) {
				output = true;
			}
		}

		return output;
	};

	const onSaveDetails = (values: IFormikFields) => {
		delete values.title;
		const _contactDetails = { ...values, title: personTitleId ?? _personTitleId };
		setDisplayButtons(false);
		updateProfile({ contactDetails: _contactDetails });
		if (isHbbWfbbProcess) {
			dispatch(
				setPersistentCheckoutDetails({
					hbbWfbbProcessCheckout: {
						emailId: _contactDetails.email,
						contactNumber1: _contactDetails.phoneNumber,
						firstName: _contactDetails.firstName,
						lastName: _contactDetails.lastName,
						title: _contactDetails.title,
						middleName: _contactDetails.middleName,
						customerName: _contactDetails.lastName,
						birthDate:
							hbbWfbbProcessCheckout?.operationContext === 'WFBB'
								? moment(_contactDetails.birthDate, 'DD/MM/YYYY').format('YYYY-MM-DD')
								: _contactDetails.birthDate,
						fatherName: _contactDetails.fatherName,
						grandfatherName: _contactDetails.grandfatherName,
						gender: _contactDetails.title === '1' ? 'M' : 'F',
					},
				})
			);
		}
	};

	const onCancel = () => {
		FIELDS.forEach((field) => {
			contactDetailsRef?.current?.setFieldValue(field, contactDetails?.[field] ?? '');
		});
		setDisplayButtons(false);
	};

	const getControlState = (controlAttributeName: string, controlName: keyof IFormikFields) => {
		if (isB2b) return false;
		const fieldValue: string | undefined =
			contactDetails && contactDetails[controlName] ? contactDetails[controlName] : '';
		return controlAttributeName === ControlState.UNEDITABLE && fieldValue !== '';
	};

	return (
		<ContactDetailsWrapper>
			<Row>
				<Hidden xs sm>
					<IconWrapper>
						<Icon name="personalInfo" width={40} height={40} />
					</IconWrapper>
				</Hidden>
				<Col>
					<HeaderWrapper>
						<Visible xs sm>
							<IconWrapper>
								<Icon name="personalInfo" width={40} height={40} />
							</IconWrapper>
						</Visible>
					</HeaderWrapper>
					<div>
						<ValueBold>{translate('contact-details.title')}</ValueBold>
						<Divider marginBottom={8} withoutLine />
						<Subtitle>{translate('contact-details.provide.details')}</Subtitle>
						<Divider marginBottom={24} withoutLine />
					</div>
					<Formik<IFormikFields>
						validationSchema={personalDataValidator(!!isWfbbProcess)}
						validateOnBlur={true}
						initialValues={{
							birthDate: '',
							title: '',
							fatherName: '',
							firstName: '',
							grandfatherName: '',
							middleName: '',
							lastName: '',
							email: '',
							phoneNumber: '',
						}}
						innerRef={contactDetailsRef}
						onSubmit={(values) => {
							onSaveDetails(values);
						}}
					>
						{({ setFieldValue, values, errors, touched, setFieldTouched }) => {
							return (
								<FormWrapper>
									<RegularDropdown
										dropdownHeight={DropdownHeight.AUTO}
										items={dropdownArray}
										setSelectedItem={(selectedItem: IDropdownItem) => {
											setFieldValue('title', selectedItem.text);
											(setPersonTitleId ?? _setPersonTitleId)(selectedItem.id);
											setDisplayButtons(shouldDisplayButtons(selectedItem.id, 'title'));
										}}
										selectedItem={getSelectedItem()}
										label={translate('contact-details.input.title')}
										labelType={LabelType.FLOATING}
										dropdownListWidth="320px"
										disabled={getControlState(contactDetailsControls.TITLE_FORM, 'title')}
									/>
									<Divider marginBottom={16} withoutLine />
									<TextField
										id="firstName"
										label={translate('contact-details.input.name')}
										placeholder={translate('contact-details.input.name')}
										value={values.firstName}
										onChange={(value) => {
											setFieldTouched('firstName', true);
											setFieldValue('firstName', value.toString());
											setDisplayButtons(shouldDisplayButtons(value.toString(), 'firstName'));
										}}
										message={!!errors.firstName && touched.firstName ? errors.firstName : ''}
										error={!!errors.firstName && touched.firstName}
										marginBottom={16}
										disabled={getControlState(contactDetailsControls.NAME_FORM, 'firstName')}
									/>
									<TextField
										id="middleName"
										label={translate('contact-details.input.middle.name')}
										placeholder={translate('contact-details.input.middle.name')}
										value={values.middleName}
										onChange={(value) => {
											setFieldTouched('middleName', true);
											setFieldValue('middleName', value.toString());
											setDisplayButtons(shouldDisplayButtons(value.toString(), 'middleName'));
										}}
										message={!!errors.middleName && touched.middleName ? errors.middleName : ''}
										error={!!errors.middleName && touched.middleName}
										marginBottom={16}
										disabled={getControlState(
											contactDetailsControls.MIDDLE_NAME_FORM,
											'middleName'
										)}
									/>
									<TextField
										id="lastName"
										label={translate('contact-details.input.surname')}
										placeholder={translate('contact-details.input.surname')}
										value={values.lastName}
										onChange={(value) => {
											setFieldTouched('lastName', true);
											setFieldValue('lastName', value.toString());
											setDisplayButtons(shouldDisplayButtons(value.toString(), 'lastName'));
										}}
										message={!!errors.lastName && touched.lastName ? errors.lastName : ''}
										error={!!errors.lastName && touched.lastName}
										marginBottom={16}
										disabled={getControlState(contactDetailsControls.SURNAME_FORM, 'lastName')}
									/>
									<ConditionalRender
										show={!!isWfbbProcess}
										onTrue={() => (
											<>
												<DatePickerFieldWrapper>
													<DatePickerField
														id="birthDate"
														name="birthDate"
														width={320}
														onChange={(value) => {
															setFieldValue('birthDate', value || '');
														}}
														value={values.birthDate || ''}
														message={
															!!errors.birthDate && touched.birthDate
																? errors.birthDate
																: translate('contact-details.provide.birth-date')
														}
														error={!!errors.birthDate && touched.birthDate}
														disabled={getControlState(
															contactDetailsControls.BIRTH_DATE_FORM,
															'birthDate'
														)}
													/>
												</DatePickerFieldWrapper>
												<TextField
													id="fatherName"
													label={translate('contact-details.input.father-name')}
													placeholder={translate('contact-details.input.father-name')}
													value={values.fatherName}
													onChange={(value) => {
														setFieldTouched('fatherName', true);
														setFieldValue('fatherName', value.toString());
														setDisplayButtons(
															shouldDisplayButtons(value.toString(), 'fatherName')
														);
													}}
													message={
														!!errors.fatherName && touched.fatherName
															? errors.fatherName
															: ''
													}
													error={!!errors.fatherName && touched.fatherName}
													marginBottom={16}
													disabled={getControlState(
														contactDetailsControls.FATHER_NAME_FORM,
														'fatherName'
													)}
												/>
												<TextField
													id="grandfatherName"
													label={translate('contact-details.input.grandfather-name')}
													placeholder={translate('contact-details.input.grandfather-name')}
													value={values.grandfatherName}
													onChange={(value) => {
														setFieldTouched('grandfatherName', true);
														setFieldValue('grandfatherName', value.toString());
														setDisplayButtons(
															shouldDisplayButtons(value.toString(), 'grandfatherName')
														);
													}}
													message={
														!!errors.grandfatherName && touched.grandfatherName
															? errors.grandfatherName
															: ''
													}
													error={!!errors.grandfatherName && touched.grandfatherName}
													marginBottom={16}
													disabled={getControlState(
														contactDetailsControls.GRANDFATHER_NAME_FORM,
														'grandfatherName'
													)}
												/>
											</>
										)}
									/>
									<TextField
										id="email"
										label={translate('contact-details.input.mail')}
										placeholder={translate('contact-details.input.mail')}
										value={values.email}
										onChange={(value) => {
											setFieldTouched('email', true);
											setFieldValue('email', value.toString());
											setDisplayButtons(shouldDisplayButtons(value.toString(), 'email'));
										}}
										message={
											!!errors.email && touched.email
												? errors.email
												: orderIncludesPlan
												? ''
												: translate('contact-details.digital.on.email.address')
										}
										error={!!errors.email && touched.email}
										marginBottom={16}
										disabled={getControlState(contactDetailsControls.EMAIL_FORM, 'email')}
									/>
									<TextField
										id="phoneNumber"
										label={translate('contact-details.input.phone')}
										placeholder={translate('contact-details.input.phone')}
										value={values.phoneNumber}
										onChange={(value) => {
											setFieldTouched('phoneNumber', true);
											setFieldValue('phoneNumber', value);
											setDisplayButtons(shouldDisplayButtons(value.toString(), 'phoneNumber'));
										}}
										error={!!errors.phoneNumber && touched.phoneNumber}
										message={errors.phoneNumber || translate('contact-details.omani.numbers')}
										disabled={getControlState(
											contactDetailsControls.PHONE_NUMBER_FORM,
											'phoneNumber'
										)}
									/>
								</FormWrapper>
							);
						}}
					</Formik>
					<ConditionalRender
						show={displayButtons}
						onTrue={() => (
							<ButtonsWrapper>
								<PrimaryMediumButtonAuto
									uniqueId="contact-details.save"
									onClick={() => {
										contactDetailsRef?.current?.handleSubmit();
									}}
								>
									{translate('contact-details.save')}
								</PrimaryMediumButtonAuto>
								<TertiaryMediumButtonAuto uniqueId="contact-details.cancel" onClick={onCancel}>
									{translate('contact-details.cancel')}
								</TertiaryMediumButtonAuto>
							</ButtonsWrapper>
						)}
					/>
				</Col>
			</Row>
		</ContactDetailsWrapper>
	);
};

export default ContactDetails;
