import { getLanguage } from '@Utils/language/language';
import { isUserSignedIn } from '@Redux/modules/api/authentication/selectors';

interface IFunctionalEndpointConfig extends types.endpoints.functionalEndpointConfig {}

export const getTermsAndConditionsContent: IFunctionalEndpointConfig = () => {
	return {
		url: `wcm/${
			isUserSignedIn() ? 'myconnect' : 'connect'
		}/Omantel_${getLanguage()}/z6_000000000000000000000000a0/contract_activation_tc/buy-car-insurance`,
	};
};
