import React, { FunctionComponent, useState, useEffect, useRef } from 'react';
import { ProductBoxWrapper, ChildernCollapseWrapper, IconWrapper } from './ExpandableBox.styled';
import TitleSeparator from '@Components/TitleSeparator/TitleSeparator';
import ConditionalRender from '@Components/ConditionalRender/ConditionalRender';
import Icon from '@Components/Icon/Icon';
import useScreen from '@Utils/hooks/useScreen';

export interface IExpandableBox {
	title: string;
	isExpanded?: boolean;
	children: React.ReactNode;
	expandAvailable?: boolean;
	minHeight?: number;
	marginLeft?: number;
	bottomMode?: boolean;
	withLine?: boolean;
	setBoxHeight?: (height: number) => void;
	typography?: keyof types.ITypography;
	iconExpand?: types.iconNames;
	iconHide?: types.iconNames;
	getExpandedValue?: (expanded: boolean) => void;
}

const ExpandableBox: FunctionComponent<IExpandableBox> = ({
	title = '',
	isExpanded = false,
	children,
	expandAvailable = false,
	minHeight = 0,
	bottomMode = false,
	setBoxHeight,
	withLine = true,
	typography,
	marginLeft,
	iconExpand = 'chevronDown',
	iconHide = 'chevronUp',
	getExpandedValue,
}) => {
	const [expanded, setExpanded] = useState<boolean>(!!isExpanded);
	const boxRef = useRef<HTMLDivElement>(null);
	const { screen } = useScreen();

	useEffect(() => {
		setTimeout(() => {
			setBoxHeight && setBoxHeight(boxRef.current?.getBoundingClientRect().height ?? 0);
		}, 500);

		const handleResize = () => {
			setBoxHeight && setBoxHeight(boxRef.current?.getBoundingClientRect().height ?? 0);
		};
		window.addEventListener('resize', handleResize);
	}, [expanded, boxRef]);

	const handleExpand = () => {
		setExpanded((expanded) => !expanded);
	};

	useEffect(() => {
		getExpandedValue && getExpandedValue(expanded);
	}, [expanded]);

	return (
		<ProductBoxWrapper>
			<ConditionalRender
				show={!!bottomMode}
				onTrue={() => (
					<IconWrapper>
						{expandAvailable && (
							<Icon
								name={expanded ? iconExpand : iconHide}
								width={24}
								height={24}
								onClick={handleExpand}
							/>
						)}
					</IconWrapper>
				)}
				onFalse={() => (
					<TitleSeparator
						title={title}
						withLine={!!withLine}
						expandAvailable={expandAvailable}
						onClick={handleExpand}
						typography={typography || screen('xs') ? 'titleBold' : 'displayMBold'}
						expanded={expanded}
						iconExpand={iconExpand}
						iconHide={iconHide}
					/>
				)}
			/>
			<ChildernCollapseWrapper
				ref={boxRef}
				expanded={expanded || !expandAvailable}
				minHeight={minHeight}
				marginLeft={marginLeft}
			>
				{children}
			</ChildernCollapseWrapper>
		</ProductBoxWrapper>
	);
};

export default ExpandableBox;
