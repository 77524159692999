import { BodyM, Subheading, Caption } from '@Components/Typography/Typography';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { marginLeft, marginRight } from '@Utils/layout/layoutSupport';

type ISummaryProps = types.IStyledProps & { addMarginLeft?: number; addMarginRight?: number };

type IBarProps = types.IStyledProps & { color: types.theme.themeColors; hasMargin?: boolean };

export const CardWrapper = styled.div`
	padding: 8px 16px;
`;

export const Title = styled(Subheading)`
	padding-bottom: 16px;
`;

export const AdditionalInfoTitle = styled(Subheading)`
	padding-bottom: 16px;
	${({ theme }) => {
		return { color: theme.colors.black54 };
	}}
`;

export const DescriptionTitle = styled(BodyM)`
	padding-bottom: 4px;
`;

export const PriceDescriptionWrapper = styled.div<{ align?: string }>`
	display: flex;
	flex-direction: column;
	justify-content: center;
	${({ theme, align }) => {
		return { alignItems: !!align ? align : 'flex-end' };
	}}
`;

export const BoldWrapper = styled.div`
	font-weight: bold;
	display: inline;
`;

export const StrikethroughWrapper = styled.div`
	font-weight: bold;
	text-decoration: line-through;
	display: inline;
`;

export const PriceContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-end;
`;

export const Subtitle = styled(Caption)`
	margin-right: 4px;
`;

export const IconWrapper = styled.div`
	margin-right: 6px;
	margin-bottom: 2px;
`;

export const LinkWrapper = styled(Link)`
	${({ theme }) => ({ color: theme.colors.primary })};
`;

export const MessageContainer = styled.div`
	width: 100%;
	display: flex;
	padding: 8px 16px;
	margin-bottom: 8px;
	border-radius: 8px;
	${({ theme }) => ({ backgroundColor: theme.colors.grey100 })};
	flex-direction: column;
	align-items: center;
`;

export const Message = styled(Caption)`
	${({ theme }) => ({ color: theme.colors.black54 })}
	${() => marginLeft(16)}
	text-align:center;
`;

export const SummaryWrapper = styled.div<{ withBorder: boolean }>`
	${({ theme, withBorder }) => {
		const border = withBorder
			? {
					border: `1px solid ${theme.colors.black12}`,
					borderRadius: '8px',
					padding: 16,
			  }
			: {};
		return {
			width: '100%',
			...border,
		};
	}}
`;
export const PriceSummaryRow = styled.div`
	display: flex;
	justify-content: space-between;
`;

export const SummaryContainer = styled.div<ISummaryProps>`
	${({ addMarginLeft = 0 }) => marginLeft(addMarginLeft)}
	${({ addMarginRight = 0 }) => marginRight(addMarginRight)}
`;

export const MessageBarContainer = styled.div<IBarProps>`
	${({ theme, color }) => ({ backgroundColor: theme.colors[color] })};

	margin: 15px -15px 0 -15px;

	${({ hasMargin = true }) => ({ marginTop: !!hasMargin ? '15px' : '0' })};

	padding: 15px 0;

	& .row-container {
		margin-left: 15px !important;
		margin-right: 15px !important;

		& .img-container {
			width: 40px;
			margin-top: 5px;
			margin-right: 10px;
		}
	}
`;

export const VatMessageBarCircle = styled.div`
	width: 42px;
	display: flex;
	position: relative;
`;

export const VatMessageBarValue = styled.div`
	height: 28px;
	width: 28px;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	${({ theme }) => ({
		border: `2px solid ${theme.colors.black38}`,
		color: theme.colors.black54,
	})}
	z-index: 1;
	font-size: 12px;
	font-weight: bold;
`;

export const VatMessageBarPercent = styled.div`
	height: 32px;
	width: 32px;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	right: -15px;
	font-size: 12px;
	font-weight: bold;
	${({ theme }) => ({
		color: theme.colors.white,
		backgroundColor: theme.colors.grey,
	})}
	z-index: 1;
`;
