export interface IChoosePaymentMethod {
	paymentInstruction: IPaymentInstruction[];
	orderId: string;
	resourceName: string;
}

interface IPaymentInstruction {
	piId: string;
}

const model: types.models.IInputModel = {
	paymentInstruction: ['response.paymentInstruction', []],
	orderId: 'response.orderId',
	resourceName: 'response.resourceName',
};

export default model;
