import React, { FunctionComponent } from 'react';
import { CircularLoaderWrapper, Circle, Bar, LoaderWrapper } from './CircularLoader.styled';

export interface ICircularLoaderBaseProps {
	size: number;
}

export interface ICircularLoaderProps extends ICircularLoaderBaseProps {
	backgroundColor: types.theme.themeColors;
	barColor: types.theme.themeColors;
	marginTop?: number;
}

const CircularLoader: FunctionComponent<ICircularLoaderProps> = ({ size, backgroundColor, barColor, marginTop }) => {
	return (
		<LoaderWrapper marginTop={marginTop ?? 0}>
			<CircularLoaderWrapper size={24}>
				<Circle size={24} backgroundColor="white12" barColor="white" />
				<Bar size={24} backgroundColor="white12" barColor="white" />
			</CircularLoaderWrapper>
		</LoaderWrapper>
	);
};

export default CircularLoader;
