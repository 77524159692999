import { ParagraphS } from '@Components/Typography/Typography';
import styled from 'styled-components';

export const OrderTrackingContainer = styled.div``;

export const PickupInfo = styled(ParagraphS)`
	${({ theme }) => ({
		color: theme.colors.black54,
	})};
	display: flex;
	flex-wrap: wrap;
`;
