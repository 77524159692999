import { ISinglePackage } from '@ApiModels/singlePackage';
import BusyIndicator from '@Components/BusyIndicator/BusyIndicator';
import Icon from '@Components/Icon/Icon';
import { PlanBarType } from '@Components/PlanBar/PlanBarType';
import PlanComparison, { IPlanComparison } from '@Components/PlanComparison/PlanComparison';
import { TitleBold } from '@Components/Typography/Typography.styled';
import { addBusyIndicator, removeBusyIndicator } from '@Redux/modules/busyIndicator/actions';
import { setModal } from '@Redux/modules/modal/actions';
import PackagesService from '@Services/packages/packages';
import useTranslate from '@Utils/hooks/useTranslate';
import { getBarTypeByPlan } from '@Utils/plans/barType';
import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { Col, Row } from 'react-grid-system';
import { useDispatch } from 'react-redux';
import {
	ActionWrapper,
	CardWrapper,
	DetailsWrapper,
	Header,
	PlanComparisonWrapper,
	TitleBoldWrapper,
	BusyIndicatorWrapper,
} from './PlanDetails.styled';

const GET_SPECIFIC_PLAN_DETAILS = 'GET_SPECIFIC_PLAN_DETAILS';

const PlanDetailsCard: FunctionComponent<types.cards.IPlanDetailsProps> = ({
	params: { planId, isHBB, isWFBB, isPrepaid },
}) => {
	const [plan, setPlan] = useState<ISinglePackage>();
	const { translate } = useTranslate();
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(addBusyIndicator(GET_SPECIFIC_PLAN_DETAILS));
		PackagesService.getPackagesByGroupAndType(
			'PLAN',
			isWFBB ? 'WFBB' : isHBB ? 'HBB' : isPrepaid ? 'MOBILE_PREPAID_NEW_ESIM' : 'POSTPAID'
		).subscribe(
			(r) => {
				const properPackage = r.packages?.find(
					(singlePackage) =>
						singlePackage.partNumber === `OM_${planId}` || singlePackage.partNumber === planId
				);
				setPlan(properPackage);
				dispatch(removeBusyIndicator(GET_SPECIFIC_PLAN_DETAILS));
			},
			() => {
				dispatch(removeBusyIndicator(GET_SPECIFIC_PLAN_DETAILS));
			}
		);
	}, []);

	const planDetails: IPlanComparison[] = useMemo(() => {
		return [
			{
				id: plan?.uniqueID ?? '',
				type: plan ? getBarTypeByPlan(plan) : PlanBarType.BAQATI,
				name: plan?.shortDescription ?? plan?.name ?? '',
				contractCommitment: plan?.duration
					? translate('plan.selector.commitment', String(plan.duration))
					: isWFBB || isHBB
					? ''
					: translate('plan.selector.no.commitment'),
				isCurrentPlan: false,
				isRecommendedPlan: false,
				monthlyPayment: plan?.price,
				offerPrice: plan?.offerPrice,
				localAndGccData:
					plan?.planDetails?.includedData?.itemValue === '-'
						? 'Unlimited'
						: plan?.planDetails?.includedData?.itemValue,
				localMinutes:
					plan?.planDetails?.includedMinutes?.itemValue === '-'
						? 'Unlimited'
						: plan?.planDetails?.includedMinutes?.itemValue,
				internationalMinutes: plan?.planDetails?.intlMinutes?.itemValue,
				roamingRecievingMinutes: plan?.planDetails?.roamingRecMinutes?.itemValue,
				worldRoamingData: plan?.planDetails?.roamingData?.itemValue,
				dataSharing: plan?.planDetails?.dataSharing?.itemValue,
				storeCredit: plan?.planDetails?.promoAppStore?.itemValue,
				dataIncluded:
					plan?.planDetails?.data?.itemValue || plan?.planDetails?.hbbDataIncluded?.itemValue === '-'
						? 'Unlimited'
						: plan?.planDetails?.hbbDataIncluded?.itemValue,
				downloadSpeed: plan?.planDetails?.downloadSpeed?.itemValue,
				downloadSpeedUnit: plan?.planDetails?.downloadSpeed?.itemUnit === 'Gbps' ? 'GB' : 'MB',
				uploadSpeed: plan?.planDetails?.uploadSpeed?.itemValue,
				freeMinutesToOmantel:
					plan?.planDetails?.includedMinutes?.itemValue === '-'
						? 'Unlimited'
						: plan?.planDetails?.includedMinutes?.itemValue,
				intlCallsDiscount: plan?.planDetails?.callDiscount?.itemValue,
				planVatPercent: plan?.vatPercent,
				planVatValue: plan?.vatValue,
				durationLabel: plan?.durationLabel,
				socialMediaDataValue: plan?.planDetails?.PLAN_PLACEHOLDER_1?.itemValue,
				socialMediaDataUnit: plan?.planDetails?.PLAN_PLACEHOLDER_1?.itemUnit,
			},
		];
	}, [plan]);

	const handleClose = () => {
		dispatch(
			setModal({
				type: null,
				data: null,
			})
		);
	};

	return (
		<CardWrapper>
			<Header>
				<Row>
					<Col>
						<TitleBoldWrapper>
							<TitleBold>
								{translate(isPrepaid ? 'plan-comparison.details.title' : 'plan.details.title')}
							</TitleBold>
						</TitleBoldWrapper>
					</Col>
					<Col xs={1}>
						<ActionWrapper onClick={handleClose}>
							<Icon width={24} height={24} name="close" fill="black" />
						</ActionWrapper>
					</Col>
				</Row>
			</Header>
			<DetailsWrapper isPrepaid={isPrepaid}>
				<BusyIndicatorWrapper>
					<BusyIndicator listener={GET_SPECIFIC_PLAN_DETAILS}>
						<Row>
							<Col>
								<PlanComparisonWrapper>
									<PlanComparison
										plans={planDetails}
										planDetailsMode
										currentPlanIsHBB={isHBB}
										currentPlanIsPrepaid={isPrepaid}
										hbbWfbbProcess={isHBB || isWFBB}
									/>
								</PlanComparisonWrapper>
							</Col>
						</Row>
					</BusyIndicator>
				</BusyIndicatorWrapper>
			</DetailsWrapper>
		</CardWrapper>
	);
};
export default PlanDetailsCard;
