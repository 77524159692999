import { useState, useEffect } from 'react';
import { IProductById } from '@ApiModels/productById';
import { useCart } from '@Utils/context/CartContext';
import { ProductsService } from '@Services/productsService/productsService';
import { APPLE_BRAND_STORE_CATEGORIES } from '@Config/app.config';

interface IUseAddressBook {
	isAppleProductView: (productId: IProductById) => boolean;
	getAppleItemCategory: (productId: IProductById) => IAppleCategoryDictionary;
	isAppleItemCategory: (productId: IProductById) => boolean;
	isAppleProductInCart: boolean;
	isAppleUrl: () => boolean;
	isBrandStore: () => boolean;
	isAppleAccessories: () => boolean;
}

const manufacturers = ['Apple', 'Apple Watch'];

interface IAppleCategoryDictionary {
	name: string;
	id: string;
}

const useAppleBrand = (): IUseAddressBook => {
	const { cart, loading } = useCart();

	const [isAppleProductInCart, setIsAppleProductInCart] = useState(false);
	const isAppleProductView = (productId: IProductById) => {
		return manufacturers.includes(productId.manufacturer?.manufacturer ?? '');
	};
	const [cartProducts, setCartProducts] = useState<string[]>([]);

	useEffect(() => {
		if (!loading && cart.items.length > 0) {
			cart.items.forEach((item) => {
				ProductsService.getProductsByPartNumber(item.partNumber).subscribe(
					(product) => {
						setCartProducts([...cartProducts, product.manufacturer]);
					},
					() => {}
				);
			});
		}
	}, [cart, loading]);

	useEffect(() => {
		if (!loading && cart.items.length > 0 && cart.items.length === cartProducts.length) {
			const hasCartAppleProduct = cartProducts.some((cartProduct) => {
				cartProduct === 'Apple';
			});
			setCartProducts([]);
			setIsAppleProductInCart(hasCartAppleProduct);
		}
	}, [cartProducts, cart, loading]);

	const isAppleUrl = () => {
		const pathname = window.location.href.toLocaleLowerCase();
		if (
			(pathname.includes('apple') ||
				pathname.includes('ipad') ||
				pathname.includes('iphone') ||
				pathname.includes('airpod') ||
				pathname.includes('airtag') ||
				pathname.includes('magsafe')) &&
			!pathname.includes('apple-brand-store')
		) {
			return true;
		} else {
			return false;
		}
	};

	const isBrandStore = () => {
		if (window.location.pathname.toLocaleLowerCase().includes('apple-brand-store')) {
			return true;
		} else {
			return false;
		}
	};

	const isAppleAccessories = () => {
		if (window.location.pathname.toLocaleLowerCase().includes('appleaccessories')) {
			return true;
		} else {
			return false;
		}
	};

	const getAppleItemCategory = (productById: IProductById): IAppleCategoryDictionary => {
		const response = { name: '', id: '' };
		type AppleBrandStoreCategories = keyof typeof APPLE_BRAND_STORE_CATEGORIES;
		for (const key in APPLE_BRAND_STORE_CATEGORIES) {
			if (
				APPLE_BRAND_STORE_CATEGORIES.hasOwnProperty(key) &&
				(!!productById?.item?.parentCatalogGroupID || !!productById?.item?.parentCatalogGroupID?.[0])
			) {
				const categoryId = APPLE_BRAND_STORE_CATEGORIES[key as AppleBrandStoreCategories];
				if (
					productById?.item?.parentCatalogGroupID?.indexOf(categoryId) !== -1 ||
					productById?.item?.parentCatalogGroupID?.[0].includes(categoryId)
				) {
					response.name = key;
					response.id = categoryId;
				}
			}
		}
		return response;
	};

	const isAppleItemCategory = (productById: IProductById): boolean => {
		const appleItemCategory = getAppleItemCategory(productById || ({} as IProductById));
		return productById?.item?.parentCatalogGroupID?.indexOf(appleItemCategory.id) !== -1;
	};

	return {
		isAppleProductView,
		isAppleProductInCart,
		isAppleUrl,
		isBrandStore,
		getAppleItemCategory,
		isAppleItemCategory,
		isAppleAccessories,
	};
};

export default useAppleBrand;
