import { ValueBold, Value, BodyS } from '@Components/Typography/Typography.styled';
import { marginLeft, paddingRight, matchScreen } from '@Utils/layout/layoutSupport';
import styled from 'styled-components';

export const IconWrapper = styled.div`
	${() => paddingRight(16)}
	align-self: center;
`;

export const OutletAppointmentWrapper = styled.div`
	padding: 16px;
	${({}) => ({
		paddingLeft: matchScreen('xs') ? '0px' : '16px',
		marginLeft: matchScreen('xs') ? '-4px' : '0',
	})}
`;

export const PickupWrapper = styled.div`
	${() => marginLeft(20)}
`;

export const HeaderWrapper = styled.div`
	display: flex;
`;

export const TitleWrapper = styled(ValueBold)`
	${({ theme }) => ({
		color: theme.colors.black87,
	})}
`;

export const DescriptionWrapper = styled(BodyS)`
	${({ theme }) => ({
		color: theme.colors.black54,
	})}
	margin-top: 8px;
`;

export const ButtonWrapper = styled.div`
	margin: 4px 16px 0 32px;
	align-self: center;
`;

export const SmallButtonWrapper = styled.div`
	margin: 16px 0px 0px 20px;
	width: 100%;
`;

export const SelectedAppointmentWrapper = styled.div`
	display: flex;
	margin: 32px 0;
`;

export const SelectedAppointmentLabel = styled(Value)`
	${({ theme }) => ({
		color: theme.colors.black87,
	})}
	margin-right: 5px;
`;

export const BookingLink = styled.span`
	margin-right: 24px;
	${({ theme }) => ({
		color: theme.colors.primary,
		textDecoration: 'underline',
		cursor: 'pointer',
	})}
`;
