export type IPermission = {
	accountId: string;
	departmentId: string;
	permissionExpiration?: string;
};

export type IEbuUserProfile = {
	response: any;
	userId: string;
	upId: string;
	userName: string;
	userType: EbuUserType | null;
	accountName: string;
	email: string;
	objectId: string;
	accountPermissions: IPermission[];
	departmentPermissions: IPermission[];
	contactNumber1: string;
};

export enum EbuUserType {
	SUPERADMIN = 'SUPERADMIN',
	ADMIN = 'ADMIN',
	MANAGER = 'MANAGER',
	ENDUSER = 'ENDUSER',
}

const model = {
	response: 'response',
	userId: 'response.getCurrentUserDetailsResponse.userData.userId',
	upId: 'response.getCurrentUserDetailsResponse.userData.upId',
	userName: 'response.getCurrentUserDetailsResponse.userData.userName',
	userType: 'response.getCurrentUserDetailsResponse.userData.userType',
	accountName: 'response.getCurrentUserDetailsResponse.userData.accountName',
	email: 'response.getCurrentUserDetailsResponse.userData.email',
	objectId: 'response.getCurrentUserDetailsResponse.userData.objectId',
	departmentPermissions: ['response.getCurrentUserDetailsResponse.departmentPermissions', []],
	accountPermissions: ['response.getCurrentUserDetailsResponse.accountPermissions', []],
	contactNumber1: ['response.getCurrentUserDetailsResponse.userData.contactNumber1', []],
};

export default model;
