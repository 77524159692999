import { useMemo } from 'react';
import { useCart } from '@Utils/context/CartContext';
import { useHistory } from 'react-router';
import { getLang } from '@Utils/language/language';
import { DISABLE_MS_CSP } from '@Config/features.config';

const useMicrosoftAutomationProcess = (): {
	isMicrosoftAutomationProcess: boolean;
	isBusinessMicrosoftProduct: (productName: string) => boolean;
} => {
	const { cart } = useCart();
	const history = useHistory();

	const isBusinessMicrosoftProduct = (productName: string) => {
		if (!DISABLE_MS_CSP) {
			return productName.includes('Microsoft 365') && productName.includes('Business');
		} else return false;
	};

	const isMicrosoftAutomationProcess: boolean = useMemo(() => {
		const microsoft365BussinessItems = cart.items.filter((cartItem) =>
			isBusinessMicrosoftProduct(cartItem.name ?? '')
		);
		if (microsoft365BussinessItems.length > 0 && !DISABLE_MS_CSP) {
			return true;
		} else if (window.location.href.toLocaleLowerCase().includes('microsoft-automation-services')) {
			history.push(`/${getLang()}/store`);
		}
		return false;
	}, [cart.items]);

	return {
		isMicrosoftAutomationProcess,
		isBusinessMicrosoftProduct,
	};
};

export default useMicrosoftAutomationProcess;
