import { BodyMBold } from '@Components/Typography/Typography';
import { marginRight, isRTL, left } from '@Utils/layout/layoutSupport';
import styled from 'styled-components';

const LIST_ITEM_SIZE = 172;

export const ListProductCardWrapper = styled.div``;

export const ImageWrapper = styled.div`
	width: ${LIST_ITEM_SIZE}px;
	min-width: ${LIST_ITEM_SIZE}px;
	height: ${LIST_ITEM_SIZE}px;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	margin-right: 8px;
`;

export const ContentWrapper = styled.div`
	display: flex;
	flex-direction: row;
`;

export const SectionWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	flex-direction: column;
	align-items: stretch;
	height: ${LIST_ITEM_SIZE}px;
`;

export const ColorPickerWrapper = styled.div`
	margin-top: 16px;
`;

export const IconsSectionWrapper = styled.div`
	display: flex;
	justify-content: flex-end;
`;

export const Image = styled.img`
	max-height: 158px;
	max-width: 100%;
`;

export const LoaderContainer = styled.div`
	position: absolute;
	left: 50%;
	top: 50%;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
`;

export const TitleWrapper = styled.div`
	height: 40px;
	display: flex;
`;

export const BrandWrapper = styled(BodyMBold)`
	${() => marginRight(4)}
`;

export const BadgeWrapper = styled.div`
	${() => (isRTL() ? { left: '32px' } : { right: '32px' })}
	position: absolute;
	top: 56px;
`;

export const Badge = styled.div`
	display: flex;
	position: relative;
	${() => (isRTL() ? { marginLeft: '-29px' } : { marginRight: '-29px' })}
	margin-top: 10px;
`;

export const BadgeRibbon = styled.div`
	${({ theme }) => ({
		backgroundImage: `linear-gradient(to top, ${theme.colors.support2}, ${theme.colors.support2Gradient})`,
		color: theme.colors.white,
	})}
	${() =>
		isRTL()
			? {
					borderTopRightRadius: '16px',
					borderBottomRightRadius: '16px',
					padding: '4px 16px 4px 20px',
			  }
			: {
					borderTopLeftRadius: '16px',
					borderBottomLeftRadius: '16px',
					padding: '4px 20px 4px 16px',
			  }}
`;

const TRIANGLE_SIZE = 12;
export const BadgeTriangle = styled.div`
	width: 0;
	height: 0;
	${({ theme }) => ({
		borderBottom: `${TRIANGLE_SIZE}px solid ${theme.colors.support2}`,
	})}
	align-content: flex-end;
	margin-top: -${TRIANGLE_SIZE}px;
	${() =>
		isRTL()
			? {
					marginRight: `-${TRIANGLE_SIZE}px`,
					borderLeft: `${TRIANGLE_SIZE}px solid transparent`,
			  }
			: {
					marginLeft: `-${TRIANGLE_SIZE}px`,
					borderRight: `${TRIANGLE_SIZE}px solid transparent`,
			  }}
`;

export const TagWrapper = styled.div`
	position: absolute;
	top: 12px;
	z-index: 1;
	${() => left(120)}
`;
