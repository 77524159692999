import { SecondaryMediumButtonAuto, TertiaryMediumButtonAuto } from '@Components/controls/Button/Button';
import { SquareCheckbox } from '@Components/controls/Checkbox/Checkbox';
import Icon from '@Components/Icon/Icon';
import React, { useState } from 'react';
import {
	CategoryCardContainer,
	CategoryTextWrapper,
	CategoryWrapper,
	IconWrapper,
	FilterSubheadingWrapper,
	ShowMoreButtonRow,
	RangeRow,
	RangeInput,
	RangeSeparator,
	RangeButtonsRow,
} from './FilterCard.styled';
import TextField from '@Components/controls/TextField/TextField';
import { IFilterEntry } from '@ApiModels/productsByCategoryId';
import { dispatch } from '@Redux/store';
import { toggleFilter } from '@Redux/modules/filters/actions';
import { useSelector } from 'react-redux';
import { clearPriceFilter, setPriceFilter } from '@Redux/modules/priceFilter/actions';
import useTranslate from '@Utils/hooks/useTranslate';
import ConditionalRender from '@Components/ConditionalRender/ConditionalRender';
import TagChip from '@Components/controls/TagChip/TagChip';
import Divider from '@Components/Divider/Divider';

const FilterCard = ({
	params: {
		values,
		collapsable,
		collapsed = false,
		filter,
		expandable,
		expanded = true,
		hideMoreThan,
		range,
		maxLength = 8,
	},
}: types.cards.IFilterCardProps): JSX.Element => {
	const [isCollapsed, setIsCollapsed] = useState(collapsed);
	const [isExpanded, setIsExpanded] = useState(expanded);
	const [resetSaveButtons, setResetSaveButtons] = useState<boolean>(false);
	const priceFilter = useSelector((state: types.redux.IState) => state.priceFilter);
	const { translate } = useTranslate();
	const [min, setMin] = useState<number | undefined>(priceFilter.min);
	const [max, setMax] = useState<number | undefined>(priceFilter.max);

	function returnValues() {
		if (values && Array.isArray(values)) {
			let lengthToShow: number;
			if (isExpanded) {
				lengthToShow = values.length;
			} else if (typeof hideMoreThan === 'number') {
				lengthToShow = hideMoreThan;
			} else {
				lengthToShow = values.length;
			}
			const enabledFilters = values.filter((item) => item.enabled).length;

			if (lengthToShow < enabledFilters) {
				lengthToShow = enabledFilters;
			}

			const _values = [...values];

			if (!isExpanded) {
				const enabled: IFilterEntry[] = [];
				const indexListOfNotEnabled: number[] = [];
				values.map((item, index) => {
					if (index + 1 > lengthToShow && item.enabled) {
						enabled.push(item);
					} else if (!item.enabled && index < lengthToShow) {
						indexListOfNotEnabled.push(index);
					}
				});
				if (enabled.length) {
					enabled.forEach((item) => {
						_values[indexListOfNotEnabled[indexListOfNotEnabled.length - 1]] = item;
						indexListOfNotEnabled.splice(indexListOfNotEnabled.length - 1);
					});
				}
			}

			return _values.slice(0, lengthToShow).map((value, index) => <Button entry={value} key={index} />);
		}
	}

	function returnButton() {
		if (expandable && values && Array.isArray(values)) {
			if (typeof hideMoreThan !== 'number' || hideMoreThan >= values.length) {
				return null;
			} else {
				let buttonLabel;
				if (isExpanded) {
					buttonLabel = translate('filters.show-less');
				} else {
					buttonLabel = translate('filters.show-more', values.length - hideMoreThan);
				}
				return (
					<ShowMoreButtonRow>
						<TertiaryMediumButtonAuto
							uniqueId="set.is.expanded.filter.card"
							onClick={() => {
								setIsExpanded(!isExpanded);
							}}
						>
							{buttonLabel}
						</TertiaryMediumButtonAuto>
					</ShowMoreButtonRow>
				);
			}
		}
	}

	const onSavePriceRange = () => {
		setResetSaveButtons(false);
		dispatch(setPriceFilter({ max, min, active: false }));
	};

	const getLabel = () => {
		if (priceFilter.max && priceFilter.min) {
			return translate('price-filter.range', priceFilter.min, priceFilter.max);
		} else if (priceFilter.max) {
			return translate('price-filter.less', priceFilter.max);
		}
		return translate('price-filter.more', priceFilter.min ?? '');
	};

	const showResetSaveButtons = (min: number, max: number) => {
		if (min === undefined && max === undefined) {
			setResetSaveButtons(false);
		} else {
			setResetSaveButtons(true);
		}
	};

	return (
		<CategoryCardContainer>
			<CategoryTextWrapper
				onClick={() => {
					if (collapsable) {
						setIsCollapsed(!isCollapsed);
					}
				}}
			>
				<ConditionalRender
					show={!!filter}
					onTrue={() => <FilterSubheadingWrapper>{filter}</FilterSubheadingWrapper>}
				/>
				<ConditionalRender
					show={!!collapsable}
					onTrue={() => (
						<IconWrapper focused={isCollapsed}>
							<Icon name="chevronUp" fill="black38" />
						</IconWrapper>
					)}
				/>
			</CategoryTextWrapper>
			<ConditionalRender
				show={!isCollapsed && !!values}
				onTrue={() => (
					<>
						{returnValues()}
						{returnButton()}
					</>
				)}
			/>
			{!isCollapsed && range && (
				<ConditionalRender
					show={!!priceFilter.active}
					onTrue={() => (
						<>
							<RangeRow>
								<RangeInput>
									<TextField
										label={translate('price-filter.from')}
										id="min"
										onChange={(value) => {
											setMin(Number(value));
											showResetSaveButtons(Number(value), priceFilter.max ?? 0);
										}}
										maxlength={maxLength}
										value={min ?? ''}
									/>
								</RangeInput>
								<RangeSeparator />
								<RangeInput>
									<TextField
										label={translate('price-filter.to')}
										id="max"
										onChange={(value) => {
											setMax(Number(value));
											showResetSaveButtons(priceFilter.min ?? 0, Number(value));
										}}
										maxlength={maxLength}
										value={max ?? ''}
									/>
								</RangeInput>
							</RangeRow>
							{resetSaveButtons ? (
								<RangeButtonsRow>
									<TertiaryMediumButtonAuto
										uniqueId="reset.save.buttons"
										onClick={() => {
											setMin(undefined);
											setMax(undefined);
											dispatch(clearPriceFilter());
											setResetSaveButtons(false);
										}}
									>
										{translate('price-filter.reset')}
									</TertiaryMediumButtonAuto>
									<SecondaryMediumButtonAuto
										uniqueId="price-filter.save"
										onClick={onSavePriceRange}
										disabled={Number(max) < Number(min)}
									>
										{translate('price-filter.save')}
									</SecondaryMediumButtonAuto>
								</RangeButtonsRow>
							) : (
								''
							)}
						</>
					)}
					onFalse={() => (
						<>
							<Divider color="transparent" marginBottom={22} />
							<TagChip
								label={getLabel()}
								onClick={() => {
									dispatch(clearPriceFilter());
								}}
								selected
								iconName="close"
							/>
						</>
					)}
				/>
			)}
		</CategoryCardContainer>
	);
};

const Button = ({ entry }: { entry: IFilterEntry }) => {
	return (
		<CategoryWrapper key={entry.value}>
			<SquareCheckbox
				active={entry.enabled}
				onChange={() => {
					dispatch(toggleFilter(entry));
				}}
				label={entry.label}
				count={entry.count}
				labelClickable
			/>
		</CategoryWrapper>
	);
};

export default FilterCard;
