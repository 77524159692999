import styled from 'styled-components';
import { marginRight } from '@Utils/layout/layoutSupport';

export const LongDescWrapper = styled.div`
	font-family: 'FSAlbert-Regular';
	${() => marginRight(15)}
`;

export const JawwyBanner = styled.img`
	width: 100%;
	height: 100%;
	${() => marginRight(15)}
	margin-top: 16px;
`;
