import styled from 'styled-components';
import * as cssTypes from 'csstype';

interface ISkeletonRowContainerProps extends types.IStyledProps {
	marginBottom?: number;
	marginTop?: number;
	marginLeft?: number;
	marginRight?: number;
	paddingBottom?: number;
	paddingTop?: number;
	paddingLeft?: number;
	paddingRight?: number;
	height?: number;
}

interface ISizeProps extends types.IStyledProps {
	width?: number | string;
	height?: number | string;
	color?: types.theme.themeColors;
}

interface IFormatProps {
	divHeight?: string;
	justifyContent?: cssTypes.Property.JustifyContent;
	alignItems?: cssTypes.Property.AlignItems;
	flexDirection?: cssTypes.Property.FlexDirection;
	flexWrap?: cssTypes.Property.FlexWrap;
}

interface IFlexGrowProps {
	flexGrow?: cssTypes.Property.FlexGrow;
}

interface ISkeletonColumnProps {
	alignItems?: cssTypes.Property.AlignItems;
	justifyContent?: cssTypes.Property.JustifyContent;
	mobileMenu?: boolean;
	height?: number | string;
}

export const withMarginsAndPaddings = styled.div<types.IStyledProps & ISkeletonRowContainerProps>`
	${({
		marginBottom = 0,
		marginLeft = 0,
		marginRight = 0,
		marginTop = 0,
		paddingTop = 0,
		paddingBottom = 0,
		paddingLeft = 0,
		paddingRight = 0,
	}) => ({
		marginBottom,
		marginLeft,
		marginRight,
		marginTop,
		paddingBottom,
		paddingLeft,
		paddingRight,
		paddingTop,
	})}
`;

const animation = `
animation: blinker 1s ease-in-out infinite;
@keyframes blinker {
	50% {
		opacity: 30%;
	}
}`;

export const Circle = styled(withMarginsAndPaddings)<ISizeProps>`
	align-self: flex-start;
	border-radius: 200px;
	${animation}
	${({ width = 20, height = 20, theme, color }: ISizeProps) => {
		return {
			backgroundColor: color ? theme.colors[color] : theme.colors.grey100,
			height,
			width,
		};
	}}
`;

export const Rectangular = styled(withMarginsAndPaddings)<ISizeProps>`
	border-radius: 6px;
	${animation}
	${({ width = 128, height = 16, theme, color }: ISizeProps) => {
		return {
			backgroundColor: color ? theme.colors[color] : theme.colors.grey100,
			height,
			width,
		};
	}}
`;

export const PlaceHolder = styled(withMarginsAndPaddings)<ISizeProps>`
	${({ width = 128, height = 16, theme, color }: ISizeProps) => {
		return {
			height,
			width,
		};
	}}
`;

export const SkeletonRow = styled(withMarginsAndPaddings)<types.IStyledProps>`
	flex-direction: row;
	align-items: center;
	width: 100%;
`;

export const SkeletonColumn = styled(withMarginsAndPaddings)<ISkeletonColumnProps>`
	display: flex;
	flex-direction: column;
	justify-content: ${({ justifyContent = 'flex-start' }) => justifyContent};
	align-items: ${({ alignItems = 'flex-start' }) => alignItems};
	width: 100%;
	flex-shrink: 1;
`;

export const SkeletonCardContainer = styled(withMarginsAndPaddings)<types.IStyledProps & { width?: string }>`
	background: ${({ theme }) => theme.colors.white};
	border-radius: 10px;
	left: 0;
	padding-bottom: 16px;
	padding-left: 16px;
	padding-right: 16px;
	padding-top: 16px;
	box-sizing: border-box;
	${({ width = '100%' }) => {
		return {
			width,
		};
	}}
`;

export const SkeletonGridCardContainer = styled(withMarginsAndPaddings)<types.IStyledProps>`
	background: ${({ theme }) => theme.colors.white};
	border-radius: 10px;
	left: 0;
	padding-bottom: 16px;
	padding-left: 16px;
	padding-right: 16px;
	padding-top: 16px;
`;

export const DisplayFlex = styled(withMarginsAndPaddings)<IFormatProps>`
	display: flex;
	justify-content: ${({ justifyContent = 'normal' }) => justifyContent};
	align-items: ${({ alignItems = 'normal' }) => alignItems};
	height: ${({ divHeight = 'auto' }) => divHeight};
	flex-direction: ${({ flexDirection = 'row' }) => flexDirection};
	flex-wrap: ${({ flexWrap = 'unset' }) => flexWrap};
`;

export const FlexGrow = styled(withMarginsAndPaddings)<IFlexGrowProps>`
	flex-grow: ${({ flexGrow = 1 }) => flexGrow};
`;

export const BottomContentWrapper = styled(SkeletonColumn)<ISkeletonColumnProps>``;

export const RightFlexWrapper = styled(withMarginsAndPaddings)<types.IStyledProps>`
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	justify-content: center;
`;
