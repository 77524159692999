import { IProductsByPartNumber } from '@ApiModels/productsByPartNumber';
import { ICartItem, IInsuranceCartItem } from '@ApiModels/viewCart';
import ConditionalRender from '@Components/ConditionalRender/ConditionalRender';
import { EXCLUDE_CART_ITEMS } from '@Components/GetInsurance/GetInsurance';
import Icon from '@Components/Icon/Icon';
import { ItemType, ProductsService } from '@Services/productsService/productsService';
import useCartPlans from '@Utils/hooks/useCartPlans';
import useTranslate from '@Utils/hooks/useTranslate';
import { BUY_HBB, BUY_WFBB } from '@Views/Map/Map';
import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import {
	ESIMContainer,
	ESIMTitleContainer,
	IconWrapper,
	IconWrapperPlus,
	Image,
	ImageContainer,
	InsuranceContainer,
	InsuranceInfo,
	InsuranceSubtitle,
	InsuranceTitle,
	ItemDescription,
	ItemDescriptionWrapper,
	ItemTitle,
	ItemWrapper,
	OrderItemContainer,
	OrderItemDescription,
	OrderItemDescriptionContainer,
	OrderItemTitle,
	PlanTitleWrapper,
	TitleWrapper,
} from './OrderItem.styled';
import { INSURANCE_REQUEST_TYPE } from '@Config/app.config';
import { INSURANCE_TYPE } from '@Utils/context/InsuranceContext';
import { getLang } from '@Utils/language/language';
import { POSTPAID_PLAN } from '@Config/app.config';
interface IOrderItemProps {
	item: ICartItem | IInsuranceCartItem;
}

const MOBILE_PLAN_UPGRADE = 'MOBILE_PLAN_UPGRADE';

const OrderItem: FunctionComponent<IOrderItemProps> = ({ item }) => {
	const { partNumber, quantity, plan, esimAction, esimNo, requestType, logo, type } = item;
	const { manufacturer: carManufacturer, model, name: insuranceProviderName, features } = item as IInsuranceCartItem;
	const [product, setProduct] = useState<IProductsByPartNumber>();
	const { translate } = useTranslate();
	const { planOfSpecificAccount, cartItemWithPlan, cart } = useCartPlans({}, [product]);

	useEffect(() => {
		ProductsService.getProductsByPartNumber(partNumber).subscribe(
			(product) => {
				setProduct(product);
			},
			() => {}
		);
	}, [partNumber]);

	const buyCarInsurance = useMemo(() => {
		return !!requestType && INSURANCE_REQUEST_TYPE.includes(requestType);
	}, [requestType]);

	const isInsurance = useMemo(() => {
		return EXCLUDE_CART_ITEMS.includes(partNumber);
	}, [partNumber]);

	const isWFBB = useMemo(() => (item?.plan?.PA ? [BUY_WFBB].includes(item?.plan?.PA) : false), [item]);

	const isHBB = useMemo(() => (item?.plan?.PA ? [BUY_HBB].includes(item?.plan?.PA) : false), [item]);

	const hbbWfbbCart = useMemo(() => {
		return cart.items.find((item) => [BUY_HBB, BUY_WFBB].includes(item.plan?.PA || ''));
	}, [partNumber]);

	const { manufacturer, name, thumbnailUrl, capacity, color, isGiftCard, giftCardValue, country, isDigitalProduct } =
		product || {};

	const eSIMOrder = useMemo(() => {
		return (
			<ConditionalRender
				show={!!esimNo && !!esimAction}
				onTrue={() => (
					<ESIMContainer>
						<ImageContainer>
							<Icon name="plus" width={16} height={16} fill="black54" />
						</ImageContainer>
						<ESIMTitleContainer>
							<ItemTitle>
								{translate(esimAction === 'NEW' ? 'order-item.new-esim' : 'order-item.transfer-esim')}
							</ItemTitle>
							<ItemDescription>+968 {esimNo}</ItemDescription>
						</ESIMTitleContainer>
					</ESIMContainer>
				)}
			/>
		);
	}, [esimAction, esimNo]);

	const selectedPlanComponent = () => (
		<ConditionalRender
			show={
				!!plan?.planName &&
				plan?.PA !== ItemType.ITEM_ACCESSORY_INSTALMENTS &&
				plan?.PA !== ItemType.ITEM_ACCESSORY_INSTALMENTS_HBB &&
				plan?.PA !== ItemType.ITEM_ACCESSORY_INSTALMENTS_WFBB &&
				plan?.PA !== ItemType.TABLETS_LAPTOPS_INSTALMENTS &&
				plan?.PA !== ItemType.TABLETS_LAPTOPS_INSTALMENTS_HBB &&
				plan?.PA !== ItemType.TABLETS_LAPTOPS_INSTALMENTS_WFBB &&
				plan?.PA !== ItemType.B2B2C_ITEM_ACCESSORY_BAQATI &&
				plan?.PA !== ItemType.B2B2C_TABLETS_LAPTOPS_BAQATI &&
				plan?.PA !== ItemType.B2B_ITEM_ACCESSORY_BAQATI &&
				plan?.PA !== ItemType.B2B_TABLETS_LAPTOPS_BAQATI &&
				plan?.PA !== POSTPAID_PLAN
			}
			onTrue={() => (
				<ItemWrapper>
					<Icon name={isHBB || isWFBB ? 'buyHbbWfbb' : 'eSim'} height={40} width={40} />
					<ConditionalRender
						show={isHBB || isWFBB}
						onTrue={() => (
							<>
								<PlanTitleWrapper>
									<ItemTitle>
										{hbbWfbbCart?.plan?.planName || hbbWfbbCart?.plan?.shortDesc || ''}
									</ItemTitle>
									<ItemDescription>
										{translate(
											'product.cart.plan.duration.contract',
											hbbWfbbCart?.plan?.planContractDuration ?? '-'
										)}
									</ItemDescription>
								</PlanTitleWrapper>
							</>
						)}
						onFalse={() => (
							<>
								<ItemDescriptionWrapper>
									<ConditionalRender
										show={
											!!planOfSpecificAccount?.name &&
											cartItemWithPlan?.plan?.PA === MOBILE_PLAN_UPGRADE
										}
										onTrue={() => (
											<PlanTitleWrapper>
												<TitleWrapper>
													<ItemTitle>{planOfSpecificAccount?.name}</ItemTitle>
												</TitleWrapper>
												<IconWrapper>
													<Icon name="arrowSwipe" width={16} height={16} fill="black87" />
												</IconWrapper>
												<TitleWrapper>
													<ItemTitle>
														{cartItemWithPlan?.plan?.planName ||
															cartItemWithPlan?.plan?.shortDesc}
													</ItemTitle>
												</TitleWrapper>
											</PlanTitleWrapper>
										)}
										onFalse={() => <ItemTitle>{planOfSpecificAccount?.name}</ItemTitle>}
									/>
									<ItemDescription>
										{cartItemWithPlan?.plan?.planContractDuration
											? translate(
													'product.cart.plan.duration.upgrade',
													cartItemWithPlan?.plan?.planContractDuration ?? '-'
											  )
											: translate('product.cart.plan.no.commitment')}
									</ItemDescription>
								</ItemDescriptionWrapper>
							</>
						)}
					/>
				</ItemWrapper>
			)}
		/>
	);

	const InsuranceComponent = () => {
		return (
			<InsuranceContainer>
				<IconWrapperPlus>
					<Icon name="plus" width={16} height={16} />
				</IconWrapperPlus>
				<InsuranceInfo>
					<InsuranceTitle>{name}</InsuranceTitle>
					<InsuranceSubtitle>
						{translate('order.item.insurance.subtitle', product?.duration || '')}
					</InsuranceSubtitle>
				</InsuranceInfo>
			</InsuranceContainer>
		);
	};

	return (
		<>
			<ConditionalRender
				show={isInsurance}
				onTrue={() => <InsuranceComponent />}
				onFalse={() => (
					<ConditionalRender
						show={!buyCarInsurance && !isWFBB && !isHBB && item.PA !== POSTPAID_PLAN}
						onTrue={() => (
							<OrderItemContainer>
								<ImageContainer>
									<Image src={thumbnailUrl} />
								</ImageContainer>
								<OrderItemDescriptionContainer>
									<OrderItemTitle>
										{manufacturer} {name}
									</OrderItemTitle>
									<ConditionalRender
										show={!isGiftCard && !isDigitalProduct}
										onTrue={() => (
											<OrderItemDescription>{`${capacity ? `${capacity}, ` : ''}${
												color ?? ''
											}`}</OrderItemDescription>
										)}
										onFalse={() => (
											<ConditionalRender
												show={!isDigitalProduct}
												onTrue={() => (
													<OrderItemDescription>
														{giftCardValue}, {country}
													</OrderItemDescription>
												)}
												onFalse={() => (
													<OrderItemDescription>
														{translate(
															'cart-item.subscription',
															giftCardValue?.toLowerCase() ?? ''
														)}
													</OrderItemDescription>
												)}
											/>
										)}
									/>
									<ConditionalRender
										show={quantity > 1}
										onTrue={() => (
											<OrderItemDescription>
												{translate('cart.items.quantity', quantity)}
											</OrderItemDescription>
										)}
									/>
								</OrderItemDescriptionContainer>
							</OrderItemContainer>
						)}
					/>
				)}
			/>
			<ConditionalRender
				show={buyCarInsurance}
				onTrue={() => (
					<OrderItemContainer>
						<ImageContainer>
							<Image src={logo} />
						</ImageContainer>
						<OrderItemDescriptionContainer>
							<OrderItemTitle>{item.plan?.name}</OrderItemTitle>
							<OrderItemDescription>
								{carManufacturer}
								{',\xa0'}
								{model}
							</OrderItemDescription>
							<OrderItemDescription>
								{(type as string) === INSURANCE_TYPE.FULL_COMPREHENSIVE
									? translate('insurance.type.choice1')
									: translate('insurance.type.choice2')}
								, {features}
							</OrderItemDescription>
						</OrderItemDescriptionContainer>
					</OrderItemContainer>
				)}
			/>
			<ConditionalRender
				show={item.PA === POSTPAID_PLAN}
				onTrue={() => (
					<>
						<ItemWrapper>
							<ImageContainer>
								<Icon name="eSim" height={48} width={48} />
							</ImageContainer>
							<ItemDescriptionWrapper>
								<ItemTitle>{item.plan?.planName}</ItemTitle>
								<ItemDescription>
									{translate('product.cart-card.buySim.SimIncluded')}
									{',\xa0'}
									{getLang() === 'ar'
										? `${item.plan?.durationLabel}\xa0${translate(
												'product.cart-card.buySim.plan-valid'
										  )}`
										: `${translate('product.cart-card.buySim.plan-valid')}\xa0${
												item.plan?.durationLabel
										  }`}
									{',\xa0'}
									{translate('product.cart-card.upfront-payment')}
								</ItemDescription>
							</ItemDescriptionWrapper>
						</ItemWrapper>
					</>
				)}
			/>
			{selectedPlanComponent()}
			{eSIMOrder}
		</>
	);
};

export default OrderItem;
