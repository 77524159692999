import React, { FunctionComponent, useState, useRef, useEffect } from 'react';
import { ComparisonWrapper } from './Comparison.styled';
import NavigationBar from '@Components/NavigationBar/NavigationBar';
import Comparison from '@Views/Comparison/Comparison';
import Divider from '@Components/Divider/Divider';
import { NAVBAR_HEIGHT } from '@Layouts/Dashboard/Dashboard';
import { isLargeScreen, matchScreen } from '@Utils/layout/layoutSupport';

const Dashboard: FunctionComponent = () => {
	const navbarRef = useRef<HTMLDivElement>();
	const [leftOffset, setLeftOffset] = useState<number>(navbarRef.current?.getBoundingClientRect().x ?? 0);
	const isSmallDevice = matchScreen(['xs', 'sm']);

	useEffect(() => {
		window.addEventListener('resize', () => {
			setLeftOffset(navbarRef.current?.getBoundingClientRect().x ?? 0);
		});
	}, []);

	const setRef = (node: HTMLDivElement | null) => {
		if (node) {
			setLeftOffset(node.getBoundingClientRect().x);
			navbarRef.current = node;
		}
	};

	useEffect(() => {
		window.scrollTo({ top: 0 });
	}, []);

	return (
		<ComparisonWrapper footerHeight={32}>
			<NavigationBar ref={setRef} />
			<Divider marginBottom={NAVBAR_HEIGHT - (isLargeScreen() ? 0 : isSmallDevice ? 56 : 12)} />
			<Comparison paddingLeft={leftOffset} />
		</ComparisonWrapper>
	);
};

export default Dashboard;
