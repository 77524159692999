import { Observable } from 'rxjs';
import api from '@Utils/api';
import { IGetVehicleInsurance } from '@ApiModels/../queries/omantelApi';
import { getLanguage } from '@Utils/language/language';
import { IVehicleInsurance } from '@ApiModels/vehicleInsurance';
import { IVehiclePlateChars } from '@ApiModels/vehiclePlateChars';
import { AjaxResponse } from 'rxjs/ajax';
export class InsuranceService {
	public static getVehicleInsurance(
		payload: Omit<IGetVehicleInsurance, 'languageCode'>
	): Observable<IVehicleInsurance> {
		const languageCode: string = getLanguage().toLowerCase();
		return api.omantelApi.getVehicleInsurance({ ...payload, languageCode }).call();
	}

	public static getVehiclePlateChars(): Observable<IVehiclePlateChars> {
		const languageCode: string = getLanguage().toLowerCase();
		return api.omantelApi.getVehiclePlateChars(languageCode).call();
	}

	public static getTermsAndConditions(): Observable<AjaxResponse> {
		return api.wcm.getTermsAndConditions().call();
	}
}

export default InsuranceService;
