import React, { FunctionComponent, useState } from 'react';
import {
	AdditionalInfoContainer,
	AdditionalInfoContainerTabContainer,
	FAQContainer,
	AccordionContentWrapper,
	SectionContent,
	SectionTitle,
	SectionContentDescription,
	FooterSubtitle,
	ReturnPolicySection,
	ReturnPolicyTitle,
} from './ProductAdditionalInfoPanel.styled';
import { PrimaryTabs } from '@Components/controls/Tabs/Tabs';
import useTranslate from '@Utils/hooks/useTranslate';
import TechnicalSpecification from './TechnicalSpecification/TechnicalSpecification';
import LongDescription from './LongDescription/LongDescription';
import { ITechnicalSpecification } from '@ApiModels/productById';
import Accordion from '@Components/Accordion/Accordion';
import { DisplayMBold } from '@Components/Typography/Typography.styled';
import Divider from '@Components/Divider/Divider';
import ConditionalRender from '@Components/ConditionalRender/ConditionalRender';
import { CUSTOMER_B2B2C_CARE_PHONE, CUSTOMER_CARE_WHATSAPP } from '@Config/app.config';
import Reviews from './Reviews/Reviews';

interface IProductAdditionalInfoPanel {
	marginTop?: number;
	marginBottom?: number;
	activeTabMarginTop?: number;
	activeTabMarginBottom?: number;
	data: {
		longDescription?: string;
		technicalSpecification?: ITechnicalSpecification[];
		isJawwyTv?: boolean;
	};
}
const faqTranslationKeysBusinessDevice = [
	{
		title: 'order',

		content: [
			{
				subtitle: 'how.can.request.businessdevice',
				content: 'how.can.request.businessdevice.content',
				html: true,
			},

			{ subtitle: 'track.businessdevice.order', content: 'track.businessdevice.order.content', html: true },

			{
				subtitle: 'buy.bulk.quantity.businessdevice',
				content: 'buy.bulk.quantity.businessdevice.content',
				html: false,
			},
			{
				subtitle: 'not.listed.businessdevice.purchase',
				content: 'not.listed.businessdevice.purchase.content',
				html: false,
			},
		],
	},
];

const faqTranslationKeys = [
	{
		title: 'order',

		content: [
			{ subtitle: 'how.can.purchase', content: 'how.can.purchase.content', html: false },

			{ subtitle: 'what.information', content: 'what.information.content', html: false },

			{ subtitle: 'have.the.product', content: 'have.the.product.content', html: false },
		],
	},

	{
		title: 'payment',

		content: [
			{ subtitle: 'payment.methods', content: 'payment.methods.content', html: false },

			{ subtitle: 'pay.cash', content: 'pay.cash.content', html: false },

			{ subtitle: 'can.purchase.installment', content: 'can.purchase.installment.content', html: false },

			{ subtitle: 'vat.charged', content: 'vat.charged.content', html: false },
		],
	},

	{
		title: 'return.and.cancellation',

		content: [
			{ subtitle: 'what.is.return.policy', content: 'what.is.return.policy.content', html: false },

			{ subtitle: 'what.is.order.cancellation', content: 'what.is.order.cancellation.content', html: false },
		],
	},

	{
		title: 'delivery',

		content: [
			{ subtitle: 'what.delivery.charges', content: 'what.delivery.charges.content', html: false },

			{ subtitle: 'how.long.receive.order', content: 'how.long.receive.order.content', html: false },

			{ subtitle: 'can.track.order', content: 'can.track.order.content', html: false },
		],
	},

	{
		title: 'terms.and.conditions',

		content: [{ subtitle: 'what.are.the.t.and.c', content: 'what.are.the.t.and.c.content', html: true }],
	},
];
const returnPolicyKeys = [
	{
		title: 'return.policy',
		content: 'return.policy.content',
	},
	{
		title: 'cancellation.before.shipping',
		content: 'cancellation.before.shipping.content',
	},
	{
		title: 'cancellation.before.delivery',
		content: 'cancellation.before.delivery.content',
	},
	{
		title: 'missing.order.item',
		content: 'missing.order.item.content',
	},
	{
		title: 'damaged.items',
		content: 'damaged.items.content',
	},
	{
		title: 'wrong.delivered',
		content: 'wrong.delivered.content',
	},
	{
		title: 'repair.during.warranty',
		content: 'repair.during.warranty.content',
	},
	{
		title: 'legal.disclaimer',
		content: 'legal.disclaimer.content',
	},
];

const ProductAdditionalInfoPanel: FunctionComponent<IProductAdditionalInfoPanel> = ({
	marginTop = 58,
	marginBottom = 56,
	activeTabMarginTop = 24,
	activeTabMarginBottom = 24,
	data,
}) => {
	const { translate } = useTranslate();
	const [productAdditionalInfoPanelActiveTab, setProductAdditionalInfoPanelActiveTab] = useState<number>(0);

	const createTabContainer = (content: JSX.Element) => {
		return (
			<AdditionalInfoContainerTabContainer marginTop={activeTabMarginTop} marginBottom={activeTabMarginBottom}>
				{content}
			</AdditionalInfoContainerTabContainer>
		);
	};
	const productType = true;
	const faqContent = () => (
		<FAQContainer>
			<DisplayMBold>{translate('product.additional.info.panel.faq.title')}</DisplayMBold>
			<Divider marginTop={39} />
			{(productType ? faqTranslationKeysBusinessDevice : faqTranslationKeys).map((titleWithContent) => (
				<>
					<SectionTitle>
						{translate(`product.additional.section.title.${titleWithContent.title}`)}
					</SectionTitle>
					{titleWithContent.content.map((content) => (
						<>
							<Accordion
								title={translate(`product.additional.subtitle.${content.subtitle}`)}
								subtitleColor="black54"
								smallSubtitle
								enableSubtitleOnExpand
								constantMargin
								extraBold
								withoutPadding
								accordionHeight={64}
							>
								<AccordionContentWrapper>
									<ConditionalRender
										show={!!content?.html}
										onTrue={() => (
											<SectionContentDescription>
												<LongDescription
													description={translate(
														`product.additional.subtitle.${content.content}`
													)}
												/>
											</SectionContentDescription>
										)}
										onFalse={() => (
											<SectionContent>
												{translate(`product.additional.subtitle.${content.content}`)}
											</SectionContent>
										)}
									/>
								</AccordionContentWrapper>
							</Accordion>
							<Divider />
						</>
					))}
				</>
			))}
			<Divider withoutLine marginTop={70} />
			<FooterSubtitle>{translate('product.additional.find.the.answer')}</FooterSubtitle>
			<Divider withoutLine marginTop={32} />
			<FooterSubtitle>{translate('product.additional.call.centre', CUSTOMER_B2B2C_CARE_PHONE)}</FooterSubtitle>
			<FooterSubtitle>{translate('product.additional.on.whatsapp', CUSTOMER_CARE_WHATSAPP)}</FooterSubtitle>
		</FAQContainer>
	);

	const returnPolicyContent = () => (
		<FAQContainer>
			<DisplayMBold>{translate('product.additional.info.panel.return.policy.title')}</DisplayMBold>
			<Divider marginTop={39} />
			{returnPolicyKeys.map((titleWithContent) => (
				<>
					<ReturnPolicyTitle>{translate(`return.policy.title.${titleWithContent.title}`)}</ReturnPolicyTitle>
					<ReturnPolicySection>
						<LongDescription description={translate(`return.policy.title.${titleWithContent.content}`)} />
					</ReturnPolicySection>
				</>
			))}
			<Divider withoutLine marginTop={70} />
		</FAQContainer>
	);

	const getTabs = () => {
		const tabs = [
			{
				label: translate('product.additional.description.tab.faq'),

				content: createTabContainer(faqContent()),
			},
			{
				label: translate('product.additional.description.tab.return.policy'),
				content: createTabContainer(returnPolicyContent()),
			},
		];
		if (false) {
			// user Reviews to be accepted and finished
			tabs.unshift({
				label: translate('product.additional.description.tab.reviews'),
				content: createTabContainer(<Reviews />),
			});
		}
		if ((data.technicalSpecification?.length ?? 0) > 1) {
			tabs.unshift({
				label: translate('product.additional.description.tab.technical.specification'),
				content: createTabContainer(<TechnicalSpecification items={data.technicalSpecification} />),
			});
		}
		if (data.longDescription) {
			tabs.unshift({
				label: translate('product.additional.description.tab.description'),
				content: createTabContainer(
					<LongDescription description={data.longDescription} isJawwyTv={data.isJawwyTv} />
				),
			});
		}

		// Out of scope for P1
		// { label: translate('product.additional.description.tab.reviews'), content: createTabContainer(<p>Tab reviews</p>) },

		return tabs;
	};

	return (
		<AdditionalInfoContainer marginTop={marginTop} marginBottom={marginBottom}>
			<PrimaryTabs
				justifyContent="flex-start"
				tabs={getTabs()}
				activeTab={productAdditionalInfoPanelActiveTab}
				setActiveTab={setProductAdditionalInfoPanelActiveTab}
			/>
		</AdditionalInfoContainer>
	);
};

export default ProductAdditionalInfoPanel;
