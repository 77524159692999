import styled from 'styled-components';
import { ColorPickerPosition } from '@Components/controls/ColorPicker/ColorPicker.styles';
interface ISliderWrapperProps {
	position: ColorPickerPosition;
	size: number;
	carouselVisible: boolean;
}

interface IColorDotProps {
	color: string;
	unavailable: boolean;
	isLightColor?: boolean;
}

interface IColorDotWrapperProps {
	selected: boolean;
	separator: number;
	size: number;
	position: ColorPickerPosition;
	first: boolean;
	last: boolean;
}

interface ISingleSlideProps {
	position: ColorPickerPosition;
	size: number;
}

const getDualColor = (color: string) => color.split(':');

export const SingleSlide = styled.div<ISingleSlideProps>`
	display: inline-flex;
	justify-content: center;
	align-items: center;
	${({ position, size }) =>
		position === ColorPickerPosition.HORIZONTAL
			? { flexDirection: 'row', width: size, minWidth: size }
			: { flexDirection: 'column', height: size, minHeight: size }}
`;

export const SliderWrapper = styled.div<ISliderWrapperProps>`
	display: inline-flex;
	overflow: hidden;
	${({ position, carouselVisible }) =>
		carouselVisible && (position === ColorPickerPosition.HORIZONTAL ? { margin: '0 16px' } : { margin: '16px 0' })}
	${({ position, size }) =>
		position === ColorPickerPosition.HORIZONTAL
			? { flexDirection: 'row', width: size }
			: { flexDirection: 'column', height: size }}
`;

export const ColorDotWrapper = styled.div<IColorDotWrapperProps>`
	width: 28px;
	height: 28px;
	display: inline-block;
	padding: 4px;
	box-sizing: border-box;
	border-radius: 50%;
	cursor: pointer;
	overflow: hidden;
	${({ separator, position }) =>
		position === ColorPickerPosition.HORIZONTAL ? { margin: `0 ${separator}px` } : { margin: `${separator}px 0` }}
	${({ size }) => ({ width: size, height: size })}
	${({ selected, theme }) => selected && { border: `solid 1px ${theme.colors.primary}`, padding: 3 }}
	${({ first, position }) =>
		first && (position === ColorPickerPosition.HORIZONTAL ? { marginLeft: 0 } : { marginTop: 0 })}
	${({ last, position }) =>
		last && (position === ColorPickerPosition.HORIZONTAL ? { marginRight: 0 } : { marginBottom: 0 })}
`;
export const ColorDot = styled.div<IColorDotProps>`
	width: 100%;
	height: 100%;
	border-radius: 50%;
	box-sizing: border-box;
	${({ theme, isLightColor = false }) => ({
		border: isLightColor ? `solid 1px ${theme.colors.black}` : '0px',
	})}
	${({ color }) => {
		if (getDualColor(color).length > 1) {
			return {
				background: `linear-gradient( 90deg, ${getDualColor(color)[0]}, ${
					getDualColor(color)[0]
				} 49%, white 49%, white 51%, ${getDualColor(color)[1]} 51% )`,
			};
		} else {
			return { backgroundColor: color };
		}
	}}
	
	${({ unavailable }) => ({ opacity: unavailable ? 0.2 : 1 })}
`;

export const CountryDot = styled.img`
	width: 100%;
	height: 100%;
	border-radius: 50%;
`;

export const CrossLine = styled.div`
		position: relative;
		width: 100%;
		height: 100%;
		left: 0;
		bottom: 11px;
		border-top: 3px solid;
		border-color: ${({ theme }) => theme.colors.warning};
		transform: skewY(-45deg);
	}
`;
