import { ISetModalAction, setModal, setModalState } from './actions';
import immerHandleActions from '@Redux/utils/immerHandleActions';

const reducers = {
	[setModal.toString()](draft: types.redux.IModal, action: ISetModalAction) {
		const {
			type,
			data,
			availableClosing = true,
			closeAllModals = false,
			actionOnClose,
			withoutContainer = false,
		} = action.payload;
		if (closeAllModals) {
			draft.modalList.splice(0, draft.modalList.length);
			return draft;
		}
		if (!!type) {
			draft.modalList.push({
				actionOnClose,
				availableClosing,
				data,
				type,
				withoutContainer,
			});
		} else {
			draft.modalList.pop();
		}
		return draft;
	},
	[setModalState.toString()](draft: types.redux.IModal, action: any) {
		const modalState = action.payload;
		const modalType = action.payload.modalType;
		draft.modalState = {
			...draft.modalState,
			[modalType]: {
				...modalState,
			},
		};
		return draft;
	},
};

const init: types.redux.IModal = {
	availableClosing: true,
	modalList: [],
	modalState: {},
};

export default immerHandleActions(reducers, init);
