import { dispatch, store } from '@Redux/store';
import { signIn } from './actions';

export const isUserSignedIn = (): boolean => {
	return !!store?.getState()?.api?.authentication.signedIn;
};

export const getLoginType = (): 'guest' | 'user' | undefined => {
	return store?.getState()?.api?.authentication.loginType;
};

export const getUserId = (): string | undefined => {
	return store?.getState()?.api?.authentication.userId;
};

export const getB2bUser = (): boolean => {
	return !!store?.getState()?.api?.authentication.isB2b;
};

export const getB2b2CUser = (): boolean => {
	return !!store?.getState()?.api?.authentication.isB2b2c;
};

export const getIsMasterProfile = (): boolean | undefined => {
	return store?.getState()?.api?.authentication.isMasterProfile;
};

export const getLtpaToken = (): string => {
	const ltpa = document.cookie
		.split(';')
		.map((c) => {
			return c.trim();
		})
		.filter((c) => {
			return c.startsWith('LtpaToken2=');
		});
	if (ltpa.length > 0) {
		return ltpa[0].substring(11, ltpa[0].length);
	} else {
		dispatch(
			signIn({
				signedIn: false,
				telesales: false,
				ltpaToken2: '',
			})
		);
	}
	return '';
};
