import styled from 'styled-components';

export const ColumnWrapper = styled.div`
	padding: 8px 0;
`;

export const TableRow = styled.div`
	${({ theme }) => ({
		borderBottom: `1px solid ${theme.colors.black12}`,
	})}
`;
