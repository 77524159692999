import { Caption } from '@Components/Typography/Typography.styled';
import { isRTL } from '@Utils/layout/layoutSupport';
import styled from 'styled-components';

type ITextAreaWrapperProps = types.IStyledProps & {
	active: boolean;
	disabled: boolean;
	error: boolean;
	marginBottom?: number;
	message?: boolean;
	width?: string;
};

export const TextAreaContainer = styled.div<{ width?: string }>`
	width: ${({ width = '320px' }) => width};
	max-width: 100%;
`;

export const TextAreaWrapper = styled.div<ITextAreaWrapperProps>`
	display: inline-block;
	box-sizing: border-box;
	border-radius: 4px;
	transition: all 0.1s ease-in-out;
	width: ${({ width = '320px' }) => width};
	height: 104px;
	max-width: 100%;
	cursor: text;
	${({ theme, marginBottom, message }) => ({
		borderColor: theme.colors.black54,
		background: theme.colors.white,
		marginBottom: message ? 8 : marginBottom,
	})};

	${({ active, disabled, theme, error }) => {
		const paddingDirection = `padding-right: ${isRTL() ? 8 : 0};
		 						 padding-left: ${isRTL() ? 0 : 8};`;

		if (disabled) {
			return `
					${paddingDirection}
					cursor: default;
					padding: 8px;
					background: ${theme.colors.black6};
					border: 1px solid ${theme.colors.black12};
				`;
		} else if (error) {
			return `
					${paddingDirection}
					padding: 7px;
					border: 2px solid ${theme.colors.warning};
				`;
		} else if (active) {
			return `
					${paddingDirection}
					padding: 7px;
					border: 2px solid;
					border-color: ${theme.colors.primary};
					&:hover {
						border: 2px solid ${theme.colors.primary};
					};
					&:active {
						border: 2px solid ${theme.colors.primary};
					}`;
		} else {
			return `
					${paddingDirection}
					padding: 8px;
					border: 1px solid ${theme.colors.black87};
					&:hover {
						${paddingDirection}
						padding: 7px;
						border: 2px solid ${theme.colors.black87};
					};
					&:active {
						${paddingDirection}
						padding: 7px;
						border: 2px solid ${theme.colors.primary};
					}`;
		}
	}};
`;

export const TextAreaContent = styled.div`
	position: relative;
	display: flex;
	height: 100%;
`;

type InputProps = types.IStyledProps & { typography: keyof types.ITypography; active: boolean };

export const TextAreaInput = styled.textarea<InputProps>`
	border: none;
	resize: none;
	outline: none;
	width: 100%;
	height: 72px;
	transition: all 0.1s ease-in-out;
	padding: 0;
	margin-top: 16px;
	background: transparent;
	::placeholder {
		transition: all 0.1s ease-in-out;
		${({ theme }) => ({
			...theme.typography.value,
			color: theme.colors.black38,
		})}
	}
	${({ theme, typography, disabled }) => ({
		...theme.typography[typography],
		color: disabled ? theme.colors.black54 : theme.colors.black87,
	})}

	::placeholder {
		${({ active }) => (active ? { opacity: 1 } : { opacity: 0 })}
	}
`;

type ILabelProps = types.IStyledProps & { active: boolean; value: string | number; error: boolean; disabled: boolean };

export const Label = styled.label<ILabelProps>`
	position: absolute;
	transition: all 0.1s ease-in-out;
	user-select: none;
	${({ theme, error }) => ({
		color: theme.colors[error ? 'warning' : 'black54'],
	})}
	${({ value, active }) => (value || active ? `top: 0;` : `top: 16px; transform: translateY(-50%);`)}
	${({ value, active, theme }) => (value || active ? theme.typography.label : theme.typography.floatingLabel)}
`;

type IMessageProps = types.IStyledProps & { error: boolean; marginBottom?: number };

export const TextAreaMessage = styled(Caption)<IMessageProps>`
	${({ error, marginBottom, theme }) => {
		return {
			color: theme.colors[error ? 'warning' : 'black54'],
			marginBottom,
		};
	}}
`;

export const TextAreaCounter = styled(Caption)<IMessageProps>`
	${({ theme, error, marginBottom }) => {
		return {
			color: theme.colors[error ? 'warning' : 'black54'],
			marginBottom,
		};
	}}
	text-align: right;
`;
