interface IAvailability {
	available: boolean;
	resourceName: string;
}

export interface IProductAvailability {
	returnValue: IAvailability;
}

const model: types.models.IInputModel = {
	returnValue: 'response',
};

export default model;
