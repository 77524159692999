import React from 'react';
import { ButtonWrapper, Container, Title, TopContainer, SubTitle } from './ImageLink.styled';
import Button from '@Components/controls/Button/Button';
import { ButtonSize, ButtonVariant, ButtonWidth } from '@Components/controls/Button/Button.styled';
import { useHistory } from 'react-router-dom';
import useTranslate from '@Utils/hooks/useTranslate';
import Icon from '@Components/Icon/Icon';
import { getLang } from '@Utils/language/language';
import { BIMA } from '@Views/Store/Store';
import ConditionalRender from '@Components/ConditionalRender/ConditionalRender';

const ImageLink = ({
	params: { name, title, description, backgroundImagePath, buttonTitle, isSingleItem, redirectUrl },
}: types.cards.IImageLinkCardProps): JSX.Element => {
	const { translate } = useTranslate();
	const history = useHistory();
	return (
		<Container backgroundImagePath={backgroundImagePath} isSingleItem={isSingleItem}>
			<TopContainer name={name}>
				<ConditionalRender
					show={name === BIMA}
					onTrue={() => (
						<>
							<Title color="white">{title || translate('short.description.about.title')}</Title>
							<SubTitle color="white">{description}</SubTitle>
							<Icon name="landingPageCar" width={30} height={30} justifycontent="flex-start" />
						</>
					)}
				/>
			</TopContainer>
			<ButtonWrapper>
				<Button
					uniqueId="action.section"
					onClick={(e) => {
						name === BIMA
							? history.push(`/${getLang()}/product/car-insurance`)
							: window.open(redirectUrl, '_blank');
					}}
					variant={ButtonVariant.PRIMARY}
					size={ButtonSize.MEDIUM}
					width={ButtonWidth.FLEX}
				>
					{buttonTitle}
				</Button>
			</ButtonWrapper>
		</Container>
	);
};

export default ImageLink;
