import React, { FunctionComponent } from 'react';
import Card from '@Components/Card/Card';
import { ModalWrapper } from './PlanDetails.styled';
const PlanDetails: FunctionComponent<types.cards.IPlanDetailsProps['params']> = ({
	planId,
	isHBB,
	isWFBB,
	isPrepaid,
}) => {
	return (
		<ModalWrapper>
			<Card<types.cards.IPlanDetailsProps>
				card="planDetails"
				params={{ planId, isHBB, isWFBB, isPrepaid }}
				withoutContainerPadding
			/>
		</ModalWrapper>
	);
};

export default PlanDetails;
