import React, { FunctionComponent, useState, useRef, useEffect } from 'react';
import { CartContainer, CartWrapper } from './Cart.styled';
import NavigationBar from '@Components/NavigationBar/NavigationBar';
import Footer from '@Components/Footer/Footer';
import CartView from '@Views/Cart/Cart';
import Divider from '@Components/Divider/Divider';
import { NAVBAR_HEIGHT_WITH_PADDING } from '@Layouts/Dashboard/Dashboard';
import { isLargeScreen, matchScreen } from '@Utils/layout/layoutSupport';

const CartLayout: FunctionComponent = () => {
	const [footerHeight, setFooterHeight] = useState<number>(450);
	const navbarRef = useRef<HTMLDivElement>(null);
	const isSmallScreen = matchScreen(['xs', 'sm']);

	useEffect(() => {
		window.scrollTo({ top: 0 });
	}, [footerHeight]);

	return (
		<CartWrapper footerHeight={footerHeight}>
			<NavigationBar ref={navbarRef} />
			<Divider marginBottom={NAVBAR_HEIGHT_WITH_PADDING - (isLargeScreen() ? 0 : isSmallScreen ? 44 : 12)} />
			<CartContainer>
				<CartView />
			</CartContainer>
			<Footer setFooterHeight={setFooterHeight} />
		</CartWrapper>
	);
};

export default CartLayout;
