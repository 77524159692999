import { IValueWithoutArray } from '@ApiModels/productById';
import { IAttribute } from '@ApiModels/productsByPartNumber';

export default new Map([
	[
		'KEY_OPERATING_SYSTEM',
		(attr: IAttribute<IValueWithoutArray>) => {
			if (attr.values[0].value.toLowerCase().includes('ios')) {
				return { path: 'https://store.omantel.om/wcsstore/eshop/eshop2/icons/key_features/ic_pro_Apple.svg' };
			} else {
				return { path: 'https://store.omantel.om/wcsstore/eshop/eshop2/icons/key_features/ic_pro_Android.svg' };
			}
		},
	],
	[
		'KEY_BATTERY',
		() => {
			return { path: 'https://store.omantel.om/wcsstore/eshop/eshop2/icons/key_features/ic_pro_Battery.svg' };
		},
	],
	[
		'KEY_CAMERA',
		() => {
			return { path: 'https://store.omantel.om/wcsstore/eshop/eshop2/icons/key_features/ic_pro_Camera.svg' };
		},
	],
	[
		'KEY_DIMENSIONS',
		() => {
			return { path: 'https://store.omantel.om/wcsstore/eshop/eshop2/icons/key_features/ic_pro_Size.svg' };
		},
	],
	[
		'KEY_SCREEN_SIZE',
		() => {
			return { path: 'https://store.omantel.om/wcsstore/eshop/eshop2/icons/key_features/ic_pro_Smartphone.svg' };
		},
	],
	[
		'KEY_MEMORY',
		() => {
			return { path: 'https://store.omantel.om/wcsstore/eshop/eshop2/icons/key_features/ic_pro_Storage.svg' };
		},
	],
	[
		'KEY_WEIGHT',
		() => {
			return { path: 'https://store.omantel.om/wcsstore/eshop/eshop2/icons/key_features/ic_pro_Weight.svg' };
		},
	],
	[
		'KEY_INTERNET',
		() => {
			return { path: 'https://store.omantel.om/wcsstore/eshop/eshop2/icons/key_features/ic_pro_Wifi.svg' };
		},
	],
]);
