import immerHandleActions from '@Redux/utils/immerHandleActions';
import {
	IAddressBook,
	setActiveAddressBook,
	setNewAddressBook,
	setOutletPickupFocus,
	setRefreshAddressBook,
} from './actions';

const init = {
	activeAddressBook: '',
	newAddressBook: '',
	refreshAddressBook: false,
	outletPickupFocus: false,
};

type draft = typeof init;

const reducers = {
	[setActiveAddressBook.toString()](draft: draft, action: { payload: IAddressBook }) {
		const { activeAddressBook } = action.payload;
		draft.activeAddressBook = activeAddressBook ?? '';
		return draft;
	},
	[setNewAddressBook.toString()](draft: draft, action: { payload: IAddressBook }) {
		const { newAddressBook } = action.payload;
		draft.newAddressBook = newAddressBook ?? '';
		return draft;
	},
	[setRefreshAddressBook.toString()](draft: draft) {
		draft.refreshAddressBook = !draft.refreshAddressBook;
		return draft;
	},
	[setOutletPickupFocus.toString()](draft: draft, action: { payload: IAddressBook }) {
		const { outletPickupFocus } = action.payload;
		draft.outletPickupFocus = outletPickupFocus ?? false;
		return draft;
	},
};

export default immerHandleActions(reducers, init);
