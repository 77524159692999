import styled from 'styled-components';
import { IHourItemProps } from './HourItem';
import { CaptionBigger } from '@Components/Typography/Typography';

export const HourSlot = styled.div<IHourItemProps>`
	width: 54px;
	flex-grow: 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 12px;
	padding: 13px;
	border-radius: 8px;
	cursor: pointer;
	${({ theme, selected }) => ({
		border: `solid 1px ${theme.colors.black24};`,
		backgroundColor: selected ? theme.colors.primary24 : theme.colors.white,
	})};
	&:hover {
		${({ theme }) => ({
			backgroundColor: theme.colors.primary6,
		})};
	}
`;

export const Label = styled(CaptionBigger)<IHourItemProps>`
	margin: 0;
	padding: 0;
	${({ theme }) => ({ color: theme.colors.black87 })}
`;
