import styled from 'styled-components';

interface ICGuestWrapperProps {
	footerHeight?: number;
	minheight: number;
}

export const GuestWrapper = styled.div<ICGuestWrapperProps>`
	position: relative;
	${({ footerHeight, theme }) => ({
		backgroundColor: theme.colors.background,
		paddingBottom: footerHeight,
	})}
	min-height: ${({ minheight }) => minheight}px;
`;
