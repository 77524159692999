import React, { FunctionComponent, useEffect, useState } from 'react';
import useTranslate from '@Utils/hooks/useTranslate';
import { useSelector } from 'react-redux';
import externalLinks from '@Utils/externalLinks/externalLinks';
import { IMenuContent } from '@ApiModels/megaMenuContent';
import { ItemsWrapper, LeftContainer, LeftSectionWrapper, RightContainer } from '@Components/MegaMenu/MegaMenu.styled';
import MegaMenuMultilevelItem, {
	MegaMenuMultilevelItemType,
} from '@Components/MegaMenuMultilevelItem/MegaMenuMultilevelItem';
import ConditionalRender from '@Components/ConditionalRender/ConditionalRender';
import Divider from '@Components/Divider/Divider';
import { StoreWrapper } from '@Components/MegaMenu/subcomponents/HomeServices/MegaMenuHomeServices.styled';
import { RIGHT_DIVIDER_MARGIN_BOTTOM } from '@Components/MegaMenu/MegaMenu';
import { matchScreen } from '@Utils/layout/layoutSupport';
import { getLang } from '@Utils/language/language';

interface IMegaMenuMobileProps {
	mobileMenu: boolean;
	isMd: boolean;
	activeMenuIndex: number;
	setActiveMenuIndex: (index: number) => void;
}

const MegaMenuHomeServices: FunctionComponent<IMegaMenuMobileProps> = ({
	mobileMenu,
	isMd,
	activeMenuIndex,
	setActiveMenuIndex,
}) => {
	const [content, setContent] = useState<IMenuContent>();
	const language = useSelector((state: types.redux.IState) => state.settings.language);
	const { translate } = useTranslate();
	const isSmallDevice = matchScreen(['xs', 'sm']);

	useEffect(() => {
		return () => {
			setActiveMenuIndex(-1);
		};
	}, []);

	useEffect(() => {
		setContent({
			rows: [
				{
					label: translate('menu.home-services.item.home-internet-plans'),
					link: externalLinks('PERSONAL'),
					children: [
						{
							label: translate('menu.home-services.item.baiti-fiber'),
							link: externalLinks('ULTRA_FAST'),
						},
						{
							label: translate('menu.home-services.item.baiti-5g'),
							link: externalLinks('WIRELESS'),
						},
						{
							label: translate('menu.home-services.item.basic-adsl'),
							link: externalLinks('BASIC'),
						},
						{
							label: translate('menu.home-services.item.afaaq-satellite'),
							link: externalLinks('AFAAQ'),
						},
					],
				},
				{
					label: translate('menu.home-services.item.tv-&-entertainment'),
					link: externalLinks('PERSONAL'),
					children: [
						{
							label: translate('menu.home-services.item.osn+'),
							link: externalLinks('OSN'),
						},
						{
							label: translate('menu.home-services.item.shahid'),
							link: externalLinks('SHAHID'),
						},
						{
							label: translate('menu.home-services.item.jawwy-tv-home'),
							link: externalLinks('JAWWY_TV_HOME'),
						},
						{
							label: translate('menu.home-services.item.jawwy-TV'),
							link: externalLinks('JAWWY_TV'),
						},
					],
				},
				{
					label: translate('megamenu.home.gaming'),
					link: externalLinks('GAMING_HEADER'),
					children: [
						{
							label: translate('menu.home-services.item.geforce-now'),
							link: externalLinks('GE_FORCE_EVERYWHERE'),
						},
						{
							label: translate('menu.home-services.item.gaming-console'),
							link: `/${getLang()}/store/gaming&multimedia`,
						},
						{
							label: translate('menu.home-services.item.gaming-add-on'),
							link: externalLinks('GAMIN_ADD_ON'),
						},
					],
				},
				{
					label: translate('menu.home-services.item.devices'),
					link: externalLinks('GAMING_HEADER'),
					children: [
						{
							label: translate('menu.home-services.item.wi-fi-mesh'),
							link: externalLinks('MESH'),
						},
						{
							label: translate('megamenu.home.apple-tv'),
							link: `/${getLang()}/store/Apple%20TV`,
						},
						{
							label: translate('menu.home-services.item.smart-living'),
							link: `/${getLang()}/store/smarthome`,
						},
					],
				},
				{
					label: translate('megamenu.home.fixed-service'),
					link: externalLinks('FIXED_SERVICES'),
					children: [
						{
							label: translate('megamenu.home.family-plan'),
							link: externalLinks('HOME_FAMILY_PLAN'),
						},
						{
							label: translate('megamenu.home.fixed-line'),
							link: externalLinks('FIXED_LINE'),
						},
						{
							label: translate('megamenu.home.sahl'),
							link: externalLinks('SAHL'),
						},
					],
				},
				{
					label: translate('menu.home-services.item.track-your-hbb'),
					link: externalLinks('TRACK_HBB'),
					children: [],
				},
				{
					label: translate('menu.home-services.item.additional services'),
					link: externalLinks('ADDITIONAL_SERVICES_HOME'),
					children: [],
				},
				{
					label: translate('menu.postpaid.item.promotions'),
					link: externalLinks('PROMOTIONS_HOME_INTERNET'),
					children: [],
				},
			],
		});
	}, [language]);

	return (
		<StoreWrapper mobileMenu={!!mobileMenu} isMd={isMd}>
			<ConditionalRender
				show={activeMenuIndex < 0 || !isSmallDevice}
				onTrue={() => (
					<LeftContainer mobileMenu={mobileMenu} isMd={isMd} isSmallDevice={isSmallDevice}>
						<>
							<MegaMenuMultilevelItem
								type={MegaMenuMultilevelItemType.HEADER}
								name={translate('menu.item.homeservices')}
								iconRight="arrowRightBox"
							/>
							{content?.rows?.map((row, index) => (
								<LeftSectionWrapper key={index}>
									<ItemsWrapper mobileMenu={mobileMenu} key={row.label}>
										<MegaMenuMultilevelItem
											key={index}
											type={
												row?.children?.length > 0
													? MegaMenuMultilevelItemType.ITEM
													: MegaMenuMultilevelItemType.LINK
											}
											onClick={() => {
												row?.children?.length > 0 && setActiveMenuIndex(index);
											}}
											to={row.link}
											name={row.label}
											paddingLeft={16}
											active={activeMenuIndex === index}
											iconRight={row?.children?.length > 0 ? 'arrowRightBox' : undefined}
										/>
									</ItemsWrapper>
								</LeftSectionWrapper>
							))}
						</>
					</LeftContainer>
				)}
			/>
			<ConditionalRender
				show={activeMenuIndex >= 0}
				onTrue={() => (
					<RightContainer mobileMenu={mobileMenu} isMd={isMd} isSmallDevice={isSmallDevice}>
						<>
							<ConditionalRender
								show={!isSmallDevice}
								onTrue={() => <Divider marginBottom={RIGHT_DIVIDER_MARGIN_BOTTOM} withoutLine />}
							/>
							<ConditionalRender
								show={isMd}
								onFalse={() =>
									content?.rows[activeMenuIndex]?.children?.map(({ label, link }) => (
										<MegaMenuMultilevelItem
											paddingLeft={16}
											key={label}
											type={MegaMenuMultilevelItemType.SUB_ITEM}
											to={link}
											name={label}
										/>
									))
								}
								onTrue={() =>
									content?.rows[activeMenuIndex]?.children?.map(({ label, link }) => (
										<MegaMenuMultilevelItem
											paddingLeft={16}
											key={label}
											type={MegaMenuMultilevelItemType.SUB_ITEM}
											to={link}
											name={label}
										/>
									))
								}
							/>
						</>
					</RightContainer>
				)}
			/>
		</StoreWrapper>
	);
};

export default MegaMenuHomeServices;
