import React, { FunctionComponent, useState, useRef, useCallback, useMemo } from 'react';
import { SecondaryMediumButtonAuto, TertiaryMediumButtonFlex } from '@Components/controls/Button/Button';
import Icon from '@Components/Icon/Icon';
import { clearComparison, removeFromCompare } from '@Redux/modules/comparison/actions';
import { dispatch } from '@Redux/store';
import useTranslate from '@Utils/hooks/useTranslate';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import {
	ComparisonArrowContainer,
	ComparisonBarContainer,
	DescriptionWrapper,
	ErrorContainer,
	ErrorText,
	IconContainer,
	Thumbnail,
	ThumbnailsContainer,
	ThumbnailWrapper,
	TopThumbnailsContainer,
	ButtonWrapper,
} from './ComparisonBar.styled';
import { useEffect } from 'react';
import { Container } from 'react-grid-system';
import useScreen from '@Utils/hooks/useScreen';
import ConditionalRender from '@Components/ConditionalRender/ConditionalRender';

export const COMPARISON_PATH = '/comparison';
const WARNING_ICON_SIZE = 24;
const MAX_COMPARISON_ITEMS = 3;

const ComparisonBar: FunctionComponent = () => {
	const { translate } = useTranslate();
	const history = useHistory();
	const location = useLocation();
	const [showError, setShowError] = useState(false);
	const [width, setWidth] = useState(500);
	const containerRef = useRef<HTMLDivElement>(null);
	const { screen } = useScreen();
	const isXs = screen('xs');
	const isSm = screen('sm');
	const isSmall = isXs || isSm;
	const touchscreen = matchMedia('(hover: none)').matches;

	const comparisonList: types.redux.IState['comparisonList'] = useSelector(
		(state: types.redux.IState) => state.comparisonList
	);

	useEffect(() => {
		if (comparisonList.length > MAX_COMPARISON_ITEMS) {
			dispatch(removeFromCompare({ id: comparisonList[comparisonList.length - 1].id }));
			if (!showError) {
				setShowError(true);
				setTimeout(() => {
					setShowError(false);
				}, 5 * 1000);
			}
		}
		setWidth((containerRef.current?.firstChild as any)?.offsetWidth);
	}, [comparisonList]);

	useEffect(() => {
		const resizeListener = () => {
			setWidth((containerRef.current?.firstChild as any)?.offsetWidth);
		};
		window.addEventListener('resize', resizeListener);
		resizeListener();
		return () => {
			window.removeEventListener('resize', resizeListener);
		};
	}, []);

	const ContainerElement = isSmall ? ({ children }: { children: any }) => <div>{children}</div> : Container;

	const getMarginLeft = useCallback(() => {
		if (width < 956) {
			return 0;
		}
		return (width - 956) / 2;
	}, [width]);

	const thumbnails = useMemo(
		() =>
			comparisonList.slice(0, MAX_COMPARISON_ITEMS).map((item, index) => {
				return (
					<>
						<ThumbnailWrapper
							key={index}
							onClick={() => {
								dispatch(removeFromCompare({ id: item.id }));
							}}
							touchscreen={touchscreen}
						>
							<Thumbnail src={item.thumbnailUrl} />
							<IconContainer touchscreen={touchscreen}>
								<Icon name="plus" fill="warning" width={isSmall ? 12 : 20} height={isSmall ? 12 : 20} />
							</IconContainer>
						</ThumbnailWrapper>
						<ConditionalRender
							show={index < comparisonList.length - 1}
							onTrue={() => (
								<ComparisonArrowContainer>
									<Icon name="comparisonArrow" width={16} height={16} fill="black87" />
								</ComparisonArrowContainer>
							)}
						/>
					</>
				);
			}),
		[comparisonList, touchscreen]
	);

	return (
		<div ref={containerRef}>
			<ContainerElement>
				<ComparisonBarContainer
					bottom={comparisonList.length && !location.pathname.includes(COMPARISON_PATH) ? 0 : -120}
					width={width - (isSmall ? 24 : 54)}
					isXs={isXs}
					marginLeft={getMarginLeft()}
				>
					<ConditionalRender
						show={isXs}
						onTrue={() => <TopThumbnailsContainer>{thumbnails}</TopThumbnailsContainer>}
					/>
					<ErrorContainer showError={showError}>
						<Icon width={WARNING_ICON_SIZE} height={WARNING_ICON_SIZE} name="warning" fill="white" />
						<ErrorText>{translate('comparison.length-error')}</ErrorText>
					</ErrorContainer>
					<TertiaryMediumButtonFlex
						uniqueId="comparison-bar.cancel"
						onClick={() => {
							dispatch(clearComparison());
						}}
					>
						{translate('comparison-bar.cancel')}
					</TertiaryMediumButtonFlex>
					<ThumbnailsContainer>{!isXs && thumbnails}</ThumbnailsContainer>
					<ConditionalRender
						show={comparisonList.length <= 1}
						onTrue={() => (
							<DescriptionWrapper>{translate('comparison-bar.less-then-two')}</DescriptionWrapper>
						)}
						onFalse={() => (
							<ButtonWrapper>
								<SecondaryMediumButtonAuto
									uniqueId="comparison-bar.compare"
									disabled={comparisonList.length <= 1}
									onClick={() => {
										history.push(`${COMPARISON_PATH}?id=${comparisonList.map((it) => it.id)}`);
									}}
								>
									{translate('comparison-bar.compare')}
								</SecondaryMediumButtonAuto>
							</ButtonWrapper>
						)}
					/>
				</ComparisonBarContainer>
			</ContainerElement>
		</div>
	);
};

export default ComparisonBar;
