import styled from 'styled-components';
import { matchScreen } from '@Utils/layout/layoutSupport';
import { isRTL } from '@Utils/layout/layoutSupport';

const HEADER_HEIGHT = 56;

export const MobileMenuHeader = styled.div`
	${() => {
		return {
			height: HEADER_HEIGHT,
		};
	}}
	width: 100%;
	box-sizing: border-box;
	display: flex;
	align-items: flex-end;
`;

export const HeaderContent = styled.div`
	height: 56px;
	width: 100%;
	padding: 16px;
	box-sizing: border-box;
	display: flex;
	align-items: center;
	justify-content: flex-end;
`;

export const ListIconWrapper = styled.div`
	width: 24px;
	height: 24px;
`;

export const ContentWrapper = styled.div<{ open: boolean }>`
	${({ open, theme }) => {
		const transform = isRTL()
			? open
				? 'translate(0%, 0)'
				: 'translate(100%, 0)'
			: open
			? 'translate(0%, 0)'
			: 'translate(-100%, 0)';
		const isXS = matchScreen('xs');
		const isSM = matchScreen('sm');
		return {
			transform,
			top: 0,
			width: isXS ? '100%' : isSM ? '60%' : '40%',
			maxWidth: !matchScreen('xs') ? 467 : 'inherit',
			opacity: open ? 1 : 0,
			visibility: open ? 'visible' : 'hidden',
			backgroundColor: theme.colors.background,
		};
	}}
	position: fixed;
	height: 100%;
	z-index: 202;
	transition: all 0.3s ease-in-out;
`;

export const MenuBackground = styled.div<{ open: boolean }>`
	${({ open }) => {
		return {
			opacity: open ? 0.64 : 0,
			visibility: open ? 'visible' : 'hidden',
		};
	}}
	position: fixed;
	background: rgb(0, 0, 0);
	transition: all 300ms ease-in-out;
	pointer-events: auto;
	inset: 0px;
	z-index: 201;
`;

export const ChildrenWrapper = styled.div`
	${() => {
		return {
			overflowX: 'scroll',
			maxHeight: `calc(100% - ${HEADER_HEIGHT}px)`,
		};
	}}
	padding:0px 16px;
`;
