import appstore from '@Assets/images/appstore.png';
import playstore from '@Assets/images/playstore.png';
import ConditionalRender from '@Components/ConditionalRender/ConditionalRender';
import { PlanBarType } from '@Components/PlanBar/PlanBarType';
import { BodySBold } from '@Components/Typography/Typography';
import useScreen from '@Utils/hooks/useScreen';
import useTranslate from '@Utils/hooks/useTranslate';
import React, { FunctionComponent } from 'react';
import Plan from './Plan/Plan';
import {
	MenuPanel,
	PlanComparisonContainer,
	Plans,
	StoreCredit,
	StoreCreditIcon,
	StoreCreditIcons,
	StoreCreditText,
} from './PlanComparison.styled';
import PlanMenuItem from './PlanMenuItem/PlanMenuItem';

export interface IPlanComparison {
	id: string;
	type: PlanBarType;
	name: string;
	contractCommitment: string;
	isCurrentPlan?: boolean;
	isRecommendedPlan?: boolean;
	monthlyPayment?: number | string;
	localAndGccData?: number | string;
	localMinutes?: number | string;
	internationalMinutes?: number | string;
	roamingRecievingMinutes?: number | string;
	worldRoamingData?: number | string;
	dataSharing?: number | string;
	storeCredit?: number | string;
	keepThisPlan?: boolean;
	selectPlan?: boolean;
	dataIncluded?: number | string;
	downloadSpeed?: number | string;
	downloadSpeedUnit?: string;
	uploadSpeed?: number | string;
	freeMinutesToOmantel?: number | string;
	intlCallsDiscount?: number | string;
	planVatPercent?: number | string;
	planVatValue?: number | string;
	planContractDuration?: string;
	durationLabel?: string;
	offerPrice?: string;
	socialMediaDataValue?: number | string;
	socialMediaDataUnit?: number | string;
}

export interface IPlanComparisonProps {
	plans: IPlanComparison[];
	isProductCardRoute?: boolean;
	onPlanSelection?: (planIndex: number) => void;
	planDetailsMode?: boolean;
	currentPlanIsHBB: boolean;
	currentPlanIsPrepaid: boolean;
	hbbWfbbProcess?: boolean;
}

const PlanComparison: FunctionComponent<IPlanComparisonProps> = ({
	plans,
	onPlanSelection,
	isProductCardRoute,
	planDetailsMode = false,
	currentPlanIsHBB,
	currentPlanIsPrepaid,
	hbbWfbbProcess,
}) => {
	const { translate } = useTranslate();
	const { screenClass } = useScreen();
	const PADDING_XS = 16;
	const PADDING_S = currentPlanIsHBB ? 38 : 24;
	const PADDING_M = 30;
	const PADDING_X = 48;

	const LeftTableColumn = () => {
		return (
			<>
				<ConditionalRender
					show={!!hbbWfbbProcess}
					onTrue={() => (
						<>
							<PlanMenuItem padding={PADDING_X} title="plan-comparison.monthly.payment" />
							<PlanMenuItem padding={PADDING_M} title="plan-comparison.data.included" />
							<PlanMenuItem padding={PADDING_M} title="plan-comparison.download.speed" />
							<PlanMenuItem padding={PADDING_M} title="plan-comparison.upload.speed" />
							<PlanMenuItem padding={PADDING_XS} title="plan-comparison.free.minutes.to.fixed" />
							<PlanMenuItem padding={PADDING_M} title="plan-comparison.intl.calls.discount" />
						</>
					)}
					onFalse={() => (
						<>
							<ConditionalRender
								show={!currentPlanIsHBB && !currentPlanIsPrepaid}
								onTrue={() => (
									<>
										<PlanMenuItem padding={PADDING_S} title="plan-comparison.monthly.payment" />
										<PlanMenuItem padding={PADDING_X} title="plan-comparison.local.gcc.data" />
										<PlanMenuItem padding={PADDING_S} title="plan-comparison.local.minutes" />
										<PlanMenuItem
											padding={PADDING_S}
											title="plan-comparison.international.minutes"
										/>
										<PlanMenuItem
											padding={PADDING_S}
											title="plan-comparison.roaming.recieving.minutes"
										/>
										<PlanMenuItem padding={PADDING_S} title="plan-comparison.world.roaming.data" />
										<PlanMenuItem padding={PADDING_S} title="plan-comparison.data.sharing" />
									</>
								)}
							/>
							<ConditionalRender
								show={currentPlanIsHBB}
								onTrue={() => (
									<>
										<PlanMenuItem padding={PADDING_S} title="plan-comparison.monthly.payment" />
										<PlanMenuItem padding={PADDING_X} title="plan-comparison.data.included" />
										<PlanMenuItem padding={PADDING_M} title="plan-comparison.download.speed" />
										<PlanMenuItem padding={PADDING_M} title="plan-comparison.upload.speed" />
										<PlanMenuItem
											padding={PADDING_XS}
											title="plan-comparison.free.minutes.to.fixed"
										/>
										<PlanMenuItem padding={PADDING_X} title="plan-comparison.intl.calls.discount" />
									</>
								)}
							/>
							<ConditionalRender
								show={currentPlanIsPrepaid}
								onTrue={() => (
									<>
										<PlanMenuItem padding={PADDING_M} title="plan-comparison.data.allowance" />
										<PlanMenuItem padding={PADDING_X} title="plan-comparison.social.media.data" />
										<PlanMenuItem padding={PADDING_S} title="plan-comparison.flexi.minutes" />
										<PlanMenuItem padding={PADDING_S} title="plan-comparison.cashback.promotion" />
										<PlanMenuItem padding={PADDING_S} title="plan-comparison.plan.duration.time" />
									</>
								)}
							/>
						</>
					)}
				/>
			</>
		);
	};

	return (
		<PlanComparisonContainer>
			<ConditionalRender
				show={screenClass === 'xs' && !hbbWfbbProcess}
				onTrue={() => (
					<Plans>
						{plans.map((plan, index) => (
							<Plan
								key={index}
								plan={plan}
								max={plans.length}
								isProductCardRoute
								index={index}
								onPlanSelection={onPlanSelection}
								planDetailsMode={planDetailsMode}
								currentPlanIsHBB={currentPlanIsHBB}
								currentPlanIsPrepaid={currentPlanIsPrepaid}
								hbbWfbbProcess={hbbWfbbProcess}
							/>
						))}
					</Plans>
				)}
				onFalse={() => (
					<>
						<MenuPanel
							menuMargin={96}
							currentPlanIsPrepaid={currentPlanIsPrepaid}
							hbbWfbbProcess={hbbWfbbProcess}
						>
							<ConditionalRender
								show={!hbbWfbbProcess || screenClass !== 'xs'}
								onTrue={() => <LeftTableColumn />}
							/>
							<ConditionalRender
								show={!currentPlanIsHBB && screenClass === 'xs'}
								onTrue={() => (
									<StoreCredit>
										<StoreCreditText>
											<BodySBold color="black54">
												{translate('plan-comparison.store.credit')}
											</BodySBold>
										</StoreCreditText>
										<StoreCreditIcons>
											<StoreCreditIcon>
												<img title="credit icon" src={appstore} />
											</StoreCreditIcon>
											<StoreCreditIcon>
												<img title="credit icon" src={playstore} />
											</StoreCreditIcon>
										</StoreCreditIcons>
									</StoreCredit>
								)}
							/>
						</MenuPanel>
						<Plans hbbWfbbProcess={hbbWfbbProcess}>
							{plans.map((plan, index) => (
								<Plan
									key={index}
									plan={plan}
									isProductCardRoute
									max={plans.length}
									index={index}
									onPlanSelection={onPlanSelection}
									planDetailsMode={planDetailsMode}
									currentPlanIsHBB={currentPlanIsHBB}
									currentPlanIsPrepaid={currentPlanIsPrepaid}
									hbbWfbbProcess={hbbWfbbProcess}
								/>
							))}
						</Plans>
					</>
				)}
			/>
		</PlanComparisonContainer>
	);
};

export default PlanComparison;
