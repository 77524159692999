import { getLayoutDirection } from '@Redux/modules/settings/layout/selectors';
import { CSSObject, CSSProperties } from 'styled-components';
import { ScreenClass, useScreenClass } from 'react-grid-system';

export function checkLayoutDirection(language: types.redux.settings.language): types.redux.settings.layout {
	const rtlLanguages = ['AR'];
	const _isRTL = rtlLanguages.includes(language);
	return _isRTL ? 'rtl' : 'ltr';
}

export function isRTL(): boolean {
	return getLayoutDirection() === 'rtl';
}

export function matchScreen(match: ScreenClass | ScreenClass[]): boolean {
	const usedScreenClass = useScreenClass() as ScreenClass;

	if (Array.isArray(match)) {
		return match.includes(usedScreenClass);
	}
	return usedScreenClass === match;
}

export function marginLeft(val: number | string): CSSObject {
	return isRTL() ? { marginRight: val } : { marginLeft: val };
}

export function marginRight(val: number | string): CSSObject {
	return isRTL() ? { marginLeft: val } : { marginRight: val };
}

export function paddingLeft(val: number | string): CSSObject {
	return isRTL() ? { paddingRight: val } : { paddingLeft: val };
}

export function paddingRight(val: number | string): CSSObject {
	return isRTL() ? { paddingLeft: val } : { paddingRight: val };
}

export function left(val: number | string): CSSObject {
	return isRTL() ? { right: val } : { left: val };
}

export function right(val: number | string): CSSObject {
	return isRTL() ? { left: val } : { right: val };
}

export const getResponsiveModalStyles = (screenClass: ScreenClass): CSSProperties => {
	const centerPosition = {
		position: 'absolute',
		left: '50%',
		top: '50%',
		transform: 'translate(-50%, -50%)',
		zIndex: 5,
	};
	if (screenClass.includes('xl') || screenClass.includes('lg')) {
		return { width: '770px', ...centerPosition } as CSSProperties;
	}
	if (screenClass.includes('md')) {
		return { width: '690px', ...centerPosition } as CSSProperties;
	}
	if (screenClass.includes('sm')) {
		return { width: '500px', ...centerPosition } as CSSProperties;
	}
	return { width: '100%', height: '100%', zIndex: 5, position: 'relative', display: 'flex' };
};

export function textLeft(): CSSObject {
	return isRTL() ? { textAlign: 'right' } : { textAlign: 'left' };
}

export function textRight(): CSSObject {
	return isRTL() ? { textAlign: 'left' } : { textAlign: 'right' };
}

export function floatRight(): CSSObject {
	return isRTL() ? { float: 'left' } : { float: 'right' };
}

export function floatLeft(): CSSObject {
	return isRTL() ? { float: 'right' } : { float: 'left' };
}

export function flexDirection(direction: 'row' | 'column'): CSSObject {
	if (direction === 'row') {
		return isRTL() ? { flexDirection: 'row-reverse' } : { flexDirection: 'row' };
	}
	return { flexDirection: direction };
}

export function justifyContent(justifyContent: CSSProperties['justifyContent']): CSSObject {
	if (justifyContent === 'flex-end') {
		return isRTL() ? { justifyContent: 'flex-start' } : { justifyContent: 'flex-end' };
	} else if (justifyContent === 'flex-start') {
		return isRTL() ? { justifyContent: 'flex-end' } : { justifyContent: 'flex-start' };
	}
	return { justifyContent };
}

export function alignItems(alignItems: CSSProperties['alignItems']): CSSObject {
	if (alignItems === 'flex-end') {
		return isRTL() ? { alignItems: 'flex-start' } : { alignItems: 'flex-end' };
	} else if (alignItems === 'flex-start') {
		return isRTL() ? { alignItems: 'flex-end' } : { alignItems: 'flex-start' };
	}
	return { alignItems };
}
export function borderRadius(val1: string, val2: string, val3: string, val4: string): CSSObject {
	return isRTL()
		? { borderRadius: `${val3} ${val4} ${val1} ${val2}` }
		: { borderRadius: `${val1} ${val2} ${val3} ${val4}` };
}

export function isLargeScreen(): boolean {
	return matchScreen(['xl', 'xxl']);
}

export function isSmallScreen(): boolean {
	return matchScreen(['xs']);
}
