import Icon from '@Components/Icon/Icon';
import React, { FunctionComponent, useEffect } from 'react';
import { Col, Hidden, Row, Visible } from 'react-grid-system';
import {
	OutletAppointmentWrapper,
	PickupWrapper,
	HeaderWrapper,
	IconWrapper,
	TitleWrapper,
	DescriptionWrapper,
	ButtonWrapper,
	SmallButtonWrapper,
	SelectedAppointmentWrapper,
	SelectedAppointmentLabel,
	BookingLink,
} from './BookAppointment.styles';
import useTranslate from '@Utils/hooks/useTranslate';
import { SecondaryLargeButtonFixed, SecondaryLargeButtonFlex } from '@Components/controls/Button/Button';
import { dispatch } from '@Redux/store';
import { modalTypes, setModal } from '@Redux/modules/modal/actions';
import ConditionalRender from '@Components/ConditionalRender/ConditionalRender';
import { BodySBold } from '@Components/Typography/Typography';
import moment from 'moment';

const ICON_SIZE = 40;

const BookAppointment: FunctionComponent<types.cards.IOutletPickupCardProps> = ({
	params: {
		selectedOutletId,
		bookingPickupAppointment,
		setAlertVisibility,
		disabledDates,
		cancelCurrentAppointment,
		bookNewAppointment,
	},
}) => {
	const { translate } = useTranslate();

	useEffect(() => {
		if (!!selectedOutletId) {
			setAlertVisibility(false);
		}
	}, [selectedOutletId]);

	return (
		<OutletAppointmentWrapper>
			<Row>
				<Hidden xs>
					<Col lg={1} md={1} sm={1}>
						<Row align="center" justify="center">
							<Icon name="deliveryDate" fill="black38" width={ICON_SIZE} height={ICON_SIZE} />
						</Row>
					</Col>
				</Hidden>
				<Col lg={11} md={11} sm={11}>
					<PickupWrapper>
						<Row>
							<HeaderWrapper>
								<Visible xs>
									<IconWrapper>
										<Icon name="deliveryDate" fill="black38" width={ICON_SIZE} height={ICON_SIZE} />
									</IconWrapper>
								</Visible>
								<div>
									<TitleWrapper>{translate('book-appointment.outlet.label-1')}</TitleWrapper>
									<DescriptionWrapper>
										{translate('book-appointment.outlet.label-2')}
									</DescriptionWrapper>
									<ConditionalRender
										show={!!bookingPickupAppointment}
										onTrue={() => (
											<>
												<SelectedAppointmentWrapper>
													<SelectedAppointmentLabel>
														{translate('pickup-from-outlet.selected-appointment')}
													</SelectedAppointmentLabel>
													<TitleWrapper>
														{moment(bookingPickupAppointment).format('D MMM YYYY, h:mm A')}
													</TitleWrapper>
												</SelectedAppointmentWrapper>
												<BodySBold>
													<BookingLink
														onClick={() =>
															dispatch(
																setModal({
																	type: modalTypes.PICKUP_BOOKING,
																	data: {
																		selectedOutletId,
																		disabledDates,
																		cancelCurrentAppointment,
																		bookNewAppointment,
																		bookingPickupAppointment,
																	},
																})
															)
														}
													>
														{translate('pickup-from-outlet.edit-appointment-date')}
													</BookingLink>
													<BookingLink
														onClick={() => {
															cancelCurrentAppointment()
																.then(() => {
																	return;
																})
																.catch(() => {
																	return;
																});
														}}
													>
														{translate('pickup-from-outlet.delete-appointment-date')}
													</BookingLink>
												</BodySBold>
											</>
										)}
									/>
								</div>
								<Hidden xs>
									<ConditionalRender
										show={!bookingPickupAppointment}
										onTrue={() => (
											<ButtonWrapper>
												<SecondaryLargeButtonFixed
													uniqueId="checkout-next-step.next"
													onClick={() => {
														if (!!selectedOutletId) {
															dispatch(
																setModal({
																	type: modalTypes.PICKUP_BOOKING,
																	data: {
																		selectedOutletId,
																		disabledDates,
																		cancelCurrentAppointment,
																		bookNewAppointment,
																		bookingPickupAppointment,
																	},
																})
															);
														} else setAlertVisibility(true);
													}}
													disabled={false}
												>
													{translate('book-appointment.outlet.book-button')}
												</SecondaryLargeButtonFixed>
											</ButtonWrapper>
										)}
									/>
								</Hidden>
							</HeaderWrapper>
						</Row>
					</PickupWrapper>
				</Col>
			</Row>
			<ConditionalRender
				show={!bookingPickupAppointment}
				onTrue={() => (
					<Row>
						<Visible xs>
							<SmallButtonWrapper>
								<SecondaryLargeButtonFlex
									uniqueId="checkout-booking-button"
									onClick={() => {
										if (!!selectedOutletId) {
											dispatch(
												setModal({
													type: modalTypes.PICKUP_BOOKING,
													data: {
														selectedOutletId,
														disabledDates,
														cancelCurrentAppointment,
														bookNewAppointment,
														bookingPickupAppointment,
													},
												})
											);
										} else setAlertVisibility(true);
									}}
									disabled={false}
								>
									{translate('book-appointment.outlet.book-button')}
								</SecondaryLargeButtonFlex>
							</SmallButtonWrapper>
						</Visible>
					</Row>
				)}
			/>
		</OutletAppointmentWrapper>
	);
};

export default BookAppointment;
