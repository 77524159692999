import styled from 'styled-components';
import { MarketingBannerType } from './MarketingBanner';

interface IMarketingBannerContainerProps {
	marginTop?: number;
	marginBottom?: number;
	type?: MarketingBannerType;
	img?: string;
}

export const MarketingBannerContainer = styled.div<IMarketingBannerContainerProps>`
	overflow: hidden;
	border-radius: 8px;
	${({ marginTop, marginBottom }) => ({
		marginTop,
		marginBottom,
	})}
	cursor: pointer;
	position: relative;
`;

export const MarketingBannerWrapper = styled.div<IMarketingBannerContainerProps>`
	${({ type }) => ({
		minHeight:
			type === MarketingBannerType.VERTICAL
				? '408px; display: flex; flex-direction: column; justify-content: space-between'
				: 'auto',
		maxHeight: type === MarketingBannerType.VERTICAL ? '408px' : 'auto',
	})}
`;

export const MarketingBannerImage = styled.div<IMarketingBannerContainerProps>`
	img {
		width: 100%;
	}
`;

export const MarketingBannerImageVertical = styled.div<IMarketingBannerContainerProps>`
	width: 100%;
	height: 408px;
	${({ img }) => ({
		backgroundImage: `url(${img})`,
	})}
	background-position: center;
	background-size: cover;
`;

export const TimerContainer = styled.div<{ isXS: boolean; isMD: boolean }>`
	position: absolute;
	${({ isXS, isMD }) => {
		if (isXS) {
			return {
				top: '16px',
				left: '12px',
			};
		}
		if (isMD) {
			return {
				bottom: '12px',
				left: '16px',
			};
		}
		return {
			bottom: '32px',
			left: '24px',
		};
	}}
`;
