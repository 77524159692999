import React, { FunctionComponent } from 'react';
import {
	ImageWithTitleAndButtonWrapper,
	ImageWithTitleAndButtonTitle,
	ImageWithTitleAndButtonDescription,
	TextWrapper,
	ImageWithTitleAndButtonLink,
} from './ImageWithTitleAndButton.styled';
import useTranslate from '@Utils/hooks/useTranslate';
import useScreen from '@Utils/hooks/useScreen';
// import Image from '@Components/Image/Image';
import ConditionalRender from '@Components/ConditionalRender/ConditionalRender';

const ImageWithTitleAndButtonCard: FunctionComponent<types.cards.IImageWithTitleAndButtonProps> = ({
	params: { imagePath, title, description, linkUrl, linkText, imageWidth, imageHeight, direction = 'row' },
}) => {
	const { translate } = useTranslate();
	const { screen } = useScreen();
	const isXS = screen(['xs']);

	return (
		<ImageWithTitleAndButtonWrapper direction={direction}>
			<img
				title="mini-banner"
				src={imagePath}
				width={!!imageWidth ? imageWidth : '100%'}
				height={!!imageHeight ? imageHeight : '100%'}
			/>
			<TextWrapper direction={direction}>
				<ImageWithTitleAndButtonTitle>{title}</ImageWithTitleAndButtonTitle>
				<ImageWithTitleAndButtonDescription direction={direction}>
					{description}
				</ImageWithTitleAndButtonDescription>
				<ConditionalRender
					show={!!linkText && !!linkUrl}
					onTrue={() => <ImageWithTitleAndButtonLink>{linkText}</ImageWithTitleAndButtonLink>}
				/>
			</TextWrapper>
		</ImageWithTitleAndButtonWrapper>
	);
};
export default ImageWithTitleAndButtonCard;
