import React, { FunctionComponent } from 'react';
import { SkeletonRow, Rectangular } from '@Components/Skeleton/Skeleton.styled';

const ShortDescription: FunctionComponent = () => {
	return (
		<SkeletonRow marginTop={8} marginBottom={8}>
			<Rectangular width={96} height={16} marginBottom={16} />
			<Rectangular width={64} height={16} marginBottom={16} />
			<Rectangular width={72} height={16} marginBottom={16} />
		</SkeletonRow>
	);
};

export default ShortDescription;
