import React, { FunctionComponent } from 'react';
import {
	MakasibWarningWrapper,
	CloseIconWrapper,
	TitleWrapper,
	CancelWrapper,
	IconWrapper,
	TitleLabelWrapper,
	ButtonsWrapper,
	SmallerLabelWrapper,
	MakasibWarningButtonContainer,
	DismissButtonContainer,
	CustomModalContainer,
} from './MakasibWarning.styled';
import Icon from '@Components/Icon/Icon';
import Divider from '@Components/Divider/Divider';
import { setModal } from '@Redux/modules/modal/actions';
import { useDispatch } from 'react-redux';
import useTranslate from '@Utils/hooks/useTranslate';
import { PrimaryMediumButtonFlex, TertiaryMediumButtonFlex } from '@Components/controls/Button/Button';
import { useHistory } from 'react-router-dom';
import { getLang } from '@Utils/language/language';
import useScreen from '@Utils/hooks/useScreen';
import ConditionalRender from '@Components/ConditionalRender/ConditionalRender';
import { BodyM, BodyMBold } from '@Components/Typography/Typography.styled';
import { roundMoney } from '@Utils/converters/roundMoney';
export interface IMakasibWarning {
	params: {
		excessDiscount: number;
		handleContinue: () => void;
	};
}

const IconSize = 96;

const MakasibWarning: FunctionComponent<IMakasibWarning['params']> = ({ excessDiscount, handleContinue }) => {
	const dispatch = useDispatch();
	const { translate } = useTranslate();
	const history = useHistory();
	const { screen } = useScreen();

	const gotoHomePage = () => {
		handleClose();
		history.push(`/${getLang()}/store`);
	};

	const handleClose = () => {
		dispatch(
			setModal({
				type: null,
				data: null,
			})
		);
	};

	return (
		<CustomModalContainer isXs={screen('xs')} isSm={screen('sm')}>
			<MakasibWarningWrapper>
				<TitleWrapper>
					<CloseIconWrapper onClick={handleClose}>
						<Icon name="close" fill="black" width={24} height={24} />
					</CloseIconWrapper>
				</TitleWrapper>
				<Divider />

				<CancelWrapper>
					<IconWrapper>
						<Icon name="warning" fill="primary" width={IconSize} height={IconSize} />
					</IconWrapper>
					<TitleLabelWrapper>{translate('cart.voucher.modal.checkout-question')}</TitleLabelWrapper>
					<ConditionalRender
						show={excessDiscount < 0}
						onTrue={() => (
							<SmallerLabelWrapper isXs={screen('xs')}>
								<span>
									<BodyM>{translate('cart.voucher.modal.excess1')}</BodyM>
									<BodyMBold>
										{translate('cart.voucher.modal.excess2', roundMoney(excessDiscount * -1))}
										{'\xa0'}
									</BodyMBold>
									<BodyM>{translate('cart.voucher.modal.excess3')}</BodyM>
								</span>
							</SmallerLabelWrapper>
						)}
						onFalse={() => (
							<SmallerLabelWrapper isXs={screen('xs')}>
								{translate('cart.voucher.modal.no-changes1')}
								{translate('cart.voucher.modal.no-changes2')}
							</SmallerLabelWrapper>
						)}
					/>

					<ButtonsWrapper isXs={screen('xs')}>
						<DismissButtonContainer>
							<TertiaryMediumButtonFlex
								uniqueId="do.not.cancel.order.button"
								onClick={() => (excessDiscount < 0 ? gotoHomePage() : handleClose())}
							>
								{excessDiscount < 0
									? translate('added-to-card.continue')
									: translate('cart.voucher.modal.return')}
							</TertiaryMediumButtonFlex>
						</DismissButtonContainer>
						<MakasibWarningButtonContainer>
							<PrimaryMediumButtonFlex uniqueId="continue.to.checkout" onClick={() => handleContinue()}>
								{translate('continue.to.checkout')}
							</PrimaryMediumButtonFlex>
						</MakasibWarningButtonContainer>
					</ButtonsWrapper>
				</CancelWrapper>
			</MakasibWarningWrapper>
		</CustomModalContainer>
	);
};

export default MakasibWarning;
