export const NO_HEADER_FOOTER_COOKIE: string = 'no-header-footer';

export const setCookie = (cname: string, cvalue: string): void => {
	document.cookie = cname + '=' + cvalue + ';path=/';
};

export const getCookie = (name: string): string | undefined => {
	const value = `; ${document.cookie}`;
	const parts = value.split(`; ${name}=`) || [];
	if (parts?.length === 2) {
		return (parts?.pop() || '').split(';').shift();
	}
	return undefined;
};

export const hasCookie = (name: string): boolean => {
	return !!getCookie(name);
};

export const eraseCookie = (name: string): void => {
	document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
};
