import styled from 'styled-components';
import { matchScreen, paddingRight, marginLeft } from '@Utils/layout/layoutSupport';
import { BodyS } from '@Components/Typography/Typography';

export const ContactDetailsWrapper = styled.div`
	${() => ({
		padding: matchScreen('xs') ? 0 : '8px 32px',
	})}
`;

export const TCContent = styled(BodyS)`
	${({ theme }) => ({
		color: theme.colors.black54,
	})}
`;

export const IconWrapper = styled.div`
	${() => paddingRight(16)}
	align-self: self-start;
`;

export const AcceptTermsAndConditionsWrapper = styled.div`
	display: flex;
	justify-content: flex-start;
	align-items: center;
`;
export const AcceptEnhancedTermsAndConditionsWrapper = styled(AcceptTermsAndConditionsWrapper)`
	margin-left: -20px;
`;
export const AcceptText = styled.div`
	display: flex;
	justify-content: flex-start;
	padding-top: 0px;
	${() => marginLeft(8)}
`;

export const BodySWrapper = styled(BodyS)`
	${() => marginLeft(4)}
	cursor: pointer;
	text-decoration: underline;
`;

export const HighlightedText = styled.span`
	${({ theme }) => ({
		color: theme.colors.primary,
		textDecoration: 'underline',
		cursor: 'pointer',
	})}
`;

export const HeaderWrapper = styled.div`
	display: flex;
`;

export const ShippingWrapper = styled.div`
	${() => marginLeft(20)}
`;

export const EnhancedTermsWrapper = styled.div`
	display: flex;
`;
export const EnhancedTermsTableWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	width: 100%;
`;
export const EnhancedTermsTitle = styled(BodyS)`
	font-size: 13px;
	font-weight: 700;
	line-height: 16px;
`;
export const EnhancedTermsText = styled(EnhancedTermsTitle)`
	font-weight: 400;
	${({ theme }) => ({
		color: theme.colors.black54,
	})}
`;
export const EnhancedTermsTextCenter = styled(EnhancedTermsText)`
	text-align: center;
`;
export const EnhancedTermsTitleCenter = styled(EnhancedTermsTitle)`
	text-align: center;
`;
