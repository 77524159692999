import styled from 'styled-components';
import { isRTL } from '@Utils/layout/layoutSupport';

export const FeatureWrapper = styled.div`
	height: 96px;
`;

export const IconWraper = styled.div`
	padding: 16px;
	${() => {
		return isRTL() ? { float: 'right' } : { float: 'left' };
	}}
	height: 64px;
	width: 64px;
	img {
		height: 64px;
		width: 64px;
		${({ theme }) => ({
			fill: theme.colors.black54,
		})}
	}
`;

export const DescriptionWraper = styled.div`
	padding-top: 24px;
`;
