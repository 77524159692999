import { IFilter, IFilterEntry } from '@ApiModels/productsByCategoryId';
import { Action, ActionFunction0, ActionFunction1, createAction } from 'redux-actions';

export interface ISetFiltersAction {
	payload: IFilter[];
}

export const setFilters: ActionFunction1<
	ISetFiltersAction['payload'],
	Action<ISetFiltersAction['payload']>
> = createAction('SET_FILTERS', (action: ISetFiltersAction['payload']) => action);

export interface IToggleFilterAction {
	payload: IFilterEntry;
}

export const toggleFilter: ActionFunction1<
	IToggleFilterAction['payload'],
	Action<IToggleFilterAction['payload']>
> = createAction('TOGGLE_FILTER', (action: IToggleFilterAction['payload']) => action);

export const enableFilter: ActionFunction1<
	IToggleFilterAction['payload'],
	Action<IToggleFilterAction['payload']>
> = createAction('ENABLE_FILTER', (action: IToggleFilterAction['payload']) => action);

export const clearFilters: ActionFunction0<Action<void>> = createAction('CLEAR_FILTERS', () => {});
