import React, { FunctionComponent, useState, useEffect, useRef, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { CategoriesService } from '@Services/categories/categories';
import { EspotService } from '@Services/espot/espot';
import Card from '@Components/Card/Card';
import {
	SectionName,
	SortingMethodsRow,
	SearchingWrapper,
	SearchingTitle,
	ControlsWrapper,
	EmptyStateInfoWrapper,
	EmptyStateTitle,
	EmptyStateDescription,
	SearchInputWrapper,
	CategoryTitleWrapper,
	SubmitWrapper,
	AppleBannerWrapper,
	BannerButtonWrapper,
	BannerLabelWrapper,
} from './Store.styled';
import { IContent, ISingleCategory } from '@ApiModels/flatCategories';
import { useLocation } from 'react-router-dom';
import { Col, Row, Container, Visible } from 'react-grid-system';
import { TileSize, ViewType } from '@Components/Card/cards/ProductCard/ProductCard.styled';
import BusyIndicator from '@Components/BusyIndicator/BusyIndicator';
import { addBusyIndicator, removeBusyIndicator } from '@Redux/modules/busyIndicator/actions';
import { ProductsService } from '@Services/productsService/productsService';
import { IFilter, IFilterEntry, IItem, IProductsByCategoryId } from '@ApiModels/productsByCategoryId';
import useTranslate from '@Utils/hooks/useTranslate';
import { SortingMethodsVariants } from '@Redux/modules/sortingMethod/reducer';
import SortingMethods from '@Components/SortingMethods/SortingMethods';
import { getViewType } from '@Redux/modules/settings/viewType/selectors';
import ViewChangeButton from '@Components/controls/ViewChangeButton/ViewChangeButton';
import ViewFiltersButton from '@Components/controls/ViewFiltersButton/ViewFiltersButton';
import MarketingBanner, { MarketingBannerType } from '@Components/MarketingBanner/MarketingBanner';
import Pagination from '@Components/Pagination/Pagination';
import Filters from '@Components/Filters/Filters';
import { clearFilters, enableFilter, setFilters } from '@Redux/modules/filters/actions';
import { updateBreadcrumb } from '@Redux/modules/breadcrumbs/actions';
import useBreadcrumbs from '@Utils/hooks/useBreadcrumbs';
import { useRouter } from '@Utils/router/useRouter';
import useScreen from '@Utils/hooks/useScreen';
import ConditionalRender from '@Components/ConditionalRender/ConditionalRender';
import Keyword from '@Components/Keyword/Keyword';
import TextField from '@Components/controls/TextField/TextField';
import { PrimaryMediumButtonFixed, PrimaryMediumButtonFlex } from '@Components/controls/Button/Button';
import TitleSeparator from '@Components/TitleSeparator/TitleSeparator';
import PopularProducts from '@Components/PopularProducts/PopularProducts';
import Divider from '@Components/Divider/Divider';
import { DEF_ITEM_TYPE, getFilters } from '@ApiModels/utils/attributeGetters';
import { clearPriceFilter } from '@Redux/modules/priceFilter/actions';
import Icon from '@Components/Icon/Icon';
import { setSelectedItems } from '@Redux/modules/selectedItems/actions';
import SidePanel from '@Components/SidePanel/SidePanel';
import { PlanType } from '@Components/Card/cards/Plan/Plan';
import { STORE_PAGE_SIZE, DEFAULT_PAGE_SIZE } from '@Config/app.config';
import { IMarketingBanners, ISingleMarketingBanner } from '@ApiModels/marketingBanners';
import { setCheckoutTimer } from '@Redux/modules/settings/settingsCheckout/actions';
import { getLang } from '@Utils/language/language';
import useAppleBrand from '@Utils/hooks/useAppleBrand';
import { getInstallmentValue } from '@Utils/converters/iItemInventoryAvailability';
import TagManager from 'react-gtm-module';
import { ButtonMBold, CaptionBold, TitleBold } from '@Components/Typography/Typography.styled';
import { ITEMS_LIST } from '@ApiModels/relatedProductsById';
import { getUserId } from '@Redux/modules/api/authentication/selectors';
import { DISABLE_CAR_INSURANCE, ENABLE_ESHOP_LITE } from '@Config/features.config';

export const GET_CATEGORIES_BI = 'GET_CATEGORIES_BI';
export const GET_NEXT_PRODUCTS_BI = 'GET_NEXT_PRODUCTS_BI';
export const GET_PRODUCTS_INSTALLMENT_BI = 'GET_PRODUCTS_INSTALLMENT_BI';
export const TRADE_IN = 'Trade In';
export const BIMA = 'BIMA';
const GET_PRODUCTS_BI = 'GET_PRODUCTS_BI';
const APPLE_WATCH = 'APPLE WATCH';
const AR_COLOR = 'اللون';
const AR_CAPACITY = 'السعة';
const COLOR = 'Color';
const CAPACITY = 'Capacity';

const Store: FunctionComponent = () => {
	const { screen, screenClass } = useScreen();
	const { translate } = useTranslate();
	const [products, setProducts] = useState<IProductsByCategoryId['items']>([]);
	const [marketingBanners, setMarketingBanners] = useState<IMarketingBanners>({ banners: [] });
	const [searchValue, setSearchValue] = useState<string>('');
	const [filterValue, setFilterValue] = useState<string>('');
	const [totalProducts, setTotalProducts] = useState<number>(0);
	const [filteredProducts, setFilteredProducts] = useState<IProductsByCategoryId['items']>([]);
	const [categories, setCategories] = useState<IContent | null>(null);
	const [selectedCategory, setSelectedCategory] = useState<string[]>([]);
	const [getNextPageProducts, setGetNextPageProducts] = useState<boolean>(false);
	const [searchInput, setSearchInput] = useState<string | number>('');
	const [filterOpen, setFilterOpen] = useState<boolean>(false);
	const language = useSelector((state: types.redux.IState) => state.settings.language);
	const sortingMethodCategories = useSelector((state: types.redux.IState) => state.sortingMethod.categories);
	const filters = useSelector((state: types.redux.IState) => state.filters);
	const displayedCategories = categories?.children ?? [];
	const location = useLocation();
	const history = useHistory();
	const router = useRouter();
	const { getBreadcrumbsFromCategory } = useBreadcrumbs();
	const viewType = getViewType();
	const priceFilter = useSelector((state: types.redux.IState) => state.priceFilter);
	const dispatch = useDispatch();
	const paginationRef = useRef<HTMLDivElement>(null);
	const { isAppleUrl } = useAppleBrand();
	const [isSmallerLgWidth, setIsSmallerLgWidth] = useState<boolean>();
	const [tradeInProduct, setTradeInProduct] = useState<IItem>();

	const pathname = window.location.href.toLocaleLowerCase();

	useEffect(() => {
		dispatch(
			setSelectedItems({
				details: undefined,
				planType: PlanType.DEVICE_ONLY,
				wrongDeviceChoosen: false,
				currentPlan: undefined,
				plan: undefined,
				isHBB: false,
				isWFBB5G: false,
				isWFBB4G: false,
				showWarning: false,
				userHaveLegacyPlan: false,
				cantBeUpgradedPlanId: undefined,
			})
		);
		dispatch(setCheckoutTimer({ checkoutTimer: undefined }));
	}, []);

	const selectedFiltersCounter = useMemo(() => {
		return filters.reduce((accumulator, currentValue) => {
			return accumulator + currentValue.entry.filter((itemFilter) => itemFilter.enabled).length;
		}, 0);
	}, [filters]);

	const carInsuranceCategory = useMemo(() => {
		return !!(categories?.children?.find((cat) => cat.id === selectedCategory[1])?.identifier === 'CarInsurance');
	}, [categories, selectedCategory]);

	useEffect(() => {
		if (router?.query?.queryString?.search) {
			const query = router?.query?.queryString?.search;
			setSearchValue(query);
			setSearchInput(query);
		} else {
			setSearchValue('');
		}
		if (router?.query?.queryString?.filter) {
			const query = router?.query?.queryString?.filter;
			setFilterValue(query);
		} else {
			setFilterValue('');
		}
	}, [router]);

	useEffect(() => {
		dispatch(updateBreadcrumb(getBreadcrumbsFromCategory(selectedCategory)));
	}, [selectedCategory]);

	useEffect(() => {
		dispatch(clearFilters());
		dispatch(clearPriceFilter());
		const urlParts = location.pathname.split('/').filter((part) => !!part);
		let counter = 1;
		if (urlParts.length > counter) {
			if (urlParts[0].toLowerCase() === 'ar' || urlParts[0].toLowerCase() === 'en') {
				counter = 2;
			}
		}
		if (urlParts.length === counter && !!categories) {
			setSelectedCategory([categories.id]);
		}
		if (urlParts.length > counter && !!categories) {
			const categoryFromPath = categories.children.find(
				(cat: ISingleCategory) => cat.identifier === urlParts[urlParts.length - 1]
			);
			if (!!categoryFromPath) {
				const categoriesPath =
					categories.id === categoryFromPath.parentId
						? [categories.id, categoryFromPath.id]
						: [categories.id, categoryFromPath.parentId, categoryFromPath.id];
				setSelectedCategory(categoriesPath);
			}
		}
	}, [location.pathname, categories]);

	useEffect(() => {
		setMarketingBanners({} as IMarketingBanners);
		const subscriber = EspotService.getMarketingBanners('AppleBrandedStoreBanners').subscribe(
			(r) => {
				if (r.banners.length > 0) {
					setMarketingBanners(r);
				}
			},
			() => {
				subscriber.unsubscribe();
			}
		);
	}, [language]);

	useEffect(() => {
		dispatch(addBusyIndicator(GET_CATEGORIES_BI));
		CategoriesService.getFlatCategories().subscribe(
			(r) => {
				setCategories(r.contents);
				dispatch(removeBusyIndicator(GET_CATEGORIES_BI));
			},
			() => {
				dispatch(removeBusyIndicator(GET_CATEGORIES_BI));
			}
		);
	}, [language]);

	const enableQueryFilter = (uniqueId: string) => {
		const entry: IFilterEntry = {
			enabled: true,
			parsedValue: '',
			label: '',
			value: '',
			count: '',
			extendedData: {
				uniqueId,
			},
		};
		dispatch(enableFilter(entry));
	};

	const changeFilterLabelByAppleWatchRelatedCategories = (filter: IFilter[]) => {
		if (getCategoryName().toLowerCase().includes(APPLE_WATCH.toLowerCase())) {
			return filter.map((obj) => {
				if (obj.name.includes(AR_COLOR) || obj.name.includes(COLOR)) {
					return { ...obj, name: translate('store.apple.watch.filters.bandColor') };
				} else if (obj.name.includes(AR_CAPACITY) || obj.name.includes(CAPACITY)) {
					return { ...obj, name: translate('store.apple.watch.filters.caseColor') };
				} else return obj;
			});
		} else return filter;
	};

	useEffect(() => {
		if (selectedCategory[selectedCategory.length - 1] && (selectedCategory.length || searchValue || filterValue)) {
			dispatch(addBusyIndicator(GET_PRODUCTS_BI));
			dispatch(setFilters([]));
			setProducts([]);
			const replacedSearchValue = searchValue.replaceAll(' ', '-');
			ProductsService.getProductsByParentCategory({
				parentCategory: selectedCategory[selectedCategory.length - 1],
				offset: 0,
				searchValue: replacedSearchValue,
				limit: selectedCategory.length <= 1 ? STORE_PAGE_SIZE : DEFAULT_PAGE_SIZE,
			}).subscribe(
				({ items, total, facets }) => {
					dispatch(addBusyIndicator(GET_PRODUCTS_INSTALLMENT_BI));
					setProducts(items.filter((it) => it.uniqueID));
					if (isAppleUrl() && !ENABLE_ESHOP_LITE) {
						items.map((value, index) => {
							const fetchInstallment = async () => {
								value.productTextInstallmentValue = await getInstallmentValue(value.uniqueID);
								if (index === items.length - 1) {
									setProducts(items.filter((it) => it.uniqueID));
									dispatch(removeBusyIndicator(GET_PRODUCTS_INSTALLMENT_BI));
								}
							};
							fetchInstallment().catch(() => {
								dispatch(removeBusyIndicator(GET_PRODUCTS_INSTALLMENT_BI));
							});
						});
					}
					setTotalProducts(total);
					const smartphonesCategory = !!(
						categories?.children.find((cat) => cat.id === selectedCategory[1])?.identifier === 'Smartphones'
					);
					if (!carInsuranceCategory)
						dispatch(
							setFilters(
								changeFilterLabelByAppleWatchRelatedCategories(
									getFilters(facets, items, smartphonesCategory)
								)
							)
						);
					if (filterValue) {
						enableQueryFilter(filterValue);
					}
					dispatch(removeBusyIndicator(GET_PRODUCTS_BI));
				},
				() => {
					dispatch(removeBusyIndicator(GET_PRODUCTS_BI));
				},
				() => {
					setGetNextPageProducts(false);
					dispatch(removeBusyIndicator(GET_PRODUCTS_BI));
				}
			);
		}
	}, [selectedCategory, searchValue]);

	const getProductsByParentCategory = (offset: number) => {
		dispatch(addBusyIndicator(GET_NEXT_PRODUCTS_BI));
		const replacedSearchValue = searchValue.replaceAll(' ', '-');
		ProductsService.getProductsByParentCategory({
			parentCategory: selectedCategory[selectedCategory.length - 1],
			offset,
			searchValue: replacedSearchValue,
			limit: selectedCategory.length <= 1 ? STORE_PAGE_SIZE : DEFAULT_PAGE_SIZE,
		}).subscribe(
			({ items }) => {
				setProducts((state) => {
					return [...state, ...items];
				});
				dispatch(removeBusyIndicator(GET_NEXT_PRODUCTS_BI));
				setGetNextPageProducts(false);
			},
			() => {
				dispatch(removeBusyIndicator(GET_NEXT_PRODUCTS_BI));
				setGetNextPageProducts(false);
			}
		);
	};

	useEffect(() => {
		if (getNextPageProducts && totalProducts > products.length) {
			getProductsByParentCategory(products.length);
		}
	}, [getNextPageProducts]);

	const getItems = useCallback(() => {
		const top = paginationRef?.current?.getBoundingClientRect().top;
		if (top && window.innerHeight - top > 0.3 * window.innerHeight) {
			setGetNextPageProducts(true);
		}
	}, [selectedCategory, products]);

	useEffect(() => {
		function watchScroll() {
			window.addEventListener('scroll', getItems);
		}
		watchScroll();
		return () => {
			window.removeEventListener('scroll', getItems);
		};
	}, []);

	const sortProducts = (a: IItem, b: IItem) => {
		switch (sortingMethodCategories) {
			case SortingMethodsVariants.NEWEST_ARRIVALS: {
				if (Number(a.uniqueID) < Number(b.uniqueID)) return 1;
				if (Number(a.uniqueID) > Number(b.uniqueID)) return -1;
				return 0;
			}
			case SortingMethodsVariants.RECOMMENDED: {
				if ((a.place ?? 0) < (b.place ?? 0)) {
					return -1;
				}
				return 1;
			}
			case SortingMethodsVariants.PRICE_ASSCENDING: {
				if (a.price > b.price) return 1;
				if (a.price < b.price) return -1;
				return 0;
			}
			case SortingMethodsVariants.PRICE_DESCENDING: {
				if (a.price < b.price) return 1;
				if (a.price > b.price) return -1;
				return 0;
			}
			default:
				return 0;
		}
	};

	const isDigitalOrGC = (product: IItem) => {
		if (Array.isArray(product.itemType)) {
			return product.itemType.some((item) => ITEMS_LIST.includes(item));
		} else if (typeof product.itemType === 'string') {
			return ITEMS_LIST.includes(product.itemType);
		} else return false;
	};

	const additionalSort = (a: IItem, b: IItem) => {
		if (a.preorder && !b.preorder) {
			return -1;
		} else if (b.preorder && !a.preorder) {
			return 1;
		}
		switch (sortingMethodCategories) {
			case SortingMethodsVariants.RECOMMENDED: {
				if (
					(isDigitalOrGC(a)
						? a.availability || isProductAvailable(a)
						: a.availability && isProductAvailable(a)) &&
					!(isDigitalOrGC(b)
						? b.availability || isProductAvailable(b)
						: b.availability && isProductAvailable(b))
				) {
					return -1;
				} else if (
					!(isDigitalOrGC(a)
						? a.availability || isProductAvailable(a)
						: a.availability && isProductAvailable(a)) &&
					(isDigitalOrGC(b)
						? b.availability || isProductAvailable(b)
						: b.availability && isProductAvailable(b))
				) {
					return 1;
				}
				return 0;
			}
			default: {
				return 0;
			}
		}
	};

	const insertBannerInsideProducts = (index: number) => {
		const unique = Math.random().toFixed(4).toString();
		if (index === 4 && viewType === ViewType.GRID && ['xl'].includes(screenClass)) {
			return (
				<ConditionalRender
					show={!!marketingBanners?.banners?.find((item) => item?.img?.includes('/XL-CardBanner'))}
					onTrue={() => (
						<Col lg={4} sm={12} key={unique}>
							<MarketingBanner
								banner={
									marketingBanners?.banners?.find((item) => item?.img?.includes('/XL-CardBanner')) ||
									{}
								}
								type={MarketingBannerType.VERTICAL}
								marginTop={8}
								marginBottom={0}
								withoutButton
							/>
						</Col>
					)}
				/>
			);
		}
	};

	const getFilteredProducts = () => {
		const filtersWithEnabledEntries = filters.filter((filter) => filter.entry.some(({ enabled }) => enabled));
		if (filtersWithEnabledEntries.length || priceFilter.max || priceFilter.min) {
			const _filteredProducts: IItem[] = [];
			const _filters: { attribute: string; values: string[] }[] = [];
			for (const filter of filtersWithEnabledEntries) {
				const filterEnabledValues = filter.entry.filter(({ enabled }) => enabled);
				for (const enabledValue of filterEnabledValues) {
					const propertyValue = filter.extendedData.propertyvalue.replace('manufacturer.raw', 'MANUFACTURER');
					const item = _filters.findIndex((item) => item.attribute === propertyValue);
					if (item >= 0) {
						_filters[item].values.push(enabledValue.parsedValue);
					} else {
						_filters.push({ attribute: propertyValue, values: [enabledValue.parsedValue] });
					}
				}
			}
			products
				.filter((product) => {
					const { max, min } = priceFilter;
					if (max) {
						if (min) {
							return product.price >= min && product.price <= max;
						}
						return product.price <= max;
					}
					if (min) {
						return product.price >= min;
					}
					return true;
				})
				.forEach((product) => {
					const testValues = _filters.map((filter) => {
						const _value = product.attributes?.find((attr) => attr.identifier === filter.attribute)
							?.values[0].value;
						if (Array.isArray(_value)) {
							return _value.some((value) => filter.values.includes(value));
						} else if (_value) {
							return filter.values.includes(_value);
						}
						return false;
					});
					if (!testValues.includes(false)) {
						_filteredProducts.push(product);
					}
				});
			setFilteredProducts(_filteredProducts);
		} else {
			setFilteredProducts([...products]);
		}
	};

	useEffect(() => {
		getFilteredProducts();
	}, [filters, priceFilter, products, sortingMethodCategories]);

	useEffect(() => {
		if (filteredProducts.length > 0) {
			const payload = [{ productId: filteredProducts.map((product) => product.uniqueID ?? '1') }];
			TagManager.dataLayer({
				dataLayer: { event: 'listViewProducts', listViewProducts: payload, userId: getUserId() },
			});
		}
	}, [filteredProducts]);

	const handleKeywordClick = () => {
		history.push(location.pathname);
		setSearchValue('');
	};

	const isProductAvailable = (product: IItem) => {
		const inventoryId = product.attributes?.find((attr) => attr.identifier === DEF_ITEM_TYPE)?.id;
		if (!product.preorder) {
			if (inventoryId && product.inventory) {
				const productInventory = product.inventory[inventoryId];
				if (productInventory?.some((inv) => !!Number(inv.value) && Number(inv.value) > 0)) {
					return true;
				} else {
					return false;
				}
			} else {
				return false;
			}
		} else {
			return true;
		}
	};

	const getCategoryName = () => {
		if (categories && selectedCategory.length > 1) {
			return (
				categories.children?.find((cat) => cat.id === selectedCategory[selectedCategory.length - 1])?.name ?? ''
			);
		}
		return translate('menu.item.Store');
	};

	const getBanner = (): ISingleMarketingBanner => {
		if (marketingBanners.banners && marketingBanners.banners.length > 1) {
			if (screenClass === 'xs' && viewType !== ViewType.LIST) {
				return marketingBanners?.banners?.find((item) => item?.img?.includes('/XS-banner')) || {};
			} else if (screenClass === 'sm' && viewType !== ViewType.LIST) {
				return marketingBanners?.banners?.find((item) => item?.img?.includes('/S-banner')) || {};
			} else if (screenClass === 'md' && viewType !== ViewType.LIST) {
				return marketingBanners?.banners?.find((item) => item?.img?.includes('/XL-L-M-banner')) || {};
			} else if (['lg', 'xl'].includes(screenClass)) {
				return marketingBanners?.banners?.find((item) => item?.img?.includes('/XL-L-M-banner')) || {};
			}
		}
		return {};
	};

	const getWidth = () => window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

	const setupGridView = () => {
		if (getWidth() < 1199 && screen('lg')) {
			setIsSmallerLgWidth(true);
		} else {
			setIsSmallerLgWidth(false);
		}
	};

	useEffect(() => {
		let timeoutId: any = null;
		const resizeListener = () => {
			clearTimeout(timeoutId);
			timeoutId = setTimeout(() => setupGridView(), 200);
		};
		window.addEventListener('resize', resizeListener);
		resizeListener();
		return () => {
			window.removeEventListener('resize', resizeListener);
		};
	}, [screen(['xs']), screen(['sm']), screen(['md']), screen(['lg'])]);

	const getGridValue = () => {
		if (viewType === ViewType.LIST) {
			return 12;
		} else {
			return 4;
		}
	};

	const CategoriesAndFilters = () => {
		return (
			<>
				<SectionName>{translate('store.categories.header')}</SectionName>
				<BusyIndicator listener={[GET_CATEGORIES_BI, GET_PRODUCTS_BI]} skeleton="category">
					<>
						<Card<types.cards.ICategoryCardProps>
							card="categories"
							params={{
								categories: displayedCategories,
								selectedCategory,
								onClick: setSelectedCategory,
							}}
							marginTop={18}
							marginBottom={10}
							withoutContainerPadding
						/>
						<ConditionalRender
							show={selectedCategory.length > 1 && !carInsuranceCategory}
							onTrue={() => <Filters />}
						/>
					</>
				</BusyIndicator>
			</>
		);
	};

	const getAttributeValue = (product: IItem, attributeName: string) => {
		const productDescription = product.attributes?.find((attribute) => attribute?.identifier === attributeName)
			?.values;
		return !!productDescription
			? Array.isArray(productDescription[0].value)
				? productDescription[0].value[0]
				: productDescription[0].value
			: '';
	};

	const _filteredProducts = useMemo(
		() =>
			filteredProducts
				.sort(sortProducts)
				.sort(additionalSort)
				.filter((item, index, sortedProducts) => {
					const iphone14ProFirstOccurrenceIndex = sortedProducts.findIndex(
						(product) => product.name === 'iPhone 14 Pro'
					);
					const tradeInProductId = sortedProducts.findIndex((product) => product.name === TRADE_IN);
					if (tradeInProductId >= 0 && index === tradeInProductId) {
						setTradeInProduct(sortedProducts[tradeInProductId]);
						return false;
					}
					if (index === iphone14ProFirstOccurrenceIndex) return true;
					else
						return (
							item.name !== 'iPhone 14 Pro' &&
							item.inventory &&
							(Object.keys(item.inventory).length !== 0 || item.name === BIMA)
						);
				})
				.map((product, index, products) => (
					<React.Fragment key={index}>
						{insertBannerInsideProducts(index)}
						<ConditionalRender
							show={product.name === BIMA && !DISABLE_CAR_INSURANCE}
							onTrue={() => getSpecialCard(product)}
							onFalse={() => (
								<Col lg={isSmallerLgWidth ? 6 : getGridValue()} sm={6}>
									<Card<types.cards.IProductCardProps>
										card="product"
										params={{
											inventory: product.inventory,
											tileSize: TileSize.BIG,
											preorderStartDate: product.preorderDate,
											product,
											iconsHidden: !selectedCategory.some(
												(cat) =>
													categories?.children?.find((child) => cat === child.id)
														?.identifier === 'Smartphones'
											),
										}}
										marginTop={8}
										marginBottom={8}
										showShadowOnHover
									/>
								</Col>
							)}
						/>
					</React.Fragment>
				)),
		[filteredProducts, sortingMethodCategories, viewType, selectedCategory, isSmallerLgWidth]
	);

	const getSpecialCard = (product: IItem | undefined) => {
		if (!!product) {
			return (
				<Col lg={isSmallerLgWidth ? 6 : getGridValue()} sm={6}>
					<Card<types.cards.IImageLinkCardProps>
						card="imageLink"
						params={{
							name: product.name,
							title: product.shortDescription,
							description: getAttributeValue(product, 'PRODUCT_DESCRIPTION'),
							backgroundImagePath: getAttributeValue(product, 'BACKGROUND_URL'),
							buttonTitle: getAttributeValue(product, 'CARD_ACTION_BUTTON_TITLE'),
							redirectUrl: getAttributeValue(product, 'REDIRECT_URL'),
							isSingleItem:
								product.name === TRADE_IN && screen(['xs', 'sm', 'md']) ? true : products.length === 1,
						}}
						withoutContainer
						fullHeight
					/>
				</Col>
			);
		} else {
			return <></>;
		}
	};

	if (!!searchValue && products.length === 0 && selectedCategory.length === 1) {
		const handleChange = (e: string | number) => {
			setSearchInput(e);
		};
		const handleSubmit = () => {
			history.push(`/${getLang()}/store?search=${searchInput}`);
		};
		const handleClearInput = () => {
			setSearchInput('');
		};
		return (
			<Container>
				<SearchingWrapper>
					<SearchingTitle>{translate('store.searching.title', searchValue)}</SearchingTitle>
				</SearchingWrapper>
				<BusyIndicator listener={GET_PRODUCTS_BI} skeleton="categoryTitle">
					<>
						<Icon name="searchEmptyState" width={286} height={286} />
						<EmptyStateInfoWrapper>
							<EmptyStateTitle>
								{translate('store.products.empty-state.title', searchValue)}
							</EmptyStateTitle>
							<EmptyStateDescription>
								{translate('store.products.empty-state.description')}
							</EmptyStateDescription>
							<SearchInputWrapper>
								<SubmitWrapper align="center">
									<Col offset={{ md: 1, lg: 2 }} xs={12} sm={8} md={7} lg={6}>
										<TextField
											onChange={(e) => handleChange(e)}
											value={searchInput}
											id="search"
											placeholder={translate('megamenu.search.input.placeholder')}
											rightIcon="clearInput"
											leftIcon="search"
											onIconClick={handleClearInput}
											width="100%"
											onEnter={handleSubmit}
										/>
									</Col>
									<Visible xs>
										<Divider withoutLine marginTop={16} />
									</Visible>
									<Col offset={{ xs: 3, sm: 0 }} xs={6} sm={4} md={3} lg={2}>
										<PrimaryMediumButtonFlex
											uniqueId="store.products.empty-state.button"
											onClick={handleSubmit}
											listener={GET_PRODUCTS_BI}
										>
											{translate('store.products.empty-state.button')}
										</PrimaryMediumButtonFlex>
									</Col>
								</SubmitWrapper>
							</SearchInputWrapper>
						</EmptyStateInfoWrapper>
						<TitleSeparator title={translate('product.popular.products')} />
						<PopularProducts />
					</>
				</BusyIndicator>
			</Container>
		);
	}

	return (
		<>
			<Container>
				<ConditionalRender
					show={
						!!marketingBanners?.banners?.find(
							(item) => item?.img?.includes('/XL-L-M-banner') && item?.topBanner
						) && !isAppleUrl()
					}
					onTrue={() => (
						<MarketingBanner
							banner={getBanner()}
							isTradeIn={getBanner().img?.includes('/trade-in')}
							type={MarketingBannerType.HORIZONTAL_FULL_WIDTH}
							marginTop={16}
							marginBottom={20}
						/>
					)}
				/>
				<ConditionalRender
					show={
						!!marketingBanners?.banners?.find((item) =>
							item.img?.includes(`/Product_page/${screenClass.toUpperCase()}`)
						) && isAppleUrl()
					}
					onTrue={() => (
						<AppleBannerWrapper>
							<img
								src={
									marketingBanners?.banners?.find((item) =>
										item.img?.includes(`/Product_page/${screenClass.toUpperCase()}`)
									)?.img
								}
								height="auto"
							/>
							<ConditionalRender
								show={screen(['sm', 'md'])}
								onTrue={() => (
									<BannerLabelWrapper isXSSMMD>
										<ButtonMBold color="black38">
											{translate('store.banner.explore.our')}
										</ButtonMBold>
										<ButtonMBold color="black87">
											{translate('store.banner.apple.store')}
										</ButtonMBold>
									</BannerLabelWrapper>
								)}
								onFalse={() => (
									<ConditionalRender
										show={screen(['xs'])}
										onTrue={() => (
											<BannerLabelWrapper isXSSMMD isXS>
												<CaptionBold color="black38">
													{translate('store.banner.explore.our')}
												</CaptionBold>
												<CaptionBold color="black87">
													{translate('store.banner.apple.store')}
												</CaptionBold>
											</BannerLabelWrapper>
										)}
										onFalse={() => (
											<BannerLabelWrapper>
												<TitleBold color="black38">
													{translate('store.banner.explore.our')}
												</TitleBold>
												<TitleBold color="black87">
													{translate('store.banner.apple.store')}
												</TitleBold>
											</BannerLabelWrapper>
										)}
									/>
								)}
							/>
							<BannerButtonWrapper isXS={screen(['xs'])}>
								<PrimaryMediumButtonFixed
									uniqueId="store.banner.explore"
									onClick={() => history.push(`/${getLang()}/apple-brand-store`)}
								>
									{translate('store.banner.explore')}
								</PrimaryMediumButtonFixed>
							</BannerButtonWrapper>
						</AppleBannerWrapper>
					)}
				/>
				<ConditionalRender
					show={!searchValue}
					onTrue={() => (
						<BusyIndicator listener={GET_PRODUCTS_BI} skeleton="categoryTitle">
							<CategoryTitleWrapper>
								<SearchingTitle>{getCategoryName()}</SearchingTitle>
							</CategoryTitleWrapper>
						</BusyIndicator>
					)}
				/>
				<ConditionalRender
					show={!!searchValue}
					onTrue={() => (
						<SearchingWrapper>
							<SearchingTitle>{translate('store.searching.title', searchValue)}</SearchingTitle>
						</SearchingWrapper>
					)}
				/>

				<Row>
					<Col sm={12} md={4} lg={isSmallerLgWidth ? 4 : 3}>
						<ConditionalRender show={screen(['xl', 'lg'])} onTrue={() => <CategoriesAndFilters />} />
					</Col>
					<Col lg={isSmallerLgWidth ? 8 : 9} md={12} sm={12} xs={12}>
						<SortingMethodsRow justify="between">
							<ControlsWrapper>
								<ViewChangeButton />
								<ViewFiltersButton
									open={filterOpen}
									onFilterClick={setFilterOpen}
									counter={selectedFiltersCounter}
								/>
								<ConditionalRender
									show={!!searchValue}
									onTrue={() => <Keyword onClick={handleKeywordClick} keyword={searchValue} />}
								/>
							</ControlsWrapper>
							<SortingMethods type="categories" />
						</SortingMethodsRow>
						<ConditionalRender
							show={
								!!marketingBanners?.banners?.find(
									(item) => item?.img?.includes('/XL-L-M-banner') && !item?.topBanner
								)
							}
							onTrue={() => (
								<MarketingBanner
									banner={getBanner()}
									type={MarketingBannerType.HORIZONTAL_MEDIUM}
									marginTop={16}
									marginBottom={10}
								/>
							)}
						/>
						<Row>
							<BusyIndicator
								listener={[GET_CATEGORIES_BI, GET_PRODUCTS_BI]}
								skeleton="product"
								skeletonProps={{ viewType }}
							>
								<>
									<ConditionalRender
										show={!!tradeInProduct}
										onTrue={() => getSpecialCard(tradeInProduct)}
									/>
									{_filteredProducts}
									<Col xs={12}>
										<div style={{ position: 'relative' }} ref={paginationRef}>
											<Pagination
												displayedItems={filteredProducts.length}
												totalItems={totalProducts}
											/>
										</div>
									</Col>
								</>
							</BusyIndicator>
						</Row>
					</Col>
				</Row>
			</Container>
			<SidePanel open={filterOpen} setPanelExpanded={setFilterOpen}>
				<CategoriesAndFilters />
			</SidePanel>
		</>
	);
};

export default Store;
