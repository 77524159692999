import Accordion from '@Components/Accordion/Accordion';
import ConditionalRender from '@Components/ConditionalRender/ConditionalRender';
import { PrimaryLargeButtonFixed } from '@Components/controls/Button/Button';
import TextField from '@Components/controls/TextField/TextField';
import Divider from '@Components/Divider/Divider';
import Icon from '@Components/Icon/Icon';
import PriceTag from '@Components/PriceTag/PriceTag';
import { CaptionBigger, CaptionBiggerBold, ParagraphMBold } from '@Components/Typography/Typography.styled';
import { setMakasibPoints } from '@Redux/modules/makasibPoints/actions';
import { dispatch } from '@Redux/store';
import { AuthenticationService } from '@Services/authentication/authenticationService';
import { MakasibService } from '@Services/makasib/makasib';
import { useCart } from '@Utils/context/CartContext';
import { roundMoney } from '@Utils/converters/roundMoney';
import externalLinks from '@Utils/externalLinks/externalLinks';
import useTranslate from '@Utils/hooks/useTranslate';
import useScreen from '@Utils/hooks/useScreen';
import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { Col, Row } from 'react-grid-system';
import { useSelector } from 'react-redux';
import {
	AccordionContentWrapper,
	EVoucherCardContainer,
	Button,
	VerticalDivider,
	TextButton,
	StatusInfoWrapper,
	DescriptionWrapper,
	BackgroundImageWrapper,
	PriceWrapper,
	VoucherRow,
	VoucherWarningContainer,
	VoucherCodeWrapper,
	HeaderRow,
	HeaderText,
	VoucherLabelsWrapper,
	DescriptionContainer,
	List,
	EmptyLine,
	ListItem,
	NonLoggedAccordionContentWrapper,
	RemoveItemWrapper,
	LineDivider,
	VoucherInfoRow,
	ExpiryDateWrapper,
	VoucherLimitInfo,
} from './EVoucherCard.styled';
import { ProfileService } from '@Services/profile/profileService';
import { IAddItemsToCartPayload, OrderFlowService } from '@Services/orderFlow/orderFlow';
import { MAKASIB_POINTS_PRODUCT_ID, MAKASIB_VOUCHER_PRODUCT_ID } from '@Config/app.config';
import { ICartItem, IInsuranceCartItem } from '@ApiModels/viewCart';
import TagManager from 'react-gtm-module';
import { getUserId } from '@Redux/modules/api/authentication/selectors';
import moment from 'moment';
import { addBusyIndicator, removeBusyIndicator } from '@Redux/modules/busyIndicator/actions';

const WARNING_ICON_SIZE = 20;
const MAKASIB_PAYMENT_VOUCHER = 'MAKASIB_PAYMENT_VOUCHER';
const GET_VOUCHER_DATA = 'GET_VOUCHER_DATA';

const CheckoutInfo: FunctionComponent<types.cards.ICartMakasibCardProps> = ({
	params: {
		hasGiftCards,
		discoundablePrice,
		hasDigitalProduct,
		setNonLoggedVoucherAccordion,
		setNonLoggedMakasibAccordion,
		nonLoggedVoucherAccordion,
	},
}) => {
	const { translate } = useTranslate();
	const isLogged = useSelector((state: types.redux.IState) => !!state.api.authentication.signedIn);
	const [login, setLogin] = useState<string | number>('');
	const [password, setPassword] = useState<string | number>('');
	const [loading, setLoading] = useState<boolean>(false);
	const [isError, setIsError] = useState<boolean>(false);
	const [errorMsg, setErrorMsg] = useState<string>('');
	const isSignedIn = useSelector((state: types.redux.IState) => state.api.authentication.signedIn);
	const [voucherValue, setVoucherValue] = useState<string | number>('');
	const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(true);
	const [voucherError, setVoucherError] = useState<string | undefined>();
	const [totalDiscount, setTotalDiscount] = useState<number>(0);

	const { screen } = useScreen();

	const { cart } = useCart();

	const _signIn = () => {
		setLoading(true);
		setIsError(false);
		setErrorMsg('');
		AuthenticationService.login(login.toString(), password.toString()).subscribe(
			() => {
				setLoading(false);
				location.reload();
			},
			() => {
				setIsError(true);
				setLoading(false);
				setErrorMsg(translate('modal.login.invalid.credentials'));
			}
		);
	};

	const makasibDiscount = useMemo(() => {
		return -Number(cart.items.find((item) => item.productId === MAKASIB_POINTS_PRODUCT_ID)?.price) ?? 0;
	}, [cart.items]);

	const getUserProfile = async (): Promise<string> => {
		return new Promise((resolve) => {
			ProfileService.getUserProfile().subscribe(
				(response) => {
					resolve(response.uid);
				},
				() => {
					resolve('');
				}
			);
		});
	};

	useEffect(() => {
		if (String(voucherValue).length > 5) {
			setIsButtonDisabled(false);
		} else {
			setIsButtonDisabled(true);
		}
	}, [voucherValue]);

	const makasibVoucherProducts = useMemo(() => {
		return cart.items.filter((item) => item.productId === MAKASIB_VOUCHER_PRODUCT_ID);
	}, [cart.items]);

	const isMakasibVoucherInCart = useMemo(() => {
		return makasibVoucherProducts.length > 0;
	}, [makasibVoucherProducts]);

	const vouchersValues = useMemo(() => {
		let usedVouchersValueInOMR = 0;
		let totalVouchersValueInOMR = 0;
		makasibVoucherProducts.forEach((item) => {
			usedVouchersValueInOMR = usedVouchersValueInOMR + Number(item.usedVoucherValueInOMR ?? 0);
			totalVouchersValueInOMR = totalVouchersValueInOMR + Number(item.totalVoucherValueInOMR ?? 0);
		});
		return { usedVouchersValueInOMR, totalVouchersValueInOMR };
	}, [makasibVoucherProducts]);

	useEffect(() => {
		let voucherDiscount = 0;
		if (makasibVoucherProducts.length > 0) {
			voucherDiscount = makasibVoucherProducts.reduce(
				(voucherDiscount, item) => voucherDiscount + Number(item.usedVoucherValueInOMR),
				0
			);
		}
		setTotalDiscount(!!makasibDiscount ? voucherDiscount + makasibDiscount : voucherDiscount);
	}, [makasibDiscount, makasibVoucherProducts]);

	const deleteVoucher = (voucherItem: ICartItem | IInsuranceCartItem) => {
		OrderFlowService.removeSingleCartItem(voucherItem?.orderItemId).subscribe(
			() => {
				MakasibService.manageMakasibVoucherReservation(String(voucherItem?.voucherId), 'U').subscribe(
					() => {},
					() => {}
				);
				TagManager.dataLayer({
					dataLayer: {
						Product_Name: voucherItem?.name,
						event: 'Remove item from cart',
						Logged_in: isSignedIn ? 'Yes' : 'No',
						userId: getUserId(),
					},
				});
			},
			() => {}
		);
	};

	useEffect(() => {
		dispatch(setMakasibPoints({ isTouched: false }));
	}, []);

	useEffect(() => {
		if (nonLoggedVoucherAccordion) {
			setNonLoggedMakasibAccordion(false);
		}
	}, [nonLoggedVoucherAccordion]);

	const isDisabled = useMemo(() => {
		return makasibVoucherProducts.length >= 5 || discoundablePrice === 0 || totalDiscount >= discoundablePrice;
	}, [discoundablePrice, totalDiscount, makasibVoucherProducts]);

	const forgotPassword = () => {
		window.open(externalLinks('FORGOT_PASSWORD'));
	};

	const isVoucherValueInCart = useMemo(() => {
		return !!makasibVoucherProducts.find((item) => item.voucherId === voucherValue);
	}, [makasibVoucherProducts, voucherValue, isMakasibVoucherInCart]);

	const handleSubmitVoucher = async () => {
		if (String(voucherValue).length <= 5 || isVoucherValueInCart) {
			setVoucherError(translate('cart.makasib.voucher.error'));
		} else {
			setVoucherError(undefined);
			dispatch(addBusyIndicator(GET_VOUCHER_DATA));
			let loggedUserPhoneNumber = '';
			await getUserProfile()
				.then((number) => {
					loggedUserPhoneNumber = number;
				})
				.catch(() => {
					dispatch(removeBusyIndicator(GET_VOUCHER_DATA));
				});
			const preparedLoginNumber = loggedUserPhoneNumber.replace(/[^0-9]+/g, '');
			if (!!preparedLoginNumber) {
				MakasibService.manageMakasibVoucherReservation(String(voucherValue), 'GW').subscribe(
					(response) => {
						const payload: IAddItemsToCartPayload = {
							quantity: '1',
							productId: MAKASIB_VOUCHER_PRODUCT_ID,
							xitem_planAction: MAKASIB_PAYMENT_VOUCHER,
							xitem_login: preparedLoginNumber,
							xitem_voucherId: String(voucherValue),
							xitem_totalVoucherValueInOMR: String(response.voucherReservationResponse.bonusAmount),
							xitem_usedVoucherValueInOMR: String(response.voucherReservationResponse.bonusAmount),
							xitem_expirationDate: String(response.voucherReservationResponse.voucherExpirationDate),
						};

						OrderFlowService.addItemsToCart([payload]).subscribe(
							() => {
								setVoucherValue('');
								dispatch(removeBusyIndicator(GET_VOUCHER_DATA));
							},
							() => {
								setVoucherError(translate('cart.makasib.voucher.error'));
								setVoucherValue('');
								dispatch(removeBusyIndicator(GET_VOUCHER_DATA));
							}
						);
					},
					() => {
						setVoucherValue('');
						dispatch(removeBusyIndicator(GET_VOUCHER_DATA));
						setVoucherError(translate('cart.makasib.voucher.error'));
					}
				);
			} else {
				setVoucherError(translate('cart.makasib.voucher.error'));
				dispatch(addBusyIndicator(GET_VOUCHER_DATA));
			}
		}
	};

	const getAccordionSubtitle = () => {
		if (isMakasibVoucherInCart) {
			const { usedVouchersValueInOMR } = vouchersValues;
			return translate('cart.voucher.accordion.subtitle', usedVouchersValueInOMR, makasibVoucherProducts.length);
		} else if (hasGiftCards || hasDigitalProduct) {
			return translate('cart.voucher.gift-card.warning');
		} else return undefined;
	};

	return (
		<>
			<EVoucherCardContainer isXs={screen(['xs'])} isSm={screen(['sm'])}>
				<ConditionalRender
					show={isLogged}
					onTrue={() => (
						<Accordion
							title={translate('cart.voucher.section.title')}
							subtitleTopMargin="8px"
							subtitleColor="black54"
							titleBold
							iconColor="black87"
							subtitle={getAccordionSubtitle()}
							icon={!screen(['xs']) ? 'eVoucher' : undefined}
							constantMargin
							customAccordionHeight="100%"
							isTitleExpandable
							iconSize={40}
							titleLeftMargin={screen(['sm']) ? 0 : screen(['xs']) ? -16 : 16}
							isIconExpandable
						>
							<AccordionContentWrapper isSm={screen('sm')} isXs={screen('xs')}>
								<Row>
									<Col xl={6} lg={12}>
										<TextField
											id="eVoucher"
											width={screen('xl') ? '320' : '100%'}
											height={56}
											disabled={isDisabled}
											buttonDisabled={isButtonDisabled}
											name="eVoucher"
											placeholder={translate('cart.makasib.voucher.placeholder')}
											label={translate('cart.makasib.voucher.placeholder')}
											placeholderColor="black54"
											value={voucherValue}
											onChange={(voucherValue) => {
												setVoucherValue(voucherValue);
												setVoucherError(undefined);
												dispatch(setMakasibPoints({ isTouched: true }));
											}}
											message={voucherError}
											error={!!voucherError}
											type="text"
											onEnter={() => handleSubmitVoucher()}
											rightText={translate('cart.makasib.apply.button')}
											rightTextOnClick={() => handleSubmitVoucher()}
											rightTextPointer={true}
											rightTextSpinner={true}
											rightTextListener={GET_VOUCHER_DATA}
										/>
										<ConditionalRender
											show={makasibVoucherProducts.length >= 5}
											onTrue={() => (
												<VoucherLimitInfo>
													{translate('cart.makasib.voucher-info.limit-reached')}
												</VoucherLimitInfo>
											)}
										/>
									</Col>
									<ConditionalRender
										show={screen('xl')}
										onTrue={() => (
											<Col>
												<VerticalDivider />
											</Col>
										)}
									/>
									<ConditionalRender
										show={screen('xl')}
										onTrue={() => (
											<Col xl={5}>
												<StatusInfoWrapper isXl={screen('xl')}>
													<List>
														<DescriptionContainer>
															<ListItem>
																<DescriptionWrapper>
																	{translate('cart.makasib.voucher-info.exchange')}
																</DescriptionWrapper>
															</ListItem>
														</DescriptionContainer>
														<DescriptionContainer>
															<ListItem>
																<DescriptionWrapper>
																	{translate('cart.makasib.voucher-info.giftcards')}
																</DescriptionWrapper>
															</ListItem>
														</DescriptionContainer>
													</List>
												</StatusInfoWrapper>
											</Col>
										)}
									/>
								</Row>
								<ConditionalRender
									show={!screen('xl')}
									onTrue={() => (
										<Row>
											<LineDivider margin="24px 16px" />
											<StatusInfoWrapper isXl={screen('xl')} isXs={screen('xs')}>
												<List>
													<DescriptionContainer>
														<ListItem>
															<DescriptionWrapper>
																{translate('cart.makasib.voucher-info.exchange')}
															</DescriptionWrapper>
														</ListItem>
													</DescriptionContainer>
													<DescriptionContainer margin={screen('xl') ? '0' : '8px 0px 0px'}>
														<ListItem>
															<DescriptionWrapper>
																{translate('cart.makasib.voucher-info.giftcards')}
															</DescriptionWrapper>
														</ListItem>
													</DescriptionContainer>
												</List>
											</StatusInfoWrapper>
										</Row>
									)}
								/>
								<ConditionalRender
									show={isMakasibVoucherInCart}
									onTrue={() => (
										<ConditionalRender
											show={screen('xs')}
											onTrue={() => (
												<>
													{makasibVoucherProducts.map((voucher, index) => (
														<VoucherRow key={voucher.productId}>
															<VoucherLabelsWrapper
																margin={index === 0 ? '40px 8px 0px' : '8px 8px 0px'}
															>
																<ParagraphMBold color="black54">
																	{translate('cart.makasib.voucher.applied')}
																</ParagraphMBold>
																<VoucherInfoRow isXl>
																	<VoucherCodeWrapper>
																		{voucher.voucherId}
																	</VoucherCodeWrapper>
																	<ExpiryDateWrapper>
																		{translate(
																			'cart.voucher.expires.on',
																			moment(
																				voucher.expirationDate,
																				'YYYY-MM-DD'
																			).format('DD.MM.YYYY') ?? ''
																		)}
																	</ExpiryDateWrapper>
																</VoucherInfoRow>

																<VoucherRow margin="24px 0px 0px">
																	<Col xs={6}>
																		<HeaderText alignLeft>
																			{translate('cart.items.one-time')}
																		</HeaderText>
																	</Col>
																	<Col xs={6}>
																		<PriceWrapper>
																			<PriceTag
																				price={voucher.unitPrice}
																				color="primary"
																				size="small"
																			/>
																		</PriceWrapper>
																	</Col>
																</VoucherRow>

																<VoucherRow>
																	<Col xs={6}>
																		<HeaderText alignLeft>
																			{translate('cart.items.monthly')}
																		</HeaderText>
																	</Col>
																	<Col xs={6}>
																		<PriceWrapper>
																			<EmptyLine />
																		</PriceWrapper>
																	</Col>
																</VoucherRow>

																<RemoveItemWrapper
																	onClick={() => deleteVoucher(voucher)}
																>
																	{translate('cart.makasib.voucher.remove')}
																</RemoveItemWrapper>
																<LineDivider margin="16px 0px" />
															</VoucherLabelsWrapper>
														</VoucherRow>
													))}
												</>
											)}
											onFalse={() => (
												<>
													<HeaderRow>
														<Col
															lg={3}
															md={3}
															sm={3.6}
															offset={{ lg: 5.6, md: 6, sm: 5.9 }}
														>
															<HeaderText alignRight>
																{translate('cart.items.one-time')}
															</HeaderText>
														</Col>
														<Col lg={3.4} md={2} sm={2.5}>
															<HeaderText alignRight>
																{translate('cart.items.monthly')}
															</HeaderText>
														</Col>
														<LineDivider margin="0px 12px 16px" />
													</HeaderRow>
													{makasibVoucherProducts.map((voucher) => (
														<VoucherRow key={voucher.productId}>
															<Col xl={5.9} lg={5.9} md={5.9} sm={5.9}>
																<VoucherLabelsWrapper>
																	<ParagraphMBold color="black54">
																		{translate('cart.makasib.voucher.applied')}
																	</ParagraphMBold>
																	<VoucherInfoRow isXl={screen('xl')}>
																		<VoucherCodeWrapper>
																			{voucher.voucherId}
																		</VoucherCodeWrapper>
																		<ExpiryDateWrapper>
																			{translate(
																				'cart.voucher.expires.on',
																				moment(
																					voucher.expirationDate,
																					'YYYY-MM-DD'
																				).format('DD.MM.YYYY') ?? ''
																			)}
																		</ExpiryDateWrapper>
																	</VoucherInfoRow>
																	<RemoveItemWrapper
																		onClick={() => deleteVoucher(voucher)}
																	>
																		{translate('cart.makasib.voucher.remove')}
																	</RemoveItemWrapper>
																</VoucherLabelsWrapper>
															</Col>
															<Col xl={3} lg={3} md={3} sm={4.1}>
																<PriceWrapper>
																	<PriceTag
																		price={voucher.unitPrice}
																		color="primary"
																		size="small"
																	/>
																</PriceWrapper>
															</Col>
															<Col xl={3.1} lg={3.1} md={3.1} sm={2}>
																<PriceWrapper>
																	<EmptyLine />
																</PriceWrapper>
															</Col>
															<LineDivider margin="16px 12px" />
														</VoucherRow>
													))}
												</>
											)}
										/>
									)}
								/>

								<ConditionalRender
									show={
										discoundablePrice -
											(makasibDiscount ? makasibDiscount : 0) -
											vouchersValues.totalVouchersValueInOMR <
										0
									}
									onTrue={() => (
										<VoucherWarningContainer>
											<Icon
												fill="black87"
												name="info"
												height={WARNING_ICON_SIZE}
												width={WARNING_ICON_SIZE}
											/>
											<span>
												<CaptionBigger color="black54">
													{translate('cart.voucher.warning1')}
												</CaptionBigger>
												<CaptionBiggerBold color="black54">
													{translate(
														'cart.voucher.warning2',
														roundMoney(
															(discoundablePrice -
																(makasibDiscount ? makasibDiscount : 0) -
																vouchersValues.totalVouchersValueInOMR) *
																-1
														)
													)}
													{'\xa0'}
												</CaptionBiggerBold>
												<CaptionBigger color="black54">
													{translate('cart.voucher.warning3')}
												</CaptionBigger>
											</span>
										</VoucherWarningContainer>
									)}
								/>
							</AccordionContentWrapper>
						</Accordion>
					)}
					onFalse={() => (
						<Accordion
							titleBold
							title={translate('cart.voucher.section.title')}
							constantMargin
							iconSize={40}
							enableSubtitleOnExpand={!!nonLoggedVoucherAccordion}
							customAccordionState={nonLoggedVoucherAccordion}
							subtitleTopMargin="8px"
							subtitleColor="black54"
							subtitle={
								(hasGiftCards || hasDigitalProduct) && !nonLoggedVoucherAccordion
									? translate('cart.voucher.gift-card.warning')
									: translate('cart.voucher.log-in')
							}
							isTitleExpandable
							setIsAccordionExpanded={setNonLoggedVoucherAccordion}
							titleLeftMargin={screen(['sm']) ? 0 : screen(['xs']) ? -16 : 16}
							icon={!screen(['xs']) ? 'eVoucher' : undefined}
						>
							<NonLoggedAccordionContentWrapper isXs={screen(['xs'])} isSm={screen(['sm'])} isNonLogged>
								<Col>
									<TextField
										id="login"
										name="login"
										type="text"
										width={screen(['xs']) ? '100%' : '304px'}
										label={translate('modal.login.phone.number.or.email')}
										placeholder={translate('modal.login.phone.number.or.email')}
										value={login}
										onChange={(login) => {
											setLogin(login);
										}}
										marginBottom={8}
										onEnter={() => _signIn()}
										error={isError}
									/>
									<TextField
										id="1"
										name="password"
										placeholder={translate('modal.login.password')}
										value={password}
										label={translate('modal.login.password')}
										width={screen(['xs']) ? '100%' : '304px'}
										onChange={(password) => {
											setPassword(password);
										}}
										marginBottom={8}
										message={errorMsg}
										error={isError}
										type="password"
										onEnter={() => _signIn()}
									/>
									<Divider marginBottom={8} withoutLine />
									<Button isXs={screen(['xs'])}>
										<PrimaryLargeButtonFixed
											uniqueId="modal.login.sign.in"
											listener={loading}
											onClick={() => _signIn()}
										>
											{translate('modal.login.sign.in')}
										</PrimaryLargeButtonFixed>
									</Button>
									<Divider marginBottom={16} withoutLine />
									<TextButton onClick={() => forgotPassword()}>
										{translate('modal.login.forgot.password')}
									</TextButton>
									<Divider marginBottom={40} withoutLine />
								</Col>
							</NonLoggedAccordionContentWrapper>
						</Accordion>
					)}
				/>
			</EVoucherCardContainer>
			<ConditionalRender
				show={screen(['xl', 'lg', 'md']) && !isLogged && !!nonLoggedVoucherAccordion}
				onTrue={() => (
					<Col>
						<BackgroundImageWrapper>
							<img title="MakasibPattern" src="makasibWatermark.png" width="100%" height="100%" />
						</BackgroundImageWrapper>
					</Col>
				)}
			/>
		</>
	);
};

export default CheckoutInfo;
