import { ActionFunction1, createAction, Action } from 'redux-actions';

export enum ControlState {
	EDITABLE = 'EDITABLE',
	UNEDITABLE = 'UNEDITABLE',
}

export interface IControl {
	id: string;
	identifier: string;
	name: string;
	value: ControlState;
}
export interface IForm {
	id: string | undefined;
	code: string | undefined;
	name: string | undefined;
	controls: IControl[] | undefined;
}
export interface ISettingsConfig {
	accountCategory?: string[] | null;
	forms?: IForm[];
}

export interface ISettingsConfigData {
	payload: ISettingsConfig;
}

export const setConfig: ActionFunction1<ISettingsConfig, Action<ISettingsConfig>> = createAction(
	'SET_CONFIG',
	(action: ISettingsConfig) => action
);
