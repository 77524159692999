import api from '@Utils/api';
import { Observable, of } from 'rxjs';
import { catchError, concatMap, tap } from 'rxjs/operators';
import { dispatch } from '@Redux/store';
import { logOut, signIn } from '@Redux/modules/api/authentication/actions';
import { IGuestLogIn } from '@ApiModels/guestLogIn';
import { ILTPAToken } from '@ApiModels/authentication';
import { invalidateAllCache } from '@Redux/modules/api/cache/actions';
import { OrderFlowService } from '@Services/orderFlow/orderFlow';
import { clearCheckoutDetails } from '@Redux/modules/checkout/actions';
import TagManager from 'react-gtm-module';
import { getUserId } from '@Redux/modules/api/authentication/selectors';
import { IBnplSession } from '@ApiModels/createBuyNowPayLaterSession';

const TAG_MANAGER_WEB_ESHOP_LOGIN = 'Web_eShop_login';

export interface IBuNowPayLeterSessionPayload {
	orderId: string;
	dateOfBirth: string;
	creditRating?: string;
	isDisabledBnplCr7985?: boolean;
}

export class AuthenticationService {
	public static login(user: string, secret: string, telesales?: boolean): Observable<IGuestLogIn> {
		return api.omantelApi
			.login(user, secret)
			.call()
			.pipe(
				concatMap(
					(response: ILTPAToken): Observable<IGuestLogIn> => {
						const tagManagerArgs = {
							dataLayer: {
								Logged_in: 'Yes',
								event: TAG_MANAGER_WEB_ESHOP_LOGIN,
								userId: getUserId(),
							},
						};
						TagManager.dataLayer(tagManagerArgs);
						dispatch(
							signIn({
								signedIn: true,
								telesales,
								ltpaToken2: response.login.LTPAToken,
								loginType: 'user',
							})
						);
						return of({
							WCToken: '',
							WCTrustedToken: '',
							personalizationID: '',
							resourceName: '',
							userId: '',
							telesales: false,
							supportedAccountCategories: null,
						});
					}
				)
			);
	}

	private static logoutOmantelApi(): Observable<{ status: string }> {
		return api.omantelApi
			.logout()
			.call()
			.pipe(
				tap(() => {
					dispatch(invalidateAllCache());
					dispatch(logOut());
					dispatch(clearCheckoutDetails());
				})
			);
	}

	public static logout(): Observable<{ status: string }> {
		return OrderFlowService.userLogOut().pipe(
			catchError(() => this.logoutOmantelApi()),
			concatMap(() => this.logoutOmantelApi())
		);
	}

	public static createBuyNowPayLaterSession(payload: IBuNowPayLeterSessionPayload): Observable<IBnplSession> {
		return api.omantelShop.createBuyNowPayLaterSession(payload).call();
	}

	public static retriveBuyNowPayLaterSession(orderId: string): Observable<IBnplSession> {
		return api.omantelShop.retriveBuyNowPayLaterSession(orderId).call();
	}
}
