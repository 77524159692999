import { STORE_ID, CURRENCY, CONTRACT_ID, CATALOG_ID, DEFAULT_PAGE_SIZE } from '@Config/app.config';
import {
	IProductByIdSpecificSkusPayload,
	IRelatedProductsByIdPayload,
} from '@Services/productsService/productsService';
import { getLanguageCode } from '@Utils/language/language';

export interface IGetProductsByParentCategory {
	parentCategory: string;
	offset: number;
	searchValue?: string;
	limit?: string;
}

export const getProductsByCategoryId = (categoryId: string): types.endpoints.IEndpointConfig => {
	return {
		url: `/search/resources/api/v2/products?categoryId=${categoryId}&contractId=${CONTRACT_ID}&currency=${CURRENCY}&storeId=${STORE_ID}&langId=${getLanguageCode()}&catalogId=${CATALOG_ID}`,
	};
};

export const getProductsByPartNumber = (partNumber: string): types.endpoints.IEndpointConfig => {
	return {
		url: `/search/resources/api/v2/products?catalogId=${CATALOG_ID}&contractId=${CONTRACT_ID}&storeId=${STORE_ID}&langId=${getLanguageCode()}&partNumber=${partNumber.replace(
			'+',
			'%2b'
		)}`,
	};
};

export const getProductsByParentCategory = ({
	parentCategory,
	offset,
	searchValue,
	limit,
}: IGetProductsByParentCategory): types.endpoints.IEndpointConfig => {
	return {
		url: `/search/resources/api/v2/products?currency=${CURRENCY}&facet=path.tree%3A${parentCategory}&offset=${offset}&searchTerm=${
			!!searchValue ? searchValue : '*'
		}&storeId=${STORE_ID}&langId=${getLanguageCode()}&catalogId=${CATALOG_ID}&limit=${
			limit ?? DEFAULT_PAGE_SIZE
		}&_wcf.search.sort=3`,
	};
};

export const getProductById = ({
	productId,
	itemType,
	customCatalog,
}: IProductByIdSpecificSkusPayload): types.endpoints.IEndpointConfig => {
	return {
		url: `search/resources/api/v2/products?productId=${productId}&storeId=${STORE_ID}&langId=${getLanguageCode()}&catalogId=${
			!!customCatalog ? customCatalog : CATALOG_ID
		}${itemType ? '&defItemType=' + itemType : ''}`,
	};
};

export const getRelatedProductsById = ({
	productId,
	itemType,
}: IRelatedProductsByIdPayload): types.endpoints.IEndpointConfig => {
	return {
		url: `search/resources/store/${STORE_ID}/productview/byId/${productId}?catalogId=${CATALOG_ID}${
			itemType ? '&defItemType=' + itemType : ''
		}`,
	};
};

export const getProductIdBySeo = (seo: string): types.endpoints.IEndpointConfig => {
	return {
		url: `search/resources/api/v2/urls?identifier=${seo}&storeId=${STORE_ID}&langId=${getLanguageCode()}`,
	};
};

export const getProductOutletAvailability = (partNumber: string): types.endpoints.IEndpointConfig => {
	return {
		url: `wcs/resources/store/${STORE_ID}/outletpickup/${partNumber}`,
	};
};

export const getProductOutletsPickup = (partNumber: string): types.endpoints.IEndpointConfig => {
	return {
		url: `wcs/resources/store/${STORE_ID}/outletpickup/outlets/${partNumber}`,
	};
};

export const getOutletPickup = (branchId: string): types.endpoints.IEndpointConfig => {
	return {
		url: `wcs/resources/store/${STORE_ID}/outletpickup/outlet/${branchId}`,
	};
};

export const getProductInstallment = (productId: string): types.endpoints.IEndpointConfig => {
	return {
		url: `wcs/resources/store/${STORE_ID}/productext/${productId}/installment`,
	};
};
