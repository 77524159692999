import { telesalesSaveInProgress, IUpdateTelesalesAction } from './actions';
import immerHandleActions from '@Redux/utils/immerHandleActions';

const init = {
	saveInProgress: false,
};

type draft = typeof init;

export interface ITelesalesDraft extends draft {}

const reducers = {
	[telesalesSaveInProgress.toString()](draft: draft, action: IUpdateTelesalesAction) {
		return { ...draft, saveInProgress: action.payload };
	},
};

export default immerHandleActions(reducers, init);
