import React, { FunctionComponent } from 'react';
import Icon from '@Components/Icon/Icon';
import useTranslate from '@Utils/hooks/useTranslate';
import { IconContainer, Title, Container, ButtonContainer, TopContainer } from './UpgradePlan.styled';
import { PrimaryMediumButtonFlex, SecondaryMediumButtonFlex } from '@Components/controls/Button/Button';
import Divider from '@Components/Divider/Divider';

interface IUpgradePlan {
	label: string;
	firstButtonLabel: string;
	secondButtonLabel: string;
	onFirstButtonClick: () => void;
	onSecondButtonClick: () => void;
	currentPlanIsHBB: boolean;
}

const ICON_SIZE = 96;
const UNLIMITED_SIZE = 24;

const UpgradePlan: FunctionComponent<IUpgradePlan> = ({
	label,
	firstButtonLabel,
	secondButtonLabel,
	onFirstButtonClick,
	onSecondButtonClick,
	currentPlanIsHBB,
}) => {
	const { translate } = useTranslate();

	return (
		<Container>
			<>
				<TopContainer>
					<IconContainer>
						<Icon
							name={currentPlanIsHBB ? 'recommendationWiFi' : 'recommendationPhone'}
							width={ICON_SIZE}
							height={ICON_SIZE}
						/>
					</IconContainer>
					<Title>{label}</Title>
				</TopContainer>
			</>
			<Divider withoutLine marginBottom={24} />
			<ButtonContainer>
				<PrimaryMediumButtonFlex onClick={onFirstButtonClick} uniqueId="upgrade.plan.now">
					{firstButtonLabel}
				</PrimaryMediumButtonFlex>
				<Divider withoutLine marginBottom={8} />
				<SecondaryMediumButtonFlex onClick={onSecondButtonClick} uniqueId="upgrade.plan.get.details">
					{secondButtonLabel}
				</SecondaryMediumButtonFlex>
			</ButtonContainer>
		</Container>
	);
};

export default UpgradePlan;
