import React, { FunctionComponent } from 'react';
import Card from '@Components/Card/Card';
import { FiltersWrapper, SubheadingWrapper, SubheaderText } from './Fitlters.styled';
import { TertiaryMediumButtonAuto } from '@Components/controls/Button/Button';
import { useSelector } from 'react-redux';
import { dispatch } from '@Redux/store';
import { clearFilters } from '@Redux/modules/filters/actions';
import { clearPriceFilter } from '@Redux/modules/priceFilter/actions';
import useTranslate from '@Utils/hooks/useTranslate';

const Filters: FunctionComponent = () => {
	const filters = useSelector((state: types.redux.IState) => state.filters);
	const { translate } = useTranslate();

	return (
		<FiltersWrapper>
			<SubheadingWrapper>
				<SubheaderText>{translate('filters.title')}</SubheaderText>
				<TertiaryMediumButtonAuto
					uniqueId="filters.clear"
					onClick={() => {
						dispatch(clearFilters());
						dispatch(clearPriceFilter());
					}}
				>
					{translate('filters.clear')}
				</TertiaryMediumButtonAuto>
			</SubheadingWrapper>
			{filters.map((filter, index) => (
				<Card<types.cards.IFilterCardProps>
					card="filter"
					params={{
						values: filter.entry,
						filter: filter.name,
						expanded: false,
						expandable: index > 0,
						collapsable: index > 0,
						hideMoreThan: 10,
					}}
					withoutContainerPadding
					marginBottom={16}
					key={`Filter-${filter.value}`}
				/>
			))}
			<Card<types.cards.IFilterCardProps>
				card="filter"
				params={{
					filter: translate('filters.price'),
					range: {
						min: 0,
						max: 9999,
					},
					maxLength: 6,
				}}
				withoutContainerPadding
				marginBottom={16}
				key="Filter-range"
			/>
		</FiltersWrapper>
	);
};

export default Filters;
