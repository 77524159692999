import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import useTranslate from '@Utils/hooks/useTranslate';
import { Col, Hidden, Row } from 'react-grid-system';
import Icon from '@Components/Icon/Icon';
import {
	Horizontal,
	IcoWrapper,
	Link,
	OrderItemWrapper,
	PaymentAndDeliveryContainer,
	PaymentDetails,
	PaymentDetailsDesc,
	PaymentDetailsTitle,
	Status,
	StatusWrapper,
	Underline,
	CancelDescriptionWrapper,
	CancelButtonWrapper,
	CanceledOrderInfoWrapper,
	CanceledOrderInfoLabelWrapper,
} from './OrderItem.styled';
import { BodyS, Caption, ParagraphM, Value, ValueBold, ParagraphMBold } from '@Components/Typography/Typography.styled';
import { OrderHistoryType } from '../OrderHistory';
import ConditionalRender from '@Components/ConditionalRender/ConditionalRender';
import { useHistory, useLocation } from 'react-router-dom';
import Divider from '@Components/Divider/Divider';
import {
	IOrderCoordinates,
	IOrderDetails,
	IOrderDetailsItem,
	IOrderDetailsResponse,
	IOrderItem,
} from '@ApiModels/orderDetails';
import CartPaymentSummary from '../../CartPaymentSummary/CartPaymentSummary';
import { OrderFlowService } from '@Services/orderFlow/orderFlow';
import { dispatch } from '@Redux/store';
import { addBusyIndicator, removeBusyIndicator } from '@Redux/modules/busyIndicator/actions';
import BusyIndicator from '@Components/BusyIndicator/BusyIndicator';
import { ICartItem } from '@ApiModels/viewCart';
import { CartItemType } from '../../CartItems/subcomponents/CartItem';
import { modalTypes, setModal } from '@Redux/modules/modal/actions';
import moment from 'moment';
import {
	DEF_ITEM_TYPE,
	DIGITAL_PRODUCT_ITEM_TYPE,
	getAttributeValueAsString,
	getTariffIncludedFromAttributes,
	GIFT_CARD_ITEM_TYPE,
} from '@ApiModels/utils/attributeGetters';
import { getLang } from '@Utils/language/language';
import TitleWithLine from '@Components/TitleWithLine/TitleWithLine';
import OrderTracking from '@Components/OrderTracking/OrderTracking';
import { BUY_HBB, BUY_WFBB } from '@Views/Map/Map';
import { ItemType } from '@Services/productsService/productsService';
import { SecondaryMediumButtonFlex } from '@Components/controls/Button/Button';
import { DISABLE_ORDER_CANCELLATION } from '@Config/features.config';
import { CUSTOMER_CARE_PHONE, CUSTOMER_B2B2C_CARE_PHONE } from '@Config/app.config';
import { useSelector } from 'react-redux';

export interface IOrderSingleItem {
	orderId: string;
	date: string;
	item?: string;
	type: OrderHistoryType;
	status: string;
	isOrderHistoryView?: boolean;
}

export const GET_ORDER_HISTORY_DETAILS_BI = 'GET_ORDER_HISTORY_DETAILS_BI';
const BUY_SIM = 'BUY_SIM';
const MAKASIB_REDEEM = 'MAKASIB_REDEEM';
const COM_PARAM_INSTALLMENT_VALUE = 'COM_PARAM_INSTALLMENT_VALUE';

const OrderItem: FunctionComponent<IOrderSingleItem> = ({
	orderId,
	date,
	item,
	type,
	status,
	isOrderHistoryView = false,
}): JSX.Element => {
	const { translate } = useTranslate();
	const ICON_SIZE = 40;
	const CHEVRON_ICON_SIZE = 24;
	const STATUS_ICON_SIZE = 21;
	const { search } = useLocation();
	const query = new URLSearchParams(search);
	const history = useHistory();
	const [details, setDetails] = useState<IOrderDetails | undefined>(undefined);
	const [isDigital, setIsDigital] = useState<boolean>(false);
	const [detailsItem, setDetailsItem] = useState<IOrderDetailsItem>({
		paymentDate: '',
		deliveryDate: '',
		shipmentNumber: '',
		shipmentTrackUrl: '',
		items: [],
		upfrontPaymentSum: 0,
		period: '12',
		periodPaymentSum: 0,
		vat: 0,
		vatPercent: 0,
	});
	const isB2b = useSelector((state: types.redux.IState) => state.api.authentication.isB2b);
	const isB2b2c = useSelector((state: types.redux.IState) => state.api.authentication.isB2b2c);

	const getOrderId = () => {
		return query.get('orderId');
	};

	const handleSwitchPage = (page: string) => {
		history.push(page);
	};

	const path = (): string => {
		if (type === OrderHistoryType.ORDERS_IN_PROGRESS) {
			return `/${getLang()}/account/orders/inprogress`;
		} else {
			return `/${getLang()}/account/orders/history`;
		}
	};

	const close = () => {
		handleSwitchPage(path());
	};

	const open = () => {
		handleSwitchPage(path() + `?orderId=${orderId}`);
		getOrderDetails();
	};

	const getCoordinates = (data: string): IOrderCoordinates | undefined => {
		if (data.length > 0) {
			const coordinates = JSON.parse(data);
			const lat = +coordinates.latitude;
			const lng = +coordinates.longitude;
			return { lat, lng };
		}
	};

	const setUpCartItems = (items: IOrderItem[] | undefined): ICartItem[] => {
		const cartItems = items?.map((_item) => {
			let qty: number = 0;
			if (_item.quantity) {
				qty = +_item.quantity;
			}
			const attributes: { attrIdentifier: string; attrValue: string; attrName: string }[] = JSON.parse(
				_item.xitem_attributes ?? ''
			);
			const defItemType = attributes?.find(({ attrIdentifier }) => attrIdentifier === DEF_ITEM_TYPE)?.attrValue;
			const parsedFiled = JSON.parse(_item.xitem_field2 ?? '');
			const plan = {
				...parsedFiled,
				longDesc: _item.xitem_planLongDescription,
				priceMonthly: _item.xitem_planMonthy,
				planName: _item.xitem_planName,
				shortDesc: _item.xitem_planShortDescription,
				thumbnail: _item.xitem_thumbnail,
				planDetails: getTariffIncludedFromAttributes(parsedFiled.productId),
				installmentValue: getAttributeValueAsString(parsedFiled, COM_PARAM_INSTALLMENT_VALUE) ?? 0,
				planTaxablePrice: _item.xitem_planTaxablePrice,
				planContractDuration: _item.xitem_planContractDuration,
				planMonthy: _item.xitem_planMonthy,
				planVatValue: _item.xitem_planVatValue,
			};

			return {
				price: String(Number(_item.unitPrice ?? '0') * Number(_item?.quantity ?? 1) ?? ''),
				unitPrice: '',
				currency: _item.currency ?? 'OMR',
				salesTax: _item.salesTax ?? '',
				partNumber: _item.partNumber ?? '',
				productId: _item.productId ?? '',
				orderItemId: _item.orderItemId ?? '',
				quantity: qty,
				freeGift: /true/i.test(_item.freeGift ?? 'false'),
				type: CartItemType.ORDER_HISTORY,
				vat: setVat([_item]),
				vatPercent: setVatPercent([_item]),
				withPlan: !!_item.xitem_planName,
				plan,
				isDigital: defItemType === GIFT_CARD_ITEM_TYPE || defItemType === DIGITAL_PRODUCT_ITEM_TYPE,
			};
		});
		return !!cartItems ? cartItems : [];
	};

	const setUpUpfrontPaymentSum = (items: IOrderItem[] | undefined): number => {
		let upfrontPaymentSum = 0;
		items?.map((_item) => {
			let price: number = 0;
			if (_item.orderItemPrice) {
				price = +_item.orderItemPrice;
				upfrontPaymentSum += price;
			}
		});
		return upfrontPaymentSum;
	};

	const setPeriodPaymentSum = (items: IOrderItem[] | undefined): number => {
		let periodPaymentSum = 0;
		items?.map((_item) => {
			let price: number = 0;
			const parsedFiled = JSON.parse(_item.xitem_field2 ?? '');
			if (
				_item.xitem_planMonthy &&
				(parsedFiled.PA === ItemType.ITEM_ACCESSORY_INSTALMENTS ||
					parsedFiled.PA === ItemType.TABLETS_LAPTOPS_INSTALMENTS ||
					parsedFiled.PA === ItemType.B2B_ITEM_ACCESSORY_BAQATI ||
					parsedFiled.PA === ItemType.B2B_TABLETS_LAPTOPS_BAQATI ||
					parsedFiled.PA === ItemType.B2B2C_ITEM_ACCESSORY_BAQATI ||
					parsedFiled.PA === ItemType.B2B2C_TABLETS_LAPTOPS_BAQATI)
			) {
				price = +Number(parsedFiled?.IV);
				periodPaymentSum += price;
			} else if (_item.xitem_planMonthy) {
				price = +_item.xitem_planMonthy + Number(parsedFiled?.IV || 0);
				periodPaymentSum += price;
			}
		});
		return periodPaymentSum;
	};

	const setVat = (items: IOrderItem[] | undefined): number => {
		let _vat = 0;
		items?.map((_item) => {
			const v = JSON.parse(_item?.xitem_field2 ?? '');
			_vat += Number(_item.quantity) * Number(v.vatValue);
		});
		return _vat;
	};

	const setVatPercent = (items: IOrderItem[] | undefined): number => {
		let vatPercent = 0;
		items?.map((_item) => {
			const v = JSON.parse(_item?.xitem_field2 ?? '');
			const p = Number(v.vatPercent);
			if (vatPercent < p) {
				vatPercent = p;
			}
		});
		return vatPercent;
	};

	const setUpData = (data: IOrderDetails) => {
		const productItem = data.orderItem?.find((orderItem) => {
			const parsedAdditionalProductData = orderItem?.xitem_field2 ? JSON.parse(orderItem?.xitem_field2) : '';
			return 'branchId' in parsedAdditionalProductData;
		});
		const additionalProductData = !!productItem?.xitem_field2 ? JSON.parse(productItem?.xitem_field2) : undefined;
		setDetailsItem({
			paymentDate: data.x_paymentDate ? moment(data.x_paymentDate).format('DD MMM YYYY') : undefined,
			deliveryDate: '',
			shipmentNumber: data.x_shipmentNumber ?? '',
			shipmentTrackUrl: data.x_shipmentTrackUrl ?? '',
			coordinates: getCoordinates(data.x_shippingGeoCode ?? ''),
			items: setUpCartItems(data.orderItem ?? []),
			upfrontPaymentSum: setUpUpfrontPaymentSum(data.orderItem ?? []),
			period: '12',
			periodPaymentSum: setPeriodPaymentSum(data.orderItem ?? []),
			vat: setVat(data.orderItem ?? []),
			vatPercent: setVatPercent(data.orderItem ?? []),
			isPickup: data.orderItem?.some((orderItem) => orderItem.shipModeCode === 'Pickup'),
			email: data?.orderItem?.[0]?.email1,
			isOutletPickup: !!data.x_outletPickupOrder && data.x_outletPickupOrder === 'true',
			isShipment: !!data.x_noShipment && data.x_noShipment === 'true',
			outletPickupBookingAppointment: !!additionalProductData
				? additionalProductData.bookingPickupAppointment
				: '',
			branchId: !!additionalProductData ? additionalProductData.branchId : '',
			ticketNumber: !!additionalProductData ? additionalProductData.ticketNumber : '',
		});
	};

	const getOrderDetails = () => {
		if (orderId) {
			dispatch(addBusyIndicator(GET_ORDER_HISTORY_DETAILS_BI));
			OrderFlowService.getOrderDetails(orderId).subscribe(
				(orderDetails: IOrderDetailsResponse) => {
					setDetails(orderDetails.response);
					setUpData(orderDetails.response);
					dispatch(removeBusyIndicator(GET_ORDER_HISTORY_DETAILS_BI));
				},
				() => {
					setDetails(undefined);
					dispatch(removeBusyIndicator(GET_ORDER_HISTORY_DETAILS_BI));
				}
			);
		}
	};

	useEffect(() => {
		getOrderDetails();
	}, [orderId]);

	useEffect(() => {
		detailsItem.shipmentNumber === '-' ? setIsDigital(true) : '';
	}, [detailsItem]);

	const getAddress = () => {
		if (details && details.orderItem) {
			const _item = details.orderItem[0];
			return `${_item.addressLine?.filter((it) => {
				return !!it && it !== 'null';
			})}, ${_item.city} ${_item.zipCode}, ${_item.country}`;
		}
		return '';
	};

	const getCity = () => {
		return getAddress().split(',')[0] || '';
	};

	const getSubAddress = () => {
		return getAddress().substring(getAddress().indexOf(',') + 1) || '';
	};

	const hbbWfbbOrderDetails = useMemo(() => {
		return details?.orderItem?.find((item) => [BUY_HBB, BUY_WFBB].includes(item.partNumber || ''));
	}, [details]);

	const isWFBB = useMemo(() => {
		return !!details?.orderItem?.find((item) => [BUY_WFBB].includes(item.partNumber || ''));
	}, [details]);

	const isHBBWFBBSIM = useMemo(() => {
		return !!details?.orderItem?.find((item) => [BUY_HBB, BUY_WFBB, BUY_SIM].includes(item.partNumber || ''));
	}, [details]);

	const isCancellationAvailable = !detailsItem.items.some((item) => item.isDigital === true) && !isHBBWFBBSIM;

	return (
		<OrderItemWrapper>
			<Row>
				<Hidden xs sm md>
					<Col sm={1}>
						<IcoWrapper>
							<Icon name="order" width={ICON_SIZE} height={ICON_SIZE} />
						</IcoWrapper>
					</Col>
				</Hidden>
				<Col>
					<Row>
						<Col>
							<ConditionalRender
								show={getOrderId() === orderId}
								onTrue={() => <Divider marginBottom={8} withoutLine />}
							/>
							<Horizontal>
								<ValueBold color="black87">
									{translate('account.management.order.id', orderId)}
								</ValueBold>
								<Value color="black87">{date}</Value>
							</Horizontal>
							<Divider marginBottom={8} withoutLine />
							<ConditionalRender
								show={getOrderId() !== orderId}
								onTrue={() => (
									<BodyS color="black54">
										{item
											?.replace(BUY_SIM, translate('order.item.hayyak.subtitle'))
											.replace(`, ${MAKASIB_REDEEM}`, '')
											.replace(MAKASIB_REDEEM, '')}
									</BodyS>
								)}
							/>
						</Col>
						<Col md={4}>
							<StatusWrapper>
								<Horizontal>
									<ConditionalRender
										show={type === OrderHistoryType.ORDERS_IN_PROGRESS}
										onTrue={() => (
											<>
												<Status>
													<Caption color="support3">
														{translate('account.management.order.in.progress')}
													</Caption>
												</Status>
												<Icon
													name="waiting"
													width={STATUS_ICON_SIZE}
													height={STATUS_ICON_SIZE}
													fill="black38"
												/>
											</>
										)}
									/>
									<ConditionalRender
										show={type === OrderHistoryType.ORDER_HISTORY}
										onTrue={() => (
											<>
												<ConditionalRender
													show={status === 'S'}
													onTrue={() => (
														<>
															<Status>
																<Caption color="positive">
																	{translate('account.management.order.completed')}
																</Caption>
															</Status>
															<Icon
																name="success"
																width={STATUS_ICON_SIZE}
																height={STATUS_ICON_SIZE}
															/>
														</>
													)}
													onFalse={() => (
														<ConditionalRender
															show={
																!!details?.x_canceledDate && !DISABLE_ORDER_CANCELLATION
															}
															onTrue={() => (
																<>
																	<Status>
																		<Caption color="warning">
																			{translate(
																				'account.management.order.canceled'
																			)}
																		</Caption>
																	</Status>
																	<Icon
																		name="error"
																		fill="warning"
																		width={STATUS_ICON_SIZE}
																		height={STATUS_ICON_SIZE}
																	/>
																</>
															)}
														/>
													)}
												/>
											</>
										)}
									/>
									<Link>
										{getOrderId() === orderId ? (
											<Icon
												name="chevronUp"
												width={CHEVRON_ICON_SIZE}
												height={CHEVRON_ICON_SIZE}
												onClick={close}
												fill="black"
											/>
										) : (
											<Icon
												name="chevronDown"
												width={CHEVRON_ICON_SIZE}
												height={CHEVRON_ICON_SIZE}
												onClick={open}
												fill="black"
											/>
										)}
									</Link>
								</Horizontal>
							</StatusWrapper>
						</Col>
					</Row>
				</Col>
			</Row>
			<ConditionalRender
				show={getOrderId() === orderId}
				onTrue={() => (
					<Row>
						<Hidden xs sm md>
							<Col sm={1}></Col>
						</Hidden>
						<Col>
							<BusyIndicator listener={GET_ORDER_HISTORY_DETAILS_BI} skeleton="orderTrackingDetails">
								<>
									<ConditionalRender
										show={
											type === OrderHistoryType.ORDER_HISTORY &&
											status === 'X' &&
											!!details?.x_canceledDate &&
											!DISABLE_ORDER_CANCELLATION
										}
										onTrue={() => (
											<CanceledOrderInfoWrapper>
												<ParagraphMBold>
													{translate('cancel-order.order-canceled.info.title')}
												</ParagraphMBold>
												<CanceledOrderInfoLabelWrapper>
													<ParagraphM>
														{translate(
															'cancel-order.order-canceled.info.description',
															moment(details?.x_canceledDate).format('Do [of] MMMM YYYY')
														)}
													</ParagraphM>
												</CanceledOrderInfoLabelWrapper>
											</CanceledOrderInfoWrapper>
										)}
									/>
									<TitleWithLine
										title={translate('account.management.payment.shipment.status')}
										marginTop={36}
										marginBottom={20}
									/>
									<PaymentAndDeliveryContainer>
										<ConditionalRender
											show={!isDigital && !detailsItem.isPickup && !detailsItem.isOutletPickup}
											onTrue={() => (
												<PaymentDetails>
													<Icon name="locationPin" width={ICON_SIZE} height={ICON_SIZE} />
													<PaymentDetailsDesc>
														<PaymentDetailsTitle>
															{translate('account.management.delivery.address')}
														</PaymentDetailsTitle>
														<BodyS color="black54">{getCity()}</BodyS>
														<BodyS color="black54">{getSubAddress()}</BodyS>
														<Divider color="transparent" marginBottom={16} />
														<Link
															onClick={() => {
																dispatch(
																	setModal({
																		type: modalTypes.SHOW_ON_MAP,
																		data: {
																			coordinates: detailsItem.coordinates,
																		},
																	})
																);
															}}
														>
															<Underline color="primary">
																{translate('account.management.show.on.map')}
															</Underline>
														</Link>
													</PaymentDetailsDesc>
												</PaymentDetails>
											)}
										/>
										<ConditionalRender
											show={!!detailsItem.paymentDate}
											onTrue={() => (
												<PaymentDetails>
													<Icon name="card" width={ICON_SIZE} height={ICON_SIZE} />
													<PaymentDetailsDesc justifyContent="flex-start">
														<PaymentDetailsTitle>
															{translate('account.management.online.payment')}
														</PaymentDetailsTitle>
														<BodyS color="black54">
															{translate(
																'account.management.payment.recieved',
																detailsItem.paymentDate ?? ''
															)}
														</BodyS>
													</PaymentDetailsDesc>
												</PaymentDetails>
											)}
										/>
									</PaymentAndDeliveryContainer>
									<OrderTracking
										orderId={orderId}
										detailsItem={detailsItem}
										type={type}
										isOrderHistoryView={isOrderHistoryView}
										status={status}
										hbbWfbbOrderDetails={hbbWfbbOrderDetails}
										isWFBB={isWFBB}
									/>
									<CartPaymentSummary
										params={{
											upfront: Number(detailsItem.upfrontPaymentSum),
											period: detailsItem.period,
											periodPrice: detailsItem.periodPaymentSum,
											items: detailsItem.items,
										}}
										card="cartPaymentSummary"
									/>
									<ConditionalRender
										show={
											type === OrderHistoryType.ORDERS_IN_PROGRESS && !DISABLE_ORDER_CANCELLATION
										}
										onTrue={() => (
											<>
												<Divider marginBottom={32} marginTop={48} />
												<CancelDescriptionWrapper>
													{translate(
														isCancellationAvailable
															? 'cancel-order.description.order.history'
															: 'cancel-order.description.unavailable-cancel.order.history',
														isB2b2c || isB2b
															? CUSTOMER_B2B2C_CARE_PHONE
															: CUSTOMER_CARE_PHONE
													)}
												</CancelDescriptionWrapper>
												<CancelButtonWrapper>
													<SecondaryMediumButtonFlex
														leftPadding={0}
														rightPadding={0}
														disabled={!isCancellationAvailable}
														uniqueId="cancel.order"
														onClick={() =>
															dispatch(
																setModal({
																	type: modalTypes.CANCEL_ORDER,
																	data: { data: orderId },
																	withoutContainer: true,
																})
															)
														}
													>
														{translate('cancel-order.button.cancel')}
													</SecondaryMediumButtonFlex>
												</CancelButtonWrapper>
											</>
										)}
									/>
								</>
							</BusyIndicator>
						</Col>
					</Row>
				)}
			/>
		</OrderItemWrapper>
	);
};

export default OrderItem;
