import immerHandleActions from '@Redux/utils/immerHandleActions';
import { clearCheckoutDetails, ICheckout, setCheckoutDetails } from './actions';

const init = {
	hbbWfbbProcessCheckout: undefined,
	prepaidProcessCheckout: undefined,
	contactDetails: undefined,
	billingAddress: undefined,
	shipmentAddress: undefined,
	shipmentSameOrDiffrent: 'same',
	checkoutTimer: undefined,
	pickup: false,
};

type draft = ICheckout['payload'];

const reducers = {
	[setCheckoutDetails.toString()](draft: draft, action: ICheckout) {
		if (!action.payload.billingAddress) {
			delete action.payload.billingAddress;
		}
		if (!action.payload.shipmentAddress) {
			delete action.payload.shipmentAddress;
		}
		if (!action.payload.contactDetails) {
			delete action.payload.contactDetails;
		}
		return { ...draft, ...action.payload };
	},
	[clearCheckoutDetails.toString()](draft: draft) {
		return { ...init, checkoutTimer: draft.checkoutTimer };
	},
};

export default immerHandleActions(reducers, init);
