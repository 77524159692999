import { ENABLE_ESHOP_LITE } from './features.config';

const storeId = { TST: '11', STG: '10001', PROD: '10001' };
const analytics = { TST: '', STG: 'UA-86583004-2', PROD: 'UA-86583004-1' };
const catalogId = { TST: '11501', STG: '3074457345616676668', PROD: '10001' };
const plansStoreId = { TST: '10001', STG: '10001', PROD: '10001' };
const plansCatalogId = { TST: '10102', STG: '10102', PROD: '10102' };
const plansCatalogIdBySearchTerm = { TST: '10102', STG: '10001', PROD: '10001' };
const carInsuranceProductId = { TST: '79510', STG: '79510', PROD: '86501' };
const domain = { TST: '', STG: 'https://testportal.omantel.om', PROD: 'https://www.omantel.om' };
const ebuDomain = {
	TST: [''],
	STG: ['http://localhost:3000', 'https://testbusiness.omantel.om'],
	PROD: ['https://business.omantel.om/'],
};
const gtmId = { TST: '', STG: 'GTM-W4B2F5R', PROD: 'GTM-MVQ4HKF' };
const pathname = window.location.href.toLocaleLowerCase();

const filtersToRemove = {
	STG: [
		'price_omr',
		'parentCatgroup_id_search',
		'attributes.7741124012283334335.value.raw',
		'attributes.7741124012283334837.value.raw',
		'attributes.7741124012283334835.value.raw',
		'manufacturer.raw',
		'attributes.7741124012283356336.value.raw',
		'facets.7741124012283356336.value.raw',
		'facets.7741124012283389835.value.raw',
		'attributes.7741124012283389835.value.raw',
		,
	],
	TST: [
		'price_omr',
		'parentCatgroup_id_search',
		'attributes.7741124012283334335.value.raw',
		'attributes.7741124012283334837.value.raw',
		'attributes.7741124012283334835.value.raw',
		'manufacturer.raw',
		'attributes.7741124012283356336.value.raw',
		'facets.7741124012283356336.value.raw',
		'facets.7741124012283389835.value.raw',
		'attributes.7741124012283389835.value.raw',
	],
	PROD: [
		'price_omr',
		'parentCatgroup_id_search',
		'attributes.7741124012283334335.value.raw',
		'attributes.7741124012283334837.value.raw',
		'attributes.7741124012283334835.value.raw',
		'manufacturer.raw',
		'attributes.7741124012283356336.value.raw',
		'attributes.7000000000000015002.value.raw',
		'facets.7000000000000015002.value.raw',
		'facets.7741124012283389835.value.raw',
		'attributes.7741124012283389835.value.raw',
	],
};

const REACT_HOST = {
	STG: 'https://testshop.omantel.om',
	TST: 'https://hcl91-react.tuatara.pl',
	PROD: 'https://store.omantel.om',
};

const queryFilters = {
	STG: {
		FIVE_G: '1',
		SONY: '83111110121',
	},
	TST: {
		FIVE_G: '1',
		SONY: '83111110121',
	},
	PROD: {
		FIVE_G: '1',
		SONY: '83111110121',
	},
};

const appleBrandStoreCategories = {
	STG: {
		IPHONE: '11603',
		APPLE_WATCH: '3074457345616700668',
		ACCESSORIES: '3074457345616706668',
		IPAD: '3074457345616707668',
	},
	TST: {
		IPHONE: '11603',
		APPLE_WATCH: '3074457345616700668',
		ACCESSORIES: '3074457345616706668',
		IPAD: '3074457345616707668',
	},
	PROD: {
		IPHONE: '10003',
		APPLE_WATCH: '25501',
		ACCESSORIES: '21001',
		IPAD: '32501',
	},
};

const HBB_PRODUCT_ID_NUMBERS = {
	TST: 36501,
	STG: 36501,
	PROD: 48001,
};

const MAKASIB_REDEEM_PRODUCT_ID = {
	TST: '3074457345616692205',
	STG: '3074457345616692205',
	PROD: '114001',
};

const MAKASIB_PAYMENT_VOUCHER_ID = {
	TST: '42004',
	STG: '42004',
	PROD: '113501',
};

const WFBB_PRODUCT_ID_NUMBERS = {
	TST: 45501,
	STG: 45501,
	PROD: 48003,
};

const HAYYAK_PRODUCT_ID_NUMBERS = {
	TST: '53001',
	STG: '53001',
	PROD: '67001',
};

const FORMS_CATEGORY_CONFIG = {
	TST: '3074457345616716168',
	STG: '3074457345616716168',
	PROD: '42502',
};

const AL_UFUQ_SKU_ID_NUMBERS = {
	TST: ['OM_14196', '14196', '1201030'],
	STG: ['OM_14196', '14196', '1201030'],
	PROD: ['OM_14196', '14196', '1201030'],
};

const ADDRESS_DROPDOWN_CMPNTID_CONFIG = {
	TST: {
		CITIES_CMPNTID: '3453fed5-163f-4877-a9f3-005035847d4b',
		CITIES_CMPNTID_2023: 'd72a0a52-2f31-40ed-be2d-0559961f6ae0',
		GOVERNORATES_CMPNTID: '3fee9688-5b6b-4855-823e-c061901a7911',
		GOVERNORATES_CMPNTID_2023: '8ab193a1-b981-476a-94d4-a1b684ec062a',
	},
	STG: {
		CITIES_CMPNTID: '3453fed5-163f-4877-a9f3-005035847d4b',
		CITIES_CMPNTID_2023: 'd72a0a52-2f31-40ed-be2d-0559961f6ae0',
		GOVERNORATES_CMPNTID: '3fee9688-5b6b-4855-823e-c061901a7911',
		GOVERNORATES_CMPNTID_2023: '8ab193a1-b981-476a-94d4-a1b684ec062a',
	},
	PROD: {
		CITIES_CMPNTID: '3453fed5-163f-4877-a9f3-005035847d4b',
		CITIES_CMPNTID_2023: 'b7b0747d-c660-4c14-91a5-82717446981a',
		GOVERNORATES_CMPNTID: '3fee9688-5b6b-4855-823e-c061901a7911',
		GOVERNORATES_CMPNTID_2023: '1262863b-ea9b-496e-89b4-261009a5f14c',
	},
};

export const DESTINATION = process.env.REACT_APP_DESTINATION;
export const ENV: types.apiConfig.envKey = (process.env.REACT_APP_ENV ?? 'STG') as types.apiConfig.envKey;
export const USER_KEY = 'USER_KEY';
export const CUSTOMER_CARE_PHONE = '1234';
export const CUSTOMER_B2B2C_CARE_PHONE = '1235';
export const OMANTEL_PHONE_NUMBER_PREFIX = '+968';
export const MS_EMAIL = 'Microsoft.ICT@Omantel.om';
export const CUSTOMER_CARE_WHATSAPP = '+968 72700700';
export const STORE_ID = storeId[ENV] as types.apiConfig.envKey;
export const CURRENCY = (process.env.CURRENCY ?? 'OMR') as types.apiConfig.envKey;
export const CONTRACT_ID = (process.env.CONTRACT_ID ?? '-11005') as types.apiConfig.envKey;
export const CATALOG_ID = catalogId[ENV] as types.apiConfig.envKey;
export const GTM_ID = gtmId[ENV];
export const PAGE_SIZE = (process.env.PAGE_SIZE ?? '30') as types.apiConfig.envKey;
export const STORE_PAGE_SIZE = ENABLE_ESHOP_LITE ? '6' : '15';
export const DEFAULT_PAGE_SIZE = '50';
export const PLAN_PAGE_SIZE = '999' as types.apiConfig.envKey;
export const PLANS_STORE_ID = plansStoreId[ENV] as types.apiConfig.envKey;
export const PLANS_CATALOG_ID = plansCatalogId[ENV] as types.apiConfig.envKey;
export const PLANS_CATALOG_ID_BY_SEARCH_TERM = plansCatalogIdBySearchTerm[ENV] as types.apiConfig.envKey;
export const CLEAR_CHECKOUT_TIMEOUT = 1800000;
export const FILTERS_TO_REMOVE = filtersToRemove[ENV];
export const APPLE_BRAND_STORE_CATEGORIES = appleBrandStoreCategories[ENV];
export const ANALYTICS = analytics[ENV] as types.apiConfig.envKey;
export const DOMAIN = domain[ENV];
export const B2B_CATEGORIES = ['1', '2', '3', '4'];
export const B2B2C_CATEGORIES = ['23'];
export const DOCUMENT_TYPE_FOR_EBU_USER = ['105', '106', '107', '108', '109', '209'];
export const EBU_DOMAIN = ebuDomain[ENV];
export const STORE_QUERY_FILTERS = queryFilters[ENV];
export const HBB_PRODUCT_ID = HBB_PRODUCT_ID_NUMBERS[ENV];
export const WFBB_PRODUCT_ID = WFBB_PRODUCT_ID_NUMBERS[ENV];
export const MAKASIB_POINTS_PRODUCT_ID = MAKASIB_REDEEM_PRODUCT_ID[ENV];
export const MAKASIB_VOUCHER_PRODUCT_ID = MAKASIB_PAYMENT_VOUCHER_ID[ENV];
export const HAYYAK_PRODUCT_ID = HAYYAK_PRODUCT_ID_NUMBERS[ENV];
export const FORMS_CONFIG_CATEGORY_ID = FORMS_CATEGORY_CONFIG[ENV];
export const ESHOP_MARKETING_TAG = 'ESHOP_MARKETING_TAG';
export const DEVICE_LABEL_TYPE = 'DEVICE_LABEL_TYPE';
export const COMMITMENT_BAQATI_CONFIG = 'COMMITMENT_BAQATI_CONFIG';
export const ENABLE_CREDIT_RATING_LOGIC_GN_NGN = 'ENABLE_CREDIT_RATING_LOGIC_GN_NGN';
export const EBU_PLAN_ACTIONS = [
	'B2B_WITHOUT_VOUCHER_INSTALL',
	'B2B_WITH_VOUCHER_INSTALL',
	'B2B_WITHOUT_VOUCHER_UPFRONT',
	'B2B_WITH_VOUCHER_UPFRONT',
	'B2B2C_WITHOUT_VOUCHER_INSTALL',
	'B2B2C_WITH_VOUCHER_INSTALL',
	'B2B2C_WITHOUT_VOUCHER_UPFRONT',
	'B2B2C_WITH_VOUCHER_UPFRONT',
];
export const ICON_SIZE = 32;
export const iFramePath = '/apple-brand-store#iframeapplewatch';
export const isIFrameAppleWatch = pathname.includes(iFramePath);
export const iFrameHost = isIFrameAppleWatch ? REACT_HOST[ENV] : '';
export const INSURANCE_REQUEST_TYPE = ['BUY_CAR_INSURANCE_BIMA', 'BUY_CAR_INSURANCE_NLG'];
export const FIRST_STEPPER_STEP = 0;
export const SECOND_STEPPER_STEP = 1;
export const THIRD_STEPPER_STEP = 2;
export const LAST_STEPPER_STEP = 3;
export const NUMBER_OF_STEPS = 4;
export const CAR_INSURANCE_API_TIMEOUT = 45000;
export const CAR_INSURANCE_PRODUCT_ID = carInsuranceProductId[ENV] as types.apiConfig.envKey;
export const VEHICLE_MAX_VALUE = '99999';
export const POSTPAID_PLAN = 'BUY_SIM';
export const AL_UFUQ_SKU_TO_REMOVE = AL_UFUQ_SKU_ID_NUMBERS[ENV];
export const JAWWY_PRODUCT_PARTNUMBER_ID_PART = 'JAWWY_TV_HOME';
export const ADDRESS_DROPDOWN_CMPNTID = ADDRESS_DROPDOWN_CMPNTID_CONFIG[ENV];
export const emfConfigId = {
	POSTPAID: '13',
	HBB: '331',
	WFBB: '351',
};
export const BAQATI_PLAN_GROUP = ['BAQATI_DATA_CONTRACT', 'BAQATI_DATA_CONTRACT_DUMMY', 'BAQATI_COMMITMENT'];
export const MenusToRemoveForCBU: string[] = {
	TST: [
		'3074457345616722668', // Business Device
	],
	STG: [
		'3074457345616722668', // Business Device
	],
	PROD: ['57501'],
}[ENV];
export enum CreditRating {
	DEFAULT = 'Default',
	RED = 'Red',
	YELLOW = 'Yellow',
	GREEN = 'Green',
	BLUE = 'Blue',
	BLACKLIST = 'BlackList',
}
