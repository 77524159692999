import styled from 'styled-components';
import { HeadlineBold, BodyM } from '@Components/Typography/Typography.styled';
import { matchScreen } from '@Utils/layout/layoutSupport';

export interface ISizes {
	isL?: boolean;
	isMd?: boolean;
	isSm?: boolean;
	isXs?: boolean;
}

export const ExistingAccountPromptWrapper = styled.div<{ isBIActive: boolean; sizes?: ISizes }>`
	${({ isBIActive, sizes }) => ({
		padding: sizes?.isL
			? '48px 80px'
			: sizes?.isMd
			? '48px 90px'
			: sizes?.isSm
			? '48px 60px'
			: sizes?.isXs
			? '32px 16px'
			: '48px 168.5px 56px 168.5px',
		textAlign: isBIActive ? 'center' : 'left',
	})}
`;

export const Title = styled(HeadlineBold)`
	${({ theme }) => ({
		color: theme.colors.black87,
	})}
	padding-bottom: 12px;
`;

export const RadioWrapper = styled.div`
	display: flex;
	flex: 1;
	flex-direction: column;
	padding: 12px 0;
`;

export const FormWrapper = styled.div`
	${() =>
		matchScreen('xs') && {
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'left',
		}}
`;

export const IconWrapper = styled.div`
	margin-right: 16px;
`;

export const InfoTitleWrapper = styled(BodyM)`
	${({ theme }) => {
		return {
			color: theme.colors.black54,
		};
	}}
`;

export const InfoWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 16px;
	padding: 8px 16px;
	border-radius: 4px;
	${({ theme }) => {
		return {
			backgroundColor: theme.colors.black6,
		};
	}}
`;
