import { Action, ActionFunction0, ActionFunction1, createAction } from 'redux-actions';

export interface IAddToCompareAction {
	payload: { thumbnailUrl: string; id: string };
}

export interface IRemoveFromCompareAction {
	payload: { id: string };
}

export const addToCompare: ActionFunction1<
	IAddToCompareAction['payload'],
	Action<IAddToCompareAction['payload']>
> = createAction('ADD_TO_COMPARE', (action: IAddToCompareAction['payload']) => action);

export const removeFromCompare: ActionFunction1<
	IRemoveFromCompareAction['payload'],
	Action<IRemoveFromCompareAction['payload']>
> = createAction('REMOVE_FROM_COMPARE', (action: IRemoveFromCompareAction['payload']) => action);

export const updateThumbnail: ActionFunction1<
	IAddToCompareAction['payload'],
	Action<IAddToCompareAction['payload']>
> = createAction('UPDATE_THUMBNAIL', (action: IAddToCompareAction['payload']) => action);

export const clearComparison: ActionFunction0<Action<[]>> = createAction('CLEAR_COMPARISON', () => []);
