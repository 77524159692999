import styled from 'styled-components';
import { Link } from 'react-router-dom';

interface IItemProps {
	typography: keyof types.ITypography;
	themeColor?: types.theme.themeColors;
}

interface IItemWrapperProps {
	marginBottom?: number;
	justifyContent: types.justifyContent;
}

export const LinkItem = styled(Link)<IItemProps>`
	display: block;
	text-decoration: none;
	${({ theme, typography, themeColor }) => ({
		...theme.typography[typography],
		color: theme.colors[themeColor || 'white'],
	})}
`;

export const TextItem = styled.p<IItemProps>`
	${({ theme, typography, themeColor }) => ({
		...theme.typography[typography],
		color: theme.colors[themeColor || 'white'],
	})}
	margin:0;
`;

export const NumberItem = styled.p<IItemProps>`
	${({ theme, typography, themeColor }) => ({
		...theme.typography[typography],
		color: theme.colors[themeColor || 'white'],
	})}
	margin:0;
`;

export const ItemWrapper = styled.div<IItemWrapperProps>`
	${({ marginBottom }) => !!marginBottom && { marginBottom }}
	${({ justifyContent }) => ({ justifyContent })}
	display: flex;
	align-items: center;
	gap: 8px;
`;
