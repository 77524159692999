import { STORE_ID, CURRENCY } from '@Config/app.config';
import { DISABLE_ORDER_CANCELLATION } from '@Config/features.config';
import { IMoveOrderPayload } from '@Services/orderFlow/orderFlow';
import { getLanguageCode } from '@Utils/language/language';

export const guestLogIn = (): types.endpoints.IEndpointConfig => {
	return {
		method: 'POST',
		url: `/wcs/resources/store/${STORE_ID}/guestidentity`,
	};
};

export const guestLogOut = (): types.endpoints.IEndpointConfig => {
	return {
		method: 'DELETE',
		url: `/wcs/resources/store/${STORE_ID}/guestidentity/@self`,
	};
};

export const userLogIn = (): types.endpoints.IEndpointConfig => {
	return {
		method: 'POST',
		url: `/wcs/resources/store/${STORE_ID}/loginidentity`,
	};
};

export const userLogOut = (): types.endpoints.IEndpointConfig => {
	return {
		method: 'DELETE',
		url: `/wcs/resources/store/${STORE_ID}/loginidentity/@self`,
	};
};

export const viewCart = (): types.endpoints.IEndpointConfig => {
	return {
		method: 'GET',
		url: `/wcs/resources/store/${STORE_ID}/cart/@self?currency=${CURRENCY}&sortOrderItemBy=orderItemID&langId=${getLanguageCode()}`,
	};
};

export const addItemsToCart = (): types.endpoints.IEndpointConfig => {
	return {
		method: 'POST',
		url: `/wcs/resources/store/${STORE_ID}/cart?langId=${getLanguageCode()}`,
	};
};

export const clearAllCartItems = (): types.endpoints.IEndpointConfig => {
	return {
		method: 'DELETE',
		url: `/wcs/resources/store/${STORE_ID}/cart/@self`,
	};
};

export const removeSingleCartItem = (): types.endpoints.IEndpointConfig => {
	return {
		method: 'PUT',
		url: `/wcs/resources/store/${STORE_ID}/cart/@self/delete_order_item`,
	};
};

export const updateSingleCartItem = (): types.endpoints.IEndpointConfig => {
	return {
		method: 'PUT',
		url: `/wcs/resources/store/${STORE_ID}/cart/@self/update_order_item?langId=${getLanguageCode()}`,
	};
};

export const getOrderHistory = ({
	pageNumber,
	pageSize,
}: {
	pageNumber: number;
	pageSize: number;
}): types.endpoints.IEndpointConfig => {
	return {
		method: 'GET',
		url: `/wcs/resources/store/${STORE_ID}/order/byStatus/C,I${
			!DISABLE_ORDER_CANCELLATION ? ',X,R' : ',R'
		},S,K,D,F,G,H,J,K?pageNumber=${pageNumber}&pageSize=${pageSize}&langId=${getLanguageCode()}`,
	};
};

export const getInstallmentAmount = (): types.endpoints.IEndpointConfig => {
	return {
		method: 'POST',
		url: `wcs/resources/store/${STORE_ID}/installmentAmount/getInstallmentAmount`,
	};
};

export const getBuyNowPayLaterInstallmentAmount = (): types.endpoints.IEndpointConfig => {
	return {
		method: 'POST',
		url: `wcs/resources/store/${STORE_ID}/bnpl/calculate`,
	};
};

export const getOrderDetails = ({ orderId }: { orderId: string }): types.endpoints.IEndpointConfig => {
	return {
		method: 'GET',
		url: `/wcs/resources/store/${STORE_ID}/order/${orderId}?langId=${getLanguageCode()}`,
	};
};

export const getOrderMSKeys = ({ orderId }: { orderId: string }): types.endpoints.IEndpointConfig => {
	return {
		method: 'GET',
		url: `/wcs/resources/store/${STORE_ID}/ms/orderId/${orderId}`,
	};
};

export const validateMakasibOtp = ({ orderId }: { orderId: string }): types.endpoints.IEndpointConfig => {
	return {
		method: 'POST',
		url: `/wcs/resources/store/${STORE_ID}/makasib/validateOtp/${orderId}`,
	};
};

export const getItemMSKey = ({ itemId }: { itemId: string }): types.endpoints.IEndpointConfig => {
	return {
		method: 'GET',
		url: `/wcs/resources/store/${STORE_ID}/ms/orderItemId/${itemId}`,
	};
};

export const validateOffer = (): types.endpoints.IEndpointConfig => {
	return {
		method: 'POST',
		url: `/wcs/resources/store/${STORE_ID}/tariffValidate?langId=${getLanguageCode()}`,
	};
};

export const validateEbuOffer = (): types.endpoints.IEndpointConfig => {
	return {
		method: 'POST',
		url: `/wcs/resources/store/${STORE_ID}/ebu/tariffValidate?langId=${getLanguageCode()}`,
	};
};

export const cancelOrder = ({ orderId }: { orderId: string }): types.endpoints.IEndpointConfig => {
	return {
		method: 'PUT',
		url: `/wcs/resources/store/${STORE_ID}/ordercancel/orderId/${orderId}`,
	};
};

export const refundPaymentLog = ({ orderId }: { orderId: string }): types.endpoints.IEndpointConfig => {
	return {
		method: 'POST',
		url: `/wcs/resources/store/${STORE_ID}/ordercancel/orderId/${orderId}/paymentRefund`,
	};
};

export const getPaymentInformation = (): types.endpoints.IEndpointConfig => {
	return {
		method: 'GET',
		url: `/wcs/resources/store/${STORE_ID}/cart/@self/usable_payment_info`,
	};
};

export const doPreCheckout = (): types.endpoints.IEndpointConfig => {
	return {
		method: 'PUT',
		url: `/wcs/resources/store/${STORE_ID}/cart/@self/precheckout?langId=${getLanguageCode()}`,
	};
};

export const doCheckout = (): types.endpoints.IEndpointConfig => {
	return {
		method: 'POST',
		url: `/wcs/resources//store/${STORE_ID}/cart/@self/checkout?langId=${getLanguageCode()}`,
	};
};

export const processOrder = (): types.endpoints.IEndpointConfig => {
	return {
		method: 'POST',
		url: `/wcs/resources/store/${STORE_ID}/cybersource`,
	};
};

export const chooseARAMEXShipment = (): types.endpoints.IEndpointConfig => {
	return {
		method: 'PUT',
		url: `/wcs/resources/store/${STORE_ID}/cart/@self/shipping_info?langId=${getLanguageCode()}`,
	};
};

export const chooseCybersourcePayment = (): types.endpoints.IEndpointConfig => {
	return {
		method: 'POST',
		url: `/wcs/resources/store/${STORE_ID}/cart/@self/payment_instruction?langId=${getLanguageCode()}`,
	};
};

export const deleteAllPaymentInstructions = (): types.endpoints.IEndpointConfig => {
	return {
		method: 'DELETE',
		url: `/wcs/resources/store/${STORE_ID}/cart/@self/payment_instruction`,
	};
};

export const createNotificationRequest = (): types.endpoints.IEndpointConfig => {
	return {
		method: 'POST',
		url: `/wcs/resources/store/${STORE_ID}/createNotificationRequest`,
	};
};

export const getOrderTracking = ({ orderId }: types.payloads.IGetOrderTracking): types.endpoints.IEndpointConfig => ({
	method: 'GET',
	url: `/wcs/resources/store/${STORE_ID}/tracking/${orderId}`,
});

export const setHbbOrder = (): types.endpoints.IEndpointConfig => ({
	method: 'POST',
	url: `/wcs/resources/store/${STORE_ID}/accountorder`,
});

export const setWfbbOrder = (): types.endpoints.IEndpointConfig => ({
	method: 'POST',
	url: `/wcs/resources/store/${STORE_ID}/accountorder/wfbb`,
});

export const otpValidateDocumentId = (orderId: string): types.endpoints.IEndpointConfig => ({
	method: 'POST',
	url: `/wcs/resources/store/${STORE_ID}/telesales/validateStepOne/${orderId}`,
});

export const otpValidateSmsPassword = (orderId: string): types.endpoints.IEndpointConfig => ({
	method: 'POST',
	url: `/wcs/resources/store/${STORE_ID}/telesales/validateStepTwo/${orderId}`,
});

export const moveOrder = ({
	orderId,
}: Omit<IMoveOrderPayload, 'phoneNumber' | 'email'>): types.endpoints.IEndpointConfig => ({
	method: 'POST',
	url: `/wcs/resources/store/${STORE_ID}/telesales/transform/${orderId}?langId=${getLanguageCode()}`,
});

export const getMakasibPointsCalculation = (): types.endpoints.IEndpointConfig => ({
	method: 'GET',
	url: `/wcm/myconnect/mobileapp_en/mobile_icons?srv=cmpnt&cmpntname=makasibPointsConversion&source=library`,
});
