import { store } from '@Redux/store';

export const isUserLoggedIn = (): boolean => {
	const wcTokenExists = store?.getState()?.api?.headers.WCToken;
	const wcTrustedTokenExists = store?.getState()?.api?.headers.WCTrustedToken;
	return !!wcTokenExists && !!wcTrustedTokenExists;
};

export const getHeaders = (): types.redux.api.headers => {
	return store?.getState()?.api?.headers;
};

export const getHeadersWithoutTokens = (): types.redux.api.headers => {
	const headers = { ...store?.getState()?.api?.headers };
	delete headers.WCToken;
	delete headers.WCTrustedToken;
	delete headers.WCPersonalization;
	return headers;
};
