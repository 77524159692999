import React, { FunctionComponent } from 'react';
import { CalendarDayWrapper, DayOfWeek, DayOfMonth } from './DayItem.styled';

export interface IDayItemProps {
	state: string;
	selected: boolean;
	today: boolean;
}
interface IDayItemExtendedProps extends IDayItemProps {
	date: string;
	dayOfMonth: string;
	dayOfWeek: string;
	onDayClick: (selectedDay: string) => void;
}

const DayItem: FunctionComponent<IDayItemExtendedProps> = ({
	date,
	state,
	selected,
	today,
	dayOfMonth,
	dayOfWeek,
	onDayClick,
}) => {
	return (
		<CalendarDayWrapper selected={selected} today={today} state={state} onClick={() => onDayClick(date)}>
			<DayOfWeek selected={selected} today={today} state={state}>
				{dayOfWeek}
			</DayOfWeek>
			<DayOfMonth selected={selected} today={today} state={state}>
				{dayOfMonth}
			</DayOfMonth>
		</CalendarDayWrapper>
	);
};

export default DayItem;
