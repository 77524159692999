import { BodyM, BodyMBold, BodySBold, Title, ParagraphS } from '@Components/Typography/Typography.styled';
import { isRTL } from '@Utils/layout/layoutSupport';
import styled, { CSSProperties } from 'styled-components';

export const OrderItemWrapper = styled.div`
	padding: 16px;
	border-radius: 8px;
	${({ theme }) => ({
		backgroundColor: theme.colors.grey50,
	})}
`;

export const IcoWrapper = styled.div`
	position: absolute;
`;

export const Status = styled.div`
	margin-top: 4px;
`;

export const StatusWrapper = styled.div`
	padding-top: 8px;
	${() =>
		isRTL()
			? {
					float: 'left',
			  }
			: {
					float: 'right',
			  }}
`;

export const Horizontal = styled.div`
	display: flex;
	gap: 8px;
`;

export const PaymentDetails = styled.div`
	display: flex;
	gap: 18px;
	width: 100%;
`;

export const WaitingWrapper = styled.div`
	padding: 16px 0;
	display: flex;
	background-color: ${({ theme }) => theme.colors.black6}};
	flex-direction: column;
	margin-right: 38px;
	align-items: center;
	gap: 16px;
	margin-bottom: 64px;
	border-radius: 8px;
`;

export const WaitingTitleWrapper = styled(Title)`
	${({ theme }) => ({ color: theme.colors.black87 })}
	text-align: center;
`;
export const WaitingDescriptionContainer = styled.div`
	display: flex;
	flex-direction: column;
	text-align: center;
	gap: 14px;
	text-align: center;
	padding: 0 32px;
`;
export const WaitingDescriptionWrapper = styled(BodyM)`
	${({ theme }) => ({ color: theme.colors.black54 })}
`;

export const PaymentDetailsDesc = styled.div<{ justifyContent?: CSSProperties['justifyContent'] }>`
	display: flex;
	flex-direction: column;
	${({ justifyContent = 'center' }) => ({ justifyContent })};
`;

export const Link = styled.div`
	cursor: pointer;
	width: fit-content;
	p {
		display: inline;
	}
`;

export const Underline = styled(BodySBold)`
	text-decoration: underline;
`;

export const PaymentDetailsTitle = styled(BodyMBold)`
	${({ theme }) => ({
		color: theme.colors.black87,
	})};
	margin-bottom: 4px;
`;

export const PaymentAndDeliveryContainer = styled.div`
	display: flex;
	margin-bottom: 64px;
`;

export const CancelDescriptionWrapper = styled(ParagraphS)`
	${({ theme }) => ({
		color: theme.colors.black54,
	})};
`;

export const CancelButtonWrapper = styled.div`
	margin-top: 24px;
	width: 130px;
`;

export const CanceledOrderInfoWrapper = styled.div`
	margin-top: 32px;
	${({ theme }) => ({
		backgroundColor: theme.colors.accent6,
	})};
	padding: 16px;
	border-radius: 8px;
`;

export const CanceledOrderInfoLabelWrapper = styled.div`
	margin-top: 10px;
`;
