import styled from 'styled-components';

export const Input = styled.input<{ error?: boolean; disabled?: boolean; isXS: boolean }>`
	text-align: center;
	width: 64px;
	padding: 8px;
	border-radius: 4px;

	${({ isXS }) => ({
		width: isXS ? 38 : 64,
		height: isXS ? 55 : 64,
	})}

	${({ theme, error, disabled }) => ({
		border: `1px solid ${theme.colors[disabled ? 'black12' : error ? 'warning' : 'black54']}`,
		backgroundColor: `${theme.colors[disabled ? 'black6' : 'white']}`,
		color: theme.colors[disabled ? 'black38' : 'black87'],
		...theme.typography.displayMBold,
	})}

	${({ theme, disabled }) =>
		!disabled &&
		`
	&:hover {
		border: 1px solid ${theme.colors.primary};
	};
	&:active {
		border: 1px solid ${theme.colors.primary};
	}
	&:focus {
		outline: none !important;
		border: 1px solid ${theme.colors.primary};
	}`}
`;
