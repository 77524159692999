export interface IExtractedGISData {
	governorate: string;
	wilayat: string;
	town: string;
	buildingNo: string;
	wayNo: string;
	exchange: string;
	cabinetStr: string;
	dp: string;
	obcTag: string;
	obcOlt: string;
	obcFDHnumber: string;
	olt: string;
	fdh: string;
	mdu: string;
	ap: string;
	ftp: string;
	bsm: string;
	lat: string;
	long: string;
	north: string;
	east: string;
	itdd: boolean;
	otdd: boolean;
	wfbb4GIn: boolean;
	wfbb4GOut: boolean;
	wfbb3G: boolean;
	itdd5G: boolean;
	otdd5G: boolean;
	flag5G: boolean;
	flag4G: boolean;
	flag3G: boolean;
	flag2G: boolean;
	remarks: string;
	status: string;
	networkType: string;
	adslSpeed: string;
	obcFdhStr: string;
	feasibilityRemarks: string;
	obcFlag: string;
	lblGovernarateValue: HTMLElement | null;
	lblWilayatValue: HTMLElement | null;
	lblTownValue: HTMLElement | null;
	txtbldgNo: HTMLElement | null;
	txtWayNo: HTMLElement | null;
}

export type WfbbTypes = 'ITDD5G' | 'OTDD5G' | 'WFBB4GOUT' | 'ITDD' | 'OTDD' | 'WFBB4GIN';

const getObjValueByName = function (str: string, objName: string) {
	const res = str.split(',');
	let returnStr = '';
	for (const value of res) {
		const splitStr = value.split('=');
		const tagNameTmp = splitStr[0];
		if (objName === tagNameTmp.trim()) {
			returnStr = splitStr[1].trim();
		}
	}
	return returnStr;
};

function isPositive(value: string) {
	return ['yes'].includes(value.toLowerCase());
}

function convertToBoolean(value: string) {
	return isPositive(value);
}

export default function customerDetailsFromGIS(detailsString: string): IExtractedGISData | undefined {
	try {
		return {
			governorate: getObjValueByName(detailsString, 'Governorate'),
			wilayat: getObjValueByName(detailsString, 'Wilayat'),
			town: getObjValueByName(detailsString, 'Town'),
			buildingNo: getObjValueByName(detailsString, 'BuildingNo'),
			wayNo: getObjValueByName(detailsString, 'WayNo'),
			exchange: getObjValueByName(detailsString, 'exchange'),
			cabinetStr: getObjValueByName(detailsString, 'cabinet'),
			dp: getObjValueByName(detailsString, 'DP'),
			obcTag: getObjValueByName(detailsString, 'OBCTAGNUMBER'),
			obcOlt: getObjValueByName(detailsString, 'OBCOLT'),
			obcFDHnumber: getObjValueByName(detailsString, 'OBCFDHNUMBER'),
			olt: getObjValueByName(detailsString, 'OLT'),
			fdh: getObjValueByName(detailsString, 'FDH'),
			mdu: getObjValueByName(detailsString, 'MDU'),
			ap: getObjValueByName(detailsString, 'AP'),
			ftp: getObjValueByName(detailsString, 'FTP'),
			bsm: getObjValueByName(detailsString, 'MSAN'),
			lat: getObjValueByName(detailsString, 'LAT'),
			long: getObjValueByName(detailsString, 'LONG'),
			north: getObjValueByName(detailsString, 'NORTH'),
			east: getObjValueByName(detailsString, 'EAST'),
			itdd: convertToBoolean(getObjValueByName(detailsString, 'ITDD')),
			otdd: convertToBoolean(getObjValueByName(detailsString, 'OTDD')),
			wfbb4GIn: convertToBoolean(getObjValueByName(detailsString, 'WFBB4GIn')),
			wfbb4GOut: convertToBoolean(getObjValueByName(detailsString, 'WFBB4GOut')),
			wfbb3G: convertToBoolean(getObjValueByName(detailsString, 'WFBB3G')),
			itdd5G: convertToBoolean(getObjValueByName(detailsString, '5GITDD')),
			otdd5G: convertToBoolean(getObjValueByName(detailsString, '5GOTDD')),
			flag5G: convertToBoolean(getObjValueByName(detailsString, '5G')),
			flag4G: convertToBoolean(getObjValueByName(detailsString, '4G')),
			flag3G: convertToBoolean(getObjValueByName(detailsString, '3G')),
			flag2G: convertToBoolean(getObjValueByName(detailsString, '2G')),
			remarks: getObjValueByName(detailsString, 'remarks'),
			status: getObjValueByName(detailsString, 'status'),
			networkType: getObjValueByName(detailsString, 'networktype'),
			adslSpeed: getObjValueByName(detailsString, 'ADSLSPEED'),
			obcFdhStr: getObjValueByName(detailsString, 'OBCFDH'),
			feasibilityRemarks: getObjValueByName(detailsString, 'feasibilityRemarks'),
			obcFlag: 'N',
			lblGovernarateValue: document.getElementById('lblGovernarateValue'),
			lblWilayatValue: document.getElementById('lblWilayatValue'),
			lblTownValue: document.getElementById('lblTownValue'),
			txtbldgNo: document.getElementById('txtbldgNo'),
			txtWayNo: document.getElementById('txtWayNo'),
		};
	} catch (e) {}
}

export const getType = (extractedData: IExtractedGISData): WfbbTypes | undefined => {
	if (extractedData.itdd5G) {
		return 'ITDD5G';
	}
	if (extractedData.otdd5G) {
		return 'OTDD5G';
	}
	if (extractedData.itdd) {
		return 'ITDD';
	}
	if (extractedData.otdd) {
		return 'OTDD';
	}
	if (extractedData.wfbb4GIn) {
		return 'WFBB4GIN';
	}
	if (extractedData.wfbb4GOut) {
		return 'WFBB4GOUT';
	}
};
