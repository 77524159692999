import styled from 'styled-components';

export const StatusModalContainer = styled.div<{ isXS: boolean }>`
	position: absolute;
	z-index: 4;
	${({ theme }) => ({
		backgroundColor: theme.colors.white,
	})};
	${({ isXS }) =>
		isXS
			? {
					left: '50%',
					bottom: 0,
					transform: 'translate(-50%, -50%)',
					zIndex: 5,
					borderRadius: 8,
					padding: 16,
					width: 'calc(100% - 112px)',
			  }
			: {
					height: '100%',
					top: 0,
					right: 0,
					padding: 40,
					width: 520,
					minWidth: '40%',
			  }}
`;

export const HeaderContainer = styled.div<{ isXS: boolean }>`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	${({ isXS }) => ({
		height: isXS ? 40 : 96,
	})}
`;

export const IconContainer = styled.div`
	display: flex;
	align-items: center;
`;

export const IconButton = styled.div`
	cursor: pointer;
	padding: 8px;
`;

export const ContentContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	margin-top: 24px;
	height: calc(100% - 200px);
`;

export const ContentTopContainer = styled.div<{ isXS: boolean }>`
	display: flex;
	flex-direction: column;
	align-items: center;
	${({ isXS }) => ({
		marginBottom: isXS ? 40 : 0,
	})}
`;

export const ButtonsContainer = styled.div`
	width: 100%;
`;
