import { HeadlineBold } from '@Components/Typography/Typography.styled';
import styled from 'styled-components';

export interface IContainerProps {
	marginTop?: number;
	marginBottom?: number;
}

export const TitleContainer = styled.div<IContainerProps>`
	display: flex;
	align-items: center;
	gap: 24px;
	${({ marginTop, marginBottom }) => ({
		marginTop,
		marginBottom,
	})};
	${HeadlineBold} {
		white-space: nowrap;
	}
`;

export const TitleWrapper = styled(HeadlineBold)`
	${({ theme }) => ({
		color: theme.colors.black87,
	})}
`;
