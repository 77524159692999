export interface ICreateNotificationResponse {
	isSuccess: boolean;
}

const model: types.models.IInputModel = {
	_resultCode: 'response.resultCode',
	isSuccess: (model: any) => {
		return model._resultCode === '0';
	},
};

export default model;
