import { AddressType, IAddress } from '@ApiModels/commerceProfile';
import { DEFAULT_MAP_POSITION } from '@Components/Map/Map';
import { setActiveAddressBook, setNewAddressBook, setRefreshAddressBook } from '@Redux/modules/addressBook/actions';
import { addBusyIndicator, removeBusyIndicator } from '@Redux/modules/busyIndicator/actions';
import { setCheckoutDetails } from '@Redux/modules/checkout/actions';
import { modalTypes, setModal } from '@Redux/modules/modal/actions';
import { dispatch } from '@Redux/store';
import { AddressesService, ICities } from '@Services/addresses/addressesService';
import { CommerceProfileService, IUpdateProfilePayloadInput } from '@Services/profile/commerceProfileService';
import useTranslate from '@Utils/hooks/useTranslate';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import useCommerceProfile from './useCommerceProfile';

export const GET_ADDRESS_BOOK_BI = 'GET_ADDRESS_BOOK_BI';

interface IUseAddressBook {
	getTitle: (address: IAddress) => string;
	getUserAddresses: () => void;
	removeAddress: (nickName: string) => void;
	editAddress: (address: IAddress, isIncorrectArea?: boolean) => void;
	updateShippingAddress: (address: IAddress | undefined) => void;
	addNewAddress: () => void;
	addNewAddressWithoutModal: (address: IAddress) => void;
	addressBook: IAddress[];
	selectedAddress: IAddress | undefined;
}

const useAddressBook = (): IUseAddressBook => {
	const { translate } = useTranslate();
	const [addressBook, setAddressBook] = useState<IAddress[]>([]);
	const [selectedAddress, setSelectedAddress] = useState<IAddress | undefined>();
	const refreshAddressBook = useSelector((state: types.redux.IState) => state.addressBook.refreshAddressBook);
	const savedAddress = useSelector((state: types.redux.IState) => state.checkout.shipmentAddress);
	const newAddressBook = useSelector((state: types.redux.IState) => state.addressBook.newAddressBook);
	const activeAddressBook = useSelector((state: types.redux.IState) => state.addressBook.activeAddressBook);
	const [cities, setCities] = useState<ICities[]>([]);
	const { contactDetails } = useCommerceProfile();

	const _setSelectedAddresTmp = (filter: string) => {
		const data = addressBook.filter((a) => a.addressId === filter);
		if (data.length > 0) {
			setSelectedAddress(data[0]);
		}
	};

	const findZipCode = (city: string): string => {
		if (city) {
			const findedCity = cities.find(({ cities }) => cities.find(({ key }) => key === city));
			if (findedCity) {
				const cityToSelect = findedCity.cities.find(({ key }) => key === city);
				return cityToSelect?.zip_code ?? '';
			}
		}
		return '';
	};

	const getUserAddresses = () => {
		dispatch(addBusyIndicator(GET_ADDRESS_BOOK_BI));
		CommerceProfileService.getUserProfile().subscribe(
			({ addresses }) => {
				const filtered = addresses.filter((a) => a.addressType !== 'ShippingAndBilling');
				setAddressBook(filtered ?? []);
				if (filtered.length === 1) {
					setSelectedAddress(filtered[0]);
				} else if (newAddressBook) {
					_setSelectedAddresTmp(newAddressBook);
				} else if (activeAddressBook) {
					_setSelectedAddresTmp(activeAddressBook);
				}
			},
			() => {
				setAddressBook([]);
			},
			() => {
				dispatch(removeBusyIndicator(GET_ADDRESS_BOOK_BI));
			}
		);
	};

	useEffect(() => {
		if (!cities || cities.length === 0) {
			AddressesService.getCities().subscribe((response) => {
				setCities(response);
			});
		}
	}, []);

	useEffect(() => {
		getUserAddresses();
	}, [refreshAddressBook]);

	const fillAddress = (_address: IAddress | undefined): IAddress => {
		return {
			organization: _address?.organization ?? '',
			governorate: _address?.governorate ?? '',
			wilayat: _address?.wilayat ?? '',
			city: _address?.city ?? '',
			way: _address?.way ?? '',
			house: _address?.house ?? '',
			apartment: _address?.apartment ?? '',
			landmark: _address?.landmark ?? '',
			addressId: _address?.addressId ?? '',
			markerPosition: {
				lat: _address?.markerPosition?.lat ?? DEFAULT_MAP_POSITION.lat,
				lng: _address?.markerPosition?.lng ?? DEFAULT_MAP_POSITION.lng,
			},
			nickName: _address?.nickName ?? '',
			addressType: _address?.addressType ?? '',
			zipCode: findZipCode(_address?.city ?? ''),
			area: _address?.area ?? '',
		};
	};

	useEffect(() => {
		setSelectedAddress(fillAddress(savedAddress));
	}, [savedAddress]);

	const removeAddress = (nickName: string) => {
		dispatch(setCheckoutDetails({ ['shipmentAddress']: {} as IAddress }));
		dispatch(
			setActiveAddressBook({
				activeAddressBook: undefined,
			})
		);
		dispatch(addBusyIndicator(GET_ADDRESS_BOOK_BI));
		CommerceProfileService.deleteUserAddress(nickName).subscribe(
			({ response }) => {
				if (response?.addressId?.[0] === newAddressBook) {
					dispatch(setNewAddressBook({ newAddressBook: '' }));
				}
				const filtered = addressBook.filter((_address) => _address.nickName !== nickName);
				setAddressBook(filtered);
			},
			() => {},
			() => {
				dispatch(removeBusyIndicator(GET_ADDRESS_BOOK_BI));
			}
		);
	};

	const addNewAddress = () => {
		dispatch(
			setModal({
				type: modalTypes.DELIVERY_ADDRESS,
				data: { type: 'shipmentAddress', action: 'create' },
				withoutContainer: true,
			})
		);
	};

	const addNewAddressWithoutModal = (address: IAddress) => {
		const _type: AddressType = 'ShippingAndBilling';
		const payload = {
			addressType: _type,
			organization: address.organization,
			way: address.way,
			house: address.house,
			apartment: address.apartment,
			landmark: address.landmark,
			governorate: address.governorate,
			markerPosition: address.markerPosition,
			city: address.city,
			wilayat: address.wilayat,
			area: address.area,
			zipCode: findZipCode(address.city),
			...contactDetails,
		};

		const p: IUpdateProfilePayloadInput = payload;
		if (payload.markerPosition) {
			const { lat, lng } = payload.markerPosition;
			p.geographicalShippingCode = `{"longitude": "${lng}", "latitude":"${lat}"}`;
		}
		p.nickName = `${p.governorate?.substring(0, 3).toUpperCase()}${Math.random().toString(36).substring(8)}`;
		CommerceProfileService.addContactToProfile(p).subscribe(
			(response) => {
				dispatch(
					setNewAddressBook({
						newAddressBook: response.addressId,
					})
				);
				updateShippingAddress({
					addressId: response.addressId,
					...payload,
					zipCode: findZipCode(address.city),
					way: payload?.way ?? '',
				});
				dispatch(setRefreshAddressBook({}));
			},
			() => {}
		);
	};

	const getTitle = (address: IAddress) => {
		let _address = address?.wilayat;
		if (address?.way) {
			_address += `, ${address?.way}`;
		}
		if (address?.house && address?.way) {
			_address += `, ${translate('address.apt')} ${address?.house}`;
			if (address.apartment) {
				_address += `/${address?.apartment}`;
			}
		}
		_address += `, ${address?.city}`;
		return _address;
	};

	const updateShippingAddress = (address: IAddress | undefined) => {
		dispatch(setCheckoutDetails({ ['shipmentAddress']: { ...fillAddress(address) } }));
		setSelectedAddress(fillAddress(address));
	};

	const editAddress = (address: IAddress, isIncorrectArea?: boolean) => {
		updateShippingAddress(address);
		dispatch(
			setModal({
				type: modalTypes.DELIVERY_ADDRESS,
				data: {
					type: 'shipmentAddress',
					nickName: address.nickName ?? '',
					action: 'update',
					address,
					isIncorrectArea,
				},
				withoutContainer: true,
			})
		);
		dispatch(
			setActiveAddressBook({
				activeAddressBook: address.addressId,
			})
		);
	};

	return {
		getTitle,
		addressBook,
		selectedAddress,
		getUserAddresses,
		removeAddress,
		editAddress,
		addNewAddress,
		addNewAddressWithoutModal,
		updateShippingAddress,
	};
};

export default useAddressBook;
