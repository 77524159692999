import React, { FunctionComponent } from 'react';
import { StepperState, StepperWrapper, IconWrapper } from './Stepper.styled';
import ConditionalRender from '@Components/ConditionalRender/ConditionalRender';
import Icon from '@Components/Icon/Icon';
import { CaptionBold } from '@Components/Typography/Typography.styled';

export interface IStepperBaseProps {
	value: number;
}

export interface IStepperAdditionalProps {
	size: number;
	state: StepperState;
	bgColor?: types.theme.themeColors | string;
}

export interface IStepperProps extends IStepperAdditionalProps, IStepperBaseProps {}

const Stepper: FunctionComponent<IStepperProps> = ({ value, size, state, bgColor }) => {
	const color: types.theme.themeColors = state === StepperState.ACTIVE ? 'primary' : 'black38';
	const backgroundColor: types.theme.themeColors | string = !!bgColor ? bgColor : 'unset';

	return (
		<StepperWrapper size={size} state={state} bgColor={backgroundColor}>
			<ConditionalRender
				show={state === StepperState.DONE}
				onTrue={() => (
					<IconWrapper>
						<Icon name="check" fill="white" height={16} width={16} />
					</IconWrapper>
				)}
				onFalse={() => <CaptionBold color={color}>{value}</CaptionBold>}
			/>
		</StepperWrapper>
	);
};

export const DoneStepper: FunctionComponent<IStepperBaseProps> = (props) => (
	<Stepper size={20} state={StepperState.DONE} {...props} />
);

export const UndoneStepper: FunctionComponent<IStepperBaseProps> = (props) => (
	<Stepper size={20} state={StepperState.UNDONE} {...props} />
);

export const ActiveStepper: FunctionComponent<IStepperBaseProps> = (props) => (
	<Stepper size={20} state={StepperState.ACTIVE} {...props} />
);

export default Stepper;
