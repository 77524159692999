export interface IPointsConversionElement {
	from: number;
	to: number;
	pointsValueNoMarkUp: number;
	pointsValueWithMarkUp: number;
}

const model: types.models.IInputModel = {
	nontelecomPointsConversion: 'response.nontelecomPointsConversion',
};

export default model;
