import styled from 'styled-components';

export const CardWrapper = styled.div`
	flex-grow: 0;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: left;
	border-radius: 8px;
	${({ theme }) => ({
		backgroundColor: theme.colors.white,
	})}
`;
