import { TitleBold, BodyS } from '@Components/Typography/Typography';
import styled from 'styled-components';
import { matchScreen } from '@Utils/layout/layoutSupport';
import { BIMA } from '@Views/Store/Store';
interface IImageLinkCardProps {
	backgroundImagePath: string;
	isSingleItem: boolean;
}
export const Container = styled.div<IImageLinkCardProps>`
	background-image: url(${({ backgroundImagePath }) => backgroundImagePath});
	background-size: cover;
	margin: 8px 0px;
	border-radius: 8px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	${({ isSingleItem }) => {
		if (isSingleItem) return { height: '407px' };
		const isXlLg = matchScreen(['xl', 'lg']);
		if (isXlLg) return { height: '96%' };
		return {};
	}}
`;

export const ButtonWrapper = styled.div`
	margin-top: auto;
	padding: 16px;
`;

export const Title = styled(TitleBold)`
	padding-bottom: 16px;
`;

export const SubTitle = styled(BodyS)`
	padding-bottom: 16px;
	${({ theme }) => ({
		color: theme.colors.white70,
	})}
`;

export const TopContainer = styled.div<{ name: string }>`
	${({ theme, name }) => ({
		backgroundImage:
			name === BIMA ? `linear-gradient(to bottom, ${theme.colors.black} 6%, rgba(0, 0, 0, 0) 100%)` : 'none',
	})}
	padding: 16px;
	border-radius: 8px;
`;
