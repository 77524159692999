import React, { FunctionComponent, RefObject, useRef, useState } from 'react';
import { Col, Row } from 'react-grid-system';
import {
	BGContainer,
	Button,
	CardContainer,
	CardWrapper,
	LogoWrapper,
	Header,
	TextButton,
	TextButtonWrapper,
	DescriptionWrapper,
	Description,
	Horizontal,
	DescriptionError,
} from './TelesalesOtp.styled';
import useTranslate from '@Utils/hooks/useTranslate';
import Icon from '@Components/Icon/Icon';
import { ParagraphS, TitleBold } from '@Components/Typography/Typography';
import { PrimaryLargeButtonFixed } from '@Components/controls/Button/Button';
import { useDispatch } from 'react-redux';
import Divider from '@Components/Divider/Divider';
import { useHistory } from 'react-router-dom';
import { setModal } from '@Redux/modules/modal/actions';
import ConditionalRender from '@Components/ConditionalRender/ConditionalRender';
import { Formik, FormikProps } from 'formik';
import OtpInputs from '@Components/OtpInputs/OtpInputs';
import { useCart } from '@Utils/context/CartContext';
import { getTimerFormat } from '@Utils/date/format';
import { addBusyIndicator, removeBusyIndicator } from '@Redux/modules/busyIndicator/actions';
import { OrderFlowService } from '@Services/orderFlow/orderFlow';
import { ProfileService } from '@Services/profile/profileService';
import useOtpTimer from '@Utils/hooks/useOtpTimer';
import { deleteWCTokens } from '@Redux/modules/api/headers/actions';
import { getLang } from '@Utils/language/language';
import { logOut, setLoginType } from '@Redux/modules/api/authentication/actions';
import { invalidateAllCache } from '@Redux/modules/api/cache/actions';
import { updateCart } from '@Redux/modules/cart/actions';
import { clearCheckoutDetails } from '@Redux/modules/checkout/actions';
import { maskProfileLogin } from '@Utils/converters/maskProfileLogin';
import useScreen from '@Utils/hooks/useScreen';

const OPERATION_ID = '30';
const FIRST_STEP_INPUT_NUMBERS = 4;
const SECOND_STEP_INPUT_NUMBERS = 5;
const SUBMIT_OTP_BI = 'SUBMIT_OTP_BI';
const TELESALES_OPERATION_CONTEXT = 'TELESALES';

const inputs: RefObject<HTMLInputElement>[] = [];

export interface IOtpFormikFields {
	otp: string[];
}

const TelesalesOtp: FunctionComponent<types.cards.IOtpCardProps> = (): JSX.Element => {
	const history = useHistory();
	const [otpError, setOtpError] = useState<boolean>(false);
	const [step, setStep] = useState<number>(0);
	const [otpArrayCode] = useState<string[]>([]);
	const { translate } = useTranslate();
	const dispatch = useDispatch();
	const otpRef = useRef<FormikProps<IOtpFormikFields>>(null);
	const { cart } = useCart();
	const {
		timer: { time, activated },
		setTimer,
	} = useOtpTimer();
	const { screen } = useScreen();
	const isXS = screen('xs');

	const phoneNumber = cart.items[0]?.telesalesPhone ?? '';
	const contactNumber = cart.items[0]?.contactNumber ?? '';

	const handleClose = () => {
		dispatch(setLoginType(undefined));
		dispatch(deleteWCTokens());
		dispatch(invalidateAllCache());
		dispatch(logOut());
		dispatch(clearCheckoutDetails());
		dispatch(
			setModal({
				type: null,
				data: null,
			})
		);
		dispatch(updateCart(true));
		history.push(`/${getLang()}/store`);
	};

	const validateIdCard = () => {
		dispatch(addBusyIndicator(SUBMIT_OTP_BI));
		OrderFlowService.otpValidateDocumentId({
			orderId: cart.orderId,
			msisdn: phoneNumber,
			document: otpRef?.current?.values?.otp.join('') || '',
		}).subscribe(
			(response) => {
				if (response.validate) {
					generateOtpCode();
				} else {
					dispatch(removeBusyIndicator(SUBMIT_OTP_BI));
					otpRef?.current?.setFieldError('otp', translate('telesales-checkout.popup.enter-digits.incorrect'));
				}
			},
			() => {
				dispatch(removeBusyIndicator(SUBMIT_OTP_BI));
				otpRef?.current?.setFieldError('otp', translate('telesales-checkout.popup.enter-digits.incorrect'));
			}
		);
	};

	const generateOtpCode = () => {
		ProfileService.generateOTP({
			msisdn: contactNumber,
			operationContext: TELESALES_OPERATION_CONTEXT,
		}).subscribe(
			({ returnValue }) => {
				if (returnValue) {
					dispatch(removeBusyIndicator(SUBMIT_OTP_BI));
					otpRef?.current?.setValues({ otp: [] });
					setStep(step + 1);
					setTimer({
						time: 120000,
						activated: true,
					});
				}
			},
			() => {}
		);
	};

	const reSendOtpCode = () => {
		dispatch(addBusyIndicator(SUBMIT_OTP_BI));
		ProfileService.generateOTP({
			msisdn: contactNumber,
			operationContext: TELESALES_OPERATION_CONTEXT,
		}).subscribe(
			({ returnValue }) => {
				if (returnValue) {
					dispatch(removeBusyIndicator(SUBMIT_OTP_BI));
					dispatch(
						setTimer({
							time: 120000,
							activated: true,
						})
					);
				}
			},
			() => {
				dispatch(removeBusyIndicator(SUBMIT_OTP_BI));
			},
			() => {
				dispatch(removeBusyIndicator(SUBMIT_OTP_BI));
			}
		);
	};

	const validateSmsPassword = () => {
		dispatch(addBusyIndicator(SUBMIT_OTP_BI));
		OrderFlowService.otpValidateSmsPassword({
			orderId: cart.orderId,
			msisdn: contactNumber,
			token: otpRef?.current?.values?.otp.join('') || '',
		}).subscribe(
			({ validate }) => {
				if (validate) {
					dispatch(updateCart(true));
					dispatch(removeBusyIndicator(SUBMIT_OTP_BI));
					dispatch(
						setModal({
							closeAllModals: true,
						})
					);
				} else {
					dispatch(removeBusyIndicator(SUBMIT_OTP_BI));
					otpRef.current?.setFieldError('otp', translate('otp-card.error'));
					setOtpError(true);
				}
			},
			() => {
				dispatch(removeBusyIndicator(SUBMIT_OTP_BI));
				otpRef.current?.setFieldError('otp', translate('otp-card.error'));
				setOtpError(true);
			}
		);
	};

	const confirm = () => {
		switch (step) {
			case 0:
				return validateIdCard();
			case 1:
				return validateSmsPassword();
		}
	};

	const createRefs = (numberOfInputs: number) => {
		for (let index = 0; index < numberOfInputs; index++) {
			inputs[index] = React.createRef();
		}
		return inputs;
	};

	const content = () => {
		switch (step) {
			case 0:
				return stepOne();
			case 1:
				return stepTwo();
		}
	};

	const stepOne = () => {
		return (
			<>
				<TitleBold>{translate('telesales-checkout.popup.title')}</TitleBold>
				<Divider marginBottom={isXS ? 32 : 8} withoutLine />
				<DescriptionWrapper>
					<Description>{translate('telesales-checkout.popup.description')}</Description>
				</DescriptionWrapper>
				<Divider marginBottom={40} withoutLine />
				<TitleBold>{translate('telesales-checkout.popup.enter-ID-card')}</TitleBold>
				<Divider marginBottom={16} withoutLine />
				<Formik<IOtpFormikFields>
					initialValues={{ otp: otpArrayCode }}
					onSubmit={() => {
						confirm();
					}}
					innerRef={otpRef}
				>
					{({ setFieldValue, values, errors }) => (
						<>
							<OtpInputs
								otpArrayCode={values.otp}
								inputRefs={createRefs(FIRST_STEP_INPUT_NUMBERS)}
								onChange={(value) => {
									setFieldValue('otp', value.split(','));
								}}
							/>
							<Divider marginBottom={16} withoutLine />
							<ConditionalRender
								show={!!errors.otp}
								onTrue={() => (
									<Horizontal>
										<>
											<Icon name="warningFilled" fill="warning" width={24} height={24} />
											<ParagraphS color="warning">{errors.otp}</ParagraphS>
										</>
									</Horizontal>
								)}
							/>
						</>
					)}
				</Formik>
			</>
		);
	};

	const stepTwo = () => {
		return (
			<>
				<TitleBold>{translate('telesales-checkout.popup.title.SMS.password')}</TitleBold>
				<Divider marginBottom={isXS ? 32 : 16} withoutLine />
				<DescriptionWrapper>
					<Description>
						{translate('telesales-checkout.popup.description.SMS', maskProfileLogin(contactNumber))}
					</Description>
				</DescriptionWrapper>
				<Divider marginBottom={16} withoutLine />
				<Description>{translate('telesales-checkout.popup.sub-description.SMS')}</Description>
				<Divider marginBottom={16} withoutLine />
				<Formik<IOtpFormikFields>
					initialValues={{ otp: otpArrayCode }}
					onSubmit={() => {
						confirm();
					}}
					innerRef={otpRef}
					enableReinitialize={true}
				>
					{({ setFieldValue, values }) => (
						<OtpInputs
							inputRefs={createRefs(SECOND_STEP_INPUT_NUMBERS)}
							otpArrayCode={values.otp}
							onChange={(value) => {
								setFieldValue('otp', value.split(','));
							}}
						/>
					)}
				</Formik>
			</>
		);
	};

	return (
		<CardContainer>
			<ConditionalRender
				show={activated}
				onTrue={() => (
					<BGContainer>
						<Icon name="loginBg" width={550} height={655} />
					</BGContainer>
				)}
			/>
			<CardWrapper>
				<Row>
					<Header>
						<LogoWrapper>
							<Icon name="omantelLogo" width={96} height={48} />
						</LogoWrapper>
					</Header>
				</Row>
				<Row>
					<Divider marginBottom={isXS ? 48 : 40} withoutLine />
				</Row>
				<Row>
					<Col>
						{content()}
						<Divider marginBottom={16} withoutLine />
						<ConditionalRender
							show={activated}
							onTrue={() => (
								<Horizontal>
									<>
										<ParagraphS>
											{translate(
												'telesales-checkout.popup.password.valid-for',
												getTimerFormat(time)
											)}
										</ParagraphS>
									</>
								</Horizontal>
							)}
						/>
						<Divider marginBottom={16} withoutLine />
						<ConditionalRender
							show={otpError}
							onTrue={() => (
								<DescriptionWrapper>
									<DescriptionError>{translate('otp-card.error')}</DescriptionError>
								</DescriptionWrapper>
							)}
						/>
						<Divider marginBottom={16} withoutLine />
						<ConditionalRender
							show={step === 1}
							onTrue={() => (
								<Horizontal>
									<TextButtonWrapper>
										<TextButton
											onClick={() => {
												reSendOtpCode();
												setOtpError(false);
											}}
										>
											{translate('telesales-checkout.popup.resend-code')}
										</TextButton>
									</TextButtonWrapper>
								</Horizontal>
							)}
						/>
						<Divider marginBottom={40} withoutLine />
						<Button>
							<PrimaryLargeButtonFixed
								uniqueId="modal.pin.confirm"
								onClick={() => confirm()}
								listener={SUBMIT_OTP_BI}
								disabled={!activated && step === 1}
							>
								{translate('telesales-confirm')}
							</PrimaryLargeButtonFixed>
						</Button>
						<Divider marginBottom={16} withoutLine />
						<TextButtonWrapper>
							<TextButton onClick={() => handleClose()}>{translate('telesales-cancel')}</TextButton>
						</TextButtonWrapper>
					</Col>
				</Row>
			</CardWrapper>
		</CardContainer>
	);
};
export default TelesalesOtp;
