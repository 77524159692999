import React, { FunctionComponent } from 'react';
import Card from '@Components/Card/Card';
import useScreen from '@Utils/hooks/useScreen';
import { ModalContainer } from './Upsell.styled';
import { getResponsiveModalStyles } from '@Utils/layout/layoutSupport';

const ModalAddedToCart: FunctionComponent<types.cards.IUpsellCardProps['params']> = ({ products, upsellProducts }) => {
	const { screenClass } = useScreen();

	return (
		<ModalContainer styles={getResponsiveModalStyles(screenClass)}>
			<Card<types.cards.IUpsellCardProps> params={{ products, upsellProducts }} card="upsell" withoutContainer />
		</ModalContainer>
	);
};

export default ModalAddedToCart;
