interface IInputModel {
	_getProductsResponse: {
		result?: {
			result: {
				resultDescription: string;
				resultCode: string;
				resultTime: string;
			};
		};
		product?: {
			productCharacteristic?: {
				name: string;
				value: string;
			};
			productSpecification?: {
				productCategory: {
					type: string;
					description: '';
				};
				productSpecCharacteristic?: {
					name: 'BILLING_TYPE';
					productSpecCharacteristicValue: {
						value: string;
					};
				};
			};
		};
		header?: {
			serviceContext: {
				operation: string;
				serviceVersion: string;
				serviceName: string;
			};
		};
	};
}

export interface IGetProductsUnauthorized {
	isPrepaid: boolean;
}

const model: types.models.IInputModel = {
	_getProductsResponse: ['response.getProductsResponse', {}],
	isPrepaid: (model: IInputModel) =>
		model._getProductsResponse?.product?.productSpecification?.productSpecCharacteristic
			?.productSpecCharacteristicValue.value === 'PREPAID',
};

export default model;
