import styled from 'styled-components';
import { matchScreen } from '@Utils/layout/layoutSupport';
import { DisplayMBold, CaptionBigger, BodyM, BodyMBold } from '@Components/Typography/Typography.styled';

export interface ISizes {
	isL?: boolean;
	isMd?: boolean;
	isSm?: boolean;
	isXs?: boolean;
}

export const Title = styled(DisplayMBold)`
	${({ theme }) => ({
		color: theme.colors.black87,
	})}
	padding-bottom: 40px;
	text-align: center;
`;

export const AccountCreationFormWrapper = styled.div<{ sizes?: ISizes }>`
	${({ sizes }) => ({
		padding: sizes?.isL
			? '48px 80px'
			: sizes?.isMd
			? '48px 90px'
			: sizes?.isSm
			? '48px 60px'
			: sizes?.isXs
			? '32px 16px'
			: '48px 84.5px 56px 84.5px',
	})}
`;

export const FormWrapper = styled.div<{ isSmall?: boolean }>`
	${({ isSmall }) => ({
		padding: isSmall ? '0' : '0 40px',
	})}
	display: flex;
	flex-wrap: wrap;
	flex-direction: row;
	align-items: left;
	justify-content: space-between;
	${() => matchScreen('xs') && { display: 'flex', flexDirection: 'row', alignItems: 'left' }}
`;

export const HintWrapper = styled.div`
	display: flex;
	margin-top: 8px;
	flex-direction: row;
	justify-content: center;
	align-items: flex-start;
	padding: 0px;
	gap: 8px;
`;

export const HintIcon = styled.div``;

export const HintText = styled(CaptionBigger)`
	text-align: center;
	${({ theme }) => ({
		color: theme.colors.black54,
	})}
`;

export const SubTitle = styled(BodyM)`
	text-align: center;
	${({ theme }) => ({
		color: theme.colors.black87,
	})}
`;

export const SubTitleBold = styled(BodyMBold)`
	text-align: center;
	${({ theme }) => ({
		color: theme.colors.black87,
	})}
`;

export const InfoWrapper = styled.div`
	display: flex;
	flex-direction: column;
	margin-top: -24px;
	gap: 20px;
	${({ theme }) => ({
		color: theme.colors.black87,
	})}
`;
