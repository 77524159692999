import styled from 'styled-components';
import { IWithScreenClass } from '@Components/Card/cards/ProductCard/ProductCard.styled';

export const AppleRelatedProductsWrapper = styled.div<IWithScreenClass>`
	${({ screenClass }) => {
		return {
			paddingLeft: ['sm', 'md'].includes(screenClass) ? 16 : 0,
			paddingRight: ['sm', 'md'].includes(screenClass) ? 16 : 0,
			marginLeft: ['sm', 'md'].includes(screenClass) ? -32 : ['lg', 'xl'].includes(screenClass) ? 0 : -16,
			marginRight: ['sm', 'md'].includes(screenClass) ? -32 : ['lg', 'xl'].includes(screenClass) ? 0 : -16,
		};
	}}
	display: flex;
	overflow-x: scroll;

	::-webkit-scrollbar {
		display: none;
	}
	> .single-product-xs {
		min-width: 260px;
		max-width: 260px;
	}
	> .single-product-sm {
		min-width: 235px;
		max-width: 235px;
	}
	> .single-product-md {
		min-width: 255px;
		max-width: 255px;
	}
	> .single-product-lg {
		min-width: 290px;
		max-width: 290px;
	}
	> .single-product-xl {
		min-width: 255px;
		max-width: 255px;
	}
`;
