import styled from 'styled-components';

export const TitleSeparatorContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
`;

export const TitleWrapper = styled.p<types.IStyledProps & { typography: keyof types.ITypography }>`
	${({ theme, typography }) => ({
		color: theme.colors.black87,
		...theme.typography[typography],
	})}
`;

export const Line = styled.div`
	${({ theme }) => ({
		backgroundColor: theme.colors.black12,
	})}
	flex: 1;
	height: 1px;
	display: inline-block;
	align-self: center;
	margin: 0 24px;
`;
