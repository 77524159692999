import {
	BodyS,
	CaptionBigger,
	DisplayMBoldH1,
	Headline,
	ParagraphM,
	TitleBold,
} from '@Components/Typography/Typography.styled';
import { paddingLeft } from '@Utils/layout/layoutSupport';
import { Container, Row } from 'react-grid-system';
import styled from 'styled-components';

export const ContainerWrapper = styled(Container)<{ isXs: boolean }>`
	margin-top: 200px;
	> * {
		${({ isXs }) => !isXs && paddingLeft(32)}
	}
`;

export const Title = styled(DisplayMBoldH1)`
	${({ theme }) => ({
		color: theme.colors.primary,
	})}
`;

export const TitleXS = styled(TitleBold)`
	${({ theme }) => ({
		color: theme.colors.primary,
	})}
`;

export const Subtitle = styled(Headline)`
	${({ theme }) => ({
		color: theme.colors.black54,
	})};
	margin-top: 16px;
`;

export const SubtitleXS = styled(BodyS)`
	${({ theme }) => ({
		color: theme.colors.black54,
	})};
	margin-top: 16px;
`;

export const InputRow = styled(Row)`
	margin-top: 40px;
	margin-bottom: 16px;
`;

export const ErrorTitle = styled(TitleBold)`
	${({ theme }) => ({
		color: theme.colors.black87,
	})};
	text-align: center;
	margin-top: 24px;
`;

export const ErrorTitleXS = styled(ParagraphM)`
	${({ theme }) => ({
		color: theme.colors.black87,
	})};
	text-align: center;
	margin-top: 24px;
`;

export const WarningContainer = styled.div`
	height: 40px;
	margin-bottom: 76px;
	width: 100%;
	border-radius: 4px;
	${({ theme }) => ({
		backgroundColor: theme.colors.warning6,
	})}
	display: flex;
	align-items: center;
`;

export const ErrorTextWrapper = styled(CaptionBigger)`
	${({ theme }) => ({
		color: theme.colors.warning,
	})}
	margin-top: 1px;
`;

export const IconContainer = styled.div`
	margin: 0 16px;
`;
