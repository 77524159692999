import React, { FunctionComponent } from 'react';
import { CloseBackground } from './ModalCloseButton.styled';
import { useDispatch, useSelector } from 'react-redux';
import { setModal } from '@Redux/modules/modal/actions';

const ModalCloseButton: FunctionComponent = () => {
	let availableClosing = true;
	let actionOnClose: () => void | undefined;

	const modalList = useSelector((state: types.redux.rootReducer) => state.modal.modalList);
	const dispatch = useDispatch();
	if (Array.isArray(modalList) && modalList[modalList.length - 1]) {
		availableClosing = modalList[modalList.length - 1].availableClosing;
		actionOnClose = modalList[modalList.length - 1].actionOnClose;
	}
	function closeModal() {
		dispatch(
			setModal({
				data: null,
				type: null,
			})
		);
		if (actionOnClose && typeof actionOnClose === 'function') {
			actionOnClose();
		}
	}

	function handleCloseModal() {
		closeModal();
	}

	return availableClosing ? <CloseBackground onClick={handleCloseModal} /> : null;
};

export default ModalCloseButton;
