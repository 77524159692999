import React, { FunctionComponent } from 'react';
import {
	AppleBrandStoreProductSubSection,
	AppleBrandStoreProductSubSectionTitle,
	ExtraSupportWrapper,
	AppleBrandStoreProductSection,
	AppleBrandStoreProductSectionTitle,
	AppleBrandStoreHeroBanner,
	DescriptionWrapper,
	ImageWrapper,
	ImagesWrapper,
	IconTextWrapper,
	SmallDescriptionWrapper,
	XSDescriptionWrapper,
	XSImagesWrapper,
	XSImageWrapper,
	Container,
} from './AppleServices.styled';
import Card from '@Components/Card/Card';
import useTranslate from '@Utils/hooks/useTranslate';
import useScreen from '@Utils/hooks/useScreen';
import {
	BodyXLBold,
	Status,
	Description,
	BodyLBold,
	ParagraphM,
	BodyS,
	BodySemiLBold,
	ParagraphS,
	HeadlineBold,
} from '@Components/Typography/Typography.styled';
import ConditionalRender from '@Components/ConditionalRender/ConditionalRender';
import TitleSeparator from '@Components/TitleSeparator/TitleSeparator';

const APPLE_TV = 'APPLE TV+';
const APPLE_MUSIC = 'Apple Music';
const APPLE_ARCADE = 'Apple Arcade';
const iCloud = 'iCloud';

interface IAppleServices {
	titleSeparator?: boolean;
}

const AppleServices: FunctionComponent<IAppleServices> = ({ titleSeparator }) => {
	const { translate } = useTranslate();
	const { screen } = useScreen();
	return (
		<Container>
			<AppleBrandStoreProductSection separator={titleSeparator}>
				<ConditionalRender
					show={!titleSeparator}
					onTrue={() => (
						<ConditionalRender
							show={screen(['xs', 'sm'])}
							onTrue={() => <HeadlineBold>{translate('apple-services.support.label')}</HeadlineBold>}
							onFalse={() => (
								<AppleBrandStoreProductSectionTitle>
									{translate('apple-services.support.label')}
								</AppleBrandStoreProductSectionTitle>
							)}
						/>
					)}
				/>
			</AppleBrandStoreProductSection>
			<ConditionalRender
				show={!!titleSeparator}
				onTrue={() => (
					<TitleSeparator
						title={translate('apple-services.support.label')}
						typography={screen('xs') ? 'titleBold' : 'displayMBold'}
					/>
				)}
			/>
			<AppleBrandStoreHeroBanner isSmall={screen(['sm'])} isXS={screen(['xs'])}>
				<ConditionalRender
					show={!screen(['xs', 'sm'])}
					onTrue={() => (
						<>
							<BodyXLBold>{translate('apple-services.hero-banner.title-first')}</BodyXLBold>
							<BodyXLBold>{translate('apple-services.hero-banner.title-second')}</BodyXLBold>
							<DescriptionWrapper>
								<Status color="black54">{translate('apple-services.hero-banner.description')}</Status>
							</DescriptionWrapper>
							<ImagesWrapper>
								<ImageWrapper>
									<img title="appleTvImg" src="apple/XLAppleTV.jpg" width="100%" height="100%" />
									<IconTextWrapper>
										<Description>{APPLE_TV}</Description>
									</IconTextWrapper>
								</ImageWrapper>
								<ImageWrapper>
									<img
										title="appleMusicImg"
										src="apple/XLAppleMusic.jpg"
										width="100%"
										height="100%"
									/>
									<IconTextWrapper>
										<Description>{APPLE_MUSIC}</Description>
									</IconTextWrapper>
								</ImageWrapper>
								<ImageWrapper>
									<img
										title="AppleArcadeImg"
										src="apple/XLAppleArcade.jpg"
										width="100%"
										height="100%"
									/>
									<IconTextWrapper>
										<Description>{APPLE_ARCADE}</Description>
									</IconTextWrapper>
								</ImageWrapper>
								<ImageWrapper>
									<img title="ICloudImg" src="apple/XLAppleCloud.webp" width="100%" height="100%" />
									<IconTextWrapper>
										<Description>{iCloud}</Description>
									</IconTextWrapper>
								</ImageWrapper>
							</ImagesWrapper>
						</>
					)}
					onFalse={() => (
						<ConditionalRender
							show={screen('sm')}
							onTrue={() => (
								<>
									<BodyLBold>{translate('apple-services.hero-banner.title-first')}</BodyLBold>
									<BodyLBold>{translate('apple-services.hero-banner.title-second')}</BodyLBold>
									<SmallDescriptionWrapper>
										<ParagraphM color="black54">
											{translate('apple-services.hero-banner.description')}
										</ParagraphM>
									</SmallDescriptionWrapper>
									<ImagesWrapper>
										<ImageWrapper>
											<img
												title="appleTvImg"
												src="apple/XLAppleTV.jpg"
												width="84px"
												height="64px"
											/>
											<IconTextWrapper>
												<BodyS>{APPLE_TV}</BodyS>
											</IconTextWrapper>
										</ImageWrapper>
										<ImageWrapper>
											<img
												title="appleMusicImg"
												src="apple/XLAppleMusic.jpg"
												width="102px"
												height="64px"
											/>
											<IconTextWrapper>
												<BodyS>{APPLE_MUSIC}</BodyS>
											</IconTextWrapper>
										</ImageWrapper>
										<ImageWrapper>
											<img
												title="AppleArcadeImg"
												src="apple/XLAppleArcade.jpg"
												width="112px"
												height="64px"
											/>
											<IconTextWrapper>
												<BodyS>{APPLE_ARCADE}</BodyS>
											</IconTextWrapper>
										</ImageWrapper>
										<ImageWrapper>
											<img
												title="ICloudImg"
												src="apple/XLAppleCloud.webp"
												width="64px"
												height="64px"
											/>
											<IconTextWrapper>
												<BodyS>{iCloud}</BodyS>
											</IconTextWrapper>
										</ImageWrapper>
									</ImagesWrapper>
								</>
							)}
							onFalse={() => (
								<>
									<BodySemiLBold>{translate('apple-services.hero-banner.title-first')}</BodySemiLBold>
									<BodySemiLBold>
										{translate('apple-services.hero-banner.title-second')}
									</BodySemiLBold>
									<XSDescriptionWrapper>
										<ParagraphS color="black54">
											{translate('apple-services.hero-banner.description')}
										</ParagraphS>
									</XSDescriptionWrapper>
									<XSImagesWrapper>
										<ImagesWrapper>
											<XSImageWrapper>
												<img
													title="appleTvImg"
													src="apple/XLAppleTV.jpg"
													width="84px"
													height="64px"
												/>
												<IconTextWrapper>
													<BodyS>{APPLE_TV}</BodyS>
												</IconTextWrapper>
											</XSImageWrapper>
											<XSImageWrapper>
												<img
													title="appleMusicImg"
													src="apple/XLAppleMusic.jpg"
													width="102px"
													height="64px"
												/>
												<IconTextWrapper>
													<BodyS>{APPLE_MUSIC}</BodyS>
												</IconTextWrapper>
											</XSImageWrapper>
										</ImagesWrapper>
										<ImagesWrapper>
											<XSImageWrapper>
												<img
													title="AppleArcadeImg"
													src="apple/XLAppleArcade.jpg"
													width="112px"
													height="64px"
												/>
												<IconTextWrapper>
													<BodyS>{APPLE_ARCADE}</BodyS>
												</IconTextWrapper>
											</XSImageWrapper>
											<XSImageWrapper>
												<img
													title="ICloudImg"
													src="apple/XLAppleCloud.webp"
													width="64px"
													height="64px"
												/>
												<IconTextWrapper>
													<BodyS>{iCloud}</BodyS>
												</IconTextWrapper>
											</XSImageWrapper>
										</ImagesWrapper>
									</XSImagesWrapper>
								</>
							)}
						/>
					)}
				/>
			</AppleBrandStoreHeroBanner>
			<ConditionalRender
				show={false}
				onTrue={() => (
					<>
						<AppleBrandStoreProductSubSection>
							<AppleBrandStoreProductSubSectionTitle>
								{translate('apple-services.title')}
							</AppleBrandStoreProductSubSectionTitle>
						</AppleBrandStoreProductSubSection>
						<ExtraSupportWrapper isSmXs={screen(['xs', 'sm'])}>
							<Card<types.cards.IImageWithTitleAndButtonProps>
								card="imageWithTitleAndButton"
								params={{
									imagePath: 'apple/AppleCare+@2x.jpg',
									title: translate('apple-services.service.title'),
									description: translate('apple-services.service.description'),
									imageWidth: 96,
								}}
							/>
							<Card<types.cards.IImageWithTitleAndButtonProps>
								card="imageWithTitleAndButton"
								params={{
									imagePath: 'apple/Support App@2x.jpg',
									title: translate('apple-services.support.title'),
									description: translate('apple-services.support.description'),
									imageWidth: 96,
								}}
							/>
						</ExtraSupportWrapper>
					</>
				)}
			/>
		</Container>
	);
};

export default AppleServices;
