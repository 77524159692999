import { cacheApiRequest, invalidateCache, invalidateAllCache } from './actions';
import immerHandleActions from '@Redux/utils/immerHandleActions';
import hasObjPaths from '@Utils/objectsValidation/hasObjectPath';

interface ICacheApiRequestAction extends types.redux.api.cache.ICacheRequestAction {}
interface ICachedParams extends types.redux.api.cache.ICachedParams {}

export interface IDraft {
	requests: {
		[endpointUrl: string]: {
			[payloadHash: string]: ICachedParams;
		};
	};
	hashMap: {
		[key: string]: {
			endpointUrl: string;
			currentHash: string | number;
		};
	};
}

const init: IDraft = {
	hashMap: {},
	requests: {},
};

const reducers = {
	[cacheApiRequest.toString()](draft: IDraft, action: ICacheApiRequestAction) {
		const { endpointUrl, payloadHash, validTill, response, invalidateKey } = action.payload;

		if (!hasObjPaths(draft.requests)) {
			draft.requests = {};
		}
		if (!hasObjPaths(draft.requests, endpointUrl)) {
			draft.requests[endpointUrl] = {};
		}

		draft.requests = {
			...draft.requests,
			...draft.requests,
			[endpointUrl]: {
				...draft.requests[endpointUrl],
				[payloadHash]: {
					response,
					validTill,
				},
			},
		};
		if (invalidateKey) {
			draft.hashMap = {
				...draft.hashMap,
				[invalidateKey]: {
					currentHash: payloadHash,
					endpointUrl,
				},
			};
		}

		return draft;
	},
	[invalidateCache.toString()](draft: IDraft, action: ICacheApiRequestAction) {
		const { endpointUrl, payloadHash } = action.payload;
		const invalidatedDate = new Date(0);
		// for current user
		if (payloadHash) {
			if (hasObjPaths(draft.requests, endpointUrl, payloadHash)) {
				draft.requests[endpointUrl][payloadHash].validTill = invalidatedDate;
			}
			// for all users
		} else if (hasObjPaths(draft.requests, endpointUrl)) {
			for (const hash in draft.requests[endpointUrl]) {
				if (typeof hash === 'string') {
					draft.requests[endpointUrl][hash].validTill = invalidatedDate;
				}
			}
		}

		return draft;
	},
	[invalidateAllCache.toString()]() {
		return init;
	},
};

export default immerHandleActions(reducers, init);
