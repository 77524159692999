import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import NavigationBar from '@Components/NavigationBar/NavigationBar';
import { Route, Switch, useHistory, useLocation } from 'react-router-dom';
import Footer from '@Components/Footer/Footer';
import CartMobile from '@Components/CartMobile/CartMobile';
import Divider from '@Components/Divider/Divider';
import { Col, Row, Container } from 'react-grid-system';
import { AccountWrapper, MenuWrapper } from './Account.styled';
import useTranslate from '@Utils/hooks/useTranslate';
import { Subheading } from '@Components/Typography/Typography.styled';
import MegaMenuItem, { MegaMenuItemType } from '@Components/MegaMenuItem/MegaMenuItem';
import Card from '@Components/Card/Card';
import { OrderHistoryType } from '@Components/Card/cards/OrderHistory/OrderHistory';
import { useSelector } from 'react-redux';
import { FormikProps } from 'formik';
import { IFormikFields } from '@Components/Card/cards/ContactDetails/ContactDetails';
import { dispatch } from '@Redux/store';
import { addBusyIndicator, removeBusyIndicator } from '@Redux/modules/busyIndicator/actions';
import { OrderFlowService } from '@Services/orderFlow/orderFlow';
import { IOrderHistory, IOrderItems } from '@ApiModels/orderHistory';
import moment from 'moment';
import { IOrderSingleItem } from '@Components/Card/cards/OrderHistory/OrderItem/OrderItem';
import { ProfileService } from '@Services/profile/profileService';
import { modalTypes, setModal } from '@Redux/modules/modal/actions';
import { PAGE_SIZE } from '@Config/app.config';
import { getLang } from '@Utils/language/language';
import { isLargeScreen, matchScreen } from '@Utils/layout/layoutSupport';
import { BUY_HBB, BUY_WFBB } from '@Views/Map/Map';
import { BUY_CAR_INSURANCE_BIMA } from '@Components/CartFyloutItem/CartFlyoutItem';

export const NAVBAR_HEIGHT = 100;
export const NAVBAR_HEIGHT_WITH_PADDING = NAVBAR_HEIGHT + 40;
export const GET_ORDER_HISTORY_BI = 'GET_ORDER_HISTORY_BI';

const Account: FunctionComponent = () => {
	const { translate } = useTranslate();
	const history = useHistory();
	const location = useLocation();
	const [footerHeight, setFooterHeight] = useState<number>(0);
	const [cartHeight, setCartHeight] = useState<number>(0);
	const contactDetailsRef = useRef<FormikProps<IFormikFields>>(null);
	const [orders, setOrders] = useState<IOrderSingleItem[] | undefined>(undefined);
	const [pageNumber, setPageNumber] = useState<number>(1);
	const [pageSize, setPageSize] = useState<number>(+PAGE_SIZE);
	const [ordersInProgress, setOrdersInProgress] = useState<number>(0);
	const isUserSignedIn = useSelector((state: types.redux.IState) => state.api?.authentication.signedIn);
	const orderUpdated = useSelector((state: types.redux.IState) => state.orderHistory?.orderUpdated);
	const isSmallScreen = matchScreen(['xs', 'sm']);

	const handleSwitchPage = (page: string) => {
		history.push(page);
	};

	const activeMenuItem = (page: string): boolean => {
		if (location.pathname === page) {
			return true;
		}
		return false;
	};

	const getItemName = (orderItems: string | undefined): string => {
		if (orderItems === undefined) {
			return '';
		} else {
			let itemName = '';
			if (orderItems.length > 0) {
				const items: IOrderItems[] = JSON.parse(orderItems);
				items.map((item) => {
					const name: string = [BUY_HBB, BUY_WFBB].includes(item.name || '') ? '' : item.name ?? '';
					let quantity: number = 0;
					if (item.quantity !== undefined) {
						quantity = +item.quantity;
					}
					if (quantity > 1) {
						itemName += name + ' (' + String(quantity) + '), ';
					} else {
						itemName += name + ', ';
					}
				});
			}
			return itemName.substring(0, itemName.length - 2);
		}
	};

	const getTypeByStatus = (status: string) => {
		switch (status) {
			case 'X':
				return OrderHistoryType.ORDER_HISTORY;
			case 'S':
				return OrderHistoryType.ORDER_HISTORY;
			default:
				return OrderHistoryType.ORDERS_IN_PROGRESS;
		}
	};

	const onError = () => {
		dispatch(removeBusyIndicator(GET_ORDER_HISTORY_BI));
		setOrders([]);
	};

	useEffect(() => {
		dispatch(addBusyIndicator(GET_ORDER_HISTORY_BI));
		OrderFlowService.getOrderHistory(pageNumber, pageSize).subscribe(
			(orderHistory: IOrderHistory) => {
				setOrders(
					orderHistory.Order?.map((order) => {
						order.x_orderItems = order?.x_orderItems?.replaceAll(
							BUY_CAR_INSURANCE_BIMA,
							translate('car-insurance.breadcrumb')
						);
						return {
							orderId: order.orderId ?? '',
							date: moment(order.placedDate).format('DD MMM YYYY') ?? '',
							item: getItemName(order?.x_orderItems),
							type: getTypeByStatus(order.orderStatus ?? ''),
							status: order.orderStatus ?? '',
							cancelDate: order.x_canceledDate ?? '',
						};
					})
				);
				dispatch(removeBusyIndicator(GET_ORDER_HISTORY_BI));
			},
			() => onError()
		);
	}, [isUserSignedIn, orderUpdated]);

	useEffect(() => {
		const inProgress = orders?.filter((o) => o.type === OrderHistoryType.ORDERS_IN_PROGRESS).length;
		if (inProgress && inProgress >= 0) {
			setOrdersInProgress(inProgress);
		}
	}, [orders]);

	useEffect(() => {
		ProfileService.getUserProfile().subscribe(
			() => {},
			() => {
				dispatch(
					setModal({
						type: modalTypes.LOGIN,
						actionOnClose: () => {
							handleSwitchPage(`/${getLang()}/store`);
						},
					})
				);
			}
		);
	}, [isUserSignedIn]);

	return (
		<AccountWrapper footerHeight={footerHeight + cartHeight} minheight={window.outerHeight}>
			<NavigationBar />
			<Divider marginBottom={NAVBAR_HEIGHT_WITH_PADDING - (isLargeScreen() ? 0 : isSmallScreen ? 44 : 12)} />
			<Container>
				<Subheading color="black87">{translate('account.management.orders').toUpperCase()}</Subheading>
				<Divider color="transparent" marginBottom={28} />
				<Row>
					<Col md={3}>
						<MenuWrapper>
							<MegaMenuItem
								iconLeft="chevronRight"
								type={MegaMenuItemType.ITEM}
								active={activeMenuItem(`/${getLang()}/account/orders/inprogress`)}
								onClick={() => handleSwitchPage(`/${getLang()}/account/orders/inprogress`)}
								name={
									ordersInProgress > 0
										? translate('account.management.orders.in.progress.count', ordersInProgress)
										: translate('account.management.orders.in.')
								}
							/>
							<MegaMenuItem
								iconLeft="chevronRight"
								type={MegaMenuItemType.ITEM}
								active={activeMenuItem(`/${getLang()}/account/orders/history`)}
								onClick={() => handleSwitchPage(`/${getLang()}/account/orders/history`)}
								name={translate('account.management.order.history')}
							/>
						</MenuWrapper>
						<Divider color="transparent" marginBottom={36} />
						<Subheading color="black87">
							{translate('account.management.settings').toUpperCase()}
						</Subheading>
						<Divider color="transparent" marginBottom={28} />
						<MenuWrapper>
							<MegaMenuItem
								iconLeft="chevronRight"
								type={MegaMenuItemType.ITEM}
								active={activeMenuItem(`/${getLang()}/account/personal/data`)}
								onClick={() => handleSwitchPage(`/${getLang()}/account/personal/data`)}
								name={translate('account.management.settings.personal.data')}
							/>
							<MegaMenuItem
								iconLeft="chevronRight"
								type={MegaMenuItemType.ITEM}
								active={activeMenuItem(`/${getLang()}/account/address/book`)}
								onClick={() => handleSwitchPage(`/${getLang()}/account/address/book`)}
								name={translate('account.management.settings.address.book')}
							/>
							<MegaMenuItem
								iconLeft="chevronRight"
								type={MegaMenuItemType.ITEM}
								active={activeMenuItem(`/${getLang()}/account/payment/method`)}
								onClick={() => handleSwitchPage(`/${getLang()}/account/payment/method`)}
								name={translate('account.management.settings.payment.method')}
							/>
						</MenuWrapper>
						<Divider color="transparent" marginBottom={28} />
					</Col>
					<Col>
						<Switch>
							<Route path={['/account/orders/inprogress', '/:lang/account/orders/inprogress']}>
								<Card<types.cards.IOrderHistoryCardProps>
									card="orderHistory"
									params={{
										type: OrderHistoryType.ORDERS_IN_PROGRESS,
										orders,
									}}
								/>
							</Route>
							<Route path={['/account/orders/history', '/:lang/account/orders/history']}>
								<Card<types.cards.IOrderHistoryCardProps>
									card="orderHistory"
									params={{
										type: OrderHistoryType.ORDER_HISTORY,
										orders,
									}}
								/>
							</Route>
							<Route path={['/account/personal/data', '/:lang/account/personal/data']}>
								<Card<types.cards.IContactDetailsProps>
									card="contactDetails"
									params={{
										contactDetailsRef,
										withButtons: true,
									}}
								/>
								<Divider marginBottom={16} withoutLine />
							</Route>
							<Route path={['/account/address/book', '/:lang/account/address/book']}>
								<Card<types.cards.IAddressBookProps> card="addressBook" />
							</Route>
							<Route path={['/account/payment/method', '/:lang/account/payment/method']}>
								<Card<types.cards.IPaymentMethodCardProps> card="paymentMethod" />
							</Route>
						</Switch>
						<Divider color="transparent" marginBottom={72} />
					</Col>
				</Row>
			</Container>
			<Footer setFooterHeight={setFooterHeight} />
			<CartMobile />
		</AccountWrapper>
	);
};

export default Account;
