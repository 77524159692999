import React, { FunctionComponent } from 'react';
import {
	BottomContentWrapper,
	Circle,
	DisplayFlex,
	Rectangular,
	SkeletonRow,
} from '@Components/Skeleton/Skeleton.styled';

const SearchResultSkeleton: FunctionComponent = () => {
	return (
		<DisplayFlex divHeight="240">
			<BottomContentWrapper marginTop={24} paddingLeft={15} paddingRight={15}>
				<SkeletonRow marginBottom={24}>
					<DisplayFlex>
						<Circle width={160} height={40} marginRight={16} color="grey300" />
						<Circle width={160} height={40} color="grey300" />
					</DisplayFlex>
				</SkeletonRow>
				{[1, 2, 3, 4].map((key) => (
					<SkeletonRow key={key} marginBottom={16}>
						<DisplayFlex justifyContent="space-between">
							<Rectangular width={160} height={20} color="grey300" />
						</DisplayFlex>
					</SkeletonRow>
				))}
			</BottomContentWrapper>
		</DisplayFlex>
	);
};
export default SearchResultSkeleton;
