import { Action, ActionFunction1, createAction } from 'redux-actions';

export interface IOutletsModalObjects {
	id: string;
	isOpen: boolean;
}

export interface IOutletAvailability {
	uniqueId: string;
	isAvailable: boolean;
}
export interface IConfiguratorPayload {
	selectedCapacity?: string;
	outlets?: IOutletsModalObjects[];
	outletAvailability?: IOutletAvailability[];
}
export interface IConfigurator {
	payload: IConfiguratorPayload;
}

export const setConfigurator: ActionFunction1<
	IConfigurator['payload'],
	Action<IConfigurator['payload']>
> = createAction('SET_CONFIGURATOR', (action: IConfigurator['payload']) => action);
export const clearConfigurator: ActionFunction1<
	IConfigurator['payload'],
	Action<IConfigurator['payload']>
> = createAction('CLEAR_CONFIGURATOR', (action: IConfigurator['payload']) => action);
export const setOpenOutletModal: ActionFunction1<
	IConfigurator['payload'],
	Action<IConfigurator['payload']>
> = createAction('SET_CONFIGURATOR', (action: IConfigurator['payload']) => action);
export const setOutletAvailability: ActionFunction1<
	IConfigurator['payload'],
	Action<IConfigurator['payload']>
> = createAction('SET_CONFIGURATOR', (action: IConfigurator['payload']) => action);
