import styled from 'styled-components';
import { ValueBold } from '@Components/Typography/Typography';
import { paddingLeft } from '@Utils/layout/layoutSupport';

export const KeywordWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 4px;
	padding: 8px 16px;
	${({ theme }) => ({
		backgroundColor: theme.colors.white,
	})}
`;

export const Label = styled(ValueBold)`
	${({ theme }) => ({
		color: theme.colors.black87,
	})}
`;

export const IconWrapper = styled.div`
	cursor: pointer;
	${() => paddingLeft(8)}
`;
