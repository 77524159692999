import React, { FunctionComponent } from 'react';
import Icon from '@Components/Icon/Icon';
import { Container } from 'react-grid-system';
import {
	LanguageButton,
	LeftSectionWrapper,
	OptionButton,
	OptionsBarWrapper,
	OptionsSectionWrapper,
	RightSectionWrapper,
} from '@Components/OptionsBar/OptionsBar.styled';
import externalLinks from '@Utils/externalLinks/externalLinks';
import { isRTL, matchScreen } from '@Utils/layout/layoutSupport';
import useTranslate from '@Utils/hooks/useTranslate';
import { getLanguage } from '@Utils/language/language';
import { setLanguage } from '@Redux/modules/settings/language/actions';
import { dispatch } from '@Redux/store';
import { useHistory, useLocation } from 'react-router-dom';
import ConditionalRender from '@Components/ConditionalRender/ConditionalRender';

const ARROW_ICON_SIZE = 16;

const OptionsBar: FunctionComponent = () => {
	const { translate } = useTranslate();
	const isSmallDevice = matchScreen(['xs', 'sm']);
	const isMd = matchScreen(['md']);
	const history = useHistory();
	const { search } = useLocation();

	const handleChangeLanguage = () => {
		const query = new URLSearchParams(search);
		if (getLanguage() === 'EN') {
			history.push(`/ar/${location.pathname.substring(4, location.pathname.length)}${query ? `?${query}` : ''}`);
			dispatch(setLanguage('AR'));
		} else {
			dispatch(setLanguage('EN'));
			history.push(`/en/${location.pathname.substring(4, location.pathname.length)}${query ? `?${query}` : ''}`);
		}
	};
	return (
		<OptionsBarWrapper>
			<Container fluid={isSmallDevice || isMd} id="options-section-wrapper">
				<OptionsSectionWrapper>
					<LeftSectionWrapper>
						<OptionButton
							active={true}
							onClick={() => {
								window.location.href = externalLinks('PERSONAL');
							}}
						>
							{translate('navbar.personal')}
						</OptionButton>
						<OptionButton
							active={false}
							onClick={() => {
								window.location.href = externalLinks('SME');
							}}
						>
							{isSmallDevice ? translate('navbar.smb') : translate('navbar-business')}
						</OptionButton>
						<OptionButton
							active={false}
							onClick={() => {
								window.location.href = externalLinks('WHOLESALE');
							}}
						>
							{isSmallDevice
								? translate('navbar.small.enterprises-&-government')
								: translate('navbar.wholesale')}
						</OptionButton>
						<OptionButton
							active={false}
							onClick={() => {
								window.location.href = externalLinks('INVESTORS');
							}}
						>
							{translate('navbar.investors')}
						</OptionButton>
					</LeftSectionWrapper>
					<ConditionalRender
						show={!isSmallDevice}
						onTrue={() => (
							<RightSectionWrapper onClick={handleChangeLanguage}>
								<LanguageButton>{translate('navbar.language')}</LanguageButton>
								<Icon
									name={isRTL() ? 'arrowLeftBox' : 'arrowRightBox'}
									fill="primary"
									width={ARROW_ICON_SIZE}
									height={ARROW_ICON_SIZE}
								/>
							</RightSectionWrapper>
						)}
					/>
				</OptionsSectionWrapper>
			</Container>
		</OptionsBarWrapper>
	);
};

export default OptionsBar;
