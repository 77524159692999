import styled from 'styled-components';
import { matchScreen, paddingRight } from '@Utils/layout/layoutSupport';
import { BodyS, BodySBold } from '@Components/Typography/Typography';

export const TelesalesDetailsWrapper = styled.div`
	${() => ({
		padding: matchScreen('xs') ? 0 : '8px 16px',
	})}
`;

export const IconWrapper = styled.div`
	${() => paddingRight(16)}
`;

export const DescriptionWrapper = styled.div`
	display: inline;
`;

export const TextBodyS = styled(BodyS)`
	display: inline;
`;

export const TextBodySBold = styled(BodySBold)`
	display: inline;
`;

export const HeaderWrapper = styled.div`
	display: flex;
`;
