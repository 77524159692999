import { IBillsHistory } from '@ApiModels/billsHistoryForAccounts';
import { AccountsService } from '@Services/accounts/accountsService';
import api from '@Utils/api';
import { Observable, of } from 'rxjs';
import { catchError, concatMap, map } from 'rxjs/operators';

export default class BillsService {
	public static getBillsHistoryForAccounts(filtered: boolean = false): Observable<IBillsHistory> {
		return AccountsService.getMainAccount().pipe(
			concatMap((acc) => {
				return api.omantelApi
					.getBillsHistoryForAccounts()
					.call()
					.pipe(
						catchError((e) => {
							return of({ billsData: [], totalUnpaid: 0 });
						}),
						map((response: IBillsHistory) => {
							let totalUnpaid = 0;
							response.billsData
								.find((data) => data.billingId === String(acc.accountId))
								?.invoices.filter((inv) => Number(inv.balanceDue) > 0)
								.forEach((item) => {
									totalUnpaid += Number(item.balanceDue);
								});
							return { ...response, totalUnpaid: String(totalUnpaid) };
						})
					);
			})
		);
	}
}
