import React from 'react';
import ConditionalRender from '@Components/ConditionalRender/ConditionalRender';
import Icon from '@Components/Icon/Icon';
import {
	CheckIconContainer,
	StepContainer,
	IconContainer,
	StepDescriptionActive,
	StepDescription,
} from './Step.styled';

interface IProps {
	iconName: types.iconNames;
	active: boolean;
	description: string;
	checked: boolean;
	mainColor: types.theme.themeColors;
}
import { matchScreen } from '@Utils/layout/layoutSupport';

const ICON_SIZE = 32;
const CHECK_ICON_SIZE = 12;

const Step = ({ iconName, active, description, checked, mainColor }: IProps): JSX.Element => {
	const TextComponent = active ? StepDescriptionActive : StepDescription;
	const isXs = matchScreen(['xs']);
	const isSmallDevice = matchScreen(['xs', 'sm']);

	return (
		<StepContainer active={active}>
			<IconContainer>
				<Icon
					name={active ? (`${iconName}Active` as types.iconNames) : iconName}
					width={ICON_SIZE}
					height={ICON_SIZE}
					fill="black54"
				/>
				<ConditionalRender
					show={active}
					onTrue={() => <TextComponent mainColor={mainColor}>{description}</TextComponent>}
					onFalse={() => (
						<ConditionalRender
							show={!isSmallDevice}
							onTrue={() => <TextComponent mainColor={mainColor}>{description}</TextComponent>}
						/>
					)}
				/>
			</IconContainer>
			<ConditionalRender
				show={checked}
				onTrue={() => (
					<CheckIconContainer>
						<Icon name="check" fill="white" width={CHECK_ICON_SIZE} height={CHECK_ICON_SIZE} />
					</CheckIconContainer>
				)}
			/>
		</StepContainer>
	);
};

export default Step;
