import styled from 'styled-components';
import { ParagraphS } from '@Components/Typography/Typography.styled';
import { isRTL, matchScreen } from '@Utils/layout/layoutSupport';

interface IOptionButtonProps extends types.IStyledProps {
	active?: boolean;
}

export const LanguageButton = styled(ParagraphS)<IOptionButtonProps>`
	cursor: pointer;
	border: none;
	background-color: transparent;
	outline: none;
	color: ${({ theme }) => theme.colors.primary};
`;

export const LeftSectionWrapper = styled.div`
	display: flex;
`;

export const OptionButton = styled.button<IOptionButtonProps>`
	border: none;
	background-color: transparent;
	outline: none;
	cursor: pointer;
	padding: 8px 16px;
	color: rgba(0, 11, 37, 0.64);
	${({ theme }) => {
		return { ...theme.typography.smallCaption };
	}}
	${({ active }) => (active ? { color: '#000B25', backgroundColor: '#FFFFFF' } : { color: 'rgba(0,11,37,.64)' })}
	&:hover {
		color: #000b25;
	}
`;

export const OptionsSectionWrapper = styled.div`
	display: flex;
	height: 32px;
	${() => {
		const isSmallDevice = matchScreen(['xs', 'sm']);
		const rtl = isRTL();
		return isSmallDevice && rtl ? { height: '65px' } : {};
	}}
	width: 100%;
	display: flex;
	justify-content: space-between;
`;

export const OptionsBarWrapper = styled.div`
	background-color: #f9f9f9;
`;

export const RightSectionWrapper = styled.div`
	gap: 8px;
	cursor: pointer;
	display: flex;
	align-items: center;
	&:hover {
		background-color: #fcf5eb;
	}
`;
