import { IInventory } from '@ApiModels/inventoryByProductId';

export interface IPopularProducts {
	products: ISinglePopularProduct[];
}

export interface ISinglePopularProduct {
	inventory: { [p: string]: IInventory[] };
	manufacturer: string;
	name: string;
	thumbnail: string;
	uniqueID: string;
	partNumber: string;
	price: string;
}

interface IDescription {
	longDescription: string;
	thumbnail: string;
	language: string;
	shortDescription: string;
	fullImage: string;
	productName: string;
}

interface IInputPopularProduct {
	baseMarketingSpotActivityName: string;
	productId: string;
	description: IDescription[];
	listPriceCurrency: string;
	ownerID: string;
	baseMarketingSpotActivityID: string;
	baseMarketingSpotDataType: string;
	productPartNumber: string;
	activityIdentifierID: string;
	attributes: string;
	catalogEntryTypeCode: string;
	activityPriority: string;
	properties: string;
	listPrice: string;
}

interface IContent {
	baseMarketingSpotActivityData: IInputPopularProduct[];
	behavior: string;
	defaultContentDisplaySequence: any[];
	eSpotName: string;
	marketingSpotDataTitle: any[];
	marketingSpotIdentifier: string;
	nextTimeLimit: string;
}

interface IModel {
	_contents: IContent[];
	_products: IInputPopularProduct[];
}

const model: types.models.IInputModel = {
	_contents: 'response.MarketingSpotData',
	_products: (model: IModel) => model._contents[0].baseMarketingSpotActivityData,
	products: (model: IModel) => {
		return model?._products?.map((product) => {
			return {
				name: product.description[0].productName,
				thumbnail: product.description[0].thumbnail,
				uniqueID: product.productId,
				partNumber: product.productPartNumber,
				price: product.listPrice,
			};
		});
	},
};

export default model;
