interface IGuestLogInFullModel extends IGuestLogIn {
	_supportedAccountCategories: string;
}
export interface IGuestLogIn {
	WCToken: string;
	WCTrustedToken: string;
	personalizationID: string;
	resourceName: string;
	userId: string;
	telesales?: boolean;
	supportedAccountCategories: string[] | null;
}

export const model: types.models.IInputModel = {
	_supportedAccountCategories: 'response.supportedAccountCategories',
	WCToken: 'response.WCToken',
	WCTrustedToken: 'response.WCTrustedToken',
	personalizationID: 'response.personalizationID',
	resourceName: 'response.resourceName',
	userId: 'response.userId',
	supportedAccountCategories: (model: IGuestLogInFullModel) => {
		if (model?._supportedAccountCategories) {
			return model._supportedAccountCategories.trim().split(',');
		}
		return null;
	},
	telesales: 'response.telesales',
};

export default model;
