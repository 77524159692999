import React, { FunctionComponent, RefObject } from 'react';
import { Input } from '@Components/OtpInputs/OtpSingleInput/OtpSingleInput.styled';
import useScreen from '@Utils/hooks/useScreen';

export interface IOtpSingleInput {
	onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
	onInput: (event: React.ChangeEvent<HTMLInputElement>) => void;
	onKeyDown: (event: React.KeyboardEvent<HTMLInputElement>) => void;
	onFocus: (event: React.ChangeEvent<HTMLInputElement>) => void;
	onPaste: (event: React.ClipboardEvent<HTMLInputElement>) => void;
	value: string;
	inputRef: RefObject<HTMLInputElement>;
	error?: boolean;
	disabled?: boolean;
}
const OtpSingleInput: FunctionComponent<IOtpSingleInput> = ({
	value,
	inputRef,
	onChange,
	onInput,
	onKeyDown,
	onFocus,
	onPaste,
	error,
	disabled,
}) => {
	const { screen } = useScreen();
	const isXS = screen('xs');
	return (
		<>
			<Input
				isXS={isXS}
				maxLength={1}
				onChange={onChange}
				onInput={onInput}
				onKeyDown={onKeyDown}
				onFocus={onFocus}
				onPaste={onPaste}
				value={value}
				error={error}
				disabled={disabled}
				ref={inputRef}
			/>
		</>
	);
};
export default OtpSingleInput;
