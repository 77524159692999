import styled from 'styled-components';
import { IDayItemProps } from './DayItem';
import { CaptionBiggerBold, CaptionBigger } from '@Components/Typography/Typography';

export const CalendarDayWrapper = styled.div<IDayItemProps>`
	width: 24px;
	flex-grow: 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 12px;
	padding: 16px;
	border-radius: 8px;
	${({ theme, selected, state, today }) => ({
		border: `solid 1px ${today ? theme.colors.primary : theme.colors.black24};`,
		backgroundColor:
			state === 'Disabled' ? theme.colors.black6 : selected ? theme.colors.primary24 : theme.colors.white,
		cursor: state === 'Default' ? 'pointer' : 'auto',
	})};
	&:hover {
		${({ theme }) => ({
			backgroundColor: theme.colors.primary6,
		})};
	}
`;

export const DayOfMonth = styled(CaptionBigger)<IDayItemProps>`
	margin: 0;
	padding: 0;
	${({ theme, state }) => ({ color: state === 'Disabled' ? theme.colors.black38 : theme.colors.black87 })}
`;

export const DayOfWeek = styled(CaptionBiggerBold)<IDayItemProps>`
	margin: 0;
	padding: 0;
	${({ theme, state }) => ({ color: state === 'Disabled' ? theme.colors.black38 : theme.colors.black87 })}
`;
