import React, { FunctionComponent } from 'react';
import { ViewType } from '@Components/Card/cards/ProductCard/ProductCard.styled';
import GridProductSkeleton from './subcompnents/GridProductSkeleton';
import { Row } from 'react-grid-system';

interface IProductSkeletonProps {
	viewType?: ViewType;
}

const UpSellCartItems: FunctionComponent<IProductSkeletonProps> = () => {
	return (
		<Row>
			<GridProductSkeleton />
			<GridProductSkeleton />
			<GridProductSkeleton />
		</Row>
	);
};
export default UpSellCartItems;
