import React, { FunctionComponent } from 'react';
import { MenuWrapper, AppleMenuElement, ElementsWrapper } from './AppleMenu.styled';
import { Container } from 'react-grid-system';
import { BodySBold, BodyS } from '@Components/Typography/Typography.styled';
import { getLang } from '@Utils/language/language';
import { useHistory } from 'react-router-dom';
import useTranslate from '@Utils/hooks/useTranslate';
import { hasCookie, NO_HEADER_FOOTER_COOKIE } from '@Utils/cookie/cookie';
import ConditionalRender from '@Components/ConditionalRender/ConditionalRender';

const IPHONE = 'iPhone';
const AIRPODS = 'AirPods';
const IPAD = 'iPad';

const AppleMenu: FunctionComponent<types.cards.IAppleMenu> = ({}) => {
	const history = useHistory();
	const { translate } = useTranslate();

	return (
		<ConditionalRender
			show={!hasCookie(NO_HEADER_FOOTER_COOKIE)}
			onTrue={() => (
				<MenuWrapper>
					<Container>
						<ElementsWrapper>
							<AppleMenuElement onClick={() => history.push(`/${getLang()}/apple-brand-store`)}>
								<BodySBold textCut={true}>{translate('apple-menu.apple-store')}</BodySBold>
							</AppleMenuElement>
							<AppleMenuElement onClick={() => history.push(`/${getLang()}/store/Apple`)}>
								<BodyS textCut={true}>{IPHONE}</BodyS>
							</AppleMenuElement>
							<AppleMenuElement onClick={() => history.push(`/${getLang()}/store/AppleTablets`)}>
								<BodyS textCut={true}>{IPAD}</BodyS>
							</AppleMenuElement>
							<AppleMenuElement onClick={() => history.push(`/${getLang()}/store/Apple%20Watch`)}>
								<BodyS textCut={true}>{translate('apple-menu.watch')}</BodyS>
							</AppleMenuElement>
							<AppleMenuElement onClick={() => history.push(`/${getLang()}/store/Apple%20TV`)}>
								<BodyS textCut={true}>{translate('apple-menu.apple-tv')}</BodyS>
							</AppleMenuElement>
							<AppleMenuElement onClick={() => history.push(`/${getLang()}/store/AirPods`)}>
								<BodyS textCut={true}>{AIRPODS}</BodyS>
							</AppleMenuElement>
							<AppleMenuElement onClick={() => history.push(`/${getLang()}/store/AppleAccessories`)}>
								<BodyS textCut={true}>{translate('apple-menu.accessories')}</BodyS>
							</AppleMenuElement>
						</ElementsWrapper>
					</Container>
				</MenuWrapper>
			)}
			onFalse={() => <></>}
		/>
	);
};

export default AppleMenu;
