import { getLanguageCode } from '@Utils/language/language';
import hasObjectPath from '@Utils/objectsValidation/hasObjectPath';
import idConfig from '@Utils/ecommerce/services/id.config';
import {
	ENV,
	CURRENCY,
	PLAN_PAGE_SIZE,
	PLANS_STORE_ID,
	PLANS_CATALOG_ID,
	PLANS_CATALOG_ID_BY_SEARCH_TERM,
} from '@Config/app.config';

interface IFunctionalEndpointConfig extends types.endpoints.functionalEndpointConfig {}
interface IGroupTypePayload {
	group: types.ecommerce.PackageGroup;
	type: types.ecommerce.PackageType;
}

const getParams = (catalogId: string) => {
	return `?langId=${getLanguageCode()}&catalogId=${catalogId}&searchType=1000&currency=${CURRENCY}&responseFormat=json&searchSource=E&pageSize=${PLAN_PAGE_SIZE}&pageNumber=1`;
};

const _asEndpoint = (url: string): types.endpoints.IEndpointConfig => {
	return {
		url,
	};
};

const _getBySearchTerm = (id: string): string => {
	return `productview/bySearchTerm/${id}${getParams(PLANS_CATALOG_ID_BY_SEARCH_TERM)}`;
};

const _getProxy = (url: string): string => {
	return `search/resources/store/${PLANS_STORE_ID}/${url}`;
};

const _getByCategory = (category: string): string => {
	return `productview/byCategory/${category}${getParams(PLANS_CATALOG_ID)}`;
};

export const getContentBySearchTerm: IFunctionalEndpointConfig = (term: string) => {
	return _asEndpoint(_getProxy(_getBySearchTerm(term)));
};

export const getByGroupAndType: IFunctionalEndpointConfig = ({ group, type }: IGroupTypePayload) => {
	if (hasObjectPath(idConfig.PACKAGES, group, type)) {
		const categoryID = idConfig.PACKAGES[group][type][ENV];
		return _asEndpoint(_getProxy(_getByCategory(categoryID)));
	} else {
		return _asEndpoint('');
	}
};
