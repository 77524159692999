import styled, { keyframes } from 'styled-components';
import { ICircularLoaderProps, ICircularLoaderBaseProps } from './CircularLoader';

const rotation = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

export const CircularLoaderWrapper = styled.div<ICircularLoaderBaseProps>`
	${({ size }) => ({ width: size, height: size })}
	display: inline-block;
	position: relative;
	justify-content: center;
	align-items: center;
`;

const Common = styled.div`
	display: inline-block;
	box-sizing: border-box;
	width: 100%;
	position: absolute;
	left: 0;
	top: 0;
`;

export const Circle = styled(Common)<ICircularLoaderProps>`
	${({ backgroundColor, theme }) => ({
		border: `solid 3px ${theme.colors[backgroundColor]}`,
	})}
	${({ size }) => ({ borderRadius: size / 2 })}
	height: 100%;
`;

export const Bar = styled(Common)<ICircularLoaderProps>`
	${({ barColor, theme }) => ({
		border: `solid 3px ${theme.colors[barColor]}`,
	})}
	${({ size }) => ({ borderTopLeftRadius: size / 2, borderTopRightRadius: size / 2, height: size / 2 })}
	border-bottom: 0;
	animation: 1s ${rotation} linear infinite;
	transform-origin: center bottom;
`;
