import React, { useEffect, useRef, useState } from 'react';
import {
	ContentWrapper,
	DropdownWrapper,
	DropdownMainContainer,
	DropdownContainer,
	DropdownItem,
	LabelBold,
	Label,
} from './MultipleSegmentSelector.styled';
import Segment, { ISegmentSize } from '@Components/controls/Segment/Segment';
import ConditionalRender from '@Components/ConditionalRender/ConditionalRender';
import { ISmartVoucher } from '@ApiModels/getSmartVoucher';
import { isRTL } from '@Utils/layout/layoutSupport';
export interface IMultipleSegmentSelectorProps<T> {
	segments: ISingleSegmentProps<T>[];
	selectedValue?: T;
	size?: ISegmentSize;
	onChange: (value: T) => void;
}

export interface ISingleSegmentProps<T> {
	value: T;
	name: string;
	data?: ISmartVoucher;
	productId?: number;
	disabled?: boolean;
}

export interface ISingleSegmentItemProps {
	selected: boolean;
	onClick: () => void;
}

export interface ISegmentDropdownProps {
	open: boolean;
}

const MultipleSegmentSelector = <T,>({
	segments = [],
	selectedValue,
	size = ISegmentSize.SMALL,
	onChange,
}: IMultipleSegmentSelectorProps<T>): JSX.Element => {
	const [isOpen, setIsOpen] = useState<boolean>(false);
	const [isSelectedExpanded, setIsSelectedExpanded] = useState<boolean>(false);
	const wrapperRef = useRef<HTMLDivElement>(null);

	const handleClickOutside = (event: MouseEvent) => {
		if (wrapperRef?.current && !wrapperRef?.current?.contains(event.target as HTMLDivElement)) {
			setIsOpen(false);
		}
	};

	useEffect(() => {
		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	});

	return (
		<ContentWrapper>
			{segments.slice(0, 3).map((segment, i) => (
				<Segment
					key={i}
					id={`${i}`}
					label={segment.name}
					selected={segment.value === selectedValue}
					disabled={!!segment.disabled}
					onClick={() => {
						onChange(segment.value);
						setIsSelectedExpanded(false);
					}}
					size={size}
					margin={(i === segments.length - 1 && segments.length <= 3) || isRTL() ? 0 : 8}
				/>
			))}
			{segments.length > 3 && (
				<>
					<DropdownWrapper>
						<Segment
							key="more"
							id="more"
							label="..."
							selected={isSelectedExpanded}
							disabled={false}
							clickSelected={true}
							onClick={() => {
								setIsOpen(!isOpen);
							}}
							size={size === ISegmentSize.BIG ? ISegmentSize.EXPANDER_BIG : ISegmentSize.EXPANDER_SMALL}
							margin={0}
						/>

						<DropdownMainContainer>
							<DropdownContainer open={isOpen} ref={wrapperRef}>
								{segments.slice(3, segments.length).map((segment, i) => (
									<DropdownItem
										onClick={() => {
											onChange(segment.value);
											setIsSelectedExpanded(true);
										}}
										selected={segment.value === selectedValue}
										key={`${i}`}
									>
										<ConditionalRender
											show={segment.value === selectedValue}
											onTrue={() => (
												<LabelBold color={segment.disabled ? 'black38' : 'black87'}>
													{segment.name}
												</LabelBold>
											)}
											onFalse={() => (
												<Label color={segment.disabled ? 'black38' : 'black87'}>
													{segment.name}
												</Label>
											)}
										/>
									</DropdownItem>
								))}
							</DropdownContainer>
						</DropdownMainContainer>
					</DropdownWrapper>
				</>
			)}
		</ContentWrapper>
	);
};

export default MultipleSegmentSelector;
