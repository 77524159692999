import React, { FunctionComponent, useEffect, useState } from 'react';
import { IDropdownItem, SimpleDropdown } from '@Components/controls/Dropdown/Dropdown';
import { setSortingMethod } from '@Redux/modules/sortingMethod/actions';
import { SortingMethodsVariants, SortingTypes } from '@Redux/modules/sortingMethod/reducer';
import { useDispatch, useSelector } from 'react-redux';
import useTranslate from '@Utils/hooks/useTranslate';
import { useLocation } from 'react-router-dom';

interface ISortingMethodsProps {
	sortingMethods?: SortingMethodsVariants[];
	type: SortingTypes;
}

const defaultSortingMethods: SortingMethodsVariants[] = [
	SortingMethodsVariants.RECOMMENDED,
	SortingMethodsVariants.PRICE_DESCENDING,
	SortingMethodsVariants.PRICE_ASSCENDING,
	SortingMethodsVariants.NEWEST_ARRIVALS,
];

const SortingMethods: FunctionComponent<ISortingMethodsProps> = ({ sortingMethods, type }: ISortingMethodsProps) => {
	const [_sortingMethods, setSortingMethods] = useState<IDropdownItem[]>([]);
	const [selectedDropdownItem, setSelectedDropdownItem] = useState<IDropdownItem | null>(null);
	const { translate } = useTranslate();
	const dispatch = useDispatch();
	const sortingMethod = useSelector((state: types.redux.IState) => state.sortingMethod);
	const language = useSelector((state: types.redux.IState) => state.settings.language);
	const location = useLocation();

	useEffect(() => {
		const params = new URLSearchParams(location.search);
		const sortingFromParams = params.get('sorting');
		if (!!sortingFromParams) {
			const sortingMethodId = Number(sortingFromParams);
			if (!!sortingMethodId && defaultSortingMethods[sortingMethodId]) {
				setSelectedDropdownItem(
					_sortingMethods.find((method) => method.id === defaultSortingMethods[sortingMethodId]) ?? null
				);
			}
		} else setSelectedDropdownItem(_sortingMethods.find((method) => method.id === sortingMethod[type]) ?? null);
	}, [location.search, _sortingMethods, sortingMethod]);

	useEffect(() => {
		if (sortingMethods) {
			setSortingMethods(
				sortingMethods.map((method) => ({ text: translate(`sorting-variant.${method}`), id: method }))
			);
		} else {
			setSortingMethods(
				defaultSortingMethods.map((method) => ({ text: translate(`sorting-variant.${method}`), id: method }))
			);
		}
	}, [sortingMethods, language]);

	useEffect(() => {
		if (selectedDropdownItem) {
			dispatch(setSortingMethod({ type, value: selectedDropdownItem.id }));
		}
	}, [selectedDropdownItem]);

	return (
		<SimpleDropdown
			items={_sortingMethods}
			selectedItem={selectedDropdownItem}
			setSelectedItem={setSelectedDropdownItem}
			label={translate('sorting-method.title')}
			backgroundColor="background"
			dropdownWidth="250px"
		/>
	);
};

export default SortingMethods;
