import ConditionalRender from '@Components/ConditionalRender/ConditionalRender';
import Icon from '@Components/Icon/Icon';
import useTranslate from '@Utils/hooks/useTranslate';
import React, { FunctionComponent } from 'react';
import { Container, CaptionWrapper, ItemTitle, DeliveryDate, DeliveryInfo } from './CartAdditionalInfo.styled';
import { getLang } from '@Utils/language/language';
import { isSoonPeorderDate } from '@Utils/date/moment';
interface ICartAdditionalInfoProps {
	preorder: { preorder: boolean; preorderDate?: string };
}
const ICON_SIZE = 40;
const CartAdditionalInfo: FunctionComponent<ICartAdditionalInfoProps> = ({ preorder: { preorder, preorderDate } }) => {
	const { translate } = useTranslate();
	return (
		<ConditionalRender
			show={!!preorder}
			onTrue={() => (
				<Container>
					<Icon
						name={getLang() === 'ar' ? 'preorder_ar' : 'preorder_en'}
						fill="support2"
						height={ICON_SIZE}
						width={ICON_SIZE}
					/>
					<CaptionWrapper>{translate('cart.additional.info.title')}</CaptionWrapper>
					<DeliveryInfo>
						{isSoonPeorderDate(preorderDate ?? '')
							? translate('availability.preorder.subtitle.second-version')
							: translate('cart.additional.info.delivery.info')}
					</DeliveryInfo>
					<DeliveryDate>
						<ConditionalRender
							show={!isSoonPeorderDate(preorderDate ?? '')}
							onTrue={() => <>{preorderDate}</>}
						/>
					</DeliveryDate>
					<ItemTitle>{translate('cart.additional.info.subtitle')}</ItemTitle>
				</Container>
			)}
		/>
	);
};

export default CartAdditionalInfo;
