import useTranslate from '@Utils/hooks/useTranslate';
import * as Yup from 'yup';

export default (): Yup.AnyObjectSchema => {
	const { translate } = useTranslate();
	return Yup.object().shape({
		customerIdType: Yup.string().required(translate('document.data.form.document.type.required')),
		customerId: Yup.string().required(translate('sim-document.data.form.civil-number.required')),
	});
};
