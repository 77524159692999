import styled from 'styled-components';

export enum ColorPickerPosition {
	VERTICAL = 'VERTICAL',
	HORIZONTAL = 'HORIZONTAL',
}

interface IColorPickerWrapperProps {
	position: ColorPickerPosition;
	separator: number;
}

interface IIconWrapperProps {
	hidden: boolean;
}

export const ColorPickerWrapper = styled.div<IColorPickerWrapperProps>`
	display: inline-flex;
	align-items: center;
	justify-content: center;
	flex-direction: ${({ position }) => (position === ColorPickerPosition.HORIZONTAL ? 'row' : 'column')};
`;

export const IconWrapper = styled.div<IIconWrapperProps>`
	display: inline-block;
	transition: all 0.1s ease-in-out;
	${({ hidden }) => (hidden ? { visibility: 'hidden' } : { cursor: 'pointer' })}
`;
