import immerHandleActions from '@Redux/utils/immerHandleActions';
import {
	addToCompare,
	removeFromCompare,
	clearComparison,
	IAddToCompareAction,
	IRemoveFromCompareAction,
	updateThumbnail,
} from './actions';

const init: IAddToCompareAction['payload'] | any[] = [];

type draft = typeof init;

const reducers = {
	[addToCompare.toString()](draft: draft, action: IAddToCompareAction) {
		const itemExists = !!draft.find((item) => item.id === action.payload.id);
		if (!itemExists) {
			draft.push(action.payload);
		}
		return draft;
	},
	[removeFromCompare.toString()](draft: draft, action: IRemoveFromCompareAction) {
		return draft.filter((item) => item.id !== action.payload.id);
	},
	[clearComparison.toString()]() {
		return [];
	},
	[updateThumbnail.toString()](draft: draft, action: IAddToCompareAction) {
		const itemToUpdate = draft.find(({ id }) => id === action.payload.id);
		if (itemToUpdate) {
			draft = draft.map((item) => {
				if (item.id === action.payload.id) {
					return action.payload;
				}
				return item;
			});
		}
		return draft;
	},
};

export default immerHandleActions(reducers, init);
