import React, { FunctionComponent, useState } from 'react';
import {
	AppleLobMenuWrapper,
	ElementsWrapper,
	CarouselRightIconWrapper,
	CarouselWrapper,
	CarouselLeftIconWrapper,
} from './AppleLobMenu.styled';
import Card from '@Components/Card/Card';
import AliceCarousel from 'react-alice-carousel';
import useScreen from '@Utils/hooks/useScreen';
import Icon from '@Components/Icon/Icon';

const CHEVRON_ICON_SIZE = 20;

const AppleLobMenu: FunctionComponent = () => {
	const { screen } = useScreen();
	const [index, setIndex] = useState<number>(0);

	const appleLobMenuItems = [
		{ imagePath: 'apple/iphone.jpg', categoryName: 'iPhone', routingPath: '/store/Apple' },
		{ imagePath: 'apple/iPad_photo.jpg', categoryName: 'iPad', routingPath: '/store/AppleTablets' },
		{ imagePath: 'apple/apple_watch.jpg', categoryName: 'Apple Watch', routingPath: '/store/Apple%20Watch' },
		{ imagePath: 'apple/airpods.jpg', categoryName: 'AirPods', routingPath: '/store/AirPods' },
		{ imagePath: 'apple/appletv.jpg', categoryName: 'Apple TV', routingPath: '/store/Apple%20TV' },
		{ imagePath: 'apple/case.jpg', categoryName: 'Accessories', routingPath: '/store/AppleAccessories' },
	];

	const defaultResponsive = {
		0: { items: 2 },
		456: { items: 3 },
		576: { items: 4 },
		768: { items: 4 },
		992: { items: 5 },
		1200: { items: 6 },
	};

	const disableRightButton = () => {
		if (screen(['xs', 'sm'])) {
			return true;
		} else if (screen(['md'])) {
			return index === 2;
		} else if (screen(['lg'])) {
			return index === 1;
		} else return true;
	};

	const disableLeftButton = () => {
		if (screen(['xs', 'sm'])) {
			return true;
		} else if (screen(['md'])) {
			return index === 0;
		} else if (screen(['lg'])) {
			return index === 0;
		} else return true;
	};

	return (
		<AppleLobMenuWrapper>
			<ElementsWrapper>
				<AliceCarousel
					activeIndex={index}
					paddingRight={screen(['xl']) ? 0 : screen(['sm']) ? 10 : 22}
					items={appleLobMenuItems.map((appleLobMenuItem, index) => (
						<CarouselWrapper key={`appleLobMenuItem_ ${index}`}>
							<Card<types.cards.IAppleLobMenuItemProps>
								card="appleLobMenuItem"
								params={{ ...appleLobMenuItem }}
								showShadowOnHover
							/>
						</CarouselWrapper>
					))}
					responsive={defaultResponsive}
					disableDotsControls
					controlsStrategy="reponsive"
					renderNextButton={() =>
						!disableRightButton() ? (
							<CarouselRightIconWrapper onClick={() => setIndex(index + 1)}>
								<Icon
									name="chevronRight"
									width={CHEVRON_ICON_SIZE}
									height={CHEVRON_ICON_SIZE}
									fill="primary"
								/>
							</CarouselRightIconWrapper>
						) : null
					}
					renderPrevButton={() =>
						!disableLeftButton() ? (
							<CarouselLeftIconWrapper onClick={() => setIndex(index - 1)}>
								<Icon
									name="chevronLeft"
									width={CHEVRON_ICON_SIZE}
									height={CHEVRON_ICON_SIZE}
									fill="primary"
								/>
							</CarouselLeftIconWrapper>
						) : null
					}
					mouseTracking={screen(['sm', 'xs'])}
				/>
			</ElementsWrapper>
		</AppleLobMenuWrapper>
	);
};

export default AppleLobMenu;
