import { IDeviceDimension } from '@ApiModels/productById';
import Icon from '@Components/Icon/Icon';
import React, { FunctionComponent } from 'react';
import { Col, Row } from 'react-grid-system';
import { Device, DeviceDepth, DeviceHeight, DeviceImage, DeviceWidth, DimensionsWrapper } from './Dimensions.styled';

interface IDimensions {
	dimensions?: IDeviceDimension;
}

const Dimensions: FunctionComponent<IDimensions> = ({ dimensions }) => {
	return (
		<DimensionsWrapper>
			<DeviceHeight>{dimensions?.height}</DeviceHeight>
			<Device>
				<DeviceImage>
					<Icon name="dimensions" width={272} height={392} />
				</DeviceImage>
				<Row>
					<Col xs={9}>
						<DeviceWidth>{dimensions?.width}</DeviceWidth>
					</Col>
					<Col>
						<DeviceDepth>{dimensions?.depth}</DeviceDepth>
					</Col>
				</Row>
			</Device>
		</DimensionsWrapper>
	);
};

export default Dimensions;
