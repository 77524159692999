import styled from 'styled-components';
import { BodyS, ParagraphS } from '@Components/Typography/Typography.styled';

export const QuantityButton = styled.div<{ disabled?: boolean }>`
	width: 10px;
	height: 10px;
	padding: 4px;
	border-radius: 16px;
	${({ theme, disabled }) => ({
		backgroundColor: theme.colors[disabled ? 'black12' : 'primary'],
	})}
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
`;

export const FlyoutQuantityButton = styled.div<{ disabled?: boolean }>`
	width: 10px;
	height: 10px;
	padding: 4px;
	border-radius: 16px;
	${({ theme, disabled }) => ({
		backgroundColor: theme.colors.white,
		border: `1px solid ${theme.colors.primary}`,
	})}
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
`;

export const QuantityText = styled(BodyS)`
	${({ theme }) => ({
		color: theme.colors.black87,
		padding: '0 12px',
		marginTop: '2px',
	})}
`;

export const FlyoutQuantityText = styled(ParagraphS)`
	${({ theme }) => ({
		color: theme.colors.primary,
		padding: '0 12px',
	})}
`;

export const QuantityContainer = styled.div`
	padding: 0;
	display: flex;
	flex-direction: row;
`;

export const Minus = styled.div<{ disabled?: boolean }>`
	width: 8px;
	height: 1px;
	border-radius: 2px;
	margin-top: -1px;
	${({ theme, disabled }) => ({
		backgroundColor: theme.colors[disabled ? 'black38' : 'white'],
	})}
`;
