import { isRTL } from '@Utils/layout/layoutSupport';
import styled from 'styled-components';
import { ButtonL } from '@Components/Typography/Typography.styled';

export const ViewChangeContainer = styled.div<types.IStyledProps>`
	display: flex;
	flex-direction: row;
`;

export const ViewChangeButtonContainer = styled.button<types.IStyledProps>`
	${({ theme }) => ({
		padding: '8px',
		backgroundColor: theme.colors.white,
		borderRadius: '4px',
		cursor: 'pointer',
		outline: 'none',
		border: 0,
	})}
	${() => (isRTL() ? 'margin-left: 8px' : 'margin-right: 8px')}
`;

export const Counter = styled(ButtonL)<types.IStyledProps>`
	${({ theme }) => ({
		padding: '8px',
		cursor: 'pointer',
		outline: 'none',
		border: 0,
	})}
	${() => (isRTL() ? 'margin-left: 8px' : 'margin-right: 8px')}
`;
