import React from 'react';
import useTranslate from '@Utils/hooks/useTranslate';
import Icon from '@Components/Icon/Icon';
import ConditionalRender from '@Components/ConditionalRender/ConditionalRender';
import Button from '@Components/controls/Button/Button';
import { ButtonSize, ButtonVariant, ButtonWidth } from '@Components/controls/Button/Button.styled';
import { IconWrapper, CardElementsWraper, InsuranceDescription } from './InsuranceBima.styled';
import { useHistory } from 'react-router-dom';
import { getLang } from '@Utils/language/language';
import { useSelector } from 'react-redux';
import { dispatch } from '@Redux/store';
import { modalTypes, setModal } from '@Redux/modules/modal/actions';

const InsuranceBimaCard = ({ params: { withButton } }: types.cards.IInsuranceBimaCardProps): JSX.Element => {
	const { translate } = useTranslate();
	const history = useHistory();
	const isUserSignedIn = useSelector((state: types.redux.IState) => state.api?.authentication.signedIn);

	const handleOnClick = () => {
		if (isUserSignedIn) {
			history.push(`/${getLang()}/product/car-insurance/details`);
		} else {
			dispatch(
				setModal({
					data: {
						onContinue: () => {
							history.push(`/${getLang()}/product/car-insurance/details`);
						},
						withoutFooter: true,
					},
					type: modalTypes.LOGIN,
				})
			);
		}
	};

	return (
		<CardElementsWraper>
			<IconWrapper>
				<Icon name="logoBima" width={135} height={32} justifycontent="flex-start" />
			</IconWrapper>
			<IconWrapper>
				<Icon name="insuranceProvider" width={144} height={96} justifycontent="flex-start" />
			</IconWrapper>
			<InsuranceDescription>{translate('insurance.bima.welcome')}</InsuranceDescription>
			<ConditionalRender
				show={withButton}
				onTrue={() => (
					<Button
						uniqueId="insurance-bima-welcome-button"
						onClick={handleOnClick}
						variant={ButtonVariant.QUATERNARY}
						size={ButtonSize.MEDIUM}
						width={ButtonWidth.FLEX}
					>
						{translate('insurance.action')}
					</Button>
				)}
			/>
		</CardElementsWraper>
	);
};

export default InsuranceBimaCard;
