import styled from 'styled-components';
import { paddingLeft } from '@Utils/layout/layoutSupport';

export const AvailabilityWrapper = styled.div`
	margin-top: 24px;
	display: flex;
	flex-direction: column;
	gap: 24px;
`;

export const IconWrapper = styled.div`
	${() => paddingLeft('16px')}
`;

export const DescriptionWrapper = styled.div`
	display: flex;
	${() => paddingLeft('16px')}
	align-items: flex-start;
	justify-content: center;
	flex-direction: column;
	gap: 4px;
`;
