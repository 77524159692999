import { BodyS } from '@Components/Typography/Typography.styled';
import { isRTL, paddingLeft } from '@Utils/layout/layoutSupport';
import styled from 'styled-components';

export const DimensionsWrapper = styled.div`
	width: calc(100% - 32px);
	display: flex;
`;

export const DeviceHeight = styled(BodyS)`
	writing-mode: vertical-rl;
	text-orientation: mixed;
	text-align: center;
	${({ theme }) => ({
		color: theme.colors.black87,
	})}
`;

export const Device = styled.div``;

export const DeviceImage = styled.div`
	max-height: 456px;
	img {
		max-width: 100%;
		max-height: 100%;
	}
`;

export const DeviceWidth = styled(BodyS)`
	${({ theme }) => ({
		color: theme.colors.black87,
	})}
	text-align: center;
	${() => paddingLeft('75px')}
`;

export const DeviceDepth = styled(BodyS)`
	${({ theme }) => ({
		color: theme.colors.black87,
	})}
	${() =>
		isRTL()
			? {
					textAlign: 'left',
					marginLeft: '-10px',
			  }
			: {
					textAlign: 'right',
					marginRight: '-10px',
			  }}
`;
