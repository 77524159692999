import styled from 'styled-components';

interface IDashboardWrapperProps {
	footerHeight?: number;
}

export const ComparisonWrapper = styled.div<IDashboardWrapperProps>`
	${({ footerHeight, theme }) => ({
		backgroundColor: theme.colors.background,
		paddingBottom: footerHeight,
		maxWidth: window.innerWidth,
	})}
`;
