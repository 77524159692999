import useScreen from '@Utils/hooks/useScreen';
import React, { FunctionComponent } from 'react';
import { CircularLoaderWrapper, Circle, Bar, LoaderWrapper } from './SmallCircularLoader.styled';

export interface ICircularLoaderBaseProps {
	size: number;
}

export interface ICircularLoaderProps extends ICircularLoaderBaseProps {
	backgroundColor: types.theme.themeColors;
	barColor: types.theme.themeColors;
}

const CircularLoader: FunctionComponent<ICircularLoaderProps> = ({ size, backgroundColor, barColor }) => {
	const { screen } = useScreen();
	return (
		<LoaderWrapper customWidth={screen(['xl']) ? '168px' : undefined}>
			<CircularLoaderWrapper size={24}>
				<Circle size={24} backgroundColor="white12" barColor="white" />
				<Bar size={24} backgroundColor="white12" barColor="white" />
			</CircularLoaderWrapper>
		</LoaderWrapper>
	);
};

export default CircularLoader;
