import React, { useEffect, useState } from 'react';
import { useInsurance, INSURANCE_TYPE } from '@Utils/context/InsuranceContext';
import useTranslate from '@Utils/hooks/useTranslate';
import {
	Image,
	TitleWrapper,
	SubtitleWrapper,
	HeaderText,
	HeaderWrapper,
	CostTitle,
	CostWrapper,
	ButtonContent,
	PriceWrapper,
	PriceContainer,
	VatInfo,
	SecureTransactionWrapper,
	SecureTransactionText,
	HighlightedText,
	InsuranceProvider,
	InsuranceProviderWrapper,
	InsuranceOfferDetails,
	TCWrapper,
	TCLink,
	CardWrapper,
	PriceTCWrapper,
} from './InsuranceSummary.styled';
import Button from '@Components/controls/Button/Button';
import { ButtonSize, ButtonVariant, ButtonWidth } from '@Components/controls/Button/Button.styled';
import PriceTag from '@Components/PriceTag/PriceTag';
import Divider from '@Components/Divider/Divider';
import { useDispatch } from 'react-redux';
import { IAddInsuranceToCartPayload, OrderFlowService } from '@Services/orderFlow/orderFlow';
import { InsuranceService } from '@Services/insurance/insurance';
import { getLang } from '@Utils/language/language';
import { modalTypes, setModal } from '@Redux/modules/modal/actions';
import { errors } from '@Utils/api/errors/errors';
import Icon from '@Components/Icon/Icon';
import { CAR_INSURANCE_PRODUCT_ID } from '@Config/app.config';
import useCommerceProfile from '@Utils/hooks/useCommerceProfile';
import { useCart } from '@Utils/context/CartContext';
import { INSURANCE_REQUEST_TYPE } from '@Config/app.config';
const ADD_TO_CART_BI = 'ADD_TO_CART_BI';
import { useHistory } from 'react-router-dom';
import { BodyS } from '@Components/Typography/Typography';
import { SquareCheckbox } from '@Components/controls/Checkbox/Checkbox';
import ConditionalRender from '@Components/ConditionalRender/ConditionalRender';
import useScreen from '@Utils/hooks/useScreen';

const InsuranceSummary = ({ params: { expanded } }: types.cards.IInsuranceSummaryCardProps): JSX.Element => {
	const {
		vehicleInformation,
		insuranceType,
		selectedProvider,
		selectedReferenceNo,
		selectedTrackId,
		type,
		licenseNumber,
		contactNumber,
		isBimaInsurance,
		ownerDataFormErrors,
	} = useInsurance();
	const { translate } = useTranslate();
	const dispatch = useDispatch();
	const { cart } = useCart();
	const [insuranceValue, setInsuranceValue] = useState('');
	const { contactDetails } = useCommerceProfile();
	const history = useHistory();
	const [isAddToCartButtonActive, setIsAddToCartButtonActive] = useState(false);
	const [tcAccept, setTcAccept] = useState(false);
	const { screen } = useScreen();

	useEffect(() => {
		const selectedOffer = selectedProvider?.offers?.find(({ referenceNo }) => referenceNo === selectedReferenceNo);
		if (selectedOffer) setInsuranceValue(selectedOffer.amount);
	}, [selectedProvider, selectedReferenceNo]);

	const handelClick = () => {
		InsuranceService.getTermsAndConditions().subscribe(
			({ response }) => {
				dispatch(
					setModal({
						data: {
							content: response.content,
							vehicleInformation,
							selectedProvider,
						},
						type: modalTypes.TERMS_AND_CONDITIONS,
					})
				);
			},
			(e) => {}
		);
	};

	const handleAddToCart = () => {
		const item = !!cart.items.find((cartItem) => {
			return !!cartItem?.requestType && INSURANCE_REQUEST_TYPE.includes(cartItem.requestType);
		});
		if (item) {
			dispatch(
				setModal({
					data: {
						status: 'error',
						body: (
							<>
								<TitleWrapper>{translate('car-insurance.already.in.cart.title')}</TitleWrapper>
								<SubtitleWrapper>
									{translate('car-insurance.already.in.cart.subtitle.part1')}
								</SubtitleWrapper>
							</>
						),
						primaryButtonText: translate('navbar.cart.go-to-cart'),
						onClickPrimary: () => {
							dispatch(setModal({ closeAllModals: true, type: null }));
							history.push(`/${getLang()}/cart`);
						},
						secondaryButtonText: translate('insurance.step.back'),
						onClickSecondary: () => {
							dispatch(setModal({ closeAllModals: true, type: null }));
						},
					},
					type: modalTypes.STATUS,
					withoutContainer: true,
				})
			);
		} else {
			addInsuranceToCart();
		}
	};

	const addInsuranceToCart = () => {
		const payload: IAddInsuranceToCartPayload = {
			quantity: '1',
			xitem_planAction: isBimaInsurance ? 'BUY_CAR_INSURANCE_BIMA' : 'BUY_CAR_INSURANCE_NLG',
			xitem_logo: selectedProvider.thumbnailImageUrl,
			xitem_login: contactDetails?.phoneNumber ? contactDetails?.phoneNumber : '',
			xitem_requestMsisdn: contactNumber,
			xitem_referenceNo: selectedReferenceNo,
			xitem_amount: insuranceValue,
			xitem_name: selectedProvider.name,
			xitem_plateCode: vehicleInformation.vehiclePlateCharCode,
			xitem_licenseNo: licenseNumber,
			xitem_ins: type,
			xitem_value: vehicleInformation.vehicleValue,
			xitem_type: insuranceType,
			xitem_lang: getLang(),
			xitem_vehicleNo: vehicleInformation.vehicleNo,
			xitem_trackId: selectedTrackId,
			xitem_model: vehicleInformation.model,
			xitem_manufacturer: vehicleInformation.manufacturer,
			xitem_features: selectedProvider.features.map((feature) => feature.name).join(', '),
			productId: CAR_INSURANCE_PRODUCT_ID,
		};
		OrderFlowService.addItemsToCart([payload]).subscribe(
			({ response }) => {
				dispatch(
					setModal({
						type: modalTypes.ADDED_TO_CART,
						data: { orderId: response.orderItem[0].orderItemId },
						withoutContainer: true,
					})
				);
			},
			(e) => {
				if (e.response?.errors?.length > 0) {
					if (e.response?.errors[0]?.errorKey) {
					} else if (e.response?.errors[0]?.errorKey === errors.SESSION_GENERIC_USER) {
						dispatch(
							setModal({
								type: modalTypes.LOGIN_SESSION_EXPIRED,
								data: {},
							})
						);
					}
				}
			}
		);
	};

	useEffect(() => {
		setIsAddToCartButtonActive(
			Object.keys(ownerDataFormErrors.errors).length === 0 &&
				Object.keys(ownerDataFormErrors.touched).length === 3 &&
				!!tcAccept
		);
	}, [ownerDataFormErrors, tcAccept]);

	return (
		<CardWrapper expanded={expanded || screen(['xs', 'lg', 'xl', 'xxl'])}>
			<ConditionalRender
				show={expanded || screen(['lg', 'xl', 'xxl'])}
				onTrue={() => (
					<>
						<HeaderWrapper>
							<HeaderText>{translate('insurance.summary.title')}</HeaderText>
							<Divider color="black12" marginBottom={10} />
						</HeaderWrapper>
						<InsuranceProviderWrapper>
							<Image src={selectedProvider?.thumbnailImageUrl} />
							<div>
								<InsuranceProvider>{selectedProvider?.name}</InsuranceProvider>
								<InsuranceOfferDetails>
									{`${vehicleInformation?.manufacturer}, ${vehicleInformation?.model}, `}
									{insuranceType === INSURANCE_TYPE.FULL_COMPREHENSIVE
										? translate('insurance.type.choice1')
										: translate('insurance.type.choice2')}
									{', '}
									{selectedProvider?.features?.map(({ name }, index) => (
										<>{name}, </>
									))}
								</InsuranceOfferDetails>
							</div>
						</InsuranceProviderWrapper>
						<Divider color="black12" marginBottom={10} marginTop={10} />
					</>
				)}
			/>
			<PriceTCWrapper expanded={expanded || screen(['xs', 'lg', 'xl', 'xxl'])}>
				<PriceWrapper>
					<CostWrapper fullWidth withoutPadding>
						<CostTitle>{translate('product.cart-card.upfront')}</CostTitle>
						<PriceContainer>
							<PriceTag price={insuranceValue} />
							<VatInfo>{translate('navbar.cart.vat', '5')}</VatInfo>
						</PriceContainer>
					</CostWrapper>
				</PriceWrapper>
				<ConditionalRender
					show={expanded || screen(['lg', 'xl', 'xxl'])}
					onTrue={() => <Divider marginBottom={10} marginTop={15} color="black12" />}
				/>
				<TCWrapper>
					<SquareCheckbox active={tcAccept} onChange={() => setTcAccept(!tcAccept)}></SquareCheckbox>
					<BodyS>
						{translate('insurance.term.and.conditions.label')}
						<TCLink onClick={handelClick}>{translate('terms.and.conditions.title')}</TCLink>
					</BodyS>
				</TCWrapper>
			</PriceTCWrapper>
			<ButtonContent expanded={expanded || screen(['xs', 'lg', 'xl', 'xxl'])}>
				<Button
					uniqueId="product.cart-card.add-to-cart"
					listener={[ADD_TO_CART_BI]}
					onClick={handleAddToCart}
					variant={ButtonVariant.PRIMARY}
					size={ButtonSize.LARGE}
					width={ButtonWidth.FLEX}
					disabled={!isAddToCartButtonActive}
				>
					{translate('product.cart-card.add-to-cart')}
				</Button>
			</ButtonContent>
			<ConditionalRender
				show={expanded || screen(['lg', 'xl', 'xxl'])}
				onTrue={() => (
					<SecureTransactionWrapper>
						<Icon name="lock" fill="black87" width={24} height={24} />
						<SecureTransactionText color="black87">
							{translate('product.cart-card.transaction.part-1')}
							<HighlightedText color="primary">
								{translate('product.cart-card.transaction.part-2')}
							</HighlightedText>
						</SecureTransactionText>
					</SecureTransactionWrapper>
				)}
			/>
		</CardWrapper>
	);
};

export default InsuranceSummary;
