import React from 'react';
import endpoints from '@Utils/api/endpoints';
import { ENV } from '@Config/app.config';

const _url = endpoints.omantelApi.paymentSite().url;

const CONFIG = {
	PROD: 'portal.omantel.om/OmantelApi/webengine/',
	STG: 'testportal.omantel.om/OmantelApi/webengine/',
	TST: 'testportal.omantel.om/OmantelApi/webengine/',
};

const PaymentFrame = (): JSX.Element => {
	const source = CONFIG[ENV];
	window.location.href = `https://${source}${_url}`;
	return <></>;
};

export default PaymentFrame;
