import React, { FunctionComponent } from 'react';
import { ContentWrapper, LabelWidth, TabsHeader, TabsVariant, TabsWrapper } from './Tabs.styled';
import PrimaryLabel from './subcomponents/PrimaryLabel/PrimaryLabel';
import SecondaryLabel from './subcomponents/SecondaryLabel/SecondaryLabel';

interface ISingleTabProps {
	label: string;
	disabled?: boolean;
	content: React.ReactElement;
}

interface IBaseTabsProps {
	activeTab: number;
	setActiveTab: (index: number) => void;
	tabs: ISingleTabProps[];
	labelWidth?: LabelWidth;
	justifyContent?: types.justifyContent;
}

interface IAdditionalTabsProps {
	variant: TabsVariant;
}

interface ITabsProps extends IBaseTabsProps, IAdditionalTabsProps {}

const Tabs: FunctionComponent<ITabsProps> = ({
	tabs,
	variant,
	labelWidth = LabelWidth.AUTO,
	activeTab,
	setActiveTab,
	justifyContent = 'center',
}) => {
	const Label = variant === TabsVariant.PRIMARY ? PrimaryLabel : SecondaryLabel;

	return (
		<TabsWrapper>
			<TabsHeader variant={variant} justifyContent={justifyContent}>
				{tabs.map((tab, index) => (
					<Label
						label={tab.label}
						labelWidth={labelWidth}
						key={index}
						active={activeTab === index}
						setActiveItem={() => !tab.disabled && setActiveTab(index)}
						disabled={tab.disabled}
					/>
				))}
			</TabsHeader>
			<ContentWrapper>{tabs[activeTab].content}</ContentWrapper>
		</TabsWrapper>
	);
};

export const PrimaryTabs: FunctionComponent<IBaseTabsProps> = (props) => (
	<Tabs {...props} variant={TabsVariant.PRIMARY} />
);

export const SecondaryTabs: FunctionComponent<IBaseTabsProps> = (props) => (
	<Tabs {...props} variant={TabsVariant.SECONDARY} />
);

export default Tabs;
