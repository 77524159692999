import Stepper from '@Components/controls/Stepper/Stepper';
import { StepperState } from '@Components/controls/Stepper/Stepper.styled';
import useScreen from '@Utils/hooks/useScreen';
import useTranslate from '@Utils/hooks/useTranslate';
import React, { FunctionComponent } from 'react';
import { StepperWrapper, StepContainer, StepDivider, StepTitle, StepWrapper } from './AccountCreationStepper.styled';
import theme from '@Utils/theme/theme';

const STEP_SIZE = 20;

const AccountCreationStepper: FunctionComponent<{ step: number }> = ({ step }) => {
	const { screen } = useScreen();
	const { translate } = useTranslate();
	const isXs = screen('xs');

	const steps = [
		translate('microsoft-automation-proccess.stepper.step-1'),
		translate('microsoft-automation-proccess.stepper.step-2'),
		translate('microsoft-automation-proccess.stepper.step-3'),
	];

	return (
		<StepperWrapper>
			{steps.map((_step, index) => {
				const stepNumber = step ?? 0;
				const getStepperState = () => {
					if (stepNumber === index + 1) {
						return 'ACTIVE';
					} else if (stepNumber > index + 1) {
						return 'DONE';
					}
					return 'UNDONE';
				};
				return (
					<StepContainer key={index} isXs={isXs}>
						<StepWrapper>
							<Stepper
								size={STEP_SIZE}
								value={index + 1}
								state={StepperState[getStepperState()]}
								bgColor={theme.colors.white}
							/>
						</StepWrapper>
						{!(index + 1 === steps.length) && <StepDivider active={stepNumber >= index + 2} isXs={isXs} />}
						<StepTitle>{_step}</StepTitle>
					</StepContainer>
				);
			})}
		</StepperWrapper>
	);
};

export default AccountCreationStepper;
