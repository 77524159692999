import { BodyS, Caption } from '@Components/Typography/Typography.styled';
import { matchScreen, paddingLeft, paddingRight } from '@Utils/layout/layoutSupport';
import styled from 'styled-components';

export const ContactDetailsWrapper = styled.div`
	${() => ({
		padding: matchScreen('xs') ? 0 : 8,
	})}
	${() => paddingLeft(matchScreen('xs') ? 0 : 32)}
	${() => paddingRight(matchScreen('xs') ? 0 : 16)}
`;

export const HeaderWrapper = styled.div`
	display: flex;
`;

export const Subtitle = styled(BodyS)`
	${({ theme }) => ({
		color: theme.colors.black54,
	})}
`;

export const ErrorMessage = styled(Caption)`
	margin-top: 12px;
	margin-bottom: 16px;
	${({ theme }) => ({
		color: theme.colors.warning,
	})}
`;

export const IconWrapper = styled.div`
	${() => paddingRight(16)}
`;

export const ResidentIconWrapper = styled.div`
	display: flex;
	justify-content: flex-start;
	padding-top: 16px;
	padding-bottom: 32px;
`;

export const IconContainer = styled.div`
	box-shadow: 0px 6px 20px -7px ${({ theme }) => theme.colors.black24};
	border-radius: 6px;
`;

export const ButtonsWrapper = styled.div`
	margin-top: 32px;
`;

export const FormWrapper = styled.div``;

export const DragAndDropWrapper = styled.div`
	display: flex;
	gap: 16px;
`;
